import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { BIN_COLORS, SCORE_BIN_COLORS } from '../../constants';
import BarSegmentItems from './BarSegmentItems';
import BarSegments, { BarSegmentTickFormat } from './BarSegments';

const useSegmentedBarStyles = makeStyles((theme) => ({
  segmentedBarContainer: {
    position: 'relative',
    height: '100%',
    width: '90%',
  },
  barSegments: {
    cursor: 'pointer',
    opacity: 0.5,
  },
}));

export function BaselineSegmentedBar({ pages, bins, chartHeight, ...rest }) {
  const [binRatios, setBinRatios] = useState([]);
  const [positions, setPositions] = useState({});
  const classes = useSegmentedBarStyles();
  const { width, height, ref } = useResizeDetector();

  const calculateBinRatios = (bins) => {
    return bins.map(({ pageIds }) => {
      return 1 + (pageIds ? pageIds.length * 0.1 : 0);
    });
  };

  const generateSegmentBoundRects = (binRatios, bar) => {
    const ratioAggregate = binRatios.reduce((sum, ratio) => sum + ratio, 0.0);
    const base = bar.width / ratioAggregate;
    const rects = binRatios.reduce(
      (all, ratio) => {
        const width = all.base * ratio;
        all.segments.push({
          left: all.widths,
          mid: width * 0.5,
          width: width,
        });
        all.widths = all.widths + width;
        return all;
      },
      { widths: 0, base, segments: [] }
    );
    return rects.segments;
  };

  const transitionItemsToPoints = (rects, bins) => {
    const newPositions = bins.reduce((positions, bin, segment) => {
      if (bin.pageIds.length === 0) {
        return positions;
      }

      if (bin.pageIds.length === 1) {
        positions[`${segment}-${bin.pageIds[0]}`] = rects[segment].left + rects[segment].mid;
        return positions;
      }

      /*
         May not be the most efficient way of solving the problem,
         but basically we needed to balance the items within a bin
         so that:
         1) They are evenly space and centered in the bin
         2) If there is a direct center, a pin should be on it
         3) If there is no direct center, all pins balance on
         either side of the direct center
         4) The baseline page must be (or at most
         one step away from) the direct center
         */
      const midpoint = bin.pageIds.length / 2;
      const belowMidpoint = bin.pageIds.slice(0, midpoint);
      const aboveMidpoint = bin.pageIds.slice(midpoint);

      const centerPageId =
        aboveMidpoint.length > belowMidpoint.length
          ? aboveMidpoint.shift()
          : belowMidpoint.length > aboveMidpoint.length
          ? belowMidpoint.pop()
          : null;

      const scaleFactor = 50;
      const pinWidth = 30;
      const actualScale = centerPageId === null ? scaleFactor / 2 : scaleFactor;
      if (centerPageId !== null) {
        positions[`${segment}-${centerPageId}`] = rects[segment].left + rects[segment].mid;
      }

      belowMidpoint.forEach((pageId, index) => {
        let newPos = rects[segment].left + rects[segment].mid - actualScale * (index + 1);
        if (newPos - pinWidth / 2 <= rects[segment].left) {
          newPos = rects[segment].left + pinWidth / 2;
        }
        positions[`${segment}-${pageId}`] = newPos;
      });

      aboveMidpoint.forEach((pageId, index) => {
        let newPos = rects[segment].left + rects[segment].mid + actualScale * (index + 1);
        const right = rects[segment].left + rects[segment].width;
        if (newPos + pinWidth / 2 >= right) {
          newPos = right - pinWidth / 2;
        }
        positions[`${segment}-${pageId}`] = newPos;
      });

      return positions;
    }, {});

    return newPositions;
  };
  const updateDimensions = useCallback(() => {
    if (!ref?.current) {
      return;
    }

    const newBinRatios = calculateBinRatios(bins);
    const rect = ref.current.getBoundingClientRect();
    const rects = generateSegmentBoundRects(newBinRatios, rect);
    const newPositions = transitionItemsToPoints(rects, bins);
    setBinRatios(newBinRatios);
    setPositions(newPositions);
  }, [bins, ref]);

  useEffect(() => {
    updateDimensions();
  }, [width, height, pages, updateDimensions]);

  return (
    <div className={classes.segmentedBarContainer}>
      <section ref={ref}>
        <BarSegmentItems
          bins={bins}
          pages={pages}
          positions={positions}
          height={chartHeight}
          showFlags={true}
          showScores={false}
        />
        <BarSegments bins={bins} binColors={BIN_COLORS} binRatios={binRatios} height={chartHeight} />
      </section>
    </div>
  );
}

export function PageScoreSegmentedBar({ pages, bins, chartHeight, ...rest }) {
  const PAGE_SCORE_MIN = 0;
  const PAGE_SCORE_MAX = 100;

  const [binRatios, setBinRatios] = useState([]);
  const [positions, setPositions] = useState({});
  const classes = useSegmentedBarStyles();
  const { width, height, ref } = useResizeDetector();

  const calculateBinRatios = (bins) => {
    return bins.map(({ pageIds }) => {
      return 1 + (pageIds ? pageIds.length * 0.1 : 0);
    });
  };

  const generateSegmentBoundRects = useCallback((binRatios, bar) => {
    const ratioAggregate = binRatios.reduce((sum, ratio) => sum + ratio, 0.0);
    const base = bar.width / ratioAggregate;
    const rects = binRatios.reduce(
      (all, ratio) => {
        const width = all.base * ratio;
        all.segments.push({
          left: all.widths,
          mid: width * 0.5,
          width: width,
        });
        all.widths = all.widths + width;
        return all;
      },
      { widths: 0, base, segments: [] }
    );
    return rects.segments;
  }, []);

  const rescaleScoreToRange = useCallback((score, rangeMin, rangeMax, desiredMin, desiredMax) => {
    return ((score - rangeMin) / (rangeMax - rangeMin)) * (desiredMax - desiredMin) + desiredMin;
  }, []);

  const transitionItemsToPoints = useCallback(
    (rects, bins) => {
      const newPositions = bins.reduce((positions, bin, segment) => {
        if (bin.pageIds.length === 0) {
          return positions;
        }

        const segmentRect = rects[segment];
        const segmentMin = segmentRect.left;
        const segmentMax = segmentMin + segmentRect.width;
        const binMin = bin.min ?? PAGE_SCORE_MIN;
        const binMax = bin.max ?? PAGE_SCORE_MAX;

        const pagesByScore = bin.pageIds
          .map((pageId) => pages.find((page) => page.id === pageId))
          .reduce((acc, page) => {
            const score = Math.round(page.rawScore ?? 0);
            score in acc ? acc[score].push(page) : (acc[score] = [page]);
            return acc;
          }, {});

        Object.keys(pagesByScore).forEach((score) => {
          const pages = pagesByScore[score];
          const position = rescaleScoreToRange(score, binMin, binMax, segmentMin, segmentMax);

          // use midpoint adjustment to determine positions of icons
          // when there are multiple for a given score, we nudge them a little bit to avoid overlapping
          const midpoint = Math.floor(pages.length / 2);

          pages.forEach((page, index) => {
            positions[`${segment}-${page.id}`] = position + (index - (1 / pages.length) * midpoint) * 20;
          });
        });

        return positions;
      }, {});

      return newPositions;
    },
    [pages, rescaleScoreToRange]
  );

  const updateDimensions = useCallback(() => {
    if (!ref?.current) {
      return;
    }

    const newBinRatios = calculateBinRatios(bins);
    const rect = ref.current.getBoundingClientRect();
    const rects = generateSegmentBoundRects(newBinRatios, rect);
    const newPositions = transitionItemsToPoints(rects, bins);
    setBinRatios(newBinRatios);
    setPositions(newPositions);
  }, [bins, generateSegmentBoundRects, ref, setBinRatios, setPositions, transitionItemsToPoints]);

  useEffect(() => {
    updateDimensions();
  }, [width, height, pages, updateDimensions]);

  return (
    <div className={classes.segmentedBarContainer}>
      <section ref={ref}>
        <BarSegmentItems
          bins={bins}
          pages={pages}
          positions={positions}
          height={chartHeight}
          showFlags={false}
          showScores={true}
        />
        <BarSegments
          bins={bins}
          binColors={SCORE_BIN_COLORS}
          binRatios={binRatios}
          binLabels={bins.map((bin) => bin.label)}
          height={chartHeight}
          tickFormat={BarSegmentTickFormat.Numeric}
          showTicks={false}
        />
      </section>
    </div>
  );
}
