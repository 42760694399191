import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomQuestionMutationKeys } from '../../../modules/intake/constants';

const updateSortOrder = async ({ id: wevoId, groupId, ...changedFields }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/custom-questions/groups/${groupId}/reorder`,
    method: 'PUT',
    data: changedFields,
  });
  return response.data.groups;
};

/**
 * Custom hook for updating a customQuestion sort order.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the updated data
 */
export default function useUpdateSortOrder() {
  const queryClient = useQueryClient();

  return useMutation(updateSortOrder, {
    mutationKey: CustomQuestionMutationKeys.updateSortOrder,
    onMutate: async ({ id: wevoId, groupId, ...changedFields }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['customQuestionsData', { wevoId }]);

      // Snapshot the previous customQuestionsData value
      const previousCustomQuestionsState = await queryClient.getQueryData(['customQuestionsData', { wevoId }]);

      // Return a context object with the snapshotted value
      return { previousCustomQuestionsState };
    },
    onSuccess: (groups, variables) => {
      queryClient.setQueryData(['customQuestionsData', { wevoId: variables.id }], groups);
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { id: wevoId, groupId, ...changedFields }, { previousCustomQuestionsState }) => {
      queryClient.setQueryData(['customQuestionsData', { wevoId }], previousCustomQuestionsState);
    },
  });
}
