import { useMutation } from 'react-query';
import axios from '../api';

const importFigmaPrototype = async ({
  id: wevoId,
  journeyStartUrl,
  deviceId,
  pageId,
  primerId,
  primerContext,
}) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/prototypes/figma`,
    method: 'PUT',
    data: { importURL: journeyStartUrl, deviceId, pageId, primerId, primerContext },
  });
  return data || {};
};

export default function useImportFigmaPrototype() {
  return useMutation(importFigmaPrototype);
}
