import HowToRegIcon from '@mui/icons-material/HowToReg';
import InfoIcon from '@mui/icons-material/Info';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Divider, styled, Tooltip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as EmailValidator from 'email-validator';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as DownloadIcon } from '../../../src/assets/download-icon.svg';
import useAuthorizeFigma from '../../hooks/useAuthorizeFigma';
import useCreditUsage, { useDownloadCreditUsageReport } from '../../hooks/useCreditUsage';
import useFetchAuthorization from '../../hooks/useFetchAuthorization';
import {
  canOnlyUseAutomatedInsightSessions,
  canOnlyUseWevoPro,
} from '../../modules/automated-insights/helpers';
import { getError } from '../../modules/error/selectors';
import * as UserActions from '../../modules/user/actions';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { AuthenticExperienceOption, CreditUsageReporting } from '../../modules/wevos/constants';
import { snackbar } from '../../notifications';
import { TrackEvent } from '../analytics';
import { StyledTextField } from '../automated-insights/ui/TextField';

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const ProfileForm = (props) => {
  const {
    fetchUserInfo,
    userProfile,
    userCustomizations,
    inputLabelStyles,
    inputBaseRootStyles,
    updateButtonStyles,
    figmaButtonStyles,
    textStyles,
  } = props;

  const { track } = useAnalytics();

  const [formData, setFormData] = useState(userProfile);

  const [isFirstNameValid, setFirstNameValid] = useState(false);
  const [isLastNameValid, setLastNameValid] = useState(false);
  const [isEmailValid, setEmailValid] = useState(false);

  const [isFormValid, setFormValid] = useState(false);

  const [isProfileChanged, setProfileChanged] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const { data: authorization } = useFetchAuthorization();
  const { mutate: authorizeFigma } = useAuthorizeFigma();
  const { mutate: downloadCreditUsageReport } = useDownloadCreditUsageReport();

  const {
    data: creditUsage,
    isLoading,
    isError,
  } = useCreditUsage({
    enabled:
      // must do a nil check because on first render userCustomizations may be undefined, and therefore
      // the second check would be true even if creditUsageReporting is really disabled for the user.
      !_.isNil(userCustomizations) &&
      userCustomizations?.features?.creditUsageReporting !== CreditUsageReporting.Disabled,
  });

  const creditsRemaining = useMemo(() => {
    return creditUsage?.usage?.[0]?.creditsRemaining;
  }, [creditUsage]);

  const creditsRemainingDisplayValue = useMemo(() => {
    return _.isNil(creditsRemaining) ? '?' : Math.max(0, creditsRemaining);
  }, [creditsRemaining]);

  const showRemainingCredits = useMemo(() => {
    return !isLoading && !isError && !_.isNil(creditsRemaining);
  }, [isLoading, isError, creditsRemaining]);

  const pulseCreditsRemaining = useMemo(() => {
    return creditUsage?.pulseUsage?.pulseCreditsRemaining;
  }, [creditUsage]);

  const pulseCreditsRemainingDisplayValue = useMemo(() => {
    return _.isNil(pulseCreditsRemaining) ? '?' : Math.max(0, pulseCreditsRemaining);
  }, [pulseCreditsRemaining]);

  const showPulseRemainingCredits = useMemo(() => {
    return !isLoading && !isError && !_.isNil(pulseCreditsRemaining);
  }, [isLoading, isError, pulseCreditsRemaining]);

  const showFigmaAuthorization = userCustomizations?.authenticExperience === AuthenticExperienceOption.Enabled;

  const isPulseOnlyUser = useMemo(
    () => canOnlyUseAutomatedInsightSessions(userProfile, userCustomizations),
    [userCustomizations, userProfile]
  );

  const isWevoProOnlyUser = useMemo(
    () => canOnlyUseWevoPro(userProfile, userCustomizations),
    [userCustomizations, userProfile]
  );

  const authorizeApp = () => {
    authorizeFigma(
      { nextUri: `${process.env.REACT_APP_BASE_URL}/profile` },
      {
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error authorizing Figma');
        },
      }
    );
  };

  const handleDownloadCreditUsageReport = () => {
    downloadCreditUsageReport(null, {
      onSuccess: () => {
        track(TrackEvent.DOWNLOADED_CREDIT_USAGE_REPORT, { userId: userProfile.id });
      },
      onError: (err) => {
        snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error downloading credit usage report.');
      },
    });
  };

  useEffect(() => {
    // code to run on component mount
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // pass empty array as second argument to ensure this only executes once

  useEffect(() => {
    setProfileChanged(
      formData.firstName !== userProfile.firstName ||
        formData.lastName !== userProfile.lastName ||
        formData.email !== userProfile.email ||
        formData.title !== userProfile.title
    );
  }, [formData, userProfile]);

  useEffect(() => {
    setFormData({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      email: userProfile.email,
      title: userProfile.title,
    });
  }, [userProfile]);

  useEffect(() => {
    setSubmitButtonDisabled(!(!!isProfileChanged && !!isFormValid));
  }, [isProfileChanged, isFormValid]);

  useEffect(() => {
    setFirstNameValid(!!formData.firstName || formData.firstName === userProfile.firstName);
  }, [formData.firstName, userProfile.firstName]);

  useEffect(() => {
    setLastNameValid(!!formData.lastName || formData.lastName === userProfile.lastName);
  }, [formData.lastName, userProfile.lastName]);

  useEffect(() => {
    setEmailValid(EmailValidator.validate(formData.email) || formData.email === userProfile.email);
  }, [formData.email, userProfile.email]);

  useEffect(() => {
    setFormValid(!!isFirstNameValid && !!isLastNameValid && !!isEmailValid);
  }, [isFirstNameValid, isLastNameValid, isEmailValid]);

  const handleFormInput = (ev) => {
    setFormData({ ...formData, [ev.target.name]: ev.target.value });
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    if (!isFormValid) {
      return false;
    } else {
      props.updateUser(formData);
    }
  };

  return (
    <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Avatar sx={{ margin: 1, backgroundColor: (theme) => theme.palette.primary.main }}>
        <PersonOutlineIcon />
      </Avatar>
      <Typography variant="h4" sx={{ ...textStyles }}>
        Account
      </Typography>
      <Grid container rowGap={3} sx={{ marginTop: 2 }}>
        <Grid item xs={12} sm={3}>
          <Box py={2}>
            <Typography fontWeight="bold" variant="body2" sx={{ ...inputLabelStyles }}>
              Profile
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box px={{ sm: 6 }}>
            <StyledForm noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    id="fname"
                    name="firstName"
                    type="text"
                    autoComplete="given-name"
                    fullWidth
                    label="First Name"
                    value={formData.firstName}
                    variant="outlined"
                    inputLabelStyles={inputLabelStyles}
                    inputBaseRootStyles={inputBaseRootStyles}
                    onChange={handleFormInput}
                    error={!isFirstNameValid}
                    helperText={!isFirstNameValid && 'First name cannot be empty'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    id="lname"
                    name="lastName"
                    type="text"
                    autoComplete="family-name"
                    fullWidth
                    label="Last Name"
                    value={formData.lastName}
                    variant="outlined"
                    inputLabelStyles={inputLabelStyles}
                    inputBaseRootStyles={inputBaseRootStyles}
                    onChange={handleFormInput}
                    error={!isLastNameValid}
                    helperText={!isLastNameValid && 'Last name cannot be empty'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    id="title"
                    name="title"
                    type="text"
                    autoComplete="organization-title"
                    fullWidth
                    label="Title"
                    value={formData.title}
                    variant="outlined"
                    inputLabelStyles={inputLabelStyles}
                    inputBaseRootStyles={inputBaseRootStyles}
                    onChange={handleFormInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    label="Email Address"
                    value={formData.email}
                    fullWidth
                    variant="outlined"
                    inputLabelStyles={inputLabelStyles}
                    inputBaseRootStyles={inputBaseRootStyles}
                    onChange={handleFormInput}
                    error={!isEmailValid}
                    helperText={!isEmailValid && 'Please enter a valid email address'}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={onSubmit}
                disabled={submitButtonDisabled}
                sx={{ mt: showRemainingCredits ? 2 : 6, ...updateButtonStyles }}>
                Update Account
              </Button>
            </StyledForm>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {(showRemainingCredits || showPulseRemainingCredits) && (
          <>
            <Grid item xs={6} alignContent="center">
              <Box>
                <Typography
                  mr={1}
                  component="span"
                  fontWeight="bold"
                  variant="body2"
                  sx={{ ...inputLabelStyles }}>
                  Remaining balance
                </Typography>
                <Tooltip
                  placement={'bottom-end'}
                  title={
                    <Box>
                      <Box mb={2}>
                        <Typography variant="body1">
                          If you have pending tests, the remaining balance is subject to change.
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body1">
                          Please reach out to your Customer Success Manager if you need to clarify the exact
                          amount.
                        </Typography>
                      </Box>
                    </Box>
                  }>
                  <InfoIcon
                    sx={{ color: grey[600], fontSize: 18, verticalAlign: 'text-bottom', ...textStyles }}
                  />
                </Tooltip>
              </Box>
            </Grid>

            {showRemainingCredits && !isPulseOnlyUser && (
              <>
                <Grid item xs={6}>
                  <Box textAlign="right" pr={2}>
                    <Typography
                      component="span"
                      fontWeight={700}
                      sx={{ color: 'primary.main', marginRight: 1 }}>
                      {creditsRemainingDisplayValue}
                    </Typography>
                    <Typography component="span" variant="caption" fontWeight={500} sx={{ ...textStyles }}>
                      Pro Credits
                    </Typography>
                  </Box>
                </Grid>
              </>
            )}

            {showPulseRemainingCredits && !isWevoProOnlyUser && (
              <Grid item xs={showRemainingCredits && !isPulseOnlyUser ? 12 : 6}>
                <Box textAlign="right" pr={2}>
                  <Typography component="span" fontWeight={700} sx={{ color: 'primary.main', marginRight: 1 }}>
                    {pulseCreditsRemainingDisplayValue}
                  </Typography>
                  <Typography component="span" variant="caption" fontWeight={500} sx={{ ...textStyles }}>
                    Pulse Credits
                  </Typography>
                </Box>
              </Grid>
            )}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {showRemainingCredits && !isPulseOnlyUser && (
              <>
                <Grid item xs={6}>
                  <Typography
                    mr={1}
                    component="span"
                    fontWeight="bold"
                    variant="body2"
                    sx={{ ...inputLabelStyles }}>
                    Usage Data
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Box textAlign="right">
                    <Button
                      variant="plain"
                      size="sm"
                      onClick={handleDownloadCreditUsageReport}
                      sx={{ textTransform: 'none', color: 'text.primary' }}>
                      <Box component="span" mr={2} sx={{ verticalAlign: 'text-bottom' }}>
                        <DownloadIcon />
                      </Box>
                      <Typography variant="body" sx={{ ...inputLabelStyles }}>
                        Download
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </>
            )}
          </>
        )}

        <Grid item xs={6}>
          <Typography mr={1} component="span" fontWeight="bold" variant="body2" sx={{ ...inputLabelStyles }}>
            Privacy
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Box textAlign="right">
            <Button
              variant="plain"
              size="sm"
              onClick={() => {
                window.ketch && window.ketch('showPreferences');
              }}
              sx={{ textTransform: 'none', color: 'text.primary' }}>
              <Box component="span" mr={2} sx={{ verticalAlign: 'text-bottom' }}>
                <SettingsIcon sx={{ fontSize: '16px', verticalAlign: 'middle' }} color="primary" />
              </Box>
              <Typography variant="body" sx={{ ...inputLabelStyles }}>
                Preferences
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>

        {showFigmaAuthorization && !isPulseOnlyUser && (
          <>
            <Grid item xs={6}>
              <Typography
                mr={1}
                component="span"
                fontWeight="bold"
                variant="body2"
                sx={{ ...inputLabelStyles }}>
                Figma Authorization
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="right">
                <Button
                  variant="plain"
                  color="primary"
                  size="sm"
                  onClick={authorizeApp}
                  sx={{ ...figmaButtonStyles, textTransform: 'none' }}>
                  {!_.isNil(authorization) && (
                    <HowToRegIcon color="primary" sx={{ fontSize: '16px', verticalAlign: 'middle', mr: 2 }} />
                  )}
                  <Typography variant="body" sx={{ ...inputLabelStyles }}>
                    Authorize Figma
                  </Typography>
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfile: getUserProfile(state),
    userCustomizations: getUserCustomizations(state),
    errorMessage: getError(state),
  };
};

const actionCreators = {
  fetchUserInfo: UserActions.fetchUserInfo,
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, actionCreators)(ProfileForm);
