import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Grid,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import {
  BarChartBenchmarksFontSizes,
  DiagnosticOutcome,
  DiagnosticOutcomeToColor,
  DiagnosticOutcomeToLabel,
} from '../../../modules/report/constants';
import { chunkString } from '../../../modules/report/helpers.js';
import { VersionedDiagnosticsOrdering } from '../../../modules/wevos/constants';
import BarChartWithBenchmarks from '../components/BarChartWithBenchmarks';

const ChartTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 450,
  },
});

const DiagnosticsBarGraph = (props) => {
  const {
    diagnostics,
    benchmarks,
    onBarSelected,
    width,
    height,
    tooltipPlacement,
    fontSize,
    isDashboard,
    isPptComponent,
    componentId,
    hasBenchmarks = true,
  } = props;

  const theme = useTheme();
  const styles = {
    chartContainer: {
      width: '100%',
      height: '100%',
      background: 'white',
      padding: hasBenchmarks ? '2% 4% 2% 10%' : '2% 4% 2% 4%',
    },
    chartInfoContainer: {
      marginTop: theme.spacing(-3),
      paddingBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end',
      maxWidth: '260px',
      minWidth: '10px',
    },
    chartTooltipContent: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    chartTooltipItems: {
      margin: 0,
      padding: 0,
      paddingLeft: 1,
      '& li': {
        marginTop: theme.spacing(1),
        marginRight: 0,
      },
    },
    chartKey: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: theme.spacing(1),
    },
    chartKeyLabel: {
      fontSize: '11px',
      margin: 0,
    },
    legendHeader: {
      fontSize: '11px',
      fontWeight: 'bold',
      marginTop: '4px',
    },
  };

  // ensure ordering is always consistent
  const diagnosticOrdering = VersionedDiagnosticsOrdering;
  const benchmarkThresholds = Array(5).fill(5);

  const [diagnosticByName, setDiagnosticByName] = useState({});

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
  let labelMaxWidth;

  if (isSmallDevice) {
    labelMaxWidth = 25;
  } else {
    labelMaxWidth = 45;
  }

  useEffect(() => {
    setDiagnosticByName(
      diagnostics.reduce((acc, cur) => {
        acc[cur.name] = cur;
        return acc;
      }, {})
    );
  }, [diagnostics]);

  // only render the bar chart when the data is available
  if (Object.keys(diagnosticByName).length === 0) {
    return null;
  }

  return (
    <Box sx={styles.chartContainer}>
      <Grid container spacing={4} id={componentId} style={{ marginTop: '16px' }}>
        <Grid item xs={isPptComponent ? 9 : 11} lg={hasBenchmarks ? 9 : 11}>
          <BarChartWithBenchmarks
            labels={diagnosticOrdering.map((diagnostic) => diagnosticByName[diagnostic]?.name)}
            dataLabels={[
              diagnosticOrdering.map((diagnostic) => Math.round(diagnosticByName[diagnostic]?.score ?? 0.0)),
            ]}
            datasets={[
              diagnosticOrdering.map((diagnostic) => Math.round(diagnosticByName[diagnostic]?.score ?? 0.0)),
            ]}
            benchmarkThresholds={benchmarkThresholds}
            benchmarks={diagnosticOrdering.map((diagnostic) => {
              const benchmarkScore = benchmarks?.[diagnostic]?.score;
              return benchmarkScore ? Math.round(benchmarkScore) : null;
            })}
            onBarSelected={onBarSelected}
            width={width || '100%'}
            height={height || '375px'}
            benchmarkLabel={'Industry benchmark'}
            tooltipLabelDescription={(dataIndex) =>
              chunkString(
                diagnostics?.find((diagnostic) => diagnostic.name === diagnosticOrdering[dataIndex])
                  ?.description || '',
                labelMaxWidth
              )
            }
            fontSize={fontSize}
            chartLabelSize={BarChartBenchmarksFontSizes.Small}
            hasBenchmarks={hasBenchmarks}
          />
        </Grid>
        <Grid item xs={isPptComponent ? 3 : 12} lg={hasBenchmarks ? 3 : 1} sx={styles.chartInfoContainer}>
          <Grid container spacing={2} justifyContent="flex-end" wrap="nowrap">
            {hasBenchmarks && (
              <Grid item xs={10}>
                <div>
                  <Typography gutterBottom sx={styles.legendHeader}>
                    How do I read this chart?
                  </Typography>
                  <div>
                    <ul style={{ paddingLeft: 1 }}>
                      {Object.values(DiagnosticOutcome).map((outcome) => (
                        <li key={outcome} style={styles.chartKey}>
                          <div
                            style={{
                              backgroundColor: DiagnosticOutcomeToColor[outcome],
                              paddingLeft: theme.spacing(1),
                              width: '24px',
                              height: '9px',
                              marginRight: '12px',
                              minWidth: '10px',
                            }}
                          />
                          <p style={styles.chartKeyLabel}>{DiagnosticOutcomeToLabel[outcome]}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Grid>
            )}
            {!isPptComponent && (
              <Grid item xs={2}>
                <ChartTooltip
                  placement={tooltipPlacement || 'left-end'}
                  title={
                    <div style={styles.chartTooltipContent}>
                      <Typography component="p" sx={{ paddingBottom: 1 }} variant="caption">
                        WEVO’s methodology generates more authentic results by relying on panelists’ emotional
                        response and eliminating the rational answer (what panelists feel they should say) for
                        all scoring questions.
                      </Typography>
                      <Typography component="p" sx={{ paddingBottom: 1 }} variant="caption">
                        WEVO uses well-tested techniques such as word buckets, emotion facial images, etc. to
                        generate the most authentic (vs. contrived) response.
                      </Typography>
                      <Box component="ul" sx={styles.chartTooltipItems}>
                        <li>
                          Visitors select words that best reflect their experience from a series of word banks.
                        </li>
                        <li>
                          Visitors complete open-end responses after completing each word bank which provide
                          the “why” for each of the diagnostics.
                        </li>
                        <li>
                          WEVO’s proprietary method transforms word choice into a raw score which is converted
                          to a standard range with normal distribution, leveraging a historic median.
                        </li>
                        <li>All diagnostics scores are out of 40.</li>
                        <li>
                          The Y-axis on the diagnostic graph will most often display 30, as the graph size
                          depends on the range of scores in each test, but scores are always based on a scale
                          of 0-40 regardless of what is displayed on the Y-axis of the graph.
                        </li>
                        <li>Responses are filterable by sentiment, demographics and custom filters.</li>
                      </Box>
                    </div>
                  }>
                  <InfoIcon
                    sx={{
                      color: grey[600],
                      fontSize: isDashboard ? '1.25rem' : '1.5rem',
                    }}
                  />
                </ChartTooltip>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item id="diagnostics-graph-content" sx={{ display: 'none', maxHeight: '0px' }}>
          {`Graph content: ${String(
            diagnosticOrdering.map(
              (diagnostic) =>
                `${diagnosticByName[diagnostic]?.name} ${Math.round(
                  diagnosticByName[diagnostic]?.score ?? 0.0
                )}`
            )
          )}`}
        </Grid>
      </Grid>
      {!hasBenchmarks && (
        <Typography component="p" sx={{ paddingTop: 1, float: 'right' }} variant="caption">
          * Diagnostics do not include statistical significance.
        </Typography>
      )}
    </Box>
  );
};

export default DiagnosticsBarGraph;
