import _ from 'lodash';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  Card,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CustomQuestionTypes, SelectOptions } from '../../../modules/intake/constants';
import { WevoType } from '../../../modules/wevos/constants';
import ImagePreview from '../../../ui/ImagePreview';

const useStyles = makeStyles((theme) => ({
  field: {
    marginTop: theme.spacing(1),
  },
  pageName: {
    fontSize: '11px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  styledCard: {
    borderRadius: '15px',
  },
  card: {
    maxWidth: '150px',
    maxHeight: '95px',
  },
  typeDescription: {
    marginRight: theme.spacing(-3),
    fontSize: '13px',
    color: grey[600],
  },
  tableCell: {
    borderBottom: 'none',
  },
  expandIconBox: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
    height: theme.spacing(4),
    width: theme.spacing(5),
    alignSelf: 'flex-end',
  },
  honorNewLines: {
    whiteSpace: 'pre-line',
  },
}));

const QUESTION_TYPE = {
  [CustomQuestionTypes.CustomQualitative]: 'Text',
  [CustomQuestionTypes.YesNoTrueFalse]: 'Yes/No',
  [CustomQuestionTypes.MultipleChoice]: 'Multiple Choice',
  [CustomQuestionTypes.FiveLikertScale]: 'Likert 5',
  [CustomQuestionTypes.SevenLikertScale]: 'Likert 7',
  [CustomQuestionTypes.MultiSelect]: 'Multiple Selection',
  [CustomQuestionTypes.Heatmap]: 'Click Map',
};

const LABELS_TYPE = {
  [SelectOptions.AgreeDisagree]: 'Agree Disagree',
  [SelectOptions.EasyDifficult]: 'Easy Difficult',
  [SelectOptions.LikeDislike]: 'Like Dislike',
  [SelectOptions.LikelyUnlikely]: 'Likely Unlikely',
  [SelectOptions.SatisfiedDissatisfied]: 'Satisfied Dissatisfied',
  [SelectOptions.TrueFalse]: 'True/False',
  [SelectOptions.YesNo]: 'Yes/No',
  [SelectOptions.Custom]: 'Custom',
};

// Customed AccordionSummary
const AccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expandIcon: {
    alignSelf: 'flex-end',
    marginBottom: '10px',
  },
  expanded: {},
})(MuiAccordionSummary);

// Customed AccordionDetails
const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: grey[100],
    borderTop: '2px solid rgba(0, 0, 0, .125)',
  },
}))(MuiAccordionDetails);

function SelectedAssetsGrid({ assets }) {
  const classes = useStyles();

  return assets?.map((asset) => {
    return (
      <Grid item id={asset.id} key={asset.id} xs={6}>
        <Card elevation={2} className={classes.card}>
          <ImagePreview image={asset.images} maxHeight="70px" maxWidth="150px" scopeImage={false} />
          <Grid container spacing={1} alignItems="center" justifyContent="space-around">
            <Grid item xs={12}>
              <Tooltip title={asset.name}>
                <Typography align="center" className={classes.pageName}>
                  {asset.name}
                </Typography>
              </Tooltip>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  });
}

SelectedAssetsGrid.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object),
};

/*
 * Shows card with a group and its info.
 */
const GroupReviewCard = (props) => {
  const { group, type, pages, handleEditDetailsClick, allowEditing, isStyled } = props;
  const classes = useStyles();

  const groupId = group?.groupId;
  const scopes = group?.scopes;
  const name = group?.name;
  const question = group?.question;
  const questionType = question?.type;
  const questionText = question?.questionText;
  const followUpText = question?.followUpQuestionText;
  const labelsType = question?.labelsType;

  const [isExpanded, setIsExpanded] = useState(false);

  const pageExistsInScopes = (page) => {
    const id = String(page.id);
    return scopes.find((scope) => scope.wevoPageId === id && !scope.stepId);
  };

  const stepExistsInScopes = (step) => {
    const id = String(step.id);
    return scopes.find((scope) => scope.stepId === id);
  };

  const handleArrowClicked = (ev) => {
    if (selectionLevel() === 'Selected Pages') {
      // Stops the onClick() of the parent component (AccordionSummary)
      ev.stopPropagation();
      setIsExpanded(!isExpanded);
    }
  };

  const filteredPages = pages
    .filter((page) => pageExistsInScopes(page))
    .filter((page) => !_.isNil(page?.images));
  const filteredSteps = pages
    .flatMap((page) => page?.steps ?? [])
    .filter((step) => stepExistsInScopes(step))
    .filter((step) => !_.isNil(step?.images));

  const selectionLevel = () => {
    if (type === WevoType.Classic) {
      return 'Selected Pages';
    } else {
      if (filteredSteps.length) {
        return 'Selected Pages';
      } else {
        return 'Journey-Level';
      }
    }
  };

  return (
    <Card elevation={isStyled && 4} className={isStyled && classes.styledCard}>
      <Accordion key={groupId} expanded={isExpanded}>
        <AccordionSummary
          aria-controls="Custom-Questions"
          id={String(groupId)}
          {...(allowEditing && { onClick: handleEditDetailsClick })}
          style={{ cursor: allowEditing ? 'pointer' : 'default', userSelect: 'text' }}>
          <Grid container direction="column">
            {name && (
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  {name}
                </Typography>
              </Grid>
            )}
            {questionText && (
              <Grid item>
                <Typography variant="body2" color="textSecondary" className={classes.honorNewLines}>
                  {questionText}
                </Typography>
              </Grid>
            )}
            {followUpText && (
              <Grid item className={classes.field}>
                <Typography variant="h5" gutterBottom>
                  Follow up Question:
                </Typography>
                <Typography variant="body2" color="textSecondary" className={classes.honorNewLines}>
                  {followUpText}
                </Typography>
              </Grid>
            )}
            <Table size="small" aria-label="QuestionType" className={classes.field}>
              <TableBody>
                {questionType && (
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography variant="h6">Question Type</Typography>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Typography variant="body2" className={classes.typeDescription}>
                        {QUESTION_TYPE[questionType]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {labelsType && (
                  <TableRow>
                    <TableCell align="left" className={classes.tableCell}>
                      <Typography variant="h6">Labels Type</Typography>
                    </TableCell>
                    <TableCell align="right" className={classes.tableCell}>
                      <Typography variant="body2" className={classes.typeDescription}>
                        {LABELS_TYPE[labelsType]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell align="left" className={classes.tableCell}>
                    <Typography variant="h6">Scopes</Typography>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <Typography variant="body2" className={classes.typeDescription}>
                      {selectionLevel()}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Grid>
          <Grid item className={classes.expandIconBox}>
            <IconButton
              disabled={selectionLevel() === 'Journey-Level'}
              onClick={(ev) => handleArrowClicked(ev)}
              size="large">
              {isExpanded ? (
                <ExpandLessIcon
                  style={{
                    color: selectionLevel() === 'Selected Pages' ? '' : 'transparent',
                  }}
                />
              ) : (
                <ExpandMoreIcon
                  style={{
                    color: selectionLevel() === 'Selected Pages' ? '' : 'transparent',
                  }}
                />
              )}
            </IconButton>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row" spacing={1} justifyContent="center" alignItems="center">
            {filteredPages?.length > 0 || filteredSteps?.length > 0 ? (
              <div>
                <SelectedAssetsGrid assets={filteredPages} />
                <SelectedAssetsGrid assets={filteredSteps} />
              </div>
            ) : (
              <Grid item>No selected pages</Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

GroupReviewCard.propTypes = {
  group: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  isStyled: PropTypes.bool,
};

export default GroupReviewCard;
