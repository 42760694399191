import { Box, Button, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { BenchmarkScope } from '../../../modules/wevos/constants';
import { TrackEvent } from '../../analytics';
import useBenchmarkScopes from '../hooks/useBenchmarkScopes';

const BenchmarkSelector = ({ wevo, page, publishedBenchmarkScope, onScopeChange }) => {
  const { track } = useAnalytics();
  const [selectedScopeId, setSelectedScopeId] = useState(BenchmarkScope.Default);

  const { data: scopes, isLoading: isBenchmarkScopesLoading } = useBenchmarkScopes(wevo?.id, page?.id);

  // First benchmark scope is the benchmarks that the wevo was published with.
  let benchmarkName;
  const wevoProductName = wevo?.product?.name ?? '';
  const wevoIndustryName = wevo?.product?.industry?.name ?? '';
  if (publishedBenchmarkScope === BenchmarkScope.Product) {
    benchmarkName = `${wevoIndustryName} > ${wevoProductName}`;
  } else if (publishedBenchmarkScope === BenchmarkScope.Industry) {
    benchmarkName = wevoIndustryName;
  } else {
    benchmarkName = 'All similar tests';
  }

  const handleChange = (event) => {
    const scopeId = event.target.value;
    setSelectedScopeId(scopeId);

    const scope = scopes?.find((scope) => scope.scopeId === scopeId);
    if (scope) {
      onScopeChange(scope);
    } else {
      onScopeChange({ scopeType: BenchmarkScope.Default, scopeId: null });
    }

    track(TrackEvent.CHANGED_BENCHMARKS, { wevoId: wevo?.analyticsId, pageId: page?.id });
  };

  const handleResetClick = () => {
    setSelectedScopeId(BenchmarkScope.Default);
    onScopeChange({ scopeType: BenchmarkScope.Default, scopeId: null });

    track(TrackEvent.RESET_BENCHMARKS, { wevoId: wevo?.analyticsId, pageId: page?.id });
  };

  // If no scope is selected, this indicates that the benchmark scope is the benchmarks
  // that the wevo was published with.
  const isDefaultScope = selectedScopeId === BenchmarkScope.Default;

  const benchmarkDate = new Date(wevo?.datePublished).toLocaleDateString(/*locale=*/ undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <>
      <Box pb={1}>
        {!isBenchmarkScopesLoading && publishedBenchmarkScope && (
          <Typography variant="body1">
            My default benchmark is: <b style={{ fontWeight: 600 }}>{benchmarkName}</b> as of{' '}
            <b style={{ fontWeight: 600 }}>{benchmarkDate}</b>
          </Typography>
        )}
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Typography component="span" variant="body1">
          How do my diagnostics compare to
        </Typography>
        <FormControl variant="standard" sx={{ px: 1, minWidth: 200, marginTop: '2px' }}>
          <Select
            labelId="benchmark-selector-label"
            id="benchmark-selector"
            value={selectedScopeId}
            onChange={handleChange}
            label="Benchmark Scope"
            displayEmpty>
            <MenuItem value={BenchmarkScope.Default}>
              <em>Default Benchmarks</em>
            </MenuItem>
            {scopes &&
              scopes.map((scope) => (
                <MenuItem key={`scope-${scope.scopeId}`} value={scope.scopeId}>
                  {scope.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {!isDefaultScope && (
          <Box component="span" pr={1}>
            <Button size="small" variant="text" onClick={handleResetClick}>
              Reset
            </Button>
          </Box>
        )}
      </Box>
    </>
  );
};

export default BenchmarkSelector;
