import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Checkbox, Grid, Paper, Popover, styled, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { useEffectUpdate } from '../../../hooks';
import { DEFAULT_AUDIENCE_ATTRIBUTES } from '../../../modules/wevos/constants';

const DropdownButton = styled(Button)(() => ({
  border: '1px solid #757575',
  borderRadius: '6px',
  color: '#757575',
  cursor: 'pointer',
  textTransform: 'none',
  justifyContent: 'space-between',
}));

const AudienceFilterDropdown = ({ filterName, onClick, isOpen }) => {
  const icon = isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <DropdownButton onClick={onClick} endIcon={icon} fullWidth>
      {filterName}
    </DropdownButton>
  );
};

AudienceFilterDropdown.propTypes = {
  filterName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const AudienceSegment = ({ segmentId, segmentName, tooltipTitle, isChecked, isDisabled, onChange }) => {
  return (
    <Grid container justifyContent="space-between" spacing={1}>
      <Grid item xs>
        <Box pl={1}>
          <Typography variant="caption">{segmentName}</Typography>
        </Box>
      </Grid>
      <Grid item container xs={1} justifyContent="end">
        <Tooltip title={tooltipTitle || ''}>
          <span>
            <Checkbox
              size="small"
              sx={{ padding: '2px' }}
              value={segmentId}
              checked={isChecked}
              onChange={onChange}
              disabled={isDisabled}
            />
          </span>
        </Tooltip>
      </Grid>
    </Grid>
  );
};

AudienceSegment.propTypes = {
  segmentId: PropTypes.string.isRequired,
  segmentName: PropTypes.string.isRequired,
  tooltipTitle: PropTypes.string,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const AudienceFilter = ({
  items,
  itemType,
  advancedFilters,
  filterName,
  onFilterChange,
  isAdvancedFilter = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasAudienceMap, setHasAudienceMap] = useState({});
  const [hasAudienceSegmentMap, setHasAudienceSegmentMap] = useState({});
  const [segmentToCheckedMap, setSegmentToCheckedMap] = useState({});
  const [segmentToAudienceMap, setSegmentToAudienceMap] = useState({});

  // How to pass in the filters? Because items needs to be the full list

  useEffect(() => {
    // Build maps based on the list of items:
    //  - hasAudienceMap: Determines whether there are any items with a specific audience (e.g. gender)
    //  - hasAudienceSegmentMap: Determines whether there are any items with a specific segment (e.g. female)
    //  - segmentToCheckedMap: Determines whether a segment filter checkbox is checked or not.
    //  - segmentToAudienceMap: Maps segment ID to the audience information associated with the segment
    const hasAudienceMap = {};
    const hasAudienceSegmentMap = {};
    const segmentToCheckedMap = {};
    const segmentToAudienceMap = {};

    items?.forEach((item) => {
      if (!item || !Array.isArray(item.attributes)) {
        return;
      }

      item.attributes.forEach((audience) => {
        if (!audience) {
          return;
        }

        // if this is an advanced filter, skip attributes that are not advanced filters
        if (
          (isAdvancedFilter && !audience?.isAdvancedFilter) ||
          (!isAdvancedFilter && audience?.isAdvancedFilter)
        ) {
          return;
        }

        const attributeId = String(audience.attributeId);
        const segmentId = String(audience.segmentId);

        hasAudienceMap[attributeId] = true;
        hasAudienceSegmentMap[segmentId] = true;
        segmentToAudienceMap[segmentId] = audience;

        if (!segmentToCheckedMap?.[attributeId]) {
          segmentToCheckedMap[attributeId] = {};
          segmentToCheckedMap[attributeId][segmentId] = false;
        } else {
          segmentToCheckedMap[attributeId][segmentId] = false;
        }
      });
    });

    setHasAudienceMap(hasAudienceMap);
    setHasAudienceSegmentMap(hasAudienceSegmentMap);
    setSegmentToCheckedMap(segmentToCheckedMap);
    setSegmentToAudienceMap(segmentToAudienceMap);
  }, [items, isAdvancedFilter]);

  useEffectUpdate(() => {
    // Tell parent component that the item filters have changed.
    onFilterChange(segmentToCheckedMap, segmentToAudienceMap);
  }, [segmentToCheckedMap]);

  // Determine whether there are any items for a specific audience segment ID.
  const isEmptySegment = (segmentId) => {
    return !(hasAudienceSegmentMap && hasAudienceSegmentMap[segmentId]);
  };

  // Determine whether the segment filter is checked.
  const isSegmentChecked = (filterId, segmentId) => {
    return Boolean(segmentToCheckedMap && segmentToCheckedMap?.[filterId]?.[segmentId]);
  };

  const allAttributeSegmentsChecked = (filterId) => {
    const segments = segmentToCheckedMap?.[filterId];
    return !!segments && Object.values(segments).every((value) => Boolean(value));
  };

  const attributeHasResponses = (filterId) => {
    const segments = segmentToCheckedMap?.[filterId];
    return !!segments && !_.isEmpty(segmentToCheckedMap?.[filterId]);
  };

  const handleFilterClick = (ev) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterCheckboxClick = (ev, filterId) => {
    const segmentId = String(ev.target.value);

    setSegmentToCheckedMap((prevSegmentToCheckedMap) => ({
      ...prevSegmentToCheckedMap,
      [filterId]: {
        ...prevSegmentToCheckedMap[filterId],
        [segmentId]: Boolean(ev.target.checked),
      },
    }));
  };

  const handleSelectAllCheckboxClick = (ev, attributeId) => {
    const isChecked = ev.target.checked;

    setSegmentToCheckedMap((prevSegmentToCheckedMap) => ({
      ...prevSegmentToCheckedMap,
      [attributeId]: Object.keys(prevSegmentToCheckedMap[attributeId]).reduce((acc, key) => {
        acc[key] = isChecked; // set each segment of the selected attribute to the value of isChecked
        return acc;
      }, {}),
    }));
  };

  // Build list of filters for the items. Only returns audiences when there are items matching
  // that audience.
  const audienceFilters = useMemo(() => {
    let filters = [];
    if (isAdvancedFilter && !_.isEmpty(advancedFilters)) {
      filters = advancedFilters;
    } else if (!isAdvancedFilter) {
      Object.values(DEFAULT_AUDIENCE_ATTRIBUTES).forEach((audience) => {
        if (audience && audience.id in hasAudienceMap) {
          filters.push(audience);
        }
      });
    }
    return filters;
  }, [advancedFilters, isAdvancedFilter, hasAudienceMap]);

  const isOpen = Boolean(anchorEl);

  return (
    <>
      <AudienceFilterDropdown filterName={filterName} onClick={handleFilterClick} isOpen={isOpen} />

      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Paper sx={{ padding: '16px', maxWidth: '700px' }}>
          <Box pb={2}>
            <Typography variant="h5">{filterName} Filters</Typography>
          </Box>

          {audienceFilters?.map((filter) => (
            <Box key={String(filter.id)} py={1}>
              <Grid container justifyContent="space-between" spacing={1}>
                <Grid item xs>
                  <Box pb={1}>
                    <Typography variant="body2">{filter.name}</Typography>
                  </Box>
                </Grid>
                <Grid item container xs={1} justifyContent="end">
                  <Tooltip title={allAttributeSegmentsChecked(String(filter.id)) ? 'Clear' : 'Select All'}>
                    <Checkbox
                      size="small"
                      sx={{ padding: '2px', color: (theme) => theme.palette.primary.main }}
                      value={String(filter.id)}
                      checked={allAttributeSegmentsChecked(String(filter.id))}
                      disabled={!attributeHasResponses(filter.id)}
                      onChange={(ev) => handleSelectAllCheckboxClick(ev, String(filter.id))}
                    />
                  </Tooltip>
                </Grid>
              </Grid>
              {isAdvancedFilter ? (
                <>
                  {filter?.options?.map((option) => (
                    <AudienceSegment
                      key={String(option?.id)}
                      segmentId={String(option?.id)}
                      segmentName={option?.optionText}
                      tooltipTitle={
                        !isEmptySegment(String(option?.id))
                          ? ''
                          : `There are no ${itemType} with this audience`
                      }
                      isChecked={isSegmentChecked(String(filter?.id), String(option?.id))}
                      isDisabled={isEmptySegment(String(option.id))}
                      onChange={(ev) => handleFilterCheckboxClick(ev, String(filter?.id))}
                    />
                  ))}
                </>
              ) : (
                <>
                  {filter?.segments?.map((segment) => (
                    <AudienceSegment
                      key={String(segment?.id)}
                      segmentId={String(segment?.id)}
                      segmentName={segment?.label}
                      tooltipTitle={
                        !isEmptySegment(String(segment?.id))
                          ? ''
                          : `There are no ${itemType} with this audience`
                      }
                      isChecked={isSegmentChecked(String(filter?.id), String(segment?.id))}
                      isDisabled={isEmptySegment(segment?.id)}
                      onChange={(ev) => handleFilterCheckboxClick(ev, String(filter?.id))}
                    />
                  ))}
                </>
              )}
            </Box>
          ))}
        </Paper>
      </Popover>
    </>
  );
};

AudienceFilter.propTypes = {
  items: PropTypes.array.isRequired,
  itemType: PropTypes.string.isRequired,
  advancedFilters: PropTypes.array,
  filterName: PropTypes.string.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  isAdvancedFilter: PropTypes.bool,
};

export default AudienceFilter;
