import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  TextField,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { forwardRef, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RemoveLinkButton = withStyles((theme) => ({
  root: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: 'rgba(255, 82, 82, 0.1)',
    },
  },
}))(Button);

const rootID = 'link-url-dialog';

// our promise to be stored for resolve on input
let returnResponse = () => {};

function CreateLinkDialog({ prevUrl }) {
  let div = document.getElementById(rootID);
  if (!div) {
    div = document.createElement('div');
    div.id = rootID;
    document.body.appendChild(div);
  }

  ReactDOM.render(
    <AlertRoot message={'Please enter/paste a link here'} title={'Insert Hyperlink'} prevUrl={prevUrl} />,
    div
  );
}

export function EditHyperlinkUrl(prevUrl) {
  CreateLinkDialog({ prevUrl });
  // set our promise for resolve on input
  return new Promise((resolve) => {
    returnResponse = resolve;
  });
}

const AlertRoot = (props) => {
  const { message, title, prevUrl } = props;
  const root = useRef();
  const [url, setUrl] = useState(prevUrl || '');

  useEffect(() => {
    let div = document.getElementById(rootID);
    root.current = div;
  }, []);

  function Close() {
    root.current?.remove();
  }

  function Confirm() {
    returnResponse(url);
    Close();
  }

  function Cancel() {
    returnResponse(prevUrl);
    Close();
  }

  function Remove() {
    returnResponse(null);
    Close();
  }

  const onUrlChange = (ev) => {
    setUrl(ev.target.value);
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      // Cancel on dismiss
      onClose={() => Cancel()}
      open={true}
      disablePortal={true}
      fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{message}</DialogContentText>
        <TextField fullWidth variant="outlined" value={url} onChange={onUrlChange} />
      </DialogContent>
      <DialogActions>
        <Button onClick={Confirm}>Apply</Button>
        <Button onClick={Cancel}>Cancel</Button>
        <RemoveLinkButton onClick={Remove}>Remove Link</RemoveLinkButton>
      </DialogActions>
    </Dialog>
  );
};
