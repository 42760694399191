import { useMutation } from 'react-query';
import axios from '../../../api';

const sendChatMessage = async ({ sessionId, segmentId, message }) => {
  if (!segmentId) {
    return null;
  }

  const response = await axios({
    url: `/api/v2/automated-insight-sessions/${sessionId}/segments/${segmentId}/messages`,
    method: 'POST',
    data: { message },
  });

  return response?.data;
};

export default function useSendChatMessage(options) {
  return useMutation(sendChatMessage, options);
}
