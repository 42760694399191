import { Box, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import cloneDeep from 'lodash/cloneDeep';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useMemo, useState } from 'react';
import { DEFAULT_AUDIENCE_ATTRIBUTES } from '../../../modules/wevos/constants';
import EditQuotas from './EditQuotas';

const revenueMarks = [
  { value: 1, label: '0', intValue: 0 },
  { value: 2, label: '50k', intValue: 50 },
  { value: 3, label: '250k', intValue: 250 },
  { value: 4, label: '500k', intValue: 500 },
  { value: 5, label: '750k', intValue: 750 },
  { value: 6, label: '1M', intValue: 1000 },
  { value: 7, label: '2M', intValue: 2000 },
  { value: 8, label: '5M', intValue: 5000 },
];

const DEFAULT_REVENUE_RANGE = [1, 8];

const Revenue = (props) => {
  const { revenue, setRevenue, setIsRevenueValid } = props;
  const [revenueRange, setRevenueRange] = useState(DEFAULT_REVENUE_RANGE);
  const [quotasTotal, setQuotasTotal] = useState(sumBy(revenue.segments, (segment) => segment.percentage));

  const isAttributeSelected = useMemo(() => revenue?.isSelected, [revenue?.isSelected]);
  const isCustomizedQuota = useMemo(() => Boolean(revenue.isCustomizedQuota), [revenue.isCustomizedQuota]);

  useEffect(() => {
    setQuotasTotal(sumBy(revenue.segments, (segment) => segment.percentage));
    // update revenue range whenever revenue changes to ensure slider is always in sync with current attribute state
    let newRevenueRange = [Infinity, -Infinity];
    revenue.segments.forEach((segment, index) => {
      if (segment.isSelected) {
        if (index + 1 < newRevenueRange[0]) {
          newRevenueRange[0] = index + 1;
        }
        if (index + 2 > newRevenueRange[1] && index + 2 <= DEFAULT_REVENUE_RANGE[1]) {
          newRevenueRange[1] = index + 2;
        }
      }
    });
    setRevenueRange(newRevenueRange);
  }, [revenue.segments]);

  useEffect(() => {
    setIsRevenueValid(quotasTotal === 100);
  }, [quotasTotal, setIsRevenueValid]);

  const handleRevenueChange = (ev, newRevenueRange) => {
    if (newRevenueRange[0] !== newRevenueRange[1]) {
      const lowerBound = revenueMarks.find((revenueMark) => revenueMark.value === newRevenueRange[0]).intValue;
      const upperBound = revenueMarks.find((revenueMark) => revenueMark.value === newRevenueRange[1]).intValue;

      let newRevenueSegments = cloneDeep(DEFAULT_AUDIENCE_ATTRIBUTES['revenue'].segments);
      newRevenueSegments = newRevenueSegments.map((segment) => ({
        ...segment,
        isSelected: segment.lowerBound >= lowerBound && segment.upperBound <= upperBound,
      }));
      const numSelectedSegments = newRevenueSegments.filter((segment) => !!segment.isSelected).length;
      const basePercentage = Math.floor(100 / numSelectedSegments);
      let remainder = 100 - basePercentage * numSelectedSegments;
      newRevenueSegments = newRevenueSegments.map((segment) => {
        if (!segment.isSelected) {
          return { ...segment, percentage: 0 };
        } else {
          if (remainder > 0) {
            remainder -= 1;
            return { ...segment, percentage: basePercentage + 1 };
          }
          return {
            ...segment,
            percentage: basePercentage,
          };
        }
      });
      setRevenue({ ...revenue, segments: newRevenueSegments });
      setRevenueRange(newRevenueRange);
    }
  };

  const updateQuotas = (ev) => {
    const newPercentage = Number(ev.target.value, 10) || 0;
    const segmentLabel = ev.target.name;
    if (newPercentage >= 5 && newPercentage <= 100) {
      if (revenue.segments.filter((segment) => segment.isSelected).length === 2) {
        setRevenue({
          ...revenue,
          segments: revenue.segments.map((segment) => {
            if (segment.label === segmentLabel) {
              return { ...segment, percentage: newPercentage };
            } else {
              if (segment.isSelected) {
                return { ...segment, percentage: Number(100 - newPercentage) };
              }
              return segment;
            }
          }),
        });
      } else {
        setRevenue({
          ...revenue,
          segments: revenue.segments.map((segment) => {
            if (segment.label === segmentLabel) {
              return { ...segment, percentage: newPercentage };
            }
            return segment;
          }),
        });
      }
    }
  };

  const handleCustomizeQuotasClick = (ev) => {
    setRevenue({
      ...revenue,
      isCustomizedQuota: ev.target.checked,
    });
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
      <Grid item xs={7}>
        <Slider
          disabled={!isAttributeSelected}
          value={revenueRange}
          valueLabelDisplay="off"
          marks={revenueMarks}
          min={DEFAULT_REVENUE_RANGE[0]}
          max={DEFAULT_REVENUE_RANGE[1]}
          step={null}
          onChange={handleRevenueChange}
          size="small"
        />
      </Grid>
      <Grid item xs>
        <FormControlLabel
          sx={{ width: '100%', justifyContent: 'flex-end' }}
          control={
            <Checkbox
              checked={isCustomizedQuota}
              onChange={handleCustomizeQuotasClick}
              value={'CheckBoxRevenue'}></Checkbox>
          }
          label={
            <Box style={{ fontSize: '11px' }}>
              <Typography variant="caption">Customize my quotas</Typography>
            </Box>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <EditQuotas
          open={isCustomizedQuota}
          audience={revenue}
          quotasTotal={quotasTotal}
          handleQuotaChange={updateQuotas}
        />
      </Grid>
    </Grid>
  );
};

export default Revenue;
