import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { ReactComponent as PulseInfoIcon } from '../../../src/assets/pulse-info-icon.svg';
import { ReactComponent as PulseWarningIcon } from '../../../src/assets/pulse-warning-icon.svg';

function ScreenshotExtensionLink() {
  return (
    <Typography variant="body3">
      Tip: To take a screenshot of the website you want to analyze, you can use a chrome extension such as{' '}
      <Link
        variant="body3"
        sx={{ color: '#3B6CAB' }}
        href="https://chrome.google.com/webstore/detail/gofullpage-full-page-scre/fdpohaocaechififmbbbbbknoalclacl"
        target="_blank"
        rel="noopener nofollow noreferrer">
        GoFullPage
      </Link>
      .
    </Typography>
  );
}

function ImportURLTips() {
  return (
    <Box>
      <Typography variant="body3">
        If this page doesn't match the page you are trying to get feedback on, feel free to try again! Also, if
        your website has the below elements, it is usually better to upload a screenshot vs. using a URL:
      </Typography>
      <Box mb={2} />
      <ul>
        <li>
          <Typography variant="body3" mb={0.5}>
            The website has many interactive elements
          </Typography>
        </li>
        <li>
          <Typography variant="body3" mb={0.5}>
            The website might have security measures
          </Typography>
        </li>
        <li>
          <Typography variant="body3" mb={0.5}>
            The website's settings might not allow us to retrieve the image properly
          </Typography>
        </li>
      </ul>
      <Box mb={2} />
    </Box>
  );
}

function WaitingMessage() {
  return (
    <Typography variant="body2">
      Seems our system has many pulse tests happening right now! Check back soon!
    </Typography>
  );
}

function InfoBox({ sx, children, isWarning = false }) {
  return (
    <Box
      className="lightContainer"
      sx={{
        border: 0.5,
        borderRadius: 4,
        backgroundColor: '#F8F8F8',
        borderColor: '#E0E0E0',
        ...sx,
      }}>
      <Grid container sx={{ height: 'inherit' }} py={2} pl={2}>
        <Grid item sx={{ width: 30 }}>
          {isWarning ? <PulseWarningIcon /> : <PulseInfoIcon />}
        </Grid>
        <Grid item sx={{ overflowY: 'auto', height: 'inherit', width: 'calc(100% - 35px)', paddingRight: 2 }}>
          <Box>{children}</Box>
        </Grid>
      </Grid>
    </Box>
  );
}

function ButtonWithInfoBox({ buttonContainerStyles, infoBoxContainerStyles, includeTips, tipsStartOpen }) {
  const [showInfoBox, setShowInfoBox] = useState(false);

  useEffect(() => {
    setShowInfoBox(tipsStartOpen);
  }, [tipsStartOpen]);

  const buttonStyles = (theme) => {
    return {
      fontSize: '12px',
      textTransform: 'none',
      borderRadius: '14px',
      padding: 1.5,
      backgroundColor: '#F8F8F8',
      border: '1px solid #E0E0E0',
      color: theme.palette.text.primary,
    };
  };

  const handleClick = () => {
    setShowInfoBox(!showInfoBox);
  };

  if (showInfoBox) {
    return (
      <Box
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          ...infoBoxContainerStyles,
        }}>
        <InfoBox sx={{ height: '100%', lineHeight: 1.3 }}>
          {includeTips && <ImportURLTips />}
          <ScreenshotExtensionLink />
        </InfoBox>
      </Box>
    );
  }
  return (
    <Box sx={{ ...buttonContainerStyles }}>
      <Button sx={(theme) => buttonStyles(theme)} startIcon={<PulseInfoIcon />} onClick={handleClick}>
        Help fix my upload
      </Button>
    </Box>
  );
}

export { ButtonWithInfoBox, ImportURLTips, InfoBox, ScreenshotExtensionLink, WaitingMessage };
