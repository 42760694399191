import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Grid, IconButton, styled, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { memo, useLayoutEffect, useRef, useState } from 'react';
import { areEqual, FixedSizeList as List } from 'react-window';

const StyledIconButton = styled(IconButton)(() => ({
  width: '100%',
  borderRadius: 0,
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const VerticalCarousel = ({
  items,
  itemComponent = ({ index, style, item }) => <></>,
  onSelection,
  onClick,
  itemHeight = 200,
  itemWidth = 200,
  numItemsDisplayed = 1,
}) => {
  const theme = useTheme();

  const listRef = useRef();
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  // resets the carousel to the beginning (first item) when the list of items changes
  useLayoutEffect(() => {
    setCurrentItemIndex(0);
    listRef.current.scrollToItem(0);
  }, [items]);

  const handlePrevious = () => {
    if (currentItemIndex > 0) {
      const previousIndex = currentItemIndex - 1;
      const previousItemId = items[previousIndex]?.id;
      listRef.current.scrollToItem(previousIndex);
      setCurrentItemIndex(previousIndex);
      onClick(previousItemId);
    }
  };

  const handleNext = () => {
    if (currentItemIndex < items?.length - 1) {
      const nextIndex = currentItemIndex + 1;
      const nextItemId = items[nextIndex]?.id;
      listRef.current.scrollToItem(nextIndex);
      setCurrentItemIndex(nextIndex);
      onClick(nextItemId);
    }
  };

  const ListItem = memo(({ index, style }) => {
    const currentItem = items[index];
    return (
      <div
        style={{
          cursor: onSelection && items.length > 1 ? 'pointer' : 'initial',
          ...style,
        }}>
        {itemComponent({ index, style, item: currentItem })}
      </div>
    );
  }, areEqual);

  return (
    <Grid
      container
      item
      flexDirection="column"
      sx={{ backgroundColor: theme.palette.background.default, borderRadius: '10px' }}>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          borderBottom: '1px solid lightgrey',
        }}>
        <StyledIconButton
          aria-label="previous"
          disabled={currentItemIndex === 0}
          disableRipple
          onClick={handlePrevious}>
          <KeyboardArrowUp />
        </StyledIconButton>
      </Grid>
      <Grid item>
        <List
          ref={listRef}
          height={itemHeight * numItemsDisplayed}
          itemCount={items?.length}
          itemSize={itemHeight}
          width={itemWidth}
          style={{ overflow: 'hidden' }}>
          {ListItem}
        </List>
      </Grid>
      <Grid
        item
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderTop: '1px solid lightgrey',
        }}>
        <StyledIconButton
          aria-label="next"
          disabled={currentItemIndex === items.length - 1}
          disableRipple
          onClick={handleNext}>
          <KeyboardArrowDown />
        </StyledIconButton>
      </Grid>
    </Grid>
  );
};

VerticalCarousel.propTypes = {
  items: PropTypes.array.isRequired,
  itemComponent: PropTypes.func.isRequired,
  onSelection: PropTypes.func,
  onClick: PropTypes.func,
  itemHeight: PropTypes.number,
  itemWidth: PropTypes.number,
  numItemsDisplayed: PropTypes.number,
};

export default VerticalCarousel;
