import { Box, Card, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import MiniPage from './MiniPage';

const useTogglePageCardStyles = makeStyles((theme) => ({
  card: {
    cursor: 'pointer',
    borderRadius: '20px',
  },
  cardDeselected: {
    cursor: 'pointer',
    opacity: 0.5,
  },
}));

const TogglePageCard = (props) => {
  const { onToggleSelected, onImageSelected, isSelected, ...rest } = props;
  const classes = useTogglePageCardStyles();
  const [elevation, setElevation] = useState(1);

  return (
    <Box
      m={3}
      component={Card}
      className={isSelected ? classes.card : classes.cardDeselected}
      onClick={onImageSelected}
      onMouseOver={() => setElevation(4)}
      onMouseOut={() => setElevation(1)}
      elevation={elevation}>
      <Box p={2}>
        <MiniPage onToggleVisibility={onToggleSelected} isVisible={isSelected} {...rest} />
      </Box>
    </Box>
  );
};

TogglePageCard.propTypes = {
  onToggleSelected: PropTypes.func,
  isSelected: PropTypes.bool,
  rest: PropTypes.object,
};

export function TogglePageCardList({ wevo, pages, filters, onTogglePage, onImageClicked }) {
  return (
    <Grid container justifyContent="center" style={{ flexGrow: 1 }}>
      {pages.map((page, idx) => {
        return (
          <Grid item xs={12} sm={12} md={12} lg={6} xl={3} key={idx}>
            <TogglePageCard
              wevo={wevo}
              page={page}
              isSelected={filters[idx]}
              onToggleSelected={(event) => {
                onTogglePage(idx, page, event);
                event.stopPropagation(); // don't bubble event up to image click handler
              }}
              onImageSelected={(event) => {
                if (onImageClicked) {
                  onImageClicked(idx, page, event);
                }
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

TogglePageCardList.propTypes = {
  wevo: PropTypes.object.isRequired,
  pages: PropTypes.array.isRequired,
  onTogglePage: PropTypes.func,
};
