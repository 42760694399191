import produce from 'immer';
import { isNil as _isNil, set as _set } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { JobToBeDone, MutationKeys } from '../../../modules/intake/constants';
import { AudienceCategory } from '../../../modules/wevos/constants';

const OPTIMISTIC_UPDATE_FIELDS = [
  'audienceCategoryId',
  'journeyStartUrl',
  'taskToComplete',
  'visitorObjective',
  'devices',
  'type',
  'testType',
  'jobToBeDone',
];

/**
 *
 * @param {Object} wevo The changed wevo object
 * @returns
 */
const saveWevo = async ({ id: wevoId, ...changedFields }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}`,
    method: 'PUT',
    data: changedFields,
  });
  return response.data.wevo;
};

/**
 * Custom hook for saving a wevo.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the updated data
 *
 */
export default function useSaveWevo() {
  const queryClient = useQueryClient();

  return useMutation(saveWevo, {
    mutationKey: MutationKeys.saveWevo,
    onMutate: async ({ id: wevoId, ...changedFields }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      if (Object.keys(changedFields).some((field) => OPTIMISTIC_UPDATE_FIELDS.includes(field))) {
        const updatedWevoState = produce(previousWevoState, (draft) => {
          if (changedFields.audienceCategoryId) {
            const newAudienceCategory = Object.values(AudienceCategory).find(
              (audienceCategory) => audienceCategory.id === changedFields.audienceCategoryId
            );
            if (newAudienceCategory) {
              _set(draft, 'audienceCategory', newAudienceCategory);
            }
          }
          if (!_isNil(changedFields.savedAudienceGroupId)) {
            _set(draft, 'savedAudienceGroupId', changedFields.savedAudienceGroupId);
          }
          if (!_isNil(changedFields.taskToComplete)) {
            _set(draft, 'details.taskToComplete', changedFields.taskToComplete);
          }
          if (!_isNil(changedFields.journeyStartUrl)) {
            _set(draft, 'details.journeyStartUrl', changedFields.journeyStartUrl);
          }
          if (!_isNil(changedFields.visitorObjective)) {
            _set(draft, 'visitorObjective', changedFields.visitorObjective);
          }
          if (changedFields.devices) {
            _set(draft, 'devices', changedFields.devices);
          }
          if (changedFields.type) {
            if (draft.type !== changedFields.type) {
              _set(draft, 'pages', []); // changing journey <-> classic always means we always discard the pages
            }

            _set(draft, 'type', changedFields.type);
          }
          if (changedFields.testType) {
            _set(draft, 'testType', changedFields.testType);
          }
          if (changedFields.jobToBeDone) {
            _set(draft, 'jobToBeDone', changedFields.jobToBeDone);

            if (changedFields.jobToBeDone === JobToBeDone.Usability) {
              // changing jobToBeDone between other and usability flips a virtual flag computed on the server
              _set(draft, 'isUsabilityTestType', true);
            } else {
              _set(draft, 'isUsabilityTestType', false);
            }
            if (changedFields.jobToBeDone === JobToBeDone.Lite) {
              // changing jobToBeDone between other and usability flips a virtual flag computed on the server
              _set(draft, 'isLiteTestType', true);
            } else {
              _set(draft, 'isLiteTestType', false);
            }
          }
        });
        queryClient.setQueryData(['wevoData', { wevoId }], updatedWevoState);
      }

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    onError: (err, { id: wevoId, ...changedFields }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
