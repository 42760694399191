import { Badge, IconButton } from '@mui/material';
import ThumbUp from '@mui/icons-material/ThumbUp';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { createEditor } from 'slate';
import { Editable, Slate, useReadOnly, withReact } from 'slate-react';
import { Element, Leaf, VoteableSlateElements } from '../../../../modules/plate/constants';
import { getUserProfile } from '../../../../modules/user/selectors';
import useKeyFindingVotes from '../../hooks/useKeyFindingVotes';
import useToggleKeyFindingVote from '../../hooks/useToggleKeyFindingVote';

const SlateKeyFindingsViewer = ({
  findings,
  wevoId,
  pageId,
  allowUpvote = false,
  showUpvotes = false,
  isDashboard = false,
}) => {
  const [editor] = useState(() => withReact(createEditor()));
  const userProfile = useSelector(getUserProfile);
  const { data: upvotes = {} } = useKeyFindingVotes({ wevoId, pageId });
  const { mutate: toggleFindingVote } = useToggleKeyFindingVote();

  const onUpvoteClick = useCallback(
    (elementId, userId) => {
      toggleFindingVote({ elementId, wevoId, pageId, userId, upvotes });
    },
    [pageId, toggleFindingVote, upvotes, wevoId]
  );

  const renderElement = useCallback(
    (props) => (
      <ElementContainer
        {...props}
        userProfile={userProfile}
        upvotes={upvotes}
        allowUpvote={allowUpvote}
        showUpvotes={showUpvotes}
        onUpvoteClick={onUpvoteClick}
        isDashboard={isDashboard}
      />
    ),
    [allowUpvote, onUpvoteClick, showUpvotes, upvotes, userProfile, isDashboard]
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  return (
    <Slate editor={editor} value={findings} onChange={() => {}}>
      <Editable readOnly renderElement={renderElement} renderLeaf={renderLeaf} />
    </Slate>
  );
};

const ElementContainer = ({
  attributes,
  children,
  element,
  userProfile,
  upvotes,
  allowUpvote,
  showUpvotes,
  onUpvoteClick,
  isDashboard,
}) => {
  const readOnly = useReadOnly();
  const [isHover, setIsHover] = useState(false);

  if (
    VoteableSlateElements.includes(element.type) &&
    readOnly &&
    !!element.id &&
    (showUpvotes || allowUpvote)
  ) {
    return (
      // if there are any changes to the hierarchy here, please make sure the ref.current.firstChild.offsetHeight for
      // dashboard UserKeyFidings and WevoKeyFindings still refers the height of the key findings content
      <div
        style={{ display: 'flex', alignItems: element.type === 'lic' ? 'flex-start' : 'center' }}
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}>
        <div
          contentEditable={false}
          style={{
            userSelect: 'none',
            marginRight: element.type === 'lic' ? '24px' : '4px',
            marginLeft: element.type === 'lic' ? '-50px' : '-30px',
          }}>
          <Badge
            badgeContent={upvotes[element.id]?.length}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}>
            <IconButton
              style={{
                visibility: (isHover && allowUpvote) || upvotes[element.id]?.length > 0 ? 'visible' : 'hidden',
              }}
              size="small"
              disabled={!allowUpvote}
              onClick={() => {
                onUpvoteClick(element.id, userProfile?.id);
              }}>
              <ThumbUp
                fontSize="small"
                color={
                  upvotes?.[element?.id]?.some((userId) => String(userId) === String(userProfile?.id))
                    ? 'secondary'
                    : 'inherit'
                }
              />
            </IconButton>
          </Badge>
        </div>
        <Element attributes={attributes} children={children} element={element} isDashboard={isDashboard} />
      </div>
    );
  } else {
    return <Element attributes={attributes} children={children} element={element} isDashboard={isDashboard} />;
  }
};

SlateKeyFindingsViewer.propTypes = {
  findings: PropTypes.array.isRequired,
  wevoId: PropTypes.string.isRequired,
  pageId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  allowUpvote: PropTypes.bool,
  showUpvotes: PropTypes.bool,
  isDashboard: PropTypes.bool,
};

export default SlateKeyFindingsViewer;
