import { IntakeStepIds } from '../modules/intake/constants';

export const basePath = '/wevos/:wevoId/edit';

export const start = `${basePath}/${IntakeStepIds.Start}`;
export const testGoal = `${basePath}/${IntakeStepIds.TestGoal}`;
export const audience = `${basePath}/${IntakeStepIds.Audience}`;
export const testType = `${basePath}/${IntakeStepIds.TestType}`;
export const details = `${basePath}/${IntakeStepIds.Details}`;
export const review = `${basePath}/${IntakeStepIds.Review}`;
