import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchExperienceOutcomes = async ({ queryKey: [key, { wevoId, pageId, experienceId }] }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/experiences/${experienceId}/outcomes`,
    method: 'GET',
  });
  return response?.data.outcomes ?? [];
};

export default function useFetchExperienceOutcomes({ wevoId, pageId, experienceId }, options) {
  return useQuery(['experienceOutcomes', { wevoId, pageId, experienceId }], fetchExperienceOutcomes, options);
}
