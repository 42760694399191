import { Container, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import produce from 'immer';
import _, { debounce as _debounce } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as UserActions from '../../../modules/user/actions';
import { getUserCustomizations } from '../../../modules/user/selectors';
import { faqs } from './FAQContent';
import FAQs from './FAQs';
import QuestionSearch from './QuestionSearch';
import VideoCards from './VideoCards';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    marginBottom: theme.spacing(10),
  },
  linkCard: {
    width: '200px',
    height: '240px',
    cursor: 'pointer',
  },
  videoGrid: {
    marginRight: '-80px',
  },
  videoCardText: {
    marginTop: '-12px',
  },
  videoCardIcon: {
    marginTop: '-8px',
  },
}));

const getQuestionSearchHit = (question, query) => {
  const lowerCaseQuery = query.toLowerCase();
  const lowerCaseQuestion = question.question.toLowerCase();
  const lowerCaseAnswer = question.answer.toLowerCase();
  return lowerCaseQuestion.includes(lowerCaseQuery) || lowerCaseAnswer.includes(lowerCaseQuery);
};

const filterQuestionsBySection = (query) => {
  return faqs.reduce((acc, section) => {
    acc[section.id] = section.questions.filter((question) => !query || getQuestionSearchHit(question, query));
    return acc;
  }, {});
};
const initialFilteredState = faqs.reduce((acc, section) => {
  acc[section.id] = section.questions;
  return acc;
}, {});

const FAQsPage = (props) => {
  const { userCustomizations, fetchUser } = props;

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const isUserlytics = Boolean(_.get(userCustomizations, 'integrations.userlytics'));

  const classes = useStyles();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredQuestionsBySection, setFilteredQuestionsBySection] = useState(() => initialFilteredState);
  const [openQuestions, setOpenQuestions] = useState({});

  // this function gets called every time the user types in the search bar and is debounced with a 250ms wait
  const debouncedSetFilteredQuestions = useMemo(
    () =>
      _debounce((query) => {
        const newFilteredQuestionsBySection = filterQuestionsBySection(query);
        setFilteredQuestionsBySection(newFilteredQuestionsBySection);
        setOpenQuestions((prevOpenQuestions) => {
          return produce(prevOpenQuestions, (newOpenQuestions) => {
            // for each question that is currently marked as open, we should mark it as closed if it has
            // just been filtered out by the new search query
            for (const [questionId, isOpen] of Object.entries(newOpenQuestions)) {
              newOpenQuestions[questionId] =
                isOpen &&
                Object.values(newFilteredQuestionsBySection).some((filteredQuestion) =>
                  filteredQuestion.some((question) => question.id === questionId)
                );
            }
          });
        });
      }, 250),
    []
  );

  const onSearchQueryChange = (query) => {
    setSearchQuery(query);
    debouncedSetFilteredQuestions(query);
  };

  return (
    isUserlytics && (
      <Container className={classes.root} maxWidth="md">
        <Grid container spacing={4} direction="column">
          <Grid container item spacing={2} alignItems="center">
            <Grid item>
              <Typography
                variant="h5"
                style={{
                  fontSize: '22px',
                }}>
                How Can We Help You?
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <VideoCards />
          </Grid>
          <Grid item>
            <QuestionSearch searchQuery={searchQuery} onSearchQueryChange={onSearchQueryChange} />
          </Grid>
          <Grid item>
            <FAQs
              filteredQuestionsBySection={filteredQuestionsBySection}
              showBadgeNumbers={Boolean(searchQuery)}
              openQuestions={openQuestions}
              setOpenQuestions={setOpenQuestions}
            />
          </Grid>
        </Grid>
      </Container>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    userCustomizations: getUserCustomizations(state),
  };
};

const actionCreators = {
  fetchUser: UserActions.fetchUserInfo,
};

export default withRouter(connect(mapStateToProps, actionCreators)(FAQsPage));
