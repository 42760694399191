import { useMutation, useQuery } from 'react-query';
import { default as api, default as axios } from '../api';
import { snackbar } from '../notifications';

const fetchToken = async ({ queryKey: [key, { token }] }) => {
  if (!token) {
    return {};
  }

  const response = await api({
    url: `/api/v2/users/pulse/invites/${token}`,
    method: 'GET',
  });
  return { token: response?.data?.token, user: response?.data?.token.user || {} };
};

const acceptToken = async ({ formData }) => {
  const response = await axios({
    url: `/api/v2/users/pulse/invites/accept`,
    method: 'POST',
    data: formData,
  });
  return response.data;
};

const fetchUser = async (data) => {
  const response = await api({
    url: `/api/v2/users/pulse/email`,
    method: 'POST',
    data: data,
  });
  return response.data;
};

export function useFetchToken(token, options) {
  return useQuery(['userToken', { token }], fetchToken, {
    notifyOnChangeProps: 'tracked',
    ...(options ?? {}),
  });
}

export function useAcceptToken() {
  return useMutation(acceptToken, {
    onError: (err) => {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error accepting invitation');
    },
  });
}

export function useFetchUser() {
  return useMutation(fetchUser, {
    onError: (err) => {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error fetching user');
    },
  });
}
