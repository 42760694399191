import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useMemo } from 'react';
import ShowImage from '../../components/ShowImage';
import PageLabel from './PageLabel';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import StandardTopLevelScoreChart from '../../components/StandardTopLevelScoreChart';
import { useTheme } from '@emotion/react';

export default function MiniPage({ wevo, page, isVisible, onToggleVisibility, ...rest }) {
  const theme = useTheme();

  // prior to versioned diagnostics, top-level classic scores used a different system,
  // and were not displayed, so don't show scores when we detect the test is using the old system
  const useVersionedDiagnostics = useMemo(
    () => (wevo && (wevo?.useNewDiagnostics || wevo?.useVersionedDiagnostics)) || false,
    [wevo]
  );

  return (
    <Box>
      <Box mb={1}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item xs={10}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <PageLabel label={page.label} color={page.color} />
              </Grid>
              <Tooltip
                title={
                  <Typography variant="caption" component="span">
                    {page.name}
                  </Typography>
                }>
                <Grid
                  item
                  xs={10}
                  style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  <Typography component="span">{page.name}</Typography>
                </Grid>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Box onClick={onToggleVisibility}>{isVisible ? <Visibility /> : <VisibilityOff />}</Box>
          </Grid>
        </Grid>
      </Box>
      <Box mb={useVersionedDiagnostics ? 2 : 0}>
        <ShowImage image={page.images} height="200px" />
      </Box>
      {useVersionedDiagnostics && (
        <Box px={6} py={4}>
          <Grid container alignItems="center">
            <Grid item sx={{ width: '100%' }}>
              <StandardTopLevelScoreChart
                score={page.rawScore}
                isVersionedDiagnostics={useVersionedDiagnostics}
                componentId={`compare-dashboard-mini-page-${page.id}`}
                width="33%"
                ratingTextStyles={{ height: 50 }}
                subTextStyles={{ height: 30 }}
                textColor={theme.palette.primary.main}
              />
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

MiniPage.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onToggleVisibility: PropTypes.func.isRequired,
};
