import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  TableFooter,
  TablePagination,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { CONCEPT_SCORE_COLORS, CustomQuestionTypes } from '../../../modules/report/constants';
import { orderChipGroups } from '../../../modules/report/helpers';
import { CdsCustomQuestionNames, orderDiagnostics } from '../../../modules/wevos/constants';
import { Paths } from '../../../routes';
import QuoteAudienceFilter from './QuoteAudienceFilter';
import QuoteBlock from './QuoteBlock';
import QuoteSearch from './QuoteSearch';
import CustomScreenerFilter from './CustomScreenerFilter';
import QuestionCarousel from './QuestionCarousel';
import useFetchCustomScreeners from '../hooks/useFetchCustomScreeners';

// Utility diagnostic for CDS
const Utility = 'Utility';

const styles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: (props) => (!!props.maxHeight ? props.maxHeight : 'none'),
    overflow: (props) => (!!props.maxHeight ? 'auto' : 'visible'),
  },
  paper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  noBottomBorder: {
    borderBottom: 'none',
  },
  cell: {
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: 'rgb(243, 243, 243)',
  },
  paginationRoot: {
    '& .MuiTablePagination-selectRoot': {
      marginRight: theme.spacing(2),
    },
    '& .MuiTablePagination-toolbar': {
      paddingRight: 0,
    },
  },
  bottomPagination: {
    '& .MuiToolbar-root': {
      padding: '8px 16px',
    },
  },
  paginationActions: {
    flexShrink: 0,
    marginLeft: theme.spacing(2),
  },
  utilityContainer: {
    borderRadius: '20px',
  },
  utilityTitleWrapper: {
    backgroundColor: CONCEPT_SCORE_COLORS.Value,
    padding: '0px 24px',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  utilityTitle: {
    padding: '10px 0px',
    fontWeight: 500,
    fontSize: '13px',
    color: '#FFFFFF',
  },
  utilityContentWrapper: {
    alignSelf: 'center',
    padding: '4px 24px 4px 16px',
    lineHeight: '1.3',
  },
  utilityLink: {
    color: '#757575',
    fontSize: '13px',
    fontWeight: 400,
    textDecoration: 'none',
  },
  sentimentMapPage: { fontSize: '14px' },
  tablePagination: {
    marginTop: ({ sentimentMapMatch, experienceMapMatch, showThumbnails }) =>
      (sentimentMapMatch || experienceMapMatch) && showThumbnails ? '-16px' : '',
  },
}));

function TablePaginationActions(props) {
  const classes = styles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.paginationActions}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="small">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="small">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        size="small"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const DefaultQuoteTableChipContainer = (props) => {
  const { clearWordFilters, deselectWord, selectedWords } = props;

  return (
    <Grid container item xs={12} spacing={1}>
      {selectedWords.map((word, index) => (
        <Grid item key={index}>
          <Chip label={word} onDelete={() => deselectWord(word)} />
        </Grid>
      ))}
      <Grid item>
        <Button onClick={clearWordFilters}>Clear</Button>
      </Grid>
    </Grid>
  );
};

DefaultQuoteTableChipContainer.propTypes = {
  clearWordFilters: PropTypes.func,
  deselectWord: PropTypes.func,
  selectedWords: PropTypes.array.isRequired,
};

export const GroupedQuoteTableChipContainer = (props) => {
  const {
    wevo,
    selectedChipGroups,
    chipGroupColor,
    clearWordFilters,
    deselectWord,
    selectedWords,
    selectedCompositeMetrics,
  } = props;

  const orderedSelectedChipGroups = useMemo(() => {
    const orderedChips = selectedChipGroups.map((chipGroup) => {
      return orderDiagnostics(wevo?.metricType, chipGroup);
    });
    return orderChipGroups(wevo?.metricType, orderedChips, selectedCompositeMetrics);
  }, [wevo?.metricType, selectedChipGroups, selectedCompositeMetrics]);

  const ungroupedWords = useMemo(() => {
    const chipGroupWords = orderedSelectedChipGroups.flat();
    return selectedWords.filter((word) => chipGroupWords.indexOf(word) === -1);
  }, [orderedSelectedChipGroups, selectedWords]);

  return (
    <Grid container item xs={12} spacing={1}>
      {orderedSelectedChipGroups.flatMap((chipGroup, chipGroupIdx) => (
        <Grid container item key={chipGroupIdx} spacing={1}>
          {chipGroup.map((word) => {
            const chipColor = chipGroupColor(word, chipGroupIdx);
            const isWordSelected = selectedWords.indexOf(word);
            let chipStyles = { borderColor: chipColor, color: chipColor };

            if (isWordSelected > -1) {
              chipStyles.backgroundColor = chipColor;
              chipStyles.color = 'white';
            }

            const chipVariant = isWordSelected > -1 ? 'default' : 'outlined';

            return (
              <Grid item key={word}>
                <Chip
                  style={chipStyles}
                  variant={chipVariant}
                  label={word}
                  onClick={() => deselectWord(word)}
                />
              </Grid>
            );
          })}
        </Grid>
      ))}
      <Grid container item spacing={1}>
        {ungroupedWords.map((word) => (
          <Grid item key={word}>
            <Chip label={word} onDelete={() => deselectWord(word)} />
          </Grid>
        ))}
      </Grid>
      <Grid item>
        <Button onClick={clearWordFilters}>Clear</Button>
      </Grid>
    </Grid>
  );
};

GroupedQuoteTableChipContainer.propTypes = {
  wevo: PropTypes.object.isRequired,
  selectedChipGroups: PropTypes.array.isRequired,
  chipGroupColor: PropTypes.func,
  clearWordFilters: PropTypes.func,
  deselectWord: PropTypes.func,
  selectedWords: PropTypes.array.isRequired,
};

const QuotesTable = (props) => {
  const {
    wevo,
    page,
    fullQuotes,
    displayQuotes,
    onQuoteUpdate,
    onAudienceFilterChange,
    onCustomScreenerFilterChange,
    onSearchChange,
    searchQuery,
    handleQuoteClick,
    selectedQuote,
    title,
    selectedWords,
    deselectWord,
    clearWordFilters,
    filters,
    chips,
    quoteChipContainerComponent,
    questionType,
  } = props;

  const { data: screenerQuestions } = useFetchCustomScreeners({ wevoId: wevo?.id });

  const sentimentMapMatch = useRouteMatch({
    path: [Paths.reports.sentimentMap, Paths.reports.limitedSentimentMap],
  });

  const experienceMapMatch = useRouteMatch({
    path: [Paths.reports.experienceMap, Paths.reports.limitedExperienceMap],
  });

  const classes = styles({ ...props, sentimentMapMatch, experienceMapMatch });

  const [pageNum, setPageNum] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [selectedScreenerQuestions, setSelectedScreenerQuestions] = useState([]);

  const getUtilityQuestionGroupNum = () => {
    const questionGroup = page?.customQuestions.find(
      (question) => question.name === CdsCustomQuestionNames.UtilityValues
    );
    return questionGroup?.sortOrder + 1;
  };

  const utilityQuestionPath = generatePath(Paths.reports.questionDetails, {
    wevoId: wevo?.id,
    groupNum: getUtilityQuestionGroupNum(),
  });

  const onlyUtilitySelected = selectedWords?.length === 1 && selectedWords?.[0] === Utility;

  const getClassNamesForQuote = (quote, index) => {
    let classNames = [];

    if (typeof handleQuoteClick === 'function') {
      classNames.push(classes.cell);
    }

    classNames.push(!!selectedQuote && quote.id === selectedQuote.id && classes.selected);
    classNames.push(index >= rows.length - 1 && classes.noBottomBorder);

    return classNames;
  };

  const handleChangePage = (event, newPageNum) => {
    setPageNum(newPageNum);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNum(0);
  };

  const rows =
    rowsPerPage > 0
      ? displayQuotes.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage)
      : displayQuotes;

  if (!rows.length > 0 && pageNum !== 0) {
    setPageNum(0);
  }

  const showPagination = rows.length > 0;

  const handleScreenerFilterChange = useCallback(
    (segmentToCheckedMap, segmentToAudienceMap) => {
      onCustomScreenerFilterChange(segmentToCheckedMap, segmentToAudienceMap);

      const filteredScreenerQuestions = [];
      screenerQuestions.forEach((filter) => {
        const segments = segmentToCheckedMap[filter?.id];
        const checkedSegments = filter?.options?.filter((option) => segments?.[option?.id] === true);

        if (checkedSegments.length) {
          const questionAndSegments = { ...filter };
          questionAndSegments.options = checkedSegments;

          filteredScreenerQuestions.push(questionAndSegments);
        }
      });

      setSelectedScreenerQuestions(filteredScreenerQuestions);
    },
    [screenerQuestions, onCustomScreenerFilterChange]
  );

  const renderQuoteChipContainerComponent = () => {
    if (_.isEmpty(selectedWords)) {
      return <></>;
    }

    if (quoteChipContainerComponent) {
      return quoteChipContainerComponent;
    }

    return (
      <DefaultQuoteTableChipContainer
        selectedWords={selectedWords}
        clearWordFilters={clearWordFilters}
        deselectWord={deselectWord}
      />
    );
  };

  return (
    <Paper className={classes.root} elevation={0}>
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                className={classes.noBottomBorder}
                style={{
                  padding: '0px',
                  top:
                    sentimentMapMatch || experienceMapMatch || questionType === CustomQuestionTypes.Heatmap
                      ? 0
                      : '64px',
                }}>
                <Paper className={classes.paper} elevation={0}>
                  <Grid container columnGap={1} rowGap={2}>
                    <Grid item xs={12}>
                      <Typography>{title}</Typography>
                    </Grid>
                    <Grid item xs={12} lg="auto" sx={{ flexGrow: { lg: 1 } }}>
                      <QuoteSearch onSearchChange={onSearchChange} query={searchQuery} />
                    </Grid>
                    <Grid item container columnGap={1} rowGap={2} xs={12} lg="auto">
                      {!_.isEmpty(screenerQuestions) && (
                        <Grid item>
                          <CustomScreenerFilter
                            quotes={fullQuotes}
                            filters={screenerQuestions}
                            onFilterChange={handleScreenerFilterChange}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        <QuoteAudienceFilter quotes={fullQuotes} onFilterChange={onAudienceFilterChange} />
                      </Grid>
                      {filters &&
                        filters.map((FilterComponent, index) => (
                          <Grid item key={index}>
                            {FilterComponent}
                          </Grid>
                        ))}
                    </Grid>
                    {renderQuoteChipContainerComponent()}
                    {selectedWords?.includes(Utility) && (
                      <Grid container item justifyContent="center">
                        <Paper elevation={2} className={classes.utilityContainer}>
                          <Grid container item wrap="nowrap">
                            <Grid item className={classes.utilityTitleWrapper}>
                              <Typography className={classes.utilityTitle}>Utility</Typography>
                            </Grid>
                            <Grid item className={classes.utilityContentWrapper}>
                              <Link to={utilityQuestionPath} className={classes.utilityLink}>
                                The Utility diagnostic breakdown can be found in <em>Custom Questions.</em>
                              </Link>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <QuestionCarousel questionsAndOptions={selectedScreenerQuestions} />
                    </Grid>
                    {showPagination && (
                      <Grid item xs={12} className={classes.tablePagination}>
                        <TablePagination
                          className={classes.paginationRoot}
                          component="div"
                          rowsPerPageOptions={[10, 25, 50]}
                          count={displayQuotes.length}
                          rowsPerPage={rowsPerPage}
                          page={pageNum}
                          SelectProps={{
                            inputProps: { 'aria-label': 'quotes per page:' },
                            native: true,
                          }}
                          labelRowsPerPage="Quotes per page"
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Paper>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.isEmpty(displayQuotes) && !onlyUtilitySelected && (
              <TableRow>
                <TableCell>
                  <Box py={1}>
                    <Typography>Sorry! There are no quotes to display.</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!_.isEmpty(displayQuotes) &&
              rows.map((quote, index) => (
                <TableRow key={quote.id}>
                  <TableCell className={clsx(getClassNamesForQuote(quote, index))}>
                    <QuoteBlock
                      wevo={wevo}
                      pageId={String(page.id)}
                      quote={quote}
                      highlightQuote={handleQuoteClick}
                      onQuoteUpdate={onQuoteUpdate}
                      isSelected={!!selectedQuote && quote.id === selectedQuote.id}
                      chips={chips}
                      questionType={questionType}
                      sentimentMapMatch={sentimentMapMatch}
                      experienceMapMatch={experienceMapMatch}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          {!props.maxHeight && showPagination && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  className={clsx(classes.paginationRoot, classes.bottomPagination)}
                  rowsPerPageOptions={[10, 25, 50]}
                  count={displayQuotes.length}
                  rowsPerPage={rowsPerPage}
                  page={pageNum}
                  SelectProps={{
                    inputProps: { 'aria-label': 'quotes per page:' },
                    native: true,
                  }}
                  labelRowsPerPage="Quotes per page"
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Paper>
  );
};

const attributeProps = {
  attributeId: PropTypes.string.isRequired,
  attributeLabel: PropTypes.string.isRequired,
  segmentId: PropTypes.string.isRequired,
  segmentLabel: PropTypes.string.isRequired,
};

const quoteProps = {
  quote: PropTypes.string.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape(attributeProps)).isRequired,
};

QuotesTable.propTypes = {
  wevo: PropTypes.object.isRequired,
  fullQuotes: PropTypes.arrayOf(PropTypes.shape(quoteProps)).isRequired,
  displayQuotes: PropTypes.arrayOf(PropTypes.shape(quoteProps)).isRequired,
  onQuoteUpdate: PropTypes.func,
  onAudienceFilterChange: PropTypes.func.isRequired,
  onCustomScreenerFilterChange: PropTypes.func,
  clearWordFilters: PropTypes.func,
  onSearchChange: PropTypes.func.isRequired,
  handleQuoteClick: PropTypes.func,
  selectedQuote: PropTypes.shape(quoteProps),
  title: PropTypes.string.isRequired,
  selectedWords: PropTypes.arrayOf(PropTypes.string),
  deselectWord: PropTypes.func,
  filters: PropTypes.arrayOf(PropTypes.element),
  chips: PropTypes.arrayOf(PropTypes.func),
  maxHeight: PropTypes.string,
  QuoteChipContainerComponent: PropTypes.element,
};

QuotesTable.defaultProps = {
  filters: [],
  chips: [],
};

export default QuotesTable;
