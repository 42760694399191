import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

/**
 * Update automated insights session details (e.g. name, type, directive)
 */
const updateSessionDetails = async ({ sessionId, updates }) => {
  const { data } = await axios({
    url: `/api/v2/automated-insight-sessions/${sessionId}`,
    method: 'PUT',
    data: updates,
  });
  return data?.automatedInsightSession;
};

export default function useUpdateSessionDetails() {
  const queryClient = useQueryClient();
  return useMutation(updateSessionDetails, {
    onSuccess: (automatedInsightSession, { sessionId }) => {
      queryClient.setQueryData(['automatedInsightSession', sessionId], (oldData) => ({
        ...oldData,
        ...automatedInsightSession,
      }));
      queryClient.invalidateQueries('automatedInsightSessions');
    },
  });
}
