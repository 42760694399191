import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import { clamp } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RegionSelect from 'react-region-select';
import { useResizeDetector } from 'react-resize-detector';
import ScrollLock from 'react-scroll-lock-component';
import {
  BUBBLE_MAP_DEFAULT_BG_COMPONENT_ID,
  BUBBLE_MAP_DEFAULT_COMPONENT_ID,
  DEFAULT_HIGHLIGHT_SIZE,
  EXPERIENCE_CLICK_MAP_BUBBLE_RADIUS_PX,
} from '../../../modules/report/constants';
import BubbleMap from './BubbleMap';
import { Throbber } from './Throbber';

const styles = {
  container: {
    overflow: 'auto',
    maxHeight: '90vh',
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    zIndex: 0,
  },
  chart: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    zIndex: -1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'visible',
    border: 'solid rgba(0, 0, 0, 0.5)',
  },
  remove: {
    color: 'white',
    background: 'rgba(0, 0, 0, 0.5)',
    position: 'absolute',
    zIndex: 3,
    cursor: 'pointer',
    borderRadius: '1px',
    border: '1px solid white',
    /* center the div to its parent container */
    left: '50%' /* position the left edge of the element at the middle of the parent */,
    transform: 'translateX(-50%)' /* move the element to half its size */,
    width: 'fit-content',
    height: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    padding: '8px',
  },
  removeRegionText: {
    zIndex: -1,
    fontSize: '10px',
    whiteSpace: 'nowrap',
  },
  removeRegionIcon: {
    fontSize: '14px',
  },
};

const ClickTrackingMap = ({
  image,
  points,
  radius = EXPERIENCE_CLICK_MAP_BUBBLE_RADIUS_PX,
  highlightedPoints,
  selectedRegion,
  onRegionChange,
  minRadiusPx = null,
  maxRadiusPx = null,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(null);

  const { width, height, ref } = useResizeDetector();
  const containerRef = useRef();
  const imageRef = useRef();

  const scaledRadius = useMemo(() => {
    if (!scaleFactor) {
      return;
    }

    const scaledRadius = radius * scaleFactor;

    if (minRadiusPx && scaledRadius < minRadiusPx) {
      return minRadiusPx;
    }

    if (maxRadiusPx && scaledRadius > maxRadiusPx) {
      return maxRadiusPx;
    }

    return scaledRadius;
  }, [maxRadiusPx, minRadiusPx, radius, scaleFactor]);

  const scaledPoints = useMemo(() => {
    if (!points || !scaleFactor) {
      return;
    }

    return points.map((unscaledPoint) => {
      return {
        x: unscaledPoint.x * scaleFactor,
        y: unscaledPoint.y * scaleFactor,
        r: scaledRadius,
      };
    });
  }, [points, scaleFactor, scaledRadius]);

  const computeScaleFactor = useCallback(() => {
    if (imageRef?.current) {
      const scaleFactor = imageRef?.current.clientWidth / image?.width;
      setScaleFactor(scaleFactor);
    }
  }, [image?.width]);

  const handleImageLoad = () => {
    setImageLoaded(true);
    computeScaleFactor();
  };

  const handleWindowResize = useCallback(() => {
    computeScaleFactor();
  }, [computeScaleFactor]);

  useEffect(() => {
    handleWindowResize();
  }, [height, width, handleWindowResize]);

  const renderHighlightedPoint = ({ highlightedPoint, index, size }) => {
    if (!highlightedPoints) {
      return null;
    }

    const highlightSize = size || DEFAULT_HIGHLIGHT_SIZE;

    const colors = {
      centerColor: '#000000',
      centerStroke: 0,
      centerStrokeColor: 'none',
      haloColor: '#ffffff',
      haloStroke: 3,
      pulseColor: '#ffffff',
    };

    const left = highlightedPoint.x * scaleFactor - highlightSize / 2;
    const top = highlightedPoint.y * scaleFactor - highlightSize / 2;
    return (
      <div
        key={`throbber ${index} (${left}, ${top})`}
        style={{
          position: 'absolute',
          height: highlightSize,
          width: highlightSize,
          left: left,
          top: top,
        }}>
        <Throbber {...colors} />
      </div>
    );
  };

  const shouldDisplayRegion = () => {
    return !!selectedRegion;
  };

  const renderRegion = ({ isChanging }) => {
    if (isChanging || !shouldDisplayRegion()) {
      return null;
    }

    const region = selectedRegion;
    const { clientHeight } = imageRef?.current;
    const bottomY = ((region.y + region.height) * clientHeight) / 100;
    const onBottomOfPage = clientHeight - bottomY < 35;
    return (
      <div>
        <div
          onClick={() => onRegionChange(null)}
          style={{
            ...styles.remove,
            ...(onBottomOfPage ? { top: '-3.2rem' } : { bottom: '-3.1rem' }),
          }}>
          <CloseIcon sx={styles.removeRegionIcon} />
          <Typography sx={styles.removeRegionText} component="p">
            Remove
          </Typography>
        </div>
      </div>
    );
  };

  const renderRegionOverlay = () => {
    if (!shouldDisplayRegion()) {
      return null;
    }

    const region = selectedRegion;
    const { clientWidth, clientHeight } = imageRef?.current;

    // region coords are in percent so we convert to pixels
    const regionX = (region.x * clientWidth) / 100;
    const regionY = (region.y * clientHeight) / 100;
    const regionWidth = (region.width * clientWidth) / 100;
    const regionHeight = (region.height * clientHeight) / 100;

    // create a 'poke hole' effect for a selected region, by rendering
    // a div with borders that appear as the inverse of the selected region
    const top = regionY;
    const left = regionX;
    const right = clientWidth - regionX - regionWidth;
    const bottom = clientHeight - top - regionHeight;

    const widthString = [top, right, bottom, left].map((coord) => `${coord}px`).join(' ');
    return <div style={{ ...styles.overlay, ...{ borderWidth: widthString } }} />;
  };

  const onSelectRegionChange = (regions) => {
    const region = regions[0];

    // When we pass [] to the <RegionSelect> component, it notifies us about the change but the x, y, width,
    // and height fields are not present. We can safely ignore these calls.
    const newRegionIsValid = region && ['x', 'y', 'width', 'height'].every((p) => isFinite(region[p]));
    if (!newRegionIsValid || !onRegionChange) {
      return;
    }

    const newRegionIsSmall = region.height <= 1 && region.width <= 1;
    if (newRegionIsSmall) {
      if (selectedRegion) {
        // The user clicked but they already have a region selected. We interpret this as a request to clear
        // that region.
        onRegionChange(null);
      } else {
        // The user clicked and there's no region selected. We'll draw a FIXED_BOX_SIZE_IN_PX square region
        // centered on the location they clicked.
        const widthPct = (100 * 100) / imageRef?.current.clientWidth;
        const heightPct = (100 * 100) / imageRef?.current.clientHeight;

        // make sure the x & y stay within the bounds of the heatmap
        const x = clamp(region.x - widthPct / 2, 0, 100 - widthPct);
        const y = clamp(region.y - heightPct / 2, 0, 100 - heightPct);

        const fixedSizeRegion = {
          x,
          y,
          width: widthPct,
          height: heightPct,
        };
        onRegionChange(fixedSizeRegion);
      }
    } else {
      onRegionChange(region);
    }
  };

  if (!points) {
    return;
  }

  return (
    <ScrollLock>
      <Box sx={styles.container} ref={containerRef}>
        <div ref={ref} style={styles.wrapper}>
          {
            // <img> is the only statically-positioned element so it defines the size of the <div>
            // container. We don't know how big the container is going to be until the image finishes
            // loading, so we trigger an update when that happens.
          }
          <img
            style={styles.image}
            src={image?.url}
            onLoad={handleImageLoad}
            ref={imageRef}
            alt="page screenshot"
            id={BUBBLE_MAP_DEFAULT_BG_COMPONENT_ID}
          />
          <div
            style={{
              height: imageRef?.current?.clientHeight,
              ...styles.chart,
            }}
            id={BUBBLE_MAP_DEFAULT_COMPONENT_ID}>
            <Box
              component={RegionSelect}
              maxRegions={1}
              sx={{ ...styles.heatmapSelection }}
              className={shouldDisplayRegion() ? 'showRegion' : 'hideRegion'}
              regions={selectedRegion ? [selectedRegion] : []}
              onChange={onSelectRegionChange}
              regionRenderer={renderRegion}
              constraint={true}>
              <BubbleMap
                clickPoints={scaledPoints}
                width={imageRef?.current?.clientWidth}
                height={imageRef?.current?.clientHeight}
                imageLoaded={imageLoaded}
              />
              {highlightedPoints &&
                highlightedPoints?.map((highlightedPoint, index) =>
                  renderHighlightedPoint({ highlightedPoint, index, size: scaleFactor * 200 })
                )}
              {renderRegionOverlay()}
            </Box>
          </div>
        </div>
      </Box>
    </ScrollLock>
  );
};

ClickTrackingMap.propTypes = {
  image: PropTypes.object.isRequired,
  points: PropTypes.array,
  radius: PropTypes.number,
  highlightedPoints: PropTypes.array,
  selectedRegion: PropTypes.object,
  onRegionChange: PropTypes.func,
};

export default ClickTrackingMap;
