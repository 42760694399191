import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import { Fragment, useCallback } from 'react';
import AssetUploadCard from './AssetUploadCard';

export default function AssetUploadList({ files, onChange, maxFiles, isUploading, error }) {
  const handleFileInput = useCallback(
    (newFiles) => {
      onChange([...files, ...newFiles]);
    },
    [files, onChange]
  );

  const handleDeleteFile = useCallback(
    (index) => onChange(files.slice(0, index).concat(files.slice(index + 1))),
    [files, onChange]
  );

  return (
    <Box>
      <Grid container rowGap={1} alignItems="center" mb={1}>
        <Grid item md={2}></Grid>
        <Grid item xs={12} md={8}>
          <AssetUploadCard
            handleFileInput={handleFileInput}
            multiple={maxFiles > 1}
            iconColor={'rgba(255, 255, 255, 0.32)'}
            isUploading={isUploading}
            maxFiles={maxFiles}
            background={files.length > 0 ? 'transparent' : null}
          />
        </Grid>
        <Grid item xs={1} md={2}></Grid>
      </Grid>
      <Grid container rowGap={1} alignItems="center">
        {files.map((file, index) => {
          return (
            <Fragment key={index}>
              <Grid item xs={12} md={2} sx={{ textAlign: { md: 'right' }, pr: 4 }}>
                <Typography color="primary" variant="body2">
                  Page {index + 1}
                </Typography>
              </Grid>
              <Grid item xs md={8}>
                <Box
                  sx={{
                    borderRadius: 2,
                    border: '1px solid rgba(217, 217, 217, 0.2)',
                    color: 'rgba(221, 221, 221, 0.83)',
                    px: 2,
                  }}
                  p={1}>
                  <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid item sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      <Typography noWrap>{file.name}</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleDeleteFile(index)}
                        color="primary"
                        sx={{
                          textTransform: 'none',
                          opacity: 0.6,
                          '&:hover': {
                            backgroundColor: 'transparent',
                            opacity: 0.8,
                          },
                          '&:focus': {
                            opacity: 0.8,
                          },
                        }}>
                        <CancelRoundedIcon style={{ height: '20px', width: '20px' }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item md={2}></Grid>
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
}
