import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

const createPage = async ({ wevoId, name, deviceId, primerId, primerContext }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/pages`,
    method: 'POST',
    data: {
      wevoId,
      name,
      deviceId,
      primerId,
      primerContext,
    },
  });

  return response.data.page;
};

export default function useAddPage() {
  const queryClient = useQueryClient();
  return useMutation(createPage, {
    mutationKey: 'createPage',
    onSuccess: (page) => {
      queryClient.setQueryData(['wevoData', { wevoId: page.wevoInfoId }], (previousWevoState) => {
        return {
          ...previousWevoState,
          pages: (previousWevoState?.pages ?? []).concat(page),
        };
      });
      queryClient.invalidateQueries(['customQuestionsData', { wevoId: page.wevoInfoId }]);
    },
    onError: (err, { wevoId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
