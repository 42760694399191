import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { generatePath, Link } from 'react-router-dom';
import { PlateEmptyValue } from '../../../../modules/plate/constants';
import { KeyFindingType } from '../../../../modules/report/constants';
import { hasKeyFindings } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';
import useKeyFindings from '../../hooks/useKeyFindings';
import SlateKeyFindingsViewer from '../../takeaways/components/SlateKeyFindingsViewer';

const TakeawaySummary = ({ wevo, page }) => {
  const { data: keyFindings, isLoading } = useKeyFindings({ wevoId: wevo?.id, pageId: page?.id });

  let keyFindingSource;
  const keyFindingType = keyFindings?.[0]?.type;
  if (keyFindingType === KeyFindingType.Slate) {
    keyFindingSource = keyFindings?.[0]?.summary ?? PlateEmptyValue;
  } else if (keyFindingType === KeyFindingType.Markdown) {
    keyFindingSource = keyFindings?.[0]?.summary ?? '';
  } else {
    keyFindingSource = _.get(keyFindings?.bullets ?? '');
  }

  // Show Key Findings section only if there are any key findings to show. else, return null and show nothing
  if (!hasKeyFindings(wevo, keyFindings)) {
    return null;
  }

  return (
    <Card elevation={4} sx={{ padding: 4, marginTop: 4, marginBottom: 4, borderRadius: '20px' }}>
      <CardHeader
        title={
          <Typography variant="h3" align="center">
            Key Findings
          </Typography>
        }
      />
      <CardContent>
        <Takeaways
          keyFindingType={keyFindingType}
          keyFindingSource={keyFindingSource}
          wevoId={wevo?.id}
          pageId={page?.id}
          isLoading={isLoading}
        />
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end' }}>
        <Link
          to={generatePath(Paths.reports.takeaways, { wevoId: wevo?.id })}
          style={{ textDecoration: 'none' }}>
          <Button endIcon={<ChevronRightIcon />}>See Takeaways</Button>
        </Link>
      </CardActions>
    </Card>
  );
};

TakeawaySummary.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
};

const Takeaways = ({ keyFindingType, keyFindingSource, wevoId, pageId, isLoading }) => {
  if (isLoading) {
    return (
      <Box p={2} textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else {
    if (keyFindingType === KeyFindingType.Slate) {
      return <SlateKeyFindingsViewer findings={keyFindingSource} wevoId={wevoId} pageId={pageId} />;
    } else {
      return (
        <Typography variant="body2" component="span" sx={{ color: grey[800] }}>
          <ReactMarkdown>{keyFindingSource}</ReactMarkdown>
        </Typography>
      );
    }
  }
};

export default TakeawaySummary;
