import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Link, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback, useMemo, useState } from 'react';
import { ReactComponent as FlagIcon } from '../../../assets/intake-experience-flag.svg';
import { ReactComponent as LocationIcon } from '../../../assets/intake-experience-location.svg';
import useFetchExperienceAsset from '../../../hooks/useExperienceAsset';
import { ExperienceEndScreenType } from '../../../modules/intake/constants';
import { DEVICE_ID_TO_NAME } from '../../../modules/wevos/constants';
import ImagePreview from '../../../ui/ImagePreview';
import FigmaEmbeddedProtoype from './FigmaEmbeddedPrototype';

export function ExperienceGoalSelection({
  wevo,
  experience,
  onSelected,
  isEditable = true,
  orientation = 'horizontal',
  maxImageHeight = null,
}) {
  const [isSelectingGoalStep, setIsSelectingGoalStep] = useState(false);

  const startStep = useMemo(() => {
    return (experience?.steps || []).find(
      (step) => step?.configuration?.id === experience?.configuration?.entryPointNodes?.[0]
    );
  }, [experience]);

  const destinationStep = useMemo(() => {
    return (experience?.steps || []).find((step) => step?.endScreenType === ExperienceEndScreenType.Success);
  }, [experience]);

  const handleSelected = useCallback(
    (experienceStep) => {
      if (onSelected) {
        onSelected(experienceStep);
      }

      setIsSelectingGoalStep(false);
    },
    [onSelected, setIsSelectingGoalStep]
  );

  const startLabel = useMemo(() => {
    return (
      <Typography variant="caption" color="textSecondary">
        <Box component="span" pr={1}>
          <LocationIcon
            style={{
              height: 15,
              width: 15,
              stroke: grey[700],
              verticalAlign: 'middle',
            }}
          />
        </Box>
        Start Page
      </Typography>
    );
  }, []);

  const startImage = useMemo(() => {
    return (
      <ImagePreview
        image={{ original: startStep.staticImage }}
        maxHeight={maxImageHeight || 350}
        maxWidth={250}
      />
    );
  }, [startStep, maxImageHeight]);

  const destinationLabel = useMemo(() => {
    if (isEditable) {
      return (
        <Link sx={{ cursor: 'pointer' }} color="textSecondary" onClick={() => setIsSelectingGoalStep(true)}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">
                <Box component="span" pr={1}>
                  <FlagIcon style={{ height: 15, width: 15, stroke: grey[700], verticalAlign: 'middle' }} />
                </Box>

                {destinationStep ? 'Select Destination Page' : 'Destination Page'}
              </Typography>
            </Grid>
          </Grid>
        </Link>
      );
    }

    return (
      <Typography variant="caption" color="textSecondary">
        <Box component="span" pr={1}>
          <FlagIcon style={{ height: 15, width: 15, stroke: grey[700], verticalAlign: 'middle' }} />
        </Box>
        Destination Page
      </Typography>
    );
  }, [destinationStep, isEditable, setIsSelectingGoalStep]);

  const destinationImage = useMemo(() => {
    if (destinationStep) {
      return (
        <ImagePreview
          image={{ original: destinationStep.staticImage }}
          maxHeight={maxImageHeight || 350}
          maxWidth={250}
        />
      );
    }
    return <></>;
  }, [destinationStep, maxImageHeight]);

  let layout;

  if (orientation === 'vertical') {
    layout = (
      <Grid container>
        <Grid item xs={12}>
          <Grid container pt={2}>
            <Grid item xs={6}>
              {startImage}
            </Grid>
            <Grid item xs={6} sx={{ alignSelf: 'center', textAlign: 'center' }}>
              {startLabel}
            </Grid>
          </Grid>
          <Grid container pt={2}>
            <Grid item xs={6}>
              {destinationImage}
            </Grid>
            <Grid item xs={6} sx={{ alignSelf: 'center', textAlign: 'center' }}>
              {destinationLabel}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    layout = (
      <Grid container spacing={1} justifyContent="space-around">
        <Grid item xs={6} md={4}>
          <Box>
            <Box mb={1}>{startLabel}</Box>
            {startImage}
          </Box>
        </Grid>
        <Grid item xs={6} md={4} alignSelf={destinationStep ? 'initial' : 'center'}>
          <Box>
            <Box mb={1}>{destinationLabel}</Box>
            {destinationImage}
          </Box>
        </Grid>
      </Grid>
    );
  }

  return (
    <div>
      {layout}
      {isEditable && (
        <ExperienceScreenSelectionDialog
          wevo={wevo}
          experience={experience}
          open={isSelectingGoalStep}
          onClose={() => setIsSelectingGoalStep(false)}
          onNext={handleSelected}
        />
      )}
    </div>
  );
}

export function ExperienceScreenSelectionDialog({ wevo, experience, open, onClose, onNext }) {
  const { data: experienceAsset } = useFetchExperienceAsset({
    wevoId: wevo.id,
    pageId: experience.wevoPageId,
    experienceId: experience.id,
  });

  const [selectedExperienceStep, setSelectedExperienceStep] = useState(null);
  const [isInvalidDestination, setIsInvalidDestination] = useState(false);

  const externalIdToExperienceStep = useMemo(() => {
    return experience.steps.reduce((acc, cur) => {
      acc[cur.externalId] = cur;
      return acc;
    }, {});
  }, [experience]);

  const deviceType = useMemo(() => {
    const deviceId = wevo?.devices?.[0];
    return DEVICE_ID_TO_NAME?.[deviceId] || null;
  }, [wevo]);

  const handleClose = useCallback(
    (event, reason) => {
      if (reason && reason === 'backdropClick') {
        return;
      }

      onClose();
    },
    [onClose]
  );

  const handlePrototypeTransition = useCallback(
    (event) => {
      const experienceStep = externalIdToExperienceStep?.[event?.screenId];

      if (!experienceStep) {
        setIsInvalidDestination(true);
        return;
      }

      setIsInvalidDestination(false);
      setSelectedExperienceStep(experienceStep);
    },
    [externalIdToExperienceStep]
  );

  const handleNext = useCallback(() => {
    onNext(selectedExperienceStep);
  }, [onNext, selectedExperienceStep]);

  const experienceContainerHeight = useCallback(() => {
    const deviceHeight = experience?.configuration?.device?.size?.height;

    if (deviceHeight) {
      return deviceHeight;
    }

    return deviceType === 'mobile' ? '70vh' : '60vh';
  }, [experience?.configuration?.device?.size?.height, deviceType]);

  // To Do: for now we assume an embedded figma prototype. In the future, we'll swap this out for a WEVO Renderer
  const renderedExperience = useMemo(() => {
    if (!experienceAsset) {
      return <></>;
    }
    return (
      <FigmaEmbeddedProtoype
        prototype={experienceAsset}
        deviceType={deviceType}
        onTransition={handlePrototypeTransition}
      />
    );
  }, [deviceType, experienceAsset, handlePrototypeTransition]);

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth={deviceType === 'mobile' ? 'md' : 'lg'}>
      <DialogContent>
        <Grid container>
          <Grid item xs={deviceType === 'mobile' ? 5 : 8} sx={{ height: experienceContainerHeight() }}>
            {renderedExperience}
          </Grid>
          <Grid item xs={deviceType === 'mobile' ? 7 : 4} alignSelf="center" sx={{ p: 4 }}>
            <Box mb={1}>
              <Typography fontWeight={600}>
                Click through the prototype or the arrows to select the destination page
              </Typography>
            </Box>
            <Box mb={1}>
              <Typography>
                The destination page is used to determine the time it takes respondents to complete the
                experience and the success rate for the experience.
              </Typography>
            </Box>
            {isInvalidDestination && (
              <Box mb={1}>
                <Typography color="error">
                  This destination page is not recognized. Your prototype may have changed since it was last
                  imported. Reimporting your prototype may resolve this issue.
                </Typography>
              </Box>
            )}
            <Box mt={6}>
              You selected:{' '}
              {selectedExperienceStep && (
                <Typography fontWeight={600} color="primary" component="span">
                  {selectedExperienceStep?.configuration?.name ||
                    `Page ${selectedExperienceStep?.sortOrder + 1}`}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box p={2}>
          <Button
            size="lg"
            variant="contained"
            color="inherit"
            onClick={handleClose}
            sx={{ mx: 1, minWidth: '5.5rem', borderRadius: '10px', backgroundColor: 'white', color: 'black' }}>
            Cancel
          </Button>
          <Button
            size="lg"
            variant="contained"
            onClick={handleNext}
            sx={{ mx: 1, minWidth: '5.5rem', borderRadius: '10px' }}
            disabled={!selectedExperienceStep || isInvalidDestination}>
            Next
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
