import { fetchExperienceStepImage } from '../../components/reports/api';
import { drawBubbles } from '../../components/reports/components/BubbleMap';
import { EXPERIENCE_CLICK_MAP_BUBBLE_RADIUS_PX } from './constants';

const downloadExperienceClickmap = async (wevoId, pageId, experienceStep, points, filename) => {
  // fetch step image from s3 bucket
  const bgImage = new Image();
  const imageURL = await fetchExperienceStepImage(
    wevoId,
    pageId,
    experienceStep?.experienceId,
    experienceStep?.id
  );
  bgImage.src = imageURL;

  bgImage.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = bgImage.width;
    canvas.height = bgImage.height;
    context.drawImage(bgImage, 0, 0, canvas.width, canvas.height);

    // exploit that the original image and click coordinates are both in the original image's coordinate space,
    // we can just draw points directy on the image without bothering with any rescaling
    drawBubbles(
      context,
      (points ?? []).map((point) => ({
        x: point.x,
        y: point.y,
        r: EXPERIENCE_CLICK_MAP_BUBBLE_RADIUS_PX,
      }))
    );

    const dataURL = canvas.toDataURL();
    const link = document.createElement('a');
    link.download = `${filename}.png`;
    link.href = dataURL;
    link.click();
  };
};

export default downloadExperienceClickmap;
