import { Box, Typography, styled } from '@mui/material';
import { ReactComponent as SentimentFilterCheck } from '../../../src/assets/sentiment-filter-check.svg';
import { ClusterTypeNames, ClusterTypes } from '../../modules/automated-insights/constants';

const FilterButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})(({ theme, isSelected }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '25px',
  borderRadius: '12px',
  border: isSelected ? 'none' : '1px solid #F1F1F1',
  backgroundColor: '#F1F1F1',
  fontFamily: theme.typography.fontFamily,
  cursor: 'pointer',
  [theme.breakpoints.down('sm')]: {
    width: '100px',
  },
  [theme.breakpoints.up('sm')]: {
    width: '115px',
  },
  [theme.breakpoints.up('lg')]: {
    width: '120px',
  },
}));

function SentimentMapFilterButton({ isSelected, name, onToggleClick }) {
  return (
    <FilterButton
      component="button"
      aria-label={`${name} filter`}
      isSelected={isSelected}
      onClick={onToggleClick}>
      <Box
        sx={{
          display: 'flex',
          height: '15px',
          width: '15px',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: isSelected ? '#439793' : '#D9EAE9',
          borderRadius: '50%',
        }}>
        {isSelected && <SentimentFilterCheck fill="#FFFFFF" />}
      </Box>
      <Typography variant="body3" sx={{ marginX: 'auto', textTransform: 'capitalize' }}>
        {name}
      </Typography>
    </FilterButton>
  );
}

export default function SentimentMapFilterButtons({ hasContentious, selectedOptions, onToggleClick }) {
  return (
    <Box aria-label="sentiment options" sx={{ display: 'flex', columnGap: { xs: '4px', sm: 1 } }}>
      <SentimentMapFilterButton
        isSelected={selectedOptions.includes(ClusterTypes.Likes)}
        name={ClusterTypeNames.Likes}
        onToggleClick={() => onToggleClick(ClusterTypes.Likes)}
      />
      <SentimentMapFilterButton
        isSelected={selectedOptions.includes(ClusterTypes.Dislikes)}
        name={ClusterTypeNames.Dislikes}
        onToggleClick={() => onToggleClick(ClusterTypes.Dislikes)}
      />
      {hasContentious && (
        <SentimentMapFilterButton
          isSelected={selectedOptions.includes(ClusterTypes.Contentious)}
          name={ClusterTypeNames.Contentious}
          onToggleClick={() => onToggleClick(ClusterTypes.Contentious)}
        />
      )}
    </Box>
  );
}
