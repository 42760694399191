import PropTypes from 'prop-types';
import { getPointsByStepId } from '../../../../modules/report/experienceMaps';
import Carousel from '../../components/Carousel';
import ClickTrackingMap from '../../components/ClickTrackingMap';

const ExperienceCarousel = ({
  currentStep,
  steps,
  filteredOutcomes,
  onSelection,
  itemHeight = 130,
  numItemsDisplayed = 4,
}) => {
  const handleSelection = ({ index }) => {
    onSelection(steps[index], index);
  };

  return (
    <Carousel
      selectedItemIndex={steps.map((step) => step.id).indexOf(currentStep.id)}
      items={steps}
      itemComponent={({ index }) => {
        const stepId = steps[index]?.id;
        const points = getPointsByStepId(filteredOutcomes, stepId);
        return <ClickTrackingMap image={steps[index]?.staticImage} points={points} />;
      }}
      onSelection={handleSelection}
      itemHeight={itemHeight}
      numItemsDisplayed={numItemsDisplayed}
    />
  );
};

ExperienceCarousel.propTypes = {
  currentStep: PropTypes.object.isRequired,
  steps: PropTypes.array.isRequired,
  filteredOutcomes: PropTypes.array,
  onSelection: PropTypes.func.isRequired,
  itemHeight: PropTypes.number,
  numItemsDisplayed: PropTypes.number,
};

export default ExperienceCarousel;
