import { useMutation } from 'react-query';
import axios from '../api';

const updateExperienceStep = async ({ wevoId, pageId, experienceId, experienceStepId, endScreenType }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/experiences/${experienceId}/experience-steps/${experienceStepId}`,
    method: 'PUT',
    data: { endScreenType },
  });
  return data || {};
};

export default function useUpdateExperienceStep() {
  return useMutation(updateExperienceStep);
}
