import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { ReactComponent as ClickMapIcon } from '../../../assets/ease-icon.svg';
import { ReactComponent as ExperienceMapIcon } from '../../../assets/experience-map.svg';
import { ReactComponent as ExperiencePathsIcon } from '../../../assets/experience-paths.svg';
import { ReactComponent as SentimentMapIcon } from '../../../assets/sentiment-map.svg';
import { HASH_LINKS, LeftNavLevelType } from '../../../modules/report/constants';
import { WevoTestType, WevoType } from '../../../modules/wevos/constants';
import { Paths } from '../../../routes';
import LeftNavItem from './LeftNavItem';
import { LEFT_NAV_LABELS } from '../../../modules/report/constants';
import { useMemo } from 'react';

const experienceHashLinks = [
  {
    id: HASH_LINKS.experienceSentimentMap,
    type: LeftNavLevelType.HashLink,
    icon: <SentimentMapIcon style={{ width: '22px', height: 'inherit' }} />,
    name: LEFT_NAV_LABELS[HASH_LINKS.experienceSentimentMap],
  },
  {
    id: HASH_LINKS.experiencePaths,
    type: LeftNavLevelType.HashLink,
    icon: <ExperiencePathsIcon style={{ width: '22px', height: 'inherit' }} />,
    name: LEFT_NAV_LABELS[HASH_LINKS.experiencePaths],
  },
  {
    id: HASH_LINKS.experienceClickTrackingMap,
    type: LeftNavLevelType.HashLink,
    icon: <ClickMapIcon style={{ width: '22px', height: 'inherit', paddingLeft: '3px' }} />,
    name: LEFT_NAV_LABELS[HASH_LINKS.experienceClickTrackingMap],
  },
];

const ExperienceMapLeftNavButton = ({
  wevo,
  selected,
  isLimitedReport,
  selectedSubItem,
  setSelectedSubItem,
}) => {
  const levels = useMemo(() => {
    if (wevo?.pages?.length === 1) {
      return experienceHashLinks;
    }

    return (wevo?.pages ?? []).map((page) => {
      return {
        id: page.id,
        type: LeftNavLevelType.Level,
        level: 'pageNumber',
        levelId: String(page.pageNumber),
        itemNumber: page.pageNumber,
        name: page.name,
        label: page.label,
        pageNumber: page.pageNumber,
        items: experienceHashLinks.map((hashLink) => ({
          ...hashLink,
          pageNumber: page.pageNumber,
          groupNumber: 1,
          stepNumber: 1,
        })),
      };
    });
  }, [wevo]);

  if (wevo?.type === WevoType.Journey && wevo?.testType === WevoTestType.Compare) {
    return (
      <LeftNavItem
        icon={<ExperienceMapIcon style={{ width: 'inherit', height: 'inherit' }} />}
        label="Experience Maps"
        path={generatePath(
          isLimitedReport ? Paths.reports.limitedExperienceMapPage : Paths.reports.experienceMapPage,
          {
            wevoId: wevo?.id,
            pageNum: selectedSubItem?.pageNumber || 1,
          }
        )}
        subItemPath={Paths.reports.experienceMapPage}
        generateSubItemPath={(path, { pageNumber: pageNum }) =>
          generatePath(path, { wevoId: wevo?.id, pageNum })
        }
        selectedSubItem={selectedSubItem}
        setSelectedSubItem={setSelectedSubItem}
        selected={selected}
        levels={levels}
        yOffset={-75}
      />
    );
  }

  if (wevo?.type === WevoType.Journey && wevo?.testType === WevoTestType.Page) {
    return (
      <LeftNavItem
        icon={<ExperienceMapIcon style={{ width: 'inherit', height: 'inherit' }} />}
        label="Experience Maps"
        path={generatePath(
          isLimitedReport ? Paths.reports.limitedExperienceMap : Paths.reports.experienceMap,
          {
            wevoId: wevo?.id,
          }
        )}
        selected={selected}
        levels={levels}
        yOffset={-75}
      />
    );
  }
};

ExperienceMapLeftNavButton.propTypes = {
  wevo: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  isLimitedReport: PropTypes.bool,
  selectedSubItem: PropTypes.object.isRequired,
  setSelectedSubItem: PropTypes.func.isRequired,
};

export default ExperienceMapLeftNavButton;
