import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Checkbox, Grid, Link, Paper, Popover, styled, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { EXPERIENCE_OUTCOMES } from '../../../../modules/report/constants';
import theme from '../../../../theme';

const DropdownButton = styled(Button)(() => ({
  border: '1px solid #757575',
  borderRadius: '6px',
  color: '#757575',
  cursor: 'pointer',
  textTransform: 'none',
  justifyContent: 'space-between',
}));

const PathFilterDropdown = ({ onClick, isOpen }) => {
  const icon = isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <DropdownButton onClick={onClick} endIcon={icon} fullWidth>
      Paths
    </DropdownButton>
  );
};

PathFilterDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const PathFilter = ({ selectedOutcomeFilters, onOutcomeFilterChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const isChecked = (filter, item) => {
    if (filter === 'outcomes') {
      return selectedOutcomeFilters?.includes(item);
    }
  };

  const handleFilterClick = (ev) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOutcomeCheckbox = (ev) => {
    if (ev.target.checked) {
      onOutcomeFilterChange([...selectedOutcomeFilters, ev.target.name]);
    } else {
      onOutcomeFilterChange(selectedOutcomeFilters.filter((outcome) => outcome !== ev.target.name));
    }
  };

  const handleSelectAllCheckboxesClick = (filter) => {
    if (filter === 'outcomes') {
      onOutcomeFilterChange(Object.values(EXPERIENCE_OUTCOMES));
    }
  };

  return (
    <>
      <PathFilterDropdown onClick={handleFilterClick} isOpen={isOpen} />
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        style={{ width: '100%' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Paper sx={{ padding: theme.spacing(2), width: 250 }}>
          <Box>
            <Typography variant="body2" pb={1}>
              Paths
            </Typography>
            {Object.values(EXPERIENCE_OUTCOMES).map((outcome) => (
              <Grid container key={outcome} justifyContent="space-between">
                <Grid item xs={10}>
                  <Box pl={1}>
                    <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                      {outcome}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    size="small"
                    sx={{ padding: '2px' }}
                    name={outcome}
                    checked={isChecked('outcomes', outcome)}
                    onChange={handleOutcomeCheckbox}
                  />
                </Grid>
              </Grid>
            ))}
            <Box textAlign="right">
              <Typography variant="caption">
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleSelectAllCheckboxesClick('outcomes')}
                  underline="hover">
                  Select All
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

export default PathFilter;

PathFilter.propTypes = {
  selectedOutcomeFilters: PropTypes.array.isRequired,
  onOutcomeFilterChange: PropTypes.func.isRequired,
};
