import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Collapse } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { ExpectationOutcome } from '../../../modules/report/constants';
import QuoteBlock from '../components/QuoteBlock';

const useQuoteStyles = makeStyles((theme) => ({
  heading: {
    padding: theme.spacing(1),
    cursor: 'pointer',
  },
}));

const ExpectationQuotes = (props) => {
  const classes = useQuoteStyles();
  const { wevo, page, expectation, onQuoteUpdate } = props;

  const [showMetQuotes, setShowMetQuotes] = useState(true);
  const [showSomewhatQuotes, setShowSomewhatQuotes] = useState(true);
  const [showUnmetQuotes, setShowUnmetQuotes] = useState(true);

  const handleExpandQuotesClick = (ev, outcome) => {
    ev.preventDefault();

    if (outcome === ExpectationOutcome.Met) {
      setShowMetQuotes(!showMetQuotes);
    } else if (outcome === ExpectationOutcome.Somewhat) {
      setShowSomewhatQuotes(!showSomewhatQuotes);
    } else if (outcome === ExpectationOutcome.Unmet) {
      setShowUnmetQuotes(!showUnmetQuotes);
    }
  };

  const filterQuotes = (outcome) => {
    return expectation.quotes.filter((quote) => quote.outcome === outcome);
  };

  const metQuotes = filterQuotes(ExpectationOutcome.Met);
  const somewhatMetQuotes = filterQuotes(ExpectationOutcome.Somewhat);
  const unmetQuotes = filterQuotes(ExpectationOutcome.Unmet);

  const sections = [
    {
      titleLabel: 'Not Met',
      titleColor: '#C85151',
      titleBackgroundColor: 'rgba(200, 81, 81, 0.1)',
      titleOnClick: (ev) => {
        handleExpandQuotesClick(ev, ExpectationOutcome.Unmet);
      },
      quotes: unmetQuotes,
      showQuotes: showUnmetQuotes,
      emptyText: 'Sorry! There are no unmet expectations.',
    },
    {
      titleLabel: 'Somewhat Met',
      titleColor: '#AA8E0D',
      titleBackgroundColor: 'rgba(170, 142, 13, 0.1)',
      titleOnClick: (ev) => {
        handleExpandQuotesClick(ev, ExpectationOutcome.Somewhat);
      },
      quotes: somewhatMetQuotes,
      showQuotes: showSomewhatQuotes,
      emptyText: 'Sorry! There are no somewhat met expectations.',
    },
    {
      titleLabel: 'Fully Met',
      titleColor: '#2B6C2D',
      titleBackgroundColor: 'rgba(76, 175, 80, 0.1)',
      titleOnClick: (ev) => {
        handleExpandQuotesClick(ev, ExpectationOutcome.Met);
      },
      quotes: metQuotes,
      showQuotes: showMetQuotes,
      emptyText: 'Sorry! There are no met expectations.',
    },
  ];

  return (
    <Fragment>
      {sections.map((section) => (
        <Box key={section.titleLabel} p={1}>
          <Grid
            container
            justifyContent="space-between"
            className={classes.heading}
            style={{ backgroundColor: section.titleBackgroundColor }}
            onClick={section.titleOnClick}>
            <Grid item>
              <Typography variant="h5" style={{ color: section.titleColor }} display="inline">
                {section.titleLabel}
              </Typography>
            </Grid>
            <Grid item>
              {section.showQuotes ? (
                <ExpandLessIcon style={{ color: section.titleColor, verticalAlign: 'middle' }} />
              ) : (
                <ExpandMoreIcon style={{ color: section.titleColor, verticalAlign: 'middle' }} />
              )}
            </Grid>
          </Grid>
          <Collapse in={section.showQuotes} unmountOnExit>
            <Grid item container spacing={2}>
              <Box py={2} px={1} width="100%">
                {!section.quotes.length && (
                  <Box py={1} px={2}>
                    <Typography>{section.emptyText}</Typography>
                  </Box>
                )}
                {section.quotes.map((quote) => (
                  <QuoteBlock
                    key={quote.id}
                    wevo={wevo}
                    pageId={String(page.id)}
                    quote={quote}
                    onQuoteUpdate={onQuoteUpdate}
                  />
                ))}
              </Box>
            </Grid>
          </Collapse>
        </Box>
      ))}
    </Fragment>
  );
};

ExpectationQuotes.propTypes = {
  wevo: PropTypes.object.isRequired,
  expectation: PropTypes.object.isRequired,
};

export default ExpectationQuotes;
