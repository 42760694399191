import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Grid, IconButton, styled, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { debounce as _debounce } from 'lodash';
import { useMemo } from 'react';
import { CustomScreenerTypes } from '../../../modules/intake/constants';
import useUpdateCustomScreener from '../hooks/useUpdateCustomScreener';
import { OutcomeSelector } from './CustomScreenerOptions';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: grey[600],
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const Option = (props) => {
  const {
    wevoId,
    questionId,
    optionId,
    optionText,
    outcome,
    onOptionChange,
    onOptionDelete,
    onOutcomeChange,
    questionType,
    choicesLength,
    disableDelete,
  } = props;

  const { mutate: updateCustomScreener } = useUpdateCustomScreener();

  const debounceOptionChange = useMemo(
    () =>
      _debounce((updatedOption) => {
        updateCustomScreener({
          wevoId,
          filterId: questionId,
          changes: { options: [updatedOption] },
        });
      }, 1000),
    [wevoId, questionId, updateCustomScreener]
  );

  const handleOptionTextChange = (ev) => {
    const newOptionText = ev.target.value;
    const optionChange = { id: optionId, optionText: newOptionText };
    debounceOptionChange(optionChange);
    onOptionChange(newOptionText);
  };

  return (
    <Grid container direction="row" alignItems="center" sx={{ ml: 1, columnGap: 1 }}>
      <Grid item display="flex" alignItems="center">
        <RadioButtonUncheckedIcon sx={{ fontSize: 'large', color: grey[600] }} />
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          type="text"
          variant="outlined"
          value={optionText}
          placeholder="Please enter an option..."
          onChange={handleOptionTextChange}
          name="Question"
          autoComplete="off"
        />
      </Grid>

      <Grid item xs={3}>
        <OutcomeSelector
          selectedOutcome={outcome}
          questionType={questionType}
          onOutcomeClick={onOutcomeChange}
        />
      </Grid>

      <Grid item>
        {[CustomScreenerTypes.MultipleChoice, CustomScreenerTypes.MultiSelect].includes(questionType) && (
          <StyledIconButton
            aria-label="delete"
            size="large"
            onClick={onOptionDelete}
            disabled={choicesLength <= 2 || disableDelete}>
            <IndeterminateCheckBoxIcon />
          </StyledIconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default Option;
