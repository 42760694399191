import { Button, Link, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as AngleDownIcon } from '../../assets/angle-down-small.svg';
import { ReactComponent as AngleUpIcon } from '../../assets/angle-up-small.svg';
import { ReactComponent as ChatIcon } from '../../assets/chat-circle.svg';
import { ReactComponent as DetailsIcon } from '../../assets/details.svg';
import { ReactComponent as IdeaIcon } from '../../assets/idea.svg';
import { ReactComponent as ListIcon } from '../../assets/list.svg';
import { AutomatedInsightSections, QuantDiagnosticColors } from '../../modules/automated-insights/constants';
import { TrackEvent } from '../analytics';

const style = (quantCategory) => ({
  button: {
    backgroundColor: QuantDiagnosticColors[quantCategory].button,
    '&:hover': {
      backgroundColor: QuantDiagnosticColors[quantCategory].primary,
    },
    '&:selected': {
      backgroundColor: QuantDiagnosticColors[quantCategory].primary,
    },
    width: '86px',
    height: '27px',
    color: 'black',
    borderRadius: 13.5,
    textTransform: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    gap: '5px',
  },
  openButton: {
    backgroundColor: QuantDiagnosticColors[quantCategory].primary,
    '&:hover': {
      backgroundColor: QuantDiagnosticColors[quantCategory].primary,
    },
    '&:focus': {
      backgroundColor: QuantDiagnosticColors[quantCategory].primary,
    },
    '&:selected': {
      backgroundColor: QuantDiagnosticColors[quantCategory].primary,
    },
    width: '86px',
    height: '27px',
    color: 'black',
    borderRadius: 13.5,
    textTransform: 'none',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '10px',
    gap: '5px',
  },
  font: {
    fontSize: '12px',
    color: 'black',
  },
});

const dropdownStyle = {
  menu: {
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)',
    borderRadius: '6px',
    width: '176px',
  },
  item: {
    gap: '8px',
  },
  icon: {
    minWidth: '20px',
    minHeight: '20px',
  },
  text: {
    fontSize: '14px',
    color: 'black',
    fontWeight: 400,
  },
};

export const ExploreButton = ({
  quantCategory,
  setSelectedQuantTheme,
  setSelectedTab,
  onQuestionsClick,
  expandDetails,
  setExpandDetails,
}) => {
  const { track } = useAnalytics();

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClickUnderstand = (theme) => {
    setAnchorEl(null);
    setSelectedQuantTheme(theme);
    setSelectedTab(AutomatedInsightSections.KeyFindings);
    track(TrackEvent.PULSE_QUANT_TO_UNDERSTAND, { theme });
  };

  const handleClickNextSteps = (theme) => {
    setAnchorEl(null);
    setSelectedQuantTheme(theme);
    setSelectedTab(AutomatedInsightSections.RecommendedExperiments);
    track(TrackEvent.PULSE_QUANT_TO_NEXT_STEPS, { theme });
  };

  const handleClickAskQuestions = (theme) => {
    setAnchorEl(null);
    setSelectedQuantTheme(theme);
    handleClose();
    track(TrackEvent.PULSE_QUANT_TO_ASK_QUESTIONS, { theme });
    onQuestionsClick();
  };

  const handleClickSeeMore = () => {
    setExpandDetails(!expandDetails);
    handleClose();
  };
  return (
    <>
      <Button onClick={handleMenu} sx={isOpen ? style(quantCategory).openButton : style(quantCategory).button}>
        <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '12px', color: 'black' }}>
          Explore
        </Typography>
        {isOpen ? <AngleUpIcon /> : <AngleDownIcon />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={isOpen}
        onClose={handleClose}
        PaperProps={{
          sx: dropdownStyle.menu,
        }}>
        <MenuItem sx={dropdownStyle.item} onClick={handleClickSeeMore}>
          <DetailsIcon sx={dropdownStyle.icon} />
          <Typography sx={dropdownStyle.text}>{expandDetails ? 'See less' : 'See more'}</Typography>
        </MenuItem>
        <Link onClick={() => handleClickUnderstand(quantCategory)}>
          <MenuItem sx={dropdownStyle.item}>
            <IdeaIcon sx={dropdownStyle.icon} />
            <Typography sx={dropdownStyle.text}>Understand why</Typography>
          </MenuItem>
        </Link>
        <MenuItem sx={dropdownStyle.item} onClick={() => handleClickAskQuestions(quantCategory)}>
          <ChatIcon sx={dropdownStyle.icon} />
          <Typography sx={dropdownStyle.text}>Ask questions</Typography>
        </MenuItem>
        <Link onClick={() => handleClickNextSteps(quantCategory)}>
          <MenuItem sx={dropdownStyle.item}>
            <ListIcon sx={dropdownStyle.icon} />
            <Typography sx={dropdownStyle.text}>Next steps</Typography>
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};
