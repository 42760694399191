import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchUserKeyFindings = async ({ queryKey: [key, { wevoId }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/user/key-findings`,
    method: 'GET',
  });

  return data?.keyFindings ?? {};
};

/**
 *
 * @param {{wevoId: string}} params
 * @returns
 */
export default function useUserKeyFindings({ wevoId }) {
  return useQuery(['userKeyFindings', { wevoId }], fetchUserKeyFindings, {
    notifyOnChangeProps: 'tracked',
    placeholderData: {},
  });
}
