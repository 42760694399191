import { Card, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';

export const useStyles = makeStyles((theme) => ({
  font: { fontSize: '12px' },
  serviceCard: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    [theme.breakpoints.down('xl')]: {
      height: '55px',
    },
    [theme.breakpoints.up('md')]: {
      height: '36px',
    },
    textAlign: 'left',
  },
  fontBYOP: {
    fontSize: '11px',
    marginTop: theme.spacing(-1),
  },
  serviceCreditCard: {
    padding: theme.spacing(1.25),
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    color: 'inherit',
    [theme.breakpoints.down('xl')]: {
      height: '55px',
    },
    [theme.breakpoints.up('md')]: {
      height: '36px',
    },
    textAlign: 'right',
  },
  audienceCard: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    height: '36px',
    width: '70px',
    textAlign: 'center',
  },
  audienceCardBYOP: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    height: '36px',
    width: '90px',
    textAlign: 'center',
  },
  creditsCard: {
    padding: theme.spacing(1.25),
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    color: 'inherit',
    height: '36px',
    width: '80px',
    textAlign: 'center',
  },
}));

const CreditDescription = (props) => {
  const { title, credit, showServices, BYOP } = props;
  const classes = useStyles();

  if (showServices) {
    return (
      <Grid container>
        <Grid item xs={8}>
          <Card className={classes.serviceCard} elevation={2}>
            <Typography className={classes.font}>
              <b>{title}</b>
            </Typography>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={2} className={classes.serviceCreditCard}>
            <Typography className={classes.font}>{credit}</Typography>
          </Card>
        </Grid>
      </Grid>
    );
  } else {
    return (
      <Grid container>
        <Grid item>
          <Card className={BYOP ? classes.audienceCardBYOP : classes.audienceCard} elevation={2}>
            <Typography variant="h6" className={BYOP ? classes.fontBYOP : classes.font}>
              <b>{title}</b>
            </Typography>
          </Card>
        </Grid>
        <Grid item>
          <Card elevation={2} className={classes.creditsCard}>
            <Typography variant="body1" className={classes.font}>
              {credit}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    );
  }
};

CreditDescription.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
  credit: PropTypes.string.isRequired,
  showServices: PropTypes.bool,
  BYOP: PropTypes.bool,
};

export default CreditDescription;
