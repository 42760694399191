import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import { CircularProgress, ListItemIcon, listItemIconClasses, styled } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as AddTagIcon } from '../../../src/assets/addTagIcon.svg';
import { ReactComponent as CopyTestIcon } from '../../../src/assets/copyTestIcon.svg';
import { wevoStatus } from '../../modules/wevos/constants';

const PrimaryColorMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.primary.main,
  [`& .${listItemIconClasses.root}`]: {
    color: theme.palette.primary.main,
    minWidth: theme.spacing(4),
  },
}));

const DeleteWevoMenuItem = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.error.main,
  [`& .${listItemIconClasses.root}`]: {
    color: theme.palette.error.main,
    minWidth: theme.spacing(4),
  },
}));

const WevoOptionsMenu = ({
  wevo,
  anchorEl,
  handleArchiveClick,
  handleRestoreClick,
  handleDeleteClick,
  handleCopyTestClick,
  handleAddTagClick,
  handleClose,
  isCopying,
}) => {
  if (!wevo) {
    return null;
  }

  const isLaunchedTest =
    (wevo.status === wevoStatus['pending'] || wevo.status === wevoStatus['running']) && !wevo.isArchived;
  const canEdit = wevo.canEdit && !isLaunchedTest;

  let disabledTooltipTitle = '';
  if (!canEdit) {
    disabledTooltipTitle = isLaunchedTest
      ? 'Cannot archive or delete a launched test'
      : 'Cannot archive or delete a test that you do not own';
  }

  return (
    <Menu id="wevo-options-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
      <PrimaryColorMenuItem onClick={handleCopyTestClick}>
        <ListItemIcon>
          <CopyTestIcon />
        </ListItemIcon>
        Duplicate Test
        {isCopying && <CircularProgress size={24} style={{ marginLeft: '16px' }} />}
      </PrimaryColorMenuItem>
      <PrimaryColorMenuItem onClick={handleAddTagClick}>
        <ListItemIcon>
          <AddTagIcon style={{ width: '18px', height: '18px' }} />
        </ListItemIcon>
        Add Tag
      </PrimaryColorMenuItem>
      {!wevo.isArchived && (
        <Tooltip title={disabledTooltipTitle} disableHoverListener={canEdit}>
          <span>
            <PrimaryColorMenuItem onClick={handleArchiveClick} disabled={!canEdit}>
              <ListItemIcon>
                <ArchiveIcon fontSize="small" />
              </ListItemIcon>
              Archive
            </PrimaryColorMenuItem>
          </span>
        </Tooltip>
      )}
      {!!wevo.isArchived && (
        <PrimaryColorMenuItem onClick={handleRestoreClick}>
          <ListItemIcon>
            <UnarchiveIcon fontSize="small" />
          </ListItemIcon>
          Restore
        </PrimaryColorMenuItem>
      )}
      <Tooltip title={disabledTooltipTitle} disableHoverListener={canEdit}>
        <span>
          <DeleteWevoMenuItem onClick={handleDeleteClick} disabled={!canEdit}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete
          </DeleteWevoMenuItem>
        </span>
      </Tooltip>
    </Menu>
  );
};

export default WevoOptionsMenu;
