import { generatePath, Redirect } from 'react-router-dom';
import { Paths } from '../../../routes';
import ComparePagesDashboard from './ComparePagesDashboard';

const ComparePagesDashboardContainer = ({ wevo }) => {
  if (wevo?.pages?.length < 2) {
    return <Redirect to={generatePath(Paths.reports.sentimentMap, { wevoId: wevo?.id })} />;
  }
  const baselinePage = wevo?.pages?.find((page) => page.isBaseline);
  return <ComparePagesDashboard wevo={wevo} baselinePageId={baselinePage?.id} />;
};

export default ComparePagesDashboardContainer;
