import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import IconButton from '@mui/material/IconButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CloseIcon from '@mui/icons-material/Close';
import { green } from '@mui/material/colors';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';

import { dismissNotification } from '../modules/notification/actions';
import { getNotification } from '../modules/notification/selectors';

const snackbarStyles = makeStyles((theme) => ({
  success: {
    backgroundColor: green[600],
    margin: theme.spacing(1),
  },
  info: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1),
  },
  error: {
    backgroundColor: theme.palette.error.main,
    margin: theme.spacing(1),
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const variantIcon = {
  success: CheckCircleIcon,
  info: InfoIcon,
  error: ErrorIcon,
};

const Notification = () => {
  const notification = useSelector(getNotification);
  const dispatch = useDispatch();
  const { message, variant, position, open } = notification;
  const Icon = variantIcon[variant];
  const classes = snackbarStyles();

  const handleClose = (ev, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(dismissNotification());
  };

  const vertical = !!position && !!position.vertical ? position.vertical : 'top';
  const horizontal = !!position && !!position.horizontal ? position.horizontal : 'center';

  const SlideTransition = useCallback(
    (props) => (
      <Slide {...props} mountOnEnter unmountOnExit direction={vertical === 'bottom' ? 'up' : 'down'} />
    ),
    [vertical]
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={5000}
      open={open}
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          size="large">
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      onClose={handleClose}
      ContentProps={{ className: !!variant ? classes[variant] : classes.info }}
      TransitionComponent={SlideTransition}
    />
  );
};

export default Notification;
