import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import WEVO_Overview_Thumbnail from '../../../assets/WEVO_Overview_Thumbnail.png';
import WEVO_Demo_Thumbnail from '../../../assets/WEVO_Demo_Thumbnail.png';
import How_to_Submit_a_WEVO_Test_Thumbnail from '../../../assets/How_to_Submit_a_WEVO_Test_Thumbnail.png';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import { GettingStartedVideoOptions } from '../../../modules/wevos/constants';

const useStyles = makeStyles((theme) => ({
  linkCard: {
    cursor: 'pointer',
  },
}));

export default function VideoCards() {
  const classes = useStyles();

  const handleVideoCardClick = (videoType) => {
    switch (videoType) {
      case GettingStartedVideoOptions.SettingUpTest:
        return window.open('https://www.youtube.com/watch?v=cVpIpoRm3mQ/', '_blank', 'noopener,noreferrer');

      case GettingStartedVideoOptions.ProductOverview:
        return window.open('https://www.youtube.com/watch?v=4hpij-5YU2c/', '_blank', 'noopener,noreferrer');

      case GettingStartedVideoOptions.ResultsReview:
        return window.open('https://www.youtube.com/watch?v=a_EtTZsuVuI/', '_blank', 'noopener,noreferrer');

      default:
        return '';
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Card className={classes.linkCard}>
          <CardActionArea onClick={() => handleVideoCardClick(GettingStartedVideoOptions.SettingUpTest)}>
            <CardMedia
              component="img"
              className={classes.media}
              src={How_to_Submit_a_WEVO_Test_Thumbnail}
              title="Setting Up Test"
            />
            <CardContent>
              <Grid container spacing={1} alignItems="center" justifyContent="space-around">
                <Grid item>
                  <Typography variant="body2">Setting Up Test</Typography>
                </Grid>
                <Grid item>
                  <VideocamOutlinedIcon />
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item>
        <Card className={classes.linkCard}>
          <CardActionArea onClick={() => handleVideoCardClick(GettingStartedVideoOptions.ProductOverview)}>
            <CardMedia
              component="img"
              className={classes.media}
              src={WEVO_Overview_Thumbnail}
              title="Product Overview"
            />
            <CardContent>
              <Grid container spacing={1} alignItems="center" justifyContent="space-around">
                <Grid item>
                  <Typography variant="body2">Product Overview</Typography>
                </Grid>
                <Grid item>
                  <VideocamOutlinedIcon />
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item>
        <Card className={classes.linkCard}>
          <CardActionArea onClick={() => handleVideoCardClick(GettingStartedVideoOptions.ResultsReview)}>
            <CardMedia
              component="img"
              className={classes.media}
              src={WEVO_Demo_Thumbnail}
              title="Results Review"
            />
            <CardContent>
              <Grid container spacing={1} alignItems="center" justifyContent="space-around">
                <Grid item>
                  <Typography variant="body2">Results Review</Typography>
                </Grid>
                <Grid item>
                  <VideocamOutlinedIcon />
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
}
