import PropTypes from 'prop-types';
import AudienceFilter from './AudienceFilter';

const CustomScreenerFilter = ({ quotes, filters, onFilterChange }) => {
  return (
    <AudienceFilter
      filterName={'Advanced Segmentation'}
      items={quotes}
      itemType={'quotes'}
      advancedFilters={filters}
      onFilterChange={onFilterChange}
      isAdvancedFilter={true}
    />
  );
};

CustomScreenerFilter.propTypes = {
  quotes: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default CustomScreenerFilter;
