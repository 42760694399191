import { Box, Link } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_CODE_BLOCK,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_HR,
  ELEMENT_IMAGE,
  ELEMENT_LI,
  ELEMENT_LIC,
  ELEMENT_LINK,
  ELEMENT_MEDIA_EMBED,
  ELEMENT_OL,
  ELEMENT_PARAGRAPH,
  ELEMENT_TD,
  ELEMENT_TODO_LI,
  ELEMENT_UL,
  getAbove,
  getAndUpsertLink,
  getListItemEntry,
  getPluginType,
  isBlockAboveEmpty,
  isSelectionAtBlockStart,
  KEYS_HEADING,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  someNode,
  toggleNodeType,
  unwrapNodes,
  withProps,
} from '@udecode/plate-headless';

const DASHBOARD_FONT_SIZE = '14px';
const DEFAULT_FONT_SIZE = '16px';

const slateStyles = makeStyles((theme) => ({
  slateList: {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop: 0,
    marginBottom: 0,
  },
  slateListItem: {
    marginBottom: theme.spacing(1.5),
    fontSize: ({ isDashboard }) => (isDashboard ? DASHBOARD_FONT_SIZE : DEFAULT_FONT_SIZE),
    lineHeight: 1.5,
    '&:first-child': {
      marginTop: theme.spacing(1.5),
    },
    '&:last-child li': {
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  slateElement: {
    marginBottom: theme.spacing(1.5),
    fontSize: ({ isDashboard }) => (isDashboard ? DASHBOARD_FONT_SIZE : DEFAULT_FONT_SIZE),
    lineHeight: 1.5,
  },
}));

const resetBlockTypesCommonRule = {
  types: [ELEMENT_BLOCKQUOTE, ELEMENT_TODO_LI],
  defaultType: ELEMENT_PARAGRAPH,
};

export const VoteableSlateElements = [ELEMENT_PARAGRAPH, ELEMENT_LIC, ELEMENT_BLOCKQUOTE];
export const SlateListTypes = [ELEMENT_OL, ELEMENT_UL, ELEMENT_LI];

export const PlateEmptyNode = {
  type: ELEMENT_PARAGRAPH,
  children: [{ text: '' }],
};

export const PlateEmptyValue = [PlateEmptyNode];

export const isBlockActive = (editor, type) => someNode(editor, { match: { type } });
export const isListActive = (editor, type) =>
  !!editor?.selection && getListItemEntry(editor)?.list?.[0]?.type === type;
export const isLink = (editor) =>
  !!editor?.selection && someNode(editor, { match: { type: getPluginType(editor, ELEMENT_LINK) } });
export const toggleBlock = (editor, type) => toggleNodeType(editor, type);
export const setLinkUrl = (editor, editLinkUrl) => {
  if (!editor) {
    return;
  }
  getAndUpsertLink(editor, editLinkUrl);
};

export const removeLink = (editor) => {
  const type = getPluginType(editor, ELEMENT_LINK);

  const linkNode = getAbove(editor, {
    match: { type },
  });
  linkNode &&
    editor.selection &&
    unwrapNodes(editor, {
      at: editor.selection,
      match: { type: getPluginType(editor, ELEMENT_LINK) },
    });

  return;
};

export const getLinkUrl = (editor) => {
  const type = getPluginType(editor, ELEMENT_LINK);

  const linkNode = getAbove(editor, {
    match: { type },
  });

  if (linkNode) {
    return linkNode[0].url;
  }

  return null;
};

export const getSelectionBoundingClientRect = () => {
  const domSelection = window.getSelection();
  if (!domSelection || domSelection.rangeCount < 1) return;

  const domRange = domSelection.getRangeAt(0);

  return domRange.getBoundingClientRect();
};

export const Element = ({ attributes, children, element, nodeProps, isDashboard }) => {
  const classes = slateStyles({ isDashboard });
  switch (element.type) {
    case ELEMENT_BLOCKQUOTE:
      return (
        <blockquote {...attributes} className={classes.slateElement}>
          {children}
        </blockquote>
      );
    case ELEMENT_UL:
      return (
        <ul {...attributes} className={classes.slateList}>
          {children}
        </ul>
      );
    case ELEMENT_H1:
      return <h1 {...attributes}>{children}</h1>;
    case ELEMENT_H2:
      return <h2 {...attributes}>{children}</h2>;
    case ELEMENT_H3:
      return <h3 {...attributes}>{children}</h3>;
    case ELEMENT_H4:
      return <h4 {...attributes}>{children}</h4>;
    case ELEMENT_H5:
      return <h5 {...attributes}>{children}</h5>;
    case ELEMENT_H6:
      return <h6 {...attributes}>{children}</h6>;
    case ELEMENT_LI:
      return (
        <li {...attributes} className={classes.slateListItem}>
          {children}
        </li>
      );
    case ELEMENT_OL:
      return (
        <ol {...attributes} className={classes.slateList}>
          {children}
        </ol>
      );
    case ELEMENT_LIC:
      return <div {...attributes}>{children}</div>;
    case ELEMENT_LINK:
      return (
        <Link {...attributes} href={element.url} {...nodeProps} underline="hover">
          {children}
        </Link>
      );
    default:
      return (
        <p {...attributes} className={classes.slateElement}>
          {children}
        </p>
      );
  }
};

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <s>{children}</s>;
  }

  return <span {...attributes}>{children}</span>;
};

export const PLATE_CONFIG = {
  editableProps: {
    autoFocus: false,
    spellCheck: true,
    placeholder: 'Enter key findings here...',
  },
  components: {
    [ELEMENT_BLOCKQUOTE]: Element,
    [ELEMENT_LINK]: Element,
    [ELEMENT_H1]: Element,
    [ELEMENT_H2]: Element,
    [ELEMENT_H3]: Element,
    [ELEMENT_H4]: Element,
    [ELEMENT_H5]: Element,
    [ELEMENT_H6]: Element,
    [ELEMENT_UL]: Element,
    [ELEMENT_OL]: Element,
    [ELEMENT_PARAGRAPH]: Element,
    [ELEMENT_LI]: Element,
    [ELEMENT_UL]: Element,
    [ELEMENT_OL]: Element,
    [MARK_BOLD]: withProps(Box, { component: 'strong' }),
    [MARK_ITALIC]: withProps(Box, { component: 'em' }),
    [MARK_STRIKETHROUGH]: withProps(Box, { component: 's' }),
    [MARK_UNDERLINE]: withProps(Box, { component: 'u' }),
  },

  align: {
    inject: {
      props: {
        validTypes: [
          ELEMENT_PARAGRAPH,
          ELEMENT_H1,
          ELEMENT_H2,
          ELEMENT_H3,
          ELEMENT_H4,
          ELEMENT_H5,
          ELEMENT_H6,
        ],
      },
    },
  },
  indent: {
    inject: {
      props: {
        validTypes: [
          ELEMENT_PARAGRAPH,
          ELEMENT_H1,
          ELEMENT_H2,
          ELEMENT_H3,
          ELEMENT_H4,
          ELEMENT_H5,
          ELEMENT_H6,
          ELEMENT_BLOCKQUOTE,
          ELEMENT_CODE_BLOCK,
        ],
      },
    },
  },
  lineHeight: {
    inject: {
      props: {
        defaultNodeValue: 'normal',
        validTypes: [
          ELEMENT_PARAGRAPH,
          ELEMENT_H1,
          ELEMENT_H2,
          ELEMENT_H3,
          ELEMENT_H4,
          ELEMENT_H5,
          ELEMENT_H6,
        ],
      },
    },
  },
  resetBlockType: {
    options: {
      rules: [
        {
          ...resetBlockTypesCommonRule,
          hotkey: 'Enter',
          predicate: isBlockAboveEmpty,
        },
        {
          ...resetBlockTypesCommonRule,
          hotkey: 'Backspace',
          predicate: isSelectionAtBlockStart,
        },
      ],
    },
  },
  trailingBlock: { type: ELEMENT_PARAGRAPH },
  softBreak: {
    options: {
      rules: [
        { hotkey: 'shift+enter' },
        {
          hotkey: 'enter',
          query: {
            allow: [ELEMENT_CODE_BLOCK, ELEMENT_BLOCKQUOTE, ELEMENT_TD],
          },
        },
      ],
    },
  },
  exitBreak: {
    options: {
      rules: [
        {
          hotkey: 'mod+enter',
        },
        {
          hotkey: 'mod+shift+enter',
          before: true,
        },
        {
          hotkey: 'enter',
          query: {
            start: true,
            end: true,
            allow: KEYS_HEADING,
          },
        },
      ],
    },
  },
  selectOnBackspace: {
    options: {
      query: {
        allow: [ELEMENT_IMAGE, ELEMENT_MEDIA_EMBED, ELEMENT_HR],
      },
    },
  },
};
