import { BarController, Chart, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import truncate from 'truncate';
import { memoAreEqual } from '../../../helpers.js';
import { BAR_CHART_LABEL } from '../../../modules/report/constants.js';
import { chunkString, convertScoresToPercentages, displayLabels } from '../../../modules/report/helpers.js';
import theme from '../../../theme.js';

Chart.register(BarController, Legend, Tooltip);

const MultiplechoiceChart = React.memo((props) => {
  const { labels, scores, isDashboard, isPptComponent, width = '', height = '260px', componentId } = props;
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const percentages = convertScoresToPercentages(scores);

  const config = useMemo(
    () => ({
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: percentages,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            interaction: {
              mode: 'point',
            },
            enabled: true,
            displayColors: false,
            backgroundColor: 'rgba(97, 97, 97, .95)',
            padding: 10,
            caretPadding: 15,
            titleFont: {
              family: theme.typography.fontFamily,
              weight: theme.typography.fontWeightBold,
            },
            bodyFont: {
              family: theme.typography.fontFamily,
            },
            callbacks: {
              label: function (context) {
                const label = context.label;
                if (!label) {
                  return;
                }
                const score = scores[context.dataIndex];
                const currentPercentage = context.dataset['data'][context.dataIndex].toFixed(1);
                return `${score} respondents (${currentPercentage}%)`;
              },
              title: function (context) {
                const title = context[0].label;
                return chunkString(title, BAR_CHART_LABEL.tooltipMaxCharsInLine);
              },
            },
          },
          datalabels: {
            color: 'white',
            formatter: function (value) {
              const currentPercentage = value.toFixed(1);
              return `${currentPercentage}%`;
            },
            font: {
              weight: 'bold',
              size: 13,
            },
            display: function (context) {
              return displayLabels(context);
            },
          },
        },
        datasets: {
          bar: {
            barPercentage: 0.99,
            backgroundColor: theme.palette.primary.light,
            minBarLength: 3,
          },
        },
        responsive: !isPptComponent,
        maintainAspectRatio: false,
        indexAxis: 'y',
        scales: {
          x: {
            display: false,
          },
          y: {
            display: true,
            grid: {
              display: false,
              drawBorder: true,
            },
            ticks: {
              callback: function (value, index, values) {
                const originalLabel = labels[index] || '';
                const maxLineLength = isDashboard
                  ? BAR_CHART_LABEL.dashboardMaxCharsInLine
                  : BAR_CHART_LABEL.maxCharsInLine;

                if (isDashboard) {
                  const truncatedLabel = truncate(originalLabel, BAR_CHART_LABEL.dashboardMaxLabelChars);
                  return chunkString(truncatedLabel, maxLineLength);
                }
                return chunkString(originalLabel, maxLineLength);
              },
            },
          },
        },
      },
    }),
    [scores, labels, percentages, isDashboard, isPptComponent]
  );

  useEffect(() => {
    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [config]);

  return <canvas id={componentId} height={height} width={width} ref={canvasRef}></canvas>;
}, memoAreEqual);

MultiplechoiceChart.propTypes = {
  labels: PropTypes.array.isRequired,
  scores: PropTypes.array.isRequired,
  isDashboard: PropTypes.bool,
  isPptComponent: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  componentId: PropTypes.string,
};

export default MultiplechoiceChart;
