import { useTheme } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { ReactComponent as ExpectationsIcon } from '../../../../assets/expectations.svg';
import { ComponentType } from '../../../../modules/wevos/constants';
import { hasComponent, isClassicComparePages } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';
import useExpectations from '../../hooks/useExpectations';

const ExpectationsSummary = (props) => {
  const { wevo, page, selectedAssetNum, isLimitedReport } = props;

  const theme = useTheme();
  const styles = {
    card: {
      borderRadius: '20px',
      height: '100%',
    },
    cardContent: {
      display: 'flex',
      height: '100%',
    },
    expectationsIconGrid: {
      marginRight: theme.spacing(2),
    },
    expectationsIcon: {
      color: theme.palette.primary.main,
      width: '25px',
      height: '25px',
      marginTop: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      marginLeft: theme.spacing(0.25),
      marginBottom: theme.spacing(-0.25),
    },
    link: {
      color: theme.palette.primary.main,
      fontSize: '12px',
      textAlign: 'end',
    },
    rightArrow: {
      marginLeft: theme.spacing(1),
      verticalAlign: 'bottom',
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
    infoIcon: {
      marginLeft: theme.spacing(1),
      verticalAlign: 'bottom',
      color: grey[600],
    },
    expectationsContainer: {
      alignContent: 'space-between',
      minHeight: '280px',
    },
    icon: {
      backgroundColor: grey[100],
      borderRadius: '50%',
      padding: '3px',
    },
    iconButton: {
      backgroundColor: grey[100],
    },
    header: {
      overflowWrap: 'break-word',
      whiteSpace: 'wrap',
    },
    subText: {
      color: theme.palette.primary.dark,
    },
    tooltipText: {
      fontSize: '12px',
      marginBottom: '8px',
    },
    tooltip: {
      fontSize: '12px',
    },
    expectationsText: {
      marginLeft: theme.spacing(2),
    },
    percentagesContainer: {
      marginLeft: theme.spacing(2),
      flexWrap: 'nowrap',
    },
    percentages: {
      color: theme.palette.primary.main,
      fontSize: '13px',
    },
    theme: {
      fontSize: '13px',
    },
    underline: {
      textDecoration: 'none',
    },
  };

  const { data: expectations, isLoading } = useExpectations({ wevoId: wevo?.id, pageId: page?.id });

  const numSelections = expectations?.map(
    (expectation) => expectation.numMet + expectation.numSomewhat + expectation.numUnmet
  );
  const totalSelections = numSelections?.reduce((a, b) => a + b, 0);
  const percentages = numSelections?.map((numSelection) =>
    ((numSelection / totalSelections) * 100).toFixed(0)
  );

  const expectationsPath =
    wevo?.useVersionedDiagnostics && isClassicComparePages(wevo)
      ? generatePath(Paths.reports.expectationsPage, {
          wevoId: wevo?.id,
          pageNum: selectedAssetNum,
        })
      : generatePath(Paths.reports.expectations, {
          wevoId: wevo?.id,
        });

  if (!hasComponent(wevo, ComponentType.Expectation)) {
    return (
      <Card sx={styles.card} elevation={4}>
        <CardContent sx={styles.cardContent}>
          <Box m="auto">
            <Typography variant="body2">This study does not include Expectations</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={styles.card} elevation={4}>
      <CardContent sx={styles.cardContent}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <Grid container spacing={2} sx={styles.expectationsContainer}>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item container justifyContent="space-between" sx={styles.noWrap} xs={12}>
                  <Grid item container justifyContent="flex-start" sx={styles.noWrap}>
                    <Grid
                      item
                      sx={styles.expectationsIconGrid}
                      aria-labelledby="expectations-heading expectations-sub-heading expectations-graph-content">
                      {isLimitedReport ? (
                        <Box sx={styles.icon}>
                          <ExpectationsIcon
                            fill={theme.palette.primary.main}
                            style={styles.expectationsIcon}
                          />
                        </Box>
                      ) : (
                        <IconButton size="small" sx={styles.iconButton}>
                          <Link to={expectationsPath} component={RouterLink}>
                            <ExpectationsIcon
                              fill={theme.palette.primary.main}
                              style={styles.expectationsIcon}
                            />
                          </Link>
                        </IconButton>
                      )}
                    </Grid>
                    <Grid item sx={styles.header}>
                      <Typography variant="h5" id="expectations-heading">
                        Expectations
                      </Typography>
                      <Typography variant="caption" id="expectations-sub-heading" sx={styles.subText}>
                        See how your journey addressed the visitors' biggest hopes and concerns
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Tooltip
                      title={
                        <div>
                          <Typography gutterBottom sx={styles.tooltipText}>
                            You will see here the hopes and concerns that respondents expected your page or
                            experience to address.
                          </Typography>
                          <Typography gutterBottom sx={styles.tooltip}>
                            The percentage listed next to these expectations reflects the number of respondents
                            who listed that particular hope or concern. Please note that because respondents
                            are able to list more than one hope or concern, the percentages may not sum to
                            100%.
                          </Typography>
                        </div>
                      }
                      placement="left-start">
                      <InfoIcon color="primary" fontSize="small" sx={styles.infoIcon} />
                    </Tooltip>
                  </Grid>
                </Grid>
                <Grid item container spacing={2} sx={styles.expectationsText} xs={12}>
                  {expectations?.slice(0, 5).map((expectation, index) => {
                    return (
                      <Grid
                        container
                        item
                        spacing={2}
                        alignItems="center"
                        justifyContent="flex-start"
                        key={index}
                        sx={styles.percentagesContainer}>
                        <Grid item xs={2}>
                          <Typography variant="h5" display="inline" sx={styles.percentages}>
                            {`${percentages[index]} %`}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Typography variant="h5" display="inline" sx={styles.theme}>
                            {expectation.theme}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
                <Grid item id="expectations-graph-content" sx={{ display: 'none', maxHeight: '0px' }}>
                  {`Graph content: ${String(
                    expectations
                      ?.slice(0, 5)
                      .map((expectation, index) => `${percentages[index]}% ${expectation.theme}`)
                  )}`}
                </Grid>
              </Grid>
            </Grid>
            {!isLimitedReport && (
              <Grid item xs={12}>
                <Link to={expectationsPath} component={RouterLink} sx={styles.underline}>
                  <Typography variant="body2" sx={styles.link}>
                    See Expectations
                    <ChevronRightIcon fontSize="small" sx={styles.rightArrow} />
                  </Typography>
                </Link>
              </Grid>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default ExpectationsSummary;
