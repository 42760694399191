import _ from 'lodash';
import { useMutation, useQuery } from 'react-query';
import axios from '../api';

const validatePrototypes = async ({ figmaPrototypeUrl }) => {
  const { data } = await axios({
    url: `/api/v2/prototypes/validate`,
    method: 'PUT',
    data: { context: { figmaPrototypeUrl } },
  });
  return data;
};

export default function useValidatePrototypes() {
  return useMutation(validatePrototypes);
}

const checkPrototypeURL = async ({ queryKey: [key, { url }] }) => {
  const result = await validatePrototypes({ figmaPrototypeUrl: url });
  return Boolean(!_.isNil(result));
};

export function useCheckPrototypeURL(url, options) {
  return useQuery([`checkPrototypeURL/${url}`, { url }], checkPrototypeURL, {
    notifyOnChangeProps: 'tracked',
    ...(options ?? {}),
  });
}
