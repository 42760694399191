import { Box, Card, CardContent, CardHeader, CircularProgress, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { WevoType } from '../../../../modules/wevos/constants';
import { filterQuotes } from '../../../../modules/wevos/quotes';
import { Paths } from '../../../../routes';
import useFetchPageHeatmapSummary from '../../../reports/hooks/useFetchPageHeatmapSummary';
import useFetchStepHeatmapSummary from '../../../reports/hooks/useFetchStepHeatmapSummary';
import Heatmap from '../../components/Heatmap';

const styles = makeStyles((theme) => ({
  card: {
    height: '100%',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  sentimentMapHeader: {
    color: 'white',
    fontSize: '13px',
  },
  heatMap: { color: theme.palette.primary.main, fontSize: '14px' },
  mapGrid: {
    marginTop: theme.spacing(1),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    textAlign: 'end',
    textDecoration: 'none',
  },
  rightArrow: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  linkGrid: { marginBottom: theme.spacing(-1) },
}));

const renderHeatmap = (image, points, handleRegionChange) => {
  if (!points || !image) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Heatmap
        image={image}
        data={points}
        onSelectRegionChange={handleRegionChange}
        customSize={true}
        customHeight={180}
        customWidth={180}
      />
    );
  }
};

const ClusterTypes = {
  Likes: 'MostAppeal',
  Dislikes: 'LeastAppeal',
};

const AssetSentimentMap = (props) => {
  const { wevo, page, asset, index, selectedAssetNum, isLimitedReport } = props;
  const classes = styles();

  const [points, setPoints] = useState(null);
  const heatMap = asset?.images?.heatmap || asset?.images?.heatmap;

  const { data: pageHeatMapPoints, isSuccess: isSuccessPageHeatMap } = useFetchPageHeatmapSummary({
    wevoId: wevo?.id,
    pageId: page?.id,
  });
  const { data: stepHeatMapPoints, isSuccess: isSuccessStepHeatMap } = useFetchStepHeatmapSummary({
    wevoId: wevo?.id,
    stepId: wevo.type === WevoType.Classic ? null : asset?.id,
  });

  const likePoints = useMemo(
    () => (!_.isNil(points) ? filterQuotes(points, { cluster: ClusterTypes.Likes }) : null),
    [points]
  );
  const dislikePoints = useMemo(
    () => (!_.isNil(points) ? filterQuotes(points, { cluster: ClusterTypes.Dislikes }) : null),
    [points]
  );

  useEffect(() => {
    if (wevo.type === WevoType.Journey) {
      if (isSuccessStepHeatMap && stepHeatMapPoints.length > 0) {
        setPoints(stepHeatMapPoints.filter((point) => !_.isNil(point.x) && !_.isNil(point.y)));
      }
    } else {
      if (isSuccessPageHeatMap && pageHeatMapPoints.length > 0) {
        setPoints(pageHeatMapPoints.filter((point) => !_.isNil(point.x) && !_.isNil(point.y)));
      }
    }
  }, [wevo?.type, isSuccessPageHeatMap, isSuccessStepHeatMap, pageHeatMapPoints, stepHeatMapPoints]);

  const path =
    wevo.type === WevoType.Classic ? Paths.reports.sentimentMapPage : Paths.reports.sentimentMapPageStep;

  const data =
    wevo.type === WevoType.Classic
      ? { wevoId: wevo?.id, pageNum: selectedAssetNum }
      : { wevoId: wevo?.id, pageNum: 1, stepNum: index + 1 };

  return (
    <Card className={classes.card} elevation={4}>
      <CardHeader
        className={classes.cardHeader}
        title={<div className={classes.sentimentMapHeader}>{`Page ${index + 1} `}</div>}
      />
      <CardContent>
        <Grid container spacing={2} justifyContent="space-evenly">
          <Grid item>
            <Typography variant="h5" className={classes.heatMap}>
              Likes
            </Typography>
            <Grid item className={classes.mapGrid}>
              {renderHeatmap(heatMap, likePoints, () => {})}
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant="h5" className={classes.heatMap}>
              Dislikes
            </Typography>
            <Grid item className={classes.mapGrid}>
              {renderHeatmap(heatMap, dislikePoints, () => {})}
            </Grid>
          </Grid>
        </Grid>
        {!isLimitedReport && (
          <Grid item xs={12} className={classes.linkGrid}>
            <Link to={generatePath(path, data)} className={classes.link}>
              <Typography variant="body2" className={classes.link}>
                See Sentiment Map
                <ChevronRightIcon fontSize="small" className={classes.rightArrow} />
              </Typography>
            </Link>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default AssetSentimentMap;
