import { Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { IntakePrototypeImportStages, TestTypes } from '../../../../../modules/intake/constants';
import FigmaPrototypeCard from './FigmaPrototypeCard';
import DebouncedInput from '../../DebouncedInput';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    verticalAlign: 'bottom',
    color: grey[600],
  },
  invisibleIcon: {
    fontSize: '14px',
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.25),
  },
  div: { position: 'relative' },
  spinner: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  importButton: {
    borderRadius: 12,
    position: 'absolute',
    top: '10px',
    right: '8px',
  },
  helperText: {
    fontSize: '12px',
    color: 'grey',
    marginTop: '8px',
    marginLeft: '14px',
  },
  textField: {
    '& .MuiInputBase-input': {
      maxWidth: ({ canRunUsability }) => (canRunUsability ? 590 : 440),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

function TestTypeJourneyForm({
  journeyStartUrl,
  handleJourneyStartUrlInput,
  importPrototype,
  deletePrototype,
  isFigmaURL,
  prototypeImportStage,
  hasAuthenticExperience,
  figmaPrototype,
  isExperienceSetupComplete,
  setUpPrototype,
  testType,
  canRunUsability,
}) {
  const classes = useStyles({ canRunUsability });

  return (
    <>
      <Typography variant="h5">
        {testType === TestTypes.Usability ? 'Experience Start (Desktop only)' : 'Journey Prototype'}
      </Typography>
      <Typography py={1} style={{ fontWeight: 400 }} gutterBottom>
        If using a prototype or live site for your test, please insert the URL below.
      </Typography>
      {testType === TestTypes.Usability && (
        <Typography py={1} style={{ fontWeight: 400, marginTop: '-20px' }} gutterBottom>
          Note: In order to access usability metrics and clickpath, your prototype must be a Figma file.
        </Typography>
      )}
      {hasAuthenticExperience ? (
        <div className={classes.div}>
          {figmaPrototype ? (
            <div>
              <FigmaPrototypeCard
                url={journeyStartUrl}
                figmaPrototype={figmaPrototype}
                deletePrototype={deletePrototype}
              />
              {testType !== TestTypes.Usability && (
                <Typography variant="body1" className={classes.helperText}>
                  Note: A Journey should take less than 3 minutes to complete.
                </Typography>
              )}
            </div>
          ) : (
            <div>
              <DebouncedInput
                value={journeyStartUrl}
                onChange={handleJourneyStartUrlInput}
                debounceMs={500}
                renderInput={({ value, onChange }) => (
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={value}
                    placeholder="Add live or prototype URL"
                    onChange={onChange}
                    helperText={
                      testType !== TestTypes.Usability &&
                      'Note: A Journey should take less than 3 minutes to complete.'
                    }
                    className={classes.textField}
                    disabled={prototypeImportStage === IntakePrototypeImportStages.Importing}
                  />
                )}
              />
              {isFigmaURL && (
                <Box>
                  {prototypeImportStage === IntakePrototypeImportStages.Importing ? (
                    <CircularProgress size={25} className={classes.spinner} />
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={importPrototype}
                      className={classes.importButton}>
                      Import
                    </Button>
                  )}
                </Box>
              )}
            </div>
          )}
        </div>
      ) : (
        <DebouncedInput
          value={journeyStartUrl}
          onChange={handleJourneyStartUrlInput}
          debounceMs={500}
          renderInput={({ value, onChange }) => (
            <TextField
              fullWidth
              variant="outlined"
              value={value}
              placeholder="Add live or prototype URL"
              onChange={onChange}
              helperText="Note: A Journey should take less than 3 minutes to complete."
            />
          )}
        />
      )}
      {hasAuthenticExperience &&
        [
          IntakePrototypeImportStages.GeneratingImages,
          IntakePrototypeImportStages.GeneratingComponents,
        ].includes(prototypeImportStage) && (
          <Box py={2}>
            <Grid container textAlign="center">
              <Grid item xs={12}>
                <Box>
                  <Typography fontWeight={600}>Almost done!</Typography>
                </Box>
                <Box mt={3} mb={2}>
                  <CircularProgress />
                  {prototypeImportStage === IntakePrototypeImportStages.GeneratingImages && (
                    <Typography>Downloading images...</Typography>
                  )}
                  {prototypeImportStage === IntakePrototypeImportStages.GeneratingComponents && (
                    <Typography>Generating assets...</Typography>
                  )}
                </Box>
                <Box>
                  <Typography>Please don't refresh the page. This may take a minute.</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
      {figmaPrototype &&
        !isExperienceSetupComplete &&
        [IntakePrototypeImportStages.Ready].includes(prototypeImportStage) && (
          <Box pt={2}>
            <Grid container alignContent="center">
              <Grid item xs={9}>
                <Box pr={1}>
                  <Typography color="error">
                    Incomplete setup detected. Please click the button to finish setting up or reimport your
                    prototype.
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Button
                  size="sm"
                  variant="contained"
                  sx={{ borderRadius: '10px' }}
                  onClick={() => setUpPrototype(figmaPrototype)}>
                  Finish Setup
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Box px={1}>
                  <Typography color="error" variant="caption">
                    Note: This process can take a few minutes. Please don't refresh your browser while setup is
                    in progress.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
    </>
  );
}

TestTypeJourneyForm.propTypes = {
  journeyStartUrl: PropTypes.string.isRequired,
  handleJourneyStartUrlInput: PropTypes.func.isRequired,
  importPrototype: PropTypes.func.isRequired,
  deletePrototype: PropTypes.func.isRequired,
  isFigmaURL: PropTypes.bool,
  prototypeImportStage: PropTypes.string.isRequired,
  hasAuthenticExperience: PropTypes.bool,
  figmaPrototype: PropTypes.object,
  isExperienceSetupComplete: PropTypes.bool,
  setUpPrototype: PropTypes.func,
  testType: PropTypes.string,
  canRunUsability: PropTypes.bool,
};

export default TestTypeJourneyForm;
