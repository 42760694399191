import axios from '../../api';
import { from } from 'rxjs';

export const fetchUser = () => {
  const requestConfig = {
    url: `/api/v2/users/me`,
    method: 'GET',
  };
  return from(axios(requestConfig));
};

export const updateUser = (payload) => {
  const requestConfig = {
    url: `/api/v2/users/me`,
    method: 'PUT',
    data: payload,
  };
  return from(axios(requestConfig));
};

export const changePassword = (payload) => {
  const requestConfig = {
    url: `/api/v2/password/change`,
    method: 'POST',
    data: payload,
  };
  return from(axios(requestConfig));
};
