import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchChatMessageStatus = async ({ queryKey: [key, { sessionId, segmentId }] }) => {
  if (!segmentId) {
    return null;
  }

  const response = await axios({
    url: `/api/v2/automated-insight-sessions/${sessionId}/segments/${segmentId}/messages/status`,
    method: 'GET',
  });
  return response?.data ?? {};
};

export default function useFetchChatMessageStatus({ sessionId, segmentId }, options) {
  return useQuery(
    ['automatedInsightsChatMessageStatus', { sessionId, segmentId }],
    fetchChatMessageStatus,
    options
  );
}
