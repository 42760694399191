import { Box, Button, Grid, Paper } from '@mui/material';
import {
  createAlignPlugin,
  createBlockquotePlugin,
  createBoldPlugin,
  createDeserializeMdPlugin,
  createExitBreakPlugin,
  createHeadingPlugin,
  createImagePlugin,
  createIndentPlugin,
  createItalicPlugin,
  createJuicePlugin,
  createLinkPlugin,
  createListPlugin,
  createMediaEmbedPlugin,
  createParagraphPlugin,
  createPlateEditor,
  createPlugins,
  createResetNodePlugin,
  createSelectOnBackspacePlugin,
  createSoftBreakPlugin,
  createStrikethroughPlugin,
  createTrailingBlockPlugin,
  createUnderlinePlugin,
} from '@udecode/plate-headless';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Prompt } from 'react-router-dom';
import { Transforms } from 'slate';
import { v4 as uuidv4 } from 'uuid';
import {
  PlateEmptyValue,
  PLATE_CONFIG,
  SlateListTypes,
  VoteableSlateElements,
} from '../../../../modules/plate/constants';
import { isAuthenticated } from '../../../../modules/user/helpers';
import { isSlateEmptyElement } from '../../../../modules/wevos/helpers';
import { EditHyperlinkUrl } from '../../../plate/HyperlinkDialog';
import PlateEditor from '../../../plate/PlateEditor';
import useSaveUserKeyFindings from '../../hooks/useSaveUserKeyFindings';

const removeId = (editor, path) => {
  Transforms.transform(editor, { type: 'set_node', path, newProperties: { id: null } });
};

const populateId = (editor, path) => {
  Transforms.transform(editor, { type: 'set_node', path, newProperties: { id: uuidv4() } });
};

const populateIds = (editor, elements, parentPath) => {
  elements.forEach((element, index) => {
    const path = parentPath ? [...parentPath, index] : [index];
    if (element.id && isSlateEmptyElement(element)) {
      removeId(editor, path);
    }
    if (!element.id && VoteableSlateElements.includes(element.type) && !isSlateEmptyElement(element)) {
      populateId(editor, path);
    } else if (SlateListTypes.includes(element.type)) {
      populateIds(editor, element.children, path);
    }
  });
};

const plugins = createPlugins([
  createParagraphPlugin(),
  createBlockquotePlugin(),
  createHeadingPlugin(),
  createImagePlugin(),
  createLinkPlugin({ options: { getLinkUrl: EditHyperlinkUrl } }),
  createListPlugin(),
  createMediaEmbedPlugin(),
  createAlignPlugin(PLATE_CONFIG.align),
  createBoldPlugin(),
  createItalicPlugin(),
  createUnderlinePlugin(),
  createStrikethroughPlugin(),
  createIndentPlugin(PLATE_CONFIG.indent),
  createResetNodePlugin(PLATE_CONFIG.resetBlockType),
  createSoftBreakPlugin(PLATE_CONFIG.softBreak),
  createExitBreakPlugin(PLATE_CONFIG.exitBreak),
  createTrailingBlockPlugin(PLATE_CONFIG.trailingBlock),
  createSelectOnBackspacePlugin(PLATE_CONFIG.selectOnBackspace),
  createDeserializeMdPlugin(),
  createJuicePlugin(),
]);

const KeyFindingsEditor = ({ wevoId, readOnly, setReadOnly, userKeyFindings, header, setHeader }) => {
  const [plateEditor] = useState(() => createPlateEditor({ plugins, components: PLATE_CONFIG.components }));

  const { mutate: saveUserKeyFindings } = useSaveUserKeyFindings();

  const handleCancelClick = () => {
    plateEditor.children = userKeyFindings.finding || PlateEmptyValue;
    setReadOnly(true);
    setHeader(userKeyFindings.header || '');
  };

  const handleSaveEditClick = () => {
    if (!readOnly) {
      populateIds(plateEditor, plateEditor.children);
      saveUserKeyFindings(
        { wevoId, findings: plateEditor.children, header },
        {
          onError: () => {
            plateEditor.children = userKeyFindings.finding || PlateEmptyValue;
            setHeader(userKeyFindings.header || '');
          },
        }
      );
    }
    setReadOnly((readOnly) => !readOnly);
  };

  return (
    <>
      <Paper
        style={{ paddingRight: 32, paddingLeft: 32, paddingTop: 16, paddingBottom: 16, borderRadius: '20px' }}
        elevation={4}>
        <PlateEditor
          editor={plateEditor}
          editorId={`user-key-findings-editor-wevo-${wevoId}`}
          initialValue={userKeyFindings.finding || PlateEmptyValue}
          readOnly={readOnly}
        />
      </Paper>
      <Box py={1} />
      {isAuthenticated() && (
        <Grid container justifyContent="flex-end" spacing={2}>
          {!readOnly && (
            <Grid item>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </Grid>
          )}
          <Grid item>
            <Button variant="contained" onClick={handleSaveEditClick}>
              {readOnly ? 'Edit' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      )}
      <Prompt
        when={!readOnly}
        message={'Are you sure you want to leave? You will lose any unsaved changes to you key findings.'}
      />
    </>
  );
};

KeyFindingsEditor.propTypes = {
  wevoId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  setReadOnly: PropTypes.func.isRequired,
  userKeyFindings: PropTypes.object,
};

export default KeyFindingsEditor;
