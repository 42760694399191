import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '15px',
  },
  cardHeader: {
    paddingLeft: theme.spacing(3),
  },
  cardContent: {
    paddingTop: 0,
    paddingLeft: theme.spacing(3),
  },
  audienceDescription: {
    whiteSpace: 'pre-line',
  },
}));

/**
 * Shows card with custom audience request.
 *
 * Props:
 *  customAudience: Custom audience that customer is asking for in the test.
 *  onClick?: Handler when the card is clicked. If not set, card will not be clickable.
 *  onDelete?: Handler when the card is deleted. If not set, delete button will not be shown.
 */
export default function CustomAudienceReviewCard(props) {
  const { customAudience, onClick, onDelete } = props;

  const classes = useStyles();

  const handleOnDeleteClick = (event) => {
    event.stopPropagation();
    onDelete(event);
  };

  const header = (
    <CardHeader
      className={classes.cardHeader}
      action={
        onDelete && (
          <IconButton aria-label="settings" onClick={handleOnDeleteClick} size="large">
            <DeleteIcon />
          </IconButton>
        )
      }
      title="Custom Audience"
    />
  );

  const content = (
    <CardContent className={classes.cardContent}>
      <Typography className={classes.audienceDescription} variant="caption">
        {customAudience}
      </Typography>
    </CardContent>
  );

  if (isFunction(onClick)) {
    return (
      <Card>
        <CardActionArea onClick={onClick}>
          {header}
          {content}
        </CardActionArea>
      </Card>
    );
  } else {
    return (
      <Card className={classes.card} elevation={4}>
        {header}
        {content}
      </Card>
    );
  }
}

CustomAudienceReviewCard.propTypes = {
  customAudience: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};
