import { Box, Card, CardActionArea, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import DoughnutScoreGraph from '../components/DoughnutScoreGraph';

const styles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    borderRadius: '20px',
    // transparent border prevents elements from shifting when colored border appears upon card selection
    border: 3,
    borderColor: 'transparent',
    borderStyle: 'solid',
  },
  cardSelected: {
    borderWidth: 3,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  scoreContainer: {
    margin: '0 auto',
    height: '50%',
    width: '50%',
  },
  titleContainer: {
    textAlign: 'center',
  },
}));

const DiagnosticsScoreCard = (props) => {
  const classes = styles(props);

  const { diagnostic, isSelected, onClick } = props;
  const score = useMemo(() => Math.round(diagnostic.score * 100), [diagnostic]);

  let checkbox;
  if (isSelected) {
    checkbox = <CheckCircleOutlineIcon color="primary" />;
  } else {
    checkbox = <RadioButtonUncheckedIcon color="primary" />;
  }

  return (
    <Card elevation={4} className={classNames(classes.root, { [classes.cardSelected]: isSelected })}>
      <CardActionArea className={classes.content} onClick={onClick}>
        <CardContent>
          <Box display="flex" justifyContent="flex-end">
            {checkbox}
          </Box>

          <Box px={2} pb={2} className={classes.scoreContainer} justifyContent="center">
            <DoughnutScoreGraph score={score} height={60} width={60} />
          </Box>
          <Box className={classes.titleContainer}>
            <Typography variant="h5">{diagnostic.name}</Typography>
          </Box>
          <Box p={2}>
            <Typography variant="caption">{diagnostic.description}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

DiagnosticsScoreCard.propTypes = {
  diagnostic: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default DiagnosticsScoreCard;
