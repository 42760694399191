import { Box, CircularProgress, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { BarController, Chart } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { memoAreEqual } from '../../../helpers';
import { BarChartBenchmarksFontSizes } from '../../../modules/report/constants';
import { chunkString } from '../../../modules/report/helpers.js';
import theme from '../../../theme';

Chart.register(BarController, annotationPlugin);

const legendStyles = makeStyles((theme) => ({
  benchmarkLine: {
    width: '30px',
    height: '0px',
    border: '1px solid rgba(0, 0, 0, 1.0)',
  },
  benchmarkLabel: {
    color: 'rgba(112, 112, 112, 1.0)',
    verticalAlign: 'text-bottom',
  },
}));

export const BarChartBenchmarkLegend = ({ benchmarkLabel, ...rest }) => {
  const classes = legendStyles(rest);
  return (
    <Grid container justifyContent="flex-end" alignItems="center" alignContent="center">
      <Grid item>
        <Box mr={2}>
          <div className={classes.benchmarkLine}></div>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="caption" component="span" className={classes.benchmarkLabel}>
          {benchmarkLabel}
        </Typography>
      </Grid>
    </Grid>
  );
};

const chartStyles = makeStyles((theme) => ({
  chartContainer: { position: 'relative', width: (props) => props.width },
  benchmarkLegendContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    position: 'absolute',
    top: theme.spacing(-2),
    right: 0,
    background: 'rgba(255, 255, 255, 1.0)',
  },
}));

/**
 * Converts labels and datasets into an aria-label for the chart
 */
function formatAriaLabel(labels, datasets) {
  // Check if datasets and labels have the same number of elements
  if (datasets.length === 0 || datasets[0].length !== labels.length) {
    return 'Bar chart';
  }

  return datasets
    .map((dataset, index) => {
      const dataLabels = dataset
        .map((value, dataIndex) => {
          return `${labels[dataIndex]} ${value}%`;
        })
        .join(', ');

      return `dataset ${index + 1}: ${dataLabels}`;
    })
    .join(', ');
}

const BarChartWithBenchmarks = React.memo((props) => {
  const {
    labels,
    datasets,
    barColor,
    backgroundColor,
    benchmarks,
    benchmarkThresholds,
    onBarSelected,
    benchmarkLabel,
    chartLabelSize,
    dataLabels,
    hoverBackgroundColor,
    tooltipTitle,
    tooltipBeforeLabel,
    tooltipLabel,
    tooltipLabelDescription,
    width = '85%',
    height = '450px',
    displayLegend = true,
    hideDataLabels,
    fontSize,
    yShowAxisBorder = true,
    barDisplayOptions = { barPercentage: 0.8, categoryPercentage: 0.7 },
    componentId,
    hasBenchmarks = true,
  } = props;
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  // adjust settings for smaller screens
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('xl'));
  let shouldDisplayLegend = hasBenchmarks && displayLegend;

  let chartTickLabelSize = chartLabelSize || BarChartBenchmarksFontSizes.Large;

  // if a constant chartLabelSize is not specified by the component props, then make chartTickLabelSize responseive
  if (isSmallDevice) {
    chartTickLabelSize = chartLabelSize || BarChartBenchmarksFontSizes.Small;
    shouldDisplayLegend = false;
  } else if (isMediumDevice) {
    chartTickLabelSize = chartLabelSize || BarChartBenchmarksFontSizes.Medium;
  }

  const classes = chartStyles({ width });

  const buildBenchmarkAnnotations = useCallback(() => {
    if (!hasBenchmarks) {
      return [];
    }
    return benchmarks
      .map((benchmarkValue, idx) => {
        if (benchmarkValue === null || benchmarkValue === undefined) {
          return null;
        }

        return {
          type: 'line',
          // x-segments are centered on the x-axis about their index value +/- 0.5
          // if there are multiple datasets, additional compensation must be added to account for the space between bars
          xMin: idx - 0.3 - (datasets.length - 1) * 0.03,
          xMax: idx + 0.3 + (datasets.length - 1) * 0.03,
          yMin: benchmarkValue,
          yMax: benchmarkValue,
          borderColor: 'rgba(0, 0, 0, 1.0)',
          borderWidth: 1.5,
        };
      })
      .filter((benchmarkConfig) => benchmarkConfig !== null)
      .reduce((acc, cur, idx) => {
        acc[labels[idx]] = cur;
        return acc;
      }, {});
  }, [labels, benchmarks, datasets.length, hasBenchmarks]);

  const onClickHandler = useCallback(
    (evt) => {
      const nearestElements = evt.chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true);

      if (nearestElements.length === 1 && typeof onBarSelected === 'function') {
        const datasetIdx = nearestElements[0].datasetIndex;
        const dataIdx = nearestElements[0].index;

        const selectedlabel = evt.chart.data.labels[dataIdx];
        const selectedValue = evt.chart.data.datasets[datasetIdx].data[dataIdx];

        onBarSelected(selectedlabel, selectedValue, dataIdx, datasetIdx);
      }
    },
    [onBarSelected]
  );

  const defaultBarColor = useCallback(
    (datum, idx) => {
      const benchmark = benchmarks[idx];
      const benchmarkPoints = benchmarkThresholds[idx];

      // no benchmark is present, so we don't have enough information to render the bar green or red
      // Lite wevos don't use benchmarks
      if (benchmark === null || benchmarkPoints === null || !hasBenchmarks) {
        return theme.palette.grey[300];
      }

      const diff = datum - benchmark;

      if (diff >= benchmarkPoints) {
        return 'rgba(74, 159, 67, 1.0)';
        // benchmark threshold determines how far the value must fall short of the benchmark
        // to be colored red.
      } else if (diff <= -benchmarkPoints) {
        return 'rgba(204, 74, 68, 1.0)';
      } else {
        // bar color is grey if it is within +/- benchmark threshold
        return theme.palette.grey[300];
      }
    },
    [benchmarks, benchmarkThresholds, hasBenchmarks]
  );

  const isValidBenchmarks = benchmarks.every(
    (benchmark) => benchmark === null || typeof benchmark === 'number'
  );

  const config = useMemo(
    () => ({
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets.map((data, datasetIdx) => {
          return {
            data: data,
            hoverBackgroundColor: hoverBackgroundColor,
            backgroundColor: backgroundColor
              ? backgroundColor
              : data.map((datum, idx) => {
                  return (barColor || defaultBarColor)(datum, idx, data, datasetIdx, benchmarks);
                }),
            ...barDisplayOptions,
          };
        }),
      },
      plugins: [ChartDataLabels],
      options: {
        animation: {
          duration: 0, // general animation time, for performance
        },
        layout: {
          padding: { top: 20 },
        },
        onClick: onClickHandler,
        onHover: function (e) {
          // show pointer cursor when hovering over a bar
          const intersections = this.getElementsAtEventForMode(
            e,
            'index',
            { axis: 'x', intersect: true },
            false
          );

          if (intersections.length) {
            // cursor is hovering over a bar
            e.native.target.style.cursor = 'pointer';
          } else {
            // otherwise set to default
            e.native.target.style.cursor = 'default';
          }
        },
        maintainAspectRatio: false,
        responsive: true,
        cutout: '80%',
        scales: {
          x: {
            ticks: {
              font: {
                family: theme.typography.fontFamily,
                size: BarChartBenchmarksFontSizes[fontSize] || chartTickLabelSize,
                weight: theme.typography.fontWeightBold,
                color: 'rgba(112, 112, 112, 1.0)',
              },
              callback: function (value, index, values) {
                const originalLabel = labels[index] || '';
                const chunked = chunkString(originalLabel, 14);
                return chunked;
              },
              autoSkip: false,
            },
            grid: {
              display: false,
            },
          },
          y: {
            suggestedMax: 40,
            ticks: {
              callback: function (value, index, values) {
                if (yShowAxisBorder || isSmallDevice) {
                  return value;
                }

                // returning an empty string effectively hides the y-axis tick label
                // while also preserving the tight layout of showing the axis border
                return '';
              },
            },
            grid: {
              borderColor: 'transparent',
            },
          },
        },
        legend: {
          display: false,
        },
        plugins: {
          annotation: {
            annotations: buildBenchmarkAnnotations(),
          },
          datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: function (value, context) {
              const dataLabel = dataLabels?.[context.datasetIndex]?.[context.dataIndex];
              return dataLabel ? dataLabel : null;
            },
            font: {
              size: chartTickLabelSize,
            },
            color: hideDataLabels ? '' : Chart.defaults.color,
          },
          legend: {
            display: false,
          },
          tooltip: {
            interaction: {
              mode: 'point',
            },
            enabled: true,
            displayColors: false,
            xAlign: 'center',
            yAlign: 'top',
            position: 'nearest',
            backgroundColor: 'rgba(97, 97, 97, .95)',
            padding: 10,
            caretPadding: 15,
            titleFont: {
              family: theme.typography.fontFamily,
              weight: theme.typography.fontWeightBold,
            },
            bodyFont: {
              family: theme.typography.fontFamily,
            },
            callbacks: {
              beforeLabel: (toolTipItem) => {
                if (tooltipBeforeLabel) {
                  return tooltipBeforeLabel(
                    toolTipItem.formattedValue,
                    toolTipItem.rawValue,
                    toolTipItem.dataIndex,
                    toolTipItem.datasetIndex
                  );
                }
                return null;
              },
              label: (toolTipItem) => {
                if (tooltipLabelDescription) {
                  return tooltipLabelDescription(toolTipItem.dataIndex);
                }
                if (tooltipLabel) {
                  return tooltipLabel(
                    toolTipItem.formattedValue,
                    toolTipItem.rawValue,
                    toolTipItem.dataIndex,
                    toolTipItem.datasetIndex
                  );
                }
                return toolTipItem.formattedValue;
              },
              title: (toolTipItem) => {
                toolTipItem = toolTipItem[0];

                if (tooltipTitle) {
                  return tooltipTitle(toolTipItem.label, toolTipItem.dataIndex, toolTipItem.datasetIndex);
                }
                return toolTipItem.label;
              },
            },
          },
        },
      },
    }),
    [
      backgroundColor,
      barColor,
      barDisplayOptions,
      benchmarks,
      buildBenchmarkAnnotations,
      chartTickLabelSize,
      dataLabels,
      datasets,
      defaultBarColor,
      fontSize,
      hideDataLabels,
      hoverBackgroundColor,
      isSmallDevice,
      labels,
      onClickHandler,
      tooltipBeforeLabel,
      tooltipLabel,
      tooltipLabelDescription,
      tooltipTitle,
      yShowAxisBorder,
    ]
  );

  useEffect(() => {
    if (!isValidBenchmarks) {
      return;
    }

    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [config, isValidBenchmarks]);

  if (!isValidBenchmarks) {
    return (
      <Box p={3} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={classes.chartContainer} aria-label={formatAriaLabel(labels, datasets)} tabIndex={0}>
      {shouldDisplayLegend && (
        <div className={classes.benchmarkLegendContainer}>
          <BarChartBenchmarkLegend benchmarkLabel={benchmarkLabel} />
        </div>
      )}
      <canvas id={componentId} height={'100%'} width={'100%'} ref={canvasRef} style={{ height: height }} />
    </div>
  );
}, memoAreEqual);

BarChartWithBenchmarks.propTypes = {
  labels: PropTypes.array.isRequired,
  datasets: PropTypes.array.isRequired,
  barColor: PropTypes.func,
  backgroundColor: PropTypes.array,
  barDisplayOptions: PropTypes.object,
  benchmarks: PropTypes.array.isRequired,
  benchmarkLabel: PropTypes.string,
  benchmarkThresholds: PropTypes.array.isRequired,
  chartLabelSize: PropTypes.string,
  dataLabels: PropTypes.array,
  hideDataLabels: PropTypes.bool,
  fontSize: PropTypes.string,
  hoverBackgroundColor: PropTypes.array,
  onBarSelected: PropTypes.func,
  tooltipBeforeLabel: PropTypes.func,
  tooltipLabel: PropTypes.func,
  tooltipLabelDescription: PropTypes.func,
  tooltipTitle: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  yMax: PropTypes.number,
  yShowAxisBorder: PropTypes.bool,
  hasBenchmarks: PropTypes.bool,
};

export default BarChartWithBenchmarks;
