import { combineReducers } from 'redux';
import { errorReducer } from './modules/error/reducer';
import leftNavReducer from './modules/left-nav/leftNavSlice';
import { notificationReducer } from './modules/notification/reducer';
import { userReducer } from './modules/user/reducer';
import { wevosReducer } from './modules/wevos/reducer';

export const rootReducer = combineReducers({
  user: userReducer,
  error: errorReducer,
  notification: notificationReducer,
  wevos: wevosReducer,
  leftNav: leftNavReducer,
});
