import { Button, styled } from '@mui/material';
import React from 'react';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as CircleIcon } from '../../assets/circle.svg';
import pulsingIntuitive from '../../assets/pulsingIntuitive.gif';
import pulsingTrusted from '../../assets/pulsingTrusted.gif';
import pulsingValuable from '../../assets/pulsingValuable.gif';
import { AutomatedInsightSections, QuantDiagnosticColors } from '../../modules/automated-insights/constants';
import { TrackEvent } from '../analytics';
import { ThemeTooltip } from './ui/Tooltip';

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isClickable',
})(({ isClickable }) => ({
  minWidth: '12px',
  width: '12px',
  height: '12px',
  padding: 0,
  overflow: 'hidden',
  cursor: isClickable ? 'pointer' : 'default', // Dynamically set the cursor based on isClickable
}));

const quantThemeGif = (index) => ({
  Intuitive: (
    <img style={{ width: '12px', height: '12px' }} src={pulsingIntuitive} alt="Intuitive" key={index} />
  ),
  Trusted: <img style={{ width: '12px', height: '12px' }} src={pulsingTrusted} alt="Trusted" key={index} />,
  Valuable: <img style={{ width: '12px', height: '12px' }} src={pulsingValuable} alt="Valuable" key={index} />,
});

const ThemeIcon = ({ innerIndex, theme, selectedTheme, setSelectedTab, isClickable }) => {
  const themeColor = QuantDiagnosticColors?.[theme]?.primary;
  const { track } = useAnalytics();
  return (
    <ThemeTooltip
      placement="bottom-start"
      title={theme}
      color={(theme) => theme.palette.text.secondary}
      backgroundColor={themeColor}
      key={innerIndex}>
      <StyledButton
        isClickable={isClickable}
        onClick={() => {
          track(TrackEvent.PULSE_CLICK_THEME_ICON, { theme, buttonActive: isClickable });
          return isClickable ? setSelectedTab(AutomatedInsightSections.Quant) : null;
        }}>
        {selectedTheme === theme ? (
          quantThemeGif(`gif-${innerIndex}`)[theme]
        ) : (
          <CircleIcon
            fill={themeColor}
            onClick={() => {
              track(TrackEvent.PULSE_CLICK_THEME_ICON, { theme, buttonActive: isClickable });
              return isClickable ? setSelectedTab(AutomatedInsightSections.Quant) : null;
            }}
          />
        )}
      </StyledButton>
    </ThemeTooltip>
  );
};

export default ThemeIcon;
