import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomScreenerMutationKeys } from '../../../modules/intake/constants';

const deleteCustomScreenerOption = async ({ wevoId, filterId, optionId }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/filters/${filterId}/options/${optionId}`,
    method: 'DELETE',
  });
  return data?.filter;
};

export default function useDeleteCustomScreenerOption() {
  const queryClient = useQueryClient();

  return useMutation(deleteCustomScreenerOption, {
    mutationKey: CustomScreenerMutationKeys.deleteCustomScreenerOption,
    onSuccess: (filterData, { wevoId }) => {
      queryClient.invalidateQueries(['customScreeners', { wevoId }]);
    },
  });
}
