import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchCustomQuestion = async ({ queryKey: [key, { wevoId, pageId, questionId }] }) => {
  if (!wevoId) {
    return [];
  }
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/custom-questions/${questionId}`,
    method: 'GET',
  });
  return data?.customQuestion;
};

/**
 *
 * @param {{wevoId: string, pageId: string, questionId: string}} params
 * @returns
 */
export default function useCustomQuestion({ wevoId, pageId, questionId }, options) {
  return useQuery(['customQuestionData', { wevoId, pageId, questionId }], fetchCustomQuestion, {
    ...options,
    notifyOnChangeProps: 'tracked',
  });
}
