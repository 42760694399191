import { BarController, Chart } from 'chart.js';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { memoAreEqual } from '../../../helpers';

Chart.register(BarController);

// If there are less than 5 significant words, just take the 5 most meaningful ones by score.
const MIN_SIGNIFICANT_WORDS = 5;

const COLOR_POSITIVE = '#49A84D';
const COLOR_NEUTRAL = '#757575';
const COLOR_NEGATIVE = '#C85151';

const WordGraph = React.memo((props) => {
  const { wordScores, selectedWords, showAll, onChange } = props;
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  // Generate list of word scores to show.
  let wordScoresToShow = [];
  if (showAll) {
    wordScoresToShow = wordScores;
  } else {
    // Sort any word scores manually set as significant first, and then by score, and take up to 5.
    const sortedWordScoresBySignificance = wordScores.sort(
      (a, b) => +b.isSignificant - +a.isSignificant || Math.abs(b.score - a.score)
    );

    let numShown = 0;
    sortedWordScoresBySignificance.forEach((wordScore) => {
      if (wordScore.isSignificant || numShown < MIN_SIGNIFICANT_WORDS) {
        wordScoresToShow.push(wordScore);
        numShown++;
      }
    });
  }

  const sortedWordScores = wordScoresToShow.sort((a, b) => b.score - a.score);

  const labels = useMemo(() => {
    const wordLabels = [];

    sortedWordScores.forEach((wordScore) => {
      if (!wordScore || !wordScore.word || !_.isNumber(wordScore.score)) {
        return;
      }

      wordLabels.push(wordScore.word);
    });

    return wordLabels;
  }, [sortedWordScores]);

  const handleOnClick = useMemo(
    () => (clickedIndex) => {
      const clickedWord = labels[clickedIndex];

      // Tell parent component that the emotion word was selected.
      if (clickedWord) {
        if (selectedWords.includes(clickedWord)) {
          onChange(selectedWords.filter((word) => word !== clickedWord));
        } else {
          onChange([...selectedWords, clickedWord]);
        }
      }
    },
    [labels, selectedWords, onChange]
  );

  /**
   * Determine if emotion word is clicked from the labels.
   *
   * @param {Event} ev
   */
  const handleOnClickLabels = (ev) => {
    if (!chartRef?.current) {
      return;
    }

    // Calculate the x,y click coordinates starting from the canvas.
    const canvasOffsetX = _.get(chartRef.current, 'canvas.offsetLeft', 0);
    const canvasOffsetY = _.get(chartRef.current, 'canvas.offsetTop', 0);
    const clickX = ev.pageX - canvasOffsetX;
    const clickY = ev.pageY - canvasOffsetY;

    // Get x coordinates for when the horizontal bars begin.
    const barsLeftX = _.get(chartRef.current, 'scales.x.left', 0);

    // If the click event was on the bars, ignore it. Only switch the emotion word if it was on the tick label.
    if (clickX > barsLeftX) {
      return;
    }

    // Determine which emotion word was clicked.
    const clickedIndex = chartRef.current.scales.y.getValueForPixel(clickY);
    handleOnClick(clickedIndex);
  };

  /**
   * Determine which emotion word was clicked when bar is clicked.
   *
   * @param {Event} ev
   * @param {object} item
   */
  const handleOnClickBars = useMemo(
    () => (ev, item) => {
      const clickedIndex = _.get(item, '0.index');
      if (typeof clickedIndex !== 'number') {
        return;
      }

      handleOnClick(clickedIndex);
    },
    [handleOnClick]
  );

  useEffect(() => {
    const scores = [];
    const colors = [];
    sortedWordScores.forEach((wordScore) => {
      if (!wordScore || !wordScore.word || !_.isNumber(wordScore.score)) {
        return;
      }

      const score = Number(wordScore.score);
      const isSignificant = Boolean(wordScore.isSignificant);

      scores.push(score);

      // Determine the chart bar color.
      let color;
      if (isSignificant) {
        color = score > 0 ? COLOR_POSITIVE : COLOR_NEGATIVE;
      } else {
        color = COLOR_NEUTRAL;
      }

      colors.push(color);
    });

    const data = {
      labels,
      datasets: [
        {
          data: scores,
          backgroundColor: colors,
          barPercentage: 0.5,
        },
      ],
    };

    const config = {
      type: 'bar',
      data,
      options: {
        animation: { duration: 0 },
        indexAxis: 'y',
        responsive: true,
        scales: {
          x: {
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: { display: false },
          },

          y: {
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: {
              // Change the font color for the emotion word that is selected.
              color: (context) => {
                if (_.isEmpty(selectedWords)) {
                  return;
                }

                let labelIndexes = [];
                labels.forEach((label, index) => {
                  if (selectedWords.includes(label)) {
                    labelIndexes.push(index);
                  }
                });

                const tickIndex = _.get(context, 'tick.value');
                return labelIndexes.includes(tickIndex) ? '#276EB0' : undefined;
              },
            },
          },
        },
        plugins: {
          tooltip: { enabled: false },
          legend: {
            display: false,
          },
        },
        onClick: handleOnClickBars,
      },
    };

    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [handleOnClickBars, labels, selectedWords, sortedWordScores]);

  return <canvas ref={canvasRef} onClick={handleOnClickLabels} />;
}, memoAreEqual);

WordGraph.propTypes = {
  wordScores: PropTypes.array.isRequired,
  showAll: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedWords: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default WordGraph;
