import { produce } from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomScreenerMutationKeys } from '../../../modules/intake/constants';

const addCustomScreenerOption = async ({ wevoId, filterId }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/filters/${filterId}/options`,
    method: 'POST',
  });
  return data?.filter;
};

export default function useAddCustomScreenerOption() {
  const queryClient = useQueryClient();

  return useMutation(addCustomScreenerOption, {
    mutationKey: CustomScreenerMutationKeys.addCustomScreenerOption,
    onSuccess: (filter, { wevoId, filterId }) => {
      const previousCustomScreenersState = queryClient.getQueryData(['customScreeners', { wevoId }]);
      const updatedCustomScreenersState = produce(previousCustomScreenersState, (draftState) => {
        const index = draftState?.findIndex((q) => String(q?.id) === String(filterId));
        if (index >= 0) {
          draftState[index] = filter;
        }
      });

      queryClient.setQueryData(['customScreeners', { wevoId }], updatedCustomScreenersState);
    },
  });
}
