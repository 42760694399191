import { useMutation } from 'react-query';
import axios from '../api';

const generateStaticImages = async ({ wevoId, pageId, assetId, assetSource = 'figma', preflight = false }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/prototypes/${assetSource}/${assetId}/images?preflight=${preflight}`,
    method: 'PUT',
    data: {},
  });
  return data || {};
};

export default function usePrototypeGenerateStaticImages(options) {
  return useMutation(generateStaticImages, options);
}
