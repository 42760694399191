import { Box, CircularProgress } from '@mui/material';

export default function ImagePlaceholder() {
  return (
    <Box
      sx={{
        height: 'calc(100vh - 240px)',
      }}>
      <Box sx={{ height: 'inherit', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress color="secondary" />
      </Box>
    </Box>
  );
}
