import { Box, Grid, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import * as UserActions from '../../../modules/user/actions';
import { getUserCustomizations } from '../../../modules/user/selectors';
import { MastercardDqsOption } from '../../../modules/wevos/constants';
import { TrackEvent } from '../../analytics';
import PricingModal from '../edit/PricingModal';
import ToolSelection from '../edit/ToolSelection';

export const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '1400px',
    marginTop: theme.spacing(9),
  },
  title: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  infoIcon: {
    color: grey[600],
    fontSize: 'medium',
    marginLeft: theme.spacing(0.5),
    marginTop: theme.spacing(0.25),
  },
  font: { fontSize: '12px' },
  launchNewStudy: {
    marginTop: '-48px',
    marginBottom: '24px',
  },
  selectToolGrid: {
    height: '130px',
  },
  effortGrid: {
    height: '32px',
  },
  participantsGrid: {
    height: '44px',
  },
  whenGrid: {
    height: '196px',
  },
  whatGrid: {
    height: '178px',
  },
  pricingGrid: {
    height: '56px',
  },
  keyFindingsGrid: {
    height: '110px',
  },
  otherPricingGrid: {
    height: '80px',
    marginTop: theme.spacing(2),
  },
  pricingInfo: {
    cursor: 'pointer',
  },
  learnMoreLink: {
    color: 'black',
    cursor: 'pointer',
    textDecoration: 'underline',
    fontSize: '12px',
  },
  learnMoreLinkGrid: {
    marginTop: theme.spacing(4),
  },
  pricingHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  closeIconGrid: {
    marginRight: theme.spacing(-1),
  },
  white: {
    color: 'white',
  },
  dialogContent: {
    marginTop: theme.spacing(1),
    height: (window.innerHeight * 6) / 8,
  },
  link: {
    color: theme.palette.primary.main,
  },
  serviceCard: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    [theme.breakpoints.down('xl')]: {
      height: '55px',
    },
    [theme.breakpoints.up('md')]: {
      height: '36px',
    },
    textAlign: 'left',
  },
  fontBYOP: {
    fontSize: '11px',
    marginTop: theme.spacing(-1),
  },
  serviceCreditCard: {
    padding: theme.spacing(1.25),
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    color: 'inherit',
    [theme.breakpoints.down('xl')]: {
      height: '55px',
    },
    [theme.breakpoints.up('md')]: {
      height: '36px',
    },
    textAlign: 'right',
  },
  audienceCard: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    height: '36px',
    width: '70px',
    textAlign: 'center',
  },
  audienceCardBYOP: {
    border: '1px solid',
    borderColor: grey[500],
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    padding: theme.spacing(1),
    color: 'inherit',
    height: '36px',
    width: '90px',
    textAlign: 'center',
  },
  creditsCard: {
    padding: theme.spacing(1.25),
    borderBottomLeftRadius: 0,
    borderTopLeftRadius: 0,
    color: 'inherit',
    height: '36px',
    width: '80px',
    textAlign: 'center',
  },
}));

const UserlyticsLanding = (props) => {
  const { userCustomizations, fetchUser } = props;

  const classes = useStyles();
  const { track } = useAnalytics();

  const isUserlytics = Boolean(_.get(userCustomizations, 'integrations.userlytics'));
  const isDqs = userCustomizations?.features?.mastercardDqs === MastercardDqsOption.Enabled;
  const [openPricing, setOpenPricing] = useState(false);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  const openPricingModal = () => {
    track(TrackEvent.USERLYTICS_CLICKED_PRICING, { fromComponent: 'landing' });
    setOpenPricing(true);
  };

  const handleClose = () => {
    setOpenPricing(false);
  };

  return (
    <Box>
      <PricingModal handleClose={handleClose} openPricing={openPricing} />
      <Grid container spacing={isDqs ? 3 : 1} justifyContent="center" className={classes.root}>
        <Grid item>
          <Grid container className={classes.launchNewStudy}>
            <Typography variant="h4">Launch a New Study</Typography>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.selectToolGrid}>
            <Typography variant="h6" className={classes.font}>
              To start, select a tool:
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.effortGrid}>
            <Typography variant="h6" className={classes.font}>
              Effort
            </Typography>
            <Tooltip
              placement="right-start"
              title={
                <Typography className={classes.font}>
                  Effort reflects the hours it can take your team to create the test plan, recruit and
                  qualify/screen participants and cleanse and organize results (e.g. ready for insight)
                </Typography>
              }>
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.participantsGrid}>
            <Typography variant="h6" className={classes.font}>
              Participants
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.whenGrid}>
            <Typography variant="h6" className={classes.font}>
              When you need to
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.whatGrid}>
            <Typography variant="h6" className={classes.font}>
              What do you get
            </Typography>
            <Tooltip
              placement="right-start"
              title={
                <Typography className={classes.font}>
                  Click on each listed item to link to examples for each report/output
                </Typography>
              }>
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.pricingGrid}>
            <Typography variant="h6" className={classes.font}>
              Pricing (B2C audience)
            </Typography>
            {isUserlytics && (
              <div onClick={openPricingModal} className={classes.pricingInfo}>
                <InfoIcon className={classes.infoIcon} />
              </div>
            )}
          </Grid>
          <Grid container justifyContent="flex-end" className={classes.keyFindingsGrid}>
            <Typography variant="h6" className={classes.font}>
              Key Findings (Optional)
            </Typography>
          </Grid>
          {isUserlytics && (
            <Grid container justifyContent="flex-end" className={classes.otherPricingGrid}>
              <Typography variant="h6" className={classes.font}>
                Other pricing:
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item>
          <ToolSelection isUserlytics={isUserlytics} isDqs={isDqs} />
          {isUserlytics && (
            <Grid item onClick={openPricingModal} className={classes.learnMoreLinkGrid}>
              <Typography variant="body2" className={classes.learnMoreLink}>
                Learn More on Professional Services and Pricing
              </Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    userCustomizations: getUserCustomizations(state),
  };
};

const actionCreators = {
  fetchUser: UserActions.fetchUserInfo,
};

export default withRouter(connect(mapStateToProps, actionCreators)(UserlyticsLanding));
