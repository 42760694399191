import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

const TaskToCompleteCard = (props) => {
  const { wevo } = props;
  const wevoTaskToComplete = wevo?.details?.taskToComplete;

  return (
    <Card elevation={4} sx={{ borderRadius: '20px', height: '100%' }}>
      <CardContent sx={{ display: 'flex', height: '100%' }}>
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ marginTop: '3px', padding: 2 }}>
          <Grid item>
            <Typography variant="h5" sx={{ marginRight: '16px' }}>
              Task To Complete
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant={wevoTaskToComplete ? 'body2' : 'h4'}>{wevoTaskToComplete}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TaskToCompleteCard;
