import { Box, Tooltip } from '@mui/material';
import { yellow } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import StarQuoteButton from '../../components/StarQuoteButton';
import StarredQuoteContext from './StarredQuoteContext';

const styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  starContainer: {
    textAlign: 'center',
  },
  star: {
    verticalAlign: 'middle',
    color: yellow[700],
    paddingRight: theme.spacing(1),
  },
  audienceText: {
    cursor: 'pointer',
    fontSize: 10,
    color: '#757575',
  },
  contextLink: {
    cursor: 'pointer',
  },
}));

const StarredQuoteBlock = ({ wevo, pageNum, pageLabel, quote, handleStarClick, isLimitedReport }) => {
  const classes = styles();

  return (
    <Grid key={quote.id} container spacing={2} className={classes.root}>
      <Grid item xs={1} className={classes.starContainer}>
        <StarQuoteButton
          wevo={wevo}
          handleStarClick={handleStarClick}
          isStarredByCompany={quote.starredByCompany}
          isStarredByWevo={quote.starredByWevo}
        />
      </Grid>
      <Grid container item xs={9}>
        <Grid item xs={12}>
          <Typography variant="body2" component="span">
            "{quote.quote}"
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box py={1}>
            {quote.attributes &&
              quote.attributes.map((attribute, index) => (
                <Fragment key={`attribute.attributeId-${index}`}>
                  <Tooltip title={attribute.attributeLabel}>
                    <Typography variant="caption" component="span" className={classes.audienceText}>
                      {index > 0 && ', '}
                      {attribute.segmentLabel}
                    </Typography>
                  </Tooltip>
                </Fragment>
              ))}
          </Box>
        </Grid>
        {!isLimitedReport && (
          <Grid item xs={12}>
            <StarredQuoteContext wevo={wevo} pageNum={pageNum} pageLabel={pageLabel} quote={quote} />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

StarredQuoteBlock.propTypes = {
  wevo: PropTypes.object.isRequired,
  pageNum: PropTypes.number.isRequired,
  pageLabel: PropTypes.string.isRequired,
  quote: PropTypes.object.isRequired,
  handleStarClick: PropTypes.func.isRequired,
  isLimitedReport: PropTypes.bool,
};

export default StarredQuoteBlock;
