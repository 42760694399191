import { Grid } from '@mui/material';
import { convertScoresToPercentages, roundPercentages } from '../../../modules/report/helpers';
import React from 'react';
import { GraphIdContext } from '../dashboard/components/CustomQuestionCard';
/*
 * We added this component for screen readers to be able to read graphs' content
 */
const ChartAccessibility = ({ id, labels, scores, percentages }) => {
  const roundedPercentages = percentages || roundPercentages(convertScoresToPercentages(scores), 1);

  return (
    <Grid item sx={{ display: 'none', maxHeight: '0px' }} id={React.useContext(GraphIdContext)}>
      {`Graph content: ${String(labels.map((label, index) => `${label} ${roundedPercentages?.[index]}%`))}`}
    </Grid>
  );
};

export default ChartAccessibility;
