import { Box, Button, Paper, styled } from '@mui/material';
import { Links } from '../../modules/automated-insights/constants';

const GradientButton = styled(Button)(({ theme }) => ({
  height: '34px',
  textTransform: 'none',
  minWidth: 170,
  color: theme.palette.text.secondary,
  background: theme.palette.gradient.main,
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: '27px',
  '&:hover': {
    border: '1px solid rgba(255,255,255,.5)',
    background: theme.palette.gradient.main,
  },
}));

/**
 * Shows a rounded bottom bar with a button to try WEVO Pulse. Shown for unauthenticated users.
 */
export default function RoundedBottomBar() {
  const handleTryPulseButton = () => {
    window.location.href = Links.TakeAPulse;
  };

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}>
      <Paper
        elevation={3}
        sx={{
          backgroundColor: 'rgba(38, 65, 81, 0.96)',
          width: '100%',
          height: '85px',
          borderRadius: '30px 30px 0 0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: 'auto',
        }}>
        <Box>
          <GradientButton variant="contained" size="small" disableElevation onClick={handleTryPulseButton}>
            Take a Free Pulse
          </GradientButton>
        </Box>
      </Paper>
    </Box>
  );
}
