import { useQuery } from 'react-query';
import axios from '../../../api';

/**
 * Fetches all segments associated with a user
 */

const fetchSegments = async () => {
  const response = await axios({
    url: `/api/v2/segments`,
    method: 'GET',
  });
  return response?.data.segments ?? [];
};

export default function useFetchSegments(options) {
  return useQuery('segments', fetchSegments, options ?? {});
}
