import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import darkTheme from '../../darkTheme';
import ProgressBar from './ProgressBar';

const TaskStatusBar = ({ message, percentage, isCompleted }) => {
  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      {message && (
        <Box mb={3}>
          <Typography sx={{ color: grey[600] }}>{message}</Typography>
        </Box>
      )}
      <ProgressBar
        maxWidth={300}
        value={percentage}
        barColor={darkTheme.palette.background.default}
        isCompleted={isCompleted}
      />
    </Box>
  );
};

export default TaskStatusBar;
