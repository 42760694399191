import { useMutation } from 'react-query';
import axios from '../../../api';

const createSessionGoal = async ({ automatedInsightSessionId, description, isSelected = false }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-session-goals`,
    method: 'POST',
    data: { sessionId: automatedInsightSessionId, description, isSelected },
  });

  return response?.data?.goal;
};

export function useCreateSessionGoal() {
  return useMutation(createSessionGoal);
}
