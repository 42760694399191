import { createTheme, responsiveFontSizes } from '@mui/material';
import { grey } from '@mui/material/colors';

const darkTheme = createTheme({
  typography: {
    fontFamily: 'Montserrat, "Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: 48,
      fontWeight: 700,
    },
    h2: {
      fontSize: 36,
      fontWeight: 700,
    },
    h3: {
      fontSize: 24,
      fontWeight: 700,
    },
    h4: {
      fontSize: 20,
      fontWeight: 700,
    },
    h5: {
      fontSize: 16,
      fontWeight: 600,
    },
    h6: {
      fontSize: 14,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 24,
      fontWeight: 500,
      letterSpacing: 0.25,
    },
    subtitle2: {
      fontSize: 20,
      fontWeight: 700,
      letterSpacing: 1.25,
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: 0.25,
    },
    body3: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.2,
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: '#294151',
    },
    gradient: {
      main: 'linear-gradient(180deg, #133751 0%, #125454 100%)',
    },
    error: {
      main: '#FF5252',
    },
    success: {
      main: '#03C37E',
    },
    background: {
      default: '#082739',
      paper: '#FFFFFF',
    },
    disabled: {
      default: '#EFEFEF',
    },
    avatar: {
      main: '#FFFFFF',
    },
    text: {
      primary: '#000000',
      secondary: '#FFFFFF',
      tertiary: '#1A2A3A',
    },
  },
  components: {
    MuiInputAdornment: {
      styleOverrides: {
        positionEnd: {
          marginLeft: 0,
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        marked: {
          padding: '8px 0px',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 12,
          fontWeight: 400,
          backgroundColor: '#656565',
          opacity: 0.94,
          '.MuiTooltip-arrow': {
            color: '#656565',
            opacity: 0.94,
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
            backgroundColor: '#082739',
          },
          '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
            backgroundColor: '#082739',
          },
          '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
            borderRadius: '20px',
            backgroundColor: '#294151',
            minHeight: 24,
            border: '3px solid #082739',
          },
          '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
            backgroundColor: '#294151',
          },
          '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
            backgroundColor: '#294151',
          },
          '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#294151',
          },
          '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
            backgroundColor: '#082739',
            borderRadius: '20px',

            '@supports not selector(::-webkit-scrollbar)': {
              scrollbarColor: '#294151 #082739',
              scrollbarWidth: 'thin',
            },
          },
          '.lightContainer': {
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: '#FFFFFF',
              width: '6px',
            },
            '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
              backgroundColor: '#FFFFFF',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: '20px',
              backgroundColor: grey[300],
              minHeight: 24,
              border: 'none',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: grey[300],
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: grey[300],
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: grey[300],
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: '#FFFFFF',
              borderRadius: '20px',
            },
            '@supports not selector(::-webkit-scrollbar)': {
              scrollbarColor: `${grey[300]} #FFFFFF`,
              scrollbarWidth: 'thin',
            },
          },
          '.transparentScrollBar': {
            '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
              backgroundColor: 'transparent',
              width: '6px',
            },
            '&::-webkit-scrollbar-track, & *::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
              borderRadius: '20px',
              backgroundColor: 'rgba(255, 255, 255, .3)',
              minHeight: 24,
              border: 'none',
            },
            '&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
              backgroundColor: 'rgba(255, 255, 255, .3)',
            },
            '&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
              backgroundColor: 'rgba(255, 255, 255, .3)',
            },
            '&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
              backgroundColor: 'rgba(255, 255, 255, .3)',
            },
            '&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner': {
              backgroundColor: 'rgba(255, 255, 255, .3)',
              borderRadius: '20px',
            },
            '@supports not selector(::-webkit-scrollbar)': {
              backgroundColor: 'rgba(255, 255, 255, .3)',
              scrollbarWidth: 'thin',
            },
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1600,
      xxl: 1920,
      customDropdown: 1425,
    },
  },
});

export default responsiveFontSizes(darkTheme);
