import { Check, Visibility } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, ButtonGroup, MenuItem, TextField, Typography, styled } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import * as React from 'react';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as AudienceOutlineIcon } from '../../../src/assets/audience-outline.svg';
import { AutomatedInsightSections } from '../../modules/automated-insights/constants';
import { AssetBadgeLabelStyle, getBadgeLabel } from '../../modules/automated-insights/helpers';
import { TrackEvent } from '../analytics';
import TaskStatusBar from './TaskStatusBar';
import DropdownIcon from './ui/DropdownIcon';

const AudienceTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'highlight',
})(({ theme, sx, highlight }) => ({
  '& .MuiInputBase-root': {
    width: sx?.width,
    fontSize: '12px',
    borderRadius: '27px',
    ...(highlight && {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      fontWeight: 600,
    }),
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiInputLabel-root': {
    color: '#575757',
    fontSize: '13px',
    '&.Mui-focused': {
      color: '#575757',
    },
    ...(highlight && {
      display: 'none',
    }),
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&:hover fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '& input': {
      cursor: sx?.cursor || 'pointer',
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '12px',
}));

const PROPOSED_AUDIENCE_OPTION = 'Proposed';
const MY_AUDIENCES_OPTION = 'My Personas';
const CREATE_AUDIENCE_OPTION = 'Add your persona';

// these are dummy options for designating special values as selectable options
export const ProposedAudienceOption = {
  id: PROPOSED_AUDIENCE_OPTION,
  name: PROPOSED_AUDIENCE_OPTION,
};

export const CreateNewAudienceOption = {
  id: CREATE_AUDIENCE_OPTION,
  name: CREATE_AUDIENCE_OPTION,
};

const PersonasAudienceSelect = ({
  selectedSegment,
  selectedTab,
  segments,
  isReadOnly,
  onAudienceChange,
  onMyAudiencesClick,
  onAudienceCreateClick,
  width = 145,
  maxHeight = 250,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  disabled = false,
  section,
  setSelectedSection,
  onSectionButtonClick,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleAudienceChange = (segmentId) => {
    if (segmentId === MY_AUDIENCES_OPTION) {
      onMyAudiencesClick();
      handleToggle();
    } else if (segmentId === CREATE_AUDIENCE_OPTION) {
      onAudienceCreateClick();
      handleToggle();
    } else if (
      selectedTab !== AutomatedInsightSections.MyAudience &&
      selectedSegment?.id &&
      segmentId === selectedSegment.id
    ) {
      return;
    } else {
      const segment = segments?.find((segment) => segment?.id === segmentId);
      onAudienceChange(segment);
      handleToggle();
    }
  };

  if (isReadOnly) {
    return (
      <>
        <ButtonGroup aria-label="Personas and Audience selection">
          <Button
            disabled
            onClick={() => {}}
            sx={{
              borderTopLeftRadius: '16.5px',
              borderBottomLeftRadius: '16.5px',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              fontSize: 12,
              width: '76px',
              height: '29px',
              textTransform: 'capitalize',
              border: selectedTab === section?.name ? '' : '1px solid  #D1CFCF',
            }}>
            Personas
          </Button>
          <Button
            disabled
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="menu"
            onClick={() => {}}
            sx={{
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              borderTopRightRadius: '16.5px',
              borderBottomRightRadius: '16.5px',
              fontSize: 12,
              width: '16px',
              height: '29px',
              textTransform: 'capitalize',
              border: selectedTab === section?.name ? '' : '1px solid  #D1CFCF',
            }}>
            <KeyboardArrowDownIcon />
          </Button>
        </ButtonGroup>

        {section?.isGenerating && (
          <Box pt={0.5}>
            <Box sx={{ height: 8 }}>
              <TaskStatusBar
                message={''}
                percentage={section?.progressPercentage}
                isCompleted={section?.isCompleted}
              />
            </Box>
            <Typography component="span" fontSize={10} sx={{ display: 'block', textAlign: 'center' }}>
              {section?.timeRemainingLabel}
            </Typography>
          </Box>
        )}
      </>
    );
  }

  return (
    <>
      <ButtonGroup ref={anchorRef} aria-label="Personas and Audience selection">
        <Button
          onClick={() => {
            onSectionButtonClick(section?.name);
            setSelectedSection(AutomatedInsightSections.Audience);
          }}
          sx={{
            borderTopLeftRadius: '16.5px',
            borderBottomLeftRadius: '16.5px',
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            fontSize: 12,
            fontWeight: selectedTab === section?.name ? 700 : '',
            color: selectedTab === section?.name ? 'white' : 'black',
            background: selectedTab === section?.name ? '#112637' : 'transparent',
            width: '76px',
            height: '29px',
            textTransform: 'capitalize',
            border:
              selectedTab === AutomatedInsightSections.Audience
                ? '1px solid #112637'
                : selectedTab === section?.name
                ? ''
                : '1px solid  #D1CFCF',
            borderRight: selectedTab === AutomatedInsightSections.Audience ? '1px solid #FFFFFF' : '',
            '&:hover': {
              background: selectedTab === section?.name ? '#112637' : '#EDEDED',
              border: selectedTab === section?.name ? '' : '1px solid  #D1CFCF',
            },
          }}>
          Personas
        </Button>
        <Button
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="menu"
          onClick={handleToggle}
          sx={{
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: '16.5px',
            borderBottomRightRadius: '16.5px',
            fontSize: 12,
            fontWeight: 700,
            background: selectedTab === section?.name ? '#112637' : 'transparent',
            color: selectedTab === section?.name ? 'white' : 'black',
            width: '16px',
            height: '29px',
            textTransform: 'capitalize',
            border:
              selectedTab === AutomatedInsightSections.Audience
                ? '1px solid #112637'
                : selectedTab === section?.name
                ? ''
                : '1px solid  #D1CFCF',
            borderLeft: selectedTab === AutomatedInsightSections.Audience ? '1px solid #FFFFFF' : '',
            '&:hover': {
              background: selectedTab === section?.name ? '#112637' : '#EDEDED',
              border: selectedTab === section?.name ? '' : '1px solid  #D1CFCF',
            },
          }}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        placement={'bottom-start'}
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>
            <Paper
              sx={{
                minWidth: '160px',
                borderRadius: '10px',
                border: '1px solid #DCDCDC',
                marginTop: 0.7,
              }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {!segments?.length && (
                    <StyledMenuItem value={PROPOSED_AUDIENCE_OPTION} disableRipple>
                      {PROPOSED_AUDIENCE_OPTION} <Check fontSize="small" sx={{ marginLeft: 1 }} />
                    </StyledMenuItem>
                  )}
                  {segments?.map((segment) => (
                    <StyledMenuItem
                      key={segment?.id}
                      value={segment?.id}
                      disableRipple
                      disabled={disabled}
                      onClick={() => handleAudienceChange(segment?.id)}
                      sx={{
                        fontWeight: segment?.id === selectedSegment?.id ? 700 : '',
                        textTransform: 'capitalize',
                      }}>
                      {segment?.name}
                      {selectedTab !== AutomatedInsightSections.MyAudience &&
                        segment?.id === selectedSegment?.id && (
                          <Check sx={{ marginLeft: 1, fontSize: '14px' }} />
                        )}
                    </StyledMenuItem>
                  ))}
                  {onAudienceCreateClick && (
                    <StyledMenuItem
                      value={CREATE_AUDIENCE_OPTION}
                      disableRipple
                      disabled={disabled}
                      onClick={() => handleAudienceChange(CREATE_AUDIENCE_OPTION)}
                      sx={{
                        borderTop: '1px solid rgba(160, 160, 160, 0.37)',
                        textTransform: 'capitalize',
                        height: '36px',
                        marginBottom: -1,
                      }}>
                      <span style={{ paddingTop: 5, marginRight: 5 }}>
                        <AudienceOutlineIcon
                          fill={'black'}
                          style={{ height: '28px', width: '28px', marginLeft: -4 }}
                        />
                      </span>
                      {CREATE_AUDIENCE_OPTION}
                    </StyledMenuItem>
                  )}
                  {/*This hidden value makes it so that the component doesn't throw errors because My Audiences isn't an option */}
                  <StyledMenuItem value={MY_AUDIENCES_OPTION} sx={{ display: 'none' }}></StyledMenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {section?.isGenerating && (
        <Box pt={0.5}>
          <Box sx={{ height: 8, width: '115px' }}>
            <TaskStatusBar
              message={''}
              percentage={section?.progressPercentage}
              isCompleted={section?.isCompleted}
            />
          </Box>
          <Typography component="span" fontSize={10} sx={{ display: 'block', textAlign: 'center' }}>
            {section?.timeRemainingLabel}
          </Typography>
        </Box>
      )}
    </>
  );
};

export function MultiSessionAudienceSelect({
  sessions,
  selectedSegment,
  segments,
  onAudienceChange,
  onMyAudiencesClick,
  width = 150,
  maxHeight = 250,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  disabled = false,
}) {
  const { track } = useAnalytics();

  const sessionSegments = useMemo(() => sessions.flatMap((session) => session?.segments ?? []), [sessions]);

  const handleAudienceChange = (e) => {
    if (e.target.value === MY_AUDIENCES_OPTION) {
      track(TrackEvent.VIEWED_ALL_COMPARE_AUDIENCES);
      onMyAudiencesClick();
    } else if (selectedSegment?.id && e.target.value === selectedSegment.id) {
      return;
    } else {
      const segment = (segments ?? [])
        .concat(sessionSegments)
        .find((segment) => segment?.id === e.target.value);
      track(TrackEvent.VIEWED_COMPARE_PULSE_AUDIENCE, { segmentId: segment?.id });

      onAudienceChange(segment);
    }
  };

  const shouldShowSessionSegments = useMemo(() => {
    // basically we don't want to show the audiences until they are all done loading,
    // and a way to do that is to ensure that every session has a generated segment
    return sessions.every((session) => !!session?.segments?.find((segment) => segment.isGenerated));
  }, [sessions]);

  const getGeneratedSessionSegment = useCallback(
    (session) => {
      if (!session?.segments || (session?.segments ?? []).length < 1) {
        return [];
      }

      const generatedSessionSegment = session?.segments?.find((segment) => segment.isGenerated);

      return [
        <StyledMenuItem
          key={generatedSessionSegment?.id}
          value={generatedSessionSegment?.id}
          disableRipple
          sx={{ '&:hover .visibility-icon': { display: 'initial' } }}>
          <Box sx={{ display: 'flex', width: '100%' }} justifyContent="space-between" alignContent="center">
            <Typography fontSize={12} minHeight={24} alignSelf="center">
              {generatedSessionSegment?.name}
            </Typography>
            {generatedSessionSegment?.id === selectedSegment?.id && (
              <Check fontSize="small" sx={{ marginLeft: 1 }} />
            )}
            {!(generatedSessionSegment?.id === selectedSegment?.id) && (
              <Visibility
                className="visibility-icon"
                fontSize="small"
                sx={{ marginLeft: 1, display: 'none' }}
              />
            )}
          </Box>
        </StyledMenuItem>,
      ];
    },
    [selectedSegment]
  );

  if (!shouldShowSessionSegments && onMyAudiencesClick) {
    return (
      <AudienceTextField
        size="small"
        label="Persona"
        value={selectedSegment?.name ?? `${sessions?.length} options selected`}
        onClick={() => onMyAudiencesClick()}
        InputProps={{
          readOnly: true,
        }}
        disabled={disabled}
      />
    );
  }

  return (
    <AudienceTextField
      select
      sx={{ width }}
      size="small"
      label="Persona"
      onChange={handleAudienceChange}
      value={segments?.length ? selectedSegment?.id ?? PROPOSED_AUDIENCE_OPTION : PROPOSED_AUDIENCE_OPTION}
      SelectProps={{
        IconComponent: (props) => <DropdownIcon {...props} />,
        renderValue: () =>
          shouldShowSessionSegments
            ? selectedSegment?.name ?? `${sessions?.length} options selected`
            : PROPOSED_AUDIENCE_OPTION,
        MenuProps: {
          className: 'transparentScrollBar',
          anchorOrigin: anchorOrigin,
          transformOrigin: transformOrigin,
          PaperProps: {
            sx: {
              boxShadow: 'none',
              border: '1px solid rgba(160, 160, 160, 0.37)',
              maxHeight: maxHeight,
            },
          },
        },
      }}
      disabled={disabled}>
      {(!segments?.length || !shouldShowSessionSegments) && (
        <StyledMenuItem value={PROPOSED_AUDIENCE_OPTION} disableRipple sx={{ display: 'none' }}>
          {PROPOSED_AUDIENCE_OPTION}
          <Check fontSize="small" sx={{ marginLeft: 1 }} />
        </StyledMenuItem>
      )}
      {shouldShowSessionSegments &&
        sessions.flatMap((session, index) => {
          return [
            <StyledMenuItem key={session.id} disabled sx={{ '&.Mui-disabled': { opacity: 1 } }}>
              <Typography fontSize={12} fontWeight={600}>
                {`Page ${getBadgeLabel(session?.assets?.[0], index, AssetBadgeLabelStyle.Alphabetical)}: ${
                  session.name
                }`}
              </Typography>
            </StyledMenuItem>,
          ].concat(getGeneratedSessionSegment(session));
        })}
      {onMyAudiencesClick && (
        <StyledMenuItem
          value={MY_AUDIENCES_OPTION}
          disableRipple
          sx={{ borderTop: '1px solid rgba(160, 160, 160, 0.37)', mt: 1 }}>
          View All
        </StyledMenuItem>
      )}
    </AudienceTextField>
  );
}

export default PersonasAudienceSelect;
