import { Alert, Box, Grid, Paper, useTheme } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { BenchmarkScope } from '../../../modules/wevos/constants';
import { filterQuotes, Sentiments } from '../../../modules/wevos/quotes';
import {
  buildAudienceFilterProperties,
  buildWordFilterProperties,
  TrackEvent,
  useTrackPageLoad,
} from '../../analytics';
import { shouldTrackAudienceFilterChange } from '../../analytics/eventPropertyHelpers';
import { DiagnosticChip, SentimentChip } from '../components/QuoteChip';
import QuotesTable from '../components/QuotesTable';
import { DiagnosticWordFilter, SentimentFilter } from '../components/QuoteWordFilter';
import { RightDrawerButton } from '../components/RightDrawer';
import useBenchmarks from '../hooks/useBenchmarks';
import useDiagnostics from '../hooks/useDiagnostics';
import BenchmarkSelector from './BenchmarkSelector';
import DiagnosticsBarGraph from './DiagnosticsBarGraph';
import DiagnosticsScoreCard from './DiagnosticScoreCard';
import { JobToBeDone } from '../../../modules/intake/constants';

const Diagnostics = (props) => {
  const theme = useTheme();
  const {
    wevo,
    page,
    rightDrawerOpen,
    setRightDrawerOpen,
    componentId,
    isPptComponent,
    width = '100%',
    height = '100%',
  } = props;

  const styles = {
    paper: {
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderRadius: '20px',
      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    diagnosticsGraph: {
      height: height,
      width: width,
    },
    quotesContainer: {
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(4),
      },
    },
  };

  const [quotes, setQuotes] = useState([]);
  const [benchmarkScope, setBenchmarkScope] = useState({ scopeType: BenchmarkScope.Default, scopeId: null });
  const [publishedBenchmarkScope, setPublishedBenchmarkScope] = useState();

  const { data } = useDiagnostics(
    { wevoId: wevo?.id, pageId: page?.id },
    {
      onSuccess: (data) => {
        setQuotes(data?.quotes);
      },
    }
  );

  const { data: benchmarksData } = useBenchmarks(wevo?.id, page?.id, benchmarkScope, {
    onSuccess: (data) => {
      // Set the published benchmark scope if it's not already set. We do this in here
      // because we don't want the published benchmark scope to change while switching
      // the benchmarks.
      setPublishedBenchmarkScope(data?.publishedBenchmarkScope);
    },
  });

  const benchmarks = benchmarksData?.benchmarks;

  const hasBenchmarks = useMemo(() => wevo?.jobToBeDone !== JobToBeDone.Lite, [wevo]);
  const showBenchmarkWarning =
    hasBenchmarks &&
    benchmarkScope?.scopeType !== BenchmarkScope.Default &&
    Object.values(benchmarks ?? []).some((benchmark) => benchmark?.scope === BenchmarkScope.Global);

  const diagnostics = useMemo(() => data?.diagnostics ?? [], [data]);

  // NOTE: THIS IS A TERRIBLE HACK, REMOVE ONCE ALL WEVOS USE BAR CHART WITH BENCHMARK
  const useNewDiagnostics = useMemo(
    () => (wevo && (wevo?.useNewDiagnostics || wevo?.useVersionedDiagnostics)) || false,
    [wevo]
  );

  const [filters, setFilters] = useState({ diagnostics: [], sentiments: [] });
  const filteredQuotes = useMemo(() => filterQuotes(quotes, filters), [quotes, filters]);
  const selectedDiagnostics = useMemo(() => filters.diagnostics, [filters]);
  const selectedSentiments = useMemo(() => filters.sentiments, [filters]);
  const allWords = useMemo(() => diagnostics.map((diagnostic) => diagnostic.name), [diagnostics]);

  const { track } = useAnalytics();

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_JOURNEY_DIAGNOSTICS,
    properties: { wevoId: wevo?.analyticsId, pageId: page?.id },
  });

  const onQuoteUpdate = ({ id, updates }) => {
    const quoteIndex = quotes.findIndex((quote) => quote.id === id);
    const updatedQuote = { ...quotes[quoteIndex], ...updates };
    const updatedQuotes = [...quotes];
    updatedQuotes[quoteIndex] = updatedQuote;
    setQuotes(updatedQuotes);
  };

  // Called by QuoteSearch if search query has changed.
  const onSearchChange = (searchQuery) => {
    if (searchQuery) {
      track(TrackEvent.SEARCHED_QUOTE, {
        searchQuery,
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      });
    }

    setFilters((filters) => ({
      ...filters,
      search: searchQuery,
    }));
  };

  // Called by QuoteAudienceFilter if audience filters are changed.
  const onAudienceFilterChange = (audienceFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      audienceFilters,
      filters.audience,
      segmentToAudienceMap,
      {
        wevoId: wevo?.id,
        pageId: page?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, audienceFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_AUDIENCE, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      audience: audienceFilters,
    }));
  };

  // Called by CustomScreenerFilter if custom screener filters are changed.
  const onCustomScreenerFilterChange = (screenerFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      screenerFilters,
      filters.screeners,
      segmentToAudienceMap,
      {
        wevoId: wevo?.id,
        pageId: page?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, screenerFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_CUSTOM_SCREENER, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      screeners: screenerFilters,
    }));
  };

  /**
   * Called by DiagnosticsFilter if selected diagnostics list is changed.
   * @param {string[]} selectedDiagnosticNames
   */
  const onDiagnosticChange = (selectedDiagnosticNames) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_DIAGNOSITCS,
      buildWordFilterProperties(selectedDiagnosticNames, filters.diagnostics, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      })
    );
    setFilters((filters) => ({
      ...filters,
      diagnostics: selectedDiagnosticNames,
    }));
  };

  const onBenchmarkScopeChange = (scope) => {
    setBenchmarkScope(scope);
  };

  /**
   * Called by SentimentFilter if selected sentiments list is changed.
   * @param {string[]} selectedDiagnosticNames
   */
  const onSentimentChange = (selectedSentiments) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_SENTIMENT,
      buildWordFilterProperties(selectedSentiments, filters.sentiments, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      })
    );

    setFilters((filters) => ({
      ...filters,
      sentiments: selectedSentiments,
    }));
  };

  /**
   * Toggles whether diagnostic is selected or not.
   * @param {string} diagnosticName - Diagnostic word to toggle
   */
  const toggleDiagnostic = (diagnosticName) => {
    const shouldDeselect = selectedDiagnostics.includes(diagnosticName);
    track(TrackEvent.FILTERED_QUOTES_BY_DIAGNOSITCS, {
      filters: [diagnosticName],
      location: 'diagnostic score card',
      deselected: shouldDeselect,
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    if (shouldDeselect) {
      deselectDiagnostic(diagnosticName);
    } else {
      const diagnosticsFilters = (filters && filters.diagnostics) || [];
      setFilters((filters) => ({
        ...filters,
        diagnostics: [...diagnosticsFilters, diagnosticName],
      }));
    }
  };

  /**
   * Sets diagnostic filter so that only the passed in diagnostic is selected
   * @param {string} diagnosticName - Diagnostic word to select
   */
  const selectOnlyDiagnostic = (diagnosticName) => {
    track(TrackEvent.FILTERED_QUOTES_BY_DIAGNOSITCS, {
      filters: [diagnosticName],
      location: 'diagnostic bar chart',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      diagnostics: [diagnosticName],
    }));
  };

  /**
   * Remove a given diagnostic from the list of selected diagnostics in the filters
   * @param {string} diagnosticName
   */
  const deselectDiagnostic = (diagnosticName) => {
    setFilters((filters) => ({
      ...filters,
      diagnostics: filters.diagnostics.filter((diagnostic) => diagnostic !== diagnosticName),
    }));
  };

  /**
   * Remove a given diagnostic or sentiment from the filters
   * @param {string} deselectedWord diagnostic or sentiment
   */
  const deselectWord = (deselectedWord) => {
    track(TrackEvent.DESELECTED_QUOTE_FILTER, {
      filters: [deselectedWord],
      deselected: true,
      location: 'chip',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      diagnostics: filters.diagnostics.filter((diagnostic) => diagnostic !== deselectedWord),
      sentiments: filters.sentiments.filter((sentiment) => sentiment !== deselectedWord),
    }));
  };

  /**
   * Clear diagnostic and sentiment filters
   */
  const clearWordFilters = () => {
    track(TrackEvent.CLEARED_QUOTE_FILTERS, {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      diagnostics: [],
      sentiments: [],
    }));
  };

  /**
   * Determines whether diagnostic is selected.
   */
  const isDiagnosticSelected = (diagnosticName) => {
    if (_.isEmpty(selectedDiagnostics)) {
      return false;
    }

    return selectedDiagnostics.includes(diagnosticName);
  };

  let title = `${filteredQuotes.length} Quotes`;
  if (quotes.length !== filteredQuotes.length) {
    title += ` (out of ${quotes.length})`;
  }

  const hasDiagnostics = diagnostics.filter((d) => !_.isNil(d?.score)).length > 0;

  return (
    <Box mr={5}>
      {!useNewDiagnostics && (
        <Grid container justifyContent="center" spacing={3}>
          {diagnostics &&
            diagnostics.map((diagnostic) => (
              <Grid key={diagnostic.id} item xs={12} sm={6} md={4}>
                <DiagnosticsScoreCard
                  diagnostic={diagnostic}
                  isSelected={isDiagnosticSelected(diagnostic.name)}
                  onClick={() => toggleDiagnostic(diagnostic.name)}
                />
              </Grid>
            ))}
        </Grid>
      )}

      {useNewDiagnostics && hasDiagnostics && (
        <Paper elevation={4} sx={styles.paper}>
          {hasBenchmarks && (
            <Box pt={6} pl={4}>
              <BenchmarkSelector
                wevo={wevo}
                page={page}
                publishedBenchmarkScope={publishedBenchmarkScope}
                onScopeChange={onBenchmarkScopeChange}
              />
            </Box>
          )}
          {showBenchmarkWarning && (
            <Box py={1}>
              <Alert severity="info">
                Due to limited related data in this category, some benchmarks may not be accurately tailored
                for this test.
              </Alert>
            </Box>
          )}
          <Box pb={4}>
            <Grid sx={styles.diagnosticsGraph}>
              <DiagnosticsBarGraph
                diagnostics={diagnostics}
                benchmarks={benchmarks}
                onBarSelected={(label) => selectOnlyDiagnostic(label)}
                isPptComponent={isPptComponent}
                componentId={componentId}
                hasBenchmarks={hasBenchmarks}
              />
            </Grid>
          </Box>
        </Paper>
      )}

      <Paper elevation={4} sx={styles.paper}>
        <Box sx={styles.quotesContainer}>
          <Grid container>
            <Grid item xs={12}>
              <QuotesTable
                wevo={wevo}
                page={page}
                fullQuotes={quotes}
                displayQuotes={filteredQuotes}
                onQuoteUpdate={onQuoteUpdate}
                onAudienceFilterChange={onAudienceFilterChange}
                onCustomScreenerFilterChange={onCustomScreenerFilterChange}
                clearWordFilters={clearWordFilters}
                onSearchChange={onSearchChange}
                title={title}
                selectedWords={[...selectedDiagnostics, ...selectedSentiments]}
                deselectWord={deselectWord}
                filters={[
                  <DiagnosticWordFilter
                    wevo={wevo}
                    words={allWords}
                    selectedWords={selectedDiagnostics}
                    onWordFilterChange={onDiagnosticChange}
                  />,
                  <SentimentFilter
                    sentiments={Object.values(Sentiments)}
                    selectedSentiments={selectedSentiments}
                    onSentimentFilterChange={onSentimentChange}
                  />,
                ]}
                chips={[DiagnosticChip, SentimentChip]}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <RightDrawerButton
        wevo={wevo}
        rightDrawerOpen={rightDrawerOpen}
        handleRightDrawerOpenClick={setRightDrawerOpen}
      />
    </Box>
  );
};

Diagnostics.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  rightDrawerOpen: PropTypes.bool.isRequired,
  setRightDrawerOpen: PropTypes.func.isRequired,
  isPptComponent: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default Diagnostics;
