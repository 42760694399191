import { combineEpics } from 'redux-observable';
import * as userEpics from './modules/user/epics';
import * as errorEpics from './modules/error/epics';
import * as notificationEpics from './modules/notification/epics';
import * as wevosEpics from './modules/wevos/epics';

export const rootEpic = combineEpics(
  ...Object.values(userEpics),
  ...Object.values(errorEpics),
  ...Object.values(notificationEpics),
  ...Object.values(wevosEpics)
);
