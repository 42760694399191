import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';

import * as ErrorActions from '../modules/error/actions';
import { getError } from '../modules/error/selectors';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    color: 'red',
  },
  error: {},
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ErrorDialog = (props) => {
  const { error, callback } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(!error.seen);
  }, [error.seen]);

  const classes = useStyles();

  const handleClose = () => {
    props.dismissError();
    !!callback && callback();
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="error-dialog"
        aria-describedby="error-dialog-description">
        <DialogTitle className={classes.title}>{error.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="error-dialog-description">{error.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>OK</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    error: getError(state),
  };
};

const actionCreators = {
  dismissError: ErrorActions.dismissError,
};

export default connect(mapStateToProps, actionCreators)(ErrorDialog);
