import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: true,
};

export const leftNavSlice = createSlice({
  name: 'leftNav',
  initialState,
  reducers: {
    close: (state) => {
      state.isOpen = false;
    },
    open: (state) => {
      state.isOpen = true;
    },
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
  },
});

// Action creators are generated for each case reducer function
export const { close: closeLeftNav, open: openLeftNav, toggle: toggleLeftNav } = leftNavSlice.actions;

export const selectIsLeftNavOpen = (state) => state.leftNav.isOpen;

export default leftNavSlice.reducer;
