import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchPageHeatmapSummary = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  if (!wevoId || !pageId) {
    return [];
  }

  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/heatmap/summary?maxHeight=1200`,
    method: 'GET',
  });
  return response?.data?.points ?? [];
};

export default function useFetchPageHeatmapSummary({ wevoId, pageId }, options) {
  return useQuery(['pageHeatMapSummary', { wevoId, pageId }], fetchPageHeatmapSummary, options);
}
