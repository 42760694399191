import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

/**
 * Updates segment with changes (e.g. name, description)
 */

const updateSegment = async ({ segmentId, changes }) => {
  const { data } = await axios({
    url: `/api/v2/segments/${segmentId}`,
    method: 'PUT',
    data: changes,
  });
  return data?.segment;
};

export default function useUpdateSegment(options = {}) {
  const queryClient = useQueryClient();
  return useMutation(updateSegment, {
    onSuccess: () => {
      queryClient.invalidateQueries('segments');
      queryClient.invalidateQueries('automatedInsightSession');
    },
    ...options,
  });
}
