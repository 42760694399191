import { of } from 'rxjs';
import { catchError, switchMapTo } from 'rxjs/operators';
import { CHANGE_PASSWORD_SUCCESS, UPDATE_USER_SUCCESS } from '../user/actions';
import {
  ARCHIVE_WEVO_SUCCESS,
  COPY_WEVO_SUCCESS,
  DELETE_WEVO_SUCCESS,
  LAUNCH_WEVO_SUCCESS,
  RESTORE_WEVO_SUCCESS,
} from '../wevos/actions';
import { notify } from './actions';
import { variants } from './constants';

export const userUpdateNotificationEpic = (action$) =>
  action$.ofType(UPDATE_USER_SUCCESS).pipe(
    switchMapTo(of(notify({ message: 'Profile updated!', variant: variants.SUCCESS }))),
    catchError((error) => of(console.error(error)))
  );

export const changePasswordNotificationEpic = (action$) =>
  action$.ofType(CHANGE_PASSWORD_SUCCESS).pipe(
    switchMapTo(of(notify({ message: 'Password changed!', variant: variants.SUCCESS }))),
    catchError((error) => of(console.error(error)))
  );

export const archiveWevoNotificationEpic = (action$) =>
  action$.ofType(ARCHIVE_WEVO_SUCCESS).pipe(
    switchMapTo(of(notify({ message: 'Test archived!', variant: variants.SUCCESS }))),
    catchError((error) => of(console.error(error)))
  );

export const restoreWevoNotificationEpic = (action$) =>
  action$.ofType(RESTORE_WEVO_SUCCESS).pipe(
    switchMapTo(of(notify({ message: 'Test restored!', variant: variants.SUCCESS }))),
    catchError((error) => of(console.error(error)))
  );

export const deleteWevoNotificationEpic = (action$) =>
  action$.ofType(DELETE_WEVO_SUCCESS).pipe(
    switchMapTo(of(notify({ message: 'Test deleted!', variant: variants.SUCCESS }))),
    catchError((error) => of(console.error(error)))
  );

export const copyWevoNotificationEpic = (action$) =>
  action$.ofType(COPY_WEVO_SUCCESS).pipe(
    switchMapTo(of(notify({ message: 'Test copied!', variant: variants.SUCCESS }))),
    catchError((error) => of(console.error(error)))
  );

export const launchWevoNotificationEpic = (action$) =>
  action$.ofType(LAUNCH_WEVO_SUCCESS).pipe(
    switchMapTo(of(notify({ message: 'Test submitted!', variant: variants.SUCCESS }))),
    catchError((error) => of(console.error(error)))
  );
