import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { isClassicSinglePage } from '../../../modules/wevos/helpers';
import { Paths } from '../../../routes';
import Diagnostics from './Diagnostics';
import Drivers from './Drivers';
import { WevoTestType } from '../../../modules/wevos/constants';

const DiagnosticsContainer = ({ wevo, rightDrawerOpen, setRightDrawerOpen }) => {
  if (wevo?.testType === WevoTestType.Compare) {
    const pageNums = wevo?.pages.map((page) => page.pageNumber);
    const diagnosticsPagePath = `${Paths.reports.diagnosticsPage}(${pageNums.join('|')})`;

    return (
      <Switch>
        <Route
          exact
          path={diagnosticsPagePath}
          render={({ match: { params } }) => {
            const { pageNum } = params;
            if (wevo?.useVersionedDiagnostics) {
              return (
                <Diagnostics
                  wevo={wevo}
                  page={wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum))}
                  rightDrawerOpen={rightDrawerOpen}
                  setRightDrawerOpen={setRightDrawerOpen}
                />
              );
            }

            return (
              <Drivers
                wevo={wevo}
                page={wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum))}
                rightDrawerOpen={rightDrawerOpen}
                setRightDrawerOpen={setRightDrawerOpen}
              />
            );
          }}
        />
        <Redirect
          to={generatePath(Paths.reports.diagnosticsPage, { wevoId: wevo?.id, pageNum: pageNums[0] })}
        />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={Paths.reports.diagnostics}>
          {isClassicSinglePage(wevo) && !wevo?.useVersionedDiagnostics ? (
            <Drivers
              wevo={wevo}
              page={wevo?.pages?.[0]}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={setRightDrawerOpen}
            />
          ) : (
            <Diagnostics
              wevo={wevo}
              page={wevo?.pages?.[0]}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={setRightDrawerOpen}
            />
          )}
        </Route>
        <Redirect to={generatePath(Paths.reports.diagnostics, { wevoId: wevo?.id })} />
      </Switch>
    );
  }
};

export default DiagnosticsContainer;
