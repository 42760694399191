import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { ReactComponent as PinIcon } from '../../assets/pin.svg';
import {
  Percentiles,
  PercentileTypes,
  QuantDiagnosticColors,
  QuantDiagnostics,
} from '../../modules/automated-insights/constants';
import { numberToOrdinal } from '../../modules/report/helpers';

// Define the decile ranges
const ranges = {
  [PercentileTypes.Quartile]: {
    Q1: [0, 24],
    Q2: [25, 49],
    Q3: [50, 74],
    Q4: [75, 100],
  },
  [PercentileTypes.Decile]: {
    D1_3: [0, 29],
    D4: [30, 39],
    D5: [40, 49],
    D6: [50, 59],
    D7: [60, 69],
    D8: [70, 79],
    D9: [80, 89],
    D10: [90, 100],
  },
};

const styles = {
  firstSegment: {
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    borderRight: '1px solid  rgba(43, 77, 101, 0.86)',
  },
  middleSegment: {
    borderRight: '1px solid  rgba(43, 77, 101, 0.86)',
  },
  lastSegment: {
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
  },
  bold: {
    fontWeight: 'bold',
  },
};

const calculateAdjustedPositions = (benchmarks, scores, percentileType, containerWidth) => {
  // group scores by percentileType
  const groups = Object.keys(ranges[percentileType]).reduce((acc, key) => {
    acc[key] = [];
    return acc;
  }, {});

  scores.forEach((theme) => {
    const { name, score } = theme;
    const percentile = benchmarks[name].percentile;
    groups[percentile].push({ name, score });
  });

  const positions = Object.keys(groups).reduce((acc, group) => {
    const range = ranges[percentileType][group];
    const items = groups[group];
    const rangeSpan = range[1] - range[0];

    // sort items by score to ensure consistent order
    items.sort((a, b) => a.score - b.score);

    items.forEach((item) => {
      // calculate the position in percentage
      let exactPosition = range[0] + (item.score / 100) * rangeSpan;

      // convert the position to pixels
      let exactPositionPx = (exactPosition / 100) * containerWidth;

      // adjust to make sure there is at least 15px gap between each marker
      if (acc.length > 0) {
        const lastPositionPx = (acc[acc.length - 1].position / 100) * containerWidth;

        // ensure at least 15px gap
        if (exactPositionPx - lastPositionPx < 15) {
          exactPositionPx = lastPositionPx + 15;
        }
      }

      // convert back to percentage
      exactPosition = (exactPositionPx / containerWidth) * 100;

      // ensure positions don't exceed the segment boundaries
      exactPosition = Math.min(range[1], exactPosition);

      acc.push({ ...item, position: exactPosition });
    });

    return acc;
  }, []);

  return positions;
};

const SegmentedBarItems = ({ items = [] }) => {
  const [hoveredItemIndex, setHoveredItemIndex] = useState(null);

  return (
    <>
      {items?.map((item, index) => (
        <Fragment key={item?.name}>
          <Box
            onMouseEnter={() => setHoveredItemIndex(index)}
            onMouseLeave={() => setHoveredItemIndex(null)}
            sx={{
              position: 'absolute',
              left: `${item?.position}%`,
              top: '2px',
              transform: hoveredItemIndex === index ? 'translateX(-50%) scale(1.5)' : 'translateX(-50%)',
              ...(hoveredItemIndex === index && { zIndex: 1 }),
              cursor: 'pointer',
            }}>
            <PinIcon fill={QuantDiagnosticColors[item?.name].primary} />
          </Box>
          <Box
            onMouseEnter={() => setHoveredItemIndex(index)}
            onMouseLeave={() => setHoveredItemIndex(null)}
            sx={{
              position: 'absolute',
              left: `${item.position}%`,
              top: '45px',
              width: '56px',
              paddingY: 0.5,
              borderRadius: '11px',
              backgroundColor: QuantDiagnosticColors[item?.name].secondary,
              transform: `translateX(-70%) rotate(-45deg)`,
              textAlign: 'center',
              cursor: 'pointer',
              ...(hoveredItemIndex === index && { zIndex: 1 }),
            }}>
            <Typography fontSize={10} fontWeight={700}>
              {item?.name}
            </Typography>
          </Box>
        </Fragment>
      ))}
    </>
  );
};

const DecileSegments = ({ benchmarks, scores, percentileType }) => {
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const percentilesWithBenchmark = [
    ...new Set(QuantDiagnostics.map((theme) => benchmarks[theme]?.percentile).filter(Boolean)),
  ];

  const percentileSegments = Object.keys(Percentiles[PercentileTypes.Decile]);

  const adjustedMarkers = useMemo(() => {
    return calculateAdjustedPositions(benchmarks, scores, percentileType, containerWidth);
  }, [benchmarks, scores, percentileType, containerWidth]);

  return (
    <>
      <Grid container>
        {percentileSegments.map((percentile, index) => (
          <Grid item key={`label-${percentile}`} sx={{ width: index === 0 ? '30%' : '10%' }}>
            <Typography
              fontSize={10}
              textAlign="center"
              sx={{ ...(percentilesWithBenchmark.includes(percentile) && styles.bold) }}>
              {index === 0 ? '1st-3rd' : numberToOrdinal(index + 3)}{' '}
              {percentilesWithBenchmark.includes(percentile) && 'decile'}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        my={1}
        ref={containerRef}
        sx={{
          position: 'relative',
          width: '100%',
          height: '30px',
          border: '1px solid  rgba(43, 77, 101, 0.86)',
          borderRadius: '8px',
          backgroundColor: '#F6F6F6',
        }}>
        {percentileSegments.map((percentile, index) => (
          <Grid
            item
            key={percentile}
            sx={{
              width: index === 0 ? '30%' : '10%',
              ...(index === 0 && styles.firstSegment),
              ...(index !== 0 && index !== percentileSegments.length - 1 && styles.middleSegment),
              ...(index === percentileSegments.length - 1 && styles.lastSegment),
            }}></Grid>
        ))}
        <SegmentedBarItems items={adjustedMarkers} />
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={10} fontWeight={700}>
          Bottom 30%
        </Typography>
        <Typography fontSize={10} fontWeight={700}>
          Top 10%
        </Typography>
      </Box>
    </>
  );
};

const QuartileSegments = ({ benchmarks, scores, percentileType }) => {
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const percentilesWithBenchmark = [
    ...new Set(QuantDiagnostics.map((theme) => benchmarks[theme]?.percentile).filter(Boolean)),
  ];

  const percentileSegments = Object.keys(Percentiles[PercentileTypes.Quartile]);

  const adjustedMarkers = useMemo(() => {
    return calculateAdjustedPositions(benchmarks, scores, percentileType, containerWidth);
  }, [benchmarks, scores, percentileType, containerWidth]);

  return (
    <>
      <Grid container>
        {percentileSegments.map((percentile, index) => (
          <Grid item key={`label-${percentile}`} xs={3}>
            <Typography
              fontSize={10}
              textAlign="center"
              sx={{ ...(percentilesWithBenchmark.includes(percentile) && styles.bold) }}>
              {numberToOrdinal(index + 1)} quartile
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        my={1}
        ref={containerRef}
        sx={{
          position: 'relative',
          width: '100%',
          height: '30px',
          border: '1px solid  rgba(43, 77, 101, 0.86)',
          borderRadius: '8px',
          backgroundColor: '#F6F6F6',
        }}>
        {percentileSegments.map((percentile, index) => (
          <Grid
            item
            key={percentile}
            xs={3}
            sx={{
              ...(index === 0 && styles.firstSegment),
              ...(index !== 0 && index !== percentileSegments.length - 1 && styles.middleSegment),
              ...(index === percentileSegments.length - 1 && styles.lastSegment),
            }}></Grid>
        ))}
        <SegmentedBarItems items={adjustedMarkers} />
      </Grid>
      <Box display="flex" justifyContent="space-between">
        <Typography fontSize={10} fontWeight={700}>
          Bottom 25%
        </Typography>
        <Typography fontSize={10} fontWeight={700}>
          Top 25%
        </Typography>
      </Box>
    </>
  );
};

const BenchmarksSegmentedBar = ({ benchmarks, percentileType, scores }) => {
  return (
    <Box my={2}>
      {percentileType === PercentileTypes.Quartile ? (
        <QuartileSegments benchmarks={benchmarks} scores={scores} percentileType={percentileType} />
      ) : (
        <DecileSegments benchmarks={benchmarks} scores={scores} percentileType={percentileType} />
      )}
    </Box>
  );
};

export default BenchmarksSegmentedBar;
