import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import Carousel from './Carousel';
import { HeatmapPreviewThumbnail } from './HeatmapPreviewThumbnails';
import { Link as RouterLink } from 'react-router-dom';
import { WevoType } from '../../../modules/wevos/constants';

const HeatmapCarousel = ({
  wevo,
  page,
  currentAsset,
  assets,
  selectedCluster,
  onSelection,
  links = null,
  itemHeight = 130,
  numItemsDisplayed = 4,
}) => {
  const handleSelection = ({ index }) => {
    onSelection(assets[index], index);
  };

  return (
    <Carousel
      selectedItemIndex={assets.map((asset) => asset.id).indexOf(currentAsset.id)}
      items={assets}
      itemComponent={({ index }) => {
        const link = links?.[index];
        const pageAsset = wevo?.type === WevoType.Classic ? assets[index] : page;
        const stepAsset = wevo?.type === WevoType.Classic ? null : assets[index];

        const thumbnail = (
          <HeatmapPreviewThumbnail
            wevo={wevo}
            page={pageAsset}
            step={stepAsset}
            selectedCluster={selectedCluster}
          />
        );

        return link ? (
          <Link to={link} component={RouterLink}>
            {thumbnail}
          </Link>
        ) : (
          thumbnail
        );
      }}
      onSelection={handleSelection}
      itemHeight={itemHeight}
      numItemsDisplayed={numItemsDisplayed}
    />
  );
};

HeatmapCarousel.propTypes = {
  currentAsset: PropTypes.object.isRequired,
  assets: PropTypes.array.isRequired,
  points: PropTypes.array,
  onSelection: PropTypes.func.isRequired,
  itemHeight: PropTypes.number,
  numItemsDisplayed: PropTypes.number,
};

export default HeatmapCarousel;
