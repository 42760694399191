import { Check } from '@mui/icons-material';
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LockSectionIcon from '../../assets/lockSectionIcon.png';
import sparklesGIF from '../../assets/sparkles.gif';
import { AutomatedInsightSections } from '../../modules/automated-insights/constants';
import TaskStatusBar from './TaskStatusBar';
import DropdownIcon from './ui/DropdownIcon';

const TabButton = styled(Button, {
  shouldForwardProp: (prop) => !['isSelected', 'isComingSoon', 'showBorder'].includes(prop),
})(({ theme, isSelected, isComingSoon, showBorder }) => ({
  height: '29px',
  fontSize: '0.7rem',
  fontWeight: isSelected ? 700 : 500,
  textTransform: 'capitalize',
  color: isSelected ? theme.palette.text.secondary : theme.palette.text.primary,
  background: isSelected ? (isComingSoon ? '#EDEDED' : '#112637') : 'transparent',
  borderRadius: '16.5px',
  border: showBorder ? (isSelected ? '1px solid #112637' : '1px solid #D1CFCF') : '1px solid transparent',
  '&:hover': {
    background: isSelected && !isComingSoon ? '#112637' : '#EDEDED',
  },
}));

const SelectButton = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: '29px',
    width: '145px',
    fontSize: '12px',
    textTransform: 'capitalize',
    borderRadius: '27px',
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&:hover fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '& input': {
      cursor: 'pointer',
    },
  },
}));

const styles = {
  menuItem: {
    fontSize: '12px',
    textTransform: 'capitalize',
    minHeight: 0,
  },
  iconButton: {
    background: (theme) => theme.palette.gradient.main,
  },
};

const SECTION_STATUS_KEY = 'sectionStatus';
const oneHour = 3600000; // milliseconds in one hour

// Function to update unread status
const updateUnreadStatus = ({ sessionId, segmentId, sectionName, sessionCreatedAt, isUnread }) => {
  const sectionStatus = JSON.parse(sessionStorage.getItem(SECTION_STATUS_KEY)) || {};

  const sessionStatus = sectionStatus[sessionId] || {};
  const segmentStatus = sessionStatus[segmentId] || {};

  const updatedSegmentStatus = {
    ...segmentStatus,
    [sectionName]: {
      ...segmentStatus[sectionName],
      isUnread: isUnread,
    },
  };

  const updatedStatus = {
    ...sectionStatus,
    [sessionId]: {
      ...sessionStatus,
      sessionCreatedAt,
      [segmentId]: updatedSegmentStatus,
    },
  };

  // Update the session storage with the new status
  sessionStorage.setItem(SECTION_STATUS_KEY, JSON.stringify(updatedStatus));
};

const InsightSelectionButtons = ({
  sessionId,
  session,
  segmentId,
  sessionCreatedAt,
  sections = [],
  selectedSection,
  onSectionButtonClick,
  onChatButtonClick,
  allComplete,
  isShareLink,
  openChat,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [unreadStatus, setUnreadStatus] = useState({});

  // Note: This is guaranteed to be run before the other useEffects to ensure that the session statuses are up to date before
  // we start to mutate them
  useEffect(() => {
    const currentTime = Date.now();
    const sectionStatus = JSON.parse(sessionStorage.getItem(SECTION_STATUS_KEY)) || {};

    // Filter out old session statuses older than 1 hour
    const filteredStatus = Object.entries(sectionStatus).reduce((acc, [sessionId, sessionData]) => {
      const sessionCreationTime = new Date(sessionData.sessionCreatedAt).getTime();
      if (currentTime - sessionCreationTime <= oneHour) {
        acc[sessionId] = sessionData;
      }
      return acc;
    }, {});

    sessionStorage.setItem(SECTION_STATUS_KEY, JSON.stringify(filteredStatus));
  }, []);

  useEffect(() => {
    const currentTime = Date.now();
    const sessionCreationTime = new Date(sessionCreatedAt).getTime();
    const isRecentSession = currentTime - sessionCreationTime <= oneHour;
    if (!sessionId || !segmentId || !sessionCreatedAt || !isRecentSession) {
      return;
    }

    const sectionStatusesBySession = JSON.parse(sessionStorage.getItem(SECTION_STATUS_KEY)) || {};
    const segmentStatus = sectionStatusesBySession?.[sessionId]?.[segmentId];
    const updatedStatuses = {};
    let isUnread;

    // if session storage exists and state hasn't been set, set it
    if (segmentStatus && !unreadStatus[segmentId]) {
      Object.keys(segmentStatus).forEach((section) => {
        if (section === selectedSection) {
          isUnread = false;
        } else {
          isUnread = segmentStatus[section].isUnread;
        }
        updatedStatuses[section] = isUnread;
      });
      setUnreadStatus({ ...unreadStatus, [segmentId]: updatedStatuses });
    }
    // if session storage doesn't exist or there's already something in state
    else {
      sections?.forEach((section) => {
        if (
          [
            AutomatedInsightSections.SentimentMap,
            AutomatedInsightSections.PersonaIntent,
            AutomatedInsightSections.RecommendedExperiments,
            AutomatedInsightSections.Quant,
          ].includes(section?.name)
        ) {
          // update state with the value in the cookie
          if (segmentStatus?.[section?.name]) {
            isUnread = segmentStatus?.[section?.name].isUnread;
            updatedStatuses[section?.name] = isUnread;
          } else {
            // if session storage doesn't exist, set isUnread based on section completion
            isUnread =
              section?.isCompleted &&
              !section?.isDisabled &&
              section?.name !== selectedSection &&
              isRecentSession;
            updatedStatuses[section?.name] = isUnread;

            // if status not in session storage and section is completed, update session storage
            if (section?.isCompleted && !section?.isDisabled) {
              updateUnreadStatus({
                sessionId,
                segmentId,
                sectionName: section?.name,
                sessionCreatedAt,
                isUnread,
              });
            }
          }
        }
      });

      setUnreadStatus((prevUnreadStatus) => ({
        ...prevUnreadStatus,
        [segmentId]: { ...prevUnreadStatus[segmentId], ...updatedStatuses },
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, segmentId, sessionId, sessionCreatedAt, selectedSection]);

  const handleSectionButtonClick = useCallback(
    (section) => {
      if (section === 'placeholder') {
        onSectionButtonClick(null);
      } else if (section === AutomatedInsightSections.Chat) {
        onChatButtonClick(!openChat);
      } else {
        onSectionButtonClick(section);

        if (unreadStatus?.[segmentId]?.[section]) {
          updateUnreadStatus({
            sessionId,
            segmentId,
            sectionName: section,
            sessionCreatedAt,
            isUnread: false,
          });
          setUnreadStatus((prevUnreadStatus) => ({
            ...prevUnreadStatus,
            [segmentId]: { ...prevUnreadStatus[segmentId], [section]: false },
          }));
        }
      }
    },
    [openChat, onSectionButtonClick, onChatButtonClick, sessionId, segmentId, sessionCreatedAt, unreadStatus]
  );

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const hasMultipleSections = useMemo(() => sections?.length > 1, [sections]);

  const hasUnreadSections = useMemo(() => {
    const segmentStatuses = unreadStatus?.[segmentId] || {};
    return Object.values(segmentStatuses).some((value) => value === true);
  }, [unreadStatus, segmentId]);

  const isSectionUnread = useCallback(
    (sectionName) => {
      return unreadStatus?.[segmentId]?.[sectionName] || false;
    },
    [segmentId, unreadStatus]
  );

  const keyFindingsSection = useMemo(
    () => sections?.find((section) => section?.name === AutomatedInsightSections.KeyFindings),
    [sections]
  );

  const selectedSectionData = useMemo(
    () => sections?.find((section) => section?.name === selectedSection),
    [sections, selectedSection]
  );

  const selectComponent = useMemo(
    () => (
      <>
        <Badge
          color="success"
          badgeContent=" "
          invisible={!hasUnreadSections}
          sx={{
            '& .MuiBadge-badge': {
              height: '12px',
              minWidth: '12px',
              right: '8px',
              top: '2px',
            },
          }}>
          <SelectButton
            select
            size="small"
            value={
              selectedSection === null ||
              [AutomatedInsightSections.Audience, AutomatedInsightSections.MyAudience].includes(
                selectedSection
              )
                ? 'placeholder'
                : selectedSection
            }
            onChange={(e) => handleSectionButtonClick(e.target.value)}
            SelectProps={{
              open: menuOpen,
              onClose: handleMenuClose,
              onOpen: handleMenuOpen,
              IconComponent: (props) => <DropdownIcon {...props} />,
              renderValue: () =>
                selectedSection === null ||
                [AutomatedInsightSections.Audience, AutomatedInsightSections.MyAudience].includes(
                  selectedSection
                )
                  ? 'select a section'
                  : selectedSectionData?.displayName,
              MenuProps: {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                PaperProps: {
                  sx: {
                    boxShadow: 'none',
                    border: '1px solid rgba(160, 160, 160, 0.37)',
                  },
                },
              },
            }}>
            {/* a bit hacky but when key findings are generating, we want the selected value to be null so we'll use a placeholder here */}
            <MenuItem value="placeholder" disabled sx={{ ...styles.menuItem, fontWeight: 600 }}>
              Select a section
            </MenuItem>
            {sections?.map((section) => (
              <MenuItem
                key={section?.name}
                value={section?.name}
                selected={selectedSection === section?.name}
                disabled={section?.isDisabled}
                sx={styles.menuItem}>
                <Badge
                  color="success"
                  variant="dot"
                  badgeContent=" "
                  invisible={!isSectionUnread(section?.name)}
                  sx={{
                    '& .MuiBadge-badge': {
                      right: '-8px',
                      top: '9px',
                    },
                  }}>
                  {section?.isComingSoon ? (
                    <span style={{ color: grey[400] }}>{section?.displayName}</span>
                  ) : (
                    section?.displayName
                  )}
                </Badge>
                {selectedSection === section?.name && <Check fontSize="small" sx={{ marginLeft: 1 }} />}
                {section?.isGenerating && (
                  <CircularProgress size={12} color="secondary" sx={{ marginLeft: 1 }} />
                )}
              </MenuItem>
            ))}
          </SelectButton>
        </Badge>

        {keyFindingsSection?.isGenerating && (
          <Box pt={selectedSection === null && !keyFindingsSection?.isCompleted ? 0 : 1}>
            <Box sx={{ height: 10 }}>
              <TaskStatusBar
                message={''}
                percentage={keyFindingsSection?.progressPercentage}
                isCompleted={keyFindingsSection?.isCompleted}
              />
            </Box>
            <Typography component="span" fontSize={10} sx={{ display: 'block', textAlign: 'center' }}>
              {keyFindingsSection?.timeRemainingLabel}
            </Typography>
          </Box>
        )}
      </>
    ),
    [
      sections,
      selectedSection,
      selectedSectionData,
      handleSectionButtonClick,
      keyFindingsSection,
      menuOpen,
      hasUnreadSections,
      isSectionUnread,
    ]
  );

  useEffect(() => {
    const handleResize = () => {
      if (menuOpen) {
        handleMenuClose();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen]);

  return (
    <>
      <Box
        sx={{
          display: {
            ...{ xs: hasMultipleSections ? 'none' : 'flex' },
            ...(sections?.length > 3 ? { customDropdown: 'flex' } : { lg: 'flex' }),
          },
          gap: { lg: 0.5 },
        }}>
        {sections.map((section) => (
          <Box key={section?.name} sx={{ display: 'flex', flexDirection: 'column' }}>
            {allComplete && section?.isDisabled && section?.disabledTooltip ? (
              !isShareLink || (isShareLink && !section?.isLocked) ? (
                <Tooltip title={section?.disabledTooltip} arrow placement="bottom">
                  <span>
                    <TabButton
                      disabled={section?.isDisabled}
                      isSelected={selectedSection === section?.name}
                      showBorder={selectedSection !== section?.name}
                      onClick={() => handleSectionButtonClick(section?.name)}>
                      {`${section?.displayName} `}
                      {!isShareLink && section?.isLocked && (
                        <img src={LockSectionIcon} alt="selectable" style={{ width: '9px', height: 'auto' }} />
                      )}
                    </TabButton>
                  </span>
                </Tooltip>
              ) : null
            ) : !isShareLink || (isShareLink && !section?.isLocked) ? (
              <TabButton
                disabled={section?.isDisabled}
                isSelected={selectedSection === section?.name}
                isComingSoon={section?.isComingSoon}
                showBorder={selectedSection !== section?.name}
                onClick={() => handleSectionButtonClick(section?.name)}>
                {section?.isComingSoon ? (
                  <span style={{ color: grey[400] }}>{section?.displayName}</span>
                ) : section?.name === AutomatedInsightSections.Chat ? (
                  <>
                    <div style={{ marginLeft: -2, marginRight: 5 }}>
                      <IconButton component="div" aria-label="chat icon" sx={{ ...styles.iconButton }}>
                        <img
                          style={{ width: '2px', height: '2px', transform: 'scale(7.6)', marginRight: 0.2 }}
                          src={sparklesGIF}
                          alt="chat sparkles"
                        />
                      </IconButton>
                    </div>
                    <Badge
                      color="success"
                      badgeContent=" "
                      variant="dot"
                      invisible={!isSectionUnread(section?.name)}>
                      <span>
                        {`${section?.displayName} `}
                        {!isShareLink && section?.isLocked && (
                          <img
                            src={LockSectionIcon}
                            alt="selectable"
                            style={{ width: '9px', height: 'auto' }}
                          />
                        )}
                      </span>
                    </Badge>
                  </>
                ) : (
                  <Badge
                    color="success"
                    badgeContent=" "
                    variant="dot"
                    invisible={!isSectionUnread(section?.name)}>
                    <span>
                      {`${section?.displayName} `}
                      {!isShareLink && section?.isLocked && (
                        <img src={LockSectionIcon} alt="selectable" style={{ width: '9px', height: 'auto' }} />
                      )}
                    </span>
                  </Badge>
                )}
              </TabButton>
            ) : null}
            {section?.isGenerating && (
              <Box pt={0.5}>
                <Box sx={{ height: 8 }}>
                  <TaskStatusBar
                    message={''}
                    percentage={section?.progressPercentage}
                    isCompleted={section?.isCompleted}
                  />
                </Box>
                <Typography component="span" fontSize={10} sx={{ display: 'block', textAlign: 'center' }}>
                  {section?.timeRemainingLabel}
                </Typography>
              </Box>
            )}
          </Box>
        ))}
      </Box>

      <Box
        sx={{
          display: {
            ...{ xs: !hasMultipleSections && 'none' },
            ...(sections?.length > 3 ? { customDropdown: 'none' } : { lg: 'none' }),
          },
        }}>
        {selectComponent}
      </Box>
    </>
  );
};

export default InsightSelectionButtons;
