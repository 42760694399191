import qs from 'qs';
import { Box, CircularProgress, Grid } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const FIGMA_EVENTS = {
  SessionStart: 'INITIAL_LOAD',
  Click: 'MOUSE_PRESS_OR_RELEASE',
  Transition: 'PRESENTED_NODE_CHANGED',
};

export default function FigmaEmbeddedProtoype({ prototype, onStart, onClick, onTransition }) {
  const prototypeURL = useMemo(() => {
    const startNodeId = prototype?.startNodeId;
    const queryParams = qs.stringify({
      'node-id': startNodeId,
      scaling: 'scale-down-width',
    });
    return `https://www.figma.com/embed?embed_host=wevoconversion&url=https://www.figma.com/proto/${prototype.fileId}?${queryParams}`;
  }, [prototype]);

  const [isPrototypeLoaded, setIsPrototypeLoaded] = useState(false);

  const iframeRef = useRef(null);

  const handleMessage = useCallback(
    (event) => {
      switch (event?.data?.type) {
        case FIGMA_EVENTS.SessionStart:
          setIsPrototypeLoaded(true);
          if (onStart) {
            onStart({ event: event });
          }
          break;
        case FIGMA_EVENTS.Click:
          if (onClick) {
            onClick({
              nodeId: event?.data?.data?.targetNodeId,
              screenId: event?.data?.data?.presentedNodeId,
              event: event,
            });
          }
          break;
        case FIGMA_EVENTS.Transition:
          if (onTransition) {
            onTransition({
              nodeId: event?.data?.data?.presentedNodeId,
              screenId: event?.data?.data?.presentedNodeId,
              event: event,
            });
          }
          break;
        default:
          break;
      }
    },
    [onStart, onClick, onTransition, setIsPrototypeLoaded]
  );

  const iframeLoadedHandler = useCallback(() => {
    if (iframeRef?.current) {
      console.log('IFRAME: ', iframeRef.current);
      console.log('IFRAME: ', iframeRef.current.contentWindow);
    }
  }, [iframeRef]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, [handleMessage]);

  return (
    <Box sx={{ height: 'inherit', minWidth: 'inherit' }}>
      <Box
        style={{
          height: 'inherit',
          width: 'inherit',
          display: isPrototypeLoaded ? 'none' : 'initial',
        }}>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          style={{ height: 'inherit', width: 'inherit', overflow: 'hidden' }}>
          <Grid item>
            <CircularProgress size={'7rem'} />
          </Grid>
        </Grid>
      </Box>
      <Grid container style={{ height: '100%', width: '100%' }}>
        <Grid item style={{ height: '100%', width: '100%', display: isPrototypeLoaded ? 'initial' : 'none' }}>
          <iframe
            title="figma-prototype"
            ref={iframeRef}
            onLoad={iframeLoadedHandler}
            height="100%"
            width="100%"
            src={prototypeURL}
            style={{ border: 'none' }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
