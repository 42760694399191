import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ReactComponent as FlagIcon } from '../../../../assets/intake-experience-flag.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/intake-experience-location.svg';
import { ExperienceEndScreenType } from '../../../../modules/intake/constants';
import Carousel from '../../components/Carousel';

const ExperienceStepsCarousel = ({
  experience,
  visitorsPerStep,
  firstItemIndex,
  handleStepIndex,
  itemHeight = 200,
  numItemsDisplayed = 4,
}) => {
  const steps = useMemo(() => experience.steps || [], [experience]);

  const startStep = useMemo(() => {
    return (steps || []).find(
      (step) => step?.configuration?.id === experience?.configuration?.entryPointNodes?.[0]
    );
  }, [experience, steps]);

  const destinationStep = useMemo(() => {
    return (steps || []).find((step) => step?.endScreenType === ExperienceEndScreenType.Success);
  }, [steps]);

  const getIcon = (stepId) => {
    if (stepId === startStep.id) {
      return <FlagIcon style={{ width: 16, height: 16 }} />;
    } else if (stepId === destinationStep.id) {
      return <LocationIcon style={{ width: 16, height: 16 }} />;
    } else return <></>;
  };

  return (
    <Carousel
      items={steps}
      itemComponent={({ index }) => {
        const stepId = steps[index].id;
        const image = steps[index].staticImage;
        const name = steps[index].configuration.name;
        const icon = getIcon(stepId);
        return (
          <Box
            sx={{
              height: '100%',
              borderRadius: '10px',
              paddingTop: 1,
              paddingBottom: 2,
              paddingX: { xs: 0, md: 1 },
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: 1,
                marginBottom: 1,
              }}>
              {icon}
              <Typography
                fontSize={14}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {name}
              </Typography>
            </Box>
            <Box
              sx={{
                position: 'relative',
                maxHeight: 140,
                overflow: 'hidden',
                borderRadius: '10px',
              }}>
              <img style={{ width: '100%' }} src={image?.url} alt="page screenshot" />
              {visitorsPerStep[stepId] === 0 && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  }}>
                  <CancelIcon fontSize="large" sx={{ color: 'rgba(255, 255, 255, 0.6)' }} />
                </Box>
              )}
            </Box>
          </Box>
        );
      }}
      onClick={handleStepIndex}
      firstItemIndex={firstItemIndex}
      itemHeight={itemHeight}
      numItemsDisplayed={numItemsDisplayed}
    />
  );
};

ExperienceStepsCarousel.propTypes = {
  experience: PropTypes.object.isRequired,
  visitorsPerStep: PropTypes.object.isRequired,
  firstItemIndex: PropTypes.number.isRequired,
  handleStepIndex: PropTypes.func.isRequired,
  itemHeight: PropTypes.number,
  numItemsDisplayed: PropTypes.number,
};

export default ExperienceStepsCarousel;
