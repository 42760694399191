const TrackEvent = {
  /** Userlytics Landing Page Events **/

  // Custom Events
  USERLYTICS_CLICKED_PRICING: 'Clicked on Userlytics Pricing Link',
  USERLYTICS_CLICKED_START: 'Clicked on Userlytics START Button',
  USERLYTICS_CLICKED_FAQ: 'Clicked on Userlytics FAQ Link',
  USERLYTICS_CLICKED_SCREENSHOT: 'Clicked on Userlytics Screenshot',

  /** Account Events **/

  // Custom Events
  USER_ACCEPTED_PULSE_INVITE: 'Accepted Pulse Invite',
  USER_LOGGED_OUT: 'Logged Out',

  /** Test Events **/

  // Page load Events
  LOADED_DASHBOARD: 'Loaded Dashboard',
  STARTED_CREATE_TEST: 'Started Create Test',
  STARTED_EDIT_TEST: 'Started Edit Test',
  VIEWED_EDIT_TEST_AUDIENCE: 'Viewed Edit Test Audience',
  VIEWED_EDIT_TEST_TYPE: 'Viewed Edit Test Type',
  VIEWED_EDIT_TEST_DETAILS: 'Viewed Edit Test Details',
  VIEWED_REVIEW_TEST: 'Viewed Review Test',
  VIEWED_TEST_DETAILS: 'Viewed Test Details',

  // Custom Events
  CREATED_TEST: 'Created Test',
  LAUNCHED_TEST: 'Launched Test',
  SAVE_AND_EXIT_TEST: 'Save and Exit Test',
  COPY_TEST_CLICKED: 'Clicked on Copy Test',
  ADD_TAG_CLICKED: 'Clicked on Add Tag',

  /** Report Events **/

  // Page Load Events
  VIEWED_REPORT_DASHBOARD: 'Viewed Report Dashboard',
  VIEWED_REPORT_JOURNEY_CUSTOM_QUESTIONS: 'Viewed Report Journey Custom Questions',
  VIEWED_REPORT_JOURNEY_DIAGNOSTICS: 'Viewed Report Journey Diagnostics',
  VIEWED_REPORT_JOURNEY_EXPECTATIONS: 'Viewed Report Journey Expectations',
  VIEWED_REPORT_PAGE_ATTRIBUTES: 'Viewed Report Page Attributes',
  VIEWED_REPORT_PAGE_FLOW: 'Viewed Report Page Flow',
  VIEWED_REPORT_PAGE_SENTIMENT: 'Viewed Report Page Sentiment',
  VIEWED_REPORT_TAKEAWAYS: 'Viewed Report Takeaways',
  VIEWED_REPORT_COMPARE_PAGES: 'Viewed Report Compare Pages',
  VIEWED_LIMITED_DASHBOARD_REPORT: 'Viewed Limited Dashboard Report',
  VIEWED_REPORT_CUSTOM_QUESTION: 'Viewed Report Custom Question',
  VIEWED_REPORT_EXPERIENCE_MAP: 'Viewed Report Experience Map',

  // Custom Events
  COPIED_SHARE_LINK: 'Copied Share Link',
  CLICKED_SHARE_BUTTON: 'Clicked Share Button',
  VIEWED_REPORT_TEST_DETAILS: 'Viewed Report Test Details',
  DOWNLOADED_REPORT: 'Downloaded Report',
  DOWNLOADED_CREDIT_USAGE_REPORT: 'Downloaded Credit Usage Report',
  DOWNLOADED_REPORT_TO_POWER_POINT: 'Downloaded Report To Power Point',
  DOWNLOADED_SENTIMENT_MAP: 'Downloaded Sentiment Map',
  DOWNLOADED_CUSTOM_CLICK_MAP: 'Downloaded Custom Click Map',
  DOWNLOADED_CLICK_TRACKING_MAP: 'Downloaded Click Tracking Map',
  SHOW_REPORT_PAGE: 'Report Clicked Through To Page',
  BOOKMARKED_QUOTE: 'Bookmarked Quote',
  REPORT_QUOTE: 'Quote Reported',
  CHANGED_BENCHMARKS: 'Changed Benchmarks',
  RESET_BENCHMARKS: 'Reset Benchmarks',
  FILTERED_QUOTES_BY_AUDIENCE: 'Filtered Quotes By Audience',
  FILTERED_QUOTES_BY_CUSTOM_SCREENER: 'Filtered Quotes By Custom Screener',
  FILTERED_QUOTES_BY_DIAGNOSITCS: 'Filtered Quotes By Diagnostics',
  FILTERED_QUOTES_BY_EMOTION: 'Filtered Quotes By Emotion',
  FILTERED_QUOTES_BY_EXPECTATION: 'Filtered Quotes By Expectation',
  FILTERED_QUOTES_BY_RATING: 'Filtered Quotes By Rating',
  FILTERED_QUOTES_BY_SENTIMENT: 'Filtered Quotes By Sentiment',
  FILTERED_QUOTES_BY_TRANSITION_TYPE: 'Filtered Quotes By Transition Type',
  FILTERED_QUOTES_BY_COMPOSITE_METRIC: 'Filtered Quotes By Composite Metric',
  FILTERED_OUTCOMES_BY_OUTCOME: 'Filtered Outcomes By Outcome',
  FILTERED_OUTCOMES_BY_TASK_TIME: 'Filtered Outcomes By Task Time',
  FILTERED_OUTCOMES_BY_EASE_SCORE: 'Filtered Outcomes By Ease Score',
  DESELECTED_QUOTE_FILTER: 'Deselected Quote Filter',
  CLEARED_QUOTE_FILTERS: 'Cleared Quotes Filters',
  SEARCHED_QUOTE: 'Searched Quote',
  VIEWED_COMPARE_PAGE_IMAGE_OVERLAY: 'Viewed Compare Page Image Overlay',
  TOGGLED_COMPARE_PAGE_VISIBILITY: 'Toggled Compare Page Visbility',
  SELECTED_COMPARE_PAGE_BASELINE: 'Selected Compare Page Baseline',
  VIEWED_COMPARE_PAGE_TABLE: 'Viewed Compare Page Table',
  CLICKED_COMPARE_PAGE_TABLE: 'Clicked on Compare Page Table',
  VIEWED_COMPARE_PAGE_GRAPH: 'Viewed Compare Page Graph',
  CLICKED_COMPARE_PAGE_GRAPH: 'Clicked on Compare Page Graph',
  CLICKED_COMPARE_PAGE_USABILITY_TABLE: 'Clicked on Compare Journey Usability Table',
  VIEWED_COMPARE_PAGE_SCORE_COMPARISON: 'Viewed Compare Page Score Comparison',
  VIEWED_COMPARE_PAGE_PERCENTAGE_COMPARISON: 'Viewed Compare Percentage Comparison',
  CLICKED_FEEDBACK_BUTTON: 'Clicked Feedback Button',
  VIEWED_CUSTOM_CLICK_MAP: 'Viewed Custom Click Map',

  /** Automated Insights (Pulse) Events **/

  // Page Load Events
  VIEWED_PULSE_SESSION_START: 'Pulse Session Start',
  VIEWED_PULSE_SESSION_INSIGHTS: 'Pulse Session Insights',
  VIEWED_PULSE_SESSION_IMPORT_URL_CONFIRMATION: 'Pulse Session Import URL Confirmation',
  VIEWED_PULSE_TRIAL_SIGN_UP: 'Pulse Trial Sign Up',
  VIEWED_PULSE_SUBSCRIPTION_PLANS: 'Pulse Subscription Plans',
  VIEWED_ACCOUNT_SETTINGS: 'Account Settings',

  // Custom Events
  UPLOADED_PULSE_ASSET: 'Uploaded Pulse Asset',
  CLICKED_PULSE_SHARE_BUTTON: 'Clicked Pulse Share Button',
  CLICKED_PULSE_DETAILS_BUTTON: 'Clicked Pulse Details Button',
  COPIED_PULSE_SHARE_LINK: 'Copied Pulse Share Link',
  COPIED_PULSE_FREE_TRIAL_SHARE_LINK: 'Copied Pulse Free Trial Share Link',
  VIEWED_PULSE_KEY_FINDINGS: 'Viewed Pulse Key Findings',
  VIEWED_PULSE_SENTIMENT_MAP: 'Viewed Pulse Sentiment Map',
  VIEWED_PULSE_PERSONA_INTENT: 'Viewed Pulse Persona Intent',
  VIEWED_PULSE_RECOMMENDED_EXPERIMENTS: 'Viewed Pulse Recommended Experiments',
  VIEWED_PULSE_QUANT: 'Viewed Pulse Quant',
  VIEWED_PULSE_CHAT: 'Viewed Pulse Chat',
  VIEWED_PULSE_MY_AUDIENCES: 'Viewed My Audiences',
  VIEWED_PULSE_AUDIENCE: 'Viewed Pulse Audience',
  VIEWED_PULSE_WITH_SHARE_LINK: 'Viewed Pulse With Share Link',
  VIEWED_PULSE_AS_USER: 'Viewed Pulse As User',
  FILTERED_PULSE_QUOTES_BY_SENTIMENT: 'Filtered Pulse Quotes By Sentiment',
  IMPORTED_PULSE_URL_ASSET: 'Uploaded Pulse Asset via URL',
  CLICKED_PULSE_GENERATE_SESSION_FROM_URL: 'Clicked Pulse Generate Session via URL Asset',
  CLICKED_PULSE_CANCEL_GENERATE_SESSION_FROM_URL: 'Clicked Pulse Try Again via URL Asset',
  REORDERED_PULSE_URL_ASSET: 'Reordered Pulse URL Asset',
  REPLACED_PULSE_URL_ASSET: 'Replaced Pulse URL Asset',
  ADDED_PULSE_URL_ASSET: 'Added Pulse URL Asset',
  DELETED_PULSE_URL_ASSET: 'Deleted Pulse URL Asset',
  CLICKED_INVITE_COLLEAGUE_BUTTON_FROM_PULSE: 'Clicked Invite Colleague Button From Pulse',
  CLICKED_RUN_STUDY_BUTTON_FROM_PULSE: 'Clicked Run Study Button From Pulse',
  CLICKED_RUN_PULSE_BUTTON_FROM_PULSE: 'Clicked Run Pulse Button From Pulse',
  CLICKED_PULSE_FAQ_BUTTON_FROM_PULSE: 'Clicked Pulse FAQ Button From Pulse',
  CLICKED_BACK_TO_WEVO_FROM_PULSE: 'Clicked Back To WEVO From Pulse',
  PULSE_FAQ_PAGE_LOAD: 'Pulse FAQ Page Load',
  GENERATE_PULSE_FROM_CUSTOM_AUDIENCE_EXISTING_MY_AUDIENCES:
    'Generate Pulse From Custom Audience: Existing My Audiences',
  GENERATE_PULSE_FROM_CUSTOM_AUDIENCE_NEW_AUDIENCE: 'Generate Pulse From Custom Audience: New Audience',
  // this could be either creating from the drop down when initially generating or creating in My Audiences
  GENERATE_PULSE_FROM_CUSTOM_AUDIENCE_SELECT_WHILE_GENERATING:
    'Generate Pulse From Custom Audience: Select While Generating',
  GENERATE_PULSE_WITH_GOAL_AND_PROPOSED_AUDIENCE: 'Generate Pulse With Goal And Proposed Audience',
  GENERATE_PULSE_WITH_GOAL_AND_EXISTING_AUDIENCE: 'Generate Pulse With Goal And Existing Audience',
  GENERATE_PULSE_WITH_GOAL_AND_NEW_AUDIENCE: 'Generate Pulse With Goal And New Audience',

  GENERATE_COMPARE_PULSE: 'Generate Compare Pulse',
  CLICKED_COMPARE_PULSE_LINKED_SESSION: 'Clicked Pulse Compare Linked Session',
  ADDED_COMPARE_PULSE_SESSION: 'Added Compare Pulse Session',
  DELETED_COMPARE_PULSE_SESSION: 'Deleted Compare Pulse Session',
  REORDERED_COMPARE_PULSE_SESSION: 'Reordered Compare Pulse Session',
  VIEWED_ALL_COMPARE_AUDIENCES: 'Viewed All Compare Pulse Audiences',
  VIEWED_COMPARE_PULSE_AUDIENCE: 'Viewed Compare Pulse Audience',

  SAVED_PULSE_AUDIENCE: 'Saved Pulse Audience',
  CLICKED_PULSE_NEW_AUDIENCE_BUTTON: 'Clicked Pulse New Audience Button',
  CLICKED_PULSE_CANCEL_AUDIENCE_CUSTOMIZATION_BUTTON: 'Clicked Pulse Cancel Audience Customization Button',
  SUBMITTED_EMAIL_FOR_PULSE_TRIAL: 'Submitted Email For Pulse Trial',
  RESEND_EMAIL_FOR_PULSE_TRIAL: 'Resend Email For Pulse Trial',
  GENERATE_PULSE_WITH_UNVERIFIED_EMAIL: 'Generate Pulse With Unverified Email',
  CLICKED_BUTTON_TO_EXPLORE_PLANS_FROM_BLOCKED_FEATURE: 'Clicked Button To Explore Plans From Blocked Feature',

  // Pulse Chat Events
  PULSE_CHAT_OPEN: 'Pulse Chat Open',
  PULSE_CHAT_SENT: 'Pulse Chat Sent',
  PULSE_CHAT_RECEIVED: 'Pulse Chat Received',
  PULSE_CHAT_ATTEMPTS_MULTIPLE_MESSAGES: 'Pulse Chat Attempts Multiple Messages',
  PULSE_CHAT_ATTEMPTS_LIMIT_EXCEEDED: 'Pulse Chat Attempts Limit Exceeded',
  SELECTED_SUGGESTED_PULSE_CHAT_QUESTION: 'Selected Suggested Pulse Chat Question',
  SELECTED_PULSE_CHAT_SUGGESTED_PROMPTS_MENU_ITEM: 'Selected Pulse Chat Suggested Prompts Menu Item',
  COPY_PULSE_KEY_FINDINGS: 'Copy Pulse Key Findings',

  // Pulse Partner Events
  IMPORTED_PULSE_URL_VIA_PARTNER_CODE: 'Imported Pulse URL via Partner Code',

  // Pulse Tab In-Out Events
  PULSE_USER_LEFT_SESSION_WINDOW: 'Pulse User Left Session Window',
  PULSE_USER_RETURNED_SESSION_WINDOW: 'Pulse User Returned To Session Window',

  // Pulse Action Bar Events
  PULSE_CLICKED_ACTION_BAR_SHARE_RESULTS: 'Pulse User Clicked Action Bar Share Results',
  PULSE_CLICKED_ACTION_BAR_TRY_OTHER_PERSONAS: 'Pulse User Clicked Action Bar Try Other Personas',
  PULSE_CLICKED_ACTION_BAR_NEW_PRO: 'Pulse User Clicked Action Bar New Pro',

  PULSE_QUANT_TO_UNDERSTAND: 'Pulse Quant To Understand',
  PULSE_QUANT_TO_ASK_QUESTIONS: 'Pulse Quant To Ask Questions',
  PULSE_QUANT_TO_NEXT_STEPS: 'Pulse Quant To Next Steps',

  PULSE_CLICK_THEME_ICON: 'Pulse Click Theme Icon',

  // Pulse Subscription Events
  PULSE_TRIAL_BANNER_CLICKED_UPGRADE: 'Pulse User Clicked Upgrade From Banner',
  PULSE_ACCOUNT_SETTINGS_CLICKED_UPGRADE: 'Pulse User Clicked Upgrade From Account Settings',
  PULSE_USER_CLICKED_VIEW_SEATS: 'Pulse User Clicked View Seats',
  PULSE_USER_CLICKED_ADD_SEATS: 'Pulse User Clicked Add Seats',
  PULSE_USER_CLICKED_INVITE_USER: 'Pulse User Clicked Invite User',
  PULSE_USER_CLICKED_CANCEL_SUBSCRIPTION: 'Pulse User Clicked Cancel Subscription',
  PULSE_USER_CLICKED_RESTART_SUBSCRIPTION: ' Pulse User Clicked Restart Subscription',
  PULSE_USER_PLAN_SELECTION_CLICKED_ADD_SEATS: 'Pulse User Clicked Add Seats From Plan Selection',
  PULSE_USER_PLAN_SELECTION_CLICKED_REMOVE_SEATS: 'Pulse User Clicked Remove Seats From Plan Selection',
  PULSE_USER_PLAN_SELECTION_CLICKED_CHANGE_PLAN: 'Pulse User Clicked Remove Seats From Plan Selection',
  PULSE_USER_PLAN_SELECTION_TOGGLED_BILLING_FREQUENCY:
    'Pulse User Toggled Billing Frequency From Plan Selection',
  PULSE_USER_PLAN_SELECTION_SELECTED_FREE_TIER: ' Pulse User Selected Free Tier From Plan Selection',
  PULSE_USER_PLAN_SELECTION_SELECTED_SELF_SERVICE_PAID_TIER:
    ' Pulse User Selected Self-Service Paid Tier From Plan Selection',
  PULSE_USER_PLAN_SELECTION_SELECTED_CHANGE_SELF_SERVICE_PAID_TIER:
    ' Pulse User Selected Change Self-Service Paid Tier From Plan Selection',
  PULSE_USER_PLAN_SELECTION_SELECTED_ENTERPRISE_TIER:
    ' Pulse User Selected Enterprise Tier From Plan Selection',
  PULSE_USER_PLAN_SELECTION_CHANGED_PLAN: ' Pulse User Changed Plan From Plan Selection',
  PULSE_USER_CLICKED_EXPLORE_PLANS: 'Clicked Explore Plans',
  PULSE_USER_ADDED_SEATS: 'Pulse User Added Seats To Subscription',
  PULSE_USER_INVITED_NEW_USERS: 'Pulse User Invited New Users',
  PULSE_USER_ASSIGNED_USERS_TO_SEATS: 'Pulse User Assigned Users To Seats',
  PULSE_USER_REMOVED_USER: 'Pulse User Removed User Access To Pulse',
  PULSE_USER_RESTORED_USER: 'Pulse User Restored User Access To Pulse',
};

export default TrackEvent;
