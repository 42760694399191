import {
  getSelectionText,
  isSelectionExpanded,
  useEditorState,
  useEventEditorSelectors,
} from '@udecode/plate-headless';
import { useEffect, useState } from 'react';
import { useFocused } from 'slate-react';
import { getSelectionBoundingClientRect, isLink } from '../../modules/plate/constants';
import usePopperPosition from './usePopperPosition';

const usePlatePopper = (options) => {
  const focusedEditorId = useEventEditorSelectors.focus();
  const editor = useEditorState();
  const focused = useFocused();

  const [isHidden, setIsHidden] = useState(true);

  const selectionExpanded = editor && isSelectionExpanded(editor);
  const selectionText = editor && getSelectionText(editor);
  const cursorInLink = isLink(editor) && focused && editor.id === focusedEditorId;

  useEffect(() => {
    if (cursorInLink) {
      setIsHidden(false);
    } else if (!selectionExpanded || !selectionText || !focused || editor.id !== focusedEditorId) {
      setIsHidden(true);
    } else if (selectionText && selectionExpanded) {
      setIsHidden(false);
    }
  }, [cursorInLink, focused, focusedEditorId, selectionExpanded, selectionText, editor.id]);

  const popperResult = usePopperPosition({
    isHidden,
    getBoundingClientRect: getSelectionBoundingClientRect,
    ...options,
  });

  const selectionTextLength = selectionText?.length ?? 0;
  const { update } = popperResult;

  useEffect(() => {
    if (selectionTextLength > 0) {
      update?.();
    }
  }, [selectionTextLength, update]);

  return popperResult;
};

export default usePlatePopper;
