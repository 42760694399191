import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * A dialog to allow the user to confirm if they wish to discard their current target audience criteria
 * and therefore change the price
 * @param {{open: boolean, discardTargetAudience: function, closeCallback: function}} props
 * @returns The discard target audience dialog component
 */
const DiscardTargetAudienceDialog = ({ open, discardTargetAudience, closeCallback }) => {
  const handleClose = () => {
    !!closeCallback && closeCallback();
  };

  const handleReset = () => {
    !!discardTargetAudience && discardTargetAudience();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="discard target audience confirmation dialog"
        aria-describedby="discard-target-audience-confirmation-description">
        <DialogTitle>Switching Target Audience Criteria</DialogTitle>
        <DialogContent>
          <DialogContentText id="discard-target-audience-confirmation-description">
            Switching your audience may impact price of study. Is that okay?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Yup! Switch Audience Criteria</Button>
          <Button onClick={handleClose}>Do Nothing</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DiscardTargetAudienceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  discardTargetAudience: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

export default React.memo(DiscardTargetAudienceDialog);
