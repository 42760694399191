import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { BounceLoader } from 'react-spinners';
import { ReactComponent as UploadIcon } from '../../../src/assets/pulse-image-upload-icon.svg';
import { BlockedFeature, MAX_PULSE_JOURNEY_ASSETS } from '../../modules/automated-insights/constants';
import { getFilesWithError } from '../../modules/automated-insights/helpers';
import FeatureTooltip from './FeatureTooltip';

const MAX_FILE_SIZE = 20000000;
const ACCEPTED_FILE_TYPES = {
  'image/jpeg': [],
  'image/png': [],
};

const FILE_ERROR_CODES = {
  FILE_TOO_LARGE: 'file-too-large',
  TOO_MANY_FILES: 'too-many-files',
};

const FileTooLargeError = ({ fileRejections }) => {
  const largeFiles = getFilesWithError(fileRejections, FILE_ERROR_CODES.FILE_TOO_LARGE);

  if (largeFiles.length === 0) return <></>;

  return (
    <Box>
      <Typography variant="caption" sx={{ display: 'block', color: 'text.secondary' }}>
        Files must be less than 20 MB:
      </Typography>
      <Box>
        {largeFiles?.map((file, index) => (
          <Typography
            key={`${index}-${file?.file?.name}`}
            variant="caption"
            sx={{
              display: 'block',
              color: 'text.secondary',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {file?.file?.name}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};

const AssetUploadCard = ({
  handleFileInput,
  multiple,
  iconColor,
  isUploading,
  maxFiles = 1,
  background,
  disableFeatures,
  freeTrialExpired,
  subscriptionExpired,
  reachedUsageLimit,
  user,
}) => {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: ACCEPTED_FILE_TYPES,
    maxFiles: maxFiles,
    maxSize: MAX_FILE_SIZE,
    multiple: multiple,
    onDrop: handleFileInput,
  });

  const hasFileTooLarge = useMemo(() => {
    const largeFiles = getFilesWithError(fileRejections, FILE_ERROR_CODES.FILE_TOO_LARGE);
    return largeFiles?.length > 0;
  }, [fileRejections]);

  const tooManyFilesError = useMemo(() => {
    if (fileRejections.length > 0) {
      const filesExceedingLimit = getFilesWithError(fileRejections, FILE_ERROR_CODES.TOO_MANY_FILES);
      if (filesExceedingLimit.length === 0) return '';

      return `You can only upload up to ${MAX_PULSE_JOURNEY_ASSETS} files`;
    }
    return '';
  }, [fileRejections]);

  const getCardContent = () => {
    return (
      <>
        {!isUploading && (
          <>
            <input {...getInputProps()} disabled={disableFeatures} />
            <Grid container item direction="column">
              <Grid item xs={12} sx={{ width: '100%' }}>
                <Box>
                  <Box my={2}>
                    <UploadIcon style={{ color: isDragActive && !isDragReject ? '#FFFFFF' : iconColor }} />
                  </Box>
                  <Typography color="primary" variant="body2">
                    Drag and drop or{' '}
                    <Typography component="span" variant="body2" sx={{ color: '#43BCFF' }}>
                      Choose{' '}
                    </Typography>
                    one or multiple files to upload
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'rgba(221, 221, 221, 0.83)' }}>
                    PNG, JPG or JPEG
                  </Typography>
                </Box>
              </Grid>
              {isDragReject && (
                <Grid item xs={12} mt={1} sx={{ width: '100%' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    Sorry, either the file type is not accepted or too many files were uploaded.
                  </Typography>
                </Grid>
              )}
              {hasFileTooLarge && (
                <Grid item xs={12} mt={1} sx={{ width: '100%' }}>
                  <FileTooLargeError fileRejections={fileRejections} />
                </Grid>
              )}
              {tooManyFilesError && (
                <Grid item xs={12} mt={1} sx={{ width: '100%' }}>
                  <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                    {tooManyFilesError}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
        {isUploading && (
          <Grid container direction="column" alignItems="center" spacing={2}>
            <Grid item>
              <BounceLoader color="#FFFFFF" size={40} aria-label="loading spinner" />
            </Grid>
            <Grid item>
              <Typography sx={{ color: 'text.secondary' }}>Uploading...</Typography>
            </Grid>
          </Grid>
        )}
      </>
    );
  };

  return (
    <Box
      sx={{
        cursor: disableFeatures ? 'not-allowed' : 'pointer',
        background: (theme) => background || theme.palette.gradient.main,
        borderRadius: '10px',
      }}>
      <FeatureTooltip
        blockedFeature={BlockedFeature.AssetUpload}
        disableFeatures={disableFeatures}
        freeTrialExpired={freeTrialExpired}
        subscriptionExpired={subscriptionExpired}
        reachedUsageLimit={reachedUsageLimit}
        user={user}>
        <Grid
          container
          sx={{ minHeight: 175, border: '1px solid rgba(217, 217, 217, 0.2)', borderRadius: '10px' }}>
          {disableFeatures ? (
            <Grid
              container
              item
              alignItems="center"
              sx={{
                textAlign: 'center',
                padding: 2,
              }}>
              {getCardContent()}
            </Grid>
          ) : (
            <Grid
              container
              item
              alignItems="center"
              {...getRootProps()}
              sx={{
                textAlign: 'center',
                padding: 2,
              }}>
              {getCardContent()}
            </Grid>
          )}
        </Grid>
      </FeatureTooltip>
    </Box>
  );
};

AssetUploadCard.propTypes = {
  handleFileInput: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  background: PropTypes.string,
  iconColor: PropTypes.string.isRequired,
  isUploading: PropTypes.bool.isRequired,
};

AssetUploadCard.defaultProps = {
  multiple: false,
};

export default memo(AssetUploadCard);
