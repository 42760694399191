import PeopleIcon from '@mui/icons-material/People';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import theme from '../../../../theme';
import useFetchSurveyDetails from '../../hooks/useFetchSurveyDetails';

const RespondentsCard = (props) => {
  const { wevo, page } = props;

  const surveyDetailsResult = useFetchSurveyDetails({
    wevoId: wevo?.id,
    pageId: page?.id,
  });

  const numRespondents = useMemo(() => {
    return surveyDetailsResult?.data?.numRespondents ?? '?';
  }, [surveyDetailsResult]);

  return (
    <Card elevation={4} sx={{ borderRadius: '20px', height: '100%' }}>
      <CardHeader sx={{ height: '50%' }} />
      <CardContent sx={{ height: '50%' }}>
        <Grid
          container
          spacing={1}
          justifyContent="center"
          sx={{
            marginTop: theme.spacing(-7),
            padding: 1,
          }}>
          <Grid item xs={12} sx={{ marginBottom: '-8px' }}>
            <Typography variant="h4" sx={{ color: theme.palette.primary.main, textAlign: 'center' }}>
              {numRespondents}
            </Typography>
          </Grid>
          <Grid item xs={12} sx={{ fontSize: '13px', textAlign: 'center' }}>
            <PeopleIcon
              sx={{
                color: 'white',
                stroke: 'black',
                strokeWidth: '1px',
                marginRight: '8px',
                marginBottom: '-5px',
              }}
            />
            respondents
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RespondentsCard;
