import { useIsMutating, useQuery } from 'react-query';
import api from '../api';
import { MutationKeys } from '../modules/intake/constants';

const fetchWevo = async ({ queryKey: [key, { wevoId }] }) => {
  if (!wevoId) {
    return {};
  }
  const response = await api({
    url: `/api/v2/wevos/${wevoId}`,
    method: 'GET',
  });
  return response?.data?.wevo;
};

export default function useWevo(wevoId, options) {
  const numOngoingMutations = useIsMutating({
    predicate: (mutation) =>
      Object.values(MutationKeys).includes(mutation.options.mutationKey) &&
      mutation.options.variables.wevoId === wevoId,
  });
  return useQuery(['wevoData', { wevoId }], fetchWevo, {
    notifyOnChangeProps: 'tracked',
    enabled: numOngoingMutations === 0, // don't refetch this query while relevant mutations are ongoing,
    retry: false,
    initialData: {},
    ...(options ?? {}),
  });
}
