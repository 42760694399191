import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { get as _get, set as _set, sortBy as _sortBy } from 'lodash';
import produce from 'immer';
import { MutationKeys } from '../../../modules/intake/constants';
import { WevoType } from '../../../modules/wevos/constants';

const bulkUpdateAssets = ({ wevoId, changes }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/assets`,
    method: 'PUT',
    data: { changes },
  });
};

export default function useBulkUpdateAssets() {
  const queryClient = useQueryClient();
  return useMutation(bulkUpdateAssets, {
    mutationKey: MutationKeys.bulkUpdateAssets,
    onMutate: async ({ wevoId, changes }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      queryClient.setQueryData(['wevoData', { wevoId }], (oldData) => {
        if (oldData.type === WevoType.Classic) {
          return produce(oldData, (draft) => {
            const pages = _get(draft, 'pages', []);
            for (const page of changes) {
              const index = pages.findIndex((p) => String(p.id) === String(page.id));
              if (index >= 0) {
                pages[index] = {
                  ...pages[index],
                  ...page,
                };
              }
            }
            _set(draft, 'pages', _sortBy(pages, ['sortOrder']));
          });
        } else {
          // Journey
          return produce(oldData, (draft) => {
            const steps = _get(draft, 'pages.0.steps', []);

            for (const step of changes) {
              const index = steps.findIndex((s) => String(s.id) === String(step.id));
              if (index >= 0) {
                steps[index] = {
                  ...steps[index],
                  ...step,
                };
              }
            }
            _set(draft, 'pages.0.steps', _sortBy(steps, ['sortOrder']));
          });
        }
      });

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}

const bulkUpdateAssetsV2 = ({ wevoId, changes }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/assets/v2`,
    method: 'PUT',
    data: { changes },
  });
};

export function useBulkUpdateAssetsV2() {
  const queryClient = useQueryClient();
  return useMutation(bulkUpdateAssetsV2, {
    mutationKey: MutationKeys.bulkUpdateAssetsV2,
    onMutate: async ({ wevoId, changes }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      queryClient.setQueryData(['wevoData', { wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const pages = draft?.pages ?? [];

          for (const change of changes) {
            const pageId = change?.pageId;
            const pageIndex = pages.findIndex((p) => String(p.id) === String(pageId));

            if (pageIndex > 0) {
              const stepId = change?.stepId;

              // if stepId, then we're applying a change to the step
              if (stepId) {
                const steps = pages[pageIndex]?.steps ?? [];
                const stepIndex = steps.findIndex((s) => String(s.id) === String(stepId));

                if (stepIndex > 0) {
                  pages[pageIndex].steps[stepIndex] = { ...pages[pageIndex].steps[stepIndex], ...change };
                }

                continue;
              }

              // otherwise, we're applying the change directly to the page
              pages[pageIndex] = { ...pages[pageIndex], ...change };
            }
          }
        });
      });

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
