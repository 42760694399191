import { isEmpty as _isEmpty, omit as _omit } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import axios from '../../../api';
import { getUserProfile } from '../../../modules/user/selectors';

const toggleKeyFindingVote = ({ wevoId, pageId, elementId, userId, upvotes }) => {
  if (upvotes?.[elementId]?.includes(userId)) {
    return deleteKeyFindingVote({ wevoId, pageId, elementId });
  } else {
    return upvoteKeyFinding({ wevoId, pageId, elementId });
  }
};

const deleteKeyFindingVote = ({ wevoId, pageId, elementId }) => {
  if (pageId) {
    return axios({
      url: `/api/v2/wevos/${wevoId}/pages/${pageId}/key-findings/votes`,
      method: 'DELETE',
      data: {
        elementId,
      },
    });
  } else {
    return axios({
      url: `/api/v2/wevos/${wevoId}/key-findings/votes`,
      method: 'DELETE',
      data: {
        elementId,
      },
    });
  }
};

const upvoteKeyFinding = ({ wevoId, pageId, elementId }) => {
  if (pageId) {
    return axios({
      url: `/api/v2/wevos/${wevoId}/pages/${pageId}/key-findings/votes`,
      method: 'POST',
      data: {
        elementId,
      },
    });
  } else {
    return axios({
      url: `/api/v2/wevos/${wevoId}/key-findings/votes`,
      method: 'POST',
      data: {
        elementId,
      },
    });
  }
};

export default function useToggleKeyFindingVote() {
  const queryClient = useQueryClient();
  const { id: userId } = useSelector(getUserProfile);
  return useMutation(toggleKeyFindingVote, {
    onMutate: ({ wevoId, pageId, elementId }) => {
      const previousKeyFindingVotes = queryClient.getQueryData(['keyFindingVotes', { wevoId, pageId }]);
      queryClient.setQueryData(['keyFindingVotes', { wevoId, pageId }], (currVotes) => {
        if (currVotes[elementId]) {
          // there are already votes for this element so we must determine if we need to add this user id or remove it
          const indexToRemove = currVotes[elementId].indexOf(userId);
          if (indexToRemove >= 0) {
            // user has already upvoted this element so we should remove their id from the array
            const newVotesForElementId = [
              ...currVotes[elementId].slice(0, indexToRemove),
              ...currVotes[elementId].slice(indexToRemove + 1, currVotes.length - 1),
            ];
            if (_isEmpty(newVotesForElementId)) {
              // if this element now has no votes, remove it from the votes maps
              return _omit(currVotes, elementId);
            } else {
              return { ...currVotes, [elementId]: newVotesForElementId };
            }
          } else {
            return {
              ...currVotes,
              [elementId]: [...currVotes[elementId], userId],
            };
          }
        } else {
          return {
            ...currVotes,
            [elementId]: [userId],
          };
        }
      });
      return { previousKeyFindingVotes };
    },
    onSuccess: (res, { wevoId, pageId }) => {
      queryClient.setQueryData(['keyFindingVotes', { wevoId, pageId }], res.data?.votes);
    },
    onError: (err, { wevoId, pageId }, { previousKeyFindingVotes }) => {
      queryClient.setQueryData(['keyFindingVotes', { wevoId, pageId }], previousKeyFindingVotes);
    },
  });
}
