import { produce } from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { SavedAudienceMutationKeys } from '../../../modules/intake/constants';

const addSavedAudience = async ({ id: wevoId, name, draft }) => {
  const response = await axios({
    url: `/api/v2/saved-audiences/groups/wevos/${wevoId}`,
    method: 'POST',
    data: { name, draft },
  });
  return response.data.group;
};

export default function useAddSavedAudience() {
  const queryClient = useQueryClient();

  return useMutation(addSavedAudience, {
    mutationKey: SavedAudienceMutationKeys.addSavedAudience,
    onSuccess: (group) => {
      queryClient.setQueryData(['savedAudienceData'], (oldData) => {
        return produce(oldData, (draftState) => {
          draftState.push(group);
        });
      });
    },
  });
}
