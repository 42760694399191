import { Box, CircularProgress } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';
import { WevoType } from '../../../modules/wevos/constants';
import { ClusterTypes } from '../../../modules/report/constants';
import { filterQuotes } from '../../../modules/wevos/quotes';
import useFetchPageHeatmapSummary from '../../reports/hooks/useFetchPageHeatmapSummary';
import useFetchStepHeatmapSummary from '../../reports/hooks/useFetchStepHeatmapSummary';
import Heatmap from '../components/Heatmap';
import HeatmapCarousel from './HeatmapCarousel';

const renderHeatmap = (image, points, handleRegionChange, heatmapBgID, sentimentMapID) => {
  if (!points || !image) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <Heatmap
        image={image}
        data={points}
        onSelectRegionChange={handleRegionChange}
        heatmapBgID={heatmapBgID}
        sentimentMapID={sentimentMapID}
      />
    );
  }
};

export const HeatmapPreviewThumbnail = memo(function ({ wevo, page, step, selectedCluster }) {
  const heatMap = page?.images?.heatmap || step?.images?.heatmap;

  const { data: pageHeatMapPoints, isSuccess: isSuccessPageHeatMap } = useFetchPageHeatmapSummary(
    {
      wevoId: wevo?.id,
      pageId: page?.id,
    },
    {
      enabled: wevo.type === WevoType.Classic,
    }
  );

  const { data: stepHeatMapPoints, isSuccess: isSuccessStepHeatMap } = useFetchStepHeatmapSummary(
    {
      wevoId: wevo?.id,
      stepId: wevo.type === WevoType.Classic ? null : step?.id,
    },
    {
      enabled: wevo.type === WevoType.Journey,
    }
  );

  const points = useMemo(() => {
    if (wevo.type === WevoType.Journey) {
      if (isSuccessStepHeatMap && stepHeatMapPoints.length > 0) {
        return stepHeatMapPoints.filter((point) => !_.isNil(point.x) && !_.isNil(point.y));
      }
    } else {
      if (isSuccessPageHeatMap && pageHeatMapPoints.length > 0) {
        return pageHeatMapPoints.filter((point) => !_.isNil(point.x) && !_.isNil(point.y));
      }
    }
  }, [isSuccessPageHeatMap, isSuccessStepHeatMap, pageHeatMapPoints, stepHeatMapPoints, wevo.type]);

  const likePoints = useMemo(
    () => (!_.isNil(points) ? filterQuotes(points, { cluster: ClusterTypes.Likes }) : null),
    [points]
  );
  const dislikePoints = useMemo(
    () => (!_.isNil(points) ? filterQuotes(points, { cluster: ClusterTypes.Dislikes }) : null),
    [points]
  );

  const heatmapBgImageID = useMemo(
    () => `heatmap-bg-thumbnail-page-${page?.id}-step-${step?.id || 'none'}-${selectedCluster}`,
    [page, step, selectedCluster]
  );

  const thumbnailSentimentMapID = useMemo(
    () => `sentiment-map-thumbnail-page-${page?.id}-step-${step?.id || 'none'}-${selectedCluster}`,
    [page, step, selectedCluster]
  );

  return (
    <>
      {selectedCluster === ClusterTypes.Likes
        ? renderHeatmap(heatMap, likePoints, () => {}, heatmapBgImageID, thumbnailSentimentMapID)
        : renderHeatmap(heatMap, dislikePoints, () => {}, heatmapBgImageID, thumbnailSentimentMapID)}
    </>
  );
}, _.isEqual);

HeatmapPreviewThumbnail.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  step: PropTypes.object,
  selectedCluster: PropTypes.string,
};

const HeatmapPreviewThumbnails = ({
  wevo,
  selectedPage,
  selectedStep,
  handleAssetSelected,
  selectedCluster,
  links,
}) => {
  const assets = (wevo?.type === WevoType.Classic ? wevo?.pages : selectedPage?.steps) ?? [];
  const currentAsset = wevo?.type === WevoType.Classic ? selectedPage : selectedStep;

  if (assets?.length < 2) {
    return <></>;
  }

  return (
    <HeatmapCarousel
      wevo={wevo}
      page={selectedPage}
      currentAsset={currentAsset}
      assets={assets}
      selectedCluster={selectedCluster}
      onSelection={handleAssetSelected}
      links={links}
    />
  );
};

HeatmapPreviewThumbnails.propTypes = {
  wevo: PropTypes.object.isRequired,
  selectedPage: PropTypes.object.isRequired,
  selectedStep: PropTypes.object,
  handleAssetSelected: PropTypes.func,
  selectedCluster: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.string),
};

export default HeatmapPreviewThumbnails;
