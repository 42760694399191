import { Box, Button, CircularProgress, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useCheckPrototypeURL } from '../../../../../hooks/useValidatePrototypes';
import { IntakePrototypeImportStages, TestTypes } from '../../../../../modules/intake/constants';
import { getUserCustomizations } from '../../../../../modules/user/selectors';
import { AuthenticExperienceOption } from '../../../../../modules/wevos/constants';
import DebouncedInput from '../../DebouncedInput';

function JourneyNameInput({
  name,
  inputLabel = 'Journey Name',
  placeholderLabel = 'Add your Journey name',
  handleNameChanged,
}) {
  return (
    <DebouncedInput
      value={name}
      onChange={handleNameChanged}
      debounceMs={500}
      renderInput={({ value, onChange }) => (
        <TextField
          required
          fullWidth
          variant="outlined"
          label={inputLabel}
          value={value}
          placeholder={placeholderLabel}
          onChange={onChange}
        />
      )}
    />
  );
}

JourneyNameInput.propTypes = {
  name: PropTypes.string.isRequired,
  handleNameChanged: PropTypes.func,
};

function JourneyStartURLTitle({ testType, isLiteTestType }) {
  const getHeading = () => {
    if (testType === TestTypes.Usability) {
      return 'Experience Start (Desktop only)';
    } else if (isLiteTestType) {
      return 'Single Page or Journey URL (Optional)';
    } else {
      return 'Journey Prototype';
    }
  };
  return (
    <>
      <Typography variant="h5">{getHeading()}</Typography>
      <Typography py={1} style={{ fontWeight: 400 }} gutterBottom>
        If using a prototype or live site for your test, please insert the URL below.
      </Typography>
      {testType === TestTypes.Usability && (
        <Typography py={1} style={{ fontWeight: 400, marginTop: '-20px' }} gutterBottom>
          Note: In order to access usability metrics and clickpath, your prototype must be a Figma file.
        </Typography>
      )}
    </>
  );
}

JourneyStartURLTitle.propTypes = {
  testType: PropTypes.string.isRequired,
};

const useJourneyStartUrlStyles = makeStyles((theme) => ({
  infoIcon: {
    verticalAlign: 'bottom',
    color: grey[600],
  },
  invisibleIcon: {
    fontSize: '14px',
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.25),
  },
  div: { position: 'relative' },
  spinner: {
    position: 'absolute',
    top: '16px',
    right: '16px',
  },
  importButton: {
    borderRadius: 12,
    position: 'absolute',
    top: '10px',
    right: '8px',
  },
  helperText: {
    fontSize: '12px',
    color: 'grey',
    marginTop: '8px',
    marginLeft: '14px',
  },
  textField: {
    '& .MuiInputBase-input': {
      maxWidth: ({ size }) => (size === 'md' ? 590 : 440),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
}));

function JourneyStartURLInput({
  handleJourneyStartUrlChanged,
  handleImportPrototype,
  prototypeImportStage,
  journeyStartUrl,
  helperText = 'Note: A Journey should take less than 3 minutes to complete.',
  size = 'md',
  prototypeImportEnabled = false,
}) {
  const classes = useJourneyStartUrlStyles({ size });

  const userCustomizations = useSelector(getUserCustomizations);
  const hasAuthenticExperience = userCustomizations?.authenticExperience === AuthenticExperienceOption.Enabled;

  const { data: isFigmaURL } = useCheckPrototypeURL(journeyStartUrl, { enabled: !!journeyStartUrl });

  const isImportingPrototype = prototypeImportStage !== IntakePrototypeImportStages.Ready;

  const inputComponent = (
    <DebouncedInput
      value={journeyStartUrl}
      onChange={handleJourneyStartUrlChanged}
      debounceMs={500}
      renderInput={({ value, onChange }) => (
        <TextField
          fullWidth
          variant="outlined"
          value={value}
          placeholder="Add live or prototype URL"
          onChange={onChange}
          helperText={helperText}
          className={hasAuthenticExperience ? classes.textField : ''}
          disabled={hasAuthenticExperience && isImportingPrototype}
        />
      )}
    />
  );

  const importButton =
    isFigmaURL && prototypeImportEnabled ? (
      <Box>
        {isImportingPrototype ? (
          <CircularProgress size={25} className={classes.spinner} />
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={handleImportPrototype}
            className={classes.importButton}>
            Import
          </Button>
        )}
      </Box>
    ) : (
      <></>
    );

  return (
    <Box sx={{ position: 'relative' }}>
      {inputComponent}
      {importButton}
    </Box>
  );
}

JourneyStartURLInput.propTypes = {
  handleJourneyStartUrlChanged: PropTypes.func,
  handleImportPrototype: PropTypes.func,
  handleDeletePrototype: PropTypes.func,
  helperText: PropTypes.string,
  journeyStartUrl: PropTypes.string.isRequired,
  prototypeImportStage: PropTypes.string,
};

export { JourneyNameInput, JourneyStartURLInput, JourneyStartURLTitle };
