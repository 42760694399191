import { useMutation } from 'react-query';
import axios from '../../../api';

const generateBillingPortalLink = async () => {
  const response = await axios({
    url: `/api/v2/pulse/billing-portal`,
    method: 'GET',
  });

  return response?.data;
};

export default function useGenerateBillingPortalLink(options) {
  return useMutation(generateBillingPortalLink, options);
}
