export const basePath = '/pulse';

export const session = `${basePath}/sessions/:sessionId`;
export const sessionAssets = `${basePath}/sessions/:sessionId/assets`;
export const sessionSettings = `${basePath}/sessions/:sessionId/settings`;
export const profile = `${basePath}/profile`;
export const accountSettings = `${basePath}/account-settings`;
export const password = `${basePath}/profile/password`;
export const acceptInvite = `${basePath}/accept-invite/:token`;
export const faq = `${basePath}/faq`;
export const allFaq = `${basePath}/all-faq`;
export const importURL = `${basePath}/import-url/:importUrlId`;
export const trial = `${basePath}/free-trial`;
export const subscription = `${basePath}/subscription`;
