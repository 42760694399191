import produce from 'immer';
import { isNil as _isNil, set as _set } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomQuestionMutationKeys, CustomQuestionTypes } from '../../../modules/intake/constants';

const OPTIMISTIC_UPDATE_FIELDS = [
  'type',
  'name',
  'questionText',
  'followUpQuestionText',
  'labels',
  'labelsType',
  'scopes',
  'validation',
];

const updateCustomQuestion = async ({ id: wevoId, groupId, ...changedFields }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/custom-questions/groups/${groupId}`,
    method: 'PUT',
    data: changedFields,
  });
  return response.data.group;
};

/**
 * Custom hook for updating a customQuestionGroup.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the updated data
 */
export default function useUpdateCustomQuestion() {
  const queryClient = useQueryClient();

  return useMutation(updateCustomQuestion, {
    mutationKey: CustomQuestionMutationKeys.updateCustomQuestion,
    onMutate: async ({ id: wevoId, groupId, ...changedFields }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['customQuestionsData', { wevoId }]);
      // Snapshot the previous customQuestionsData value
      const previousCustomQuestionsState = await queryClient.getQueryData(['customQuestionsData', { wevoId }]);

      if (Object.keys(changedFields).some((field) => OPTIMISTIC_UPDATE_FIELDS.includes(field))) {
        const updatedCustomQuestionState = produce(previousCustomQuestionsState, (draftState) => {
          const index = draftState.findIndex((q) => String(q.groupId) === String(groupId));
          if (index >= 0) {
            if (!_isNil(changedFields.name)) {
              _set(draftState[index], 'name', changedFields.name);
            }
            if (!_isNil(changedFields.questionText)) {
              _set(draftState[index], 'question.questionText', changedFields.questionText);
            }
            if (!_isNil(changedFields.followUpQuestionText)) {
              _set(draftState[index], 'question.followUpQuestionText', changedFields.followUpQuestionText);
            }
            if (!_isNil(changedFields.type)) {
              _set(draftState[index], 'question.type', changedFields.type);
              if (changedFields.type !== CustomQuestionTypes.MultiSelect) {
                _set(draftState[index], 'question.rangeType', null);
                _set(draftState[index], 'question.number', null);
              }
            }
            if (!_isNil(changedFields.labels)) {
              _set(draftState[index], 'question.labels', changedFields.labels);
            }
            if (!_isNil(changedFields.labelsType)) {
              _set(draftState[index], 'question.labelsType', changedFields.labelsType);
            }
            if (!_isNil(changedFields.scopes)) {
              _set(draftState[index], 'scopes', changedFields.scopes);
            }
            if (!_isNil(changedFields?.validation?.rangeType)) {
              _set(draftState[index], 'question.rangeType', changedFields.validation.rangeType);
            }
            if (!_isNil(changedFields?.validation?.number)) {
              _set(draftState[index], 'question.number', changedFields.validation.number);
            }
          }
        });
        queryClient.setQueryData(['customQuestionsData', { wevoId }], updatedCustomQuestionState);
      }

      // Return a context object with the snapshotted value
      return { previousCustomQuestionsState };
    },

    onSuccess: (group, { id: wevoId }) => {
      queryClient.setQueryData(['customQuestionsData', { wevoId }], (oldData) => {
        return produce(oldData, (draftState) => {
          const index = draftState.findIndex((q) => String(q.groupId) === String(group.groupId));
          draftState[index].isScopesSectionValid = group.isScopesSectionValid;
        });
      });
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { id: wevoId, groupId, ...changedFields }, { previousCustomQuestionsState }) => {
      queryClient.setQueryData(['customQuestionsData', { wevoId }], previousCustomQuestionsState);
    },
  });
}
