import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import Lightbox from 'react-image-lightbox';

const ImagePreview = (props) => {
  const { image, maxHeight, maxWidth, scopeImage } = props;
  const [isOpen, setIsOpen] = useState(false);

  const url = image?.mini?.url || image?.original?.url;

  const handleOnClick = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  return (
    <Fragment>
      <img
        style={{
          height: scopeImage ? '110px' : 'auto',
          width: '100%',
          maxHeight,
          maxWidth,
          objectFit: 'cover',
          objectPosition: '0% 0',
          border: '1px solid #979797',
          cursor: 'pointer',
        }}
        src={url}
        alt={image.name}
        onClick={handleOnClick}
      />

      {isOpen && (
        <Lightbox
          mainSrc={url}
          onCloseRequest={handleOnClick}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
    </Fragment>
  );
};

ImagePreview.propTypes = {
  image: PropTypes.object.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  scopeImage: PropTypes.bool,
};

export default ImagePreview;
