import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { DEVICE_ID_TO_NAME, DEVICE_NAME_TO_DEVICE_ID } from '../../modules/wevos/constants';

const DeviceFilter = ({ value = '', onChange }) => {
  const handleChange = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <FormControl variant="standard">
      <InputLabel id="device-filter-heading" shrink>
        Device
      </InputLabel>
      <Select
        variant="standard"
        style={{ textTransform: 'capitalize' }}
        autoWidth
        value={value}
        onChange={handleChange}
        aria-labelledby="device-filter-heading"
        displayEmpty
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}>
        <MenuItem value={''}>All</MenuItem>
        {Object.values(DEVICE_NAME_TO_DEVICE_ID).map((deviceId) => (
          <MenuItem key={`device-filter-${deviceId}`} value={deviceId} style={{ textTransform: 'capitalize' }}>
            {DEVICE_ID_TO_NAME[deviceId]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DeviceFilter;
