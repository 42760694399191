import styled from '@emotion/styled';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import {
  AutomatedInsightSessionType,
  MainContainerDimensions,
} from '../../modules/automated-insights/constants';
import {
  canUseAutomatedInsightSessions,
  isAutomatedInsightSessionShareLink,
} from '../../modules/automated-insights/helpers';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { snackbar } from '../../notifications';
import { Paths } from '../../routes';
import { TrackEvent } from '../analytics';
import SessionAssetGrid from './SessionAssetGrid';
import { useCreateSessionGoal } from './hooks/useAutomatedInsightSessionGoal';
import useFetchSession from './hooks/useFetchSession';
import useGenerateInsights from './hooks/useGenerateInsights';
import useUpdateSessionDetails from './hooks/useUpdateSessionDetails';
import CustomButton from './ui/Button';

const DETAILS = {
  Goal: 'goal',
  Directive: 'directive',
};

const ExperienceTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: 0,
  },
  '& .MuiInputBase-input': {
    fontSize: '12px',
    lineHeight: '20px',
    padding: '8px 12px',
    textOverflow: 'ellipsis',
  },
  '& fieldset': { border: 'none' },
}));

const StyledAccordion = styled(Accordion, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ theme, isActive }) => ({
  borderRadius: '12px !important',
  border: '1.5px solid #E1E1E1',
  ...(isActive && {
    border: '3px solid rgba(119, 141, 134, 0.69)',
  }),
  ...(!isActive && {
    '&:hover': {
      border: '1.5px solid rgba(119, 141, 134, 0.69)',
      boxShadow: '0 0 0 1.5px rgba(119, 141, 134, 0.69)', // prevents shifting when the border size is increased
    },
  }),
}));

function ExperienceDetailsEditor({
  header,
  description,
  textFieldPlaceholder,
  isActive,
  isOptional,
  onChange,
  value,
  inputMaxLength,
  defaultExpanded = false,
  disabled = false,
}) {
  return (
    <StyledAccordion elevation={0} defaultExpanded={defaultExpanded} isActive={isActive}>
      <AccordionSummary aria-controls={`${header}-content`} id={header}>
        <Box sx={{ width: '100%' }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="body2" fontWeight="bold">
                {header}
              </Typography>
            </Grid>
            {isOptional && (
              <Grid item>
                <Typography
                  component="span"
                  fontSize={10}
                  fontWeight={500}
                  sx={{
                    padding: '2px 6px',
                    color: '#4D5B57',
                    borderRadius: '4px',
                    border: '1px solid rgba(134, 152, 145, 0.23)',
                  }}>
                  Optional
                </Typography>
              </Grid>
            )}
          </Grid>
          <Box>
            <Typography fontSize={12}>{description}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px 16px 16px' }}>
        <Box
          sx={{
            backgroundColor: '#FFFFFF',
            borderRadius: '8px',
            border: '1px solid rgba(217, 217, 217, 0.83)',
          }}>
          <ExperienceTextField
            className="lightContainer"
            disabled={disabled}
            value={value}
            placeholder={textFieldPlaceholder}
            multiline
            rows={4}
            onChange={(ev) => onChange(ev.target.value)}
            inputProps={{ maxLength: inputMaxLength }}
            helperText={`${value.length} / ${inputMaxLength}`}
            FormHelperTextProps={{
              sx: {
                pt: 1,
                color: '#4D5B57',
                fontSize: 10,
                textAlign: 'right',
              },
            }}
            sx={{
              width: '100%',
            }}
          />
        </Box>
      </AccordionDetails>
    </StyledAccordion>
  );
}

export default function EditSessionSettings() {
  const { track } = useAnalytics();
  const history = useHistory();
  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);

  const { sessionId } = useParams();

  const isShareLink = isAutomatedInsightSessionShareLink(sessionId);

  const hasAutomatedInsightsFeatureEnabled = canUseAutomatedInsightSessions(user, userCustomizations);

  const [goal, setGoal] = useState('');
  const [directive, setDirective] = useState('');
  const [activeSection, setActiveSection] = useState(DETAILS.Goal);

  const { mutateAsync: createSessionGoalAsync } = useCreateSessionGoal();
  const { mutateAsync: generateInsightsAsync } = useGenerateInsights();
  const { mutateAsync: updateSessionDetailsAsync } = useUpdateSessionDetails();

  const [isGeneratingInsights, setIsGeneratingInsights] = useState(false);

  const { data: sessionAndTasks } = useFetchSession(
    { sessionId }, // todo: include tests with personalized findings only...should be exceedingly rare
    {
      onSuccess: (data) => {
        // Can't edit session assets post-launch, which is the case if there are any tasks
        const alreadyLaunched = Object.values(data?.tasks).filter((task) => !!task.status)?.length > 0;
        const isExperience = data?.automatedInsightSession?.type === AutomatedInsightSessionType.Experience;

        if (alreadyLaunched || !isExperience) {
          history.replace({
            pathname: generatePath(Paths.automatedInsights.session, {
              sessionId,
            }),
          });
        }
        // redirect user to assets page if assets are not well formed
        const assets = data?.automatedInsightSession?.assets ?? [];
        const isPopulated = assets.length > 0 && assets.every((asset) => !!asset.image);

        if (!isPopulated) {
          history.replace({
            pathname: generatePath(Paths.automatedInsights.sessionAssets, {
              sessionId,
            }),
          });
        }
      },
      onError: (err) => {
        if ([403, 404].includes(err?.response?.status)) {
          history.replace({
            pathname: Paths.automatedInsights.basePath,
          });
        } else {
          snackbar.error(
            err?.response?.humanReadableMessage ??
              'Something went wrong getting this Pulse. Please try again later.'
          );
        }
      },
      retry: 1,
    }
  );

  const startGeneratingInsights = useCallback(async () => {
    setIsGeneratingInsights(true);

    const params = {
      sessionId: sessionAndTasks.automatedInsightSession.id,
    };

    let sessionGoal;

    if (goal) {
      try {
        sessionGoal = await createSessionGoalAsync({
          automatedInsightSessionId: sessionAndTasks.automatedInsightSession.id,
          description: goal,
          isSelected: true,
        });
        params.goalId = sessionGoal.id;
      } catch (e) {
        snackbar.error('Something went wrong generating insights. Please try again later.');
        setIsGeneratingInsights(false);
        return;
      }
    }

    if (directive) {
      try {
        await updateSessionDetailsAsync({
          sessionId: sessionAndTasks.automatedInsightSession.id,
          updates: { directive },
        });
        params.directive = directive;
      } catch (e) {
        snackbar.error('Something went wrong generating insights. Please try again later.');
        setIsGeneratingInsights(false);
        return;
      }
    }

    // kick off a task for proposed audience
    try {
      await generateInsightsAsync(params);
      track(TrackEvent.GENERATE_PULSE_WITH_GOAL_AND_PROPOSED_AUDIENCE, {
        sessionId,
      });
    } catch (e) {
      snackbar.error('Something went wrong generating insights. Please try again later.');
      setIsGeneratingInsights(false);
      return;
    }

    history.push({
      pathname: generatePath(Paths.automatedInsights.session, {
        sessionId: sessionAndTasks.automatedInsightSession.id,
      }),
      state: { sessionDetails: sessionAndTasks.automatedInsightSession },
    });
  }, [
    createSessionGoalAsync,
    directive,
    generateInsightsAsync,
    goal,
    history,
    sessionAndTasks,
    sessionId,
    setIsGeneratingInsights,
    track,
    updateSessionDetailsAsync,
  ]);

  const handleActiveSection = (section) => {
    setActiveSection(section);
  };

  if (!sessionAndTasks || ((_.isNil(user) || _.isNil(userCustomizations)) && !isShareLink)) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isShareLink && !hasAutomatedInsightsFeatureEnabled) {
    return window.location.replace('/');
  }

  return (
    <Box pt={6}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} order={{ xs: 1, md: 0 }}>
          <Paper
            elevation={0}
            sx={{
              height: MainContainerDimensions.Height,
              minHeight: MainContainerDimensions.MinHeight,
              py: 1.5,
              borderRadius: 4,
            }}>
            <Box sx={{ height: '100%', overflow: 'auto' }} className="transparentScrollBar">
              <SessionAssetGrid
                session={sessionAndTasks.automatedInsightSession}
                baseHeight="(100vh - 200px)"
                sx={{ height: '100%', borderRadius: 4 }}
              />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6} order={{ xs: 0, md: 1 }}>
          <Paper
            elevation={0}
            sx={{
              borderRadius: 4,
              height: MainContainerDimensions.Height,
              minHeight: MainContainerDimensions.MinHeight,
            }}>
            <Grid container sx={{ height: '100%', justifyContent: 'space-between' }}>
              <Grid item xs={12} sx={{ paddingTop: 3 }}>
                <Box
                  className="transparentScrollBar"
                  sx={{
                    height: '100%',
                    paddingX: { xs: 1.5, md: 3 },
                    borderRadius: 4,
                    overflowY: 'auto',
                  }}>
                  <Grid container rowGap={2} mt={1}>
                    <Grid item xs={12} onClick={() => handleActiveSection(DETAILS.Goal)}>
                      <ExperienceDetailsEditor
                        header={'Add task'}
                        description={'Enter a description of the task you want your user to perform.'}
                        textFieldPlaceholder={'Example: Users are looking to buy a fishing license.'}
                        isActive={activeSection === DETAILS.Goal}
                        isOptional={true}
                        onChange={(value) => setGoal(value)}
                        value={goal}
                        inputMaxLength={1000}
                        defaultExpanded={true}
                        disabled={isGeneratingInsights}
                      />
                    </Grid>
                    <Grid item xs={12} onClick={() => handleActiveSection(DETAILS.Directive)}>
                      <ExperienceDetailsEditor
                        header={'Add context'}
                        description={
                          'Provide details about your study\'s goals and scenarios to help tailor the results to your needs. Including specific information leads to more accurate and relevant feedback. This should not be used to add details about the intended Audience, as you will want to utilize the "Personas" customization feature for that.'
                        }
                        textFieldPlaceholder={
                          'Example: "I am comparing two homepage designs for user engagement", or "Looking to analyze this new checkout flow using UX/UI best practices", or "These three pages represent a transition from my brand website to a purchase on Amazon. We are looking to understand what my brand can do better to drive conversion"'
                        }
                        isActive={activeSection === DETAILS.Directive}
                        isOptional={true}
                        onChange={(value) => setDirective(value)}
                        value={directive}
                        inputMaxLength={1000}
                        defaultExpanded={false}
                        disabled={isGeneratingInsights}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} alignSelf="end" pb={{ xs: 2, lg: 3 }}>
                <Box sx={{ paddingX: { xs: 1.5, md: 3 } }}>
                  <Grid container justifyContent="flex-end" alignItems="flex-end" columnGap={1}>
                    <Grid item>
                      <CustomButton
                        variant="secondaryDark"
                        onClick={() => {
                          history.push({
                            pathname: generatePath(Paths.automatedInsights.sessionAssets, {
                              sessionId: sessionAndTasks.automatedInsightSession.id,
                            }),
                          });
                        }}
                        disabled={isGeneratingInsights}>
                        Back
                      </CustomButton>
                    </Grid>
                    <Grid item>
                      {isGeneratingInsights ? (
                        <Box px={2}>
                          <CircularProgress color="secondary" size="2rem" />
                        </Box>
                      ) : (
                        <Tooltip title={'Start generating insights'}>
                          <span>
                            <CustomButton
                              variant="gradient"
                              onClick={() => startGeneratingInsights()}
                              disabled={isGeneratingInsights}>
                              Next
                            </CustomButton>
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
