import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { LiteTestInfo } from '../../../../../modules/intake/constants';
import PropTypes from 'prop-types';

function LiteTestInfoBanner({ classes }) {
  return (
    <Box mt={2}>
      <Card sx={{ backgroundColor: '#d3deea' }}>
        <CardContent>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={12}>
              <Typography variant="h5" className={classes.disabled}>
                Study Output:
              </Typography>
            </Grid>
            {LiteTestInfo?.map((item, index) => (
              <Grid item xs={4} key={index}>
                <Typography className={classes.infoText}>
                  {item.included ? (
                    <CheckIcon className={classes.infoIncluded} />
                  ) : (
                    <CloseIcon className={classes.infoOmitted} />
                  )}
                  {item.info}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </CardContent>
        <Typography component="p" sx={{ paddingTop: 1, mb: 1, mr: 2, float: 'right' }} variant="caption">
          * Diagnostics do not include statistical significance.
        </Typography>
      </Card>
      <Box mt={2} />
    </Box>
  );
}

LiteTestInfoBanner.propTypes = {
  classes: PropTypes.object,
};

export default LiteTestInfoBanner;
