import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Checkbox,
  Grid,
  Link,
  Paper,
  Popover,
  Slider,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { EASE_OPTIONS, EXPERIENCE_OUTCOMES } from '../../../../modules/report/constants';
import { secondsToMinutes } from '../../../../modules/report/helpers';
import theme from '../../../../theme';

const DropdownButton = styled(Button)(() => ({
  border: '1px solid #757575',
  borderRadius: '6px',
  color: '#757575',
  cursor: 'pointer',
  textTransform: 'none',
  justifyContent: 'space-between',
}));

const MetricsFilterDropdown = ({ onClick, isOpen }) => {
  const icon = isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  return (
    <DropdownButton onClick={onClick} endIcon={icon} fullWidth>
      Metrics
    </DropdownButton>
  );
};

MetricsFilterDropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const MetricsFilter = ({
  selectedOutcomes,
  onOutcomeFilterChange,
  selectedEaseScores,
  onEaseFilterChange,
  maxTaskTimeSeconds,
  onTaskTimeFilterChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sliderValue, setSliderValue] = useState([0, 100]);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const isOpen = Boolean(anchorEl);

  const isChecked = (filter, item) => {
    if (filter === 'outcomes') {
      return selectedOutcomes?.includes(item);
    } else if (filter === 'easeScores') {
      return selectedEaseScores?.includes(item);
    }
  };

  useEffect(() => {
    if (maxTaskTimeSeconds) {
      setMaxValue(maxTaskTimeSeconds);
    }
  }, [maxTaskTimeSeconds]);

  const handleFilterClick = (ev) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOutcomeCheckbox = (ev) => {
    if (ev.target.checked) {
      onOutcomeFilterChange([...selectedOutcomes, ev.target.name]);
    } else {
      onOutcomeFilterChange(selectedOutcomes.filter((outcome) => outcome !== ev.target.name));
    }
  };

  const handleEaseScoreCheckbox = (ev) => {
    if (ev.target.checked) {
      onEaseFilterChange([...selectedEaseScores, ev.target.name]);
    } else {
      onEaseFilterChange(selectedEaseScores.filter((score) => score !== ev.target.name));
    }
  };

  const handleSelectAllCheckboxesClick = (filter) => {
    if (filter === 'outcomes') {
      onOutcomeFilterChange(Object.values(EXPERIENCE_OUTCOMES));
    } else if (filter === 'easeScores') {
      onEaseFilterChange(EASE_OPTIONS);
    }
  };

  const handleClearFiltersClick = (filter) => {
    if (filter === 'outcomes') {
      onOutcomeFilterChange([]);
    } else if (filter === 'easeScores') {
      onEaseFilterChange([]);
    }
  };

  const handleSliderChange = (event, newValue) => {
    // we need to scale the min/max values because the slider max = 100
    // but our max task time may be > 100 so we'll treat the new slider value as a perccentage
    // and multiply it by the max task time
    const scaledMinValue = maxTaskTimeSeconds * (newValue[0] * 0.01);
    const scaledMaxValue = maxTaskTimeSeconds * (newValue[1] * 0.01);

    setMinValue(scaledMinValue);
    setMaxValue(scaledMaxValue);
    setSliderValue(newValue);
    onTaskTimeFilterChange([scaledMinValue, scaledMaxValue]);
  };

  const handleSliderReset = () => {
    setMinValue(0);
    setMaxValue(maxTaskTimeSeconds);
    setSliderValue([0, 100]);
    onTaskTimeFilterChange([0, maxTaskTimeSeconds]);
  };

  return (
    <>
      <MetricsFilterDropdown onClick={handleFilterClick} isOpen={isOpen} />
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        style={{ width: '100%' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Paper sx={{ padding: theme.spacing(2), width: 250 }}>
          <Box pb={2}>
            <Typography variant="h5">Metrics Filters</Typography>
          </Box>

          {/* Success filter */}
          <Box>
            <Typography variant="body2" pb={1}>
              Success
            </Typography>
            {Object.values(EXPERIENCE_OUTCOMES).map((outcome) => (
              <Grid container key={outcome} justifyContent="space-between">
                <Grid item xs={10}>
                  <Box pl={1}>
                    <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                      {outcome}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    size="small"
                    sx={{ padding: '2px' }}
                    name={outcome}
                    checked={isChecked('outcomes', outcome)}
                    onChange={handleOutcomeCheckbox}
                  />
                </Grid>
              </Grid>
            ))}
            <Box textAlign="right">
              <Typography variant="caption">
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleSelectAllCheckboxesClick('outcomes')}
                  underline="hover">
                  Select All
                </Link>{' '}
                |{' '}
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleClearFiltersClick('outcomes')}
                  underline="hover">
                  None
                </Link>
              </Typography>
            </Box>
          </Box>

          {/* Time on task filter */}
          <Box pt={2}>
            <Typography variant="body2" pb={1}>
              Time on task
            </Typography>
            <Grid container spacing={1} alignItems="center" justifyContent="space-between">
              <Grid item xs={12}>
                <Box px={1}>
                  <Slider
                    value={sliderValue}
                    size="small"
                    onChange={handleSliderChange}
                    getAriaLabel={() => 'Task time range'}
                    step={0.1}
                  />
                </Box>
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  size="small"
                  value={secondsToMinutes(minValue)}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: 'center' },
                    },
                  }}
                />
              </Grid>
              <Grid item>
                <Typography>-</Typography>
              </Grid>
              <Grid item xs>
                <TextField
                  variant="outlined"
                  size="small"
                  value={secondsToMinutes(maxValue)}
                  InputProps={{
                    inputProps: {
                      style: { textAlign: 'center' },
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Box textAlign="right">
              <Typography variant="caption">
                <Link sx={{ cursor: 'pointer' }} onClick={handleSliderReset} underline="hover">
                  Reset
                </Link>
              </Typography>
            </Box>
          </Box>

          {/* Ease filter */}
          <Box pt={2}>
            <Typography variant="body2" pb={1}>
              Ease
            </Typography>
            {EASE_OPTIONS.map((score) => (
              <Grid container key={score} justifyContent="space-between">
                <Grid item xs={10}>
                  <Box pl={1}>
                    <Typography variant="caption" sx={{ textTransform: 'capitalize' }}>
                      {score}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={1}>
                  <Checkbox
                    size="small"
                    sx={{ padding: '2px' }}
                    name={score}
                    checked={isChecked('easeScores', score)}
                    onChange={handleEaseScoreCheckbox}
                  />
                </Grid>
              </Grid>
            ))}
            <Box textAlign="right">
              <Typography variant="caption">
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleSelectAllCheckboxesClick('easeScores')}
                  underline="hover">
                  Select All
                </Link>{' '}
                |{' '}
                <Link
                  sx={{ cursor: 'pointer' }}
                  onClick={() => handleClearFiltersClick('easeScores')}
                  underline="hover">
                  None
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Popover>
    </>
  );
};

MetricsFilter.propTypes = {
  selectedOutcomes: PropTypes.array.isRequired,
  onOutcomeFilterChange: PropTypes.func.isRequired,
  selectedEaseScores: PropTypes.array.isRequired,
  onEaseFilterChange: PropTypes.func.isRequired,
  maxTaskTimeSeconds: PropTypes.number.isRequired,
  onTaskTimeFilterChange: PropTypes.func.isRequired,
};

export default MetricsFilter;
