import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { Paths } from '../../../../routes';
import { TrackEvent } from '../../../analytics';
import PageLabel from './PageLabel';
import { secondsToMinutes } from '../../../../modules/report/helpers';

function LinkCell({ value, to, onClick }) {
  return (
    <Link to={to} onClick={() => onClick && onClick()} style={{ color: 'black', textDecoration: 'none' }}>
      {value}
    </Link>
  );
}

LinkCell.propTypes = {
  value: PropTypes.element,
  to: PropTypes.string,
  onClic: PropTypes.func,
};

export default function CompareUsabilityMetricsTable({ wevo, pages, usabilityMetrics }) {
  const tableDefinition = [
    { header: 'Page', accessor: ({ page }) => page.label },
    { header: 'Success rate', accessor: ({ metrics }) => `${Math.round(metrics?.successRate * 100)}%` },
    { header: 'Time on task', accessor: ({ metrics }) => secondsToMinutes(metrics?.taskTimeSeconds) },
    { header: 'Ease', accessor: ({ metrics }) => `${metrics?.easeScore}/${metrics?.easeScale}` },
  ];

  const { track } = useAnalytics();

  const rows = (pages ?? [])
    .filter((page) => page.isSelected)
    .map((page) => {
      return tableDefinition.map((column) => {
        const metrics = usabilityMetrics?.[page?.id] ?? {};

        if (column.header === 'Page') {
          return <PageLabel color={page.color} label={column.accessor({ page })} />;
        } else {
          const value = column.accessor({ metrics });
          return (
            <LinkCell
              value={<Typography component="span">{value}</Typography>}
              to={generatePath(Paths.reports.experienceMapPage, { wevoId: wevo.id, pageNum: page.pageNumber })}
              onClick={() => {
                track(TrackEvent.CLICKED_COMPARE_PAGE_USABILITY_TABLE, {
                  wevoId: wevo?.analyticsId,
                  header: column.header,
                  value: value,
                });
              }}
            />
          );
        }
      });
    });

  return (
    <TableContainer component={Paper} style={{ borderRadius: '20px' }} elevation={4}>
      <Table aria-label="compare-drivers-table">
        <TableHead>
          <TableRow>
            {tableDefinition.map((column, idx) => {
              return (
                <TableCell key={idx} style={{ textAlign: 'center' }}>
                  {column.header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            return (
              <TableRow key={idx}>
                {row.map((component, idx) => {
                  return (
                    <TableCell key={idx} style={{ textAlign: 'center' }}>
                      {component}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

CompareUsabilityMetricsTable.propTypes = {
  wevo: PropTypes.object.isRequired,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  usabilityMetrics: PropTypes.object.isRequired,
};
