import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  Slide,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useMemo } from 'react';

import { DEVICE_ID_TO_NAME } from '../../modules//wevos/constants';
import { AutomatedInsightSessionTypeToDisplayName } from '../../modules/automated-insights/constants';

const styles = {
  dialogContent: { padding: 3 },
  titleText: {
    marginTop: '-16px',
    fontSize: '14px',
    fontWeight: 900,
  },
  iconButton: {
    position: 'absolute',
    right: 16,
    top: 12,
    color: '#000000',
  },
  header: { color: grey[500], fontWeight: 500, fontSize: '14px' },
  capitalizedText: {
    fontSize: '14px',
    textTransform: 'capitalize',
    fontWeight: 500,
  },
  text: {
    fontSize: '14px',
    fontWeight: 500,
  },
  urlText: {
    fontSize: '14px',
    fontWeight: 500,
  },
  fieldGrid: { paddingTop: '12px' },
  urlFieldGrid: { marginLeft: -2, marginBottom: -3, paddingTop: -2 },
  urlsList: {
    maxHeight: 80,
    padding: 0,
    marginBottom: 2,
  },
};

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SessionDetailsDialog = (props) => {
  const { session, urls, goals, isOpen, onToggleDetailsDialog } = props;

  const handleClose = () => {
    onToggleDetailsDialog(false);
  };

  const date = new Date(session?.createdAt);
  const sessionDate = date.toLocaleDateString({ year: 'numeric', month: '2-digit', day: 'numeric' });

  const sessionGoal = useMemo(() => {
    // TODO: this is a hack that assumes the session has only one active goal at any given time
    // we'll probably need this to be selectable at time of viewing
    return goals?.find((goal) => goal.isSelected)?.description;
  }, [goals]);

  const sessionContext = useMemo(() => {
    return session?.directive;
  }, [session]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      TransitionComponent={SlideTransition}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: '20px',
          paddingTop: '16px',
          paddingBottom: '16px',
        },
      }}>
      <DialogTitle>
        <Grid container spacing={2} wrap="nowrap">
          <Grid item>
            <Typography sx={styles.titleText}>{session?.name}</Typography>
          </Grid>
          <Grid item xs={1}>
            {onToggleDetailsDialog && (
              <IconButton
                aria-label="close"
                size="small"
                onClick={() => onToggleDetailsDialog(false)}
                sx={styles.iconButton}>
                <CloseIcon sx={{ fontSize: '20px' }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent className="lightContainer" sx={styles.dialogContent}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs={3}>
            <Typography sx={styles.header}>Device</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.capitalizedText}>{DEVICE_ID_TO_NAME[session?.deviceId]}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={3} sx={styles.fieldGrid}>
          <Grid item xs={3}>
            <Typography sx={styles.header}> Pulse type</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.capitalizedText}>
              {AutomatedInsightSessionTypeToDisplayName[session?.type]}
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" spacing={3} sx={styles.fieldGrid}>
          <Grid item xs={3}>
            <Typography sx={styles.header}> Date created</Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography sx={styles.text}>{sessionDate}</Typography>
          </Grid>
        </Grid>
        {urls?.length ? (
          <Grid container spacing={3} sx={styles.fieldGrid}>
            <Grid item xs={3}>
              <Typography sx={styles.header}> {urls?.length > 1 ? 'URLs' : 'URL'}</Typography>
            </Grid>
            <Grid item xs={9} sx={styles.urlFieldGrid}>
              <List
                sx={{
                  ...styles.urlsList,
                }}>
                {urls?.map((url, index) => (
                  <ListItem key={index} sx={{ paddingTop: 0 }}>
                    <Typography sx={styles.urlText}>{url}</Typography>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        ) : null}
        {!!sessionGoal && (
          <Grid container spacing={3} sx={styles.fieldGrid}>
            <Grid item xs={3}>
              <Typography sx={styles.header}>Task</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography sx={styles.text}>{sessionGoal}</Typography>
            </Grid>
          </Grid>
        )}
        {!!sessionContext && (
          <Grid container spacing={3} sx={styles.fieldGrid}>
            <Grid item xs={3}>
              <Typography sx={styles.header}>Context</Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography sx={styles.text}>{sessionContext}</Typography>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SessionDetailsDialog;
