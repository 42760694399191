import { useQuery } from 'react-query';
import axios from '../api';

const fetchExperienceAsset = async ({ queryKey: [key, { wevoId, pageId, experienceId }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/experiences/${experienceId}/asset`,
    method: 'GET',
  });
  return data?.asset;
};

export default function useFetchExperienceAsset({ wevoId, pageId, experienceId }, options) {
  return useQuery(['experienceAsset', { wevoId, pageId, experienceId }], fetchExperienceAsset, {
    notifyOnChangeProps: 'tracked',
    ...options,
  });
}
