import Info from '@mui/icons-material/Info';
import { Box, Grid, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { grey } from '@mui/material/colors';
import { BIN_COLORS } from '../constants';
import styled from '@emotion/styled';

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: 350,
  },
});

const tooltipStyles = {
  infoIcon: {
    color: grey[600],
    fontSize: '1.7rem',
    margin: '0 ',
    verticalAlign: 'text-bottom',
  },
  tooltipContainer: {
    px: 1,
    py: 0,
  },
  keyRow: {
    marginTop: 0.5,
    marginBottom: 0.5,
  },
  bar: {
    width: '1.2rem',
    marginTop: 'auto',
    marginBottom: 'auto',
    height: '0.5rem',
  },
  label: {
    //marginLeft: '1rem',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  chartHeaderText: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: grey[600],
  },
};

function KeyWithTooltip({ colorKey }) {
  return (
    <div>
      <Grid container alignItems="center" spacing={0} justifyContent="center">
        <Grid>
          <CustomWidthTooltip
            placement="right-start"
            sx={tooltipStyles.tooltip}
            title={
              <Grid container direction="row" sx={tooltipStyles.tooltipContainer} alignContent="center">
                {colorKey.map((item) => (
                  <Grid item key={item.color} sx={tooltipStyles.keyRow} xs={12}>
                    <Grid container alignItems="center">
                      <Grid item xs={2}>
                        <Box sx={{ ...tooltipStyles.bar, background: item.color }} />
                      </Grid>
                      <Grid item sx={tooltipStyles.label} xs={10}>
                        <span>{item.text}</span>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            }>
            <Info sx={tooltipStyles.infoIcon} />
          </CustomWidthTooltip>
        </Grid>
      </Grid>
    </div>
  );
}

export function BaselineKeyWithTooltip() {
  const binColors = BIN_COLORS;
  const positiveIndex = 3;
  const negativeIndex = 1;

  const colorKey = [
    {
      color: binColors[positiveIndex],
      text: 'Predicted conversion more than baseline',
    },
    {
      color: '#e6e6e6',
      text: 'Too similar to predict',
    },
    {
      color: binColors[negativeIndex],
      text: 'Predicted conversion less than baseline',
    },
  ];

  return <KeyWithTooltip colorKey={colorKey} />;
}

export function PageScoresTooltip() {
  return (
    <Grid container alignItems="center" spacing={2} justifyContent="space-between">
      <Grid item>
        <Typography component="p" variant="body1" sx={tooltipStyles.chartHeaderText}>
          How do I read this chart?
        </Typography>
      </Grid>
      <Grid item sx={{ padding: 0 }}>
        <CustomWidthTooltip
          placement="right-start"
          sx={tooltipStyles.tooltip}
          title={
            <Grid
              container
              direction="row"
              sx={tooltipStyles.tooltipContainer}
              alignContent="center"
              px={2}
              py={1}>
              <Grid item xs={12}>
                <Typography component="p" pb={1} variant="caption">
                  In WEVO scoring, one standard deviation is equal to 8 points. This means that page scores
                  must be at least 8 points apart to be considered better or worse at a level of statistical
                  significance.
                </Typography>
                <Typography component="p" gutterBottom variant="caption">
                  If two scores differ by 8 points or fewer, this difference falls within WEVO’s margin for
                  error and therefore is not considered to be statistically significant.
                </Typography>
              </Grid>
            </Grid>
          }>
          <Info sx={tooltipStyles.infoIcon} />
        </CustomWidthTooltip>
      </Grid>
    </Grid>
  );
}
