import { Box, Grid, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as PulseInfoIcon } from '../../assets/pulse-info-icon.svg';
import { BenchmarkFilterTypes } from '../../modules/automated-insights/constants';
import BenchmarksFilter from './BenchmarksFilter';
import BenchmarksSegmentedBar from './BenchmarksSegmentedBar';

const Benchmarks = ({
  scores,
  benchmarks,
  percentileType,
  allTags,
  selectedTags,
  onTagSelection,
  isSessionOwner,
}) => {
  return (
    <Box>
      <Box display="flex" alignItems="center" gap={1} sx={{ marginBottom: 1 }}>
        <Typography variant="h6">Benchmarks</Typography>
        <Tooltip title="How your experience compares to other experiences of the same type, industry, or product">
          <PulseInfoIcon width="15px" />
        </Tooltip>
      </Box>
      <Grid container spacing={1} sx={{ justifyContent: 'space-between', py: 2, rowGap: 1 }}>
        {BenchmarkFilterTypes?.map((type) => (
          <Grid item key={type} xs={12} sm={4}>
            <BenchmarksFilter
              type={type}
              listOfTags={allTags[type] || []}
              selectedTagId={selectedTags?.[type] || ''}
              onTagSelection={onTagSelection}
              disablePopper={!isSessionOwner}
            />
          </Grid>
        ))}
      </Grid>
      <BenchmarksSegmentedBar benchmarks={benchmarks} percentileType={percentileType} scores={scores} />
    </Box>
  );
};

export default Benchmarks;
