import { useQueries, useQuery } from 'react-query';
import axios from '../../../api';

const fetchStarredQuotes = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  if (!wevoId || !pageId) {
    return [];
  }

  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/starred`,
    method: 'GET',
  });

  return { quotes: data.quotes ?? [], pageId };
};

/**
 *
 * @param {{wevoId: string, pageId: string, onSuccess: function}} params
 * @returns
 */
export default function useStarredQuotes({ wevoId, pageId, onSuccess }) {
  return useQuery(['starredQuotes', { wevoId, pageId }], fetchStarredQuotes, {
    onSuccess: onSuccess,
  });
}

/**
 *
 * @param {{wevoId: string, pageIds: [string]}} params
 * @returns
 */
export function useFetchAllPagesStarredQuotes({ wevoId, pageIds }) {
  return useQueries(
    pageIds?.map((pageId) => {
      return {
        queryKey: ['starredQuotes', { wevoId, pageId }],
        queryFn: fetchStarredQuotes,
        staleTime: 30 * 1000,
      };
    })
  );
}
