import Check from '@mui/icons-material/Check';
import { Box, StepLabel, Typography } from '@mui/material';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/styles';
import PropTypes from 'prop-types';

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#4caf50',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#4caf50',
    },
  },
}));

function CircleStepIcon(props) {
  const { active, completed } = props;

  return (
    <Box
      sx={{ color: active ? 'primary.main' : '#eaeaf0', display: 'flex', height: 22, alignItems: 'center' }}>
      {completed && !active ? (
        <Check sx={{ color: '#4caf50', zIndex: 1, fontSize: 18 }} />
      ) : (
        <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: 'currentColor' }} />
      )}
    </Box>
  );
}

CircleStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,

  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
};

function HorizontalStepper(props) {
  const { step, steps, setStep, disableStepClicks } = props;

  const handleStepClick = (newStep) => () => {
    setStep(newStep);
  };

  return (
    <Box sx={{ display: 'flex', maxWidth: 800, justifyContent: 'center', alignItems: 'center' }}>
      <Stepper
        sx={{ backgroundColor: 'background.default', width: '100%', p: 3 }}
        activeStep={step}
        alternativeLabel
        connector={<Connector />}
        nonLinear>
        {steps.map((stepObj) => (
          <Step key={stepObj.label} completed={stepObj.completed}>
            <StepButton onClick={handleStepClick(stepObj.id)} disabled={disableStepClicks}>
              <StepLabel StepIconComponent={CircleStepIcon}>
                <Typography variant="h6">{stepObj.label}</Typography>
              </StepLabel>
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}

HorizontalStepper.propTypes = {
  step: PropTypes.number.isRequired,
  steps: PropTypes.arrayOf(Object),
};

export default HorizontalStepper;
