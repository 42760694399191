import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { CustomScreenerTypes, ScreenerSelectOptions } from '../../../modules/intake/constants';

const styles = {
  styledCard: {
    borderRadius: '15px',
  },
  typeDescription: {
    marginRight: (theme) => theme.spacing(-2),
    fontSize: '13px',
    color: grey[600],
  },
  tableCell: {
    borderBottom: 'none',
  },
  honorNewLines: {
    whiteSpace: 'pre-line',
  },
};

const QUESTION_TYPE = {
  [CustomScreenerTypes.YesNoTrueFalse]: 'Yes/No',
  [CustomScreenerTypes.MultipleChoice]: 'Multiple Choice',
  [CustomScreenerTypes.FiveLikertScale]: 'Likert 5',
  [CustomScreenerTypes.SevenLikertScale]: 'Likert 7',
  [CustomScreenerTypes.MultiSelect]: 'Multiple Selection',
};

const LABELS_TYPE = {
  [ScreenerSelectOptions.AgreeDisagree]: 'Agree Disagree',
  [ScreenerSelectOptions.EasyDifficult]: 'Easy Difficult',
  [ScreenerSelectOptions.LikeDislike]: 'Like Dislike',
  [ScreenerSelectOptions.LikelyUnlikely]: 'Likely Unlikely',
  [ScreenerSelectOptions.SatisfiedDissatisfied]: 'Satisfied Dissatisfied',
  [ScreenerSelectOptions.TrueFalse]: 'True/False',
  [ScreenerSelectOptions.YesNo]: 'Yes/No',
  [ScreenerSelectOptions.Custom]: 'Custom',
};

const CustomScreenerReviewCard = (props) => {
  const { questionName, questionText, questionType, labelsType, onClick, isStyled } = props;

  return (
    <Card elevation={isStyled && 4} sx={isStyled && styles.styledCard}>
      <CardActionArea
        onClick={onClick}
        sx={{
          cursor: onClick ? 'pointer' : 'default',
          pointerEvents: onClick ? 'auto' : 'none',
        }}>
        <CardContent>
          <Grid container direction="column">
            {questionName && (
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  {questionName}
                </Typography>
              </Grid>
            )}
            {questionText && (
              <Grid item>
                <Typography variant="body2" color="textSecondary" sx={styles.honorNewLines}>
                  {questionText}
                </Typography>
              </Grid>
            )}
            <Table size="small" aria-label="QuestionType" sx={{ marginTop: 1 }}>
              <TableBody>
                {questionType && (
                  <TableRow>
                    <TableCell align="left" sx={styles.tableCell}>
                      <Typography variant="h6">Question Type</Typography>
                    </TableCell>
                    <TableCell align="right" sx={styles.tableCell}>
                      <Typography variant="body2" sx={styles.typeDescription}>
                        {QUESTION_TYPE[questionType]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                {labelsType && (
                  <TableRow>
                    <TableCell align="left" sx={styles.tableCell}>
                      <Typography variant="h6">Labels Type</Typography>
                    </TableCell>
                    <TableCell align="right" sx={styles.tableCell}>
                      <Typography variant="body2" sx={styles.typeDescription}>
                        {LABELS_TYPE[labelsType]}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

CustomScreenerReviewCard.propTypes = {
  questionName: PropTypes.string.isRequired,
  questionText: PropTypes.string.isRequired,
  questionType: PropTypes.string.isRequired,
  labelsType: PropTypes.string,
  onClick: PropTypes.func,
  isStyled: PropTypes.bool,
};

export default CustomScreenerReviewCard;
