import { Box, Card, CardContent, CardHeader, Grid, Hidden, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import StepCard from './StepCard';

const styles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    textAlign: 'center',
  },
  scoreContainer: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
  },
  arrow: {
    textAlign: 'center',
  },
  card: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: '20px',
  },
}));

const PageSummary = (props) => {
  const classes = styles(props);
  const { wevo, page } = props;

  const steps = (page && page.steps) || [];

  const arrowComponent = (
    <Fragment>
      <Hidden lgDown>
        <ArrowForwardIcon />
      </Hidden>
      <Hidden mdUp>
        <Box py={1}>
          <ArrowDownwardIcon />
        </Box>
      </Hidden>
    </Fragment>
  );

  return (
    <Card className={classes.card} elevation={4}>
      <CardHeader
        title={
          <Typography variant="h3" align="center" gutterBottom>
            Pages
          </Typography>
        }
        subheader={
          <Typography variant="caption" component="p" align="center">
            See how visitors evaluate each page in your journey
          </Typography>
        }
      />
      <CardContent>
        <Grid container justifyContent="center">
          {steps.map((step, index) => (
            <Grid
              item
              container
              md={12 / steps.length}
              sm={12}
              key={step.id}
              justifyContent="center"
              alignItems="center">
              <Grid item md={10} sm={12}>
                <StepCard wevo={wevo} step={step} />
              </Grid>
              <Grid item md={2} sm={12} className={classes.arrow}>
                {index < steps.length - 1 && arrowComponent}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

PageSummary.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
};

export default PageSummary;
