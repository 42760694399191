import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Autocomplete,
  Box,
  Card,
  Chip,
  CircularProgress,
  DialogActions,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
  inputClasses,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Decimal from 'decimal.js';
import * as EmailValidator from 'email-validator';
import _ from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import InviteIcon from '../../assets/invite-icon.png';
import SearchIcon from '../../assets/search-icon.png';
import useFetchUsers from '../../hooks/useUserSearch';
import * as UserActions from '../../modules/user/actions';
import { snackbar } from '../../notifications';
import { TrackEvent } from '../analytics';
import SeatsCounter from './SeatsCounter';
import {
  useAllocateSeats,
  useAssignUsersToSeats,
  useGetPreviewAllocation,
  usePulseBulkCreateUsers,
  useReallocateSeat,
  useUnallocateSeat,
} from './hooks/useManageSeats';
import CustomButton from './ui/Button';

const SearchTextField = styled(TextField)(({ theme }) => ({
  [`& .${inputClasses.input}`]: {
    padding: theme.spacing(1),
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '16ch',
      '&:focus': {
        width: '24ch',
      },
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid rgba(217, 217, 217, 0.46)',
    },
    '&:hover fieldset': {
      border: '1px solid rgba(217, 217, 217, 0.46)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(217, 217, 217, 0.46)',
    },
  },
  borderRadius: '10px',
}));

const commonStyles = {
  dialogTitle: {
    justifyContent: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#000000',
  },
  seatCard: {
    width: '105px',
    height: '65px',
    borderRadius: '10px',
    alignContent: 'center',
    border: '1px solid rgba(210, 210, 210, 0.25)',
  },
  number: { fontSize: '12px', textAlign: 'center' },
  seatCardHeader: { fontSize: '9.5px', fontWeight: 600, textAlign: 'center' },
};

const manageUserStyles = {
  ...commonStyles,
  statsContainer: {
    justifyContent: 'space-evenly',
  },
};

const contactSupportStyles = {
  ...commonStyles,
};

const inviteUsersStyles = {
  ...commonStyles,
};

export const SeatManagementAction = {
  ManageUsers: 'manage_users',
  InviteUsers: 'invite_users',
  AddSeats: 'add_seats',
  ContactSupport: 'contact_support',
};

const SeatStatsBar = ({
  totalNumberOfSeats,
  pendingInvites,
  unassignedSeats,
  onInviteClicked,
  onTotalSeatsClicked,
  styles,
}) => {
  const shouldShowInviteButton = !_.isNil(onInviteClicked);

  return (
    <Grid container spacing={1} sx={styles?.statsContainer ?? { justifyContent: 'center' }}>
      {shouldShowInviteButton && (
        <Grid item onClick={onInviteClicked} sx={{ cursor: 'pointer' }}>
          <Card elevation={0} sx={{ ...styles.seatCard, border: '1px solid rgba(75, 192, 200, 1)' }}>
            <Grid container justifyContent={'center'}>
              <Grid item>
                <img src={InviteIcon} alt="selectable" style={{ width: '18px', height: 'auto' }} />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: -0.5 }}>
                <Typography sx={styles.seatCardHeader}>Invite</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      )}
      <Grid item>
        <Card
          elevation={0}
          sx={{ ...styles.seatCard, cursor: onTotalSeatsClicked ? 'pointer' : 'initial' }}
          onClick={onTotalSeatsClicked || (() => {})}>
          <Typography variant="h4" sx={styles.number}>
            {totalNumberOfSeats}
          </Typography>
          <Typography sx={styles.seatCardHeader}>Seats</Typography>
        </Card>
      </Grid>
      <Grid item>
        <Card elevation={0} sx={styles.seatCard}>
          <Typography variant="h4" sx={styles.number}>
            {pendingInvites}
          </Typography>
          <Typography sx={styles.seatCardHeader}>Pending invites</Typography>
        </Card>
      </Grid>
      <Grid item>
        <Card elevation={0} sx={styles.seatCard}>
          <Typography variant="h4" sx={styles.number}>
            {unassignedSeats}
          </Typography>
          <Typography sx={styles.seatCardHeader}>Unassigned seats</Typography>
        </Card>
      </Grid>
    </Grid>
  );
};

const ManageUsers = ({
  totalNumberOfSeats,
  pendingInvites,
  unassignedSeats,
  subscriptionSeats,
  handleInviteClick,
  onRemoveUserClicked,
  onRestoreClicked,
  isUpdatingSeatStatus,
  styles,
  showUserActions,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [userActionAnchorRef, setUserActionAnchorRef] = useState(null);
  const [focusedSeat, setFocusedSeat] = useState(null);

  const isUserActionOpen = useMemo(() => Boolean(userActionAnchorRef), [userActionAnchorRef]);

  const closeActionMenu = () => {
    setUserActionAnchorRef(null);
    setFocusedSeat(null);
  };

  const filteredUsers = useMemo(() => {
    return subscriptionSeats?.filter((seat) => {
      if (_.isNil(seat?.user)) {
        return false;
      }

      if (!searchInput) {
        return true;
      }

      const fullName = `${seat?.user?.firstName} ${seat?.user?.lastName}`;
      return fullName.toLowerCase().includes(searchInput) || seat?.user?.emailAddress.includes(searchInput);
    });
  }, [searchInput, subscriptionSeats]);

  const getSeatStatus = (seat) => {
    if (seat?.activeToDate) {
      return (
        <Tooltip title={'This user will lose access at the start of your next billing cycle.'}>
          <Typography fontSize="13px" sx={{ cursor: 'default' }}>
            Removed
          </Typography>
        </Tooltip>
      );
    }

    return seat?.user?.isEmailVerified ? 'Claimed' : 'Pending';
  };

  return (
    <Box>
      <SeatStatsBar
        totalNumberOfSeats={totalNumberOfSeats}
        pendingInvites={pendingInvites}
        unassignedSeats={unassignedSeats}
        onInviteClicked={handleInviteClick}
        styles={styles}
      />
      <Grid container spacing={2} justifyContent={'space-evenly'} sx={{ marginTop: 0.3 }}>
        <Grid item xs={12}>
          <SearchTextField
            variant="outlined"
            size="small"
            fullWidth
            value={searchInput}
            onChange={(ev) => setSearchInput(ev.target.value)}
            InputProps={{
              placeholder: `Search by name or email`,
              startAdornment: (
                <Grid item sx={{ marginRight: 1.5, marginTop: 0.5 }}>
                  <img src={SearchIcon} alt="selectable" style={{ width: '14px', height: 'auto' }} />
                </Grid>
              ),
              sx: {
                borderRadius: 50,
                height: '36px',
                fontSize: '13px',
                fontWeight: 400,
                color: 'primary',
              },
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="start"
        sx={{ marginTop: 1.2, display: { xs: 'none', sm: 'flex' } }}>
        <Grid item container xs={showUserActions ? 11 : 12} spacing={2}>
          <Grid item xs={6}>
            <Typography sx={{ color: 'rgba(121, 121, 121, 1)', fontSize: '11px' }}>
              <b>Name</b>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: 'rgba(121, 121, 121, 1)', fontSize: '11px' }}>
              <b>Type</b>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography sx={{ color: 'rgba(121, 121, 121, 1)', fontSize: '11px' }}>
              <b>Status</b>
            </Typography>
          </Grid>
        </Grid>
        {showUserActions && <Grid item xs={1}></Grid>}
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent={'space-evenly'}
        sx={{
          marginTop: 0.7,
          height: '235px',
          overflowY: 'scroll',
          scrollbarColor: `transparent`,
          scrollbarWidth: 'thin',
          alignContent: 'start',
        }}>
        <Grid item xs={12} sx={{ marginY: -2.5 }}>
          <Divider sx={{ height: '1px', bgcolor: 'rgba(244, 244, 244, 0.05)' }} />
        </Grid>
        {!filteredUsers ? (
          <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          filteredUsers?.map((seat, index) => {
            return (
              <Fragment key={index}>
                {index > 0 && (
                  <Grid item xs={12} sx={{ marginTop: -2 }}>
                    <Divider sx={{ bgcolor: 'rgba(244, 244, 244, 0.05)' }} />
                  </Grid>
                )}
                <Grid container item xs={12} sx={{ minHeight: '52px' }}>
                  <Grid container xs={showUserActions ? 11 : 12} item spacing={2} justifyContent="start">
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      sx={{ paddingTop: { sm: '0px !important' } }}
                      alignContent="center">
                      <Typography sx={{ color: 'black', fontSize: '13px' }}>
                        <Typography
                          component="span"
                          sx={{
                            color: 'rgba(121, 121, 121, 1)',
                            fontSize: '11px',
                            display: { xs: 'block', sm: 'none' },
                          }}>
                          <b>Name</b>
                        </Typography>
                        {seat?.user?.firstName || seat?.user?.lastName
                          ? `${seat?.user?.firstName} ${seat?.user?.lastName}`
                          : '-'}
                      </Typography>

                      <Typography sx={{ color: 'rgba(121, 121, 121, 1)', fontSize: '13px' }}>
                        <Typography
                          component="span"
                          sx={{
                            color: 'rgba(121, 121, 121, 1)',
                            fontSize: '11px',
                            display: { xs: 'block', sm: 'none' },
                          }}>
                          <b>Email</b>
                        </Typography>
                        {seat?.user?.emailAddress}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{ fontSize: '13px', paddingTop: { sm: '0px !important' } }}
                      alignContent="center">
                      <Typography
                        component="span"
                        sx={{
                          color: 'rgba(121, 121, 121, 1)',
                          fontSize: '11px',
                          display: { xs: 'block', sm: 'none' },
                        }}>
                        <b>Type</b>
                      </Typography>
                      {seat?.user?.isBillingAdmin ? 'Admin' : 'Member'}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={3}
                      sx={{ fontSize: '13px', paddingTop: { sm: '0px !important' } }}
                      alignContent="center">
                      <Typography
                        component="span"
                        sx={{
                          color: 'rgba(121, 121, 121, 1)',
                          fontSize: '11px',
                          display: { xs: 'block', sm: 'none' },
                        }}>
                        <b>Status</b>
                      </Typography>
                      {getSeatStatus(seat)}
                    </Grid>
                  </Grid>
                  {showUserActions && !seat?.user?.isBillingAdmin && (
                    <Grid item xs={1}>
                      <IconButton
                        id="basic-button"
                        sx={{ paddingY: 0 }}
                        size="small"
                        aria-controls={isUserActionOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={isUserActionOpen ? 'true' : undefined}
                        onClick={(event) => {
                          setUserActionAnchorRef(event.currentTarget);
                          setFocusedSeat(seat);
                        }}>
                        <MoreVertIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Fragment>
            );
          })
        )}
      </Grid>
      <UserActionMenu
        open={isUserActionOpen}
        anchorEl={userActionAnchorRef}
        onClose={closeActionMenu}
        seat={focusedSeat}
        user={focusedSeat?.user}
        onRemoveUserClicked={() => {
          if (onRemoveUserClicked && focusedSeat) {
            onRemoveUserClicked(focusedSeat);
          }
          closeActionMenu();
        }}
        onRestoreClicked={() => {
          if (onRestoreClicked && focusedSeat) {
            onRestoreClicked(focusedSeat);
          }
          closeActionMenu();
        }}
        disabled={isUpdatingSeatStatus}
      />
    </Box>
  );
};

const UserActionMenu = ({
  open,
  anchorEl,
  user,
  seat,
  onClose,
  onRestoreClicked,
  onRemoveUserClicked,
  disabled,
}) => {
  return (
    <Paper sx={{ width: 320 }}>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}>
        {seat && user && _.isNil(seat?.activeToDate) && (
          <MenuItem
            onClick={disabled || !onRemoveUserClicked ? () => {} : onRemoveUserClicked}
            disabled={disabled}>
            {disabled ? (
              <CircularProgress size={12} color="secondary" />
            ) : (
              <Typography variant="body2">Remove user</Typography>
            )}
          </MenuItem>
        )}
        {seat && user && !_.isNil(seat?.activeToDate) && (
          <MenuItem onClick={disabled || !onRestoreClicked ? () => {} : onRestoreClicked} disabled={disabled}>
            {disabled ? (
              <CircularProgress size={12} color="secondary" />
            ) : (
              <Typography variant="body2">Restore</Typography>
            )}
          </MenuItem>
        )}
      </Menu>
    </Paper>
  );
};

const InviteUsers = ({
  seats,
  totalNumberOfSeats,
  pendingInvites,
  unassignedSeats,
  handleTotalSeatsClick,
  handleAddSeatsClick,
  usersToInvite,
  onUsersToInviteChanged,
  styles,
}) => {
  const existingEmails = useMemo(
    () => (seats ?? []).filter((seat) => seat?.user?.emailAddress).map((seat) => seat.user.emailAddress),
    [seats]
  );

  const excludeFn = useCallback(
    (user) =>
      existingEmails.includes(user.emailAddress) ||
      usersToInvite.map((user) => user.email).includes(user.emailAddress),
    [existingEmails, usersToInvite]
  );

  const inviteMembersComponent = useMemo(() => {
    const reachedSeatCount = usersToInvite?.length >= unassignedSeats;
    return (
      <>
        <Typography variant="body2" mt={4} mb={1}>
          Invite team member
        </Typography>
        <Grid container spacing={2} justifyContent={'space-evenly'} mb={1}>
          <Grid item xs={12}>
            <InviteUserSearchInput
              usersToInvite={usersToInvite}
              onKeyPress={onUsersToInviteChanged}
              disabled={reachedSeatCount}
              excludeFn={excludeFn}
            />
          </Grid>
        </Grid>
        <Grid container sx={{ minHeight: 85 }}>
          <InviteUsersIdentifiersBlock
            usersToInvite={usersToInvite}
            onClick={(userToRemove) => {
              onUsersToInviteChanged(usersToInvite.filter((user) => user.email !== userToRemove.email));
            }}
          />
        </Grid>
        <Grid container>
          <Grid item xs={12}>
            {reachedSeatCount && (
              <Box>
                <Typography variant="caption">
                  You've reached the seat limit for your subscription. If you would like to add additional
                  users, please remove users to invite or
                  <Box
                    sx={{ display: 'inline', cursor: 'pointer', color: 'rgb(59 130 246)' }}
                    onClick={handleAddSeatsClick || (() => {})}>
                    {' '}
                    add additional seats to your plan.
                  </Box>
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </>
    );
  }, [excludeFn, handleAddSeatsClick, onUsersToInviteChanged, unassignedSeats, usersToInvite]);

  const addSeatsCTAComponent = useMemo(() => {
    return (
      <Grid container spacing={2} justifyContent={'center'} alignContent={'center'} minHeight={200}>
        <Grid item xs={12} sx={{ textAlign: 'center' }} pb={1}>
          <Box pb={2}>
            <Typography variant="body2">You don't have seats to assign.</Typography>
          </Box>
          <CustomButton variant="secondaryDark" onClick={handleAddSeatsClick || (() => {})}>
            Add Seats
          </CustomButton>
        </Grid>
      </Grid>
    );
  }, [handleAddSeatsClick]);

  return (
    <Box>
      <SeatStatsBar
        totalNumberOfSeats={totalNumberOfSeats}
        pendingInvites={pendingInvites}
        unassignedSeats={unassignedSeats}
        styles={styles}
        onTotalSeatsClicked={handleTotalSeatsClick}
      />
      {unassignedSeats > 0 ? inviteMembersComponent : addSeatsCTAComponent}
    </Box>
  );
};

const InviteUserSearchInput = ({ usersToInvite, excludeFn = (user) => false, onKeyPress, disabled }) => {
  const [search, setSearch] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [userToInvite, setUserToInvite] = useState(null);

  const usersToInviteEmails = usersToInvite.map((u) => u.emailAddress);

  const { data: users, isLoading } = useFetchUsers(
    { searchQuery: debouncedSearch },
    { enabled: debouncedSearch?.length > 2 }
  );

  const handleKeyPress = useCallback(
    (ev) => {
      if (ev.key !== 'Enter') {
        return;
      }

      if (!userToInvite) {
        return;
      }

      ev.preventDefault();

      const maybeEmail = userToInvite?.emailAddress;
      const found = (usersToInvite ?? []).find((user) => user.emailAddress === maybeEmail);

      if (EmailValidator.validate(maybeEmail)) {
        if (!found && !excludeFn(userToInvite)) {
          onKeyPress(usersToInvite.concat([userToInvite]));
        }

        setSearch('');
        setSelectedValue('');
        setUserToInvite(null);
        setDebouncedSearch('');
      }
    },
    [
      onKeyPress,
      setSearch,
      setSelectedValue,
      setDebouncedSearch,
      usersToInvite,
      excludeFn,
      userToInvite,
      setUserToInvite,
    ]
  );

  useEffect(() => {
    const debounced = _.debounce(() => {
      setDebouncedSearch(search);
    }, 500);

    debounced();

    return () => debounced.cancel();
  }, [search]);

  return (
    <Autocomplete
      disabled={disabled}
      freeSolo
      loading={isLoading}
      loadingText={'loading...'}
      disableClearable
      id="user-search-autocomplete"
      options={(users?.users ?? [])
        .filter((u) => !excludeFn(u))
        .map((u) => u.emailAddress)
        .filter((emailAddress) => !usersToInviteEmails.includes(emailAddress))}
      renderOption={(props, option) => (
        <Typography {...props} variant="body2">
          {option}
        </Typography>
      )}
      ListboxProps={{
        sx: {
          msOverflowStyle: 'none', // IE and edge
          scrollbarWidth: 'none', // firefox
          '&::-webkit-scrollbar': {
            display: 'none', // chrome and safari
          },
        },
      }}
      value={selectedValue}
      inputValue={search}
      onChange={(ev, newValue) => {
        // we have to set the user record here because debounced search queries might cause a refetch
        // at the time when we do the key press and the users list could be undefined
        let userRecord = (users?.users ?? []).find(
          (user) => (user?.emailAddress || '').toLowerCase().trim() === newValue.toLowerCase().trim()
        );

        if (!userRecord) {
          // new record because user does not exist, they will get created with a user invite
          userRecord = { id: null, emailAddress: newValue };
        }

        setUserToInvite(userRecord);
        setSelectedValue(newValue);
      }}
      onInputChange={(ev, newInputValue) => {
        setSearch(newInputValue);
        setUserToInvite(null);
      }}
      onKeyPress={handleKeyPress}
      renderInput={(params) => (
        <SearchTextField
          {...params}
          variant="outlined"
          size="small"
          fullWidth
          InputProps={{
            ...params.InputProps,
            type: 'search',
            placeholder: `Search by email`,
            startAdornment: (
              <Grid item sx={{ marginRight: 1.5, marginTop: 0.5 }}>
                <img src={SearchIcon} alt="selectable" style={{ width: '14px', height: 'auto' }} />
              </Grid>
            ),
            sx: {
              borderRadius: 50,
              height: '36px',
              fontSize: '13px',
              fontWeight: 400,
              color: 'primary',
            },
          }}
        />
      )}
    />
  );
};

const InviteUsersIdentifiersBlock = ({ usersToInvite, onClick }) => {
  return (
    <Box>
      {usersToInvite.map((user, index) => (
        <Chip
          key={index}
          label={user.emailAddress}
          size="small"
          sx={{
            background: '#E4EEF9',
            marginRight: index < (usersToInvite ?? []).length - 1 ? 1 : 0, // don't add margin on the last item in the list
            marginBottom: 1,
          }}
          px={2}
          onDelete={() => onClick(user)}
          deleteIcon={<CloseIcon sx={{ fontSize: '10px!important', color: 'black!important' }} />}
        />
      ))}
    </Box>
  );
};

const InviteUsersAction = ({ hidden, disabled, isLoading, onInviteClicked }) => {
  return (
    <Grid container justifyContent={'flex-end'}>
      <Grid item px={2}>
        <CustomButton
          disabled={disabled || isLoading}
          variant="secondaryDark"
          sx={{ minWidth: 75, display: hidden ? 'none' : 'initial' }}
          onClick={onInviteClicked || (() => {})}>
          {isLoading ? <CircularProgress size={14} color="secondary" /> : 'Invite'}
        </CustomButton>
      </Grid>
    </Grid>
  );
};

const AddSeats = ({
  subscription,
  numberOfSeats,
  seatLimit,
  numberOfActiveSeats,
  shouldFetchEstimate,
  onNumberOfSeatsChanged,
  onEstimatedCostChanged,
}) => {
  const [newSeats, setNewSeats] = useState(numberOfSeats);
  const reachedSeatLimit = numberOfSeats === seatLimit;

  const subscriptionTerm =
    (subscription?.overrideRules?.subscriptionUnit ||
      subscription?.pulseSubscriptionTier?.rules?.subscriptionUnit) === 'years'
      ? 'Annual'
      : 'Monthly';

  const subscriptionName = subscription?.pulseSubscriptionTier?.name;

  // preview is based on the new desired total of active seats, which is why we add new seats to the number of active seats
  // we use the debounced version that is mirrored from `numberOfSeats` so that we don't make too many calls to this endpoint;
  // it is relatively expensive
  const { data: preview, isLoading } = useGetPreviewAllocation(
    { quantity: newSeats + numberOfActiveSeats },
    {
      enabled: newSeats > 0 && shouldFetchEstimate,
      retry: 1, // low retry count because it is expensive
      onSuccess: (preview) => {
        onEstimatedCostChanged(newSeats > 0 && preview?.result?.totalCents > 0);
      },
      onError: (err) => {
        snackbar.error(
          err?.response?.data?.humanReadableMessage ??
            'An error ocurred trying to generate estimated costs. Please contact us.'
        );
        onEstimatedCostChanged(false);
      },
    }
  );

  const estimatedAmount = useMemo(() => {
    if (numberOfSeats < 1) {
      return '0';
    }

    if (!preview) {
      return '-';
    }

    if (!preview?.result?.totalCents) {
      return '-';
    }

    // dollar math, so using Decimal to prevent float precision weirdness
    return new Decimal(preview.result.totalCents).dividedBy(100.0).toFixed(2);
  }, [preview, numberOfSeats]);

  const estimatedTimeRemaining = useMemo(() => {
    if (!preview) {
      return '- time';
    }

    if (!preview?.result?.startDate || !preview?.result?.endDate) {
      return '- time';
    }

    const startDate = Date.parse(preview.result.startDate);
    const endDate = Date.parse(preview.result.endDate);
    const timeDelta = endDate - startDate;

    const oneDayMilliseconds = 24 * 60 * 60 * 1000;
    const thirtyDaysMilliseconds = 30 * oneDayMilliseconds;

    if (timeDelta > thirtyDaysMilliseconds) {
      return `${((timeDelta / oneDayMilliseconds / 365) * 12).toFixed(3)} months`;
    } else {
      return `${(timeDelta / oneDayMilliseconds).toFixed(3)} days`;
    }
  }, [preview]);

  const estimatedTimeRemainingMessage = useMemo(() => {
    if (isLoading) {
      return <Typography variant="caption">Getting an estimate...</Typography>;
    }

    return numberOfSeats > 0 ? (
      <Typography variant="caption">Estimated amount for {estimatedTimeRemaining} remaining</Typography>
    ) : (
      <Typography variant="caption">Estimated amount will be calculated when you add seats.</Typography>
    );
  }, [estimatedTimeRemaining, isLoading, numberOfSeats]);

  useEffect(() => {
    const debounced = _.debounce(() => {
      setNewSeats(numberOfSeats);
    }, 500);

    debounced();

    return () => {
      debounced.cancel();
    };
  }, [numberOfSeats]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="body2">
          The charges for the new seats will occur by the end of the billing period and will include the
          prorated amount in addition to your regular charges.
        </Typography>
      </Grid>
      <Grid item xs={12} alignContent="center" minHeight={120}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-evenly"
          rowGap={{ xs: 1, sm: 0 }}
          flexWrap={{ xs: 'wrap-reverse', sm: 'nowrap' }}
          py={{ xs: 2, sm: 0 }}>
          <Grid item xs={12} sm={3} alignSelf="center">
            <SeatsCounter
              numberOfSeats={numberOfSeats}
              defaultSeatCount={0}
              hideTitle={true}
              setNumberOfSeats={onNumberOfSeatsChanged}
              textFieldStyles={{
                width: '45px',
                textColor: 'primary',
                inputBorderColor: 'rgba(75, 192, 200, 1.0)',
              }}
              iconColor={'#4BC0C8'}
            />
          </Grid>
          <Grid item xs={9} sm={6} sx={{ lineHeight: 0 }}>
            <Typography variant="body1" fontWeight={500}>
              {subscriptionTerm} {subscriptionName} seats
            </Typography>
            {estimatedTimeRemainingMessage}
          </Grid>
          <Grid item xs={3} sm={2} sx={{ textAlign: 'center' }} alignSelf="center">
            <Typography variant="body1" pr={2}>
              ${estimatedAmount}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {reachedSeatLimit && (
          <>
            <Typography variant="caption">
              You've reached the seat limit for your plan. To add or assign seats above this limit, please
              reach out to{' '}
              <a href="mailto:pulsesupport@wevo.ai" target="_top" style={{ textDecoration: 'none' }}>
                <Typography
                  variant="body1"
                  component={'span'}
                  style={{
                    color: '#43BCFF',
                    fontSize: '12px',
                    cursor: 'pointer',
                  }}>
                  pulsesupport@wevo.ai
                </Typography>
              </a>
              .
            </Typography>
          </>
        )}
      </Grid>
    </Grid>
  );
};

const AddSeatsAction = ({ subscription, disabled, onAddSeatsClicked, isLoading }) => {
  const subscriptionTerm =
    (subscription?.overrideRules?.subscriptionUnit ||
      subscription?.pulseSubscriptionTier?.rules?.subscriptionUnit) === 'years'
      ? 'Annual'
      : 'Monthly';

  return (
    <Grid container justifyContent={'flex-end'}>
      <Grid item px={2}>
        <CustomButton
          disabled={disabled || isLoading}
          variant="secondaryDark"
          sx={{ minWidth: 75 }}
          onClick={onAddSeatsClicked || (() => {})}>
          {isLoading ? <CircularProgress size={14} color="secondary" /> : `Add ${subscriptionTerm} Seats`}
        </CustomButton>
      </Grid>
    </Grid>
  );
};

const ContactSupport = ({
  handleTotalSeatsClick,
  totalNumberOfSeats,
  pendingInvites,
  unassignedSeats,
  styles,
}) => {
  return (
    <div>
      <SeatStatsBar
        totalNumberOfSeats={totalNumberOfSeats}
        pendingInvites={pendingInvites}
        unassignedSeats={unassignedSeats}
        styles={styles}
        onTotalSeatsClicked={handleTotalSeatsClick}
      />
      <Grid container spacing={2} justifyContent={'center'} sx={{ alignItems: 'center', height: '240px' }}>
        <Grid item xs={12}>
          <Typography sx={{ textAlign: 'center', fontSize: '13px' }}>
            To add or assign seats, please reach out
          </Typography>
          <Typography sx={{ textAlign: 'center', fontSize: '13px' }}>
            {`to `}
            <a href="mailto:pulsesupport@wevo.ai" target="_top" style={{ textDecoration: 'none' }}>
              <Typography
                variant="body1"
                component={'span'}
                style={{
                  color: '#43BCFF',
                  fontSize: '12px',
                  cursor: 'pointer',
                }}>
                pulsesupport@wevo.ai
              </Typography>
            </a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const SeatsModal = (props) => {
  const {
    open,
    closeCallback,
    subscriptionSeats,
    initialAction,
    resetOnClose,
    subscription,
    canViewUsers,
    canInviteUsers,
    canAddUsers,
  } = props;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  const [action, setAction] = useState(
    initialAction && Object.values(SeatManagementAction).includes(initialAction)
      ? initialAction
      : SeatManagementAction.ManageUsers
  );
  const [seatsToAdd, setSeatsToAdd] = useState(0);

  const activeSeatCount = (subscriptionSeats ?? []).filter((seat) => _.isNil(seat?.activeToDate)).length;

  const seatLimit = useMemo(() => {
    // we don't count seats that are going to expire in the term as part of the seat limit
    // they are technically "deleted", just on a delay
    const tierLimit =
      (subscription?.overrideFeatures?.seatLimit ||
        subscription?.pulseSubscriptionTier?.tier_features?.seatLimit) ??
      0;
    return Math.max(tierLimit - activeSeatCount, 0) || 0;
  }, [activeSeatCount, subscription]);

  const [usersToInvite, setUsersToInvite] = useState([]);

  const { mutate: allocateSeats } = useAllocateSeats();
  const { mutateAsync: provisionUsersAsync } = usePulseBulkCreateUsers();
  const { mutateAsync: assignUsersToSeatsAsync } = useAssignUsersToSeats();
  const { mutate: unallocateSeat } = useUnallocateSeat();
  const { mutate: reallocateSeat } = useReallocateSeat();

  // Add Seats loading / fetching control parameters
  const [isAddingSeats, setIsAddingSeats] = useState(false);
  const [canAddSeats, setCanAddSeats] = useState(true);

  // Provision & assign users control parameters
  const [isInvitingUsers, setIsInvitingUsers] = useState(false);

  // Seat Remove / restore control parameters
  const [isUpdatingSeatStatus, setIsUpdatingSeatStatus] = useState(false);

  const totalNumberOfSeats = subscriptionSeats?.length || 0;
  const pendingInvites =
    subscriptionSeats?.filter((seat) => seat?.user?.isEmailVerified === false)?.length || 0;
  const unassignedSeats = subscriptionSeats?.filter((seat) => _.isNull(seat?.user))?.length || 0;

  const handleClose = useCallback(() => {
    // don't close the modal if we know we're busy
    if (isAddingSeats || isInvitingUsers) {
      return;
    }

    !!closeCallback && closeCallback();

    if (resetOnClose) {
      setAction(initialAction);
      setUsersToInvite([]);
      setSeatsToAdd(0);
    }
  }, [
    closeCallback,
    resetOnClose,
    setAction,
    initialAction,
    setUsersToInvite,
    setSeatsToAdd,
    isAddingSeats,
    isInvitingUsers,
  ]);

  const handleInviteUsers = useCallback(async () => {
    if (!usersToInvite || usersToInvite.length < 1) {
      return;
    }

    setIsInvitingUsers(true);

    // users with no ID are users we need to invite
    const usersToProvision = usersToInvite.filter((user) => _.isNil(user?.id));

    let createdUsers = [];

    if (usersToProvision.length > 0) {
      try {
        const result = await provisionUsersAsync({
          users: usersToProvision.map((user) => ({
            emailAddress: user.emailAddress,
          })),
        });

        createdUsers = result.createdUsers;

        track(TrackEvent.PULSE_USER_INVITED_NEW_USERS, {
          userIds: createdUsers.map((u) => u?.id),
        });
      } catch (err) {
        const message =
          err?.response?.data?.humanReadableMessage ??
          'Something went wrong inviting users. Please contact us.';
        snackbar.error(message);
        setIsInvitingUsers(false);
        return;
      }
    }

    // users with an ID just get a seat assignment, no other work needed
    // we also concat the users we just created so that they are assigned seats
    const usersToAssign = usersToInvite.filter((user) => !_.isNil(user?.id)).concat(createdUsers);

    if (usersToAssign.length > 0) {
      try {
        await assignUsersToSeatsAsync({ userIds: usersToAssign.map((user) => user.id) });

        track(TrackEvent.PULSE_USER_ASSIGNED_USERS_TO_SEATS, {
          userIds: usersToAssign.map((u) => u?.id),
        });
      } catch (err) {
        const message =
          err?.response?.data?.humanReadableMessage ??
          'Something went wrong assigning users to seats. Please contact us.';
        snackbar.error(message);
        setIsInvitingUsers(false);
        return;
      }
    }

    // force user data to refetch, probably
    dispatch(UserActions.fetchUserInfo());

    // invalidate seats data
    await queryClient.invalidateQueries(['subscriptionSeats', { includeUsers: true }]);

    // unlock the modal
    setIsInvitingUsers(false);

    setAction(SeatManagementAction.ManageUsers);
  }, [
    assignUsersToSeatsAsync,
    dispatch,
    provisionUsersAsync,
    queryClient,
    setAction,
    setIsInvitingUsers,
    track,
    usersToInvite,
  ]);

  const handleAddSeats = useCallback(() => {
    setIsAddingSeats(true);

    // this function is a "true" PUT; it replaces the old count with the new one
    // so we need to provide the new desired quantity for the subscription in the call
    allocateSeats(
      { quantity: activeSeatCount + seatsToAdd },
      {
        onSuccess: () => {
          snackbar.success('Successfully added seats.');

          track(TrackEvent.PULSE_USER_ADDED_SEATS, {
            quantity: seatsToAdd,
            newCount: activeSeatCount + seatsToAdd,
          });

          // force user data to refetch, probably
          dispatch(UserActions.fetchUserInfo());

          // invalidate seats data
          queryClient.invalidateQueries(['subscriptionSeats', { includeUsers: true }]);

          // reset seats so user doesn't see the quantity they just added
          setSeatsToAdd(0);

          // either close the modal or redirect the user to the next logical place in the user management flow
          if (initialAction === SeatManagementAction.AddSeats) {
            handleClose();
          } else {
            setAction(SeatManagementAction.InviteUsers);
          }
        },
        onError: (err) => {
          snackbar.error(
            err?.response?.data?.humanReadableMessage ??
              'An error ocurred trying to add seats. Please contact us.'
          );
        },
        onSettled: () => setIsAddingSeats(false),
      }
    );
  }, [
    activeSeatCount,
    allocateSeats,
    dispatch,
    handleClose,
    initialAction,
    queryClient,
    seatsToAdd,
    setAction,
    setIsAddingSeats,
    track,
  ]);

  const handleUnallocateSeat = useCallback(
    (seat) => {
      setIsUpdatingSeatStatus(true);

      unallocateSeat(
        { seatId: seat.id },
        {
          onSuccess: () => {
            track(TrackEvent.PULSE_USER_REMOVED_USER, {
              seatId: seat.id,
              userId: seat?.usesr?.id,
            });

            snackbar.success('Request to remove user succeeded.');

            // force user data to refetch, probably
            dispatch(UserActions.fetchUserInfo());

            // invalidate seats data
            queryClient.invalidateQueries(['subscriptionSeats', { includeUsers: true }]);
          },
          onError: (err) => {
            snackbar.error(err?.humanReadableMessage ?? 'Request to remove user failed. Please contact us.');
          },
          onSettled: () => setIsUpdatingSeatStatus(false),
        }
      );
    },
    [dispatch, queryClient, setIsUpdatingSeatStatus, track, unallocateSeat]
  );

  const handleReallocateSeat = useCallback(
    (seat) => {
      setIsUpdatingSeatStatus(true);

      reallocateSeat(
        { seatId: seat.id },
        {
          onSuccess: () => {
            track(TrackEvent.PULSE_USER_RESTORED_USER, {
              seatId: seat.id,
              userId: seat?.usesr?.id,
            });

            snackbar.success('Request to restore user succeeded.');

            // force user data to refetch, probably
            dispatch(UserActions.fetchUserInfo());

            // invalidate seats data
            queryClient.invalidateQueries(['subscriptionSeats', { includeUsers: true }]);
          },
          onError: (err) => {
            snackbar.error(err?.humanReadableMessage ?? 'Request to restore user failed. Please contact us.');
          },
          onSettled: () => setIsUpdatingSeatStatus(false),
        }
      );
    },
    [dispatch, queryClient, reallocateSeat, setIsUpdatingSeatStatus, track]
  );

  const renderDialogTitle = useMemo(() => {
    switch (action) {
      case SeatManagementAction.AddSeats:
        return (
          <Typography variant="body1" fontWeight="bold" pb={1}>
            Add Seats
          </Typography>
        );
      default:
        return <></>;
    }
  }, [action]);

  const renderDialogContent = useMemo(() => {
    if (!subscription || !subscription?.isSeatBased) {
      return (
        <ContactSupport
          totalNumberOfSeats="-"
          pendingInvites="-"
          unassignedSeats="-"
          styles={contactSupportStyles}
          handleTotalSeatsClick={() => {}}
        />
      );
    }

    switch (action) {
      case SeatManagementAction.ManageUsers:
        if (canViewUsers) {
          return (
            <ManageUsers
              totalNumberOfSeats={totalNumberOfSeats}
              pendingInvites={pendingInvites}
              unassignedSeats={unassignedSeats}
              subscriptionSeats={subscriptionSeats}
              handleInviteClick={() => setAction(SeatManagementAction.InviteUsers)}
              onRemoveUserClicked={handleUnallocateSeat}
              onRestoreClicked={handleReallocateSeat}
              isUpdatingSeatStatus={isUpdatingSeatStatus}
              styles={manageUserStyles}
              showUserActions={canInviteUsers}
            />
          );
        }
        return (
          <ContactSupport
            totalNumberOfSeats={totalNumberOfSeats}
            pendingInvites={pendingInvites}
            unassignedSeats={unassignedSeats}
            styles={contactSupportStyles}
            handleTotalSeatsClick={() => setAction(SeatManagementAction.ManageUsers)}
          />
        );
      case SeatManagementAction.InviteUsers:
        if (canInviteUsers) {
          return (
            <InviteUsers
              seats={subscriptionSeats}
              totalNumberOfSeats={totalNumberOfSeats}
              pendingInvites={pendingInvites}
              unassignedSeats={unassignedSeats}
              styles={inviteUsersStyles}
              handleTotalSeatsClick={() => setAction(SeatManagementAction.ManageUsers)}
              handleAddSeatsClick={() => setAction(SeatManagementAction.AddSeats)}
              usersToInvite={usersToInvite}
              onUsersToInviteChanged={(users) => setUsersToInvite(users)}
            />
          );
        }

        return (
          <ContactSupport
            totalNumberOfSeats={totalNumberOfSeats}
            pendingInvites={pendingInvites}
            unassignedSeats={unassignedSeats}
            styles={contactSupportStyles}
            handleTotalSeatsClick={() => setAction(SeatManagementAction.ManageUsers)}
          />
        );
      case SeatManagementAction.AddSeats:
        if (canAddUsers) {
          return (
            <AddSeats
              subscription={subscription}
              numberOfSeats={seatsToAdd}
              seatLimit={seatLimit}
              numberOfActiveSeats={activeSeatCount}
              onNumberOfSeatsChanged={(newValue) => {
                setSeatsToAdd(
                  newValue > -1 ? (newValue > seatLimit ? Number(seatLimit) : Number(newValue)) : 0
                );
              }}
              onEstimatedCostChanged={(canAddSeats) => setCanAddSeats(canAddSeats)}
            />
          );
        }

        return (
          <ContactSupport
            totalNumberOfSeats={totalNumberOfSeats}
            pendingInvites={pendingInvites}
            unassignedSeats={unassignedSeats}
            styles={contactSupportStyles}
            handleTotalSeatsClick={() => setAction(SeatManagementAction.ManageUsers)}
          />
        );
      default:
        return (
          <ContactSupport
            totalNumberOfSeats={totalNumberOfSeats}
            pendingInvites={pendingInvites}
            unassignedSeats={unassignedSeats}
            styles={contactSupportStyles}
            handleTotalSeatsClick={() => setAction(SeatManagementAction.ManageUsers)}
          />
        );
    }
  }, [
    action,
    activeSeatCount,
    canInviteUsers,
    canAddUsers,
    canViewUsers,
    handleReallocateSeat,
    handleUnallocateSeat,
    isUpdatingSeatStatus,
    pendingInvites,
    subscriptionSeats,
    seatsToAdd,
    seatLimit,
    setSeatsToAdd,
    setUsersToInvite,
    subscription,
    totalNumberOfSeats,
    unassignedSeats,
    usersToInvite,
  ]);

  const renderDialogActions = useMemo(() => {
    switch (action) {
      case SeatManagementAction.InviteUsers:
        if (canInviteUsers) {
          return (
            <InviteUsersAction
              hidden={unassignedSeats < 1}
              disabled={
                usersToInvite?.length < 1 || usersToInvite?.length > unassignedSeats || isInvitingUsers
              }
              isLoading={isInvitingUsers}
              onInviteClicked={handleInviteUsers}
            />
          );
        }

        return <></>;
      case SeatManagementAction.AddSeats:
        if (canAddUsers) {
          return (
            <AddSeatsAction
              subscription={subscription}
              disabled={(seatsToAdd < 1 || seatsToAdd > seatLimit) && canAddSeats}
              isLoading={isAddingSeats}
              onAddSeatsClicked={handleAddSeats}
              shouldFetchEstimate={canAddSeats && !isAddingSeats}
            />
          );
        }
        return <></>;
      default:
        return <></>;
    }
  }, [
    action,
    canInviteUsers,
    canAddUsers,
    seatLimit,
    seatsToAdd,
    unassignedSeats,
    usersToInvite,
    canAddSeats,
    handleAddSeats,
    handleInviteUsers,
    isAddingSeats,
    isInvitingUsers,
    subscription,
  ]);

  const ariaProperties = useCallback(() => {
    switch (action) {
      case SeatManagementAction.ManageUsers:
        return { 'aria-labelledby': 'seats modal', 'aria-describedby': 'seats-modal' };
      default:
        return { 'aria-labelledby': 'contact support modal', 'aria-describedby': 'contact-support-modal' };
    }
  }, [action]);

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        {...ariaProperties()}
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: '20px',
            paddingTop: '16px',
            paddingBottom: '16px',
            minHeight: '360px',
            width: '750px',
          },
        }}>
        <DialogTitle sx={{ ...commonStyles.dialogTitle, marginTop: -2, marginBottom: 0.8 }}>
          {renderDialogTitle}
          <IconButton aria-label="close" size="small" onClick={handleClose} sx={manageUserStyles.closeIcon}>
            <CloseIcon style={{ fontSize: '16px' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>{renderDialogContent}</DialogContent>
        <DialogActions px={2}>{renderDialogActions}</DialogActions>
      </Dialog>
    </div>
  );
};

export default SeatsModal;
