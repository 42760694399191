import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { WevoType } from '../../../../modules/wevos/constants';
import { Box } from '@mui/material';
import HeatmapPreviewThumbnails from '../../components/HeatmapPreviewThumbnails';
import { ClusterTypes, HASH_LINKS } from '../../../../modules/report/constants';
import { usePrefetchAllStepsHeatmaps } from '../../hooks/useFetchStepHeatmap';
import HeatmapWithQuotes from '../../components/HeatmapWithQuotes';

const ExperienceSentimentMap = ({ wevo, page, showThumbnails }) => {
  const [step, setStep] = useState(page?.steps?.[0]);
  const [clusterType, setClusterType] = useState(ClusterTypes.Likes);

  const updateSelectedStep = useCallback(
    (newStep) => {
      setStep(newStep);
    },
    [setStep]
  );

  const prefetchResult = usePrefetchAllStepsHeatmaps({
    wevoId: wevo?.id,
    steps: page?.steps,
  });

  const assetToHeatmapPoints = useMemo(() => {
    if (prefetchResult.some((heatmapRequest) => heatmapRequest.isLoading)) {
      return {};
    }

    const assetList = wevo?.type === WevoType.Journey ? page.steps : wevo.pages;

    return prefetchResult.reduce((acc, heatmapRequest, index) => {
      if (heatmapRequest.isError) {
        return (acc[assetList[index]] = []);
      }

      acc[assetList[index].id] = heatmapRequest.data.filter(
        (point) => !_.isNil(point?.x) && !_.isNil(point?.y)
      );
      return acc;
    }, {});
  }, [prefetchResult, page, wevo.pages, wevo.type]);

  const points = useMemo(() => assetToHeatmapPoints?.[step.id] || [], [assetToHeatmapPoints, step]);

  const thumbnails = useMemo(() => {
    if (!showThumbnails) {
      return null;
    }

    return (
      <Box mb={1}>
        <HeatmapPreviewThumbnails
          wevo={wevo}
          selectedPage={page}
          selectedStep={step}
          selectedCluster={clusterType}
          handleAssetSelected={updateSelectedStep}
        />
      </Box>
    );
  }, [wevo, page, step, clusterType, updateSelectedStep, showThumbnails]);

  useEffect(() => {
    setStep(page?.steps?.[0]);
  }, [page]);

  return (
    <>
      {points?.length > 0 && (
        <HeatmapWithQuotes
          id={HASH_LINKS.experienceSentimentMap}
          title="How respondents engaged - Sentiment Map"
          wevo={wevo}
          page={page}
          step={step}
          heatmapPoints={points}
          showBorders
          showClusterTypes
          thumbnails={thumbnails}
          handleClusterTypeChanged={setClusterType}
        />
      )}
    </>
  );
};

ExperienceSentimentMap.propTypes = {
  wevo: PropTypes.object.isRequired,
  step: PropTypes.object,
  showThumbnails: PropTypes.bool,
};

export default ExperienceSentimentMap;
