import Container from '@mui/material/Container';
import React, { useState } from 'react';
import InfoDrawer from '../automated-insights/InfoDrawer';
import OnboardingUserForm from './Onboarding';

const OnboardingUser = (props) => {
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);

  const toggleInfoDrawer = (open) => {
    setInfoDrawerOpen(open);
  };

  return (
    <Container component="main" maxWidth="xs">
      <OnboardingUserForm {...props} />
      <InfoDrawer open={infoDrawerOpen} onClose={toggleInfoDrawer} />
    </Container>
  );
};

export default OnboardingUser;
