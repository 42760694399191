import { Add, Check, Visibility } from '@mui/icons-material';
import { Box, MenuItem, TextField, Typography, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useAnalytics } from 'use-analytics';
import { AutomatedInsightSections } from '../../modules/automated-insights/constants';
import { AssetBadgeLabelStyle, getBadgeLabel } from '../../modules/automated-insights/helpers';
import { TrackEvent } from '../analytics';
import DropdownIcon from './ui/DropdownIcon';

const AudienceTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'highlight',
})(({ theme, sx, highlight }) => ({
  '& .MuiInputBase-root': {
    width: sx?.width,
    fontSize: '12px',
    borderRadius: '27px',
    ...(highlight && {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      fontWeight: 600,
    }),
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiInputLabel-root': {
    color: '#575757',
    fontSize: '13px',
    '&.Mui-focused': {
      color: '#575757',
    },
    ...(highlight && {
      display: 'none',
    }),
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&:hover fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '& input': {
      cursor: sx?.cursor || 'pointer',
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '12px',
}));

const PROPOSED_AUDIENCE_OPTION = 'Proposed';
const MY_AUDIENCES_OPTION = 'My Personas';
const CREATE_AUDIENCE_OPTION = 'Create New';

// these are dummy options for designating special values as selectable options
export const ProposedAudienceOption = {
  id: PROPOSED_AUDIENCE_OPTION,
  name: PROPOSED_AUDIENCE_OPTION,
};

export const CreateNewAudienceOption = {
  id: CREATE_AUDIENCE_OPTION,
  name: CREATE_AUDIENCE_OPTION,
};

const AudienceSelect = ({
  selectedSegment,
  selectedTab,
  segments,
  isReadOnly,
  onAudienceChange,
  onMyAudiencesClick,
  onAudienceCreateClick,
  width = 145,
  maxHeight = 250,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
  disabled = false,
}) => {
  const handleAudienceChange = (e) => {
    if (e.target.value === MY_AUDIENCES_OPTION) {
      onMyAudiencesClick();
    } else if (e.target.value === CREATE_AUDIENCE_OPTION) {
      onAudienceCreateClick();
    } else if (
      selectedTab !== AutomatedInsightSections.MyAudience &&
      selectedSegment?.id &&
      e.target.value === selectedSegment.id
    ) {
      return;
    } else {
      const segment = segments?.find((segment) => segment?.id === e.target.value);
      onAudienceChange(segment);
    }
  };

  const selectedValue = useMemo(() => {
    if ([AutomatedInsightSections.MyAudience].includes(selectedTab)) {
      return MY_AUDIENCES_OPTION;
    }
    return segments?.length ? selectedSegment?.id ?? PROPOSED_AUDIENCE_OPTION : PROPOSED_AUDIENCE_OPTION;
  }, [segments, selectedSegment, selectedTab]);

  const segmentName = useMemo(
    () => (segments?.length ? selectedSegment?.name ?? PROPOSED_AUDIENCE_OPTION : PROPOSED_AUDIENCE_OPTION),
    [segments, selectedSegment]
  );

  if (isReadOnly) {
    return (
      <AudienceTextField
        id="select-persona"
        size="small"
        label="Persona"
        value={selectedSegment?.name ?? PROPOSED_AUDIENCE_OPTION}
        sx={{ cursor: 'initial', width }}
        InputProps={{
          readOnly: true,
        }}
      />
    );
  }

  return (
    <AudienceTextField
      id="select-persona"
      select
      sx={{ width }}
      size="small"
      highlight={selectedTab === AutomatedInsightSections.MyAudience}
      label="Persona"
      value={selectedValue}
      onChange={handleAudienceChange}
      SelectProps={{
        IconComponent: (props) => (
          <DropdownIcon
            {...props}
            colorVariant={selectedTab === AutomatedInsightSections.MyAudience ? '#FFFFFF' : '#0C3327'}
          />
        ),
        renderValue: () =>
          (selectedTab === AutomatedInsightSections.MyAudience && MY_AUDIENCES_OPTION) || segmentName,

        MenuProps: {
          className: 'transparentScrollBar',
          anchorOrigin: anchorOrigin,
          transformOrigin: transformOrigin,
          PaperProps: {
            sx: {
              boxShadow: 'none',
              border: '1px solid rgba(160, 160, 160, 0.37)',
              maxHeight: maxHeight,
            },
          },
        },
      }}
      disabled={disabled}>
      {onAudienceCreateClick && (
        <StyledMenuItem
          value={CREATE_AUDIENCE_OPTION}
          disableRipple
          sx={{ borderBottom: '1px solid rgba(160, 160, 160, 0.37)', color: 'rgb(92, 92, 92, 0.9)' }}>
          <Add sx={{ marginRight: 1, strokeWidth: 1, fontSize: '12px' }} /> {CREATE_AUDIENCE_OPTION}
        </StyledMenuItem>
      )}
      {!segments?.length && (
        <StyledMenuItem value={PROPOSED_AUDIENCE_OPTION} disableRipple>
          {PROPOSED_AUDIENCE_OPTION} <Check fontSize="small" sx={{ marginLeft: 1 }} />
        </StyledMenuItem>
      )}
      {segments?.map((segment) => (
        <StyledMenuItem key={segment?.id} value={segment?.id} disableRipple>
          {segment?.name}
          {selectedTab !== AutomatedInsightSections.MyAudience && segment?.id === selectedSegment?.id && (
            <Check fontSize="small" sx={{ marginLeft: 1 }} />
          )}
        </StyledMenuItem>
      ))}
      {/*This hidden value makes it so that the component doesn't throw errors because My Audiences isn't an option */}
      <StyledMenuItem value={MY_AUDIENCES_OPTION} sx={{ display: 'none' }}></StyledMenuItem>
    </AudienceTextField>
  );
};

export function MultiSessionAudienceSelect({
  sessions,
  selectedSegment,
  segments,
  onAudienceChange,
  onMyAudiencesClick,
  width = 150,
  maxHeight = 250,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
  disabled = false,
}) {
  const { track } = useAnalytics();

  const sessionSegments = useMemo(() => sessions.flatMap((session) => session?.segments ?? []), [sessions]);

  const handleAudienceChange = (e) => {
    if (e.target.value === MY_AUDIENCES_OPTION) {
      track(TrackEvent.VIEWED_ALL_COMPARE_AUDIENCES);
      onMyAudiencesClick();
    } else if (selectedSegment?.id && e.target.value === selectedSegment.id) {
      return;
    } else {
      const segment = (segments ?? [])
        .concat(sessionSegments)
        .find((segment) => segment?.id === e.target.value);
      track(TrackEvent.VIEWED_COMPARE_PULSE_AUDIENCE, { segmentId: segment?.id });

      onAudienceChange(segment);
    }
  };

  const shouldShowSessionSegments = useMemo(() => {
    // basically we don't want to show the audiences until they are all done loading,
    // and a way to do that is to ensure that every session has a generated segment
    return sessions.every((session) => !!session?.segments?.find((segment) => segment.isGenerated));
  }, [sessions]);

  const getGeneratedSessionSegment = useCallback(
    (session) => {
      if (!session?.segments || (session?.segments ?? []).length < 1) {
        return [];
      }

      const generatedSessionSegment = session?.segments?.find((segment) => segment.isGenerated);

      return [
        <StyledMenuItem
          key={generatedSessionSegment?.id}
          value={generatedSessionSegment?.id}
          disableRipple
          sx={{ '&:hover .visibility-icon': { display: 'initial' } }}>
          <Box sx={{ display: 'flex', width: '100%' }} justifyContent="space-between" alignContent="center">
            <Typography fontSize={12} minHeight={24} alignSelf="center">
              {generatedSessionSegment?.name}
            </Typography>
            {generatedSessionSegment?.id === selectedSegment?.id && (
              <Check fontSize="small" sx={{ marginLeft: 1 }} />
            )}
            {!(generatedSessionSegment?.id === selectedSegment?.id) && (
              <Visibility
                className="visibility-icon"
                fontSize="small"
                sx={{ marginLeft: 1, display: 'none' }}
              />
            )}
          </Box>
        </StyledMenuItem>,
      ];
    },
    [selectedSegment]
  );

  if (!shouldShowSessionSegments && onMyAudiencesClick) {
    return (
      <AudienceTextField
        size="small"
        label="Persona"
        value={selectedSegment?.name ?? `${sessions?.length} options selected`}
        onClick={() => onMyAudiencesClick()}
        InputProps={{
          readOnly: true,
        }}
        disabled={disabled}
      />
    );
  }

  return (
    <AudienceTextField
      select
      sx={{ width }}
      size="small"
      label="Persona"
      onChange={handleAudienceChange}
      value={segments?.length ? selectedSegment?.id ?? PROPOSED_AUDIENCE_OPTION : PROPOSED_AUDIENCE_OPTION}
      SelectProps={{
        IconComponent: (props) => <DropdownIcon {...props} />,
        renderValue: () =>
          shouldShowSessionSegments
            ? selectedSegment?.name ?? `${sessions?.length} options selected`
            : PROPOSED_AUDIENCE_OPTION,
        MenuProps: {
          className: 'transparentScrollBar',
          anchorOrigin: anchorOrigin,
          transformOrigin: transformOrigin,
          PaperProps: {
            sx: {
              boxShadow: 'none',
              border: '1px solid rgba(160, 160, 160, 0.37)',
              maxHeight: maxHeight,
            },
          },
        },
      }}
      disabled={disabled}>
      {(!segments?.length || !shouldShowSessionSegments) && (
        <StyledMenuItem value={PROPOSED_AUDIENCE_OPTION} disableRipple sx={{ display: 'none' }}>
          {PROPOSED_AUDIENCE_OPTION}
          <Check fontSize="small" sx={{ marginLeft: 1 }} />
        </StyledMenuItem>
      )}
      {shouldShowSessionSegments &&
        sessions.flatMap((session, index) => {
          return [
            <StyledMenuItem key={session.id} disabled sx={{ '&.Mui-disabled': { opacity: 1 } }}>
              <Typography fontSize={12} fontWeight={600}>
                {`Page ${getBadgeLabel(session?.assets?.[0], index, AssetBadgeLabelStyle.Alphabetical)}: ${
                  session.name
                }`}
              </Typography>
            </StyledMenuItem>,
          ].concat(getGeneratedSessionSegment(session));
        })}
      {onMyAudiencesClick && (
        <StyledMenuItem
          value={MY_AUDIENCES_OPTION}
          disableRipple
          sx={{ borderTop: '1px solid rgba(160, 160, 160, 0.37)', mt: 1 }}>
          View All
        </StyledMenuItem>
      )}
    </AudienceTextField>
  );
}

export default AudienceSelect;
