import darkTheme from '../../darkTheme';

/**
 * Feature detection as recommended by https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 */
function hasTouchScreen() {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = matchMedia?.('(pointer:coarse)');
    if (mQ?.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
}

export function isMobile() {
  const isWithinPortraitDimensions =
    window.innerWidth < darkTheme.breakpoints.values.md &&
    window.innerHeight < darkTheme.breakpoints.values.lg &&
    ['portrait-primary', 'portrait-secondary'].includes(window?.screen?.orientation?.type);

  const isWithinLandscapeDimensions =
    window.innerWidth < darkTheme.breakpoints.values.lg &&
    window.innerHeight < darkTheme.breakpoints.values.lg &&
    ['landscape-primary', 'landscape-secondary'].includes(window?.screen?.orientation?.type);

  return hasTouchScreen() && (isWithinPortraitDimensions || isWithinLandscapeDimensions);
}

export function isTablet() {
  const isWithinPortraitDimensions =
    window.innerWidth > darkTheme.breakpoints.values.md &&
    window.innerWidth < darkTheme.breakpoints.values.lg &&
    window.innerHeight > darkTheme.breakpoints.values.lg &&
    ['portrait-primary', 'portrait-secondary'].includes(window?.screen?.orientation?.type);

  const isWithinLandscapeDimensions =
    window.innerWidth > darkTheme.breakpoints.values.md &&
    window.innerWidth < darkTheme.breakpoints.values.xl &&
    window.innerHeight > darkTheme.breakpoints.values.md &&
    ['landscape-primary', 'landscape-secondary'].includes(window?.screen?.orientation?.type);

  return hasTouchScreen() && (isWithinPortraitDimensions || isWithinLandscapeDimensions);
}
