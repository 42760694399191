import _ from 'lodash';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import { Box, Button, Card, CardContent, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { ReactComponent as KeyFindingsIcon } from '../../../../assets/key-findings.svg';
import { hasUserKeyFindings, isClassicComparePages } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';
import theme from '../../../../theme';
import SlateKeyFindingsViewer from '../../takeaways/components/SlateKeyFindingsViewer';

const styles = makeStyles((theme) => ({
  card: {
    borderRadius: '20px',
    height: ({ isExpanded }) => (isExpanded ? '' : '100%'),
    maxHeight: ({ isExpanded }) => (isExpanded ? '' : '500px'),
    width: '100%',
  },
  cardContent: {
    display: 'flex',
    height: '100%',
  },
  container: {
    alignContent: 'space-between',
  },
  keyFindingsIcon: {
    color: theme.palette.primary.main,
    width: '25px',
    height: '25px',
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    textAlign: 'end',
    padding: '6px 0px',
  },
  linkGrid: {
    alignSelf: 'self-end',
  },
  rightArrow: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  underline: {
    textDecoration: 'none',
  },
  addButton: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
  },
  infoIcon: {
    color: grey[600],
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  iconGrid: {
    marginRight: theme.spacing(2),
  },
  icon: {
    backgroundColor: grey[100],
    borderRadius: '50%',
    padding: '3px',
  },
  grey: {
    backgroundColor: grey[100],
  },
  subText: {
    color: theme.palette.primary.dark,
  },
  keyFindingsContainer: {
    marginLeft: theme.spacing(6.5),
    marginRight: theme.spacing(4.5),
    overflowWrap: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'wrap',
    maxHeight: ({ isExpanded }) => (isExpanded ? '' : '340px'),
  },
  userKeyFindings: {
    alignItems: 'flex-start',
    minHeight: '260px',
  },
  tooltip: {
    textAlign: 'end',
  },
  text: {
    textAlign: 'center',
  },
  plusGrid: {
    marginTop: '-24px',
  },
  expandButton: {
    fontSize: '12px',
    textTransform: 'capitalize',
    lineHeight: 'inherit',
  },
}));

const UserKeyFindings = (props) => {
  const { wevo, page, userKeyFindings, selectedAssetNum, isLimitedReport } = props;

  const companyName = wevo?.company?.name;

  const [showExpandButton, setShowExpandButton] = useState();
  const [isExpanded, setIsExpanded] = useState(false);

  const classes = styles({ isExpanded });

  const teamKeyFindingsRef = useRef();

  const userKeyFindingsPath =
    wevo?.useVersionedDiagnostics && isClassicComparePages(wevo)
      ? generatePath(Paths.reports.takeawaysPage, {
          wevoId: wevo?.id,
          pageNum: selectedAssetNum,
        })
      : generatePath(Paths.reports.takeaways, {
          wevoId: wevo?.id,
        });

  useEffect(() => {
    handleExpandButton();
  }, [teamKeyFindingsRef?.current?.firstChild?.offsetHeight]); // firstChild of the ref refers to the element within the SlateKeyFindingsViewer

  useEffect(() => {
    window.addEventListener('resize', handleExpandButton);
    handleExpandButton();

    return () => {
      window.removeEventListener('resize', handleExpandButton);
    };
  });

  const handleExpandButton = () => {
    // firstChild of the ref refers to the element within the SlateKeyFindingsViewer
    if (teamKeyFindingsRef?.current?.firstChild?.offsetHeight) {
      setShowExpandButton(teamKeyFindingsRef?.current?.firstChild?.offsetHeight > 345);
    }
  };

  const toggleExpanded = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <Card className={classes.card} elevation={4}>
      <CardContent className={classes.cardContent}>
        {hasUserKeyFindings(userKeyFindings) ? (
          <Grid container spacing={2} justifyContent="center" className={classes.container}>
            <Grid item container spacing={1} xs={12}>
              <Grid item container className={classes.noWrap} xs={12}>
                <Grid item className={classes.iconGrid}>
                  {isLimitedReport ? (
                    <Box className={classes.icon}>
                      <KeyFindingsIcon fill={theme.palette.primary.main} className={classes.keyFindingsIcon} />
                    </Box>
                  ) : (
                    <IconButton size="small" className={classes.grey}>
                      <Link to={userKeyFindingsPath}>
                        <KeyFindingsIcon
                          fill={theme.palette.primary.main}
                          className={classes.keyFindingsIcon}
                        />
                      </Link>
                    </IconButton>
                  )}
                </Grid>
                <Grid item>
                  <Typography variant="h5">
                    {userKeyFindings?.header || `${companyName} Team's Key Findings`}
                  </Typography>
                  <Typography variant="caption" className={classes.subText}>
                    Insights generated by customers
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container className={classes.keyFindingsContainer}>
                <Typography variant="body2" component="span" ref={teamKeyFindingsRef}>
                  <SlateKeyFindingsViewer
                    key={`user-key-findings-dashboard-viewer-wevo-${wevo?.id}-page-${page?.id}`}
                    findings={userKeyFindings?.finding}
                    wevoId={wevo?.id}
                    pageId={page?.id}
                    isDashboard={true}
                  />
                </Typography>
              </Grid>
            </Grid>
            {isLimitedReport || _.isNil(selectedAssetNum) ? (
              showExpandButton && (
                <Grid item xs={12}>
                  <Button
                    className={classes.expandButton}
                    endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={toggleExpanded}
                    cursor={'pointer'}>
                    {isExpanded ? 'Show Less' : 'Show More'}
                  </Button>
                </Grid>
              )
            ) : showExpandButton ? (
              <Grid container item justifyContent="space-between">
                <Grid item>
                  <Button
                    className={classes.expandButton}
                    endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={toggleExpanded}
                    cursor={'pointer'}>
                    {isExpanded ? 'Show Less' : 'Show More'}
                  </Button>
                </Grid>
                <Grid item>
                  <Link
                    to={generatePath(Paths.reports.takeawaysPage, {
                      wevoId: wevo?.id,
                      pageNum: selectedAssetNum,
                    })}
                    className={classes.underline}>
                    <Typography variant="body2" className={classes.link}>
                      See Key Findings
                      <ChevronRightIcon fontSize="small" className={classes.rightArrow} />
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <Link
                  to={generatePath(Paths.reports.takeawaysPage, {
                    wevoId: wevo?.id,
                    pageNum: selectedAssetNum,
                  })}
                  className={classes.underline}>
                  <Typography variant="body2" className={classes.link}>
                    See Key Findings
                    <ChevronRightIcon fontSize="small" className={classes.rightArrow} />
                  </Typography>
                </Link>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container spacing={2} justifyContent="center" className={classes.userKeyFindings}>
            {isLimitedReport ? (
              <>
                <Grid item />
                <Grid item container justifyContent="center" xs={12}>
                  <Grid item xs={12} className={classes.text}>
                    <Typography variant="body2">
                      There currently aren't any <br /> Team Key Findings
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} className={classes.tooltip}>
                  <Tooltip
                    title={`Keep track of and share your learnings by adding your team's Key Findings`}
                    placement="left-start">
                    <InfoIcon color="primary" fontSize="small" className={classes.infoIcon} />
                  </Tooltip>
                </Grid>
                <Grid item container justifyContent="center" xs={12} className={classes.plusGrid}>
                  <Grid item>
                    <Typography>
                      <IconButton size="small">
                        <Link to={userKeyFindingsPath}>
                          <AddBoxIcon fontSize="large" className={classes.addButton} />
                        </Link>
                      </IconButton>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.text}>
                    <Typography variant="body2">Add your own Key Findings</Typography>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default UserKeyFindings;
