import { useQueries, useQuery } from 'react-query';
import { default as api, default as axios } from '../../../api';
import { ExpectationOutcome } from '../../../modules/report/constants';

const filterExpectationsQuotes = (outcome, expectations) => {
  return expectations?.[0]?.quotes?.filter((quote) => quote.outcome === outcome);
};

const fetchExpectationsQuotes = async (wevoId, pageId) => {
  if (!wevoId || !pageId) {
    return [];
  }

  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/expectations`,
    method: 'GET',
  });

  const expectations = data.expectations ?? [];
  const expectationOutcomes = [ExpectationOutcome.Met, ExpectationOutcome.Somewhat, ExpectationOutcome.Unmet];
  let modifiedExpectationHopeQuestion;
  let modifiedExpectationFearQuestion;

  if (expectations.length) {
    // The regexes below remove the parentheses part from the Expectation Hope and Fear questions
    modifiedExpectationHopeQuestion = data?.expectationHopeQuestion?.replace(/ *\([^)]*\) */, '');
    modifiedExpectationFearQuestion = data?.expectationFearQuestion?.replace(/ *\([^)]*\) */, '');
  }

  let quotes = [];
  expectationOutcomes.forEach((expectationOutcome) => {
    const expectationOutcomeQuotes = filterExpectationsQuotes(expectationOutcome, expectations) ?? [];
    quotes = quotes?.concat(expectationOutcomeQuotes);
  });

  return {
    quotes,
    expectationHopeQuestion: modifiedExpectationHopeQuestion || '',
    expectationFearQuestion: modifiedExpectationFearQuestion || '',
    pageId,
  };
};

const fetchExpectations = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  if (!wevoId || !pageId) {
    return {};
  }
  const response = await api({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/expectations`,
    method: 'GET',
  });
  return response?.data?.expectations ?? [];
};

export default function useExpectations({ wevoId, pageId }) {
  return useQuery(['expectationsData', { wevoId, pageId }], fetchExpectations, {
    notifyOnChangeProps: 'tracked',
    retry: false,
  });
}

/**
 *
 * @param {{wevoId: string, pageIds: [string]}} params
 * @returns
 */
export function useFetchAllPagesExpectationsQuotes({ wevoId, pageIds }) {
  return useQueries(
    pageIds?.map((pageId) => {
      return {
        queryKey: ['expectationsQuotes', { wevoId, pageId }],
        queryFn: () => fetchExpectationsQuotes(wevoId, pageId),
        staleTime: 30 * 1000,
      };
    })
  );
}
