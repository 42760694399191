import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { default as React, useCallback, useState } from 'react';
import {
  AudienceCategory,
  audienceIdToName,
  audienceTableWidth,
  SEGMENT_ID_TO_SEGMENT_LABEL,
} from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import useDeleteSavedAudience from '../hooks/useDeleteSavedAudience';
import DeleteSavedAudienceDialog from './DeleteSavedAudienceDialog';

const useStyles = makeStyles((theme) => ({
  deleteText: {
    fontSize: '12px',
  },
  deleteButton: {
    marginBottom: theme.spacing(-1),
    marginLeft: theme.spacing(-1),
  },
  arrowIcon: {
    color: theme.palette.primary.main,
    fontSize: '24px',
  },
  divider: {
    width: '100%',
    color: 'grey',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  segmentsDivider: {
    width: '120px',
    color: 'grey',
  },
  percentage: {
    color: theme.palette.primary.main,
    fontSize: '10px',
    marginLeft: theme.spacing(-4),
  },
  cellText: {
    fontSize: '10px',
  },
  tableCell: {
    borderBottom: 'none',
    width: 2,
  },
  delete: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

/**
 * Shows card with a saved audience and its info.
 */
export default function SavedAudienceCard(props) {
  const { savedAudienceGroup } = props;
  const classes = useStyles();

  const { mutate: deleteSavedAudience } = useDeleteSavedAudience();
  const [isSavedAudienceOpen, setIsSavedAudienceOpen] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const toggleShowDeleteSavedAudienceDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  const handleOpenSavedAudienceChange = () => {
    setIsSavedAudienceOpen((isSavedAudienceOpen) => !isSavedAudienceOpen);
  };

  const handleDeleteSavedAudienceClick = () => {
    deleteGroup(savedAudienceGroup?.id);
  };

  const deleteGroup = useCallback(
    (groupId) =>
      deleteSavedAudience(
        { groupId: groupId },
        {
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting saved audience');
          },
        }
      ),
    [deleteSavedAudience]
  );

  return (
    <Accordion
      expanded={isSavedAudienceOpen}
      key={savedAudienceGroup?.id}
      onChange={handleOpenSavedAudienceChange}>
      <AccordionSummary
        expandIcon={<KeyboardArrowDownIcon className={classes.arrowIcon} />}
        aria-controls="Saved-Audience"
        id="Saved-Audience-1">
        <FormControlLabel
          value={savedAudienceGroup?.id}
          control={
            <Radio
              color="primary"
              onClick={(ev) => {
                ev.stopPropagation(); // to stop toggling expansion of accordion when clicking radio button
              }}
            />
          }
          label={<Typography variant="h5">{savedAudienceGroup?.name}</Typography>}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="row">
          <Grid item container justifyContent="flex-start" xs={3}>
            <Table size="small" aria-label="group section">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableCell}>
                    <Typography variant="h6" className={classes.cellText}>
                      Group
                    </Typography>
                  </TableCell>
                  <TableCell align="right" className={classes.tableCell}>
                    <Typography variant="body2" className={classes.cellText}>
                      {AudienceCategory.B2C.id ===
                      Number(savedAudienceGroup?.['audience_categories']?.[0]?.audienceCategoryId)
                        ? AudienceCategory.B2C.name
                        : AudienceCategory.B2B.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Grid>
          <Grid item container spacing={2} xs={12}>
            {savedAudienceGroup?.audiences?.map((audience, index) => {
              const nonZeroAudience = audience?.['audience_segments'].filter(
                (segment) => segment.percentage > 0
              );
              const audienceId = audience?.audience?.['audience_id'];

              return (
                <Grid item key={index}>
                  <Table
                    size="small"
                    aria-label="audience segments"
                    style={{
                      minWidth: audienceTableWidth[audienceIdToName(Number(audienceId))],
                      maxWidth: audienceTableWidth[audienceIdToName(Number(audienceId))],
                    }}>
                    <TableBody>
                      <TableRow>
                        <TableCell align="left" className={classes.tableCell}>
                          <Typography
                            variant="h6"
                            className={classes.cellText}
                            style={{ color: !audience.audience.isSelected ? 'lightGrey' : '' }}>
                            {audienceIdToName(Number(audienceId))}
                          </Typography>
                        </TableCell>
                        <TableCell className={classes.tableCell} />
                      </TableRow>
                      {audience?.['audience_segments']
                        ?.filter((segment) => segment.percentage > 0)
                        ?.map((audienceSegment, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell
                                align="left"
                                style={{
                                  borderBottom: index === nonZeroAudience?.length - 1 && 'none',
                                  minWidth: audienceTableWidth[audienceIdToName(Number(audienceId))] - 10,
                                  maxWidth: audienceTableWidth[audienceIdToName(Number(audienceId))] - 10,
                                }}>
                                <Typography
                                  variant="body2"
                                  className={classes.cellText}
                                  style={{ color: !audience.audience.isSelected ? 'lightGrey' : '' }}>
                                  {SEGMENT_ID_TO_SEGMENT_LABEL[Number(audienceSegment?.audienceSegmentId)]}
                                </Typography>
                              </TableCell>
                              <TableCell
                                align="right"
                                style={{
                                  borderBottom: index === nonZeroAudience?.length - 1 && 'none',
                                  minWidth: 10,
                                  maxWidth: 10,
                                }}>
                                <Typography
                                  variant="h6"
                                  className={classes.percentage}
                                  style={{ color: !audience.audience.isSelected ? 'lightGrey' : '' }}>
                                  {audience?.audience?.isCustomizedQuota
                                    ? `${audienceSegment.percentage}%`
                                    : 'N/A'}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Grid>
              );
            })}
          </Grid>
          <Grid item container justifyContent="flex-start" alignItems="center">
            <Table size="small" aria-label="audience segments and quotas">
              <TableHead>
                <TableRow>
                  <TableCell align="left" className={classes.tableCell} style={{ width: 50 }}>
                    <Typography variant="h6" className={classes.cellText}>
                      Other Criteria
                    </Typography>
                  </TableCell>
                  <TableCell align="left" className={classes.tableCell} style={{ width: 450 }}>
                    <Typography variant="body2" className={classes.cellText}>
                      {savedAudienceGroup?.audienceDescription}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </Grid>
          <Divider className={classes.divider} />
          <Grid item xs={12} className={classes.deleteButton}>
            <Button
              size="small"
              onClick={() => toggleShowDeleteSavedAudienceDialog()}
              className={classes.delete}>
              <Typography className={classes.deleteText}>{'Delete'}</Typography>
            </Button>
          </Grid>
          <DeleteSavedAudienceDialog
            open={showDeleteDialog}
            deleteSavedAudience={handleDeleteSavedAudienceClick}
            closeCallback={toggleShowDeleteSavedAudienceDialog}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

SavedAudienceCard.propTypes = {
  savedAudienceGroup: PropTypes.object,
};
