import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, CardContent, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { UsabilityTestInfo } from '../../../../../modules/intake/constants';
import PropTypes from 'prop-types';

function UsabilityTestInfoBanner({ classes }) {
  return (
    <Box mt={2}>
      <Card sx={{ backgroundColor: '#d3deea' }}>
        <CardContent>
          <Grid container spacing={2} justifyContent="left">
            <Grid item xs={4}>
              <Typography variant="h5" className={classes.disabled}>
                Test Info:
              </Typography>
            </Grid>
            {UsabilityTestInfo?.map((item, index) => (
              <Grid item xs={4} key={index}>
                <Typography className={classes.infoText}>
                  {item.included ? (
                    <CheckIcon className={classes.infoIncluded} />
                  ) : (
                    <CloseIcon className={classes.infoOmitted} />
                  )}
                  {item.info}
                </Typography>
              </Grid>
            ))}
            <Grid item xs={12} sx={{ textAlign: 'right', marginBottom: '-12px', marginTop: '-16px' }}>
              <a
                href={'https://help.wevo.ai/beta-usability-tests-.5-credit?hs_preview=AtYvuojz-114420939268'}
                target="_blank"
                rel="noreferrer"
                className={classes.link}>
                <Typography className={classes.linkText}>Learn More</Typography>
              </a>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
}

UsabilityTestInfoBanner.propTypes = {
  classes: PropTypes.object,
};

export default UsabilityTestInfoBanner;
