import { Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCallback, useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router';
import { useAnalytics } from 'use-analytics';
import {
  DriversBenchmarkThresholds,
  DriversOrdering,
  VersionedDiagnosticsOrdering,
} from '../../../../modules/wevos/constants';
import { Paths } from '../../../../routes';
import { TrackEvent } from '../../../analytics';
import BarChartWithBenchmarks from '../../components/BarChartWithBenchmarks';

const styles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    background: 'white',
    padding: '4% 2% 4% 2%',
    borderRadius: '20px',
  },
}));

export default function CompareDriversGraph({ wevo, pages, benchmarks, ...rest }) {
  const history = useHistory();
  const [datasets, setDatasets] = useState([]);
  const [dataLabels, setDataLabels] = useState([]);

  const { track } = useAnalytics();

  const classes = styles(rest);

  const labels = wevo?.useVersionedDiagnostics ? VersionedDiagnosticsOrdering : DriversOrdering;

  useEffect(() => {
    setDatasets(
      pages
        .filter((page) => page.isSelected)
        .map((page) => {
          return labels.map((name) => Math.round(page.drivers[name]));
        })
    );

    setDataLabels(
      pages
        .filter((page) => page.isSelected)
        .map((page) => {
          return Array(labels.length).fill(page.label);
        })
    );
  }, [labels, pages]);

  const barColor = useCallback(
    (datum, idx, dataset, datasetIdx) => {
      return pages.filter((page) => page.isSelected)[datasetIdx]?.color;
    },
    [pages]
  );

  const tooltipBeforeLabel = useCallback(
    (formattedValue, rawValue, dataIdx, datasetIdx) => {
      return pages.filter((page) => page.isSelected)[datasetIdx]?.name;
    },
    [pages]
  );

  const tooltipTitle = useCallback(
    (label, dataIdx, datasetIdx) => {
      return `Page ${pages.filter((page) => page.isSelected)[datasetIdx]?.label}`;
    },
    [pages]
  );

  const onBarSelected = useCallback(
    (selectedlabel, selectedValue, dataIdx, datasetIdx) => {
      const page = pages.filter((page) => page.isSelected)[datasetIdx];
      track(TrackEvent.CLICKED_COMPARE_PAGE_GRAPH, {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        diagnostic: selectedlabel,
        testType: wevo?.type,
      });
      return history.push(
        generatePath(Paths.reports.diagnosticsPage, { wevoId: wevo?.id, pageNum: page?.pageNumber })
      );
    },
    [history, pages, track, wevo?.analyticsId, wevo?.id, wevo?.type]
  );

  return (
    <Paper elevation={4} className={classes.chartContainer}>
      <BarChartWithBenchmarks
        displayLegend={false}
        labels={labels}
        datasets={datasets}
        barColor={barColor}
        dataLabels={dataLabels}
        hideDataLabels={true}
        benchmarkThresholds={DriversBenchmarkThresholds}
        benchmarks={labels.map((name) => {
          const benchmark = benchmarks[name];
          return benchmark ? Math.round(benchmark) : null;
        })}
        width={'100%'}
        height={'300px'}
        tooltipTitle={tooltipTitle}
        tooltipBeforeLabel={tooltipBeforeLabel}
        tooltipLabel={(formattedValue) => `Score: ${formattedValue}`}
        onBarSelected={onBarSelected}
      />
    </Paper>
  );
}
