import { Box, Grid, Typography } from '@mui/material';
import backgroundTips from '../../assets/backgroundTips.png';
import { ReactComponent as IdeaIcon } from '../../assets/idea-gradient.svg';

const style = {
  tipBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    borderRadius: '20px',
    border: '1px solid #5C6F7B',
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingX: '15px',
    backgroundImage: `url(${backgroundTips})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  typographyBox: {
    paddingTop: '10px',
    height: 'fit-content',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tipText: {
    fontSize: { xs: '0.6rem', sm: '0.75rem', lg: '0.8rem' },
    fontWeight: 400,
    color: '#FFFFFF',
    textAlign: 'center',
    overflow: 'hidden',
  },
  headerText: {
    height: '22px',
    fontSize: { xs: '0.6rem', sm: '0.75rem', lg: '0.8rem' },
    fontWeight: 700,
    color: '#FFFFFF',
    textAlign: 'center',
  },
};

export default function TipBox({ text, ...rest }) {
  return (
    <Box {...rest}>
      <Box sx={style.tipBox}>
        <Box sx={{ height: 'fit-content', alignContent: 'center' }}>
          <Box sx={{ height: 'fit-content', width: '100%' }}>
            <Grid container alignItems="center" spacing={1} height="100%" justifyContent="center">
              <Grid item>
                <IdeaIcon width="20px" height="22px" />
              </Grid>
              <Grid item>
                <Typography sx={style.headerText}>TIP</Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={style.typographyBox}>
            <Typography sx={style.tipText}>{text}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
