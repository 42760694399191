import { Box, CircularProgress } from '@mui/material';
import { useMemo, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { ReactComponent as WarningIcon } from '../../assets/pulse-warning-icon.svg';
import { TaskStatus } from '../../modules/automated-insights/constants';

export default function SessionAssetThumbnail({
  asset,
  height,
  badge,
  onClick,
  status,
  sx,
  enableZoom,
  ...rest
}) {
  const [isOpen, setIsOpen] = useState(false);

  const thumbnailComponent = useMemo(() => {
    const handleOnClick = () => {
      document.body.style.overflow = isOpen ? '' : 'hidden';
      setIsOpen((isOpen) => !isOpen);
    };

    if (status === TaskStatus.Failed) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'inherit',
            background: 'rgba(92, 92, 92, 0.1)',
          }}>
          <Box>
            <WarningIcon style={{ height: 24, width: 24 }} />
          </Box>
        </Box>
      );
    }

    if ([TaskStatus.Pending, TaskStatus.Enqueued, TaskStatus.Running].includes(status)) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'inherit',
            background: 'rgba(92, 92, 92, 0.1)',
          }}>
          <CircularProgress color="secondary" size={24} />
        </Box>
      );
    }

    if (asset?.image?.url) {
      return (
        <>
          <img src={asset.image.url} alt="page" style={{ width: '100%' }} onClick={handleOnClick} />
          {enableZoom && isOpen && (
            <Lightbox
              mainSrc={asset.image.url}
              onCloseRequest={handleOnClick}
              reactModalStyle={{ overlay: { zIndex: 9000 } }}
            />
          )}
        </>
      );
    }

    return <></>;
  }, [asset, status, enableZoom, isOpen]);

  return (
    <Box
      key={asset.id}
      sx={{
        position: 'relative',
        height: height,
        overflow: 'hidden',
        borderRadius: 2,
        border: '1px solid rgba(0, 0, 0, 0.05)',
        ...sx,
        cursor: 'pointer',
      }}
      onClick={onClick || (() => {})}
      {...rest}>
      {thumbnailComponent}
      {badge && <Box sx={{ position: 'absolute', bottom: 5, right: 5 }}>{badge}</Box>}
    </Box>
  );
}
