import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { ReactComponent as ComparePagesIcon } from '../../../assets/compare-pages-dynamic.svg';
import { Paths } from '../../../routes';
import LeftNavItem from './LeftNavItem';

const useStyles = makeStyles((theme) => ({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
}));

const ComparePageLeftNavButton = ({ wevo, selected }) => {
  const classes = useStyles();
  return (
    <LeftNavItem
      icon={<ComparePagesIcon className={classes.svg} />}
      label="Compare"
      path={generatePath(Paths.reports.compare, { wevoId: wevo?.id })}
      selected={selected}
    />
  );
};

ComparePageLeftNavButton.propTypes = {
  wevo: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ComparePageLeftNavButton;
