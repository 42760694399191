import { Grid } from '@mui/material';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import AnswersCustomQuestion from '../../../assets/AnswersToCustomQuestions.png';
import Metrics from '../../../assets/DesignQualityScore.png';
import Heatmap from '../../../assets/HeatmapOfPainPoints.png';
import ExpectationsGap from '../../../assets/OptionalExpectationGap.png';
import QualQuant from '../../../assets/QualQuant.png';
import ScoresBenchmarks from '../../../assets/ScoresBenchmarks.png';
import UserlyticsKeyFindings from '../../../assets/UserlyticsKeyFindings.png';
import VideoTranscripts from '../../../assets/VideoTranscripts.png';
import WEVOKeyFindings from '../../../assets/WEVOKeyFindings.png';
import WEVOKeyFindingsCross from '../../../assets/WEVOKeyFindingsCross.png';
import CompareIcon from '../../../assets/compare.svg';
import CustomSurveyIcon from '../../../assets/customSurvey.svg';
import DiagnosticIcon from '../../../assets/diagnostic.svg';
import performanceCompare from '../../../assets/performanceCompare.png';
import UsabilityStudyIcon from '../../../assets/usabilityStudy.svg';
import { getUserCustomizations } from '../../../modules/user/selectors';
import { TrackEvent } from '../../analytics';
import SelectableToolCard from '../edit/SelectableToolCard';

export const Tools = {
  Diagnostic: 'diagnostic',
  Compare: 'compare',
  UsabilityStudy: 'usabilityStudy',
  CustomSurvey: 'customSurvey',
};

const DiagnosticGoals = [
  'Identify key pain points & strengths',
  'Diagnose using standardized scoring & benchmarking',
  'Understand gaps between user expectation & actual experience',
  'Get high volume verbatim feedback',
  '(Optional) Key insights and findings prepared by professional researcher',
];
const CompareGoals = ['Compare multiple designs before going live', 'Compare and learn from competitors'];
const UsabilityStudyGoals = [
  'See users interact with designs',
  'Ask users questions directly in a live session (moderated) or scripted questions (unmoderated)',
];
const CustomSurveyGoals = [
  'Validate market trends and needs',
  'Determine likely audience/segment for your new product',
];
export const Outputs = {
  UxScoreBenchmark: 'Ux Score & Benchmark',
  Metric: 'Metric: DQS/CDS or WEVO Standard Score',
  Heatmap: 'Heatmap of Pain Points',
  ExpectationsGap: '(Optional): Expectations Gap',
  AnswersCustomQuestion: 'Answers to Custom Questions',
  KeyFindings: '(Optional): Key Findings',
  PerformanceComparison: 'Performance Comparison of Multiple Designs',
  KeyFindingsCrossPage: '(Optional): Key Findings with Cross Page Comparison',
  VideosTranscripts: 'Videos & Transcripts of User Sessions',
  QualQuant: 'Qual & Quant Responses',
};

const DiagnosticOutputs = [
  Outputs.UxScoreBenchmark,
  Outputs.Heatmap,
  Outputs.AnswersCustomQuestion,
  Outputs.KeyFindings,
  Outputs.ExpectationsGap,
];

const DiagnosticOutputsDQS = [
  Outputs.Metric,
  Outputs.Heatmap,
  Outputs.AnswersCustomQuestion,
  Outputs.KeyFindings,
  Outputs.ExpectationsGap,
];
const CompareOutputs = [Outputs.PerformanceComparison, Outputs.KeyFindingsCrossPage];
const UsabilityStudyOutputs = [Outputs.VideosTranscripts, Outputs.KeyFindings];
const CustomSurveyOutputs = [Outputs.QualQuant, Outputs.KeyFindings];

const ToolSelection = ({ isUserlytics, isDqs }) => {
  let history = useHistory();
  const { track } = useAnalytics();
  const userCustomizations = useSelector(getUserCustomizations);
  const userlyticsDomain = userCustomizations?.integrations?.userlyticsDomain;
  const userlyticsTeamId = userCustomizations?.integrations?.userlyticsTeamId;
  const userlyticsProjectId = userCustomizations?.integrations?.userlyticsProjectId;

  const [toolSelected, setToolSelected] = useState(Tools.Diagnostic);
  const [uxScoreOpen, setUxScoreOpen] = useState(false);
  const [metricOpen, setMetricOpen] = useState(false);
  const [heatMapOpen, setHeatMapOpen] = useState(false);
  const [expectationsGapOpen, setExpectationsGapOpen] = useState(false);
  const [answersCustomQuestionOpen, setAnswersCustomQuestionOpen] = useState(false);
  const [keyFindingsOpen, setKeyFindingsOpen] = useState(false);
  const [keyFindingsCrossPageOpen, setKeyFindingscrossPageOpen] = useState(false);
  const [keyFindingsUserlyticsOpen, setKeyFindingsUserlyticsOpen] = useState(false);
  const [performanceComparisonOpen, setPerformanceComparisonOpen] = useState(false);
  const [videoTranscriptOpen, setVideoTranscriptOpen] = useState(false);
  const [qualQuantOpen, setQualQuantOpen] = useState(false);

  const handleToolSelected = (selectedTool) => {
    setToolSelected(selectedTool);
  };

  const handleStartClicked = (selectedTool) => {
    if (isUserlytics) {
      track(TrackEvent.USERLYTICS_CLICKED_START, {
        selectedTool: selectedTool,
        fromComponent: 'landing',
      });
    }

    const userlyticsBaseUrl = `${process.env.REACT_APP_USERLYTICS_HOST}${process.env.REACT_APP_USERLYTICS_SSO_PATH}/${userlyticsDomain}`;
    const userlyticsStudyPath = `/client/teams/${userlyticsTeamId}/projects/${userlyticsProjectId}/study`;

    // Redirect to WEVO test intake form
    if ([Tools.Diagnostic, Tools.Compare].includes(selectedTool)) {
      history.push('/wevos/create');
    }
    // Redirects to Userlytics
    else if (selectedTool === Tools.UsabilityStudy) {
      window.location.href = `${userlyticsBaseUrl}?redirect_uri=${userlyticsStudyPath}`;
    } else if (selectedTool === Tools.CustomSurvey) {
      window.location.href = `${userlyticsBaseUrl}?redirect_uri=${userlyticsStudyPath}?type=quant`;
    }
  };

  const handleOutputClicked = (output, tool) => {
    if (output === Outputs.UxScoreBenchmark) {
      if (!uxScoreOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setUxScoreOpen((uxScoreOpen) => !uxScoreOpen);
    } else if (output === Outputs.Metric) {
      if (!metricOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setMetricOpen((metricOpen) => !metricOpen);
    } else if (output === Outputs.Heatmap) {
      if (!heatMapOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setHeatMapOpen((heatMapOpen) => !heatMapOpen);
    } else if (output === Outputs.ExpectationsGap) {
      if (!expectationsGapOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setExpectationsGapOpen((expectationsGapOpen) => !expectationsGapOpen);
    } else if (output === Outputs.AnswersCustomQuestion) {
      if (!answersCustomQuestionOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setAnswersCustomQuestionOpen((answersCustomQuestionOpen) => !answersCustomQuestionOpen);
    } else if (output === Outputs.KeyFindings) {
      if ([Tools.UsabilityStudy, Tools.CustomSurvey].includes(tool)) {
        if (!keyFindingsUserlyticsOpen) {
          track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
        }
        setKeyFindingsUserlyticsOpen((keyFindingsUserlyticsOpen) => !keyFindingsUserlyticsOpen);
      } else {
        if (!keyFindingsOpen) {
          track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
        }
        setKeyFindingsOpen((keyFindingsOpen) => !keyFindingsOpen);
      }
    } else if (output === Outputs.PerformanceComparison) {
      if (!performanceComparisonOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setPerformanceComparisonOpen((performanceComparisonOpen) => !performanceComparisonOpen);
    } else if (output === Outputs.KeyFindingsCrossPage) {
      if (!keyFindingsCrossPageOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setKeyFindingscrossPageOpen((keyFindingsCrossPageOpen) => !keyFindingsCrossPageOpen);
    } else if (output === Outputs.VideosTranscripts) {
      if (!videoTranscriptOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setVideoTranscriptOpen((videoTranscriptOpen) => !videoTranscriptOpen);
    } else if (output === Outputs.QualQuant) {
      if (!qualQuantOpen) {
        track(TrackEvent.USERLYTICS_CLICKED_SCREENSHOT, { output, fromComponent: 'landing' });
      }
      setQualQuantOpen((qualQuantOpen) => !qualQuantOpen);
    }
  };

  return (
    <Grid container spacing={isDqs ? 4 : 1} justifyContent="center">
      <Grid item>
        <SelectableToolCard
          handleOutputClicked={(output) => handleOutputClicked(output, Tools.Diagnostic)}
          image={DiagnosticIcon}
          title={'Diagnostic'}
          testTypes={'Single Page, Journey and Compare Studies'}
          tool={Tools.Diagnostic}
          effort={'< 1 hour'}
          participants={'N = 120'}
          goals={DiagnosticGoals}
          outputs={isDqs ? DiagnosticOutputsDQS : DiagnosticOutputs}
          isSelected={toolSelected === Tools.Diagnostic}
          setToolSelected={() => handleToolSelected(Tools.Diagnostic)}
          onStartClick={() => handleStartClicked(Tools.Diagnostic)}
        />
      </Grid>
      {!isDqs && (
        <Grid item>
          <SelectableToolCard
            handleOutputClicked={(output) => handleOutputClicked(output, Tools.Compare)}
            image={CompareIcon}
            title={'Compare'}
            tool={Tools.Compare}
            effort={'< 1 hour'}
            participants={'N = 120 per page'}
            goals={CompareGoals}
            outputs={CompareOutputs}
            isSelected={toolSelected === Tools.Compare}
            setToolSelected={() => handleToolSelected(Tools.Compare)}
            onStartClick={() => handleStartClicked(Tools.Compare)}
          />
        </Grid>
      )}
      <Grid item>
        <SelectableToolCard
          handleOutputClicked={(output) => handleOutputClicked(output, Tools.UsabilityStudy)}
          image={UsabilityStudyIcon}
          title={'Usability Study'}
          tool={Tools.UsabilityStudy}
          effort={'~50 hour'}
          participants={'N = up to 12'}
          goals={UsabilityStudyGoals}
          outputs={UsabilityStudyOutputs}
          isSelected={toolSelected === Tools.UsabilityStudy}
          setToolSelected={() => handleToolSelected(Tools.UsabilityStudy)}
          onStartClick={() => handleStartClicked(Tools.UsabilityStudy)}
        />
      </Grid>
      <Grid item>
        <SelectableToolCard
          handleOutputClicked={(output) => handleOutputClicked(output, Tools.CustomSurvey)}
          image={CustomSurveyIcon}
          title={'Custom Survey'}
          tool={Tools.CustomSurvey}
          effort={'~50 hour'}
          participants={'N = 100'}
          goals={CustomSurveyGoals}
          outputs={CustomSurveyOutputs}
          isSelected={toolSelected === Tools.CustomSurvey}
          setToolSelected={() => handleToolSelected(Tools.CustomSurvey)}
          onStartClick={() => handleStartClicked(Tools.CustomSurvey)}
        />
      </Grid>
      {uxScoreOpen && (
        <Lightbox
          mainSrc={ScoresBenchmarks}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.UxScoreBenchmark, Tools.Diagnostic);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {metricOpen && (
        <Lightbox
          mainSrc={Metrics}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.Metric, Tools.Diagnostic);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {heatMapOpen && (
        <Lightbox
          mainSrc={Heatmap}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.Heatmap, Tools.Diagnostic);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {answersCustomQuestionOpen && (
        <Lightbox
          mainSrc={AnswersCustomQuestion}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.AnswersCustomQuestion, Tools.Diagnostic);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {keyFindingsOpen && (
        <Lightbox
          mainSrc={WEVOKeyFindings}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.KeyFindings, Tools.Diagnostic);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {expectationsGapOpen && (
        <Lightbox
          mainSrc={ExpectationsGap}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.ExpectationsGap, Tools.Diagnostic);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {performanceComparisonOpen && (
        <Lightbox
          mainSrc={performanceCompare}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.PerformanceComparison, Tools.Compare);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {keyFindingsCrossPageOpen && (
        <Lightbox
          mainSrc={WEVOKeyFindingsCross}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.KeyFindingsCrossPage, Tools.Compare);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {videoTranscriptOpen && (
        <Lightbox
          mainSrc={VideoTranscripts}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.VideosTranscripts, Tools.UsabilityStudy);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {qualQuantOpen && (
        <Lightbox
          mainSrc={QualQuant}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.QualQuant, Tools.CustomSurvey);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
      {keyFindingsUserlyticsOpen && (
        <Lightbox
          mainSrc={UserlyticsKeyFindings}
          onCloseRequest={() => {
            handleOutputClicked(Outputs.KeyFindings, Tools.UsabilityStudy);
          }}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
    </Grid>
  );
};

export default ToolSelection;
