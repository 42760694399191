import { Box, Button, Grid, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import wevoSuccessGIF from '../../../assets/wevoSuccessGIF.gif';
import { getUserCustomizations, getUserProfile } from '../../../modules/user/selectors';
import { useSelector } from 'react-redux';

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(4),
  },
  finalGif: {
    height: '165px',
  },
}));

const SuccessPage = () => {
  const classes = useStyles();
  let history = useHistory();

  const handleStartNewWevoClick = () => {
    history.push('./wevos/create');
  };

  const handleReturnToDashboardClick = () => {
    history.push('./dashboard');
  };

  const userCustomizations = useSelector(getUserCustomizations);
  const { email } = useSelector(getUserProfile);
  const approval = userCustomizations?.approval;
  const requiresApproval =
    approval?.approvalList?.length > 0 &&
    !approval?.approvalList.includes(email) &&
    !approval?.whitelist?.includes(email);

  return (
    <Box paddingTop="30px">
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <Typography align="center">
            <img className={classes.finalGif} src={wevoSuccessGIF} alt="the WEVO confetti Final logo" />
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h4" align="center">
            Your WEVO was submitted {requiresApproval ? 'for approval!' : 'successfully!'}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            A WEVO team member may reach out if there are specific items to confirm for your test.
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: '70px' }}>
          <Typography variant="body1" align="center">
            Need to submit another WEVO?
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">
            <Button variant="contained" size="large" onClick={handleStartNewWevoClick}>
              START A NEW WEVO
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2" align="center">
            {`Or return to your WEVO `}
            <Link
              color="primary"
              style={{ cursor: 'pointer' }}
              onClick={handleReturnToDashboardClick}
              underline="hover">
              Dashboard
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SuccessPage;
