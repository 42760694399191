import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchKeyFindingVotes = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  if (pageId) {
    const { data } = await axios({
      url: `/api/v2/wevos/${wevoId}/pages/${pageId}/key-findings/votes`,
      method: 'GET',
    });

    return data.votes ?? {};
  } else {
    const { data } = await axios({
      url: `/api/v2/wevos/${wevoId}/key-findings/votes`,
      method: 'GET',
    });

    return data.votes ?? {};
  }
};

/**
 *
 * @param {{wevoId: string, }} params
 */
export default function useKeyFindingVotes({ wevoId, pageId }) {
  return useQuery(['keyFindingVotes', { wevoId, pageId }], fetchKeyFindingVotes, {
    notifyOnChangeProps: 'tracked',
  });
}
