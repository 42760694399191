import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import { canUseAutomatedInsightSessions, canUseCore } from '../../modules/automated-insights/helpers';
import * as UserActions from '../../modules/user/actions';
import { isAuthenticated } from '../../modules/user/helpers';
import { getUserCompany, getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { isMobile, isTablet } from './devices';

/**
 * Converts date into yyyy-mm-dd format because Hubspot date properties are date pickers and cannot support datetime
 */
function dateToYMD(dateStr) {
  if (!dateStr) {
    return null;
  }

  const date = new Date(dateStr);

  const year = date.toLocaleString('default', { year: 'numeric' });
  const month = date.toLocaleString('default', { month: '2-digit' });
  const day = date.toLocaleString('default', { day: '2-digit' });

  // Generate yyyy-mm-dd date string
  return `${year}-${month}-${day}`;
}

/**
 * React hook for sending page load events to Segment.io
 *
 * @param {Object} props - The props to the component.
 * @param {string} props.name - The name of the page.
 * @param {Object} props.properties - Custom data associated a particular page load.
 * @param {Object} props.userProfile - An object containing user information, provided by redux
 * @param {Object} props.companyInfo - An object containing company information, provided by redux
 * @returns {JSX} - fragment
 */
export default function useTrackPageLoad({ name, properties }) {
  const { page, identify } = useAnalytics();
  const userProfile = useSelector(getUserProfile);
  const companyInfo = useSelector(getUserCompany);
  const userCustomizations = useSelector(getUserCustomizations);
  const dispatch = useDispatch();

  // Make sure the user of the app is authenticated
  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(UserActions.fetchUserInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // PageLoad is a componentized side-effect to call 'page' the first time a component is mounted.
  useEffect(() => {
    const isMobileDevice = isMobile();
    const isTabletDevice = isTablet();

    page(
      {
        name: name,
        userAgent: window.navigator.userAgent,
        isMobile: isMobileDevice && !isTabletDevice,
        isTablet: !isMobileDevice && isTabletDevice,
        isDesktop: !(isMobileDevice || isTabletDevice),
        ...properties,
      },
      {},
      () => {
        if (userProfile && userProfile.id) {
          // Convert dateCreated to yyyy-mm-dd format because Hubspot createdAtDate property is a date picker
          let createdAtDate;
          if (userProfile?.dateCreated) {
            createdAtDate = dateToYMD(userProfile.dateCreated);
          }

          const proEnabled = canUseCore(userCustomizations);
          const pulseEnabled = canUseAutomatedInsightSessions(userProfile, userCustomizations);

          const pulseSubscription = userProfile?.pulseSubscription;
          const pulseSubscriptionType = pulseSubscription?.pulseSubscriptionTier?.type;
          const pulseSubscriptionIsExpired = pulseSubscription?.isExpired ?? false;
          const pulseSubscriptionExpiresAtDate = dateToYMD(pulseSubscription?.expiresAt);

          // Identify user in Hubspot (id is an custom external ID)
          //
          // Note: Unsure whether we actually need this since segment should forward the identify
          // call to Hubspot. However, there is a potential race condition if we don't that I'm not
          // confident about where the call from Ketch might go to Hubspot before the identify call
          // from Segment.
          window._hsq?.push([
            'identify',
            {
              email: userProfile.email,
              id: userProfile.id,
            },
          ]);

          identify(userProfile.id, {
            firstName: userProfile.firstName,
            lastName: userProfile.lastName,
            email: userProfile.email,
            companyId: companyInfo?.id,
            companyName: companyInfo?.name,
            isEmailVerified: userProfile.isEmailVerified,
            createdAtDate,
            proEnabled,
            pulseEnabled,
            pulseSubscriptionType,
            pulseSubscriptionIsExpired,
            pulseSubscriptionExpiresAtDate,
          });
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile, userCustomizations]);
}
