import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import Lightbox from 'react-image-lightbox';

const ShowImageOverlay = (props) => {
  const { image, label, onCloseRequest, isOpen } = props;

  const url = image?.mini?.url || image?.original?.url;

  return (
    <Fragment>
      {isOpen && (
        <Lightbox
          mainSrc={url}
          imageTitle={label || ''}
          onCloseRequest={onCloseRequest}
          reactModalStyle={{ overlay: { zIndex: 9000 } }}
        />
      )}
    </Fragment>
  );
};

ShowImageOverlay.propTypes = {
  image: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onCloseRequest: PropTypes.func,
};

export default ShowImageOverlay;
