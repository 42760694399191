import { useQuery } from 'react-query';
import axios from '../api';

const fetchAuthorization = async () => {
  const { data } = await axios({
    url: `/api/v2/oauth/figma/authorization`,
    method: 'GET',
  });
  return data?.authorization;
};

export default function useFetchAuthorization() {
  return useQuery(['figmaAuthorization'], fetchAuthorization, {
    notifyOnChangeProps: 'tracked',
  });
}
