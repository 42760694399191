import { Button, styled } from '@mui/material';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { Paths } from '../../../routes';

// Explore Plans button which redirects to /subscription on click

const StyledExplorePlansButton = styled(Button, {
  shouldForwardProp: (prop) => !['height', 'width', 'fontSize'].includes(prop),
})(({ theme, height, width, fontSize }) => ({
  height: height || '28px',
  width: width || '105px',
  borderRadius: '27px',
  border: '1px solid #082739',
  color: 'rgba(9, 41, 60, 1)',
  textTransform: 'none',
  fontSize: fontSize || '12px',
  fontWeight: 'bold',
  whiteSpace: 'nowrap',
  '&:hover': {
    border: '1px solid #082739',
  },
}));

export const ExplorePlansButton = ({ blockedFeature, trackEventName, ...otherProps }) => {
  const history = useHistory();
  const { track } = useAnalytics();

  const redirectToSubscriptions = useCallback(() => {
    if (trackEventName && blockedFeature) {
      track(trackEventName, { blockedFeature });
    } else if (trackEventName) {
      track(trackEventName);
    }

    history.push(`${Paths.automatedInsights.basePath}/subscription`);
  }, [blockedFeature, history, track, trackEventName]);

  return (
    <StyledExplorePlansButton onClick={redirectToSubscriptions} {...otherProps}>
      Explore plans
    </StyledExplorePlansButton>
  );
};

// reusable button components

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'variant',
})(({ theme, variant }) => {
  // Default styles
  let styles = {
    minWidth: 100,
    fontSize: 12,
    textTransform: 'none',
    borderRadius: '27px',
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 140,
    },
  };

  // Variant-specific styles
  switch (variant) {
    case 'error':
      // red button
      styles = {
        ...styles,
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        '&:hover': {
          backgroundColor: theme.palette.error.main,
          opacity: 0.9,
        },
      };
      break;
    case 'gradient':
      // gradient button
      styles = {
        ...styles,
        color: theme.palette.text.secondary,
        background: theme.palette.gradient.main,
        border: '1px solid rgba(255,255,255,.3)',
        '&:hover': {
          border: '1px solid rgba(255,255,255,.5)',
          opacity: 0.9,
        },
      };
      break;
    case 'secondaryLight':
      // transparent button for dark backgrounds
      styles = {
        ...styles,
        color: theme.palette.text.secondary,
        backgroundColor: 'transparent',
        border: '1px solid rgba(255,255,255,.3)',
        '&:hover': {
          border: '1px solid rgba(255,255,255,.5)',
        },
      };
      break;
    case 'secondaryDark':
      // transparent button for light backgrounds
      styles = {
        ...styles,
        color: theme.palette.text.primary,
        backgroundColor: 'transparent',
        border: `1px solid ${theme.palette.text.primary}`,
        '&:hover': {
          opacity: 0.6,
        },
      };
      break;

    default:
      break;
  }

  return styles;
});

const CustomButton = ({ children, variant, ...otherProps }) => {
  return (
    <StyledButton variant={variant} disableElevation {...otherProps}>
      {children}
    </StyledButton>
  );
};

export default CustomButton;
