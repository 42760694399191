import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';

export const BarSegmentTickFormat = {
  Numeric: 'numeric',
  Percent: 'percent',
};

const useBarSegmentStyles = makeStyles((theme) => ({
  barSegments: {
    contain: 'strict',
    position: 'relative',
    height: (props) => `${props?.height || 200}px`,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  barSegment: {
    position: 'relative',
    top: '10px',
    height: '30px',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    borderRight: '1px solid lightgrey',
    '&:last-child': {
      borderRight: '0px solid lightgrey',
    },
  },
  barSegmentLabel: {
    position: 'absolute',
    top: '35px',
    height: '30px',
    color: grey[600],
    fontSize: '0.85rem',
    width: '100%',
    textAlign: 'center',
  },
  barSegmentBin: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  barSegmentItems: {
    contain: 'strict',
    zIndex: 2,
    position: 'absolute',
    top: '0px',
    left: '0px',
    height: '200px',
    width: '100%',
  },
  barSegmentBinRange: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '8px',
    width: '100%',
    color: 'white',
    background: 'repeating-linear-gradient(-45deg, #e6e6e6, #e6e6e6 2px, #cecece 4px, #cecece 4px)',
    '&-baseline': {
      backgroundColor: 'lightgrey',
    },
    '&-winner': {
      backgroundColor: 'lightskyblue',
    },
    '&-loser': {
      backgroundColor: 'lightcoral',
    },
  },
  barSegmentTickPercent: {
    color: '#CFD0D3',
    position: 'absolute',
    right: 0,
    bottom: -10,
    margin: '50px -15px -15px 0px',
  },
  barSegmentTickScore: {
    color: grey[600],
    fontWeight: 'bold',
    position: 'absolute',
    right: 0,
    bottom: -10,
    margin: '50px -10px -15px -0px',
  },
}));

const SegmentedBarSegment = ({ ratio, color, threshold, label, tickFormat, showTick = true, ...rest }) => {
  const classes = useBarSegmentStyles(rest);

  return (
    <div className={classes.barSegment} style={{ flex: ratio }}>
      <div className={classes.barSegmentBin}>
        <div style={{ background: `${color}`, height: '5px' }} className={classes.barSegmentBinRange} />
      </div>
      {showTick && tickFormat === BarSegmentTickFormat.Percent && (
        <h5 className={classes.barSegmentTickPercent}>
          {threshold.max ? `${threshold.max > 0 ? '+' : ''}${threshold.max}%` : ''}
        </h5>
      )}
      {showTick && tickFormat === BarSegmentTickFormat.Numeric && (
        <h5 className={classes.barSegmentTickScore}>{threshold.max ? `${threshold.max}` : ''}</h5>
      )}
      {label && <p className={classes.barSegmentLabel}>{label}</p>}
    </div>
  );
};

export default function BarSegments({
  bins,
  binRatios,
  binColors,
  binLabels = [],
  tickFormat = BarSegmentTickFormat.Percent,
  showTicks = true,
  ...rest
}) {
  const classes = useBarSegmentStyles(rest);
  return (
    <div className={classes.barSegments}>
      {bins.map((threshold, index) => (
        <SegmentedBarSegment
          key={`segment-${index}`}
          color={binColors[index]}
          ratio={binRatios[index]}
          label={binLabels?.[index]}
          threshold={threshold}
          tickFormat={tickFormat}
          showTick={showTicks}
        />
      ))}
    </div>
  );
}
