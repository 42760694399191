export const RAISE_ERROR = 'RAISE_ERROR';
export const DISMISS_ERROR = 'DISMISS_ERROR';

export const raiseError = failedActionPayload => {
  return {
    type: RAISE_ERROR,
    payload: {
      title: failedActionPayload.errorTitle,
      message: failedActionPayload.errorMessage,
      code: failedActionPayload.errorCode,
      seen: failedActionPayload.errorCode === 401, // error dialog is not displayed if seen=true
    }
  };
};

export const dismissError = () => {
  return {
    type: DISMISS_ERROR,
    payload: {}
  };
};
