const PinIcon = ({ height = '40px', fill = '#444c57', stroke = '#444c57', strokeWidth = '2px' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 30 41.47">
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        d="M15,1A13.87,13.87,0,0,0,1,14.65C1,24.89,15,40,15,40S29,24.89,29,14.65A13.87,13.87,0,0,0,15,1Z"
      />
    </svg>
  );
};

export default PinIcon;
