import { CircularProgress } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { canOnlyUseAutomatedInsightSessions } from '../../modules/automated-insights/helpers';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';

export const Start = () => {
  let history = useHistory();

  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);

  useEffect(() => {
    if (!user || !userCustomizations) {
      return;
    }

    // If the user was redirected to the login page, then redirect them back to the page they were on.
    const redirectToPath = sessionStorage.getItem('redirectToPath');
    if (redirectToPath) {
      sessionStorage.removeItem('redirectToPath');

      // NOTE: we use window.location.href instead of history.push because some paths have different
      // AppBars (e.g. /pulse)
      window.location.href = `${process.env.REACT_APP_BASE_URL}${redirectToPath}`;
      return;
    }

    const isMastercard = Boolean(userCustomizations?.isMastercard);
    const hasLoginInterstitial = userCustomizations?.hasLoginInterstitial !== false;

    // If the company is Pulse-only, then redirect them to /pulse
    const onlyAutomatedInsightSessionsEnabled = canOnlyUseAutomatedInsightSessions(user, userCustomizations);

    if (isMastercard) {
      history.push('/landing');
    } else if (onlyAutomatedInsightSessionsEnabled) {
      window.location.href = `${process.env.REACT_APP_BASE_URL}/pulse`;
      // If Fidelity - redirect to /dashboard
    } else if (!hasLoginInterstitial) {
      history.push('/dashboard');
    } else {
      history.push('/start');
    }
  }, [user, userCustomizations, history]);

  return <CircularProgress />;
};
