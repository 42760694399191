import Heatmap from 'heatmap.js';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CHUNK_HEIGHT } from '../../../modules/report/constants';

/**
 * Heatmap that automatically splits up data points into multiple heatmap components to improve
 * canvas rendering performance.
 */
const ReactHeatmap = ({ data, radius, height, minOpacity, maxOpacity }) => {
  // Chunks up the points into 10k pixel heatmap segments for faster canvas rendering.
  const chunks = useMemo(() => {
    const dataChunks = {};

    data.forEach((point) => {
      const chunkIndex = Math.floor(point.y / CHUNK_HEIGHT);
      if (!dataChunks[chunkIndex]) {
        dataChunks[chunkIndex] = [];
      }

      // Calculate the offset y coordinate from the start of the chunk instead of the total y.
      const offset = chunkIndex * CHUNK_HEIGHT;
      const offsetPoint = {
        ...point,
        y: point.y - offset,
      };

      dataChunks[chunkIndex].push(offsetPoint);
    });

    return dataChunks;
  }, [data]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      {Object.values(chunks).map((chunk, index) => {
        const chunkStartY = index * CHUNK_HEIGHT;
        const chunkEndY = (index + 1) * CHUNK_HEIGHT;

        // For initial chunks, use the max chunk height. For last chunk, set height to the remaining
        // height.
        let chunkHeight;
        if (chunkEndY < height) {
          chunkHeight = CHUNK_HEIGHT;
        } else {
          chunkHeight = Math.max(height - chunkStartY, 0);
        }

        if (!chunkHeight) {
          return null;
        }

        return (
          <div key={`heatmap-${chunkHeight}-${index}`} style={{ height: `${chunkHeight}px` }}>
            <ReactHeatmapChunk data={chunk} radius={radius} minOpacity={minOpacity} maxOpacity={maxOpacity} />
          </div>
        );
      })}
    </div>
  );
};

ReactHeatmap.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  radius: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  minOpacity: PropTypes.number,
  maxOpacity: PropTypes.number,
  gradient: PropTypes.objectOf(PropTypes.string),
};

ReactHeatmap.defaultProps = {
  data: [],
  minOpacity: 0,
  maxOpacity: 0.5,
  gradient: {
    0.25: '#0000FF',
    0.55: '#AEEA00',
    0.85: '#FFFF00',
    1.0: '#F50057',
  },
};

/**
 * Heatmap that renders one chunk of the data.
 *
 * See https://www.patrick-wied.at/static/heatmapjs/docs.html for documentation on heatmap.js
 */
const ReactHeatmapChunk = React.memo(({ data, radius, min, max, minOpacity, maxOpacity }) => {
  const heatmapRef = useRef();
  const [heatmap, setHeatmap] = useState();

  useEffect(() => {
    if (heatmapRef.current && heatmap) {
      heatmap.setData({ min, max, data });
    } else {
      const config = {
        radius,
        minOpacity,
        maxOpacity,
        container: heatmapRef.current,
      };

      const heatmapInstance = Heatmap.create(config);
      heatmapInstance.setData({ min, max, data });

      setHeatmap(heatmapInstance);
    }
  }, [heatmapRef, heatmap, setHeatmap, data, radius, min, max, minOpacity, maxOpacity]);

  return <div ref={heatmapRef} style={{ width: '100%', height: '100%', minHeight: '100px' }} />;
});

ReactHeatmapChunk.propTypes = {
  max: PropTypes.number,
  min: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  radius: PropTypes.number.isRequired,
  minOpacity: PropTypes.number,
  maxOpacity: PropTypes.number,
};

ReactHeatmapChunk.defaultProps = {
  data: [],
  max: 5,
  min: 0,
  minOpacity: 0,
  maxOpacity: 5,
};

export default ReactHeatmap;
