import { Box, CircularProgress, Divider, Grid, Paper, ToggleButtonGroup, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { DriversOrdering } from '../../../modules/wevos/constants';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import { BarChartBenchmarkLegend } from '../components/BarChartWithBenchmarks';
import ShowImageOverlay from '../components/ShowImageOverlay';
import StyledToggleButton from '../components/StyledToggleButton';
import UserKeyFindings from '../dashboard/components/UserKeyFindings';
import { WevoCrossPageKeyFindings } from '../dashboard/components/WevoKeyFindings';
import useComparePageResults from '../hooks/useComparePagesResults';
import { useFetchAllUsabilityScores } from '../hooks/useFetchUsabilityScores';
import { useCrossPageKeyFindings } from '../hooks/useKeyFindings';
import useUserKeyFindings from '../hooks/useUserKeyFindings';
import BaselineSelect from './components/BaselineSelect';
import CompareDriversGraph from './components/CompareDriversGraph';
import CompareDriversTable, { CompareDriversTableLegend } from './components/CompareDriversTable';
import CompareUsabilityMetricsTable from './components/CompareUsabilityMetricsTable';
import { BaselineSegmentedBar, PageScoreSegmentedBar } from './components/SegmentedBar/SegmentedBar';
import { TogglePageCardList } from './components/TogglePageCardList';
import { BaselineKeyWithTooltip, PageScoresTooltip } from './components/Tooltips';
import { ComparePageMode, PAGE_COLORS } from './constants';

const PAGE_SCORE_BINS = [
  { min: null, max: 60, label: 'Less Effective' },
  { min: 60, max: 80, label: 'Somewhat Effective' },
  { min: 80, max: 90, label: 'Effective' },
  { min: 90, max: null, label: 'Highly Effective' },
];
const assignPagesToBins = (bins, pageIds, liftScoresByPageId) => {
  return bins.map((bin) => {
    return {
      ...bin,
      pageIds: pageIds.filter(
        (pageId) =>
          (_.isNil(bin.min) || liftScoresByPageId[pageId] >= bin.min) &&
          (_.isNil(bin.max) || liftScoresByPageId[pageId] < bin.max)
      ),
    };
  });
};

const assignPagesToScoreBins = (bins, pages) => {
  const scoresByPage = pages.reduce((acc, page) => {
    acc[page.id] = Math.round(page?.score ?? 0.0);
    return acc;
  }, {});

  const pageIds = pages.map((page) => page.id);

  return bins.map((bin) => {
    return {
      ...bin,
      pageIds: pageIds.filter(
        (pageId) =>
          (_.isNil(bin.min) || scoresByPage[pageId] >= bin.min) &&
          (_.isNil(bin.max) || scoresByPage[pageId] < bin.max)
      ),
    };
  });
};

const computeLiftScoresForBaseline = (baselinePageId, pages, scores) => {
  const baselineScore = scores[baselinePageId].score;
  return pages.reduce((acc, page) => {
    acc[page.id] = (100 * (scores[page.id].score - baselineScore)) / baselineScore;
    return acc;
  }, {});
};

const findBaselineSelectedByUser = (pages) => {
  // Find the page that was selected as baseline by the user.
  const pageSelectedAsBaseLine = pages.find((page) => page.isBaseline);
  if (pageSelectedAsBaseLine) {
    return pageSelectedAsBaseLine.id;
  }
};

const findLowestScorePageId = (pages, scores) => {
  // Find the page with the lowest raw score. That's our default baseline page.
  return pages
    .map((page) => page.id)
    .reduce((baselineId, id) => (scores[baselineId].score <= scores[id].score ? baselineId : id));
};

const mapPropsToData = (props) => {
  const { wevo, results, selectedPages } = props;
  let pages = wevo && wevo.pages;

  // Make sure we have a score for each page. If we don't... uh, kick the can down the road I guess
  const hasScores =
    !_.isNil(pages) &&
    pages.every((page) => {
      const scores = _.get(results, ['pages', page.id, 'diagnostics'], null);
      return scores !== null;
    });

  if (!hasScores) {
    return {
      hasPageScores: false,
    };
  }

  const driverBenchmarks =
    results?.benchmarks ??
    DriversOrdering.reduce((acc, cur) => {
      acc[cur] = null;
      return acc;
    }, {});

  // Decide on a baseline page. If the user selected one, then this is easy. Otherwise we want to
  // select the one whose challengers have the lowest cumulative lift score.
  const baselinePageId =
    parseInt(props.baseline, 10) ||
    findBaselineSelectedByUser(pages) ||
    findLowestScorePageId(pages, results.pages);

  // Compute lift scores over the baseline by page ID
  const liftScoresByPageId = computeLiftScoresForBaseline(baselinePageId, pages, results.pages);

  // Sort the pages by score and assign them to bins
  const sortedPageIds = pages
    .map((page) => page.id)
    .sort((pageId1, pageId2) => liftScoresByPageId[pageId1] - liftScoresByPageId[pageId2]);

  const binAssignments = assignPagesToBins(results.binConfiguration, sortedPageIds, liftScoresByPageId);

  // Determine the winner. A page is a winner if:
  // a) it's alone in its bin... if there's more than 1 page in the bin, then none are labeled
  // winners
  // b) it's in a bin with a lower bound
  // c) if the bin has an upper bound, the upper bound is a positive number
  // Note: I copy binAssignments because reverse() works in-place and I don't want to modify
  // binAssignments
  const highestPopulatedBin = [...binAssignments].reverse().find((bin) => bin.pageIds.length > 0);

  const winnerPageId =
    highestPopulatedBin.pageIds.length === 1 && // a)
    !_.isNil(highestPopulatedBin.min) && // b)
    (_.isNil(highestPopulatedBin.max) || highestPopulatedBin.max > 0) && // c)
    highestPopulatedBin.pageIds[0];

  const pageScoreBinAssignments = assignPagesToScoreBins(PAGE_SCORE_BINS, pages);

  // Cram all of this info into the page objects
  pages = pages.map((page, index) => {
    const color = PAGE_COLORS[index % PAGE_COLORS.length];
    const drivers = _.get(results, ['pages', page.id, 'diagnostics'], {});
    const binIndex = binAssignments.findIndex((bin) => {
      return bin.pageIds.includes(page.id);
    });

    const pageScoreBinIndex = pageScoreBinAssignments.findIndex((bin) => {
      return bin.pageIds.includes(page.id);
    });

    let images = page?.images;

    // if the page is not an asset, use steps
    if (_.isEmpty(images)) {
      images = page?.steps?.[0]?.images;
    }

    return {
      ...page,
      color,
      drivers,
      rawScore: page.score,
      score: liftScoresByPageId[page.id],
      isBaseline: page.id === baselinePageId,
      isWinner: page.id === winnerPageId,
      isSelected: selectedPages[index],
      binIndex: binIndex,
      pageScoreBinIndex: pageScoreBinIndex,
      images,
    };
  });
  pages = pages.sort((a, b) => a.label.localeCompare(b.label));

  return {
    hasPageScores: true,
    bins: binAssignments,
    pageScoreBins: pageScoreBinAssignments,
    benchmarks: driverBenchmarks,
    pages,
    wevo,
  };
};

const useComparePageStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ComparePagesTitle = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="body1" gutterBottom>
          See how your page performs compared to an alternative design or competitor page
        </Typography>
      </Grid>
    </Grid>
  );
};

const ComparePagesDashboard = (props) => {
  const { wevo, baselinePageId } = props;
  const classes = useComparePageStyles();

  // prior to versioned diagnostics, top-level classic scores used a different system,
  // and were not displayed, so don't show scores when we detect the test is using the old system
  const useVersionedDiagnostics = useMemo(
    () => (wevo && (wevo?.useNewDiagnostics || wevo?.useVersionedDiagnostics)) || false,
    [wevo]
  );

  const usabilityScoresRequests = useFetchAllUsabilityScores(
    (wevo?.pages || []).map((page) => {
      return { wevoId: wevo.id, pageId: page.id };
    })
  );

  const usabilityScoresByPageId = useMemo(() => {
    const loading = usabilityScoresRequests.some((request) => request.isLoading);

    if (loading) {
      return {};
    }

    // guaranteed to return a successful response with either null or usability scores
    const pageUsabilityScores = usabilityScoresRequests.map((request) => request?.data);

    return wevo?.pages?.reduce((acc, page, index) => {
      acc[page.id] = pageUsabilityScores[index];
      return acc;
    }, {});
  }, [usabilityScoresRequests, wevo]);

  const shouldShowUsabilityTable = useMemo(() => {
    if (!usabilityScoresByPageId) {
      return false;
    }
    // only show usability scores table if every page has usability metrics
    return Object.values(usabilityScoresByPageId).every((usabilityScores) => Boolean(usabilityScores));
  }, [usabilityScoresByPageId]);

  const { data: crossPageKeyFindings } = useCrossPageKeyFindings({ wevoId: wevo.id });

  const hasCrossPageKeyFindings = useMemo(() => !_.isEmpty(crossPageKeyFindings), [crossPageKeyFindings]);

  const { data: userKeyFindings = {} } = useUserKeyFindings({
    wevoId: wevo?.id,
  });

  const hasUserKeyFindings = useMemo(() => !_.isEmpty(userKeyFindings), [userKeyFindings]);

  const [baselinePage, setBaselinePage] = useState(
    wevo?.pages?.find(({ id }) => id === Number(baselinePageId)) || wevo?.pages?.[0]
  );

  const [selectedPageFilters, setSelectedPageFilters] = useState([]);

  // default is scores for versioned diagnostics and percentage for old tests because prior to versioned diagnostics
  // comparing top-level page scores in this way doesn't make sense
  const compareMode = useVersionedDiagnostics ? ComparePageMode.Score : ComparePageMode.Percentage;

  const [showGraph, setShowGraph] = useState(true);

  const compareResults = useComparePageResults(wevo.id);

  const [data, setData] = useState({});

  const [overlayPage, setOverlayPage] = useState(null);

  useEffect(() => {
    setSelectedPageFilters(Array(wevo?.pages.length).fill(true));
  }, [wevo]);

  useEffect(() => {
    const hasError = compareResults.isFetched && compareResults.isError;
    const isScored =
      compareResults.data && Object.values(compareResults.data.pages).every((page) => page.score);
    if (!hasError && isScored) {
      setData(
        mapPropsToData({
          results: compareResults.data,
          baseline: baselinePage.id,
          selectedPages: selectedPageFilters,
          ...props,
        })
      );
    }
  }, [
    baselinePage,
    compareResults.data,
    compareResults.isError,
    compareResults.isFetched,
    selectedPageFilters,
    props,
  ]);

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_COMPARE_PAGES,
    properties: { wevoId: wevo?.analyticsId },
  });

  const { track } = useAnalytics();

  if (compareResults.isLoading) {
    return (
      <Box p={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  if (
    compareResults.isError ||
    (compareResults.isSuccess && (!data.hasPageScores || _.isEmpty(data.benchmarks)))
  ) {
    return (
      <div>
        <ComparePagesTitle />
        <Typography variant="body2">Some pages in this test have not been scored yet.</Typography>
      </div>
    );
  }

  return (
    <Fragment>
      <ComparePagesTitle />
      {compareMode === ComparePageMode.Score && (
        <Paper elevation={4} className={classes.paper}>
          <Box p={4} m={4}>
            <Grid container justifyContent="end" flow="row nowrap" alignItems="center" spacing={2}>
              <Grid item>
                <PageScoresTooltip />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <PageScoreSegmentedBar
                bins={data.pageScoreBins}
                pages={data.pages}
                selectedPages={selectedPageFilters}
                chartHeight={350}
              />
            </Box>
          </Box>
        </Paper>
      )}
      {compareMode === ComparePageMode.Percentage && (
        <Paper elevation={4} className={classes.paper}>
          <Box p={4} m={4}>
            <Grid container justifyContent="end" flow="row nowrap" alignItems="center" spacing={2}>
              <Grid item>
                <BaselineSelect
                  pages={data.pages}
                  selectedPageId={baselinePage.id}
                  onChangeBaseline={(pageId) => {
                    track(TrackEvent.SELECTED_COMPARE_PAGE_BASELINE, {
                      wevoId: wevo?.analyticsId,
                      pageId: pageId,
                      testType: wevo?.type,
                    });
                    setBaselinePage(data.pages.find(({ id }) => id === pageId));
                  }}
                />
              </Grid>
              <Grid item>
                <BaselineKeyWithTooltip />
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <BaselineSegmentedBar
                bins={data.bins}
                pages={data.pages}
                selectedPages={selectedPageFilters}
                chartHeight={350}
              />
            </Box>
          </Box>
        </Paper>
      )}
      <TogglePageCardList
        wevo={wevo}
        pages={data.pages || []}
        filters={selectedPageFilters}
        onTogglePage={(selectedIdx) => {
          track(TrackEvent.TOGGLED_COMPARE_PAGE_VISIBILITY, {
            wevoId: wevo?.analyticsId,
            pageId: data.pages?.[selectedIdx]?.id,
            visibility: !selectedPageFilters[selectedIdx],
            testType: wevo?.type,
          });

          const newFilters = selectedPageFilters.map((filterValue, idx) =>
            idx === selectedIdx ? !filterValue : filterValue
          );
          setSelectedPageFilters(newFilters);
        }}
        onImageClicked={(selectedIdx) => {
          track(TrackEvent.VIEWED_COMPARE_PAGE_IMAGE_OVERLAY, {
            wevoId: wevo?.analyticsId,
            pageId: data.pages?.[selectedIdx]?.id,
            testType: wevo?.type,
          });

          setOverlayPage(data.pages?.[selectedIdx]);
        }}
      />

      {overlayPage && (
        <ShowImageOverlay
          image={overlayPage.images}
          label={overlayPage.name}
          onCloseRequest={() => setOverlayPage(null)}
          isOpen={!!overlayPage}
        />
      )}

      {hasCrossPageKeyFindings && (
        <>
          <Box mt={10}>
            <Typography component="h2">Key Findings</Typography>
          </Box>

          <Divider className={classes.divider} />

          <Grid container spacing={2} justifyContent="flex-start">
            <Grid
              item
              xs={12}
              lg={hasUserKeyFindings || !hasCrossPageKeyFindings ? 6 : 9}
              sx={{ minHeight: '280px' }}>
              <WevoCrossPageKeyFindings wevo={wevo} isLimitedReport={false} />
            </Grid>
            <Grid
              item
              xs={12}
              lg={hasCrossPageKeyFindings && !hasUserKeyFindings ? 3 : 6}
              sx={{ minHeight: '280px' }}>
              <UserKeyFindings
                wevo={wevo}
                page={wevo?.pages?.[0]}
                hasUserKeyFindings={hasUserKeyFindings}
                userKeyFindings={userKeyFindings}
                selectedAssetNum={1}
                isLimitedReport={false}
              />
            </Grid>
          </Grid>
        </>
      )}

      {shouldShowUsabilityTable && (
        <>
          <Box mt={10}>
            <Typography component="h2">Usability Metrics</Typography>
          </Box>

          <Divider className={classes.divider} />
          <CompareUsabilityMetricsTable
            wevo={wevo}
            pages={data?.pages ?? []}
            usabilityMetrics={usabilityScoresByPageId}
          />
        </>
      )}

      <Box mt={10}>
        <Typography component="h2">Diagnostic Scores</Typography>
      </Box>

      <Divider className={classes.divider} />

      <Grid container justifyContent="space-between" alignItems="center" alignContent="space-between">
        <Grid>
          <ToggleButtonGroup exclusive aria-label="show graph or table selection" value={showGraph}>
            <StyledToggleButton
              value={true}
              onClick={() => {
                track(TrackEvent.VIEWED_COMPARE_PAGE_GRAPH, {
                  wevoId: wevo?.analyticsId,
                  testType: wevo?.type,
                });
                setShowGraph(true);
              }}>
              Graph
            </StyledToggleButton>
            <StyledToggleButton
              value={false}
              onClick={() => {
                track(TrackEvent.VIEWED_COMPARE_PAGE_TABLE, {
                  wevoId: wevo?.analyticsId,
                  testType: wevo?.type,
                });
                setShowGraph(false);
              }}>
              Table
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          {showGraph ? (
            <BarChartBenchmarkLegend benchmarkLabel={'Industry Benchmark'} />
          ) : (
            <CompareDriversTableLegend />
          )}
        </Grid>
      </Grid>
      <Box mt={4}>
        <Grid container>
          {showGraph ? (
            <CompareDriversGraph wevo={wevo} pages={data?.pages ?? []} benchmarks={data?.benchmarks} />
          ) : (
            <CompareDriversTable wevo={wevo} pages={data?.pages ?? []} benchmarks={data?.benchmarks} />
          )}
        </Grid>
      </Box>
    </Fragment>
  );
};

export default ComparePagesDashboard;
