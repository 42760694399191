import { useMutation } from 'react-query';
import { useAnalytics } from 'use-analytics';
import axios from '../../../api';
import { TrackEvent } from '../../analytics';

const downloadResults = async (wevo) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevo?.id}/results/export/presigned`,
    responseType: 'json',
    method: 'GET',
  });
  const link = document.createElement('a');
  link.href = data?.presignedUrl;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default function useDownloadResults() {
  const { track } = useAnalytics();
  return useMutation(downloadResults, {
    onMutate: (wevo) => {
      track(TrackEvent.DOWNLOADED_REPORT, { wevoId: wevo?.analyticsId, testType: wevo?.type });
    },
  });
}
