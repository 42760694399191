import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Grid, IconButton, styled, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { memo, useCallback, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { FixedSizeList as List, areEqual } from 'react-window';

const StyledIconButton = styled(IconButton)(() => ({
  '&:hover': {
    backgroundColor: 'transparent',
  },
}));

const Carousel = ({
  selectedItemIndex,
  items,
  itemComponent = ({ index, style, item }) => <></>,
  onSelection,
  onClick,
  firstItemIndex,
  itemHeight = 130,
  numItemsDisplayed = 4,
  containerStyles = {},
  backgroundColor,
  arrowHeight,
}) => {
  const theme = useTheme();
  const listRef = useRef();

  const { width, ref } = useResizeDetector();

  const [position, setPosition] = useState(0);

  const itemWidth = useMemo(() => width / numItemsDisplayed, [width, numItemsDisplayed]);

  const shouldDisplayArrows = items?.length > numItemsDisplayed;
  const arrowWidth = 30;
  const arrowWidthPx = `${arrowWidth}px`;
  const contentWidthPx = shouldDisplayArrows ? `calc(100% - ${2 * arrowWidth}px)` : '100%';

  const handleNext = () => {
    if (position < itemWidth * items?.length) {
      const nextPosition = position + itemWidth;
      setPosition(nextPosition);
      listRef.current.scrollTo(nextPosition);

      if (onClick) {
        onClick(firstItemIndex + 1);
      }
    }
  };

  const handlePrevious = () => {
    if (position > 0) {
      const prevPosition = position - itemWidth;
      setPosition(prevPosition);
      listRef.current.scrollTo(prevPosition);

      if (onClick) {
        onClick(firstItemIndex - 1);
      }
    }
  };

  const handleSelection = useCallback(
    (index) => {
      if (onSelection) {
        onSelection({ index, item: items[index] });
      }
    },
    [items, onSelection]
  );

  const ListItem = memo(({ index, style }) => {
    const selectedItem = items[selectedItemIndex];
    const currentItem = items[index];
    return (
      <div
        style={{
          overflowY: 'hidden',
          cursor: onSelection ? 'pointer' : 'initial',
          border:
            selectedItem?.id === currentItem?.id
              ? `3px solid ${theme.palette.primary.main}`
              : '3px solid transparent',
          borderRadius: 8,
          ...style,
        }}
        onClick={() => handleSelection(index)}>
        {itemComponent({ index, style, item: currentItem })}
      </div>
    );
  }, areEqual);

  return (
    <Grid
      container
      item
      sx={{
        backgroundColor: backgroundColor || theme.palette.background.default,
        borderRadius: '10px',
        ...containerStyles,
      }}>
      <Grid
        item
        sx={{
          display: shouldDisplayArrows ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          width: arrowWidthPx,
        }}>
        <StyledIconButton
          aria-label="previous"
          disabled={position === 0}
          disableRipple
          onClick={handlePrevious}>
          <ArrowBackIos fontSize="small" sx={{ height: arrowHeight || 'initial' }} />
        </StyledIconButton>
      </Grid>
      <Grid
        item
        ref={ref}
        sx={{
          overflow: 'hidden',
          width: contentWidthPx,
          height: itemHeight,
        }}>
        {width && (
          <List
            ref={listRef}
            height={itemHeight}
            itemCount={items?.length}
            itemSize={itemWidth}
            layout="horizontal"
            width={width}
            style={{ overflow: 'hidden' }}>
            {ListItem}
          </List>
        )}
      </Grid>
      <Grid
        item
        sx={{
          display: shouldDisplayArrows ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          width: arrowWidthPx,
        }}>
        <StyledIconButton
          aria-label="next"
          // position >= total width of all items minus width of the carousel container
          disabled={position >= itemWidth * items?.length - width}
          disableRipple
          onClick={handleNext}>
          <ArrowForwardIos fontSize="small" sx={{ height: arrowHeight || 'initial' }} />
        </StyledIconButton>
      </Grid>
    </Grid>
  );
};

Carousel.propTypes = {
  selectedItemIndex: PropTypes.number,
  items: PropTypes.array.isRequired,
  itemComponent: PropTypes.func.isRequired,
  onSelection: PropTypes.func,
  onClick: PropTypes.func,
  firstItemIndex: PropTypes.number,
  itemHeight: PropTypes.number,
  numItemsDisplayed: PropTypes.number,
};

export default Carousel;
