import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import axios from '../../../api';

const launchWevo = ({ id: wevoId }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/launch`,
    method: 'POST',
  });
};

export default function useLaunchWevo() {
  let history = useHistory();
  return useMutation(launchWevo, {
    onSuccess: () => {
      history.push('/success');
    },
  });
}
