import { useMutation, useQueryClient } from 'react-query';
import axios from '../api';

const copyWevo = async ({ id: wevoId }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/copy-test`,
    method: 'POST',
  });
  return response.data;
};

/**
 * Custom hook for copying a wevo.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the new wevo data
 */
export default function useCopyTest() {
  const queryClient = useQueryClient();

  return useMutation(copyWevo, {
    onSuccess: (data, { id: wevoId }) => {
      queryClient.invalidateQueries('wevoData');
      queryClient.invalidateQueries('wevos');
    },
  });
}
