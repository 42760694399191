import html2canvas from 'html2canvas';
import _ from 'lodash';
import pptxgen from 'pptxgenjs';
import downloadImageIcon from '../../assets/downloadImageIcon.png';
import wevoLogo from '../../assets/wevo_logo_wide_color_grey.png';
import { ComponentType, DEVICE_ID_TO_NAME } from '../../modules/wevos/constants';
import { hasComponent, hasQuotes } from '../../modules/wevos/helpers';
import { CustomQuestionTypes, MetricTypes } from '../intake/constants';
import { SLIDES } from './constants';
import { determineStarredQuoteIcon, getExperienceSummaryElementId } from './helpers';

// Compose Presentation
export const composePresentation = async (props) => {
  const { wevo, groups, starredQuotes, diagnosticsQuotes, expectationsQuotes } = props;

  let presentation = new pptxgen();
  const titleOpts = {
    fontSize: 32,
    bold: true,
    breakLine: true,
    w: '100%',
    h: '100%',
    valign: 'top',
    margin: 40,
    isTextBox: true,
  };
  const wevoLogoOpts = { x: 8.7, y: 5.2, w: 1.0, h: 0.2 };
  const wevoType = wevo?.type === 'classic' ? (wevo?.pages?.length > 1 ? 'compare' : 'classic') : 'journey';
  const isDqs = wevo?.metricType === MetricTypes.MastercardDqs;
  const isCds = wevo?.metricType === MetricTypes.MastercardCds;

  const hasStarredQuotes = hasQuotes(wevo, starredQuotes);
  const hasExpectationsQuotes = hasQuotes(wevo, expectationsQuotes, ComponentType.Expectation);
  const hasDiagnosticsQuotes = hasQuotes(wevo, diagnosticsQuotes, ComponentType.Diagnostic);
  const hasResultsOverview = wevo?.pages?.some((page) =>
    Boolean(document.getElementById(getExperienceSummaryElementId(page.id)))
  );

  // First slide
  const firstSlide = presentation.addSlide();

  firstSlide.addText(`${wevo?.name} Report`, {
    w: '100%',
    h: '100%',
    fontSize: 40,
    bold: true,
    valign: 'center',
    align: 'center',
  });

  for (let index = 0; index < SLIDES?.[wevoType]?.slides.length; index++) {
    const presSlide = SLIDES?.[wevoType]?.slides[index];

    // Objective slide
    if (presSlide.id === 'objective') {
      const allSectionsInSlide = [];
      const objectiveSlide = presentation.addSlide();
      objectiveSlide.addText(presSlide.title, titleOpts);

      presSlide?.fields?.forEach((field) => {
        let hasField;
        let textboxText;
        let textboxOpts = { fontSize: 14, paraSpaceAfter: 14, breakLine: true };

        switch (field.id) {
          case 'description':
            hasField = Boolean(wevo?.description);
            textboxText = hasField ? `"${wevo?.description}"` : '';
            break;
          default:
            hasField = Boolean(wevo?.[field.id]);
            textboxText = hasField ? wevo?.[field.id] : '';
        }

        if (hasField) {
          allSectionsInSlide.push({
            text: field.title,
            options: { fontSize: 18, bold: true, breakLine: true },
          });

          allSectionsInSlide.push({ text: textboxText, options: textboxOpts });
        }
      });

      objectiveSlide.addText(allSectionsInSlide, {
        y: 0.7,
        w: '100%',
        h: '85%',
        margin: 40,
        valign: 'top',
        shrinkText: true,
        isTextBox: true,
      });

      objectiveSlide.addImage({
        path: wevoLogo,
        ...wevoLogoOpts,
      });
    }

    // Test Details slide
    else if (presSlide.id === 'testDetails') {
      const allSectionsInSlide = [];
      const testDetailsSlide = presentation.addSlide();
      testDetailsSlide.addText(presSlide.title, titleOpts);

      presSlide?.fields?.forEach((field) => {
        let hasField;
        let textboxText;
        let textboxOpts = { fontSize: 14, paraSpaceAfter: 14, breakLine: true };
        const details = wevo?.details || {};

        switch (field.id) {
          case 'taskToComplete':
            hasField = Boolean(details?.taskToComplete);
            textboxText = hasField ? details?.taskToComplete?.replaceAll('\n', '') : '';
            break;
          case 'audienceDescription':
            hasField = Boolean(wevo?.audienceDescription);
            textboxText = hasField ? wevo?.audienceDescription.replaceAll('\n', '') : '';
            break;
          case 'devices':
            hasField = Boolean(wevo?.devices[0]);
            textboxText = hasField ? DEVICE_ID_TO_NAME[wevo?.devices[0]] : '';
            break;
          case 'journeyStartUrl':
            hasField = Boolean(details?.journeyStartUrl);
            textboxText = hasField ? 'Link' : '';
            if (hasField) {
              textboxOpts = { ...textboxOpts, hyperlink: { url: details.journeyStartUrl } };
            }
            break;
          default:
            hasField = Boolean(wevo?.[field.id]);
            textboxText = hasField ? wevo?.[field.id] : '';
        }

        if (hasField) {
          allSectionsInSlide.push({
            text: field.title,
            options: { fontSize: 18, bold: true, breakLine: true },
          });

          allSectionsInSlide.push({ text: textboxText, options: textboxOpts });
        }
      });

      testDetailsSlide.addText(allSectionsInSlide, {
        y: 0.7,
        w: '100%',
        h: '85%',
        margin: 40,
        valign: 'top',
        shrinkText: true,
        isTextBox: true,
      });

      testDetailsSlide.addImage({
        path: wevoLogo,
        ...wevoLogoOpts,
      });
    }

    // Results Overview slide
    else if (presSlide.id === 'resultsOverview' && hasResultsOverview) {
      for (let index = 0; index < wevo?.pages?.length; index++) {
        const allSectionsInSlide = [];
        const page = wevo?.pages?.[index];
        const experienceSummaryElement = document.getElementById(getExperienceSummaryElementId(page.id));

        const resultsOverviewSlide = presentation.addSlide();
        resultsOverviewSlide.addText(presSlide.title, titleOpts);

        if (experienceSummaryElement) {
          const canvas = await html2canvas(experienceSummaryElement);
          const experienceSummaryPath = canvas.toDataURL('image/png');

          resultsOverviewSlide.addImage({
            data: experienceSummaryPath,
            x: 0.55,
            y: 2.4,
            w: 3.8,
            h: 2.85,
            valign: 'top',
            align: 'center',
          });
        }

        allSectionsInSlide.push({
          text: `Page name: ${page?.name}`,
          options: {
            fontSize: 13,
            breakLine: true,
            paraSpaceAfter: 8,
          },
        });

        presSlide?.fields?.forEach((field) => {
          let hasField;
          let textboxText;
          let textboxOpts = { fontSize: 14, paraSpaceAfter: 14, breakLine: true };

          switch (field.id) {
            case 'numRespondents':
              hasField = Boolean(page?.[field.id]);
              textboxText = hasField ? page?.[field.id] : '';
              break;
            default:
              hasField = Boolean(page?.[field.id]);
              textboxText = hasField ? page?.[field.id] : '';
          }

          if (hasField) {
            allSectionsInSlide.push({
              text: field.title,
              options: { fontSize: 18, bold: true, breakLine: true },
            });

            allSectionsInSlide.push({ text: textboxText, options: textboxOpts });
          }
        });

        resultsOverviewSlide.addText(allSectionsInSlide, {
          y: 0.75,
          w: '100%',
          h: '85%',
          margin: 40,
          valign: 'top',
          shrinkText: true,
          isTextBox: true,
        });

        resultsOverviewSlide.addImage({
          path: wevoLogo,
          ...wevoLogoOpts,
        });
      }
    }

    // Key Findings slide - Is not part of the MVP
    else if (presSlide.id === 'keyFindings' && hasComponent(wevo, ComponentType.KeyFinding)) {
      // if (!_.isEmpty(serializedKeyFindings)) {
      //   const keyFindingsSlide = presentation.addSlide();
      //   keyFindingsSlide.addText(presSlide.title, titleOpts);
      //   keyFindingsSlide.addText(`${serializedKeyFindings}`, {
      //     y: 0.7,
      //     w: '100%',
      //     h: '85%',
      //     fontSize: 14,
      //     margin: 40,
      //     valign: 'top',
      //     align: 'left',
      //     shrinkText: true,
      //     isTextBox: true,
      //   });
      //   keyFindingsSlide.addImage({
      //     path: wevoLogo,
      //     ...wevoLogoOpts,
      //   });
      // }
    }

    // Team Key Findings slide - Is not part of the MVP
    else if (presSlide.id === 'teamKeyFindings') {
      // if (!_.isEmpty(serializedTeamKeyFindings)) {
      //   const teamKeyFindingsSlide = presentation.addSlide();
      //   teamKeyFindingsSlide.addText(presSlide.title, titleOpts);
      //   teamKeyFindingsSlide.addText(`${serializedTeamKeyFindings}`, {
      //     y: 0.7,
      //     w: '100%',
      //     h: '85%',
      //     fontSize: 14,
      //     margin: 40,
      //     valign: 'top',
      //     align: 'left',
      //     shrinkText: true,
      //     isTextBox: true,
      //   });
      //   teamKeyFindingsSlide.addImage({
      //     path: wevoLogo,
      //     ...wevoLogoOpts,
      //   });
      // }
    }

    // Starred Quotes slide
    else if (presSlide.id === 'starredQuotes' && hasStarredQuotes) {
      for (let index = 0; index < wevo.pages.length; index++) {
        const page = wevo.pages[index];
        const pageStarredQuotes = starredQuotes[page.id];

        const starredQuotesSlide = presentation.addSlide();
        starredQuotesSlide.addText(presSlide.title, titleOpts);

        starredQuotesSlide.addText(`Page name: ${page?.name}`, {
          y: 0.75,
          w: '100%',
          h: '100%',
          valign: 'top',
          margin: 40,
          isTextBox: true,
          fontSize: 13,
        });

        let height = 1.7;

        for (let index = 0; index < Math.min(pageStarredQuotes?.length, 4); index++) {
          const starredQuote = pageStarredQuotes[index];
          const starredQuoteIcon = determineStarredQuoteIcon(starredQuote?.quote);

          starredQuotesSlide.addText(`"${starredQuote.quote.quote}"`, {
            shape: presentation.shapes.ROUNDED_RECTANGLE,
            x: 1.0,
            y: height,
            w: 9.2,
            h: 0.8,
            rectRadius: 0.2,
            fill: { color: 'F3F8FB' },
            align: 'left',
            fontSize: 14,
            margin: 20,
          });

          starredQuotesSlide.addImage({
            data: starredQuoteIcon,
            x: 0.85,
            y: height + 0.26,
            w: 0.3,
            h: 0.3,
          });

          height += 0.9;
        }

        starredQuotesSlide.addImage({
          path: wevoLogo,
          ...wevoLogoOpts,
        });
      }
    }

    // Diagnostics Graph slide
    else if (presSlide.id === 'diagnosticsGraph' && hasComponent(wevo, ComponentType.Diagnostic)) {
      for (let index = 0; index < wevo?.pages?.length; index++) {
        const diagnosticsGraphSlide = presentation.addSlide();
        const page = wevo?.pages?.[index];
        diagnosticsGraphSlide.addText(presSlide.title, titleOpts);

        const diagnosticsGraphElement = document.getElementById(`diagnosticsGraph-${page.id}`);

        if (diagnosticsGraphElement) {
          const canvas = await html2canvas(diagnosticsGraphElement);
          const diagnosticsGraphPath = canvas.toDataURL('image/png');
          diagnosticsGraphSlide.addImage({
            data: diagnosticsGraphPath,
            x: 1.4,
            y: 1.4,
            w: 7.0,
            h: 4,
            valign: 'top',
            align: 'center',
          });
        }

        diagnosticsGraphSlide.addText(`Page name: ${page?.name}`, {
          y: 0.75,
          w: '100%',
          h: '100%',
          valign: 'top',
          margin: 40,
          isTextBox: true,
          fontSize: 13,
        });

        diagnosticsGraphSlide.addImage({
          path: wevoLogo,
          ...wevoLogoOpts,
        });
      }
    }

    // Diagnostics Quotes slide
    else if (presSlide.id === 'diagnosticsQuotes' && hasDiagnosticsQuotes) {
      for (let index = 0; index < wevo.pages.length; index++) {
        const page = wevo.pages[index];
        const pageDiagnosticsQuotes = diagnosticsQuotes[page.id];

        const diagnosticsQuotesSlide = presentation.addSlide();
        diagnosticsQuotesSlide.addText(presSlide.title, titleOpts);

        diagnosticsQuotesSlide.addText(`Page name: ${page?.name}`, {
          y: 0.75,
          w: '100%',
          h: '100%',
          valign: 'top',
          margin: 40,
          isTextBox: true,
          fontSize: 13,
        });

        let height = 1.7;

        // some pages don't have starred quotes
        for (let index = 0; index < Math.min(pageDiagnosticsQuotes?.length, 4); index++) {
          const diagnosticsQuote = pageDiagnosticsQuotes[index];
          const starredQuoteIcon = determineStarredQuoteIcon(diagnosticsQuote);

          diagnosticsQuotesSlide.addText(`"${diagnosticsQuote?.quote}"`, {
            shape: presentation.shapes.ROUNDED_RECTANGLE,
            x: 1.0,
            y: height,
            w: 9.2,
            h: 0.8,
            rectRadius: 0.2,
            fill: { color: 'F3F8FB' },
            align: 'left',
            fontSize: 14,
            margin: 20,
          });

          diagnosticsQuotesSlide.addImage({
            data: starredQuoteIcon,
            x: 0.85,
            y: height + 0.26,
            w: 0.3,
            h: 0.3,
          });
          height += 0.9;
        }

        diagnosticsQuotesSlide.addImage({
          path: wevoLogo,
          ...wevoLogoOpts,
        });
      }
    }

    // Expectations Graph slide
    else if (presSlide.id === 'expectationsGraph' && hasComponent(wevo, ComponentType.Expectation)) {
      if (!isDqs && !isCds) {
        for (let index = 0; index < wevo?.pages?.length; index++) {
          const expectationsGraphSlide = presentation.addSlide();
          expectationsGraphSlide.addText(presSlide.title, titleOpts);

          const page = wevo?.pages?.[index];
          const expectationsGraphElement = document.getElementById(`expectationsGraph-${page.id}`);
          const pageDiagnosticsQuotes = expectationsQuotes[page.id];

          if (expectationsGraphElement) {
            const canvas = await html2canvas(expectationsGraphElement);
            const expectationsGraphPath = canvas.toDataURL('image/png');
            expectationsGraphSlide.addImage({
              data: expectationsGraphPath,
              x: 1.4,
              y: 1.6,
              w: 7.0,
              h: 4,
              valign: 'top',
              align: 'center',
            });
          }

          hasExpectationsQuotes &&
            expectationsGraphSlide.addText(
              `${pageDiagnosticsQuotes?.expectationHopeQuestion} ${pageDiagnosticsQuotes?.expectationFearQuestion}`,
              {
                x: 0.45,
                y: 0.5,
                w: 9.2,
                h: 2.0,
                align: 'left',
                fontSize: 14,
              }
            );

          expectationsGraphSlide.addText(`Page name: ${page?.name}`, {
            y: 0.75,
            w: '100%',
            h: '100%',
            valign: 'top',
            margin: 40,
            isTextBox: true,
            fontSize: 13,
          });

          expectationsGraphSlide.addImage({
            path: wevoLogo,
            ...wevoLogoOpts,
          });
        }
      }
    }

    // Expectations Quotes slide
    else if (presSlide.id === 'expectationsQuotes' && hasExpectationsQuotes) {
      if (!isDqs && !isCds && !_.isEmpty(expectationsQuotes)) {
        for (let index = 0; index < wevo.pages.length; index++) {
          const page = wevo.pages[index];
          const pageExpectationQuotes = expectationsQuotes[page.id];
          const expectationsQuotesSlide = presentation.addSlide();
          expectationsQuotesSlide.addText(presSlide.title, titleOpts);

          expectationsQuotesSlide.addText(`Page name: ${page?.name}`, {
            y: 0.75,
            w: '100%',
            h: '100%',
            valign: 'top',
            margin: 40,
            isTextBox: true,
            fontSize: 13,
          });

          let height = 1.7;

          for (let index = 0; index < Math.min(pageExpectationQuotes?.quotes?.length, 4); index++) {
            const expectationsQuote = pageExpectationQuotes?.quotes?.[index];
            const starredQuoteIcon = determineStarredQuoteIcon(expectationsQuote);

            expectationsQuotesSlide.addText(`"${expectationsQuote?.quote || 'Example text'}"`, {
              shape: presentation.shapes.ROUNDED_RECTANGLE,
              x: 1.0,
              y: height,
              w: 9.2,
              h: 0.8,
              rectRadius: 0.2,
              fill: { color: 'F3F8FB' },
              align: 'left',
              fontSize: 14,
              margin: 20,
            });

            expectationsQuotesSlide.addImage({
              data: starredQuoteIcon,
              x: 0.85,
              y: height + 0.26,
              w: 0.3,
              h: 0.3,
            });
            height += 0.9;
          }

          expectationsQuotesSlide.addImage({
            path: wevoLogo,
            ...wevoLogoOpts,
          });
        }
      }
    }

    // Sentiment Map slide
    else if (presSlide.id === 'sentimentMap') {
      const sentimentMapSlide = presentation.addSlide();

      sentimentMapSlide.addText(presSlide.title, titleOpts);

      sentimentMapSlide.addText(
        `Insert your sentiment map(s) here. To download a sentiment map, click    “Download”      at the top right of your screen.`,
        {
          fontSize: 17,
          breakLine: true,
          y: 1.2,
          w: '100%',
          h: '100%',
          valign: 'top',
          margin: 40,
          isTextBox: true,
        }
      );

      sentimentMapSlide.addImage({
        data: downloadImageIcon,
        x: 7.48,
        y: 1.7,
        w: 1.5,
        h: 0.35,
      });

      sentimentMapSlide.addText(
        `Pro tip: Did you know you can download your sentiment map after applying filters? Simply apply the audience filters you want to show before clicking the download image button`,
        {
          fontSize: 14,
          breakLine: true,
          y: 2.2,
          w: '100%',
          h: '100%',
          valign: 'top',
          margin: 40,
          isTextBox: true,
          italic: true,
        }
      );

      sentimentMapSlide.addImage({
        path: wevoLogo,
        ...wevoLogoOpts,
      });
    }

    // Custom Questions slides
    else if (presSlide.id === 'customQuestions') {
      const filteredGroups = groups?.filter(
        (group) =>
          group?.question?.type !== CustomQuestionTypes.Heatmap &&
          group?.question?.type !== CustomQuestionTypes.CustomQualitative
      );
      if (!_.isEmpty(filteredGroups)) {
        for (let index = 0; index < filteredGroups.length; index++) {
          const group = filteredGroups[index];
          for (let j = 0; j < group.scopes.length; j++) {
            const scope = group.scopes[j];

            const asset =
              wevo?.type === 'classic'
                ? wevo?.pages?.find((page) => String(page.id) === String(scope.wevoPageId))
                : wevo?.pages?.[0]?.steps?.find((step) => String(step.id) === String(scope.stepId));
            const name =
              wevo?.type === 'classic'
                ? asset?.name
                  ? `Page name: ${asset?.name}`
                  : ''
                : asset?.name
                ? `Step name: ${asset?.name}`
                : 'Journey-Wide Question';

            const customQuestionSlide = presentation.addSlide();
            const customQuestionGraphElement = document.getElementById(
              `customQuestionGraph-${group.groupId}-${scope.questionId}`
            );

            customQuestionSlide.addText(presSlide.title, titleOpts);

            if (customQuestionGraphElement) {
              const canvas = await html2canvas(customQuestionGraphElement);
              const customQuestionGraphPath = canvas.toDataURL('image/png');
              const questionType = group?.question?.type || '';
              customQuestionSlide.addImage({
                data: customQuestionGraphPath,
                x: questionType === CustomQuestionTypes.YesNoTrueFalse ? 2 : 1.5,
                y: 2.1,
                w: questionType === CustomQuestionTypes.YesNoTrueFalse ? 5.8 : 6.9,
                h: questionType === CustomQuestionTypes.YesNoTrueFalse ? 3 : 3.4,
                align: 'center',
              });
            }

            customQuestionSlide.addText(`${name}`, {
              y: 0.7,
              w: '100%',
              h: '100%',
              valign: 'top',
              margin: 40,
              isTextBox: true,
              fontSize: 13,
              breakLine: true,
            });

            customQuestionSlide.addText(`"${group?.question?.questionText}"`, {
              y: 1.05,
              w: '100%',
              h: '100%',
              valign: 'top',
              margin: 40,
              fontSize: 15,
              isTextBox: true,
            });

            customQuestionSlide.addImage({
              path: wevoLogo,
              ...wevoLogoOpts,
            });
          }
        }
      }
    }
  }

  return presentation;
};
