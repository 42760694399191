import { Grid, Slide, useScrollTrigger } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { get as _get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom';
import mcLogo from '../../assets/mcLogo.svg';
import { getAppBarColor } from '../../helpers';
import useWevo from '../../hooks/useWevo';
import { Paths } from '../../routes';

const AppBarContainer = (props) => {
  const { children } = props;
  const sentimentMapMatch = useRouteMatch({
    path: [Paths.reports.sentimentMap, Paths.reports.limitedSentimentMap],
    exact: true,
  });

  const scrollTriggerConfig = !sentimentMapMatch
    ? { disableHysteresis: true, threshold: 0 }
    : { threshold: 50 };

  const trigger = useScrollTrigger(scrollTriggerConfig);

  if (sentimentMapMatch) {
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {React.cloneElement(children, { elevation: 0 })}
      </Slide>
    );
  } else {
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }
};

AppBarContainer.propTypes = {
  children: PropTypes.element.isRequired,
};

const AppBarMC = (props) => {
  const reportsMatch = useRouteMatch({
    path: Paths.reports.basePath,
  });
  const reportsDashboardMatch = useRouteMatch({
    path: Paths.reports.dashboard,
    exact: true,
  });

  const { data: wevo } = useWevo(_get(reportsMatch, ['params', 'wevoId'], ''));

  const showWevoName =
    Boolean(reportsMatch) && !Boolean(reportsDashboardMatch) && Boolean(_get(wevo, 'name', ''));

  return (
    <div style={{ flexGrow: 1 }}>
      <AppBarContainer>
        <AppBar position="fixed" sx={{ backgroundColor: getAppBarColor() }}>
          <Toolbar>
            <div style={{ width: !showWevoName && '100%' }}>
              <Grid container direction="row" spacing={2} justifyContent="flex-start" alignItems="center">
                <Grid item>
                  <img src={mcLogo} alt="the MC logo" style={{ maxHeight: '20px' }} />
                </Grid>
                <Grid item>
                  <Typography variant="body2" sx={{ fontSize: '17px' }}>
                    Mastercard User Research Center
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Toolbar>
        </AppBar>
      </AppBarContainer>
    </div>
  );
};

export default withRouter(AppBarMC);
