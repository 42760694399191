import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Card, CardActions, CardContent, Grid, IconButton, Link, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import React, { useMemo } from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { ReactComponent as ExperienceMapIcon } from '../../../../assets/experience-map.svg';
import { ComponentType } from '../../../../modules/wevos/constants';
import { isClassicComparePages, isClassicSinglePage } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';
import ExperienceUsabilityMetricsSummary from '../../experience-map/components/ExperienceUsabilityMetricsSummary';
import AssetSentimentMapCard from './AssetSentimentMapCard';

const ExperienceSentimentMapSummary = ({ wevo, page, assets, selectedAssetNum }) => {
  return (
    <Grid container spacing={1} justifyContent="center" sx={{ padding: 1 }}>
      <Grid item xs={12}>
        <Box p={2}>
          <Typography variant="h5">Sentiment Map</Typography>
        </Box>
      </Grid>
      <Grid item container spacing={4} justifyContent="center">
        {assets?.map((asset, index) => {
          return (
            <Grid item key={index} sx={{ minWdith: '400px' }}>
              <AssetSentimentMapCard
                wevo={wevo}
                page={page}
                asset={asset}
                index={index}
                selectedAssetNum={selectedAssetNum}
                // N.b. Hard coding isLimitedReport is a short-term hack to hide the sentiment map link on the asset card.
                // Since an Experience Map doesn't have "pages" or "steps" in the reporting sense, and we'll
                // need something entirely different when we're showing experience step interactions, this seemed better than
                // duplicating 99% of the implementation.
                isLimitedReport={true}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
const ExperienceMapSummary = (props) => {
  const { wevo, page, selectedAssetNum, usabilityScores, isLimitedReport } = props;

  const theme = useTheme();

  const styles = {
    card: {
      borderRadius: '20px',
      height: '100%',
    },
    cardContent: {
      display: 'flex',
      height: '100%',
    },
    sentimentMapIconGrid: {
      marginRight: theme.spacing(2),
    },
    sentimentMapIcon: {
      color: theme.palette.primary.main,
      width: '25px',
      height: '25px',
      marginTop: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      marginLeft: theme.spacing(0.25),
      marginBottom: theme.spacing(-0.25),
    },
    text: {
      color: theme.palette.primary.dark,
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
    icon: {
      backgroundColor: grey[100],
      borderRadius: '50%',
      padding: '3px',
    },
    iconButton: {
      backgroundColor: grey[100],
    },
    link: {
      color: theme.palette.primary.main,
      fontSize: '12px',
      textAlign: 'end',
    },
    rightArrow: {
      marginLeft: theme.spacing(1),
      verticalAlign: 'bottom',
    },
  };

  const isClassicSinglePageWevo = isClassicSinglePage(wevo);
  const isClassicComparePagesWevo = isClassicComparePages(wevo);
  const assets = useMemo(() => {
    if (isClassicSinglePageWevo) {
      return wevo?.pages;
    } else if (isClassicComparePagesWevo) {
      return Array(wevo?.pages?.[selectedAssetNum - 1]);
    } else {
      return wevo?.pages?.[0]?.steps;
    }
  }, [wevo?.pages, isClassicSinglePageWevo, isClassicComparePagesWevo, selectedAssetNum]);

  const experienceMapPath = isClassicComparePages(wevo)
    ? generatePath(Paths.reports.experienceMap, {
        wevoId: wevo?.id,
        pageNum: selectedAssetNum,
      })
    : generatePath(Paths.reports.experienceMap, {
        wevoId: wevo?.id,
      });

  if (!Boolean(wevo?.components?.[ComponentType.Heatmap])) {
    return (
      <Card sx={styles.card} elevation={4}>
        <CardContent sx={styles.cardContent}>
          <Box m="auto">
            <Typography variant="body2">This study does not include an Experience Map</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={styles.card} elevation={4}>
      <CardContent>
        <Grid container sx={styles.noWrap}>
          <Grid item sx={styles.sentimentMapIconGrid}>
            {isLimitedReport ? (
              <Box sx={styles.icon}>
                <ExperienceMapIcon fill={theme.palette.primary.main} style={styles.sentimentMapIcon} />
              </Box>
            ) : (
              <IconButton size="small" sx={styles.iconButton}>
                <Link to={experienceMapPath} component={RouterLink}>
                  <ExperienceMapIcon fill={theme.palette.primary.main} style={styles.sentimentMapIcon} />
                </Link>
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h5">Experience Map</Typography>
            <Typography variant="caption" sx={styles.text}>
              Areas on your experience that are most and least liked
            </Typography>
          </Grid>
        </Grid>
        <Box pt={4} px={3}>
          <ExperienceUsabilityMetricsSummary usabilityScores={usabilityScores} />
        </Box>
        <Box pt={4} />
        <ExperienceSentimentMapSummary
          wevo={wevo}
          page={page}
          assets={assets}
          selectedAssetNum={selectedAssetNum}
        />
      </CardContent>
      {!isLimitedReport && (
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Box p={2}>
            <Link to={experienceMapPath} component={RouterLink} sx={{ textDecoration: 'none' }}>
              <Typography variant="body2" sx={styles.link}>
                See Experience Map
                <ChevronRightIcon fontSize="small" sx={styles.rightArrow} />
              </Typography>
            </Link>
          </Box>
        </CardActions>
      )}
    </Card>
  );
};

export default ExperienceMapSummary;
