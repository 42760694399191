import useMediaQuery from '@mui/material/useMediaQuery';
import { BarController, Chart, Legend, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { memoAreEqual } from '../../../helpers.js';
import { chunkString, convertScoresToPercentages } from '../../../modules/report/helpers.js';
import theme from '../../../theme.js';

Chart.register(BarController, Legend, Tooltip);

const MAX_LABEL_LINE_LENGTH = 10;

const LikertChart = React.memo((props) => {
  const {
    labels,
    scores,
    narrowBarsWidth,
    barsWidth,
    isDashboard,
    isPptComponent,
    width = '',
    height = '240px',
    componentId,
  } = props;

  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const percentages = convertScoresToPercentages(scores);
  const config = useMemo(
    () => ({
      type: 'bar',
      data: {
        labels: labels,
        datasets: [{ data: percentages }],
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: !isPptComponent,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            interaction: {
              mode: 'point',
            },
            enabled: true,
            displayColors: false,
            xAlign: 'center',
            yAlign: 'top',
            position: 'nearest',
            backgroundColor: 'rgba(97, 97, 97, .95)',
            padding: 10,
            caretPadding: 15,
            titleFont: {
              family: theme.typography.fontFamily,
              weight: theme.typography.fontWeightBold,
            },
            bodyFont: {
              family: theme.typography.fontFamily,
            },
            callbacks: {
              label: function (context) {
                const label = context.label;
                if (!label) {
                  return;
                }
                const score = scores[context.dataIndex];
                const currentPercentage = context.dataset['data'][context.dataIndex].toFixed(1);
                return `${label}: ${score}  (${currentPercentage}%)`;
              },
            },
          },
          datalabels: {
            color: 'white',
            formatter: function (value) {
              const currentPercentage = value.toFixed(1);
              return `${currentPercentage}%`;
            },
            font: {
              weight: 'bold',
              size: 13,
            },
            display: function (context) {
              const currentPercentage = context.dataset['data'][context.dataIndex].toFixed(1);
              return currentPercentage > 4.5;
            },
          },
        },
        datasets: {
          bar: {
            barThickness: isSmallScreen || (isDashboard && labels.length >= 7) ? narrowBarsWidth : barsWidth,
            backgroundColor: theme.palette.primary.light,
            minBarLength: 3,
          },
        },
        scales: {
          x: {
            display: true,
            grid: {
              display: false,
            },
            ticks: {
              callback: function (value, index, values) {
                const label = labels[index];
                return chunkString(label, MAX_LABEL_LINE_LENGTH);
              },
            },
          },
          y: {
            display: true,
            grid: {
              display: false,
              drawBorder: false,
            },
            ticks: {
              callback: function () {
                return ' ';
              },
            },
          },
        },
      },
    }),
    [barsWidth, isDashboard, isSmallScreen, labels, narrowBarsWidth, percentages, scores, isPptComponent]
  );

  useEffect(() => {
    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [config]);

  return <canvas id={componentId} height={height} width={width} ref={canvasRef}></canvas>;
}, memoAreEqual);

LikertChart.propTypes = {
  labels: PropTypes.array.isRequired,
  scores: PropTypes.array.isRequired,
  isDashboard: PropTypes.bool,
  isPptComponent: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  componentId: PropTypes.string,
};

export default LikertChart;
