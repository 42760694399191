import { Box, Grid, Tooltip, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import {
  BarChartBenchmarksFontSizes,
  DiagnosticOutcome,
  DiagnosticOutcomeToColor,
  DiagnosticOutcomeToLabel,
} from '../../../modules/report/constants';
import { chunkString } from '../../../modules/report/helpers.js';
import { DriversBenchmarkThresholds, DriversInfo, DriversOrdering } from '../../../modules/wevos/constants';
import theme from '../../../theme';
import BarChartWithBenchmarks from '../components/BarChartWithBenchmarks';

const styles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    background: 'white',
    padding: '2% 4% 2% 10%',
  },
  chartInfoContainer: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    maxWidth: '260px',
    minWidth: '150px',
  },
  chartInfoIcon: {
    color: grey[600],
  },
  chartTooltip: {
    maxWidth: 450,
  },
  chartTooltipContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  chartTooltipItems: {
    margin: 0,
    padding: 0,
    paddingLeft: 8,
    '& li': {
      marginTop: theme.spacing(1),
      marginRight: 0,
    },
  },
  tooltipText: {
    fontSize: '12px',
  },
  legendChartContainer: {
    paddingTop: 8,
  },
  chartKeyList: {
    paddingLeft: theme.spacing(1),
  },
  chartKey: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  chartKeyDash: {
    width: 24,
    height: 9,
    marginRight: 12,
  },
  chartKeyLabel: {
    fontSize: '11px',
    margin: 0,
  },
  legendHeader: {
    fontSize: '11px',
    fontWeight: 'bold',
    marginLeft: '8px',
    marginTop: '4px',
  },
  tooltipDescription: {
    listStyle: 'none',
    marginLeft: theme.spacing(-1.5),
  },
}));

const DriversBarGraph = (props) => {
  const { drivers, onBarSelected, width, height, tooltipPlacement } = props;
  const classes = styles(props);

  const [driverByName, setDriverByName] = useState({});

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
  let labelMaxWidth;

  if (isSmallDevice) {
    labelMaxWidth = 25;
  } else {
    labelMaxWidth = 45;
  }

  useEffect(() => {
    setDriverByName(_.keyBy(drivers, 'name'));
  }, [drivers]);

  // only render the bar chart when the data is available
  if (Object.keys(driverByName).length === 0) {
    return null;
  }

  return (
    <Box className={classes.chartContainer}>
      <Grid container spacing={3}>
        <Grid item xs={11} lg={9} style={{ marginTop: '80px' }}>
          <BarChartWithBenchmarks
            labels={DriversOrdering.map((driver) => driverByName[driver].name)}
            dataLabels={[DriversOrdering.map((driver) => Math.round(driverByName[driver]?.score ?? 0.0))]}
            datasets={[DriversOrdering.map((driver) => Math.round(driverByName[driver].score ?? 0.0))]}
            benchmarkThresholds={DriversBenchmarkThresholds}
            benchmarks={DriversOrdering.map((driver) => {
              const benchmark = driverByName[driver].benchmark;
              return benchmark ? Math.round(benchmark) : null;
            })}
            onBarSelected={onBarSelected}
            benchmarkLabel={'Industry benchmark'}
            width={width || '100%'}
            height={height || '375px'}
            tooltipLabelDescription={(dataIndex) =>
              chunkString(DriversInfo?.[DriversOrdering?.[dataIndex]]?.description || '', labelMaxWidth)
            }
            chartLabelSize={BarChartBenchmarksFontSizes.Small}
          />
        </Grid>
        <Grid item xs={12} lg={3} className={classes.chartInfoContainer}>
          <Grid container spacing={2} justifyContent="flex-end" className={classes.legendChartContainer}>
            <Grid item xs={10}>
              <div>
                <Typography gutterBottom className={classes.legendHeader}>
                  How do I read this chart?
                </Typography>
                <div>
                  <ul className={classes.chartKeyList}>
                    {Object.values(DiagnosticOutcome).map((outcome) => (
                      <li key={outcome} className={classes.chartKey}>
                        <div
                          className={classes.chartKeyDash}
                          style={{ backgroundColor: DiagnosticOutcomeToColor[outcome] }}
                        />
                        <p className={classes.chartKeyLabel}>{DiagnosticOutcomeToLabel[outcome]}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={2}>
              <Tooltip
                placement={tooltipPlacement || 'left-end'}
                classes={{
                  tooltip: classes.chartTooltip,
                }}
                title={
                  <div className={classes.chartTooltipContent}>
                    <ul className={classes.chartTooltipItems}>
                      <li className={classes.tooltipDescription}>
                        <Typography component="p" gutterBottom variant="caption">
                          WEVO’s methodology generates more authentic results by relying on panelists’
                          emotional response and eliminating the rational answer (what panelists feel they
                          should say) for all scoring questions.
                        </Typography>
                      </li>
                      <li className={classes.tooltipDescription} style={{ marginBottom: '16px' }}>
                        <Typography component="p" gutterBottom variant="caption">
                          WEVO uses well-tested techniques such as word buckets, emotion facial images, etc. to
                          generate the most authentic (vs. contrived) response.
                        </Typography>
                      </li>
                      <li>
                        Visitors select words that best reflect their experience from a series of word banks.
                      </li>
                      <li>
                        Visitors complete open-end responses after completing each word bank which provide the
                        “why” for each of the diagnostics.
                      </li>
                      <li>
                        WEVO’s proprietary method transforms word choice into a raw score which is converted to
                        a standard range with normal distribution, leveraging a historic median.
                      </li>
                      <li>All diagnostics scores are out of 40.</li>
                      <li>
                        The Y-axis on the diagnostic graph will most often display 30, as the graph size
                        depends on the range of scores in each test, but scores are always based on a scale of
                        0-40 regardless of what is displayed on the Y-axis of the graph.
                      </li>
                      <li>Responses are filterable by sentiment, demographics and custom filters.</li>
                    </ul>
                  </div>
                }>
                <InfoIcon className={classes.chartInfoIcon} />
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DriversBarGraph;
