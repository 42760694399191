export let Limits = {};

export const setIntakeConstants = (constants) => {
  Limits = constants?.Limits;
};

export const IntakeStepIds = {
  Start: 'start',
  TestGoal: 'test-goal',
  Audience: 'audience',
  TestType: 'test-type',
  Details: 'details',
  Review: 'review',
};

export const MutationKeys = {
  saveWevo: 'saveWevo',
  uploadPage: 'uploadPage',
  uploadStep: 'uploadStep',
  deletePage: 'deletePage',
  deletePrimer: 'deletePrimer',
  deleteStep: 'deleteStep',
  updatePage: 'updatePage',
  updateStep: 'updateStep',
  bulkUpdateAssets: 'bulkUpdateAssets',
  bulkUpdateAssetsV2: 'bulkUpdateAssetsV2',
  uploadPrimer: 'uploadPrimer',
  updateStepSortOrder: 'updateStepSortOrder',
  updatePageSortOrder: 'updatePageSortOrder',
};

export const CustomQuestionMutationKeys = {
  addCustomQuestion: 'addCustomQuestion',
  updateCustomQuestion: 'updateCustomQuestion',
  updateSortOrder: 'updateSortOrder',
  deleteQuestion: 'deleteQuestion',
};

export const CustomScreenerMutationKeys = {
  addCustomScreener: 'addCustomScreener',
  addCustomScreenerOption: 'addCustomScreenerOption',
  updateCustomScreener: 'updateCustomScreener',
  updateCustomScreenerQuestions: 'updateCustomScreenerQuestions',
  deleteCustomScreener: 'deleteCustomScreener',
  deleteCustomScreenerOption: 'deleteCustomScreenerOption',
};

export const SavedAudienceMutationKeys = {
  addSavedAudience: 'addSavedAudience',
  copySavedAudience: 'copySavedAudience',
  deleteSavedAudience: 'deleteSavedAudience',
};

export const TestTypes = {
  Usability: 'usability',
  Journey: 'journey',
  CompareJourneys: 'compareJourneys',
  SinglePage: 'singlePage',
  ComparePages: 'comparePages',
  Lite: 'lite',
};

export const JobToBeDone = {
  Usability: 'usability',
  Lite: 'lite',
};

export const PrimerValues = {
  Yes: 'yes',
  No: 'no',
};

export const KeyFindingValues = {
  Yes: 'yes',
  No: 'no',
};

export const QualitativeExpectationsValues = {
  Yes: 'yes',
  No: 'no',
};

export const Metrics = {
  CDS: 'conceptDesirabilityScore',
  DQS: 'designQualityScore',
  Wevo: 'standardWEVOScore',
};

export const MetricTypeToText = {
  CDS: 'Concept Desirability Score',
  DQS: 'Design Quality Score',
  Wevo: 'Standard WEVO Score',
};

export const MetricTypes = {
  MastercardCds: 'mastercard_cds',
  MastercardDqs: 'mastercard_dqs',
  Standard: 'standard',
};

export const PagesApplyTo = {
  JourneyLevel: 'journeyLevel',
  InsightPages: 'insightPages',
};

export const CustomQuestionTypes = {
  CustomQualitative: 'text',
  FiveLikertScale: 'likert5',
  SevenLikertScale: 'likert7',
  MultipleChoice: 'multiplechoice',
  YesNoTrueFalse: 'yesno',
  MultiSelect: 'multiselect',
  Heatmap: 'heatmap',
};

export const SelectOptions = {
  AgreeDisagree: 'agreeDisagree',
  LikeDislike: 'likeDislike',
  SatisfiedDissatisfied: 'satisfiedDissatisfied',
  LikelyUnlikely: 'likelyUnlikely',
  EasyDifficult: 'easyDifficult',
  YesNo: 'yesNo',
  TrueFalse: 'trueFalse',
  Custom: 'custom',
};

export const MultiSelectOptions = {
  AllThatApply: 'allThatApply',
  Exactly: 'exactly',
  AtLeast: 'atLeast',
  UpTo: 'upTo',
};

export const Scales = {
  agreeDisagree: {
    likert5: ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'],
    likert7: [
      'Strongly Disagree',
      'Disagree',
      'Somewhat Disagree',
      'Neutral',
      'Somewhat Agree',
      'Agree',
      'Strongly Agree',
    ],
  },

  likeDislike: {
    likert5: ['Strongly Dislike', 'Dislike', 'Neutral', 'Like', 'Strongly Like'],
    likert7: [
      'Strongly Dislike',
      'Dislike',
      'Somewhat Dislike',
      'Neutral',
      'Somewhat Like',
      'Like',
      'Strongly Like',
    ],
  },

  satisfiedDissatisfied: {
    likert5: ['Strongly Dissatisfied', 'Dissatisfied', 'Neutral', 'Satisfied', 'Strongly Satisfied'],
    likert7: [
      'Strongly Dissatisfied',
      'Dissatisfied',
      'Somewhat Dissatisfied',
      'Neutral',
      'Somewhat Satisfied',
      'Satisfied',
      'Strongly Satisfied',
    ],
  },

  likelyUnlikely: {
    likert5: ['Very Unlikely', 'Unlikely', 'Neutral', 'Likely', 'Very Likely'],
    likert7: [
      'Very Unlikely',
      'Unlikely',
      'Somewhat Unlikely',
      'Neutral',
      'Somewhat Likely',
      'Likely',
      'Very Likely',
    ],
  },

  easyDifficult: {
    likert5: ['Very Difficult', 'Difficult', 'Neutral', 'Easy', 'Very Easy'],
    likert7: [
      'Very Difficult',
      'Difficult',
      'Somewhat Difficult',
      'Neutral',
      'Somewhat Easy',
      'Easy',
      'Very Easy',
    ],
  },

  yesNo: ['Yes', 'No'],
  trueFalse: ['True', 'False'],
};

export const UsabilityTestInfo = [
  { info: 'N=50 Participants', included: true },
  { info: 'Usability metrics (Figma only)', included: true },
  { info: 'Click map (Figma only)', included: true },
  { info: 'Custom Questions', included: true },
  { info: '1 sentiment map', included: true },
  { info: 'Key Findings', included: false },
  { info: 'Expectations', included: false },
  { info: 'Diagnostics', included: false },
];

export const LiteTestInfo = [
  { info: 'N=50 Participants', included: true },
  { info: 'Custom Questions', included: true },
  { info: 'Diagnostics *', included: true },
  { info: 'Sentiment map', included: true },
  { info: 'Key Findings', included: false },
  { info: 'Expectations', included: false },
];

export const Components = {
  Primer: 'Primer',
};

export const IntakePrototypeImportStages = {
  Ready: 'ready',
  Importing: 'importing',
  GeneratingImages: 'generatingImages',
  GeneratingComponents: 'generatingComponents',
};

export const ExperienceEndScreenType = {
  Success: 'success',
};

export const MAX_PRIMER_COUNT = 1;

export const AssetScope = {
  Page: 'page',
  Step: 'step',
};

export const CustomScreenerTypes = {
  FiveLikertScale: 'likert5',
  SevenLikertScale: 'likert7',
  YesNoTrueFalse: 'yes_no',
  MultipleChoice: 'multiple_choice',
  MultiSelect: 'multiselect',
};

export const ScreenerSelectOptions = {
  AgreeDisagree: 'agree_disagree',
  LikeDislike: 'like_dislike',
  SatisfiedDissatisfied: 'satisfied_dissatisfied',
  LikelyUnlikely: 'likely_unlikely',
  EasyDifficult: 'easy_difficult',
  YesNo: 'yes_no',
  TrueFalse: 'true_false',
  Custom: 'custom',
};

export const OutcomeOptions = {
  Accept: 'accept',
  MaySelect: 'may_select',
  Reject: 'reject',
};
