import { useTheme } from '@emotion/react';
import { Box, Grid, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getJourneyScoreSubText, getJourneyScoreText } from '../../../modules/report/constants';
import HalfDoughnutScoreGraph from './HalfDoughnutScoreGraph';

const StandardTopLevelScoreChart = (props) => {
  const {
    score,
    isVersionedDiagnostics,
    title,
    ratingTextStyles = {},
    subTextStyles = {},
    textColor = 'black',
    width = 'initial',
  } = props;

  const theme = useTheme();
  const styles = {
    experienceGrid: {
      textAlign: 'center',
    },
    halfDoughnutGraph: {
      marginTop: theme.spacing(-3.5),
      maxWidth: '190px',
      width: width,
    },
    subText: {
      marginTop: theme.spacing(-2),
    },
  };

  const topLevelScore = useMemo(() => {
    if (isVersionedDiagnostics) {
      return Math.round(score) || 0;
    }

    return Math.floor(score * 100) || 0;
  }, [isVersionedDiagnostics, score]);

  if (!score) {
    return null;
  }

  return (
    <Grid container justifyContent="center" sx={styles.experienceGrid}>
      <Grid item xs={12}>
        {title && (
          <Typography variant="body2" color={textColor}>
            {title}
          </Typography>
        )}
        <Box sx={ratingTextStyles}>
          <Typography variant="h4" color={textColor}>
            {topLevelScore ? getJourneyScoreText(topLevelScore) : 'Still Being Calculated'}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={10} style={styles.halfDoughnutGraph}>
        <HalfDoughnutScoreGraph score={topLevelScore} textColor={textColor} />
      </Grid>
      <Grid item xs={12} sx={styles.subText}>
        <Grid container sx={subTextStyles}>
          <Grid item xs={12}>
            <Typography variant="body2" color={textColor}>
              {topLevelScore ? getJourneyScoreSubText(topLevelScore) : 'Still Calculating'}!
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

StandardTopLevelScoreChart.propTypes = {
  score: PropTypes.number,
  isVersionedDiagnostics: PropTypes.bool,
  title: PropTypes.string,
  width: PropTypes.string,
  textColor: PropTypes.string,
  ratingTextStyles: PropTypes.object,
  subTextStyles: PropTypes.object,
};

export default StandardTopLevelScoreChart;
