import axios from '../../api';
import { WevoType } from '../../modules/wevos/constants';

/**
 * Bookmarks quote into Takeaways.
 */
export async function starQuote({ wevoId, pageId, quoteType, quoteId }) {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/quotes/${quoteType}/${quoteId}/star`,
    method: 'PUT',
  });
  return data.starredQuote;
}

/**
 * Fetches heatmap image for specific page/step and returns object URL
 */
export const fetchHeatmapImage = async (wevoId, type, pageId, stepId) => {
  const url =
    type === WevoType.Journey
      ? `/api/v2/wevos/${wevoId}/steps/${stepId}/heatmap/image`
      : `/api/v2/wevos/${wevoId}/pages/${pageId}/heatmap/image`;

  const response = await axios({
    url,
    responseType: 'blob',
    method: 'GET',
  });

  const blob = new Blob([response.data]);
  const objectURL = URL.createObjectURL(blob);
  return objectURL;
};

/**
 * Fetches heatmap image for specific page/step and returns object URL
 */
export const fetchExperienceStepImage = async (wevoId, pageId, experienceId, experienceStepId) => {
  const url = `/api/v2/wevos/${wevoId}/pages/${pageId}/experiences/${experienceId}/experience-steps/${experienceStepId}/image`;

  const response = await axios({
    url,
    responseType: 'blob',
    method: 'GET',
  });

  const blob = new Blob([response.data]);
  const objectURL = URL.createObjectURL(blob);
  return objectURL;
};
