import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const LinearProgressBar = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'maxWidth' && prop !== 'barColor' && prop !== 'isCompleted',
})(({ theme, maxWidth, barColor, isCompleted }) => ({
  height: 20,
  width: '100%',
  maxWidth: `${maxWidth}px`,
  borderRadius: '20px',
  border: `1px solid ${theme.palette.grey[400]}`,
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: '20px',
    background: !isCompleted
      ? `linear-gradient(45deg, #123650 0%, #123650 33%,
        #134a53 33.1%, #134a53 33.1%, #134a53 66%,
      #123650 67%, #123650 91%)`
      : barColor,
    backgroundSize: !isCompleted ? '40px 20px' : 'initial',
    animation: !isCompleted ? 'stripes 1s linear infinite' : '',
  },
  '@keyframes stripes': {
    '0%': {
      backgroundPositionX: '0px',
    },
    '100%': {
      backgroundPositionX: '40px',
    },
  },
}));

const ProgressBar = ({ maxWidth, barColor, value = 0, isCompleted, sx }) => {
  return (
    <LinearProgressBar
      variant="determinate"
      value={value}
      maxWidth={maxWidth}
      barColor={barColor}
      isCompleted={isCompleted}
      sx={{ ...(sx ?? {}) }}
    />
  );
};

export default ProgressBar;
