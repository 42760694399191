import { Box, CircularProgress, Divider, Grid, Typography } from '@mui/material';
import { QuantDiagnostics } from '../../modules/automated-insights/constants';
import ThemeIcon from './ThemeIcon';

function RecommendedExperimentItem({
  label,
  experiment,
  selectedQuantTheme,
  setSelectedTab,
  showTheme,
  isQuantReady,
}) {
  return (
    <Grid container>
      <Grid item sx={{ maxWidth: '30px' }} pl={1} pr={1}></Grid>
      <Grid item sx={{ width: 'calc(100% - 30px)', marginBottom: '5px' }}>
        <Box mb={1.5}>
          <Typography fontWeight={500} component="span" pr={1} variant="body2">
            {label}
          </Typography>
          <Typography mb={2} fontWeight={500} component="span" variant="body2">
            {experiment?.experiment}
          </Typography>
        </Box>
        <Typography variant="body2" mb={1}>
          {experiment?.explanation}
        </Typography>
        {showTheme && QuantDiagnostics.includes(experiment?.theme) && (
          <ThemeIcon
            theme={experiment?.theme}
            selectedTheme={selectedQuantTheme}
            key={label}
            setSelectedTab={setSelectedTab}
            isClickable={isQuantReady}
          />
        )}
      </Grid>
    </Grid>
  );
}

export default function RecommendedExperiments({
  recommendedExperiments,
  selectedQuantTheme,
  setSelectedTab,
  showTheme,
  isQuantReady,
  hideReportContent,
  isSessionOwner,
}) {
  const showLoadingIndicatorForShareLink = !isSessionOwner && hideReportContent;

  return (
    <Box
      className="lightContainer"
      sx={{
        overflow: hideReportContent ? 'hidden' : 'auto',
        height: hideReportContent ? '100%' : '95%',
        paddingBottom: 6,
      }}>
      {!recommendedExperiments || showLoadingIndicatorForShareLink ? (
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Grid container direction="column">
          <Grid
            item
            className="lightContainer"
            sx={{
              flex: 1,
              paddingX: 1,
            }}>
            <Typography pl={1.5} mt={2} mb={3} fontSize={13} fontStyle="italic">
              Recomendations - Try these ideas to make your experience even better.
            </Typography>

            <Box
              sx={{
                fontSize: '14px',
                position: 'relative',
              }}>
              {recommendedExperiments.map((experiment, index) => {
                return (
                  <Box key={experiment?.experiment} my={2}>
                    <RecommendedExperimentItem
                      label={`${index + 1}.`}
                      experiment={experiment}
                      selectedQuantTheme={selectedQuantTheme}
                      setSelectedTab={setSelectedTab}
                      showTheme={showTheme}
                      isQuantReady={isQuantReady}
                    />
                    <Divider sx={{ my: 1 }} />
                  </Box>
                );
              })}
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
