import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import { isFunction } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { audienceIdToName, SEGMENT_ID_TO_SEGMENT_LABEL } from '../../../modules/wevos/constants';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '15px',
  },
  cardHeader: {
    paddingLeft: theme.spacing(3),
  },
  cardContent: {
    paddingTop: 0,
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  segmentName: {
    textTransform: 'capitalize',
  },
  tableRow: {
    '&:last-child th, &:last-child td': {
      borderBottom: 0,
    },
  },
}));

/**
 * Shows card with the audience and its quota.
 *
 * Props:
 *  audience: Audience object that includes name, segments, etc.
 *  onClick?: Handler when the card is clicked. If not set, the card will not be clickable.
 *  onDelete?: Handler when the card is deleted. If not set, the delete button will not be shown.
 */
export default function AudienceReviewCard(props) {
  const { audience, onClick, onDelete } = props;
  const audienceName = audienceIdToName(audience.id);

  const classes = useStyles();

  const handleOnDeleteClick = (event) => {
    event.stopPropagation();
    onDelete(event);
  };

  const header = (
    <CardHeader
      className={classes.cardHeader}
      action={
        onDelete && (
          <IconButton aria-label="settings" onClick={handleOnDeleteClick} size="large">
            <DeleteIcon />
          </IconButton>
        )
      }
      title={audienceName}
    />
  );

  const content = (
    <CardContent className={classes.cardContent}>
      <TableContainer>
        <Table size="small" aria-label="audience segments and quotas">
          <TableHead>
            <TableRow>
              <TableCell>Segment</TableCell>
              <TableCell align="right">Quota</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {audience.segments
              .filter((segment) => segment.percentage > 0)
              .map((segment) => (
                <TableRow key={segment.id} className={classes.tableRow}>
                  <TableCell className={classes.segmentName}>
                    {SEGMENT_ID_TO_SEGMENT_LABEL[segment.id] || segment.label}
                  </TableCell>
                  <TableCell align="right">
                    {audience && audience.isCustomizedQuota ? `${segment.percentage}%` : 'N/A'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardContent>
  );

  if (isFunction(onClick)) {
    return (
      <Card>
        <CardActionArea onClick={onClick}>
          {header}
          {content}
        </CardActionArea>
      </Card>
    );
  } else {
    return (
      <Card className={classes.card} elevation={4}>
        {header}
        {content}
      </Card>
    );
  }
}

AudienceReviewCard.propTypes = {
  audience: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
};
