import { useMutation, useQueryClient } from 'react-query';
import axios from '../api';

const restoreWevo = ({ wevoId }) => {
  return axios({
    url: `/api/v2/wevos/restore`,
    method: 'PATCH',
    data: {
      wevoId,
    },
  });
};

export default function useRestoreWevo() {
  const queryClient = useQueryClient();

  return useMutation(restoreWevo, {
    onMutate: ({ wevoId }) => {
      const previousWevos = queryClient.getQueryData('wevos');
      queryClient.setQueryData('wevos', (currWevos) => {
        return currWevos.map((wevo) =>
          String(wevo.id) === String(wevoId) ? { ...wevo, is_archived: false } : wevo
        );
      });
      return { previousWevos };
    },
    onSuccess: (res, { wevoId }) => {
      queryClient.invalidateQueries('wevos');
    },
    onError: (err, { wevoId }, { previousWevos }) => {
      queryClient.setQueryData('wevos', previousWevos);
    },
  });
}
