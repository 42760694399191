import { Box, styled } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import * as EmailValidator from 'email-validator';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { getError } from '../../modules/error/selectors';
import * as UserActions from '../../modules/user/actions';
import { getUserProfile } from '../../modules/user/selectors';
import { StyledTextField } from '../automated-insights/ui/TextField';

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const NewProfileForm = (props) => {
  const { inputLabelStyles, inputBaseRootStyles, updateButtonStyles, textStyles } = props;

  const userProfile = useSelector(getUserProfile);
  const [formData, setFormData] = useState(userProfile);

  const [isFirstNameValid, setFirstNameValid] = useState(false);
  const [isLastNameValid, setLastNameValid] = useState(false);
  const [isEmailValid, setEmailValid] = useState(false);

  const [isFormValid, setFormValid] = useState(false);

  const [isProfileChanged, setProfileChanged] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    setProfileChanged(
      formData.firstName !== userProfile.firstName ||
        formData.lastName !== userProfile.lastName ||
        formData.email !== userProfile.email ||
        formData.title !== userProfile.title
    );
  }, [formData, userProfile]);

  useEffect(() => {
    setFormData({
      firstName: userProfile.firstName,
      lastName: userProfile.lastName,
      email: userProfile.email,
      title: userProfile.title,
    });
  }, [userProfile]);

  useEffect(() => {
    setSubmitButtonDisabled(!(!!isProfileChanged && !!isFormValid));
  }, [isProfileChanged, isFormValid]);

  useEffect(() => {
    setFirstNameValid(!!formData.firstName || formData.firstName === userProfile.firstName);
  }, [formData.firstName, userProfile.firstName]);

  useEffect(() => {
    setLastNameValid(!!formData.lastName || formData.lastName === userProfile.lastName);
  }, [formData.lastName, userProfile.lastName]);

  useEffect(() => {
    setEmailValid(EmailValidator.validate(formData.email) || formData.email === userProfile.email);
  }, [formData.email, userProfile.email]);

  useEffect(() => {
    setFormValid(!!isFirstNameValid && !!isLastNameValid && !!isEmailValid);
  }, [isFirstNameValid, isLastNameValid, isEmailValid]);

  const handleFormInput = (ev) => {
    setFormData({ ...formData, [ev.target.name]: ev.target.value });
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    if (!isFormValid) {
      return false;
    } else {
      props.updateUser(formData);
    }
  };

  return (
    <Box sx={{ marginTop: 2, alignItems: 'center' }}>
      <Grid container justifyContent={'center'} rowGap={1} sx={{ marginTop: 5 }}>
        <Grid item xs={12} sm={7} sx={{ marginLeft: 1 }}>
          <Typography variant="h5" sx={{ ...textStyles }}>
            Profile
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Box px={{ sm: 6 }}>
            <StyledForm noValidate>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    id="fname"
                    name="firstName"
                    type="text"
                    autoComplete="given-name"
                    fullWidth
                    label="First Name"
                    value={formData.firstName}
                    variant="outlined"
                    inputLabelStyles={inputLabelStyles}
                    inputBaseRootStyles={inputBaseRootStyles}
                    onChange={handleFormInput}
                    error={!isFirstNameValid}
                    helperText={!isFirstNameValid && 'First name cannot be empty'}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <StyledTextField
                    id="lname"
                    name="lastName"
                    type="text"
                    autoComplete="family-name"
                    fullWidth
                    label="Last Name"
                    value={formData.lastName}
                    variant="outlined"
                    inputLabelStyles={inputLabelStyles}
                    inputBaseRootStyles={inputBaseRootStyles}
                    onChange={handleFormInput}
                    error={!isLastNameValid}
                    helperText={!isLastNameValid && 'Last name cannot be empty'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    id="title"
                    name="title"
                    type="text"
                    autoComplete="organization-title"
                    fullWidth
                    label="Title"
                    value={formData.title}
                    variant="outlined"
                    inputLabelStyles={inputLabelStyles}
                    inputBaseRootStyles={inputBaseRootStyles}
                    onChange={handleFormInput}
                  />
                </Grid>
                <Grid item xs={12}>
                  <StyledTextField
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    label="Email Address"
                    value={formData.email}
                    fullWidth
                    variant="outlined"
                    inputLabelStyles={inputLabelStyles}
                    inputBaseRootStyles={inputBaseRootStyles}
                    onChange={handleFormInput}
                    error={!isEmailValid}
                    helperText={!isEmailValid && 'Please enter a valid email address'}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                onClick={onSubmit}
                disabled={submitButtonDisabled}
                sx={{ mt: 2, ...updateButtonStyles }}>
                Update Account
              </Button>
            </StyledForm>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getError(state),
  };
};

const actionCreators = {
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, actionCreators)(NewProfileForm);
