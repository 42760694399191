import { useMutation, useQueryClient } from 'react-query';
import axios from '../api';

const deleteWevo = ({ wevoId }) => {
  return axios({
    url: `/api/v2/wevos/delete`,
    method: 'DELETE',
    data: {
      wevoId,
    },
  });
};

export default function useDeleteWevo() {
  const queryClient = useQueryClient();

  return useMutation(deleteWevo, {
    onMutate: ({ wevoId }) => {
      const previousWevos = queryClient.getQueryData('wevos');
      queryClient.setQueryData('wevos', (currWevos) => {
        return currWevos.map((wevo) =>
          String(wevo.id) === String(wevoId) ? { ...wevo, is_deleted: true } : wevo
        );
      });
      return { previousWevos };
    },
    onSuccess: (res, { wevoId }) => {
      queryClient.invalidateQueries('wevos');
    },
    onError: (err, { wevoId }, { previousWevos }) => {
      queryClient.setQueryData('wevos', previousWevos);
    },
  });
}
