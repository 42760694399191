import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  Chip,
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Tooltip,
  useTheme,
} from '@mui/material';
import { isNil as _isNil } from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { reportLeftNavWidth } from '../../../modules/left-nav/constants';
import { selectIsLeftNavOpen } from '../../../modules/left-nav/leftNavSlice';
import { Fragment, useCallback, useMemo } from 'react';
import { LeftNavLevelType } from '../../../modules/report/constants';

const conditionalPropValidator = (props, propName, componentName, controlPropName, expectedType) => {
  if (props[controlPropName]) {
    if (_isNil(props[propName])) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` supplied to' +
          ' `' +
          componentName +
          '`. `' +
          propName +
          '` is required when `' +
          controlPropName +
          '` is provided.'
      );
    }

    if (typeof props[propName] !== expectedType) {
      return new Error(
        'Invalid prop `' +
          propName +
          '` supplied to' +
          ' `' +
          componentName +
          '`. Expected type `' +
          expectedType +
          '` but received type `' +
          typeof props[propName] +
          '`.'
      );
    }
  }
};

const generateStyles = ({ theme, selected, showSubItems }) => ({
  icon: {
    width: theme.spacing(3.5),
    fill: selected ? theme.palette.primary.main : 'white',
    display: 'initial', // fixes a weird safari styling issue related to display: inline-flex
  },
  itemContainer: {
    backgroundColor: selected ? 'white' : 'inherit',
    borderTopLeftRadius: selected ? theme.spacing(2) : 0,
    borderBottomLeftRadius: selected ? theme.spacing(2) : 0,
    color: selected ? theme.palette.primary.main : 'white',
    borderInlineEnd: 8,
  },
  subItemNumberChip: {
    color: theme.palette.primary.main,
    backgroundColor: 'white',
    border: `thin solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    minWidth: '30px',
    minHeight: '30px',
  },
  selectedSubItemNumberChip: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    cursor: 'pointer',
  },
  itemButton: {
    color: selected ? theme.palette.primary.main : 'white',
    borderTopLeftRadius: theme.spacing(2),
    borderBottomLeftRadius: showSubItems && selected ? 0 : theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  expandIcon: {
    position: 'absolute',
    color: 'white',
    left: theme.spacing(5),
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  expandIconShift: {
    position: 'absolute',
    color: 'white',
    left: reportLeftNavWidth - 40,
    transition: theme.transitions.create('left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const SubItemButton = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isOpen' && prop !== 'isLastSubItem',
})(({ theme, isOpen, isLastSubItem }) => ({
  color: theme.palette.primary.main,
  transition: theme.transitions.create('padding', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(isOpen && {
    paddingLeft: theme.spacing(4),
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(isLastSubItem && {
    borderBottomLeftRadius: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }),
}));

const SubItemIcon = styled(ListItemIcon)(({ theme }) => ({
  fill: theme.palette.primary.main,
  display: 'initial', // fixes a weird safari styling issue related to display: inline-flex  },
}));

const LeftNavSubItem = ({
  label,
  subItemLabel,
  subItem,
  styles,
  handleClick,
  isLastSubItem,
  path,
  selected,
}) => {
  const isOpen = useSelector(selectIsLeftNavOpen);

  return (
    <Link style={{ textDecoration: 'none' }} to={path}>
      <Tooltip title={label} arrow placement="right">
        <SubItemButton isOpen={isOpen} isLastSubItem={isLastSubItem} onClick={() => handleClick(subItem)}>
          <ListItemIcon>
            <Chip
              label={subItemLabel}
              size="small"
              sx={{ ...styles.subItemNumberChip, ...(selected && styles.selectedSubItemNumberChip) }}
            />
          </ListItemIcon>
          <ListItemText
            primaryTypographyProps={{
              fontWeight: selected && 'bold',
              noWrap: true,
            }}
            primary={label}
          />
        </SubItemButton>
      </Tooltip>
    </Link>
  );
};

LeftNavSubItem.propTypes = {
  label: PropTypes.string.isRequired,
  subItemNum: PropTypes.number.isRequired,
  styles: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  isLastSubItem: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

const LeftNavSubItemHashLink = ({ label, subItemIcon, isLastSubItem, path, yOffset }) => {
  const isOpen = useSelector(selectIsLeftNavOpen);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + (yOffset || 0), behavior: 'smooth' });
  };

  return (
    <HashLink style={{ textDecoration: 'none' }} to={path} scroll={scrollWithOffset}>
      <Tooltip title={label} arrow placement="right">
        <SubItemButton isOpen={isOpen} isLastSubItem={isLastSubItem}>
          <SubItemIcon>{subItemIcon}</SubItemIcon>
          <ListItemText
            primaryTypographyProps={{
              noWrap: true,
            }}
            primary={label}
          />
        </SubItemButton>
      </Tooltip>
    </HashLink>
  );
};

LeftNavSubItemHashLink.propTypes = {
  label: PropTypes.string.isRequired,
  subItemIcon: PropTypes.element.isRequired,
  isLastSubItem: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  yOffset: PropTypes.number,
};

/**
 *
 * @param {Object} props
 * @param {Array} [props.subItems]
 * @param {Function} [props.generateSubItemPath]
 * @param {JSX.Element} props.icon
 * @param {string} props.label
 * @param {string} props.path
 * @param {string} props.pathNameKey
 * @param {boolean} props.selected
 * @param {number} [props.selectedSubItemNum]
 * @param {Function} [props.setSelectedSubItemNum]
 * @param {string} [props.subItemPath]
 * @returns {JSX.Element}
 */
const LeftNavItem = ({
  levels,
  generateSubItemPath,
  icon,
  label,
  path,
  selected,
  selectedSubItem,
  setSelectedSubItem,
  subItemPath,
  yOffset,
}) => {
  const isOpen = useSelector(selectIsLeftNavOpen);
  const showSubItems = levels?.length > 0;
  const theme = useTheme();
  const styles = generateStyles({ theme, selected, showSubItems });

  const handleSubItemClick = (subItem) => {
    setSelectedSubItem(subItem);
  };

  const levelKeys = useMemo(() => {
    const levelKeys = new Set();

    let items = levels;

    while (items?.length > 0) {
      const nextItem = items[0];
      items = items.slice(1, items.length);

      if (nextItem?.type === LeftNavLevelType.Level) {
        items.push(...(nextItem?.items ?? []));
      }

      levelKeys.add(nextItem?.level);
    }
    return [...levelKeys];
  }, [levels]);

  const isSelectedSubItemLevels = useCallback(
    (subItem, selectedSubItem) => {
      return levelKeys.every((levelKey) => {
        return (
          (_isNil(subItem?.[levelKey]) && _isNil(selectedSubItem?.[levelKey])) ||
          Number(subItem?.[levelKey]) === Number(selectedSubItem?.[levelKey])
        );
      });
    },
    [levelKeys]
  );

  const getFirstEntry = (level) => {
    // this function gets the first non-level item in a nested level structure
    let entry = level;

    while (entry?.type === LeftNavLevelType.Level) {
      entry = entry?.items?.[0];
    }

    return entry;
  };

  const renderLevels = (levels, selectedSubItem, selected) => {
    if (!levels || levels?.length < 1) {
      return <></>;
    }

    return levels.map((entry, index) => {
      switch (entry?.type) {
        case LeftNavLevelType.Level: {
          const levelSelected = selected && String(selectedSubItem[entry.level]) === String(entry.itemNumber);
          const firstEntry = getFirstEntry(entry);

          return (
            <Fragment key={`report-left-nav-level-${entry.id}-index-${index}`}>
              <LeftNavSubItem
                label={entry?.name || ''}
                styles={styles}
                subItemLabel={entry?.label ?? 'A'}
                subItemNum={entry.itemNumber}
                handleClick={() => handleSubItemClick(firstEntry)}
                path={generateSubItemPath(subItemPath, firstEntry)}
                isLastSubItem={index === levels.length - 1}
                selected={levelSelected}
              />
              <Box pl={isOpen ? 6 : 0}>
                <Collapse in={levelSelected} timeout={{ appear: 400, enter: 400, exit: 200 }} unmountOnExit>
                  <List component="div" disablePadding>
                    {renderLevels(entry?.items ?? [], selectedSubItem, levelSelected)}
                  </List>
                </Collapse>
              </Box>
            </Fragment>
          );
        }
        case LeftNavLevelType.Link: {
          return (
            <LeftNavSubItem
              key={`report-left-nav-link-${entry.id}-index-${index}`}
              label={entry?.name ? entry?.name : `Question #${entry.itemNumber}`}
              styles={styles}
              subItemLabel={entry.label}
              subItemNum={entry.itemNumber}
              handleClick={() => handleSubItemClick(entry)}
              path={generateSubItemPath(subItemPath, entry)}
              isLastSubItem={index === levels.length - 1}
              selected={selected && isSelectedSubItemLevels(entry, selectedSubItem)}
            />
          );
        }
        case LeftNavLevelType.HashLink: {
          return (
            <LeftNavSubItemHashLink
              key={`report-left-nav-hashlink-${entry.id}-index-${index}`}
              label={entry?.name}
              subItemId={entry.id}
              subItemIcon={entry.icon}
              path={`#${entry.id}`}
              isLastSubItem={index === levels.length - 1}
              yOffset={yOffset}
            />
          );
        }
        default:
          return <></>;
      }
    });
  };

  return (
    <div style={styles.itemContainer}>
      <Link style={{ textDecoration: 'none' }} to={path}>
        <ListItemButton sx={styles.itemButton}>
          <Tooltip arrow title={!isOpen ? label : ''} placement="right">
            <ListItemIcon sx={styles.icon}>{icon}</ListItemIcon>
          </Tooltip>
          <ListItemText primary={label} />
          {!selected && levels?.length > 0 && (
            <ChevronRightIcon
              sx={{ ...(!isOpen && styles.expandIcon), ...(isOpen && styles.expandIconShift) }}
            />
          )}
        </ListItemButton>
      </Link>
      <Collapse in={selected} timeout={{ appear: 400, enter: 400, exit: 200 }} unmountOnExit>
        {levels && renderLevels(levels, selectedSubItem, selected)}
      </Collapse>
    </div>
  );
};

LeftNavItem.propTypes = {
  levels: PropTypes.arrayOf(PropTypes.object),
  generateSubItemPath: (props, propName, componentName) => {
    return conditionalPropValidator(props, propName, componentName, 'subItems', 'function');
  },
  icon: PropTypes.element.isRequired,
  label: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  hashLinks: PropTypes.array,
  yOffset: PropTypes.number,
  selectedSubItem: (props, propName, componentName) => {
    return conditionalPropValidator(props, propName, componentName, 'subItems', 'object');
  },
  setSelectedSubItem: (props, propName, componentName) => {
    return conditionalPropValidator(props, propName, componentName, 'subItems', 'function');
  },
  subItemPath: (props, propName, componentName) => {
    return conditionalPropValidator(props, propName, componentName, 'subItems', 'string');
  },
};

export default LeftNavItem;
