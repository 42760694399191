import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import WarningIcon from '@mui/icons-material/Warning';
import { generatePath, Link } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import {
  DriversBenchmarkMaximum,
  DriversBenchmarkPoints,
  DriversOrdering,
  VersionedDiagnosticsOrdering,
} from '../../../../modules/wevos/constants';
import { Paths } from '../../../../routes';
import { TrackEvent } from '../../../analytics';
import PageLabel from './PageLabel';

export const CompareDriversTableLegend = () => {
  return (
    <Box>
      <Box pb={1}>
        <StarIcon style={{ color: 'green', fontSize: '18px', verticalAlign: 'text-top' }} />
        <Typography variant="caption" component="span">
          Significantly above benchmark
        </Typography>
      </Box>
      <Box>
        <WarningIcon style={{ color: 'red', fontSize: '18px', verticalAlign: 'text-top' }} />
        <Typography variant="caption" component="span">
          Significantly below benchmark
        </Typography>
      </Box>
    </Box>
  );
};

const ScoreCell = ({ driverScore, benchmarkScore, wevoId, pageNumber, onClick }) => {
  const diff = driverScore - benchmarkScore;

  let textComponent = <Typography component="span">{driverScore}</Typography>;
  const isSignificantlyBelowBenchmark = diff <= -DriversBenchmarkPoints;
  const isSignificantlyAboveBenchmark = diff >= DriversBenchmarkPoints;

  if (isSignificantlyBelowBenchmark) {
    textComponent = (
      <Typography component="span" style={{ color: 'red' }}>
        {driverScore}
        <WarningIcon style={{ fontSize: '18px', verticalAlign: 'text-top' }} />
      </Typography>
    );
  } else if (isSignificantlyAboveBenchmark) {
    textComponent = (
      <Typography component="span" style={{ color: 'green' }}>
        {driverScore}
        <StarIcon style={{ fontSize: '18px', verticalAlign: 'text-top' }} />
      </Typography>
    );
  }

  return (
    <Link
      to={`${generatePath(Paths.reports.diagnosticsPage, { wevoId, pageNum: pageNumber })}`}
      onClick={() => {
        if (onClick) {
          onClick({
            isSignificantlyBelowBenchmark,
            isSignificantlyAboveBenchmark,
            diff,
            driverScore,
            benchmarkScore,
          });
        }
      }}
      style={{ color: 'black', textDecoration: 'none' }}>
      {textComponent}
    </Link>
  );
};

export default function CompareDriversTable({ wevo, pages, benchmarks }) {
  const ordering = wevo?.useVersionedDiagnostics ? VersionedDiagnosticsOrdering : DriversOrdering;
  const headers = ['Page'].concat(ordering);

  const { track } = useAnalytics();

  const rows = (pages ?? [])
    .filter((page) => page.isSelected)
    .map((page) => {
      return headers.map((header) => {
        if (header === 'Page') {
          return <PageLabel color={page.color} label={page.label} />;
        } else {
          return (
            <ScoreCell
              driverScore={Math.round(page.drivers[header])}
              benchmarkScore={Math.round(benchmarks[header])}
              wevoId={wevo?.id}
              pageNumber={page.pageNumber}
              onClick={(data) => {
                const {
                  isSignificantlyAboveBenchmark,
                  isSignificantlyBelowBenchmark,
                  diff,
                  driverScore,
                  benchmarkScore,
                } = data;

                track(TrackEvent.CLICKED_COMPARE_PAGE_TABLE, {
                  wevoId: wevo?.analyticsId,
                  isSignificantlyAboveBenchmark,
                  isSignificantlyBelowBenchmark,
                  diff,
                  driverScore,
                  benchmarkScore,
                  diagnostic: header,
                  testType: wevo?.type,
                });
              }}
            />
          );
        }
      });
    });

  return (
    <TableContainer component={Paper} style={{ borderRadius: '20px' }} elevation={4}>
      <Table aria-label="compare-drivers-table">
        <TableHead>
          <TableRow>
            {headers.map((header, idx) => {
              return (
                <TableCell key={idx} style={{ textAlign: 'center' }}>
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            return (
              <TableRow key={idx}>
                {row.map((component, idx) => {
                  return (
                    <TableCell key={idx} style={{ textAlign: 'center' }}>
                      {component}
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
          <TableRow>
            {headers.map((header, idx) => {
              if (header === 'Page') {
                return (
                  <TableCell key={idx} style={{ textAlign: 'center' }}>
                    <Typography component="span">Industry Benchmark</Typography>
                  </TableCell>
                );
              } else {
                return (
                  <TableCell key={idx} style={{ textAlign: 'center' }}>
                    <Typography component="span">
                      {`${Math.round(benchmarks[header])} / ${DriversBenchmarkMaximum}`}
                    </Typography>
                  </TableCell>
                );
              }
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
}
