import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { ReactComponent as EaseIcon } from '../../../../assets/ease-icon.svg';
import { ReactComponent as SuccessRateIcon } from '../../../../assets/success-rate-icon.svg';
import { ReactComponent as TimeToCompleteIcon } from '../../../../assets/time-to-task-icon.svg';
import { secondsToMinutes } from '../../../../modules/report/helpers';

const ExperienceUsabilityMetricsSummary = ({ usabilityScores }) => {
  const theme = useTheme();

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Box py={1}>
          <Typography variant="h5">Topline Metrics</Typography>
        </Box>
      </Grid>
      <Grid item container spacing={1} xs={12} justifyContent="space-evenly" sx={{ paddingTop: 2 }}>
        <Grid item>
          <Typography variant="h4" sx={{ color: theme.palette.primary.main, textAlign: 'center' }}>
            {Math.round(usabilityScores?.successRate * 100)}%
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '12px', marginTop: '8px' }}>
            <SuccessRateIcon width="12px" style={{ marginBottom: '-11px', marginRight: '6px' }} />
            success rate
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={{ color: theme.palette.primary.main, textAlign: 'center' }}>
            {secondsToMinutes(usabilityScores?.taskTimeSeconds)}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '12px', marginTop: '8px' }}>
            <TimeToCompleteIcon width="12px" style={{ marginBottom: '-11px', marginRight: '6px' }} />
            time on task
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" sx={{ color: theme.palette.primary.main, textAlign: 'center' }}>
            {`${usabilityScores?.easeScore}/${usabilityScores?.easeScale}`}
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '12px', marginTop: '8px' }}>
            <EaseIcon width="12px" style={{ marginBottom: '-11px', marginRight: '6px' }} />
            ease (likert scale)
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

ExperienceUsabilityMetricsSummary.propTypes = {
  usabilityScores: PropTypes.object.isRequired,
};

export default ExperienceUsabilityMetricsSummary;
