import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Link,
  styled,
  Tooltip,
  tooltipClasses,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as DownloadIcon } from '../../../../src/assets/download-icon-white.svg';
import AlertIcon from '../../../assets/alert-icon.png';
import { ReactComponent as PulseInfoIcon } from '../../../assets/pulse-info-icon.svg';
import useAuthorizeFigma from '../../../hooks/useAuthorizeFigma';
import useCreditUsage, { useDownloadCreditUsageReport } from '../../../hooks/useCreditUsage';
import useFetchAuthorization from '../../../hooks/useFetchAuthorization';
import {
  canOnlyUseAutomatedInsightSessions,
  canOnlyUseWevoPro,
} from '../../../modules/automated-insights/helpers';
import { getError } from '../../../modules/error/selectors';
import * as UserActions from '../../../modules/user/actions';
import { getUserCustomizations, getUserProfile } from '../../../modules/user/selectors';
import { AuthenticExperienceOption, CreditUsageReporting } from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import { Paths } from '../../../routes';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import ContactSupportModal from '../ContactSupportModal';
import useFetchSubscriptionSeats from '../hooks/useFetchSubscriptionSeats';
import useFetchSubscriptionTiers from '../hooks/useFetchSubscriptionTiers';
import useGenerateBillingPortalLink from '../hooks/useGenerateBillingPortalLink';
import SeatsModal, { SeatManagementAction } from '../SeatsModal';
import SubscriptionPlanCard from '../SubscriptionPlanCard';
import { ExplorePlansButton } from '../ui/Button';

const ServicesTooltip = styled(({ className, maxWidth, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCFCFC',
    color: theme.palette.text.primary,
    borderRadius: '24px',
    border: '1px solid #E0E0E0',
    maxWidth: maxWidth,
  },
}));

const AlertTooltip = styled(({ className, maxWidth, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCFCFC',
    fontSize: '12px',
    color: theme.palette.text.primary,
    borderRadius: '24px',
    border: '1px solid #E0E0E0',
    height: '150px',
    minWidth: '340px',
  },
}));

const AccountSettingsForm = (props) => {
  const { fetchUserInfo, userProfile, userCustomizations, inputLabelStyles, textStyles, user } = props;

  useTrackPageLoad({ name: TrackEvent.VIEWED_ACCOUNT_SETTINGS });
  const { track } = useAnalytics();
  const history = useHistory();

  const { data: authorization } = useFetchAuthorization();
  const { mutate: authorizeFigma } = useAuthorizeFigma();
  const { mutate: downloadCreditUsageReport } = useDownloadCreditUsageReport();
  const { mutate: generateBillingPortalLink } = useGenerateBillingPortalLink();

  const { data: tiers } = useFetchSubscriptionTiers();
  const { data: seats } = useFetchSubscriptionSeats({ includeUsers: true });

  const [billingMethod, setBillingMethod] = useState(null);
  const [modalHeader, setModalHeader] = useState('');
  const [modalAction, setModalAction] = useState('');

  // Renewal dates
  const nextRenewalDate = user?.usage?.nextRenewalDate || null;
  const renewalDate = new Date(nextRenewalDate);
  const renewalMonth = renewalDate.toLocaleString('default', { month: 'long' });
  const renewalDay = renewalDate.toLocaleString('default', { day: '2-digit' });
  const renewalYear = renewalDate.toLocaleString('default', { year: 'numeric' });

  // Reset dates
  const nextUsageResetDate = user?.usage?.nextUsageResetDate || null;
  const resetDate = new Date(nextUsageResetDate);
  const resetMonth = resetDate.toLocaleString('default', { month: 'long' });
  const resetDay = resetDate.toLocaleString('default', { day: '2-digit' });

  // Expiration dates
  const expiresAt = user?.pulseSubscription?.expiresAt || null;
  const expirationDate = new Date(expiresAt);
  const expirationMonth = expirationDate.toLocaleString('default', { month: 'long' });
  const expirationDay = expirationDate.toLocaleString('default', { day: '2-digit' });
  const expirationYear = expirationDate.toLocaleString('default', { year: 'numeric' });

  const cancelledAt = user?.pulseSubscription?.cancelledAt;
  const today = new Date();

  const [showSeatsDialog, setShowSeatsDialog] = useState(false);
  const [showAddSeatsDialog, setShowAddSeatsDialog] = useState(false);
  const [showContactSupportDialog, setShowContactSupportDialog] = useState(false);

  const {
    data: creditUsage,
    isLoading,
    isError,
  } = useCreditUsage({
    enabled:
      // must do a nil check because on first render userCustomizations may be undefined, and therefore
      // the second check would be true even if creditUsageReporting is really disabled for the user.
      !_.isNil(userCustomizations) &&
      userCustomizations?.features?.creditUsageReporting !== CreditUsageReporting.Disabled,
  });

  const pulseSubscriptionTier = useMemo(() => {
    return tiers?.find((tier) => tier.type === user?.pulseSubscription?.pulseSubscriptionTier?.type);
  }, [tiers, user?.pulseSubscription]);

  const creditsRemaining = useMemo(() => {
    return creditUsage?.usage?.[0]?.creditsRemaining;
  }, [creditUsage]);

  const creditsRemainingDisplayValue = useMemo(() => {
    return _.isNil(creditsRemaining) ? '?' : Math.max(0, creditsRemaining);
  }, [creditsRemaining]);

  const showRemainingCredits = useMemo(() => {
    return !isLoading && !isError && !_.isNil(creditsRemaining);
  }, [isLoading, isError, creditsRemaining]);

  const pulseCreditsRemaining = useMemo(() => {
    return creditUsage?.pulseUsage?.pulseCreditsRemaining;
  }, [creditUsage]);

  const pulseCreditsRemainingDisplayValue = useMemo(() => {
    return _.isNil(pulseCreditsRemaining) ? '?' : Math.max(0, pulseCreditsRemaining);
  }, [pulseCreditsRemaining]);

  const showPulseRemainingCredits = useMemo(() => {
    return !isLoading && !isError && !_.isNil(pulseCreditsRemaining);
  }, [isLoading, isError, pulseCreditsRemaining]);

  const showFigmaAuthorization = userCustomizations?.authenticExperience === AuthenticExperienceOption.Enabled;

  const isPulseOnlyUser = useMemo(
    () => canOnlyUseAutomatedInsightSessions(userProfile, userCustomizations),
    [userCustomizations, userProfile]
  );

  const isWevoProOnlyUser = useMemo(
    () => canOnlyUseWevoPro(userProfile, userCustomizations),
    [userCustomizations, userProfile]
  );

  const isCreditBasedSubscription = user?.pulseSubscription?.isCreditBased;
  const isSeatBasedSubscription = user?.pulseSubscription?.isSeatBased;
  const isFreePlan = user?.pulseSubscription?.isFreePlan;
  const isFreeTrial = user?.pulseSubscription?.isFreeTrial;
  const isEnterprise = user?.pulseSubscription?.isEnterprise;
  const isSeatBasedEnterprise = isEnterprise && isSeatBasedSubscription;

  const hasSelfServiceBilling = useMemo(() => {
    if (!_.isNil(user?.pulseSubscription?.overrideRules?.hasSelfServiceBilling)) {
      return user?.pulseSubscription?.overrideRules?.hasSelfServiceBilling;
    }
    return pulseSubscriptionTier?.selfServiceBilling || false;
  }, [pulseSubscriptionTier, user?.pulseSubscription]);

  const canManagePayment = useMemo(() => {
    return hasSelfServiceBilling && !isFreeTrial && user?.isBillingAdmin !== false;
  }, [hasSelfServiceBilling, isFreeTrial, user?.isBillingAdmin]);

  // temporary hack: when we need to make a wevo plan different from a maxio one, we won't allocate seats properly
  // since we rely on the tier information to allocate the right components
  const canManageUsers =
    userCustomizations?.features?.pulseUserManagement !== 'disabled' && !isFreePlan && !isFreeTrial;

  // dumb aliases for now because these condition will likely change in the future
  const canViewUsers = canManageUsers;
  const canInviteUsers = canManageUsers && !isSeatBasedEnterprise;
  const canAddUsers = canManageUsers && !isSeatBasedEnterprise;

  // n.b. we're showing the manage user buttons for free plan and free trials, but because they'll fail the `canManageUsers` check
  // they will be shown a "contact us" modal instead of the actual user managements. This is so that we can get more touch points
  // with free users
  const userIsAuthorizedToManageUsers =
    user?.isBillingAdmin === true || isFreePlan || isFreeTrial || isSeatBasedEnterprise;

  const planPrice = useMemo(() => {
    if (user?.pulseSubscription?.overrideRules?.price) {
      return user.pulseSubscription.overrideRules.price;
    }

    return pulseSubscriptionTier?.rules?.price || 0;
  }, [pulseSubscriptionTier, user?.pulseSubscription?.overrideRules]);

  const pulseLimitPerSeat = useMemo(() => {
    return user?.usage?.seatRunLimit || 0;
  }, [user]);

  const pulseRunsBySeat = useMemo(() => {
    return user?.usage?.seatRunCount || 0;
  }, [user]);

  const pulseRunsRemainingDisplayValue = useMemo(() => {
    return Math.max(pulseLimitPerSeat - pulseRunsBySeat, 0);
  }, [pulseLimitPerSeat, pulseRunsBySeat]);

  const numSeatsForSubscription = useMemo(() => {
    // we use the current active seat count rather than what their subscription is provisioned for
    // since the active number within the current billing cycle is elastic, e.g. requesting to remove seats
    // which only takes effect at the start of the next cycle.
    return (seats ?? []).length;
  }, [seats]);

  const calculateValidUntilDate = () => {
    if (_.isNull(nextUsageResetDate) && _.isNull(expiresAt)) {
      return '';
    }

    let day = expirationDay;
    let month = expirationMonth;

    if (_.isNull(expiresAt)) {
      day = resetDay;
      month = resetMonth;
    }

    if (!user?.pulseSubscription?.isFreeTrial && !_.isNull(expiresAt) && nextUsageResetDate < expiresAt) {
      day = resetDay;
      month = resetMonth;
    }

    return `${month} ${nthNumber(day)}`;
  };

  const calculateBillingDate = () => {
    if (_.isNull(nextRenewalDate) && _.isNull(expiresAt)) {
      return '';
    }

    let day = expirationDay;
    let month = expirationMonth;
    let year = expirationYear;

    if (_.isNull(expiresAt)) {
      day = renewalDay;
      month = renewalMonth;
      year = renewalYear;
    }
    if (!user?.pulseSubscription?.isFreeTrial && !_.isNull(expiresAt) && nextRenewalDate < expiresAt) {
      day = renewalDay;
      month = renewalMonth;
      year = renewalYear;
    }

    return `${month} ${nthNumber(day)}, ${year}`;
  };

  const toggleShowSeatsDialog = (open) => {
    track(TrackEvent.PULSE_USER_CLICKED_VIEW_SEATS);
    setShowSeatsDialog(open);
  };

  const toggleShowAddSeatsDialog = (open) => {
    track(TrackEvent.PULSE_USER_CLICKED_ADD_SEATS);
    setShowAddSeatsDialog(open);
  };

  const handleManagePaymentClicked = () => {
    generateBillingPortalLink(null, {
      onSuccess: (data) => {
        window.open(data.url, '_blank').focus();
      },
      onError: (err) => {
        snackbar.error(
          err?.response?.data?.humanReadableMessage ??
            'Error generating billing portal link. Please contact us.'
        );
      },
    });
  };

  const handleInviteClick = () => {
    track(TrackEvent.PULSE_USER_CLICKED_INVITE_USER);
    setShowSeatsDialog(false);
    setShowAddSeatsDialog(true);
  };

  const handleTotalSeatsClick = () => {
    setShowSeatsDialog(true);
    setShowAddSeatsDialog(false);
  };

  const toggleShowContactSupportDialog = (open, modalHeader, modalAction) => {
    if (open) {
      setModalHeader(modalHeader);
      setModalAction(modalAction);
    }
    setShowContactSupportDialog(open);
  };

  const authorizeApp = () => {
    authorizeFigma(
      { nextUri: `${process.env.REACT_APP_BASE_URL}/profile` },
      {
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error authorizing Figma');
        },
      }
    );
  };

  const handleDownloadCreditUsageReport = () => {
    downloadCreditUsageReport(null, {
      onSuccess: () => {
        track(TrackEvent.DOWNLOADED_CREDIT_USAGE_REPORT, { userId: userProfile.id });
      },
      onError: (err) => {
        snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error downloading credit usage report.');
      },
    });
  };

  const redirectToSubscriptions = useCallback(() => {
    track(TrackEvent.PULSE_ACCOUNT_SETTINGS_CLICKED_UPGRADE);
    history.push(`${Paths.automatedInsights.basePath}/subscription`);
  }, [history, track]);

  const nthNumber = (number) => {
    if (number > 3 && number < 21) return `${number}th`;
    switch (number % 10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  };

  useEffect(() => {
    // code to run on component mount
    fetchUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // pass empty array as second argument to ensure this only executes once

  useEffect(() => {
    const billingFrequency =
      user?.pulseSubscription?.overrideRules?.subscriptionUnit ||
      pulseSubscriptionTier?.rules?.subscriptionUnit;

    setBillingMethod(billingFrequency === 'months' ? 'monthly' : 'annual');
  }, [user?.pulseSubscription, pulseSubscriptionTier]);

  const UsageLimit = () => {
    return (
      <AlertTooltip
        placement="right"
        title={
          <Box>
            <Box mb={2} sx={{ marginLeft: 1, marginTop: 2 }}>
              <Typography variant="body1" sx={{ fontSize: '13px' }}>
                <b>Usage Limit Reached!</b>
              </Typography>
            </Box>
            <Box sx={{ marginLeft: 1, marginTop: -1 }}>
              <Typography variant="body1" sx={{ fontSize: '13px' }}>
                You’ve reached your usage limit for the current plan. Upgrade to unlock additional capacity.
              </Typography>
            </Box>
            <Box sx={{ marginRight: 1, marginTop: 2, textAlign: 'end' }}>
              <ExplorePlansButton trackEventName={TrackEvent.PULSE_ACCOUNT_SETTINGS_CLICKED_UPGRADE} />
            </Box>
          </Box>
        }>
        <img
          src={AlertIcon}
          alt="selectable"
          style={{ width: '14px', height: '14px', marginRight: 10, marginTop: 1 }}
        />
      </AlertTooltip>
    );
  };

  const getPhrase = () => {
    if (expiresAt && expirationDate < today) {
      return 'expired on ';
    }

    if (user?.pulseSubscription?.isFreeTrial || (cancelledAt && expiresAt && expirationDate >= today)) {
      return 'expires on ';
    }

    return 'renews on ';
  };

  const getSubscriptionTermMessage = () => {
    if (!canManagePayment && _.isNil(nextRenewalDate)) {
      // this will typically only be enterprise customers; billing is not self-service because we don't have a maxio integration point,
      // and the terms are custom to the customer, which is why we won't have a renewal data vs. a free trial where they can't manage payment but they will have a renewal date
      return (
        <>
          Please contact us at <Link href="mailto:pulsesupport@wevo.ai">pulsesupport@wevo.ai</Link> to make
          billing changes to your account.
        </>
      );
    }

    return (
      <>
        {`Your ${isFreeTrial ? 'free trial' : billingMethod + ' subscription'}  ${getPhrase()}`}
        <b>{calculateBillingDate()}</b>
      </>
    );
  };

  if (_.isNil(user) || !user?.id) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', my: 4 }}>
        <CircularProgress color="secondary" />
      </Box>
    );
  }

  if (!props.selfServiceSubscription) {
    history.replace({
      pathname: Paths.automatedInsights.basePath,
    });
  }

  return (
    <>
      <Box sx={{ alignItems: 'center' }}>
        <Grid container rowGap={3}>
          <Grid item py={2} xs={12}>
            <Typography fontWeight="bold" sx={{ ...textStyles }}>
              Account Settings
            </Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Box py={2}>
              <Typography fontWeight="bold" variant="body2" sx={{ ...inputLabelStyles, fontSize: '13px' }}>
                Plan
              </Typography>
            </Box>
          </Grid>
          <Grid item container spacing={2} xs={12} sm={10} sx={{ width: '100%', alignItems: 'center' }}>
            <SubscriptionPlanCard
              hasSelfServiceBilling={hasSelfServiceBilling}
              user={user}
              planPrice={planPrice}
              onClick={
                cancelledAt && expirationDate >= today
                  ? () =>
                      toggleShowContactSupportDialog(true, 'Restart subscription', 'restart your subscription')
                  : redirectToSubscriptions
              }
            />
            <Grid item xs={12} sx={{ marginTop: 1.5 }}>
              <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                <Grid
                  item
                  container
                  justifyContent={'space-between'}
                  xs={12}
                  sx={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: 'transparent',
                    borderRadius: '5px',
                    ...(!(user.isBillingAdmin === false) && {
                      '&:hover': {
                        backgroundColor: 'rgba(38, 65, 81, 1)',
                      },
                      cursor: 'pointer',
                    }),
                    alignContent: 'center',
                  }}
                  onClick={() => {
                    if (userIsAuthorizedToManageUsers) {
                      toggleShowSeatsDialog(true);
                    }
                  }}>
                  <Grid item sx={{ marginBottom: 1 }}>
                    <Typography sx={{ color: 'white', fontSize: '13px' }}>Seats</Typography>
                  </Grid>
                  <Grid item sx={{ marginBottom: 1.5 }}>
                    <Grid container justifyContent={'end'}>
                      <Grid item sx={{ marginTop: -0.3 }}>
                        <Typography
                          component={'span'}
                          sx={{
                            color: 'white',
                            fontSize: '13px',
                            cursor: 'pointer',
                            marginRight: 1.5,
                          }}>
                          {numSeatsForSubscription} seats
                        </Typography>
                      </Grid>
                      {userIsAuthorizedToManageUsers && (
                        <Grid item sx={{ marginRight: 1 }}>
                          <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '0.8rem' }} />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
                {userIsAuthorizedToManageUsers && (
                  <Grid
                    item
                    container
                    justifyContent={'space-between'}
                    xs={12}
                    sx={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: 'transparent',
                      borderRadius: '5px',
                      '&:hover': {
                        backgroundColor: 'rgba(38, 65, 81, 1)',
                      },
                      cursor: 'pointer',
                      alignContent: 'center',
                    }}
                    onClick={() => toggleShowAddSeatsDialog(true)}>
                    <Grid item sx={{ marginBottom: 1.6 }}>
                      <Typography sx={{ color: 'white', fontSize: '13px' }}>Add seats</Typography>
                    </Grid>
                    <Grid item sx={{ marginBottom: 1.4, marginRight: 1 }}>
                      <Typography sx={{ color: 'white', fontSize: '13px' }}>
                        <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '0.8rem' }} />
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: -2 }}>
            <Divider style={{ background: 'rgba(210, 210, 210, 0.25)' }} />
          </Grid>
          {/* Usage section */}
          <>
            <Grid item xs={12} sm={2} sx={{ marginTop: -3 }}>
              <Box>
                <Typography
                  mr={1}
                  component="span"
                  fontWeight="bold"
                  variant="body2"
                  sx={{ ...inputLabelStyles, fontSize: '13px' }}>
                  Usage
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              container
              spacing={2}
              xs={12}
              sm={10}
              sx={{ width: '100%', alignItems: 'center', marginTop: -5 }}>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid
                    item
                    container
                    justifyContent={'space-between'}
                    xs={12}
                    sx={{
                      width: '100%',
                      backgroundColor: 'transparent',
                      borderRadius: '5px',
                    }}>
                    <Grid item>
                      <Typography component={'span'} sx={{ color: 'white', fontSize: '13px' }}>
                        Remaining balance
                      </Typography>
                      {!isPulseOnlyUser && (
                        <ServicesTooltip
                          placement="right"
                          title={
                            <Box p={1}>
                              <Box mb={2}>
                                <Typography variant="body1" sx={{ fontSize: '13px' }}>
                                  If you have pending tests, the remaining balance is subject to change.
                                </Typography>
                              </Box>
                              <Box>
                                <Typography variant="body1" sx={{ fontSize: '13px' }}>
                                  Please reach out to your Customer Success Manager if you need to clarify the
                                  exact amount.
                                </Typography>
                              </Box>
                            </Box>
                          }>
                          <IconButton
                            aria-label="services"
                            size="small"
                            sx={{ marginLeft: 0.5, width: '25px', height: '25px' }}>
                            <PulseInfoIcon />
                          </IconButton>
                        </ServicesTooltip>
                      )}
                    </Grid>
                    {isSeatBasedSubscription && (
                      <Grid item sx={{ marginRight: 4 }}>
                        <Box alignSelf="center" sx={{ display: 'inline-flex', fontSize: '13px' }}>
                          {pulseRunsBySeat >= pulseLimitPerSeat && <UsageLimit />}
                          <Typography variant="body" sx={{ ...inputLabelStyles }}>
                            <b>{pulseRunsRemainingDisplayValue}</b>
                            <span style={{ fontSize: '10px' }}>{` / ${pulseLimitPerSeat} `}</span>
                            {`Pulses (until ${calculateValidUntilDate()})`}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {isCreditBasedSubscription && (showRemainingCredits || showPulseRemainingCredits) && (
                      <>
                        {showRemainingCredits && !isPulseOnlyUser && (
                          <Grid item sx={{ textAlign: 'end', marginTop: -3 }} xs={12}>
                            <Box alignSelf="center" textAlign="right" pr={2} sx={{ fontSize: '13px' }}>
                              <Typography
                                component="span"
                                sx={{ fontSize: '13px', color: 'primary.main', marginRight: 1 }}>
                                <b>{creditsRemainingDisplayValue}</b>
                              </Typography>
                              <Typography component="span" variant="caption" sx={{ ...textStyles }}>
                                Pro Credits
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                        {showPulseRemainingCredits && !isWevoProOnlyUser && (
                          <Grid item sx={{ textAlign: 'end' }} xs={12}>
                            <Box textAlign="right" pr={2} sx={{ fontSize: '13px' }}>
                              <Typography
                                component="span"
                                sx={{ fontSize: '13px', color: 'primary.main', marginRight: 1 }}>
                                <b>{pulseCreditsRemainingDisplayValue}</b>
                              </Typography>
                              <Typography component="span" variant="caption" sx={{ ...textStyles }}>
                                Pulse Credits
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </>
                    )}
                  </Grid>
                  {showRemainingCredits && !isPulseOnlyUser && (
                    <Grid
                      item
                      container
                      justifyContent={'space-between'}
                      xs={12}
                      sx={{
                        width: '100%',
                        height: '40px',
                        backgroundColor: 'transparent',
                        borderRadius: '5px',
                        '&:hover': {
                          backgroundColor: 'rgba(38, 65, 81, 1)',
                        },
                        cursor: 'pointer',
                        alignContent: 'center',
                        marginTop: 1,
                        marginBottom: -2,
                      }}
                      onClick={handleDownloadCreditUsageReport}>
                      <Grid item sx={{ marginBottom: 2 }}>
                        <Typography
                          sx={{ color: 'white', fontSize: '13px', cursor: 'pointer', marginTop: 0.2 }}>
                          Pro Usage data
                        </Typography>
                      </Grid>
                      <Grid item sx={{ marginRight: 1 }}>
                        <Box alignSelf="center" sx={{ display: 'inline-flex', fontSize: '13px' }}>
                          <Box component="span" mr={2} sx={{ verticalAlign: 'text-bottom', marginRight: 1 }}>
                            <DownloadIcon style={{ color: 'white', marginTop: -0.2 }} />
                          </Box>
                          <Typography
                            variant="body"
                            sx={{ ...inputLabelStyles, marginRight: 1.5, marginTop: -0.2 }}>
                            Download
                          </Typography>
                          <ArrowForwardIosIcon
                            sx={{ color: 'white', fontSize: '0.8rem', cursor: 'pointer', marginTop: 0.2 }}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
          <Grid item xs={12}>
            <Divider style={{ background: 'rgba(210, 210, 210, 0.25)' }} />
          </Grid>
          {/* Billing section */}
          {!isFreePlan && (
            <>
              <Grid
                item
                container
                spacing={2}
                xs={12}
                sx={{
                  width: '100%',
                  alignItems: 'start',
                  marginTop: -3,
                  marginBottom: !canManagePayment && -1,
                }}>
                <Grid item xs={12} sm={2}>
                  <Box>
                    <Typography
                      mr={1}
                      component="span"
                      fontWeight="bold"
                      variant="body2"
                      sx={{ ...inputLabelStyles, fontSize: '13px' }}>
                      Billing
                    </Typography>
                  </Box>
                </Grid>
                <Grid item container spacing={2} xs={12} sm={10} sx={{ width: '100%', alignItems: 'center' }}>
                  <Grid item xs={12}>
                    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                      <Grid
                        item
                        container
                        justifyContent={'space-between'}
                        xs={12}
                        sx={{
                          width: '100%',
                          backgroundColor: 'transparent',
                          borderRadius: '5px',
                        }}>
                        <Grid item>
                          <Typography component={'span'} sx={{ color: 'white', fontSize: '13px' }}>
                            {getSubscriptionTermMessage()}
                          </Typography>
                        </Grid>
                      </Grid>
                      {canManagePayment && (
                        <Grid
                          item
                          container
                          justifyContent={'space-between'}
                          xs={12}
                          sx={{
                            width: '100%',
                            height: '40px',
                            backgroundColor: 'transparent',
                            borderRadius: '5px',
                            '&:hover': {
                              backgroundColor: 'rgba(38, 65, 81, 1)',
                            },
                            cursor: 'pointer',
                            alignContent: 'center',
                            marginTop: 1,
                            marginBottom: -2,
                          }}
                          onClick={() => handleManagePaymentClicked()}>
                          <Grid item sx={{ marginBottom: 2 }}>
                            <Typography sx={{ color: 'white', fontSize: '13px', cursor: 'pointer' }}>
                              Manage payment
                            </Typography>
                          </Grid>
                          <Grid item sx={{ marginBottom: 1.5, marginRight: 1 }}>
                            <Box alignSelf="center" sx={{ display: 'inline-flex', fontSize: '13px' }}>
                              <ArrowForwardIosIcon
                                sx={{ color: 'white', fontSize: '0.8rem', cursor: 'pointer' }}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider style={{ background: 'rgba(210, 210, 210, 0.25)' }} />
              </Grid>
            </>
          )}
          {/* Privacy section */}
          <>
            <Grid item xs={12} sm={2} sx={{ marginTop: -1 }}>
              <Box>
                <Typography
                  mr={1}
                  component="span"
                  fontWeight="bold"
                  variant="body2"
                  sx={{ ...inputLabelStyles, fontSize: '13px' }}>
                  Privacy
                </Typography>
              </Box>
            </Grid>
            <Grid item container spacing={2} xs={12} sm={10} sx={{ width: '100%', alignItems: 'center' }}>
              <Grid item xs={12}>
                <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                  <Grid
                    item
                    container
                    justifyContent={'space-between'}
                    xs={12}
                    sx={{
                      width: '100%',
                      height: '40px',
                      backgroundColor: 'transparent',
                      borderRadius: '5px',
                      '&:hover': {
                        backgroundColor: 'rgba(38, 65, 81, 1)',
                      },
                      cursor: 'pointer',
                      alignContent: 'center',
                    }}
                    onClick={() => {
                      window.ketch && window.ketch('showPreferences');
                    }}>
                    <Grid item sx={{ marginBottom: 2 }}>
                      <Box textAlign="right">
                        <Typography variant="body" sx={{ ...inputLabelStyles, fontSize: '13px' }}>
                          Preferences
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item sx={{ marginRight: 1 }}>
                      <Box alignSelf="center" sx={{ display: 'inline-flex', fontSize: '13px' }}>
                        <ArrowForwardIosIcon sx={{ color: 'white', fontSize: '0.8rem', cursor: 'pointer' }} />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
          {/* Authorization section */}
          {showFigmaAuthorization && !isPulseOnlyUser && (
            <>
              <Grid item xs={12} sx={{ marginTop: -2 }}>
                <Divider style={{ background: 'rgba(210, 210, 210, 0.25)' }} />
              </Grid>
              <Grid item xs={12} sm={2} sx={{ marginTop: -2.8 }}>
                <Box>
                  <Typography
                    mr={1}
                    component="span"
                    fontWeight="bold"
                    variant="body2"
                    sx={{ ...inputLabelStyles, fontSize: '13px' }}>
                    Authorization
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                container
                spacing={2}
                xs={12}
                sm={10}
                sx={{ width: '100%', alignItems: 'center', marginTop: -3.8 }}>
                <Grid item xs={12}>
                  <Grid container spacing={2} sx={{ alignItems: 'center' }}>
                    <Grid
                      item
                      container
                      justifyContent={'space-between'}
                      xs={12}
                      sx={{
                        width: '100%',
                        height: '40px',
                        backgroundColor: 'transparent',
                        borderRadius: '5px',
                        '&:hover': {
                          backgroundColor: 'rgba(38, 65, 81, 1)',
                        },
                        cursor: 'pointer',
                        alignContent: 'center',
                      }}
                      onClick={authorizeApp}>
                      <Grid item sx={{ marginTop: -0.5 }}>
                        <Typography sx={{ color: 'white', fontSize: '13px', cursor: 'pointer' }}>
                          Figma
                        </Typography>
                      </Grid>
                      <Grid item sx={{ marginRight: 1, marginTop: -1 }}>
                        <Box
                          alignSelf="center"
                          sx={{ display: 'inline-flex', fontSize: '13px', marginTop: 0.3 }}>
                          <Box component="span" mr={2} sx={{ verticalAlign: 'text-bottom', marginRight: 1 }}>
                            {!_.isNil(authorization) && (
                              <HowToRegIcon
                                color="primary"
                                sx={{ fontSize: '16px', verticalAlign: 'middle' }}
                              />
                            )}
                          </Box>
                          <Typography
                            variant="body"
                            component={'span'}
                            sx={{ ...inputLabelStyles, marginRight: 1.5, marginBottom: 1.2 }}>
                            {!_.isNil(authorization) ? 'Authorized' : 'Authorize'}
                          </Typography>
                          <Typography sx={{ marginTop: -0.2 }}>
                            <ArrowForwardIosIcon
                              sx={{ color: 'white', fontSize: '0.8rem', cursor: 'pointer' }}
                            />
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item xs={12} sx={{ marginTop: -1.8 }}>
            <Divider style={{ background: 'rgba(210, 210, 210, 0.25)' }} />
          </Grid>
          {user.isBillingAdmin === true && (
            <Grid item sx={{ marginTop: -3 }}>
              <Box textAlign="right">
                <Link
                  onClick={() =>
                    toggleShowContactSupportDialog(true, 'Cancel subscription', 'cancel your subscription')
                  }
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}>
                  <Typography variant="body" sx={{ ...inputLabelStyles, fontSize: '13px', color: '#D63535' }}>
                    Cancel subscription
                  </Typography>
                </Link>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
      <SeatsModal
        open={showSeatsDialog || false}
        closeCallback={toggleShowSeatsDialog}
        handleInviteClick={handleInviteClick}
        handleTotalSeatsClick={handleTotalSeatsClick}
        subscriptionSeats={seats}
        initialAction={SeatManagementAction.ManageUsers}
        resetOnClose={true}
        subscription={user?.pulseSubscription}
        canViewUsers={canViewUsers}
        canInviteUsers={canInviteUsers}
        canAddUsers={canAddUsers}
      />
      <SeatsModal
        open={showAddSeatsDialog || false}
        closeCallback={toggleShowAddSeatsDialog}
        handleInviteClick={handleInviteClick}
        handleTotalSeatsClick={handleTotalSeatsClick}
        subscriptionSeats={seats}
        initialAction={
          canManageUsers && !isSeatBasedEnterprise
            ? SeatManagementAction.AddSeats
            : SeatManagementAction.ContactSupport
        }
        resetOnClose={true}
        subscription={user?.pulseSubscription}
        canViewUsers={canViewUsers}
        canInviteUsers={canInviteUsers}
        canAddUsers={canAddUsers}
      />
      <ContactSupportModal
        open={showContactSupportDialog}
        closeCallback={toggleShowContactSupportDialog}
        modalHeader={modalHeader}
        modalAction={modalAction}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userProfile: getUserProfile(state),
    userCustomizations: getUserCustomizations(state),
    errorMessage: getError(state),
  };
};

const actionCreators = {
  fetchUserInfo: UserActions.fetchUserInfo,
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, actionCreators)(AccountSettingsForm);
