import { useTheme } from '@emotion/react';
import { Card, CardContent } from '@mui/material';
import React, { useMemo } from 'react';
import StandardTopLevelScoreChart from '../../components/StandardTopLevelScoreChart';

const ExperienceSummary = (props) => {
  const { wevo, page, componentId, width = '100%', height = '100%' } = props;

  const theme = useTheme();
  const styles = {
    card: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: '20px',
      height: height,
      width: width,
    },
    cardContent: {
      color: 'white',
      padding: theme.spacing(3),
    },
  };

  const journeyScore = useMemo(() => {
    if (wevo?.useVersionedDiagnostics) {
      return Math.round(page?.score) || 0;
    }

    return Math.floor(page?.score * 100) || 0;
  }, [page, wevo]);

  const useNewDiagnostics = useMemo(
    () => (wevo && (wevo?.useNewDiagnostics || wevo?.useVersionedDiagnostics)) || false,
    [wevo]
  );

  if (!journeyScore) {
    return null;
  }

  return (
    <Card sx={styles.card} elevation={4} id={componentId}>
      <CardContent sx={styles.cardContent}>
        <StandardTopLevelScoreChart
          score={journeyScore}
          isVersionedDiagnostics={useNewDiagnostics}
          title="This Experience is:"
          textColor="white"
        />
      </CardContent>
    </Card>
  );
};

export default ExperienceSummary;
