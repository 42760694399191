import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, TextField } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as CopyIcon } from '../../assets/copy-icon.svg';
import { TrackEvent } from '../analytics';

const styles = {
  textField: {
    '& .MuiInputBase-root': {
      backgroundColor: 'transparent',
      borderRadius: '20px',
    },
    '& .MuiInputBase-input': {
      padding: '12px',
    },
    '& fieldset': { border: 'none' },
  },
  iconContainer: {
    '&:hover': {
      backgroundColor: '#FAFAFA',
    },
  },
};

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShareDialog = (props) => {
  const { sessionId, url, isOpen, onToggleShareDialog } = props;
  const [copySuccess, setCopySuccess] = useState(false);

  const textFieldRef = useRef(null);
  const { track } = useAnalytics();

  const copyToClipboard = () => {
    const copyText = textFieldRef.current.value;
    track(TrackEvent.COPIED_PULSE_SHARE_LINK, { sessionId, shareURL: url });
    navigator.clipboard.writeText(copyText);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const handleClose = () => {
    setCopySuccess(false);
    onToggleShareDialog(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={isOpen}
      TransitionComponent={SlideTransition}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: '20px',
          paddingTop: '16px',
          paddingBottom: '16px',
        },
      }}>
      <DialogTitle
        sx={{ fontSize: '14px', fontWeight: 600, color: (theme) => theme.palette.background.default }}>
        Share Link
        {onToggleShareDialog && (
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => onToggleShareDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#000000',
            }}>
            <CloseIcon />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{ backgroundColor: '#FAFAFA', borderRadius: '20px', marginTop: 2 }}>
          <Grid item xs>
            <TextField
              fullWidth
              inputRef={textFieldRef}
              value={url}
              InputProps={{ readOnly: true }}
              sx={styles.textField}
            />
          </Grid>
          <Grid item>
            <IconButton sx={styles.iconContainer} onClick={copyToClipboard} disableRipple size="large">
              {copySuccess ? (
                <CheckCircleOutlinedIcon style={{ color: '#4CAF50', fontSize: '18px' }} />
              ) : (
                <CopyIcon style={{ color: '#000000', height: '18px', width: '18px' }} />
              )}
            </IconButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShareDialog;
