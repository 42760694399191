import { Check } from '@mui/icons-material';
import {
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';

const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 25,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: '1px',
    border: 'none',
    //creates a dashed line
    background:
      'repeating-linear-gradient(to right, transparent, transparent 10px, rgba(0, 0, 0, 0.25) 10px, rgba(0, 0, 0, 0.25) 20px)',
  },
}));

const CircleIcon = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: '#F3F8FB',
  zIndex: 1,
  color: theme.palette.text.primary,
  fontSize: 14,
  fontWeight: 600,
  border: '1px solid rgba(0, 0, 0, 0.25)',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    border: '1px solid #264151',
  }),
  ...(ownerState.completed && {
    color: theme.palette.text.secondary,
    border: 'none',
    backgroundColor: '#65BA4D',
  }),
}));

function CircleStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <CircleIcon ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check className="StepperIcon-completedIcon" />
      ) : (
        <div className="StepperIcon-circle">{String(props.icon)}</div>
      )}
    </CircleIcon>
  );
}

CircleStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const TrialStepper = ({ step }) => {
  const steps = ['Enter email', 'Verify email', 'See your results'];

  return (
    <Stepper alternativeLabel activeStep={step} connector={<Connector />}>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={CircleStepIcon}>
            <Typography sx={{ color: '#000000', fontSize: 12, marginTop: 0 }}>{label}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

export default TrialStepper;
