import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Grid, IconButton, styled, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import { CustomQuestionTypes } from '../../../modules/intake/constants';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: grey[600],
  '&:hover': {
    color: theme.palette.primary.main,
  },
}));

const CustomChoice = (props) => {
  const { customChoice, handleCustomChoiceChange, handleCustomChoiceDelete, questionType, choicesLength } =
    props;

  return (
    <Grid container direction="row" alignItems="center" sx={{ ml: 1, columnGap: 2 }}>
      <Grid item display="flex" alignItems="center">
        <RadioButtonUncheckedIcon sx={{ fontSize: 'large', color: grey[600] }} />
      </Grid>
      <Grid item xs>
        <TextField
          fullWidth
          type="text"
          variant="outlined"
          value={customChoice}
          placeholder="Please enter a custom choice..."
          onChange={(ev) => handleCustomChoiceChange(ev.target.value)}
          name="Question"
          autoComplete="off"
        />
      </Grid>
      <Grid item>
        {[CustomQuestionTypes.MultipleChoice, CustomQuestionTypes.MultiSelect].includes(questionType) && (
          <StyledIconButton
            aria-label="delete"
            size="large"
            onClick={handleCustomChoiceDelete}
            disabled={choicesLength <= 2}>
            <IndeterminateCheckBoxIcon />
          </StyledIconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default CustomChoice;
