import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { ReactComponent as SentimentMapIcon } from '../../../assets/sentiment-map.svg';
import { WevoType } from '../../../modules/wevos/constants';
import { isClassicComparePages, isClassicSinglePage } from '../../../modules/wevos/helpers';
import { Paths } from '../../../routes';
import LeftNavItem from './LeftNavItem';

const useStyles = makeStyles((theme) => ({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
}));

const SentimentMapLeftNavButton = ({
  wevo,
  levels,
  selected,
  selectedSubItem,
  setSelectedSubItem,
  generateSubItemPath,
  isLimitedReport,
}) => {
  const classes = useStyles();
  const wevoId = wevo?.id;
  const isClassicSinglePageWevo = isClassicSinglePage(wevo);
  const isClassicComparePagesWevo = isClassicComparePages(wevo);

  const findSubItemPath = () => {
    if (wevo?.type === WevoType.Classic) {
      if (isLimitedReport) {
        return Paths.reports.limitedSentimentMapPage;
      }
      return Paths.reports.sentimentMapPage;
    } else {
      if (isLimitedReport) {
        return Paths.reports.limitedSentimentMapPageStep;
      }
      return Paths.reports.sentimentMapPageStep;
    }
  };

  if (isClassicSinglePageWevo) {
    return (
      <LeftNavItem
        icon={<SentimentMapIcon className={classes.svg} />}
        label="Sentiment Map"
        path={generatePath(isLimitedReport ? Paths.reports.limitedSentimentMap : Paths.reports.sentimentMap, {
          wevoId,
        })}
        selected={selected}
      />
    );
  }

  if (wevo?.type === WevoType.Journey || isClassicComparePagesWevo) {
    return (
      <LeftNavItem
        icon={<SentimentMapIcon className={classes.svg} />}
        label="Sentiment Map"
        path={
          wevo?.type === WevoType.Classic
            ? generatePath(
                isLimitedReport ? Paths.reports.limitedSentimentMapPage : Paths.reports.sentimentMapPage,
                {
                  wevoId,
                  pageNum: selectedSubItem.pageNumber,
                }
              )
            : generatePath(
                isLimitedReport
                  ? Paths.reports.limitedSentimentMapPageStep
                  : Paths.reports.sentimentMapPageStep,
                {
                  wevoId: wevo?.id,
                  pageNum: selectedSubItem.pageNumber,
                  stepNum: selectedSubItem.stepNumber,
                }
              )
        }
        levels={levels}
        generateSubItemPath={generateSubItemPath}
        selected={selected}
        selectedSubItem={selectedSubItem}
        setSelectedSubItem={setSelectedSubItem}
        subItemPath={findSubItemPath()}
      />
    );
  }
};

SentimentMapLeftNavButton.propTypes = {
  wevo: PropTypes.object.isRequired,
  levels: PropTypes.array,
  selected: PropTypes.bool.isRequired,
  selectedSubItem: PropTypes.object.isRequired,
  setSelectedSubItem: PropTypes.func.isRequired,
  generateSubItemPath: PropTypes.func.isRequired,
  isLimitedReport: PropTypes.bool,
};

export default SentimentMapLeftNavButton;
