import { Box, Button, styled, TextField, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { snackbar } from '../../notifications';
import { TrackEvent } from '../analytics';
import useCopySegment from './hooks/useCopySegment';
import useCreateSegment from './hooks/useCreateSegment';
import useCreateSessionSegment from './hooks/useCreateSessionSegment';
import useUpdateSegment from './hooks/useUpdateSegment';

const NameTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    marginBottom: theme.spacing(1),
    border: '1px solid rgba(217, 217, 217, 0.83)',
    backgroundColor: '#FFFFFF',
    borderRadius: '16px',
  },
  '& .MuiInputBase-input': {
    fontSize: '12px',
    padding: '8px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& fieldset': { border: 'none' },
}));

const AudienceTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: 0,
  },
  '& .MuiInputBase-input': {
    fontSize: '12px',
    padding: '8px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& fieldset': { border: 'none' },
}));

export function AudienceDetailsForm({
  segment,
  onChange,
  nameProps,
  descriptionProps,
  readOnly = false,
  disabled = false,
}) {
  return (
    <>
      <NameTextField
        fullWidth
        value={segment?.name}
        placeholder="Persona name"
        onChange={(ev) =>
          onChange({
            name: ev.target.value,
            description: segment.description,
          })
        }
        InputProps={{ readOnly: readOnly }}
        disabled={disabled}
        {...(nameProps ?? {})}
      />
      <Box
        sx={{
          height: { xs: '70%', sm: '75%' },
          minHeight: 200,
          backgroundColor: '#FFFFFF',
          borderRadius: '16px',
          border: '1px solid rgba(217, 217, 217, 0.83)',
        }}>
        <Box sx={{ height: '100%', overflow: 'auto', paddingX: 1, marginTop: 1 }}>
          <AudienceTextField
            className="lightContainer"
            value={segment?.description}
            placeholder="Persona description..."
            multiline
            minRows={8}
            onChange={(ev) =>
              onChange({
                name: segment.name,
                description: ev.target.value,
              })
            }
            sx={{
              height: '90%',
              width: '100%',
              marginTop: 1,
              overflow: 'auto',
              fontSize: '12px',
              lineHeight: '20px',
            }}
            InputProps={{ readOnly: readOnly }}
            disabled={disabled}
            {...(descriptionProps ?? {})}
          />
        </Box>
      </Box>
    </>
  );
}

const AudienceEditor = ({
  sessionId,
  selectedSegment,
  sessionSegments,
  onInsightsRegeneration,
  onSaveAudience,
  onCancel,
}) => {
  const [audienceName, setAudienceName] = useState('');
  const [audienceDescription, setAudienceDescription] = useState('');
  const [segmentId, setSegmentId] = useState('');

  const { track } = useAnalytics();

  const { mutate: updateSegment } = useUpdateSegment();
  const { mutate: copySegment } = useCopySegment();
  const { mutate: createSessionSegment } = useCreateSessionSegment();
  const { mutate: createSegment } = useCreateSegment();

  const formattedDateTime = useMemo(() => {
    const currentDateTime = new Date();

    const formattedDate = currentDateTime.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    const formattedTime = currentDateTime.toLocaleTimeString(undefined, {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    return `${formattedDate} ${formattedTime}`;
  }, []);

  useEffect(() => {
    if (selectedSegment) {
      setAudienceName(`${selectedSegment?.name} - ${formattedDateTime}`);
      setAudienceDescription(selectedSegment?.description);
      setSegmentId(selectedSegment?.id);
    }
  }, [selectedSegment, formattedDateTime]);

  const handleAudienceDetailsChanged = (segment) => {
    setAudienceName(segment.name);
    setAudienceDescription(segment.description);
  };

  const handleSaveAudienceClick = () => {
    if (!selectedSegment) {
      create();
    } else if (selectedSegment?.isLocked) {
      copyAndUpdate();
    } else {
      update();
    }
  };

  const handleRegenerateClick = () => {
    track(TrackEvent.GENERATE_PULSE_FROM_CUSTOM_AUDIENCE_NEW_AUDIENCE, { sessionId });
    if (selectedSegment?.isLocked || !selectedSegment) {
      createAndRegenerate();
    } else {
      updateAndRegenerate();
    }
  };

  const handleCancelButtonClick = () => {
    track(TrackEvent.CLICKED_PULSE_CANCEL_AUDIENCE_CUSTOMIZATION_BUTTON, {
      sessionId,
    });
    onCancel();
  };

  const create = () => {
    createSegment(
      { name: audienceName, description: audienceDescription, isGenerated: false },
      {
        onSuccess: (data) => {
          const newSegment = data;
          track(TrackEvent.SAVED_PULSE_AUDIENCE, {
            sessionId,
            segmentId: newSegment?.id,
          });
          if (onSaveAudience) {
            onSaveAudience();
          }
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving persona');
        },
      }
    );
  };

  const update = () => {
    updateSegment(
      { segmentId, changes: { name: audienceName, description: audienceDescription } },
      {
        onSuccess: () => {
          track(TrackEvent.SAVED_PULSE_AUDIENCE, {
            sessionId,
            segmentId,
          });
          if (onSaveAudience) {
            onSaveAudience();
          }
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving persona');
        },
      }
    );
  };

  const copyAndUpdate = () => {
    copySegment(
      { segmentId },
      {
        onSuccess: (data) => {
          const newSegmentId = data?.id;
          setSegmentId(newSegmentId);
          updateSegment(
            { segmentId: newSegmentId, changes: { name: audienceName, description: audienceDescription } },
            {
              onSuccess: () => {
                track(TrackEvent.SAVED_PULSE_AUDIENCE, {
                  sessionId,
                  segmentId: newSegmentId,
                });
                if (onSaveAudience) {
                  onSaveAudience();
                }
              },
              onError: (err) => {
                snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving persona');
              },
            }
          );
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving persona');
        },
      }
    );
  };

  const createAndRegenerate = () => {
    createSessionSegment(
      { sessionId, name: audienceName, description: audienceDescription, isGenerated: false },
      {
        onSuccess: (data) => {
          const newSegment = data;
          track(TrackEvent.SAVED_PULSE_AUDIENCE, {
            sessionId,
            segmentId: newSegment?.id,
          });
          onInsightsRegeneration({ segment: newSegment });
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving persona');
        },
      }
    );
  };

  const updateAndRegenerate = () => {
    updateSegment(
      { segmentId, changes: { name: audienceName, description: audienceDescription } },
      {
        onSuccess: (data) => {
          track(TrackEvent.SAVED_PULSE_AUDIENCE, {
            sessionId,
            segmentId,
          });
          const updatedSegment = data;

          onInsightsRegeneration({ segment: updatedSegment });
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving persona');
        },
      }
    );
  };

  const isUniqueName = useMemo(() => {
    return !!audienceName.trim() && sessionSegments?.every((segment) => segment?.name !== audienceName);
  }, [audienceName, sessionSegments]);

  const isNewDescription = useMemo(() => {
    return !!audienceDescription.trim() && audienceDescription !== selectedSegment?.description;
  }, [audienceDescription, selectedSegment]);

  const isValidChange = useMemo(() => {
    const notBlank = !!audienceName.trim() && !!audienceDescription.trim();
    return notBlank && isUniqueName && isNewDescription;
  }, [audienceName, audienceDescription, isUniqueName, isNewDescription]);

  const errorMessage = useMemo(() => {
    if (!audienceName.trim() || !audienceDescription.trim()) {
      return 'Fields must not be blank.';
    }
    if (selectedSegment?.isLocked && !isUniqueName) {
      return 'Please enter a unique persona name.';
    } else if (selectedSegment?.isLocked && !isNewDescription) {
      return 'Please make changes to the description.';
    }
    return '';
  }, [audienceName, audienceDescription, selectedSegment, isUniqueName, isNewDescription]);

  return (
    <>
      <Typography sx={{ fontSize: 12, fontWeight: 500, marginBottom: 1, marginLeft: 1 }}>
        Change the persona description to customize your target persona
      </Typography>
      <AudienceDetailsForm
        segment={{ name: audienceName, description: audienceDescription }}
        onChange={handleAudienceDetailsChanged}
      />
      <Typography variant="caption" color="error" sx={{ marginLeft: 1 }}>
        {errorMessage}
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'end', mt: 1, columnGap: 1 }}>
        <Button
          onClick={handleCancelButtonClick}
          sx={{
            fontSize: '12px',
            color: '#778D86',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: 'transparent',
            },
          }}>
          Cancel
        </Button>
        <Button
          disabled={(selectedSegment?.isLocked || !selectedSegment) && !isValidChange}
          onClick={handleSaveAudienceClick}
          sx={{
            backgroundColor: 'FFFFFF',
            fontSize: '12px',
            color: '#778D86',
            border: '1px solid #778D86',
            borderRadius: '20px',
            textTransform: 'none',
            '&.Mui-disabled': {
              background: '#EAEAEA',
              color: '#C0C0C0',
              border: 'none',
            },
          }}>
          Save
        </Button>
        <Button
          disabled={(selectedSegment?.isLocked || !selectedSegment) && !isValidChange}
          onClick={handleRegenerateClick}
          sx={{
            backgroundColor: '#778D86',
            fontSize: '12px',
            color: '#FFFFFF',
            borderRadius: '20px',
            textTransform: 'none',
            '&:hover': {
              backgroundColor: '#778D86',
            },
            '&.Mui-disabled': {
              background: '#EAEAEA',
              color: '#C0C0C0',
            },
          }}>
          {segmentId ? 'Save & Regenerate' : 'Save & Generate'}
        </Button>
      </Box>
    </>
  );
};

export default AudienceEditor;
