import { Box, Button, ButtonGroup, styled } from '@mui/material';
import { useMemo } from 'react';
import LockSectionIcon from '../../assets/lockSectionIcon.png';
import { ReactComponent as PulseInfoWhiteIcon } from '../../assets/pulse-info-icon-white.svg';
import {
  AutomatedInsightSessionType,
  AutomatedInsightSessionTypeToDisplayName,
  BlockedFeature,
} from '../../modules/automated-insights/constants';
import FeatureTooltip from './FeatureTooltip';
import FeaturesList from './FeaturesList';
import CustomTooltip from './ui/Tooltip';

const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  border: '1px solid rgba(217, 217, 217, 0.2)',
  borderRadius: '10px',
  '& .MuiButton-root': {
    flex: 1, // Ensure all buttons take up equal space
    whiteSpace: 'nowrap',
  },
}));

const styles = {
  button: {
    minHeight: 34,
    paddingX: 4,
    textTransform: 'none',
    border: 'none',
    '&:hover': {
      border: 'none',
    },
  },
};

const InfoIconWithFeatures = ({ type }) => {
  const getTooltipContent = (type) => {
    return (
      <Box py={1}>
        <FeaturesList sessionType={type} />
      </Box>
    );
  };

  return (
    <CustomTooltip title={getTooltipContent(type)} placement="bottom">
      <Box display="flex" alignItems="center">
        <PulseInfoWhiteIcon />
      </Box>
    </CustomTooltip>
  );
};

const SessionTypeSelection = ({ sessionType, onSelection, background, typesLocked = [], user }) => {
  const experienceLocked = useMemo(
    () => typesLocked?.includes(AutomatedInsightSessionType.Experience),
    [typesLocked]
  );

  const handleButtonClick = (type) => {
    if (typesLocked?.includes(type)) {
      return;
    }

    onSelection(type);
  };

  return (
    <StyledButtonGroup aria-label="session type buttons" disableElevation>
      <Button
        onClick={() => handleButtonClick(AutomatedInsightSessionType.Page)}
        endIcon={<InfoIconWithFeatures type={AutomatedInsightSessionType.Page} />}
        sx={{
          ...styles.button,
          borderTopLeftRadius: '9px',
          borderBottomLeftRadius: '9px',
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          fontSize: { xs: 11, sm: 12 },
          fontWeight: sessionType === AutomatedInsightSessionType.Page ? 600 : 400,
          background: (theme) =>
            sessionType === AutomatedInsightSessionType.Page
              ? background || theme.palette.gradient.main
              : 'transparent',
        }}>
        {AutomatedInsightSessionTypeToDisplayName[AutomatedInsightSessionType.Page]}
      </Button>
      <Button
        onClick={() => handleButtonClick(AutomatedInsightSessionType.Experience)}
        endIcon={
          <>
            <InfoIconWithFeatures type={AutomatedInsightSessionType.Experience} />
            {experienceLocked && (
              <FeatureTooltip
                blockedFeature={BlockedFeature.SessionTypeSelector}
                blockJourneys={experienceLocked}
                user={user}>
                <img src={LockSectionIcon} alt="lock" style={{ marginLeft: '8px', width: '11px' }} />
              </FeatureTooltip>
            )}
          </>
        }
        sx={{
          ...styles.button,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderTopRightRadius: '9px',
          borderBottomRightRadius: '9px',
          borderLeft: '1px solid rgba(217, 217, 217, 0.2)',
          fontSize: { xs: 11, sm: 12 },
          fontWeight: sessionType === AutomatedInsightSessionType.Experience ? 600 : 400,
          background: (theme) =>
            sessionType === AutomatedInsightSessionType.Experience
              ? background || theme.palette.gradient.main
              : 'transparent',
          cursor: experienceLocked ? 'not-allowed' : 'pointer',
          '&:hover': {
            border: 'none',
            borderLeft: '1px solid rgba(217, 217, 217, 0.2)',
          },
        }}>
        {AutomatedInsightSessionTypeToDisplayName[AutomatedInsightSessionType.Experience]}
      </Button>
    </StyledButtonGroup>
  );
};

export default SessionTypeSelection;
