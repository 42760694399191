import _ from 'lodash';
import styled from '@emotion/styled';
import { Box, ClickAwayListener, Grid, IconButton, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ReactComponent as SeatsCounterAddIcon } from '../../assets/seatsCounterAddIcon.svg';
import { ReactComponent as SeatsCounterSubtractIcon } from '../../assets/seatsCounterSubtractIcon.svg';

const SeatsCounterTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    !['width', 'textColor', 'inputBackgroundColor', 'inputBorderColor', 'fieldsetBorderColor'].includes(prop),
})(({ width, textColor, inputBackgroundColor, inputBorderColor, fieldsetBorderColor }) => ({
  '& .MuiInputBase-root': {
    width: width || '64px',
    height: '34px',
    fontSize: '12px',
    fontWeight: 700,
    borderRadius: '10px',
    color: textColor || 'white',
    backgroundColor: inputBackgroundColor || 'transparent',
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  '& .MuiOutlinedInput-root': {
    border: inputBorderColor ? `0.25px solid ${inputBorderColor}` : '',
    '& fieldset': {
      border: `1px solid ${fieldsetBorderColor || 'rgba(255, 255, 255, 0.12)'}`,
    },
    '&:hover fieldset': {
      border: `1px solid ${fieldsetBorderColor || 'rgba(75, 192, 200, 0.3)'}`,
    },
    '&.Mui-focused fieldset': {
      border: `1px solid ${fieldsetBorderColor || 'rgba(75, 192, 200, 0.3)'}`,
    },
    '& input': {
      cursor: 'pointer',
    },
  },
}));

const SeatsCounter = ({
  numberOfSeats,
  setNumberOfSeats,
  defaultSeatCount,
  hideTitle,
  titleStyles = {},
  textFieldStyles,
  iconColor,
}) => {
  const handleSeatsCounterClickAway = () => {
    if (numberOfSeats === '') {
      setNumberOfSeats(_.isNil(defaultSeatCount) ? 1 : defaultSeatCount);
    }
  };

  return (
    <Grid container flexWrap={'nowrap'} alignItems={'center'} columnGap={2}>
      {!hideTitle && (
        <Grid item>
          <Typography sx={{ color: 'white', fontSize: '13px', fontWeight: 'bold', ...titleStyles }}>
            Number of seats
          </Typography>
        </Grid>
      )}
      <Grid item>
        <ClickAwayListener onClickAway={handleSeatsCounterClickAway}>
          <Box
            sx={{
              display: 'inline-flex',
              cursor: 'pointer',
            }}>
            <IconButton
              onClick={() => setNumberOfSeats(numberOfSeats - 1)}
              size="small"
              sx={{ width: '27px' }}>
              <SeatsCounterSubtractIcon fill={iconColor} />
            </IconButton>
            <SeatsCounterTextField
              type="string"
              onChange={(ev) => setNumberOfSeats(ev.target.value)}
              value={numberOfSeats}
              required
              variant="outlined"
              inputProps={{ readOnly: true }}
              width={textFieldStyles?.width || '64px'}
              textColor={textFieldStyles?.textColor || 'white'}
              inputBorderColor={textFieldStyles?.inputBorderColor}
              inputBackgroundColor={textFieldStyles?.inputBackgroundColor}
            />
            <IconButton
              onClick={() => setNumberOfSeats(numberOfSeats + 1)}
              size="small"
              sx={{ width: '27px' }}>
              <SeatsCounterAddIcon fill={iconColor} />
            </IconButton>
          </Box>
        </ClickAwayListener>
      </Grid>
    </Grid>
  );
};

export default SeatsCounter;
