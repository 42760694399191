import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { get as _get, set as _set, sortBy as _sortBy } from 'lodash';
import produce from 'immer';
import { MutationKeys } from '../../../modules/intake/constants';

const updatePage = ({ wevoId, pageId, ...page }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}`,
    method: 'PUT',
    data: page,
  });
};

export default function useUpdatePage() {
  const queryClient = useQueryClient();
  return useMutation(updatePage, {
    mutationKey: MutationKeys.updatePage,
    onMutate: async ({ wevoId, pageId, ...page }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      queryClient.setQueryData(['wevoData', { wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const pages = _get(draft, 'pages', []);

          const index = pages.findIndex((p) => String(p.id) === String(pageId));
          if (index >= 0) {
            pages[index] = {
              ...pages[index],
              ...page,
            };
          }

          _set(draft, 'pages', _sortBy(pages, ['sortOrder']));
        });
      });

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
