import PropTypes from 'prop-types';
import React from 'react';
import { orderDiagnostics } from '../../../modules/wevos/constants';
import WordFilter from './WordFilter';

export const EmotionWordFilter = (props) => {
  const { words, selectedWords, onWordFilterChange } = props;
  return (
    <WordFilter
      buttonText="Attribute"
      title="Attribute Filters"
      words={words}
      selectedWords={selectedWords}
      onWordFilterChange={onWordFilterChange}
    />
  );
};

export const DiagnosticWordFilter = (props) => {
  const { wevo, words, selectedWords, onWordFilterChange } = props;

  const orderedWords = orderDiagnostics(wevo?.metricType, words);

  return (
    <WordFilter
      buttonText="Diagnostic"
      title="Diagnostic Filters"
      words={orderedWords}
      selectedWords={selectedWords}
      onWordFilterChange={onWordFilterChange}
    />
  );
};

export const SentimentFilter = (props) => {
  const { sentiments, selectedSentiments, onSentimentFilterChange } = props;
  return (
    <WordFilter
      buttonText="Sentiment"
      title="Sentiment Filters"
      words={sentiments}
      selectedWords={selectedSentiments}
      onWordFilterChange={onSentimentFilterChange}
    />
  );
};

export const RatingWordFilter = (props) => {
  const { ratings, selectedRatings, onRatingFilterChange } = props;

  return (
    <WordFilter
      buttonText="Response"
      title="Response Filters"
      words={ratings}
      selectedWords={selectedRatings}
      onWordFilterChange={onRatingFilterChange}
    />
  );
};

SentimentFilter.propTypes = {
  sentiments: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedSentiments: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSentimentFilterChange: PropTypes.func.isRequired,
};

RatingWordFilter.propTypes = {
  ratings: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedRatings: PropTypes.arrayOf(PropTypes.string).isRequired,
  onRatingFilterChange: PropTypes.func.isRequired,
};
