import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import validator from 'validator';
import { DarkTextField, GradientTextField, LightTextField } from '../automated-insights/ui/TextField';
import FeatureTooltip from './FeatureTooltip';
import { BlockedFeature } from '../../modules/automated-insights/constants';

export const TEXT_FIELD_COLOR_VARIANT = {
  Dark: 'dark',
  Gradient: 'gradient',
  Light: 'light',
};

const getVariantTextField = (colorVariant) => {
  if (colorVariant === TEXT_FIELD_COLOR_VARIANT.Light) {
    return LightTextField;
  } else if (colorVariant === TEXT_FIELD_COLOR_VARIANT.Dark) {
    return DarkTextField;
  } else return GradientTextField;
};

export default function ImportURLField({
  initialURL,
  onImportURL = null,
  isLoading = false,
  isFocused,
  onStateChange = null,
  onRemove = null,
  onFocus = null,
  helperText = null,
  showErrors = true,
  notifyErrorStates = false,
  colorVariant = TEXT_FIELD_COLOR_VARIANT.Dark,
  hasGlow,
  disableFeatures,
  freeTrialExpired,
  subscriptionExpired,
  reachedUsageLimit,
  user,
}) {
  const ref = useRef();
  const [importURL, setImportURL] = useState(initialURL ?? '');
  const [showHelperText, setShowHelperText] = useState(false);
  const [error, setError] = useState(
    initialURL ? !validator.isURL(initialURL) || validator.isEmail(initialURL) : false
  );

  const VariantTextField = getVariantTextField(colorVariant);

  const handleImportURLValueChanged = (ev) => {
    setImportURL(ev.target.value);
    const error = !validator.isURL(ev.target.value) || validator.isEmail(ev.target.value);
    ev.target.value.length === 0 ? setError(false) : setError(error);

    if (!error || notifyErrorStates) {
      // if the internal state changed, inform listeners of the new state
      // note that this input is still an "uncontrolled" input since this function is not passed to `onChange`
      onStateChange(ev.target.value);
    }
  };

  const handleInputURLKeyDown = (ev) => {
    if (error) {
      return;
    }

    if (ev.key === 'Enter' && importURL && !isLoading) {
      onImportURL && onImportURL(importURL);
    }
  };

  useEffect(() => {
    // since this is an uncontrolled component and we want to respect initialURL every time the prop changes,
    // we need to set the state and set the ref's value so it displays properly
    setImportURL(initialURL || '');

    if (ref?.current) {
      ref.current.value = initialURL || '';
    }
  }, [initialURL]);

  return (
    <Box>
      <FeatureTooltip
        blockedFeature={BlockedFeature.UrlImport}
        disableFeatures={disableFeatures}
        freeTrialExpired={freeTrialExpired}
        subscriptionExpired={subscriptionExpired}
        reachedUsageLimit={reachedUsageLimit}
        user={user}>
        <VariantTextField
          inputRef={ref}
          name="importURL"
          placeholder="URL"
          variant="outlined"
          size="medium"
          isFocused={isFocused}
          type="url"
          fullWidth
          glow={hasGlow}
          defaultValue={importURL}
          onChange={handleImportURLValueChanged}
          onKeyDown={handleInputURLKeyDown}
          onFocus={onFocus ? onFocus : () => setShowHelperText(true)}
          disabled={isLoading || disableFeatures}
          InputProps={{
            endAdornment: (
              <Box>
                <CircularProgress size={16} sx={{ display: isLoading ? 'initial' : 'none' }} />
                <IconButton
                  onClick={onRemove}
                  color="primary"
                  disableRipple
                  sx={{
                    display: !isLoading && onRemove ? 'flex' : 'none',
                    alignItems: 'center',
                    textTransform: 'none',
                    opacity: 0.6,
                    '&:hover': {
                      backgroundColor: 'transparent',
                      opacity: 0.8,
                    },
                    '&:focus': {
                      opacity: 0.8,
                    },
                  }}>
                  <CancelRoundedIcon style={{ height: '20px', width: '20px' }} />
                </IconButton>
              </Box>
            ),
          }}
          sx={{ input: { cursor: disableFeatures && 'not-allowed' } }}
        />
      </FeatureTooltip>
      {!error && helperText && showHelperText && importURL && (
        <Box textAlign="right" my={1}>
          <Typography variant="caption" color="primary">
            {helperText}
          </Typography>
        </Box>
      )}
      {error && showErrors && (
        <Box textAlign="right" my={1}>
          <Typography variant="caption" color="error">
            Please input a valid url
          </Typography>
        </Box>
      )}
    </Box>
  );
}
