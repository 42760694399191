import DesktopWindowsIcon from '@mui/icons-material/DesktopWindowsOutlined';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useRouteMatch } from 'react-router-dom';
import useUserKeyFindings from '../../../components/reports/hooks/useUserKeyFindings';
import { MetricTypes } from '../../../modules/intake/constants';
import {
  CONCEPT_SCORE_BAR_CHART_COLORS,
  CONCEPT_SCORE_COLORS,
  CONCEPT_SCORE_THEME_NAMES,
  CONCEPT_SCORE_TOP_LEVEL_NAME,
  EXPERIENCE_SCORE_BAR_CHART_COLORS,
  EXPERIENCE_SCORE_COLORS,
  EXPERIENCE_SCORE_THEME_NAMES,
  EXPERIENCE_SCORE_TOP_LEVEL_NAME,
  MIN_CDS_COMPREHENSION_SCORE,
} from '../../../modules/report/constants';
import { getUserCustomizations } from '../../../modules/user/selectors';
import { DEVICE_NAME_TO_DEVICE_ID, UsabilityOption } from '../../../modules/wevos/constants';
import { Paths } from '../../../routes';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import useFetchCustomQuestions from '../../intake/hooks/useFetchCustomQuestions';
import CompositeMetricsChart from '../components/CompositeMetricsChart';
import { RightDrawerButton } from '../components/RightDrawer';
import ShareButton from '../components/ShareButton';
import useCompositeScores from '../hooks/useCompositeScores';
import useDiagnostics from '../hooks/useDiagnostics';
import useFetchUsabilityScores from '../hooks/useFetchUsabilityScores';
import useKeyFindings from '../hooks/useKeyFindings';
import CustomQuestionCard from './components/CustomQuestionCard';
import DiagnosticsSummaryDashboard from './components/DiagnosticsSummaryDashboard';
import ExpectationsSummary from './components/ExpectationsSummary';
import ExperienceMapSummary from './components/ExperienceMapSummary';
import ExperienceSummary from './components/ExperienceSummary';
import GoalCard from './components/GoalCard';
import NumRespondentsCard from './components/NumRespondentsCard';
import SentimentMapSummary from './components/SentimentMapSummary';
import StarredQuotesCard from './components/StarredQuotesCard';
import TopLevelScoreCard from './components/TopLevelScoreCard';
import UserKeyFindings from './components/UserKeyFindings';
import WevoKeyFindings from './components/WevoKeyFindings';
import { hasUserKeyFindings } from '../../../modules/wevos/helpers';

const styles = {
  topGrid: {
    marginTop: (theme) => theme.spacing(3),
  },
  title: {
    textTransform: 'capitalize',
    alignItems: 'bottom',
  },
  deviceIcon: {
    marginRight: (theme) => theme.spacing(2),
  },
  tileGrid: {
    minHeight: '280px',
  },
  experienceSummaryGrid: {
    maxWidth: '550px',
    minWidth: '350px',
  },
  titleGrid: {
    verticalAlign: 'text-bottom',
  },
};

const DeviceIdIcon = ({ deviceId }) => {
  if (deviceId === DEVICE_NAME_TO_DEVICE_ID.desktop) {
    return <DesktopWindowsIcon />;
  }
  if (deviceId === DEVICE_NAME_TO_DEVICE_ID.mobile) {
    return <SmartphoneIcon />;
  }
  return null;
};

const NewDashboard = (props) => {
  const { wevo, page, rightDrawerOpen, setRightDrawerOpen, handleShareButtonClick, isLimitedReport } = props;
  const isDQS = wevo?.metricType === MetricTypes.MastercardDqs;
  const isCDS = wevo?.metricType === MetricTypes.MastercardCds;
  const userCustomizations = useSelector(getUserCustomizations);
  const hasUsability = userCustomizations?.usabilityTestType !== UsabilityOption.Disabled;
  const isUsabilityTestType = wevo?.isUsabilityTestType;

  const keyFindingsQueryResult = useKeyFindings({
    wevoId: wevo?.id,
    pageId: page?.id,
  });

  const keyFindings = useMemo(() => {
    return keyFindingsQueryResult?.data ?? [];
  }, [keyFindingsQueryResult]);

  const { data: userKeyFindings = {} } = useUserKeyFindings({
    wevoId: wevo?.id,
  });

  const wevoHasKeyFindings = keyFindings?.[0]?.summary;
  const wevoHasUserKeyFindings = hasUserKeyFindings(userKeyFindings);

  const isThinUserKeyFindings = wevoHasKeyFindings && !wevoHasUserKeyFindings;

  const dashboardPageMatch = useRouteMatch({
    path: [Paths.reports.dashboardPage],
    exact: true,
  });
  const selectedAssetNum = Number(dashboardPageMatch?.params?.pageNum) || 1;

  const { data } = useDiagnostics({
    wevoId: wevo.id,
    pageId: page.id,
  });

  const compositeMetricScores = useCompositeScores({
    wevoId: wevo.id,
    pageId: page.id,
  });

  const diagnostics = useMemo(() => data?.diagnostics ?? [], [data]);

  const compositeScores = useMemo(
    () => compositeMetricScores?.data?.compositeMetrics ?? [],
    [compositeMetricScores?.data]
  );

  const compositeScoresByName = useMemo(
    () =>
      compositeScores.reduce((acc, cur) => {
        acc[cur.name] = cur;
        return acc;
      }, {}),
    [compositeScores]
  );

  const { data: usabilityScores } = useFetchUsabilityScores({ wevoId: wevo.id, pageId: page.id });

  useTrackPageLoad({
    name: `${
      isLimitedReport ? TrackEvent.VIEWED_LIMITED_REPORT_DASHBOARD : TrackEvent.VIEWED_REPORT_DASHBOARD
    }`,
    properties: { wevoId: wevo?.analyticsId, pageId: page?.id, limitedReport: isLimitedReport },
  });

  const deviceId = useMemo(() => wevo?.devices?.[0], [wevo?.devices]);

  const { data: groups, isLoading } = useFetchCustomQuestions(wevo?.id);
  if (isLoading) {
    return <CircularProgress />;
  }

  const cdsComprehensionScore = compositeScoresByName['Comprehension']?.score;
  const isLowCds = isCDS && cdsComprehensionScore && cdsComprehensionScore < MIN_CDS_COMPREHENSION_SCORE;

  return (
    <Fragment>
      <Box mr={5}>
        {!isLimitedReport && (
          <Box mb={2} display="flex" justifyContent="flex-end">
            <ShareButton handleShareButtonClick={handleShareButtonClick} />
          </Box>
        )}
        <Grid container justifyContent="center" sx={styles.titleGrid} spacing={2}>
          <Grid item>
            <Typography sx={styles.deviceIcon} gutterBottom>
              {Boolean(deviceId) && <DeviceIdIcon deviceId={deviceId} />}
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.title} variant="h3" gutterBottom>
              {wevo.name}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent="center" sx={styles.topGrid}>
          <Grid
            item
            xs={5}
            sx={!isLowCds ? styles.experienceSummaryGrid : { maxWidth: '550px', minWidth: '500px' }}>
            {isDQS && compositeScoresByName[EXPERIENCE_SCORE_TOP_LEVEL_NAME]?.score && (
              <TopLevelScoreCard
                header="Design Quality Score"
                topLevelScore={compositeScoresByName[EXPERIENCE_SCORE_TOP_LEVEL_NAME]?.score}
                metricScores={EXPERIENCE_SCORE_THEME_NAMES.map((name) => compositeScoresByName[name]?.score)}
                compositeMetricsThemeNames={EXPERIENCE_SCORE_THEME_NAMES}
                compositeMetricsColors={EXPERIENCE_SCORE_COLORS}
                isDashboard={true}
              />
            )}
            {isCDS && compositeScoresByName[CONCEPT_SCORE_TOP_LEVEL_NAME]?.score && (
              <TopLevelScoreCard
                header="Concept Desirability Score"
                topLevelScore={compositeScoresByName[CONCEPT_SCORE_TOP_LEVEL_NAME]?.score}
                metricScores={CONCEPT_SCORE_THEME_NAMES.map((name) => compositeScoresByName[name]?.score)}
                compositeMetricsThemeNames={CONCEPT_SCORE_THEME_NAMES}
                compositeMetricsColors={CONCEPT_SCORE_COLORS}
                isDashboard={true}
                isLowCds={isLowCds}
              />
            )}
            {!isDQS && !isCDS && !wevo.isUsabilityTestType && <ExperienceSummary wevo={wevo} page={page} />}
          </Grid>
        </Grid>
        <Box pt={2} />
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} lg={9}>
            <GoalCard wevo={wevo} isLimitedReport={isLimitedReport} />
          </Grid>
          <Grid item xs={12} lg={3}>
            <NumRespondentsCard wevo={wevo} page={page} isLimitedReport={isLimitedReport} />
          </Grid>
        </Grid>
        <Box pt={2} />
        {isDQS && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CompositeMetricsChart
                  wevo={wevo}
                  cardTitle="Experience Score"
                  compositeMetrics={EXPERIENCE_SCORE_THEME_NAMES.map((name) => compositeScoresByName[name])}
                  compositeMetricsThemeNames={EXPERIENCE_SCORE_THEME_NAMES}
                  compositeMetricsColors={EXPERIENCE_SCORE_COLORS}
                  backgroundColor={EXPERIENCE_SCORE_BAR_CHART_COLORS.backgroundColor}
                  hoverBackgroundColor={EXPERIENCE_SCORE_BAR_CHART_COLORS.hoverBackgroundColor}
                  diagnostics={diagnostics}
                  path={generatePath(Paths.reports.experienceScore, { wevoId: wevo?.id })}
                  isDashboard={true}
                  isLimitedReport={isLimitedReport}
                  isLowCds={isLowCds}
                />
              </Grid>
            </Grid>
            <Box pt={2} />
          </>
        )}
        {isCDS && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <CompositeMetricsChart
                  wevo={wevo}
                  cardTitle="Concept Score"
                  compositeMetrics={CONCEPT_SCORE_THEME_NAMES.map((name) => compositeScoresByName[name])}
                  compositeMetricsThemeNames={CONCEPT_SCORE_THEME_NAMES}
                  compositeMetricsColors={CONCEPT_SCORE_COLORS}
                  backgroundColor={CONCEPT_SCORE_BAR_CHART_COLORS.backgroundColor}
                  hoverBackgroundColor={CONCEPT_SCORE_BAR_CHART_COLORS.hoverBackgroundColor}
                  diagnostics={diagnostics}
                  path={generatePath(Paths.reports.conceptScore, { wevoId: wevo?.id })}
                  isDashboard={true}
                  isLimitedReport={isLimitedReport}
                  isLowCds={isLowCds}
                />
              </Grid>
            </Grid>
            <Box pt={2} />
          </>
        )}
        <Grid container spacing={2} justifyContent="flex-start">
          <Grid item xs={12} lg={isThinUserKeyFindings ? 9 : 6} sx={{ minHeight: '280px' }}>
            <WevoKeyFindings
              wevo={wevo}
              page={page}
              selectedAssetNum={selectedAssetNum}
              isLimitedReport={isLimitedReport}
            />
          </Grid>
          <Grid item xs={12} lg={isThinUserKeyFindings ? 3 : 6} sx={{ minHeight: '280px' }}>
            <UserKeyFindings
              wevo={wevo}
              page={page}
              hasUserKeyFindings={hasUserKeyFindings}
              userKeyFindings={userKeyFindings}
              selectedAssetNum={selectedAssetNum}
              isLimitedReport={isLimitedReport}
            />
          </Grid>
        </Grid>
        <Box pt={2} />
        {!isDQS && !isCDS && (!hasUsability || (hasUsability && !isUsabilityTestType)) && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} sx={styles.tileGrid}>
                <DiagnosticsSummaryDashboard
                  wevo={wevo}
                  page={page}
                  isDashboard={true}
                  selectedAssetNum={selectedAssetNum}
                  isLimitedReport={isLimitedReport}
                />
              </Grid>
              <Grid item xs={12} lg={6} sx={styles.tileGrid}>
                <ExpectationsSummary
                  wevo={wevo}
                  page={page}
                  selectedAssetNum={selectedAssetNum}
                  isLimitedReport={isLimitedReport}
                />
              </Grid>
            </Grid>
            <Box pt={2} />
          </>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} sx={styles.tileGrid}>
            {wevo?.hasExperience && usabilityScores && (
              <ExperienceMapSummary
                wevo={wevo}
                page={page}
                usabilityScores={usabilityScores}
                selectedAssetNum={selectedAssetNum}
                isLimitedReport={isLimitedReport}
              />
            )}
            {!(wevo?.hasExperience && usabilityScores) && (
              <SentimentMapSummary
                wevo={wevo}
                page={page}
                selectedAssetNum={selectedAssetNum}
                isLimitedReport={isLimitedReport}
              />
            )}
          </Grid>
        </Grid>
        <Box pt={2} />
        <Grid container spacing={2}>
          {groups?.map(
            (group, index) =>
              group.scopes.some((scope) => scope.wevoPageId === String(page.id)) && (
                <Grid item xs={12} lg={6} sx={styles.tileGrid} key={index}>
                  <CustomQuestionCard
                    wevo={wevo}
                    page={page}
                    group={group}
                    index={index}
                    isDashboard={true}
                    isLimitedReport={isLimitedReport}
                  />
                </Grid>
              )
          )}
        </Grid>
        <Box pt={2} />
        <Grid container spacing={2}>
          <Grid item xs={12} sx={styles.tileGrid}>
            <StarredQuotesCard
              key={`starred-quotes-wevo-${wevo?.id}-page-${page?.id}`}
              wevo={wevo}
              page={page}
              selectedAssetNum={selectedAssetNum}
              isLimitedReport={isLimitedReport}
            />
          </Grid>
        </Grid>
        <RightDrawerButton
          wevo={wevo}
          rightDrawerOpen={rightDrawerOpen}
          handleRightDrawerOpenClick={setRightDrawerOpen}
        />
      </Box>
    </Fragment>
  );
};

NewDashboard.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  rightDrawerOpen: PropTypes.bool.isRequired,
  setRightDrawerOpen: PropTypes.func.isRequired,
  handleShareButtonClick: PropTypes.func.isRequired,
  isLimitedReport: PropTypes.bool.isRequired,
};

export default NewDashboard;
