import { Box, Grid, IconButton, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useCallback, useMemo } from 'react';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as AudienceOutlineIcon } from '../../../src/assets/audience-outline.svg';
import shareFreeTrialIcon from '../../../src/assets/share-free-trial-logo.png';
import { ReactComponent as ShareIcon } from '../../../src/assets/share.svg';
import { isAuthenticated } from '../../modules/user/helpers';
import { TrackEvent } from '../analytics';
import ChatButton from './ChatButton';
import CustomButton from './ui/Button';

const ActionButtons = ({
  sessionId,
  segmentId,
  onSharePulse,
  onInviteColleague,
  onMyAudiencesClick,
  onRunStudy,
  isSessionOwner,
  hideChat,
  showAudienceLabel = false,
}) => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMdAndUp = useMediaQuery(theme.breakpoints.up('md'));
  const isLgAndUp = useMediaQuery(theme.breakpoints.up('lg'));

  const { track } = useAnalytics();

  const getButtonStyles = useCallback(
    (overrides = {}) => {
      let minWidth;
      if (isLgAndUp) {
        minWidth = '170px';
      } else if (isMdAndUp) {
        minWidth = '100px';
      } else if (isSm) {
        minWidth = '140px';
      } else {
        minWidth = '0';
      }

      return {
        height: '40px',
        minWidth: minWidth,
        fontWeight: 400,
        border: '1px solid #D1CFCF',
        whiteSpace: 'nowrap',
        '&:hover': {
          borderColor: '#A0A0A0',
          opacity: 1,
        },
        ...overrides,
      };
    },
    [isLgAndUp, isMdAndUp, isSm]
  );

  const buttonStyles = getButtonStyles();

  const handleSharePulse = () => {
    track(TrackEvent.PULSE_CLICKED_ACTION_BAR_SHARE_RESULTS, { sessionId, segmentId });
    onSharePulse(true);
  };

  const handleInviteColleagueButtonClick = () => {
    onInviteColleague(true);
  };

  const handleTryOtherPersonas = useCallback(() => {
    track(TrackEvent.PULSE_CLICKED_ACTION_BAR_TRY_OTHER_PERSONAS, { sessionId, segmentId });
    onMyAudiencesClick();
  }, [onMyAudiencesClick, segmentId, sessionId, track]);

  const tryOtherPersonasButton = useMemo(() => {
    // we only show the emphasized button styles when we're on the persona tab, which is also the only place we show the audience label;
    // aliasing it here makes it easier to make a separate prop later and makes the intention clearer. Making a new prop for one instance
    // was not worth the complexity.
    const showEmphasizedButton = showAudienceLabel;

    return (
      <CustomButton
        variant="secondaryDark"
        size="small"
        startIcon={
          <AudienceOutlineIcon
            fill={showEmphasizedButton ? '#2A6567' : '#D1CFCF'}
            style={{ marginRight: '-8px' }}
          />
        }
        onClick={handleTryOtherPersonas}
        sx={{
          ...(showEmphasizedButton
            ? getButtonStyles({
                background: '#D9EAE9',
                border: '1px solid #C2D9D8',
              })
            : buttonStyles),
          paddingLeft: 1,
          paddingRight: 2,
        }}>
        {showEmphasizedButton ? (
          <Typography variant="subtitle" fontWeight="bold" sx={{ color: '#2A6567' }}>
            Add your persona
          </Typography>
        ) : (
          <>Add your persona</>
        )}
      </CustomButton>
    );
  }, [buttonStyles, handleTryOtherPersonas, getButtonStyles, showAudienceLabel]);

  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        position: 'relative',
        paddingTop: hideChat ? 2 : 4,
        paddingBottom: 2,
        paddingX: 2,
        flexWrap: { lg: 'nowrap' },
        flexGrow: 1,
        columnGap: { xs: 2, md: 1, lg: 2 },
        backgroundColor: '#FFFFFF',
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px',
      }}>
      {onSharePulse && (
        <Grid item sx={{ order: { xs: 1, sm: 0 } }}>
          {isXs ? (
            <IconButton
              aria-label="share link"
              size="small"
              onClick={handleSharePulse}
              sx={{
                fontWeight: 400,
                border: '1px solid #D1CFCF',
                whiteSpace: 'nowrap',
                '&:hover': {
                  borderColor: '#A0A0A0',
                  opacity: 1,
                },
              }}>
              <ShareIcon fill="#D1CFCF" />
            </IconButton>
          ) : (
            <CustomButton
              variant="secondaryDark"
              size="small"
              startIcon={<ShareIcon fill="#D1CFCF" style={{ marginRight: '-8px' }} />}
              onClick={handleSharePulse}
              sx={{
                ...buttonStyles,
                paddingLeft: 1,
                paddingRight: 2,
              }}>
              Share results
            </CustomButton>
          )}
        </Grid>
      )}
      {isSessionOwner && onMyAudiencesClick && (
        <Grid item>
          <Typography
            component="span"
            variant="caption"
            fontWeight={450}
            mr={2}
            display={{ xs: 'none', sm: showAudienceLabel ? 'inline' : 'none' }}>
            Not who you were targeting?
          </Typography>
          {tryOtherPersonasButton}
        </Grid>
      )}

      {isAuthenticated() && onRunStudy && (
        <Grid item>
          {isXs ? (
            <IconButton
              aria-label="share free trial"
              onClick={handleInviteColleagueButtonClick}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignitems: 'center',
                height: 40,
                width: 40,
                fontWeight: 400,
                border: '1px solid #D1CFCF',
                whiteSpace: 'nowrap',
                '&:hover': {
                  borderColor: '#A0A0A0',
                  opacity: 1,
                },
              }}>
              <img
                src={shareFreeTrialIcon}
                alt="Share Free Trial logo"
                style={{ height: '14px', color: '#D1CFCF' }}
              />
            </IconButton>
          ) : (
            <CustomButton
              variant="secondaryDark"
              size="small"
              startIcon={
                <img
                  src={shareFreeTrialIcon}
                  alt="Share Free Trial logo"
                  style={{ height: '14px', color: '#D1CFCF', marginRight: '-4px' }}
                />
              }
              onClick={handleInviteColleagueButtonClick}
              sx={{
                ...buttonStyles,
                paddingX: 2,
              }}>
              Share a free trial
            </CustomButton>
          )}
        </Grid>
      )}
    </Grid>
  );
};

const actionButtonsBarStyles = {
  container: {
    position: 'relative',
    borderBottomLeftRadius: '20px',
    borderBottomRightRadius: '20px',
    backgroundColor: '#FFFFFF',
    border: 'none',
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.25)',
  },
  circle: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 60,
    height: 60,
    borderRadius: '50%',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.25)',
  },
  iconButtonContainer: {
    position: 'absolute',
    top: '0',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const ActionButtonsBar = ({
  sessionId,
  selectedSegment,
  onSharePulse,
  onInviteColleague,
  onMyAudiencesClick,
  onRunStudy,
  isSessionOwner,
  onOpenChat,
  hideActionButtons,
  hideChat,
  disableChat,
  showAudienceLabel = false,
}) => {
  return (
    <Box sx={{ ...actionButtonsBarStyles.container, minHeight: hideChat ? 0 : 40 }}>
      {!hideChat && <div style={actionButtonsBarStyles.circle}></div>}
      {!hideActionButtons && (
        <ActionButtons
          sessionId={sessionId}
          segmentId={selectedSegment?.id}
          onSharePulse={onSharePulse}
          onInviteColleague={onInviteColleague}
          onMyAudiencesClick={onMyAudiencesClick}
          onRunStudy={onRunStudy}
          isSessionOwner={isSessionOwner}
          hideChat={hideChat}
          showAudienceLabel={showAudienceLabel}
        />
      )}
      {!hideChat && (
        <div style={actionButtonsBarStyles.iconButtonContainer}>
          <ChatButton onClick={() => onOpenChat(true)} disabled={disableChat} />
        </div>
      )}
    </Box>
  );
};

export default ActionButtonsBar;
