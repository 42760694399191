import moment, { utc } from 'moment';
import { createSelector } from 'reselect';

const getElapsedTime = (start, end) => {
  const t1 = moment(start);
  const t2 = moment(end);
  const duration = moment.duration(t2.diff(t1)).asMilliseconds();
  return utc(duration).format('D[d], H[h], m[m]');
};

export const getActiveWevos = createSelector(
  (state) => state.wevos.wevos,
  (wevos) => {
    return wevos
      .filter((wevo) => wevo.is_deleted === false)
      .map((activeWevo) => ({
        id: activeWevo.id,
        teamId: activeWevo.owner_team_id,
        name: activeWevo.name,
        devices: activeWevo.devices,
        updated: new Date(activeWevo.date_modified).getTime(),
        timeToComplete: getElapsedTime(activeWevo.date_published, activeWevo.date_completed),
        status: activeWevo.status,
        archived: activeWevo.is_archived,
        isPreviewing: activeWevo.isPreviewing,
        url: activeWevo.url,
        acls: activeWevo.acls,
      }));
  }
);

export const getCurrentDraft = createSelector(
  (state) => state.wevos.draft,
  (draft) => {
    return draft;
  }
);

export const getCurrentWevo = createSelector(
  (state) => state.wevos.wevo,
  (wevo) => {
    return wevo;
  }
);
