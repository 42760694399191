import { useQuery } from 'react-query';
import axios from '../../../api.js';

const fetchSavedAudience = async () => {
  const response = await axios({
    url: `/api/v2/saved-audiences/groups`,
    method: 'GET',
  });
  return response.data.groups;
};

export default function useFetchSavedAudience() {
  return useQuery(['savedAudienceData'], fetchSavedAudience, {
    notifyOnChangeProps: 'tracked',
  });
}
