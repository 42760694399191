import { from } from 'rxjs';
import axios from '../../api';

export const fetchWevos = () => {
  const requestConfig = {
    url: `/api/v2/users/me/wevos`,
    method: 'GET',
  };
  return from(axios(requestConfig));
};

export const fetchWevo = (wevoId) => {
  const requestConfig = {
    url: `/api/v2/wevos/${wevoId}`,
    method: 'GET',
  };

  return from(axios(requestConfig));
};

export const archiveWevo = (wevoId) => {
  const requestConfig = {
    url: `/api/v2/wevos/archive`,
    method: 'PATCH',
    data: { wevoId },
  };

  return from(axios(requestConfig));
};

export const restoreWevo = (wevoId) => {
  const requestConfig = {
    url: `/api/v2/wevos/restore`,
    method: 'PATCH',
    data: { wevoId },
  };

  return from(axios(requestConfig));
};

export const deleteWevo = (wevoId) => {
  const requestConfig = {
    url: `/api/v2/wevos/delete`,
    method: 'DELETE',
    data: { wevoId },
  };

  return from(axios(requestConfig));
};

export const copyWevo = (wevoId) => {
  const requestConfig = {
    url: `/api/v2/wevos/copy-test`,
    method: 'POST',
    data: { wevoId },
  };

  return from(axios(requestConfig));
};

export const createWevo = (createObj) => {
  const requestConfig = {
    url: `/api/v2/wevos/create`,
    method: 'POST',
    data: createObj,
  };

  return from(axios(requestConfig));
};

export const updateWevoDefinition = (wevoObj) => {
  const requestConfig = {
    url: `/api/v2/wevos/update/info`,
    method: 'POST',
    data: wevoObj,
  };

  return from(axios(requestConfig));
};

export const updateWevoAudience = (audienceObj) => {
  const requestConfig = {
    url: `/api/v2/wevos/update/audiences`,
    method: 'POST',
    data: audienceObj,
  };

  return from(axios(requestConfig));
};

export const uploadImage = (imageObj, progressSubscriber) => {
  const formData = new FormData();
  formData.append('file', imageObj.file);

  const requestConfig = {
    url: `/api/v2/images`,
    method: 'POST',
    data: formData,
    progressSubscriber,
  };

  return from(axios(requestConfig));
};

export const createPageFromImage = (pageObj) => {
  const requestConfig = {
    url: `/api/v2/wevos/${pageObj.wevoId}/pages`,
    method: 'POST',
    data: pageObj,
  };

  return from(axios(requestConfig));
};

export const deletePage = (wevoId, pageId) => {
  const requestConfig = {
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}`,
    method: 'DELETE',
  };

  return from(axios(requestConfig));
};

export const updatePage = ({ wevoId, pageId, ...page }) => {
  const requestConfig = {
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}`,
    method: 'PUT',
    data: page,
  };

  return from(axios(requestConfig));
};

export const createStepFromImage = (stepObj) => {
  const requestConfig = {
    url: `/api/v2/wevos/${stepObj.wevoId}/steps`,
    method: 'POST',
    data: stepObj,
  };

  return from(axios(requestConfig));
};

export const updateStep = ({ wevoId, stepId, ...step }) => {
  const requestConfig = {
    url: `/api/v2/wevos/${wevoId}/steps/${stepId}`,
    method: 'PUT',
    data: step,
  };

  return from(axios(requestConfig));
};

export const deleteStep = (wevoId, stepId) => {
  const requestConfig = {
    url: `/api/v2/wevos/${wevoId}/steps/${stepId}`,
    method: 'DELETE',
  };

  return from(axios(requestConfig));
};

export const launchWevo = (wevoId) => {
  const requestConfig = {
    url: `/api/v2/wevos/${wevoId}/launch`,
    method: 'POST',
  };

  return from(axios(requestConfig));
};

export const fetchDraft = (wevoId) => {
  const requestConfig = {
    url: `/api/v2/wevos/${wevoId}`,
    method: 'GET',
  };

  return from(axios(requestConfig));
};

export const saveWevo = (wevoObj) => {
  const { id: wevoId, ...restOfWevo } = wevoObj;

  const requestConfig = {
    url: `/api/v2/wevos/${wevoId}`,
    method: 'PUT',
    data: { ...restOfWevo },
  };

  return from(axios(requestConfig));
};
