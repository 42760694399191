import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Switch, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import withStyles from '@mui/styles/withStyles';
import React, { useMemo, useState } from 'react';
import { DEFAULT_AUDIENCE_ATTRIBUTES } from '../../../modules/wevos/constants';

const AttributeSwitch = withStyles((theme) => ({
  root: {
    width: 64,
    minHeight: 46,
    height: 46,
    padding: 12,
  },
  switchBase: {
    marginTop: theme.spacing(0.25),
    marginLeft: theme.spacing(0.5),
  },
  thumb: {
    width: 18,
    height: 18,
    border: '0px solid rgba(202,211,216, 255)',
    marginTop: '3px',
    marginLeft: '1px',
  },
  track: {
    backgroundColor: '#b0bec5',
    borderRadius: 35,
  },
  checked: {
    '&$switchBase': {
      marginLeft: theme.spacing(0.15),
    },
  },
}))(Switch);

const AttributeCard = ({ heading, attribute, setAttribute, name, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const isAttributeSelected = useMemo(() => attribute?.isSelected, [attribute?.isSelected]);

  const handleAttributeClick = (ev) => {
    setAttribute(
      ev.target.checked
        ? { ...attribute, isCustomizedQuota: attribute.isCustomizedQuota, isSelected: ev.target.checked }
        : { ...DEFAULT_AUDIENCE_ATTRIBUTES[name], isCustomizedQuota: false, isSelected: ev.target.checked }
    );
    setIsOpen(ev.target.checked);
  };

  return (
    <Accordion expanded={isOpen}>
      <AccordionSummary
        onClick={() => setIsOpen(!isOpen)}
        expandIcon={isOpen ? <IndeterminateCheckBoxIcon color="primary" /> : <AddBoxIcon color="primary" />}>
        <Grid container justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={1} style={{ marginTop: '-16px', marginBottom: '-16px' }}>
            <FormControlLabel
              control={
                <AttributeSwitch
                  color="primary"
                  checked={isAttributeSelected}
                  onChange={handleAttributeClick}
                  style={{ color: isAttributeSelected ? 'white' : '' }}
                />
              }
            />
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              marginLeft: isAttributeSelected ? '27px' : '24px',
              color: isAttributeSelected ? '' : 'lightGrey',
            }}>
            <Typography variant="h5">{heading}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      {isOpen && <AccordionDetails>{children}</AccordionDetails>}
    </Accordion>
  );
};

export default AttributeCard;
