import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ShareIcon from '@mui/icons-material/Share';
import { Menu, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';

const SessionMenu = (props) => {
  const { id, anchorEl, open, onClose, onDetailsClick, onShareClick } = props;

  const styles = {
    font: { fontSize: 14 },
    menuOptionIcon: { fontSize: 18, marginRight: 2 },
  };

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      onClose={onClose}>
      <MenuItem onClick={() => onDetailsClick()}>
        <ArticleOutlinedIcon sx={styles.menuOptionIcon} />
        <Typography sx={styles.font}>Details</Typography>
      </MenuItem>
      <MenuItem onClick={() => onShareClick()}>
        <ShareIcon sx={styles.menuOptionIcon} />
        <Typography sx={styles.font}>Share</Typography>
      </MenuItem>
    </Menu>
  );
};

export default SessionMenu;
