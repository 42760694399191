import { ArcElement, Chart, DoughnutController } from 'chart.js';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { memoAreEqual } from '../../../helpers';
import { getScoreHoverTextColor, getScoreTextColor } from '../../../modules/report/constants';

Chart.register(DoughnutController, ArcElement);

const DoughnutScoreGraph = React.memo((props) => {
  const { score, showScore, backgroundColor, maxScore = 100 } = props;
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  // Embed score text in the middle of the doughnut
  const plugins = useMemo(
    () => ({
      beforeDraw: (chart) => {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;

        ctx.restore();
        if (showScore) {
          const fontSize = (height / 50).toFixed(2);
          ctx.font = fontSize + 'em sans-serif';
          ctx.textBaseline = 'middle';

          const text = score,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;

          ctx.fillText(text, textX, textY);
        }
        ctx.save();
      },
    }),
    [showScore, score]
  );

  const data = useMemo(
    () => ({
      datasets: [
        {
          data: [(score / maxScore) * 100, ((maxScore - score) / maxScore) * 100],
          backgroundColor: [
            backgroundColor || getScoreTextColor((score / maxScore) * 100),
            'rgb(216, 216, 216, 0.5)',
          ],
          hoverBackgroundColor: [getScoreHoverTextColor((score / maxScore) * 100), 'rgb(216, 216, 216, 0.5)'],
          borderWidth: 0,
        },
      ],
    }),
    [score, maxScore, backgroundColor]
  );

  const config = useMemo(
    () => ({
      type: 'doughnut',
      data,
      options: {
        animation: { duration: 0 },
        responsive: true,
        cutout: '80%',
        legend: {
          display: false,
        },
        plugins: {
          tooltip: { enabled: false },
        },
      },
      plugins: [plugins],
    }),
    [data, plugins]
  );

  useEffect(() => {
    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [config]);

  return <canvas ref={canvasRef} />;
}, memoAreEqual);

DoughnutScoreGraph.propTypes = {
  score: PropTypes.number.isRequired,
  showScore: PropTypes.bool,
};

DoughnutScoreGraph.defaultProps = {
  showScore: true,
};

export default DoughnutScoreGraph;
