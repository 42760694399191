import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Checkbox, Grid, Link, Paper, Popover } from '@mui/material';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

/**
 * Defines the quote filter component.
 */
const styles = makeStyles((theme) => ({
  root: {},
  filterContainer: {
    padding: theme.spacing(2),
  },
  checkbox: {
    padding: '2px',
  },
  selectAll: {
    cursor: 'pointer',
  },
}));

const WordFilter = (props) => {
  const classes = styles(props);
  const { buttonText, title, words, selectedWords, onWordFilterChange } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  // Determine whether the word filter is checked.
  const isWordChecked = (word) => {
    return selectedWords.includes(word);
  };

  const handleFilterClick = (ev) => {
    setAnchorEl(ev.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterCheckboxClick = (ev) => {
    if (ev.target.checked) {
      // box was just checked, so add word to words filter
      onWordFilterChange([...selectedWords, ev.target.name]);
    } else {
      // box was unchecked, so remove word from words filter
      onWordFilterChange(selectedWords.filter((word) => word !== ev.target.name));
    }
  };

  const handleClearFiltersClick = () => {
    onWordFilterChange([]);
  };

  const isOpen = Boolean(anchorEl);

  return (
    <Fragment>
      <FilterDropdown title={buttonText} onClick={handleFilterClick} isOpen={isOpen} />
      <Popover
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <Paper className={classes.filterContainer}>
          <Box pb={2}>
            <Typography variant="h5">{title}</Typography>
          </Box>

          {words?.map((word, index) => (
            <Box key={index} py={1}>
              <Grid container justifyContent="space-between">
                <Grid item xs={10}>
                  <Box pl={1}>
                    <Typography variant="caption">{word}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Checkbox
                    size="small"
                    className={classes.checkbox}
                    name={word}
                    checked={isWordChecked(word)}
                    onChange={handleFilterCheckboxClick}
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
          <Box textAlign="right">
            <Typography variant="caption">
              <Link className={classes.selectAll} onClick={handleClearFiltersClick} underline="hover">
                Clear
              </Link>
            </Typography>
          </Box>
        </Paper>
      </Popover>
    </Fragment>
  );
};

WordFilter.propTypes = {
  buttonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  words: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedWords: PropTypes.arrayOf(PropTypes.string).isRequired,
  onWordFilterChange: PropTypes.func.isRequired,
};

/**
 * Defines the filter dropdown component.
 */
const dropdownStyles = makeStyles((theme) => ({
  root: {
    border: '1px solid #757575',
    borderRadius: '6px',
    color: '#757575',
    cursor: 'pointer',
    textTransform: 'none',
  },
  icon: {
    verticalAlign: 'middle',
  },
}));

const FilterDropdown = (props) => {
  const classes = dropdownStyles(props);

  const { title, onClick, isOpen } = props;

  const icon = isOpen ? (
    <ExpandLessIcon className={classes.icon} />
  ) : (
    <ExpandMoreIcon className={classes.icon} />
  );

  return (
    <Button className={classes.root} onClick={onClick}>
      {title} {icon}
    </Button>
  );
};

FilterDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default WordFilter;
