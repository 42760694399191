import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Slide, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useAnalytics } from 'use-analytics';
import copyShareableLinkLogo from '../../../src/assets/copy-shareable-link-logo.png';
import { TrackEvent } from '../analytics';
import CustomButton from './ui/Button';

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InviteColleagueDialog = (props) => {
  const { sessionId, link, isOpen, onToggleInviteColleagueDialog } = props;
  const [copySuccess, setCopySuccess] = useState(false);

  const { track } = useAnalytics();

  const copyToClipboard = () => {
    track(TrackEvent.COPIED_PULSE_FREE_TRIAL_SHARE_LINK, { sessionId, shareLink: link });
    navigator.clipboard.writeText(link);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const handleClose = () => {
    setCopySuccess(false);
    onToggleInviteColleagueDialog(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      TransitionComponent={SlideTransition}
      onClose={handleClose}
      PaperProps={{
        style: {
          borderRadius: '20px',
          paddingTop: '24px',
          paddingBottom: '16px',
          width: '380px',
        },
      }}>
      <DialogTitle
        sx={{
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 700,
          color: (theme) => theme.palette.background.default,
        }}>
        Share the power of WEVO Pulse!
        {onToggleInviteColleagueDialog && (
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => onToggleInviteColleagueDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#000000',
            }}>
            <CloseIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        )}
      </DialogTitle>
      <DialogContent sx={{ padding: 2 }}>
        <Grid container alignItems="center" sx={{ marginTop: -0.5 }}>
          <Grid item xs>
            <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '14px' }}>
              Gift your colleagues and friends
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '14px' }}>
              a free trial now:
            </Typography>
          </Grid>
        </Grid>
        <Grid container justifyContent={'center'} sx={{ marginTop: 2.5, marginBottom: 1 }}>
          <Grid item>
            <CustomButton
              size="small"
              startIcon={
                copySuccess ? null : (
                  <img
                    src={copyShareableLinkLogo}
                    alt="Share Free Trial logo"
                    style={{ height: '14px' }}
                    sx={{ color: 'white' }}
                  />
                )
              }
              sx={{
                background: `linear-gradient(90deg, #264151 0%, #4BC0C8 100%)`,
                fontSize: '14px',
                fontWeight: 700,
                width: '200px',
                height: '40px',
              }}
              onClick={copyToClipboard}>
              {copySuccess ? 'Copied!' : 'Copy Shareable Link'}
            </CustomButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default InviteColleagueDialog;
