import InfoIcon from '@mui/icons-material/Info';
import { Box, CircularProgress, Grid, List, ListItem, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation, useParams } from 'react-router-dom';
import { JobToBeDone, Limits } from '../../../modules/intake/constants.js';
import { WevoType } from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import useAddCustomQuestion from '../hooks/useAddCustomQuestion';
import useDeleteQuestions from '../hooks/useDeleteQuestions';
import useUpdateSortOrder from '../hooks/useUpdateSortOrder';
import AddQuestionCard from './AddQuestionCard.js';
import CustomQuestion from './CustomQuestion';

const styles = {
  infoIcon: {
    marginLeft: (theme) => theme.spacing(1),
    verticalAlign: 'bottom',
    color: grey[600],
  },
};

function CustomQuestionDetailsSection({
  draft,
  customQuestions,
  setCustomQuestions,
  setQuestionsValidity,
  customQuestionCoupletsCount,
}) {
  // HACK: Allow admins to bypass the hard cap limit of custom question groups.
  const useQuery = () => new URLSearchParams(useLocation()?.search);
  const query = useQuery();

  const forceNoLimit = query.get('noLimit') === 'true';

  const limitNumber =
    draft?.type === WevoType.Classic
      ? Limits.Classic
      : draft?.jobToBeDone === JobToBeDone.Lite
      ? Limits.Lite
      : Limits.Journey;

  const currentNumberOfQuestions =
    draft?.type === WevoType.Classic ? customQuestions?.length : customQuestionCoupletsCount;
  const showWarning = currentNumberOfQuestions > limitNumber;

  const exceedsLimits =
    !forceNoLimit &&
    (customQuestions.length >= limitNumber ||
      (draft?.type === WevoType.Journey && currentNumberOfQuestions >= limitNumber));

  const { wevoId } = useParams();

  const assets = draft?.pages ?? [];
  let wevoTestType =
    draft?.type === WevoType.Journey ? 'Journey' : assets?.length > 1 ? 'Compare' : 'Single Page';

  const [showLoadingIcon, setShowLoadingIcon] = useState(false);
  const { mutate: updateSortOrder } = useUpdateSortOrder();
  const { mutate: addCustomQuestion } = useAddCustomQuestion();
  const { mutate: deleteQuestions } = useDeleteQuestions();

  const createCustomQuestion = useCallback(
    (wevoId, scopesToAdd, prevQuestions) =>
      addCustomQuestion(
        { id: wevoId, scopesToAdd: scopesToAdd },
        {
          // Adding the new Group object that came back in the response, into the customQuestions array
          onSuccess: (group, variables, context) => {
            setCustomQuestions((customQuestions) => [...customQuestions, group]);
            setQuestionsValidity((questionsValidity) => {
              const newData = { ...questionsValidity };
              newData[String(group?.groupId)] = false;
              return newData;
            });
          },
          onError: (err) => {
            setCustomQuestions(prevQuestions);

            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error creating custom question');
          },
        }
      ),
    [addCustomQuestion, setCustomQuestions, setQuestionsValidity]
  );

  /* Handles creating new custom question */
  const handleAddCustomQuestionClick = () => {
    setShowLoadingIcon(true);
    const prevQuestions = Array.from(customQuestions);
    // Note: For journeys, we want to add all pages by default since Compares for now apply journey-level questions
    // to all journeys, and Single Journeys only have a page anyway so the net effect is the same.
    // Classic on the other hand, defaults to a single page only
    const pagesToAdd = draft.type === WevoType.Journey ? draft?.pages ?? [] : (draft?.pages ?? []).slice(0, 1);
    const scopesToAdd = pagesToAdd.map((page) => ({ wevoPageId: page.id.toString(), stepId: null }));
    createCustomQuestion(wevoId, scopesToAdd, prevQuestions);
    setShowLoadingIcon(false);
  };

  /* Update groupSortOrder */
  const updateGroupSortOrder = (groupId, sourceIndex, destinationIndex) => {
    const originalQuestions = Array.from(customQuestions);

    // Reorder custom questions
    let items = Array.from(customQuestions);
    const [itemToReorder] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, itemToReorder);

    let groupSortOrder = 0;
    items = items.map((item) => {
      return {
        ...item,
        groupSortOrder: groupSortOrder++,
      };
    });

    setCustomQuestions(items);

    updateSortOrder(
      { id: wevoId, groupId: groupId, groupSortOrder: destinationIndex },
      {
        onError: () => {
          setCustomQuestions(originalQuestions);
        },
      }
    );
    return;
  };

  const deleteCustomQuestion = useCallback(
    (groupIdToDelete) => {
      const originalArray = Array.from(customQuestions);
      const index = originalArray.findIndex((question) => question.groupId === groupIdToDelete);
      if (index < 0) {
        return;
      }

      setShowLoadingIcon(true);
      const items = Array.from(customQuestions);
      items.splice(index, 1); // removes 1 element at index
      setCustomQuestions(items); //update parent's array

      let prevQuestionValidity;
      setQuestionsValidity((questionsValidity) => {
        const newData = { ...questionsValidity };
        prevQuestionValidity = newData[String(groupIdToDelete)];
        delete newData[String(groupIdToDelete)];
        return newData;
      });

      deleteQuestions(
        { id: wevoId, groupId: groupIdToDelete, index },
        {
          onError: (err) => {
            setQuestionsValidity((questionsValidity) => {
              const newData = { ...questionsValidity };
              newData[String(groupIdToDelete)] = prevQuestionValidity;
              return newData;
            });
            setCustomQuestions(originalArray);

            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting custom question');
          },
          onSettled: () => {
            setShowLoadingIcon(false);
          },
        }
      );
    },
    [wevoId, customQuestions, deleteQuestions, setCustomQuestions, setQuestionsValidity]
  );

  const onDragEnd = (result) => {
    // make sure we drag items only on dragAndDrop area
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const groupIdToReorder = customQuestions?.[sourceIndex]?.groupId;

    updateGroupSortOrder(groupIdToReorder, sourceIndex, destinationIndex);
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h5">
          (Optional) Custom Question(s)
          <Tooltip
            title={
              <Fragment>
                <span>What, if any, custom question would you like to include?</span>
                <Box py={1} />
                <em>
                  {
                    'A WEVO account manager may contact you to review feasibility of your desired custom question'
                  }
                </em>
                .
              </Fragment>
            }
            placement="right"
            style={{ fontSize: '20px' }}>
            <InfoIcon sx={styles.infoIcon} />
          </Tooltip>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="caption"
          style={{
            color: showWarning && 'red',
          }}>
          <Fragment>
            <span>
              Your WEVO {wevoTestType} test comes with up to {limitNumber} custom questions with one follow-up
              question each.{' '}
            </span>
            {draft?.type === WevoType.Journey && (
              <span>Custom questions applied to multiple pages will count as one question per page. </span>
            )}
            <span>
              If your test requires more than {limitNumber} custom questions, please reach out to your customer
              success manager by emailing{' '}
              <a href="mailto:support@wevo.ai" target="_top" style={{ color: 'black' }}>
                <Typography
                  variant="caption"
                  style={{
                    cursor: 'pointer',
                  }}>
                  support@wevo.ai
                </Typography>
              </a>
              .{' '}
            </span>
            <Box pt={1} />
            <div>{`You currently have ${currentNumberOfQuestions} ${
              currentNumberOfQuestions === 1 ? 'custom question' : 'custom questions'
            }.`}</div>
          </Fragment>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={onDragEnd}>
          {/* note: droppableId must be a string */}
          <Droppable droppableId="ItemsList">
            {(provided) => (
              <List className="ItemsList" {...provided.droppableProps} ref={provided.innerRef}>
                {!_isEmpty(customQuestions) &&
                  customQuestions?.map((customQuestion, index) => (
                    <Draggable
                      // note: draggableId must be a string
                      draggableId={String(customQuestion?.groupId)}
                      key={customQuestion?.groupId}
                      index={index}>
                      {(provided) => (
                        <ListItem ref={provided.innerRef} {...provided.draggableProps}>
                          <CustomQuestion
                            dragHandleProps={{ ...provided.dragHandleProps }}
                            groupDetails={customQuestion}
                            setQuestionsValidity={setQuestionsValidity}
                            deleteCustomQuestion={deleteCustomQuestion}
                            exceedsLimits={exceedsLimits}
                            currentNumberOfQuestions={currentNumberOfQuestions}
                            customQuestions={customQuestions}
                            setCustomQuestions={setCustomQuestions}
                            questionNumber={index + 1}
                          />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      {assets.length > 0 ? (
        <Grid item xs={12} px={2}>
          <AddQuestionCard
            text={'Add a custom question'}
            exceedsLimits={exceedsLimits}
            exceedsLimitTooltipText={'You have hit the limit on custom questions for this test.'}
            onClick={handleAddCustomQuestionClick}
          />
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Typography variant="body2" color="error">
            <Fragment>
              You must upload at least one page in the {wevoTestType === 'Journey' ? 'Task' : 'Test Type'}{' '}
              section, in order to add a custom question.
            </Fragment>
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} align="center">
        {showLoadingIcon && <CircularProgress />}
      </Grid>
    </Grid>
  );
}

CustomQuestionDetailsSection.propTypes = {
  draft: PropTypes.object.isRequired,
  customQuestions: PropTypes.array.isRequired,
  setCustomQuestions: PropTypes.func.isRequired,
  setQuestionsValidity: PropTypes.func.isRequired,
  customQuestionCoupletsCount: PropTypes.number,
};

export default CustomQuestionDetailsSection;
