import { useTheme } from '@emotion/react';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Card, CardContent, CircularProgress, Grid, IconButton, Link, Typography } from '@mui/material';
import { green, grey, lightGreen, red } from '@mui/material/colors';
import React, { useMemo } from 'react';
import { Link as RouterLink, generatePath } from 'react-router-dom';
import { ReactComponent as DiagnosticsIcon } from '../../../../assets/diagnostics-dynamic.svg';
import { JobToBeDone } from '../../../../modules/intake/constants';
import { BenchmarkScope, ComponentType } from '../../../../modules/wevos/constants';
import { hasComponent, isClassicComparePages } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';
import DoughnutScoreGraph from '../../components/DoughnutScoreGraph';
import DiagnosticsBarGraph from '../../diagnostics/DiagnosticsBarGraph';
import useBenchmarks from '../../hooks/useBenchmarks';
import useDiagnostics from '../../hooks/useDiagnostics';

const DiagnosticsSummary = (props) => {
  const { wevo, page, isDashboard, selectedAssetNum, isLimitedReport } = props;

  const theme = useTheme();
  const styles = {
    card: {
      borderRadius: '20px',
      height: '100%',
    },
    cardContent: {
      display: 'flex',
      height: '100%',
    },
    diagnosticsIconGrid: {
      marginRight: theme.spacing(2),
    },
    diagnosticsIcon: {
      color: theme.palette.primary.main,
      width: '25px',
      height: '25px',
      marginTop: theme.spacing(0.25),
      marginRight: theme.spacing(0.25),
      marginLeft: theme.spacing(0.25),
      marginBottom: theme.spacing(-0.25),
    },
    link: {
      color: theme.palette.primary.main,
      fontSize: '12px',
      textAlign: 'end',
    },
    underline: {
      textDecoration: 'none',
    },
    rightArrow: {
      marginLeft: theme.spacing(1),
      verticalAlign: 'bottom',
    },
    noWrap: {
      flexWrap: 'nowrap',
    },
    diagnosticsContainer: {
      alignContent: 'space-between',
      minHeight: '280px',
    },
    icon: {
      backgroundColor: grey[100],
      borderRadius: '50%',
      padding: '3px',
    },
    iconButton: {
      backgroundColor: grey[100],
    },
    subText: {
      color: theme.palette.primary.dark,
    },
    name: {
      fontSize: '11px',
    },
  };

  const { data } = useDiagnostics({ wevoId: wevo?.id, pageId: page?.id });

  const diagnostics = data?.diagnostics ?? [];
  const useNewDiagnostics = data?.useNewDiagnostics || wevo?.useVersionedDiagnostics || false;
  const maxScore = useMemo(() => (useNewDiagnostics ? 30 : 100), [useNewDiagnostics]);

  const { data: benchmarksData } = useBenchmarks(wevo?.id, page?.id, { scopeType: BenchmarkScope.Default });
  const benchmarks = benchmarksData?.benchmarks;

  const rescaleScoreToScale = (score, scale) => {
    // rescale score to a number out of 100
    return (Math.round(score * 100) / scale) * 100;
  };

  const newDiagnosticsDonutColor = (diagnostic) => {
    // TODO: remove this hack, only for the  New Diagnostics Demo, only
    const benchmark = diagnostic.benchmark;
    const benchmarkPoints = 5;

    const diff = Math.round(diagnostic.score * 100) - benchmark;

    if (diff >= benchmarkPoints) {
      return green[500];
    } else if (diff <= -benchmarkPoints) {
      return red[500];
    } else {
      return lightGreen[500];
    }
  };

  const diagnosticsPath =
    wevo?.useVersionedDiagnostics && isClassicComparePages(wevo)
      ? generatePath(Paths.reports.diagnosticsPage, {
          wevoId: wevo?.id,
          pageNum: selectedAssetNum,
        })
      : generatePath(Paths.reports.diagnostics, {
          wevoId: wevo?.id,
        });

  const hasBenchmarks = useMemo(() => wevo?.jobToBeDone !== JobToBeDone.Lite, [wevo]);

  if (!hasComponent(wevo, ComponentType.Diagnostic)) {
    return (
      <Card sx={styles.card} elevation={4}>
        <CardContent sx={styles.cardContent}>
          <Box m="auto">
            <Typography variant="body2">This study does not include Diagnostics</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={styles.card} elevation={4}>
      <CardContent sx={styles.cardContent}>
        <Grid container spacing={2} sx={styles.diagnosticsContainer}>
          <Grid item xs={12}>
            <Grid item container xs={12} sx={styles.noWrap}>
              <Grid item sx={styles.diagnosticsIconGrid}>
                {isLimitedReport ? (
                  <Box sx={styles.icon}>
                    <DiagnosticsIcon fill={theme.palette.primary.main} style={styles.diagnosticsIcon} />
                  </Box>
                ) : (
                  <IconButton size="small" sx={styles.iconButton}>
                    <Link to={diagnosticsPath} component={RouterLink}>
                      <DiagnosticsIcon fill={theme.palette.primary.main} style={styles.diagnosticsIcon} />
                    </Link>
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h5" id="diagnostics-heading">
                  Diagnostics
                </Typography>
                <Typography variant="caption" id="diagnostics-sub-heading" sx={styles.subText}>
                  The journey diagnostics are based on visitors impression of the entire journey
                </Typography>
              </Grid>
            </Grid>
            {!useNewDiagnostics && (
              <Grid item container justifyContent="space-between" alignContent="center" spacing={2}>
                {diagnostics &&
                  diagnostics.map((diagnostic) => (
                    <Grid item key={diagnostic.id} xs={2}>
                      <Box>
                        <DoughnutScoreGraph
                          score={rescaleScoreToScale(diagnostic.score, maxScore)}
                          showScore={false}
                          backgroundColor={useNewDiagnostics ? newDiagnosticsDonutColor(diagnostic) : null}
                        />
                      </Box>
                      <Box textAlign="center">
                        <Typography variant="h6" sx={styles.name}>
                          {diagnostic.name}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
              </Grid>
            )}
            {useNewDiagnostics && (
              <Grid
                item
                xs={12}
                aria-labelledby="diagnostics-heading diagnostics-sub-heading diagnostics-graph-content">
                {diagnostics?.length === 0 ? (
                  <Box p={4} textAlign="center">
                    <CircularProgress />
                  </Box>
                ) : (
                  <DiagnosticsBarGraph
                    diagnostics={diagnostics}
                    benchmarks={benchmarks}
                    onBarSelected={(label) => {}}
                    width={'100%'}
                    height={isDashboard ? '180px' : '300px'}
                    tooltipPlacement={'left'}
                    isDashboard={isDashboard}
                    fontSize="XSmall"
                    hasBenchmarks={hasBenchmarks}
                  />
                )}
              </Grid>
            )}
          </Grid>
          {!isLimitedReport && (
            <Grid item xs={12}>
              <Link to={diagnosticsPath} component={RouterLink} sx={styles.underline}>
                <Typography variant="body2" sx={styles.link}>
                  See Diagnostics
                  <ChevronRightIcon fontSize="small" sx={styles.rightArrow} />
                </Typography>
              </Link>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default DiagnosticsSummary;
