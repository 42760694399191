import _ from 'lodash';

/**
 * Helper function for building a properties object for tracked
 * audience filter events.
 *
 * @param {Object} newFilters - The new state of the audience filters, a mapping of segmentId => true | false
 * @param {Object} oldFilters - The old state of the audience filters, a mapping of segmentId => true | false
 * @param {Object} segmentToAudienceMap - A mapping of segmentId => Audience, used for mapping a segmentId to
 *  additional audience characteristics to include in the event properties
 * @param {Object} rest - Other arbitrary data to include in the properties object
 * @returns {JSX} - fragment
 */
export const buildAudienceFilterProperties = (newFilters, oldFilters, segmentToAudienceMap, rest) => {
  const changedAudienceFilters = {};
  Object.keys(newFilters).forEach((filterId) => {
    const segmentFilters = newFilters[filterId];

    for (const [segmentId, shouldShowQuotes] of Object.entries(segmentFilters)) {
      if (oldFilters && shouldShowQuotes !== oldFilters?.[filterId]?.[segmentId]) {
        changedAudienceFilters[segmentId] = shouldShowQuotes;
      }
    }
  });

  return {
    filterAttributes: Object.keys(changedAudienceFilters).map((segmentId) => {
      return segmentToAudienceMap[segmentId]?.attributeLabel;
    }),
    filterSegmentLabel: Object.keys(changedAudienceFilters).map((segmentId) => {
      return segmentToAudienceMap[segmentId]?.segmentLabel;
    }),
    filterValues: Object.values(changedAudienceFilters),
    ...rest,
  };
};

/**
 * Helper function for determining whether audience filter events should be tracked.
 *
 * Note: this is a workaround for filtering out nosiy events that may happen at initialization of the
 * QuoteAudienceFilter component
 *
 * @param {Object} filteredQuotesbyAudienceProperties - An audience filter properties event
 * @param {Object} audienceFilters - The current state of the audience filters, a mapping of segmentId => true | false
 * @returns {boolean} - true if should track, else false
 */
export const shouldTrackAudienceFilterChange = (filteredQuotesbyAudienceProperties, audienceFilters) => {
  // workaround to not track events when onAudienceFilterChange fires automatically on page load to initialize all filters
  const hasFilters = filteredQuotesbyAudienceProperties.filterAttributes.length > 0;
  const audienceFiltersLength = Object.values(audienceFilters).reduce(
    (acc, segments) => acc + Object.keys(segments).length,
    0
  );
  const isFilterAll = filteredQuotesbyAudienceProperties.filterAttributes.length === audienceFiltersLength;

  return hasFilters && !isFilterAll;
};

/**
 * Helper function for building a properties object for tracked word filter events.
 *
 * @param {List[string]} newFilters - The new state of the word filters, a list of words
 * @param {List[string]} oldFilters - The old state of the word filters, a list of words
 * @param {Object} rest - Other arbitrary data to include in the properties object
 * @returns {JSX} - fragment
 */
export const buildWordFilterProperties = (newFilters, oldFilters, rest) => {
  const deselected = newFilters.length < oldFilters.length;

  let changedFilters;

  if (deselected) {
    // determine what got removed
    changedFilters = _.difference(oldFilters, newFilters);
  } else {
    // determine what got added
    changedFilters = _.difference(newFilters, oldFilters);
  }

  return {
    filters: changedFilters,
    deselected: deselected,
    ...rest,
  };
};
