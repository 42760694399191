import { Card, CardContent, Grid, Typography, Tooltip, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { grey } from '@mui/material/colors';
import { getJourneyScoreSubText } from '../../../../modules/report/constants';
import SegmentedHalfDoughtnutScoreGraph from '../../components/SegmentedHalfDoughnutScoreGraph';
import { ReactComponent as WarningIcon } from '../../../../assets/cds_warning.svg';

const styles = makeStyles((theme) => ({
  root: {
    borderRadius: '20px',
  },
  cardContent: {
    padding: theme.spacing(3),
  },
  gridContent: {
    textAlign: 'center',
  },
  responsiveGridContent: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('lg')]: {
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(3, 3, 0),
    },
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  halfDoughnutGraph: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(2),
    width: '100%',
    maxWidth: '160px',
  },
  text: {
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  cdsWarning: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  errorText: {
    color: theme.palette.error.cds,
    fontSize: 16,
    fontWeight: 700,
    marginLeft: theme.spacing(3),
  },
  warningIcon: {
    width: '20px',
    height: '20px',
    marginLeft: theme.spacing(1),
  },
  warningBox: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    justifyContent: 'center',
  },
}));

const TopLevelScoreCard = ({
  header,
  description,
  topLevelScore,
  metricScores,
  compositeMetricsThemeNames,
  compositeMetricsColors,
  isDashboard,
  componentId,
  isLowCds,
}) => {
  const classes = styles();
  const scaledScores = metricScores?.map((score) => score / metricScores.length);
  const colors = compositeMetricsThemeNames.map((name) => compositeMetricsColors[name]);
  const cdsWarning = isLowCds ? (
    <Box className={classes.cdsWarning}>
      <Typography variant="h4" className={classes.errorText}>
        This CDS has not reached the minimum passing score for “Comprehension.”
      </Typography>
      <Tooltip
        title={
          'All CDS studies that score below 80 in Comprehension are considered non-passing, regardless of all other scores within the study.'
        }
        placement={'bottom-end'}
        classes={{
          tooltip: classes.chartTooltip,
        }}>
        <InfoIcon sx={{ color: grey[600] }} />
      </Tooltip>
    </Box>
  ) : (
    <></>
  );

  const headerBox = isLowCds ? (
    <Box className={classes.warningBox}>
      <Typography variant="h4">{header}</Typography>
      <WarningIcon className={classes.warningIcon} />
    </Box>
  ) : (
    <Typography variant="h4" className={classes.header}>
      {header}
    </Typography>
  );

  return (
    <Card className={classes.root} elevation={4}>
      <CardContent className={classes.cardContent}>
        {isDashboard ? (
          <Grid container justifyContent="center" className={classes.gridContent} id={componentId}>
            <Grid item xs={12}>
              {headerBox}
            </Grid>
            <Grid item xs={10} className={classes.halfDoughnutGraph}>
              <SegmentedHalfDoughtnutScoreGraph
                topLevelScore={topLevelScore}
                scores={scaledScores}
                colors={colors}
                isLowCds={isLowCds}
              />
            </Grid>
            <Grid item>{cdsWarning}</Grid>
            {false && (
              <Grid item xs={12}>
                <Typography variant="body1">
                  {topLevelScore ? getJourneyScoreSubText(topLevelScore) : 'Still Calculating'}!
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid container direction="row" className={classes.responsiveGridContent}>
            <Grid item xs={12} md={7} lg={8} className={classes.text}>
              {headerBox}
              <Typography variant="body1">{description}</Typography>
            </Grid>
            <Grid container item xs={12} md={5} lg={4} justifyContent="center">
              <Grid item className={classes.halfDoughnutGraph}>
                <SegmentedHalfDoughtnutScoreGraph
                  topLevelScore={topLevelScore}
                  scores={scaledScores}
                  colors={colors}
                  isLowCds={isLowCds}
                />
              </Grid>
              <Grid item>{cdsWarning}</Grid>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

TopLevelScoreCard.propTypes = {
  header: PropTypes.string.isRequired,
  description: PropTypes.string,
  topLevelScore: PropTypes.number.isRequired,
  metricScores: PropTypes.array.isRequired,
  compositeMetricsThemeNames: PropTypes.array.isRequired,
  compositeMetricsColors: PropTypes.object.isRequired,
  isDashboard: PropTypes.bool,
};

export default TopLevelScoreCard;
