import { produce } from 'immer';
import { get as _get } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { MutationKeys } from '../../../modules/intake/constants';

const uploadImagesAndCreatePages = async ({ wevoId, files, deviceId, primerId, primerContext }) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('files', file));
  formData.append('wevoId', wevoId);
  formData.append('deviceId', deviceId);

  if (primerId) {
    formData.append('primerId', primerId);
  }
  if (primerContext) {
    formData.append('primerContext', primerContext);
  }

  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/images`,
    method: 'POST',
    data: formData,
  });

  return response.data.pages;
};

export default function useUploadPage() {
  const queryClient = useQueryClient();
  return useMutation(uploadImagesAndCreatePages, {
    mutationKey: MutationKeys.uploadPage,
    onSuccess: (pages, variables) => {
      queryClient.setQueryData(['wevoData', { wevoId: variables.wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const currentPages = _get(draft, 'pages', []);
          currentPages.concat(pages);
        });
      });
      queryClient.invalidateQueries(['customQuestionsData', { wevoId: variables.wevoId }]);
    },
  });
}
