import { Chart, Legend, PieController, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Color from 'color';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { memoAreEqual } from '../../../helpers.js';
import { convertScoresToPercentages } from '../../../modules/report/helpers.js';
import theme from '../../../theme';

Chart.register(PieController, Legend, Tooltip);

const YesNoChart = React.memo((props) => {
  const { labels, scores, hideNumbers, colors, isPptComponent, width = '', height = '', componentId } = props;
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const percentages = convertScoresToPercentages(scores);

  const config = useMemo(
    () => ({
      type: 'pie',
      data: {
        labels: labels,
        datasets: [
          {
            data: percentages,
            backgroundColor: hideNumbers ? colors : [theme.palette.primary.light, 'lightgrey'],
            hoverOffset: hideNumbers ? 0 : 4,
          },
        ],
      },
      plugins: [ChartDataLabels],
      options: {
        responsive: !isPptComponent,
        maintainAspectRatio: false,
        layout: {
          padding: {
            top: 5,
          },
        },
        plugins: {
          legend: {
            display: hideNumbers ? false : true,
            position: 'bottom',
            align: 'center',
            labels: {
              boxHeight: 15,
              boxWidth: 30,
            },
          },
          tooltip: {
            interaction: {
              mode: 'point',
            },
            enabled: true,
            displayColors: true,
            xAlign: 'center',
            yAlign: 'top',
            position: 'nearest',
            backgroundColor: 'rgba(97, 97, 97, .95)',
            padding: 10,
            caretPadding: 15,
            titleFont: {
              family: theme.typography.fontFamily,
              weight: theme.typography.fontWeightBold,
            },
            bodyFont: {
              family: theme.typography.fontFamily,
            },
            callbacks: {
              label: function (context) {
                const label = context.label;
                if (!label) {
                  return;
                }
                const score = scores[context.dataIndex];
                const currentPercentage = context.dataset['data'][context.dataIndex].toFixed(1);
                return `${label}: ${score}  (${currentPercentage}%)`;
              },
            },
          },
          datalabels: {
            color: function (context) {
              const lightTextColor = 'white';
              const darkTextColor = theme.palette.primary.main;
              const bgColor = Color(context.dataset.backgroundColor[context.dataIndex]);
              const lightContrast = bgColor.contrast(Color(lightTextColor));
              const darkContrast = bgColor.contrast(Color(darkTextColor));
              return lightContrast > darkContrast ? lightTextColor : darkTextColor;
            },
            formatter: function (value) {
              const currentPercentage = value.toFixed(1);
              return `${currentPercentage}%`;
            },
            font: {
              weight: 'bold',
              size: 13,
            },
            display: function (context) {
              const currentPercentage = context.dataset['data'][context.dataIndex].toFixed(1);
              return hideNumbers ? false : currentPercentage > 10;
            },
          },
        },
        rotation: 180,
        scales: {
          x: { display: false },
          y: { display: false },
        },
      },
    }),
    [labels, scores, hideNumbers, colors, percentages, isPptComponent]
  );

  useEffect(() => {
    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [config]);

  return <canvas id={componentId} height={height} width={width} ref={canvasRef}></canvas>;
}, memoAreEqual);

YesNoChart.propTypes = {
  labels: PropTypes.array.isRequired,
  scores: PropTypes.array.isRequired,
  hideNumbers: PropTypes.bool,
  colors: PropTypes.array,
  isPptComponent: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  componentId: PropTypes.string,
};

export default YesNoChart;
