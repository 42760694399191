import Analytics from 'analytics';
import segmentPlugin from '@analytics/segment';
import TrackEvent from './TrackEvent';
import useTrackPageLoad from './useTrackPageLoad';
import { buildAudienceFilterProperties, buildWordFilterProperties } from './eventPropertyHelpers';

export { buildAudienceFilterProperties, buildWordFilterProperties, TrackEvent, useTrackPageLoad };

const analytics = Analytics({
  app: 'customer-app',
  plugins: [
    segmentPlugin({
      writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY,
    }),
  ],
});

export default analytics;
