import PropTypes from 'prop-types';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Toolbar from '@mui/material/Toolbar';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { WevoTestType, WevoType } from '../../../modules/wevos/constants';
import ShowImage from './ShowImage';
import Carousel from './Carousel';

const ShowExperienceButton = withStyles({
  root: {
    float: 'right',
    position: 'fixed',
    right: -16,
    top: 140,
    color: 'white',
    boxShadow: 4,
    textTransform: 'none',
    border: '1px solid',
    borderColor: '#0063cc',
    '&:hover': {
      backgroundColor: 'rgba(39,110,176, 0.90)',
      borderColor: 'rgba(39,110,176, 0.90)',
      boxShadow: 'none',
    },
  },
})(Button);

const rightDrawerButtonStyles = makeStyles((theme) => ({
  showPagesText: {
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  showPagesButton: {
    backgroundColor: theme.palette.primary.main,
  },
  arrow: {
    marginRight: theme.spacing(-0.5),
    marginLeft: theme.spacing(-2),
  },
}));

export const RightDrawerButton = ({ wevo, rightDrawerOpen, handleRightDrawerOpenClick }) => {
  const classes = rightDrawerButtonStyles();

  if (rightDrawerOpen) {
    return null;
  }

  let showText = 'Show Experience';

  return (
    <Tooltip title={showText}>
      <ShowExperienceButton
        variant="contained"
        onClick={handleRightDrawerOpenClick}
        className={classes.showPagesButton}>
        <ArrowBackIosIcon fontSize="small" className={classes.arrow} />
        <Typography variant="body2" className={classes.showPagesText}>
          {showText}
        </Typography>
      </ShowExperienceButton>
    </Tooltip>
  );
};

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: (props) => props.width,
    flexShrink: 0,
    zIndex: 99,
  },
  drawerPaper: {
    width: (props) => props.width,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  drawerImageContainer: {
    overflowY: 'scroll',
  },
  isPreviewing: {
    marginTop: theme.spacing(6.5),
  },
}));

function ImageAssetCarousel({ assets, selectedIndex, onSelection }) {
  return (
    <Carousel
      selectedItemIndex={selectedIndex}
      items={assets}
      itemComponent={({ index, style, item }) => {
        return <ShowImage image={item?.images} maxHeight={item?.images?.height} maxWidth={1000} />;
      }}
      onSelection={onSelection}
    />
  );
}

ImageAssetCarousel.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSelection: PropTypes.func,
};

function ImageAssetViewer({ assets, selectedIndex, onSelection, showCarousel = true }) {
  const classes = useStyles();
  return (
    <>
      {showCarousel && (
        <Box px={1}>
          <ImageAssetCarousel assets={assets} selectedIndex={selectedIndex} onSelection={onSelection} />
        </Box>
      )}
      <Box p={1} className={classes.drawerImageContainer}>
        <ShowImage
          image={assets[selectedIndex]?.images}
          maxHeight={assets[selectedIndex]?.images?.height}
          maxWidth={1000}
        />
      </Box>
    </>
  );
}

ImageAssetViewer.propTypes = {
  assets: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSelection: PropTypes.func,
  showCarousel: PropTypes.bool,
};

function ClassicPageViewer({ wevo, selectedIndexPath, onSelection, showCarousel = true }) {
  return (
    <ImageAssetViewer
      assets={wevo?.pages ?? []}
      selectedIndex={selectedIndexPath[0]}
      onSelection={({ index }) => onSelection([index])}
      showCarousel={showCarousel}
    />
  );
}

ClassicPageViewer.propTypes = {
  wevo: PropTypes.object.isRequired,
  selectedAssetPath: PropTypes.arrayOf(PropTypes.number),
  onSelection: PropTypes.func,
  showCarousel: PropTypes.bool,
};

function JourneyStepViewer({ page, selectedIndexPath, onSelection }) {
  if (!page) {
    return <></>;
  }

  return (
    <ImageAssetViewer
      assets={page?.steps ?? []}
      selectedIndex={selectedIndexPath[0]}
      onSelection={({ index }) => onSelection([index])}
    />
  );
}

JourneyStepViewer.propTypes = {
  page: PropTypes.object.isRequired,
  selectedAssetPath: PropTypes.arrayOf(PropTypes.number),
  onSelection: PropTypes.func,
};

function JourneyPageStepViewer({ wevo, selectedIndexPath, onSelection }) {
  const page = wevo?.pages?.[selectedIndexPath[0]];

  if (!page) {
    return <></>;
  }

  return (
    <>
      <Tabs
        value={selectedIndexPath[0]}
        indicatorColor="primary"
        textColor="primary"
        onChange={(_, newValue) => onSelection([newValue, 0])}>
        {(wevo?.pages ?? []).map((page, index) => (
          <Tab key={index} label={`Journey ${page.label}`} />
        ))}
      </Tabs>
      <ImageAssetViewer
        assets={page?.steps ?? []}
        selectedIndex={selectedIndexPath[1]}
        onSelection={({ index }) => onSelection([selectedIndexPath[0], index])}
      />
    </>
  );
}

JourneyPageStepViewer.propTypes = {
  wevo: PropTypes.object.isRequired,
  selectedAssetPath: PropTypes.arrayOf(PropTypes.number),
  onSelection: PropTypes.func,
};

export default function RightDrawer({ open, setOpen, width, wevo }) {
  const classes = useStyles({ width });
  const theme = useTheme();

  const isJourney = useMemo(() => wevo?.type === WevoType.Journey, [wevo]);
  const isCompare = useMemo(() => wevo?.testType === WevoTestType.Compare, [wevo]);

  // tuple is used to model asset hierarchies
  const [selectedAssetPath, setSelectedAssetPath] = useState(
    wevo?.type === WevoType.Journey && wevo?.testType === WevoTestType.Compare ? [0, 0] : [0]
  );

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const assetViewer = useMemo(() => {
    // Journey Compare
    if (isJourney && isCompare) {
      return (
        <JourneyPageStepViewer
          wevo={wevo}
          selectedIndexPath={selectedAssetPath}
          onSelection={setSelectedAssetPath}
        />
      );
    }

    if (isJourney && !isCompare) {
      return (
        <JourneyStepViewer
          page={wevo?.pages?.[0]}
          selectedIndexPath={selectedAssetPath}
          onSelection={setSelectedAssetPath}
        />
      );
    }

    return (
      <ClassicPageViewer
        wevo={wevo}
        selectedIndexPath={selectedAssetPath}
        onSelection={setSelectedAssetPath}
        showCarousel={isCompare}
      />
    );
  }, [isCompare, isJourney, selectedAssetPath, setSelectedAssetPath, wevo]);

  let hideText = 'Hide Experience';

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}>
      <Toolbar />
      <div
        className={classNames(classes.drawerHeader, {
          [classes.isPreviewing]: wevo?.isPreviewing,
        })}>
        <Tooltip title={open ? hideText : ''} placement="left">
          <IconButton onClick={handleDrawerClose} size="large">
            {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </Tooltip>
      </div>
      <Divider />
      {assetViewer}
    </Drawer>
  );
}

RightDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  wevo: PropTypes.object.isRequired,
};
