import { useMutation } from 'react-query';
import axios from '../../../api';

const createSessionAsset = async ({ automatedInsightSessionId, url }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/${automatedInsightSessionId}/assets`,
    method: 'POST',
    data: { url },
  });

  return response?.data?.automatedInsightSessionAsset;
};

export function useCreateSessionAssetFromURL() {
  return useMutation(createSessionAsset);
}

const replaceSessionAsset = async ({ automatedInsightSessionId, automatedInsightSessionAssetId, url }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/${automatedInsightSessionId}/assets/${automatedInsightSessionAssetId}`,
    method: 'PUT',
    data: { url },
  });

  return response?.data?.automatedInsightSession;
};

export function useReplaceSessionAsset() {
  return useMutation(replaceSessionAsset);
}

const reorderSessionAsset = async ({
  automatedInsightSessionId,
  automatedInsightSessionAssetId,
  sortOrder,
}) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/${automatedInsightSessionId}/assets/${automatedInsightSessionAssetId}/reorder`,
    method: 'PUT',
    data: { sortOrder },
  });

  return response?.data?.automatedInsightSession;
};

export function useReordereSessionAsset() {
  return useMutation(reorderSessionAsset);
}

const deleteSessionAsset = async ({ automatedInsightSessionId, automatedInsightSessionAssetId }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/${automatedInsightSessionId}/assets/${automatedInsightSessionAssetId}`,
    method: 'DELETE',
  });

  return response?.data?.deleted;
};

export function useDeleteSessionAsset() {
  return useMutation(deleteSessionAsset);
}
