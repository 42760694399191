import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

const uploadAsset = async ({ file, files, name, deviceId, type }) => {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  if (files) {
    files.forEach((file) => formData.append('files', file));
  }

  formData.append('name', name);
  formData.append('deviceId', deviceId);

  if (type) {
    // this gets defaulted to 'page' if not provided
    formData.append('type', type);
  }

  const response = await axios({
    url: '/api/v2/automated-insight-sessions/images',
    method: 'POST',
    data: formData,
  });

  return response?.data?.automatedInsightSession;
};

export default function useUploadAsset() {
  const queryClient = useQueryClient();
  return useMutation(uploadAsset, {
    mutationKey: 'uploadAsset',
    onSuccess: (automatedInsightSession) => {
      queryClient.setQueryData('automatedInsightSession', automatedInsightSession);
    },
  });
}
