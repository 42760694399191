import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { ReactComponent as DiagnosticsIcon } from '../../../assets/diagnostics-dynamic.svg';
import { Paths } from '../../../routes';
import LeftNavItem from './LeftNavItem';

const useStyles = makeStyles((theme) => ({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
}));

const ConceptScoreLeftNavButton = ({ wevo, selected }) => {
  const classes = useStyles();
  const wevoId = wevo?.id;

  return (
    <LeftNavItem
      icon={<DiagnosticsIcon className={classes.svg} />}
      label="Concept Score"
      path={generatePath(Paths.reports.conceptScore, { wevoId })}
      selected={selected}
    />
  );
};

ConceptScoreLeftNavButton.propTypes = {
  wevo: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default ConceptScoreLeftNavButton;
