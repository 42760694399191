export const BIN_COLORS = ['#fc5f55', '#fc9e96', 'auto', '#9cddf9', '#5fc9f8'];
export const SCORE_BIN_COLORS = [
  'rgba(252,180,0, 1.0)',
  'rgba(252,180,0, 0.6)',
  'rgba(151,193,92, 0.6)',
  'rgba(151,193,92, 1.0)',
];
export const PAGE_COLORS = ['#00CCFF', '#ff8f00', '#0382D7', '#7e57c2', '#fc5f55', '#E4BD0B'];
export const ComparePageMode = {
  Score: 'score',
  Percentage: 'percentage',
};
