import { useCallback, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { getSelectionBoundingClientRect } from '../../modules/plate/constants';

const virtualReference = {
  getBoundingClientRect() {
    return {
      top: -9999,
      left: -9999,
      bottom: 9999,
      right: 9999,
      width: 90,
      height: 10,
      x: 0,
      y: 0,
      toJSON: () => null,
    };
  },
};

const usePopperPosition = ({
  popperElement,
  popperContainer = document,
  popperOptions = {},
  modifiers = [],
  offset = [0, 0],
  placement = 'auto',
  isHidden = false,
  getBoundingClientRect = getSelectionBoundingClientRect,
}) => {
  const popperResult = usePopper(virtualReference, popperElement, {
    placement,
    modifiers: [
      // default modifiers to position the popper correctly
      {
        name: 'preventOverflow',
        enabled: true,
        options: { boundary: popperContainer },
      },
      {
        name: 'flip',
        enabled: true,
        options: { padding: 8 },
      },
      {
        name: 'eventListeners',
        enabled: true,
        options: { scroll: !isHidden, resize: true },
      },
      {
        name: 'offset',
        options: {
          offset,
        },
      },
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false,
        },
      },
      // user modifiers to override the default
      ...modifiers,
    ],
    strategy: 'absolute',
    ...popperOptions,
  });

  const { update, state } = popperResult;
  const isReady = !isHidden && !!state;

  const styles = isReady
    ? popperResult.styles
    : {
        ...popperResult.styles,
        popper: { ...popperResult.styles.popper, display: 'none' },
      };

  const updatePosition = useCallback(() => {
    if (isHidden) return;
    if (!popperElement) return;

    virtualReference.getBoundingClientRect = getBoundingClientRect;
    update?.();
  }, [getBoundingClientRect, isHidden, popperElement, update]);

  useEffect(() => {
    updatePosition();
  }, [updatePosition]);

  useEffect(() => {
    popperContainer?.addEventListener('scroll', updatePosition);
    return () => popperContainer?.removeEventListener('scroll', updatePosition);
  }, [updatePosition, popperContainer]);

  return { ...popperResult, update: updatePosition, styles };
};

export default usePopperPosition;
