import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
// import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function LinearDeterminateProgressBar() {
  const classes = useStyles();
  // const [completed, setCompleted] = React.useState(50);
  // const completed = 30;

  return (
    <div className={classes.root}>
      <CircularProgress />
      {/* <LinearProgress variant="determinate" value={completed} /> */}
    </div>
  );
}
