import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _, { debounce as _debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CustomScreenerTypes } from '../../../modules/intake/constants';
import { snackbar } from '../../../notifications';
import useDeleteCustomScreener from '../hooks/useDeleteCustomScreener';
import useUpdateCustomScreener from '../hooks/useUpdateCustomScreener';
import CustomScreenerType from './CustomScreenerType';
import DeleteCustomQuestionDialog from './DeleteCustomQuestionDialog';

const styles = {
  root: {
    paddingTop: (theme) => theme.spacing(5),
  },
  infoIcon: {
    marginLeft: (theme) => theme.spacing(1),
    verticalAlign: 'bottom',
    color: grey[600],
  },
  deleteIcon: {
    color: 'white',
    verticalAlign: 'bottom',
    fontSize: '20px',
  },
  deleteText: {
    color: 'white',
    fontSize: '13px',
  },
  delete: {
    cursor: 'pointer',
    marginTop: '20px',
  },
  section: {
    marginTop: (theme) => theme.spacing(2),
  },
  text: {
    marginTop: (theme) => theme.spacing(2),
  },
  pagesRadioButtons: {
    marginTop: '10px',
    flexDirection: 'column',
  },
  radioButtonLabel: {
    marginLeft: (theme) => theme.spacing(2),
  },
  reorderIcon: {
    verticalAlign: 'bottom',
    marginRight: '10px',
  },
  tooltip: {
    fontSize: '20px',
  },
};

const CustomScreener = ({ wevoId, filterQuestion, questionNumber, dragHandleProps, onQuestionChange }) => {
  const [questionText, setQuestionText] = useState(filterQuestion?.questionText ?? '');
  const [questionName, setQuestionName] = useState(filterQuestion?.name ?? '');
  const [isQuestionExpanded, setIsQuestionExpanded] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [localErrors, setLocalErrors] = useState({});

  const { mutate: updateCustomScreener } = useUpdateCustomScreener();
  const { mutate: deleteCustomScreener, isLoading: isDeleting } = useDeleteCustomScreener();

  useEffect(() => {
    if (!_.isNil(filterQuestion) && _.isEmpty(localErrors)) {
      const initialErrors = {};
      if (filterQuestion?.name?.length === 0) {
        initialErrors.name = 'Please enter a question name.';
      } else {
        initialErrors.name = '';
      }
      if (filterQuestion?.questionText?.length === 0) {
        initialErrors.questionText = 'Please enter a question.';
      } else {
        initialErrors.questionText = '';
      }
      setLocalErrors(initialErrors);
      onQuestionChange({
        id: filterQuestion?.id,
        errors: {
          name: filterQuestion?.questionText?.length === 0,
          questionText: filterQuestion?.questionText?.length === 0,
        },
      });
    }
  }, [filterQuestion, localErrors, onQuestionChange]);

  const updateQuestionName = useMemo(
    () =>
      _debounce((newQuestionName) => {
        const changes = { name: newQuestionName };

        updateCustomScreener(
          { wevoId, filterId: filterQuestion?.id, changes },
          {
            onError: (err, variables, { previousCustomQuestionsState }) => {
              const index = previousCustomQuestionsState?.findIndex(
                (q) => String(q.id) === String(filterQuestion?.id)
              );
              if (index >= 0) {
                const prevName = previousCustomQuestionsState?.[index]?.name ?? '';
                setQuestionName(prevName);
              }

              snackbar.error(
                err?.response?.data?.humanReadableMessage ?? 'Error updating custom screener name'
              );
            },
          }
        );
      }, 1000),
    [wevoId, updateCustomScreener, filterQuestion?.id]
  );

  const updateQuestionText = useMemo(
    () =>
      _debounce((newQuestionText) => {
        const changes = { questionText: newQuestionText };

        updateCustomScreener(
          { wevoId, filterId: filterQuestion?.id, changes },
          {
            onError: (err, variables, { previousCustomQuestionsState }) => {
              const index = previousCustomQuestionsState?.findIndex(
                (q) => String(q.id) === String(filterQuestion?.id)
              );
              if (index >= 0) {
                const prevQuestionText = previousCustomQuestionsState?.[index]?.questionText ?? '';
                setQuestionText(prevQuestionText);
              }

              snackbar.error(
                err?.response?.data?.humanReadableMessage ?? 'Error updating custom screener question'
              );
            },
          }
        );
      }, 1000),
    [wevoId, updateCustomScreener, filterQuestion?.id]
  );

  const handleQuestionNameChange = useCallback(
    (ev) => {
      const newQuestionName = ev.target.value;
      setQuestionName(newQuestionName);
      updateQuestionName(newQuestionName);

      const nameIsValid = newQuestionName.length > 0;

      if (!nameIsValid && !localErrors?.name) {
        setLocalErrors((prevLocalErrors) => ({ ...prevLocalErrors, name: 'Please enter a question name.' }));
      } else if (nameIsValid && localErrors?.name) {
        setLocalErrors((prevLocalErrors) => ({ ...prevLocalErrors, name: '' }));
      }
      onQuestionChange({ id: filterQuestion?.id, errors: { name: !nameIsValid } });
    },
    [updateQuestionName, localErrors?.name, filterQuestion?.id, onQuestionChange]
  );

  const handleQuestionTextChange = useCallback(
    (ev) => {
      const newQuestionText = ev.target.value;
      setQuestionText(newQuestionText);
      updateQuestionText(newQuestionText);

      const questionTextIsValid = newQuestionText.length > 0;

      if (!questionTextIsValid && !localErrors?.questionText) {
        setLocalErrors((prevLocalErrors) => ({
          ...prevLocalErrors,
          questionText: 'Please enter a question.',
        }));
      } else if (questionTextIsValid && localErrors?.questionText) {
        setLocalErrors((prevLocalErrors) => ({ ...prevLocalErrors, questionText: '' }));
      }
      onQuestionChange({ id: filterQuestion?.id, errors: { questionText: !questionTextIsValid } });
    },
    [updateQuestionText, localErrors?.questionText, filterQuestion?.id, onQuestionChange]
  );

  const handleDeleteCustomScreenerClick = () => {
    deleteCustomScreener(
      { wevoId, filterId: filterQuestion?.id },
      {
        onSuccess: () => {
          onQuestionChange({
            id: filterQuestion?.id,
            errors: {
              name: false,
              questionText: false,
              options: false,
              outcomes: false,
            },
          });
        },
        onError: (err) => {
          snackbar.error(
            err?.response?.data?.humanReadableMessage ?? 'Error deleting custom screener question'
          );
        },
      }
    );
  };

  const handleExpandChange = () => {
    setIsQuestionExpanded(!isQuestionExpanded);
  };

  const toggleShowDeleteDialog = () => {
    setShowDeleteDialog(!showDeleteDialog);
  };

  return (
    <Accordion expanded={isQuestionExpanded} onChange={handleExpandChange}>
      <AccordionSummary
        {...dragHandleProps}
        expandIcon={isQuestionExpanded ? <RemoveIcon fontSize="small" /> : <AddIcon fontSize="small" />}
        aria-controls="Custom-Screener"
        id={`Custom-Screener-${questionNumber}`}
        sx={{ backgroundColor: (theme) => (isQuestionExpanded ? theme.palette.primary.main : '') }}>
        <Typography variant="h5" sx={{ color: isQuestionExpanded ? 'white' : 'black' }}>
          <DragIndicatorIcon fontSize="small" sx={styles.reorderIcon} />
          {`Custom Screener ${questionNumber}`}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} direction="row">
          <Grid item sx={styles.section} xs={12}>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={questionName || ''}
              label="Question Name"
              onChange={handleQuestionNameChange}
              type="text"
              inputProps={{ maxLength: '50' }} // Allows up to 50 characters for a custom question name
              name="customScreenerName"
              autoComplete="off"
              sx={styles.text}
              error={Boolean(localErrors?.name)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
              helperText={
                localErrors?.name ||
                'This name will be used to identify your question throughout the report. Max. 50 characters.'
              }
            />
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={questionText || ''}
              label="Question Text"
              onChange={handleQuestionTextChange}
              type="text"
              inputProps={{ maxLength: '1000' }} // Allows up to 1,000 characters for a custom question
              name="customScreener"
              autoComplete="off"
              sx={styles.text}
              error={Boolean(localErrors?.questionText)}
              InputLabelProps={{ shrink: true }}
              SelectProps={{ displayEmpty: true }}
              helperText={
                localErrors?.questionText ||
                `Enter your custom question.${
                  filterQuestion?.questionType === CustomScreenerTypes.MultiSelect
                    ? ' We will include instructions for how respondents should answer your question.'
                    : ''
                }`
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CustomScreenerType
              wevoId={wevoId}
              question={filterQuestion}
              onQuestionChange={onQuestionChange}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              size="medium"
              onClick={() => toggleShowDeleteDialog()}
              disabled={isDeleting}
              sx={styles.delete}
              startIcon={isDeleting ? <CircularProgress size={16} /> : <DeleteIcon sx={styles.deleteIcon} />}>
              {isDeleting ? (
                <Typography sx={{ ...styles.deleteText, color: 'black' }}>{'Deleting'}</Typography>
              ) : (
                <Typography sx={styles.deleteText}>{'Delete'}</Typography>
              )}
            </Button>
          </Grid>
          <DeleteCustomQuestionDialog
            open={showDeleteDialog}
            deleteCustomQuestion={handleDeleteCustomScreenerClick}
            closeCallback={toggleShowDeleteDialog}
            isScreenerQuestion={true}
          />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CustomScreener;
