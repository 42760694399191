import _ from 'lodash';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import PeopleIcon from '@mui/icons-material/People';
import ShareIcon from '@mui/icons-material/Share';
import { Divider, Drawer, List, ListItemButton, styled, Toolbar, Tooltip } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link, useRouteMatch } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as CustomQuestionsIcon } from '../../../assets/custom-questions.svg';
import { ReactComponent as EmotionWordsIcon } from '../../../assets/emotion-words.svg';
import { ReactComponent as FeedbackIcon } from '../../../assets/feedback.svg';
import { ReactComponent as StayOrGoIcon } from '../../../assets/stay-or-go.svg';
import { MetricTypes } from '../../../modules/intake/constants';
import { reportLeftNavWidth } from '../../../modules/left-nav/constants';
import { selectIsLeftNavOpen } from '../../../modules/left-nav/leftNavSlice';
import { LeftNavLevelType, SHARE_REPORT_TYPE } from '../../../modules/report/constants';
import { ComponentType, showEmotionWords, WevoTestType, WevoType } from '../../../modules/wevos/constants';
import { hasComponent } from '../../../modules/wevos/helpers';
import { Paths } from '../../../routes';
import { TrackEvent } from '../../analytics';
import useFetchCustomQuestions from '../../intake/hooks/useFetchCustomQuestions';
import DownloadReportToPowerPointModal from '../components/DownloadReportToPowerPointModal';
import useDownloadResults from '../hooks/useDownloadResults';
import ComparePageLeftNavButton from './ComparePageLeftNavButton';
import ConceptScoreLeftNavButton from './ConceptScoreLeftNavButton';
import DashboardLeftNavButton from './DashboardLeftNavButton';
import DiagnosticsLeftNavButton from './DiagnosticsLeftNavButton';
import ExpectationsLeftNavButton from './ExpectationsLeftNavButton';
import ExperienceMapLeftNavButton from './ExperienceMapLeftNavButton';
import ExperienceScoreLeftNavButton from './ExperienceScoreLeftNavButton';
import KeyFindingsLeftNavButton from './KeyFindingsLeftNavButton';
import LeftNavItem from './LeftNavItem';
import SentimentMapLeftNavButton from './SentimentMapLeftNavButton';

const openedMixin = (theme, width) => ({
  width: width,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  width: theme.spacing(9),
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

const paperMixin = (theme) => ({
  paddingLeft: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
  borderInlineEnd: 'none',
  maxHeight: '100vh',
  overflowX: 'hidden',
  msOverflowStyle: 'none', // IE and edge
  scrollbarWidth: 'none', // firefox
  '&::-webkit-scrollbar': {
    display: 'none', // chrome and safari
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'width',
})(({ theme, open, width }) => ({
  width: width,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, width),
    '& .MuiDrawer-paper': { ...paperMixin(theme), ...openedMixin(theme, width) },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': { ...paperMixin(theme), ...closedMixin(theme) },
  }),
}));

const StyledItemButton = styled(ListItemButton)(({ theme, selected, showSubItems }) => ({
  color: selected ? theme.palette.primary.main : 'white',
  borderTopLeftRadius: theme.spacing(2),
  borderBottomLeftRadius: showSubItems && selected ? 0 : theme.spacing(2),
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  position: 'relative',
  width: '80%',
  alignSelf: 'center',
  marginTop: 'auto',
  marginBottom: theme.spacing(1),
  left: theme.spacing(-0.5),
  backgroundColor: theme.palette.primary.light,
}));

function generateItemHierarchy(levels, items) {
  if (!levels || levels.length === 0) {
    return [];
  }

  if (levels.length === 1) {
    const levelKey = levels[0].level;
    return items.map((item) => ({
      type: LeftNavLevelType.Link,
      level: levelKey,
      levelId: String(item?.[levelKey]), // always a string for consistency with _.groupBy
      itemNumber: item?.[levelKey],
      ...item,
    }));
  }

  const level = levels[0];
  const levelKey = level.level;
  const levelGroups = _.groupBy(items, levelKey);

  return Object.keys(levelGroups).map((levelGroupKey) => {
    return {
      type: LeftNavLevelType.Level,
      level: levelKey,
      levelId: levelGroupKey, // always a string because of _.groupBy
      itemNumber: levelGroups[levelGroupKey][0]?.[levelKey],
      name: levelGroups[levelGroupKey][0]?.[level?.name],
      label: levelGroups[levelGroupKey][0]?.[level?.label],
      items: generateItemHierarchy(levels.slice(1, levels.length), levelGroups[levelGroupKey]),
    };
  });
}

const LeftNav = ({
  wevo,
  handleShareButtonClick,
  isLimitedReport,
  isLimitedDashboard,
  isLimitedSentimentMap,
  isLimitedExperienceMap,
}) => {
  const isDqs = wevo?.metricType === MetricTypes.MastercardDqs;
  const isCds = wevo?.metricType === MetricTypes.MastercardCds;
  const isOpen = useSelector(selectIsLeftNavOpen);
  const { mutate: downloadResults, isLoading: isDownloading } = useDownloadResults();
  const { data: groups = [] } = useFetchCustomQuestions(wevo?.id);
  const { track } = useAnalytics();
  const [openDownloadReportToPP, setOpenDownloadReportToPP] = useState(false);

  const dashboardMatch = useRouteMatch({
    path: [Paths.reports.dashboard, Paths.reports.dashboardPage, ...Paths.reports.LimitedDashboardPaths],
    exact: true,
  });
  const takeawaysMatch = useRouteMatch({
    path: [Paths.reports.takeaways, Paths.reports.takeawaysPage],
    exact: true,
  });
  const sentimentMapMatch = useRouteMatch({
    path: [...Paths.reports.SentimentMapPaths, ...Paths.reports.LimitedSentimentMapPaths],
    exact: true,
  });
  const experienceMapMatch = useRouteMatch({
    path: [
      Paths.reports.experienceMap,
      Paths.reports.experienceMapPage,
      Paths.reports.limitedExperienceMap,
      Paths.reports.limitedExperienceMapPage,
    ],
    exact: true,
  });
  const expectationsMatch = useRouteMatch({
    path: [Paths.reports.expectations, Paths.reports.expectationsPage],
    exact: true,
  });
  const diagnosticsMatch = useRouteMatch({
    path: [Paths.reports.diagnostics, Paths.reports.diagnosticsPage],
    exact: true,
  });
  const experienceScoreMatch = useRouteMatch({
    path: Paths.reports.experienceScore,
    exact: true,
  });
  const conceptScoreMatch = useRouteMatch({
    path: Paths.reports.conceptScore,
    exact: true,
  });
  const stayOrGoMatch = useRouteMatch({
    path: [Paths.reports.stayGo, Paths.reports.stayGoPage, Paths.reports.stayGoPageStep],
    exact: true,
  });
  const emotionWordsMatch = useRouteMatch({
    path: [Paths.reports.emotionWords, Paths.reports.emotionWordsPage, Paths.reports.emotionWordsPageStep],
    exact: true,
  });
  const questionDetailsMatch = useRouteMatch({ path: Paths.reports.questionDetails });
  const compareMatch = useRouteMatch({ path: Paths.reports.compare, exact: true });
  const getPageNumFromUrl = useCallback(() => {
    const routes = [
      sentimentMapMatch,
      stayOrGoMatch,
      diagnosticsMatch,
      expectationsMatch,
      takeawaysMatch,
      dashboardMatch,
      experienceMapMatch,
    ];
    for (const routeMatch of routes) {
      if (routeMatch) {
        return routeMatch?.params?.pageNum || 1;
      }
    }
    return 1;
  }, [
    diagnosticsMatch,
    expectationsMatch,
    sentimentMapMatch,
    stayOrGoMatch,
    takeawaysMatch,
    dashboardMatch,
    experienceMapMatch,
  ]);

  const getStepNumFromUrl = useCallback(() => {
    const routes = [sentimentMapMatch, stayOrGoMatch];
    for (const routeMatch of routes) {
      if (routeMatch) {
        return routeMatch?.params?.stepNum || 1;
      }
    }
    return 1;
  }, [sentimentMapMatch, stayOrGoMatch]);

  const [selectedPageNum, setSelectedPageNum] = useState(getPageNumFromUrl());
  const [selectedStepNum, setSelectedStepNum] = useState(getStepNumFromUrl());
  const [selectedGroupNum, setSelectedGroupNum] = useState(
    Number(questionDetailsMatch?.params?.groupNum ?? 1)
  );

  const selectedSubItem = useMemo(() => {
    return { pageNumber: selectedPageNum, stepNumber: selectedStepNum, groupNumber: selectedGroupNum };
  }, [selectedPageNum, selectedStepNum, selectedGroupNum]);

  const handleSelectedSubItem = useCallback(
    ({ pageNumber, stepNumber, groupNumber }) => {
      setSelectedPageNum(pageNumber);
      setSelectedStepNum(stepNumber);
      setSelectedGroupNum(groupNumber);
    },
    [setSelectedPageNum, setSelectedStepNum, setSelectedGroupNum]
  );

  useEffect(() => {
    if (
      sentimentMapMatch ||
      stayOrGoMatch ||
      (wevo?.testType === WevoTestType.Compare &&
        (diagnosticsMatch || expectationsMatch || takeawaysMatch || dashboardMatch || experienceMapMatch))
    ) {
      const pageNumFromUrl = getPageNumFromUrl();

      if (pageNumFromUrl !== selectedPageNum) {
        setSelectedPageNum(pageNumFromUrl);
      }
      const stepNumFromUrl = getStepNumFromUrl();

      if (stepNumFromUrl !== selectedStepNum) {
        setSelectedStepNum(stepNumFromUrl);
      }
    }
  }, [
    diagnosticsMatch,
    expectationsMatch,
    experienceMapMatch,
    getPageNumFromUrl,
    selectedPageNum,
    setSelectedPageNum,
    getStepNumFromUrl,
    selectedStepNum,
    setSelectedStepNum,
    sentimentMapMatch,
    stayOrGoMatch,
    takeawaysMatch,
    dashboardMatch,
    wevo?.testType,
  ]);

  useEffect(() => {
    if (Number(questionDetailsMatch?.params?.groupNum ?? 1) !== selectedGroupNum) {
      setSelectedGroupNum(Number(questionDetailsMatch?.params?.groupNum ?? 1));
    }
  }, [selectedGroupNum, questionDetailsMatch?.params?.groupNum]);

  const handleTestDetailsClick = () => {
    track(TrackEvent.VIEWED_REPORT_TEST_DETAILS, { wevoId: wevo?.analyticsId, testType: wevo?.type });
  };

  const handleDownloadClick = () => {
    if (!isDownloading) {
      downloadResults(wevo);
    }
  };

  const handleFeedbackButtonClick = () => {
    track(TrackEvent.CLICKED_FEEDBACK_BUTTON);
  };

  const handleDownloadReportToPPClicked = () => {
    track(TrackEvent.DOWNLOADED_REPORT_TO_POWER_POINT, { wevoId: wevo?.analyticsId, testType: wevo?.type });
    setOpenDownloadReportToPP(true);
  };

  const handleClose = () => {
    setOpenDownloadReportToPP(false);
  };

  const generateAssetPath = useCallback(
    (path, { pageNumber: pageNum, stepNumber: stepNum }) => {
      return generatePath(path, { wevoId: wevo?.id, pageNum, stepNum });
    },
    [wevo?.id]
  );

  const generateQuestionDetailsPath = useCallback(
    (path, { groupNumber: groupNum }) => {
      let pathParams = { wevoId: wevo?.id };
      pathParams = { ...pathParams, groupNum };
      return generatePath(path, pathParams);
    },
    [wevo?.id]
  );

  const isJourney = wevo?.type === WevoType.Journey;
  const hasExperience = wevo?.hasExperience;
  const drawerWidth = reportLeftNavWidth;

  const pageAssets = useMemo(
    () =>
      (wevo?.pages ?? []).map((page, index) => ({
        id: page.id,
        label: page.label,
        name: page.name,
        pageNumber: index + 1,
        stepNumber: 1,
        groupNumber: 1,
      })),
    [wevo]
  );

  const stepAssets = useMemo(
    () =>
      (wevo?.pages ?? []).flatMap((page, pageIndex) => {
        return (page?.steps ?? []).map((step, stepIndex) => ({
          id: step.id,
          name: step.name,
          label: step.label,
          pageId: page.id,
          pageName: page.name,
          pageLabel: page.label,
          pageNumber: pageIndex + 1,
          stepNumber: stepIndex + 1,
          groupNumber: 1,
        }));
      }),
    [wevo]
  );

  const pageLevels = useMemo(() => {
    return generateItemHierarchy([{ level: 'pageNumber' }], pageAssets);
  }, [pageAssets]);

  const stepLevels = useMemo(() => {
    return generateItemHierarchy([{ level: 'stepNumber' }], stepAssets);
  }, [stepAssets]);

  const pageStepLevels = useMemo(() => {
    return generateItemHierarchy(
      [{ level: 'pageNumber', name: 'pageName', label: 'pageLabel' }, { level: 'stepNumber' }],
      stepAssets
    );
  }, [stepAssets]);

  const groupLevels = useMemo(() => {
    return generateItemHierarchy([{ level: 'groupNumber' }], groups);
  }, [groups]);

  return (
    <StyledDrawer variant="permanent" open={isOpen} width={drawerWidth}>
      <Toolbar />
      <List sx={{ marginTop: wevo?.isPreviewing && 6.5 }}>
        {wevo?.testType === WevoTestType.Compare && !isLimitedReport && (
          <ComparePageLeftNavButton wevo={wevo} selected={Boolean(compareMatch)} />
        )}
        {!isLimitedSentimentMap && !isLimitedExperienceMap && (
          <DashboardLeftNavButton
            wevo={wevo}
            selected={Boolean(dashboardMatch)}
            levels={pageLevels}
            subItemPath={Paths.reports.dashboardPage}
            generateSubItemPath={generateAssetPath}
            selectedSubItem={selectedSubItem}
            setSelectedSubItem={handleSelectedSubItem}
            isLimitedReport={isLimitedReport}
          />
        )}
        {!isLimitedReport && hasComponent(wevo, ComponentType.KeyFinding) && (
          <KeyFindingsLeftNavButton
            wevo={wevo}
            selected={Boolean(takeawaysMatch)}
            levels={pageLevels}
            selectedSubItem={selectedSubItem}
            setSelectedSubItem={handleSelectedSubItem}
            generateSubItemPath={generateAssetPath}
          />
        )}
        {!isLimitedReport && hasComponent(wevo, ComponentType.Expectation) && (
          <ExpectationsLeftNavButton
            wevo={wevo}
            selected={Boolean(expectationsMatch)}
            levels={pageLevels}
            selectedSubItem={selectedSubItem}
            setSelectedSubItem={handleSelectedSubItem}
            generateSubItemPath={generateAssetPath}
          />
        )}
        {!isDqs && !isCds && !isLimitedReport && hasComponent(wevo, ComponentType.Diagnostic) && (
          <DiagnosticsLeftNavButton
            wevo={wevo}
            selected={Boolean(diagnosticsMatch)}
            levels={pageLevels}
            selectedSubItem={selectedSubItem}
            setSelectedSubItem={handleSelectedSubItem}
            generateSubItemPath={generateAssetPath}
          />
        )}
        {isDqs && !isLimitedReport && (
          <ExperienceScoreLeftNavButton wevo={wevo} selected={Boolean(experienceScoreMatch)} />
        )}
        {isCds && !isLimitedReport && (
          <ConceptScoreLeftNavButton wevo={wevo} selected={Boolean(conceptScoreMatch)} />
        )}
        {isJourney && !isLimitedReport && showEmotionWords(wevo) && (
          <LeftNavItem
            levels={stepLevels}
            icon={<EmotionWordsIcon style={{ width: 'inherit', height: 'inherit' }} />}
            label="Emotion Words"
            path={generatePath(Paths.reports.emotionWordsPageStep, {
              wevoId: wevo?.id,
              pageNum: selectedPageNum,
              stepNum: selectedStepNum,
            })}
            generateSubItemPath={generateAssetPath}
            selected={Boolean(emotionWordsMatch)}
            selectedSubItem={selectedSubItem}
            setSelectedSubItem={handleSelectedSubItem}
            subItemPath={Paths.reports.emotionWordsPageStep}
          />
        )}
        {isJourney && hasExperience && !isLimitedDashboard && (
          <ExperienceMapLeftNavButton
            wevo={wevo}
            selected={Boolean(experienceMapMatch)}
            isLimitedReport={isLimitedReport}
            selectedSubItem={selectedSubItem}
            setSelectedSubItem={handleSelectedSubItem}
          />
        )}
        {!hasExperience && !isLimitedDashboard && hasComponent(wevo, ComponentType.Heatmap) && (
          <SentimentMapLeftNavButton
            wevo={wevo}
            selected={Boolean(sentimentMapMatch)}
            levels={
              isJourney ? (wevo?.testType === WevoTestType.Compare ? pageStepLevels : stepLevels) : pageLevels
            }
            selectedSubItem={selectedSubItem}
            setSelectedSubItem={handleSelectedSubItem}
            generateSubItemPath={generateAssetPath}
            isLimitedReport={isLimitedReport}
          />
        )}
        {isJourney && !isLimitedReport && hasComponent(wevo, ComponentType.Transition) && (
          <LeftNavItem
            levels={stepLevels}
            generateSubItemPath={generateAssetPath}
            icon={<StayOrGoIcon style={{ width: 'inherit', height: 'inherit' }} />}
            label="Stay or Go"
            path={generatePath(Paths.reports.stayGoPageStep, {
              wevoId: wevo?.id,
              pageNum: selectedPageNum,
              stepNum: selectedStepNum,
            })}
            selected={Boolean(stayOrGoMatch)}
            selectedSubItem={selectedSubItem}
            setSelectedSubItem={handleSelectedSubItem}
            subItemPath={
              wevo?.type === WevoType.Classic ? Paths.reports.stayGoPage : Paths.reports.stayGoPageStep
            }
          />
        )}
        {!isLimitedReport && hasComponent(wevo, ComponentType.CustomQuestion) && (
          <LeftNavItem
            levels={groupLevels}
            icon={<CustomQuestionsIcon style={{ width: 'inherit', height: 'inherit' }} />}
            label="Custom Questions"
            path={generatePath(Paths.reports.questionDetails, {
              wevoId: wevo?.id,
              groupNum: selectedGroupNum,
            })}
            generateSubItemPath={generateQuestionDetailsPath}
            selected={Boolean(questionDetailsMatch)}
            selectedSubItem={{ groupNumber: selectedGroupNum }}
            setSelectedSubItem={({ groupNumber }) => setSelectedGroupNum(groupNumber)}
            subItemPath={Paths.reports.questionDetails}
          />
        )}
      </List>
      {!isLimitedReport && (
        <>
          <StyledDivider />
          <List>
            <Link
              to={generatePath(Paths.reports.testDetails, { wevoId: wevo?.id })}
              style={{ textDecoration: 'none' }}>
              <Tooltip title={!isOpen && 'Test Details'} arrow placement="right">
                <StyledItemButton onClick={handleTestDetailsClick}>
                  <ListItemIcon sx={{ color: 'white' }}>
                    <PeopleIcon />
                  </ListItemIcon>
                  <ListItemText primary="Test Details" />
                </StyledItemButton>
              </Tooltip>
            </Link>
            <Tooltip title={!isOpen && 'Download CSV'} arrow placement="right">
              <StyledItemButton onClick={handleDownloadClick}>
                <ListItemIcon sx={{ color: 'white' }}>
                  <CloudDownloadIcon />
                </ListItemIcon>
                <ListItemText primary="Download CSV" />
              </StyledItemButton>
            </Tooltip>
            {!isDqs && !isCds && (
              <Tooltip title={!isOpen && 'Download PowerPoint'} arrow placement="right">
                <StyledItemButton onClick={handleDownloadReportToPPClicked}>
                  <ListItemIcon sx={{ color: 'white' }}>
                    <CloudDownloadIcon />
                  </ListItemIcon>
                  <ListItemText primary="Download PowerPoint" />
                </StyledItemButton>
              </Tooltip>
            )}
            {openDownloadReportToPP && (
              <DownloadReportToPowerPointModal
                wevo={wevo}
                handleClose={handleClose}
                openDownloadReportToPPModal={openDownloadReportToPP}
                setOpenDownloadReportToPP={setOpenDownloadReportToPP}
                assets={isJourney ? stepAssets : pageAssets}
                isDqs={isDqs}
                isCds={isCds}
              />
            )}
            <Tooltip title={!isOpen && 'Share'} arrow placement="right">
              <StyledItemButton onClick={() => handleShareButtonClick(SHARE_REPORT_TYPE.entireReport)}>
                <ListItemIcon sx={{ color: 'white' }}>
                  <ShareIcon />
                </ListItemIcon>
                <ListItemText primary="Share" />
              </StyledItemButton>
            </Tooltip>
            <Tooltip title={!isOpen && 'Feedback'} arrow placement="right">
              <StyledItemButton
                component="a"
                href="https://docs.google.com/forms/d/1CNQUkRZShuqzGUesCvFw5O83Pp_VnUMJPoaDRL7_WKQ/viewform?edit_requested=true"
                target="_blank"
                rel="noopener noreferrer"
                onClick={handleFeedbackButtonClick}>
                <ListItemIcon>
                  <FeedbackIcon style={{ width: '24px', fill: 'white' }} />
                </ListItemIcon>
                <ListItemText primary="Feedback" />
              </StyledItemButton>
            </Tooltip>
          </List>
        </>
      )}
    </StyledDrawer>
  );
};

LeftNav.propTypes = {
  wevo: PropTypes.object.isRequired,
  handleShareButtonClick: PropTypes.func.isRequired,
  isLimitedReport: PropTypes.bool,
  isLimitedDashboard: PropTypes.bool,
  isLimitedSentimentMap: PropTypes.bool,
};

export default LeftNav;
