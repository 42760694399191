import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserCustomizations } from '../../modules/user/selectors';
import { clearDraft } from '../../modules/wevos/actions';
import { Paths } from '../../routes';

const CreateTestOptions = {
  Wevo: 'Pro',
  Video: 'Video',
};

export function CreatePulseButton() {
  const userCustomizations = useSelector(getUserCustomizations);
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const handleCreateClick = () => {
    window.location.href = `${process.env.REACT_APP_BASE_URL}${Paths.automatedInsights.basePath}`;
  };

  return (
    <Button
      variant="outlined"
      onClick={handleCreateClick}
      sx={{
        backgroundColor: 'white',
        borderWidth: '2px',
        '&:hover': {
          borderWidth: '2px',
        },
      }}>
      {isMastercard ? 'Take a Pulse' : 'New Pulse'}
    </Button>
  );
}

export function CreateTestButton() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const userCustomizations = useSelector(getUserCustomizations);
  const isMastercard = Boolean(userCustomizations?.isMastercard);
  const isUserlytics = Boolean(userCustomizations?.integrations?.userlytics);
  const userlyticsDomain = userCustomizations?.integrations?.userlyticsDomain;
  const userlyticsTeamId = userCustomizations?.integrations?.userlyticsTeamId;
  const userlyticsProjectId = userCustomizations?.integrations?.userlyticsProjectId;

  const handleCreateClick = () => {
    dispatch(clearDraft());

    if (isMastercard) {
      history.push('/landing');
    } else {
      history.push('/wevos/create');
    }
  };

  const handleMenuItemClick = (event, selectedOption) => {
    setOpen(false);

    if (selectedOption === CreateTestOptions.Wevo) {
      handleCreateClick();
    } else if (selectedOption === CreateTestOptions.Video) {
      const userlyticsBaseUrl = `${process.env.REACT_APP_USERLYTICS_HOST}${process.env.REACT_APP_USERLYTICS_SSO_PATH}/${userlyticsDomain}`;
      const userlyticsStudyPath = `/client/teams/${userlyticsTeamId}/projects/${userlyticsProjectId}/study`;
      window.location.href = `${userlyticsBaseUrl}?redirect_uri=${userlyticsStudyPath}`;
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  if (!isUserlytics || isMastercard) {
    return (
      <Button variant="contained" endIcon={<AddCircleOutlinedIcon />} onClick={handleCreateClick}>
        {isMastercard ? 'Run a Study' : 'New Pro'}
      </Button>
    );
  } else {
    return (
      <>
        <ButtonGroup variant="contained" ref={anchorRef}>
          <Button onClick={handleCreateClick}>{isMastercard ? 'Run a Study' : 'New Study'}</Button>
          <Button size="small" onClick={handleToggle}>
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          transition
          disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="create-test-menu" autoFocusItem sx={{ pr: 3 }}>
                    <MenuItem
                      key={`option-create-wevo-${CreateTestOptions.Wevo}`}
                      onClick={(event) => handleMenuItemClick(event, CreateTestOptions.Wevo)}>
                      {isMastercard ? 'WEVO Study' : CreateTestOptions.Wevo}
                    </MenuItem>
                    {isUserlytics && !isMastercard && (
                      <MenuItem
                        key={`option-create-wevo-${CreateTestOptions.Video}`}
                        onClick={(event) => handleMenuItemClick(event, CreateTestOptions.Video)}>
                        {isMastercard ? 'Video Study' : CreateTestOptions.Video}
                      </MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    );
  }
}
