import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import React, { memo } from 'react';
import { PrimerValues } from '../../../../../modules/intake/constants';

const PrimerSelect = ({ showPrimer, allowPrimer, handleShowPrimerSelect }) => {
  return (
    <FormControl variant="standard" component="fieldset">
      <RadioGroup
        aria-labelledby="primer-selection-section-heading"
        name="show-primer"
        value={showPrimer}
        onChange={handleShowPrimerSelect}>
        <Tooltip
          title={!allowPrimer ? 'You must have at least one page to add a primer' : ''}
          placement="right">
          <FormControlLabel
            value={PrimerValues.Yes}
            control={<Radio color="primary" />}
            label="Show a primer"
            disabled={!allowPrimer}
          />
        </Tooltip>
        <FormControlLabel
          value={PrimerValues.No}
          control={<Radio color="primary" />}
          label="Do not show a primer"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default memo(PrimerSelect);
