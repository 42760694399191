import { Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import { yellow } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { Sentiments } from '../../../modules/wevos/quotes';

const PositiveChip = withStyles({
  root: {
    backgroundColor: '#49A84D',
    color: 'white',
  },
})(Chip);

const NegativeChip = withStyles({
  root: {
    backgroundColor: '#C85151',
    color: 'white',
  },
})(Chip);

const MixedChip = withStyles({
  root: {
    backgroundColor: yellow[600],
    color: 'black',
  },
})(Chip);

const styles = makeStyles((theme) => ({
  chip: {
    width: '100%',
    maxWidth: '180px',
    textTransform: 'capitalize',
  },
}));

export const SentimentChip = (props) => {
  const classes = styles(props);
  const { quote } = props;
  const sentiment = quote && quote.sentiment;

  if (!sentiment) {
    return null;
  }

  switch (sentiment) {
    case Sentiments.positive:
      return (
        <Tooltip title="Positive sentiment">
          <PositiveChip className={`${classes.chip} sentiment-chip`} label={sentiment} />
        </Tooltip>
      );
    case Sentiments.negative:
      return (
        <Tooltip title="Negative sentiment">
          <NegativeChip className={`${classes.chip} sentiment-chip`} label={sentiment} />
        </Tooltip>
      );
    case Sentiments.mixed:
    case Sentiments.neutral:
      return (
        <Tooltip title="Neutral sentiment">
          <MixedChip className={`${classes.chip} sentiment-chip`} label={sentiment} />
        </Tooltip>
      );
    default:
      return <Chip className={`${classes.chip} sentiment-chip`} label={sentiment} />;
  }
};

export const EmotionWordChip = (props) => {
  const classes = styles(props);
  const { quote } = props;

  if (!quote.word) {
    return null;
  }

  return <Chip className={classes.chip} label={quote && quote.word} />;
};

export const DiagnosticChip = (props) => {
  const classes = styles(props);
  const { quote } = props;

  if (!quote.diagnostic) {
    return null;
  }

  return (
    <Tooltip title={quote.diagnostic}>
      <Chip className={classes.chip} label={quote && quote.diagnostic} />
    </Tooltip>
  );
};

export const RatingChip = (props) => {
  const classes = styles(props);
  const { label } = props;

  if (!label) {
    return null;
  }

  return (
    <Tooltip title={label}>
      <Chip className={classes.chip} label={label} />
    </Tooltip>
  );
};

export const TransitionTypeChip = (props) => {
  const classes = styles(props);
  const { quote } = props;

  if (!quote.type) {
    return null;
  }

  return <Chip className={classes.chip} label={quote && quote.type} />;
};
