import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * A dialog to allow the user to confirm if they wish to discard their current metric
 * and therefore discard all of their current asssets (steps or pages)
 * @param {{open: boolean, newMetricName: string, currentMetricName: string, switchMetric: function, closeCallback: function}} props
 * @returns The discard test type dialog component
 */
const DiscardMetricDialog = ({ open, newMetricName, currentMetricName, switchMetric, closeCallback }) => {
  const handleClose = () => {
    !!closeCallback && closeCallback();
  };

  const handleReset = () => {
    !!switchMetric && switchMetric();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="discard metric confirmation dialog"
        aria-describedby="discard-metric-confirmation-description">
        <DialogTitle>Switching UX Metric</DialogTitle>
        <DialogContent>
          <DialogContentText id="discard-test-type-confirmation-description">
            Switching between {currentMetricName} and {newMetricName} may delete certain details of the test
            that are not included in the {newMetricName} test type. Is that okay?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Yup! Switch Metric</Button>
          <Button onClick={handleClose}>Do Nothing</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DiscardMetricDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  switchMetric: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  newMetricName: PropTypes.string.isRequired,
  currentMetricName: PropTypes.string.isRequired,
};

export default React.memo(DiscardMetricDialog);
