import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Snackbar } from '@mui/material';
import { useMemo } from 'react';

const styles = {
  snackbar: {
    zIndex: 100,
    '.MuiSnackbarContent-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.85)',
      color: '#FFFFFF',
      borderRadius: '10px',
      fontSize: '13px',
    },
  },
};

const CustomSnackbar = ({ message, action, anchorOrigin, autoHideDuration, open, onClose, sx }) => {
  const snackbarAction = useMemo(() => {
    if (action) {
      return action;
    } else if (onClose) {
      return (
        <IconButton size="small" aria-label="close snackbar" color="inherit" onClick={onClose}>
          <CloseIcon fontSize="40px" />
        </IconButton>
      );
    }

    return <></>;
  }, [action, onClose]);

  return (
    <Snackbar
      anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'left' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      sx={{ ...styles.snackbar, ...sx }}
      action={snackbarAction}
    />
  );
};

export default CustomSnackbar;
