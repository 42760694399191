import { Box, Grid, inputBaseClasses, styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { default as React, useMemo } from 'react';
import { SEGMENT_ID_TO_SEGMENT_LABEL } from '../../../modules/wevos/constants';

const QuotaInput = styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

const EditQuotas = (props) => {
  const { audience, quotasTotal, handleQuotaChange, open } = props;
  const selectedSegments = audience.segments.filter((segment) => segment.isSelected);

  const isAttributeSelected = useMemo(() => Boolean(audience.isSelected), [audience.isSelected]);
  const isCustomizedQuota = useMemo(() => Boolean(audience.isCustomizedQuota), [audience.isCustomizedQuota]);

  const error = quotasTotal !== 100;

  return (
    open && (
      <Grid item sx={{ marginTop: '30px', color: !isCustomizedQuota || !isAttributeSelected ? 'grey' : '' }}>
        <Box
          sx={{
            padding: '16px',
            border: 'thin solid grey',
            borderRadius: '3px',
          }}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={2}>
                  <Typography variant="h5">Quotas</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography
                    fontSize="14px"
                    marginTop="2px"
                    color={
                      !isCustomizedQuota || !isAttributeSelected ? 'disabled' : error ? 'error' : 'secondary'
                    }>{`Total: ${quotasTotal}`}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{ paddingBottom: '20px' }}>
              <Typography fontSize="12px" sx={{ overflowWrap: 'break-word' }}>
                WEVO will determine quota distribution unless you make specific selections. If you do make
                specific selections, WEVO may contact you based on panel feasibility.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} flex-wrap="wrap" alignItems="flex-end" justifyContent="flex-start">
                {selectedSegments.map((segment) => (
                  <Grid item key={segment.id}>
                    <InputLabel sx={{ fontSize: '10px', textTransform: 'capitalize' }}>
                      {SEGMENT_ID_TO_SEGMENT_LABEL[segment.id] || segment.label}
                    </InputLabel>
                    <QuotaInput
                      disabled={!isCustomizedQuota || !isAttributeSelected}
                      type="number"
                      variant="outlined"
                      value={segment.percentage.toString()} // the toString eliminates any leading zeroes
                      onChange={handleQuotaChange}
                      name={segment.label}
                      autoComplete="off"
                      inputProps={{
                        max: 100,
                        min: 0,
                        readOnly: selectedSegments.length === 1,
                      }}
                      InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    )
  );
};

export default EditQuotas;
