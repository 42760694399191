import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { DialogActions, Grid, IconButton, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

const OkButton = styled(Button)(({ theme }) => ({
  height: '45px',
  width: '85px',
  borderRadius: '27px',
  border: '1px solid #082739',
  color: 'rgba(9, 41, 60, 1)',
}));

const styles = {
  dialogTitle: {
    justifyContent: 'center',
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#000000',
  },
  seatCard: {
    width: '105px',
    height: '65px',
    borderRadius: '10px',
    alignContent: 'center',
    border: '1px solid rgba(210, 210, 210, 0.25)',
  },
  number: { fontSize: '12px', textAlign: 'center' },
  seatCardHeader: { fontSize: '9.5px', fontWeight: 600, textAlign: 'center' },
};

const ContactSupportModal = (props) => {
  const { open, closeCallback, modalHeader, modalAction } = props;

  const handleClose = () => {
    !!closeCallback && closeCallback();
  };

  return (
    <div>
      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="cancel subscription modal"
        aria-describedby="cancel-subscription-modal"
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: '20px',
            paddingTop: '16px',
            paddingBottom: '16px',
            width: '500px',
          },
        }}>
        <DialogTitle sx={{ ...styles.dialogTitle, marginTop: -2, marginBottom: 0.8 }}>
          <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 600 }}>
            <b>{modalHeader}</b>
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => closeCallback(false)}
            sx={styles.closeIcon}>
            <CloseIcon style={{ fontSize: '16px' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ overflow: 'hidden' }}>
          <Grid container spacing={2} justifyContent={'center'} sx={{ alignItems: 'center' }}>
            <Grid item xs={12}>
              <Typography sx={{ textAlign: 'center', fontSize: '13px' }}>
                To {modalAction}, please reach out
              </Typography>
              <Typography sx={{ textAlign: 'center', fontSize: '13px' }}>
                {`to `}
                <a href="mailto:pulsesupport@wevo.ai" target="_top" style={{ textDecoration: 'none' }}>
                  <Typography
                    variant="body1"
                    component={'span'}
                    style={{
                      color: '#276EB0',
                      fontSize: '12px',
                      cursor: 'pointer',
                    }}>
                    pulsesupport@wevo.ai
                  </Typography>
                </a>
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'center' }}>
          <OkButton onClick={() => closeCallback(false)}>
            <Typography sx={{ textTransform: 'none' }}>
              <b>Ok</b>
            </Typography>
          </OkButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ContactSupportModal;
