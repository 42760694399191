import { useTheme } from '@emotion/react';
import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  styled,
  Switch,
  Typography,
} from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useRouteMatch } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ClusterLabel, ClusterTypes } from '../../../modules/report/constants';
import downloadHeatmap from '../../../modules/report/downloadHeatmap';
import { pointInRegion, slugify } from '../../../modules/report/helpers';
import { WevoType } from '../../../modules/wevos/constants';
import { filterQuotes } from '../../../modules/wevos/quotes';
import { Paths } from '../../../routes';
import { buildAudienceFilterProperties, TrackEvent, useTrackPageLoad } from '../../analytics';
import { shouldTrackAudienceFilterChange } from '../../analytics/eventPropertyHelpers';
import HeatMap, { HeatmapPointRadius } from '../components/Heatmap';
import QuotesTable from '../components/QuotesTable';
import ShareButton from '../components/ShareButton';
import DownloadButton from './DownloadButton';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  color: 'rgb(125, 125, 125)',
  backgroundColor: 'white',
  '&.Mui-selected': {
    color: 'white',
    backgroundColor: '#276EB0',
    '&:hover': {
      backgroundColor: 'rgba(39,110,176, 0.85)',
    },
  },
}));

const renderQuotes = (
  wevo,
  page,
  quotes,
  filteredQuotes,
  onQuoteUpdate,
  region,
  pageImage,
  onAudienceFilterChange,
  onCustomScreenerFilterChange,
  onSearchChange,
  handleQuoteClick,
  cluster,
  selectedQuote,
  showClusterTypes,
  questionType,
  showThumbnails
) => {
  if (_.isNil(quotes) || _.isNil(filteredQuotes)) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else {
    let displayQuotes = filteredQuotes;
    const numClusterQuotes = quotes.filter((quote) => quote.clusterType === ClusterTypes[cluster]).length;
    if (region) {
      displayQuotes = displayQuotes.filter((point) => !!pointInRegion(point, region, pageImage));
    }

    let title = '';
    if (!showClusterTypes) {
      if (displayQuotes.length < quotes.length) {
        title = `${displayQuotes.length} Quotes (out of ${quotes.length})`;
      } else {
        title = title = `${displayQuotes.length} Quotes`;
      }
    } else if (displayQuotes.length < numClusterQuotes) {
      title = `${displayQuotes.length} Quotes (of ${numClusterQuotes} ${cluster})`;
    } else {
      title = `${displayQuotes.length} Quotes (${cluster})`;
    }

    return (
      <QuotesTable
        wevo={wevo}
        page={page}
        fullQuotes={quotes}
        displayQuotes={displayQuotes}
        onQuoteUpdate={onQuoteUpdate}
        onAudienceFilterChange={onAudienceFilterChange}
        onCustomScreenerFilterChange={onCustomScreenerFilterChange}
        onSearchChange={onSearchChange}
        handleQuoteClick={handleQuoteClick}
        selectedQuote={selectedQuote}
        title={title}
        questionType={questionType}
        maxHeight={showThumbnails ? '85vh' : '90vh'}
        showThumbnails={showThumbnails}
      />
    );
  }
};

const renderHeatmap = (
  image,
  points,
  highlightedPoint,
  radius,
  showOverlay,
  selectedRegion,
  handleRegionChange,
  showThumbnails,
  sentimentMapID,
  heatmapBgID
) => {
  if (!points || !image) {
    return (
      <Box textAlign="center">
        <CircularProgress />
      </Box>
    );
  } else {
    return (
      <HeatMap
        image={image}
        data={points}
        highlightedPoint={highlightedPoint}
        selectedRegion={selectedRegion}
        onSelectRegionChange={handleRegionChange}
        radius={radius}
        showOverlay={showOverlay}
        showThumbnails={showThumbnails}
        sentimentMapID={sentimentMapID}
        heatmapBgID={heatmapBgID}
      />
    );
  }
};

const HeatmapWithQuotes = ({
  id,
  title,
  wevo,
  page,
  step,
  heatmapPoints,
  questionType,
  header,
  followUpQuestion,
  showClusterTypes,
  showBorders,
  thumbnails,
  handleShareButtonClick,
  isLimitedReport,
  sentimentMapID,
  heatmapBgID,
  handleClusterTypeChanged,
}) => {
  const theme = useTheme();
  const styles = {
    heatmapContainer: {
      [theme.breakpoints.down('lg')]: {
        order: -1,
      },
    },
    paper: {
      padding: theme.spacing(2),
      borderRadius: '20px',
    },
  };

  const [points, setPoints] = useState(heatmapPoints);
  const [filters, setFilters] = useState({ cluster: ClusterTypes.Likes, search: '' });

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [showHeatmap, setShowHeatmap] = useState(true);

  const heatMap = page?.images?.heatmap || step?.images?.heatmap;

  const sentimentMapMatch = useRouteMatch({
    path: [Paths.reports.sentimentMap, Paths.reports.limitedSentimentMap],
  });

  const filteredPoints = useMemo(
    () => (!_.isNil(points) ? filterQuotes(points, filters) : null),
    [points, filters]
  );

  const { track } = useAnalytics();

  let contentElement = null;

  const setContentElement = (element) => {
    contentElement = element;
  };

  useEffect(() => {
    if (selectedQuote) {
      if (!filteredPoints.some((point) => point.id === selectedQuote.id)) {
        setSelectedQuote(null);
      }
    }
  }, [filteredPoints, selectedQuote]);

  useEffect(() => {
    handleRegionChange(null);
    setSelectedQuote(null);
    setPoints(heatmapPoints);
  }, [step?.id, page?.id, heatmapPoints]);

  useTrackPageLoad({
    name: sentimentMapMatch
      ? TrackEvent.VIEWED_REPORT_PAGE_SENTIMENT
      : TrackEvent.VIEWED_REPORT_CUSTOM_QUESTION,
    properties: {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      step: step?.id,
      stepNumber: step?.stepNumber,
    },
  });

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
    setSelectedQuote(null);
  };

  const handleClusterChange = (ev, newCluster) => {
    if (!_.isNil(newCluster)) {
      setFilters((filters) => ({ ...filters, cluster: newCluster }));
      handleClusterTypeChanged(newCluster);
    }
  };

  const handleHeatmapToggle = (ev) => {
    setShowHeatmap(ev.target.checked);
  };

  const handleQuoteClick = useCallback(
    (quoteId) => {
      if (contentElement) {
        const contentNode = ReactDOM.findDOMNode(contentElement);
        const rect = contentNode.getBoundingClientRect();
        // magic number 64 = the height of the header
        // magic number 20 = visually appealing whitespace
        const top = window.scrollY + rect.top - 64 - 20;
        if (top > window.scrollY) {
          window.scrollTo(window.scrollX, top);
        }
      }
      if (!_.isNil(selectedQuote) && quoteId === selectedQuote.id) {
        setSelectedQuote(null);
      } else {
        setSelectedQuote(filteredPoints.find((point) => point.id === quoteId));
      }
    },
    [contentElement, filteredPoints, selectedQuote]
  );

  const generateFilename = () => {
    const label = filters.cluster;
    const filter = ClusterLabel[label];
    const filteredAudience = Object.values(filters.audience).some((val) => !val);
    const filteredSearch = filters.search;

    let name;
    if (wevo.type === WevoType.Classic) {
      name = page.name;
    } else {
      name = step.name;
    }

    let filename;
    if (!showHeatmap) {
      filename = name;
    } else if (sentimentMapMatch) {
      filename =
        filteredSearch || filteredAudience
          ? `sentiment map ${name} ${filter} filtered`
          : `sentiment map ${name} ${filter}`;
    } else {
      filename =
        filteredSearch || filteredAudience ? `custom click map ${name} filtered` : `custom click map ${name}`;
    }
    return slugify(filename);
  };

  const handleHeatmapDownload = () => {
    track(sentimentMapMatch ? TrackEvent.DOWNLOADED_SENTIMENT_MAP : TrackEvent.DOWNLOADED_CUSTOM_CLICK_MAP, {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      stepId: step?.id,
      stepNumber: step?.stepNumber,
      testType: wevo?.type,
      searchQuery: filters.search,
      hasFilters: Object.values(filters.audience).some((val) => !val),
    });

    const filename = generateFilename();
    downloadHeatmap(wevo, page, step, filename);
  };

  const onQuoteUpdate = ({ id, updates }) => {
    const pointIndex = points.findIndex((point) => point.id === id);
    const updatedPoint = { ...points[pointIndex], ...updates };
    const updatedPoints = [...points];
    updatedPoints[pointIndex] = updatedPoint;
    setPoints(updatedPoints);
  };

  // Called by QuoteAudienceFilter if audience filters are changed.
  const onAudienceFilterChange = (audienceFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      audienceFilters,
      filters.audience,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, audienceFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_AUDIENCE, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      audience: audienceFilters,
    }));
  };

  // Called by CustomScreenerFilter if custom screener filters are changed.
  const onCustomScreenerFilterChange = (screenerFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      screenerFilters,
      filters.screeners,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, screenerFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_CUSTOM_SCREENER, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      screeners: screenerFilters,
    }));
  };

  // Called by QuoteSearch if search query is changed
  const onSearchChange = (searchQuery) => {
    if (searchQuery) {
      track(TrackEvent.SEARCHED_QUOTE, {
        searchQuery,
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      });
    }

    setFilters((filters) => ({ ...filters, search: searchQuery }));
  };

  const numRespondents = useMemo(
    () => !_.isNil(points) && points.reduce((acc, point) => acc.add(point.responseId), new Set()).size,
    [points]
  );
  let radius;
  if (numRespondents >= 500) {
    radius = HeatmapPointRadius.Small;
  } else if (numRespondents <= 75) {
    radius = HeatmapPointRadius.Large;
  } else {
    radius = HeatmapPointRadius.Normal;
  }

  const renderQuotesAndHeatmap = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} sx={styles.heatmapContainer}>
          {thumbnails || <></>}
          {renderHeatmap(
            heatMap,
            filteredPoints,
            selectedQuote,
            radius,
            showHeatmap,
            selectedRegion,
            handleRegionChange,
            !!thumbnails,
            sentimentMapID,
            heatmapBgID
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderQuotes(
            wevo,
            page,
            points,
            filteredPoints,
            onQuoteUpdate,
            selectedRegion,
            heatMap,
            onAudienceFilterChange,
            onCustomScreenerFilterChange,
            onSearchChange,
            handleQuoteClick,
            ClusterLabel[filters.cluster],
            selectedQuote,
            showClusterTypes,
            questionType,
            !!thumbnails
          )}
        </Grid>
      </Grid>
    );
  };

  const content = (
    <Box p={2}>
      {title && (
        <Box py={1}>
          <Typography variant="h5">{title}</Typography>
        </Box>
      )}
      <Grid container alignItems="center" justifyContent="space-between" spacing={2} py={2}>
        {showClusterTypes && (
          <Grid item>
            <ToggleButtonGroup
              value={filters.cluster}
              exclusive
              onChange={handleClusterChange}
              aria-label="likes or dislikes selection">
              <StyledToggleButton value={ClusterTypes.Likes}>Likes</StyledToggleButton>
              <StyledToggleButton value={ClusterTypes.Dislikes}>Dislikes</StyledToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}
        {followUpQuestion && (
          <Grid item xs={12} md={5}>
            <Typography style={{ fontSize: 16, fontWeight: 600 }}>Follow-up Question:</Typography>
            <Typography component="span" style={{ fontSize: 16, whiteSpace: 'pre-line' }}>
              "{followUpQuestion}"
            </Typography>
          </Grid>
        )}
        <Grid container item justifyContent="flex-end" xs={12} md={7}>
          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={showHeatmap}
                  name="heatmapToggle"
                  color="primary"
                  onChange={handleHeatmapToggle}
                />
              }
              label={<Typography variant="h6">View Map</Typography>}
              labelPlacement="start"
            />
          </Grid>
          <Grid item sx={{ marginLeft: { md: 4 } }}>
            <DownloadButton handleDownload={handleHeatmapDownload} />
          </Grid>
        </Grid>
      </Grid>
      <Box py={2}>{renderQuotesAndHeatmap()}</Box>
    </Box>
  );

  return (
    <Box ref={(elt) => setContentElement(elt)} {...(id ? { id } : {})}>
      {/* share button for sentiment map only, not custom click map questions */}
      {!isLimitedReport && sentimentMapMatch && (
        <Box mb={2}>
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <ShareButton handleShareButtonClick={handleShareButtonClick} />
            </Grid>
          </Grid>
        </Box>
      )}

      <Grid container>
        <Grid item xs={12}>
          {header}
        </Grid>
      </Grid>
      {showBorders ? (
        <Paper elevation={4} sx={styles.paper}>
          {content}
        </Paper>
      ) : (
        content
      )}
    </Box>
  );
};

HeatmapWithQuotes.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  step: PropTypes.object,
  heatmapPoints: PropTypes.array.isRequired,
  questionType: PropTypes.string,
  header: PropTypes.object,
  followUpQuestion: PropTypes.string,
  showClusterTypes: PropTypes.bool,
  showBorders: PropTypes.bool,
  thumbnails: PropTypes.element,
  handleShareButtonClick: PropTypes.func,
  isLimitedReport: PropTypes.bool,
  sentimentMapID: PropTypes.string,
  heatmapBgID: PropTypes.string,
  handleClusterTypeChanged: PropTypes.func,
};

export default HeatmapWithQuotes;
