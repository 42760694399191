import AddIcon from '@mui/icons-material/Add';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Button, CircularProgress, Grid, Paper, TextField, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PlateEmptyValue } from '../../../modules/plate/constants';
import { KeyFindingType } from '../../../modules/report/constants';
import { isAuthenticated } from '../../../modules/user/helpers';
import { getUserCustomizations, getUserType } from '../../../modules/user/selectors';
import { UsabilityOption, UserType } from '../../../modules/wevos/constants';
import { hasKeyFindings } from '../../../modules/wevos/helpers';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import { RightDrawerButton } from '../components/RightDrawer';
import useKeyFindings, { useCrossPageKeyFindings } from '../hooks/useKeyFindings';
import useStarQuote from '../hooks/useStarQuote';
import useStarredQuotes from '../hooks/useStarredQuotes';
import useUserKeyFindings from '../hooks/useUserKeyFindings';
import KeyFindingCard from './components/KeyFindingCard';
import KeyFindingsEditor from './components/KeyFindingsEditor';
import StarredQuoteBlock from './components/StarredQuoteBlock';

const styles = {
  keyFindingsHeaderTextField: {
    width: '50%',
    '& .MuiFilledInput-root': {
      borderRadius: (theme) => theme.spacing(1.5),
    },
  },
};

const Takeaways = (props) => {
  const { wevo, page, rightDrawerOpen, setRightDrawerOpen } = props;
  const theme = useTheme();

  const { data: keyFindings = [], isLoading: isKeyFindingsLoading } = useKeyFindings({
    wevoId: wevo?.id,
    pageId: page.id,
  });

  const { data: crossPageKeyFindings } = useCrossPageKeyFindings({
    wevoId: wevo.id,
  });

  const { data: userKeyFindings = {}, isLoading: isUserKeyFindingsLoading } = useUserKeyFindings({
    wevoId: wevo?.id,
  });

  const { mutate: toggleQuoteStar } = useStarQuote();

  const userType = useSelector(getUserType);

  const userCustomizations = useSelector(getUserCustomizations);
  const hasUsability = userCustomizations?.usabilityTestType !== UsabilityOption.Disabled;
  const isUsabilityTestType = wevo?.isUsabilityTestType;

  const [starredQuotes, setStarredQuotes] = useState([]);
  const [pagesById, setPagesById] = useState({});
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [userKeyFindingsHeader, setUserKeyFindingsHeader] = useState(userKeyFindings?.header ?? '');

  const handleRightDrawerOpenClick = () => {
    setRightDrawerOpen(true);
  };

  useEffect(() => {
    setPagesById(_.keyBy(wevo?.pages ?? [], 'id'));
  }, [wevo]);

  useEffect(() => {
    setUserKeyFindingsHeader(userKeyFindings?.header ?? '');
  }, [userKeyFindings?.header, wevo?.company?.name]);

  useEffect(() => {
    if (
      !_.isEmpty(userKeyFindings?.finding) &&
      !_.isEqual(userKeyFindings?.finding, PlateEmptyValue) &&
      !isEditorOpen
    ) {
      setIsEditorOpen(true);
    }
  }, [isEditorOpen, userKeyFindings?.finding]);

  useStarredQuotes({
    wevoId: wevo?.id,
    pageId: page?.id,
    onSuccess: (data) => {
      setStarredQuotes(data?.quotes ?? []);
    },
  });

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_TAKEAWAYS,
    properties: { wevoId: wevo?.analyticsId },
  });

  const handleStarClick = (quote) => {
    toggleQuoteStar({
      wevoId: wevo.id,
      pageId: quote.wevoPageId,
      quoteType: quote.quoteType,
      quoteId: quote.id,
    });
    // update the starredQuotes array so the list of quotes and the star icons update immediately
    setStarredQuotes((starredQuotes) =>
      starredQuotes
        .map((starredQuote) => {
          if (starredQuote.quoteId === quote.id) {
            return {
              ...starredQuote,
              quote: {
                ...starredQuote.quote,
                starredByWevo: userType === UserType.Wevo ? !quote.starredByWevo : quote.starredByWevo,
                starredByCompany:
                  userType === UserType.External ? !quote.starredByCompany : quote.starredByCompany,
              },
            };
          } else {
            return starredQuote;
          }
        })
        .filter((starredQuote) => starredQuote.quote.starredByCompany || starredQuote.quote.starredByWevo)
    );
  };

  const handleAddKeyFindingsClick = () => {
    setIsEditorOpen(true);
    setReadOnly(false);
  };

  const handleUserKeyFindingsChange = (ev) => {
    setUserKeyFindingsHeader(ev.target.value);
  };

  if (isKeyFindingsLoading) {
    return (
      <Box p={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Fragment>
      <Box sx={{ paddingRight: { xs: '15%', lg: '25%' } }}>
        <Grid container alignItems="center" style={{ marginBottom: '8px' }}>
          <Grid item xs>
            {readOnly && (
              <Typography component="h3" variant="h3">
                {isEditorOpen
                  ? userKeyFindingsHeader || `${wevo?.company?.name} Team's Key Findings`
                  : 'WEVO Key Findings'}
              </Typography>
            )}
            {!readOnly && (
              <TextField
                sx={styles.keyFindingsHeaderTextField}
                InputProps={{ disableUnderline: true }}
                inputProps={{
                  style: {
                    padding: theme.spacing(1, 2),
                    fontWeight: theme.typography.h3.fontWeight,
                    fontSize: '1.4996rem',
                  },
                }}
                value={userKeyFindingsHeader}
                placeholder={`${wevo?.company?.name} Team's Key Findings`}
                variant="filled"
                onChange={handleUserKeyFindingsChange}
              />
            )}
          </Grid>
          {!isEditorOpen && isAuthenticated() && (
            <Grid item>
              <Button
                startIcon={<AddIcon />}
                onClick={handleAddKeyFindingsClick}
                disabled={isUserKeyFindingsLoading}>
                Add Your Key Findings
              </Button>
            </Grid>
          )}
        </Grid>
        {isEditorOpen && (
          <KeyFindingsEditor
            key={wevo?.id + page?.id}
            wevoId={wevo?.id}
            readOnly={readOnly}
            setReadOnly={setReadOnly}
            userKeyFindings={userKeyFindings}
            header={userKeyFindingsHeader}
            setHeader={setUserKeyFindingsHeader}
          />
        )}
        <Box py={2} />
        {isEditorOpen && (
          <Typography id="wevo-key-findings-section" variant="h3" gutterBottom>
            {'WEVO Key Findings'}
          </Typography>
        )}
        {((!hasKeyFindings(wevo, keyFindings) && !hasKeyFindings(wevo, [crossPageKeyFindings])) ||
          (hasUsability && isUsabilityTestType)) && (
          <Box width={'100%'}>
            <KeyFindingCard wevoId={wevo?.id} pageId={page?.id} />
          </Box>
        )}
        {(hasKeyFindings(wevo, keyFindings) || hasKeyFindings(wevo, [crossPageKeyFindings])) &&
          !isUsabilityTestType &&
          keyFindings.map((keyFinding, idx) => {
            if (keyFinding.type === KeyFindingType.Markdown) {
              // creates a card for each keyFinding only if it's keyFinding.finding field exists
              return (
                <Box width={'100%'} key={`wevo-${wevo?.id}-page-${page?.id}-${idx}`}>
                  <KeyFindingCard
                    keyFinding={keyFinding}
                    wevoId={wevo?.id}
                    pageId={page?.id}
                    crossPageKeyFindings={idx === 0 ? crossPageKeyFindings : null}
                  />
                </Box>
              );
            }
            return (
              <Box width={'100%'} key={`wevo-${wevo?.id}-page-${page?.id}-${idx}`}>
                <KeyFindingCard
                  keyFinding={keyFinding}
                  wevoId={wevo?.id}
                  pageId={page?.id}
                  crossPageKeyFindings={idx === 0 ? crossPageKeyFindings : null}
                />
              </Box>
            );
          })}
        <Box py={`${100.5 / 2}px`} />
        <Typography id="starred-section" variant="h3" gutterBottom>
          Starred Quotes
        </Typography>
        <Paper elevation={4} style={{ borderRadius: '20px' }}>
          <Box py={2}>
            {starredQuotes.map((starredQuote) => (
              <StarredQuoteBlock
                key={starredQuote.id}
                wevo={wevo}
                pageNum={pagesById[starredQuote.wevoPageId].pageNumber}
                pageLabel={pagesById[starredQuote.wevoPageId].label}
                quote={starredQuote.quote}
                handleStarClick={() => handleStarClick(starredQuote.quote)}
              />
            ))}
            {!starredQuotes.length && (
              <Box px={2}>
                <Typography>
                  Sorry! You haven't saved any quotes yet. Click on the{' '}
                  <StarBorderIcon style={{ color: grey[500] }} /> next to a quote to bookmark.
                </Typography>
              </Box>
            )}
          </Box>
        </Paper>
      </Box>

      <RightDrawerButton
        wevo={wevo}
        rightDrawerOpen={rightDrawerOpen}
        handleRightDrawerOpenClick={handleRightDrawerOpenClick}
      />
    </Fragment>
  );
};

Takeaways.propTypes = {
  wevo: PropTypes.object.isRequired,
  rightDrawerOpen: PropTypes.bool.isRequired,
  setRightDrawerOpen: PropTypes.func.isRequired,
};

export default Takeaways;
