import { useQueries, useQuery } from 'react-query';
import axios from '../api';

const fetchExperience = async ({ queryKey: [key, { wevoId, pageId, includeConfig }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/experiences?includeConfig=${includeConfig}`,
    method: 'GET',
  });
  return data?.experience;
};

export default function useFetchExperience({ wevoId, pageId, includeConfig = true }, options) {
  return useQuery(['experience', { wevoId, pageId, includeConfig }], fetchExperience, {
    notifyOnChangeProps: 'tracked',
    ...options,
  });
}

export function useFetchExperiences(queries, options) {
  return useQueries(
    queries.map(({ wevoId, pageId, includeConfig }) => {
      return {
        queryKey: ['experience', { wevoId, pageId, includeConfig }],
        queryFn: fetchExperience,
        notifyOnChangeProps: 'tracked',
        ...options,
      };
    })
  );
}
