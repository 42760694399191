import makeStyles from '@mui/styles/makeStyles';

const useToothpasteTagStyles = makeStyles((theme) => ({
  toothpasteTag: {
    color: '#FFFFFF',
    textAlign: 'center',
    bottom: 0,
    padding: '3px 5px',
    cursor: 'default',
    margin: '0% 1%',
    width: '100px',
    backgroundColor: (props) => props.color,
    height: (props) => props.height || '20px',
    display: 'flex',
    flexOrientation: 'column',
    borderRadius: (props) => `0px ${props.borderRadius}px 0px ${props.borderRadius}px`,
  },
  toothpasteTagText: {
    margin: 'auto',
  },
}));

export default function ToothpasteTag({ label, borderRadius = 10, color, height = '20px', style = {} }) {
  const classes = useToothpasteTagStyles({ height, color, borderRadius });

  return (
    <div className={classes.toothpasteTag} style={style}>
      <h5 className={classes.toothpasteTagText}>{label || ''}</h5>
    </div>
  );
}
