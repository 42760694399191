import React, { useEffect } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: 'red',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteWevoDialog = ({ wevoName, deleteCallback, closeCallback, ...props }) => {
  const [open, setOpen] = React.useState(props.open);

  const classes = useStyles();

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    !!closeCallback && closeCallback();
    setOpen(false);
  };

  const handleDelete = () => {
    !!deleteCallback && deleteCallback();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="delete wevo confirmation dialog"
      aria-describedby="wevo-deletion-confirmation-description">
      <DialogTitle className={classes.title}>{`Are you sure you want to delete ${wevoName}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText
          id="wevo-deletion-confirmation-description"
          aria-describedby="alert-dialog-slide-detail">
          {`You are about to delete the test "${wevoName}". This action is permanent and cannot be undone.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDelete}>Delete</Button>
        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteWevoDialog;
