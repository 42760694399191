import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { useEffect, useState } from 'react';
import { BarChartBenchmarksFontSizes } from '../../../modules/report/constants';
import { chunkString } from '../../../modules/report/helpers.js';
import theme from '../../../theme';
import BarChartWithBenchmarks from '../components/BarChartWithBenchmarks';

const styles = makeStyles((theme) => ({
  chartContainer: {
    width: '100%',
    height: '100%',
    background: 'white',
  },
  chartInfoContainer: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chartInfoIcon: {
    color: grey[600],
    fontSize: ({ isDashboard }) => (isDashboard ? '1.25rem' : ''),
  },
  chartTooltip: {
    maxWidth: 450,
  },
  chartTooltipContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  chartTooltipItems: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  },
  tooltipText: {
    fontSize: '12px',
  },
}));

const CompositeMetricsDiagnosticsBarGraph = (props) => {
  const {
    diagnostics,
    diagnosticOrdering,
    onBarSelected,
    width,
    height,
    fontSize,
    backgroundColor,
    hoverBackgroundColor,
    isDashboard,
    componentId,
  } = props;
  const classes = styles(props);

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
  let labelMaxWidth;

  if (isSmallDevice) {
    labelMaxWidth = 25;
  } else {
    labelMaxWidth = 40;
  }

  // DQS doesn't have benchmarks, for now
  const benchmarkThresholds = diagnosticOrdering.map(() => null);

  const [diagnosticByName, setDiagnosticByName] = useState({});

  useEffect(() => {
    setDiagnosticByName(
      diagnostics.reduce((acc, cur) => {
        acc[cur.name] = cur;
        return acc;
      }, {})
    );
  }, [diagnostics]);

  // only render the bar chart when the data is available
  if (Object.keys(diagnosticByName).length === 0) {
    return null;
  }

  return (
    <Box className={classes.chartContainer}>
      <Grid container>
        <Grid item xs={11}>
          <Box mb={4}>
            <Typography variant={isDashboard ? 'h5' : 'h4'}>Diagnostics</Typography>
          </Box>
        </Grid>
        <Grid container justifyContent="center" spacing={0}>
          <Grid item xs={12} md={10}>
            <BarChartWithBenchmarks
              labels={diagnosticOrdering.map((diagnostic) => diagnosticByName[diagnostic]?.name)}
              backgroundColor={backgroundColor}
              hoverBackgroundColor={hoverBackgroundColor}
              datasets={[
                diagnosticOrdering.map((diagnostic) => Math.round(diagnosticByName[diagnostic]?.score ?? 0.0)),
              ]}
              benchmarkThresholds={benchmarkThresholds}
              benchmarks={diagnosticOrdering.map(() => null)} // suppress benchmarks for now
              dataLabels={[
                diagnosticOrdering.map((diagnostic) => Math.round(diagnosticByName[diagnostic]?.score ?? 0.0)),
              ]}
              onBarSelected={onBarSelected}
              width={width || '100%'}
              height={height || '375px'}
              benchmarkLabel={'Industry benchmark'}
              tooltipLabelDescription={(dataIndex) =>
                chunkString(
                  diagnosticByName?.[diagnosticOrdering[dataIndex]]?.description || '',
                  labelMaxWidth
                )
              }
              fontSize={fontSize}
              chartLabelSize={BarChartBenchmarksFontSizes.Small}
              displayLegend={false}
              barDisplayOptions={{
                maxBarThickness: 85,
                borderRadius: 6,
                barPercentage: 0.95,
                categoryPercentage: 1.0,
              }}
              componentId={componentId}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CompositeMetricsDiagnosticsBarGraph;
