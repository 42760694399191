import { Grid, Paper, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { EASE_OPTIONS, EXPERIENCE_OUTCOMES, HASH_LINKS } from '../../../../modules/report/constants';
import downloadExperienceClickmap from '../../../../modules/report/downloadExperienceClickmap';
import { filterOutcomes, getPointsByStepId } from '../../../../modules/report/experienceMaps';
import { pointInRegion } from '../../../../modules/report/helpers';
import { buildAudienceFilterProperties, TrackEvent } from '../../../analytics';
import { shouldTrackAudienceFilterChange } from '../../../analytics/eventPropertyHelpers';
import ClickTrackingMap from '../../components/ClickTrackingMap';
import DownloadButton from '../../components/DownloadButton';
import ExperienceCarousel from './ExperienceCarousel';
import OutcomesTable from './OutcomesTable';

const ExperienceClickMap = ({ wevo, page, experience, outcomes }) => {
  const [step, setStep] = useState(experience?.steps?.[0]);
  const [filters, setFilters] = useState({
    outcomes: Object.values(EXPERIENCE_OUTCOMES),
    taskTimeRange: [],
    easeScores: EASE_OPTIONS,
  });
  const [maxTaskTimeSeconds, setMaxTaskTimeSeconds] = useState(0);
  const [highlightedOutcome, setHighlightedOutcome] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const { track } = useAnalytics();

  const filteredOutcomes = useMemo(
    () => (!_.isNil(outcomes) ? filterOutcomes({ outcomes, stepId: step?.id, filters }) : null),
    [outcomes, step, filters]
  );

  const selectedOutcomes = useMemo(() => filters.outcomes, [filters]);
  const selectedEaseScores = useMemo(() => filters.easeScores, [filters]);

  useEffect(() => {
    const taskTimesSeconds = outcomes?.map((outcome) => outcome?.taskTimeSeconds);
    if (taskTimesSeconds) {
      setMaxTaskTimeSeconds(Math.max(...taskTimesSeconds));
    }
  }, [outcomes]);

  useEffect(() => {
    if (highlightedOutcome) {
      if (!filteredOutcomes.some((outcome) => outcome.id === highlightedOutcome.id)) {
        setHighlightedOutcome(null);
      }
    }
  }, [filteredOutcomes, highlightedOutcome]);

  useEffect(() => {
    // Note: since `step` is a state field, when the experience prop changes, the selected step doesn't automatically change.
    // this means when the experience prop changes, it is possible for the step to still reference a step in the prior experience prop
    // to prevent this, we need to detect when the experience prop has changed and reset the step accordingly
    // TODO: refactor - this problem suggests selected step should be a prop
    if (!_.isNil(experience) && experience.id !== step.experienceId) {
      setStep(experience?.steps?.[0]);
    }
  }, [experience, step, setStep]);

  const handleStepSelection = (selectedStep) => {
    setStep(selectedStep);
    setSelectedRegion(null);
    setHighlightedOutcome(null);
  };

  const handleRegionChange = (region) => {
    setSelectedRegion(region);
    setHighlightedOutcome(null);
  };

  const handleOutcomeClick = useCallback(
    (outcomeId) => {
      if (!_.isNil(highlightedOutcome) && outcomeId === highlightedOutcome.id) {
        setHighlightedOutcome(null);
      } else {
        setHighlightedOutcome(filteredOutcomes.find((outcome) => outcome.id === outcomeId));
      }
    },
    [filteredOutcomes, highlightedOutcome]
  );

  const onAudienceFilterChange = (audienceFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      audienceFilters,
      filters?.audience,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        experienceId: step?.experienceId,
        experienceStepId: step?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, audienceFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_AUDIENCE, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      audience: audienceFilters,
    }));
  };

  const onOutcomeFilterChange = (outcomeFilters) => {
    track(TrackEvent.FILTERED_OUTCOMES_BY_OUTCOME, {
      filters: outcomeFilters,
      location: 'Metrics Filter',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      experienceId: step?.experienceId,
      experienceStepId: step?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      outcomes: outcomeFilters,
    }));
  };

  const onEaseFilterChange = (easeScoreFilters) => {
    track(TrackEvent.FILTERED_OUTCOMES_BY_EASE_SCORE, {
      filters: easeScoreFilters,
      location: 'Metrics Filter',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      experienceId: step?.experienceId,
      experienceStepId: step?.id,
      testType: wevo?.type,
    });
    setFilters((filters) => ({
      ...filters,
      easeScores: easeScoreFilters,
    }));
  };

  const onTaskTimeFilterChange = (taskTimeRange) => {
    track(TrackEvent.FILTERED_OUTCOMES_BY_TASK_TIME, {
      filter: taskTimeRange,
      location: 'Metrics Filter',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      experienceId: step?.experienceId,
      experienceStepId: step?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      taskTimeRange: taskTimeRange,
    }));
  };

  const points = getPointsByStepId(filteredOutcomes, step?.id);

  const highlightedPoints = useMemo(
    () => getPointsByStepId([highlightedOutcome], step?.id),
    [highlightedOutcome, step?.id]
  );

  let outcomesInRegion;
  // if a region is selected, filter out the outcomes that include at least
  // one point within the selected region of the current step
  if (filteredOutcomes && selectedRegion) {
    outcomesInRegion = filteredOutcomes?.filter((item) => {
      const clicks = item?.clicks;
      return clicks.some(
        (click) =>
          click.experienceStepId === step?.id &&
          !!pointInRegion({ x: click.x, y: click.y }, selectedRegion, step?.staticImage)
      );
    });
  }
  const handleDownloadClickMap = async () => {
    track(TrackEvent.DOWNLOADED_CLICK_TRACKING_MAP, {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      experienceId: step?.experienceId,
      experienceStepId: step?.id,
      testType: wevo?.type,
    });

    await downloadExperienceClickmap(wevo.id, page.id, step, points, `${step.id}_clickmap`);
  };

  return (
    <Paper elevation={4} sx={{ padding: 4, borderRadius: '20px' }} id={HASH_LINKS.experienceClickTrackingMap}>
      <Grid container justifyContent="space-between" mb={4}>
        <Grid item>
          <Typography variant="h5">How respondents engaged - Clicks</Typography>
        </Grid>
        <Grid item>
          <DownloadButton handleDownload={handleDownloadClickMap} />
        </Grid>
      </Grid>
      <Grid container spacing={2} pb={2}>
        <Grid item container spacing={1} xs={12} md={6} sx={{ height: '100%' }}>
          <Grid item xs={12}>
            <ExperienceCarousel
              currentStep={step}
              steps={experience?.steps}
              filteredOutcomes={filteredOutcomes}
              onSelection={handleStepSelection}
            />
          </Grid>
          <Grid item xs={12}>
            <ClickTrackingMap
              image={step?.staticImage}
              points={points}
              highlightedPoints={highlightedPoints}
              selectedRegion={selectedRegion}
              onRegionChange={handleRegionChange}
              minRadiusPx={10}
              maxRadiusPx={20}
            />
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6}>
          {outcomes && (
            <OutcomesTable
              outcomes={outcomes}
              filteredOutcomes={selectedRegion ? outcomesInRegion : filteredOutcomes}
              onAudienceFilterChange={onAudienceFilterChange}
              selectedOutcomes={selectedOutcomes}
              onOutcomeFilterChange={onOutcomeFilterChange}
              selectedEaseScores={selectedEaseScores}
              onEaseFilterChange={onEaseFilterChange}
              maxTaskTimeSeconds={maxTaskTimeSeconds}
              onTaskTimeFilterChange={onTaskTimeFilterChange}
              highlightedOutcome={highlightedOutcome}
              onOutcomeClick={handleOutcomeClick}
            />
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

ExperienceClickMap.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  experience: PropTypes.object.isRequired,
  outcomes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExperienceClickMap;
