import { useQueries, useQuery } from 'react-query';
import axios from '../../../api';

const fetchDiagnostics = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  if (!wevoId || !pageId) {
    return {};
  }

  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/diagnostics`,
    method: 'GET',
  });

  let quotes = [];

  data?.diagnostics.forEach((diagnostic) => {
    const diagnosticQuotes = diagnostic?.quotes ?? [];
    quotes = quotes.concat(diagnosticQuotes);
  });

  // TODO: remove useNewDiagnostics once cutover to the new system
  return { diagnostics: data?.diagnostics, quotes, useNewDiagnostics: data?.useNewDiagnostics, pageId };
};

/**
 *
 * @param {{wevoId: string, pageId: string}} params
 * @returns
 */
export default function useDiagnostics({ wevoId, pageId }, options) {
  return useQuery(['diagnostics', { wevoId, pageId }], fetchDiagnostics, {
    ...options,
    notifyOnChangeProps: 'tracked',
  });
}

/**
 *
 * @param {{wevoId: string, pageIds: [string]}} params
 * @returns
 */
export function useFetchAllPagesDiagnosticsQuotes({ wevoId, pageIds }) {
  return useQueries(
    pageIds?.map((pageId) => {
      return {
        queryKey: ['diagnostics', { wevoId, pageId }],
        queryFn: fetchDiagnostics,
        staleTime: 30 * 1000,
      };
    })
  );
}
