// this is the parent component for the journeys reporting flow
import { Box, CircularProgress, Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { generatePath, Redirect, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import warningIcon from '../../assets/warning.svg';
import useWevo from '../../hooks/useWevo';
import { LIMITED_REPORT_PAGES, SHARE_REPORT_TYPE } from '../../modules/report/constants';
import { generateLimitedReportPageUrl } from '../../modules/report/helpers';
import { isAuthenticated } from '../../modules/user/helpers';
import { WevoType } from '../../modules/wevos/constants';
import { sortPages } from '../../modules/wevos/helpers';
import { Paths } from '../../routes';
import { TrackEvent } from '../analytics';
import ViewTest from '../intake/edit/ViewTest';
import useFetchCustomQuestions from '../intake/hooks/useFetchCustomQuestions';
import RightDrawer from './components/RightDrawer';
import ShareLinkDialog from './components/ShareLinkDialog';
import ConceptScoreContainer from './concept-score';
import CustomQuestionsContainer from './custom-questions';
import DashboardContainer from './dashboard';
import DiagnosticsContainer from './diagnostics';
import EmotionWordsContainer from './emotion-words';
import ExpectationsContainer from './expectations';
import ExperienceMapContainer from './experience-map';
import ExperienceScoreContainer from './experience-score';
import LeftNav from './left-nav';
import SentimentMapContainer from './sentiment-map';
import StayOrGoContainer from './stay-or-go';
import TakeawaysContainer from './takeaways';
import ComparePagesDashboardContainer from './compare-dashboard';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
  isPreviewing: {
    marginTop: theme.spacing(5),
  },
  errorContainer: {
    height: '80vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorMessage: {
    color: theme.palette.primary.main,
  },
  warning: {
    maxHeight: '150px',
  },
}));

const JourneyContainer = (props) => {
  const classes = useStyles();
  const { wevoId } = useParams();

  const dashboardMatch = useRouteMatch({
    path: [Paths.reports.dashboard, Paths.reports.dashboardPage, ...Paths.reports.LimitedDashboardPaths],
    exact: true,
  });

  const diagnosticsMatch = useRouteMatch({
    path: [Paths.reports.diagnostics, Paths.reports.diagnosticsPage],
    exact: true,
  });

  const expectationsMatch = useRouteMatch({
    path: [Paths.reports.expectations, Paths.reports.expectationsPage],
    exact: true,
  });

  const keyFindingsMatch = useRouteMatch({
    path: [Paths.reports.takeaways, Paths.reports.takeawaysPage],
    exact: true,
  });

  const sentimentMapMatch = useRouteMatch({
    path: [...Paths.reports.SentimentMapPaths, ...Paths.reports.LimitedSentimentMapPaths],
    exact: true,
  });

  const questionDetailsMatch = useRouteMatch({ path: Paths.reports.questionDetails });

  const experienceScoreMatch = useRouteMatch({ path: Paths.reports.experienceScore });
  const conceptScoreMatch = useRouteMatch({ path: Paths.reports.conceptScore });

  const experienceMapMatch = useRouteMatch({ path: Paths.reports.experienceMap });

  const isLimitedDashboard = Boolean(
    useRouteMatch({
      path: Paths.reports.LimitedDashboardPaths,

      exact: true,
    })
  );

  const isLimitedSentimentMap = Boolean(
    useRouteMatch({
      path: Paths.reports.LimitedSentimentMapPaths,
      exact: true,
    })
  );

  const isLimitedExperienceMap = Boolean(
    useRouteMatch({
      path: Paths.reports.limitedExperienceMap,
      exact: true,
    })
  );

  const isLimitedReport = isLimitedDashboard || isLimitedSentimentMap || isLimitedExperienceMap;

  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [page, setPage] = useState(null);
  const [shareLinkDialogTrigger, setShareLinkDialogTrigger] = useState({
    open: false,
    shareReportType: SHARE_REPORT_TYPE.entireReport,
    sharePage: null,
    shareLink: '',
  });

  const { track } = useAnalytics();

  const { data: wevo, isLoading, isError } = useWevo(wevoId);
  const { data: groups = [] } = useFetchCustomQuestions(wevo?.id);

  const assetExistsInScopes = useCallback(
    (asset, groupToShowId) => {
      const id = asset?.id?.toString();
      const groupToShow = groups?.find((group) => group.groupNumber === groupToShowId);
      const found =
        wevo?.type === WevoType.Classic
          ? groupToShow?.scopes?.find((scope) => scope.wevoPageId === id)
          : groupToShow?.scopes?.find((scope) => scope.stepId === id);

      return Boolean(found);
    },
    [groups, wevo?.type]
  );

  const assets = useMemo(() => {
    if (wevo?.type === WevoType.Classic) {
      return sortPages(wevo?.pages ?? []);
    }
    return sortPages(wevo?.pages?.[0]?.steps ?? []);
  }, [wevo?.pages, wevo?.type]);

  const assetsInGroup = useMemo(() => {
    const groupToShowId = Number(questionDetailsMatch?.params?.groupNum ?? 1);
    const filteredAssets = assets?.filter((asset) => assetExistsInScopes(asset, groupToShowId));
    return filteredAssets;
  }, [questionDetailsMatch?.params?.groupNum, assets, assetExistsInScopes]);

  const openRightDrawer = useCallback(() => {
    setRightDrawerOpen(true);
  }, [setRightDrawerOpen]);

  useEffect(() => {
    // Note, this only covers classic pages and not journey step numbers because its used for
    // setting the Drawer context. For now, there isn't a use case for rendering an image for a single step
    // in the Right Drawer since those pages (Sentiment Map, Emotion Words, Stay or Go) already feature an image.
    // We'll need to tighten things up if we want to show step images in the Right Drawer
    let assetNum = 1;

    if (diagnosticsMatch) {
      assetNum = Number(diagnosticsMatch?.params?.pageNum);
    } else if (expectationsMatch) {
      assetNum = Number(expectationsMatch?.params?.pageNum);
    } else if (keyFindingsMatch) {
      assetNum = Number(keyFindingsMatch?.params?.pageNum);
    } else if (dashboardMatch) {
      assetNum = Number(dashboardMatch?.params?.pageNum);
    }
    setPage(wevo?.pages?.find((page) => page.pageNumber === assetNum));
  }, [diagnosticsMatch, expectationsMatch, keyFindingsMatch, dashboardMatch, wevo?.pages]);

  // as other pages become supported in the sharing of limited reports, add them here and also to LIMITED_REPORT_PAGES
  const getCurrentReportPage = () => {
    if (dashboardMatch) {
      return LIMITED_REPORT_PAGES.dashboard;
    } else if (sentimentMapMatch) {
      return LIMITED_REPORT_PAGES['sentiment-map'];
    } else if (experienceMapMatch) {
      return LIMITED_REPORT_PAGES['experience-map'];
    }
  };

  const getLimitedReportUrl = () => {
    const shareUrl = wevo?.shareUrl;
    if (sentimentMapMatch) {
      return generateLimitedReportPageUrl(shareUrl, LIMITED_REPORT_PAGES['sentiment-map']);
    } else if (experienceMapMatch) {
      return generateLimitedReportPageUrl(shareUrl, LIMITED_REPORT_PAGES['experience-map']);
    } else {
      return generateLimitedReportPageUrl(shareUrl, LIMITED_REPORT_PAGES.dashboard);
    }
  };

  const limitedReportUrl = getLimitedReportUrl();

  const handleShareButtonClick = (shareReportType) => {
    track(TrackEvent.CLICKED_SHARE_BUTTON, {
      wevoId: wevo?.analyticsId,
      testType: wevo?.type,
      buttonClicked: shareReportType === SHARE_REPORT_TYPE.limitedReport ? 'share this page' : 'share',
    });

    const shareLink = shareReportType === SHARE_REPORT_TYPE.entireReport ? wevo.shareUrl : limitedReportUrl;
    setShareLinkDialogTrigger({ open: true, shareReportType, sharePage: getCurrentReportPage(), shareLink });
  };

  const closeShareLinkDialog = () => {
    setShareLinkDialogTrigger({ ...shareLinkDialogTrigger, open: false });
  };

  // check for error or loading status
  if (isError && isAuthenticated()) {
    return <Redirect to="/" />;
  } else if (isError && !isAuthenticated()) {
    return (
      <Box className={classes.errorContainer}>
        <img className={classes.warning} src={warningIcon} alt="warning" />
        <Typography variant="h1" className={classes.errorMessage}>
          OOPS. WEVO not found.
        </Typography>
      </Box>
    );
  } else if (isLoading || _.isEmpty(wevo)) {
    return (
      <Box p={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  let noMatchRedirect = generatePath(Paths.reports.dashboard, { wevoId }); // journey

  if (wevo?.pages?.length > 1) {
    // compare
    noMatchRedirect = generatePath(Paths.reports.compare, { wevoId });
  } else {
    // single page
    if (wevo?.useVersionedDiagnostics) {
      noMatchRedirect = generatePath(Paths.reports.dashboard, { wevoId });
    } else {
      noMatchRedirect = generatePath(Paths.reports.takeaways, { wevoId });
    }
  }

  const shouldOpenRightDrawer =
    rightDrawerOpen &&
    (Boolean(dashboardMatch) ||
      Boolean(keyFindingsMatch) ||
      Boolean(diagnosticsMatch) ||
      Boolean(expectationsMatch) ||
      Boolean(questionDetailsMatch) ||
      Boolean(experienceScoreMatch) ||
      Boolean(conceptScoreMatch));

  return (
    <Box
      display="flex"
      className={classNames({
        [classes.isPreviewing]: wevo?.isPreviewing,
      })}>
      <LeftNav
        wevo={wevo}
        handleShareButtonClick={handleShareButtonClick}
        isLimitedReport={isLimitedReport}
        isLimitedDashboard={isLimitedDashboard}
        isLimitedSentimentMap={isLimitedSentimentMap}
        isLimitedExperienceMap={isLimitedExperienceMap}
      />
      <Container className={classes.container} component="main" maxWidth={false}>
        <Switch>
          <Route path={[Paths.reports.dashboard, Paths.reports.limitedDashboard]}>
            <DashboardContainer
              wevo={wevo}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={openRightDrawer}
              handleShareButtonClick={handleShareButtonClick}
              isLimitedReport={isLimitedReport}
            />
          </Route>
          <Route exact path={Paths.reports.compare}>
            <ComparePagesDashboardContainer wevo={wevo} />
          </Route>
          <Route path={Paths.reports.takeaways}>
            <TakeawaysContainer
              wevo={wevo}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={openRightDrawer}
            />
          </Route>
          <Route path={Paths.reports.expectations}>
            <ExpectationsContainer
              wevo={wevo}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={openRightDrawer}
            />
          </Route>
          <Route path={Paths.reports.diagnostics}>
            <DiagnosticsContainer
              wevo={wevo}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={openRightDrawer}
            />
          </Route>
          <Route exact path={Paths.reports.experienceScore}>
            <ExperienceScoreContainer
              wevo={wevo}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={openRightDrawer}
            />
          </Route>
          <Route exact path={Paths.reports.conceptScore}>
            <ConceptScoreContainer
              wevo={wevo}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={openRightDrawer}
            />
          </Route>
          <Route path={Paths.reports.emotionWords}>
            <EmotionWordsContainer wevo={wevo} />
          </Route>
          <Route path={[Paths.reports.sentimentMap, Paths.reports.limitedSentimentMap]}>
            <SentimentMapContainer
              wevo={wevo}
              handleShareButtonClick={handleShareButtonClick}
              isLimitedReport={isLimitedReport}
            />
          </Route>
          <Route path={[Paths.reports.experienceMap, Paths.reports.limitedExperienceMap]}>
            <ExperienceMapContainer
              wevo={wevo}
              handleShareButtonClick={handleShareButtonClick}
              isLimitedReport={isLimitedReport}
            />
          </Route>
          <Route path={Paths.reports.stayGo}>
            <StayOrGoContainer wevo={wevo} />
          </Route>
          <Route path={Paths.reports.customQuestions}>
            <CustomQuestionsContainer
              wevo={wevo}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={openRightDrawer}
            />
          </Route>
          <Route path={Paths.reports.testDetails}>
            <ViewTest wevo={wevo} />
          </Route>
          <Redirect to={noMatchRedirect} />
        </Switch>
      </Container>
      <ShareLinkDialog
        wevo={wevo}
        url={wevo.shareUrl}
        limitedReportUrl={limitedReportUrl}
        shareLinkDialogTrigger={shareLinkDialogTrigger}
        setShareLinkDialogTrigger={setShareLinkDialogTrigger}
        callback={closeShareLinkDialog}
      />
      <RightDrawer
        open={shouldOpenRightDrawer}
        setOpen={setRightDrawerOpen}
        wevo={wevo}
        page={page}
        assets={questionDetailsMatch && assetsInGroup?.length ? assetsInGroup : assets}
        selectedGroup={Number(questionDetailsMatch?.params?.groupNum ?? 1)}
      />
    </Box>
  );
};

export default JourneyContainer;
