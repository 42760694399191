import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import CloseIcon from '@mui/icons-material/Close';
import React from 'react';
import CreditDescription from '../edit/CreditDescription';

export const useStyles = makeStyles((theme) => ({
  pricingHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  closeIconGrid: {
    marginRight: theme.spacing(-1),
  },
  white: {
    color: 'white',
  },
  dialogContent: {
    marginTop: theme.spacing(1),
    height: (window.innerHeight * 6) / 8,
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const PricingModal = (props) => {
  const { handleClose, openPricing } = props;
  const classes = useStyles();

  return (
    <Dialog fullWidth maxWidth={'md'} open={openPricing} onClose={handleClose}>
      <DialogTitle className={classes.pricingHeader}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" className={classes.white}>
              Pricing and Professional Services
            </Typography>
          </Grid>
          <Grid item className={classes.closeIconGrid}>
            <IconButton onClick={handleClose} size="large">
              <CloseIcon className={classes.white} />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText component={'span'}>
          <Typography variant="h6">Pricing</Typography>
          <Box pt={1} />
          <Typography variant="body2">
            Prices are per study and use a credit system. Each Mastercard team/organization has the ability to
            purchase credits and then use them for studies. To purchase credits, please reach out to{' '}
            <a href="mailto:mastercard@wevo.ai" target="_top" className={classes.link}>
              <Typography component={'span'} variant="body2">
                mastercard@wevo.ai
              </Typography>
            </a>
            . The current credit price is $590 per credit (subject to change).
          </Typography>
          <Box pt={3} />
          <Typography variant="body2">
            Pricing per study depends on the audience you are looking to target with your study. If a target
            participant / audience is narrow to a degree that incident rates drop below 30%, WEVO may propose
            new pricing for the test that Mastercard may elect to accept.
          </Typography>
          <Box pt={3} />
          <Grid container spacing={3} direction="row" justifyContent="center">
            <Grid item>
              <CreditDescription classes={classes} title={'B2B'} credit={'8 credits'} />
            </Grid>
            <Grid item>
              <CreditDescription classes={classes} title={'B2C'} credit={'2 credits'} />
            </Grid>
            <Grid item>
              <CreditDescription
                classes={classes}
                title={'Bring Your Own Panel'}
                credit={'1 credit'}
                BYOP={true}
              />
            </Grid>
          </Grid>
          <Box pt={3} />
          <Typography variant="body2">
            For
            <b> Compare </b>
            and
            <b> Journey </b>
            Diagnostic studies, each page is charged separately (Example: if
            <b> comparing </b>
            one Mastercard page versus 2 competitors pages, this would be a 3 page study. For a<b> Journey </b>
            study, in which 2 Insight pages were requested for B2C audience, the cost would be 2x2=4 credits.)
          </Typography>
          <Box pt={5} />
          <Typography variant="h6">Professional Services</Typography>
          <Box pt={1} />
          <Typography variant="body2">
            WEVO also offers a variety of Professional Services you can add on to your studies. For any
            additional questions or details about these, please reach out to{' '}
            <a href="mailto:mastercard@wevo.ai" target="_top" className={classes.link}>
              <Typography component={'span'} variant="body2">
                mastercard@wevo.ai
              </Typography>
            </a>
            . Prices may vary depending on the audience and number of participants, and WEVO will confirm with
            you prior to launching.
          </Typography>
          <Typography variant="body2">They include:</Typography>
          <Box pt={3} />
          <Grid container spacing={1} direction="row" justifyContent="center">
            <Grid item xs={12}>
              <CreditDescription
                classes={classes}
                title={'Diagnostic/Compare study key findings'}
                credit={'1 credit per study'}
                showServices={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CreditDescription
                classes={classes}
                title={'Moderated/Unmoderated study test planning and setup'}
                credit={'2 credits per study'}
                showServices={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CreditDescription
                classes={classes}
                title={'Mod/Unmod/Custom Survey key findings/study summary'}
                credit={'8 credits per study'}
                showServices={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CreditDescription
                classes={classes}
                title={'Professional Moderator for moderated studies'}
                credit={'8 credits per study'}
                showServices={true}
              />
            </Grid>
            <Grid item xs={12}>
              <CreditDescription
                classes={classes}
                title={'Translating study screeners/questions/transcripts'}
                credit={'1 credit per 4,800 words'}
                showServices={true}
              />
            </Grid>
          </Grid>
          <Box pt={2} />
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default PricingModal;
