import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchSubscriptionSeats = async ({ queryKey: [key, { includeUsers }] }) => {
  const response = await axios({
    url: `/api/v2/pulse-seats?includeUsers=${includeUsers}`,
    method: 'GET',
  });
  return response?.data?.seats ?? [];
};

export default function useFetchSubscriptionSeats({ includeUsers }) {
  return useQuery(['subscriptionSeats', { includeUsers }], fetchSubscriptionSeats);
}
