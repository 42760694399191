import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ReactComponent as FlagIcon } from '../../../../assets/intake-experience-flag.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/intake-experience-location.svg';
import { ReactComponent as RestartIcon } from '../../../../assets/restart.svg';
import { ReactComponent as SuccessRateIcon } from '../../../../assets/success-rate-icon.svg';
import { ExperienceEndScreenType } from '../../../../modules/intake/constants';
import {
  EXPERIENCE_OUTCOMES,
  EXPERIENCE_OUTCOME_TYPES,
  HASH_LINKS,
} from '../../../../modules/report/constants';
import { getNumOfSuccessfulOutcomes } from '../../../../modules/report/experienceMaps';
import { numberToOrdinal } from '../../../../modules/report/helpers';
import ExperiencePathStepCarousel from './ExperiencePathStepCarousel';
import ExperienceStepCard from './ExperienceStepCard';
import PathBlocks from './PathBlocks';
import PathFilter from './PathFilter';

const FirstCard = ({
  step,
  numOfVisitors,
  numOfSuccessfulOutcomes,
  startStepId,
  isFirstPathStep,
  onClick,
}) => {
  const name = step?.configuration?.name;
  const image = step?.staticImage;

  const isStartStep = step?.id === startStepId;

  return (
    <Box>
      <Grid container columnGap={1}>
        <Grid item sx={{ order: { md: isFirstPathStep ? 0 : 1 } }}>
          <Box>
            <ExperienceStepCard
              name={name}
              image={image}
              width={isFirstPathStep ? 180 : 120}
              height={isFirstPathStep ? 180 : 120}
              icon={isStartStep ? <FlagIcon style={{ width: 20, height: 20 }} /> : <></>}
            />
            {!isFirstPathStep && (
              <Box display="flex" justifyContent="center" mt={1}>
                <Button
                  startIcon={<RestartIcon />}
                  onClick={onClick}
                  sx={{ color: 'inherit', fontSize: 13, textTransform: 'none', fontWeight: 'inherit' }}>
                  Restart
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: '100%',
            }}>
            <Grid container alignItems="center" columnGap={1}>
              <Grid item display="flex" justifyContent="flex-end" sx={{ minWidth: 24 }}>
                <PeopleOutlineIcon />
              </Grid>
              <Grid item>
                <Typography variant="body2" sx={{ minWidth: 24 }}>
                  {numOfVisitors}
                </Typography>
              </Grid>
            </Grid>
            {isFirstPathStep && (
              <Grid container alignItems="center" columnGap={1} py={1}>
                <Grid item display="flex" justifyContent="flex-end" sx={{ minWidth: 24 }}>
                  <SuccessRateIcon style={{ width: 14, height: 14 }} />
                </Grid>
                <Grid item>
                  <Typography variant="body2">{numOfSuccessfulOutcomes} successful</Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

FirstCard.propTypes = {
  step: PropTypes.object.isRequired,
  numOfVisitors: PropTypes.number.isRequired,
  numberOfSuccessfulOutcomes: PropTypes.number,
  startStepId: PropTypes.string.isRequired,
  isFirstPathStep: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

const ExperiencePaths = ({ experience, outcomes }) => {
  const [stepsAtCurrentPathIndex, setStepsAtCurrentPathIndex] = useState([]);
  const [currentStepsAndOutcomes, setCurrentStepsAndOutcomes] = useState({});
  const [filters, setFilters] = useState({
    outcomes: Object.values(EXPERIENCE_OUTCOMES),
  });

  const steps = useMemo(() => experience?.steps, [experience]);

  const startStep = useMemo(() => {
    return (steps || []).find(
      (step) => step?.configuration?.id === experience?.configuration?.entryPointNodes?.[0]
    );
  }, [experience, steps]);

  const destinationStep = useMemo(() => {
    return (steps || []).find((step) => step?.endScreenType === ExperienceEndScreenType.Success);
  }, [steps]);

  const filteredOutcomes = useMemo(
    () =>
      !_.isNil(outcomes)
        ? outcomes?.filter((outcome) => filters.outcomes.includes(EXPERIENCE_OUTCOMES[outcome.outcome]))
        : null,
    [outcomes, filters]
  );

  const initialPathStep = useCallback(() => {
    const id = startStep?.id;
    const numOfVisitors = filteredOutcomes?.length;
    const numOfSuccessfulOutcomes = getNumOfSuccessfulOutcomes(filteredOutcomes);
    return { id, numOfVisitors, numOfSuccessfulOutcomes };
  }, [filteredOutcomes, startStep]);

  const [pathSteps, setPathSteps] = useState([initialPathStep()]);
  const [pathOutcomes, setPathOutcomes] = useState(outcomes);
  const selectedOutcomeFilters = useMemo(() => filters.outcomes, [filters]);

  // reset state variables when the experience or outcomes change
  useEffect(() => {
    setPathSteps([initialPathStep()]);
    setStepsAtCurrentPathIndex([]);
    setCurrentStepsAndOutcomes({});
    setPathOutcomes(outcomes);
    setFilters({
      outcomes: Object.values(EXPERIENCE_OUTCOMES),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experience, outcomes]);

  const firstCardStep = useMemo(() => {
    // the path step before the current one or the first path step (start step)
    const stepId = pathSteps[pathSteps.length - 2]?.id || pathSteps[0]?.id;
    return steps?.find((step) => step.id === stepId);
  }, [pathSteps, steps]);

  const firstCardStepMetrics = useMemo(() => {
    return pathSteps[pathSteps.length - 2] || pathSteps[0];
  }, [pathSteps]);

  const lastPathStepIsDestinationStep = useMemo(
    () => pathSteps[pathSteps.length - 1]?.id === destinationStep?.id,
    [destinationStep, pathSteps]
  );

  const getStepOutcomes = (pathStepIndex, stepId) => {
    return pathOutcomes.filter((outcome) => outcome.path[pathStepIndex] === stepId);
  };

  const nextStepsAndVisitors = useMemo(() => {
    const stepsMapping = steps?.reduce((acc, step) => {
      acc[step.id] = [];
      return acc;
    }, {});

    // will be used to render the give up block
    stepsMapping['giveUps'] = [];

    const nextPathStepIndex = pathSteps.length;

    pathOutcomes?.forEach((outcome) => {
      const nextStepId = outcome.path[nextPathStepIndex];
      if (nextStepId) {
        stepsMapping[nextStepId]?.push(outcome);
      } else if (outcome.outcome === EXPERIENCE_OUTCOME_TYPES.success) {
        // the path for a successful outcome doesn't include the destination step
        // so if we've reached the end of the path steps for an outcome and it was a success,
        // it means the respondent reached the destination step
        stepsMapping[destinationStep.id]?.push(outcome);
      } else {
        // if there aren't any steps left in the path of an outcome, and it was a failure,
        // it means the respondent gave up
        stepsMapping['giveUps']?.push(outcome);
      }
    });
    return stepsMapping;
  }, [steps, pathOutcomes, pathSteps, destinationStep]);

  const numOfBlocks = useMemo(
    () => Object.entries(nextStepsAndVisitors).filter(([key, value]) => value.length > 0).length,
    [nextStepsAndVisitors]
  );

  const isEnd = useMemo(
    () => steps.every((step) => !nextStepsAndVisitors[step?.id]?.length),
    [steps, nextStepsAndVisitors]
  );

  const onOutcomeFilterChange = (outcomeFilters) => {
    setFilters((filters) => ({
      ...filters,
      outcomes: outcomeFilters,
    }));

    const newFilteredOutcomes = outcomes?.filter((outcome) =>
      outcomeFilters.includes(EXPERIENCE_OUTCOMES[outcome.outcome])
    );

    const numOfSuccessfulOutcomes = getNumOfSuccessfulOutcomes(newFilteredOutcomes);
    setPathSteps([{ id: startStep.id, numOfVisitors: newFilteredOutcomes.length, numOfSuccessfulOutcomes }]);
    setStepsAtCurrentPathIndex([]);
    setCurrentStepsAndOutcomes({});
    setPathOutcomes(newFilteredOutcomes);
  };

  const handleNextStepSelection = (stepId) => {
    const carouselSteps = [];
    // first step in array will be the selected step
    carouselSteps[0] = steps?.find((step) => step.id === stepId);

    steps?.forEach((step) => {
      if (nextStepsAndVisitors[step?.id].length > 0 && step.id !== stepId) {
        carouselSteps.push(step);
      }
    });
    setStepsAtCurrentPathIndex(carouselSteps);
    setCurrentStepsAndOutcomes(nextStepsAndVisitors);

    const outcomes = getStepOutcomes(pathSteps.length, stepId);

    if (stepId === destinationStep.id && !outcomes.length) {
      setPathOutcomes(nextStepsAndVisitors[stepId]);
    } else {
      setPathOutcomes(outcomes);
    }

    const selectedStep = { id: stepId, numOfVisitors: nextStepsAndVisitors[stepId].length };
    setPathSteps([...pathSteps, selectedStep]);
  };

  const handleCurrentPathStepSelection = (stepId) => {
    if (pathSteps.length > 1) {
      const lastIndex = pathSteps.length - 1;
      const newPathSteps = [...pathSteps];
      newPathSteps[lastIndex] = { id: stepId, numOfVisitors: currentStepsAndOutcomes[stepId].length };
      setPathSteps(newPathSteps);
      setPathOutcomes(currentStepsAndOutcomes[stepId]);
    }
  };

  const handleRestartClick = () => {
    setPathSteps([initialPathStep()]);
    setStepsAtCurrentPathIndex([]);
    setCurrentStepsAndOutcomes({});
    setPathOutcomes(filteredOutcomes);
  };

  return (
    <Paper elevation={4} sx={{ padding: 4, borderRadius: '20px' }} id={HASH_LINKS.experiencePaths}>
      <Box mb={4}>
        <Typography variant="h5">How respondents engaged - Paths</Typography>
      </Box>
      <Grid container mb={2}>
        <Grid item xs={3}>
          <PathFilter
            selectedOutcomeFilters={selectedOutcomeFilters}
            onOutcomeFilterChange={onOutcomeFilterChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        sx={{
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: { md: 'space-between' },
          alignItems: { md: 'center' },
          flexWrap: { md: 'nowrap' },
          rowGap: 4,
          columnGap: { md: 1, lg: 0 },
        }}>
        {filteredOutcomes.length > 0 ? (
          <>
            {firstCardStep && (
              <Grid item>
                <Box>
                  <Typography variant="body2" mb={2}>
                    {pathSteps.length < 3 ? 'Start page' : `${numberToOrdinal(pathSteps.length - 1)} visit`}
                  </Typography>
                  <FirstCard
                    step={firstCardStep}
                    numOfVisitors={firstCardStepMetrics?.numOfVisitors}
                    numOfSuccessfulOutcomes={
                      pathSteps.length === 1 && firstCardStepMetrics?.numOfSuccessfulOutcomes
                    }
                    startStepId={startStep?.id}
                    isFirstPathStep={pathSteps.length === 1}
                    onClick={handleRestartClick}
                  />
                </Box>
              </Grid>
            )}
            {pathSteps.length > 1 && (
              <Grid item>
                <Box>
                  <Typography variant="body2" mb={2}>
                    {numberToOrdinal(pathSteps.length)} visit
                  </Typography>
                  <ExperiencePathStepCarousel
                    experience={experience}
                    steps={steps}
                    stepsAtCurrentPathIndex={stepsAtCurrentPathIndex}
                    pathOutcomes={pathOutcomes}
                    onClick={handleCurrentPathStepSelection}
                  />
                </Box>
              </Grid>
            )}
            {!lastPathStepIsDestinationStep ? (
              // blocks for next steps including give up
              <Grid
                item
                sx={{
                  height: 650,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: { md: numOfBlocks < 4 ? 'center' : 'start' },
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  marginRight: { xs: 'auto', md: 0 },
                }}>
                <Box>
                  <Typography variant="body2">
                    {isEnd ? 'End' : `${numberToOrdinal(pathSteps.length + 1)} visit`}
                  </Typography>
                  <PathBlocks
                    steps={steps}
                    visitorsPerStep={nextStepsAndVisitors}
                    startStep={startStep}
                    destinationStep={destinationStep}
                    onStepSelection={handleNextStepSelection}
                  />
                </Box>
              </Grid>
            ) : (
              // task completed block
              <Grid
                item
                sx={{
                  height: 650,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: { md: 'center' },
                }}>
                <Box>
                  <Typography variant="body2">End</Typography>
                  <Grid container sx={{ paddingY: 2, paddingRight: { lg: 2 }, columnGap: 1 }}>
                    <Grid item sx={{ minWidth: 62, order: { xs: 1, md: 0 } }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          height: '100%',
                        }}>
                        <Grid container alignItems="center" columnGap={2}>
                          <Grid item display="flex" justifyContent="flex-end" sx={{ minWidth: 24 }}>
                            <PeopleOutlineIcon />
                          </Grid>
                          <Grid item>
                            <Typography variant="body2">
                              {pathSteps[pathSteps.length - 1].numOfVisitors}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                    <Grid item>
                      <ExperienceStepCard
                        width={226}
                        height={82}
                        icon={<LocationIcon style={{ width: 20, height: 20 }} />}
                        text={'Task completed'}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            )}
          </>
        ) : (
          <Box my={4} sx={{ width: '100%' }}>
            <Typography align="center">Sorry! There are no paths to display.</Typography>
          </Box>
        )}
      </Grid>
    </Paper>
  );
};

ExperiencePaths.propTypes = {
  experience: PropTypes.object.isRequired,
  outcomes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ExperiencePaths;
