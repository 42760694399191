import { ToggleButton } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

const StyledToggleButton = withStyles({
  root: {
    color: 'rgb(125, 125, 125)',
    backgroundColor: 'white',
    '&.Mui-selected': {
      color: 'white',
      backgroundColor: '#276EB0',
      '&:hover': {
        backgroundColor: 'rgba(39,110,176, 0.85)',
      },
    },
  },
})(ToggleButton);

export default StyledToggleButton;
