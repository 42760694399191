import { Box, CircularProgress, FormControlLabel, Grid, Paper, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { filterQuotes, Sentiments } from '../../../modules/wevos/quotes';
import ImagePreview from '../../../ui/ImagePreview';
import {
  buildAudienceFilterProperties,
  buildWordFilterProperties,
  TrackEvent,
  useTrackPageLoad,
} from '../../analytics';
import { shouldTrackAudienceFilterChange } from '../../analytics/eventPropertyHelpers';
import { EmotionWordChip, SentimentChip } from '../components/QuoteChip';
import QuotesTable from '../components/QuotesTable';
import { EmotionWordFilter, SentimentFilter } from '../components/QuoteWordFilter';
import usePageAttributes from '../hooks/usePageAttributes';
import WordGraph from './WordGraph';

const PageAttributes = (props) => {
  const { wevo, page, step } = props;

  const [showAll, setShowAll] = useState(false);
  const [filters, setFilters] = useState({ emotionWords: [], sentiments: [], search: '' });
  const [quotes, setQuotes] = useState([]);

  const {
    data: pageAttributes,
    isLoading,
    isFetching,
  } = usePageAttributes(wevo.id, step.id, {
    onSuccess: (data) => {
      setQuotes(data?.quotes);
    },
  });

  const wordScores = useMemo(() => _.get(pageAttributes, 'scores', []), [pageAttributes]);
  const allWords = useMemo(
    // sort by score here so the list of filters is in the same order as the WordGraph
    () => wordScores.sort((a, b) => b.score - a.score).map((wordScore) => wordScore.word),
    [wordScores]
  );
  const filteredQuotes = useMemo(() => filterQuotes(quotes, filters), [quotes, filters]);
  const selectedWords = useMemo(() => filters.emotionWords, [filters]);
  const selectedSentiments = useMemo(() => filters.sentiments, [filters]);

  const { track } = useAnalytics();

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_PAGE_ATTRIBUTES,
    properties: {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      step: step?.id,
      stepNumber: step?.stepNumber,
    },
  });

  const handleShowAllSwitch = () => {
    setShowAll((showAll) => !showAll);
  };

  const onQuoteUpdate = ({ id, updates }) => {
    const quoteIndex = quotes.findIndex((quote) => quote.id === id);
    const updatedQuote = { ...quotes[quoteIndex], ...updates };
    const updatedQuotes = [...quotes];
    updatedQuotes[quoteIndex] = updatedQuote;
    setQuotes(updatedQuotes);
  };

  // Called by QuoteSearch if search query has changed.
  const onSearchChange = (searchQuery) => {
    if (searchQuery) {
      track(TrackEvent.SEARCHED_QUOTE, {
        searchQuery,
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      });
    }

    setFilters((filters) => ({
      ...filters,
      search: searchQuery,
    }));
  };

  // Called by QuoteAudienceFilter if audience filters are changed.
  const onAudienceFilterChange = (audienceFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      audienceFilters,
      filters.audience,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, audienceFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_AUDIENCE, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      audience: audienceFilters,
    }));
  };

  // Called by CustomScreenerFilter if custom screener filters are changed.
  const onCustomScreenerFilterChange = (screenerFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      screenerFilters,
      filters.screeners,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, screenerFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_CUSTOM_SCREENER, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      screeners: screenerFilters,
    }));
  };

  /**
   * Called by WordGraph and WordFilter if selected words list is changed.
   * @param {string[]} selectedWords
   */
  const onWordChange = (selectedWords) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_EMOTION,
      buildWordFilterProperties(selectedWords, filters.emotionWords, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      })
    );

    setFilters((filters) => ({
      ...filters,
      emotionWords: selectedWords,
    }));
  };

  /**
   * Called by WordGraph and WordFilter if selected words list is changed.
   * @param {string[]} selectedWords
   */
  const onSentimentChange = (selectedSentiments) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_SENTIMENT,
      buildWordFilterProperties(selectedSentiments, filters.sentiments, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      })
    );

    setFilters((filters) => ({
      ...filters,
      sentiments: selectedSentiments,
    }));
  };

  /**
   * Remove a given word from the list of selected emotionWords in the filters
   * @param {string} deselectedWord
   */
  const deselectWord = (deselectedWord) => {
    track(TrackEvent.DESELECTED_QUOTE_FILTER, {
      filters: [deselectedWord],
      deselected: true,
      location: 'chip',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      stepId: step?.id,
      stepNumber: step?.stepNumber,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      sentiments: filters.sentiments.filter((sentiment) => sentiment !== deselectedWord),
      emotionWords: filters.emotionWords.filter((word) => word !== deselectedWord),
    }));
  };

  /**
   * Clear emotion word and sentiment filters
   */
  const clearWordFilters = () => {
    track(TrackEvent.CLEARED_QUOTE_FILTERS, {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      stepId: step?.id,
      stepNumber: step?.stepNumber,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      sentiments: [],
      emotionWords: [],
    }));
  };

  if (isLoading) {
    return (
      <div style={{ padding: '32px', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  let title = `${filteredQuotes.length} Quotes`;
  if (quotes.length !== filteredQuotes.length) {
    title += ` (out of ${quotes.length})`;
  }

  return (
    <Box sx={{ paddingRight: { md: 4 } }}>
      <Typography variant="body1">
        Learn how visitors score your page on key customer experience attributes
        {isFetching && <Typography variant="caption">...updating</Typography>}
      </Typography>

      <Paper
        elevation={4}
        sx={{
          marginTop: 4,
          paddingBottom: 2,
          paddingLeft: { md: 2 },
          paddingRight: { md: 2 },
          borderRadius: '20px',
        }}>
        <Grid container alignItems="center">
          <Grid
            item
            md={8}
            xs={12}
            sx={{
              paddingTop: 4,
              paddingBottom: 4,
              paddingRight: 4,
              borderRight: { md: '1px solid rgb(189,189,189)' },
            }}>
            <WordGraph
              wordScores={wordScores}
              selectedWords={selectedWords}
              showAll={showAll}
              onChange={onWordChange}
            />

            <Box sx={{ textAlign: 'right' }}>
              <FormControlLabel
                control={<Switch checked={showAll} color="primary" onChange={handleShowAllSwitch} />}
                label="Show All"
              />
            </Box>
          </Grid>

          <Grid item md={4} xs={12} sx={{ textAlign: 'center', padding: 4 }}>
            <ImagePreview image={step.images} maxHeight={250} maxWidth={250} />
          </Grid>
        </Grid>

        <Box borderTop={1} borderColor="grey.400" sx={{ padding: { md: 4 } }}>
          <Grid container>
            <Grid item xs={12}>
              <QuotesTable
                wevo={wevo}
                page={page}
                fullQuotes={quotes}
                displayQuotes={filteredQuotes}
                onQuoteUpdate={onQuoteUpdate}
                onAudienceFilterChange={onAudienceFilterChange}
                onCustomScreenerFilterChange={onCustomScreenerFilterChange}
                clearWordFilters={clearWordFilters}
                onSearchChange={onSearchChange}
                searchQuery={filters?.search}
                title={title}
                selectedWords={[...selectedWords, ...selectedSentiments]}
                deselectWord={deselectWord}
                filters={[
                  <EmotionWordFilter
                    words={allWords}
                    selectedWords={selectedWords}
                    onWordFilterChange={onWordChange}
                  />,
                  <SentimentFilter
                    sentiments={Object.values(Sentiments)}
                    selectedSentiments={selectedSentiments}
                    onSentimentFilterChange={onSentimentChange}
                  />,
                ]}
                chips={[EmotionWordChip, SentimentChip]}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

PageAttributes.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
};

export default PageAttributes;
