import { Plate } from '@udecode/plate-headless';
import { isEmpty as _isEmpty } from 'lodash';
import { PLATE_CONFIG } from '../../modules/plate/constants';
import FormatPopper from './FormatPopper';
import FormatRow from './FormatRow';

const PlateEditor = ({ editor, editorId, initialValue, readOnly }) => {
  return (
    <Plate
      id={editorId}
      editor={editor}
      initialValue={!_isEmpty(initialValue) ? initialValue : [{ children: [{ text: '' }] }]}
      editableProps={{ ...PLATE_CONFIG.editableProps, readOnly }}>
      {!readOnly && <FormatRow editorId={editorId} />}
      <FormatPopper editorId={editorId} />
    </Plate>
  );
};

export default PlateEditor;
