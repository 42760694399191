import { useQuery } from 'react-query';
import axios from '../../../api';

const getPageAttributes = async ({ queryKey: [key, { wevoId, stepId }] }) => {
  const { data } = await axios({ url: `/api/v2/wevos/${wevoId}/steps/${stepId}/findings`, method: 'GET' });
  return data;
};

export default function usePageAttributes(wevoId, stepId, options) {
  return useQuery(['pageAttributes', { wevoId, stepId }], getPageAttributes, options);
}
