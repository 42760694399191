import { Button, Grid, Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { debounce as _debounce, isEmpty as _isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { CustomQuestionTypes, MultiSelectOptions, SelectOptions } from '../../../modules/intake/constants';
import { snackbar } from '../../../notifications';
import CustomChoice from '../edit/CustomChoice';
import useUpdateCustomQuestion from '../hooks/useUpdateCustomQuestion';

const useStyles = makeStyles((theme) => ({
  customChoice: {
    marginTop: theme.spacing(1),
  },
  multipleSection: {
    marginTop: '10px',
    marginBottom: '20px',
  },
  addIcon: {
    color: grey[600],
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}));

const MAX_CHOICES = {
  [CustomQuestionTypes.MultipleChoice]: 10,
  [CustomQuestionTypes.YesNoTrueFalse]: 2,
  [CustomQuestionTypes.FiveLikertScale]: 5,
  [CustomQuestionTypes.SevenLikertScale]: 7,
  [CustomQuestionTypes.MultiSelect]: 15,
};

const MIN_NUM_RESPONSES = 1;

const MultipleChoice = (props) => {
  const { customLabels, setCustomLabels, type, wevoId, groupId, selectionRange, numOfSelections } = props;

  const classes = useStyles();
  const [choices, setChoices] = useState(customLabels);

  const { mutate: updateCustomQuestion } = useUpdateCustomQuestion();

  const handleAddCustomChoiceClick = () => {
    setChoices((choices) => [...choices, '']);
  };

  const handleCustomChoiceChange = (value, index) => {
    const prevCustomLabels = Array.from(customLabels);

    const items = Array.from(choices);
    items[index] = value;
    const filteredItems = items.filter((item) => !_isEmpty(item));

    const newLabels = [CustomQuestionTypes.MultipleChoice, CustomQuestionTypes.MultiSelect].includes(type)
      ? filteredItems
      : items;

    setChoices(items);
    setCustomLabels(newLabels);

    debounceHandleCustomChoiceChange(newLabels, prevCustomLabels);
  };

  /* Update customChoice */
  const debounceHandleCustomChoiceChange = useMemo(
    () =>
      _debounce((newLabels, prevCustomLabels) => {
        let validation;
        let randomization;
        if (type === CustomQuestionTypes.MultiSelect && selectionRange === MultiSelectOptions.AllThatApply) {
          validation = {
            type: 'range',
            rangeType: selectionRange,
            number: numOfSelections,
            min: MIN_NUM_RESPONSES,
            max: newLabels.length,
          };
          randomization = { type: 'all' };
        } else if (type === CustomQuestionTypes.MultiSelect && selectionRange === MultiSelectOptions.AtLeast) {
          validation = {
            type: 'range',
            rangeType: selectionRange,
            number: numOfSelections,
            min: numOfSelections,
            max: newLabels.length,
          };
          randomization = { type: 'all' };
        }

        updateCustomQuestion(
          {
            id: wevoId,
            groupId: groupId,
            labels: newLabels,
            labelsType: SelectOptions.Custom,
            validation,
            randomization,
          },
          {
            onError: (err, variables, { previousCustomQuestionsState }) => {
              const index = previousCustomQuestionsState?.findIndex(
                (q) => String(q.groupId) === String(groupId)
              );
              if (index >= 0) {
                const prevChoices = previousCustomQuestionsState?.[index]?.question?.labels ?? '';
                setChoices(prevChoices);
                setCustomLabels(prevChoices);
              }

              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');
            },
          }
        );
      }, 1000),
    [wevoId, groupId, updateCustomQuestion, setCustomLabels, type, numOfSelections, selectionRange]
  );

  const handleCustomChoiceDelete = (index) => {
    // Assuming there are allways at least 2 mulitpleChoices
    if (choices.length > 2) {
      const prevChoices = Array.from(choices);
      const prevCustomLabels = Array.from(customLabels);

      const items = Array.from(choices);
      const newArray = [...items.slice(0, index), ...items.slice(index + 1)];
      const filteredItems = newArray.filter((item) => !_isEmpty(item));

      updateCustomChoiceDelete(
        filteredItems,
        SelectOptions.Custom,
        newArray,
        filteredItems,
        prevChoices,
        prevCustomLabels
      );
    }
  };

  /* Update labelsType and labels together */
  const updateCustomChoiceDelete = useMemo(
    () => (newLabels, newLabelsType, newArray, filteredItems, prevChoices, prevCustomLabels) => {
      let validation;
      let randomization;
      if (type === CustomQuestionTypes.MultiSelect && selectionRange === MultiSelectOptions.AllThatApply) {
        validation = {
          type: 'range',
          rangeType: selectionRange,
          number: numOfSelections,
          min: MIN_NUM_RESPONSES,
          max: newLabels.length,
        };
        randomization = { type: 'all' };
      } else if (type === CustomQuestionTypes.MultiSelect && selectionRange === MultiSelectOptions.AtLeast) {
        validation = {
          type: 'range',
          rangeType: selectionRange,
          number: numOfSelections,
          min: numOfSelections,
          max: newLabels.length,
        };
        randomization = { type: 'all' };
      }

      updateCustomQuestion(
        {
          id: wevoId,
          groupId: groupId,
          labelsType: newLabelsType,
          labels: newLabels,
          validation,
          randomization,
        },
        {
          onSuccess: () => {
            setChoices(newArray);
            setCustomLabels(filteredItems);
          },
          onError: (err) => {
            setChoices(prevChoices);
            setCustomLabels(prevCustomLabels);

            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');
          },
        }
      );
    },
    [wevoId, groupId, updateCustomQuestion, setChoices, setCustomLabels, type, numOfSelections, selectionRange]
  );

  return (
    <Grid container spacing={2} justifyContent="flex-end" className={classes.multipleSection}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          {choices.map((choice, index) => (
            <Grid item key={index} xs={12} className={classes.customChoice}>
              <CustomChoice
                customChoice={choice}
                handleCustomChoiceChange={(value) => handleCustomChoiceChange(value, index)}
                handleCustomChoiceDelete={() => handleCustomChoiceDelete(index)}
                questionType={type}
                choicesLength={choices.length}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {choices?.length < MAX_CHOICES[type] && (
        <Grid item align="center" xs={12}>
          <Button onClick={handleAddCustomChoiceClick} className={classes.addIcon}>
            <Tooltip title={`add custom label`} placement="right">
              <AddCircleIcon className={classes.addIcon} />
            </Tooltip>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default MultipleChoice;
