import AddBoxIcon from '@mui/icons-material/AddBox';
import { Card, CardActionArea, CircularProgress, Grid, Tooltip, Typography } from '@mui/material';

const styles = {
  addQuestionCard: {
    height: (theme) => theme.spacing(5),
  },
  addIcon: {
    color: (theme) => theme.palette.primary.main,
  },
  disabled: {
    color: 'rgba(0,0,0,0.26)',
  },
};

const AddQuestionCard = ({
  height,
  text,
  exceedsLimits,
  exceedsLimitTooltipText = '',
  onClick,
  isLoading,
}) => {
  return (
    <Tooltip
      title={exceedsLimits ? exceedsLimitTooltipText : ''}
      placement="right"
      arrow
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            maxWidth: 250,
          },
        },
      }}>
      <Card sx={{ height: (theme) => height || theme.spacing(6) }}>
        <CardActionArea
          onClick={onClick}
          disabled={exceedsLimits || isLoading}
          sx={{ height: '100%', paddingX: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h5" sx={{ ...((exceedsLimits || isLoading) && styles.disabled) }}>
                {text || 'Add a question'}
              </Typography>
            </Grid>
            <Grid item display="flex" alignItems="center">
              {isLoading ? (
                <CircularProgress size={20} />
              ) : (
                <AddBoxIcon sx={{ ...styles.addIcon, ...(exceedsLimits && styles.disabled) }} />
              )}
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    </Tooltip>
  );
};

export default AddQuestionCard;
