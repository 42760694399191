import { BarElement, Chart, PieController } from 'chart.js';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { getStayOrGoChartColor } from '../../../modules/report/constants';
import { memoAreEqual } from '../../../helpers';

Chart.register(PieController, BarElement);

const PageFlowChart = React.memo((props) => {
  const { score } = props;
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  const config = useMemo(
    () => ({
      type: 'bar',
      data: {
        labels: ['stay', 'go'],
        datasets: [
          {
            data: [score],
            backgroundColor: [getStayOrGoChartColor(score)],
          },
          {
            data: [100 - score],
            backgroundColor: ['rgb(216, 216, 216, 0.5)'],
          },
        ],
      },
      options: {
        animation: { duration: 0 },
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: { display: false },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: { display: false },
          },
        },
        plugins: {
          tooltip: { enabled: false },
          legend: {
            display: false,
          },
        },
      },
    }),
    [score]
  );

  useEffect(() => {
    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [config]);

  return <canvas ref={canvasRef}></canvas>;
}, memoAreEqual);

PageFlowChart.propTypes = {
  score: PropTypes.number.isRequired,
};

export default memo(PageFlowChart);
