import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { get as _get, set as _set, sortBy as _sortBy } from 'lodash';
import produce from 'immer';
import { MutationKeys } from '../../../modules/intake/constants';

const updateStep = ({ wevoId, stepId, ...step }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/steps/${stepId}`,
    method: 'PUT',
    data: step,
  });
};

export default function useUpdateStep() {
  const queryClient = useQueryClient();
  return useMutation(updateStep, {
    mutationKey: MutationKeys.updateStep,
    onMutate: async ({ wevoId, stepId, ...step }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      queryClient.setQueryData(['wevoData', { wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const steps = _get(draft, 'pages.0.steps', []);

          const index = steps.findIndex((s) => String(s.id) === String(stepId));
          if (index >= 0) {
            steps[index] = {
              ...steps[index],
              ...step,
            };
          }

          _set(draft, 'pages.0.steps', _sortBy(steps, ['sortOrder']));
        });
      });

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
