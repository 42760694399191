import { useQueries, useQuery } from 'react-query';
import axios from '../../../api';

const fetchPageHeatmap = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  if (!wevoId || !pageId) {
    return [];
  }

  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/heatmap`,
    method: 'GET',
  });
  return response?.data?.points ?? [];
};

export default function useFetchPageHeatmap({ wevoId, pageId }, options) {
  return useQuery(['pageHeatMap', { wevoId, pageId }], fetchPageHeatmap, options);
}

export function usePrefetchAllPagesHeatmaps({ wevoId, pages }, options) {
  return useQueries(
    pages?.map((page) => {
      const pageId = page?.id;
      return {
        queryKey: ['pageHeatMap', { wevoId, pageId }],
        queryFn: fetchPageHeatmap,
        staleTime: 30 * 1000,
        ...(options ?? {}),
      };
    })
  );
}
