import { FormGroup, Grid, MenuItem, Select, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const styles = makeStyles((theme) => ({
  selectLabelContainer: {
    paddingRight: 10,
  },
  selectContainer: {
    maxWidth: 300,
    overflow: 'hidden',
  },
}));
export default function BaselineSelect({ pages, selectedPageId, onChangeBaseline }) {
  const classes = styles();
  const onChange = (e) => onChangeBaseline(e.target.value);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid className={classes.selectLabelContainer}>
        <Typography component="span">Baseline: </Typography>
      </Grid>
      <Grid className={classes.selectContainer}>
        <FormGroup>
          <Select variant="standard" onChange={onChange} value={selectedPageId}>
            {pages.map((page) => (
              <MenuItem key={`select-option-${page.id}`} value={page.id}>
                Page {page.label}: {page.name}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
      </Grid>
    </Grid>
  );
}
