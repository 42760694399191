import { Fragment } from 'react';
import { Box, Divider, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  sentimentBackgroundColor,
  sentimentBorderColor,
  sentimentToCluster,
} from '../../modules/automated-insights/helpers.js';

export default function SentimentMapQuote({
  setRef,
  point,
  isLastQuote,
  isLastQuoteInSection,
  isSelected,
  handleBlockClick,
}) {
  const sentiment = point?.sentiment;
  const dividerStyle = !isLastQuoteInSection ? { marginX: { xs: 2, sm: 3 }, padding: 0 } : {};
  return (
    <Fragment>
      <Box
        ref={setRef}
        sx={{
          minHeight: 80,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingY: 2,
          paddingLeft: { xs: 2, sm: 3 },
          paddingRight: { xs: 1, sm: 2 },
          backgroundColor: isSelected(point) && grey[100],
          cursor: 'pointer',
        }}
        onClick={() => handleBlockClick(point)}>
        <Typography variant="body2" sx={{ fontWeight: isSelected(point) && 500 }}>
          {point?.quote}
        </Typography>
        <Box sx={{ width: '20px' }} />
        <Box
          sx={{
            height: '20px',
            minWidth: '84px',
            maxWidth: '84px',
            borderRadius: '12px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
            boxSizing: 'border-box',
            background: sentimentBackgroundColor(sentiment),
            border: `1px solid ${sentimentBorderColor(sentiment)}`,
          }}>
          <Typography sx={{ fontSize: '10px', textTransform: 'capitalize' }}>
            {sentimentToCluster(sentiment)}
          </Typography>
        </Box>
      </Box>
      {!isLastQuote && <Divider sx={dividerStyle} />}
    </Fragment>
  );
}
