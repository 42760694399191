import { useMediaQuery, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/styles';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearDraft, fetchDraft } from '../../modules/wevos/actions';
import { wevoStatus } from '../../modules/wevos/constants';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  color: 'white',
}));

const WevoActionButton = ({ wevo }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPreviewing = wevo?.isPreviewing;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const editClickCallback = (wevoId) => {
    dispatch(clearDraft());
    dispatch(fetchDraft(wevoId));
    history.push(`/wevos/${wevoId}/edit/review`);
  };

  const viewClickCallback = (wevoId) => {
    history.push(`/wevo/${wevoId}/details`);
  };

  switch (wevo.status) {
    case wevoStatus.completed:
      return (
        <StyledButton fullWidth color="secondary" variant="contained" href={wevo.url}>
          {!!isSmallScreen ? 'Results' : 'View Results'}
        </StyledButton>
      );
    case wevoStatus.draft:
      const handleEditClick = (ev) => {
        ev.preventDefault();
        editClickCallback(wevo.id);
      };
      return (
        <StyledButton fullWidth variant="contained" onClick={handleEditClick}>
          Edit
        </StyledButton>
      );
    case wevoStatus.pending:
    case wevoStatus.running:
      const handleViewClick = (ev) => {
        ev.preventDefault();
        viewClickCallback(wevo.id);
      };
      return (
        <StyledButton
          fullWidth
          variant="contained"
          onClick={isPreviewing ? null : handleViewClick}
          href={isPreviewing ? wevo?.url : ''}>
          {isPreviewing ? 'View Preview' : 'View'}
        </StyledButton>
      );

    default:
      return null;
  }
};

export default WevoActionButton;
