import { Container, IconButton, styled, Tooltip } from '@mui/material';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as SidebarCloseIcon } from '../../assets/sidebar-close.svg';
import { ReactComponent as SidebarOpenIcon } from '../../assets/sidebar-open.svg';
import darkTheme from '../../darkTheme';
import { Links, PULSE_LEGACY_CREDIT_SUBSCRIPTION_TYPES } from '../../modules/automated-insights/constants';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { Paths } from '../../routes';
import { TrackEvent } from '../analytics';
import AppBarAI from '../appBar/AppBarAI';
import AccountSettings from '../automated-insights/accountSettings';
import OnboardingUser from '../onboarding';
import Profile from '../profile';
import ChangePassword from '../profile/Password';
import ProtectedRoute from '../router/ProtectedRoute';
import AllFAQ from './AllFAQ';
import CreateSession from './CreateSession';
import EditSessionAssets from './EditSessionAssets';
import EditSessionSettings from './EditSessionSettings';
import FAQ from './FAQ';
import useFetchPreviousSessions from './hooks/useFetchPreviousSessions';
import ImportURLConfirmation from './ImportURLConfirmation';
import InsightsReport from './InsightsReport';
import InviteColleagueDialog from './InviteColleagueDialog';
import Sidebar from './Sidebar';
import SubscriptionSelection from './SubscriptionSelection';
import TrialSignUp from './trial';

const SidebarToggleButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }) => ({
  position: 'fixed',
  top: 70,
  left: open ? 110 : 10,
  zIndex: 1500,
  ...(open && {
    transform: 'translateX(150px)',
    transition: 'transform .2s linear',
  }),
  ...(!open && {
    transform: 'translateX(0)',
    transition: 'transform .1s linear',
  }),
}));

const inputLabelStyles = {
  color: darkTheme.palette.text.secondary,
};

const inputBaseRootStyles = {
  color: darkTheme.palette.text.secondary,
  backgroundColor: 'rgba(255, 255, 255, 0.12)',
  borderRadius: '10px',
};

const updateButtonStyles = {
  color: darkTheme.palette.text.secondary,
  background: darkTheme.palette.gradient.main,
  borderRadius: '27px',
};

const figmaButtonStyles = {
  borderRadius: '27px',
  textTransform: 'none',
};

const textStyles = { color: darkTheme.palette.text.secondary };

const iconColor = 'primary';

const AutomatedInsights = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showShareDialog, setShowShareDialog] = useState(false);
  const [showInviteColleagueDialogOpen, setShowInviteColleagueDialogOpen] = useState(false);
  const [showAudienceList, setShowAudienceList] = useState(false);
  const [isAudienceDisabled, setIsAudienceDisabled] = useState(true);
  const [showShareDialogFromLeftMenu, setShowShareDialogFromLeftMenu] = useState(false);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);

  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);

  const { track } = useAnalytics();

  const { data: previousSessions } = useFetchPreviousSessions({ enabled: !!user?.id });

  const sessionPathMatch = useRouteMatch({
    path: [Paths.automatedInsights.session],
    exact: true,
  });

  const importUrlPathMatch = useRouteMatch({
    path: [Paths.automatedInsights.importURL],
    exact: true,
  });

  useEffect(() => {
    document.title = 'WEVO Pulse';
  }, []);

  const sessionId = useMemo(() => sessionPathMatch?.params?.sessionId, [sessionPathMatch]);

  const isFreeTrial = useMemo(() => {
    return user?.pulseSubscription?.isFreeTrial;
  }, [user]);

  const isFreePlan = useMemo(() => {
    return user?.pulseSubscription?.isFreePlan;
  }, [user]);

  const selfServiceSubscription = useMemo(() => {
    const shouldShowSelfServiceSubscriptions =
      userCustomizations?.features?.selfServiceSubscription !== 'false';

    const planSupportsSelfService = !PULSE_LEGACY_CREDIT_SUBSCRIPTION_TYPES.includes(
      user?.pulseSubscription?.pulseSubscriptionTier?.type
    );

    return shouldShowSelfServiceSubscriptions && planSupportsSelfService;
  }, [user, userCustomizations]);

  const showSidebar = useMemo(
    () => user?.id && !_.isEmpty(previousSessions) && !importUrlPathMatch,
    [user, previousSessions, importUrlPathMatch]
  );

  const toggleSidebar = (open) => {
    setSidebarOpen(open);
  };

  const toggleShareDialog = (open) => {
    if (open) {
      track(TrackEvent.CLICKED_PULSE_SHARE_BUTTON, { sessionId });
    }
    setShowShareDialog(open);
  };

  const toggleInviteColleagueDialog = (open) => {
    if (open) {
      track(TrackEvent.CLICKED_INVITE_COLLEAGUE_BUTTON_FROM_PULSE, { sessionId });
    }
    setShowInviteColleagueDialogOpen(open);
  };

  const handleMyAudienceClick = (show) => {
    setShowAudienceList(show);
  };

  return (
    <>
      <AppBarAI
        onToggleShareDialog={toggleShareDialog}
        onToggleInviteColleagueDialog={toggleInviteColleagueDialog}
        showAudienceList={showAudienceList}
        onShowAudienceList={handleMyAudienceClick}
        isAudienceDisabled={isAudienceDisabled}
        selfServiceSubscription={selfServiceSubscription}
        isFreeTrial={isFreeTrial}
      />
      <Switch>
        <ProtectedRoute
          exact
          path={Paths.automatedInsights.profile}
          render={(props) => (
            <Profile
              {...props}
              inputLabelStyles={inputLabelStyles}
              inputBaseRootStyles={inputBaseRootStyles}
              updateButtonStyles={updateButtonStyles}
              figmaButtonStyles={figmaButtonStyles}
              textStyles={textStyles}
              selfServiceSubscription={selfServiceSubscription}
            />
          )}
        />
        <ProtectedRoute
          exact
          path={Paths.automatedInsights.accountSettings}
          render={(props) => (
            <AccountSettings
              {...props}
              inputLabelStyles={inputLabelStyles}
              inputBaseRootStyles={inputBaseRootStyles}
              updateButtonStyles={updateButtonStyles}
              figmaButtonStyles={figmaButtonStyles}
              textStyles={textStyles}
              user={user}
              selfServiceSubscription={selfServiceSubscription}
            />
          )}
        />
        <ProtectedRoute
          exact
          path={Paths.automatedInsights.password}
          render={(props) => (
            <ChangePassword
              {...props}
              inputLabelStyles={inputLabelStyles}
              inputBaseRootStyles={inputBaseRootStyles}
              updateButtonStyles={updateButtonStyles}
              textStyles={textStyles}
              iconColor={iconColor}
            />
          )}
        />
        <Route
          path={Paths.automatedInsights.acceptInvite}
          render={({ match: { params }, location }) => {
            const { token } = params;
            return (
              <OnboardingUser
                inputLabelStyles={inputLabelStyles}
                inputBaseRootStyles={inputBaseRootStyles}
                updateButtonStyles={updateButtonStyles}
                figmaButtonStyles={figmaButtonStyles}
                textStyles={textStyles}
                token={token}
              />
            );
          }}
        />
        <Route path={Paths.automatedInsights.faq} exact component={FAQ} />
        <Route path={Paths.automatedInsights.allFaq} exact component={AllFAQ} />
        <Route
          path={Paths.automatedInsights.trial}
          exact
          render={(props) => {
            if (user?.id) {
              window.location.replace(Paths.automatedInsights.basePath);
              return <></>;
            }
            return <TrialSignUp {...props} />;
          }}
        />
        <ProtectedRoute
          exact
          path={Paths.automatedInsights.subscription}
          render={(props) => (
            <Container
              component="main"
              maxWidth="false"
              disableGutters
              sx={{
                minHeight: 'calc(100vh - 65px)',
                paddingLeft: { xs: '5%', lg: '3.5%', xl: '5%' },
                paddingRight: { xs: '5%', lg: '3.5%', xl: '5%' },
                backgroundColor: '#E6EEF2',
              }}>
              <SubscriptionSelection
                {...props}
                selfServiceSubscription={selfServiceSubscription}
                isFreeTrial={isFreeTrial}
                isFreePlan={isFreePlan}
              />
            </Container>
          )}
        />
        <Route>
          <Container
            component="main"
            maxWidth="false"
            disableGutters
            sx={{
              height: 'calc(100vh - 65px)',
              paddingLeft: { xs: '5%', lg: '3.5%', xl: '5%' },
              paddingRight: { xs: '5%', lg: '3.5%', xl: '5%' },
            }}>
            {showSidebar && (
              <>
                <Tooltip title={sidebarOpen ? 'Close sidebar' : 'Open sidebar'} arrow placement="right">
                  <SidebarToggleButton
                    aria-label="open"
                    open={sidebarOpen}
                    onClick={() => toggleSidebar(!sidebarOpen)}
                    sx={{ zIndex: showDetailsDialog || showShareDialogFromLeftMenu ? 0 : 1500 }}>
                    {sidebarOpen ? <SidebarCloseIcon /> : <SidebarOpenIcon />}
                  </SidebarToggleButton>
                </Tooltip>
                <Sidebar
                  sessionId={sessionId}
                  open={sidebarOpen}
                  onToggleSidebar={toggleSidebar}
                  previousSessions={previousSessions}
                  setShowShareDialogFromLeftMenu={setShowShareDialogFromLeftMenu}
                  setShowDetailsDialog={setShowDetailsDialog}
                  showShareDialogFromLeftMenu={showShareDialogFromLeftMenu}
                  showDetailsDialog={showDetailsDialog}
                />
              </>
            )}
            <Switch>
              <ProtectedRoute
                exact
                path={Paths.automatedInsights.basePath}
                render={(props) => (
                  <CreateSession
                    {...props}
                    onSidebarButtonClick={toggleSidebar}
                    onShowAudienceList={handleMyAudienceClick}
                  />
                )}
              />
              <ProtectedRoute
                exact
                path={Paths.automatedInsights.importURL}
                render={(props) => <ImportURLConfirmation {...props} />}
              />
              <Route
                exact
                path={Paths.automatedInsights.sessionAssets}
                render={(props) => <EditSessionAssets {...props} />}
              />
              <Route
                exact
                path={Paths.automatedInsights.sessionSettings}
                render={(props) => <EditSessionSettings {...props} />}
              />
              <Route
                exact
                path={Paths.automatedInsights.session}
                render={(props) => (
                  <InsightsReport
                    {...props}
                    showShareDialog={showShareDialog}
                    showInviteColleagueDialogOpen={showInviteColleagueDialogOpen}
                    onToggleShareDialog={toggleShareDialog}
                    onToggleInviteColleagueDialog={toggleInviteColleagueDialog}
                    showAudienceList={showAudienceList}
                    onShowAudienceList={handleMyAudienceClick}
                    isAudienceDisabled={isAudienceDisabled}
                    setIsAudienceDisabled={setIsAudienceDisabled}
                    previousSessions={previousSessions}
                  />
                )}
              />
              <Redirect to="/pulse" />
            </Switch>
            <InviteColleagueDialog
              sessionId={sessionId}
              link={Links.TakeAPulse}
              isOpen={showInviteColleagueDialogOpen || false}
              onToggleInviteColleagueDialog={toggleInviteColleagueDialog}
            />
          </Container>
        </Route>
        <Redirect to="/pulse" />
      </Switch>
    </>
  );
};

export default AutomatedInsights;
