import { useMutation } from 'react-query';
import axios from '../../../api';

const trialSignUp = async ({ email, url }) => {
  const response = await axios({
    url: `/api/v2/users/pulse/onboard`,
    method: 'POST',
    data: { email, url },
  });
  return response.data;
};

export function useTrialSignUp(options) {
  return useMutation(trialSignUp, options);
}
