import AdjustIcon from '@mui/icons-material/Adjust';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import IndeterminateCheckBoxRoundedIcon from '@mui/icons-material/IndeterminateCheckBoxRounded';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Chip, Grid, Tooltip } from '@mui/material';
import { green, red, yellow } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import {
  EmotionWordSentiment,
  getSentimentFromEmotionWordScore,
  getStepBenchmarkScore,
  showEmotionWords,
  StepBenchmarkScore,
} from '../../../../modules/wevos/constants';
import { Paths } from '../../../../routes';
import ImagePreview from '../../../../ui/ImagePreview';
import { TrackEvent } from '../../../analytics';

const stepStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  iconContainer: {
    textAlign: 'right',
  },
  title: {
    width: '100%',
    display: 'inline-block',
  },
  pageChip: {
    width: '100%',
    borderRadius: '4px',
    fontWeight: 600,
    padding: (props) => theme.spacing(Math.min(6 - props.step.topEmotionWords.length, 3)),
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('xl')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down('lg')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  pageChipGood: {
    backgroundColor: 'rgba(76, 175, 80, 0.1)',
    color: '#2B6C2D',
  },
  pageChipNeutral: {
    backgroundColor: 'rgba(255, 213, 15, 0.1)',
    color: '#AA8E0D',
  },
  pageChipBad: {
    backgroundColor: 'rgba(200, 81, 81, 0.1)',
    color: '#C85151',
  },
  badgeIconGood: {
    color: green[800],
  },
  badgeIconNeutral: {
    color: yellow[800],
  },
  badgeIconBad: {
    color: red[800],
  },
  cardHeader: {
    padding: theme.spacing(1),
    '& .MuiCardHeader-content': {
      overflow: 'hidden',
    },
    '& .MuiCardHeader-action': {
      marginTop: '0px',
    },
  },
  cardContent: {
    padding: '0px',
  },
  cardActions: {
    padding: '0px',
    marginTop: theme.spacing(1),
    justifyContent: 'flex-end',
  },
  wordIcon: {
    position: 'absolute',
    [theme.breakpoints.up('xl')]: {
      marginLeft: theme.spacing(4),
    },
    [theme.breakpoints.down('xl')]: {
      marginLeft: theme.spacing(2),
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: '1rem',
      marginLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '1rem',
      marginLeft: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1rem',
      marginLeft: theme.spacing(1),
    },
  },
  wordIconGood: {
    color: '#2B6C2D',
  },
  wordIconBad: {
    color: '#C85151',
  },
  wordIconNeutral: {
    color: '#AA8E0D',
  },
  link: {
    textDecoration: 'none',
  },
}));

const StepCard = (props) => {
  const classes = stepStyles(props);
  const { wevo, step } = props;

  const { track } = useAnalytics();

  const score = (step && step.score) || 0;

  const renderTopEmotionWord = (emotionWord) => {
    const sentiment = getSentimentFromEmotionWordScore(emotionWord);
    const word = emotionWord && emotionWord.word;

    if (!word) {
      return null;
    }

    let chipClass;
    let iconClass;
    let Icon;
    if (sentiment === EmotionWordSentiment.Good) {
      chipClass = clsx(classes.pageChip, classes.pageChipGood);
      iconClass = clsx(classes.wordIcon, classes.wordIconGood);
      Icon = ThumbUpIcon;
    } else if (sentiment === EmotionWordSentiment.Bad) {
      chipClass = clsx(classes.pageChip, classes.pageChipBad);
      iconClass = clsx(classes.wordIcon, classes.wordIconBad);
      Icon = ThumbDownIcon;
    } else {
      chipClass = clsx(classes.pageChip, classes.pageChipNeutral);
      iconClass = clsx(classes.wordIcon, classes.wordIconNeutral);
      Icon = IndeterminateCheckBoxRoundedIcon;
    }

    return (
      <Grid item xs={12} key={emotionWord.id} style={{ display: 'flex', alignItems: 'center' }}>
        <Icon className={iconClass} fontSize="small" />
        <Chip label={word} color="primary" className={chipClass} />
      </Grid>
    );
  };

  let icon = null;
  if (showEmotionWords(wevo)) {
    const stepBenchmark = getStepBenchmarkScore(score);
    if (stepBenchmark === StepBenchmarkScore.Good) {
      icon = (
        <Tooltip title="This step performed very well">
          <CheckCircleOutlineIcon className={classes.badgeIconGood} />
        </Tooltip>
      );
    } else if (stepBenchmark === StepBenchmarkScore.Neutral) {
      icon = (
        <Tooltip title="This step was ok">
          <AdjustIcon className={classes.badgeIconNeutral} />
        </Tooltip>
      );
    } else {
      icon = (
        <Tooltip title="This step had some problems">
          <ErrorOutlineIcon className={classes.badgeIconBad} />
        </Tooltip>
      );
    }
  }

  const handleSeePageClick = () => {
    track(TrackEvent.SHOW_REPORT_PAGE, {
      wevoId: wevo?.analyticsId,
      stepId: step?.id,
      stepNumber: step?.stepNumber,
      testType: wevo?.type,
    });
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.cardHeader}
        title={
          <Typography variant="caption" noWrap className={classes.title}>
            {step.name}
          </Typography>
        }
        action={icon}
      />
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box px={1} height="180px" display="flex" justifyContent="center" alignItems="center">
              <ImagePreview image={step.images} maxHeight={160} maxWidth={160} />
            </Box>
          </Grid>
          {showEmotionWords(wevo) && (
            <Grid item xs={12}>
              <Box px={2}>
                <Grid container spacing={1} style={{ height: '200px' }} alignContent="flex-start">
                  {step.topEmotionWords.map((word) => renderTopEmotionWord(word))}
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Link
          to={generatePath(Paths.reports.sentimentMapPageStep, {
            wevoId: wevo?.id,
            pageNum: 1,
            stepNum: step?.stepNumber,
          })}
          className={classes.link}>
          <Button onClick={handleSeePageClick} endIcon={<ChevronRightIcon />}>
            See Page
          </Button>
        </Link>
      </CardActions>
    </Card>
  );
};

StepCard.propTypes = {
  wevo: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
};

export default StepCard;
