import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Link,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as FAQBannerIcon } from '../../../src/assets/pulse-faq-help.svg';
import darkTheme from '../../darkTheme';
import { Links } from '../../modules/automated-insights/constants';
import { getUserProfile } from '../../modules/user/selectors';
import { TrackEvent, useTrackPageLoad } from '../analytics';

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: '#43BCFF',
  opacity: 1,
  '&:hover': {
    color: '#43BCFF',
    opacity: 0.7,
  },
  '&:focus': {
    color: '#7bd0ff',
    opacity: 1,
  },
  '&:selected': {
    color: '#287199',
    opacity: 1,
  },
}));

function FAQBanner() {
  return (
    <Box
      sx={{
        minHeight: 130,
        width: '100%',
        backgroundColor: 'secondary.main',
        borderRadius: 4,
        padding: 2,
        textAlign: 'center',
      }}>
      <Box mt={1} mb={1}>
        <FAQBannerIcon fontSize="large" />
      </Box>
      <Box mt={1.5}>
        <Typography fontSize="large" fontWeight={600} color="primary" sx={{ opacity: 0.8 }}>
          Frequently Asked Questions
        </Typography>
      </Box>
    </Box>
  );
}

function BulletedList({ items, fontSize = 16, paddingLeft = 16 }) {
  // MUI is remarkably bad at handling bulleted lists, so drop into to regular jsx
  return (
    <ul style={{ color: darkTheme.palette.primary.main, margin: 0, paddingLeft: paddingLeft }}>
      {items.map((item, index) => (
        <li key={index} style={{ margin: '4px 0' }}>
          <Typography fontSize={fontSize}>{item}</Typography>
        </li>
      ))}
    </ul>
  );
}

const ComparisonTableCell = styled(TableCell)(({ theme }) => ({
  border: '1px solid rgba(255, 255, 255, 0.31)',
  verticalAlign: 'top',
}));

function FAQComparisonTable() {
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const textFontSize = { xs: 12, sm: 16 };

  const dimensions = [
    {
      label: 'Duration',
      columns: [
        <Typography color="primary" textAlign={'center'} fontSize={textFontSize}>
          ~7 <b>days</b>
        </Typography>,
        <Typography color="primary" textAlign={'center'} fontSize={textFontSize}>
          <b>in minutes</b>
        </Typography>,
      ],
    },
    {
      label: 'Accuracy',
      columns: [
        <Box textAlign="center">
          <Typography color="primary" fontSize={textFontSize}>
            Very High
          </Typography>
          <Typography color="primary" fontSize={textFontSize}>
            120 Human respondents
          </Typography>
        </Box>,
        <Box textAlign="center">
          <Typography color="primary" fontSize={textFontSize}>
            About 70% of
          </Typography>
          <Typography color="primary" fontSize={textFontSize}>
            WEVO Pro key findings
          </Typography>
        </Box>,
      ],
    },

    {
      label: 'Deliverables',
      columns: [
        <Box ml={2}>
          <BulletedList
            fontSize={textFontSize}
            paddingLeft={isExtraSmallScreen ? 0 : 16}
            items={[
              'Analyst-evaluated key findings',
              'Sentiment map',
              'Expectations',
              'Scoring and Benchmarks',
              'Usability metrics',
            ]}
          />
        </Box>,
        <Box ml={2}>
          <BulletedList
            fontSize={textFontSize}
            paddingLeft={isExtraSmallScreen ? 0 : 16}
            items={[
              'Key findings',
              'Sentiment map',
              'Intent',
              'Recommendations',
              'Chat',
              'Experience Quality Score',
            ]}
          />
        </Box>,
      ],
    },
    {
      label: 'Use Case',
      columns: [
        <Box ml={2}>
          <BulletedList
            fontSize={textFontSize}
            paddingLeft={isExtraSmallScreen ? 0 : 16}
            items={['De-Risk', 'Validate', 'Uncover WHY', 'Compare']}
          />
        </Box>,
        <Box ml={2}>
          <BulletedList
            fontSize={textFontSize}
            paddingLeft={isExtraSmallScreen ? 0 : 16}
            items={['Quick directional feedback', 'When no time or budget for traditional user research']}
          />
        </Box>,
      ],
    },
  ];

  const tableDimensionCellStyles = (index) => {
    if (index === 0) {
      return {
        borderRadius: '16px 0 0 0',
        borderTop: 0,
      };
    }

    if (index === dimensions.length - 1) {
      return {
        borderRadius: '0 0 0 16px',
        borderBottom: 0,
      };
    }

    return {
      borderRadius: '',
    };
  };

  return (
    <Box textAlign={'center'}>
      <Table>
        <TableBody>
          <TableRow>
            <ComparisonTableCell sx={{ border: 0 }}></ComparisonTableCell>
            <ComparisonTableCell
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.20)',
                borderTop: 0,
                borderLeft: 0,
                borderRadius: '16px 0 0 0',
                textAlign: 'center',
                paddingX: { xs: 1, sm: 2 },
              }}>
              <Typography color="primary" fontWeight="600" fontSize={textFontSize}>
                WEVO Pro
              </Typography>
            </ComparisonTableCell>
            <ComparisonTableCell
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.20)',
                borderTop: 0,
                borderRight: 0,
                borderRadius: '0 16px 0 0',
                textAlign: 'center',
                paddingX: { xs: 1, sm: 2 },
              }}>
              <Typography color="primary" fontWeight="600" fontSize={textFontSize}>
                WEVO Pulse
              </Typography>
            </ComparisonTableCell>
          </TableRow>
          {dimensions.map((dimension, dimensionIndex) => (
            <TableRow key={dimension.label}>
              <ComparisonTableCell
                sx={{
                  backgroundColor: 'rgba(255, 255, 255, 0.20)',
                  textAlign: 'center',
                  borderLeft: 0,
                  paddingX: { xs: 1, sm: 2 },
                  ...tableDimensionCellStyles(dimensionIndex),
                }}>
                <Typography color="primary" fontSize={textFontSize}>
                  {dimension.label}
                </Typography>
              </ComparisonTableCell>
              {dimension.columns.map((column, index) => (
                <ComparisonTableCell
                  key={`${dimension.label}-value-${index}`}
                  sx={{ paddingX: { xs: 1, sm: 2 } }}>
                  <Box maxWidth={275} fontSize={textFontSize}>
                    {column}
                  </Box>
                </ComparisonTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}

const StyledAccordion = styled(Accordion)(({ theme }) => ({
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '.MuiAccordionSummary-root': {
    minHeight: 95,
    borderRadius: 4,
    paddingLeft: 32,
    paddingRight: 32,
    '&.Mui-expanded': {
      minHeight: 15,
      backgroundColor: 'rgba(255, 255, 255, 0.05)',
      borderRadius: '16px 16px 0 0', // corresponds to theme units 4 x 4 = 16px
    },
    '&.Mui-expanded > .MuiAccordionSummary-content': {
      marginTop: 20,
      marginBottom: 10,
    },
  },
  '.MuiAccordionDetails-root': {
    paddingLeft: 32,
    paddingRight: 32,
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
    borderRadius: '0 0 16px 16px', // corresponds to theme units 4 x 4 = 16px
  },
}));

function FAQAccordion({ content, title, isOpen, onChange = () => {} }) {
  return (
    <Box sx={{ border: '1px solid', borderColor: 'secondary.main', borderRadius: 4 }}>
      <StyledAccordion expanded={isOpen} onChange={onChange}>
        <AccordionSummary expandIcon={<ExpandMoreIcon color="primary" fontSize="large" />} px={4}>
          <Typography fontWeight="bold" color="primary">
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails px={4}>{content}</AccordionDetails>
      </StyledAccordion>
    </Box>
  );
}

const FAQ_SECTIONS = [
  {
    id: 'what-is-wevo-pulse',
    title: 'What is WEVO Pulse?',
    content: (
      <Box>
        <Typography color="primary">
          WEVO Pulse provides instant feedback on websites, campaigns, concepts, and messaging. It helps
          product, marketing, and design teams to produce higher-performing experiences by identifying what
          their target persona likes and dislikes about it. Pulse is the most accurate customer experience AI
          analysis tool as it leverages data from over one million user research participants. It can be
          leveraged when there isn’t enough time or budget for traditional user research given that results are
          ready within 5 minutes.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'when-to-use-wevo-pulse',
    title: 'When can you use WEVO Pulse?',
    content: (
      <Box>
        <BulletedList
          items={[
            'Uncover customer insights, what they like and dislike. By persona.',
            'Evaluate your existing website, campaign or product.',
            'Get feedback on your concepts or prototypes.',
            'Learn from your competitors - what they are doing well and what they are not.',
          ]}
        />
      </Box>
    ),
  },
  {
    id: 'main-pain-point',
    title: 'What is the main pain point that Pulse solves for?',
    content: (
      <Box>
        <BulletedList
          items={[
            'I don’t have the time or the budget to run traditional user study.',
            `I have a lot of research questions I want answered but don't have a research team to help me answer them all.`,
            'I need very quick feedback on a concept, prototype or idea I am working on.',
            'I can’t find the audience that I would like to run a study on.',
            `My legal team doesn't want these sensitive materials in front of real users, but are OK if a computer reviews it.`,
          ]}
        />
      </Box>
    ),
  },
  {
    id: 'what-can-test',
    title: 'What can I test using WEVO Pulse?',
    content: (
      <Box>
        <BulletedList
          items={[
            'Live Website',
            'Mobile App',
            'Prototype of a page or experience',
            'Images',
            'Messaging',
            'Email',
            'Ad',
            'Single Page or Full User Journey',
          ]}
        />
      </Box>
    ),
  },
  {
    id: 'how-does-it-work',
    title: 'How does it work?',
    content: (
      <Box>
        <BulletedList
          items={[
            <>
              <Typography component={'span'} fontWeight="600">
                Upload
              </Typography>
              <span>{`: Type in a URL or upload an image or experience.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                Mobile or desktop
              </Typography>
              <span>{`: Select the platform you want to analyze.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                Read the feedback
              </Typography>
              <span>{`: Key findings, sentiment map, recommendations, and the personas that are the best fit for this experience are generated in minutes.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                Ask questions
              </Typography>
              <span>{`: Ask Pulse Chat any questions you have on the messaging and UX.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                Share
              </Typography>
              <span>{`: Share your results with friends and colleagues so they can learn.`}</span>
            </>,
          ]}
        />
      </Box>
    ),
  },
  {
    id: 'cost',
    title: 'How much does WEVO Pulse cost?',
    content: (
      <Box>
        <span>
          <Typography color="primary">
            The free trial version of WEVO Pulse is available for 7 days and includes up to 50 pulses at no
            charge.
          </Typography>
          <Typography color="primary">
            {`After the trial, you can go to your ‘account settings’ and choose from several `}
          </Typography>
          <StyledLink href="/pulse/subscription">subscription plans</StyledLink>
          <Typography component={'span'} color="primary">
            , available both monthly and annually. For more details on these plans, please contact
          </Typography>
          <StyledLink href="mailto:PulseSupport@wevo.ai">{` PulseSupport@wevo.ai`}</StyledLink>
          <Typography component={'span'} color={'primary'}>
            .
          </Typography>
        </span>
      </Box>
    ),
  },
  {
    id: 'try-pulse',
    title: 'How can I try it out?',
    content: (
      <Box>
        <Typography color="primary">
          <span>
            <StyledLink href={Links.TakeAPulse}>www.wevo.ai/takeapulse</StyledLink>
            {' to evaluate a website and sign up for a 7-day free trial.'}
          </span>
        </Typography>
      </Box>
    ),
  },
  {
    id: 'ai',
    title: 'How is AI used in WEVO Pulse?',
    content: (
      <Box>
        <Typography color="primary">
          In WEVO Pulse, there is no human participation, neither in a panel nor analysis. All the results are
          generated solely through generative AI, ensuring an entirely machine-driven process. WEVO leveraged
          over 1 million participants in user studies to help the AI generate enhanced results. WEVO has filed
          over 16 patents, supporting its groundbreaking technology.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'use-chatgpt-instead',
    title: 'Can’t I just generate similar feedback with ChatGPT?',
    content: (
      <Box>
        <Typography color="primary">
          While ChatGPT offers responses based on a wide range of internet data, WEVO Pulse provides deeper,
          more targeted insights.
        </Typography>
        <Box my={2} mx={2}>
          <BulletedList
            items={[
              <>
                <Typography component={'span'} fontWeight="600">
                  Specialized Training and Unique Data
                </Typography>
                <span>{`: WEVO is underpinned by a proprietary dataset of over 1 million user studies, ensuring our AI generates not just words, but actionable insights and recommendations that drive customer experience improvements and acquisition. Unlike general large language models like ChatGPT, WEVO's specialized training offers targeted, high-value outputs, similar to comparing the performance capabilities of a bicycle to a Ferrari. Both are modes of transportation, but they deliver very different experiences.`}</span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Comprehensive Analysis
                </Typography>
                <span>{`: Unlike ChatGPT, which typically analyzes content at a page level, WEVO evaluates entire user journeys, providing a holistic view of the user experience that is crucial for comprehensive UX analysis.`}</span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Enterprise-Grade Features
                </Typography>
                <span>{`: WEVO offers a centralized platform for managing insights, studies, personas, and scores, facilitating easy sharing and management within organizations. This includes features essential for enterprises, such as user management, Single Sign-On (SSO), and more.`}</span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Customization Capabilities
                </Typography>
                <span>{`: WEVO's model can be customized with specific organizational data. For example, we are collaborating with one of the world's largest investment banks to tailor our model to their unique needs.`}</span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Technological Advancements
                </Typography>
                <span>{`: WEVO holds five granted patents with an additional 11 pending, reflecting significant technological advancements over general large language models.`}</span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Future Developments
                </Typography>
                <span>{`: In an upcoming release, WEVO will introduce the ability to generate HTML for new websites based on system recommendations and an evolutionary process, further enhancing the utility and effectiveness of our platform.`}</span>
              </>,
            ]}
          />
        </Box>
      </Box>
    ),
  },
  {
    id: 'training-data',
    title: 'Is this product trained by previous WEVO data?',
    content: (
      <Box>
        <Typography color="primary">
          The WEVO Pulse system was improved based on anonymized insights from human-based studies.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'features',
    title: 'What features does WEVO Pulse provide?',
    content: (
      <Box>
        <BulletedList
          items={[
            <>
              <Typography component={'span'} fontWeight="600">
                Recommendations
              </Typography>
              <span>{`: Based on the analysis of your assets, Pulse offers actionable next steps, such as potential fixes or experiments to try.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                Sentiment Map
              </Typography>
              <span>{`: This tool highlights specific areas for improvement by showing what aspects users ‘like’, ‘dislike’, or may feel ‘mixed’ about in your digital experiences.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                Persona Intent
              </Typography>
              <span>{`: Understand the likely actions or desires of different personas when they visit your website.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                EQS (Experience Quality Score)
              </Typography>
              <span>{`: EQS is a composite score ranging from 60 to 100, developed from evaluating three key metrics: Intuitive, Trusted, and Valuable.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                Journey Feedback
              </Typography>
              <span>{`: With WEVO Pulse, you're not just analyzing a single page but entire user journeys. This comprehensive approach is crucial for a deeper understanding of user experience.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                Collaboration and Share
              </Typography>
              <span>{`: WEVO Pulse makes it easy to store, share, and search findings, facilitating teamwork.`}</span>
            </>,
            <>
              <Typography component={'span'} fontWeight="600">
                AI Chat feature
              </Typography>
              <span>{`: You can ask WEVO Pulse custom questions. This allows you to gain a more in-depth understanding.`}</span>
            </>,
          ]}
        />
      </Box>
    ),
  },
  {
    id: 'audience-inference',
    title:
      'How does WEVO Pulse determine the persona for my asset? How can I adjust it to fit my audience more accurately?',
    content: (
      <Box>
        <Typography color="primary">
          WEVO Pulse kicks off your study by suggesting a "proposed" persona derived from the initial analysis
          of your digital asset. This suggested persona is our AI's best guess at who your content is
          targeting. However, you're not locked into this initial proposal. After the persona is generated you
          can easily tailor it to better align with your specific audience needs.
        </Typography>
        <Box py={1} />
        <Typography color="primary" fontWeight="600">
          Here’s a simple step to customize or make your own Persona:
        </Typography>
        <Box my={2} mx={2}>
          <BulletedList
            items={[
              <>
                <Typography component={'span'} fontWeight="600">
                  Wait for the Persona Generation
                </Typography>
                <span>{`: Just after the persona suggestion appears, you're set to customize. No need to wait for the full study to conclude.`}</span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Scroll and Customize
                </Typography>
                <span>{`: Scroll down to find the ‘customize’ option, or add a new persona. Both routes let you fine-tune the details to better match your audience.`}</span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Save and Regenerate
                </Typography>
                <span>{`: Once you’ve made your changes, hit ‘save and regenerate’ to rerun the analysis with your tailored persona, ensuring the results now reflect insights more specific to your audience.`}</span>
              </>,
            ]}
          />
        </Box>
        <Typography color="primary">
          By adjusting the persona, you can ensure the insights from WEVO Pulse are as relevant and actionable
          as possible, allowing you to optimize your digital asset effectively for your intended audience.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'pulse-chat',
    title: 'What is Pulse ‘Chat’? What can I ask to ‘Chat’?',
    content: (
      <Box>
        <Box>
          <Typography color="primary">
            The Chat feature in WEVO Pulse allows you to interact directly with the platform to gain deeper
            insights into your digital assets. Simply type in your questions or concerns, and the AI-driven
            Chat will provide you with detailed feedback, suggestions, and answers based on the analysis of
            your content. Whether you're seeking clarification on specific findings or need guidance on next
            steps, Chat acts as your personal research assistant, ready to support your decision-making
            process.
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    id: 'sentiment-map',
    title: 'How does the Pulse Sentiment Map work?',
    content: (
      <Box>
        <Typography color="primary">
          The Pulse Sentiment Map highlights key portions of the text, categorizing them into positive or
          negative sentiments. Our recent enhancement introduces 'mixed quotes'. This means if a particular
          segment of text elicits both positive and negative responses, the Sentiment Map now cleverly
          summarizes these mixed reactions into a single, comprehensive sentiment analysis. This improvement
          provides a more nuanced understanding of the text, reflecting the complexity and diversity of
          opinions in a consolidated and user-friendly manner.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'quality-score',
    title: 'What is EQS and how does it work?',
    content: (
      <Box>
        <Typography color="primary">
          The Pulse <b>Experience Quality Score (EQS)</b> evaluates your digital experience across three key
          metrics: <b>Valuable, Trusted, and Intuitive</b>, providing a score ranging from 60 to 100. The EQS
          was designed to estimate how human users would respond to the experience. Specifically, humans that
          are similar to the description in the Persona section of this Pulse. To achieve this goal EQS was
          trained on the responses of over 1 million users from diverse backgrounds.
        </Typography>
        <Box my={2} />
        <Typography color="primary">
          To ensure accuracy, EQS underwent rigorous testing using WEVO Pro (responses from 120 users for each
          study). In 80% of cases, EQS scores were within +/- 4 points of the WEVO Pro scores, confirming its
          reliability and precision. The WEVO model is continuously being updated by new data we provide it
          from real user studies on how humans are responding to current user experiences and technology
          trends, as well as expanding the database to include new countries/geographies and languages.
        </Typography>
        <Box my={2} />
        <Typography fontWeight="600" color="primary">
          Limitations:
        </Typography>
        <Box my={2} mx={2}>
          <BulletedList
            items={[
              'While EQS can score emails, presentations, etc, it was primarily trained on websites (mobile and desktop).',
              'EQS can provide scores for non-English sites but was trained and tested on English-only websites, though it incorporates insights from international sites.',
              'Currently, EQS provides analysis and feedback only on static images, not dynamic content like videos or carousels.',
            ]}
          />
        </Box>
        <Typography fontWeight="600" color="primary">
          Interpreting EQS Results:
        </Typography>
        <Box my={2} mx={2}>
          <BulletedList
            items={[
              <>
                <Typography component={'span'} fontWeight="600">
                  Identify Areas for Improvement
                </Typography>
                <span>
                  {`: Analyze individual scores to pinpoint areas needing improvement, e.g., low Trusted score indicating security issues.`}
                </span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Prioritize Efforts
                </Typography>
                <span>
                  {`: Focus on sites with lower scores; scores above 90 likely need minimal attention, while those below 70 require more work.`}
                </span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Track Performance Over Time
                </Typography>
                <span>
                  {`: Monitor changes in EQS scores to gauge the impact of updates and identify trends.`}
                </span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Benchmark Against Competitors
                </Typography>
                <span>
                  {`: Compare EQS results with competitors to understand relative performance and inform strategic decisions.`}
                </span>
              </>,
              <>
                <Typography component={'span'} fontWeight="600">
                  Validate Design Choices
                </Typography>
                <span>
                  {`: Use EQS results to validate design decisions and align with desired user experiences.`}
                </span>
              </>,
            ]}
          />
        </Box>
      </Box>
    ),
  },
  {
    id: 'benchmarks',
    title: 'What are Benchmarks?',
    content: (
      <Box>
        <Typography color="primary">
          Benchmarks are a measure of how your experience compares to other experiences of the same type,
          industry, or product. A benchmark is a snapshot of the average scores (Top level, Intuitive,
          Valuable, Trusted) of all Pulses that have the same type, industry, and product (or subset of these
          categories).
        </Typography>
        <Typography color="primary" fontWeight={600} my={2}>
          How do Benchmarks work?
        </Typography>
        <Typography color="primary" my={2}>
          AI assigns category values for your Pulse, e.g. the type, industry, product. This is the
          "recommended" categorization for your Pulse. When your Pulse finishes running, a benchmark is
          generated for the recommended categorization.
        </Typography>
        <Typography color="primary" my={2}>
          If there are not enough Pulses (minimum 10) for the exact combination of categories, then the subset
          of categories with the fewest number of Pulses (above the threshold) is used as the default
          benchmark.
        </Typography>
        <Typography color="primary" my={2}>
          At any time, you can change the benchmark in the app to see how your Pulse compares to others with a
          different categorization.
        </Typography>
        <Typography color="primary" fontWeight={600} my={2}>
          Why do I sometimes see quartiles and other times deciles?
        </Typography>
        <Typography color="primary" my={2}>
          WEVO Pulse requires 20 Pulses matching the selected filters in order to provide groupings at the
          resolution of deciles. If there are not enough Pulses matching the selected filters, Pulses are
          instead grouped by quartiles. A minimum of 10 Pulses are required for quartiles. If there are
          insufficient Pulses, the filters are disabled.
        </Typography>
        <Typography color="primary" fontWeight={600} my={2}>
          Why are some Benchmark filters disabled in the dropdowns?
        </Typography>
        <Typography color="primary" my={2}>
          Not all combinations have enough Pulses to form a benchmark. We determine all combinations that have
          enough Pulses to form a benchmark based on your current selection.
        </Typography>
        <Typography color="primary" fontWeight={600} my={2}>
          What is a “recommended” filter?
        </Typography>
        <Typography color="primary" my={2}>
          The recommended filter is the asset/industry/product that was assigned to a given Pulse. Not all
          Pulses have a recommendation for all three types. Some (very few) Pulses will not have any
          recommended filters.
        </Typography>
        <Typography color="primary" my={2}>
          A recommended filter informs the population that a given Pulse should be categorized as such.
          However, the recommendations cannot always be used in the default benchmark if there are not enough
          other Pulses that share the same categories.
        </Typography>
        <Typography color="primary" fontWeight={600} my={2}>
          What do the icons mean on the benchmark chart?
        </Typography>
        <Typography color="primary" my={2}>
          The placement of the icons in the benchmark chart indicate which bucket a score falls into compared
          to the scores of all Pulses matching the filters. Where an icon is placed within a bucket is an
          approximation of how the score compares to other scores in that bucket.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'compare-feature',
    title: 'Can I compare different versions of assets using WEVO Pulse?',
    content: (
      <Box>
        <Typography color="primary">
          WEVO is planning to release a comparison capability in one of the upcoming releases.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'language-support',
    title: 'Does WEVO Pulse support multiple languages?',
    content: (
      <Box>
        <Typography color="primary">
          Yes, WEVO Pulse currently supports six languages. It can analyze websites, campaigns and messaging
          in: English, Spanish, Portuguese, German, Italian, and French. The analysis appears in English. In
          addition, if you would like to read the analysis in a language other than English just ask in the
          chat functionality to translate the key findings to any of the supported languages above. If you
          would like to use WEVO for another language, please let us know.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'accessible-countries',
    title: 'In which countries is WEVO Pulse currently accessible?',
    content: (
      <Box>
        <Typography color="primary">
          USA, Canada, EU, Australia, Japan, South Korea, UK, Israel, Brazil, and Singapore.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'wevo-core-pulse-product-comparison',
    title: 'How does WEVO Pulse compare to WEVO Pro?',
    content: (
      <Box mt={4} mb={8} ml={{ xs: -3, sm: 0 }}>
        <FAQComparisonTable />
      </Box>
    ),
  },
  {
    id: 'results-interpretation',
    title: 'How should I leverage the information provided by WEVO Pulse?',
    content: (
      <Box>
        <Typography color="primary" mb={2}>
          WEVO Pulse is specifically designed to provide directional insights and is intended to support users
          in working through projects iteratively. It's not meant to replace user research tools such as WEVO
          Pro that include human participants.
        </Typography>
        <Typography color="primary">
          Rather, WEVO Pulse can be used iteratively, helping users refine concepts before investing time and
          resources in full user experience testing.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'ai-caveats',
    title: 'What are AI’s shortcomings that I should be aware of?',
    content: (
      <Box>
        <Typography color="primary">
          WEVO Pulse is designed to provide initial feedback and assist in improving digital experiences, but
          it does come with some limitations:
        </Typography>
        <Box my={2} mx={2}>
          <BulletedList
            items={[
              "It's not designed to provide full assessments of user reactions to digital assets. Rather, only assumed reactions by the model, to the best of its ability.",
              'Generative AI, the underlying technology model that is leveraged here, can provide inaccurate findings. It must be noted that this is a statistical rather than a deterministic model.',
              'It can miss crucial feedback and hence it should not be used as a replacement for a full user study.',
              'Its efficacy with some niche audiences is still being studied and may vary.',
              'It can’t review a website that has moving text as you scroll.',
              'The image limit is 20mb.',
            ]}
          />
        </Box>
        <Typography color="primary">
          WEVO Pulse is best viewed as a stepping stone before implementing a full WEVO analysis rather than a
          standalone solution for comprehensive user or customer experience research.
        </Typography>
      </Box>
    ),
  },
  {
    id: 'data-security',
    title: 'How does WEVO ensure the security of my data?',
    content: (
      <Box>
        <Typography color="primary">
          WEVO takes data security and confidentiality very seriously. Here's how we ensure the protection of
          your data:
        </Typography>
        <Box my={2} mx={2}>
          <BulletedList
            items={[
              "We don't train generative AI on your data.",
              "Data is only accessed by individuals in our company or our vendors who need to see it to ensure the system's quality, minimizing unnecessary exposure.",
              'Upon request, we will delete your data, providing you control over its retention.',
              'Details regarding where the data is stored can be provided per specific security policies, ensuring alignment with legal and regulatory requirements.',
              <span>
                {
                  'WEVO adheres to high security standards and to a detailed security policy which can be found '
                }
                <StyledLink href="https://wevo.ai/privacy/">here</StyledLink>.
              </span>,
            ]}
          />
        </Box>
        <Typography color="primary">
          These measures are designed to uphold the security and confidentiality of your data while it is in
          our care.
        </Typography>
      </Box>
    ),
  },
];

export default function AllFAQ() {
  useTrackPageLoad({
    name: TrackEvent.PULSE_FAQ_PAGE_LOAD,
  });
  const [accordionStates, setAccordionStates] = useState(
    FAQ_SECTIONS.reduce((acc, cur) => {
      acc[cur.id] = false;
      return acc;
    }, {})
  );

  const user = useSelector(getUserProfile);
  const isSeatBasedSubscription = user?.pulseSubscription?.isSeatBased;

  return (
    <Container maxWidth="md">
      <Box my={4} />
      <FAQBanner />
      <Box mt={2} mb={3}>
        <div style={{ textAlign: 'center' }}>
          <Typography color="primary" mb={2}>
            WEVO Pulse, now in Beta, provides feedback on digital assets in minutes, allowing marketing and
            product teams to enhance the quality of their work at every step in the process. It leverages
            generative AI and a data set of tens of thousands of human feedback data points.
          </Typography>
        </div>
      </Box>
      {FAQ_SECTIONS.map((section) => {
        if (!isSeatBasedSubscription && section.id === 'cost') {
          return null;
        }

        return (
          <Box key={section.id} mb={2}>
            <FAQAccordion
              title={section.title}
              content={section.content}
              isOpen={accordionStates[section.id]}
              onChange={() =>
                setAccordionStates({ ...accordionStates, [section.id]: !accordionStates[section.id] })
              }
            />
          </Box>
        );
      })}
      <div style={{ textAlign: 'center', marginTop: '35px' }}>
        <Typography color="primary" mb={2}>
          Can’t find what you’re looking for? Please reach us at{' '}
          <StyledLink href="mailto:pulsesupport@wevo.ai">pulsesupport@wevo.ai</StyledLink>.
        </Typography>
      </div>
    </Container>
  );
}
