import { Tooltip } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { ReactComponent as customerIcon } from '../../../assets/customer.svg';
import { ReactComponent as emptyIcon } from '../../../assets/empty.svg';
import { ReactComponent as outlinedAndColoredIcon } from '../../../assets/outlinedAndColored.svg';
import { ReactComponent as outlinedStarIcon } from '../../../assets/outlinedStar.svg';
import { isAuthenticated } from '../../../modules/user/helpers';

const useStyles = makeStyles((theme) => ({
  star: {
    verticalAlign: 'middle',
    paddingRight: theme.spacing(1),
    cursor: (props) => (props.isAuthenticated ? 'pointer' : 'default'),
  },
}));

const StarQuoteButton = ({ handleStarClick, wevo, isStarredByCompany, isStarredByWevo }) => {
  const authenticated = isAuthenticated();
  const classes = useStyles({
    isAuthenticated: authenticated,
  });
  let SvgIcon;
  let tooltipText;
  if (isStarredByCompany && isStarredByWevo) {
    SvgIcon = outlinedAndColoredIcon;
    tooltipText = 'Bookmarked by your team and WEVO';
  } else if (isStarredByWevo) {
    SvgIcon = outlinedStarIcon;
    tooltipText = 'Bookmarked by WEVO';
  } else if (isStarredByCompany) {
    SvgIcon = customerIcon;
    tooltipText = 'Bookmarked by your team';
  } else {
    SvgIcon = emptyIcon;
    tooltipText = 'Bookmark this quote';
  }

  if (authenticated) {
    if (wevo?.isPreviewing) {
      return (
        <Tooltip
          title="Quotes cannot be bookmarked in preview mode"
          enterDelay={500}
          enterNextDelay={150}
          placement={'right'}>
          <div>
            <SvgIcon fontSize="large" className={classes.star} />
          </div>
        </Tooltip>
      );
    }
    return (
      <Tooltip title={tooltipText} enterDelay={500} enterNextDelay={150} placement={'right'}>
        <div>
          <SvgIcon onClick={handleStarClick} fontSize="large" className={classes.star} />
        </div>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip
        title="You must be logged in to bookmark quotes"
        enterDelay={500}
        enterNextDelay={150}
        placement={'right'}>
        <div>
          <SvgIcon fontSize="large" className={classes.star} />
        </div>
      </Tooltip>
    );
  }
};

StarQuoteButton.propTypes = {
  handleStarClick: PropTypes.func.isRequired,
  wevo: PropTypes.object.isRequired,
  isStarredByCompany: PropTypes.bool.isRequired,
  isStarredByWevo: PropTypes.bool.isRequired,
};

export default StarQuoteButton;
