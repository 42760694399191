import { AccordionDetails, AccordionSummary, Box, Typography, styled } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import DropdownIcon from './DropdownIcon';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
  ({ theme }) => ({
    border: '1px solid #DFDFDF',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:last-child': {
      borderBottomLeftRadius: '16px',
      borderBottomRightRadius: '16px',
    },
    '&::before': {
      display: 'none',
    },
  })
);

const AccordionGroup = ({ header, titlesAndContent }) => {
  return (
    <Box>
      <Box
        sx={{
          backgroundColor: '#F8F8F8',
          borderTopLeftRadius: '16px',
          borderTopRightRadius: '16px',
          border: '1px solid #DFDFDF',
          borderBottom: 'none',
          padding: '8px 16px',
        }}>
        <Typography variant="body2" fontWeight={600}>
          {header}
        </Typography>
      </Box>
      {titlesAndContent?.map((item, index) => {
        return (
          <Accordion key={`${index}-${item.title}`} defaultExpanded={index === 0}>
            <AccordionSummary
              aria-controls={`panel${index + 1}-content`}
              id={`panel${index + 1}-header`}
              expandIcon={<DropdownIcon />}>
              <Typography variant="body2" fontWeight={600}>
                {item.title}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ paddingTop: 0 }}>
              <Typography variant="body2">{item.description}</Typography>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default AccordionGroup;
