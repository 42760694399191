import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as flagQuoteIcon } from '../../../assets/flagQuoteIcon.svg';
import { ReactComponent as flagQuoteRedIcon } from '../../../assets/flagQuoteRedIcon.svg';
import { ReactComponent as QuestionMarkBubbleIcon } from '../../../assets/question-mark-bubble.svg';
import { notify } from '../../../modules/notification/actions';
import { CustomQuestionTypes } from '../../../modules/report/constants';
import { attributeToDisplayLabel } from '../../../modules/report/helpers';
import { isAuthenticated } from '../../../modules/user/helpers';
import { getUserProfile, getUserType } from '../../../modules/user/selectors';
import { UserType } from '../../../modules/wevos/constants';
import { Paths } from '../../../routes';
import { TrackEvent } from '../../analytics';
import useFlagQuote from '../hooks/useFlagQuote';
import useStarQuote from '../hooks/useStarQuote';
import { RatingChip } from './QuoteChip';
import ReportQuoteDialog from './ReportQuoteDialog';
import StarQuoteButton from './StarQuoteButton';

const QuestionDetail = (props) => {
  const { questionText } = props;

  return (
    <Tooltip title={questionText} enterDelay={500} enterNextDelay={150} placement={'right'}>
      <Box sx={{ textAlign: 'center', verticalAlign: 'middle', paddingRight: 1, paddingTop: 1 }}>
        <QuestionMarkBubbleIcon
          className="question-text"
          style={{ width: '22px', height: '22px', cursor: 'pointer', color: 'grey[500]' }}
        />
      </Box>
    </Tooltip>
  );
};

const QuoteBlock = (props) => {
  const pageAttributesMatch = useRouteMatch(Paths.reports.pageAttributes);
  const {
    wevo,
    pageId,
    quote,
    highlightQuote,
    onQuoteUpdate,
    isSelected,
    chips,
    questionType,
    sentimentMapMatch,
    experienceMapMatch,
  } = props;
  const [isStarredByCompany, setIsStarredByCompany] = useState(quote.starredByCompany);
  const [isStarredByWevo, setIsStarredByWevo] = useState(quote.starredByWevo);
  const [isQuoteFlagged, setIsQuoteFlagged] = useState(quote.isFlagged);
  const { id: userId } = useSelector(getUserProfile);
  const [flaggedByUser, setFlaggedByUser] = useState(Boolean(quote?.flaggedByUsers?.includes(String(userId))));
  const [feedback, setFeedback] = useState('');
  const [showReportQuoteDialog, setShowReportQuoteDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showReportQuoteIcon, setShowReportQuoteIcon] = useState(false);

  const userType = useSelector(getUserType);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate: toggleQuoteStar } = useStarQuote();
  const { mutate: flagQuote } = useFlagQuote();

  const [isExpanded, setIsExpanded] = useState(false);

  const { track } = useAnalytics();

  const SvgIcon = isQuoteFlagged ? flagQuoteRedIcon : flagQuoteIcon;
  const tooltipText = () => {
    if (isAuthenticated()) {
      if (isQuoteFlagged) {
        if (flaggedByUser) {
          return 'You have already flagged this quotation for WEVO to review';
        }
        return 'Another user on your team has reported this quotation for WEVO to review';
      }
      return 'Report quote';
    }
    return 'You must be logged in to report quotes';
  };

  useEffect(() => {
    setIsStarredByCompany(quote.starredByCompany);
  }, [quote.starredByCompany]);

  useEffect(() => {
    setIsStarredByWevo(quote.starredByWevo);
  }, [quote.starredByWevo]);

  const handleStarClick = async (ev) => {
    ev.stopPropagation(); // this is for the sentiment map to stop it from selecting/deselecting the quote if you only click the star

    const currIsStarredByWevo = isStarredByWevo;
    const currIsStarredByCompany = isStarredByCompany;

    track(TrackEvent.BOOKMARKED_QUOTE, {
      wevoId: wevo?.analyticsId,
      pageId,
      quoteId: quote?.id,
      testType: wevo?.type,
      isStarredByCompany: userType === UserType.Wevo ? currIsStarredByCompany : !currIsStarredByCompany,
      isStarredByWevo: userType === UserType.Wevo ? !currIsStarredByWevo : currIsStarredByWevo,
    });

    if (userType === UserType.Wevo) {
      setIsStarredByWevo(!isStarredByWevo);
    } else {
      setIsStarredByCompany(!isStarredByCompany);
    }

    toggleQuoteStar(
      {
        wevoId: wevo?.id,
        pageId,
        quoteType: quote.quoteType,
        quoteId: quote.id,
        isStarredByCompany: userType === UserType.Wevo ? currIsStarredByCompany : !currIsStarredByCompany,
        isStarredByWevo: userType === UserType.Wevo ? !currIsStarredByWevo : currIsStarredByWevo,
      },
      {
        onSuccess: (starredQuote) => {
          if (onQuoteUpdate) {
            onQuoteUpdate({
              id: quote.id,
              updates: {
                starredByCompany:
                  userType === UserType.Wevo ? currIsStarredByCompany : !currIsStarredByCompany,
                starredByWevo: userType === UserType.Wevo ? !currIsStarredByWevo : currIsStarredByWevo,
              },
            });
          }

          if (starredQuote.isStarred) {
            dispatch(
              notify({
                message: 'Bookmarked quote into Key Findings!',
                variant: 'success',
                position: { vertical: 'bottom', horizontal: 'center' },
              })
            );
          }
          if (pageAttributesMatch) {
            queryClient.setQueryData(
              ['pageAttributes', { wevoId: wevo?.id, stepId: quote.stepId }],
              (oldQueryData) => {
                const indexOfChangedQuote = oldQueryData.quotes.findIndex(
                  (item) => item.id === starredQuote.quoteId
                );
                let newQuotesList = [...oldQueryData.quotes];

                // we want to only "overwrite" the `starredByX` which is represented by
                // starredQuote (a.k.a. it changed)
                let changedKey = {};
                if (userType === UserType.Wevo) {
                  changedKey = { starredByWevo: starredQuote.isStarred };
                } else {
                  changedKey = { starredByCompany: starredQuote.isStarred };
                }
                newQuotesList[indexOfChangedQuote] = {
                  ...quote,
                  ...changedKey,
                };
                return { ...oldQueryData, quotes: newQuotesList };
              }
            );
          }
        },
        onError: (err, res) => {
          setIsStarredByWevo(currIsStarredByWevo);
          setIsStarredByCompany(currIsStarredByCompany);
          dispatch(
            notify({
              message: 'Unable to bookmark quote',
              variant: 'error',
              position: { vertical: 'bottom', horizontal: 'center' },
            })
          );
        },
      }
    );
  };

  const handleReportQuoteClick = async () => {
    const currIsQuoteFlagged = isQuoteFlagged;
    const currFlaggedByUser = flaggedByUser;
    setIsQuoteFlagged(true);
    setFlaggedByUser(true);
    flagQuote(
      {
        wevoId: wevo?.id,
        pageId: pageId,
        quoteId: quote.id,
        feedback,
      },
      {
        onSuccess: () => {
          if (onQuoteUpdate) {
            onQuoteUpdate({
              id: quote?.id,
              updates: {
                isFlagged: true,
                flaggedByUsers: (quote?.flaggedByUsers || []).concat(String(userId)),
              },
            });
          }

          setFeedback('');
          setShowSuccessDialog(true);
          track(TrackEvent.REPORT_QUOTE, {
            wevoId: wevo?.analyticsId,
            pageId,
            quoteId: quote?.id,
            testType: wevo?.type,
            feedback,
          });
        },
        onError: (err, res) => {
          setIsQuoteFlagged(currIsQuoteFlagged);
          setFlaggedByUser(currFlaggedByUser);
          dispatch(
            notify({
              message: 'Unable to report quote',
              variant: 'error',
              position: { vertical: 'bottom', horizontal: 'center' },
            })
          );
        },
      }
    );
  };

  const handleQuoteClick = () => {
    if (highlightQuote) {
      highlightQuote(quote.id);
    }
  };

  const toggledExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleShowReportQuoteDialog = (ev) => {
    if (isAuthenticated()) {
      if (showSuccessDialog || showReportQuoteDialog) {
        setShowReportQuoteDialog(false);
      } else if (!flaggedByUser) {
        ev.stopPropagation();
        !showReportQuoteDialog && setShowSuccessDialog(false);
        setShowReportQuoteDialog(!showReportQuoteDialog);
      }
    }
  };

  const renderRatingChips = (ChipComponent, quote) => {
    // multi-select rating chips
    if (questionType === CustomQuestionTypes.MultiSelect) {
      if (quote?.labels?.length > 4 && !isExpanded) {
        // display max four chips unless expanded
        return quote?.labels?.slice(0, 4).map((label, index) => (
          <Grid key={`rating-chip-${index}`} item xs={12} md={6}>
            <ChipComponent label={label} />
          </Grid>
        ));
      }
      return quote?.labels?.map((label, index) => (
        <Grid key={`rating-chip-${index}`} item xs={12} md={6}>
          <ChipComponent label={label} />
        </Grid>
      ));
    }
    // non-multi-select rating chips
    return quote?.labels?.map((label, index) => (
      <Grid key={`rating-chip-${index}`} item xs={12} lg={8}>
        <ChipComponent label={label} />
      </Grid>
    ));
  };

  return (
    <Grid
      key={quote.id}
      container
      justifyContent="space-between"
      spacing={2}
      sx={{ padding: 1 }}
      onClick={handleQuoteClick}
      onMouseEnter={() => setShowReportQuoteIcon(true)}
      onMouseLeave={() => setShowReportQuoteIcon(false)}>
      <Grid item xs={1} sx={{ textAlign: 'center' }}>
        <Grid container>
          <Grid item xs={12}>
            <StarQuoteButton
              handleStarClick={handleStarClick}
              wevo={wevo}
              isStarredByCompany={isStarredByCompany}
              isStarredByWevo={isStarredByWevo}
            />
          </Grid>
          {quote?.questionDetails?.text && (
            <Grid item xs={12}>
              <QuestionDetail questionText={quote.questionDetails.text} wevo={wevo} />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={chips?.length > 0 ? 6 : 11}
        lg={chips?.length > 0 ? 7 : 11}
        xl={chips?.length > 0 ? 8 : 11}>
        <Grid item xs={11}>
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: grey[900],
              fontWeight: isSelected ? 'bold' : 400,
              fontSize: sentimentMapMatch || experienceMapMatch ? '12px' : '',
            }}>
            "{quote.quote}"
          </Typography>
        </Grid>
        <Grid item xs={1} display="flex" style={{ justifyContent: 'right' }}>
          <Tooltip title={tooltipText()} enterDelay={500} enterNextDelay={150} placement={'right'}>
            <Button
              size="small"
              onClick={toggleShowReportQuoteDialog}
              style={{
                height: '30px',
                maxWidth: '30px',
                backgroundColor: 'transparent',
              }}>
              <SvgIcon
                style={{
                  width: '20px',
                  cursor: !isAuthenticated() || flaggedByUser ? 'default' : 'pointer',
                  visibility: !showReportQuoteIcon && 'hidden',
                }}
              />
            </Button>
          </Tooltip>
        </Grid>
        <ReportQuoteDialog
          open={showReportQuoteDialog}
          reportQuote={handleReportQuoteClick}
          closeCallback={toggleShowReportQuoteDialog}
          feedback={feedback}
          setFeedback={setFeedback}
          isQuoteFlagged={isQuoteFlagged}
          showSuccessDialog={showSuccessDialog}
          quoteReportedByUser={flaggedByUser}
        />
        <Grid item xs={12}>
          <Box py={1}>
            {quote.attributes &&
              quote.attributes.map((attribute, index) => (
                <Fragment key={`attribute.attributeId-${index}`}>
                  <Tooltip title={attribute.attributeLabel}>
                    <Typography variant="caption" component="span" sx={{ fontSize: '10px', color: '#757575' }}>
                      {index > 0 && ', '}
                      {attributeToDisplayLabel(attribute)}
                    </Typography>
                  </Tooltip>
                </Fragment>
              ))}
          </Box>
        </Grid>
      </Grid>
      {chips.length > 0 && (
        <Grid container item xs={5} lg={4} xl={3} spacing={1} justifyContent="flex-end">
          <Grid container item spacing={1} xs={11} justifyContent="flex-end" alignContent="flex-start">
            {chips.map((ChipComponent, index) => {
              return ChipComponent === RatingChip ? (
                renderRatingChips(ChipComponent, quote)
              ) : (
                <Grid key={index} item xs={12} lg={6}>
                  <ChipComponent quote={quote} />
                </Grid>
              );
            })}
          </Grid>
          {questionType === CustomQuestionTypes.MultiSelect && (
            <Grid item xs={1}>
              {quote?.labels?.length > 4 && (
                <Tooltip title={isExpanded ? 'Show less' : 'Show all'}>
                  <IconButton
                    size="small"
                    aria-label={isExpanded ? 'Show less' : 'Show all'}
                    onClick={toggledExpanded}>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

QuoteBlock.propTypes = {
  wevo: PropTypes.object.isRequired,
  pageId: PropTypes.string.isRequired,
  quote: PropTypes.object.isRequired,
  highlightQuote: PropTypes.func,
  onQuoteUpdate: PropTypes.func,
  isSelected: PropTypes.bool,
  chips: PropTypes.array,
  questionType: PropTypes.string,
  sentimentMapMatch: PropTypes.object,
  experienceMapMatch: PropTypes.object,
};

QuoteBlock.defaultProps = {
  isSelected: false,
  chips: [],
};

export default memo(QuoteBlock);
