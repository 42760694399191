import React, { memo } from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ImagePreview from '../../../../../ui/ImagePreview';
import LinearDeterminateProgressBar from '../../LinearDeterminateProgressBar';

export const useStyles = makeStyles((theme) => ({
  progressContainer: {
    height: 250,
    width: 350,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  cardContent: {
    textAlign: 'center',
  },
  loadingCardContent: {
    textAlign: '-webkit-center',
  },
}));

const PrimerCard = ({ primer, isLoading }) => {
  const classes = useStyles();
  if (isLoading) {
    return (
      <Card>
        <CardContent className={classes.loadingCardContent}>
          <div className={classes.progressContainer}>
            <Typography variant="caption" noWrap={true} gutterBottom>
              Uploading primer image
            </Typography>
            <LinearDeterminateProgressBar />
          </div>
        </CardContent>
      </Card>
    );
  }
  return (
    <Card>
      <CardContent className={classes.cardContent}>
        <ImagePreview image={primer.images} maxHeight={250} maxWidth={350} />
      </CardContent>
    </Card>
  );
};

export default memo(PrimerCard);
