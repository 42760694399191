import { catchError, map, filter } from 'rxjs/operators';
import { of } from 'rxjs';

import { raiseError } from './actions';

export const getErrorEpic = action$ =>
  action$.pipe(
    filter(action => !!action.error),
    map(action => raiseError(action.payload)),
    catchError(error => of(console.error(error)))
  );
