import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchSubscriptionTiers = async () => {
  const response = await axios({
    url: `/api/v2/subscription/tiers`,
    method: 'GET',
  });
  return response?.data.tiers ?? [];
};

export default function useFetchSubscriptionTiers() {
  return useQuery(['subscriptionTiers'], fetchSubscriptionTiers);
}
