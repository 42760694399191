export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION';

export const notify = ({ message, variant, position }) => {
  return {
    type: SHOW_NOTIFICATION,
    payload: {
      message,
      variant,
      position,
    },
  };
};

export const dismissNotification = () => {
  return {
    type: DISMISS_NOTIFICATION,
    payload: {},
  };
};
