import ShareIcon from '@mui/icons-material/Share';
import { Button, styled } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { SHARE_REPORT_TYPE } from '../../../modules/report/constants';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  '&:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));

const ShareButton = ({ handleShareButtonClick }) => {
  return (
    <StyledButton
      variant="outlined"
      color="primary"
      endIcon={<ShareIcon />}
      onClick={() => handleShareButtonClick(SHARE_REPORT_TYPE.limitedReport)}>
      Share this page
    </StyledButton>
  );
};

ShareButton.propTypes = {
  handleShareButtonClick: PropTypes.func,
};

export default ShareButton;
