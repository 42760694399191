import { useMutation } from 'react-query';
import axios from '../api';

const deleteFigmaPrototype = async ({ wevoId, pageId, prototypeId }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/prototypes/figma/${prototypeId}`,
    method: 'DELETE',
  });
  return response.data;
};

export default function useDeleteFigmaPrototype() {
  return useMutation(deleteFigmaPrototype);
}
