import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { ReactComponent as FlagIcon } from '../../../../assets/intake-experience-flag.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/intake-experience-location.svg';
import { ReactComponent as SuccessRateIcon } from '../../../../assets/success-rate-icon.svg';
import { ExperienceEndScreenType } from '../../../../modules/intake/constants';
import { getNumOfSuccessfulOutcomes } from '../../../../modules/report/experienceMaps';
import VerticalCarousel from '../../components/VerticalCarousel';
import ExperienceStepCard from './ExperienceStepCard';

const ExperiencePathStepCarousel = ({ experience, steps, stepsAtCurrentPathIndex, pathOutcomes, onClick }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  const itemWidth = 182;
  const itemHeight = 216;

  const itemDimensions = useMemo(() => {
    if (isSmallScreen) {
      return { height: 0.8 * itemHeight, width: 0.8 * itemWidth };
    } else if (isLargeScreen) {
      return { height: itemHeight, width: itemWidth };
    } else if (isMediumScreen) {
      return { height: 0.75 * itemHeight, width: 0.75 * itemWidth };
    }
  }, [isSmallScreen, isMediumScreen, isLargeScreen]);

  const startStep = useMemo(() => {
    return (steps || []).find(
      (step) => step?.configuration?.id === experience?.configuration?.entryPointNodes?.[0]
    );
  }, [experience, steps]);

  const destinationStep = useMemo(() => {
    return (steps || []).find((step) => step?.endScreenType === ExperienceEndScreenType.Success);
  }, [steps]);

  const getIcon = (stepId) => {
    if (stepId === startStep.id) {
      return <FlagIcon style={{ width: 20, height: 20 }} />;
    } else if (stepId === destinationStep.id) {
      return <LocationIcon style={{ width: 20, height: 20 }} />;
    } else return <></>;
  };

  const numSuccessfulOutcomes = getNumOfSuccessfulOutcomes(pathOutcomes);

  return (
    <Grid container>
      <Grid item>
        <VerticalCarousel
          items={stepsAtCurrentPathIndex}
          itemComponent={({ index }) => {
            const step = stepsAtCurrentPathIndex[index];
            const stepId = step?.id;
            const image = step?.staticImage;
            const name = step?.configuration?.name;
            const icon = getIcon(stepId);
            return (
              <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                <ExperienceStepCard name={name} image={image} width={150} height={150} icon={icon} />
              </Box>
            );
          }}
          itemHeight={itemDimensions.height}
          itemWidth={itemDimensions.width}
          numItemsDisplayed={1}
          onClick={onClick}
        />
      </Grid>
      <Grid item>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            marginLeft: 1,
          }}>
          <Grid container alignItems="center" columnGap={1}>
            <Grid item display="flex" justifyContent="flex-end" sx={{ minWidth: 24 }}>
              <PeopleOutlineIcon />
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ minWidth: 24 }}>
                {pathOutcomes.length}
              </Typography>
            </Grid>
          </Grid>
          <Grid container alignItems="center" columnGap={1} py={1}>
            <Grid item display="flex" justifyContent="flex-end" sx={{ minWidth: 24 }}>
              <SuccessRateIcon style={{ width: 14, height: 14 }} />
            </Grid>
            <Grid item>
              <Typography variant="body2" sx={{ minWidth: 102 }}>
                {numSuccessfulOutcomes} successful
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

ExperiencePathStepCarousel.propTypes = {
  experience: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  stepsAtCurrentPathIndex: PropTypes.arrayOf(PropTypes.object).isRequired,
  pathOutcomes: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ExperiencePathStepCarousel;
