import { Box, Grid } from '@mui/material';
import { useMemo } from 'react';
import { AutomatedInsightSessionType } from '../../modules/automated-insights/constants';
import { AssetBadgeLabelStyle, getBadgeLabel } from '../../modules/automated-insights/helpers';
import AssetBadge from './AssetBadge';
import SessionAssetThumbnail from './SessionAssetThumbnail';

export default function SessionAssetGrid({ session, baseHeight = '90vh', sx, ...rest }) {
  const assets = useMemo(() => {
    if (session?.type === AutomatedInsightSessionType.Compare) {
      return (session?.linkedSessions ?? []).flatMap((linkedSession) => linkedSession?.assets ?? []);
    }
    return session?.assets ?? [];
  }, [session]);

  const badgeLabelStyle = useMemo(() => {
    return session?.type === AutomatedInsightSessionType.Compare
      ? AssetBadgeLabelStyle.Alphabetical
      : AssetBadgeLabelStyle.Numeric;
  }, [session]);

  const itemSize = useMemo(() => {
    const assetLength = assets.length;

    if (assetLength < 2) {
      return 12;
    }

    if (assetLength < 5) {
      return 6;
    }

    return 4;
  }, [assets]);

  const gridItems = useMemo(() => {
    const assetLength = assets.length;

    if (assetLength === 0) {
      return [];
    }
    let multiplier = 1.0;

    if (assetLength > 1 && assetLength <= 4) {
      multiplier = 0.4;
    }

    if (assetLength > 4) {
      multiplier = 0.35;
    }

    return assets.map((asset, index) => (
      <SessionAssetThumbnail
        key={asset.id}
        asset={asset}
        height={`calc(${baseHeight} * ${multiplier})`}
        badge={<AssetBadge text={getBadgeLabel(asset, index, badgeLabelStyle)} />}
        sx={{ minHeight: 200, border: '1px solid rgba(0, 0, 0, 0.15)' }}
        enableZoom={true}
      />
    ));
  }, [assets, badgeLabelStyle, baseHeight]);

  const gridAlignContent = useMemo(() => (assets.length > 9 ? 'start' : 'center'), [assets]);

  return (
    <Box sx={sx} {...rest} px={0.5}>
      <Grid container sx={{ alignContent: gridAlignContent, height: '100%' }} spacing={0.5}>
        {gridItems.map((gridItem, index) => (
          <Grid item key={index} xs={itemSize}>
            {gridItem}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
