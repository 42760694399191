import { RAISE_ERROR, DISMISS_ERROR } from './actions';

const initialState = {
  title: '',
  message: '',
  code: null,
  seen: true,
};

export const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case RAISE_ERROR:
      return {
        ...state,
        ...action.payload
      };
    case DISMISS_ERROR:
      return {
        ...state,
        seen: true
      };
    default:
      return state;
  }
};
