import { amber, green, grey, lightGreen, red } from '@mui/material/colors';

export let KeyFindingType = {};
export let WevoStatus = {};

export const setReportConstants = (constants) => {
  KeyFindingType = constants?.KeyFindingType;
  WevoStatus = constants?.WevoStatus;
};

export const leftNavWidth = 260;

export const ExpectationOutcome = {
  Met: 'met',
  Somewhat: 'somewhat',
  Unmet: 'unmet',
};

export const ExpectationOutcomeToColor = {
  [ExpectationOutcome.Met]: green[500],
  [ExpectationOutcome.Somewhat]: amber[400],
  [ExpectationOutcome.Unmet]: red[400],
};

export const ExpectationOutcomeToLabel = {
  [ExpectationOutcome.Met]: 'Fully met',
  [ExpectationOutcome.Somewhat]: 'Somewhat met',
  [ExpectationOutcome.Unmet]: 'Not met',
};

export const DiagnosticOutcome = {
  Above: 'above',
  At: 'at',
  Below: 'below',
};

export const DiagnosticOutcomeToColor = {
  [DiagnosticOutcome.Above]: green[500],
  [DiagnosticOutcome.At]: grey[300],
  [DiagnosticOutcome.Below]: red[400],
};

export const DiagnosticOutcomeToLabel = {
  [DiagnosticOutcome.Above]: 'Above benchmark',
  [DiagnosticOutcome.At]: 'At benchmark',
  [DiagnosticOutcome.Below]: 'Below benchmark',
};

export const getJourneyScoreText = (journeyScore) => {
  if (journeyScore >= 90) {
    return 'Highly Effective';
  } else if (journeyScore >= 80) {
    return 'Effective';
  } else if (journeyScore >= 60) {
    return 'Somewhat Effective';
  } else {
    return 'Less Effective';
  }
};

export const getJourneyScoreSubText = (journeyScore) => {
  if (journeyScore >= 90) {
    return 'Excellent';
  } else if (journeyScore >= 80) {
    return 'Doing great';
  } else if (journeyScore >= 60) {
    return 'Room to Improve';
  } else {
    return 'Room to Improve';
  }
};

export const getScoreTextColor = (score) => {
  if (score >= 90) {
    return green[500];
  } else if (score >= 80) {
    return lightGreen[500];
  } else if (score >= 60) {
    return amber[600];
  } else {
    return red[500];
  }
};

export const getCompositeMetricScoreText = (score) => {
  if (score >= 90) {
    return 'Highly Effective';
  } else if (score >= 80) {
    return 'Effective';
  } else if (score >= 60) {
    return 'Somewhat Effective';
  } else {
    return 'Less Effective';
  }
};
export const getScoreHoverTextColor = (score) => {
  if (score >= 90) {
    return green[600];
  } else if (score >= 80) {
    return lightGreen[600];
  } else if (score >= 60) {
    return amber[700];
  } else {
    return red[600];
  }
};

export const getStayOrGoChartColor = (score) => {
  if (score >= 70) {
    return green[500];
  } else if (score >= 50) {
    return amber[400];
  } else {
    return red[400];
  }
};

export const Components = {
  Report: 'report',
};

export const CustomQuestionTypes = {
  CustomQualitative: 'text',
  FiveLikertScale: 'likert5',
  SevenLikertScale: 'likert7',
  MultipleChoice: 'multiplechoice',
  YesNoTrueFalse: 'yesno',
  MultiSelect: 'multiselect',
  Heatmap: 'heatmap',
};

export const BarChartBenchmarksFontSizes = {
  XSmall: '11px',
  Small: '12px',
  Medium: '14px',
  Large: '18px',
};

// Maximum chunk height (10k) before splitting into another heatmap
export const CHUNK_HEIGHT = 10000;

// id attribute for heatmap image - used in downloading the heatmap
export const HEATMAP_BG = 'heatmapBg';

// id attribute for element with heatmap canvas child elements - used in downloading the heatmap
export const HEATMAP_CANVAS_CONTAINER = 'heatmapCanvasContainer';

export const PptComponentDimensions = {
  [CustomQuestionTypes.FiveLikertScale]: { width: '600px', height: `${600 * (9 / 16)}px` },
  [CustomQuestionTypes.SevenLikertScale]: { width: '700px', height: `${700 * (9 / 16)}px` },
  [CustomQuestionTypes.MultipleChoice]: { width: '600px', height: `${600 * (9 / 16)}px` },
  [CustomQuestionTypes.YesNoTrueFalse]: { width: '600px', height: `${600 * (9 / 16)}px` },
  [CustomQuestionTypes.MultiSelect]: { width: '600px', height: `${600 * (9 / 16)}px` },
  Diagnostics: { width: '700px', height: `${700 * (9 / 16)}px` },
  CDS_DQS_Diagnostics: { width: '900px', height: `${900 * (9 / 16)}px` },
  Expectations: { width: '700px', height: `${700 * (9 / 16)}px` },
  ExperienceSummary: { width: '350px', height: '270px' },
  ScoreCard: { width: '600px', height: '500px' },
};

// Mastercard Design Quality Score Constants
export const EXPERIENCE_SCORE_TOP_LEVEL_NAME = 'Mastercard Design Quality Score';
export const EXPERIENCE_SCORE_THEME_NAMES = ['Emotion', 'Ease', 'Effectiveness'];

export const EXPERIENCE_SCORE_COLORS = {
  Emotion: '#F9D861',
  Ease: '#F38B00',
  Effectiveness: '#4FCDB0',
};

export const EXPERIENCE_SCORE_BAR_CHART_COLORS = {
  backgroundColor: [
    'rgba(249, 216, 97, 1.0)',
    'rgba(249, 216, 97, 1.0)',
    'rgba(249, 216, 97, 1.0)',
    'rgba(243, 139, 0, 1.0)',
    'rgba(243, 139, 0, 1.0)',
    'rgba(79, 205, 176, 1.0)',
    'rgba(79, 205, 176, 1.0)',
    'rgba(79, 205, 176, 1.0)',
  ],
  hoverBackgroundColor: [
    'rgba(249, 216, 97,0.65)',
    'rgba(249, 216, 97, 0.65)',
    'rgba(249, 216, 97, 0.65)',
    'rgba(243, 139, 0, 0.65)',
    'rgba(243, 139, 0, 0.65)',
    'rgba(79, 205, 176, 0.65)',
    'rgba(79, 205, 176, 0.65)',
    'rgba(79, 205, 176, 0.65)',
  ],
};

// Mastercard Concept Desirability Score Constants
export const CONCEPT_SCORE_TOP_LEVEL_NAME = 'Mastercard Concept Desirability Score';
export const CONCEPT_SCORE_THEME_NAMES = ['Comprehension', 'Relevance', 'Value'];
export const MIN_CDS_COMPREHENSION_SCORE = 80;
export const COMPREHENSION_CONCEPT = 'Comprehension';

export const CONCEPT_SCORE_COLORS = {
  Comprehension: '#F58D5A',
  Relevance: '#3F8E7C',
  Value: '#FAD560',
};

export const CONCEPT_SCORE_BAR_CHART_COLORS = {
  backgroundColor: [
    'rgba(245, 141, 90, 1.0)',
    'rgba(245, 141, 90, 1.0)',
    'rgba(63, 142, 124, 1.0)',
    'rgba(63, 142, 124, 1.0)',
    'rgba(250, 213, 96, 1.0)',
    'rgba(250, 213, 96, 1.0)',
  ],
  hoverBackgroundColor: [
    'rgba(245, 141, 90, 0.65)',
    'rgba(245, 141, 90, 0.65)',
    'rgba(63, 142, 124, 0.65)',
    'rgba(63, 142, 124, 0.65)',
    'rgba(250, 213, 96, 0.65)',
    'rgba(250, 213, 96, 0.65)',
  ],
};

export const LIMITED_REPORT_PAGES = {
  dashboard: 'dashboard',
  'sentiment-map': 'sentiment-map',
  'experience-map': 'experience-map',
};

export const SHARE_REPORT_TYPE = {
  entireReport: 'entireReport',
  limitedReport: 'limitedReport',
};

export const EXPERIENCE_OUTCOME_TYPES = {
  success: 'success',
  failure: 'failure',
};

export const EXPERIENCE_OUTCOMES = {
  success: 'successful',
  failure: 'unsuccessful',
};

// Ease question is 7-likert
export const EASE_SCALE = 7;

// Options for experience 7-likert ease question - ['1', '2', '3', '4', '5', '6', '7']
export const EASE_OPTIONS = Array.from({ length: EASE_SCALE }, (_, i) => i + 1).map((num) => String(num));

export const SLIDES = {
  journey: {
    slides: [
      {
        order: 1,
        id: 'objective',
        title: 'Objective',
        fields: [{ order: 1, id: 'description', title: 'Primary' }],
      },
      {
        order: 2,
        id: 'testDetails',
        title: 'Test Details',
        fields: [
          { order: 1, id: 'taskToComplete', title: 'Task to Complete' },
          { order: 2, id: 'audienceDescription', title: 'Custom Audience' },
          { order: 3, id: 'devices', title: 'Device' },
          { order: 4, id: 'journeyStartUrl', title: 'Prototype' },
        ],
      },
      {
        order: 3,
        id: 'resultsOverview',
        title: 'Results Overview',
        fields: [{ order: 1, id: 'numRespondents', title: 'respondents' }],
      },
      {
        order: 4,
        id: 'keyFindings',
        title: 'Key Findings',
        fields: [{ order: 1, id: 'keyFindings', title: '' }],
      },
      { order: 5, id: 'teamKeyFindings', title: 'Team Key Findings', fields: [] },
      { order: 6, id: 'starredQuotes', title: 'Starred Quotes', fields: [] },
      { order: 7, id: 'diagnosticsGraph', title: 'Diagnostics', fields: [] },
      { order: 8, id: 'diagnosticsQuotes', title: 'Diagnostics', fields: [] },
      { order: 9, id: 'expectationsGraph', title: 'Expectations', fields: [] },
      { order: 10, id: 'expectationsQuotes', title: 'Expectations', fields: [] },
      { order: 11, id: 'sentimentMap', title: 'Sentiment Map', fields: [] },
      { order: 12, id: 'customQuestions', title: 'Custom Questions', fields: [] },
    ],
  },
  classic: {
    slides: [
      {
        order: 1,
        id: 'objective',
        title: 'Objective',
        fields: [{ order: 1, id: 'description', title: 'Primary' }],
      },
      {
        order: 2,
        id: 'testDetails',
        title: 'Test Details',
        fields: [
          { order: 1, id: 'taskToComplete', title: 'Task to Complete' },
          { order: 2, id: 'audienceDescription', title: 'Custom Audience' },
          { order: 3, id: 'devices', title: 'Device' },
          { order: 4, id: 'journeyStartUrl', title: 'Prototype' },
        ],
      },
      {
        order: 3,
        id: 'resultsOverview',
        title: 'Results Overview',
        fields: [{ order: 1, id: 'numRespondents', title: 'respondents' }],
      },
      {
        order: 4,
        id: 'keyFindings',
        title: 'Key Findings',
        fields: [{ order: 1, id: 'keyFindings', title: '' }],
      },
      { order: 5, id: 'teamKeyFindings', title: 'Team Key Findings', fields: [] },
      { order: 6, id: 'starredQuotes', title: 'Starred Quotes', fields: [] },
      { order: 7, id: 'diagnosticsGraph', title: 'Diagnostics', fields: [] },
      { order: 8, id: 'diagnosticsQuotes', title: 'Diagnostics', fields: [] },
      { order: 9, id: 'expectationsGraph', title: 'Expectations', fields: [] },
      { order: 10, id: 'expectationsQuotes', title: 'Expectations', fields: [] },
      { order: 11, id: 'sentimentMap', title: 'Sentiment Map', fields: [] },
      { order: 12, id: 'customQuestions', title: 'Custom Questions', fields: [] },
    ],
  },
  compare: {
    slides: [
      {
        order: 1,
        id: 'objective',
        title: 'Objective',
        fields: [{ order: 1, id: 'description', title: 'Primary' }],
      },
      {
        order: 2,
        id: 'testDetails',
        title: 'Test Details',
        fields: [
          { order: 1, id: 'taskToComplete', title: 'Task to Complete' },
          { order: 2, id: 'audienceDescription', title: 'Custom Audience' },
          { order: 3, id: 'devices', title: 'Device' },
          { order: 4, id: 'journeyStartUrl', title: 'Prototype' },
        ],
      },
      {
        order: 3,
        id: 'resultsOverview',
        title: 'Results Overview',
        fields: [{ order: 1, id: 'numRespondents', title: 'respondents' }],
      },
      {
        order: 4,
        id: 'keyFindings',
        title: 'Key Findings',
        fields: [{ order: 1, id: 'keyFindings', title: '' }],
      },
      { order: 5, id: 'teamKeyFindings', title: 'Team Key Findings', fields: [] },
      { order: 6, id: 'starredQuotes', title: 'Starred Quotes', fields: [] },
      { order: 7, id: 'diagnosticsGraph', title: 'Diagnostics', fields: [] },
      { order: 8, id: 'diagnosticsQuotes', title: 'Diagnostics', fields: [] },
      { order: 9, id: 'expectationsGraph', title: 'Expectations', fields: [] },
      { order: 10, id: 'expectationsQuotes', title: 'Expectations', fields: [] },
      { order: 11, id: 'sentimentMap', title: 'Sentiment Map', fields: [] },
      { order: 12, id: 'customQuestions', title: 'Custom Questions', fields: [] },
    ],
  },
};

export const BUBBLE_MAP_DEFAULT_COMPONENT_ID = 'bubble-map-container';
export const BUBBLE_MAP_DEFAULT_BG_COMPONENT_ID = 'bubble-chart-bg';
export const BUBBLE_MAP_DEFAULT_FILL_STYLE = 'rgba(0,0,0, 0.5)';
export const BUBBLE_MAP_DEFAULT_STROKE_STYLE = '#fff';
export const EXPERIENCE_CLICK_MAP_BUBBLE_RADIUS_PX = 30;

// size of highlighted points rendered on heatmaps/bubble maps
export const DEFAULT_HIGHLIGHT_SIZE = 32;

export const ClusterTypes = {
  Likes: 'MostAppeal',
  Dislikes: 'LeastAppeal',
};

export const ClusterLabel = {
  MostAppeal: 'Likes',
  LeastAppeal: 'Dislikes',
};

export const LeftNavLevelType = {
  Level: 'level',
  Link: 'link',
  HashLink: 'hashlink',
};

export const HASH_LINKS = {
  experienceSentimentMap: 'experience-sentiment-map',
  experienceClickTrackingMap: 'experience-click-tracking-map',
  experiencePaths: 'experience-paths',
};

export const LEFT_NAV_LABELS = {
  [HASH_LINKS.experienceSentimentMap]: 'Sentiment',
  [HASH_LINKS.experienceClickTrackingMap]: 'Clicks',
  [HASH_LINKS.experiencePaths]: 'Paths',
};

export const BAR_CHART_LABEL = {
  maxCharsInLine: 55,
  dashboardMaxLabelChars: 80,
  dashboardMaxCharsInLine: 30,
  tooltipMaxCharsInLine: 50,
};

export const KEY_FINDING_TYPE = {
  CrossPageFinding: 'finding',
  PageFinding: 'finding',
  Summary: 'summary',
};
