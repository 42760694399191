import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { KeyFindingValues } from '../../../modules/intake/constants';

const useStyles = makeStyles((theme) => ({
  deviceRadioButtons: {
    flexDirection: 'row',
  },
}));

const KeyFindingsSelection = ({ keyFindingsSelection, handleKeyFindingSelect }) => {
  const classes = useStyles();
  return (
    <FormControl variant="standard" component="fieldset">
      <RadioGroup
        className={classes.deviceRadioButtons}
        name="keyFindings"
        value={keyFindingsSelection}
        onChange={handleKeyFindingSelect}>
        <FormControlLabel
          key={KeyFindingValues.Yes}
          value={KeyFindingValues.Yes}
          control={<Radio color="primary" />}
          label={'Add key findings'}
        />
        <FormControlLabel
          key={KeyFindingValues.No}
          value={KeyFindingValues.No}
          control={<Radio color="primary" />}
          label={"Don't add key findings"}
        />
      </RadioGroup>
    </FormControl>
  );
};

KeyFindingsSelection.propTypes = {
  keyFindingsSelection: PropTypes.string.isRequired,
  handleKeyFindingSelect: PropTypes.func.isRequired,
};

export default memo(KeyFindingsSelection);
