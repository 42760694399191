import { Box, Card, Grid, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { default as React, useCallback } from 'react';
import DebouncedInput from '../../DebouncedInput';

const EXPECTATIONS_GAP_SUFFIX = 'what are your greatest hopes/concerns?';

const ExpectationsGap = ({ expectationsGap, onExpectationsGapChanged, errors }) => {
  const regex = new RegExp(`^When (.*), ${EXPECTATIONS_GAP_SUFFIX}`);

  const handleExpectationsGapChanged = useCallback(
    (newExpectationsGap) => {
      const newVisitorObjective = Boolean(newExpectationsGap)
        ? `When ${newExpectationsGap}, ${EXPECTATIONS_GAP_SUFFIX}`
        : '';

      onExpectationsGapChanged && onExpectationsGapChanged(newVisitorObjective);
    },
    [onExpectationsGapChanged]
  );

  return (
    <>
      <Box mb={2}>
        <Typography paragraph variant="h5" id="expectations-gap-heading">
          {`Expectations: WEVO results show you the "Expectations Gap" in your current experience. Please input your Expectations specifics:*`}
          &nbsp;
        </Typography>
        <Typography paragraph variant="body2" id="expectations-gap-example">
          {`Example: "When [shopping for shoes], what are your greatest hopes/concerns?"`}
        </Typography>
        {Boolean(errors?.expectationsGap) && (
          <Box mt={1} ml={2} id="expectations-gap-error-message">
            <Typography sx={{ color: 'transparent', fontSize: '12px', height: '0px' }}>
              {`Alert: ${errors?.expectationsGap?.message ?? ''}`}
            </Typography>
          </Box>
        )}
      </Box>
      <Card style={{ paddingBottom: 3, minHeight: '70px' }}>
        <Grid
          container
          spacing={1}
          alignItems="center"
          justifyContent="space-around"
          sx={{ flexWrap: 'nowrap', marginLeft: 1, marginTop: 0.5 }}>
          <Grid item>
            <Typography variant="body2">When</Typography>
          </Grid>
          <Grid item xs={4}>
            <DebouncedInput
              value={expectationsGap?.match(regex)?.[1] ?? ''}
              onChange={handleExpectationsGapChanged}
              debounceMs={500}
              renderInput={({ value, onChange }) => (
                <TextField
                  variant="outlined"
                  fullWidth
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors?.expectationsGap ?? false)}
                  aria-labelledby="expectations-gap-heading expectations-gap-example expectations-gap-error-message"
                  aria-invalid={errors?.expectationsGap ? 'true' : undefined}
                  inputProps={{ style: { fontSize: 14, height: 12 } }}
                />
              )}
            />
          </Grid>
          &nbsp;
          {`,`}
          <Grid item xs={7}>
            <Typography variant="body2"> what are your greatest hopes/concerns?</Typography>
          </Grid>
        </Grid>
      </Card>
      {Boolean(errors?.expectationsGap ?? false) && (
        <Box mt={1} ml={2}>
          <Typography sx={{ color: '#FE6D6E', fontSize: '12px' }}>
            {errors?.expectationsGap?.message ?? ''}
          </Typography>
        </Box>
      )}
    </>
  );
};

ExpectationsGap.propTypes = {
  draft: PropTypes.object,
  isDQS: PropTypes.bool,
  isCDS: PropTypes.bool,
  errors: PropTypes.object,
  setErrors: PropTypes.func,
};

export default ExpectationsGap;
