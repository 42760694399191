import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import SearchIcon from '@mui/icons-material/Search';
import {
  Box,
  ClickAwayListener,
  Divider,
  InputAdornment,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Radio,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { AllTagsOption } from '../../modules/automated-insights/constants';

const BenchmarksTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'sx',
})(({ theme, sx }) => ({
  '& .MuiInputBase-root': {
    fontSize: '12px',
    borderRadius: '27px',
    ...sx,
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiInputLabel-root': {
    color: '#575757',
    fontSize: '13px',
    textTransform: 'capitalize',
    '&.Mui-focused': {
      color: '#575757',
    },
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&:hover fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '& input': {
      cursor: sx?.cursor || 'pointer',
    },
  },
}));

const SearchInputField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'sx',
})(({ theme, sx }) => ({
  '& .MuiInputBase-root': {
    width: sx?.width,
    fontSize: '12px',
    borderRadius: '16.5px',
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#DEDEDE',
    },
    '&:hover fieldset': {
      borderColor: '#000000',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid #DEDEDE',
    },
  },
}));

const styles = {
  paper: {
    border: '1px solid #DCDCDC',
    borderRadius: '16px',
    backgroundColor: '#FFFFFF',
  },
  dropdownIcon: {
    fontSize: 15,
    color: '#0D3327',
    cursor: 'pointer',
  },
  radioButton: {
    '& .MuiSvgIcon-root': {
      fontSize: 18,
      color: (theme) => theme.palette.text.primary,
    },
    '&.Mui-checked': {
      color: (theme) => theme.palette.text.primary,
    },
  },
};

const BenchmarksFilter = ({ type, listOfTags, selectedTagId, onTagSelection, width, disablePopper }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { width: containerWidth, ref } = useResizeDetector();

  // Effect to close the Popper when the filter is out of view
  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setOpen(false);
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    // Clean up the observer on component unmount
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  const handleTextFieldClick = (event) => {
    if (disablePopper) {
      return;
    }

    const filterButton = event.currentTarget;
    setAnchorEl(filterButton);
    setOpen(!open);
  };

  const handleClickAway = () => {
    setOpen(false);
    setSearchTerm('');
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleMenuItemClick = (option) => {
    onTagSelection({ tagType: option?.type || type, tagId: option?.id });
    handleClickAway();
  };

  const filteredOptions = useMemo(() => {
    const listOfTagsIncludingAllTypesOption = [AllTagsOption, ...listOfTags];
    return listOfTagsIncludingAllTypesOption?.filter((option) =>
      option.name?.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [listOfTags, searchTerm]);

  const getSelectedTagName = (id) => {
    if (!id) {
      return AllTagsOption.name;
    }
    return listOfTags?.find((tag) => tag?.id === selectedTagId)?.name;
  };

  const canSelectTag = (tag) => {
    if (tag.id === AllTagsOption.id) {
      return true;
    }

    if (tag.isSelectable) {
      return true;
    }

    if (tag.isSelected) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <BenchmarksTextField
        ref={ref}
        label={type}
        value={getSelectedTagName(selectedTagId)}
        onClick={handleTextFieldClick}
        sx={{ width: width || '100%', cursor: disablePopper ? 'initial' : 'pointer' }}
        size="small"
        InputProps={{
          readOnly: true,
          endAdornment: disablePopper ? (
            <></>
          ) : (
            <InputAdornment position="end" sx={styles.dropdownIcon}>
              {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
            </InputAdornment>
          ),
        }}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 5], // Adjust the offset as needed (horizontal, vertical)
            },
          },
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ]}
        sx={{ zIndex: 10 }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <Paper
            elevation={0}
            sx={{ minWidth: containerWidth, maxWidth: { xs: containerWidth, sm: '100%' }, ...styles.paper }}>
            <Box p={2}>
              <SearchInputField
                autoFocus
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                placeholder="Search"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ fontSize: 15, color: '#DEDEDE' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <Divider />
            <Box padding={1}>
              {filteredOptions?.length > 0 ? (
                <List className="lightContainer" sx={{ maxHeight: 180, overflowX: 'auto' }}>
                  {filteredOptions?.map((tag) => (
                    <ListItemButton
                      key={tag?.name}
                      onClick={() => handleMenuItemClick(tag)}
                      sx={{ paddingLeft: 1, paddingRight: 2, paddingY: 0 }}
                      disabled={!canSelectTag(tag)}>
                      <ListItemIcon>
                        <Radio checked={selectedTagId === tag?.id} sx={styles.radioButton} />
                      </ListItemIcon>
                      <ListItemText
                        sx={{ marginLeft: -2 }}
                        primary={tag?.name}
                        primaryTypographyProps={{ display: 'inline', fontSize: 12 }}
                        secondary={tag?.isRecommended ? '(Recommended)' : ''}
                        secondaryTypographyProps={{
                          component: 'span',
                          display: 'inline',
                          paddingLeft: 1,
                          fontSize: 10,
                          fontStyle: 'italic',
                          color: '#264151',
                        }}
                      />
                    </ListItemButton>
                  ))}
                </List>
              ) : (
                <Typography fontSize={12} fontStyle={'italic'} sx={{ paddingX: 1 }}>
                  No results
                </Typography>
              )}
            </Box>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
};

export default BenchmarksFilter;
