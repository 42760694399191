import { TextField, styled } from '@mui/material';

export const GradientTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isFocused' && prop !== 'inputBaseRootStyles',
})(({ theme, isFocused, inputBaseRootStyles }) => ({
  '& .MuiInputBase-root': {
    height: '50px',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    background: isFocused ? theme.palette.gradient.main : 'transparent',
    border: '1px solid rgba(217, 217, 217, 0.2)',
    borderRadius: '10px',
    ...inputBaseRootStyles,
  },
  '& .MuiInputBase-input': {
    fontSize: 16,
    padding: '4px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    opacity: 0.6,
    '&:focus': {
      opacity: 1.0,
    },
  },
  '& fieldset': { border: 'none' },
}));

export const LightTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isFocused' && prop !== 'inputBaseRootStyles',
})(({ theme, isFocused, inputBaseRootStyles }) => ({
  '& .MuiInputBase-root': {
    height: '50px',
    color: theme.palette.text.primary,
    fontWeight: 400,
    border: isFocused ? '1px solid rgba(217, 217, 217, 1)' : '1px solid rgba(217, 217, 217, 0.5)',
    borderRadius: '10px',
    ...inputBaseRootStyles,
  },
  '& .MuiInputBase-input': {
    fontSize: 16,
    padding: '4px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.primary,
    opacity: 0.6,
    '&:focus': {
      opacity: 1.0,
    },
  },
  '& fieldset': { border: 'none' },
}));

export const DarkTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isFocused' && prop !== 'inputBaseRootStyles' && prop !== 'glow',
})(({ theme, isFocused, inputBaseRootStyles, glow }) => ({
  '& .MuiInputBase-root': {
    position: 'relative',
    height: '50px',
    color: theme.palette.text.secondary,
    fontWeight: 400,
    backgroundColor: '#082434',
    border: isFocused ? '1px solid rgba(217, 217, 217, 0.5)' : '1px solid rgba(217, 217, 217, 0.2)',
    borderRadius: '10px',

    ...inputBaseRootStyles,
    ...(glow &&
      isFocused && {
        border: '1px solid rgba(217, 217, 217, 0.2)',
        '&:before': {
          content: '""',
          position: 'absolute',
          width: 'calc(100% + 4px)',
          height: 'calc(100% + 4px)',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          borderRadius: '10px',
          backgroundImage: 'linear-gradient(0deg, #3b6cab, #35ffb6)',
          opacity: 0.3,
          filter: 'blur(5px)',
          animation: 'glow 4s linear infinite',
          zIndex: -1,
        },
        '@keyframes glow': {
          '0%': {
            opacity: 1,
          },
          '50%': {
            opacity: 0.5,
          },
          '100%': {
            opacity: 1,
          },
        },
      }),
  },
  '& .MuiInputBase-input': {
    fontSize: 16,
    padding: '4px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.text.secondary,
    opacity: 0.6,
    '&::placeholder': {
      color: 'rgba(255, 255, 255, 1)',
      opacity: 0.6,
      fontSize: '12px',
      fontWeight: 700,
    },
    '&.Mui-disabled': {
      WebkitTextFillColor: 'rgba(255, 255, 255, 1)',
    },
    '&:focus': {
      opacity: 1.0,
    },
  },
  '& fieldset': { border: 'none' },
}));

export const TitleTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'isEditable' && prop !== 'isUntitled',
})(({ theme, isEditable, isUntitled }) => ({
  '& .MuiInputBase-root': {
    height: '34px',
    color: theme.palette.text.secondary,
    fontWeight: isEditable || isUntitled ? 400 : 600,
    backgroundColor: isEditable || isUntitled ? 'rgba(255, 255, 255, 0.12)' : 'transparent',
    borderRadius: '27px',
  },
  '& .MuiInputBase-input': {
    padding: '4px 12px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& fieldset': { border: 'none' },
}));

export const StyledTextField = styled(TextField, {
  shouldForwardProp: (prop) =>
    prop !== 'inputLabelStyles' &&
    prop !== 'inputBaseRootStyles' &&
    prop !== 'inputBaseInputStyles' &&
    prop !== 'fieldsetStyles',
})(({ theme, inputLabelStyles, inputBaseRootStyles, inputBaseInputStyles, fieldsetStyles }) => ({
  ...(inputLabelStyles && {
    '& .MuiInputLabel-root': {
      ...inputLabelStyles,
    },
  }),
  ...(inputBaseRootStyles && {
    '& .MuiInputBase-root': {
      ...inputBaseRootStyles,
    },
  }),
  ...(inputBaseInputStyles && {
    '& .MuiInputBase-input': {
      padding: '4px 12px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...inputBaseInputStyles,
    },
  }),
  ...(fieldsetStyles && {
    '& fieldset': {
      ...fieldsetStyles,
    },
  }),
}));
