import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomScreenerMutationKeys } from '../../../modules/intake/constants';

const deleteCustomScreener = async ({ wevoId, filterId }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/filters/${filterId}`,
    method: 'DELETE',
  });
};

/**
 * Custom hook for deleting a custom screener question.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the updated data
 *
 */
export default function useDeleteCustomScreener() {
  const queryClient = useQueryClient();

  return useMutation(deleteCustomScreener, {
    mutationKey: CustomScreenerMutationKeys.deleteCustomScreener,
    onSuccess: (data, { wevoId }) => {
      queryClient.invalidateQueries(['customScreeners', { wevoId }]);
    },
  });
}
