import { useQueries, useQuery } from 'react-query';
import axios from '../../../api';

const fetchUsabilityScores = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/usability-scores`,
    method: 'GET',
  });

  return data?.usabilityScores ?? null;
};

/**
 *
 * @param {{wevoId: string, pageIds: array[string]}} params
 * @returns
 */
export default function useFetchUsabilityScores({ wevoId, pageId }, options) {
  return useQuery(['usabilityScores', { wevoId, pageId }], fetchUsabilityScores, {
    notifyOnChangeProps: 'tracked',
    ...options,
  });
}

export function useFetchAllUsabilityScores(queries, options) {
  return useQueries(
    queries.map(({ wevoId, pageId }) => {
      return {
        queryKey: ['usabilityScores', { wevoId, pageId }],
        queryFn: fetchUsabilityScores,
        notifyOnChangeProps: 'tracked',
        ...options,
      };
    })
  );
}
