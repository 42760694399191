import { MenuItem, TextField, styled } from '@mui/material';
import { AutomatedInsightChatMenuItemToDisplayName } from '../../modules/automated-insights/constants';
// import { TrackEvent } from '../analytics';
import DropdownIcon from './ui/DropdownIcon';

const SectionTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'sx' && prop !== 'highlight',
})(({ theme, sx, highlight }) => ({
  '& .MuiInputBase-root': {
    width: sx?.width,
    fontSize: '12px',
    borderRadius: '27px',
    ...(highlight && {
      backgroundColor: theme.palette.secondary.main,
      color: 'white',
      fontWeight: 600,
    }),
  },
  '& .MuiInputBase-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontWeight: '700',
    textTransform: 'capitalize',
  },
  '& .MuiInputLabel-root': {
    color: '#575757',
    fontSize: '13px',
    '&.Mui-focused': {
      color: '#575757',
    },
    ...(highlight && {
      display: 'none',
    }),
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&:hover fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(160, 160, 160, 0.37)',
    },
    '& input': {
      cursor: sx?.cursor || 'pointer',
    },
  },
}));

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '12px',
  textTransform: 'capitalize',
}));

const PromptsSelect = ({
  selectedMenuItem,
  onMenuItemChange,
  sections,
  width = 170,
  maxHeight = 250,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
}) => {
  return (
    <SectionTextField
      id="select-section"
      select
      sx={{ width }}
      size="small"
      value={selectedMenuItem}
      onChange={onMenuItemChange}
      SelectProps={{
        IconComponent: (props) => <DropdownIcon {...props} />,
        MenuProps: {
          className: 'transparentScrollBar',
          anchorOrigin: anchorOrigin,
          transformOrigin: transformOrigin,
          PaperProps: {
            sx: {
              boxShadow: 'none',
              border: '1px solid rgba(160, 160, 160, 0.37)',
              maxHeight: maxHeight,
            },
          },
        },
      }}>
      {sections?.map((section) => (
        <StyledMenuItem
          key={section}
          value={section}
          disableRipple
          style={{ fontWeight: selectedMenuItem === section ? '700' : '' }}>
          {AutomatedInsightChatMenuItemToDisplayName[section]}
        </StyledMenuItem>
      ))}
    </SectionTextField>
  );
};

export default PromptsSelect;
