export const Throbber = ({
  centerColor,
  centerStroke,
  centerStrokeColor,
  haloColor,
  haloStroke,
  pulseColor,
}) => {
  const size = 64;
  const halfSize = size / 2;

  // We want the center circle to have an apparent radius of 11, but sometimes we draw a stroke around it. Strokes
  // are centered on the radius so we need to add half the stroke width to make sure the filled portion has radius=11
  const centerRadius = 11 + centerStroke / 2;

  const haloRadius = 18;
  const haloExtent = haloRadius + haloStroke / 2;

  // The mask's job is to draw a hole in the "pulse" circle so that it doesn't draw over the halo & center circles.
  // That means we want an apparent radius equal to haloExtent. The mask has a stroke of 15 so we have to account for
  // that as well.
  const maskRadius = haloExtent + 15 / 2;

  const shared = { cx: halfSize, cy: halfSize, strokeMiterlimit: 10 };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${size} ${size}`}>
      <defs>
        <mask id="pulse-mask" x="0" y="0" width={size} height={size}>
          <circle {...shared} r={maskRadius} fill="none" stroke="white" strokeWidth="15" />
        </mask>
      </defs>
      <circle
        {...shared}
        r={centerRadius}
        fill={centerColor}
        stroke={centerStrokeColor}
        strokeWidth={centerStroke}
      />
      <circle {...shared} r={haloRadius} fill="none" stroke={haloColor} strokeWidth={haloStroke} />
      <circle {...shared} r={halfSize} fill={pulseColor} opacity="0.4" mask="url(#pulse-mask)">
        <animate
          attributeName="r"
          repeatCount="2"
          dur="1s"
          values={`${haloExtent}; ${halfSize}; ${haloExtent}`}
          keyTimes="0; 0.5; 1"
          keySplines=".42 0 1 1; 0 0 .59 1;"
          fill="freeze"
        />
      </circle>
    </svg>
  );
};
