import React from 'react';
import { Route } from 'react-router-dom';
import { isAuthenticated, redirectToLogin } from '../../modules/user/helpers';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  if (!isAuthenticated()) {
    redirectToLogin();
    return null;
  }
  return <Route {...rest} component={Component} />;
};

export default ProtectedRoute;
