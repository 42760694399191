import Container from '@mui/material/Container';
import React from 'react';
import NewProfileForm from './NewProfile';
import ProfileForm from './Profile';

const Profile = (props) => {
  return (
    <Container component="main" maxWidth="false" sx={{ maxWidth: { xs: '80%', lg: '40%' } }}>
      {props.selfServiceSubscription ? <NewProfileForm {...props} /> : <ProfileForm {...props} />}
    </Container>
  );
};

export default Profile;
