import { useMutation, useQuery } from 'react-query';
import api from '../../../api';

const getPreviewAllocation = async ({ queryKey: [key, { quantity }] }) => {
  const response = await api({
    url: `/api/v2/pulse-seats/allocations/preview?quantity=${quantity}`,
    method: 'GET',
  });
  return response?.data;
};

export function useGetPreviewAllocation({ quantity }, queryOptions) {
  return useQuery(['previewSeatAllocation', { quantity }], getPreviewAllocation, {
    notifyOnChangeProps: 'tracked',
    ...queryOptions,
  });
}

const allocateSeats = async ({ quantity }) => {
  const response = await api({
    url: `/api/v2/pulse-seats/allocations`,
    method: 'PUT',
    data: {
      quantity,
    },
  });

  return response?.data;
};

export function useAllocateSeats(options = {}) {
  return useMutation(allocateSeats, options);
}

const pulseBulkCreateUsers = async ({ users }) => {
  const response = await api({
    url: `/api/v2/pulse/users/bulk-create`,
    method: 'POST',
    data: {
      users,
      sendUserInvites: true,
    },
  });

  return response?.data;
};

export function usePulseBulkCreateUsers(options = {}) {
  return useMutation(pulseBulkCreateUsers, options);
}

const assignUsersToSeats = async ({ userIds }) => {
  const response = await api({
    url: `/api/v2/pulse-seats/assign`,
    method: 'PUT',
    data: {
      userIds,
    },
  });

  return response?.data;
};

export function useAssignUsersToSeats(options = {}) {
  return useMutation(assignUsersToSeats, options);
}

const unallocateSeat = async ({ seatId }) => {
  const response = await api({
    url: `/api/v2/pulse-seats/${seatId}/allocation`,
    method: 'DELETE',
  });

  return response?.data;
};

export function useUnallocateSeat(options = {}) {
  return useMutation(unallocateSeat, options);
}

const reallocateSeat = async ({ seatId }) => {
  const response = await api({
    url: `/api/v2/pulse-seats/${seatId}/reallocate`,
    method: 'PUT',
  });

  return response?.data;
};

export function useReallocateSeat(options = {}) {
  return useMutation(reallocateSeat, options);
}
