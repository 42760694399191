import { Box, CircularProgress, Grid } from '@mui/material';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import {
  AutomatedInsightSectionToDisplayName,
  AutomatedInsightSections,
  AutomatedInsightSessionType,
  IncludeExperienceRecommendations,
  ProgressKeys,
  PulseTips,
  TaskStatus,
} from '../../modules/automated-insights/constants';
import {
  ProgressKeyHandlers,
  canOnlyUseAutomatedInsightSessions,
  getProgressKeysAfter,
  secondsToMinutes,
} from '../../modules/automated-insights/helpers';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import ActionButtonsBar from './ActionButtonsBar';
import { MultiSessionAudienceSelect } from './AudienceSelect';
import AutomatedKeyFindings from './AutomatedKeyFindings';
import ComingSoonPage from './ComingSoonPage';
import { InfoBox, WaitingMessage } from './InfoBox';
import InsightSelectionButtons from './InsightSelectionButtons';
import PersonaIntent from './PersonaIntent';
import PersonasAudienceSelect from './PersonasAudienceSelect';
import PersonasContainer, { MultiSessionSegmentsContainer, SessionPersonasIntent } from './PersonasContainer';
import ProposedAudienceContainer from './ProposedAudienceContainer';
import Quant from './Quant';
import RecommendedExperiments from './RecommendedExperiments';
import RetrySession from './RetrySession';
import SentimentMapInsights from './SentimentMapInsights';
import SplashLoadingIndicator, { TaskLoadingScreen } from './SplashLoadingIndicator';
import TipBox from './TipBox';
import useFetchSegments from './hooks/useFetchSegments';
import CustomSnackbar from './ui/Snackbar';

const STATUS_MESSAGES = {
  pending: 'Request to generate insights is pending',
  enqueued: 'Getting ready to generate insights',
  running: 'Hold tight! We are generating personas for this Pulse.',
};

const ContentContainer = ({ backgroundColor, children, openChat, hideActionButtons }) => {
  // add bottom padding when chat is open in order for all of the content to be visible when the chat is in the collapsed state
  const contentBottomPadding = useMemo(() => {
    if (!openChat) {
      return 0;
    }

    if (hideActionButtons) {
      // height of collapsed chat drawer minus approx. height of the bottom action buttons bar without the buttons
      return 'calc(100vh - 390px - 50px)';
    }

    // height of collapsed chat drawer minus approx. height of the bottom action buttons bar with buttons
    return 'calc(100vh - 390px - 100px)';
  }, [openChat, hideActionButtons]);

  return (
    <Box
      sx={{
        height: '100%',
        paddingTop: 2,
        overflowY: 'hidden',
        backgroundColor: backgroundColor || '',
        paddingBottom: contentBottomPadding,

        // same transition used for the  chat drawer when it opens/closes
        transition: (theme) =>
          theme.transitions.create('padding-bottom', {
            easing: theme.transitions.easing.sharp,
            duration: '100ms',
          }),
      }}>
      {children}
    </Box>
  );
};

const Insights = ({
  taskProgress,
  taskStatus,
  heatmapData,
  themeData,
  personaIntentData,
  themedFindings,
  keyFindings,
  recommendedExperimentsData,
  quantData,
  sessionDirective,
  sessionGoal,
  session,
  sessionSegments,
  selectedSegment,
  selectedTab,
  setSelectedTab,
  selectedClustersList,
  hasContentiousQuotes,
  selectedQuote,
  selectedLocation,
  segmentToResultMap,
  onTabClick,
  onClusterClick,
  onQuoteClick,
  onShowAudienceList,
  onSelectSegment,
  onInsightsRegeneration,
  onToggleShareDialog,
  onToggleInviteColleagueDialog,
  isSessionValid,
  showSplashScreen,
  setShowSplashScreen,
  isCustomizingSegment,
  setIsCustomizingSegment,
  openChat,
  onToggleChat,
  showQuant,
  setSelectedQuantTheme,
  selectedQuantTheme,
  isQuantReady,
  hasConfirmedPersona,
  onPersonaConfirmation,
  isShareLink,
  hideReportContent,
  benchmarksData,
  allTags,
}) => {
  const isCompare = useMemo(() => session?.type === AutomatedInsightSessionType.Compare, [session]);
  const isSinglePage = useMemo(() => session?.type === AutomatedInsightSessionType.Page, [session]);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [closedSnackbar, setClosedSnackbar] = useState(false);

  const [currentTipIndex, setCurrentTipIndex] = useState(0);

  const { track } = useAnalytics();
  const userProfile = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);
  const onlyAutomatedInsightSessionsEnabled = canOnlyUseAutomatedInsightSessions(
    userProfile,
    userCustomizations
  );
  const isSessionOwner = !!userProfile?.id && String(userProfile?.id) === String(session?.userId);

  const randomPulseTips = useMemo(() => {
    return _.shuffle(PulseTips);
  }, []);

  const { data: ownerSegments } = useFetchSegments({
    enabled: isSessionOwner,
  });

  const ownerSegmentsWithoutSessionInsights = useMemo(() => {
    const sessionSegmentsById = sessionSegments.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});

    const filteredSegments =
      ownerSegments?.filter((segment) => !segment.isGenerated && !sessionSegmentsById[segment?.id]) || [];
    return filteredSegments;
  }, [ownerSegments, sessionSegments]);

  const handleSectionButtonClick = useCallback(
    (section) => {
      onTabClick(section);
      onShowAudienceList(false);
      setIsCustomizingSegment(false);
    },
    [onTabClick, onShowAudienceList, setIsCustomizingSegment]
  );

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
    setClosedSnackbar(true);
  };

  const changeAudience = useCallback(
    (segment) => {
      if (selectedSegment?.id !== segment?.id) {
        onSelectSegment(segment);
        onTabClick(null);
      } else {
        if (!!keyFindings) {
          onTabClick(AutomatedInsightSections.KeyFindings);
        } else if (taskProgress?.progressKey === ProgressKeys.Insights)
          onTabClick(AutomatedInsightSections.Audience);
        else {
          onTabClick(null);
        }
      }
      onShowAudienceList(false);
      setIsCustomizingSegment(false);
    },
    [
      selectedSegment,
      keyFindings,
      taskProgress?.progressKey,
      onSelectSegment,
      onShowAudienceList,
      onTabClick,
      setIsCustomizingSegment,
    ]
  );

  const viewMyAudiences = useCallback(() => {
    onShowAudienceList(true);
    onTabClick(AutomatedInsightSections.MyAudience);
  }, [onShowAudienceList, onTabClick]);

  const runStudy = useCallback(
    ({ trackEvent }) => {
      track(trackEvent || TrackEvent.CLICKED_RUN_STUDY_BUTTON_FROM_PULSE, {
        isPulseOnly: onlyAutomatedInsightSessionsEnabled,
        sessionId: session?.id,
        segmentId: selectedSegment?.id,
      });

      if (onlyAutomatedInsightSessionsEnabled) {
        window.open('https://wevo.ai/wevo-pro/', '_blank', 'noopener,noreferrer');
        return;
      }

      window.location.href = `${process.env.REACT_APP_BASE_URL}/dashboard`;
    },
    [onlyAutomatedInsightSessionsEnabled, session, selectedSegment, track]
  );

  const timeElapsedSeconds = useMemo(() => {
    const progressKeyHandler = ProgressKeyHandlers.find((progressKeyHandler) =>
      progressKeyHandler.matches(taskProgress, session)
    );

    if (!progressKeyHandler) {
      return 0;
    }

    return progressKeyHandler.getTimeElapsed(taskProgress, session);
  }, [session, taskProgress]);

  const timeElapsedWaiting = useMemo(() => {
    return taskProgress?.createdAt ? (new Date() - new Date(taskProgress.createdAt)) / 1000 : 0;
  }, [taskProgress]);

  const estimatedDelaySeconds = 25;
  const estimatedWaitingSeconds = 30;
  const estimatedFloorSeconds = 10;

  // we do progress keys in snake case but, because they are keys in the timingEstimates object, artemis/v2 send them as camel case
  const timingEstimate = useMemo(() => {
    if (!taskProgress?.progressKey || taskProgress?.progressKey === ProgressKeys.Done) {
      return;
    }

    const progressKeyHandler = ProgressKeyHandlers.find((progressKeyHandler) =>
      progressKeyHandler.matches(taskProgress, session)
    );

    if (!progressKeyHandler) {
      return 0;
    }

    return progressKeyHandler.getTimingEstimate(taskProgress, session);
  }, [session, taskProgress]);

  const timeRemainingSeconds = useMemo(() => {
    if (!taskProgress?.startedAt) {
      return null;
    }
    return Math.max(timingEstimate - timeElapsedSeconds, estimatedFloorSeconds);
  }, [taskProgress, timingEstimate, timeElapsedSeconds]);

  const progressPercentage = useMemo(() => {
    if (taskStatus === TaskStatus.Completed) {
      return 100;
    }
    if (taskStatus === TaskStatus.Failed) {
      return 0;
    }

    return timeElapsedSeconds > timingEstimate - estimatedFloorSeconds
      ? ((timingEstimate - estimatedFloorSeconds) / timingEstimate) * 100
      : (timeElapsedSeconds / timingEstimate) * 100;
  }, [taskStatus, timeElapsedSeconds, timingEstimate]);

  const isTaskDelayed = useMemo(
    () => !!timeElapsedSeconds && timeElapsedSeconds > timingEstimate + estimatedDelaySeconds,
    [timeElapsedSeconds, timingEstimate]
  );

  const isTaskWaiting = useMemo(
    () =>
      (taskStatus === TaskStatus.Pending || taskStatus === TaskStatus.Enqueued) &&
      timeElapsedWaiting > estimatedWaitingSeconds,
    [timeElapsedWaiting, taskStatus]
  );

  const timeRemainingLabel = useMemo(() => {
    const isFailed = taskStatus === TaskStatus.Failed;
    if (!timeRemainingSeconds) {
      return '';
    }

    if (isFailed) {
      return '';
    }

    const time =
      timeRemainingSeconds > estimatedFloorSeconds
        ? secondsToMinutes(timeRemainingSeconds)
        : `~ ${secondsToMinutes(estimatedFloorSeconds)}`;

    return time;
  }, [taskStatus, timeRemainingSeconds]);

  const showTaskStatus = useMemo(
    () =>
      ([TaskStatus.Pending, TaskStatus.Enqueued].includes(taskStatus) ||
        (taskStatus === TaskStatus.Running &&
          ([
            ProgressKeys.Initialized,
            ProgressKeys.TextExtract,
            ProgressKeys.TargetAudience,
            ProgressKeys.Insights,
          ].includes(taskProgress?.progressKey) ||
            !taskProgress?.progressKey) &&
          !isCompare)) &&
      !keyFindings,
    [taskStatus, taskProgress, keyFindings, isCompare]
  );

  const showActionButtonsBar = useMemo(() => selectedTab !== null, [selectedTab]);

  const hideActionButtons = useMemo(
    () => [AutomatedInsightSections.MyAudience].includes(selectedTab),
    [selectedTab]
  );

  const hideChatButton = useMemo(() => {
    if ([AutomatedInsightSessionType.Compare].includes(session?.type)) {
      return true;
    } else {
      return taskStatus !== TaskStatus.Completed;
    }
  }, [session?.type, taskStatus]);

  // switch tips after 10 seconds
  useEffect(() => {
    let intervalId;
    intervalId = setInterval(() => {
      setCurrentTipIndex((prevIndex) => (prevIndex + 1) % randomPulseTips.length);
    }, 10000);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [randomPulseTips]);

  useEffect(() => {
    if (
      (([ProgressKeys.KeyFindings, ProgressKeys.HeatmapPoints, ProgressKeys.PersonaIntent].includes(
        taskProgress?.progressKey
      ) &&
        isTaskDelayed) ||
        // we don't really have timings on compare yet but its wait time is way different from other pulses, so for now don't do anything
        (isTaskWaiting && !isCompare)) &&
      !closedSnackbar
    ) {
      setOpenSnackbar(true);
    }

    // close snackbar if it's still open when the pulse completes
    if (taskStatus === TaskStatus.Completed && openSnackbar) {
      setOpenSnackbar(false);
      setClosedSnackbar(true);
    }
  }, [
    taskStatus,
    taskProgress?.progressKey,
    openSnackbar,
    closedSnackbar,
    isCompare,
    isTaskDelayed,
    isTaskWaiting,
  ]);

  const keyFindingsComponent = useMemo(() => {
    if (!isSessionValid && !keyFindings) {
      return (
        <RetrySession
          session={session}
          segment={selectedSegment}
          goal={sessionGoal}
          canRetry={isSessionOwner && taskProgress?.canRetry}
          displayText={taskProgress?.displayMessage}
        />
      );
    }

    return (
      <ContentContainer openChat={openChat} hideActionButtons={hideActionButtons}>
        <AutomatedKeyFindings
          keyFindings={keyFindings}
          themedFindings={themedFindings}
          taskStatus={taskStatus}
          taskProgress={taskProgress}
          isTaskDelayed={isTaskDelayed}
          isTaskWaiting={isTaskWaiting}
          showTheme={isSinglePage && session?.features?.score && !!themedFindings?.length}
          showSplashScreen={showSplashScreen}
          setShowSplashScreen={setShowSplashScreen}
          selectedQuantTheme={selectedQuantTheme}
          setSelectedTab={setSelectedTab}
          isQuantReady={isQuantReady}
          hideReportContent={hideReportContent}
          isSessionOwner={isSessionOwner}
          sessionDirective={sessionDirective}
          sessionGoal={sessionGoal}
        />
      </ContentContainer>
    );
  }, [
    isSinglePage,
    isSessionValid,
    keyFindings,
    themedFindings,
    selectedSegment,
    session,
    taskStatus,
    taskProgress,
    isSessionOwner,
    isTaskDelayed,
    isTaskWaiting,
    sessionDirective,
    sessionGoal,
    showSplashScreen,
    setShowSplashScreen,
    openChat,
    hideActionButtons,
    selectedQuantTheme,
    isQuantReady,
    setSelectedTab,
    hideReportContent,
  ]);

  const sentimentMapComponent = useMemo(() => {
    if (!isSessionValid && heatmapData?.length === 0) {
      return (
        <RetrySession
          session={session}
          segment={selectedSegment}
          goal={sessionGoal}
          canRetry={isSessionOwner && taskProgress?.canRetry}
          displayText={taskProgress?.displayMessage}
        />
      );
    }

    return (
      <ContentContainer openChat={openChat} hideActionButtons={hideActionButtons}>
        <SentimentMapInsights
          heatmapData={heatmapData}
          themeData={themeData}
          taskStatus={taskStatus}
          selectedClustersList={selectedClustersList}
          hasContentiousQuotes={hasContentiousQuotes}
          selectedQuote={selectedQuote}
          selectedLocation={selectedLocation}
          onClusterClick={onClusterClick}
          onQuoteClick={onQuoteClick}
        />
      </ContentContainer>
    );
  }, [
    isSessionOwner,
    isSessionValid,
    heatmapData,
    onClusterClick,
    onQuoteClick,
    selectedClustersList,
    hasContentiousQuotes,
    selectedLocation,
    selectedQuote,
    selectedSegment,
    sessionGoal,
    session,
    taskProgress?.canRetry,
    taskProgress?.displayMessage,
    taskStatus,
    themeData,
    openChat,
    hideActionButtons,
  ]);

  const audienceComponent = useMemo(() => {
    return (
      <Box
        sx={{
          height: '100%',
          paddingTop: 2,
          paddingBottom: 3,
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
        }}>
        <ProposedAudienceContainer
          taskStatus={taskStatus}
          sessionId={session?.id}
          sessionSegments={sessionSegments}
          selectedSegment={selectedSegment}
          segmentsWithoutSessionInsights={ownerSegmentsWithoutSessionInsights}
          segmentToResultMap={segmentToResultMap}
          text={'Customize Proposed personas below, create new ones or choose from the Other Personas list.'}
          isCustomizingSegment={isCustomizingSegment}
          setIsCustomizingSegment={setIsCustomizingSegment}
          isSessionOwner={isSessionOwner}
          onSelectSegment={onSelectSegment}
          onSectionChange={handleSectionButtonClick}
          onInsightsRegeneration={onInsightsRegeneration}
          hasConfirmedPersona={hasConfirmedPersona}
          onPersonaConfirmation={onPersonaConfirmation}
        />
      </Box>
    );
  }, [
    session,
    taskStatus,
    selectedSegment,
    sessionSegments,
    ownerSegmentsWithoutSessionInsights,
    segmentToResultMap,
    isCustomizingSegment,
    setIsCustomizingSegment,
    isSessionOwner,
    onSelectSegment,
    handleSectionButtonClick,
    onInsightsRegeneration,
    hasConfirmedPersona,
    onPersonaConfirmation,
  ]);

  const sections = useMemo(() => {
    if (_.isNil(session)) {
      return [];
    }

    if ([AutomatedInsightSessionType.Experience].includes(session?.type)) {
      return IncludeExperienceRecommendations
        ? [
            {
              name: AutomatedInsightSections.Audience,
              displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Audience],
              progressPercentage,
              timeRemainingLabel,
              isGenerating: [
                ProgressKeys.Initialized,
                ProgressKeys.TextExtract,
                ProgressKeys.TargetAudience,
              ].includes(taskProgress?.progressKey),
              isCompleted:
                getProgressKeysAfter(AutomatedInsightSessionType.Page, ProgressKeys.TargetAudience).includes(
                  taskProgress?.progressKey
                ) || taskStatus === TaskStatus.Completed,
              isDisabled: !isSessionValid || !selectedSegment,
              isLocked: false,
            },
            {
              name: AutomatedInsightSections.PersonaIntent,
              displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.PersonaIntent],
              progressPercentage: undefined,
              timeRemainingLabel: undefined,
              isGenerating: false,
              isCompleted: true,
              isDisabled: !isSessionValid || taskStatus !== TaskStatus.Completed,
              isLocked: false,
              isComingSoon: true,
            },
            {
              name: AutomatedInsightSections.RecommendedExperiments,
              displayName:
                AutomatedInsightSectionToDisplayName[AutomatedInsightSections.RecommendedExperiments],
              progressPercentage,
              timeRemainingLabel,
              isGenerating: taskProgress?.progressKey === ProgressKeys.RecommendedExperiments,
              isCompleted: taskStatus === TaskStatus.Completed,
              isDisabled:
                (isSessionValid && (_.isEmpty(recommendedExperimentsData) || !recommendedExperimentsData)) ||
                (!isSessionValid && !keyFindings),
              disabledTooltip:
                'This Pulse doesn’t include data for Recommended Experiments as it wasn’t available at the time of the original analysis. Try running a new Persona or a new Pulse for these results.',
              isLocked: !session?.features?.recommendations,
            },
            {
              name: AutomatedInsightSections.SentimentMap,
              displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.SentimentMap],
              progressPercentage: undefined,
              timeRemainingLabel: undefined,
              isGenerating: false,
              isCompleted: true,
              isDisabled: !isSessionValid || taskStatus !== TaskStatus.Completed,
              isLocked: false,
              isComingSoon: true,
            },
            {
              name: AutomatedInsightSections.KeyFindings,
              displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.KeyFindings],
              progressPercentage,
              timeRemainingLabel,
              isGenerating: taskProgress?.progressKey === ProgressKeys.Insights,
              isCompleted:
                getProgressKeysAfter(AutomatedInsightSessionType.Experience, ProgressKeys.Insights).includes(
                  taskProgress?.progressKey
                ) || taskStatus === TaskStatus.Completed,
              isDisabled: (isSessionValid && !keyFindings) || (!isSessionValid && !selectedSegment),
              isLocked: !session?.features?.findings,
            },
            {
              name: AutomatedInsightSections.Quant,
              displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Quant],
              isCompleted: showQuant && taskStatus === TaskStatus.Completed,
              isGenerating: showQuant && taskProgress?.progressKey === ProgressKeys.Quant,
              isDisabled:
                !isSessionValid ||
                (showQuant
                  ? _.isEmpty(recommendedExperimentsData) || _.isEmpty(quantData)
                  : taskStatus !== TaskStatus.Completed),
              ...(showQuant
                ? {
                    progressPercentage,
                    timeRemainingLabel,
                    disabledTooltip:
                      'This Pulse doesn’t include the Experience Quality Score as it wasn’t available at the time of the original analysis. Try running a new Persona or a new Pulse for these results.',
                  }
                : {}),
              isLocked: !session?.features?.score,
              isComingSoon: !showQuant,
            },
            {
              name: AutomatedInsightSections.Chat,
              displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Chat],
              isCompleted: taskStatus === TaskStatus.Completed,
              isGenerating: false,
              isDisabled:
                taskStatus === TaskStatus.Completed &&
                recommendedExperimentsData &&
                recommendedExperimentsData.length > 0
                  ? false
                  : true,
              isLocked: !session?.features?.chat,
            },
          ]
        : [
            {
              name: AutomatedInsightSections.Audience,
              displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Audience],
              progressPercentage,
              timeRemainingLabel,
              isGenerating: [
                ProgressKeys.Initialized,
                ProgressKeys.TextExtract,
                ProgressKeys.TargetAudience,
              ].includes(taskProgress?.progressKey),
              isCompleted:
                getProgressKeysAfter(
                  AutomatedInsightSessionType.Experience,
                  ProgressKeys.TargetAudience
                ).includes(taskProgress?.progressKey) || taskStatus === TaskStatus.Completed,
              isDisabled: !isSessionValid || !selectedSegment,
              isLocked: false,
            },
            {
              name: AutomatedInsightSections.KeyFindings,
              displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.KeyFindings],
              progressPercentage,
              timeRemainingLabel,
              isGenerating: taskProgress?.progressKey === ProgressKeys.Insights,
              isCompleted: taskStatus === TaskStatus.Completed,
              isDisabled: (isSessionValid && !keyFindings) || (!isSessionValid && !selectedSegment),
              isLocked: !session?.features?.findings,
            },
          ];
    } else if ([AutomatedInsightSessionType.Page].includes(session?.type)) {
      return [
        {
          name: AutomatedInsightSections.Audience,
          displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Audience],
          progressPercentage,
          timeRemainingLabel,
          isGenerating: [
            ProgressKeys.Initialized,
            ProgressKeys.TextExtract,
            ProgressKeys.TargetAudience,
          ].includes(taskProgress?.progressKey),
          isCompleted:
            getProgressKeysAfter(AutomatedInsightSessionType.Page, ProgressKeys.TargetAudience).includes(
              taskProgress?.progressKey
            ) || taskStatus === TaskStatus.Completed,
          isDisabled: !isSessionValid || !selectedSegment,
          isLocked: false,
        },
        {
          name: AutomatedInsightSections.PersonaIntent,
          displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.PersonaIntent],
          progressPercentage,
          timeRemainingLabel,
          isGenerating: taskProgress?.progressKey === ProgressKeys.PersonaIntent,
          isCompleted:
            getProgressKeysAfter(AutomatedInsightSessionType.Page, ProgressKeys.PersonaIntent).includes(
              taskProgress?.progressKey
            ) || taskStatus === TaskStatus.Completed,
          isDisabled:
            (isSessionValid && (_.isEmpty(personaIntentData) || !personaIntentData)) ||
            (!isSessionValid && !keyFindings),
          disabledTooltip:
            'This Pulse doesn’t include data for Persona Intent as it wasn’t available at the time of the original analysis. Try running a new Persona or a new Pulse for these results.',
          isLocked: !session?.features?.intent,
        },
        {
          name: AutomatedInsightSections.RecommendedExperiments,
          displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.RecommendedExperiments],
          progressPercentage,
          timeRemainingLabel,
          isGenerating: taskProgress?.progressKey === ProgressKeys.RecommendedExperiments,
          isCompleted: taskStatus === TaskStatus.Completed,
          isDisabled:
            (isSessionValid && (_.isEmpty(recommendedExperimentsData) || !recommendedExperimentsData)) ||
            (!isSessionValid && !keyFindings),
          disabledTooltip:
            'This Pulse doesn’t include data for Recommended Experiments as it wasn’t available at the time of the original analysis. Try running a new Persona or a new Pulse for these results.',
          isLocked: !session?.features?.recommendations,
        },
        {
          name: AutomatedInsightSections.SentimentMap,
          displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.SentimentMap],
          progressPercentage,
          timeRemainingLabel,
          isGenerating: taskProgress?.progressKey === ProgressKeys.HeatmapPoints,
          isCompleted:
            getProgressKeysAfter(AutomatedInsightSessionType.Page, ProgressKeys.HeatmapPoints).includes(
              taskProgress?.progressKey
            ) || taskStatus === TaskStatus.Completed,
          isDisabled:
            (isSessionValid && (_.isEmpty(heatmapData) || !heatmapData)) || (!isSessionValid && !keyFindings),
          isLocked: !session?.features?.sentiment,
        },
        {
          name: AutomatedInsightSections.KeyFindings,
          displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.KeyFindings],
          progressPercentage,
          timeRemainingLabel,
          isGenerating: taskProgress?.progressKey === ProgressKeys.Insights,
          isCompleted:
            getProgressKeysAfter(AutomatedInsightSessionType.Page, ProgressKeys.Insights).includes(
              taskProgress?.progressKey
            ) || taskStatus === TaskStatus.Completed,
          isDisabled: (isSessionValid && !keyFindings) || (!isSessionValid && !selectedSegment),
          isLocked: !session?.features?.findings,
        },
        {
          name: AutomatedInsightSections.Quant,
          displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Quant],
          isCompleted: showQuant && taskStatus === TaskStatus.Completed,
          isGenerating: showQuant && taskProgress?.progressKey === ProgressKeys.Quant,
          isDisabled:
            !showQuant ||
            !isSessionValid ||
            (showQuant && (_.isEmpty(recommendedExperimentsData) || _.isEmpty(quantData))) ||
            taskStatus !== TaskStatus.Completed,
          ...(showQuant
            ? {
                progressPercentage,
                timeRemainingLabel,
                disabledTooltip:
                  'This Pulse doesn’t include the Experience Quality Score as it wasn’t available at the time of the original analysis. Try running a new Persona or a new Pulse for these results.',
              }
            : {}),
          isLocked: !session?.features?.score,
        },
        {
          name: AutomatedInsightSections.Chat,
          displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Chat],
          isCompleted: taskStatus === TaskStatus.Completed,
          isGenerating: false,
          isDisabled:
            (taskStatus === TaskStatus.Completed && heatmapData && heatmapData.length > 0) ||
            (recommendedExperimentsData && recommendedExperimentsData.length > 0)
              ? false
              : true,
          isLocked: !session?.features?.chat,
        },
      ];
    } else {
      return [
        {
          name: AutomatedInsightSections.KeyFindings,
          displayName: AutomatedInsightSectionToDisplayName[AutomatedInsightSections.KeyFindings],
          progressPercentage,
          timeRemainingLabel,
          isGenerating: taskProgress?.progressKey === ProgressKeys.Insights,
          isCompleted: taskStatus === TaskStatus.Completed,
          isDisabled: (isSessionValid && !keyFindings) || (!isSessionValid && !selectedSegment),
          isLocked: !session?.features?.findings,
        },
      ];
    }
  }, [
    session,
    isSessionValid,
    keyFindings,
    heatmapData,
    taskProgress?.progressKey,
    taskStatus,
    timeRemainingLabel,
    selectedSegment,
    personaIntentData,
    progressPercentage,
    recommendedExperimentsData,
    showQuant,
    quantData,
  ]);

  const personaComponent = useMemo(() => {
    return (
      <Box
        sx={{
          height: '100%',
          paddingTop: 2,
          paddingBottom: 3,
          borderBottomLeftRadius: '20px',
          borderBottomRightRadius: '20px',
        }}>
        {
          <SessionPersonasIntent
            session={session}
            sessionSegments={sessionSegments}
            sections={sections?.filter(
              (section) => ![AutomatedInsightSections.Audience].includes(section.name)
            )}
            segment={selectedSegment}
            isOwner={isSessionOwner}
            isGenerated={!selectedSegment || selectedSegment?.isGenerated}
            hasInsightsGenerating={
              !segmentToResultMap[selectedSegment?.id]?.findings &&
              sessionSegments?.some((segment) => segment?.id === selectedSegment?.id)
            }
            hasSessionInsights={
              segmentToResultMap[selectedSegment?.id]?.findings &&
              sessionSegments?.some((segment) => segment?.id === selectedSegment?.id)
            }
            initialIsExpanded={true}
            showActions={false}
            canToggle={false}
            isSelected={false}
            maxHeightExpanded={350}
          />
        }
      </Box>
    );
  }, [selectedSegment, sessionSegments, segmentToResultMap, isSessionOwner, session, sections]);

  const personaIntentComponent = useMemo(() => {
    if (!isSessionValid && personaIntentData?.length === 0) {
      return (
        <RetrySession
          session={session}
          segment={selectedSegment}
          goal={sessionGoal}
          canRetry={isSessionOwner && taskProgress?.canRetry}
          displayText={taskProgress?.displayMessage}
        />
      );
    }
    return (
      <ContentContainer openChat={openChat} hideActionButtons={hideActionButtons}>
        <PersonaIntent
          personaIntentData={personaIntentData}
          taskStatus={taskStatus}
          taskProgress={taskProgress}
          selectedSegment={selectedSegment}
          hideReportContent={hideReportContent}
          isSessionOwner={isSessionOwner}
        />
      </ContentContainer>
    );
  }, [
    isSessionOwner,
    isSessionValid,
    personaIntentData,
    selectedSegment,
    sessionGoal,
    session,
    taskProgress,
    taskStatus,
    openChat,
    hideActionButtons,
    hideReportContent,
  ]);

  const recommendedExperimentsComponent = useMemo(() => {
    if (!isSessionValid && recommendedExperimentsData?.length === 0) {
      return (
        <RetrySession
          session={session}
          segment={selectedSegment}
          goal={sessionGoal}
          canRetry={isSessionOwner && taskProgress?.canRetry}
          displayText={taskProgress?.displayMessage}
        />
      );
    }
    return (
      <ContentContainer openChat={openChat} hideActionButtons={hideActionButtons}>
        <RecommendedExperiments
          recommendedExperiments={recommendedExperimentsData}
          selectedQuantTheme={selectedQuantTheme}
          setSelectedTab={setSelectedTab}
          showTheme={isSinglePage && session?.features?.score}
          isQuantReady={isQuantReady}
          hideReportContent={hideReportContent}
          isSessionOwner={isSessionOwner}
        />
      </ContentContainer>
    );
  }, [
    isSessionOwner,
    isSessionValid,
    isSinglePage,
    recommendedExperimentsData,
    selectedSegment,
    sessionGoal,
    session,
    openChat,
    hideActionButtons,
    selectedQuantTheme,
    setSelectedTab,
    isQuantReady,
    taskProgress?.canRetry,
    taskProgress?.displayMessage,
    hideReportContent,
  ]);

  const quantComponent = useMemo(() => {
    if (!isSessionValid && !_.isEmpty(quantData)) {
      return (
        <RetrySession
          session={session}
          segment={selectedSegment}
          goal={sessionGoal}
          canRetry={isSessionOwner && taskProgress?.canRetry}
          displayText={taskProgress?.displayMessage}
        />
      );
    }
    return (
      <ContentContainer openChat={openChat} hideActionButtons={hideActionButtons}>
        <Quant
          sessionId={session?.id}
          quantData={quantData}
          benchmarksData={benchmarksData}
          allTags={allTags}
          onQuestionsClick={() => onToggleChat(true)}
          setSelectedQuantTheme={setSelectedQuantTheme}
          setSelectedTab={setSelectedTab}
          hideReportContent={hideReportContent}
          isSessionOwner={isSessionOwner}
        />
      </ContentContainer>
    );
  }, [
    isSessionOwner,
    isSessionValid,
    quantData,
    benchmarksData,
    allTags,
    selectedSegment,
    sessionGoal,
    session,
    openChat,
    onToggleChat,
    hideActionButtons,
    setSelectedTab,
    setSelectedQuantTheme,
    taskProgress?.canRetry,
    taskProgress?.displayMessage,
    hideReportContent,
  ]);

  const allDependentTasksResolved = useMemo(() => {
    if ((taskProgress?.dependencyTasks ?? []).length === 0) {
      return true;
    }

    return taskProgress.dependencyTasks.every((depTask) =>
      [TaskStatus.Completed, TaskStatus.Failed].includes(depTask.status)
    );
  }, [taskProgress]);

  const filteredSections = useMemo(() => {
    return sections?.filter(
      (section) =>
        ![AutomatedInsightSections.Audience, AutomatedInsightSections.PersonaIntent].includes(section.name)
    );
  }, [sections]);

  const audienceSection = useMemo(() => {
    return sections?.filter((section) => [AutomatedInsightSections.Audience].includes(section.name));
  }, [sections]);

  const audienceSelectComponent = useMemo(() => {
    if (isCompare) {
      return (
        <Box>
          <MultiSessionAudienceSelect
            sessions={session?.linkedSessions ?? []}
            selectedSegment={selectedSegment}
            segments={sessionSegments}
            onAudienceChange={(segment) => {
              onSelectSegment(segment);
              onShowAudienceList(true);
              onTabClick(AutomatedInsightSections.MyAudience);
            }}
            onMyAudiencesClick={() => {
              onSelectSegment(null);
              onShowAudienceList(true);
              onTabClick(AutomatedInsightSections.MyAudience);
            }}
            disabled={!allDependentTasksResolved}
          />
        </Box>
      );
    }

    return (
      <Box>
        <PersonasAudienceSelect
          selectedSegment={selectedSegment}
          selectedTab={selectedTab}
          segments={sessionSegments}
          isReadOnly={!sessionSegments?.length || (sessionSegments?.length === 1 && !keyFindings)}
          onAudienceChange={changeAudience}
          onAudienceCreateClick={sessionSegments.length ? viewMyAudiences : () => {}}
          section={audienceSection[0]}
          setSelectedSection={setSelectedTab}
          onSectionButtonClick={handleSectionButtonClick}
        />
      </Box>
    );
  }, [
    allDependentTasksResolved,
    isCompare,
    keyFindings,
    session,
    sessionSegments,
    selectedSegment,
    selectedTab,
    changeAudience,
    viewMyAudiences,
    onSelectSegment,
    onShowAudienceList,
    onTabClick,
    handleSectionButtonClick,
    audienceSection,
    setSelectedTab,
  ]);

  const myAudienceComponent = useMemo(() => {
    if (isCompare) {
      return (
        <MultiSessionSegmentsContainer
          sessions={session?.linkedSessions ?? []}
          taskStatus={taskStatus}
          sessionId={session?.id}
          sessionSegments={sessionSegments}
          selectedSegment={selectedSegment}
          segmentsWithoutSessionInsights={ownerSegmentsWithoutSessionInsights}
          segmentToResultMap={segmentToResultMap}
          isSessionOwner={isSessionOwner}
          onSelectSegment={onSelectSegment}
          onSectionChange={handleSectionButtonClick}
          onInsightsRegeneration={onInsightsRegeneration}
        />
      );
    }
    return (
      <ContentContainer backgroundColor="#F8F8F8" openChat={openChat} hideActionButtons={hideActionButtons}>
        <PersonasContainer
          taskStatus={taskStatus}
          sessionId={session?.id}
          session={session}
          sessionSegments={sessionSegments}
          selectedSegment={selectedSegment}
          segmentsWithoutSessionInsights={ownerSegmentsWithoutSessionInsights}
          segmentToResultMap={segmentToResultMap}
          text={'Choose your persona.'}
          isCustomizingSegment={isCustomizingSegment}
          setIsCustomizingSegment={setIsCustomizingSegment}
          isSessionOwner={isSessionOwner}
          onSelectSegment={onSelectSegment}
          onSectionChange={handleSectionButtonClick}
          onInsightsRegeneration={onInsightsRegeneration}
        />
      </ContentContainer>
    );
  }, [
    isCompare,
    session,
    taskStatus,
    selectedSegment,
    sessionSegments,
    ownerSegmentsWithoutSessionInsights,
    segmentToResultMap,
    isCustomizingSegment,
    setIsCustomizingSegment,
    isSessionOwner,
    onSelectSegment,
    handleSectionButtonClick,
    onInsightsRegeneration,
    openChat,
    hideActionButtons,
  ]);

  const loadingStatusMessage = useMemo(() => {
    if (
      hasConfirmedPersona ||
      (selectedTab === AutomatedInsightSections.Audience && !selectedSegment?.isGenerated)
    ) {
      return 'Key findings are being generated.';
    } else return STATUS_MESSAGES?.[taskStatus] || STATUS_MESSAGES.pending;
  }, [hasConfirmedPersona, selectedTab, selectedSegment, taskStatus]);

  const loadingComponent = useMemo(() => {
    if (!isSessionValid && (!keyFindings || heatmapData?.length === 0)) {
      return (
        <RetrySession
          session={session}
          segment={selectedSegment}
          goal={sessionGoal}
          canRetry={isSessionOwner && taskProgress?.canRetry}
          displayText={taskProgress?.displayMessage}
        />
      );
    } else if (!allDependentTasksResolved) {
      return (
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingX: '5%',
          }}>
          <TaskLoadingScreen task={taskProgress} />
        </Box>
      );
    } else if (showTaskStatus) {
      return (
        <Box
          sx={{
            position: 'relative',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            paddingX: '5%',
          }}>
          <SplashLoadingIndicator
            message={loadingStatusMessage}
            isDelayed={isTaskDelayed}
            isFailed={taskStatus === TaskStatus.Failed}
          />
          <TipBox
            sx={{
              width: { xs: '90%', md: '80%' },
              height: '25%',
              minHeight: '76px',
              position: 'absolute',
              bottom: (theme) => theme.spacing(2),
            }}
            text={randomPulseTips[currentTipIndex]}
          />
          {isTaskWaiting && (
            <Box
              sx={{
                width: 'inherit',
                height: 'fit-content',
                position: 'absolute',
                bottom: 10,
                paddingY: 0,
                display: ![TaskStatus.Pending, TaskStatus.Enqueued].includes(taskStatus) ? 'initial' : 'none',
              }}
              p={2}>
              <InfoBox sx={{ height: '100%' }} isWarning={true}>
                <WaitingMessage />
              </InfoBox>
            </Box>
          )}
        </Box>
      );
    }

    return (
      <Box sx={{ height: '100%', paddingTop: 2, overflowY: 'hidden' }}>
        <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress color="secondary" />
        </Box>
      </Box>
    );
  }, [
    isSessionValid,
    keyFindings,
    heatmapData?.length,
    allDependentTasksResolved,
    showTaskStatus,
    session,
    selectedSegment,
    sessionGoal,
    isSessionOwner,
    taskProgress,
    taskStatus,
    isTaskDelayed,
    randomPulseTips,
    currentTipIndex,
    isTaskWaiting,
    loadingStatusMessage,
  ]);

  const sectionComponent = useMemo(() => {
    switch (selectedTab) {
      case null:
        return loadingComponent;
      case AutomatedInsightSections.Audience:
        if (isCompare) {
          return myAudienceComponent;
        } else if (!hasConfirmedPersona && selectedSegment?.isGenerated) {
          return audienceComponent;
        } else {
          return personaComponent;
        }
      case AutomatedInsightSections.KeyFindings:
        return keyFindingsComponent;
      case AutomatedInsightSections.SentimentMap:
        return [AutomatedInsightSessionType.Experience].includes(session?.type) ? (
          <ComingSoonPage selectedTab={selectedTab} />
        ) : (
          sentimentMapComponent
        );
      case AutomatedInsightSections.PersonaIntent:
        return [AutomatedInsightSessionType.Experience].includes(session?.type) ? (
          <ComingSoonPage selectedTab={selectedTab} />
        ) : (
          personaIntentComponent
        );
      case AutomatedInsightSections.RecommendedExperiments:
        return recommendedExperimentsComponent;
      case AutomatedInsightSections.Quant:
        return showQuant ? quantComponent : <ComingSoonPage selectedTab={selectedTab} />;
      case AutomatedInsightSections.MyAudience:
        return myAudienceComponent;
      default:
        return <></>;
    }
  }, [
    audienceComponent,
    keyFindingsComponent,
    sentimentMapComponent,
    personaComponent,
    personaIntentComponent,
    recommendedExperimentsComponent,
    quantComponent,
    loadingComponent,
    myAudienceComponent,
    hasConfirmedPersona,
    isCompare,
    selectedTab,
    selectedSegment,
    showQuant,
    session?.type,
  ]);

  return (
    <>
      <Grid container direction="column" sx={{ height: '100%', position: 'relative' }}>
        <Grid
          item
          container
          sx={{
            position: 'relative', // prevents the box shadow from being hidden by the element below it
            minHeight: '50px',
            justifyContent: 'space-between',
            paddingX: { xs: 1, sm: 2 },
            paddingBottom: { xs: 1, sm: 0 },
            paddingTop: 1.35,
            gap: { xs: 1, sm: 0 },
            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.10)',
          }}>
          <Grid item>
            <Box>{audienceSelectComponent}</Box>
          </Grid>
          <Grid item>
            <InsightSelectionButtons
              sessionId={session?.id}
              session={session}
              segmentId={selectedSegment?.id}
              sessionCreatedAt={taskProgress?.createdAt}
              sections={filteredSections}
              selectedSection={selectedTab}
              onSectionButtonClick={handleSectionButtonClick}
              onChatButtonClick={onToggleChat}
              allComplete={taskStatus === TaskStatus.Completed}
              isShareLink={isShareLink}
              openChat={openChat}
            />
          </Grid>
        </Grid>

        <Grid
          item
          sx={{
            flex: 1,
            overflow: { sx: '', md: 'hidden' },
            minHeight: { xs: '100px', md: 'auto' },
          }}>
          {sectionComponent}
        </Grid>
        {showActionButtonsBar && (
          <Grid item>
            <ActionButtonsBar
              sessionId={session?.id}
              selectedSegment={selectedSegment}
              onSharePulse={selectedTab === AutomatedInsightSections.Audience ? null : onToggleShareDialog}
              onInviteColleague={onToggleInviteColleagueDialog}
              onMyAudiencesClick={isCompare ? null : viewMyAudiences}
              onRunStudy={selectedTab === AutomatedInsightSections.Audience ? null : runStudy}
              isSessionOwner={isSessionOwner}
              onOpenChat={onToggleChat}
              hideActionButtons={hideActionButtons}
              hideChat={hideChatButton}
              showAudienceLabel={selectedTab === AutomatedInsightSections.Audience}
            />
          </Grid>
        )}
      </Grid>
      <CustomSnackbar
        message={'Pulse is taking longer than expected. Please check back later.'}
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </>
  );
};

export default Insights;
