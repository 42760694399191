import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { faqs } from './FAQContent';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography component="span">{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.any.isRequired,
  index: PropTypes.any.isRequired,
};

export default function FAQs({
  filteredQuestionsBySection,
  showBadgeNumbers,
  openQuestions,
  setOpenQuestions,
}) {
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState(faqs[0]?.id);

  const handleTabChange = (ev, newValue) => {
    setOpenQuestions({});
    setSelectedTab(newValue);
  };

  const handleOpenQuestionChange = (questionIdToToggle) => {
    setOpenQuestions((prevState) => {
      const enable = !prevState[questionIdToToggle];

      return {
        ...prevState,
        [questionIdToToggle]: enable,
      };
    });
  };

  const isQuestionOpened = (questionId) => {
    return Boolean(openQuestions[questionId]);
  };

  const isXtraSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <Grid container justifyContent="center" direction="column">
      <Grid item style={{ backgroundColor: 'white' }}>
        <Paper>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            variant={isXtraSmallScreen || isSmallScreen ? 'scrollable' : 'fullWidth'}>
            {faqs.map((section) => (
              <Tab
                key={section.id}
                label={
                  <Grid container spacing={1} alignItems="center">
                    {showBadgeNumbers && (
                      <Grid item style={{ paddingLeft: '16px', paddingRight: '16px' }}>
                        <Badge
                          showZero
                          badgeContent={filteredQuestionsBySection?.[section.id]?.length}
                          color="primary"
                        />
                      </Grid>
                    )}

                    <Grid item xs>
                      {section.title}
                    </Grid>
                  </Grid>
                }
                value={section.id}
                style={{
                  backgroundColor: 'white',
                  fontSize: isXtraSmallScreen || isSmallScreen || isMediumScreen ? '11px' : '12px',
                }}
              />
            ))}
          </Tabs>
        </Paper>
      </Grid>
      <Grid item>
        <Paper
          square
          xs={12}
          style={{
            backgroundColor: 'white',
          }}>
          {faqs.map((section) => {
            return (
              <TabPanel key={section.id} value={selectedTab} index={section.id}>
                <Grid container direction="column" spacing={2} justifyContent="center">
                  <Grid item>
                    {/* if search query is the empty string - show all questions */}
                    {
                      /* else, if there are questions within this section that match the search query - show them */
                      filteredQuestionsBySection?.[section.id]?.length > 0 ? (
                        filteredQuestionsBySection?.[section.id]?.map((question) => (
                          <Accordion key={question.id} onChange={() => handleOpenQuestionChange(question.id)}>
                            <AccordionSummary
                              expandIcon={
                                isQuestionOpened(question.id) ? (
                                  <RemoveIcon fontSize="small" />
                                ) : (
                                  <AddIcon fontSize="small" />
                                )
                              }
                              aria-controls="FAQs-content"
                              id="FAQs-header">
                              <b>{question.question}</b>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography
                                className="faq-answer"
                                style={{ maxWidth: '1000px' }}
                                component="span">
                                <ReactMarkdown remarkPlugins={[remarkGfm]} children={question.answer} />
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))
                      ) : (
                        /* if there are no questions within this section that match the search query - show message */
                        <div>Sorry! There are no questions to display.</div>
                      )
                    }
                  </Grid>
                  <Grid container direction="row" style={{ marginTop: '20px' }}>
                    <Grid item xs={12} style={{ marginLeft: '10px', fontSize: '14px' }}>
                      Still have questions? Send us an email and we will get right back to you.
                    </Grid>
                    <Grid item xs={12} style={{ paddingLeft: '10px', paddingTop: '10px' }}>
                      <Button variant="contained" size="small">
                        <a href="mailto:mastercard@wevo.ai" target="_top" style={{ textDecoration: 'none' }}>
                          <Typography
                            variant="body1"
                            style={{
                              color: 'white',
                              fontSize: '12px',
                              cursor: 'pointer',
                            }}>
                            Email WEVO
                          </Typography>
                        </a>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </TabPanel>
            );
          })}
        </Paper>
      </Grid>
    </Grid>
  );
}
