import { useQuery } from 'react-query';
import api from '../api';

const fetchWevos = async () => {
  const response = await api({
    url: `/api/v2/users/me/wevos`,
    method: 'GET',
  });
  return response?.data?.wevos;
};

/**
 *
 * @param {object} options
 * @param {boolean} [options.includeDeleted] if set to true, will included deleted wevos in the list (defaults to false)
 * @returns
 */
export default function useWevos({ includeDeleted = false } = {}) {
  return useQuery('wevos', fetchWevos, {
    notifyOnChangeProps: 'tracked',
    placeholderData: [],
    select: (wevos) => (includeDeleted ? wevos : wevos.filter((wevo) => !wevo.is_deleted)),
  });
}
