import { Box, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { useMemo } from 'react';
import wevoPulseLoadingGIF from '../../assets/wevo-pulse-loading.gif';
import { secondsToMinutes } from '../../modules/automated-insights/helpers';
import { TaskStatus } from '../../modules/automated-insights/constants';
import ProgressBar from '../automated-insights/ProgressBar';
import darkTheme from '../../darkTheme';

export default function SplashLoadingIndicator({
  message,
  timeRemainingSeconds,
  floorThresholdSeconds = 0,
  isDelayed = false,
  isFailed = false,
}) {
  const timeRemainingLabel = useMemo(() => {
    if (!timeRemainingSeconds) {
      return <></>;
    }

    if (isFailed) {
      return <></>;
    }

    return (
      <Typography variant="body1" sx={{ color: grey[600] }}>
        {timeRemainingSeconds > floorThresholdSeconds && !isFailed ? (
          <Typography component="span" color="#125454">
            {secondsToMinutes(timeRemainingSeconds)}
          </Typography>
        ) : (
          <>
            <Typography component="span">About </Typography>
            <Typography component="span" color="#125454">
              {secondsToMinutes(floorThresholdSeconds)}
            </Typography>
          </>
        )}
        <Typography component="span"> remaining</Typography>
      </Typography>
    );
  }, [floorThresholdSeconds, isFailed, timeRemainingSeconds]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img
        style={{ width: '50%', minWidth: '200px', maxWidth: '300px' }}
        src={wevoPulseLoadingGIF}
        alt="the WEVO Pulse Splash Screen"
      />
      <Box mt={-4} textAlign="center">
        <Typography variant="body1">{message}</Typography>
      </Box>
      <Box mt={1} mb={12} textAlign="center" sx={{ lineHeight: 'normal' }}>
        {isDelayed ? (
          <Typography variant="body1" sx={{ margin: '0 auto', color: grey[600] }}>
            We're currently experiencing elevated demand. Please check back later to see your results.
          </Typography>
        ) : (
          timeRemainingLabel
        )}
      </Box>
    </Box>
  );
}

export function TaskLoadingScreen({ task }) {
  const isDelayed = useMemo(() => false, []);

  const runningDependencies = useMemo(
    () => (task?.dependencyTasks ?? []).filter((depTask) => [TaskStatus.Running].includes(depTask?.status)),
    [task]
  );

  const completedDependencies = useMemo(
    () => (task?.dependencyTasks ?? []).filter((depTask) => [TaskStatus.Completed].includes(depTask?.status)),
    [task]
  );

  const message = useMemo(() => {
    const inFlightOrCompleted = completedDependencies.length + runningDependencies.length;
    const total = task?.dependencyTasks?.length || 0;
    return `Hold tight! We are generating insights for ${inFlightOrCompleted} of ${total} pages.`;
  }, [completedDependencies, runningDependencies, task]);

  const progress = useMemo(
    () =>
      ((runningDependencies.length * 0.5 + completedDependencies.length) / task.dependencyTasks.length) * 100,
    [completedDependencies, runningDependencies, task]
  );

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <img style={{ width: '50%' }} src={wevoPulseLoadingGIF} alt="the WEVO Pulse Splash Screen" />
      <Box mt={-4} textAlign="center">
        <Box>
          <ProgressBar
            maxWidth={300}
            value={progress}
            barColor={darkTheme.palette.background.default}
            isCompleted={completedDependencies.length === task?.dependencyTasks.length}
            sx={{ margin: '0 auto' }}
          />
        </Box>
        <Box mt={1} textAlign="center">
          <Typography variant="body1" sx={{ maxWidth: 550 }}>
            {message}
          </Typography>
        </Box>
      </Box>

      <Box mt={1} mb={12} textAlign="center">
        {isDelayed && (
          <Typography sx={{ maxWidth: '70%', margin: '0 auto', color: grey[600], fontSize: 15 }}>
            We're currently experiencing elevated demand. Please check back later to see your results.
          </Typography>
        )}
      </Box>
    </Box>
  );
}
