import { Box, Card, Grid, Link, Table, TableCell, TableRow, Tooltip } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import screenshotCompare from '../../../assets/faq-compare.png';
import screenshotDrivers from '../../../assets/faq-drivers.png';
import screenshotExpectations from '../../../assets/faq-expectations.png';
import screenshotSentiment from '../../../assets/faq-sentiment.png';
import screenshotUserlyticsModerated from '../../../assets/faq-userlytics-moderated.png';
import screenshotUserlyticsUnmoderated from '../../../assets/faq-userlytics-unmoderated.png';
import theme from '../../../theme';

export const useStyles = makeStyles((theme) => ({
  titleLink: {
    fontSize: '12px',
    color: 'black',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  titleNonLink: {
    fontSize: '12px',
    color: 'black',
  },
  description: {
    fontSize: '12px',
    color: grey[500],
  },
  titleCell: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  cell: {
    padding: theme.spacing(1),
  },
}));

const LearnMoreCard = (props) => {
  const { setLearnMoreCardOpen } = props;
  const classes = useStyles();

  const [usabilityStudyModeratedPhotoOpen, setUsabilityStudyModeratedPhotoOpen] = useState(false);
  const [usabilityStudyUnmoderatedPhotoOpen, setUsabilityStudyUnmoderatedPhotoOpen] = useState(false);
  const [uxScoreDiagnosticsPhotoOpen, setUxScoreDiagnosticsPhotoOpen] = useState(false);
  const [sentimentMapPhotoOpen, setSentimentMapPhotoOpen] = useState(false);
  const [expectationsGapPhotoOpen, setExpectationsGapPhotoOpen] = useState(false);
  const [comparePhotoOpen, setComparePhotoOpen] = useState(false);

  const handleUsabilityStudyModeratedClick = () => {
    setUsabilityStudyModeratedPhotoOpen(!usabilityStudyModeratedPhotoOpen);
  };
  const handleUsabilityStudyUnmoderatedClick = () => {
    setUsabilityStudyUnmoderatedPhotoOpen(!usabilityStudyUnmoderatedPhotoOpen);
  };
  const handleUXScoreDiagnosticsClick = () => {
    setUxScoreDiagnosticsPhotoOpen(!uxScoreDiagnosticsPhotoOpen);
  };
  const handleSentimentMapClick = () => {
    setSentimentMapPhotoOpen(!sentimentMapPhotoOpen);
  };
  const handleExpectationsGapClick = () => {
    setExpectationsGapPhotoOpen(!expectationsGapPhotoOpen);
  };
  const handleCompareClick = () => {
    setComparePhotoOpen(!comparePhotoOpen);
  };

  const onClick = () => {
    setLearnMoreCardOpen(false);
  };

  return (
    <Box>
      <Grid
        container
        spacing={1}
        direction="row"
        justifyContent="center"
        style={{ marginTop: '10px', marginBottom: '20px' }}>
        <Grid item xs={12} style={{ textAlign: 'right', paddingRight: '16px', cursor: 'pointer' }}>
          <CloseIcon onClick={onClick} />
        </Grid>
        <Grid item>
          <Grid container direction="column" align="center">
            <Grid item>
              <Card
                elevation={3}
                style={{
                  height: '265px',
                  width: '40px',
                  marginTop: '60px',
                  writingMode: 'vertical-lr',
                  color: theme.palette.primary.main,
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: '0px',
                  borderColor: theme.palette.primary.main,
                }}>
                <Typography style={{ marginLeft: '8px' }}>Validation Research</Typography>
              </Card>
            </Grid>
            <Grid item>
              <Card
                elevation={3}
                style={{
                  height: '172px',
                  width: '40px',
                  marginTop: '8px',
                  writingMode: 'vertical-lr',
                  color: theme.palette.primary.main,
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderRadius: '0px',
                  borderColor: theme.palette.primary.main,
                }}>
                <Typography style={{ marginLeft: '8px' }}>Formative Research</Typography>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Card elevation={3} style={{ width: '155px', borderRadius: '0px' }}>
            <Table>
              <tbody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      height: '54px',
                      color: 'white',
                      backgroundColor: theme.palette.primary.main,
                    }}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        marginTop: '-10px',
                        marginBottom: '-10px',
                      }}>
                      Tool
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '75px' }}>
                  <TableCell className={classes.titleCell}>Validation Study</TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.titleCell}>
                    <Link
                      onClick={handleUXScoreDiagnosticsClick}
                      className={classes.titleLink}
                      underline="hover">
                      UX & Content Diagnostic
                    </Link>
                    {uxScoreDiagnosticsPhotoOpen && (
                      <Lightbox
                        mainSrc={screenshotDrivers}
                        onCloseRequest={handleUXScoreDiagnosticsClick}
                        reactModalStyle={{ overlay: { zIndex: 9000 } }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '40px' }}>
                  <TableCell className={classes.titleCell}>
                    <Link
                      onClick={handleSentimentMapClick}
                      className={classes.titleLink}
                      underline="hover">
                      Sentiment Analysis
                    </Link>
                    {sentimentMapPhotoOpen && (
                      <Lightbox
                        mainSrc={screenshotSentiment}
                        onCloseRequest={handleSentimentMapClick}
                        reactModalStyle={{ overlay: { zIndex: 9000 } }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '40px' }}>
                  <TableCell className={classes.titleCell}>
                    <Link
                      onClick={handleExpectationsGapClick}
                      className={classes.titleLink}
                      underline="hover">
                      Expectation Gap
                    </Link>
                    {expectationsGapPhotoOpen && (
                      <Lightbox
                        mainSrc={screenshotExpectations}
                        onCloseRequest={handleExpectationsGapClick}
                        reactModalStyle={{ overlay: { zIndex: 9000 } }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.titleCell}>
                    <Link
                      onClick={handleCompareClick}
                      className={classes.titleLink}
                      underline="hover">
                      Compare
                    </Link>
                    {comparePhotoOpen && (
                      <Lightbox
                        mainSrc={screenshotCompare}
                        onCloseRequest={handleCompareClick}
                        reactModalStyle={{ overlay: { zIndex: 9000 } }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.titleCell}>
                    <Link
                      onClick={handleUsabilityStudyModeratedClick}
                      className={classes.titleLink}
                      underline="hover">
                      Usability Study - Moderated
                    </Link>
                    {usabilityStudyModeratedPhotoOpen && (
                      <Lightbox
                        mainSrc={screenshotUserlyticsModerated}
                        onCloseRequest={handleUsabilityStudyModeratedClick}
                        reactModalStyle={{ overlay: { zIndex: 9000 } }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.titleCell}>
                    <Link
                      onClick={handleUsabilityStudyUnmoderatedClick}
                      className={classes.titleLink}
                      underline="hover">
                      Usability Study - Unmoderated
                    </Link>
                    {usabilityStudyUnmoderatedPhotoOpen && (
                      <Lightbox
                        mainSrc={screenshotUserlyticsUnmoderated}
                        onCloseRequest={handleUsabilityStudyUnmoderatedClick}
                        reactModalStyle={{ overlay: { zIndex: 9000 } }}
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.titleCell}>
                    <Typography className={classes.titleNonLink}>Fully Customized Survey</Typography>
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Card>
        </Grid>
        <Grid item>
          <Card elevation={3} style={{ width: '110px', borderRadius: '0px' }}>
            <Table>
              <tbody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      height: '33px',
                      color: 'white',
                      backgroundColor: theme.palette.primary.main,
                    }}>
                    <Tooltip title="Time (in hours) required by the research team">
                      <Typography
                        style={{
                          fontSize: '14px',
                          marginTop: '-10px',
                          marginBottom: '-10px',
                        }}>
                        Time Required
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '275px' }}>
                  <TableCell align="center" className={classes.description}>
                    0.5h
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    60h - 100h
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    40h - 80h
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    60h - 200h
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Card>
        </Grid>
        <Grid item>
          <Card elevation={3} style={{ width: '120px', borderRadius: '0px' }}>
            <Table>
              <tbody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      height: '54px',
                      color: 'white',
                      backgroundColor: theme.palette.primary.main,
                    }}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        marginTop: '-10px',
                        marginBottom: '-10px',
                      }}>
                      Result Type
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '275px' }}>
                  <TableCell align="center" className={classes.description}>
                    Quant, Qual & Benchmark
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    Qual
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    Qual
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    Quant & Qual
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Card>
        </Grid>
        <Grid item>
          <Card elevation={3} style={{ width: '100px', borderRadius: '0px' }}>
            <Table>
              <tbody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      height: '33px',
                      color: 'white',
                      backgroundColor: theme.palette.primary.main,
                    }}>
                    <Tooltip title="The research team's expertise in user research tools ">
                      <Typography
                        style={{
                          fontSize: '14px',
                          marginTop: '-10px',
                          marginBottom: '-10px',
                        }}>
                        Expertise Required
                      </Typography>
                    </Tooltip>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '275px' }}>
                  <TableCell align="center" className={classes.description}>
                    Low
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    High
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    Medium
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell align="center" className={classes.description}>
                    High
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Card>
        </Grid>
        <Grid item>
          <Card elevation={3} style={{ width: '510px', borderRadius: '0px' }}>
            <Table>
              <tbody>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{
                      height: '54px',
                      color: 'white',
                      backgroundColor: theme.palette.primary.main,
                    }}>
                    <Typography
                      style={{
                        fontSize: '14px',
                        marginTop: '-10px',
                        marginBottom: '-10px',
                      }}>
                      Tool Description
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '75px' }}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.description}>
                      Pinpoint WHY more users are not engaging on a page, experience or concept. Leverage
                      statistically significant insights, qualitative analysis at scale and benchmarking.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.description}>
                      Understand user response to UX and content on diagnostic measure including: Clarity,
                      Appeal, Credibility.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '40px' }}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.description}>
                      Visualization of the biggest roadblocks facing users on each page / concept.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '40px' }}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.description}>
                      Gap between users expectations and experience.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.description}>
                      Compare the effectiveness of different concepts, pages or experiences (your own or
                      competitors). Uncover WHY one is better than the other.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.description}>
                      Online observation of user interaction with a concept or experience, moderated by an
                      interviewer. Output: Video of each session.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.description}>
                      Online structured interview (no moderator). Output: Video of each session.
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={{ height: '60px' }}>
                  <TableCell className={classes.cell}>
                    <Typography className={classes.description}>
                      Quantitative and qualitative response to questions, regarding needs, concepts, or feature
                      preference.
                    </Typography>
                  </TableCell>
                </TableRow>
              </tbody>
            </Table>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LearnMoreCard;
