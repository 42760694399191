import { Grid, IconButton, Paper, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffOutlinedIcon from '@mui/icons-material/LinkOffOutlined';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  ELEMENT_LINK,
  getPreventDefaultHandler,
  getSelectionText,
  isMarkActive,
  isSelectionExpanded,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_UNDERLINE,
  toggleMark,
  useEditorState,
} from '@udecode/plate-headless';
import { useSnackbar } from 'notistack';
import { getLinkUrl, isLink, removeLink, setLinkUrl } from '../../modules/plate/constants';
import PlatePopper from './PlatePopper';

const FormatPopper = ({ editorId }) => {
  const editor = useEditorState(editorId);
  const { enqueueSnackbar } = useSnackbar();
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      enqueueSnackbar('Link copied to clipboard', { variant: 'success' });
    });
  };
  const selectionExpanded = editor && isSelectionExpanded(editor);
  const selectionText = editor && getSelectionText(editor);
  return (
    <PlatePopper>
      <Paper style={{ padding: 8 }}>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          {selectionText && selectionExpanded && (
            <Grid item>
              <ToggleButtonGroup value={[]} orientation="horizontal">
                <ToggleButton
                  size="small"
                  value={MARK_BOLD}
                  selected={isMarkActive(editor, MARK_BOLD)}
                  onMouseDown={getPreventDefaultHandler(toggleMark, editor, { key: MARK_BOLD })}>
                  <FormatBoldIcon />
                </ToggleButton>
                <ToggleButton
                  size="small"
                  value={MARK_ITALIC}
                  selected={isMarkActive(editor, MARK_ITALIC)}
                  onMouseDown={getPreventDefaultHandler(toggleMark, editor, { key: MARK_ITALIC })}>
                  <FormatItalicIcon />
                </ToggleButton>
                <ToggleButton
                  size="small"
                  value={MARK_UNDERLINE}
                  selected={isMarkActive(editor, MARK_UNDERLINE)}
                  onMouseDown={getPreventDefaultHandler(toggleMark, editor, { key: MARK_UNDERLINE })}>
                  <FormatUnderlinedIcon />
                </ToggleButton>
                <Tooltip title={isLink(editor) ? 'Edit Link' : 'Insert Link'}>
                  <ToggleButton
                    size="small"
                    value={ELEMENT_LINK}
                    selected={isLink(editor)}
                    onMouseDown={getPreventDefaultHandler(
                      setLinkUrl,
                      editor,
                      editor.pluginsByKey['a']?.options?.getLinkUrl
                    )}>
                    <LinkIcon />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>
            </Grid>
          )}

          {isLink(editor) && getLinkUrl(editor) && (
            <Grid item>
              <Grid container spacing={1}>
                {(!selectionText || !selectionExpanded) && (
                  <Grid item>
                    <Tooltip title="Edit link">
                      <IconButton
                        onMouseDown={getPreventDefaultHandler(
                          setLinkUrl,
                          editor,
                          editor.pluginsByKey['a']?.options?.getLinkUrl
                        )}
                        size="large">
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <Grid item>
                  <Tooltip title={'Copy link'}>
                    <IconButton
                      onMouseDown={getPreventDefaultHandler(copyToClipboard, getLinkUrl(editor))}
                      size="large">
                      <FileCopyOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Tooltip title="Remove Link">
                    <IconButton onMouseDown={getPreventDefaultHandler(removeLink, editor)} size="large">
                      <LinkOffOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Paper>
    </PlatePopper>
  );
};

export default FormatPopper;
