import PropTypes from 'prop-types';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';

import Experience from './components/Experience';
import { Paths } from '../../../routes';

const ExperienceMapContainer = ({ wevo, handleShareButtonClick, isLimitedReport }) => {
  const pageNums = wevo?.pages.map((page) => page.pageNumber);
  const pagesPath = `${Paths.reports.experienceMapPage}(${pageNums.join('|')})`;

  return (
    <Switch>
      <Route
        exact
        path={pagesPath}
        render={({ match: { params } }) => {
          const { pageNum } = params;
          return (
            <Experience
              wevo={wevo}
              page={wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum))}
              handleShareButtonClick={handleShareButtonClick}
              isLimitedReport={isLimitedReport}
            />
          );
        }}
      />
      <Route
        exact
        path={Paths.reports.experienceMap}
        render={() => {
          return (
            <Experience
              wevo={wevo}
              page={wevo?.pages?.[0]}
              handleShareButtonClick={handleShareButtonClick}
              isLimitedReport={isLimitedReport}
            />
          );
        }}
      />
      <Redirect to={generatePath(Paths.reports.experienceMap, { wevoId: wevo?.id })} />
    </Switch>
  );
};

ExperienceMapContainer.propTypes = {
  wevo: PropTypes.object.isRequired,
  handleShareButtonClick: PropTypes.func,
  isLimitedReport: PropTypes.bool,
};

export default ExperienceMapContainer;
