import { Box, Grid, Typography } from '@mui/material';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { snackbar } from '../../notifications';
import useGenerateInsights from './hooks/useGenerateInsights';
import CustomButton from './ui/Button';
import { AutomatedInsightSessionType } from '../../modules/automated-insights/constants';

export default function RetrySession({ session, segment, goal, displayText, canRetry }) {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const { mutate: generateInsights } = useGenerateInsights();

  const sessionId = useMemo(() => session?.id, [session]);
  const segmentId = useMemo(() => (segment?.isGenerated || _.isNil(segment) ? null : segment?.id), [segment]);
  const goalId = useMemo(() => goal?.id, [goal]);
  const directive = useMemo(() => session?.directive, [session]);

  const errorMessage = useMemo(() => {
    if (displayText) {
      return displayText;
    }

    return canRetry
      ? ' Something went wrong generating insights for this page. Please try again or take another pulse.'
      : 'Something went wrong generating insights for this page.';
  }, [canRetry, displayText]);

  const triggerGenerateInsights = useCallback(
    ({ sessionId, segmentId, goalId, directive }) => {
      setIsLoading(true);
      const additionalParams = {};
      if (goalId) {
        additionalParams.goalId = goalId;
      }
      if (directive) {
        additionalParams.directive = directive;
      }

      generateInsights(
        {
          sessionId,
          segmentId,
          createDependencies: session?.type === AutomatedInsightSessionType.Compare,
          ...additionalParams,
        },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries('automatedInsightSessions');
            queryClient.invalidateQueries(['automatedInsightSession', { sessionId, segmentId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error generating insights');
            setIsLoading(false);
          },
        }
      );
    },
    [generateInsights, queryClient, session?.type]
  );

  return (
    <Grid container sx={{ height: '100%' }}>
      <Box
        sx={{
          height: 'inherit',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}>
        {canRetry ? (
          <Box
            sx={{
              textAlign: 'center',
              mx: 8,
            }}>
            <Typography fontSize={16}>{errorMessage}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', spacing: 2, my: 4 }}>
              <CustomButton
                variant="secondaryDark"
                size="small"
                onClick={() => triggerGenerateInsights({ sessionId, segmentId, goalId, directive })}
                disabled={isLoading}>
                Try Again
              </CustomButton>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              textAlign: 'center',
              mx: 16,
              mb: 8,
            }}>
            <Typography fontSize={16}>{errorMessage}</Typography>
          </Box>
        )}
      </Box>
    </Grid>
  );
}
