import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { WevoType } from '../../../../../modules/wevos/constants';
import { ClassicPageConfigurationList } from './ClassicPages';
import { JourneyPageConfigurationList, JourneyConfigHandlersProps } from './JourneyPages';

function PageConfigurationList({
  draft,
  testType,
  customQuestions,
  handleAssetDelete,
  togglePageBaseline,
  togglePageCompetitor,
  updateAssetFields,
  showBaselineCompetitor,
  ongoingUploads,
  handleFileInput,
  handleReorderAssets,
  journeyConfigHandlers,
}) {
  switch (draft?.type) {
    case WevoType.Classic:
      return (
        <ClassicPageConfigurationList
          draft={draft}
          testType={testType}
          customQuestions={customQuestions}
          handleAssetDelete={handleAssetDelete}
          togglePageBaseline={togglePageBaseline}
          togglePageCompetitor={togglePageCompetitor}
          updateAssetFields={updateAssetFields}
          showBaselineCompetitor={showBaselineCompetitor}
          ongoingUploads={ongoingUploads}
          handleFileInput={handleFileInput}
          handleReorderAssets={handleReorderAssets}
        />
      );
    case WevoType.Journey:
      return (
        <JourneyPageConfigurationList
          draft={draft}
          testType={testType}
          customQuestions={customQuestions}
          handleAssetDelete={handleAssetDelete}
          togglePageBaseline={togglePageBaseline}
          togglePageCompetitor={togglePageCompetitor}
          updateAssetFields={updateAssetFields}
          showBaselineCompetitor={showBaselineCompetitor}
          ongoingUploads={ongoingUploads}
          handleFileInput={handleFileInput}
          handleReorderAssets={handleReorderAssets}
          journeyConfigHandlers={journeyConfigHandlers}
        />
      );
    default:
      return <Box>Looks like something went wrong. Please contact us.</Box>;
  }
}

PageConfigurationList.propTypes = {
  draft: PropTypes.object.isRequired,
  testType: PropTypes.string,
  customQuestions: PropTypes.arrayOf(PropTypes.object),
  handleAssetDelete: PropTypes.func,
  togglePageBaseline: PropTypes.func,
  togglePageCompetitor: PropTypes.func,
  updateAssetFields: PropTypes.func,
  showBaselineCompetitor: PropTypes.bool,
  ongoingUploads: PropTypes.arrayOf(PropTypes.object),
  handleFileInput: PropTypes.func,
  handleReorderAssets: PropTypes.func,
  journeyConfigHandlers: JourneyConfigHandlersProps,
};

export { PageConfigurationList };
