export const faqs = [
  /* 1st Section */
  {
    id: 'pricing-payment',
    title: 'Pricing / Payment',
    questions: [
      {
        id: 'pricing-payment-cost',
        question: 'How much does a user research study cost? How many users can be in a study?',
        answer: `Prices are per study and use a credit system. Each Mastercard team/organization has the ability to purchase credits and then use them for studies. To purchase credits, please reach out to [mastercard@wevo.ai](mailto:mastercard@wevo.ai). The current credit price is $590 per credit (subject to change). 

Pricing per study depends on the audience you are looking to target with your study. If a target participant / audience is narrow to a degree that incident rates drop below 30%, WEVO may propose new pricing for the test that Mastercard may elect to accept.

|                                   |                         |
|-----------------------------------|-------------------------|
| **Bring Your Own Panel audience** | **1 credit per study**  |
| **B2C audience**                  | **2 credits per study** |
| **B2B audience**                  | **8 credits per study** |


For **Compare** and **Journey** Diagnostic studies, each page is charged separately (Example: if **comparing** one Mastercard page versus 2 competitors pages, this would be a 3 page study. For a **Journey** study, in which 2 Insight pages were requested for B2C audience, the cost would be 2x2=4 credits.)

WEVO also offers a variety of Professional Services you can add on to your studies. For any additional questions or details about these, please reach out to [mastercard@wevo.ai](mailto:mastercard@wevo.ai). Prices may vary depending on the audience and number of participants, and WEVO will confirm with you prior to launching. They include:

|                                                         |                              |
| :------------------------------------------------------ | ---------------------------- |
| **Diagnostic/Compare study key findings**               | **1 credit per study**       |
| **Moderated/Unmoderated study test planning and setup** | **2 credits per study**      |
| **Mod/Unmod/Custom Survey key findings/study summary**  | **8 credits per study**      |
| **Professional Moderator for moderated studies**        | **8 credits per study**      |
| **Translating study screeners/questions/transcripts**   | **1 credit per 4,800 words** |

Maximum Study Participants: (You may choose to include fewer participants)

|                                        |                      |
| :------------------------------------- | -------------------- |
| **Standard Validation study up to**    | **120 participants** |
| **Custom Quant study up to**           | **100 participants** |
| **Usability study up to**              | **12 participants**  |

Please note that if a target audience is narrow to a degree that incident rates drop below 30%, WEVO may propose new pricing for the study, that Mastercard may elect to accept or not run the study.`,
      },
      {
        id: 'pricing-payment-pay-as-you-go',
        question:
          'Is there a pay-as-you-go option whereby our specific business unit can purchase credits as and when we need them? If so, how do we set this up and pay, and how much does each credit cost?',
        answer: `We can add credits for your business unit at your request. Cost for such ‘ad hoc’ credits is $600 USD each. If you subscribe with WEVO there is typically a 30% reduction on the credit cost. Please contact [ryan@wevo.ai](mailto:ryan@wevo.ai) for either option.`,
      },
      {
        id: 'time-for-running-a-study',
        question: 'How long does it take to run a study?',
        answer: `Most studies will be completed in less than 6 business days. However for studies with harder to reach audiences, this may take longer. Please email [mastercard@wevo.ai](mailto:mastercard@wevo.ai) if you need fast turnaround.`,
      },
      {
        id: 'approval-for-running-a-study',
        question: 'Is there approval required to run a study?',
        answer: `For the CX&D team, any study that requires 3 credits or more requires approval from [cx&d_tools@mastercard.com](mailto:cx&d_tools@mastercard.com). This is a semi-automated process, you do not have to reach out to this email separately.`,
      },
    ],
  },

  /* 2nd Section */
  {
    id: 'support-general',
    title: 'Support / General Questions',
    questions: [
      {
        id: 'support-general-research-center',
        question: 'I have questions about the Research Center. Who can I reach out to?',
        answer: `You can reach out to [mastercard@wevo.ai](mailto:mastercard@wevo.ai) with any questions. Your dedicated Customer Success Manager is Ryan Abelman.`,
      },
      {
        id: 'support-general-results',
        question: 'Can I only see the results of the studies that I run?',
        answer: `You can view the results of every study run by anyone on the Mastercard team. Please note that the list of Formative studies is located in a different location than the list of Validation studies. You can view each of the lists by clicking on “My Tests” button at the top right corner of the first page you see after login.`,
      },
      {
        id: 'login-at-wevo-website',
        question: 'Should I always log in at the wevo.ai website?',
        answer: `Yes. WEVO is Mastercard’s central user research platform. Note, if you would like to conduct a Formative study, you will be redirected to the userlytics.com website. You will not need to sign in again. Upon completion you can click on the “Go back to WEVO” button.`,
      },
      {
        id: 'assets-that-can-be-studied',
        question: 'Can concepts, prototypes, and live pages be tested? On both mobile and desktop?',
        answer: `Yes, you can test:
* Static Concepts, Prototypes, and Live pages
* Mobile or Desktop format
* Single Pages or Multi-page Journeys`,
      },
      {
        id: 'exportable-version',
        question:
          'Is there an exportable version of these readouts that we can use with our partners and stakeholders? ',
        answer: `Yes. In Validation studies, on the results page, is a share link. You can also export the data. 
        In Formative studies, you can download videos and create a highlight reel, as well as text results for surveys.`,
      },
      {
        id: 're-contact-participants',
        question: 'Can we re-contact participants we feel give good feedback for further studies? ',
        answer: `Yes. To request to re-connect to users, please email [mastercard@wevo.ai](mailto:mastercard@wevo.ai).  
        Note, it is possible that some users may not agree to participate. Price: It is one additional credit to reach out to all participants.`,
      },
    ],
  },

  /* 3rd Section */
  {
    id: 'setting-up-study',
    title: 'Setting up a study',
    questions: [
      {
        id: 'how-to-start-a-study',
        question: 'How do I start a study?',
        answer: `Login to wevo.ai using Mastercard SSO and follow the instructions on ‘Create a Test’ page.  
        &nbsp;  
        If you cannot login, please email [cx&d_tools@mastercard.com](mailto:cx&d_tools@mastercard.com) and request that your name be added to the approved SSO list. If you are still facing challenges please contact [mastercard@wevo.ai](mailto:mastercard@wevo.ai).
        `,
      },
      {
        id: 'how-add-approved-user',
        question: 'How can I add an approved user so that they can run studies?',
        answer:
          'Please email [cx&d_tools@mastercard.com](mailto:cx&d_tools@mastercard.com) and request that their name be added to the approved SSO list. If you are still facing challenges please contact [mastercard@wevo.ai](mailto:mastercard@wevo.ai).',
      },
      {
        id: 'multiple-studies-parallel',
        question: 'Can you run multiple studies in parallel?',
        answer: `Yes.`,
      },
      {
        id: 'assets-that-can-be-studies',
        question: 'Can concepts, prototypes, and live pages be tested? On both mobile and desktop?',
        answer: `Yes, you can test:
* Static Concepts, Prototypes, and Live pages. 
* Mobile or Desktop format 
* Single Pages or Multi-page Journeys`,
      },
      {
        id: 'setting-the-audience',
        question: 'How can I set the audience for a Validation Study?',
        answer: `You’ll select the audience for a Validation Study in the study submission process.`,
      },
      {
        id: 'requirements-of-study-participants',
        question: 'What are the requirements of study participants? Is there anything you need to download?',
        answer: `While on desktop or mobile, they interact with a web based application, i.e. they don't need to download anything. Only for a mobile moderated usability study will they need to download an app in order to participate in the study.`,
      },
      {
        id: 'using-a-primer',
        question: 'Can I use a primer for Validation studies?',
        answer: `Yes. If there is a primer (e.g. an ad or email or image) you’d like the participant to see before the page or journey you’re studying WEVO can incorporate that. Simply upload it in the “Create a Test” intake process. This can be helpful if you have a display ad or a page that gives context to the pages you’re studying. You will not receive feedback on the primer, it will only be used for context. If you’d like feedback on the primer, it should be a step in a validation Journey study.`,
      },
      {
        id: 'supported-assets-file-types',
        question: 'What asset file types are supported in Validation studies?',
        answer: `Validation studies will always require still images of your pages in PNG or JPEG format and you may also include a prototype or live URL. The max size per still image file is 25mb. It must be an image file of the entire page, not a portion of it.`,
      },
      {
        id: 'need-of-an-image-file-validation-study',
        question:
          'In a Validation study, do I always need an image file even if I’m studying a live site or prototype?',
        answer: `Yes. We always will require a still image for the page for the heatmap portion of the study.`,
      },
      {
        id: 'specifically-request-a-study-summary/takeaways',
        question: 'Do I need to specifically request a study summary (key takeaways)?',
        answer: `Yes. For Validation research studies in the study creation process there is an option to request a summary of the study by a professional (also referred to as Key Takeaways in validation studies).  For Formative studies, you need to reach out to [mastercard@wevo.ai](mailto:mastercard@wevo.ai) to request this, when you set the study up. Validation key takeaway summaries cost an additional credit. Formative test summaries are 8 additional credits.`,
      },
      {
        id: 'alter-study-after-submitting',
        question: 'Can I alter a study once it has been submitted?',
        answer: `Yes, as long as it has not been launched. Once the study has been launched, you cannot alter it. Please email [mastercard@wevo.ai](mailto:mastercard@wevo.ai) if you would like to alter a study that has been launched`,
      },
    ],
  },

  /* 4th Section */
  {
    id: 'functional-technical',
    title: 'Functionality / Technical Questions',
    questions: [
      {
        id: 'live-time-stamped-note-during-study',
        question: 'Is there the ability to take live time-stamped notes during a moderated usability study?',
        answer: `This is on our roadmap, but is not possible to take live annotations at present.`,
      },
      {
        id: 'invite-stakeholders',
        question:
          'Can we invite stakeholders to observe our moderated usability sessions, and enable/disable their webcam/mic so they don’t accidentally interrupt sessions?',
        answer: `Yes, you can invite observers to the session. They (observers) are by default muted. If they want to intervene in the session, they can, but the system shows a warning explaining to them it may affect the session. The webcam is never enabled for observers.
&nbsp;

Current cap is 1 moderator and 5 observers. If you would like more observers, you can get up to 25 observers by spending one extra credit. These observers do not need their own login, however, when you invite the observers (you do that when building a study) each one is invited individually and provided his/her own link. Observers have the ability to unmute themselves and communicate with the participant. In the future we will also add the possibility for the moderator to control that functionality.`,
      },
      {
        id: 'recordings-saved',
        question:
          'Are recordings of the moderated usability studies saved within WEVO? If so, is there video editing functionality so we can collate clips into a highlight reel?',
        answer: `Yes. There is an ability to create a highlight reel. You can create annotations (selecting the beginning and the end of a video) and generate highlight reels of all the annotations (clips) that contain a specific tag.  
        &nbsp;  
        Ex: if you want to create a highlight reel of all the moments related to the login page, you just have to create annotations with the tag "login" (or any other tag you want) and create a highlight reel of that tag. The system would automatically generate a video of all the clips related to the login (or whatever tag) process.  
        &nbsp;  
        At this time you cannot adjust the order of the clips, unfortunately. But this is something on the future roadmap.
        `,
      },
      {
        id: 'global-tags-across-multiple-studies',
        question:
          'Can you create global tags that go across multiple studies? Or do you have to create tags per study.  Can we manage those tags once they are in the system?',
        answer: `Currently there is no tag management system unfortunately. The platform saves tags per user (across all studies a user has in their account), but the tags can only be interacted with on a study level in the Annotations tab. There's an easy excel download in the Annotations tab, so tag usage analysis can be done across a whole user's profile, but outside of our platform.`,
      },
      {
        id: 'maintain-a-database',
        question:
          'Is there the ability to maintain a database of research participants, document the projects they’ve participated in and add data such as their contact preferences and demographics? If so, is this list filterable and shareable?',
        answer: `There is only the ability to maintain a database of research participants if you want to run BYOP (Bring-Your-Own-Panel) studies, in which case Mastercard would maintain its own list. You will have the ability in our near-term roadmap to save and clone audiences for all types of WEVO studies.`,
      },
      {
        id: 'specific-questions-formative',
        question: 'I have specific questions around Formative Studies, where do I go?',
        answer:
          'Please click [here](https://help.userlytics.com/client/faq-clients) to be redirected to our partner’s site with a full Formative Study FAQ.',
      },
    ],
  },

  /* 5th Section */
  {
    id: 'languages-country-targeting',
    title: 'Languages / Country Targeting',
    questions: [
      {
        id: 'suported-counries-validation-studies',
        question: 'What countries are supported for Validation Studies?',
        answer: `We are able to conduct research in the following countries (with more coming soon!). If there is a country you are interested in targeting that is not on this list, please reach out and we may be able to accommodate, depending on the audience:
* AMERICAS
  * UNITED STATES
  * CANADA 
  * MEXICO  
&nbsp;  
* EUROPE
  * AUSTRIA
  * BELGIUM
  * BULGARIA
  * CZECH REPUBLIC 
  * DENMARK
  * FINLAND
  * FRANCE
  * GERMANY
  * GREECE
  * HUNGARY
  * IRELAND
  * ITALY
  * NETHERLANDS 
  * NORWAY
  * POLAND
  * PORTUGAL
  * ROMANIA
  * SLOVAKIA
  * SOUTH AFRICA 
  * SPAIN
  * SWEDEN
  * SWITZERLAND
  * UNITED KINGDOM  
&nbsp;  
* LATAM
  * ARGENTINA
  * BRAZIL
  * COLOMBIA
  * CHILE  
&nbsp;  
* APAC
  * AUSTRALIA
  * CHINA
  * HONG KONG
  * INDIA
  * INDONESIA
  * JAPAN
  * MALAYSIA
  * NEW ZEALAND
  * PHILIPPINES
  * SOUTH KOREA
  * SINGAPORE
  * TAIWAN
  * THAILAND
  * VIETNAM`,
      },
      {
        id: 'supported-lanuages-validation-studies',
        question: 'What languages are supported for Validation Studies?',
        answer: `Validation type services are conducted in English in all of the countries listed above, however moderated and unmoderated usability studies can be conducted in local languages.`,
      },
      {
        id: 'supported-lanuages-and-countries-formative-studies',
        question: 'Which languages and countries are supported for Formative studies?',
        answer: `You can write your formative study instructions and questions in any language or alphabet.In addition, for formative studies you can set the language of the recorder UI and participant landing pages into the following languages:
* English
* Spanish (Spain) 
* Spanish (LatAm) 
* French 
* German 
* Italian 
* Dutch 
* Norwegian 
* Chinese (Traditional) 
* Chinese (Simplified) 
* Japanese 
* Korean 
* Estonian 
* Portuguese (Brazil) 
* Swedish 
* Polish  

In addition, AI based automatic transcription of video sessions are available in the following languages:  
* Gulf Arabic
* Modern Standard Arabic 
* Mandarin Chinese – Mainland
* Dutch
* Australian English
* British English
* Indian English
* Irish English
* Scottish English
* US English
* Welsh English
* Spanish
* US Spanish
* French
* Canadian French
* Farsi Persian
* German
* Swiss German
* Hebrew
* Indian Hindi
* Indonesian
* Italian
* Japanese
* Korean
* Malay
* Portuguese
* Brazilian Portuguese
* Russian
* Tamil
* Telugu
* Turkish`,
      },
    ],
  },
];
