import { Tooltip } from '@mui/material';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FormatStrikethroughIcon from '@mui/icons-material/FormatStrikethrough';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import LinkIcon from '@mui/icons-material/Link';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import Looks6Icon from '@mui/icons-material/Looks6';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import {
  ELEMENT_BLOCKQUOTE,
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
  ELEMENT_LINK,
  ELEMENT_OL,
  ELEMENT_UL,
  getPreventDefaultHandler,
  isMarkActive,
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
  toggleList,
  toggleMark,
  usePlateEditorState,
} from '@udecode/plate-headless';
import { isBlockActive, isLink, isListActive, setLinkUrl, toggleBlock } from '../../modules/plate/constants';

const FormatRow = ({ editorId }) => {
  const editor = usePlateEditorState(editorId);
  return (
    <>
      <ToggleButtonGroup value={[]} orientation="horizontal">
        <ToggleButton
          size="small"
          value={MARK_BOLD}
          selected={isMarkActive(editor, MARK_BOLD)}
          onMouseDown={getPreventDefaultHandler(toggleMark, editor, { key: MARK_BOLD })}>
          <FormatBoldIcon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={MARK_ITALIC}
          selected={isMarkActive(editor, MARK_ITALIC)}
          onMouseDown={getPreventDefaultHandler(toggleMark, editor, { key: MARK_ITALIC })}>
          <FormatItalicIcon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={MARK_UNDERLINE}
          selected={isMarkActive(editor, MARK_UNDERLINE)}
          onMouseDown={getPreventDefaultHandler(toggleMark, editor, { key: MARK_UNDERLINE })}>
          <FormatUnderlinedIcon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={MARK_STRIKETHROUGH}
          selected={isMarkActive(editor, MARK_STRIKETHROUGH)}
          onMouseDown={getPreventDefaultHandler(toggleMark, editor, { key: MARK_STRIKETHROUGH })}>
          <FormatStrikethroughIcon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_H1}
          selected={isBlockActive(editor, ELEMENT_H1)}
          onMouseDown={getPreventDefaultHandler(toggleBlock, editor, { activeType: ELEMENT_H1 })}>
          <LooksOneIcon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_H2}
          selected={isBlockActive(editor, ELEMENT_H2)}
          onMouseDown={getPreventDefaultHandler(toggleBlock, editor, { activeType: ELEMENT_H2 })}>
          <LooksTwoIcon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_H3}
          selected={isBlockActive(editor, ELEMENT_H3)}
          onMouseDown={getPreventDefaultHandler(toggleBlock, editor, { activeType: ELEMENT_H3 })}>
          <Looks3Icon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_H4}
          selected={isBlockActive(editor, ELEMENT_H4)}
          onMouseDown={getPreventDefaultHandler(toggleBlock, editor, { activeType: ELEMENT_H4 })}>
          <Looks4Icon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_H5}
          selected={isBlockActive(editor, ELEMENT_H5)}
          onMouseDown={getPreventDefaultHandler(toggleBlock, editor, { activeType: ELEMENT_H5 })}>
          <Looks5Icon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_H6}
          selected={isBlockActive(editor, ELEMENT_H6)}
          onMouseDown={getPreventDefaultHandler(toggleBlock, editor, { activeType: ELEMENT_H6 })}>
          <Looks6Icon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_BLOCKQUOTE}
          selected={isBlockActive(editor, ELEMENT_BLOCKQUOTE)}
          onMouseDown={getPreventDefaultHandler(toggleBlock, editor, { activeType: ELEMENT_BLOCKQUOTE })}>
          <FormatQuoteIcon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_OL}
          selected={isListActive(editor, ELEMENT_OL)}
          onMouseDown={getPreventDefaultHandler(toggleList, editor, { type: ELEMENT_OL })}>
          <FormatListNumberedIcon />
        </ToggleButton>
        <ToggleButton
          size="small"
          value={ELEMENT_UL}
          selected={isListActive(editor, ELEMENT_UL)}
          onMouseDown={getPreventDefaultHandler(toggleList, editor, { type: ELEMENT_UL })}>
          <FormatListBulletedIcon />
        </ToggleButton>
        <Tooltip title={isLink(editor) ? 'Edit Link' : 'Insert Link'}>
          <ToggleButton
            size="small"
            value={ELEMENT_LINK}
            selected={isLink(editor)}
            onMouseDown={getPreventDefaultHandler(
              setLinkUrl,
              editor,
              editor.pluginsByKey['a']?.options?.getLinkUrl
            )}>
            <LinkIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    </>
  );
};

export default FormatRow;
