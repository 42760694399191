import { Card, CardContent, Grid } from '@mui/material';
import { green, lightGreen, red } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo } from 'react';
import { getJourneyScoreText, getScoreTextColor } from '../../../modules/report/constants';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import DoughnutScoreGraph from '../components/DoughnutScoreGraph';
import DiagnosticsSummary from './components/DiagnosticsSummary';
import PageSummary from './components/PageSummary';
import TakeawaySummary from './components/TakeawaySummary';

const useJourneyDashboardStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    padding: theme.spacing(4),
    borderRadius: '20px',
  },
  cardContent: {
    [theme.breakpoints.up('md')]: {
      padding: '16px 64px',
    },
  },
  title: {
    textTransform: 'capitalize',
  },
}));

const JourneyDashboard = (props) => {
  const classes = useJourneyDashboardStyles();
  const { wevo, page } = props;
  const journeyScore = useMemo(() => {
    if (wevo?.useVersionedDiagnostics) {
      return (page && Math.round(page.score)) || 0;
    }

    return (page && Math.floor(page.score * 100)) || 0;
  }, [page, wevo]);

  const useNewDiagnostics = useMemo(
    () => (wevo && (wevo?.useNewDiagnostics || wevo?.useVersionedDiagnostics)) || false,
    [wevo]
  );

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_DASHBOARD,
    properties: { wevoId: wevo?.analyticsId, pageId: page?.id },
  });

  const getNewDiagnosticsColor = (journeyScore) => {
    // TODO: remove this hack, only for the  New Diagnostics Demo, only
    if (journeyScore > 85) {
      return green[500];
    } else if (journeyScore > 75) {
      return lightGreen[500];
    } else {
      return red[500];
    }
  };

  const getNewDiagnosticsText = (journeyScore) => {
    // TODO: remove this hack, only for the  New Diagnostics Demo, only
    if (journeyScore > 85) {
      return 'Highly Effective';
    } else if (journeyScore > 75) {
      return 'Effective';
    } else if (journeyScore > 60) {
      return 'Somewhat Effective';
    } else {
      return 'Less Effective';
    }
  };

  return (
    <Fragment>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Typography className={classes.title} variant="h3" align="center" gutterBottom>
            {wevo.name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="caption" component="p" align="center">
            WEVO compared your journey to a collection of similar companies' funnels to see how your content
            stacks up to the industry standard
          </Typography>
        </Grid>
      </Grid>
      <Card className={classes.card} elevation={4}>
        <CardContent className={classes.cardContent}>
          <Grid container justifyContent="center">
            <Grid item md={6} xs={12}>
              <Grid container justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={3}>
                  <DoughnutScoreGraph
                    score={journeyScore}
                    backgroundColor={
                      useNewDiagnostics
                        ? getNewDiagnosticsColor(journeyScore)
                        : getScoreTextColor(journeyScore)
                    }
                  />
                </Grid>
                <Grid container item xs={12} sm spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">This Journey is</Typography>
                    <Typography
                      variant="h3"
                      style={{
                        color: useNewDiagnostics
                          ? getNewDiagnosticsColor(journeyScore)
                          : getScoreTextColor(journeyScore),
                      }}>
                      {useNewDiagnostics
                        ? getNewDiagnosticsText(journeyScore)
                        : getJourneyScoreText(journeyScore)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption" component="p">
                      Your journey score reflects both page scores and journey diagnostics
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <DiagnosticsSummary wevo={wevo} page={page} />
      <PageSummary wevo={wevo} page={page} />
      <TakeawaySummary wevo={wevo} page={page} />
    </Fragment>
  );
};

JourneyDashboard.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
};

export default JourneyDashboard;
