import DeleteIcon from '@mui/icons-material/Delete';
import DoneIcon from '@mui/icons-material/Done';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import { Box, Card, CardContent, Chip, Grid, List, ListItem, Tooltip, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { isEmpty as _isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ImagePreview from '../../../../../ui/ImagePreview';
import DiscardPageDialog from '../../DiscardPageDialog';
import LinearDeterminateProgressBar from '../../LinearDeterminateProgressBar';
import DropzoneCard from './DropzoneCard';

export const useAssetPreviewStyles = makeStyles((theme) => ({
  progressContainer: {
    height: 200,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    border: '1px solid rgb(151, 151, 151)',
  },
  imageContainer: {
    // minHeight: 200,
    textAlign: 'center',
  },
  formContainer: {
    alignSelf: 'center',
  },
  deleteBtn: {
    float: 'right',
  },

  // Baseline/competitor chip
  selectedBaselineChip: {
    color: 'rgb(76, 175, 80)',
    backgroundColor: 'rgb(76, 175, 80, 0.2)',
    borderColor: 'rgb(76, 175, 80)',
  },
  unselectedBaselineChip: {
    color: 'rgb(76, 175, 80)',
    backgroundColor: 'white',
    borderColor: 'rgb(76, 175, 80)',
  },
  selectedBaselineIcon: {
    color: 'rgb(76, 175, 80)',
  },
  unselectedBaselineIcon: {
    color: 'rgb(76, 175, 80)',
  },
  selectedCompetitorIcon: {
    color: 'rgb(255, 82, 82)',
  },
  unselectedCompetitorIcon: {
    color: 'rgb(255, 82, 82)',
  },
  reorderIcon: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    color: 'grey',
  },
}));

const AssetPreview = ({
  asset,
  handleAssetDelete,
  handleUpdateFields,
  showBaselineCompetitor,
  toggleBaseline,
  isLoading,
  isDraggable = false,
  shouldWarnOnDelete = false,
}) => {
  const classes = useAssetPreviewStyles();
  const [assetName, setAssetName] = useState(asset.name || '');
  const [assetUrl, setAssetUrl] = useState(asset.url || '');

  const [showDiscardPageDialog, setShowDiscardPageDialog] = useState(false);

  const handleDelete = (ev) => {
    ev.preventDefault();
    if (shouldWarnOnDelete) {
      setShowDiscardPageDialog(true);
    } else {
      handleAssetDelete(asset.id);
    }
  };

  const handleBaselineClick = (ev) => {
    ev.preventDefault();
    toggleBaseline(asset.id);
  };

  const handleNameInput = (ev) => {
    ev.preventDefault();
    setAssetName(ev.target.value);
  };

  const handleNameUpdate = (ev) => {
    const trimmedName = assetName.trim();
    if (trimmedName !== asset.name.trim()) {
      handleUpdateFields(asset.id, { name: trimmedName });
    }
  };

  const handleUrlInput = (ev) => {
    ev.preventDefault();
    setAssetUrl(ev.target.value);
  };

  const handleUrlUpdate = (ev) => {
    const trimmedUrl = assetUrl.trim();
    if (!asset.url || trimmedUrl !== asset.url.trim()) {
      handleUpdateFields(asset.id, { url: trimmedUrl });
    }
  };

  const toggleShowDiscardPageDialog = () => {
    setShowDiscardPageDialog(!showDiscardPageDialog);
  };

  const uploadComplete = asset.progress >= 100;

  // Generates the chip component for toggling whether this page is a baseline.
  let baselineChip;
  if (showBaselineCompetitor) {
    if (asset.isBaseline) {
      baselineChip = (
        <Chip
          className={classes.selectedBaselineChip}
          icon={<DoneIcon className={classes.selectedBaselineIcon} />}
          label="BASELINE"
          variant="outlined"
          onDelete={handleBaselineClick}
        />
      );
    } else {
      baselineChip = (
        <Tooltip title="Use this image as a starting point for comparisons" aria-label="add">
          <Chip
            className={classes.unselectedBaselineChip}
            icon={<EmojiFlagsIcon className={classes.unselectedBaselineIcon} />}
            label="BASELINE"
            clickable
            variant="outlined"
            onClick={handleBaselineClick}
          />
        </Tooltip>
      );
    }
  }

  return (
    <Card>
      {uploadComplete && isDraggable ? (
        <DragIndicatorIcon fontSize="small" className={classes.reorderIcon} />
      ) : (
        <></>
      )}
      {uploadComplete && (
        <IconButton className={classes.deleteBtn} onClick={handleDelete} size="large">
          <DeleteIcon />
        </IconButton>
      )}
      <CardContent>
        <Grid container justifyContent="space-around" spacing={2}>
          {/* Asset section */}
          {isLoading ? (
            <Grid item xs={5} className={classes.progressContainer}>
              <Typography variant="caption" noWrap={true} gutterBottom>
                Uploading {asset.name}
              </Typography>
              <LinearDeterminateProgressBar />
            </Grid>
          ) : (
            <Grid item xs={5} className={classes.imageContainer}>
              <ImagePreview image={asset.images} maxHeight={250} maxWidth={350} />
            </Grid>
          )}

          {/* Form section */}
          <Grid item className={classes.formContainer} xs={7}>
            <TextField
              id={`pageName-${asset.id || asset.uploadId}-name`}
              type="text"
              variant="outlined"
              size="medium"
              value={assetName}
              fullWidth
              onChange={handleNameInput}
              onBlur={handleNameUpdate}
              label="Page Name"
              required
            />
            <Box py={1} />
            <TextField
              id={`pageName-${asset.id || asset.uploadId}-url`}
              type="text"
              variant="outlined"
              size="medium"
              value={assetUrl}
              fullWidth
              onChange={handleUrlInput}
              onBlur={handleUrlUpdate}
              label="Add URL (Optional)"
            />
            <Box py={1} />
            {showBaselineCompetitor && (
              <Grid container spacing={2}>
                <Grid item>{baselineChip}</Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <DiscardPageDialog
        open={showDiscardPageDialog}
        discardPage={() => handleAssetDelete(asset.id)}
        closeCallback={toggleShowDiscardPageDialog}
      />
    </Card>
  );
};

AssetPreview.propTypes = {
  asset: PropTypes.object.isRequired,
  handleAssetDelete: PropTypes.func,
  handleUpdateFields: PropTypes.func,
  showBaselineCompetitor: PropTypes.bool,
  toggleBaseline: PropTypes.func,
  isLoading: PropTypes.bool,
  isDraggable: PropTypes.bool,
  shouldWarnOnDelete: PropTypes.bool,
};

function AssetUploadZone({
  ongoingUploads,
  showBaselineCompetitor,
  numAssets,
  maxNumAssets,
  handleFileInput,
  showDropzone,
}) {
  return (
    <>
      {ongoingUploads?.map((mutation) => (
        <Grid item xs={12} key={mutation.mutationId}>
          <AssetPreview
            asset={{
              id: mutation.options.variables.uploadId,
              name: mutation.options.variables.name,
            }}
            showBaselineCompetitor={showBaselineCompetitor}
            isLoading={true}
          />
        </Grid>
      ))}
      {showDropzone && (
        <DropzoneCard numAssets={numAssets} maxNumAssets={maxNumAssets} handleFileInput={handleFileInput} />
      )}
    </>
  );
}

AssetUploadZone.propTypes = {
  ongoingUploads: PropTypes.arrayOf(PropTypes.object),
  showBaselineCompetitor: PropTypes.bool,
  numAssets: PropTypes.number,
  maxNumAssets: PropTypes.number,
  showDropzone: PropTypes.bool,
  handleFileInput: PropTypes.func,
};

function AssetList({
  children,
  handleFileInput,
  handleReorderAssets,
  maxNumAssets,
  numAssets,
  ongoingUploads,
  showBaselineCompetitor,
  showDropzone,
}) {
  return (
    <>
      {children && (
        <Grid item xs={12} aria-labelledby="identify-pages-section-heading">
          <DragDropContext onDragEnd={handleReorderAssets}>
            {/* note: droppableId must be a string */}
            <Droppable droppableId="ItemsList">
              {(provided) => (
                <List className="ItemsList" {...provided.droppableProps} ref={provided.innerRef}>
                  {!_isEmpty(children) &&
                    children?.map((child, index) => (
                      <Draggable
                        // note: draggableId must be a string
                        draggableId={String(child.props.id)}
                        key={child.key}
                        //key={asset?.id || asset?.uploadId}
                        index={index}>
                        {(provided) => (
                          <ListItem ref={provided.innerRef} {...provided.draggableProps}>
                            <Grid item xs={12} {...provided.dragHandleProps}>
                              {child}
                            </Grid>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </List>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      )}
      <AssetUploadZone
        ongoingUploads={ongoingUploads}
        showDropzone={showDropzone}
        numAssets={numAssets}
        maxNumAssets={maxNumAssets}
        showBaselineCompetitor={showBaselineCompetitor}
        handleFileInput={handleFileInput}
      />
    </>
  );
}

AssetList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  handleFileInput: PropTypes.func,
  handleReorderAssets: PropTypes.func,
  maxNumAssets: PropTypes.number.isRequired,
  numAssets: PropTypes.number.isRequired,
  ongoingUploads: PropTypes.arrayOf(PropTypes.object),
  showBaselineCompetitor: PropTypes.bool,
  showDropzone: PropTypes.bool,
};

export { AssetList, AssetPreview, AssetUploadZone };
