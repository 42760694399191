import { useMutation, useQuery } from 'react-query';
import axios from '../api';

const fetchFigmaPrototype = async ({ wevoId }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/prototypes/figma`,
    method: 'GET',
  });
  return data?.figmaPrototype;
};

export default function useFetchFigmaPrototype() {
  return useMutation(fetchFigmaPrototype);
}

const fetchFigmaPrototypeByPage = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/prototypes/figma`,
    method: 'GET',
  });
  return data?.figmaPrototype;
};

export function useFetchFigmaPrototypeByPage({ wevoId, pageId }, options = {}) {
  return useQuery(
    [`figmaPrototypeByPage/wevo/${wevoId}/page/${pageId}`, { wevoId, pageId }],
    fetchFigmaPrototypeByPage,
    options
  );
}
