import { Box, IconButton } from '@mui/material';
import React from 'react';
import sparklesGIF from '../../assets/sparkles.gif';

const styles = {
  iconButtonWrapper: {
    height: 60,
    width: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  },
  iconButton: {
    position: 'relative',
    background: (theme) => theme.palette.gradient.main,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -1,
      left: -1,
      width: 44,
      height: 44,
      borderRadius: '50%',
      background: 'linear-gradient(45deg, #ff0000, #fffb00, #48ff00, #00ffd5, #002bff, #ff00c8)',
      backgroundSize: '400%',
      filter: 'blur(3px)',
      animation: 'glow 20s linear infinite',
      transform: 'scale(1)',
      zIndex: -1,
      visibility: 'hidden',
    },
    '&:hover': {
      animation: 'none',
      '&:before': {
        visibility: 'initial',
      },
    },
    '@keyframes glow': {
      '0%': {
        backgroundPosition: '0 0',
      },
      '50%': {
        backgroundPosition: '400% 0',
      },
      '100%': {
        backgroundPosition: '0 0',
      },
    },
  },
};

const ChatButton = ({ onClick, disabled }) => {
  return (
    <Box sx={styles.iconButtonWrapper}>
      <IconButton aria-label="open chat" onClick={onClick} disabled={disabled} sx={{ ...styles.iconButton }}>
        <img
          style={{ width: 26, height: 26, transform: 'scale(1.6)' }}
          src={sparklesGIF}
          alt="chat sparkles"
        />
      </IconButton>
    </Box>
  );
};

export default ChatButton;
