import produce from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomQuestionMutationKeys } from '../../../modules/intake/constants';

const deleteQuestions = async ({ id: wevoId, groupId }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/custom-questions/groups/${groupId}`,
    method: 'DELETE',
  });
};

/**
 * Custom hook for deleting a customQuestions.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the updated data
 *
 */
export default function useDeleteQuestions() {
  const queryClient = useQueryClient();

  return useMutation(deleteQuestions, {
    mutationKey: CustomQuestionMutationKeys.deleteQuestion,
    onMutate: async ({ id: wevoId, groupId, index }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['customQuestionsData', { wevoId }]);

      // Snapshot the previous customQuestionsData value
      const previousCustomQuestionsState = await queryClient.getQueryData(['customQuestionsData', { wevoId }]);

      const updatedCustomQuestionState = produce(previousCustomQuestionsState, (draftState) => {
        // Remove the question
        if (index >= 0) {
          draftState.splice(index, 1);
        }
      });
      queryClient.setQueryData(['customQuestionsData', { wevoId }], updatedCustomQuestionState);

      // Return a context object with the snapshotted value
      return { previousCustomQuestionsState };
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { id: wevoId, groupId }, { previousCustomQuestionsState }) => {
      queryClient.setQueryData(['customQuestionsData', { wevoId }], previousCustomQuestionsState);
    },
  });
}
