import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchBenchmarkScopes = async ({ queryKey: [key, { wevoId, pageId, scope }] }) => {
  if (!wevoId || !pageId) {
    return;
  }

  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/benchmarks/scopes`,
    method: 'GET',
  });
  return data?.benchmarkScopes ?? [];
};

export default function useBenchmarkScopes(wevoId, pageId) {
  return useQuery(['benchmarkScopesData', { wevoId, pageId }], fetchBenchmarkScopes, {});
}
