import { useMutation, useQueryClient } from 'react-query';
import { starQuote } from '../api';

export default function useStarQuote() {
  const queryClient = useQueryClient();
  return useMutation(starQuote, {
    onSettled: () => {
      queryClient.invalidateQueries('starredQuotes');
    },
  });
}
