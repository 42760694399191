import { Link as MuiLink, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { generatePath, Link } from 'react-router-dom';
import { MetricTypes } from '../../../../modules/intake/constants';
import { QuoteType, TransitionType, WevoType } from '../../../../modules/wevos/constants';
import { isClassicComparePages } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const StarredQuoteContext = ({ wevo, pageNum, pageLabel, quote }) => {
  const classes = useStyles();

  const isDQS = wevo?.metricType === MetricTypes.MastercardDqs;
  const isCDS = wevo?.metricType === MetricTypes.MastercardCds;
  const isComparePagesClassicQuote = isClassicComparePages(wevo);
  const wevoId = wevo.id;

  let caption;

  switch (quote.quoteType) {
    case QuoteType.CustomQuestion:
      return (
        <Link
          to={`${generatePath(Paths.reports.questionDetails, {
            wevoId,
            groupNum: quote.stepNumber,
          })}?questionId=${quote.questionId}`}
          className={classes.link}>
          <MuiLink component="span" underline="hover">
            <Typography variant="caption">Custom Question #{quote.stepNumber}</Typography>
          </MuiLink>
        </Link>
      );
    case QuoteType.Diagnostic:
      let diagnosticsPath;
      if (isCDS) {
        diagnosticsPath = Paths.reports.conceptScore;
      } else if (isDQS) {
        diagnosticsPath = Paths.reports.experienceScore;
      } else {
        diagnosticsPath = Paths.reports.diagnostics;
      }

      return (
        <Link to={generatePath(diagnosticsPath, { wevoId })} className={classes.link}>
          <MuiLink component="span" underline="hover">
            <Typography variant="caption">Diagnostic: {quote.diagnostic}</Typography>
          </MuiLink>
        </Link>
      );
    case QuoteType.Driver:
      caption = `Diagnostic: ${quote.diagnostic}`;

      if (isComparePagesClassicQuote) {
        caption = `Page ${pageLabel} ${caption}`;
      }

      return (
        <Link to={generatePath(Paths.reports.diagnosticsPage, { wevoId, pageNum })} className={classes.link}>
          <MuiLink component="span" underline="hover">
            <Typography variant="caption">{caption}</Typography>
          </MuiLink>
        </Link>
      );
    case QuoteType.Expectation:
      caption = `Expectation: ${quote.theme}`;

      // only display expectation page steps on classic wevos
      if (isComparePagesClassicQuote) {
        caption = `Page ${pageLabel} ${caption}`;
      }

      return (
        <Link
          to={
            isComparePagesClassicQuote
              ? generatePath(Paths.reports.expectationsPage, { wevoId, pageNum })
              : generatePath(Paths.reports.expectations, { wevoId })
          }
          className={classes.link}>
          <MuiLink component="span" underline="hover">
            <Typography variant="caption">{caption}</Typography>
          </MuiLink>
        </Link>
      );
    case QuoteType.EmotionWord:
      return (
        <Link
          to={generatePath(Paths.reports.emotionWordsPageStep, {
            wevoId,
            pageNum,
            stepNum: quote.stepNumber,
          })}
          className={classes.link}>
          <MuiLink component="span" underline="hover">
            <Typography variant="caption">Page {quote.stepNumber}: Attribute</Typography>
          </MuiLink>
        </Link>
      );
    case QuoteType.Heatmap:
      let linkPath = '';
      let linkText = '';
      if (wevo?.type === WevoType.Classic) {
        linkPath = generatePath(Paths.reports.sentimentMapPage, { wevoId, pageNum });
        linkText = isComparePagesClassicQuote ? `Page ${pageLabel}: Heatmap` : 'Heatmap';
      } else {
        linkPath = generatePath(Paths.reports.sentimentMapPageStep, {
          wevoId,
          pageNum,
          stepNum: quote.stepNumber,
        });
        linkText = `Page ${quote.stepNumber}: Heatmap`;
      }
      return (
        <Link to={linkPath} className={classes.link}>
          <MuiLink component="span" underline="hover">
            <Typography variant="caption">{linkText}</Typography>
          </MuiLink>
        </Link>
      );
    case QuoteType.Transition:
      const text =
        quote.type === TransitionType.Stay
          ? `Page ${quote.stepNumber} Transition: What would make people decide to stay`
          : `Page ${quote.stepNumber} Transition: Why people decided to leave`;

      return (
        <Link
          to={generatePath(Paths.reports.stayGoPageStep, { wevoId, pageNum, stepNum: quote.stepNumber })}
          className={classes.link}>
          <MuiLink component="span" underline="hover">
            <Typography variant="caption">{text}</Typography>
          </MuiLink>
        </Link>
      );
    default:
      return null;
  }
};

StarredQuoteContext.propTypes = {
  wevo: PropTypes.object.isRequired,
  pageNum: PropTypes.number.isRequired,
  pageLabel: PropTypes.string.isRequired,
  quote: PropTypes.object.isRequired,
};

export default StarredQuoteContext;
