import { Box, CardHeader, Grid, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ImagePreview from '../../../ui/ImagePreview';
import { WevoType, WevoTestType } from '../../../modules/wevos/constants';
import { ExperienceGoalSelection } from './ExperienceScreenSelection';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '15px',
  },
  overflowText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  textGrid: {
    width: '100%',
    maxWidth: '200px',
  },
  action: {
    textAlign: 'center',
  },
  context: {
    marginTop: theme.spacing(1),
    fontSize: '12px',
    overflowWrap: 'anywhere',
  },
}));

function AssetDetails({ asset, isBaseline, isCompetitor, onClick }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={asset?.name || asset?.url || isBaseline || isCompetitor ? 5 : 12}>
        <ImagePreview image={asset?.images} maxHeight={80} maxWidth={120} onClick={onClick} />
      </Grid>
      <Grid item xs={7} alignSelf="center">
        <Grid container item direction="column" spacing={1} justifyContent="center">
          {asset?.name && (
            <Grid item className={classes.textGrid}>
              <Tooltip title={asset?.name}>
                <Typography variant="body2" className={classes.overflowText}>
                  {asset?.name}
                </Typography>
              </Tooltip>
            </Grid>
          )}
          {asset?.url && (
            <Grid item className={classes.textGrid}>
              <Tooltip title={asset?.url}>
                <Typography variant="body2" className={classes.overflowText}>
                  {asset?.url}
                </Typography>
              </Tooltip>
            </Grid>
          )}
          {isBaseline && (
            <Grid item>
              <Typography variant="body2">Baseline</Typography>
            </Grid>
          )}
          {isCompetitor && (
            <Grid item>
              <Typography variant="body2">Challenger</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

function PageCard({ title, children, onClick, onDelete, isStyled }) {
  const classes = useStyles();

  const handleOnDeleteClick = (event) => {
    event.stopPropagation();
    onDelete();
  };

  const card = (
    <Card className={isStyled && classes.card} elevation={isStyled && 4}>
      {(title || _.isFunction(onDelete)) && (
        <CardHeader
          title={title}
          action={
            _.isFunction(onDelete) && (
              <IconButton aria-label="delete-page" onClick={handleOnDeleteClick} size="large">
                <DeleteIcon />
              </IconButton>
            )
          }
        />
      )}
      <CardContent>{children} </CardContent>
    </Card>
  );

  return onClick ? (
    <CardActionArea onClick={onClick} disabled={!onClick}>
      {card}
    </CardActionArea>
  ) : (
    card
  );
}

export function PrimerReviewCard({ draft, onClick, isStyled }) {
  const classes = useStyles();
  const primer = draft?.pages?.[0]?.primer;
  const primerContext = draft?.pages?.[0]?.primerContext;

  return (
    <PageCard isStyled={isStyled} title="Primer" onClick={onClick}>
      {!_.isNil(primer) && <AssetDetails asset={primer} onClick={(ev) => ev.preventDefault()} />}
      <Box mb={2} />
      {primerContext && (
        <Grid>
          <Typography variant="h5">Primer Context</Typography>
          <Typography color="textSecondary" className={classes.context}>
            {primerContext}
          </Typography>
        </Grid>
      )}
    </PageCard>
  );
}

/**
 * Shows card with a page and its info.
 *
 * Props:
 *  page: Page object that includes name, image, baseline/competitor.
 *  onClick?: Handler when the image is clicked. If not set, the card will not be clickable.
 *  onDelete?: Handler when the card is deleted. If not set, the delete button will not be shown.
 */
export default function PageReviewCard({ draft, page, experience, onClick, onDelete, isStyled }) {
  const handleShowImageClick = (ev) => {
    ev.stopPropagation();
  };

  const assets = draft?.type === WevoType.Journey ? page?.steps ?? [] : [page];

  const getPageCardTitle = (page) => {
    const label = page.label;

    if (draft.type === WevoType.Classic && draft.testType === WevoTestType.Compare) {
      return `Compare Page - ${label}`;
    } else if (draft.type === WevoType.Classic && draft.testType === WevoTestType.Page) {
      return 'Insight Page';
    } else if (draft.type === WevoType.Journey && draft.testType === WevoTestType.Compare) {
      return `Journey - ${label}`;
    } else if (draft.type === WevoType.Journey && draft.testType === WevoTestType.Page) {
      return 'Journey';
    } else {
      return '';
    }
  };

  const getPageCardSubtitle = (step) => {
    const label = step.label;
    return `Insight Page - ${label}`;
  };

  const title = getPageCardTitle(page);

  return (
    <PageCard
      isStyled={isStyled}
      title={title}
      onDelete={onDelete ? () => onDelete(page.id) : null}
      onClick={onClick}>
      <Fragment>
        {page?.journeyStartUrl && (
          <Box mb={2}>
            <Typography sx={{ fontWeight: 600, fontSize: '0.85rem', mb: 1 }} gutterBottom>
              Journey Start
            </Typography>
            <Typography style={{ fontSize: '12px' }} variant="caption" color="textSecondary">
              {page.journeyStartUrl}
            </Typography>
          </Box>
        )}
        {assets.map((asset) => (
          <Box key={asset.id} mb={assets.length > 1 ? 2 : 0}>
            {assets.length > 1 && (
              <Typography sx={{ fontWeight: 600, fontSize: '0.85rem', mb: 1 }}>
                {getPageCardSubtitle(asset)}
              </Typography>
            )}
            <AssetDetails
              asset={asset}
              isBaseline={page?.isBaseline}
              isCompetitor={page?.isCompetitor}
              onClick={handleShowImageClick}
            />
          </Box>
        ))}
        {experience && (
          <>
            <Box mb={2} />
            <Grid item sx={{ width: '100%' }}>
              <Typography sx={{ fontWeight: 600, fontSize: '0.85rem', mb: 1 }}>
                Experience - Goal Page
              </Typography>
              <ExperienceGoalSelection
                wevo={draft}
                experience={experience}
                orientation={'vertical'}
                isEditable={false}
                maxImageHeight={250}
              />
            </Grid>
          </>
        )}
      </Fragment>
    </PageCard>
  );
}

PageReviewCard.propTypes = {
  draft: PropTypes.object,
  page: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  primerContext: PropTypes.string,
  isStyled: PropTypes.bool,
};
