import { Box, Card, CardActionArea, CardContent, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { COMPREHENSION_CONCEPT, getCompositeMetricScoreText } from '../../../modules/report/constants';
import HalfDoughnutScoreGraph from '../components/HalfDoughnutScoreGraph';
import { ReactComponent as WarningIcon } from '../../../assets/cds_warning.svg';
import theme from '../../../theme';

const styles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: ({ isDashboard }) => !isDashboard && 'flex',
    borderRadius: '20px',
  },
  cardContent: {
    padding: theme.spacing(1, 2, 2),
  },
  scoreContainer: {
    margin: '0 auto',
    height: '70%',
    width: '70%',
  },
  titleContainer: {
    textAlign: 'center',
  },
  subText: {
    marginTop: theme.spacing(-1),
    textAlign: 'center',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    justifyContent: 'center',
  },
  warningIcon: {
    width: '20px',
    height: '20px',
    marginLeft: theme.spacing(1),
    overflow: 'visible',
  },
}));

const CompositeMetricScoreCard = (props) => {
  const classes = styles(props);

  const { compositeMetric, isSelected, onClick, textColor, backgroundColor, isDashboard, isLowCds } = props;
  const score = useMemo(() => Math.round(compositeMetric.score), [compositeMetric]);

  let checkbox;

  if (isSelected) {
    checkbox = <CheckCircleOutlineIcon color="primary" />;
  } else {
    checkbox = <RadioButtonUncheckedIcon color="primary" />;
  }

  const titleBox =
    isLowCds && compositeMetric.name === COMPREHENSION_CONCEPT ? (
      <Box className={classes.titleBox}>
        <Typography variant="h5">{compositeMetric.name}</Typography>
        <WarningIcon className={classes.warningIcon} />
      </Box>
    ) : (
      <Box className={classes.titleBox}>
        <Typography variant="h5">{compositeMetric.name}</Typography>
      </Box>
    );
  const adjustedTextColor =
    isLowCds && compositeMetric.name === COMPREHENSION_CONCEPT ? theme.palette.error.cds : textColor;

  return (
    <Card
      elevation={isDashboard ? 0 : 2}
      className={classNames(classes.root, { [classes.cardSelected]: isSelected })}>
      {isDashboard ? (
        <CardContent>
          <Grid container>
            <Grid item className={classes.titleContainer} xs={12}>
              {titleBox}
            </Grid>
          </Grid>
          <Box px={2} className={classes.scoreContainer} justifyContent="center">
            <HalfDoughnutScoreGraph
              score={score}
              textColor={adjustedTextColor}
              textFontSize={1.25}
              textFontWeight={'bolder'}
              backgroundColor={backgroundColor}
              hoverBackgroundColor={backgroundColor}
            />
          </Box>
          {false && (
            <Grid container justifyContent="center">
              <Grid item xs={12} className={classes.subText}>
                <Typography variant="body2">
                  {score ? getCompositeMetricScoreText(score) : 'Still Calculating'}
                </Typography>
              </Grid>
            </Grid>
          )}
        </CardContent>
      ) : (
        <CardActionArea onClick={onClick}>
          <CardContent className={classes.cardContent}>
            <Grid container justifyContent="flex-end" alignItems="center">
              <Grid item className={classes.titleContainer} xs={10}>
                {titleBox}
              </Grid>
              <Grid item xs={1}>
                {checkbox}
              </Grid>
            </Grid>
            <Box px={2} className={classes.scoreContainer} justifyContent="center">
              <HalfDoughnutScoreGraph
                score={score}
                textColor={adjustedTextColor}
                textFontSize={1.25}
                textFontWeight={'bolder'}
                backgroundColor={backgroundColor}
                hoverBackgroundColor={backgroundColor}
              />
            </Box>
            {false && (
              <Grid container justifyContent="center">
                <Grid item xs={12} className={classes.subText}>
                  <Typography variant="body2">
                    {score ? getCompositeMetricScoreText(score) : 'Still Calculating'}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </CardContent>
        </CardActionArea>
      )}
    </Card>
  );
};

CompositeMetricScoreCard.propTypes = {
  compositeMetric: PropTypes.object.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool,
};

export default CompositeMetricScoreCard;
