import Container from '@mui/material/Container';
import TrialForm from './TrialForm';

const TrialSignUp = () => {
  return (
    <Container component="main" disableGutters maxWidth={false}>
      <TrialForm />
    </Container>
  );
};

export default TrialSignUp;
