import _ from 'lodash';

export const Sentiments = {
  positive: 'positive',
  negative: 'negative',
  mixed: 'mixed',
  neutral: 'neutral',
};

/**
 * Filters quotes based on search query and filters and returns the filtered quotes.
 *
 * E.g.
 *  filterQuotes(quotes, {
 *    // search query
 *    search: 'foo',
 *
 *    // audience filters
 *    audience: {
 *      '2' : {  // gender
 *        '8': true,  // male
 *        '7': true,  // female
 *      },
 *    },
 *
 *    // emotion word filters (only for Page Attributes)
 *    emotionWord: ['trustworthy'],
 *
 *    // diagnostics (only for Journey Diagnostics)
 *    diagnostics: ['Valuable', 'Trusted'],
 *
 *    // transitions type stay/leave (e.g. only for Page Flow)
 *    transitionType: ['stay'],
 *  });
 *
 * @param {Array} quotes - List of quotes to filter.
 * @param {object} filters - Filters to apply to the quotes.
 */
export function filterQuotes(quotes, filters = {}) {
  const searchQueryLower = (filters.search && filters.search.toLowerCase()) || '';
  const audienceFilters = filters.audience || {};
  const screenerFilters = filters.screeners || {};

  const filteredQuotes = quotes.filter((quote) => {
    if (!quote) {
      return false;
    }

    const quoteLower = (quote.quote && quote.quote.toLowerCase()) || '';
    const matchSearch = quoteLower.includes(searchQueryLower);

    const matchAudience = Object.keys(audienceFilters).every((filterAttributeId) => {
      // Get the segments from attributeFilters that are true
      const validSegmentIds = Object.entries(audienceFilters[filterAttributeId])
        .filter(([, value]) => value) // Filter to keep only the segments that are true
        .map(([segmentId]) => segmentId); // Extract the segment IDs

      if (!validSegmentIds.length) {
        return true;
      }

      // See if the quote has this attribute and if any of its segments match the valid segments
      const quoteAttributes = quote.attributes.filter((attr) => attr.attributeId === filterAttributeId);

      // Check if any segment for this attribute in the quote matches any of the valid segments
      return quoteAttributes?.some((quoteAttribute) => validSegmentIds.includes(quoteAttribute.segmentId));
    });

    const matchScreener = Object.keys(screenerFilters).every((filterAttributeId) => {
      // Get the segments from attributeFilters that are true
      const validSegmentIds = Object.entries(screenerFilters[filterAttributeId])
        .filter(([, value]) => value) // Filter to keep only the segments that are true
        .map(([segmentId]) => segmentId); // Extract the segment IDs

      if (!validSegmentIds.length) {
        return true;
      }

      // See if the quote has this attribute and if any of its segments match the valid segments
      const quoteAttributes = quote.attributes.filter((attr) => attr.attributeId === filterAttributeId);

      // Check if any segment for this attribute in the quote matches any of the valid segments
      return quoteAttributes?.some((quoteAttribute) => validSegmentIds.includes(quoteAttribute.segmentId));
    });

    // Determine if the emotion word matches. This is an optional query so if the quote does not
    // have an emotion word, just ignore.
    let matchEmotionWord = true;
    if (!_.isEmpty(filters.emotionWords) && quote.word) {
      matchEmotionWord = filters.emotionWords.includes(quote.word);
    }

    // Determine if the transition type (e.g. stay or leave) matches. This is an optional query so if the quote does not
    // have a transition type, just ignore.
    let matchTransitionType = true;
    if (!_.isEmpty(filters.transitionType) && quote.type) {
      matchTransitionType = filters.transitionType.includes(quote.type.toLowerCase());
    }

    // Determine if the diagnostic matches. This is an optional query so if the quote does not
    // have a diagnostic, just ignore.
    let matchDiagnostic = true;
    if (!_.isEmpty(filters.diagnostics) && quote.diagnostic) {
      matchDiagnostic = filters.diagnostics.includes(quote.diagnostic);
    }

    let matchCluster = true;
    if (filters.cluster && quote.clusterType) {
      matchCluster = quote.clusterType === filters.cluster;
    }

    let matchSentiment = true;
    if (!_.isEmpty(filters.sentiments) && quote.sentiment) {
      matchSentiment = filters.sentiments.includes(quote.sentiment);
    }

    let matchRating = true;
    if (!_.isEmpty(filters.ratings) && quote.labels) {
      // matchRating === true, if at lease one of the quote's labels matches the a filtered label
      matchRating = quote.labels.find((quoteLabel) => filters.ratings.includes(quoteLabel));
    }

    return Boolean(
      matchSearch &&
        matchAudience &&
        matchScreener &&
        matchEmotionWord &&
        matchTransitionType &&
        matchDiagnostic &&
        matchCluster &&
        matchSentiment &&
        matchRating
    );
  });

  return filteredQuotes;
}
