import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { isEdge, isFirefox, isOpera } from 'react-device-detect';

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 1.5,
  },
  title: {
    fontSize: 'small',
    marginBottom: theme.spacing(0.75),
  },
  fontSize: {
    fontSize: '11px',
  },
  bulletText: { marginLeft: theme.spacing(1), marginBottom: theme.spacing(0.75) },
  list: {
    marginLeft: theme.spacing(-1.25),
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.1),
  },
}));

const getFireshotUrl = () => {
  if (isFirefox) {
    return 'https://microsoftedge.microsoft.com/addons/detail/take-webpage-screenshots-/fcbmiimfkmkkkffjlopcpdlgclncnknm';
  } else if (isEdge) {
    return 'https://microsoftedge.microsoft.com/addons/detail/take-webpage-screenshots-/fcbmiimfkmkkkffjlopcpdlgclncnknm';
  } else if (isOpera) {
    return 'https://addons.opera.com/en/extensions/details/capture-webpage-screenshot-fireshot/';
  } else {
    return 'https://chrome.google.com/webstore/detail/take-webpage-screenshots/mcbpblocgmgfnpjjppndjkmgjaogfceg?hl=en';
  }
};

const FileRequirements = ({ maxPages, maxSteps }) => {
  const classes = useStyles();

  let maxPagesRequirement;

  if (maxPages > 1 && maxSteps > 0) {
    maxPagesRequirement = (
      <>
        <li>
          <div className={classes.bulletText}>Max Journeys - {maxPages}</div>
        </li>
        <li>
          <div className={classes.bulletText}>Max pages per journey - {maxSteps}</div>
        </li>
      </>
    );
  } else if (maxPages === 1 && maxSteps > 0) {
    maxPagesRequirement = (
      <li>
        <div className={classes.bulletText}>Max pages - {maxSteps}</div>
      </li>
    );
  } else {
    maxPagesRequirement = (
      <li>
        <div className={classes.bulletText}>Max pages - {maxPages}`</div>
      </li>
    );
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title} variant="h5" gutterBottom>
        File Requirements & Recommendations
      </Typography>
      <Typography variant="caption" color="textSecondary" className={classes.fontSize}>
        <ul className={classes.list}>
          {maxPagesRequirement}
          <li>
            <div className={classes.bulletText}>Max size per image file - 20MB</div>
          </li>
          <li>
            <div className={classes.bulletText}>File formats - PNG, JPG/JPEG</div>
          </li>
          <li>
            <div className={classes.bulletText}>Image quality - 800px (min)</div>
          </li>
          <li>
            <div className={classes.bulletText}>Image context - screenshot entire page</div>
          </li>
        </ul>
      </Typography>
      <Box py={1} />
      <Typography variant="caption" component="p" color="textSecondary">
        *Need help getting full-page screenshots? Try the{' '}
        <a href={getFireshotUrl()} target="_blank" rel="noopener noreferrer">
          Fireshot
        </a>{' '}
        browser extension.
      </Typography>
    </div>
  );
};

FileRequirements.propTypes = {
  maxPages: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
};

export default memo(FileRequirements);
