import { useQueries, useQuery } from 'react-query';
import axios from '../../../api';

const fetchStepHeatmap = async ({ queryKey: [key, { wevoId, stepId }] }) => {
  if (!wevoId || !stepId) {
    return [];
  }

  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/steps/${stepId}/heatmap`,
    method: 'GET',
  });
  return response?.data?.points ?? [];
};

export default function useFetchStepHeatmap({ wevoId, stepId }, options) {
  return useQuery(['stepHeatMap', { wevoId, stepId }], fetchStepHeatmap, options);
}

export function usePrefetchAllStepsHeatmaps({ wevoId, steps }, options) {
  return useQueries(
    steps?.map((step) => {
      const stepId = step?.id;
      return {
        queryKey: ['stepHeatMap', { wevoId, stepId }],
        queryFn: fetchStepHeatmap,
        staleTime: 30 * 1000,
        ...(options ?? {}),
      };
    })
  );
}
