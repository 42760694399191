import { useMutation, useQueryClient } from 'react-query';
import axios from '../api';
import { snackbar } from '../notifications';

const authorizeFigma = async ({ nextUri }) => {
  const { data } = await axios({
    url: `/api/v2/oauth/figma/code`,
    method: 'POST',
    data: { context: { nextUri } },
  });
  return data?.authorizationUrl || '';
};

export default function useAuthorizeFigma() {
  const queryClient = useQueryClient();

  return useMutation(authorizeFigma, {
    onSuccess: (authorizationUrl) => {
      window.location.href = authorizationUrl;
      queryClient.invalidateQueries(['figmaAuthorization']);
    },
    onError: (err) => {
      queryClient.invalidateQueries(['figmaAuthorization']);
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error authorizing Figma');
    },
  });
}
