import produce from 'immer';
import { get as _get } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { MutationKeys } from '../../../modules/intake/constants';

const deleteStep = ({ wevoId, stepId }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/steps/${stepId}`,
    method: 'DELETE',
  });
};

export default function useDeleteStep() {
  const queryClient = useQueryClient();
  return useMutation(deleteStep, {
    mutationKey: MutationKeys.deleteStep,
    onMutate: async ({ wevoId, stepId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      await queryClient.setQueryData(['wevoData', { wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const steps = _get(draft, 'pages.0.steps', []);

          // Remove the step.
          const index = steps.findIndex((s) => String(s.id) === String(stepId));
          if (index >= 0) {
            steps.splice(index, 1);
          }
        });
      });

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    onSuccess: () => {
      queryClient.invalidateQueries('customQuestionsData');
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
