import { useMutation, useQuery, useQueryClient } from 'react-query';
import { default as api, default as axios } from '../api';
import { snackbar } from '../notifications';

const fetchWevosTags = async () => {
  const response = await api({
    url: `/api/v2/tags`,
    method: 'GET',
  });
  return response?.data?.wevosTags;
};

const deleteTag = async ({ id: wevoId, tagId }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/tags/${tagId}`,
    method: 'DELETE',
  });
  return response.data;
};

const addTags = async ({ id: wevoId, tags }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/tags`,
    method: 'PUT',
    data: { tags },
  });
  return response.data;
};

export function useWevosTags() {
  return useQuery('wevosTags', fetchWevosTags, {
    notifyOnChangeProps: 'tracked',
    placeholderData: [],
  });
}

export function useDeleteTag() {
  const queryClient = useQueryClient();

  return useMutation(deleteTag, {
    onSuccess: (group, variables) => {
      queryClient.invalidateQueries(['tags', { wevoId: variables.id }]);
      queryClient.invalidateQueries('wevosTags');
      queryClient.invalidateQueries('wevos');
      queryClient.invalidateQueries('wevoData');
    },
    onError: (err) => {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting tag');
    },
  });
}

export function useAddTags() {
  const queryClient = useQueryClient();

  return useMutation(addTags, {
    onSuccess: (group, variables) => {
      queryClient.invalidateQueries(['tags', { wevoId: variables.id }]);
      queryClient.invalidateQueries('wevosTags');
      queryClient.invalidateQueries('wevos');
      queryClient.invalidateQueries('wevoData');
    },
    onError: (err) => {
      snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error adding tag');
    },
  });
}
