import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { MutationKeys } from '../../../modules/intake/constants';

const updatePageSortOrder = async ({ id: wevoId, pageId, ...changedFields }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/reorder`,
    method: 'PUT',
    data: changedFields,
  });
  return response?.data?.pages ?? [];
};

/**
 * Custom hook for updating a wevo page sort order.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the updated data
 */
export default function useUpdatePageSortOrder() {
  const queryClient = useQueryClient();

  return useMutation(updatePageSortOrder, {
    mutationKey: MutationKeys.updatePageSortOrder,
    onMutate: async ({ id: wevoId, pageId, ...changedFields }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous wevo value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    onSuccess: (pages, variables) => {
      queryClient.invalidateQueries(['wevoData', { wevoId: variables.id }]);
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: ({ id: wevoId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
