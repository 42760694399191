import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { WevoType } from '../../../modules/wevos/constants';
import { Paths } from '../../../routes';
import PageFlow from './PageFlow';

const StayOrGoContainer = ({ wevo }) => {
  const pageNums = wevo?.pages?.map((page) => page.pageNumber);
  const stepNums = wevo?.pages?.[0]?.steps?.map((step) => step.stepNumber) ?? [];
  const pagePath = `${Paths.reports.stayGoPage}(${pageNums.join('|')})`;
  const stepPath = `${pagePath}/steps/:stepNum(${stepNums.join('|')})`;

  if (wevo?.type === WevoType.Classic) {
    if (wevo?.pages?.length > 1) {
      return <Redirect to={generatePath(Paths.reports.compare, { wevoId: wevo?.id })} />;
    } else {
      return <Redirect to={generatePath(Paths.reports.sentimentMap, { wevoId: wevo?.id })} />;
    }
  }

  return (
    <Switch>
      <Route
        exact
        path={stepPath}
        render={({ match: { params } }) => {
          const { pageNum, stepNum } = params;
          return (
            <PageFlow
              wevo={wevo}
              page={wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum))}
              step={wevo?.pages?.[0]?.steps?.find((step) => Number(step.stepNumber) === Number(stepNum))}
            />
          );
        }}
      />
      <Redirect
        to={generatePath(Paths.reports.stayGoPageStep, {
          wevoId: wevo?.id,
          pageNum: pageNums[0],
          stepNum: stepNums[0],
        })}
      />
    </Switch>
  );
};

export default StayOrGoContainer;
