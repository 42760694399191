import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Box, Grid, Typography } from '@mui/material';
import { ReactComponent as ComingSoonIcon } from '../../../src/assets/coming-soon.svg';
import { AutomatedInsightSessionType } from '../../modules/automated-insights/constants';

const features = [
  {
    name: 'Findings',
    [AutomatedInsightSessionType.Page]: true,
    [AutomatedInsightSessionType.Experience]: true,
  },
  {
    name: 'Sentiment Map',
    [AutomatedInsightSessionType.Page]: true,
    [AutomatedInsightSessionType.Experience]: false,
  },
  {
    name: 'Persona Intent',
    [AutomatedInsightSessionType.Page]: true,
    [AutomatedInsightSessionType.Experience]: false,
  },
  {
    name: 'Recommendations',
    [AutomatedInsightSessionType.Page]: true,
    [AutomatedInsightSessionType.Experience]: true,
  },
  {
    name: 'EQS Score',
    [AutomatedInsightSessionType.Page]: true,
    [AutomatedInsightSessionType.Experience]: false,
  },
  {
    name: 'Chat',
    [AutomatedInsightSessionType.Page]: true,
    [AutomatedInsightSessionType.Experience]: true,
  },
  {
    name: 'Task to Complete',
    [AutomatedInsightSessionType.Page]: false,
    [AutomatedInsightSessionType.Experience]: true,
  },
  {
    name: 'Context',
    [AutomatedInsightSessionType.Page]: false,
    [AutomatedInsightSessionType.Experience]: true,
  },
];

const FeaturesList = ({ sessionType }) => {
  return (
    <Grid container rowGap={1}>
      {features?.map((feature) => (
        <Grid item xs={12}>
          <Grid container alignItems="center" gap={1}>
            <Grid item>
              {feature[sessionType] ? (
                <DoneRoundedIcon sx={{ height: '12px', color: '#32DAA9' }} />
              ) : (
                <ClearRoundedIcon sx={{ height: '12px', color: '#F44336' }} />
              )}
            </Grid>
            <Grid item>
              <Box display="flex" alignItems="center" gap={1}>
                <Typography component="span" fontSize={12} sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                  {feature.name}
                </Typography>
                {!feature[sessionType] && <ComingSoonIcon />}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default FeaturesList;
