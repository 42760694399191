import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  myTests: {
    textTransform: 'none',
    minWidth: 'fit-content',
    color: 'white',
    '& .MuiSvgIcon-root': {
      minWidth: theme.spacing(4),
    },
  },
  dropdownTests: { fontSize: '13px', minWidth: '50px' },
}));

function MyTestsButton({ label, isUserlytics, isMastercard, userlyticsDomain, canUseAutomatedInsights }) {
  const classes = useStyles();
  let history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);

  function handleMyTestsClick(ev) {
    setAnchorEl(ev.currentTarget);
  }

  function closeMenu() {
    setAnchorEl(null);
  }

  function redirectToAutomatedInsightSessions() {
    window.location.href = `${process.env.REACT_APP_BASE_URL}/pulse`;
  }

  function redirectToUserlytics() {
    window.location.href = `${process.env.REACT_APP_USERLYTICS_HOST}${process.env.REACT_APP_USERLYTICS_SSO_PATH}/${userlyticsDomain}`;
  }

  const redirectToDashboard = () => {
    if (isUserlytics) {
      closeMenu();
    }
    history.push('/dashboard');
  };

  if (isUserlytics || canUseAutomatedInsights) {
    const diagnosticsLabel = isMastercard ? 'Diagnostic/Compare Studies' : 'Pro';
    const usabilityStudyLabel = isMastercard ? 'Usability/Custom Survey Studies' : 'Video';

    return (
      <>
        <Button
          className={classes.myTests}
          onClick={handleMyTestsClick}
          startIcon={<ViewListRoundedIcon />}
          endIcon={Boolean(anchorEl) ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}>
          {label}
        </Button>
        <Menu variant="menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
          <MenuItem onClick={redirectToDashboard}>
            <Typography className={classes.dropdownTests}>{diagnosticsLabel}</Typography>
          </MenuItem>
          {canUseAutomatedInsights && (
            <MenuItem onClick={redirectToAutomatedInsightSessions}>
              <Typography className={classes.dropdownTests}>
                {isMastercard ? 'Pulse Studies' : 'Pulse'}
              </Typography>
            </MenuItem>
          )}
          {isUserlytics && (
            <MenuItem onClick={redirectToUserlytics}>
              <Typography className={classes.dropdownTests}>{usabilityStudyLabel}</Typography>
            </MenuItem>
          )}
        </Menu>
      </>
    );
  } else {
    return (
      <Button className={classes.myTests} onClick={redirectToDashboard} startIcon={<ViewListRoundedIcon />}>
        {label}
      </Button>
    );
  }
}

MyTestsButton.propTypes = {
  label: PropTypes.string.isRequired,
  isUserlytics: PropTypes.bool.isRequired,
  isMastercard: PropTypes.bool.isRequired,
  canUseAutomatedInsights: PropTypes.bool.isRequired,
};

export default MyTestsButton;
