import PropTypes from 'prop-types';
import AudienceFilter from './AudienceFilter';

const QuoteAudienceFilter = ({ quotes, onFilterChange }) => {
  return (
    <AudienceFilter
      items={quotes}
      itemType={'quotes'}
      filterName={'Audience'}
      onFilterChange={onFilterChange}
      isAdvancedFilter={false}
    />
  );
};

QuoteAudienceFilter.propTypes = {
  quotes: PropTypes.array.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default QuoteAudienceFilter;
