import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

/**
 * Generates benchmarks for a session
 */

const generateBenchmarks = async ({ sessionId, segmentId = null, tagIds }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/${sessionId}/benchmarks`,
    method: 'POST',
    data: { segmentId, tagIds },
  });

  return response?.data;
};

export default function useGenerateBenchmarks() {
  const queryClient = useQueryClient();
  return useMutation(generateBenchmarks, {
    mutationKey: 'generateBenchmarks',
    onSuccess: (data, { sessionId, segmentId }) => {
      queryClient.invalidateQueries(['automatedInsightSession', { sessionId, segmentId }]);
    },
  });
}
