import { withPlateEventProvider } from '@udecode/plate-headless';
import { forwardRef, useRef } from 'react';
import ReactDOM from 'react-dom';
import usePlatePopper from '../../hooks/plate/usePlatePopper';

const PlatePopper = withPlateEventProvider((props) => {
  const { children, portalElement, popperOptions: _popperOptions = {} } = props;

  const popperRef = useRef(null);

  const popperOptions = {
    popperElement: popperRef.current,
    placement: 'bottom',
    offset: [32, 8],
    ..._popperOptions,
  };

  const { styles: popperStyles, attributes } = usePlatePopper(popperOptions);

  return (
    <PortalBody element={portalElement}>
      <ToolbarBase ref={popperRef} style={popperStyles.popper} {...attributes.popper}>
        {children}
      </ToolbarBase>
    </PortalBody>
  );
});

const ToolbarBase = forwardRef((props, ref) => {
  return <div data-testid="Toolbar" ref={ref} {...props} />;
});

const PortalBody = ({ children, element }) => ReactDOM.createPortal(children, element || document.body);

export default PlatePopper;
