import produce from 'immer';
import { get as _get } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { MutationKeys } from '../../../modules/intake/constants';

const deletePage = ({ wevoId, pageId }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}`,
    method: 'DELETE',
  });
};

export default function useDeletePage() {
  const queryClient = useQueryClient();
  return useMutation(deletePage, {
    mutationKey: MutationKeys.deletePage,
    onMutate: async ({ wevoId, pageId }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['wevoData', { wevoId }]);

      // Snapshot the previous value
      const previousWevoState = await queryClient.getQueryData(['wevoData', { wevoId }]);

      await queryClient.setQueryData(['wevoData', { wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const pages = _get(draft, 'pages', []);

          // Remove the page.
          const index = pages.findIndex((p) => String(p.id) === String(pageId));
          if (index >= 0) {
            pages.splice(index, 1);
          }
        });
      });

      // Return a context object with the snapshotted value
      return { previousWevoState };
    },
    onSuccess: () => {
      queryClient.invalidateQueries('customQuestionsData');
    },
    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId }, { previousWevoState }) => {
      queryClient.setQueryData(['wevoData', { wevoId }], previousWevoState);
    },
  });
}
