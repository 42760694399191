import { Box, CircularProgress, Grid, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { filterQuotes, Sentiments } from '../../../modules/wevos/quotes';
import {
  buildAudienceFilterProperties,
  buildWordFilterProperties,
  TrackEvent,
  useTrackPageLoad,
} from '../../analytics';
import { shouldTrackAudienceFilterChange } from '../../analytics/eventPropertyHelpers';
import { DiagnosticChip, SentimentChip } from '../../reports/components/QuoteChip';
import QuotesTable from '../../reports/components/QuotesTable';
import { DiagnosticWordFilter, SentimentFilter } from '../../reports/components/QuoteWordFilter';
import { RightDrawerButton } from '../components/RightDrawer';
import ShowImage from '../components/ShowImage';
import useDiagnostics from '../hooks/useDiagnostics';
import DriversBarGraph from './DriversBarGraph';

const Drivers = (props) => {
  const { wevo, page, rightDrawerOpen, setRightDrawerOpen } = props;

  const [quotes, setQuotes] = useState([]);
  const [filters, setFilters] = useState({ diagnostics: [], sentiments: [], search: '' });

  // diagnostics endpoint returns drivers as well; the structure is very similar to diagnostics,
  // only the words and scoring are different, so this component treats drivers as if they are diagnostics
  // in order to reuse the existing display logic
  const { data } = useDiagnostics(
    { wevoId: wevo?.id, pageId: page?.id },
    {
      onSuccess: (data) => {
        setQuotes(data?.quotes);
      },
    }
  );

  const drivers = useMemo(() => data?.diagnostics ?? [], [data]);

  const filteredQuotes = useMemo(() => filterQuotes(quotes ?? [], filters), [quotes, filters]);
  const selectedDrivers = useMemo(() => filters.diagnostics, [filters]);
  const selectedSentiments = useMemo(() => filters.sentiments, [filters]);
  const allWords = useMemo(() => (drivers ?? []).map((driver) => driver.name), [drivers]);

  const { track } = useAnalytics();

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_JOURNEY_DIAGNOSTICS,
    properties: { wevoId: wevo?.analyticsId, pageId: page?.id },
  });

  const onQuoteUpdate = ({ id, updates }) => {
    const quoteIndex = quotes.findIndex((quote) => quote.id === id);
    const updatedQuote = { ...quotes[quoteIndex], ...updates };
    const updatedQuotes = [...quotes];
    updatedQuotes[quoteIndex] = updatedQuote;
    setQuotes(updatedQuotes);
  };

  // Called by QuoteSearch if search query has changed.
  const onSearchChange = (searchQuery) => {
    if (searchQuery) {
      track(TrackEvent.SEARCHED_QUOTE, {
        searchQuery,
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      });
    }
    setFilters((filters) => ({
      ...filters,
      search: searchQuery,
    }));
  };

  // Called by QuoteAudienceFilter if audience filters are changed.
  const onAudienceFilterChange = (audienceFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      audienceFilters,
      filters.audience,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, audienceFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_AUDIENCE, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      audience: audienceFilters,
    }));
  };

  // Called by CustomScreenerFilter if custom screener filters are changed.
  const onCustomScreenerFilterChange = (screenerFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      screenerFilters,
      filters.screeners,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, screenerFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_CUSTOM_SCREENER, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      screeners: screenerFilters,
    }));
  };

  /**
   * Called by DiagnosticsFilter if selected diagnostics list is changed.
   * @param {string[]} selectedDriverNames
   */
  const onDriverChange = (selectedDriverNames) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_DIAGNOSITCS,
      buildWordFilterProperties(selectedDriverNames, filters.diagnostics, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      })
    );
    setFilters((filters) => ({
      ...filters,
      diagnostics: selectedDriverNames,
    }));
  };

  /**
   * Called by SentimentFilter if selected sentiments list is changed.
   * @param {string[]} selectedSentiments
   */
  const onSentimentChange = (selectedSentiments) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_SENTIMENT,
      buildWordFilterProperties(selectedSentiments, filters.sentiments, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      })
    );

    setFilters((filters) => ({
      ...filters,
      sentiments: selectedSentiments,
    }));
  };

  /**
   * Toggles whether diagnostic is selected or not.
   * @param {string} driverName - Diagnostic word to toggle
   */
  const toggleDriver = (driverName) => {
    track(TrackEvent.FILTERED_QUOTES_BY_DIAGNOSITCS, {
      filters: [driverName],
      location: 'diagnostic score card',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      diagnostics: [driverName],
    }));
  };

  /**
   * Remove a given diagnostic or sentiment from the filters
   * @param {string} deselectedWord diagnostic or sentiment
   */
  const deselectWord = (deselectedWord) => {
    track(TrackEvent.DESELECTED_QUOTE_FILTER, {
      filters: [deselectedWord],
      deselected: true,
      location: 'chip',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      diagnostics: filters.diagnostics.filter((driver) => driver !== deselectedWord),
      sentiments: filters.sentiments.filter((sentiment) => sentiment !== deselectedWord),
    }));
  };

  /**
   * Clear diagnostic and sentiment filters
   */
  const clearWordFilters = () => {
    track(TrackEvent.CLEARED_QUOTE_FILTERS, {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      diagnostics: [],
      sentiments: [],
    }));
  };

  if (!(drivers ?? []).length || !(quotes ?? []).length) {
    return (
      <Box p={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  let title = `${filteredQuotes.length} Quotes`;
  if (quotes.length !== filteredQuotes.length) {
    title += ` (out of ${quotes.length})`;
  }

  return (
    <Box mr={5}>
      <Typography variant="body1">See how your page scored on five WEVO conversion diagnostics.</Typography>

      <Box py={4} style={{ display: 'none' }}>
        <Grid container>
          <Grid item lg={6} xs={12}>
            <Typography variant="body1" style={{ textAlign: 'center', paddingTop: '2%', paddingBottom: '2%' }}>
              {page.name}
            </Typography>
            <Box style={{ backgroundColor: 'white' }}>
              <Box p={1} style={{ maxHeight: '440px', overflow: 'scroll', padding: '2%' }}>
                <ShowImage image={page.images} maxHeight={page.images.original.height} maxWidth={1000} />
              </Box>
            </Box>
          </Grid>
          <Grid item lg={6} xs={12}>
            <Typography variant="body1" style={{ textAlign: 'center', paddingTop: '2%', paddingBottom: '2%' }}>
              Drivers
            </Typography>
            <DriversBarGraph drivers={drivers} onBarSelected={(label) => toggleDriver(label)} />
          </Grid>
        </Grid>
      </Box>

      <Paper
        py={4}
        elevation={4}
        sx={{
          marginTop: 4,
          paddingBottom: 2,
          paddingLeft: { sm: 2 },
          paddingRight: { sm: 2 },
          borderRadius: '20px',
        }}>
        <DriversBarGraph drivers={drivers} onBarSelected={(label) => toggleDriver(label)} />
      </Paper>

      <Paper
        elevation={4}
        sx={{
          marginTop: 4,
          paddingBottom: 2,
          paddingLeft: { sm: 2 },
          paddingRight: { sm: 2 },
          borderRadius: '20px',
        }}>
        <Box sx={{ padding: { xs: 1, md: 4 } }}>
          <Grid container>
            <Grid item xs={12}>
              <QuotesTable
                wevo={wevo}
                page={page}
                fullQuotes={quotes}
                displayQuotes={filteredQuotes}
                onQuoteUpdate={onQuoteUpdate}
                onAudienceFilterChange={onAudienceFilterChange}
                onCustomScreenerFilterChange={onCustomScreenerFilterChange}
                clearWordFilters={clearWordFilters}
                searchQuery={filters?.search}
                onSearchChange={onSearchChange}
                title={title}
                selectedWords={[...selectedDrivers, ...selectedSentiments]}
                deselectWord={deselectWord}
                filters={[
                  <DiagnosticWordFilter
                    wevo={wevo}
                    words={allWords}
                    selectedWords={selectedDrivers}
                    onWordFilterChange={onDriverChange}
                  />,
                  <SentimentFilter
                    sentiments={Object.values(Sentiments)}
                    selectedSentiments={selectedSentiments}
                    onSentimentFilterChange={onSentimentChange}
                  />,
                ]}
                chips={[DiagnosticChip, SentimentChip]}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <RightDrawerButton
        wevo={wevo}
        rightDrawerOpen={rightDrawerOpen}
        handleRightDrawerOpenClick={setRightDrawerOpen}
      />
    </Box>
  );
};

Drivers.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  rightDrawerOpen: PropTypes.bool.isRequired,
  setRightDrawerOpen: PropTypes.func.isRequired,
};

export default Drivers;
