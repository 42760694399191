import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

/**
 * Creates a segment (not associated to any session)
 */

const createSegment = async ({ name, description, isGenerated }) => {
  const response = await axios({
    url: `/api/v2/segments`,
    method: 'POST',
    data: { name, description, isGenerated },
  });

  return response?.data?.segment;
};

export default function useCreateSegment() {
  const queryClient = useQueryClient();
  return useMutation(createSegment, {
    mutationKey: 'createSegment',
    onSuccess: () => {
      queryClient.invalidateQueries('segments');
    },
  });
}
