import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchKeyFindings = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/key-findings`,
    method: 'GET',
  });

  return data.keyFindings ?? [];
};

/**
 *
 * @param {{wevoId: string, pageId: string}} params
 * @returns
 */
export default function useKeyFindings({ wevoId, pageId }, options) {
  return useQuery(['keyFindings', { wevoId, pageId }], fetchKeyFindings, {
    notifyOnChangeProps: 'tracked',
    ...(options ?? {}),
  });
}

const fetchCrossPageKeyFindings = async ({ queryKey: [key, { wevoId }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/key-findings`,
    method: 'GET',
  });

  return data?.keyFindings ?? null;
};

/**
 *
 * @param {{wevoId: string}} params
 * @returns
 */
export function useCrossPageKeyFindings({ wevoId }, options) {
  return useQuery(['crossPageKeyFindings', { wevoId }], fetchCrossPageKeyFindings, {
    notifyOnChangeProps: 'tracked',
    ...(options ?? {}),
  });
}
