import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo } from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    lineHeight: 1.1,
  },
  title: {
    fontSize: 'small',
    marginBottom: theme.spacing(0.75),
  },
  fontSize: {
    fontSize: '11px',
  },
  bulletText: { marginLeft: theme.spacing(1), marginBottom: theme.spacing(0.75) },
  list: {
    marginLeft: theme.spacing(-1.25),
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.1),
  },
}));

const PrototypeRequirements = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box mb={2}>
        <Typography className={classes.title} variant="h5" gutterBottom>
          Prototypes Requirements & Recommendations
        </Typography>
        <Typography className={classes.fontSize} color="textSecondary" sx={{ mb: 0.75 }}>
          Prototypes should not have unnecessary friction such as:
        </Typography>
        <Typography color="textSecondary" className={classes.fontSize}>
          <ul className={classes.list}>
            <li>
              <div className={classes.bulletText}>Blurry images</div>
            </li>
            <li>
              <div className={classes.bulletText}>Image placeholders</div>
            </li>
            <li>
              <div className={classes.bulletText}>Lorem Ipsum or other obvious placeholder text</div>
            </li>
            <li>
              <div className={classes.bulletText}>Partial construction</div>
            </li>
            <li>
              <div className={classes.bulletText}>Wireframes</div>
            </li>
            <li>
              <div className={classes.bulletText}>
                We recommend you include the "hot spots" that show the users where to click next in case they
                become confused
              </div>
            </li>
          </ul>
        </Typography>
      </Box>

      <Box mb={2}>
        <Typography className={classes.fontSize} variant="caption" color="textSecondary">
          In order to allow respondents to load your experience faster and successfully:
        </Typography>
        <Typography variant="caption" color="textSecondary" className={classes.fontSize}>
          <ul className={classes.list}>
            <li>
              <div className={classes.bulletText}>
                Include in your prototype only the parts you think are essential for your test.
              </div>
            </li>
            <li>
              <div className={classes.bulletText}>
                Try compressing large images using tools such as{' '}
                <a href="https://compresspng.com/" target="_blank" rel="nofollow noreferrer">
                  CompressPNG
                </a>
                , or the Figma plugin{' '}
                <a
                  href="https://www.figma.com/community/plugin/799646392992487942/Insert-Big-Image"
                  target="_blank"
                  rel="nofollow noreferrer">
                  InsertBigImage
                </a>
              </div>
            </li>
            <li>
              <div className={classes.bulletText}>Set link permissions to Anyone with the link can view.</div>
            </li>
          </ul>
        </Typography>
      </Box>
    </div>
  );
};

export default memo(PrototypeRequirements);
