import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { produce } from 'immer';
import { get as _get } from 'lodash';
import { MutationKeys } from '../../../modules/intake/constants';
import { set as _set, sortBy as _sortBy } from 'lodash';

const uploadPrimer = (imageObj) => {
  const formData = new FormData();
  formData.append('file', imageObj.file);

  return axios({
    url: '/api/v2/primer/images',
    method: 'POST',
    data: formData,
  });
};

const updatePrimerId = ({ wevoId, ...page }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/primer`,
    method: 'PUT',
    data: page,
  });
};

const uploadPrimerandUpdatePages = async ({ uploadId, image, wevoId }) => {
  const primerUploadRes = await uploadPrimer({ file: image });

  const updatePrimerIdRes = await updatePrimerId({
    wevoId,
    primerId: primerUploadRes.data.imageId,
  });
  return updatePrimerIdRes.data.page;
};

export default function useUploadPrimer() {
  const queryClient = useQueryClient();
  return useMutation(uploadPrimerandUpdatePages, {
    mutationKey: MutationKeys.uploadPrimer,
    onSuccess: (page, variables) => {
      queryClient.setQueryData(['wevoData', { wevoId: variables.wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const pages = _get(draft, 'pages', []);
          const index = pages.findIndex((p) => String(p.id) === String(page.id));
          if (index >= 0) {
            pages[index] = {
              ...pages[index],
              ...page,
            };
          }
          _set(draft, 'pages', _sortBy(pages, ['sortOrder']));
        });
      });
    },
  });
}
