import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import {
  Box,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AudienceFilter from '../../components/AudienceFilter';
import MetricsFilter from './MetricsFilter';
import OutcomesBlock from './OutcomesBlock';

function TablePaginationActions(props) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, marginLeft: 2 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        size="small">
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        size="small">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        size="small"
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        size="small"
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page">
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const OutcomesTable = ({
  outcomes,
  filteredOutcomes,
  onAudienceFilterChange,
  selectedOutcomes,
  onOutcomeFilterChange,
  selectedEaseScores,
  onEaseFilterChange,
  maxTaskTimeSeconds,
  onTaskTimeFilterChange,
  highlightedOutcome,
  onOutcomeClick,
}) => {
  const [pageNum, setPageNum] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleChangePage = (event, newPageNum) => {
    setPageNum(newPageNum);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNum(0);
  };

  const rows =
    rowsPerPage > 0
      ? filteredOutcomes?.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage)
      : filteredOutcomes;

  if (!rows?.length > 0 && pageNum !== 0) {
    setPageNum(0);
  }

  const showPagination = rows?.length > 0;

  return (
    <Paper elevation={0} sx={{ width: '100%' }}>
      <Grid container spacing={1} sx={{ height: '130px', marginTop: 1, paddingLeft: 2 }}>
        <Grid item xs={6}>
          <MetricsFilter
            selectedOutcomes={selectedOutcomes}
            onOutcomeFilterChange={onOutcomeFilterChange}
            selectedEaseScores={selectedEaseScores}
            onEaseFilterChange={onEaseFilterChange}
            maxTaskTimeSeconds={maxTaskTimeSeconds}
            onTaskTimeFilterChange={onTaskTimeFilterChange}
          />
        </Grid>
        <Grid item xs={6}>
          <AudienceFilter
            items={outcomes}
            itemType={'outcomes'}
            filterName={'Audience'}
            onFilterChange={onAudienceFilterChange}
            isAdvancedFilter={false}
          />
        </Grid>
        {showPagination && (
          <Grid item xs={12}>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50]}
              count={filteredOutcomes?.length}
              rowsPerPage={rowsPerPage}
              page={pageNum}
              SelectProps={{
                inputProps: { 'aria-label': 'outcomes per page:' },
                native: true,
              }}
              labelRowsPerPage="Outcomes per page"
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Grid>
        )}
      </Grid>
      <TableContainer sx={{ maxHeight: '90vh' }}>
        <Table>
          <TableBody>
            {_.isEmpty(filteredOutcomes) && (
              <TableRow>
                <TableCell>
                  <Box py={1}>
                    <Typography>Sorry! There are no outcomes to display.</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            )}
            {!_.isEmpty(filteredOutcomes) &&
              rows.map((outcome) => (
                <TableRow key={outcome.id}>
                  <TableCell
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: outcome.id === highlightedOutcome?.id && 'rgb(243, 243, 243)',
                    }}>
                    <OutcomesBlock
                      outcome={outcome}
                      onOutcomeClick={onOutcomeClick}
                      isSelected={outcome.id === highlightedOutcome?.id}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

OutcomesTable.propTypes = {
  outcomes: PropTypes.array.isRequired,
  filteredOutcomes: PropTypes.array.isRequired,
  onAudienceFilterChange: PropTypes.func.isRequired,
  selectedOutcomes: PropTypes.array.isRequired,
  onOutcomeFilterChange: PropTypes.func.isRequired,
  selectedEaseScores: PropTypes.array.isRequired,
  onEaseFilterChange: PropTypes.func.isRequired,
  maxTaskTimeSeconds: PropTypes.number.isRequired,
  onTaskTimeFilterChange: PropTypes.func.isRequired,
  highlightedOutcome: PropTypes.object,
  onOutcomeClick: PropTypes.func.isRequired,
};
export default OutcomesTable;
