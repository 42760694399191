import { Box, Grid, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import PropTypes from 'prop-types';
import CompositeMetricScoreCard from './CompositeMetricScoreCard';

const styles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
  chartInfoContainer: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  chartInfoIcon: {
    color: grey[600],
    fontSize: ({ isDashboard }) => (isDashboard ? '1.25rem' : ''),
  },
  chartTooltip: {
    maxWidth: 450,
  },
  chartTooltipContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  chartTooltipItems: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
    },
  },
  tooltipText: {
    fontSize: '12px',
  },
}));

const CompositeMetricsSummary = (props) => {
  const classes = styles(props);

  const {
    compositeMetrics,
    compositeMetricsColors,
    compositeMetricsThemeNames,
    selectedCompositeMetrics = [],
    onScoreCardClick,
    isDashboard,
    isLowCds,
  } = props;

  const colors = compositeMetricsThemeNames.map((name) => compositeMetricsColors[name]);

  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={11}>
          <Typography variant={isDashboard ? 'h5' : 'h4'}>Themes</Typography>
        </Grid>
        <Grid item xs={1} className={classes.chartInfoContainer}>
          <Tooltip
            placement={'bottom-end'}
            classes={{
              tooltip: classes.chartTooltip,
            }}
            title={
              <div className={classes.chartTooltipContent}>
                <ul className={classes.chartTooltipItems}>
                  {compositeMetrics.map((compositeMetric, idx) => {
                    return (
                      <li key={idx}>
                        <Typography component="p" gutterBottom className={classes.tooltipText}>
                          <Box component="span" fontWeight="fontWeightBold" display="inline">
                            {compositeMetric?.name}:
                          </Box>
                          &nbsp; {compositeMetric?.description}
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </div>
            }>
            <InfoIcon className={classes.chartInfoIcon} />
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container justifyContent="space-evenly" spacing={3}>
        {compositeMetrics.map((compositeMetric, idx) => (
          <Grid key={compositeMetric.metricId} item xs={8} md={7} lg={4}>
            <CompositeMetricScoreCard
              compositeMetric={compositeMetric}
              isSelected={selectedCompositeMetrics.indexOf(compositeMetric.name) > -1}
              onClick={() => {
                if (onScoreCardClick) {
                  onScoreCardClick(compositeMetric);
                }
              }}
              textColor={'black'}
              backgroundColor={colors[idx]}
              isDashboard={isDashboard}
              isLowCds={isLowCds}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

CompositeMetricsSummary.propTypes = {
  compositeMetrics: PropTypes.array.isRequired,
  compositeMetricsColors: PropTypes.object.isRequired,
  compositeMetricsThemeNames: PropTypes.array.isRequired,
  selectedCompositeMetrics: PropTypes.array,
  onScoreCardClick: PropTypes.func,
  isDashboard: PropTypes.bool,
};

export default CompositeMetricsSummary;
