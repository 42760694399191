import { produce } from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomQuestionTypes, CustomQuestionMutationKeys } from '../../../modules/intake/constants';

const addCustomQuestion = async ({ id: wevoId, scopesToAdd }) => {
  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/custom-questions/groups`,
    method: 'POST',
    data: { scopes: scopesToAdd, type: CustomQuestionTypes.CustomQualitative }, // the default type fot a new question
  });
  return response.data.group;
};

export default function useAddCustomQuestion() {
  const queryClient = useQueryClient();

  return useMutation(addCustomQuestion, {
    mutationKey: CustomQuestionMutationKeys.addCustomQuestion,
    onSuccess: (group, variables) => {
      queryClient.setQueryData(['customQuestionsData', { wevoId: variables.id }], (oldData) => {
        return produce(oldData, (draftState) => {
          draftState.push(group);
        });
      });
    },
  });
}
