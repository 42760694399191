import CloseIcon from '@mui/icons-material/Close';
import { Card, Grid, IconButton, Link, Typography } from '@mui/material';
import React from 'react';
import { useAnalytics } from 'use-analytics';
import ClockIcon from '../../assets/clock.png';
import { TrackEvent } from '../analytics';

const UpgradeSubscriptionBanner = ({ setShowUpgradeBox, freeTrialRemainingDays }) => {
  const { track } = useAnalytics();

  const handleUpgradeClick = () => {
    track(TrackEvent.PULSE_TRIAL_BANNER_CLICKED_UPGRADE);
    window.location.href = '/pulse/subscription';
  };

  return (
    <Grid
      item
      sx={{
        width: '340px',
        height: '35px',
      }}>
      <Card
        elevation={0}
        sx={{
          height: '100%',
          backgroundColor: '#082739',
          border: '1px solid rgba(255, 88, 118, 0.46)',
          borderRadius: '10px',
          alignContent: 'center',
        }}>
        <Grid
          container
          spacing={2}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ paddingLeft: 1, paddingRight: 1 }}>
          <Grid item sx={{ marginTop: 0.4, marginLeft: 0.5, marginRight: -1 }}>
            <img src={ClockIcon} alt="" style={{ width: '14px', height: 'auto' }} />
          </Grid>
          <Grid item>
            <Typography
              sx={{
                fontSize: '13px',
                color: 'white',
              }}>
              <b>{freeTrialRemainingDays}</b> left on your free trial.
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: 0.4 }}>
            <Link
              to="/pulse/subscription"
              onClick={handleUpgradeClick}
              sx={{ color: '#43BCFF', textDecoration: 'none', cursor: 'pointer' }}>
              <Typography component="span" sx={{ fontSize: '13px' }}>
                Upgrade
              </Typography>
            </Link>
          </Grid>
          <Grid item sx={{ marginLeft: -2 }}>
            <IconButton aria-label="close" size="small" onClick={() => setShowUpgradeBox(false)}>
              <CloseIcon sx={{ fontSize: '14px', color: 'white' }} />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default UpgradeSubscriptionBanner;
