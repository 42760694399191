import { Tooltip, styled, tooltipClasses } from '@mui/material';
import React from 'react';

export const ThemeTooltip = styled(({ className, color, backgroundColor, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, color, backgroundColor }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor,
    color: color || theme.palette.text.primary,
    borderRadius: '10px',
    fontSize: '10px',
    fontWeight: 700,
  },
}));

const StyledTooltip = styled(({ className, maxWidth, height, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth, height }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxWidth || 230,
    backgroundColor: '#082739',
    fontSize: '12px',
    color: theme.palette.text.primary,
    borderRadius: '10px',
    border: '1px solid #324B59',
  },
}));

const CustomTooltip = ({ children, ...props }) => {
  return <StyledTooltip {...props}>{children}</StyledTooltip>;
};

export default CustomTooltip;
