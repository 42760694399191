import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { AutomatedInsightSessionType } from '../../../modules/automated-insights/constants';

const createCompareSession = async ({ files, urls, sessionIds, name, deviceId }) => {
  const formData = new FormData();

  if (files) {
    files.forEach((file) => formData.append('files', file));
  }

  if (urls) {
    urls.forEach((url) => formData.append('urls', url));
  }

  if (sessionIds) {
    sessionIds.forEach((sessionId) => formData.append('sessionIds', sessionId));
  }

  formData.append('name', name);
  formData.append('deviceId', deviceId);
  formData.append('type', AutomatedInsightSessionType.Compare);

  const response = await axios({
    url: '/api/v2/automated-insight-sessions/compare',
    method: 'POST',
    data: formData,
  });

  return response?.data;
};

export default function useCreateCompareSession() {
  const queryClient = useQueryClient();
  return useMutation(createCompareSession, {
    mutationKey: 'createCompareSession',
    onSuccess: (automatedInsightSession) => {
      queryClient.setQueryData('automatedInsightSession', automatedInsightSession);
    },
  });
}
