import { useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import { BarController, BarElement, CategoryScale, Chart, LinearScale, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { memoAreEqual } from '../../../helpers';
import {
  ExpectationOutcome,
  ExpectationOutcomeToColor,
  ExpectationOutcomeToLabel,
} from '../../../modules/report/constants';
import { chunkString, displayLabels } from '../../../modules/report/helpers.js';
import theme from '../../../theme';

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip);

const ExpectationsGraph = React.memo((props) => {
  const {
    expectations,
    selectedExpectation,
    onChange,
    height = '100%',
    width = 100,
    componentId,
    isPptComponent,
  } = props;

  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  const isSmallDevice = useMediaQuery(theme.breakpoints.down('lg'));
  const isMediumDevice = useMediaQuery(theme.breakpoints.down('xl'));
  const isLargeDevice = useMediaQuery(theme.breakpoints.down('xl'));

  let chartLabelMaxWidth;

  if (isSmallDevice) {
    chartLabelMaxWidth = 25;
  } else if (isMediumDevice) {
    chartLabelMaxWidth = 35;
  } else if (isLargeDevice) {
    chartLabelMaxWidth = 45;
  } else {
    chartLabelMaxWidth = 55;
  }

  const handleOnClick = useCallback(
    (clickedIndex) => {
      const clickedExpectation = expectations[clickedIndex];

      // Tell parent component that the expectation was selected.
      if (clickedExpectation && clickedExpectation.id) {
        onChange(clickedExpectation.id);
      }
    },
    [expectations, onChange]
  );

  /**
   * Determine if expectation labels are clicked from the labels.
   *
   * @param {Event} ev
   */
  const handleOnClickLabels = (ev) => {
    if (!chartRef?.current) {
      return;
    }

    // Calculate the x,y click coordinates starting from the canvas.
    const canvasOffsetX = chartRef.current?.canvas?.offsetLeft ?? 0;
    const canvasOffsetY = chartRef.current?.canvas?.offsetTop ?? 0;
    const clickX = ev.pageX - canvasOffsetX;
    const clickY = ev.pageY - canvasOffsetY;

    // Get x coordinates for when the horizontal bars begin.
    const barsLeftX = chartRef.current?.scales?.x?.left ?? 0;

    // If the click event was on the bars, ignore it. Only switch the expectation if it was on the tick label.
    if (clickX > barsLeftX) {
      return;
    }

    // Determine which expectation was clicked.
    const clickedIndex = chartRef.current.scales.y.getValueForPixel(clickY);
    handleOnClick(clickedIndex);
  };

  /**
   * Determine which expectation was clicked when bar is clicked.
   *
   * @param {Event} ev
   * @param {object} item
   */
  const handleOnClickBars = useCallback(
    (ev, item) => {
      const clickedIndex = item?.[0]?.index;
      if (typeof clickedIndex !== 'number') {
        return;
      }

      handleOnClick(clickedIndex);
    },
    [handleOnClick]
  );

  useEffect(() => {
    const labels = expectations?.map((expectation) => expectation.theme);

    const totalMentions = expectations?.reduce((acc, expectation) => {
      acc += expectation.totalMentions;
      return acc;
    }, 0);

    const labelsSum = expectations?.map((expectation) => {
      return expectation.isLast
        ? `${totalMentions} total mentions`
        : `(${expectation.totalMentions} mentions)`;
    });

    // Build datasets where each element in a dataset is the number of met/somewhat/unmet quotes
    // for each expectation.
    const datasets = {
      [ExpectationOutcome.Met]: [],
      [ExpectationOutcome.Somewhat]: [],
      [ExpectationOutcome.Unmet]: [],
    };
    expectations.forEach((expectation) => {
      if (!expectation) {
        return;
      }

      datasets[ExpectationOutcome.Met].push(expectation.numMet);
      datasets[ExpectationOutcome.Somewhat].push(expectation.numSomewhat);
      datasets[ExpectationOutcome.Unmet].push(expectation.numUnmet);
    });

    const data = {
      labels,
      datasets: [
        {
          label: ExpectationOutcomeToLabel[ExpectationOutcome.Unmet],
          backgroundColor: ExpectationOutcomeToColor[ExpectationOutcome.Unmet],
          data: datasets[ExpectationOutcome.Unmet],
          barPercentage: 0.75,
          maxBarThickness: 40,
        },
        {
          label: ExpectationOutcomeToLabel[ExpectationOutcome.Somewhat],
          backgroundColor: ExpectationOutcomeToColor[ExpectationOutcome.Somewhat],
          data: datasets[ExpectationOutcome.Somewhat],
          barPercentage: 0.75,
          maxBarThickness: 40,
        },
        {
          label: ExpectationOutcomeToLabel[ExpectationOutcome.Met],
          backgroundColor: ExpectationOutcomeToColor[ExpectationOutcome.Met],
          data: datasets[ExpectationOutcome.Met],
          barPercentage: 0.75,
          maxBarThickness: 40,
        },
      ],
    };

    const config = {
      type: 'bar',
      data,
      plugins: [ChartDataLabels],
      options: {
        animation: {
          duration: 0,
        },
        indexAxis: 'y',
        responsive: !isPptComponent,
        maintainAspectRatio: false,
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
            },
            ticks: { display: false },
          },
          y: {
            stacked: true,
            grid: {
              display: false,
              drawBorder: false,
              drawTicks: false,
              color: (context) => {
                return context?.index === expectations?.length - 1 ? grey[400] : 'rgba(0, 0, 0, 0)';
              },
            },

            ticks: {
              // Change the font color for the expectation label that is selected.
              color: (context) => {
                if (!selectedExpectation || !selectedExpectation.id) {
                  return;
                }

                const expectationIndex = expectations.findIndex(
                  (expectation) => expectation.id === selectedExpectation.id
                );

                const tickIndex = context?.tick?.value;
                return tickIndex === expectationIndex ? '#276EB0' : undefined;
              },
              callback: function (index) {
                const originalLabel = index !== expectations?.length - 1 ? labels[index] : '';
                const str = chunkString(originalLabel, chartLabelMaxWidth);
                str.push(labelsSum[index]);
                return str;
              },
              font: {
                weight: (context) => {
                  return context?.tick?.value === expectations?.length - 1 ? 'bold' : '';
                },
                size: (context) => {
                  return context?.tick?.value === expectations?.length - 1 ? '10px' : '12px';
                },
              },
              padding: 10,
            },
          },
        },
        plugins: {
          datalabels: {
            color: 'white',
            formatter: (value, context) => {
              // Use the Least Remainder Method to ensure percentages always add up to 100%
              const dataValues = data.datasets.map((dataset) => dataset?.data?.[context.dataIndex] ?? 0);

              // get total for the theme
              const numTotal = dataValues.reduce((acc, cur) => acc + cur, 0);

              // calculate outcome distribution for the theme
              const percentages = dataValues.map((value) => (numTotal > 0 ? (value / numTotal) * 100 : 0));

              // Round all percentages down, we will make adjustments based on the deviation of this total from 100
              const roundedPercentages = percentages.map((value) => Math.floor(value));

              // determine the amount that must be redistributed
              let diff = 100 - roundedPercentages.reduce((acc, cur) => acc + cur, 0);
              let inc = 0;

              // distribute remaining values, in order of decreasing decimal part
              const percentagesWithOriginalIdx = percentages
                .map((value, idx) => ({
                  value,
                  roundedValue: Math.floor(value),
                  remainder: value - Math.floor(value),
                  originalIdx: idx,
                }))
                .sort((a, b) => {
                  return b.remainder - a.remainder; // diff is distributed in order of remainder descending
                });

              while (diff > 0) {
                percentagesWithOriginalIdx[inc % data.datasets.length].roundedValue++;
                diff--;
                inc++;
              }

              const redistributedPercentages = percentagesWithOriginalIdx
                .sort((a, b) => a.originalIdx - b.originalIdx)
                .map((roundedValueWithOriginalIdx) => roundedValueWithOriginalIdx.roundedValue);

              const contextSum = data.datasets[context.datasetIndex].data[context.dataIndex];
              return [`${redistributedPercentages[context.datasetIndex]}%`, `(${contextSum})`];
            },
            font: {
              weight: 'bold',
              size: 11,
            },
            display: (context) => {
              return displayLabels(context);
            },
          },
          tooltip: {
            xAlign: 'left',
            yAlign: 'center',
            position: 'average',
            backgroundColor: 'rgba(97, 97, 97, .95)',
            padding: 10,
            caretPadding: 15,
            titleFont: {
              family: theme.typography.fontFamily,
              weight: theme.typography.fontWeightBold,
            },
            bodyFont: {
              family: theme.typography.fontFamily,
            },
            callbacks: {
              label: function (context) {
                const label = context?.dataset?.label ?? '';
                if (!label || !Number.isInteger(context.dataIndex)) {
                  return;
                }

                const numDataset = data.datasets[context.datasetIndex].data[context.dataIndex];

                let numTotal = 0;
                data.datasets.forEach((dataset) => {
                  numTotal += dataset?.data?.[context.dataIndex] ?? 0;
                });

                const percentage = numTotal > 0 ? ((numDataset / numTotal) * 100).toFixed(1) : 0;
                return `${label}: ${numDataset} (${percentage}%)`;
              },
            },
          },
          legend: { display: false },
        },
        onClick: handleOnClickBars,
      },
    };

    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [chartLabelMaxWidth, expectations, handleOnClickBars, selectedExpectation, isPptComponent]);

  return (
    <canvas id={componentId} height={height} width={width} ref={canvasRef} onClick={handleOnClickLabels} />
  );
}, memoAreEqual);

ExpectationsGraph.propTypes = {
  expectations: PropTypes.array.isRequired,
  selectedExpectation: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isPptComponent: PropTypes.bool,
  componentId: PropTypes.string,
};

export default ExpectationsGraph;
