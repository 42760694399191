import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { ReactComponent as SentimentMapIcon } from '../../../../assets/sentiment-map.svg';
import { ComponentType } from '../../../../modules/wevos/constants';
import { isClassicComparePages, isClassicSinglePage } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';
import theme from '../../../../theme';
import AssetSentimentMapCard from './AssetSentimentMapCard';

const styles = makeStyles((theme) => ({
  card: {
    borderRadius: '20px',
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    height: '100%',
  },
  sentimentMapIconGrid: {
    marginRight: theme.spacing(2),
  },
  sentimentMapIcon: {
    color: theme.palette.primary.main,
    width: '25px',
    height: '25px',
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
  },
  text: {
    color: theme.palette.primary.dark,
  },
  tileGrid: {
    minWidth: '400px',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  icon: {
    backgroundColor: grey[100],
    borderRadius: '50%',
    padding: '3px',
  },
  iconButton: {
    backgroundColor: grey[100],
  },
}));

const SentimentMapSummary = (props) => {
  const { wevo, page, selectedAssetNum, isLimitedReport } = props;
  const classes = styles();

  const isClassicSinglePageWevo = isClassicSinglePage(wevo);
  const isClassicComparePagesWevo = isClassicComparePages(wevo);
  const assets = useMemo(() => {
    if (isClassicSinglePageWevo) {
      return wevo?.pages;
    } else if (isClassicComparePagesWevo) {
      return Array(wevo?.pages?.[selectedAssetNum - 1]);
    } else {
      return wevo?.pages?.[0]?.steps;
    }
  }, [wevo?.pages, isClassicSinglePageWevo, isClassicComparePagesWevo, selectedAssetNum]);

  const sentimentMapPath =
    wevo?.useVersionedDiagnostics && isClassicComparePages(wevo)
      ? generatePath(Paths.reports.sentimentMapPage, {
          wevoId: wevo?.id,
          pageNum: selectedAssetNum,
        })
      : generatePath(Paths.reports.sentimentMap, {
          wevoId: wevo?.id,
        });

  if (!Boolean(wevo?.components?.[ComponentType.Heatmap])) {
    return (
      <Card className={classes.card} elevation={4}>
        <CardContent className={classes.cardContent}>
          <Box m="auto">
            <Typography variant="body2">This study does not include Sentiment Map</Typography>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.card} elevation={4}>
      <CardContent>
        <Grid container className={classes.noWrap}>
          <Grid item className={classes.sentimentMapIconGrid}>
            {isLimitedReport ? (
              <Box className={classes.icon}>
                <SentimentMapIcon fill={theme.palette.primary.main} className={classes.sentimentMapIcon} />
              </Box>
            ) : (
              <IconButton size="small" className={classes.iconButton}>
                <Link to={sentimentMapPath}>
                  <SentimentMapIcon fill={theme.palette.primary.main} className={classes.sentimentMapIcon} />
                </Link>
              </IconButton>
            )}
          </Grid>
          <Grid item>
            <Typography variant="h5">Sentiment Map</Typography>
            <Typography variant="caption" className={classes.text}>
              Areas on your experience that are most and least liked
            </Typography>
          </Grid>
        </Grid>
        <Box pt={2} />
        <Grid item container spacing={4} justifyContent="center">
          {assets?.map((asset, index) => {
            return (
              <Grid item key={index} className={classes.tileGrid}>
                <AssetSentimentMapCard
                  wevo={wevo}
                  page={page}
                  asset={asset}
                  index={index}
                  selectedAssetNum={selectedAssetNum}
                  isLimitedReport={isLimitedReport}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SentimentMapSummary;
