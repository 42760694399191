import { SHOW_NOTIFICATION, DISMISS_NOTIFICATION } from './actions';
import { variants } from './constants';

const initialState = {
  variant: variants.INFO,
  message: '',
  open: false
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_NOTIFICATION:
      return {
        ...state,
        ...action.payload,
        open: true
      };
    case DISMISS_NOTIFICATION:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};
