import { IconButton, InputBase, Paper } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import _debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';

/**
 * Defines the quote search component.
 */
const styles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    border: '1px solid #757575',
    borderRadius: '6px',
    color: '#757575',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 0,
    color: '#757575',
  },
}));

const QuoteSearch = (props) => {
  const classes = styles(props);
  const { onSearchChange, query } = props;

  const [searchQuery, setSearchQuery] = useState(query ?? '');

  const debouncedOnSearchChange = useMemo(
    () => _debounce((searchQuery) => onSearchChange(searchQuery), 400),
    [onSearchChange]
  );

  const onChange = (ev) => {
    const newSearchQuery = ev.target.value || '';
    setSearchQuery(newSearchQuery);
    debouncedOnSearchChange(newSearchQuery);
  };

  const clearSearch = () => {
    setSearchQuery('');
    debouncedOnSearchChange('');
  };

  let icon;
  if (searchQuery) {
    icon = (
      <IconButton
        color="primary"
        className={classes.iconButton}
        onClick={clearSearch}
        aria-label="clear search query"
        size="large">
        <ClearIcon />
      </IconButton>
    );
  } else {
    icon = <SearchIcon className={classes.iconButton} />;
  }

  return (
    <Paper component="form" className={classes.root} elevation={0}>
      <InputBase
        value={searchQuery}
        className={classes.input}
        placeholder="Find specific quotes"
        inputProps={{ 'aria-label': 'find specific quotes' }}
        onChange={onChange}
        onKeyPress={(ev) => {
          // Enter key will reload the page by default
          if (ev.key === 'Enter') {
            ev.preventDefault();
          }
        }}
      />
      {icon}
    </Paper>
  );
};

QuoteSearch.propTypes = {
  onSearchChange: PropTypes.func.isRequired,
};

export default QuoteSearch;
