import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import { Button, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useMemo, useState } from 'react';
import { DEFAULT_AUDIENCE_ATTRIBUTES } from '../../../modules/wevos/constants';
import EditQuotas from './EditQuotas';

const incomeMarks = [
  { value: 1, label: '0', intValue: 0 },
  { value: 2, label: '50k', intValue: 50 },
  { value: 3, label: '100k', intValue: 100 },
  { value: 4, label: '150k', intValue: 150 },
  { value: 5, label: '150k+', intValue: Infinity },
];

const Income = (props) => {
  const { income, setIncome, setIsIncomeValid } = props;
  const [incomeRange, setIncomeRange] = useState([1, 5]);
  const quotasTotal = useMemo(
    () => sumBy(income.segments, (segment) => segment.percentage),
    [income.segments]
  );

  const isAttributeSelected = useMemo(() => income?.isSelected, [income?.isSelected]);
  const isCustomizedQuota = useMemo(() => Boolean(income.isCustomizedQuota), [income.isCustomizedQuota]);

  useEffect(() => {
    if (income) {
      // update income range whenever income changes to ensure slider is always in sync with current attribute state
      let newIncomeRange = [Infinity, -Infinity];
      income.segments.forEach((segment, index) => {
        if (segment.isSelected) {
          if (index + 1 < newIncomeRange[0]) {
            newIncomeRange[0] = index + 1;
          }
          if (index + 2 > newIncomeRange[1]) {
            newIncomeRange[1] = index + 2;
          }
        }
      });
      setIncomeRange(newIncomeRange);
    } else {
      setIncomeRange([1, 5]);
    }
  }, [income]);

  useEffect(() => {
    setIsIncomeValid(quotasTotal === 100);
  }, [quotasTotal, setIsIncomeValid]);

  const addIncome = (ev) => {
    ev.preventDefault();
    setIncome(DEFAULT_AUDIENCE_ATTRIBUTES['income']);
    setIsIncomeValid(true); // set the parent's validation state when adding default attribute to true to prevent a conflict between when the attribute is added into the parent's state and when the validation state is updated
    setIncomeRange([1, 5]);
  };

  const handleIncomeChange = (ev, newIncomeRange) => {
    if (newIncomeRange[0] !== newIncomeRange[1]) {
      const lowerBound = incomeMarks.find((incomeMark) => incomeMark.value === newIncomeRange[0]).intValue;
      const upperBound = incomeMarks.find((incomeMark) => incomeMark.value === newIncomeRange[1]).intValue;

      let newSegments = [...DEFAULT_AUDIENCE_ATTRIBUTES['income'].segments];
      newSegments = newSegments.map((segment) => ({
        ...segment,
        isSelected: segment.lowerBound >= lowerBound && segment.upperBound <= upperBound,
      }));
      const basePercentage = Math.floor(100 / newSegments.filter((segment) => !!segment.isSelected).length);
      let remainder = 100 % basePercentage;
      newSegments = newSegments.map((segment) => {
        if (!segment.isSelected) {
          return { ...segment, percentage: 0 };
        } else {
          if (remainder > 0) {
            remainder -= 1;
            return { ...segment, percentage: basePercentage + 1 };
          }
          return {
            ...segment,
            percentage: basePercentage,
          };
        }
      });
      setIncome({ ...income, segments: newSegments });
      setIncomeRange(newIncomeRange);
    }
  };

  const updateQuotas = (ev) => {
    const newPercentage = Number(ev.target.value, 10) || 0;
    const segmentLabel = ev.target.name;
    if (newPercentage >= 5 && newPercentage <= 100) {
      setIncome((income) => {
        if (income.segments.filter((segment) => segment.isSelected).length === 2) {
          return {
            ...income,
            segments: income.segments.map((segment) => {
              if (segment.label === segmentLabel) {
                return { ...segment, percentage: newPercentage };
              } else {
                if (segment.isSelected) {
                  return { ...segment, percentage: Number(100 - newPercentage) };
                }
                return segment;
              }
            }),
          };
        } else {
          return {
            ...income,
            segments: income.segments.map((segment) => {
              if (segment.label === segmentLabel) {
                return { ...segment, percentage: newPercentage };
              }
              return segment;
            }),
          };
        }
      });
    }
  };

  const handleCustomizeQuotasClick = (ev) => {
    setIncome({
      ...income,
      isCustomizedQuota: ev.target.checked,
    });
  };

  return !!income ? (
    <Grid container justifyContent="space-between" alignItems={'center'} spacing={2}>
      <Grid item xs={6}>
        <Slider
          disabled={!isAttributeSelected}
          value={incomeRange}
          valueLabelDisplay="off"
          marks={incomeMarks}
          min={1}
          max={5}
          step={null}
          onChange={handleIncomeChange}
          size="small"
        />
      </Grid>
      <Grid item xs>
        <FormControlLabel
          disabled={!isAttributeSelected}
          sx={{ width: '100%', justifyContent: 'flex-end' }}
          control={<Checkbox checked={isCustomizedQuota} onChange={handleCustomizeQuotasClick} />}
          label={
            <Typography variant="caption" fontSize="11px">
              Customize my quotas
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <EditQuotas
          open={isCustomizedQuota}
          audience={income}
          quotasTotal={quotasTotal}
          handleQuotaChange={updateQuotas}
        />
      </Grid>
    </Grid>
  ) : (
    <Button
      sx={{ textTransform: 'capitalize', margin: (theme) => theme.spacing(2), alignSelf: 'flex-start' }}
      variant="contained"
      startIcon={<AddCircleOutlinedIcon />}
      onClick={addIncome}>
      {'Add Income'}
    </Button>
  );
};

export default Income;
