import Container from '@mui/material/Container';
import Dashboard from './Dashboard';

const DashboardPage = () => (
  <Container>
    <Dashboard />
  </Container>
);

export default DashboardPage;
