import _get from 'lodash/get';

export const FETCH_WEVOS = 'FETCH_WEVOS';
export const FETCH_WEVOS_SUCCESS = 'FETCH_WEVOS_SUCCESS';
export const FETCH_WEVOS_FAILED = 'FETCH_WEVOS_FAILED';

export const FETCH_WEVO = 'FETCH_WEVO';
export const FETCH_WEVO_SUCCESS = 'FETCH_WEVO_SUCCESS';
export const FETCH_WEVO_FAILED = 'FETCH_WEVO_FAILED';

export const ARCHIVE_WEVO = 'ARCHIVE_WEVO';
export const ARCHIVE_WEVO_SUCCESS = 'ARCHIVE_WEVO_SUCCESS';
export const ARCHIVE_WEVO_FAILED = 'ARCHIVE_WEVO_FAILED';

export const RESTORE_WEVO = 'RESTORE_WEVO';
export const RESTORE_WEVO_SUCCESS = 'RESTORE_WEVO_SUCCESS';
export const RESTORE_WEVO_FAILED = 'RESTORE_WEVO_FAILED';

export const DELETE_WEVO = 'DELETE_WEVO';
export const DELETE_WEVO_SUCCESS = 'DELETE_WEVO_SUCCESS';
export const DELETE_WEVO_FAILED = 'DELETE_WEVO_FAILED';

export const COPY_WEVO = 'COPY_WEVO';
export const COPY_WEVO_SUCCESS = 'COPY_WEVO_SUCCESS';
export const COPY_WEVO_FAILED = 'COPY_WEVO_FAILED';

export const CREATE_WEVO = 'CREATE_WEVO';
export const CREATE_WEVO_SUCCESS = 'CREATE_WEVO_SUCCESS';
export const CREATE_WEVO_FAILED = 'CREATE_WEVO_FAILED';

export const UPDATE_WEVO_DEFINITION = 'UPDATE_WEVO_DEFINITION';
export const UPDATE_WEVO_DEFINITION_SUCCESS = 'UPDATE_WEVO_DEFINITION_SUCCESS';
export const UPDATE_WEVO_DEFINITION_FAILED = 'UPDATE_WEVO_DEFINITION_FAILED';

export const LAUNCH_WEVO = 'LAUNCH_WEVO';
export const LAUNCH_WEVO_SUCCESS = 'LAUNCH_WEVO_SUCCESS';
export const LAUNCH_WEVO_FAILED = 'LAUNCH_WEVO_FAILED';

export const UPDATE_WEVO_AUDIENCE = 'UPDATE_WEVO_AUDIENCE';
export const UPDATE_WEVO_AUDIENCE_SUCCESS = 'UPDATE_WEVO_AUDIENCE_SUCCESS';
export const UPDATE_WEVO_AUDIENCE_FAILED = 'UPDATE_WEVO_AUDIENCE_FAILED';

export const UPLOAD_PAGE_IMAGE = 'UPLOAD_PAGE_IMAGE';
export const UPLOAD_PAGE_IMAGE_SUCCESS = 'UPLOAD_PAGE_IMAGE_SUCCESS';
export const UPLOAD_PAGE_IMAGE_FAILED = 'UPLOAD_PAGE_IMAGE_FAILED';

export const UPLOAD_STEP_IMAGE = 'UPLOAD_STEP_IMAGE';
export const UPLOAD_STEP_IMAGE_SUCCESS = 'UPLOAD_STEP_IMAGE_SUCCESS';
export const UPLOAD_STEP_IMAGE_FAILED = 'UPLOAD_STEP_IMAGE_FAILED';

export const IMAGE_UPLOAD_PROGRESS = 'IMAGE_UPLOAD_PROGRESS';

export const CREATE_PAGE = 'CREATE_PAGE';
export const CREATE_PAGE_SUCCESS = 'CREATE_PAGE_SUCCESS';
export const CREATE_PAGE_FAILED = 'CREATE_PAGE_FAILED';

export const DELETE_PAGE = 'DELETE_PAGE';
export const DELETE_PAGE_SUCCESS = 'DELETE_PAGE_SUCCESS';
export const DELETE_PAGE_FAILED = 'DELETE_PAGE_FAILED';

export const UPDATE_PAGE = 'UPDATE_PAGE';
export const UPDATE_PAGE_SUCCESS = 'UPDATE_PAGE_SUCCESS';
export const UPDATE_PAGE_FAILED = 'UPDATE_PAGE_FAILED';

export const CREATE_STEP = 'CREATE_STEP';
export const CREATE_STEP_SUCCESS = 'CREATE_STEP_SUCCESS';
export const CREATE_STEP_FAILED = 'CREATE_STEP_FAILED';

export const UPDATE_STEP = 'UPDATE_STEP';
export const UPDATE_STEP_SUCCESS = 'UPDATE_STEP_SUCCESS';
export const UPDATE_STEP_FAILED = 'UPDATE_STEP_FAILED';

export const DELETE_STEP = 'DELETE_STEP';
export const DELETE_STEP_SUCCESS = 'DELETE_STEP_SUCCESS';
export const DELETE_STEP_FAILED = 'DELETE_STEP_FAILED';

export const CLEAR_DRAFT = 'CLEAR_DRAFT';
export const SET_DRAFT = 'SET_DRAFT';

export const FETCH_DRAFT = 'FETCH_DRAFT';
export const FETCH_DRAFT_SUCCESS = 'FETCH_DRAFT_SUCCESS';
export const FETCH_DRAFT_FAILED = 'FETCH_DRAFT_FAILED';

export const SAVE_WEVO = 'SAVE_WEVO';
export const SAVE_WEVO_SUCCESS = 'SAVE_WEVO_SUCCESS';
export const SAVE_WEVO_FAILED = 'SAVE_WEVO_FAILED';

export const fetchWevos = () => {
  return {
    type: FETCH_WEVOS,
    payload: {},
  };
};

export const fetchWevosSuccess = (res) => {
  return {
    type: FETCH_WEVOS_SUCCESS,
    payload: res.data,
  };
};

export const fetchWevosFailed = (err) => {
  return {
    type: FETCH_WEVOS_FAILED,
    payload: {
      errorTitle: 'Load Dashboard Error',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Load dashboard failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const fetchWevo = (wevoId) => {
  return {
    type: FETCH_WEVO,
    payload: { wevoId },
  };
};

export const fetchWevoSuccess = (res) => {
  return {
    type: FETCH_WEVO_SUCCESS,
    payload: res.data,
  };
};

export const fetchWevoFailed = (err) => {
  return {
    type: FETCH_WEVO_FAILED,
    payload: {
      errorTitle: 'Error Fetching Wevo',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Wevo fetch failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const archiveWevo = (wevoId) => {
  return {
    type: ARCHIVE_WEVO,
    payload: {
      wevoId,
    },
  };
};

export const archiveWevoSuccess = (res) => {
  return {
    type: ARCHIVE_WEVO_SUCCESS,
    payload: {
      wevoId: _get(res, 'data.wevoId', ''),
    },
  };
};

export const archiveWevoFailed = (err) => {
  return {
    type: ARCHIVE_WEVO_FAILED,
    payload: {
      errorTitle: 'Error Archiving Wevo',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Wevo archive failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const restoreWevo = (wevoId) => {
  return {
    type: RESTORE_WEVO,
    payload: {
      wevoId,
    },
  };
};

export const restoreWevoSuccess = (res) => {
  return {
    type: RESTORE_WEVO_SUCCESS,
    payload: {
      wevoId: _get(res, 'data.wevoId', ''),
    },
  };
};

export const restoreWevoFailed = (err) => {
  return {
    type: RESTORE_WEVO_FAILED,
    payload: {
      errorTitle: 'Error Restoring Wevo',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Wevo restoration failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const deleteWevo = (wevoId) => {
  return {
    type: DELETE_WEVO,
    payload: {
      wevoId,
    },
  };
};

export const deleteWevoSuccess = (res) => {
  return {
    type: DELETE_WEVO_SUCCESS,
    payload: {
      wevoId: _get(res, 'data.wevoId', ''),
    },
  };
};

export const deleteWevoFailed = (err) => {
  return {
    type: DELETE_WEVO_FAILED,
    payload: {
      errorTitle: 'Error Deleting Wevo',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Wevo deletion failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const copyWevo = (wevoId) => {
  return {
    type: COPY_WEVO,
    payload: {
      wevoId,
    },
  };
};

export const copyWevoSuccess = (res) => {
  return {
    type: COPY_WEVO_SUCCESS,
    payload: {
      wevoId: _get(res, 'data.wevoId', ''),
    },
  };
};

export const copyWevoFailed = (err) => {
  return {
    type: COPY_WEVO_FAILED,
    payload: {
      errorTitle: 'Error Copying Wevo',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Wevo copying failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const createWevo = (createObj) => {
  return {
    type: CREATE_WEVO,
    payload: createObj,
  };
};

export const createWevoSuccess = (res) => {
  return {
    type: CREATE_WEVO_SUCCESS,
    payload: { wevo: res.data.wevo },
  };
};

export const createWevoFailed = (err) => {
  return {
    type: CREATE_WEVO_FAILED,
    payload: {
      errorTitle: 'Error Creating Wevo',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Wevo creation failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const updateWevoDefinition = (wevoObj) => {
  return {
    type: UPDATE_WEVO_DEFINITION,
    payload: wevoObj,
  };
};

export const updateWevoDefinitionSuccess = (res) => {
  return {
    type: UPDATE_WEVO_DEFINITION_SUCCESS,
    payload: { wevo: res.data.wevo },
  };
};

export const updateWevoDefinitionFailed = (err) => {
  return {
    type: UPDATE_WEVO_DEFINITION_FAILED,
    payload: {
      errorTitle: 'Error Updating Wevo Definition',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Wevo update failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const updateWevoAudience = (audienceObj) => {
  return { type: UPDATE_WEVO_AUDIENCE, payload: audienceObj };
};

export const updateWevoAudienceSuccess = (res) => {
  return {
    type: UPDATE_WEVO_AUDIENCE_SUCCESS,
    payload: { wevo: res.data.wevo },
  };
};

export const updateWevoAudienceFailed = (err) => {
  return {
    type: UPDATE_WEVO_AUDIENCE_FAILED,
    payload: {
      errorTitle: 'Error Updating Audience',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Audience update failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const uploadPageImage = (imageAndPageObj) => {
  return { type: UPLOAD_PAGE_IMAGE, payload: imageAndPageObj };
};
export const uploadPageImageSuccess = (response) => {
  return { type: UPLOAD_PAGE_IMAGE_SUCCESS, payload: response };
};
export const uploadPageImageFailed = () => {
  return { type: UPLOAD_PAGE_IMAGE_FAILED };
};

export const uploadStepImage = (imageAndStepObj) => {
  return { type: UPLOAD_STEP_IMAGE, payload: imageAndStepObj };
};
export const uploadStepImageSuccess = (response) => {
  return { type: UPLOAD_STEP_IMAGE_SUCCESS, payload: response };
};
export const uploadStepImageFailed = () => {
  return { type: UPLOAD_STEP_IMAGE_FAILED };
};

export const imageUploadProgress = (progressEvent) => {
  return { type: IMAGE_UPLOAD_PROGRESS, payload: progressEvent };
};

export const createPage = (imageResponseObj) => {
  return { type: CREATE_PAGE, payload: imageResponseObj };
};

export const createPageSuccess = (res) => {
  return {
    type: CREATE_PAGE_SUCCESS,
    payload: { page: res.data.page },
  };
};

export const createPageFailed = (err) => {
  return {
    type: CREATE_PAGE_FAILED,
    payload: {
      errorTitle: 'Error Creating Page',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to add page.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const deletePage = (wevoId, pageId) => {
  return {
    type: DELETE_PAGE,
    payload: {
      wevoId,
      pageId,
    },
  };
};

export const deletePageSuccess = (res) => {
  return {
    type: DELETE_PAGE_SUCCESS,
    payload: { pageId: res.data.pageId },
  };
};

export const deletePageFailed = (err) => {
  return {
    type: DELETE_PAGE_FAILED,
    payload: {
      errorTitle: 'Error Deleting Page',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to remove page.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const updatePage = (updatePageObj) => {
  return {
    type: UPDATE_PAGE,
    payload: updatePageObj,
  };
};

export const updatePageSuccess = (res) => {
  return {
    type: UPDATE_PAGE_SUCCESS,
    payload: {
      page: res.data.page,
    },
  };
};

export const updatePageFailed = (err) => {
  return {
    type: UPDATE_PAGE_FAILED,
    payload: {
      errorTitle: 'Error Updating Page',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to update page.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const createStep = (imageResponseObj) => {
  return { type: CREATE_STEP, payload: imageResponseObj };
};

export const createStepSuccess = (res) => {
  return {
    type: CREATE_STEP_SUCCESS,
    payload: { step: res.data.step },
  };
};

export const createStepFailed = (err) => {
  return {
    type: CREATE_STEP_FAILED,
    payload: {
      errorTitle: 'Error Creating Step',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to add step.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const updateStep = (updateStepObj) => {
  return {
    type: UPDATE_STEP,
    payload: updateStepObj,
  };
};

export const updateStepSuccess = (res) => {
  return {
    type: UPDATE_STEP_SUCCESS,
    payload: {
      step: res.data.step,
    },
  };
};

export const updateStepFailed = (err) => {
  return {
    type: UPDATE_STEP_FAILED,
    payload: {
      errorTitle: 'Error Updating Step',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to update step.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const deleteStep = (wevoId, stepId) => {
  return {
    type: DELETE_STEP,
    payload: {
      wevoId,
      stepId,
    },
  };
};

export const deleteStepSuccess = (res) => {
  return {
    type: DELETE_STEP_SUCCESS,
    payload: { stepId: res.data.stepId },
  };
};

export const deleteStepFailed = (err) => {
  return {
    type: DELETE_STEP_FAILED,
    payload: {
      errorTitle: 'Error Deleting Step',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to remove step.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const launchWevo = (wevoId) => {
  return {
    type: LAUNCH_WEVO,
    payload: { wevoId },
  };
};

export const launchWevoSuccess = (res) => {
  return {
    type: LAUNCH_WEVO_SUCCESS,
    payload: {},
  };
};

export const launchWevoFailed = (err) => {
  return {
    type: LAUNCH_WEVO_FAILED,
    payload: {
      errorTitle: 'Error Submitting Test',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to submit test.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const setDraft = (draft) => {
  return { type: SET_DRAFT, payload: { draft } };
};

export const clearDraft = () => {
  sessionStorage.removeItem('draft');
  sessionStorage.removeItem('isDefinitionValid');
  sessionStorage.removeItem('isTestGoalsValid');
  sessionStorage.removeItem('isAudienceValid');
  sessionStorage.removeItem('isPagesValid');
  sessionStorage.removeItem('isDetailsValid');
  return {
    type: CLEAR_DRAFT,
  };
};

export const fetchDraft = (wevoId) => {
  return {
    type: FETCH_DRAFT,
    payload: { wevoId },
  };
};

export const fetchDraftSuccess = (res) => {
  return {
    type: FETCH_DRAFT_SUCCESS,
    payload: { draft: res.data.wevo },
  };
};

export const fetchDraftFailed = (err) => {
  return {
    type: FETCH_DRAFT_FAILED,
    payload: {
      errorTitle: 'Error Retrieving Draft',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to retrieve draft.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const saveWevo = (wevoObj) => {
  return {
    type: SAVE_WEVO,
    payload: { ...wevoObj },
  };
};

export const saveWevoSuccess = (res) => {
  return {
    type: SAVE_WEVO_SUCCESS,
    payload: { wevo: res.data.wevo },
  };
};

export const saveWevoFailed = (err) => {
  return {
    type: SAVE_WEVO_FAILED,
    payload: {
      errorTitle: 'Error Saving Wevo',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Unable to save wevo.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};
