import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { WevoType } from '../../../modules/wevos/constants';
import { isClassicSinglePage } from '../../../modules/wevos/helpers';
import { Paths } from '../../../routes';
import SentimentMap from './components/SentimentMap';

const SentimentMapContainer = ({ wevo, handleShareButtonClick, isLimitedReport }) => {
  if (isClassicSinglePage(wevo)) {
    return (
      <Switch>
        <Route exact path={[Paths.reports.sentimentMap, Paths.reports.limitedSentimentMap]}>
          <SentimentMap
            wevo={wevo}
            page={wevo?.pages?.[0]}
            handleShareButtonClick={handleShareButtonClick}
            isLimitedReport={isLimitedReport}
          />
        </Route>
        {isLimitedReport ? (
          <Redirect to={generatePath(Paths.reports.limitedSentimentMap, { wevoId: wevo?.id })} />
        ) : (
          <Redirect to={generatePath(Paths.reports.sentimentMap, { wevoId: wevo?.id })} />
        )}
      </Switch>
    );
  } else {
    // Journeys with steps and Compare Pages Wevos
    const pageNums = wevo?.pages?.map((page) => page.pageNumber);
    const stepNums = [
      ...new Set(wevo?.pages?.flatMap((page) => (page?.steps ?? []).map((step) => step.stepNumber)) ?? []),
    ];
    const pagePath = `${Paths.reports.sentimentMapPage}(${pageNums.join('|')})`;
    const stepPath = `${pagePath}/steps/:stepNum(${stepNums.join('|')})`;
    const limitedSentimentMapPagePath = `${Paths.reports.limitedSentimentMapPage}(${pageNums.join('|')})`;
    const limitedSentimentMapPageStepPath = `${limitedSentimentMapPagePath}/steps/:stepNum(${stepNums.join(
      '|'
    )})`;

    return (
      <Switch>
        <Route
          exact
          path={[pagePath, limitedSentimentMapPagePath]}
          render={({ match: { params } }) => {
            const { pageNum } = params;
            return (
              <SentimentMap
                wevo={wevo}
                page={wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum))}
                showThumbnails={true}
                handleShareButtonClick={handleShareButtonClick}
                isLimitedReport={isLimitedReport}
              />
            );
          }}
        />
        <Route
          exact
          path={[stepPath, limitedSentimentMapPageStepPath]}
          render={({ match: { params } }) => {
            const { pageNum, stepNum } = params;

            const page = wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum));
            const step = page?.steps?.find((step) => Number(step.stepNumber) === Number(stepNum));

            return (
              <SentimentMap
                wevo={wevo}
                page={page}
                step={step}
                showThumbnails={true}
                handleShareButtonClick={handleShareButtonClick}
                isLimitedReport={isLimitedReport}
              />
            );
          }}
        />
        {isLimitedReport ? (
          <Redirect
            to={
              wevo?.type === WevoType.Classic
                ? generatePath(Paths.reports.limitedSentimentMapPage, {
                    wevoId: wevo?.id,
                    pageNum: pageNums[0],
                  })
                : generatePath(Paths.reports.limitedSentimentMapPageStep, {
                    wevoId: wevo?.id,
                    pageNum: pageNums[0],
                    stepNum: stepNums[0],
                  })
            }
          />
        ) : (
          <Redirect
            to={
              wevo?.type === WevoType.Classic
                ? generatePath(Paths.reports.sentimentMapPage, { wevoId: wevo?.id, pageNum: pageNums[0] })
                : generatePath(Paths.reports.sentimentMapPageStep, {
                    wevoId: wevo?.id,
                    pageNum: pageNums[0],
                    stepNum: stepNums[0],
                  })
            }
          />
        )}
      </Switch>
    );
  }
};

export default SentimentMapContainer;
