import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import {
  Badge,
  Box,
  Card,
  Grid,
  IconButton,
  inputClasses,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo, useState } from 'react';
import { validate as uuidValidate } from 'uuid';
import { useWevosTags } from '../../hooks/useTags';
import { TagsOption } from '../../modules/wevos/constants';
import theme from '../../theme';
import { Tag } from './Tags';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 8,
    borderRadius: 6,
    padding: '0 4px',
    backgroundColor: grey[300],
  },
}));

const SearchTextField = styled(TextField)(({ theme }) => ({
  [`& .${inputClasses.input}`]: {
    padding: theme.spacing(1),
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '7ch',
    },
  },
}));

// TagsGroup component
const TagsGroup = ({ tagsGroup, handleTagClick, selectedTag, theme }) => (
  <Grid item key={tagsGroup.tagId} sx={{ maxWidth: '140px' }} xs={12}>
    <Tag
      tooltipTitle={tagsGroup.name}
      tooltipPlacement={'left'}
      label={tagsGroup.name}
      tagId={tagsGroup.tagId}
      onClick={() => handleTagClick(tagsGroup.name, tagsGroup.wevoIds)}
      onDelete={() => handleTagClick(tagsGroup.name, tagsGroup.wevoIds)}
      countIcon={
        <StyledBadge
          badgeContent={tagsGroup.wevoIds.length}
          style={{ marginLeft: '20px', marginRight: '8px', color: grey[700] }}
        />
      }
      style={{
        backgroundColor: selectedTag === tagsGroup.name ? theme.palette.primary.main : grey[100],
        color: selectedTag === tagsGroup.name ? 'white' : '',
        '&:hover': {
          backgroundColor: selectedTag === tagsGroup.name ? '#1B4D7B' : '',
        },
        borderRadius: 2,
        maxWidth: '130px',
      }}
    />
  </Grid>
);

const TagsBox = (props) => {
  const { table, selectedTag, setSelectedTag } = props;

  const { data: wevosTagsGroups } = useWevosTags();

  const wevosTagsGroupsWithUUID = useMemo(() => {
    return wevosTagsGroups.filter((tag) => !uuidValidate(tag.name));
  }, [wevosTagsGroups]);

  const wevosTagsGroupsWithoutUUID = useMemo(() => {
    return wevosTagsGroups.filter((tag) => uuidValidate(tag.name));
  }, [wevosTagsGroups]);

  const [searchTags, setSearchTags] = useState(false);
  const [searchTagsInput, setSearchTagsInput] = useState('');

  const handleTagsSearchInput = (ev) => {
    setSearchTagsInput(ev.target.value);
  };

  const handleClearSearchClick = (ev) => {
    setSearchTagsInput('');
  };

  const handleTagClick = (tagName, filterValue) => {
    const tagsGroupIndex = wevosTagsGroups?.findIndex((tagsGroup) => tagsGroup.name === tagName);
    if (tagName === TagsOption.AllTags || tagsGroupIndex === -1) {
      setSelectedTag(TagsOption.AllTags);
    } else {
      setSelectedTag(tagName);
    }
    table.getColumn('id').setFilterValue(filterValue);
  };

  return (
    <Box
      sx={{
        width: '220px',
      }}>
      <Grid container spacing={1} justifyContent="space-between">
        <Grid item>
          <Typography variant="h4">Tags</Typography>
        </Grid>
        <Grid item xs={6} sx={{ textAlign: 'right', marginRight: -4, marginTop: 0.5 }}>
          {searchTags && (
            <SearchTextField
              autoFocus
              variant="standard"
              onChange={handleTagsSearchInput}
              value={searchTagsInput}
              inputProps={{ style: { fontSize: 14, height: 12 } }}
              InputProps={{
                placeholder: 'Search...',
                endAdornment: (
                  <IconButton
                    size="small"
                    style={{
                      visibility: Boolean(searchTagsInput) ? 'visible' : 'hidden',
                    }}
                    onClick={handleClearSearchClick}>
                    <ClearIcon sx={{ fontSize: '14px' }} />
                  </IconButton>
                ),
              }}
            />
          )}
        </Grid>
        <Grid item xs={2}>
          <IconButton
            onClick={() => {
              setSearchTags((searchTags) => !searchTags);
            }}
            size="small">
            <SearchIcon sx={{ color: grey[500], marginTop: -0.5 }} />
          </IconButton>
        </Grid>
        <Grid item xs={12} sx={{ minHeight: '225px', maxHeight: '70vh' }}>
          <Card
            elevation={0}
            sx={{ height: '100%', borderRadius: '10px', overflowY: 'auto', scrollbarWidth: 'thin' }}>
            {wevosTagsGroups?.length ? (
              <Grid container spacing={1} sx={{ padding: 1 }}>
                <Grid item sx={{ maxWidth: '140px' }} xs={12}>
                  <Tag
                    tooltipPlacement={'left'}
                    label={TagsOption.AllTags}
                    onClick={() => handleTagClick(TagsOption.AllTags, [])}
                    style={{
                      minWidth: '50px',
                      backgroundColor:
                        selectedTag === TagsOption.AllTags ? theme.palette.primary.main : grey[100],
                      color: selectedTag === TagsOption.AllTags ? 'white' : '',
                      '&:hover': {
                        backgroundColor: selectedTag === TagsOption.AllTags ? '#1B4D7B' : '',
                      },
                      borderRadius: 2,
                    }}
                  />
                </Grid>
                {wevosTagsGroupsWithUUID
                  ?.filter((group) => group.name.toLowerCase().includes(searchTagsInput.toLowerCase()))
                  ?.sort((a, b) => b.wevoIds.length - a.wevoIds.length)
                  ?.map((tagsGroup, index) => (
                    <TagsGroup
                      key={`${tagsGroup.name}-${index}`}
                      tagsGroup={tagsGroup}
                      handleTagClick={handleTagClick}
                      selectedTag={selectedTag}
                      theme={theme}
                    />
                  ))}
                {wevosTagsGroupsWithoutUUID
                  ?.filter((group) => group.name.toLowerCase().includes(searchTagsInput.toLowerCase()))
                  ?.sort((a, b) => b.wevoIds.length - a.wevoIds.length)
                  ?.map((tagsGroup, index) => (
                    <TagsGroup
                      key={`${tagsGroup.name}-${index}`}
                      tagsGroup={tagsGroup}
                      handleTagClick={handleTagClick}
                      selectedTag={selectedTag}
                      theme={theme}
                    />
                  ))}
              </Grid>
            ) : (
              <Grid container spacing={1} sx={{ padding: 2 }}>
                <Grid item xs={12} sx={{ marginBottom: 0.5 }}>
                  <Typography variant="h6" sx={{ color: theme.palette.primary.main }}>
                    <i>
                      <b>You don't have any tags</b>
                    </i>
                  </Typography>
                  <Typography variant="body2">Use tags to group tests.</Typography>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  <Typography variant="caption" sx={{ color: theme.palette.primary.main }}>
                    Tagging a test:
                  </Typography>
                  <Typography variant="body2">
                    Options <MoreVertIcon sx={{ fontSize: '14px', marginBottom: '-2px' }} />
                    {'>'} Add Tag
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

TagsBox.propTypes = {
  table: PropTypes.object,
  selectedTag: PropTypes.string,
  setSelectedTag: PropTypes.func,
};

export default TagsBox;
