import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Popper,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as HelpIcon } from '../../assets/help-icon.svg';
import { grey } from '@mui/material/colors';
import { useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

const useExplainerSectionStyles = makeStyles((theme) => ({
  accordionSummaryRoot: {
    minHeight: 15,
    '&.Mui-expanded': {
      minHeight: 15,
    },
    '&.Mui-expanded > .MuiAccordionSummary-content': {
      marginTop: 20,
      marginBottom: 10,
    },
  },
}));

function ExplainerSection({ title, content, isOpen = false, onChange }) {
  const classes = useExplainerSectionStyles();

  return (
    <Accordion sx={{ mb: 1 }} expanded={isOpen} onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{ root: classes.accordionSummaryRoot }}>
        <Typography fontSize={'0.85rem'}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box>{content}</Box>
      </AccordionDetails>
    </Accordion>
  );
}

ExplainerSection.propTypes = {
  title: PropTypes.string,
  content: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
};

function Explainer({ sections, sx = {} }) {
  const [expandedSectionId, setExpandedSectionId] = useState(null);
  return (
    <Box sx={sx}>
      {(sections ?? []).map((section) => {
        return (
          <ExplainerSection
            key={section.id}
            title={section.title}
            content={section.content}
            isOpen={section.id === expandedSectionId}
            onChange={() => {
              expandedSectionId === section.id ? setExpandedSectionId(null) : setExpandedSectionId(section.id);
            }}
          />
        );
      })}
    </Box>
  );
}

Explainer.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  sx: PropTypes.object,
};

export default function ExplainerPopup({ sections, initialIsOpen = false }) {
  const ref = useRef();

  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const [isHelpTooltipOpen, setIsHelpTooltipOpen] = useState(false);

  return (
    <>
      <Box ref={ref}>
        <Tooltip title="Help" open={isHelpTooltipOpen}>
          <Box
            sx={{
              height: { md: '2.0rem', sm: '1.5rem' },
              width: { md: '2.0rem', sm: '1.5rem' },
              fill: grey[600],
              cursor: 'pointer',
              display: isOpen || sections.length < 1 ? 'none' : '',
            }}>
            <HelpIcon
              onClick={() => setIsOpen(true)}
              onMouseEnter={() => setIsHelpTooltipOpen(true)}
              onMouseLeave={() => setIsHelpTooltipOpen(false)}
            />
          </Box>
        </Tooltip>
      </Box>
      {ref.current && (
        <Popper
          anchorEl={ref.current}
          sx={{
            maxWidth: { xs: '40%', sm: '30%' },
            offset: 2,
            minWidth: 350,
            maxHeight: '100vh',
            zIndex: 999,
            overflowY: 'scroll',
          }}
          open={sections.length > 0 && isOpen}>
          <Grid container px={4}>
            <Grid item xs={1} alignSelf="center">
              <Tooltip title="Collapse">
                <Box
                  sx={{
                    height: { md: '2.0rem', sm: '1.75rem' },
                    width: { md: '2.0rem', sm: '1.75rem' },
                    fill: grey[600],
                    cursor: 'pointer',
                  }}>
                  <HelpIcon onClick={() => setIsOpen(false)} />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={11}>
              <Explainer sections={sections} sx={{ ml: { sm: 2, xs: 0 } }} />
            </Grid>
          </Grid>
        </Popper>
      )}
    </>
  );
}

ExplainerPopup.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.object),
  initialIsOpen: PropTypes.bool,
};
