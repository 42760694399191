import { Button, Card, Grid, Typography, styled } from '@mui/material';
import _ from 'lodash';
import { useMemo } from 'react';
import { PULSE_SUBSCRIPTION_TYPES_TO_HEADER } from '../../modules/automated-insights/constants';

const SelectButton = styled(Button)(({ theme }) => ({
  height: '30px',
  width: '110px',
  borderRadius: '27px',
  border: '1px solid rgba(53, 157, 171, 1)',
  color: 'white',
}));

const SubscriptionPlanCard = (props) => {
  const { user, planPrice, onClick, hasSelfServiceBilling } = props;

  const isFreeTrial = user?.pulseSubscription?.isFreeTrial;
  const freeTrialRemainingDays =
    isFreeTrial && !user?.pulseSubscription?.isExpired ? user?.pulseSubscription?.timeUntilExpiration : 0;
  const cancelledAt = user?.pulseSubscription?.cancelledAt;

  const today = new Date();
  const expiresAt = user?.pulseSubscription?.expiresAt;
  const expirationDate = new Date(expiresAt);
  const expirationMonth = expirationDate.toLocaleString('default', { month: 'long' });
  const expirationDay = expirationDate.toLocaleString('default', { day: '2-digit' });

  const planName = useMemo(() => {
    if (!_.isNil(user?.pulseSubscription?.overrideRules?.planName)) {
      return user?.pulseSubscription?.overrideRules?.planName;
    }

    return PULSE_SUBSCRIPTION_TYPES_TO_HEADER[user?.pulseSubscription?.pulseSubscriptionTier?.type];
  }, [user]);

  const nthNumber = (number) => {
    if (number > 3 && number < 21) return `${number}th`;
    switch (number % 10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  };

  const getPhrase = () => {
    if (expiresAt && expirationDate < today) {
      return 'Select plan ';
    }

    if (cancelledAt && expirationDate >= today) {
      return 'Restart subscription';
    }

    return 'Upgrade';
  };

  const getButtonWidth = () => {
    if (cancelledAt && expirationDate >= today) {
      return '170px';
    }

    if (expiresAt && expirationDate < today) {
      return '110px';
    }
    return '';
  };

  const generateContent = () => {
    if (isFreeTrial) {
      return (
        <>
          <Grid item sx={{ justifySelf: 'flex-end' }}>
            <Grid container spacing={2} justifyContent={'end'} sx={{ alignItems: 'center' }}>
              <Grid item>
                <Typography sx={{ color: 'white', fontSize: '11px' }}>
                  Free trial - <b>{freeTrialRemainingDays}</b> left
                </Typography>
              </Grid>
              <Grid item sx={{ textAlign: 'end', marginRight: 2 }}>
                <SelectButton
                  variant="contained"
                  onClick={() => onClick()}
                  sx={{
                    textTransform: 'none',
                    border: '1px solid rgba(255, 255, 255, 0.31)',
                    color: 'white',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'white',
                      borderColor: 'white',
                      color: 'black',
                    },
                  }}>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      textDecoration: 'bold',
                    }}>
                    <b>Select plan</b>
                  </Typography>
                </SelectButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ alignSelf: 'start', marginTop: 2 }}>
            <Typography sx={{ color: 'white', fontSize: '0.85rem' }} component={'span'}>
              If you don’t choose a plan before your trial ends, you’ll be downgraded to the <b>Free</b> plan
              with fewer features.
            </Typography>
          </Grid>
        </>
      );
    }

    if (cancelledAt && expirationDate >= today) {
      return (
        <Grid item xs={12} sx={{ alignSelf: 'start', paddingTop: 0.8 }}>
          <Typography sx={{ color: 'white', fontSize: '13px' }} component={'span'}>
            {`Your subscription expires on ${expirationMonth} ${nthNumber(expirationDay)}`}
          </Typography>
        </Grid>
      );
    }

    if (expiresAt && expirationDate < today) {
      return (
        <Grid item xs={12} sx={{ alignSelf: 'start', paddingTop: 0.8 }}>
          <Typography sx={{ color: 'white', fontSize: '13px' }} component={'span'}>
            {`Your subscription expired on ${expirationMonth} ${nthNumber(expirationDay)}`}
          </Typography>
        </Grid>
      );
    }

    return (
      <Grid item xs={12} sx={{ alignSelf: 'start', paddingTop: 0.8 }}>
        <Typography sx={{ color: 'white', fontSize: '18px' }} component={'span'}>
          <b>${planPrice}</b>
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: 'white', fontSize: '13px', marginLeft: 1 }}
          component={'span'}>
          user / month
        </Typography>
      </Grid>
    );
  };

  return (
    <Card
      elevation={0}
      sx={{
        width: '100%',
        backgroundColor: 'rgba(38, 65, 81, 1)',
        borderRadius: '10px',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 1,
        paddingLeft: 3,
      }}>
      {_.isNull(user?.pulseSubscription) ? (
        <Grid container spacing={2} justifyContent={'center'} sx={{ alignItems: 'center' }} wrap="nowrap">
          <Grid item xs={12} sx={{ alignSelf: 'start', marginTop: 2, height: '50px' }}>
            <Typography sx={{ color: 'white', fontSize: '12px' }}>
              Contact us about your pulse subscription
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          spacing={2}
          justifyContent={'space-between'}
          sx={{ alignItems: 'center' }}
          wrap="nowrap">
          <Grid
            item
            sx={{
              height: {
                sm: 'initial',
                md: isFreeTrial ? '116px' : '100px',
              },
            }}>
            <Grid container justifyContent={'space-between'} sx={{ alignItems: 'center', height: '100%' }}>
              <Grid item xs={!isFreeTrial && 12} sx={{ alignSelf: 'end', paddingBottom: 0.8 }}>
                <Typography sx={{ color: 'white', fontWeight: 550, fontSize: '13px' }}>{planName}</Typography>
              </Grid>
              {generateContent()}
            </Grid>
          </Grid>
          {!isFreeTrial && hasSelfServiceBilling && !(user?.isBillingAdmin === false) && (
            <Grid item sx={{ paddingRight: 1.5 }}>
              <SelectButton
                variant="contained"
                onClick={() => onClick()}
                sx={{
                  textTransform: 'none',
                  border: '1px solid rgba(255, 255, 255, 0.31)',
                  color: 'white',
                  backgroundColor: 'transparent',
                  width: getButtonWidth(),
                  '&:hover': {
                    backgroundColor: 'white',
                    borderColor: 'white',
                    color: 'black',
                  },
                  minWidth: 75,
                }}>
                <Typography
                  sx={{
                    fontSize: '12px',
                    textDecoration: 'bold',
                  }}>
                  <b>{getPhrase()}</b>
                </Typography>
              </SelectButton>
            </Grid>
          )}
        </Grid>
      )}
    </Card>
  );
};

export default SubscriptionPlanCard;
