import { useMutation } from 'react-query';
import axios from '../api';

const generateComponents = async ({ wevoId, pageId, assetId, assetSource = 'figma', preflight = false }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/prototypes/${assetSource}/${assetId}/components?preflight=${preflight}`,
    method: 'PUT',
    data: {},
  });
  return data || { components: [] };
};

export default function usePrototypeGenerateComponents(options) {
  return useMutation(generateComponents, options);
}
