import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import escapeHtml from 'escape-html';
import _ from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Text } from 'slate';
import { CustomQuestionTypes, PptComponentDimensions } from '../../../modules/report/constants';
import { getExperienceSummaryElementId } from '../../../modules/report/helpers';
import { composePresentation } from '../../../modules/report/ppt';
import useFetchCustomQuestions from '../../intake/hooks/useFetchCustomQuestions';
import useKeyFindings from '../../reports/hooks/useKeyFindings';
import useUserKeyFindings from '../../reports/hooks/useUserKeyFindings';
import CustomQuestions from '../custom-questions/CustomQuestions';
import ExperienceSummary from '../dashboard/components/ExperienceSummary';
import Diagnostics from '../diagnostics/Diagnostics';
import Expectations from '../expectations/Expectations';
import { useFetchAllPagesDiagnosticsQuotes } from '../hooks/useDiagnostics';
import { useFetchAllPagesExpectationsQuotes } from '../hooks/useExpectations';
import { useFetchAllPagesStarredQuotes } from '../hooks/useStarredQuotes';

export const useStyles = makeStyles((theme) => ({
  pricingHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  closeIconGrid: {
    marginRight: theme.spacing(-1),
  },
  white: {
    color: 'white',
  },
  dialogContent: {
    backgroundColor: theme.palette.primary.main,
    height: '85px',
    overflowY: 'hidden',
    overflowX: 'hidden',
    '-ms-overflow-style': 'none', // IE, edge, and firefox
    'scrollbar-width': 'none', // IE, edge, and firefox
    '&::-webkit-scrollbar': {
      display: 'none', // chrome and safari
    },
  },
  link: {
    color: theme.palette.primary.main,
  },
}));

const DownloadReportToPowerPointModal = (props) => {
  const { wevo, handleClose, openDownloadReportToPPModal, setOpenDownloadReportToPP, assets, isDqs, isCds } =
    props;
  const classes = useStyles();

  const [downloadReport, setDownloadReport] = useState(false);
  const [starredQuotes, setStarredQuotes] = useState();
  const [diagnosticsQuotes, setDiagnosticsQuotes] = useState();
  const [expectationsQuotes, setExpectationsQuotes] = useState();
  const { data: groups, isSuccess } = useFetchCustomQuestions(wevo?.id);

  // Fetch key findings
  const { data: keyFindings = [] } = useKeyFindings({
    wevoId: wevo?.id,
    pageId: wevo.type === 'journey' ? assets?.[0]?.pageId : assets?.[0]?.id,
  });

  // Fetch team key findings
  const { data: userKeyFindings = {} } = useUserKeyFindings({
    wevoId: wevo?.id,
  });

  const serialize = useCallback((node) => {
    if (Text.isText(node)) {
      let string = escapeHtml(node.text);
      if (node.bold) {
        string = `<strong>${string}</strong>`;
      }
      return string;
    }

    if (!node?.children) {
      return '';
    }
    const children = node?.children?.map((n) => serialize(n)).join('');

    switch (node.type) {
      case 'ul':
        return `<ul>${children}</ul>`;
      case 'p':
        return `<p>${children}</p>`;
      case 'h3':
        return `<h3>${children}</h3>`;
      default:
        return children;
    }
  }, []);

  const serializedKeyFindings = useMemo(() => {
    // todo: support markdown and bullet key findings
    if (!_.isNil(keyFindings?.[0]?.summary) && keyFindings?.[0]?.type === 'slate') {
      return serialize({ children: keyFindings?.[0]?.summary });
    }
    return '';
  }, [keyFindings, serialize]);

  const serializedTeamKeyFindings = useMemo(() => {
    if (!_.isNil(userKeyFindings?.finding)) {
      return serialize({ children: userKeyFindings?.finding });
    }
    return '';
  }, [userKeyFindings, serialize]);

  const pageIds = wevo.pages?.map((page) => page.id);

  // Fetch Starred Quotes
  const fetchedStarredQuotesResult = useFetchAllPagesStarredQuotes({
    wevoId: wevo?.id,
    pageIds: pageIds,
  });

  const assetToStarredQuotes = useMemo(() => {
    if (fetchedStarredQuotesResult?.some((quotesRequest) => quotesRequest.isLoading)) {
      return null;
    }

    const assetList = wevo.pages;

    return fetchedStarredQuotesResult?.reduce((acc, quotesRequest, index) => {
      if (quotesRequest.isError) {
        return (acc[assetList[index]] = []);
      }

      acc[assetList[index].id] = quotesRequest.data.quotes;
      return acc;
    }, {});
  }, [fetchedStarredQuotesResult, wevo.pages]);

  if (
    _.isNil(starredQuotes) &&
    !fetchedStarredQuotesResult?.some((quotesRequest) => quotesRequest.isLoading) &&
    !_.isNil(assetToStarredQuotes)
  ) {
    setStarredQuotes(assetToStarredQuotes);
  }

  // Fetch Diagnostics Quotes
  const fetchedDiagnosticsQuotesResult = useFetchAllPagesDiagnosticsQuotes({
    wevoId: wevo?.id,
    pageIds: pageIds,
  });

  const assetToDiagnosticsQuotes = useMemo(() => {
    if (fetchedDiagnosticsQuotesResult?.some((quotesRequest) => quotesRequest.isLoading)) {
      return null;
    }

    const assetList = wevo.pages;

    return fetchedDiagnosticsQuotesResult?.reduce((acc, quotesRequest, index) => {
      if (quotesRequest.isError) {
        return (acc[assetList[index]] = []);
      }

      acc[assetList[index].id] = quotesRequest.data.quotes;
      return acc;
    }, {});
  }, [fetchedDiagnosticsQuotesResult, wevo.pages]);

  if (
    _.isNil(diagnosticsQuotes) &&
    !fetchedDiagnosticsQuotesResult?.some((quotesRequest) => quotesRequest.isLoading) &&
    !_.isNil(assetToDiagnosticsQuotes)
  ) {
    setDiagnosticsQuotes(assetToDiagnosticsQuotes);
  }

  // Fetch Expectations Quotes
  const fetchedExpectationsQuotesResult = useFetchAllPagesExpectationsQuotes({
    wevoId: wevo?.id,
    pageIds: pageIds,
  });

  const assetToExpectationsQuotes = useMemo(() => {
    if (fetchedExpectationsQuotesResult?.some((quotesRequest) => quotesRequest.isLoading)) {
      return null;
    }

    const assetList = wevo.pages;

    return fetchedExpectationsQuotesResult?.reduce((acc, quotesRequest, index) => {
      if (quotesRequest.isError) {
        return (acc[assetList[index]] = []);
      }

      acc[assetList[index].id] = {
        quotes: quotesRequest.data.quotes,
        expectationHopeQuestion: quotesRequest.data.expectationHopeQuestion,
        expectationFearQuestion: quotesRequest.data.expectationFearQuestion,
      };
      return acc;
    }, {});
  }, [fetchedExpectationsQuotesResult, wevo.pages]);

  if (
    _.isNil(expectationsQuotes) &&
    !fetchedExpectationsQuotesResult?.some((quotesRequest) => quotesRequest.isLoading) &&
    !_.isNil(assetToExpectationsQuotes)
  ) {
    setExpectationsQuotes(assetToExpectationsQuotes);
  }

  const handleDownloadAsPresentationClick = useCallback(async () => {
    setTimeout(async () => {
      // hack: wrap the compose presentation in a delay to give components enough time to mount and re-render if necessary,
      // e.g. to load benchmarks for diagnostics page
      const presentation = await composePresentation({
        wevo,
        groups,
        serializedKeyFindings,
        serializedTeamKeyFindings,
        starredQuotes,
        diagnosticsQuotes,
        expectationsQuotes,
      });
      presentation.writeFile({ fileName: `${wevo?.name}_Report.pptx` });
      setOpenDownloadReportToPP(false);
    }, 500);
  }, [
    wevo,
    groups,
    serializedKeyFindings,
    serializedTeamKeyFindings,
    starredQuotes,
    diagnosticsQuotes,
    expectationsQuotes,
    setOpenDownloadReportToPP,
  ]);

  useEffect(() => {
    if (
      openDownloadReportToPPModal &&
      isSuccess &&
      !_.isNil(starredQuotes) &&
      !_.isNil(diagnosticsQuotes) &&
      !_.isNil(expectationsQuotes)
    ) {
      setDownloadReport(true);
    }
  }, [
    openDownloadReportToPPModal,
    setDownloadReport,
    isSuccess,
    starredQuotes,
    diagnosticsQuotes,
    expectationsQuotes,
  ]);

  useEffect(() => {
    if (downloadReport) {
      handleDownloadAsPresentationClick();
    }
  }, [downloadReport, handleDownloadAsPresentationClick]);

  return (
    <Dialog maxWidth={'xs'} open={openDownloadReportToPPModal} onClose={handleClose}>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText component={'span'}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item xs={10}>
              <Typography variant="h5" className={classes.white}>
                Your report is being downloaded. Please do not close this window.
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <CircularProgress size={25} className={classes.white} />
            </Grid>
            <Grid item className={classes.closeIconGrid} xs={1}>
              <IconButton onClick={handleClose} size="large">
                <CloseIcon className={classes.white} />
              </IconButton>
            </Grid>
          </Grid>
          <Box pt={4} />
          {!isDqs &&
            !isCds &&
            wevo.pages?.map((page) => (
              <Fragment key={page.id}>
                <ExperienceSummary
                  wevo={wevo}
                  page={page}
                  width={PptComponentDimensions.ExperienceSummary.width}
                  height={PptComponentDimensions.ExperienceSummary.height}
                  componentId={getExperienceSummaryElementId(page.id)}
                />

                <Diagnostics
                  wevo={wevo}
                  page={page}
                  rightDrawerOpen={false}
                  setRightDrawerOpen={() => {}}
                  isPptComponent={true}
                  width={PptComponentDimensions.Diagnostics.width}
                  height={PptComponentDimensions.Diagnostics.height}
                  componentId={`diagnosticsGraph-${page.id}`}
                />
              </Fragment>
            ))}
          {wevo.pages?.map((page) => (
            <Expectations
              key={page.id}
              wevo={wevo}
              page={page}
              rightDrawerOpen={false}
              setRightDrawerOpen={() => {}}
              isPptComponent={true}
              width={PptComponentDimensions.Expectations.width}
              height={PptComponentDimensions.Expectations.height}
              componentId={`expectationsGraph-${page.id}`}
            />
          ))}
          {groups
            ?.filter((group) => group?.question?.type !== CustomQuestionTypes.Heatmap)
            ?.map((group) => {
              return group.scopes.map((scope, index) => {
                return (
                  <CustomQuestions
                    key={`${group?.groupId}-${index}`}
                    wevo={wevo}
                    group={group}
                    questionId={scope.questionId}
                    rightDrawerOpen={false}
                    setRightDrawerOpen={() => {}}
                    isPptComponent={true}
                    width={PptComponentDimensions[group?.question?.type]?.width}
                    height={PptComponentDimensions[group?.question?.type]?.height}
                    componentId={`customQuestionGraph-${group.groupId}-${scope.questionId}`}
                    fixedPageId={scope.wevoPageId}
                    fixedStepId={scope.stepId}
                  />
                );
              });
            })}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadReportToPowerPointModal;
