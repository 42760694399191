import { CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Typography } from '@mui/material';
import { CardActionArea } from '@mui/material';
import { Grid } from '@mui/material';
import { Card } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import classNames from 'classnames';

const useScopeCardStyles = makeStyles((theme) => ({
  card: {
    width: '150px',
  },
  cardSelected: {
    borderWidth: 2,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  media: {
    height: 120,
    width: '100%',
    objectFit: 'cover',
    objectPosition: '50% 0%',
  },
  pageName: {
    fontSize: 'small',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 600,
  },
  checkBox: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(3),
  },
}));

/**
 *
 * @param {{name: string, images: Array<object>, isSelected: boolean, isPickable: boolean, onChange: function}} props
 */
export default function ScopeCard({ name, images, isSelected, isPickable, onChange }) {
  const classes = useScopeCardStyles();

  const url = images?.mini?.url || images?.original?.url;
  let checkbox;
  if (isSelected) {
    checkbox = <CheckCircleOutlineIcon color="primary" />;
  } else {
    checkbox = <RadioButtonUncheckedIcon color="primary" />;
  }
  return (
    <Card
      elevation={2}
      className={classNames(classes.card, { [classes.cardSelected]: isSelected && isPickable })}>
      <CardActionArea onClick={isPickable ? onChange : null} disabled={!isPickable}>
        <img className={classes.media} src={url} alt={name} />
        <CardContent>
          <Grid container spacing={1} alignItems="center" justifyContent="space-around">
            <Grid item xs={12}>
              <Typography variant="h6" align="center" className={classes.pageName}>
                {name}
              </Typography>
            </Grid>
            {isPickable && <Grid item>{checkbox}</Grid>}
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
