import DeleteIcon from '@mui/icons-material/Delete';
import { Card, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { ReactComponent as FigmaIcon } from '../../../../../assets/FigmaIcon.svg';

const useStyles = makeStyles((theme) => ({
  card: {
    height: '40px',
  },
  figmaIconGrid: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(-1),
  },
  figmaIcon: {
    height: '25px',
    width: '25px',
  },
  urlGrid: {
    marginTop: '10px',
  },
  url: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '14px',
  },
  deleteIconGrid: {
    justifyItems: 'self-end',
    textAlign: 'right',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(-1),
  },
  deleteIcon: {
    color: 'grey',
  },
}));

const FigmaPrototypeCard = (props) => {
  const { url, figmaPrototype, deletePrototype } = props;
  const classes = useStyles();

  const handleDelete = () => {
    deletePrototype(figmaPrototype?.id);
  };

  return (
    <Card className={classes.card}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={2} className={classes.figmaIconGrid}>
          <FigmaIcon className={classes.figmaIcon} />
        </Grid>
        <Grid item xs={8} className={classes.urlGrid}>
          <Typography variant="body1" className={classes.url}>
            {url}
          </Typography>
        </Grid>
        <Grid item xs={2} className={classes.deleteIconGrid}>
          <IconButton onClick={handleDelete} size="small">
            <DeleteIcon className={classes.deleteIcon} />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

export default FigmaPrototypeCard;
