import { ArcElement, Chart, DoughnutController } from 'chart.js';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { memoAreEqual } from '../../../helpers';
import { getScoreHoverTextColor, getScoreTextColor } from '../../../modules/report/constants';

const styles = {
  benchmarkTooltip: {
    position: 'absolute',
    backgroundColor: '#7B7B7B',
    color: 'white',
    fontSize: '10px',
    fontWeight: 700,
    padding: '5px',
    borderRadius: '5px',
    pointerEvents: 'none',
    transition: 'opacity 0.3s',
    opacity: 0,
  },
};

Chart.register(DoughnutController, ArcElement);

const HalfDoughnutScoreGraph = React.memo((props) => {
  const {
    score,
    benchmark,
    backgroundColor,
    secondaryBackgroundColor,
    maxScore = 100,
    textColor = 'white',
    textFont = 'sans-serif',
    textFontSize = null,
    textFontWeight = '',
    hoverBackgroundColor = null,
    showBorders = false,
    showScale = false,
  } = props;
  const canvasRef = useRef(null);
  const chartRef = useRef(null);
  const tooltipRef = useRef(null);

  // Embed score text in the middle of the doughnut and scale on the sides of the doughnut
  const plugins = useMemo(
    () => ({
      beforeDraw: (chart) => {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;

        ctx.restore();
        ctx.fillStyle = textColor;
        const fontSize = textFontSize || (height / 80).toFixed(2);
        ctx.font = `${textFontWeight} ${fontSize}em ${textFont}`;

        ctx.textBaseline = 'middle';

        const datasetScore = score >= 0 ? chart.config.data?.datasets[0]?.data[0] ?? '?' : 'N/A';
        const text = datasetScore,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height * 0.75;

        ctx.fillText(text, textX, textY);

        if (showScale) {
          // height and width may need to be adjusted if the font size changes
          ctx.font = `500 .625em ${textFont}`;
          ctx.fillText(0, 12, height * 0.84);
          ctx.fillText(100, width - 27, height * 0.84);
        }

        ctx.save();
      },
      afterDraw: (chart) => {
        if (benchmark) {
          const { ctx, chartArea } = chart;

          // Draw benchmark
          const angle = Math.PI * (benchmark / maxScore);
          const radius = (chartArea.right - chartArea.left) / 2;

          const centerX = (chartArea.left + chartArea.right) / 2;
          const centerY = (chartArea.top + chartArea.bottom + radius) / 2;

          // Benchmark line will extend 5% on each end
          const innerRadius = radius * 0.6; // cutout percentage - 5%
          const outerRadius = radius * 1.05; // radius + 5%

          const startX = centerX - innerRadius * Math.cos(angle);
          const startY = centerY - innerRadius * Math.sin(angle);
          const endX = centerX - outerRadius * Math.cos(angle);
          const endY = centerY - outerRadius * Math.sin(angle);

          // Define the bounding box around the line and text
          // Add/subtract extra space for the text
          const box = {
            x: Math.min(startX, endX) - 10,
            y: Math.min(startY, endY) - 20,
            width: Math.abs(endX - startX) + 30,
            height: Math.abs(endY - startY) + 30,
          };

          ctx.save();
          ctx.strokeStyle = 'rgba(255, 255, 255, 0.53)';
          ctx.lineWidth = 2;
          ctx.beginPath();
          ctx.moveTo(startX, startY);
          ctx.lineTo(endX, endY);
          ctx.stroke();

          // Draw benchmark text
          const textX = centerX - radius * 1.15 * Math.cos(angle) - 7;
          const textY = centerY - radius * 1.15 * Math.sin(angle);

          ctx.fillStyle = 'white';
          ctx.font = `${textFontWeight} .8em ${textFont}`;
          ctx.textBaseline = 'middle';
          ctx.fillText(benchmark, textX, textY);
          ctx.restore();

          // Add event listeners for custom tooltip
          const canvas = ctx.canvas;
          const showTooltip = (event) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;

            // Check if the mouse is within the bounding box
            if (x >= box.x && x <= box.x + box.width && y >= box.y && y <= box.y + box.height) {
              tooltipRef.current.style.opacity = 1;
              tooltipRef.current.style.left = `${textX + 10}px`;
              tooltipRef.current.style.top = `${textY + 10}px`;
              tooltipRef.current.innerHTML = 'Benchmark';
            } else {
              tooltipRef.current.style.opacity = 0;
            }
          };

          canvas.addEventListener('mousemove', showTooltip);
          canvas.addEventListener('mouseout', () => {
            tooltipRef.current.style.opacity = 0;
          });
        }
      },
    }),
    [textColor, textFont, textFontSize, textFontWeight, showScale, score, maxScore, benchmark]
  );

  const data = useMemo(
    () => ({
      datasets: [
        {
          data:
            score >= 0
              ? [Math.round((score / maxScore) * 100), ((maxScore - score) / maxScore) * 100]
              : [0, 0],
          backgroundColor: [
            backgroundColor || getScoreTextColor((score / maxScore) * 100),
            secondaryBackgroundColor || 'rgb(216, 216, 216, 0.5)',
          ],
          hoverBackgroundColor: [
            hoverBackgroundColor || getScoreHoverTextColor((score / maxScore) * 100),
            secondaryBackgroundColor || 'rgb(216, 216, 216, 0.5)',
          ],
          borderWidth: showBorders ? 1 : 0,
          borderColor: showBorders && !!backgroundColor ? [backgroundColor, 'rgba(255, 255, 255, .2)'] : '',
        },
      ],
    }),
    [backgroundColor, secondaryBackgroundColor, hoverBackgroundColor, maxScore, score, showBorders]
  );

  const config = useMemo(
    () => ({
      type: 'doughnut',
      data,
      options: {
        animation: { duration: 0 },
        responsive: true,
        cutout: '65%',
        legend: {
          display: false,
        },
        plugins: {
          tooltip: { enabled: false },
        },
        rotation: -90,
        circumference: 180,
        layout: {
          padding: {
            left: benchmark ? 35 : 20,
            right: benchmark ? 35 : 20,
          },
        },
      },
      plugins: [plugins],
    }),
    [data, plugins, benchmark]
  );

  useEffect(() => {
    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [config, benchmark]);

  return (
    <div style={{ position: 'relative' }}>
      <canvas ref={canvasRef} />
      <div ref={tooltipRef} style={styles.benchmarkTooltip}></div>
    </div>
  );
}, memoAreEqual);

HalfDoughnutScoreGraph.propTypes = {
  score: PropTypes.number.isRequired,
};

export default HalfDoughnutScoreGraph;
