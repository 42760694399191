import makeStyles from '@mui/styles/makeStyles';
import { PropTypes } from 'prop-types';

const usePageLabelStyles = makeStyles((theme) => ({
  pageLabel: {
    height: '1.5rem',
    width: '1.5rem',
    borderRadius: '1.2rem',
    background: 'lightgrey',
    display: 'inline-flex',
  },
  text: {
    margin: 'auto',
    fontSize: '0.8rem',
    color: 'white',
    fontWeight: 'bold',
  },
}));

export default function PageLabel({ color, label, ...props }) {
  const classes = usePageLabelStyles();

  const style = color ? { background: color } : null;
  return (
    <span className={classes.pageLabel} style={style} {...props}>
      <span className={classes.text}>{label}</span>
    </span>
  );
}

PageLabel.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string,
};
