import _ from 'lodash';
import PinIcon from './PinIcon';
import ToothpasteTag from './ToothpasteTag';

import { Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Fragment } from 'react';
import { grey } from '@mui/material/colors';

const useBarSegmentItemStyles = makeStyles((theme) => ({
  barSegmentItems: {
    contain: 'strict',
    zIndex: 2,
    position: 'absolute',
    top: '0px',
    left: '0px',
    height: (props) => `${props?.height || 200}px`,
    width: '100%',
  },
  barSegmentItem: {
    position: 'absolute',
    top: (props) => (props?.height ? `${props.height / 2 + 10}px` : '110px'),
    left: '-15px',
    width: '30px',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    transform: (props) => `translateY(-50%) translateX(${props.position}px)`,
    visibility: (props) => !props.position && 'hidden',
    zIndex: (props) => (props.isSelected ? 6 : 5),
    display: (props) => (props.isSelected ? 'flex' : 'none'),
  },
  barSegmentItemMarker: {
    fontSize: '1.4rem',
    lineHeight: '1.4rem',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  barSegmentItemMarkerLabel: {
    color: '#bbbbbb',
  },
  barSegmentItemMarkerPin: {
    zIndex: -1,
    position: 'absolute',
    top: '0px',
    left: '50%',
    transform: 'translateX(-50%)',
  },
  barSegmentPageScore: {
    // note: this '40px' is the Pin Height and references to 40 should scale accordingly if it changes
    height: '40px',
    fontWeight: 'bold',
    color: grey[600],
    fontSize: '0.85rem',
  },
  barSegmentPageScoreTick: {
    position: 'absolute',
    top: '30px',
    height: '40px',
    width: '1px',
    border: '1px solid',
    borderColor: grey[300],
    zIndex: -999,
  },
  barSegmentIconBox: {
    height: '30px',
    minWidth: '1px',
    margin: '-1px 0px',
  },
  barSegmentItemIcon: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    position: 'absolute',
  },
  barSegmentItemIconCompetitor: {
    height: '15px',
    width: '15px',
  },
  barSegmentItemFlag: {
    position: 'relative',
    width: '60px',
    height: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  barSegmentItemLabel: {
    color: '#ffffff',
  },
  barSegmentItemFlagIcon: {
    zIndex: -1,
    position: 'absolute',
    top: '0px',
  },
}));

const SegmentedBarItem = ({ page, isSelected, isWinner, showFlag, showScore, ...rest }) => {
  const classes = useBarSegmentItemStyles({ isSelected, isWinner, ...rest });

  const renderPin = (label, color, isSelected) => {
    let strokeColor = color === 'auto' ? '#bbbbbb' : color;
    let unselectedFillColor = '#ffffff';
    return (
      <div className={classes.barSegmentItemMarker}>
        <div
          style={{
            color: isSelected ? unselectedFillColor : strokeColor,
          }}
          className={classes.barSegmentItemMarkerLabel}>
          <Typography variant="h5">{label}</Typography>
        </div>
        <div className={classes.barSegmentItemMarkerPin}>
          <PinIcon height="40" fill={isSelected ? strokeColor : unselectedFillColor} stroke={strokeColor} />
        </div>
      </div>
    );
  };

  const renderFlag = (page, isWinner) => {
    const flags = [];
    const { isBaseline } = page;
    const flag = (key, label, color) => (
      <ToothpasteTag
        key={key}
        color={color ? color : 'transparent'}
        label={label ? label : '-'}
        style={{ margin: '2px 2px', opacity: color && label ? 1 : 0 }}
      />
    );
    if (isBaseline) {
      flags.push(flag(`baseline-flag-${page.id}`, 'BASELINE', '#444C57'));
    }
    if (isWinner) {
      flags.push(flag(`winner-flag-${page.id}`, 'WINNER', '#4CAF50'));
    }
    while (flags.length < 2) {
      flags.push(flag(`blank-flag-${page.id}-${flags.length}`));
    }
    return <Fragment>{flags}</Fragment>;
  };

  return (
    <div className={classes.barSegmentItem}>
      {renderPin(page.label, page.color, isSelected)}
      <div className={classes.barSegmentIconBox}>
        {(page.isBaseline || isWinner) ?? (
          <div
            className={classes.barSegmentItemIcon}
            style={{
              height: '30px',
              width: '1px',
              zIndex: 4,
              color: '#444C57',
              backgroundColor: '#000000',
            }}>
            |
          </div>
        )}
        {page.isCompetitor ?? (
          <div
            className={classes.barSegmentItemIcon}
            style={{
              height: '15px',
              width: '15px',
              zIndex: 5,
              color: '#ffffff',
              backgroundColor: '#FF8F00',
            }}>
            C
          </div>
        )}
      </div>
      {showFlag && renderFlag(page, isWinner)}
      {showScore && (
        <>
          <div className={classes.barSegmentPageScore}>{Math.round(page?.rawScore || 0)}</div>
          <div className={classes.barSegmentPageScoreTick}></div>
        </>
      )}
    </div>
  );
};

export default function BarSegmentItems({ bins, pages, positions, showFlags, showScores, ...rest }) {
  const classes = useBarSegmentItemStyles(rest);
  return (
    <div className={classes.barSegmentItems}>
      {bins.map(({ pageIds }, segment) => {
        if (_.isNil(pageIds) || pageIds.length === 0) {
          return null;
        }
        return pages
          .filter((page) => pageIds.includes(page.id))
          .map((page) => {
            return (
              <SegmentedBarItem
                position={positions[`${segment}-${page.id}`] || 0}
                key={`segmented-bar-item(${segment}-${page.id})`}
                page={page}
                isWinner={page.isWinner}
                isSelected={page.isSelected}
                height={rest?.height}
                showFlag={showFlags}
                showScore={showScores}
              />
            );
          });
      })}
    </div>
  );
}
