import _get from 'lodash/get';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const fetchUserInfo = () => {
  return {
    type: FETCH_USER,
    payload: {},
  };
};

export const fetchUserSuccess = (res) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: res.data,
  };
};

export const fetchUserFailed = (err) => {
  return {
    type: FETCH_USER_FAILED,
    payload: {
      errorTitle: 'Load Profile Error',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Load profile failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const updateUser = ({ firstName: first_name, lastName: last_name, email: email_address, title }) => {
  return {
    type: UPDATE_USER,
    payload: {
      first_name,
      last_name,
      email_address,
      title,
    },
  };
};

export const updateUserSuccess = (res) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: res.data,
  };
};

export const updateUserFailed = (err) => {
  return {
    type: UPDATE_USER_FAILED,
    payload: {
      errorTitle: 'Profile Update Error',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Profile update failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};

export const changePassword = ({ currPassword, newPassword }) => {
  return {
    type: CHANGE_PASSWORD,
    payload: {
      currPassword,
      newPassword,
    },
  };
};

export const changePasswordSuccess = (res) => {
  return {
    type: CHANGE_PASSWORD_SUCCESS,
    payload: res.data,
  };
};

export const changePasswordFailed = (err) => {
  return {
    type: CHANGE_PASSWORD_FAILED,
    payload: {
      errorTitle: 'Password Change Error',
      errorMessage: _get(err, 'response.data.humanReadableMessage', 'Password change failed.'),
      errorCode: _get(err, 'response.status'),
    },
    error: true,
  };
};
