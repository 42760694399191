import { produce } from 'immer';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomScreenerMutationKeys } from '../../../modules/intake/constants';

const addCustomScreener = async ({ wevoId }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/filters`,
    method: 'POST',
  });
  return data?.filter;
};

export default function useAddCustomScreener() {
  const queryClient = useQueryClient();

  return useMutation(addCustomScreener, {
    mutationKey: CustomScreenerMutationKeys.addCustomScreener,
    onSuccess: (filter, variables) => {
      queryClient.setQueryData(['customScreeners', { wevoId: variables.wevoId }], (oldData) => {
        return produce(oldData, (draftState) => {
          draftState.push(filter);
        });
      });
    },
  });
}
