import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

const OptionButton = styled(Button)(({ theme }) => ({
  height: '40px',
  width: '175px',
  borderRadius: '27px',
  border: '1px solid #082739',
  color: 'rgba(9, 41, 60, 1)',
}));

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    justifyContent: 'center',
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FreeTierDialog = (props) => {
  const { open, approveFreeTier, closeCallback } = props;
  const classes = useStyles();

  const handleClose = () => {
    !!closeCallback && closeCallback();
  };

  const handleReset = () => {
    !!approveFreeTier && approveFreeTier();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="approve free tier confirmation dialog"
        aria-describedby="approve-free-tier-confirmation-description"
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: '20px',
            paddingTop: '16px',
            paddingBottom: '16px',
            width: '540px',
          },
        }}>
        <DialogTitle className={classes.title}>
          <Typography sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 600 }}>
            <b>
              Confirm your <span style={{ color: '#4BC0C8' }}>Free plan</span> selection
            </b>
          </Typography>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => closeCallback(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#000000',
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent id="approve-free-tier-description">
          <Grid container spacing={1} sx={{ width: '100%' }}>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '14px' }}>The Free Plan offers you:</Typography>
            </Grid>
            <Grid item container xs={12} sx={{ alignItems: 'center', marginTop: -0.5 }}>
              <Grid item sx={{ marginRight: 1.5, marginLeft: 1, marginTop: 0.8 }}>
                <Typography variant="body2" component={'span'} sx={{ fontSize: '14px' }}>
                  <DoneRoundedIcon sx={{ fontSize: '20px', color: '#4BC0C8' }} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography component={'span'} sx={{ fontSize: '14px' }}>
                  Basic Personas
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} sx={{ alignItems: 'center', marginTop: -1 }}>
              <Grid item sx={{ marginRight: 1.5, marginLeft: 1, marginTop: 1 }}>
                <Typography variant="body2" component={'span'} sx={{ fontSize: '14px' }}>
                  <DoneRoundedIcon sx={{ fontSize: '20px', color: '#4BC0C8' }} />
                </Typography>
              </Grid>
              <Grid item>
                <Typography component={'span'} sx={{ fontSize: '14px' }}>
                  Key Findings
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ fontSize: '14px' }}>
                Upgrading unlocks more advanced tools and deeper insights. Want{' '}
                <b>recommendations, persona customization</b> and <b>journey analysis</b>? Consider upgrading
                to a paid option for a more comprehensive experience.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'end', marginRight: 18 }}>
          <Button onClick={handleClose} sx={{ marginRight: 2 }}>
            <Typography sx={{ textTransform: 'none', fontSize: '14px', color: 'black' }}>
              Explore Plans
            </Typography>
          </Button>
          <OptionButton onClick={handleReset}>
            <Typography sx={{ textTransform: 'none', fontSize: '14px' }}>
              Confirm
              <span style={{ color: '#4BC0C8', fontWeight: 'bold' }}> Free Plan</span>
            </Typography>
          </OptionButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FreeTierDialog;
