import ClearIcon from '@mui/icons-material/Clear';
import { CssBaseline, IconButton, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { grey } from '@mui/material/colors';
import { SnackbarProvider } from 'notistack';
import React, { createRef, useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider, useSelector } from 'react-redux';
import { Redirect, Route, BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { AnalyticsProvider } from 'use-analytics';
import darkTheme from '../../darkTheme';
import { Environment } from '../../helpers';
import { HOTJAR_NAME, HOTJAR_SCRIPT, useScript } from '../../hooks/useScript';
import { getUserProfile } from '../../modules/user/selectors';
import { UserType } from '../../modules/wevos/constants';
import { SnackbarUtilsConfigurator } from '../../notifications';
import { Paths } from '../../routes';
import configureStore from '../../store';
import theme from '../../theme';
import ErrorDialog from '../../ui/ErrorDialog';
import Notification from '../../ui/Notification';
import analytics from '../analytics';
import InitializeConstants from '../app/InitializeConstants';
import AppBar from '../appBar';
import AppBarMC from '../appBar/AppBarMC';
import AutomatedInsights from '../automated-insights';
import Dashboard from '../dashboard';
import Accelerate from '../dashboard/Accelerate';
import { Start } from '../dashboard/Start';
import FAQsPage from '../dashboard/faq';
import CreateWevo from '../intake/create';
import EditWevo from '../intake/edit';
import LearnMoreCard from '../intake/edit/LearnMoreCard';
import LoginInterstitial from '../intake/edit/LoginInterstitial';
import SuccessPage from '../intake/edit/SuccessPage';
import UserlyticsLanding from '../intake/edit/UserlyticsLanding';
import ViewTest from '../intake/edit/ViewTest';
import Profile from '../profile';
import ChangePassword from '../profile/Password';
import WevoReport from '../reports';
import ProtectedRoute from '../router/ProtectedRoute';

const store = configureStore();
const queryClient = new QueryClient();

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const AppScripts = () => {
  const user = useSelector(getUserProfile);

  useScript(HOTJAR_NAME, null, HOTJAR_SCRIPT, {
    crossOrigin: 'anonymous',
    enabled:
      window?.ketchConsent?.analytics &&
      user &&
      user?.userType &&
      process.env.REACT_APP_ENV_FILE === Environment.Production
        ? user?.userType !== UserType.Wevo
        : false,
  });

  return <></>;
};

const App = () => {
  const [constantsPopulated, setConstantsPopulated] = useState(false);

  const notistackRef = createRef();
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  // sets 'FAQpage' to be true only when the "FAQ" page is opened
  const FAQpage = window.location.pathname === '/faq';

  const isAutomatedInsight = window.location.pathname.startsWith('/pulse');

  const appTheme = isAutomatedInsight ? darkTheme : theme;

  const getAppBar = () => {
    if (FAQpage) {
      return <AppBarMC />;
    } else if (isAutomatedInsight) {
      return <></>;
    } else return <AppBar />;
  };

  // Disable analytics if user has not consented via Ketch. Only call it if ketch is actually loaded.
  if (window?.ketchConsent) {
    if (!window?.ketchConsent?.analytics) {
      analytics.plugins.disable('segment');
    } else {
      analytics.plugins.enable('segment');
    }
  }

  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <InitializeConstants setConstantsPopulated={setConstantsPopulated} />
        {constantsPopulated && (
          <AnalyticsProvider instance={analytics}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={appTheme}>
                <CssBaseline />
                <SnackbarProvider
                  ref={notistackRef}
                  maxSnack={2}
                  autoHideDuration={3000}
                  action={(key) => (
                    <IconButton onClick={onClickDismiss(key)} size="large">
                      <ClearIcon fontSize="small" style={{ color: grey[200] }} />
                    </IconButton>
                  )}
                  dense>
                  <SnackbarUtilsConfigurator />
                  <AppScripts />
                  <div className="App" style={{ flexGrow: 1, paddingTop: '64px' }}>
                    <Router>
                      <ScrollToTop />
                      {getAppBar()}
                      <Switch>
                        <Route exact path="/accelerate" component={Accelerate} />
                        <ProtectedRoute exact path="/" component={Start} />
                        <ProtectedRoute exact path="/profile" component={Profile} />
                        <ProtectedRoute exact path="/profile/password" component={ChangePassword} />
                        <ProtectedRoute exact path="/start" component={LoginInterstitial} />
                        <ProtectedRoute path="/dashboard" component={Dashboard} />
                        <ProtectedRoute path="/success" component={SuccessPage} />
                        <ProtectedRoute path="/wevos/create" component={CreateWevo} />
                        <ProtectedRoute path="/landing" component={UserlyticsLanding} />
                        <ProtectedRoute path="/learnMore" component={LearnMoreCard} />
                        <ProtectedRoute exact path="/faq" component={FAQsPage} />
                        <Route path="/wevo/:wevoId/details" component={ViewTest} />
                        <ProtectedRoute path="/wevos/:wevoId/edit/:stepId?" component={EditWevo} />
                        <Route path={[Paths.automatedInsights.basePath]} component={AutomatedInsights} />
                        <Route
                          path={[Paths.reports.basePath, Paths.reports.limitedReportBasePath]}
                          component={WevoReport}
                        />
                        {/* if there's no match, redirect everything to /, which will redirect to dashboard */}
                        <Redirect to="/" />
                      </Switch>
                      <ErrorDialog />
                      <Notification />
                    </Router>
                  </div>
                </SnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
            <ReactQueryDevtools />
          </AnalyticsProvider>
        )}
      </QueryClientProvider>
    </Provider>
  );
};

export default App;
