import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { isEmpty as _isEmpty } from 'lodash';

const cookies = new Cookies();

const WEVO_USER_COOKIE = 'wevoUser';

/**
 * Sets user in cookies. This helps to identify the user across page loads.
 *
 * If user is unauthenticated, call this function with userId=null, teamId=null and it will
 * generate a unique UUID to set for those values. This helps track the user for 3rd party
 * analytics tools (e.g. pendo).
 *
 * This will also initialize any integrations (e.g. pendo) that require user identification.
 *
 * @param {object} user - User information (e.g. userId, emailAddress, firstName, lastName, teamId)
 */
export const setUser = (user = {}) => {
  const existingUser = getUserFromCookie();
  if (!user.userId) {
    user.userId = existingUser?.userId ?? uuidv4();
  }
  if (!user.teamId) {
    user.teamId = existingUser?.teamId ?? uuidv4();
  }

  setUserInCookie(user);

  window?.pendo?.initialize({
    visitor: { id: `${process.env.REACT_APP_ENV_FILE}-user-${user.userId}` },
    account: { id: `${process.env.REACT_APP_ENV_FILE}-team-${user.teamId}` },
  });
};

/**
 * Sets user info in cookies.
 *
 * @param {object} user
 */
const setUserInCookie = (user) => {
  cookies.set(WEVO_USER_COOKIE, user, { path: '/' });

  // Used by Ketch to identify the user
  if (user?.emailAddress) {
    cookies.set('wevoUserEmail', user.emailAddress, { path: '/' });
  }
};

/**
 * Gets user info from cookies.
 */
const getUserFromCookie = () => {
  try {
    return cookies.get(WEVO_USER_COOKIE);
  } catch (err) {
    cookies.remove(WEVO_USER_COOKIE);
  }
};

export const isAuthenticated = () => {
  return Boolean(cookies.get('authToken'));
};

export const redirectToLogin = () => {
  // Sets redirect URL in session storage so that we can redirect back to the current page after
  // login. This will be handled by the
  const redirectToPath = `${window.location.pathname}${window.location.search}${window.location.hash}`;
  sessionStorage.setItem('redirectToPath', redirectToPath);

  window.location.replace(`${process.env.REACT_APP_HELLO_HOST}/login`);
};

export const isUserLoaded = (user) => !_isEmpty(user) && user?.id;
