import { TextField, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReportQuoteDialog = (props) => {
  const {
    open,
    reportQuote,
    closeCallback,
    feedback,
    setFeedback,
    isQuoteFlagged,
    showSuccessDialog,
    quoteReportedByUser,
  } = props;

  const handleFeedbackChange = (value) => {
    setFeedback(value);
  };

  const handleClose = () => {
    closeCallback();
  };

  const handleSubmit = () => {
    !!reportQuote && reportQuote();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="report quote confirmation dialog"
        aria-describedby="report-quote-confirmation-description">
        <DialogTitle sx={{ marginTop: '8px', marginBottom: '-16px' }}>
          <Typography component="div" variant="h5" sx={{ textAlign: 'center' }}>
            Report quote
          </Typography>
        </DialogTitle>
        {showSuccessDialog && (
          <div>
            <DialogContent sx={{ minWidth: '440px', maxWidth: '440px', marginBottom: '16px' }}>
              <DialogContentText
                id="report-quote-success-dialog"
                sx={{ textAlign: 'center', fontSize: '15px', color: 'black' }}>
                Thank you for your feedback!
              </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ marginBottom: '12px', marginRight: '16px' }}>
              <Button
                variant="contained"
                size="large"
                onClick={handleClose}
                sx={{ borderRadius: '20px', height: '30px', width: '110px' }}>
                <Typography variant="h6">Close</Typography>
              </Button>
            </DialogActions>
          </div>
        )}
        {!showSuccessDialog && (
          <>
            <DialogContent sx={{ minWidth: '435px', maxWidth: '435px', marginTop: '16px' }}>
              <DialogContentText id="report-quote-confirmation-description">
                <span style={{ fontSize: '15px', color: 'black' }}>
                  {isQuoteFlagged
                    ? `This quote has been previously reported.`
                    : `By reporting this quote, you help us to improve the quality of our response data and respondent
              pool.`}
                </span>
              </DialogContentText>
              <TextField
                disabled={quoteReportedByUser}
                onChange={(ev) => handleFeedbackChange(ev.target.value)}
                type="text"
                value={feedback}
                fullWidth
                placeholder="Additional feedback (Optional)"
                multiline
                rows={2}
                sx={{
                  border: 'none',
                  '& fieldset': { border: 'none' },
                  marginTop: '24px',
                  backgroundColor: grey[100],
                  borderRadius: '10px',
                }}
              />
            </DialogContent>
            <DialogActions sx={{ marginBottom: '12px', marginRight: '16px' }}>
              <Button onClick={handleClose}>
                <Typography variant="h6">Cancel</Typography>
              </Button>
              <Button
                disabled={quoteReportedByUser}
                variant="contained"
                size="large"
                onClick={handleSubmit}
                sx={{ borderRadius: '20px', height: '30px', width: '110px' }}>
                <Typography variant="h6">Submit</Typography>
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

ReportQuoteDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  reportQuote: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  feedback: PropTypes.string,
  setFeedback: PropTypes.func.isRequired,
  isQuoteFlagged: PropTypes.bool,
  showSuccessDialog: PropTypes.bool.isRequired,
};

export default React.memo(ReportQuoteDialog);
