import { useMutation } from 'react-query';
import axios from '../../../api';

const generateInsights = async ({ sessionId, segmentId, goalId, directive, createDependencies }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/${sessionId}/task`,
    method: 'POST',
    data: { segmentId, goalId, directive, createDependencies },
  });

  return response?.data;
};

export default function useGenerateInsights(options) {
  return useMutation(generateInsights, options);
}
