import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  Link,
  Typography,
  styled,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { grey } from '@mui/material/colors';
import * as EmailValidator from 'email-validator';
import _ from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { ReactComponent as WevoPulseLogo } from '../../assets/wevo-pulse-logo.svg';
import { useAcceptToken, useFetchToken } from '../../hooks/useOnboardingUser';
import { getError } from '../../modules/error/selectors';
import * as UserActions from '../../modules/user/actions';
import { snackbar } from '../../notifications';
import { TrackEvent } from '../analytics';
import { StyledTextField } from '../automated-insights/ui/TextField';

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const OnboardingUserForm = (props) => {
  const { inputLabelStyles, inputBaseRootStyles, updateButtonStyles, token } = props;
  const { track } = useAnalytics();

  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search), [search]);

  const { data: userToken, isSuccess, isError, isLoading, isFetched } = useFetchToken(token, { retry: 1 });
  const { mutate: acceptToken } = useAcceptToken();

  const user = useMemo(() => {
    return userToken?.user || {};
  }, [userToken]);

  const company = useMemo(() => {
    return userToken?.token?.company || {};
  }, [userToken]);

  const [formData, setFormData] = useState({ ...user });
  const [isEmailValid, setEmailValid] = useState(false);
  const [isPasswordValid, setPasswordValid] = useState(false);
  const [isTermsOfServiceAcceptedValid, setTermsOfServiceAcceptedValid] = useState(false);

  const [isFormValid, setFormValid] = useState(false);

  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((showPassword) => !showPassword);
  };

  const visibilityLogo = showPassword ? (
    <VisibilityOffOutlinedIcon
      fontSize="small"
      onClick={togglePasswordVisibility}
      sx={{
        color: grey[400],
        cursor: 'pointer',
      }}
    />
  ) : (
    <VisibilityOutlinedIcon
      fontSize="small"
      onClick={togglePasswordVisibility}
      sx={{
        color: grey[400],
        cursor: 'pointer',
      }}
    />
  );

  useEffect(() => {
    if (isSuccess) {
      setFormData({
        firstName: String(user?.firstName),
        lastName: String(user?.lastName),
        emailAddress: String(user?.emailAddress),
        companyName: String(company?.name),
        password: '',
        termsOfServiceAccepted: false,
      });
    }
  }, [isSuccess, user, company]);

  useEffect(() => {
    setSubmitButtonDisabled(!isFormValid);
  }, [isFormValid]);

  useEffect(() => {
    setEmailValid(EmailValidator.validate(formData?.emailAddress));
  }, [formData?.emailAddress]);

  useEffect(() => {
    // Regex to validate password restrictions: Minimum 10 characters, At least 1 uppercase letter, At least 1 lowercase letter and At least 1 number
    setPasswordValid(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z#?!@$%^&*-\d]{10,}$/.test(formData?.password));
  }, [formData?.password]);

  useEffect(() => {
    setTermsOfServiceAcceptedValid(!!formData?.termsOfServiceAccepted);
  }, [formData?.termsOfServiceAccepted]);

  useEffect(() => {
    setFormValid(!!isEmailValid && !!isPasswordValid && !!isTermsOfServiceAcceptedValid);
  }, [isEmailValid, isPasswordValid, isTermsOfServiceAcceptedValid]);

  const handleFormInput = (ev) => {
    setFormData({ ...formData, [ev.target.name]: ev.target.value });
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    if (!isFormValid) {
      return false;
    } else {
      acceptToken(
        { formData: { ...formData, token } },
        {
          onSuccess: () => {
            track(TrackEvent.USER_ACCEPTED_PULSE_INVITE);

            // If user selected a URL to import, include that in the redirect
            let pulseUrl = `/pulse`;
            if (queryParams?.url) {
              pulseUrl = `${pulseUrl}?url=${queryParams?.url}`;
            }

            window.location.replace(pulseUrl);
          },
          onError: () => {
            snackbar.error(
              `An error occurred accepting this invite. Please try again or reach out to support@wevo.ai.`
            );
          },
        }
      );
    }
  };

  if (isError) {
    window.location.replace(`${process.env.REACT_APP_HELLO_HOST}`);
  }

  if (isLoading || _.isEmpty(formData) || (isFetched && _.isEmpty(user))) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box justifyContent="center" sx={{ marginBottom: -16 }}>
          <WevoPulseLogo width="320px" height="320px" style={{ marginLeft: -8 }} />
        </Box>
        <CircularProgress size={25} sx={{ position: 'absolute', top: '16px', right: '16px' }} />
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ marginBottom: -14, textAlign: 'center' }}>
        <WevoPulseLogo width="320px" height="320px" style={{ marginLeft: -8 }} />
      </Box>
      <Box sx={{ paddingLeft: 1 }}>
        <Typography variant="h4" sx={{ color: '#aaa' }}>
          Set Your Password
        </Typography>
      </Box>
      <StyledForm noValidate>
        <Grid container spacing={2}>
          {user?.firstName && (
            <Grid item xs={12} sm={6}>
              <StyledTextField
                id="fname"
                name="firstName"
                type="text"
                autoComplete="given-name"
                fullWidth
                label="First Name"
                value={formData.firstName}
                variant="outlined"
                inputLabelStyles={inputLabelStyles}
                inputBaseRootStyles={inputBaseRootStyles}
                onChange={handleFormInput}
              />
            </Grid>
          )}
          {user?.lastName && (
            <Grid item xs={12} sm={6}>
              <StyledTextField
                id="lname"
                name="lastName"
                type="text"
                autoComplete="family-name"
                fullWidth
                label="Last Name"
                value={formData.lastName}
                variant="outlined"
                inputLabelStyles={inputLabelStyles}
                inputBaseRootStyles={inputBaseRootStyles}
                onChange={handleFormInput}
              />
            </Grid>
          )}
          {company?.name && (
            <Grid item xs={12}>
              <StyledTextField
                id="companyName"
                name="companyName"
                type="text"
                autoComplete="company-name"
                fullWidth
                label="Company"
                value={formData.companyName}
                variant="outlined"
                inputLabelStyles={inputLabelStyles}
                inputBaseRootStyles={inputBaseRootStyles}
                onChange={handleFormInput}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <StyledTextField
              readOnly
              focused={false}
              id="emailAddress"
              name="emailAddress"
              type="email"
              autoComplete="email-address"
              label="Email"
              value={formData.emailAddress}
              fullWidth
              variant="outlined"
              inputLabelStyles={{ ...inputLabelStyles }}
              inputBaseRootStyles={{ ...inputBaseRootStyles, color: grey[500] }}
              InputProps={{ readOnly: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <StyledTextField
              required
              id="password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              autoComplete="password"
              label="Password"
              value={formData.password}
              fullWidth
              variant="outlined"
              inputLabelStyles={inputLabelStyles}
              inputBaseRootStyles={inputBaseRootStyles}
              onChange={handleFormInput}
              InputProps={{
                endAdornment: <InputAdornment position="end">{visibilityLogo}</InputAdornment>,
              }}
              error={!isPasswordValid}
              helperText={
                !isPasswordValid &&
                `Please enter a valid password that includes:
                A lowercase letter;
                An uppercase letter;
                A number;
                Minimum 10 characters`
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={12} my={2}>
          <FormControlLabel
            control={
              <Checkbox
                size="sm"
                name="termsOfServiceAccepted"
                onChange={(ev) => {
                  setFormData({ ...formData, [ev.target.name]: ev.target.checked });
                }}
              />
            }
            label={
              <Typography color="primary" variant="caption">
                I agree to the{' '}
                <Link
                  sx={{
                    color: 'primary',
                    textDecoration: 'none',
                    fontWeight: 'bold',
                    '&:hover': { textDecoration: 'underline' },
                  }}
                  href="https://wevo.ai/terms/"
                  target="_blank">
                  Terms & Conditions
                </Link>
              </Typography>
            }
          />
        </Grid>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          onClick={onSubmit}
          disabled={submitButtonDisabled}
          sx={{ ...updateButtonStyles, textTransform: 'none' }}>
          Get started!
        </Button>
      </StyledForm>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getError(state),
  };
};

const actionCreators = {
  fetchUserInfo: UserActions.fetchUserInfo,
  updateUser: UserActions.updateUser,
};

export default connect(mapStateToProps, actionCreators)(OnboardingUserForm);
