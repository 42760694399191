import { Box, Grid, Typography } from '@mui/material';
import ComingSoonIcon from '../../assets/comingSoonIcon.png';
import { AutomatedInsightSectionToLongDisplayName } from '../../modules/automated-insights/constants';

const ComingSoonPage = ({ selectedTab }) => {
  return (
    <Grid container sx={{ height: '100%', width: '100%' }}>
      <Box
        sx={{
          height: 'inherit',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
        }}>
        {
          <Box
            sx={{
              textAlign: 'center',
              mx: 8,
            }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', spacing: 2, my: 2 }}>
              <Typography variant="h6" sx={{ textAlign: 'center' }}>
                <img src={ComingSoonIcon} alt="selectable" style={{ width: '55px', height: 'auto' }} />
              </Typography>
            </Box>
            <Box sx={{ justifyContent: 'center', spacing: 2, my: 2 }}>
              <Typography
                component={'span'}
                fontSize={14}
                sx={{ flexWrap: 'nowrap', textTransform: 'capitalize' }}>
                <b>{AutomatedInsightSectionToLongDisplayName[selectedTab]} </b>
              </Typography>
              <Typography
                component={'span'}
                fontSize={14}
                sx={{ flexWrap: 'nowrap' }}>{` for Multipage Journeys!`}</Typography>
              <Typography fontSize={14} sx={{ mt: 1 }}>
                Stay tuned.
              </Typography>
            </Box>
          </Box>
        }
      </Box>
    </Grid>
  );
};

export default ComingSoonPage;
