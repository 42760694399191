import CloseIcon from '@mui/icons-material/Close';
import { Box, Drawer, Grid, IconButton, styled, Typography } from '@mui/material';
import { ReactComponent as ExamplesIcon } from '../../assets/gradient-devices.svg';
import { ReactComponent as LimitationsIcon } from '../../assets/gradient-exclamation.svg';
import { ReactComponent as CapabilitiesIcon } from '../../assets/gradient-lightbulb.svg';
import { AboutWevoPulse } from '../../modules/automated-insights/constants';

const paperMixin = (theme, width) => ({
  backgroundColor: theme.palette.background.default,
  width: '100%',
  maxWidth: width,
  borderRadius: '34px 0px 0px 34px',
  border: '1px solid rgba(217, 217, 217, 0.2)',
  padding: theme.spacing(2),
  msOverflowStyle: 'none', // IE and edge
  scrollbarWidth: 'none', // firefox
  '&::-webkit-scrollbar': {
    display: 'none', // chrome and safari
  },
});

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'width',
})(({ theme, width }) => ({
  width: '100%',
  maxWidth: width,
  flexShrink: 0,
  zIndex: 1500, // overlap app bar
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  '& .MuiDrawer-paper': { ...paperMixin(theme, width) },
}));

const CategoryIcons = {
  Examples: <ExamplesIcon />,
  Capabilities: <CapabilitiesIcon />,
  Limitations: <LimitationsIcon />,
};

const renderContent = () => {
  return (
    <>
      {AboutWevoPulse.map((category) => (
        <Grid item container key={category.heading} xs={4} justifyContent="center">
          <Box sx={{ height: 40, display: 'flex', alignItems: 'flex-end' }}>
            {CategoryIcons[category.heading]}
          </Box>
          <Box sx={{ width: '100%', mb: 2, textAlign: 'center' }}>
            <Typography variant="caption" color="text.secondary" sx={{ fontWeight: 500 }}>
              {category.heading}
            </Typography>
          </Box>
          <Grid container spacing={1}>
            {category.items.map((item) => (
              <Grid item key={item} xs={12}>
                <Box
                  sx={{
                    height: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingX: 1,
                    borderRadius: '10px',
                    textAlign: 'center',
                    whiteSpace: 'normal',
                    backgroundColor: (theme) => theme.palette.secondary.main,
                  }}>
                  <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.80)',
                      fontSize: 12,
                      fontWeight: 400,
                      lineHeight: 'normal',
                    }}>
                    {item}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

const InfoDrawer = ({ open, onClose }) => {
  return (
    <StyledDrawer anchor="right" open={open} onClose={() => onClose(false)} width={540}>
      <Box>
        <IconButton onClick={() => onClose(false)}>
          <CloseIcon fontSize="small" color="primary" />
        </IconButton>
      </Box>
      <Box sx={{ mt: 2, mb: 4, textAlign: 'center' }}>
        <Typography variant="h6" color="text.secondary">
          What can WEVO Pulse do?
        </Typography>
      </Box>
      <Grid container alignItems="flex-start" spacing={1}>
        {renderContent()}
      </Grid>
    </StyledDrawer>
  );
};

export default InfoDrawer;
