import styled from '@emotion/styled';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  tooltipClasses,
} from '@mui/material';
import { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { ReactComponent as PulseInfoIcon } from '../../../src/assets/pulse-info-icon.svg';
import LockSectionIcon from '../../assets/lockSectionIcon.png';
import { customRenderers } from '../../helpers';
import { AutomatedInsightSections } from '../../modules/automated-insights/constants';
import { getUserProfile } from '../../modules/user/selectors';
import { TrackEvent } from '../analytics';

const CustomTooltip = styled(({ className, maxWidth, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, maxWidth }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: maxWidth,
  },
}));

const styles = {
  button: {
    width: { xs: 170, sm: 'auto' },
    fontSize: '12px',
    borderRadius: '20px',
    textTransform: 'none',
  },
};

const SelectedTag = () => {
  return (
    <Box
      sx={{ paddingX: 1, paddingY: 0.5, border: '1px solid rgba(119, 141, 134, 0.62)', borderRadius: '5px' }}>
      <Typography fontSize={12} fontWeight={500} sx={{ color: '#778D86' }}>
        Selected
      </Typography>
    </Box>
  );
};

const Segment = ({
  segment,
  isOwner,
  hasSessionInsights,
  hasInsightsGenerating,
  onSelectSegment,
  onSectionChange,
  onInsightsRegeneration,
  onSelectSegmentToEdit,
  isGeneratedSegment = false,
  initialIsExpanded = false,
  showActions = true,
  canToggle = true,
  isSelected = false,
  maxHeightExpanded = 250,
}) => {
  const [isExpanded, setIsExpanded] = useState(initialIsExpanded);
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const user = useSelector(getUserProfile);

  // the segment is generated/proposed one but findings using it have not yet been generated
  const isGeneratedSegmentWithoutFindings = useMemo(() => {
    return isGeneratedSegment && !hasSessionInsights;
  }, [hasSessionInsights, isGeneratedSegment]);

  const isFreePlan = useMemo(() => {
    return user?.pulseSubscription?.isFreePlan;
  }, [user]);

  const pulseRunsBySeat = useMemo(() => {
    return user?.usage?.seatRunCount || 0;
  }, [user]);

  const pulseLimitPerSeat = useMemo(() => {
    return user?.usage?.seatRunLimit || 0;
  }, [user]);

  const reachedUsageLimit = pulseRunsBySeat >= pulseLimitPerSeat && user?.pulseSubscription?.isSeatBased;

  // if a user has the persona customization feature AND they haven't reached their usage limit, they can customize personas
  const canCustomizePersonas = user?.pulseSubscription?.features?.personaCustomization && !reachedUsageLimit;

  const handleOpen = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleToggleExpand = () => {
    if (canToggle) {
      setIsExpanded(!isExpanded);
    }
  };

  const handleViewFindingsClick = () => {
    onSelectSegment(segment);
    onSectionChange(AutomatedInsightSections.KeyFindings);
    handleClose();
  };

  const handleCustomizeAudienceClick = (ev) => {
    // if the feature is locked, this event handler will do nothing,
    // so stopPropagation is needed to prevent the collapsible parent container from collapsing
    ev.stopPropagation();
    if (!isFreePlan && canCustomizePersonas) {
      onSelectSegmentToEdit(segment);
    }
  };

  const handleCustomAudienceGenerateFindingsClick = (ev) => {
    // if the feature is locked, this event handler will do nothing,
    // so stopPropagation is needed to prevent the collapsible parent container from collapsing
    ev.stopPropagation();
    if (!isFreePlan && canCustomizePersonas) {
      onInsightsRegeneration({
        segment,
        trackEventName: TrackEvent.GENERATE_PULSE_FROM_CUSTOM_AUDIENCE_EXISTING_MY_AUDIENCES,
      });
      handleClose();
    }
  };

  return (
    <Box
      sx={{
        paddingBottom: 2,
        backgroundColor: '#FFFFFF',
        borderRadius: '16px',
        ...(isSelected
          ? {
              boxShadow: '0 0 0 2px #778D86',
              transition: 'box-shadow 0.3s',
            }
          : {
              boxShadow: '0 0 0 1px rgba(217, 217, 217, 0.83)',
            }),
      }}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        sx={{ borderTopLeftRadius: 'inherit', borderTopRightRadius: 'inherit', backgroundColor: '#F8F8F8' }}>
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <Typography
                variant="caption"
                component="div"
                sx={{ paddingLeft: 2, paddingY: 1, fontWeight: 500 }}>
                {segment?.name}
              </Typography>
            </Grid>
            {isGeneratedSegment && (
              <Grid item display="flex" alignItems="center">
                <Tooltip
                  title={'View the AI-suggested persona based on your initial asset analysis.'}
                  placement="right">
                  <PulseInfoIcon width="12px" />
                </Tooltip>
              </Grid>
            )}
            {isSelected && (
              <Grid item sx={{ marginLeft: 1 }}>
                <SelectedTag />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          {showActions && (
            <div>
              <IconButton
                id="options-button"
                aria-controls={isOpen ? 'button-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                disableRipple
                onClick={handleOpen}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="button-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                PaperProps={{
                  sx: {
                    boxShadow: 'none',
                    border: '1px solid rgba(160, 160, 160, 0.37)',
                    borderRadius: '11px',
                  },
                }}
                MenuListProps={{
                  'aria-labelledby': 'options-button',
                }}>
                {hasSessionInsights && (
                  <MenuItem onClick={handleViewFindingsClick} sx={{ fontSize: '12px' }}>
                    See findings
                  </MenuItem>
                )}
                {isOwner && !hasSessionInsights && !hasInsightsGenerating && (
                  <CustomTooltip
                    title={
                      !user?.pulseSubscription?.features?.personaCustomization
                        ? 'This feature is not included in your subscription.'
                        : reachedUsageLimit
                        ? 'You’ve reached your usage limit for the current plan.'
                        : ''
                    }
                    maxWidth={'200px'}>
                    <MenuItem onClick={handleCustomAudienceGenerateFindingsClick} sx={{ fontSize: '12px' }}>
                      Generate findings
                      {!canCustomizePersonas && (
                        <Grid item sx={{ marginLeft: 1, marginTop: '3px' }}>
                          <img
                            src={LockSectionIcon}
                            alt="selectable"
                            style={{ width: '9px', height: 'auto' }}
                          />
                        </Grid>
                      )}
                    </MenuItem>
                  </CustomTooltip>
                )}
                {isOwner && (
                  <CustomTooltip
                    title={
                      isFreePlan || !user?.pulseSubscription?.features?.personaCustomization
                        ? 'This feature is not included in your subscription.'
                        : reachedUsageLimit
                        ? 'You’ve reached your usage limit for the current plan.'
                        : ''
                    }
                    maxWidth={'200px'}>
                    <MenuItem onClick={handleCustomizeAudienceClick} sx={{ fontSize: '12px' }}>
                      Customize this persona
                      {(isFreePlan || !canCustomizePersonas) && (
                        <Grid item sx={{ marginLeft: 1, marginTop: '3px' }}>
                          <img
                            src={LockSectionIcon}
                            alt="selectable"
                            style={{ width: '9px', height: 'auto' }}
                          />
                        </Grid>
                      )}
                    </MenuItem>
                  </CustomTooltip>
                )}
              </Menu>
            </div>
          )}
        </Grid>
      </Grid>
      <Divider />
      <Box
        onClick={handleToggleExpand}
        sx={{
          position: 'relative',
          paddingY: 1,
          fontSize: '12px',
          lineHeight: '20px',
          maxHeight: isExpanded ? 'auto' : 70,
          overflowY: isExpanded ? 'auto' : 'hidden',
          cursor: canToggle ? 'pointer' : 'initial',
        }}>
        <Box
          sx={{
            maxHeight: isExpanded ? maxHeightExpanded : 70,
            paddingX: 2,
            overflowY: isExpanded ? 'auto' : 'hidden',
          }}>
          <>
            <ReactMarkdown components={customRenderers}>{segment?.description}</ReactMarkdown>
            {!isExpanded && (
              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: 40,
                  width: '100%',
                  background: 'linear-gradient(rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.9))',
                }}
              />
            )}
          </>
        </Box>
        {showActions && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'end',
              flexWrap: 'wrap',
              mt: 3,
              gap: 1,
              paddingX: 2,
            }}>
            {isOwner && (
              <CustomTooltip
                title={
                  isFreePlan || !user?.pulseSubscription?.features?.personaCustomization
                    ? 'This feature is not included in your subscription.'
                    : reachedUsageLimit
                    ? 'You’ve reached your usage limit for the current plan'
                    : ''
                }
                maxWidth={'200px'}>
                <Button
                  onClick={handleCustomizeAudienceClick}
                  endIcon={
                    (isFreePlan || !canCustomizePersonas) && (
                      <img src={LockSectionIcon} alt="selectable" style={{ width: '9px', height: 'auto' }} />
                    )
                  }
                  sx={{
                    ...styles.button,
                    minWidth: '175px',
                    backgroundColor: isGeneratedSegmentWithoutFindings ? '#778D86' : 'FFFFFF',
                    color: isGeneratedSegmentWithoutFindings ? 'FFFFFF' : '#778D86',
                    border: isGeneratedSegmentWithoutFindings ? '' : '1px solid #778D86',
                    '&:hover': {
                      backgroundColor: isGeneratedSegmentWithoutFindings ? '#778D86' : '',
                    },
                    '&.Mui-disabled': {
                      background: '#EAEAEA',
                      color: '#C0C0C0',
                      border: isGeneratedSegmentWithoutFindings ? '' : 'none',
                    },
                  }}>
                  Customize this persona
                </Button>
              </CustomTooltip>
            )}
            {hasSessionInsights && (
              <Button
                onClick={handleViewFindingsClick}
                sx={{
                  ...styles.button,
                  minWidth: '175px',
                  backgroundColor: '#778D86',
                  color: '#FFFFFF',
                  '&:hover': {
                    backgroundColor: '#778D86',
                  },
                  '&.Mui-disabled': {
                    background: '#EAEAEA',
                    color: '#C0C0C0',
                  },
                }}>
                See findings
              </Button>
            )}
            {isOwner && !hasSessionInsights && !hasInsightsGenerating && (
              <CustomTooltip
                title={
                  !user?.pulseSubscription?.features?.personaCustomization
                    ? 'This feature is not included in your subscription.'
                    : reachedUsageLimit
                    ? 'You’ve reached your usage limit for the current plan'
                    : ''
                }
                maxWidth={'200px'}>
                <Button
                  onClick={handleCustomAudienceGenerateFindingsClick}
                  endIcon={
                    !canCustomizePersonas && (
                      <img src={LockSectionIcon} alt="selectable" style={{ width: '9px', height: 'auto' }} />
                    )
                  }
                  sx={{
                    ...styles.button,
                    minWidth: '175px',
                    backgroundColor: canCustomizePersonas ? '#778D86' : '#FFFFFF',
                    color: canCustomizePersonas ? '#FFFFFF' : '#778D86',
                    border: canCustomizePersonas ? '' : '1px solid #778D86',
                    '&:hover': {
                      backgroundColor: canCustomizePersonas ? '#778D86' : '',
                    },
                    '&.Mui-disabled': {
                      background: '#EAEAEA',
                      color: '#C0C0C0',
                      border: canCustomizePersonas ? '' : 'none',
                    },
                  }}>
                  Generate Findings
                </Button>
              </CustomTooltip>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Segment;
