import { Grid, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { HIDDEN_SEGMENT_IDS_BY_ATTRIBUTE_ID } from '../../../modules/wevos/constants';
import EditQuotas from './EditQuotas';

const CheckboxAttribute = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { attribute, setAttribute, setIsAttributeValid } = props;

  const isAttributeSelected = useMemo(() => attribute?.isSelected, [attribute?.isSelected]);
  const isCustomizedQuota = useMemo(() => Boolean(attribute.isCustomizedQuota), [attribute.isCustomizedQuota]);
  const quotasTotal = useMemo(
    () => sumBy(attribute.segments, (segment) => segment.percentage),
    [attribute.segments]
  );

  useEffect(() => {
    setIsAttributeValid(quotasTotal === 100);
  }, [quotasTotal, setIsAttributeValid]);

  const handleAttributeChange = (ev) => {
    setAttribute((attribute) => {
      let newSegments = [...attribute.segments];
      let changedSegmentIndex = newSegments.findIndex(
        (segment) => Number(segment.id) === Number(ev.target.value)
      );
      newSegments[changedSegmentIndex] = {
        ...newSegments[changedSegmentIndex],
        isSelected: ev.target.checked,
      };
      if (!newSegments.every((segment) => segment.isSelected === false)) {
        // if this change would make it so that every segment is now unchecked, don't change anything
        const numSelectedSegments = newSegments.filter((segment) => !!segment.isSelected).length;
        const basePercentage = Math.floor(100 / numSelectedSegments);
        let remainder = 100 - basePercentage * numSelectedSegments;
        newSegments = newSegments.map((segment) => {
          if (!segment.isSelected) {
            return { ...segment, percentage: 0 };
          } else {
            if (remainder > 0) {
              remainder -= 1;
              return { ...segment, percentage: basePercentage + 1 };
            }
            return {
              ...segment,
              percentage: basePercentage,
            };
          }
        });
        return { ...attribute, segments: newSegments };
      } else {
        return attribute;
      }
    });
  };

  const updateQuotas = (ev) => {
    const newPercentage = Number(ev.target.value);
    const segmentLabel = ev.target.name;
    if (newPercentage >= 5 && newPercentage <= 100) {
      if (attribute.segments.filter((segment) => segment.isSelected).length === 2) {
        setAttribute({
          ...attribute,
          segments: attribute.segments.map((segment) => {
            if (segment.label === segmentLabel) {
              return { ...segment, percentage: Number(newPercentage) };
            } else {
              if (segment.isSelected) {
                return { ...segment, percentage: Number(100 - newPercentage) };
              }
              return segment;
            }
          }),
        });
      } else {
        setAttribute({
          ...attribute,
          segments: attribute.segments.map((segment) => {
            if (segment.label === segmentLabel) {
              return { ...segment, percentage: Number(newPercentage) };
            }
            return segment;
          }),
        });
      }
    }
  };

  const handleCustomizeQuotasClick = (ev) => {
    setAttribute({ ...attribute, isCustomizedQuota: ev.target.checked });
  };

  const hiddenSegments = HIDDEN_SEGMENT_IDS_BY_ATTRIBUTE_ID[attribute.id];
  let segmentsToShow = [];
  if (attribute) {
    segmentsToShow = !!hiddenSegments
      ? attribute.segments.filter((segment) => !hiddenSegments.includes(segment.id))
      : attribute.segments;
  }

  return (
    <Grid container justifyContent="space-between" alignItems={isSmallScreen ? 'center' : 'flex-end'}>
      <Grid item xs>
        <FormControl variant="standard" component="fieldset">
          <FormGroup row>
            {segmentsToShow.map((segment) => (
              <FormControlLabel
                disabled={!isAttributeSelected}
                sx={{ width: '100%' }}
                key={segment.id}
                control={
                  <Checkbox checked={segment.isSelected} onChange={handleAttributeChange} value={segment.id} />
                }
                label={segment.label}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item>
        <FormControlLabel
          disabled={!isAttributeSelected}
          sx={{ width: '100%' }}
          control={<Checkbox checked={isCustomizedQuota} onChange={handleCustomizeQuotasClick} />}
          label={
            <Typography variant="caption" fontSize="11px">
              Customize my quotas
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <EditQuotas
          open={isCustomizedQuota}
          audience={attribute}
          quotasTotal={quotasTotal}
          handleQuotaChange={updateQuotas}
        />
      </Grid>
    </Grid>
  );
};

CheckboxAttribute.propTypes = {
  attribute: PropTypes.object, // not marked as required because if it is marked as required, an error is logged to the console whenever the attribute is null
  setAttribute: PropTypes.func.isRequired,
  setIsAttributeValid: PropTypes.func.isRequired,
};

export default CheckboxAttribute;
