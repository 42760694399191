import { isNil } from 'lodash';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Typography } from '@mui/material';
import clamp from 'lodash/clamp';
import isFinite from 'lodash/isFinite';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import RegionSelect from 'react-region-select';
import { useResizeDetector } from 'react-resize-detector';
import ScrollLock from 'react-scroll-lock-component';
import {
  DEFAULT_HIGHLIGHT_SIZE,
  HEATMAP_BG,
  HEATMAP_CANVAS_CONTAINER,
} from '../../../modules/report/constants';
import ReactHeatmap from './ReactHeatmap';
import { Throbber } from './Throbber';

const HEATMAP_SHORT_ASPECT_RATIO_THRESHOLD = 1200.0 / 950.0;

const generateHeatmapStyles = (customSize, customHeight, customWidth, showThumbnails, objectFit) => {
  return {
    container: {
      overflow: customSize ? 'hidden' : 'auto',
      height: customSize ? '' : showThumbnails ? '85vh' : '90vh',
    },
    wrapper: {
      position: 'relative',
    },
    image: {
      width: customSize ? customWidth || '180px' : '100%',
      height: customSize ? customHeight || '180px' : '',
      objectFit: !isNil(objectFit) ? objectFit : customSize ? 'cover' : '',
      objectPosition: customSize ? '0% 0%' : '',
      zIndex: 0,
    },
    heatmap: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: customSize ? customWidth || '180px' : '100%',
      height: customSize ? customHeight || '180px' : '100%',
      overflow: 'hidden',
    },
    heatmapSelection: {
      position: 'absolute !important',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      '&.hideRegion [data-wrapper="wrapper"]': {
        display: 'none !important',
      },
      '&.showRegion [data-wrapper="wrapper"]': {
        border: 'none !important',
        outline: '2px dashed white !important',
        zIndex: '2 !important',
      },
      '& div [data-dir="ne"]': {
        background: 'white',
        border: 'none',
        height: '0.6rem !important',
        width: '0.6rem !important',
      },
      '& div [data-dir="nw"]': {
        background: 'white',
        border: 'none',
        height: '0.6rem !important',
        width: '0.6rem !important',
      },
      '& div [data-dir="sw"]': {
        background: 'white',
        border: 'none',
        height: '0.6rem !important',
        width: '0.6rem !important',
      },
      '& div [data-dir="se"]': {
        background: 'white',
        border: 'none',
        height: '0.6rem !important',
        width: '0.6rem !important',
      },
    },
    heatmapSelectionOverlay: {
      position: 'absolute',
      zIndex: -1,
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'visible',
      border: 'solid rgba(0, 0, 0, 0.5)',
    },
    heatmapSelectionRemove: {
      color: 'white',
      background: 'rgba(0, 0, 0, 0.5)',
      position: 'absolute',
      zIndex: 3,
      cursor: 'pointer',
      borderRadius: '1px',
      /* center the div to its parent container */
      left: '50%' /* position the left edge of the element at the middle of the parent */,
      transform: 'translateX(-50%)' /* move the element to half its size */,
      width: 'fit-content',
      height: 'fit-content',
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
      padding: '8px',
    },
    removeRegionText: {
      zIndex: -1,
      fontSize: '10px',
      whiteSpace: 'nowrap',
    },
    removeRegionIcon: {
      fontSize: '14px',
    },
  };
};

export const HeatmapPointRadius = {
  Large: 90,
  Normal: 70,
  Small: 50,
};

const FIXED_BOX_SIZE_IN_PX = 100;

const pointProps = {
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
};

const dimensionProps = {
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

const Heatmap = (props) => {
  let resizeTimer = useRef();
  const highlightedPointRef = useRef();

  // 'short' images have a height that is less than the width within a margin
  // they require slightly different handling to display correctly compared to typical images where height is much greater than width
  const isShortImage = props?.image?.width / props?.image?.height > HEATMAP_SHORT_ASPECT_RATIO_THRESHOLD;
  const requiresHeightCorrection = isShortImage && props?.customSize;
  const objectFit = requiresHeightCorrection ? 'fill' : null;

  const styles = generateHeatmapStyles(
    props.customSize,
    props.customHeight,
    props.customWidth,
    props.showThumbnails,
    objectFit
  );

  const [imageLoaded, setImageLoaded] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(null);
  const [heightScaleCorrectionFactor, setHeightScaleCorrectionFactor] = useState(null); // only for short images

  const [isResizing, setIsResizing] = useState(false);

  const { width, height, ref } = useResizeDetector();
  const containerRef = useRef();
  const imageRef = useRef();

  const scaledRadius = useMemo(() => {
    if (!scaleFactor) {
      return;
    }

    return Math.round(props.radius * scaleFactor);
  }, [props.radius, scaleFactor]);

  const scaledPoints = useMemo(() => {
    if (!props.data || !scaleFactor) {
      return;
    }

    const yScaleFactor = requiresHeightCorrection ? heightScaleCorrectionFactor : scaleFactor;

    return props.data.map((unscaledPoint) => {
      return {
        x: Math.round(unscaledPoint.x * scaleFactor),
        y: Math.round(unscaledPoint.y * yScaleFactor),
        value: 1.3,
      };
    });
  }, [props.data, scaleFactor, heightScaleCorrectionFactor, requiresHeightCorrection]);

  useEffect(() => {
    // This method is called when the component and its children are finished rendering. At this point we will have
    // references to the image & container elements so we can force the browser to scroll if necessary.
    scrollHighlightedPointIntoView();
  });

  const scrollHighlightedPointIntoView = () => {
    // The heatmap technically supports multiple highlighted points even though we only ever highlight one at a
    // time. We'll only try to scroll automatically if there's only 1 point selected.
    if (!props.highlightedPoint || !highlightedPointRef?.current) {
      return;
    }

    const pointElement = highlightedPointRef.current;

    const pointTop = pointElement.offsetTop;
    const pointBottom = pointTop + pointElement.offsetHeight;

    const viewportTop = containerRef?.current?.scrollTop;
    const viewportBottom = viewportTop + containerRef?.current?.clientHeight;

    // If the point is not in the viewport, we try to center it in the viewport
    if (pointTop < viewportTop || pointBottom > viewportBottom) {
      const elementCenter = pointTop + pointElement.offsetHeight / 2;
      const containerCenter = containerRef?.current?.clientHeight / 2;

      // Enable smooth scrolling while we auto-scroll and revert to the configured value when we're done. Smooth
      // scrolling looks good when we're driving the scrolling, but it's awkward when the user's doing it.
      const scrollBehavior = containerRef?.current?.style.scrollBehavior;
      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollTop = elementCenter - containerCenter;
      containerRef.current.style.scrollBehavior = scrollBehavior;
    }
  };

  const computeScaleFactor = useCallback(() => {
    if (imageRef?.current) {
      const scaleFactor = imageRef?.current.clientWidth / props?.image?.width;
      setScaleFactor(scaleFactor);
    }
  }, [props?.image?.width]);

  const computeHeightScaleCorrectionFactor = useCallback(() => {
    if (imageRef?.current) {
      const scaleFactor = imageRef?.current.clientHeight / props?.image?.height;
      setHeightScaleCorrectionFactor(scaleFactor);
    }
  }, [props?.image?.height]);

  const handleImageLoad = () => {
    setImageLoaded(true);
    computeScaleFactor();
    computeHeightScaleCorrectionFactor();
  };

  const handleWindowResize = useCallback(() => {
    // The heatmap only sets its width & height properties when it's mounted. If we want to update those values,
    // we have to unmount the heatmap and remount it. That means we need to render this component without the
    // heatmap and then render again with it. That's what this code aims to do.
    setIsResizing(true);
    if (resizeTimer.current) {
      clearTimeout(resizeTimer.current);
      resizeTimer.current = null;
    }
    resizeTimer.current = setTimeout(() => setIsResizing(false), 250);
    computeScaleFactor();
    computeHeightScaleCorrectionFactor();
  }, [computeScaleFactor, computeHeightScaleCorrectionFactor]);

  useEffect(() => {
    handleWindowResize();
  }, [height, width, handleWindowResize]);

  const renderHeatmap = useMemo(() => {
    // Caller disabled the heatmap overlay
    if (!props.showOverlay) {
      return null;
    }

    // We don't have enough information to size the heatmap canvas yet
    if (isResizing || !imageLoaded || !scaledPoints || !scaledRadius) {
      return null;
    }

    return <ReactHeatmap data={scaledPoints} radius={scaledRadius} height={imageRef?.current.clientHeight} />;
  }, [props.showOverlay, isResizing, imageLoaded, scaledPoints, scaledRadius]);

  const renderHighlightedPoint = () => {
    if (!props.highlightedPoint) {
      return null;
    }

    // We don't have enough information to scale the highlighted point(s) yet
    if (isResizing || !imageLoaded || scaleFactor === null) {
      return null;
    }

    const highlightSize = props.highlightSize;

    const colors = props.showOverlay
      ? {
          centerColor: '#1da4ff',
          centerStroke: 0,
          centerStrokeColor: 'none',
          haloColor: '#ffffff',
          haloStroke: 3,
          pulseColor: '#ffffff',
        }
      : {
          centerColor: '#1da4ff',
          centerStroke: 3,
          centerStrokeColor: '#ffffff',
          haloColor: '#1da4ff',
          haloStroke: 3,
          pulseColor: '#1da4ff',
        };

    const left = Math.round(props.highlightedPoint.x * scaleFactor - highlightSize / 2);
    const top = Math.round(props.highlightedPoint.y * scaleFactor - highlightSize / 2);
    return (
      <div
        key={`throbber(${left}, ${top})`}
        style={{
          position: 'absolute',
          height: highlightSize,
          width: highlightSize,
          left: left,
          top: top,
        }}
        ref={highlightedPointRef}>
        <Throbber {...colors} />
      </div>
    );
  };

  const shouldDisplayRegion = () => {
    return !!imageRef?.current && !!props.selectedRegion;
  };

  const renderRegion = ({ isChanging }) => {
    if (isChanging || !shouldDisplayRegion()) {
      return null;
    }

    const region = props.selectedRegion;
    const { clientHeight } = imageRef?.current;
    const bottomY = ((region.y + region.height) * clientHeight) / 100;
    const onBottomOfPage = clientHeight - bottomY < 35;
    return (
      <div>
        <div
          onClick={() => props.onSelectRegionChange(null)}
          style={{
            ...styles.heatmapSelectionRemove,
            ...(onBottomOfPage ? { top: '-3.2rem' } : { bottom: '-3.1rem' }),
          }}>
          <CloseIcon sx={styles.removeRegionIcon} />
          <Typography sx={styles.removeRegionText} component="p">
            Remove
          </Typography>
        </div>
      </div>
    );
  };

  const renderRegionOverlay = () => {
    if (!shouldDisplayRegion()) {
      return null;
    }

    const region = props.selectedRegion;
    const { clientWidth, clientHeight } = imageRef?.current;

    // region coords are in percent so we convert to pixels
    const regionX = (region.x * clientWidth) / 100;
    const regionY = (region.y * clientHeight) / 100;
    const regionWidth = (region.width * clientWidth) / 100;
    const regionHeight = (region.height * clientHeight) / 100;

    // create a 'poke hole' effect for a selected region, by rendering
    // a div with borders that appear as the inverse of the selected region
    const top = regionY;
    const left = regionX;
    const right = clientWidth - regionX - regionWidth;
    const bottom = clientHeight - top - regionHeight;

    const widthString = [top, right, bottom, left].map((coord) => `${coord}px`).join(' ');
    return <div style={{ ...styles.heatmapSelectionOverlay, ...{ borderWidth: widthString } }} />;
  };

  const onSelectRegionChange = (regions) => {
    const region = regions[0];

    // When we pass [] to the <RegionSelect> component, it notifies us about the change but the x, y, width,
    // and height fields are not present. We can safely ignore these calls.
    const newRegionIsValid = region && ['x', 'y', 'width', 'height'].every((p) => isFinite(region[p]));
    if (!newRegionIsValid) {
      return;
    }

    const newRegionIsSmall = region.height <= 1 && region.width <= 1;
    if (newRegionIsSmall) {
      if (props.selectedRegion) {
        // The user clicked but they already have a region selected. We interpret this as a request to clear
        // that region.
        props.onSelectRegionChange(null);
      } else {
        // The user clicked and there's no region selected. We'll draw a FIXED_BOX_SIZE_IN_PX square region
        // centered on the location they clicked.
        const widthPct = (100 * FIXED_BOX_SIZE_IN_PX) / imageRef?.current.clientWidth;
        const heightPct = (100 * FIXED_BOX_SIZE_IN_PX) / imageRef?.current.clientHeight;

        // make sure the x & y stay within the bounds of the heatmap
        const x = clamp(region.x - widthPct / 2, 0, 100 - widthPct);
        const y = clamp(region.y - heightPct / 2, 0, 100 - heightPct);

        const fixedSizeRegion = {
          x,
          y,
          width: widthPct,
          height: heightPct,
        };
        props.onSelectRegionChange(fixedSizeRegion);
      }
    } else {
      props.onSelectRegionChange(region);
    }
  };

  return (
    <ScrollLock>
      <Box sx={styles.container} ref={containerRef}>
        <div ref={ref} style={styles.wrapper}>
          {
            // <img> is the only statically-positioned element so it defines the size of the <div>
            // container. We don't know how big the container is going to be until the image finishes
            // loading, so we trigger an update when that happens.
          }
          <img
            style={{
              ...styles.image,
              ...(props.showOverlay && !shouldDisplayRegion() && { filter: 'brightness(0.4)' }),
            }}
            src={props.image.url}
            onLoad={handleImageLoad}
            ref={imageRef}
            alt="page screenshot"
            id={props.heatmapBgID ?? HEATMAP_BG}
          />
          <div style={styles.heatmap} id={props.sentimentMapID ?? HEATMAP_CANVAS_CONTAINER}>
            <Box
              component={RegionSelect}
              maxRegions={1}
              sx={{ ...styles.heatmapSelection }}
              className={shouldDisplayRegion() ? 'showRegion' : 'hideRegion'}
              regions={props.selectedRegion ? [props.selectedRegion] : []}
              onChange={onSelectRegionChange}
              regionRenderer={renderRegion}
              constraint={true}>
              {renderHeatmap}
              {renderHighlightedPoint()}
              {renderRegionOverlay()}
            </Box>
          </div>
        </div>
      </Box>
    </ScrollLock>
  );
};

Heatmap.propTypes = {
  image: PropTypes.shape({
    url: PropTypes.string.isRequired,
    ...dimensionProps,
  }).isRequired,

  data: PropTypes.arrayOf(PropTypes.shape(pointProps)).isRequired,

  highlightedPoint: PropTypes.shape(pointProps),

  highlightSize: PropTypes.number,

  selectedRegion: PropTypes.shape({
    ...pointProps,
    ...dimensionProps,
  }),

  showOverlay: PropTypes.bool,

  onSelectRegionChange: PropTypes.func.isRequired,
  radius: PropTypes.number,
  customSize: PropTypes.bool,
};

Heatmap.defaultProps = {
  showOverlay: true,
  highlightSize: DEFAULT_HIGHLIGHT_SIZE,
  radius: HeatmapPointRadius.Normal,
};

export default Heatmap;
