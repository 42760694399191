import { Box, Button } from '@mui/material';
import { ReactComponent as ScrollToTopIcon } from '../../assets/scroll-to-top.svg';

export function BackToTopButton() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Box
      sx={{ right: { xs: 45, sm: 75 }, bottom: { xs: 77, sm: 90, md: 100 }, zIndex: 9999, position: 'fixed' }}>
      <Button size="small" sx={{ display: 'block' }} onClick={handleClick}>
        <Box textAlign="center">
          <ScrollToTopIcon style={{ width: '1.75rem', height: '1.75rem' }} />
        </Box>
        <Box sx={{ fontSize: 10 }}>BACK TO TOP</Box>
      </Button>
    </Box>
  );
}
