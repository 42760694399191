import produce from 'immer';
import _, { cloneDeep } from 'lodash';
import _pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';
import { SEGMENT_ID_TO_SEGMENT_LABEL } from '../wevos/constants';
import * as actions from './actions';

const initialState = {
  wevo: {},
  wevos: [],
  draft: {},
};

const defaultAction = {
  payload: {},
};

// List of wevo fields that will be parsed when fetching the wevo from the API
// and stored into the draft in local storage.
const WEVO_FIELDS = [
  'id',
  'name',
  'description',
  'type',
  'devices',
  'audienceCategory',
  'audience',
  'numRespondents',
];

export const wevosReducer = (state = initialState, action) => {
  const { wevos, wevoId, wevo, page, pageId, step, stepId, draft, data, uploadId } =
    action.payload || defaultAction.payload;
  switch (action.type) {
    case actions.FETCH_WEVOS_SUCCESS:
      return {
        ...state,
        wevos,
      };
    case actions.FETCH_WEVO_SUCCESS:
      return {
        ...state,
        wevo,
      };
    case actions.ARCHIVE_WEVO_SUCCESS:
      return {
        ...state,
        wevos: state.wevos.map((wevo) => (wevo.id === wevoId ? { ...wevo, is_archived: true } : wevo)),
      };
    case actions.RESTORE_WEVO_SUCCESS:
      return {
        ...state,
        wevos: state.wevos.map((wevo) => (wevo.id === wevoId ? { ...wevo, is_archived: false } : wevo)),
      };
    case actions.DELETE_WEVO_SUCCESS:
      return {
        ...state,
        wevos: state.wevos.map((wevo) => (wevo.id === wevoId ? { ...wevo, is_deleted: true } : wevo)),
      };
    case actions.CREATE_WEVO_SUCCESS:
      return {
        ...state,
        draft: _pick(wevo, WEVO_FIELDS),
      };
    case actions.UPDATE_WEVO_DEFINITION_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          ..._pick(wevo, WEVO_FIELDS),
        },
      };
    case actions.UPDATE_WEVO_AUDIENCE_SUCCESS:
      if (!wevo.audience) {
        return {
          ...state,
          draft: { ...state.draft, audience: null },
        };
      }
      let newAudience = cloneDeep(wevo.audience);
      if (newAudience.attributes) {
        newAudience.attributes = newAudience.attributes.map((attribute) => ({
          ...attribute,
          segments: attribute.segments.map((segment) => ({
            ...segment,
            label: SEGMENT_ID_TO_SEGMENT_LABEL[segment.id] || segment.label,
          })),
        })); // set the label for each segment to the appropriate pre-defined label based on the segment's ID
      }
      return {
        ...state,
        draft: { ...state.draft, audience: newAudience },
      };
    case actions.CLEAR_DRAFT:
      return {
        ...state,
        draft: {},
      };
    case actions.UPLOAD_PAGE_IMAGE: {
      const nextState = produce(state, (draftState) => {
        const draftPages = _.get(draftState, 'draft.pages', []);
        draftPages.push(action.payload);
        _.set(draftState, 'draft.pages', draftPages);
      });
      return nextState;
    }
    case actions.UPLOAD_STEP_IMAGE: {
      const nextState = produce(state, (draftState) => {
        const draftSteps = _.get(draftState, 'draft.pages.0.steps', []);
        draftSteps.push(action.payload);
        _.set(draftState, 'draft.pages.0.steps', draftSteps);
      });
      return nextState;
    }
    case actions.IMAGE_UPLOAD_PROGRESS:
      // no-op for now
      return state;
    case actions.CREATE_PAGE: {
      const { imageId } = data;
      const nextState = produce(state, (draftState) => {
        const draftPages = _.get(draftState, 'draft.pages', []);

        const index = draftPages.findIndex((p) => p.uploadId === uploadId);
        if (index >= 0) {
          draftPages[index].uploadId = imageId;
        }
      });
      return nextState;
    }
    case actions.CREATE_PAGE_SUCCESS: {
      const nextState = produce(state, (draftState) => {
        const draftPages = _.get(draftState, 'draft.pages', []);

        const index = draftPages.findIndex((p) => String(p.uploadId) === String(page.imageId));
        if (index >= 0) {
          draftPages[index] = {
            ...draftPages[index],
            ...page,
            progress: 100,
          };
        }
      });
      return nextState;
    }
    case actions.UPDATE_PAGE_SUCCESS: {
      const nextState = produce(state, (draftState) => {
        const draftPages = _.get(draftState, 'draft.pages', []);

        const index = draftPages.findIndex((p) => String(p.id) === String(page.id));
        if (index >= 0) {
          draftPages[index] = {
            ...draftPages[index],
            ...page,
          };
        }

        _.set(draftState, 'draft.pages', sortBy(draftPages, ['sortOrder']));
      });
      return nextState;
    }
    case actions.DELETE_PAGE_SUCCESS: {
      const nextState = produce(state, (draftState) => {
        const draftPages = _.get(draftState, 'draft.pages', []);

        // Remove the page.
        const index = draftPages.findIndex((p) => String(p.id) === String(pageId));
        if (index >= 0) {
          draftPages.splice(index, 1);
        }
      });
      return nextState;
    }
    case actions.CREATE_STEP: {
      const { imageId } = data;
      const nextState = produce(state, (draftState) => {
        const draftSteps = _.get(draftState, 'draft.pages.0.steps', []);

        const index = draftSteps.findIndex((s) => s.uploadId === uploadId);
        if (index >= 0) {
          draftSteps[index].uploadId = imageId;
        }
      });
      return nextState;
    }
    case actions.CREATE_STEP_SUCCESS: {
      const nextState = produce(state, (draftState) => {
        const draftSteps = _.get(draftState, 'draft.pages.0.steps', []);

        const index = draftSteps.findIndex((s) => String(s.uploadId) === String(step.imageId));
        if (index >= 0) {
          draftSteps[index] = {
            ...draftSteps[index],
            ...step,
            progress: 100,
          };
        }
      });
      return nextState;
    }
    case actions.UPDATE_STEP_SUCCESS: {
      const nextState = produce(state, (draftState) => {
        const draftSteps = _.get(draftState, 'draft.pages.0.steps', []);

        const index = draftSteps.findIndex((s) => String(s.id) === String(step.id));
        if (index >= 0) {
          draftSteps[index] = {
            ...draftSteps[index],
            ...step,
          };
        }

        _.set(draftState, 'draft.pages.0.steps', sortBy(draftSteps, ['sortOrder']));
      });
      return nextState;
    }
    case actions.DELETE_STEP_SUCCESS: {
      const nextState = produce(state, (draftState) => {
        const draftSteps = _.get(draftState, 'draft.pages.0.steps', []);

        // Remove the step.
        const index = draftSteps.findIndex((s) => String(s.id) === String(stepId));
        if (index >= 0) {
          draftSteps.splice(index, 1);
        }
      });
      return nextState;
    }
    case actions.LAUNCH_WEVO_SUCCESS:
      return {
        ...state,
        draft: { ...state.draft, launched: true },
      };
    case actions.SET_DRAFT:
      return {
        ...state,
        draft,
      };
    case actions.FETCH_DRAFT_SUCCESS:
      return {
        ...state,
        draft,
      };
    case actions.SAVE_WEVO_SUCCESS:
      return {
        ...state,
        draft: { ...state.draft, ..._pick(wevo, WEVO_FIELDS) },
      };
    default:
      return state;
  }
};
