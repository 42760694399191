import { Box, Tooltip, Typography, styled, tooltipClasses } from '@mui/material';
import React, { useMemo } from 'react';
import { TrackEvent } from '../analytics';
import { ExplorePlansButton } from './ui/Button';

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FCFCFC',
    fontSize: '12px',
    color: theme.palette.text.primary,
    borderRadius: '24px',
    border: '1px solid #E0E0E0',
    minWidth: '340px',
  },
}));

const TooltipContent = ({ title, message, blockedFeature, showButton = true }) => {
  return (
    <Box m={1}>
      <Box mb={1}>
        <Typography variant="body1" sx={{ fontSize: '13px' }}>
          <b>{title}</b>
        </Typography>
      </Box>
      <Box>
        <Typography variant="body1" sx={{ fontSize: '13px' }}>
          {message}
        </Typography>
      </Box>
      {showButton && (
        <Box sx={{ marginTop: 2, textAlign: 'end' }}>
          <ExplorePlansButton
            blockedFeature={blockedFeature}
            trackEventName={TrackEvent.CLICKED_BUTTON_TO_EXPLORE_PLANS_FROM_BLOCKED_FEATURE}
          />
        </Box>
      )}
    </Box>
  );
};

const FeatureTooltip = ({
  children,
  blockedFeature,
  blockJourneys,
  disableFeatures,
  freeTrialExpired,
  subscriptionExpired,
  reachedUsageLimit,
  user,
  ...props
}) => {
  const tooltipContent = useMemo(() => {
    if (disableFeatures || blockJourneys) {
      if (freeTrialExpired) {
        return (
          <TooltipContent
            title="Your free trial has ended"
            message="Ready to keep the momentum going? Subscribe now to unlock full access."
            blockedFeature={blockedFeature}
          />
        );
      } else if (subscriptionExpired) {
        const message = user?.isBillingAdmin
          ? 'Your subscription to Pulse has expired. Upgrade to a full subscription today and continue transforming your digital assets with the power of WEVO Pulse.'
          : 'Your subscription to Pulse has expired. Please contact your administrator to upgrade to a full subscription and continue transforming your digital assets with the power of WEVO Pulse.';

        return (
          <TooltipContent
            title="Subscription Expired"
            message={message}
            blockedFeature={blockedFeature}
            showButton={user?.isBillingAdmin}
          />
        );
      } else if (reachedUsageLimit) {
        return (
          <TooltipContent
            title="Usage Limit Reached!"
            message="You've reached your usage limit for the current plan. Upgrade to unlock additional capacity."
            blockedFeature={blockedFeature}
          />
        );
      } else if (blockJourneys) {
        return (
          <TooltipContent
            title="Feature not supported"
            message="Your current subscription does not support Journeys."
            blockedFeature={blockedFeature}
            showButton={user?.isBillingAdmin}
          />
        );
      }
      return '';
    }
    return '';
  }, [
    blockedFeature,
    blockJourneys,
    disableFeatures,
    freeTrialExpired,
    reachedUsageLimit,
    subscriptionExpired,
    user,
  ]);

  return (
    <StyledTooltip title={tooltipContent} {...props}>
      {children}
    </StyledTooltip>
  );
};

export default FeatureTooltip;
