import produce from 'immer';
import { isNil as _isNil, set as _set } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { CustomScreenerMutationKeys } from '../../../modules/intake/constants';

const updateCustomScreenerQuestions = async ({ wevoId, changes }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/filters`,
    method: 'PUT',
    data: { filters: changes },
  });
  return data?.filters;
};

/**
 * Custom hook for updating a customScreener.
 * Upon a successful response from the api, this hook will automatically update the queryCache with the updated data
 */
export default function useUpdateCustomScreenerQuestions() {
  const queryClient = useQueryClient();

  return useMutation(updateCustomScreenerQuestions, {
    mutationKey: CustomScreenerMutationKeys.updateCustomScreenerQuestions,
    onMutate: async ({ wevoId, changes }) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(['customScreeners', { wevoId }]);

      // Snapshot the previous customScreeners value
      const previousCustomScreenersState = await queryClient.getQueryData(['customScreeners', { wevoId }]);

      if (changes?.length > 0 && changes?.every((questionChange) => !!questionChange?.id)) {
        const updatedCustomScreenersState = produce(previousCustomScreenersState, (draftState) => {
          // Apply changes to individual questions
          changes?.forEach((changeObj) => {
            const index = draftState?.findIndex((q) => String(q?.id) === String(changeObj?.id));

            if (index >= 0) {
              if (!_isNil(changeObj.name)) {
                _set(draftState[index], 'name', changeObj.name);
              }
              if (!_isNil(changeObj.questionText)) {
                _set(draftState[index], 'questionText', changeObj.questionText);
              }
              if (!_isNil(changeObj.questionType)) {
                _set(draftState[index], 'questionType', changeObj.questionType);
              }
              if (!_isNil(changeObj.sortOrder)) {
                _set(draftState[index], 'sortOrder', changeObj.sortOrder);
              }
              // if (!_isNil(changes.labelsType)) {
              //   _set(draftState[index], 'question.labelsType', changeObj.labelsType);
              // }

              if (changeObj?.options?.length > 0) {
                const updatedOptions = draftState[index]?.options?.map((option) => {
                  const optionWithChanges = changeObj?.options?.find(
                    (changedOption) => changedOption?.id === option?.id
                  );
                  if (!_isNil(optionWithChanges.optionText)) {
                    option.optionText = optionWithChanges.optionText;
                  }
                  if (!_isNil(optionWithChanges.outcome)) {
                    option.outcome = optionWithChanges.outcome;
                  }
                  return option;
                });
                _set(draftState[index], 'options', updatedOptions);
              }
            }
          });

          // Reorder the array based on the updated order
          draftState.sort((a, b) => a.sortOrder - b.sortOrder);
        });

        // Update query data
        queryClient.setQueryData(['customScreeners', { wevoId }], updatedCustomScreenersState);
      }

      // Return a context object with the snapshotted value
      return { previousCustomScreenersState };
    },

    // If the mutation fails, use the context returned from onMutate to roll back
    onError: (err, { wevoId }, { previousCustomScreenersState }) => {
      queryClient.setQueryData(['customScreeners', { wevoId }], previousCustomScreenersState);
    },
  });
}
