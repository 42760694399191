import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchSession = async ({ queryKey: [key, { sessionId, segmentId }] }) => {
  let url = `/api/v2/automated-insight-sessions/${sessionId}?includeTask=true&includeDependencies=true`;

  if (segmentId) {
    url = `${url}&taskSegmentId=${segmentId}`;
  }

  const response = await axios({
    url,
    method: 'GET',
  });
  return response?.data ?? {};
};

export default function useFetchSession({ sessionId, segmentId }, options) {
  return useQuery(['automatedInsightSession', { sessionId, segmentId }], fetchSession, options);
}
