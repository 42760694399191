import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@mui/material';
import { AssetScope, TestTypes } from '../../../../../modules/intake/constants';
import { numMaxAssets } from '../../../../../modules/intake/helpers';
import { pageHasCustomQuestions } from '../../../../../modules/wevos/helpers';
import { AssetList, AssetPreview } from './Assets';

function ClassicPageConfigurationList({
  draft,
  testType,
  customQuestions,
  handleAssetDelete,
  togglePageBaseline,
  togglePageCompetitor,
  updateAssetFields,
  showBaselineCompetitor,
  ongoingUploads,
  handleFileInput,
  handleReorderAssets,
}) {
  const assetLimit = numMaxAssets(testType, AssetScope.Page);

  const errors = useMemo(() => {
    const numPages = (draft?.pages ?? []).length;
    const minPages = testType === TestTypes.ComparePages ? 2 : 1;

    if (numPages < minPages) {
      let message = 'Please add a page';

      const minDelta = numPages - minPages;

      if (testType === TestTypes.ComparePages && minDelta > 1) {
        message = 'Please add at least one more page';
      } else if (testType === TestTypes.ComparePages && minDelta === 1) {
        message = 'Please add at least two pages';
      }

      return { pages: { message } };
    }

    if (numPages > assetLimit) {
      return { pages: { message: `Please delete ${numPages - assetLimit} pages` } };
    }

    return { pages: null };
  }, [assetLimit, draft, testType]);

  const showDropzone = (draft?.pages ?? []).length < assetLimit;

  const pageInstructions =
    testType === TestTypes.ComparePages ? (
      <>
        <Typography variant="h5">Compare Individual Pages</Typography>
        <Typography py={2} style={{ fontWeight: 400 }} gutterBottom>
          Send users to 2 or more (up to 6) similar pages to compare predicted performance and gather insights.
        </Typography>
      </>
    ) : (
      <>
        <Typography variant="h5">Page</Typography>
        <Typography py={2}>
          Send your users to a specific page (live URL or prototype) to gather insights.
        </Typography>
      </>
    );

  return (
    <>
      {pageInstructions}
      {Boolean(errors?.pages) && (
        <Grid item xs={12}>
          <Typography color="error">{errors?.pages?.message}</Typography>
        </Grid>
      )}
      <AssetList
        ongoingUploads={ongoingUploads}
        showDropzone={showDropzone}
        handleFileInput={(files, fileRejections) =>
          handleFileInput({ files, fileRejections, assetScope: AssetScope.Page })
        }
        handleReorderAssets={(result) =>
          handleReorderAssets({ result, assetScope: AssetScope.Page, pageId: null })
        }
        numAssets={draft?.pages?.length ?? 0}
        maxNumAssets={assetLimit}>
        {(draft?.pages ?? []).map((page) => (
          <AssetPreview
            id={page.id}
            key={page?.id || page?.uploadId}
            asset={page}
            customQuestions={customQuestions}
            handleAssetDelete={() =>
              handleAssetDelete({ assetId: page.id, assetScope: AssetScope.Page, pageId: null })
            }
            toggleBaseline={togglePageBaseline}
            toggleCompetitor={togglePageCompetitor}
            handleUpdateFields={(_, fields) =>
              updateAssetFields({ assetId: page.id, assetScope: AssetScope.Page, fields })
            }
            showBaselineCompetitor={showBaselineCompetitor}
            isLoading={false}
            isDraggable={true}
            shouldWarnOnDelete={pageHasCustomQuestions(customQuestions, page.id)}
          />
        ))}
      </AssetList>
    </>
  );
}

ClassicPageConfigurationList.propTypes = {
  draft: PropTypes.object.isRequired,
  testType: PropTypes.string,
  customQuestions: PropTypes.arrayOf(PropTypes.object),
  handleAssetDelete: PropTypes.func,
  togglePageBaseline: PropTypes.func,
  togglePageCompetitor: PropTypes.func,
  updateAssetFields: PropTypes.func,
  showBaselineCompetitor: PropTypes.bool,
  ongoingUploads: PropTypes.arrayOf(PropTypes.object),
  handleFileInput: PropTypes.func,
  handleReorderAssets: PropTypes.func,
};

export { ClassicPageConfigurationList };
