import CloseIcon from '@mui/icons-material/Close';
import { Grid, IconButton, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import React from 'react';
import CustomButton from './ui/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * A dialog to allow the user to confirm if they wish to run a single page pulse
 * @param {{open: boolean, approveSinglePagePulse: function, closeCallback: function}} props
 * @returns The Approve single page pulse dialog component
 */
const ApproveSinglePagePulseDialog = ({ open, approveSinglePagePulse, closeCallback }) => {
  const handleClose = () => {
    !!closeCallback && closeCallback();
  };

  const handleReset = () => {
    !!approveSinglePagePulse && approveSinglePagePulse();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="approve single page pulse confirmation dialog"
        aria-describedby="approve-single-page-pulse-confirmation-description"
        maxWidth="xs"
        PaperProps={{
          style: {
            paddingTop: 1,
            width: '345px',
            height: '188px',
            backgroundColor: '#082739',
            fontSize: '12px',
            color: 'lightGrey',
            borderRadius: '10px',
            border: '1px solid #324B59',
          },
        }}>
        <DialogTitle
          sx={{
            textAlign: 'center',
            fontSize: '16px',
            fontWeight: 700,
            color: (theme) => theme.palette.background.default,
          }}>
          <IconButton
            aria-label="close"
            size="small"
            onClick={() => closeCallback(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'lightGrey',
            }}>
            <CloseIcon sx={{ fontSize: '18px' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent id="approve-single-page-pulse-description">
          <Grid container alignItems="center" spacing={1}>
            <Grid item sx={{ marginLeft: 1 }}>
              <Typography
                variant="h6"
                sx={{
                  textAlign: 'center',
                  marginBottom: 1,
                }}>
                This will be run as a Single Page Pulse
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '14px' }}>
                Multipage Journeys require two or
              </Typography>
              <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '14px' }}>
                more assets.
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'end', marginRight: 8, marginBottom: 6 }}>
          <Button onClick={handleClose} sx={{ width: '65px', borderRadius: '27px', height: '36px' }}>
            <Typography sx={{ textTransform: 'none', fontSize: '12px', color: 'lightGrey', fontWeight: 500 }}>
              Cancel
            </Typography>
          </Button>
          <CustomButton
            size="small"
            sx={{
              fontSize: '12px',
              width: '180px',
              height: '36px',
              background: (theme) => theme.palette.gradient.main,
              border: '1px solid rgba(255,255,255,.3)',
              '&:hover': {
                border: '1px solid rgba(255,255,255,.5)',
                opacity: 0.9,
              },
            }}
            onClick={handleReset}>
            Run as a Single Page
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(ApproveSinglePagePulseDialog);
