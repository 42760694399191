import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

/**
 * Copies a segment
 */

const copySegment = async ({ segmentId }) => {
  const response = await axios({
    url: `/api/v2/segments/${segmentId}/copy`,
    method: 'POST',
  });

  return response?.data?.segment;
};

export default function useCopySegment() {
  const queryClient = useQueryClient();
  return useMutation(copySegment, {
    onSuccess: () => {
      queryClient.invalidateQueries('automatedInsightSession');
    },
  });
}
