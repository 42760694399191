import { useMutation, useQuery } from 'react-query';

import api from '../api';

const fetchCreditUsage = async () => {
  const response = await api({
    url: `/api/v2/companies/usage`,
    method: 'GET',
  });
  return response?.data;
};

/**
 * Fetches the credit usage for companies the logged-in user belongs to
 * @param {object} queryOptions - passthrough for react-query useQuery options
 */
export default function useCreditUsage(queryOptions) {
  return useQuery('creditUsage', fetchCreditUsage, {
    notifyOnChangeProps: 'tracked',
    ...queryOptions,
  });
}

const downloadCreditUsageReport = async (wevo) => {
  const response = await api({
    url: `api/v2/users/usage/export`,
    responseType: 'blob',
    method: 'GET',
  });

  const filename = response.headers['x-filename'];
  const blob = new Blob([response.data]);
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(blob);
};

export function useDownloadCreditUsageReport() {
  return useMutation(downloadCreditUsageReport);
}
