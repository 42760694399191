import { Box, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import ExperienceStepsCarousel from './ExperienceStepsCarousel';
import ExperienceStepsMetricsTable from './ExperienceStepsMetricsTable';
import ExperienceUsabilityMetricsSummary from './ExperienceUsabilityMetricsSummary';

const MetricsCard = (props) => {
  const { usabilityScores, experience } = props;

  const [firstItemIndex, setfirstItemIndex] = useState(0);

  const visitorsPerStep = usabilityScores?.stepMetrics?.uniqueVisitorsPerStep;
  const giveUpsPerStep = usabilityScores?.stepMetrics?.giveUpsPerStep;

  const handleStepIndex = (index) => {
    setfirstItemIndex(index);
  };

  return (
    <Paper elevation={4} sx={{ padding: 2, borderRadius: '20px' }}>
      <Box p={2}>
        <ExperienceUsabilityMetricsSummary usabilityScores={usabilityScores} />
      </Box>
      <Box sx={{ paddingRight: '12%', paddingLeft: '12%', marginY: 2 }}>
        <ExperienceStepsCarousel
          experience={experience}
          visitorsPerStep={visitorsPerStep}
          firstItemIndex={firstItemIndex}
          handleStepIndex={handleStepIndex}
        />
      </Box>
      <Box sx={{ paddingRight: '12%', paddingLeft: 2 }}>
        <ExperienceStepsMetricsTable
          steps={experience?.steps}
          visitorsPerStep={visitorsPerStep}
          giveUpsPerStep={giveUpsPerStep}
          firstItemIndex={firstItemIndex}
        />
      </Box>
    </Paper>
  );
};

MetricsCard.propTypes = {
  usabilityScores: PropTypes.object.isRequired,
  experience: PropTypes.object.isRequired,
};
export default MetricsCard;
