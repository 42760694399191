import { AssetScope, Metrics, MetricTypes, MetricTypeToText, TestTypes } from './constants';

export const metricTypeToMetric = (draftMetricType) => {
  if (draftMetricType === MetricTypes.MastercardDqs) {
    return Metrics.DQS;
  } else if (draftMetricType === MetricTypes.MastercardCds) {
    return Metrics.CDS;
  } else {
    return Metrics.Wevo;
  }
};

export const getSelectedMetricName = (metricType) => {
  if ([MetricTypes.MastercardDqs, Metrics.DQS].includes(metricType)) {
    return MetricTypeToText.DQS;
  } else if ([MetricTypes.MastercardCds, Metrics.CDS].includes(metricType)) {
    return MetricTypeToText.CDS;
  } else {
    return MetricTypeToText.Wevo;
  }
};

export const metricToMetricType = (metric) => {
  if (metric === Metrics.DQS) {
    return MetricTypes.MastercardDqs;
  } else if (metric === Metrics.CDS) {
    return MetricTypes.MastercardCds;
  } else {
    return MetricTypes.Standard;
  }
};

/**
 * Determines the number of assets that the user is allowed to upload.
 *
 * @param {TestTypes} testType
 * @returns {number} max number of assets
 */
export const numMaxAssets = (testType, assetScope) => {
  if (assetScope === AssetScope.Page) {
    switch (testType) {
      case TestTypes.CompareJourneys:
        return 3;
      case TestTypes.ComparePages:
        return 6;
      case TestTypes.Usability:
      case TestTypes.SinglePage:
      case TestTypes.Journey:
      case TestTypes.Lite:
      default:
        return 1;
    }
  }

  if (assetScope === AssetScope.Step) {
    switch (testType) {
      case TestTypes.Journey:
      case TestTypes.Lite:
      case TestTypes.CompareJourneys:
        return 4;
      case TestTypes.Usability:
        return 1;
      case TestTypes.SinglePage:
      case TestTypes.ComparePages:
      default:
        return 0;
    }
  }

  return 0;
};

export function isPrimerConfigured(draft) {
  // Because the show / hide primer state is determined implicitly AND is stateful, i.e. we need to allow the user to
  // select "yes" with no primerId or primerContext, it has to be checked 'out of the loop' of the typical render process
  const referencePage = draft?.pages?.[0]; // all pages should have the same primer, so just look at the first one
  const hasPrimer = Boolean(referencePage?.primerId) || Boolean(referencePage?.primerContext);
  return hasPrimer;
}
