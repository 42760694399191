import { CircularProgress } from '@mui/material';
import _ from 'lodash';
import queryString from 'query-string';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { Paths } from '../../../routes';
import useFetchCustomQuestions from '../../intake/hooks/useFetchCustomQuestions';
import CustomQuestions from './CustomQuestions';

const CustomQuestionsContainer = ({ wevo, rightDrawerOpen, setRightDrawerOpen }) => {
  const { data: groups, isLoading } = useFetchCustomQuestions(wevo?.id);
  if (isLoading) {
    return <CircularProgress />;
  }

  const groupNums = groups?.map((group) => Number(group.groupNumber));
  const questionDetailsPath = `${Paths.reports.questionDetails}(${groupNums?.join('|')})`;

  return (
    <Switch>
      <Route
        exact
        path={questionDetailsPath}
        render={({ match: { params }, location }) => {
          const { groupNum } = params;
          const queryParams = queryString.parse(_.get(location, 'search', ''));
          const questionId = queryParams?.questionId;
          const group = groups?.find((group) => Number(group.groupNumber) === Number(groupNum));
          return (
            <CustomQuestions
              key={group?.groupId}
              wevo={wevo}
              group={group}
              questionId={questionId}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={setRightDrawerOpen}
            />
          );
        }}
      />
      <Redirect
        to={generatePath(Paths.reports.questionDetails, { wevoId: wevo?.id, groupNum: groupNums[0] })}
      />
    </Switch>
  );
};

export default CustomQuestionsContainer;
