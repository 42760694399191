import { Box, CardActionArea, Icon, Tooltip } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import classNames from 'classnames';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    maxWidth: 200,
  },
  cardSelected: {
    borderWidth: 3,
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  content: {
    padding: theme.spacing(1),
    flexGrow: 1,
    flexDirection: 'column',
  },
  image: {
    height: 48,
    width: 60,
  },
  disabled: {
    color: ({ disabled }) => (disabled ? grey[300] : ''),
  },
}));

const Image = (props) => {
  const { image } = props;
  const classes = useStyles();

  return (
    <Icon className={classes.image}>
      <img src={image} alt="selectable" />
    </Icon>
  );
};

export default function SelectableCard(props) {
  const { title, description, image, icon, isSelected, onClick, disabled } = props;

  const classes = useStyles({ disabled });

  let checkbox;
  if (isSelected) {
    checkbox = <CheckCircleOutlineIcon color="primary" className={classes.disabled} />;
  } else {
    checkbox = <RadioButtonUncheckedIcon color="primary" className={classes.disabled} />;
  }

  return (
    <Tooltip
      title={
        disabled
          ? 'This Test Type is not compatible with the Test Goal you have selected. To enable this Test Type, please return to your Test Goals and change your selection.'
          : ''
      }
      placement="bottom">
      <Card
        className={classNames(classes.root, { [classes.cardSelected]: isSelected })}
        elevation={isSelected ? 4 : 1}>
        <CardActionArea className={classes.content} onClick={onClick} disabled={disabled}>
          <CardContent>
            {image && (
              <Box display="flex" justifyContent="space-between" pb={3}>
                <Image image={image} />
                {checkbox}
              </Box>
            )}
            {icon && (
              <Box display="flex" justifyContent="space-between" pb={3} style={{ marginBottom: '6px' }}>
                {icon}
                {checkbox}
              </Box>
            )}

            <Box pb={1}>
              <Typography variant="h5" className={classes.disabled}>
                {title}
              </Typography>
            </Box>

            <Typography color="textSecondary" variant="caption" className={classes.disabled}>
              {description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  );
}
