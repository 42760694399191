import { CloudDownload } from '@mui/icons-material';
import { Button, styled } from '@mui/material';
import PropTypes from 'prop-types';

const StyledButton = styled(Button)(({ theme }) => ({
  textTransform: 'capitalize',
  '&:hover': {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
}));

const DownloadButton = ({ handleDownload }) => {
  return (
    <StyledButton variant="outlined" color="primary" endIcon={<CloudDownload />} onClick={handleDownload}>
      Download Image
    </StyledButton>
  );
};

DownloadButton.propTypes = {
  handleDownload: PropTypes.func.isRequired,
};

export default DownloadButton;
