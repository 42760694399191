import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { Paths } from '../../../routes';
import Takeaways from './Takeaways';
import { WevoTestType } from '../../../modules/wevos/constants';

const TakeawaysContainer = ({ wevo, rightDrawerOpen, setRightDrawerOpen }) => {
  if (wevo.testType === WevoTestType.Compare) {
    const pageNums = wevo?.pages.map((page) => page.pageNumber);
    const takeawaysPagePath = `${Paths.reports.takeawaysPage}(${pageNums.join('|')})`;

    return (
      <Switch>
        <Route
          exact
          path={takeawaysPagePath}
          render={({ match: { params } }) => {
            const { pageNum } = params;
            const page = wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum));
            return (
              <Takeaways
                key={`takeaways-wevo-${wevo?.id}-page-${page?.id}`}
                wevo={wevo}
                page={page}
                rightDrawerOpen={rightDrawerOpen}
                setRightDrawerOpen={setRightDrawerOpen}
              />
            );
          }}
        />
        <Redirect to={generatePath(Paths.reports.takeawaysPage, { wevoId: wevo?.id, pageNum: pageNums[0] })} />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route exact path={Paths.reports.takeaways}>
          <Takeaways
            key={`takeaways-wevo-${wevo?.id}-page-${wevo?.pages?.[0]?.id}`}
            wevo={wevo}
            page={wevo?.pages?.[0]}
            rightDrawerOpen={rightDrawerOpen}
            setRightDrawerOpen={setRightDrawerOpen}
          />
        </Route>
        <Redirect to={generatePath(Paths.reports.takeaways, { wevoId: wevo?.id })} />
      </Switch>
    );
  }
};

export default TakeawaysContainer;
