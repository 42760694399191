export const basePath = '/wevos/:wevoId/report';
export const limitedReportBasePath = '/wevos/:wevoId';

export const dashboard = `${basePath}/dashboard`;
export const dashboardPage = `${dashboard}/pages/:pageNum`;
export const takeaways = `${basePath}/key-findings`;
export const takeawaysPage = `${takeaways}/pages/:pageNum`;
export const expectations = `${basePath}/expectations`;
export const expectationsPage = `${expectations}/pages/:pageNum`;

export const diagnostics = `${basePath}/diagnostics`;
export const diagnosticsPage = `${diagnostics}/pages/:pageNum`;
export const pageAttributes = `${basePath}/attributes`;

export const experienceScore = `${basePath}/experience-score`;
export const conceptScore = `${basePath}/concept-score`;

export const comparePages = `${basePath}/compare-pages`;

export const sentimentMap = `${basePath}/sentiment-map`;
export const sentimentMapPage = `${sentimentMap}/pages/:pageNum`;
export const sentimentMapPageStep = `${sentimentMapPage}/steps/:stepNum`;
export const SentimentMapPaths = [sentimentMap, sentimentMapPage, sentimentMapPageStep];

export const experienceMap = `${basePath}/experience-map`;
export const experienceMapPage = `${experienceMap}/pages/:pageNum`;

export const stayGo = `${basePath}/stay-go`;
export const stayGoPage = `${stayGo}/pages/:pageNum`;
export const stayGoPageStep = `${stayGoPage}/steps/:stepNum?`;

export const emotionWords = `${basePath}/emotion-words`;
export const emotionWordsPage = `${emotionWords}/pages/:pageNum`;
export const emotionWordsPageStep = `${emotionWordsPage}/steps/:stepNum`;

export const customQuestions = `${basePath}/customQuestions`;
export const questionDetails = `${customQuestions}/:groupNum`;

export const compare = `${basePath}/compare-pages`;
export const testDetails = `${basePath}/view-test`;

// limited report paths
export const limitedDashboard = `${limitedReportBasePath}/dashboard`;
export const limitedDashboardPage = `${limitedDashboard}/pages/:pageNum`;
export const LimitedDashboardPaths = [limitedDashboard, limitedDashboardPage];

export const limitedSentimentMap = `${limitedReportBasePath}/sentiment-map`;
export const limitedSentimentMapPage = `${limitedSentimentMap}/pages/:pageNum`;
export const limitedSentimentMapPageStep = `${limitedSentimentMapPage}/steps/:stepNum`;
export const LimitedSentimentMapPaths = [
  limitedSentimentMap,
  limitedSentimentMapPage,
  limitedSentimentMapPageStep,
];

export const limitedExperienceMap = `${limitedReportBasePath}/experience-map`;
export const limitedExperienceMapPage = `${limitedExperienceMap}/pages/:pageNum`;
