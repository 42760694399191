import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { Paths } from '../../../routes';
import Expectations from './Expectations';
import { WevoTestType } from '../../../modules/wevos/constants';

const ExpectationsContainer = ({ wevo, rightDrawerOpen, setRightDrawerOpen }) => {
  if (wevo?.testType === WevoTestType.Compare) {
    const pageNums = wevo?.pages.map((page) => page.pageNumber);
    const expectationsPagePath = `${Paths.reports.expectationsPage}(${pageNums.join('|')})`;

    return (
      <Switch>
        <Route
          exact
          path={expectationsPagePath}
          render={({ match: { params } }) => {
            const { pageNum } = params;
            return (
              <Expectations
                wevo={wevo}
                page={wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum))}
                rightDrawerOpen={rightDrawerOpen}
                setRightDrawerOpen={setRightDrawerOpen}
              />
            );
          }}
        />
        <Redirect
          to={generatePath(Paths.reports.expectationsPage, { wevoId: wevo?.id, pageNum: pageNums[0] })}
        />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route path={Paths.reports.expectations}>
          <Expectations
            wevo={wevo}
            page={wevo?.pages?.[0]}
            rightDrawerOpen={rightDrawerOpen}
            setRightDrawerOpen={setRightDrawerOpen}
          />
        </Route>
        <Redirect to={generatePath(Paths.reports.expectations, { wevoId: wevo?.id })} />
      </Switch>
    );
  }
};

export default ExpectationsContainer;
