import AddCircleIcon from '@mui/icons-material/AddCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItem,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import _, { cloneDeep } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { CustomScreenerTypes, OutcomeOptions, ScreenerSelectOptions } from '../../../modules/intake/constants';
import { snackbar } from '../../../notifications';
import useAddCustomScreenerOption from '../hooks/useAddCustomScreenerOption';
import useDeleteCustomScreenerOption from '../hooks/useDeleteCustomScreenerOption';
import useUpdateCustomScreener from '../hooks/useUpdateCustomScreener';
import Option from './Option';

const styles = {
  multipleSection: {
    marginTop: 0,
    marginBottom: (theme) => theme.spacing(2),
  },
  addIcon: {
    color: grey[600],
    '&:hover': {
      color: (theme) => theme.palette.primary.main,
    },
  },
  radioIcon: {
    marginRight: 1,
    fontSize: 'large',
    color: grey[600],
  },
};

const MAX_CHOICES = {
  [CustomScreenerTypes.MultipleChoice]: 10,
  [CustomScreenerTypes.YesNoTrueFalse]: 2,
  [CustomScreenerTypes.FiveLikertScale]: 5,
  [CustomScreenerTypes.SevenLikertScale]: 7,
  [CustomScreenerTypes.MultiSelect]: 15,
};

const MULTI_SELECT_OUTCOME_NAMES = {
  [OutcomeOptions.Accept]: 'Must select',
  [OutcomeOptions.MaySelect]: 'May select',
  [OutcomeOptions.Reject]: 'Must not select',
};

const OUTCOME_NAMES = {
  [OutcomeOptions.Accept]: 'Is accepted',
  [OutcomeOptions.Reject]: 'Is rejected',
};

export const OutcomeSelector = ({ selectedOutcome, questionType, onOutcomeClick }) => {
  const outcomeName = (outcome) => {
    if (questionType === CustomScreenerTypes.MultiSelect) {
      return MULTI_SELECT_OUTCOME_NAMES[outcome];
    } else {
      return OUTCOME_NAMES[outcome];
    }
  };

  return (
    <TextField
      fullWidth
      select
      variant="outlined"
      id="OutcomeType"
      label="Respondent"
      value={selectedOutcome ?? ''}
      InputLabelProps={{ shrink: true }}
      SelectProps={{ displayEmpty: true }}>
      <MenuItem value={OutcomeOptions.Accept} onClick={() => onOutcomeClick(OutcomeOptions.Accept)}>
        {outcomeName(OutcomeOptions.Accept)}
      </MenuItem>
      {questionType === CustomScreenerTypes.MultiSelect && (
        <MenuItem value={OutcomeOptions.MaySelect} onClick={() => onOutcomeClick(OutcomeOptions.MaySelect)}>
          {outcomeName(OutcomeOptions.MaySelect)}
        </MenuItem>
      )}
      <MenuItem value={OutcomeOptions.Reject} onClick={() => onOutcomeClick(OutcomeOptions.Reject)}>
        {outcomeName(OutcomeOptions.Reject)}
      </MenuItem>
    </TextField>
  );
};

const PredefinedOptions = ({ options, questionType, onOutcomeChange }) => {
  return (
    <List>
      {options?.map((option, index) => (
        <ListItem id={option?.optionText} key={index} sx={{ paddingRight: 0 }}>
          <Grid container alignItems="center">
            <Grid item sx={{ width: '50%' }}>
              <Box display="flex" alignItems="center">
                <RadioButtonUncheckedIcon sx={styles.radioIcon} />
                <Typography variant="body1">{option?.optionText}</Typography>
              </Box>
            </Grid>
            <Grid item sx={{ width: '50%' }}>
              <OutcomeSelector
                selectedOutcome={option?.outcome}
                questionType={questionType}
                onOutcomeClick={(value) => onOutcomeChange(value, index)}
              />
            </Grid>
          </Grid>
        </ListItem>
      ))}
    </List>
  );
};

const CustomScreenerOptions = ({
  wevoId,
  questionId,
  optionsAndOutcomes,
  setOptionsAndOutcomes,
  questionType,
  labelsType,
  onQuestionChange,
}) => {
  const [localErrors, setLocalErrors] = useState({});

  const { mutate: updateCustomScreener } = useUpdateCustomScreener();
  const { mutate: addCustomScreenerOption, isLoading: isLoadingNewOption } = useAddCustomScreenerOption();
  const { mutate: deleteCustomScreenerOption } = useDeleteCustomScreenerOption();

  useEffect(() => {
    if (!_.isNil(optionsAndOutcomes) && _.isEmpty(localErrors)) {
      const initialErrors = {};
      const allOptionsValid = optionsAndOutcomes?.every((option) => option?.optionText?.trim().length > 0);
      const allOutcomesValid = !optionsAndOutcomes?.every(
        (option) => option?.outcome === OutcomeOptions.Reject
      );
      if (!allOptionsValid) {
        initialErrors.options = 'Please make sure none of your options are blank.';
      } else {
        initialErrors.options = '';
      }

      if (!allOutcomesValid) {
        initialErrors.outcomes = "Please make sure to select 'Accept' for at least one option";
      } else {
        initialErrors.outcomes = '';
      }

      setLocalErrors(initialErrors);
      onQuestionChange({ id: questionId, errors: { options: !allOptionsValid, outcomes: !allOutcomesValid } });
    }
  }, [optionsAndOutcomes, localErrors, questionId, onQuestionChange]);

  /* Update option text */
  const handleOptionChange = useCallback(
    (newOptionText, index) => {
      let updatedOptions = cloneDeep(optionsAndOutcomes);
      updatedOptions[index].optionText = newOptionText;

      setOptionsAndOutcomes(updatedOptions);

      const allOptionsValid = updatedOptions?.every((option) => option?.optionText?.trim().length > 0);

      if (!allOptionsValid && !localErrors?.options) {
        setLocalErrors((prevLocalErrors) => ({
          ...prevLocalErrors,
          options: 'Please make sure none of your options are blank.',
        }));
      } else if (allOptionsValid && localErrors?.options) {
        setLocalErrors((prevLocalErrors) => ({ ...prevLocalErrors, options: '' }));
      }

      onQuestionChange({ id: questionId, errors: { options: !allOptionsValid } });
    },
    [optionsAndOutcomes, questionId, onQuestionChange, localErrors?.options, setOptionsAndOutcomes]
  );

  const handleAddOptionClick = () => {
    addCustomScreenerOption(
      { wevoId, filterId: questionId },
      {
        onSuccess: (filterData) => {
          // update optionsAndOutcomes
          const ids = new Set(optionsAndOutcomes.map((option) => option?.id));
          const newOptions = filterData?.options?.filter((option) => !ids.has(option?.id));
          setOptionsAndOutcomes((prevOptionsAndOutcomes) => [...prevOptionsAndOutcomes, ...newOptions]);

          // update errors - there will be an options error because the new option text will be empty
          setLocalErrors({
            ...localErrors,
            options: 'Please make sure none of your options are blank.',
            outcomes: '',
          });
          onQuestionChange({ id: questionId, errors: { options: true, outcomes: false } });
        },
        onError: (err) => {
          snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error adding custom screener option');
        },
      }
    );
  };

  const handleOptionDelete = useCallback(
    ({ optionId }) => {
      const prevOptionsAndOutcomes = Array.from(optionsAndOutcomes);
      const prevLocalErrors = { ...localErrors };
      const allPrevOptionsValid = prevOptionsAndOutcomes?.every(
        (option) => option?.optionText?.trim().length > 0
      );
      const allPrevOutcomesValid = !prevOptionsAndOutcomes?.every(
        (option) => option?.outcome === OutcomeOptions.Reject
      );
      //optimistic update
      const currentOptionsAndOutcomes = Array.from(optionsAndOutcomes);
      const updatedOptionsAndOutcomes = currentOptionsAndOutcomes?.filter((option) => option?.id !== optionId);
      setOptionsAndOutcomes(updatedOptionsAndOutcomes);

      const allOptionsValid = updatedOptionsAndOutcomes?.every(
        (option) => option?.optionText?.trim().length > 0
      );
      const allOutcomesValid = !updatedOptionsAndOutcomes?.every(
        (option) => option?.outcome === OutcomeOptions.Reject
      );

      let updatedErrors = {};
      if (!allOptionsValid) {
        updatedErrors.options = 'Please make sure none of your options are blank.';
      } else {
        updatedErrors.options = '';
      }

      if (!allOutcomesValid) {
        updatedErrors.outcomes = "Please make sure to select 'Accept' for at least one option";
      } else {
        updatedErrors.outcomes = '';
      }
      setLocalErrors((prevLocalErrors) => ({ ...prevLocalErrors, ...updatedErrors }));

      onQuestionChange({
        id: questionId,
        errors: { options: !allOptionsValid, outcomes: !allOutcomesValid },
      });

      deleteCustomScreenerOption(
        { wevoId, filterId: questionId, optionId },
        {
          onError: (err) => {
            setOptionsAndOutcomes(prevOptionsAndOutcomes);
            setLocalErrors(prevLocalErrors);
            onQuestionChange({
              id: questionId,
              errors: { options: !allPrevOptionsValid, outcomes: !allPrevOutcomesValid },
            });
            snackbar.error(
              err?.response?.data?.humanReadableMessage ?? 'Error deleting custom screener option'
            );
          },
        }
      );
    },
    [
      deleteCustomScreenerOption,
      wevoId,
      questionId,
      optionsAndOutcomes,
      localErrors,
      onQuestionChange,
      setOptionsAndOutcomes,
    ]
  );

  const handleOutcomeChange = useCallback(
    (newOutcome, index) => {
      let updatedOptions = cloneDeep(optionsAndOutcomes);
      updatedOptions[index].outcome = newOutcome;
      setOptionsAndOutcomes(updatedOptions);

      const outcomeChange = { id: updatedOptions[index]?.id, outcome: newOutcome };

      updateCustomScreener(
        {
          wevoId,
          filterId: questionId,
          changes: { options: [outcomeChange] },
        },
        {
          onError: (err) => {
            snackbar.error(
              err?.response?.data?.humanReadableMessage ?? 'Error updating custom screener option'
            );
          },
        }
      );

      const allOutcomesValid = !updatedOptions?.every((option) => option?.outcome === OutcomeOptions.Reject);
      if (!allOutcomesValid) {
        setLocalErrors((prevLocalErrors) => ({
          ...prevLocalErrors,
          outcomes: "Please make sure to select 'Accept' for at least one option",
        }));
      } else if (allOutcomesValid) {
        setLocalErrors((prevLocalErrors) => ({ ...prevLocalErrors, outcomes: '' }));
      }
      onQuestionChange({ id: questionId, errors: { outcomes: !allOutcomesValid } });
    },
    [updateCustomScreener, wevoId, questionId, optionsAndOutcomes, onQuestionChange, setOptionsAndOutcomes]
  );

  if (
    [
      CustomScreenerTypes.FiveLikertScale,
      CustomScreenerTypes.SevenLikertScale,
      CustomScreenerTypes.YesNoTrueFalse,
    ].includes(questionType) &&
    labelsType !== ScreenerSelectOptions.Custom
  ) {
    return (
      <>
        <PredefinedOptions
          options={optionsAndOutcomes}
          onOutcomeChange={(value, index) => handleOutcomeChange(value, index)}
        />
        <Box>
          <Typography color="error" sx={{ fontSize: '14px', marginLeft: '12px' }}>
            {localErrors?.options}
          </Typography>
          <Typography color="error" sx={{ fontSize: '14px', marginLeft: '12px' }}>
            {localErrors?.outcomes}
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <Grid container spacing={2} justifyContent="flex-end" sx={styles.multipleSection}>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          {optionsAndOutcomes?.map((choice, index) => (
            <Grid item key={choice?.id} xs={12} sx={styles.customChoice}>
              <Option
                wevoId={wevoId}
                questionId={questionId}
                optionId={choice?.id}
                optionText={choice?.optionText}
                outcome={choice?.outcome}
                onOptionChange={(value) => handleOptionChange(value, index)}
                onOptionDelete={() => handleOptionDelete({ optionId: choice?.id })}
                onOutcomeChange={(value) => handleOutcomeChange(value, index)}
                questionType={questionType}
                choicesLength={optionsAndOutcomes.length}
                disableDelete={isLoadingNewOption}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
      {optionsAndOutcomes?.length < MAX_CHOICES[questionType] && (
        <Grid item align="center" xs={12}>
          {isLoadingNewOption ? (
            <CircularProgress size={24} />
          ) : (
            <Button onClick={handleAddOptionClick} sx={styles.addIcon}>
              <Tooltip title={`add custom label`} placement="right">
                <AddCircleIcon sx={styles.addIcon} />
              </Tooltip>
            </Button>
          )}
        </Grid>
      )}
      <Grid item xs={12}>
        <Box>
          <Typography color="error" sx={{ fontSize: '14px', marginLeft: '12px' }}>
            {localErrors?.options}
          </Typography>
          <Typography color="error" sx={{ fontSize: '14px', marginLeft: '12px' }}>
            {localErrors?.outcomes}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CustomScreenerOptions;
