import { Button, Tooltip, Typography, styled } from '@mui/material';
import { ReactComponent as FeedbackIcon } from '../../assets/feedback.svg';

const PENDO_FEEDBACK_GUIDE_ID = 'UnVjcUFqabXtMdgWxOxi-1Zyuj4';

const StyledFeedbackButton = styled(Button)(({ theme }) => ({
  float: 'right',
  position: 'fixed',
  right: -16,
  top: 140,
  color: 'white',
  boxShadow: 4,
  textTransform: 'none',
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  paddingLeft: theme.spacing(1),
  borderRadius: 8,
  minWidth: 0,
  backgroundColor: 'rgba(38, 65, 81, 1)',
  '&:hover': {
    backgroundColor: 'rgba(38, 65, 81, 0.8)',
  },
  [theme.breakpoints.down('md')]: {
    // hide feedback button on mobile phones
    display: 'none',
  },
}));

const styles = {
  text: {
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
    fontWeight: 'bold',
    fontSize: '12px',
  },
  icon: {
    width: '24px',
    fill: '#FFFFFF',
    marginBottom: '8px',
    transform: 'rotate(90deg)',
  },
};

function handleOnClick() {
  window?.pendo?.showGuideById(PENDO_FEEDBACK_GUIDE_ID);
}

export const FeedbackButton = () => {
  const showText = 'Feedback';

  return (
    <Tooltip title={showText}>
      <StyledFeedbackButton variant="contained" onClick={handleOnClick}>
        <Typography variant="body2" sx={styles.text}>
          <FeedbackIcon style={styles.icon} />
          {showText}
        </Typography>
      </StyledFeedbackButton>
    </Tooltip>
  );
};
