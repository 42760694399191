import { ArcElement, Chart, DoughnutController } from 'chart.js';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import { memoAreEqual } from '../../../helpers';
import theme from '../../../theme';

Chart.register(DoughnutController, ArcElement);

const SegmentedHalfDoughtnutScoreGraph = React.memo((props) => {
  const { topLevelScore, scores, colors, maxScore = 100, isLowCds } = props;
  const canvasRef = useRef(null);
  const chartRef = useRef(null);

  // Embed top-level score in the center of the doughnut
  const plugins = useMemo(
    () => ({
      beforeDraw: (chart) => {
        const width = chart.width,
          height = chart.height,
          ctx = chart.ctx;

        ctx.restore();
        ctx.fillStyle = !isLowCds ? 'black' : theme.palette.error.cds;
        const fontSize = (height / 80).toFixed(2);
        ctx.font = `bold ${fontSize}em sans-serif`;

        const datasetScore = topLevelScore;

        const text = datasetScore,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height * 0.75;

        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    }),
    [topLevelScore, isLowCds]
  );

  const data = useMemo(
    () => ({
      datasets: [
        {
          data: [...scores, maxScore - topLevelScore],
          backgroundColor: [...colors, '#f0f0f0'],
          borderWidth: 0,
        },
      ],
    }),
    [colors, maxScore, scores, topLevelScore]
  );

  const config = useMemo(
    () => ({
      type: 'doughnut',
      data,
      options: {
        events: [],
        responsive: true,
        cutout: '60%',
        legend: {
          display: false,
        },
        plugins: {
          tooltip: { enabled: false },
        },
        rotation: -90,
        circumference: 180,
      },
      plugins: [plugins],
    }),
    [data, plugins]
  );

  useEffect(() => {
    if (canvasRef.current && !chartRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      chartRef.current = new Chart(ctx, config);
    } else if (chartRef.current) {
      chartRef.current.data = config?.data;
      chartRef.current.options = config?.options;

      chartRef.current.update();
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
        chartRef.current = null;
      }
    };
  }, [config]);

  return <canvas ref={canvasRef} />;
}, memoAreEqual);

SegmentedHalfDoughtnutScoreGraph.propTypes = {
  topLevelScore: PropTypes.number.isRequired,
  scores: PropTypes.array.isRequired,
  colors: PropTypes.array.isRequired,
  maxScore: PropTypes.number,
};

export default SegmentedHalfDoughtnutScoreGraph;
