import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';

const saveUserKeyFindings = ({ wevoId, findings, header }) => {
  return axios({
    url: `/api/v2/wevos/${wevoId}/key-findings`,
    method: 'POST',
    data: {
      findings,
      header,
    },
  });
};

export default function useSaveUserKeyFindings() {
  const queryClient = useQueryClient();
  return useMutation(saveUserKeyFindings, {
    onMutate: ({ findings, wevoId, header }) => {
      const previousUserKeyFindings = queryClient.getQueryData(['userKeyFindings', { wevoId }]);
      queryClient.setQueryData(['userKeyFindings', { wevoId }], (oldFindings) => ({
        ...oldFindings,
        finding: findings,
        header: header,
      }));
      return { previousUserKeyFindings };
    },
    onSuccess: (data, { wevoId }) => {
      queryClient.invalidateQueries(['keyFindings', { wevoId }]);
      queryClient.invalidateQueries(['userKeyFindings', { wevoId }]);
    },
    onError: (err, { wevoId }, { previousUserKeyFindings }) => {
      queryClient.setQueryData(['userKeyFindings', { wevoId }], previousUserKeyFindings);
    },
  });
}
