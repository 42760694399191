import { Box, Typography } from '@mui/material';

export default function AssetBadge({ text, height = 25, width = 25 }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: height,
        width: width,
        background: 'rgba(255, 255, 255, 1.0)',
        borderRadius: 1,
        border: '1px solid rgba(0, 0, 0, 0.35)',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.3)',
      }}>
      <Typography variant="caption" fontSize={{ xs: 10, sm: 12 }} fontWeight={500} color="rgba(0, 0, 0, 0.5)">
        {text}
      </Typography>
    </Box>
  );
}
