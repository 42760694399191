import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel, Typography } from '@mui/material';
import { flexRender } from '@tanstack/react-table';
import { memo } from 'react';

const MemoRow = memo(({ row }) => {
  return (
    <TableRow
      sx={{
        '& .show-on-hover': {
          visibility: 'hidden',
        },
        '&:hover .show-on-hover': {
          visibility: row.original.enableTagging ? '' : 'visible',
        },
      }}>
      {row.getVisibleCells().map((cell) => (
        <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
      ))}
    </TableRow>
  );
});

const DashboardTable = ({ headerGroups, rows }) => {
  return (
    <Table>
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableRow key={headerGroup.id}>
            {headerGroup.headers.map((header) => {
              if (header.column.getCanSort()) {
                const isActiveSort = Boolean(header.column.getIsSorted());
                const sortDirection = isActiveSort
                  ? header.column.getIsSorted()
                  : header.column.getFirstSortDir();

                return (
                  <TableCell key={header.id}>
                    <TableSortLabel
                      active={isActiveSort}
                      direction={sortDirection}
                      onClick={header.column.getToggleSortingHandler()}>
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </TableSortLabel>
                  </TableCell>
                );
              }
              return (
                <TableCell key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </TableCell>
              );
            })}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {!rows?.length && (
          <TableRow>
            <TableCell colSpan={6}>
              <Typography variant="h4" align="center">
                No wevos available
              </Typography>
            </TableCell>
          </TableRow>
        )}
        {rows.map((row) => (
          <MemoRow key={row.id} row={row} />
        ))}
      </TableBody>
    </Table>
  );
};

export default memo(DashboardTable);
