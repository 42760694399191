import { Box, Typography } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { WevoType } from '../../../../modules/wevos/constants';
import HeatmapWithQuotes from '../../components/HeatmapWithQuotes';
import useFetchPageHeatmap from '../../hooks/useFetchPageHeatmap';
import useFetchStepHeatmap from '../../hooks/useFetchStepHeatmap';
import HeatmapPreviewThumbnails from '../../components/HeatmapPreviewThumbnails';
import { useCallback, useMemo, useState } from 'react';
import { ClusterTypes } from '../../../../modules/report/constants';
import { Paths } from '../../../../routes';
import { generatePath } from 'react-router-dom';

const SentimentMap = ({
  wevo,
  page,
  step,
  showThumbnails,
  handleShareButtonClick,
  isLimitedReport,
  sentimentMapID,
  heatmapBgID,
}) => {
  const { data: pageHeatMapPoints, isSuccess: isSuccessPageHeatMap } = useFetchPageHeatmap(
    {
      wevoId: wevo?.id,
      pageId: page?.id,
    },
    { enabled: wevo?.type === WevoType.Classic }
  );
  const { data: stepHeatMapPoints, isSuccess: isSuccessStepHeatMap } = useFetchStepHeatmap(
    {
      wevoId: wevo?.id,
      stepId: step?.id,
    },
    { enabled: wevo?.type === WevoType.Journey }
  );

  let points;
  if (wevo?.type === WevoType.Journey) {
    if (isSuccessStepHeatMap && stepHeatMapPoints?.length > 0) {
      points = stepHeatMapPoints?.filter((point) => !_.isNil(point?.x) && !_.isNil(point?.y));
    }
  } else {
    if (isSuccessPageHeatMap && pageHeatMapPoints?.length > 0) {
      points = pageHeatMapPoints?.filter((point) => !_.isNil(point?.x) && !_.isNil(point?.y));
    }
  }

  const [clusterType, setClusterType] = useState(ClusterTypes.Likes);

  const generateSentimentMapPath = useCallback(
    (asset, index) => {
      let path = null;

      if (wevo.type === WevoType.Classic) {
        path = isLimitedReport ? Paths.reports.limitedSentimentMapPage : Paths.reports.sentimentMapPage;
      } else {
        path = isLimitedReport
          ? Paths.reports.limitedSentimentMapPageStep
          : Paths.reports.sentimentMapPageStep;
      }

      const data =
        wevo.type === WevoType.Classic
          ? { wevoId: wevo?.id, pageNum: index + 1 }
          : { wevoId: wevo?.id, pageNum: page.pageNumber, stepNum: index + 1 };

      return generatePath(path, data);
    },
    [isLimitedReport, page, wevo]
  );

  const thumbnails = useMemo(() => {
    if (!showThumbnails) {
      return null;
    }

    // this implementation assumes pages / steps are sorted
    const assets = (wevo.type === WevoType.Classic ? wevo?.pages : page?.steps) ?? [];
    const links = assets.map((asset, index) => generateSentimentMapPath(asset, index));

    return (
      <Box mb={1}>
        <HeatmapPreviewThumbnails
          wevo={wevo}
          selectedPage={page}
          selectedStep={step}
          selectedCluster={clusterType}
          handleAssetSelected={() => {}}
          links={links}
        />
      </Box>
    );
  }, [clusterType, generateSentimentMapPath, page, showThumbnails, step, wevo]);

  return (
    <>
      {points?.length > 0 && (
        <HeatmapWithQuotes
          title="How respondents engaged"
          wevo={wevo}
          page={page}
          step={step}
          heatmapPoints={points}
          showBorders
          showClusterTypes
          header={
            <Typography variant="body1" style={{ marginBottom: 32 }}>
              See which elements of your page resonated with visitors and which elements missed the mark
            </Typography>
          }
          thumbnails={thumbnails}
          handleShareButtonClick={handleShareButtonClick}
          isLimitedReport={isLimitedReport}
          sentimentMapID={sentimentMapID}
          heatmapBgID={heatmapBgID}
          handleClusterTypeChanged={setClusterType}
        />
      )}
    </>
  );
};

SentimentMap.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  step: PropTypes.object,
  showThumbnails: PropTypes.bool,
  handleShareButtonClick: PropTypes.func,
  isLimitedReport: PropTypes.bool,
};

export default SentimentMap;
