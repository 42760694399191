import { Grid, List, ListItem } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useLocation } from 'react-router-dom';
import { snackbar } from '../../../notifications';
import useAddCustomScreener from '../hooks/useAddCustomScreener';
import useFetchCustomScreenerQuestions from '../hooks/useFetchCustomScreenerQuestions';
import useUpdateCustomScreenerQuestions from '../hooks/useUpdateCustomScreenerQuestions';
import AddQuestionCard from './AddQuestionCard';
import CustomScreener from './CustomScreener';

const MAX_QUESTIONS = 5;

const CustomScreenerQuestions = ({ wevoId, onQuestionChange }) => {
  // HACK: Allow admins to bypass the hard cap limit of custom question groups.
  const useQuery = () => new URLSearchParams(useLocation()?.search);
  const query = useQuery();
  const forceNoLimit = query.get('noLimit') === 'true';

  const { data: screenerQuestions } = useFetchCustomScreenerQuestions({ wevoId });

  const { mutate: addCustomScreener, isLoading: isLoadingNewQuestion } = useAddCustomScreener();
  const { mutate: updateCustomScreenerQuestions } = useUpdateCustomScreenerQuestions();

  const updateScreenerQuestions = ({ changesToQuestions }) => {
    updateCustomScreenerQuestions({ wevoId: wevoId, changes: changesToQuestions });
  };

  const handleAddCustomScreener = useCallback(() => {
    addCustomScreener(
      { wevoId },
      {
        onError: (err) => {
          snackbar.error(
            err?.response?.data?.humanReadableMessage ?? 'Error adding a custom screener question'
          );
        },
      }
    );
  }, [addCustomScreener, wevoId]);

  const onDragEnd = (result) => {
    // make sure we drag items only on dragAndDrop area
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    let questionsWithSortOrder = screenerQuestions?.map((question) => ({
      id: question?.id,
      sortOrder: question?.sortOrder,
    }));

    const [itemToReorder] = questionsWithSortOrder.splice(sourceIndex, 1);
    questionsWithSortOrder.splice(destinationIndex, 0, itemToReorder);

    let sortOrder = 0;
    questionsWithSortOrder = questionsWithSortOrder.map((question) => {
      return {
        ...question,
        sortOrder: sortOrder++,
      };
    });

    updateScreenerQuestions({ changesToQuestions: questionsWithSortOrder });
  };

  const exceedsQuestionLimit = useMemo(() => {
    return !forceNoLimit && screenerQuestions?.length >= MAX_QUESTIONS;
  }, [forceNoLimit, screenerQuestions]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <DragDropContext onDragEnd={onDragEnd}>
          {/* note: droppableId must be a string */}
          <Droppable droppableId="ItemsList">
            {(provided) => (
              <List className="ItemsList" {...provided.droppableProps} ref={provided.innerRef}>
                {screenerQuestions?.map((question, index) => (
                  <Draggable
                    // note: draggableId must be a string
                    draggableId={String(question?.id)}
                    key={question?.id}
                    index={index}>
                    {(provided) => (
                      <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{ paddingX: 0 }}>
                        <CustomScreener
                          dragHandleProps={{ ...provided.dragHandleProps }}
                          wevoId={wevoId}
                          filterQuestion={question}
                          questionNumber={index + 1}
                          onQuestionChange={onQuestionChange}
                        />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
      <Grid item xs={12}>
        <AddQuestionCard
          text={'Add a custom screener question'}
          exceedsLimits={exceedsQuestionLimit}
          exceedsLimitTooltipText={'You have hit the limit on custom screeners for this test.'}
          onClick={handleAddCustomScreener}
          isLoading={isLoadingNewQuestion}
        />
      </Grid>
    </Grid>
  );
};

export default CustomScreenerQuestions;
