import axios from 'axios';
import _ from 'lodash';
import Cookies from 'universal-cookie';
import { redirectToLogin } from './modules/user/helpers';

const cookies = new Cookies();

const apiInstance = axios.create({
  baseURL: process.env.REACT_APP_CUSTOMER_API_HOST,
  withCredentials: true,
  crossDomain: true,
  responseType: 'json',
});

apiInstance.interceptors.request.use((config) => {
  const token = cookies.get('authToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

apiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    const status = _.get(err, 'response.status');
    if (status === 401) {
      cookies.remove('authToken', { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
      redirectToLogin();
    }
    throw err;
  }
);

export default apiInstance;
