import { useQuery } from 'react-query';

import api from '../api';

const fetchUsers = async ({ queryKey: [key, { searchQuery }] }) => {
  const response = await api({
    url: `/api/v2/users?searchQuery=${encodeURIComponent(searchQuery)}`,
    method: 'GET',
  });
  return response?.data;
};

export default function useFetchUsers({ searchQuery }, queryOptions) {
  return useQuery(['usersSearch', { searchQuery }], fetchUsers, {
    notifyOnChangeProps: 'tracked',
    ...queryOptions,
  });
}
