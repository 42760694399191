export const AutomatedInsightSections = {
  KeyFindings: 'key findings',
  SentimentMap: 'sentiment map',
  Audience: 'audience', // Personas rendered while key findings are generating
  MyAudience: 'my audience', // My Personas
  PersonaIntent: 'persona intent',
  RecommendedExperiments: 'recommended experiments',
  Chat: 'chat',
  Quant: 'quant',
};

export const AutomatedInsightSectionTabToFeatureKey = {
  findings: 'findings',
  sentiment: 'sentiment',
  intent: 'intent',
  recommendations: 'recommendations',
  score: 'score',
  assistant: 'chat',
};

export const AutomatedInsightSectionToDisplayName = {
  [AutomatedInsightSections.KeyFindings]: 'takeaways',
  [AutomatedInsightSections.SentimentMap]: 'sentiment',
  [AutomatedInsightSections.Audience]: 'personas',
  [AutomatedInsightSections.MyAudience]: 'my audience',
  [AutomatedInsightSections.PersonaIntent]: 'intent',
  [AutomatedInsightSections.RecommendedExperiments]: 'recommendations',
  [AutomatedInsightSections.Chat]: 'assistant',
  [AutomatedInsightSections.Quant]: 'score',
};

export const AutomatedInsightSectionToLongDisplayName = {
  [AutomatedInsightSections.KeyFindings]: 'takeaways',
  [AutomatedInsightSections.SentimentMap]: 'sentiment map',
  [AutomatedInsightSections.Audience]: 'personas',
  [AutomatedInsightSections.MyAudience]: 'my audience',
  [AutomatedInsightSections.PersonaIntent]: 'persona intent',
  [AutomatedInsightSections.RecommendedExperiments]: 'recommendations',
  [AutomatedInsightSections.Chat]: 'assistant',
  [AutomatedInsightSections.Quant]: 'score',
};

export const AutomatedInsightChatMenuItemToDisplayName = {
  [AutomatedInsightSections.KeyFindings]: 'findings',
  [AutomatedInsightSections.SentimentMap]: 'sentiment',
  [AutomatedInsightSections.Audience]: 'audience',
  [AutomatedInsightSections.MyAudience]: 'my audience',
  [AutomatedInsightSections.PersonaIntent]: 'intent',
  [AutomatedInsightSections.RecommendedExperiments]: 'recommendations',
  [AutomatedInsightSections.Chat]: 'chat',
  [AutomatedInsightSections.Quant]: 'score',
  TopRated: 'top-rated',
  EQSIntuitive: 'EQS Intuitive',
  EQSTrusted: 'EQS Trusted',
  EQSValuable: 'EQS Valuable',
};

export const MainContainerDimensions = {
  Height: 'calc(100vh - 160px)',
  MinHeight: 580,
};

export const MAX_PULSE_JOURNEY_ASSETS = 8;
export const MIN_PULSE_JOURNEY_ASSETS = 2;

export const MAX_PULSE_PAGE_ASSETS = 1;

export const TaskStatus = {
  Pending: 'pending',
  Enqueued: 'enqueued',
  Running: 'running',
  Failed: 'failed',
  Completed: 'completed',
};

export const ProgressKeys = {
  Initialized: 'initialized',
  TextExtract: 'text_extract',
  Insights: 'insights',
  Goal: 'goal',
  TargetAudience: 'target_audience',
  HeatmapPoints: 'heatmap_points',
  PersonaIntent: 'persona_intent',
  RecommendedExperiments: 'recommended_experiments',
  Quant: 'quant',
  Tags: 'tags',
  Done: 'done',
};

export const TaskType = {
  AutomatedInsightSession: 'automatedInsightSession',
  AutomatedInsightSessionAudience: 'automatedInsightSessionAudience',
};

export const ClusterTypes = {
  Likes: 'likes',
  Dislikes: 'dislikes',
  Contentious: 'contentious',
};

export const ClusterTypeNames = {
  Likes: 'likes',
  Dislikes: 'dislikes',
  Contentious: 'mixed',
};

export const SentimentTypes = {
  Positive: 'positive',
  Negative: 'negative',
  Contentious: 'contentious',
};

export const AboutWevoPulse = [
  {
    heading: 'Examples',
    items: ['Web page', 'App page', 'Figma page', 'Messaging', 'Email', 'Ad'],
  },
  {
    heading: 'Capabilities',
    items: [
      'Strengths and weaknesses',
      'Feedback on specific areas',
      'Custom questions',
      'Customized audience',
      'English, German, Spanish, French, Italian, Portuguese',
      'Visual/Image Analysis',
      'Anticipates User Intent',
    ],
  },
  {
    heading: 'Limitations',
    items: ['Input: Image files and URLs', 'Journeys: Sentiment, Intent, and Score coming soon'],
  },
];

export const CannotChatReason = {
  LimitExceeded: 'limit_exceeded',
  PendingMessage: 'pending_message',
  PulseNotReady: 'pulse_not_ready',
  NotOwner: 'not_owner',
};

export const DeviceNameToId = {
  Desktop: 1,
  Mobile: 2,
};

export const ChatDelayThresholdSeconds = 45;

export const AutomatedInsightSessionType = {
  Page: 'page',
  Experience: 'experience',
  Compare: 'compare',
};

export const AutomatedInsightSessionTypeToDisplayName = {
  [AutomatedInsightSessionType.Page]: 'Single Page',
  [AutomatedInsightSessionType.Experience]: 'Multipage Journey',
  [AutomatedInsightSessionType.Compare]: 'Comparison',
};

export const GroupedInitialProgressKeys = [
  ProgressKeys.Initialized,
  ProgressKeys.TextExtract,
  ProgressKeys.TargetAudience,
];

export const QuantDiagnostics = ['Intuitive', 'Trusted', 'Valuable'];

export const PercentileTypes = {
  Decile: 'decile',
  Quartile: 'quartile',
};

export const Percentiles = {
  [PercentileTypes.Decile]: {
    D1_3: 'bottom 30%',
    D4: 'bottom 40%',
    D5: 'bottom 50%',
    D6: 'top 50%',
    D7: 'top 40%',
    D8: 'top 30%',
    D9: 'top 20%',
    D10: 'top 10%',
  },
  [PercentileTypes.Quartile]: {
    Q1: 'bottom 25%',
    Q2: 'bottom 50%',
    Q3: 'top 50%',
    Q4: 'top 25%',
  },
};

export const AutomatedInsightQuantToChatMenu = {
  Intuitive: 'EQSIntuitive',
  Trusted: 'EQSTrusted',
  Valuable: 'EQSValuable',
};

export const QuantDiagnosticColors = {
  Intuitive: {
    primary: '#FF9900',
    secondary: '#FFF3DC',
    background: '#FFFCF6',
    button: '#FFE5B478',
    text: '#EB8D00',
  },
  Trusted: {
    primary: '#6775D1',
    secondary: '#F3F3FD',
    background: '#FAFAFE',
    button: '#E6E6FAD1',
    text: '#5161D0',
  },
  Valuable: {
    primary: '#3DA7C1',
    secondary: '#C7E8F6',
    background: '#F8FCFE',
    button: '#87CEEB5E',
    text: '#3293AA',
  },
};

export const getQuantScoreSubText = (score) => {
  if (score >= 95) {
    return 'High score unlocked: Practically perfect.';
  } else if (score >= 90) {
    return 'A digital masterpiece!';
  } else if (score >= 85) {
    return 'On the cusp of greatness';
  } else if (score >= 80) {
    return 'Nearly there';
  } else if (score >= 70) {
    return 'Time for a tuneup';
  } else if (score >= 60) {
    return 'Needs work';
  } else {
    return 'Needs work';
  }
};

export const getQuantChartColor = (score) => {
  if (score >= 90) {
    return { primary: '#89BE48', secondary: 'rgba(138, 190, 72, .1' };
  } else if (score >= 80) {
    return { primary: '#FFDB87', secondary: 'rgba(255, 219, 135, .1' };
  } else if (score >= 60) {
    return { primary: '#CC4A44', secondary: 'rgba(204, 74, 68, .1' };
  } else {
    return { primary: '#CC4A44', secondary: 'rgba(204, 74, 68, .1' };
  }
};

export const PULSE_LEGACY_CREDIT_SUBSCRIPTION_TYPES = ['indefinite', 'paid'];

export const PULSE_SUBSCRIPTION_TYPES = {
  PremiumMonth: 'premium_month',
  PremiumAnnual: 'premium_annual',
  InnovatorMonth: 'innovator_month',
  InnovatorAnnual: 'innovator_annual',
  EssentialMonth: 'essential_month',
  EssentialAnnual: 'essential_annual',
  Free: 'free',
  Enterprise: 'enterprise_placeholder',
};

export const PULSE_SUBSCRIPTION_TYPES_TO_HEADER = {
  [PULSE_SUBSCRIPTION_TYPES.PremiumMonth]: 'Premium (Month)',
  [PULSE_SUBSCRIPTION_TYPES.PremiumAnnual]: 'Premium (Annual)',
  [PULSE_SUBSCRIPTION_TYPES.InnovatorMonth]: 'Innovator (Month)',
  [PULSE_SUBSCRIPTION_TYPES.InnovatorAnnual]: 'Innovator (Annual)',
  [PULSE_SUBSCRIPTION_TYPES.EssentialMonth]: 'Essential (Month)',
  [PULSE_SUBSCRIPTION_TYPES.EssentialAnnual]: 'Essential (Annual)',
  [PULSE_SUBSCRIPTION_TYPES.Free]: 'Free',
  [PULSE_SUBSCRIPTION_TYPES.Enterprise]: 'Enterprise',
  free_trial_7_days: 'Innovator',
};

export const MonthlyPulseSubscriptionsTypes = [
  PULSE_SUBSCRIPTION_TYPES.Free,
  PULSE_SUBSCRIPTION_TYPES.EssentialMonth,
  PULSE_SUBSCRIPTION_TYPES.PremiumMonth,
  PULSE_SUBSCRIPTION_TYPES.InnovatorMonth,
  PULSE_SUBSCRIPTION_TYPES.Enterprise,
];

export const AnnuallyPulseSubscriptionsTypes = [
  PULSE_SUBSCRIPTION_TYPES.Free,
  PULSE_SUBSCRIPTION_TYPES.EssentialAnnual,
  PULSE_SUBSCRIPTION_TYPES.PremiumAnnual,
  PULSE_SUBSCRIPTION_TYPES.InnovatorAnnual,
  PULSE_SUBSCRIPTION_TYPES.Enterprise,
];

export const SeatsBillingMethods = {
  Annually: 'annually',
  Monthly: 'monthly',
};

export const QueryParams = {
  SegmentId: 'personaId',
  Section: 'section',
  ChatOpen: 'chatOpen',
  Benchmarks: 'benchmarks',
};

export const TabNameToSectionId = {
  [AutomatedInsightSections.KeyFindings]: 'key-findings',
  [AutomatedInsightSections.SentimentMap]: 'sentiment-map',
  [AutomatedInsightSections.PersonaIntent]: 'persona-intent',
  [AutomatedInsightSections.RecommendedExperiments]: 'recommended-experiments',
  [AutomatedInsightSections.Chat]: 'chat',
  [AutomatedInsightSections.MyAudience]: 'personas',
  [AutomatedInsightSections.Quant]: 'quant',
};

export const PulseTips = [
  "Live websites, prototypes, concepts, images, emails, ads. If it's digital, Pulse can handle it.",
  'Pulse can dive into a single page or explore an entire user journey. Want to test a journey? Just upload multiple images or URLs.',
  'Want to test a website that requires a password? No worries. Just take a screenshot of it and then upload the image to Pulse.',
  'Beyond text, Pulse analyzes layouts, colors, and even the imagery.',
  'Curious to learn what users think about your images? Just ask “Chat” for feedback on the image.',
  'Check out the “Sentiment Map” to gauge the emotional impact of your digital experience.',
  "Pulse's “Chat” is like having a dedicated researcher to ask anything, anytime.",
  'Check out the example questions to get you started.',
  'Pulse can analyze content in English, Spanish, Portuguese, German, Italian, and French.',
  'Want to see the findings or recommendations in a different language, too? Just ask “Chat” to translate to another language.',
  'Curious to learn what your competitors do well? Just type in their URL or upload an image of their digital content.',
  "Discover what drives your customers with Pulse's “Intent” feature. By knowing what they are looking for, you can serve them better.",
  'Stuck on what to do next? Use “Recommendations” to help kick start your next iteration.',
  'Pulse complements traditional user research methods. Perfect when you don’t have much time to spare.',
  'Ready to run a study with real humans? Click on the WEVO Pro button.',
  'Dealing with a pesky cookie consent banner? Visit the site, screenshot without the banner, and upload for a cleaner analysis.',
  "Don't want Pulse to focus on certain page parts, like those dense terms and conditions? Easy fix: Crop your screenshot to highlight what matters most.",
  'If you feel the insights are too general, create a tighter target persona and click on ‘regenerate’. The findings will get much more specific.',
  'Getting the persona right is key. If the persona that Pulse created isn’t right for you, you can re-write it and click on ‘regenerate findings’',
];

export const IncludeExperienceRecommendations = true;

export const TagTypes = {
  Asset: 'asset',
  Industry: 'industry',
  Product: 'product',
};

export const AllTagsOption = {
  id: '',
  name: 'All Types',
};

export const BenchmarkFilterTypes = [TagTypes.Asset, TagTypes.Industry, TagTypes.Product];

export const BlockedFeature = {
  SessionTypeSelector: 'session type selector',
  AssetUpload: 'asset upload',
  UrlImport: 'url import',
};

export const Links = {
  TakeAPulse: 'https://wevo.ai/takeapulse',
};
