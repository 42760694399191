import { useMutation, useQuery } from 'react-query';
import axios from '../../../api';

const generateImportURL = async ({ url, deviceId, code }) => {
  let response;
  if (code) {
    response = await axios({
      url: `/api/v2/automated-insight-sessions/import-url?code=${code}`,
      method: 'POST',
    });
  } else {
    response = await axios({
      url: `/api/v2/automated-insight-sessions/import-url`,
      method: 'POST',
      data: { url, deviceId },
    });
  }
  return response?.data;
};

export function useGenerateImportURL() {
  return useMutation(generateImportURL);
}

const fetchImportURL = async ({ queryKey: [key, { automatedInsightImportUrlId }] }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/import-url/${automatedInsightImportUrlId}`,
    method: 'GET',
  });
  return response?.data ?? null;
};

export function useFetchImportURL({ automatedInsightImportUrlId }, options) {
  return useQuery(
    ['automatedInsightSessionImportUrl', { automatedInsightImportUrlId }],
    fetchImportURL,
    options
  );
}

const fetchImportUrlTask = async ({ queryKey: [key, { automatedInsightImportUrlId }] }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/import-url/${automatedInsightImportUrlId}/task`,
    method: 'GET',
  });
  return response?.data.task ?? null;
};

export function useFetchImportUrlTask({ automatedInsightImportUrlId }, options) {
  return useQuery(
    ['automatedInsightSessionImportUrlTask', { automatedInsightImportUrlId }],
    fetchImportUrlTask,
    options
  );
}

const fetchImportUrlTasks = async ({ queryKey: [key, { automatedInsightImportUrlIds }] }) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions/import-url/task?automatedInsightImportUrlIds=${automatedInsightImportUrlIds.join(
      ','
    )}`,
    method: 'GET',
  });
  return response?.data.tasks ?? [];
};

export function useFetchImportUrlTasks({ automatedInsightImportUrlIds }, options) {
  return useQuery(
    ['automatedInsightSessionImportUrlTasks', { automatedInsightImportUrlIds }],
    fetchImportUrlTasks,
    options
  );
}

const createSessionFromURLs = async ({
  automatedInsightImportUrlId,
  urls,
  name,
  deviceId,
  type,
  linkSessionId,
}) => {
  const response = await axios({
    url: `/api/v2/automated-insight-sessions`,
    method: 'POST',
    data: { automatedInsightImportUrlId, urls, name, deviceId, type, linkSessionId },
  });

  return response?.data;
};

export function useCreateSessionFromURLs() {
  return useMutation(createSessionFromURLs);
}
