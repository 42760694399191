import { useQuery } from 'react-query';
import axios from '../../../api.js';

const fetchCustomScreeners = async ({ queryKey: [key, { wevoId }] }) => {
  if (!wevoId) {
    return [];
  }

  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/filters`,
    method: 'GET',
  });
  return data?.filters;
};

export default function useFetchCustomScreeners({ wevoId }) {
  return useQuery(['customScreeners', { wevoId }], fetchCustomScreeners, {
    notifyOnChangeProps: 'tracked',
  });
}
