import { CheckCircleOutline } from '@mui/icons-material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Tooltip,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useRef, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import { LIMITED_REPORT_PAGES, SHARE_REPORT_TYPE } from '../../../modules/report/constants';
import theme from '../../../theme';
import { TrackEvent } from '../../analytics';

const styles = makeStyles((theme) => ({
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  content: {
    padding: theme.spacing(0, 2),
  },
  link: {
    backgroundColor: theme.palette.background.default,
    borderRadius: '10px',
    padding: 0,
    marginTop: theme.spacing(2),
  },
  textField: {
    '& .MuiFilledInput-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiFilledInput-input': {
      padding: theme.spacing(1.5),
    },
  },
  iconContainer: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    color: (props) => (props.copySuccess ? '#4CAF50' : theme.palette.primary.main),
  },
  closeButton: {
    fontSize: '16px',
  },
}));

const SlideTransition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShareLinkDialog = (props) => {
  const { wevo, url, limitedReportUrl, callback, shareLinkDialogTrigger, setShareLinkDialogTrigger } = props;
  const textFieldRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const classes = styles({ copySuccess });

  const isLimitedReportSharePage = Boolean(LIMITED_REPORT_PAGES[shareLinkDialogTrigger.sharePage]);

  const { track } = useAnalytics();

  const copyToClipboard = () => {
    const copyText = textFieldRef.current.value;
    track(TrackEvent.COPIED_SHARE_LINK, {
      wevoId: wevo?.analyticsId,
      testType: wevo?.type,
      shareLimitedReport: shareLinkDialogTrigger.shareReportType === SHARE_REPORT_TYPE.limitedReport,
      shareURL: copyText,
    });
    navigator.clipboard.writeText(copyText);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  const handleClose = () => {
    setCopySuccess(false);
    !!callback && callback();
  };

  const handleShareReportType = (e) => {
    if (e.target.value === SHARE_REPORT_TYPE.entireReport) {
      setShareLinkDialogTrigger({
        ...shareLinkDialogTrigger,
        shareReportType: e.target.value,
        shareLink: url,
      });
    } else {
      setShareLinkDialogTrigger({
        ...shareLinkDialogTrigger,
        shareReportType: e.target.value,
        shareLink: limitedReportUrl,
      });
    }
  };

  return (
    <div>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={shareLinkDialogTrigger.open}
        TransitionComponent={SlideTransition}
        keepMounted
        onClose={handleClose}
        PaperProps={{
          style: {
            borderRadius: '20px',
            padding: theme.spacing(2),
          },
        }}>
        <DialogTitle className={classes.header}>Share Results</DialogTitle>
        <DialogContent className={classes.content}>
          <FormControl variant="standard" component="fieldset">
            <RadioGroup
              name="share-report"
              value={shareLinkDialogTrigger.shareReportType}
              onChange={(e) => handleShareReportType(e)}>
              <Tooltip
                title={
                  wevo?.useVersionedDiagnostics
                    ? 'Only dashboard and sentiment map are supported at this time'
                    : 'Only new reports are supported at this time'
                }
                arrow
                disableHoverListener={isLimitedReportSharePage && wevo?.useVersionedDiagnostics}>
                <FormControlLabel
                  value={SHARE_REPORT_TYPE.limitedReport}
                  control={<Radio color="primary" />}
                  disabled={!isLimitedReportSharePage || !wevo?.useVersionedDiagnostics}
                  label={
                    <>
                      This page - <em>limited view of results, no links.</em>
                    </>
                  }
                />
              </Tooltip>
              <FormControlLabel
                value={SHARE_REPORT_TYPE.entireReport}
                control={<Radio color="primary" />}
                label={
                  <>
                    Entire report - <em>access to all sections of results.</em>
                  </>
                }
              />
            </RadioGroup>
          </FormControl>
          <Grid container alignItems="center" className={classes.link}>
            <Grid item xs>
              <TextField
                fullWidth
                inputRef={textFieldRef}
                value={shareLinkDialogTrigger.shareLink}
                variant="filled"
                InputProps={{ disableUnderline: true, readOnly: true }}
                className={classes.textField}
              />
            </Grid>
            <Grid item>
              <IconButton
                className={classes.iconContainer}
                onClick={copyToClipboard}
                disableRipple
                size="large">
                {copySuccess ? (
                  <CheckCircleOutline className={classes.icon} />
                ) : (
                  <FileCopyIcon className={classes.icon} />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button className={classes.closeButton} onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShareLinkDialog;
