import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { ReactComponent as GoalIcon } from '../../../../assets/goal.svg';
import { Paths } from '../../../../routes';
import theme from '../../../../theme';

const styles = makeStyles((theme) => ({
  card: {
    borderRadius: '20px',
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    height: '100%',
  },
  goalIcon: {
    color: theme.palette.primary.main,
    width: '25px',
    height: '25px',
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
  },
  goalTextGrid: {
    marginLeft: theme.spacing(6.5),
    marginRight: theme.spacing(4),
    overflowWrap: 'break-all',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'wrap',
  },
  goalGrid: {
    alignItems: 'center',
  },
  wevoGoal: {
    color: ({ wevoDescription }) => (!wevoDescription ? theme.palette.primary.main : ''),
    fontSize: '12px',
  },
  goalContainer: {
    alignItems: 'flex-start',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  iconGrid: {
    marginRight: theme.spacing(2),
  },
  icon: {
    backgroundColor: grey[100],
    borderRadius: '50%',
    padding: '3px',
  },
  iconButton: {
    backgroundColor: grey[100],
  },
}));

const GoalCard = (props) => {
  const { wevo, isLimitedReport } = props;
  const wevoDescription = wevo?.description;
  const classes = styles({ wevoDescription });

  return (
    <Card className={classes.card} elevation={4}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1} justifyContent="center" className={classes.goalContainer}>
          <Grid item xs={12}>
            <Grid container className={classes.goalGrid}>
              <Grid item className={classes.iconGrid}>
                {isLimitedReport ? (
                  <Box className={classes.icon}>
                    <GoalIcon fill={theme.palette.primary.main} className={classes.goalIcon} />
                  </Box>
                ) : (
                  <IconButton size="small" className={classes.iconButton}>
                    <Link to={generatePath(Paths.reports.testDetails, { wevoId: wevo?.id })}>
                      <GoalIcon fill={theme.palette.primary.main} className={classes.goalIcon} />
                    </Link>
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h5">Goal</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={classes.goalTextGrid}>
            {wevoDescription ? (
              <Typography variant={wevoDescription ? 'body2' : 'h4'} className={classes.wevoGoal}>
                <b>Primary: </b>
                {`"${wevoDescription}"`}
              </Typography>
            ) : (
              <Typography variant="body2">No goals were submitted with this test</Typography>
            )}
          </Grid>
          {wevo?.secondaryTestGoal && (
            <Grid item xs={12} className={classes.goalTextGrid}>
              <Typography variant="body2" className={classes.wevoGoal}>
                <b>Secondary:</b> {wevo.secondaryTestGoal}
              </Typography>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default GoalCard;
