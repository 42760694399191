import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import {
  BUBBLE_MAP_DEFAULT_FILL_STYLE,
  BUBBLE_MAP_DEFAULT_STROKE_STYLE,
} from '../../../modules/report/constants';

export function drawBubbles(context, bubbles) {
  bubbles.forEach((bubble) => {
    const { x, y, r } = bubble;
    context.beginPath();
    context.arc(x, y, r, 0, 2 * Math.PI);
    context.fillStyle = BUBBLE_MAP_DEFAULT_FILL_STYLE;
    context.fill();
    context.strokeStyle = BUBBLE_MAP_DEFAULT_STROKE_STYLE;
    context.stroke();
  });
}

const BubbleMap = ({ clickPoints, width, height, imageLoaded }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (imageLoaded) {
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');

      // Clear previous bubbles
      context.clearRect(0, 0, width, height);

      // Draw bubbles
      drawBubbles(context, clickPoints);
    }
  }, [clickPoints, width, height, imageLoaded]);

  return <canvas ref={canvasRef} width={width} height={height} />;
};

BubbleMap.propTypes = {
  clickPoints: PropTypes.array,
  width: PropTypes.number,
  height: PropTypes.number,
  imageLoaded: PropTypes.bool,
};

export default BubbleMap;
