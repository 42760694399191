import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Box, styled } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getError } from '../../modules/error/selectors';
import { changePassword } from '../../modules/user/actions';
import { StyledTextField } from '../automated-insights/ui/TextField';

const StyledForm = styled('form')(({ theme }) => ({
  width: '100%', // Fix IE 11 issue.
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));

const PasswordForm = ({
  inputLabelStyles,
  inputBaseRootStyles,
  updateButtonStyles,
  textStyles,
  iconColor,
  ...props
}) => {
  const [showCurrPassword, setShowCurrPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [currPassword, setCurrPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isNewPasswordValid, setNewPasswordValid] = useState(true);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(true);

  useEffect(() => {
    if (!currPassword || !newPassword) {
      setSubmitButtonDisabled(true);
    } else {
      setSubmitButtonDisabled(false);
    }
  }, [currPassword, newPassword]);

  const handleClickShowCurrPassword = () => {
    setShowCurrPassword(!showCurrPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownPassword = (ev) => {
    ev.preventDefault();
  };

  const handleCurrPasswordChange = (ev) => {
    setCurrPassword(ev.target.value);
  };

  const handleNewPasswordChange = (ev) => {
    setNewPassword(ev.target.value);
  };

  const validateNewPassword = () => {
    setNewPasswordValid(!!newPassword && [...newPassword].length >= 10);
    return !!newPassword && [...newPassword].length >= 10;
  };

  const onSubmit = (ev) => {
    ev.preventDefault();
    if (!!validateNewPassword()) {
      props.changePassword({ currPassword, newPassword });
    } else {
      return false;
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box sx={{ marginTop: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Avatar sx={{ margin: 1, backgroundColor: (theme) => theme.palette.avatar.main }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h4" sx={{ ...textStyles }}>
          Change Your Password
        </Typography>
        <StyledForm noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledTextField
                variant="outlined"
                required
                fullWidth
                onChange={handleCurrPasswordChange}
                name="currPassword"
                label="Current Password"
                type={showCurrPassword ? 'text' : 'password'}
                id="currPassword"
                autoComplete="current-password"
                inputLabelStyles={inputLabelStyles}
                inputBaseRootStyles={inputBaseRootStyles}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowCurrPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="large"
                        color={iconColor ? iconColor : ''}>
                        {showCurrPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <StyledTextField
                variant="outlined"
                required
                fullWidth
                onChange={handleNewPasswordChange}
                name="newPassword"
                label="New Password"
                type={showNewPassword ? 'text' : 'password'}
                id="newPassword"
                autoComplete="new-password"
                error={!isNewPasswordValid}
                helperText={!isNewPasswordValid && 'Password must be at least 10 characters'}
                inputLabelStyles={inputLabelStyles}
                inputBaseRootStyles={inputBaseRootStyles}
                InputLabelProps={{
                  shrink: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="toggle password visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                        size="large"
                        color={iconColor ? iconColor : ''}>
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 6, mb: 2, ...updateButtonStyles }}
            onClick={onSubmit}
            disabled={submitButtonDisabled}>
            Change Password
          </Button>
        </StyledForm>
      </Box>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getError(state),
    isError: !!getError(state),
  };
};

const actionCreators = {
  changePassword,
};

export default connect(mapStateToProps, actionCreators)(PasswordForm);
