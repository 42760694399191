import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { showEmotionWords } from '../../../modules/wevos/constants';
import { Paths } from '../../../routes';
import PageAttributes from './PageAttributes';

const EmotionWordsContainer = ({ wevo }) => {
  if (!showEmotionWords(wevo)) {
    return <Redirect to={Paths.reports.dashboard} />;
  }

  const pageNums = wevo?.pages?.map((page) => page.pageNumber);
  const stepNums = wevo?.pages?.[0]?.steps?.map((step) => step.stepNumber) ?? [];
  const pagePath = `${Paths.reports.emotionWordsPage}(${pageNums.join('|')})`;
  const stepPath = `${pagePath}/steps/:stepNum(${stepNums.join('|')})`;

  return (
    <Switch>
      <Route
        exact
        path={stepPath}
        render={({ match: { params } }) => {
          const { pageNum, stepNum } = params;
          return (
            <PageAttributes
              wevo={wevo}
              page={wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum))}
              step={wevo?.pages?.[0]?.steps?.find((step) => Number(step.stepNumber) === Number(stepNum))}
            />
          );
        }}
      />
      <Redirect
        to={generatePath(Paths.reports.emotionWordsPageStep, {
          wevoId: wevo?.id,
          pageNum: pageNums[0],
          stepNum: stepNums[0],
        })}
      />
    </Switch>
  );
};

export default EmotionWordsContainer;
