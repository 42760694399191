import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as DiagnosticsIcon } from '../../../assets/diagnostics-dynamic.svg';
import { getDiagnosticsOrdering } from '../../../modules/wevos/constants';
import theme from '../../../theme';
import CompositeMetricsDiagnosticsBarGraph from './CompositeMetricsDiagnosticsBarGraph';
import CompositeMetricsSummary from './CompositeMetricsSummary';

const styles = makeStyles((theme) => ({
  paper: {
    backgroundColor: 'white',
    marginTop: ({ isDashboard }) => !isDashboard && theme.spacing(4),
    paddingLeft: ({ isDashboard }) => !isDashboard && theme.spacing(4),
    paddingRight: ({ isDashboard }) => !isDashboard && theme.spacing(4),
    borderRadius: '20px',
  },
  box: {
    paddingLeft: ({ isDashboard }) => isDashboard && theme.spacing(6),
    paddingRight: ({ isDashboard }) => isDashboard && theme.spacing(6),
  },
  diagnosticsIconGrid: {
    marginRight: theme.spacing(2),
  },
  diagnosticsIcon: {
    color: theme.palette.primary.main,
    width: '25px',
    height: '25px',
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
  },
  icon: {
    backgroundColor: grey[100],
    borderRadius: '50%',
    padding: '3px',
  },
  iconButton: {
    backgroundColor: grey[100],
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    textAlign: 'end',
  },
  underline: {
    textDecoration: 'none',
  },
  rightArrow: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  subText: {
    color: theme.palette.primary.dark,
  },
}));

const CompositeMetricsChart = (props) => {
  const classes = styles(props);

  const {
    cardTitle,
    compositeMetrics,
    compositeMetricsThemeNames,
    compositeMetricsColors,
    selectedCompositeMetrics,
    backgroundColor,
    hoverBackgroundColor,
    diagnostics,
    onBarChartClick,
    onScoreCardClick,
    wevo,
    path,
    isDashboard,
    isLimitedReport,
    isLowCds,
  } = props;

  const hasCompositeMetrics = compositeMetrics.filter((c) => !_.isNil(c?.score)).length > 0;
  const hasDiagnostics = diagnostics.filter((d) => !_.isNil(d?.score)).length > 0;
  const diagnosticOrdering = getDiagnosticsOrdering(wevo?.metricType);

  return (
    <Card className={classes.paper} elevation={4}>
      <CardContent>
        {isDashboard && (
          <Grid container className={classes.noWrap}>
            <Grid item className={classes.diagnosticsIconGrid}>
              {isLimitedReport ? (
                <Box className={classes.icon}>
                  <DiagnosticsIcon fill={theme.palette.primary.main} className={classes.diagnosticsIcon} />
                </Box>
              ) : (
                <IconButton size="small" className={classes.iconButton}>
                  <Link to={path}>
                    <DiagnosticsIcon fill={theme.palette.primary.main} className={classes.diagnosticsIcon} />
                  </Link>
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <Typography variant="h5">
                <b>{cardTitle}</b>
              </Typography>
              <Typography variant="caption" className={classes.subText}>
                The journey diagnostics are based on visitors' impression of the entire journey
              </Typography>
            </Grid>
          </Grid>
        )}
        <Box pt={isDashboard ? 4 : 2} />
        {hasCompositeMetrics && (
          <Box mb={isDashboard ? 4 : 8} className={classes.box}>
            <CompositeMetricsSummary
              compositeMetrics={compositeMetrics}
              compositeMetricsColors={compositeMetricsColors}
              compositeMetricsThemeNames={compositeMetricsThemeNames}
              onScoreCardClick={onScoreCardClick}
              selectedCompositeMetrics={selectedCompositeMetrics}
              isDashboard={isDashboard}
              isLowCds={isLowCds}
            />
          </Box>
        )}

        {hasDiagnostics && (
          <Box className={classes.box}>
            <CompositeMetricsDiagnosticsBarGraph
              diagnostics={diagnostics}
              diagnosticOrdering={diagnosticOrdering}
              onBarSelected={onBarChartClick}
              tooltipPlacement={'left-start'}
              backgroundColor={backgroundColor}
              hoverBackgroundColor={hoverBackgroundColor}
              isDashboard={isDashboard}
            />
          </Box>
        )}
        {isDashboard && !isLimitedReport && (
          <Grid item xs={12}>
            <Link to={path} className={classes.underline}>
              <Typography variant="body2" className={classes.link}>
                See {cardTitle}
                <ChevronRightIcon fontSize="small" className={classes.rightArrow} />
              </Typography>
            </Link>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

CompositeMetricsChart.propTypes = {
  compositeMetrics: PropTypes.array.isRequired,
  compositeMetricsThemeNames: PropTypes.array.isRequired,
  compositeMetricsColors: PropTypes.object.isRequired,
  diagnostics: PropTypes.array.isRequired,
  onBarChartClick: PropTypes.func,
  onScoreCardClick: PropTypes.func,
  selectedCompositeMetrics: PropTypes.array,
  wevo: PropTypes.object,
  cardTitle: PropTypes.string,
  path: PropTypes.string,
  isDashboard: PropTypes.bool,
};

export default CompositeMetricsChart;
