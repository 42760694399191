import { Box, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Metrics } from '../../../modules/intake/constants';
import * as UserActions from '../../../modules/user/actions';
import { getUserProfile } from '../../../modules/user/selectors';
import * as WevoActions from '../../../modules/wevos/actions';
import { getActiveWevos } from '../../../modules/wevos/selectors';

const styles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
  subText: {
    marginLeft: theme.spacing(5),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(-0.5),
  },
  bullet: {
    marginLeft: theme.spacing(1),
  },
  subHeader: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1.25),
    fontSize: '12px',
  },
  subHeaderGrid: {
    backgroundColor: '#e1e9f2',
    borderRadius: 3,
    height: '40px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  linkText: {
    fontSize: '12px',
  },
  text: {
    color: 'grey',
  },
}));

const MetricSelection = (props) => {
  const { selectedMetric, handleMetricChange, enabledDQS, enabledCDS } = props;
  const classes = styles();

  return (
    <FormControl variant="standard" component="fieldset" className={classes.root}>
      <RadioGroup
        name="select-metric"
        value={selectedMetric}
        size="small"
        onChange={(ev) => handleMetricChange(ev.target.value)}>
        <Box className={classes.subHeaderGrid}>
          <Typography variant="h6" className={classes.subHeader}>
            Exploratory Research
          </Typography>
        </Box>
        <FormControlLabel
          value={Metrics.Wevo}
          control={<Radio color="primary" />}
          label={
            <Typography variant="h5" className={classes.bullet}>
              Standard WEVO
            </Typography>
          }
        />
        <Box className={classes.subText}>
          <Typography variant="caption" className={classes.text}>
            <span>Test how your audience reacts to the overall experience of your product/service.</span>
            <Box pt={2} />
            <span>For exploratory purposes.</span>
          </Typography>
        </Box>
        <Box className={classes.subHeaderGrid}>
          <Typography variant="h6" className={classes.subHeader}>
            Mastercard CX Metrics
          </Typography>
        </Box>

        {enabledCDS && (
          <>
            <FormControlLabel
              value={Metrics.CDS}
              control={<Radio color="primary" />}
              label={
                <Typography variant="h5" className={classes.bullet}>
                  Concept Desirability Score
                </Typography>
              }
            />
            <Box className={classes.subText}>
              <Typography variant="caption" className={classes.text}>
                <span> Test how your audience reacts to the concept of your product/service.</span>
                <Box pt={2} />
                <span>
                  Please note: in order to run a CDS, you should have developed and tested concepts, performed
                  a concept deep dive and are preparing for Product Council review prior to Studio's Prototype
                  phase.
                </span>
                <Box pt={1} />
              </Typography>
              <Box pt={2} />
              <a
                href={'https://mastercard.sharepoint.com/sites/ConceptDesirabilityScore'}
                target="_blank"
                rel="noreferrer"
                className={classes.link}>
                <Typography className={classes.linkText}>Learn More</Typography>
              </a>
            </Box>
          </>
        )}

        {enabledDQS && (
          <>
            <FormControlLabel
              value={Metrics.DQS}
              control={<Radio color="primary" />}
              label={
                <Typography variant="h5" className={classes.bullet}>
                  Design Quality Score
                </Typography>
              }
            />
            <Box className={classes.subText}>
              <Typography variant="caption" className={classes.text}>
                <span>Test how your audience begins to interact with the design of your product/service.</span>
                <Box pt={2} />
                <span>
                  Please note: in order to run a DQS, you should have created and tested your experience
                  prototype, define, or are in the process of defining, your MVP and are preparing for Product
                  Council prior to the Studio's Market Test phase.
                </span>
              </Typography>
              <Box pt={2} />
              <a
                href={'https://mastercard.sharepoint.com/sites/DesignQualityScoreDQS9'}
                target="_blank"
                rel="noreferrer"
                className={classes.link}>
                <Typography className={classes.linkText}>Learn More</Typography>
              </a>
            </Box>
          </>
        )}
      </RadioGroup>
    </FormControl>
  );
};

MetricSelection.propTypes = {
  selectedMetric: PropTypes.string,
  handleMetricChange: PropTypes.func.isRequired,
  enabledDQS: PropTypes.bool,
  enabledCDS: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    wevos: getActiveWevos(state),
    user: getUserProfile(state),
  };
};

const actionCreators = { fetchWevos: WevoActions.fetchWevos, fetchUser: UserActions.fetchUserInfo };

export default connect(mapStateToProps, actionCreators, null, { forwardRef: true })(MetricSelection);
