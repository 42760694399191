import { useState, useEffect } from 'react';

export const HOTJAR_NAME = 'hotjar-init-script';

export const HOTJAR_SCRIPT =
  "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:3851377,hjsv:6}; a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";

export const useScript = (name, url, innerText, opts) => {
  const [lib, setLib] = useState({});

  useEffect(() => {
    if (!url && !innerText) {
      return;
    }

    if (lib?.[name]) {
      return;
    }

    if ('enabled' in opts && (opts?.enabled === false || opts?.enabled === undefined)) {
      return;
    }

    const script = document.createElement('script');

    script.id = name;

    if (url) {
      script.src = url;
    }

    if (innerText) {
      script.innerText = innerText;
    }

    if (opts?.async) {
      script.async = opts.async;
    }

    if (opts?.crossOrigin) {
      script.crossOrigin = opts.crossOrigin;
    }

    if (opts?.nonce) {
      script.nonce = opts.nonce;
    }

    script.onload = () => setLib({ [name]: window[name] });

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opts?.enabled]);

  return lib;
};
