import { Alert, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import { Components } from '../../../modules/report/constants.js';

const useWarningBannerStyles = makeStyles((theme) => ({
  alert: {
    justifyContent: 'center',
    alignItems: 'center',
    maxHeight: '48px',
    lineHeight: '20px',
    borderRadius: '0px',
  },
  alertText: {
    fontWeight: 'bold',
    fontSize: '16px',
    justifyItems: 'center',
  },
}));

const WarningBanner = (props) => {
  const { component, severity, color } = props;
  const classes = useWarningBannerStyles(props);

  switch (component) {
    case Components.Report:
      return (
        <Grid item xs={12}>
          <Alert severity={severity} color={color} className={classes.alert}>
            <div className={classes.alertText}>
              Note: You are in preview mode. You are viewing a report that has not been fully completed
            </div>
          </Alert>
        </Grid>
      );

    default:
      return null;
  }
};

WarningBanner.propTypes = {
  component: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};

export default WarningBanner;
