import { Close } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import ScrollLock from 'react-scroll-lock-component/lib/ScrollLock';
import { sentimentBackgroundColor, sentimentBorderColor } from '../../modules/automated-insights/helpers.js';
import { MainContainerDimensions } from '../../modules/automated-insights/constants.js';

const styles = {
  container: {
    overflow: 'auto',
    backgroundColor: '#FFFFFF',
    height: MainContainerDimensions.Height,
    minHeight: MainContainerDimensions.MinHeight,
    borderRadius: '20px',
    '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
      display: 'none', // chrome and safari
    },
    msOverflowStyle: 'none', // IE and edge
    scrollbarWidth: 'none', // firefox
  },
  wrapper: {
    position: 'relative',
  },
  image: {
    width: '100%',
    borderRadius: '20px',
    zIndex: 0,
  },
  heatmap: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  removeButton: {
    marginTop: '2px',
    fontSize: '10px',
    paddingX: '10px',
    paddingY: '2px',
    backgroundColor: 'rgba(8, 39, 57, 0.80)',
    borderRadius: '20px',
    textTransform: 'none',
    zIndex: 10,
    '&:hover': {
      backgroundColor: 'rgba(8, 39, 57, 0.80)',
    },
  },
};

const NUM_TO_SHIFT_POSITION = -3;
const NUM_TO_EXTEND_DIMENSION = 6;

const Heatmap = ({ data, selectedQuote, selectedLocation, onClear, onSelection }) => {
  const handleRegionClick = (location) => {
    onSelection(location);
  };

  const handleButtonClick = () => {
    onClear();
  };

  return (
    <>
      {data?.map((location, index) => {
        return (
          <div
            key={index}
            style={{
              position: 'absolute',
              left: location.x + NUM_TO_SHIFT_POSITION,
              top: location.y + NUM_TO_SHIFT_POSITION,
              width: location.width + NUM_TO_EXTEND_DIMENSION,
              height: location.height + NUM_TO_EXTEND_DIMENSION,
              backgroundColor: sentimentBackgroundColor(location?.sentiment),
              border: `1px solid ${sentimentBorderColor(location?.sentiment)}`,
              borderRadius: '5px',
              cursor: 'pointer',
            }}>
            <div
              onClick={() => handleRegionClick(location)}
              style={{
                width: location.width + NUM_TO_EXTEND_DIMENSION,
                height: location.height + NUM_TO_EXTEND_DIMENSION,
              }}
            />
            {(location.blockId === selectedQuote?.block?.id ||
              location.blockId === selectedLocation?.blockId) && (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  startIcon={<Close style={{ fontSize: '10px', marginRight: '-4px' }} />}
                  onClick={handleButtonClick}
                  disableRipple
                  sx={styles.removeButton}>
                  Remove
                </Button>
              </Box>
            )}
          </div>
        );
      })}
    </>
  );
};

const SentimentMap = ({ image, data, selectedQuote, selectedLocation, onClear, onSelection }) => {
  let resizeTimer = useRef();
  const highlightedPointRef = useRef();

  const [imageLoaded, setImageLoaded] = useState(false);
  const [scaleFactor, setScaleFactor] = useState(null);
  const [isResizing, setIsResizing] = useState(false);

  const { width, height, ref } = useResizeDetector();
  const containerRef = useRef();
  const imageRef = useRef();

  const scaledPoints = useMemo(() => {
    if (!data || !scaleFactor) {
      return;
    }

    return data.map((unscaledPoint) => {
      // We're showing the location of each group of quotes rather than the point for each individual quote
      const location = unscaledPoint.location;
      const blockId = unscaledPoint?.points[0]?.block?.id;
      return {
        blockId: blockId,
        x: Math.round(location.x * scaleFactor),
        y: Math.round(location.y * scaleFactor),
        width: Math.round(location.width * scaleFactor),
        height: Math.round(location.height * scaleFactor),
        points: unscaledPoint.points,
        sentiment: unscaledPoint.sentiment,
      };
    });
  }, [data, scaleFactor]);

  useEffect(() => {
    // This method is called when the component and its children are finished rendering. At this point we will have
    // references to the image & container elements so we can force the browser to scroll if necessary.
    scrollHighlightedPointIntoView();
  });

  const scrollHighlightedPointIntoView = () => {
    // Scroll to the location of the point for the selected quote if there is one selected
    if (!selectedQuote || !highlightedPointRef?.current) {
      return;
    }

    const pointElement = highlightedPointRef.current;

    const pointTop = pointElement.offsetTop;
    const pointBottom = pointTop + pointElement.offsetHeight;

    const viewportTop = containerRef?.current?.scrollTop;
    const viewportBottom = viewportTop + containerRef?.current?.clientHeight;

    // If the point is not in the viewport, we try to center it in the viewport
    if (pointTop < viewportTop || pointBottom > viewportBottom) {
      const elementCenter = pointTop + pointElement.offsetHeight / 2;
      const containerCenter = containerRef?.current?.clientHeight / 2;

      // Enable smooth scrolling while we auto-scroll and revert to the configured value when we're done. Smooth
      // scrolling looks good when we're driving the scrolling, but it's awkward when the user's doing it.
      const scrollBehavior = containerRef?.current?.style.scrollBehavior;
      containerRef.current.style.scrollBehavior = 'smooth';
      containerRef.current.scrollTop = elementCenter - containerCenter;
      containerRef.current.style.scrollBehavior = scrollBehavior;
    }
  };

  const computeScaleFactor = useCallback(() => {
    if (imageRef?.current) {
      const scaleFactor = imageRef?.current.clientWidth / image?.width;
      setScaleFactor(scaleFactor);
    }
  }, [image?.width]);

  const handleImageLoad = () => {
    setImageLoaded(true);
    computeScaleFactor();
  };

  const handleWindowResize = useCallback(() => {
    // The heatmap only sets its width & height properties when it's mounted. If we want to update those values,
    // we have to unmount the heatmap and remount it. That means we need to render this component without the
    // heatmap and then render again with it. That's what this code aims to do.
    setIsResizing(true);
    if (resizeTimer.current) {
      clearTimeout(resizeTimer.current);
      resizeTimer.current = null;
    }
    resizeTimer.current = setTimeout(() => setIsResizing(false), 250);
    computeScaleFactor();
  }, [computeScaleFactor]);

  useEffect(() => {
    handleWindowResize();
  }, [height, width, handleWindowResize]);

  const renderHeatmap = useMemo(() => {
    // We don't have enough information to size the heatmap canvas yet
    if (isResizing || !imageLoaded || !scaledPoints) {
      return null;
    }

    return (
      <Heatmap
        data={scaledPoints}
        pointRef={highlightedPointRef}
        selectedQuote={selectedQuote}
        selectedLocation={selectedLocation}
        height={imageRef?.current.clientHeight}
        onClear={onClear}
        onSelection={onSelection}
      />
    );
  }, [isResizing, imageLoaded, scaledPoints, selectedQuote, selectedLocation, onClear, onSelection]);

  const renderHighlightedPoint = () => {
    if (!selectedQuote) {
      return null;
    }

    // We don't have enough information to scale the highlighted point(s) yet
    if (isResizing || !imageLoaded || scaleFactor === null) {
      return null;
    }

    // We're not rendering anything visible - we just want to scroll to that region
    const left = Math.round(selectedQuote.x * scaleFactor);
    const top = Math.round(selectedQuote.y * scaleFactor);
    return (
      <div
        key={`point(${left}, ${top})`}
        style={{
          position: 'absolute',
          left: left,
          top: top,
        }}
        ref={highlightedPointRef}></div>
    );
  };

  return (
    <ScrollLock>
      <Box sx={styles.container} ref={containerRef}>
        <div ref={ref} style={styles.wrapper}>
          {
            // <img> is the only statically-positioned element so it defines the size of the <div>
            // container. We don't know how big the container is going to be until the image finishes
            // loading, so we trigger an update when that happens.
          }
          <img
            style={styles.image}
            src={image?.url}
            onLoad={handleImageLoad}
            ref={imageRef}
            alt="page screenshot"
            id="sentiment-map-bg"
          />
          <div style={styles.heatmap}>
            {renderHeatmap}
            {renderHighlightedPoint()}
          </div>
        </div>
      </Box>
    </ScrollLock>
  );
};

export default SentimentMap;
