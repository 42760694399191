import queryString from 'query-string';
import { useQuery } from 'react-query';
import axios from '../../../api';
import { BenchmarkScope } from '../../../modules/wevos/constants';

/**
 * Fetch benchmarks for a given wevo and scope.
 *
 * If scope is null, then fetch the benchmarks that the wevo was published with.
 */
const fetchBenchmarks = async ({ queryKey: [key, { wevoId, pageId, scope }] }) => {
  const queryParams = {};
  if (scope && scope.scopeType !== BenchmarkScope.Default) {
    queryParams.scopeType = scope.scopeType;
    queryParams.scopeId = scope.scopeId;
  }

  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/benchmarks?${queryString.stringify(queryParams)}`,
    method: 'GET',
  });
  return data ?? {};
};

export default function useBenchmarks(wevoId, pageId, scope = null, options) {
  return useQuery(['benchmarksData', { wevoId, pageId, scope }], fetchBenchmarks, options);
}
