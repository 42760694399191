import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import ShareButton from '../../components/ShareButton';
import useFetchExperience from '../../../../hooks/useExperience';
import useFetchExperienceOutcomes from '../../hooks/useExperienceOutcomes';
import useFetchUsabilityScores from '../../hooks/useFetchUsabilityScores';
import ExperienceSentimentMap from '../../sentiment-map/components/ExperienceSentimentMap';
import ExperienceClickMap from './ExperienceClickMap';
import ExperiencePaths from './ExperiencePaths';
import MetricsCard from './MetricsCard';
import RespondentsCard from './RespondentsCard';
import TaskToCompleteCard from './TaskToCompleteCard';
import { useTrackPageLoad } from '../../../analytics';

const Experience = ({ wevo, page, handleShareButtonClick, isLimitedReport }) => {
  const { data: experience, isLoading } = useFetchExperience({
    wevoId: wevo?.id,
    pageId: page?.id,
  });

  const { data: usabilityScores } = useFetchUsabilityScores(
    {
      wevoId: wevo?.id,
      pageId: page?.id,
    },
    { enabled: Boolean(experience) }
  );

  const { data: outcomes } = useFetchExperienceOutcomes(
    {
      wevoId: wevo?.id,
      pageId: page?.id,
      experienceId: experience?.id,
    },
    { enabled: Boolean(experience) }
  );

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_EXPERIENCE_MAP,
    properties: {
      wevoId: wevo?.analyticsId,
    },
  });

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!experience || !usabilityScores || !outcomes) {
    return <></>;
  }

  return (
    <>
      <Box display="grid" justifyContent="flex-end" marginBottom={2}>
        {!isLimitedReport && <ShareButton handleShareButtonClick={handleShareButtonClick} />}
      </Box>
      <Box>
        <Typography variant="body1">
          See how the audience navigates your experience and how successfully they complete a task
        </Typography>
        <Box py={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={9} sx={{ minHeight: '120px' }}>
              <TaskToCompleteCard wevo={wevo} />
            </Grid>
            <Grid item xs={12} lg={3} sx={{ minHeight: '120px' }}>
              <RespondentsCard wevo={wevo} page={page} />
            </Grid>
          </Grid>
          <Box pt={2} />
          <Grid container spacing={2}>
            <Grid item xs={12} sx={{ minHeight: '160px' }}>
              <MetricsCard usabilityScores={usabilityScores} experience={experience} />
            </Grid>
            <Grid item xs={12}>
              <ExperienceSentimentMap wevo={wevo} page={page} showThumbnails={true} />
            </Grid>
            <Grid item xs={12}>
              <ExperiencePaths usabilityScores={usabilityScores} experience={experience} outcomes={outcomes} />
            </Grid>
            <Grid item xs={12}>
              {experience && (
                <ExperienceClickMap wevo={wevo} page={page} experience={experience} outcomes={outcomes} />
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

Experience.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  handleShareButtonClick: PropTypes.func,
  isLimitedReport: PropTypes.bool,
};

export default Experience;
