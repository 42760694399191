import { Box, Grid, Paper } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import {
  EXPERIENCE_SCORE_BAR_CHART_COLORS,
  EXPERIENCE_SCORE_COLORS,
  EXPERIENCE_SCORE_THEME_NAMES,
  EXPERIENCE_SCORE_TOP_LEVEL_NAME,
} from '../../../modules/report/constants';
import { orderCompositeMetrics } from '../../../modules/report/helpers';
import { filterQuotes, Sentiments } from '../../../modules/wevos/quotes';
import {
  buildAudienceFilterProperties,
  buildWordFilterProperties,
  TrackEvent,
  useTrackPageLoad,
} from '../../analytics';
import { shouldTrackAudienceFilterChange } from '../../analytics/eventPropertyHelpers';
import CompositeMetricsChart from '../components/CompositeMetricsChart';
import { DiagnosticChip, SentimentChip } from '../components/QuoteChip';
import QuotesTable, { GroupedQuoteTableChipContainer } from '../components/QuotesTable';
import { DiagnosticWordFilter, SentimentFilter } from '../components/QuoteWordFilter';
import { RightDrawerButton } from '../components/RightDrawer';
import TopLevelScoreCard from '../dashboard/components/TopLevelScoreCard';
import useCompositeScores from '../hooks/useCompositeScores';
import useDiagnostics from '../hooks/useDiagnostics';

const ExperienceScore = (props) => {
  const { wevo, page, rightDrawerOpen, handleRightDrawerOpenClick } = props;

  const [quotes, setQuotes] = useState([]);
  const [filters, setFilters] = useState({ diagnostics: [], sentiments: [] });

  const { data } = useDiagnostics(
    { wevoId: wevo?.id, pageId: page?.id },
    {
      onSuccess: (data) => {
        setQuotes(data?.quotes);
      },
    }
  );

  const compositeMetricScores = useCompositeScores({
    wevoId: wevo.id,
    pageId: page.id,
  });

  const diagnostics = useMemo(() => data?.diagnostics ?? [], [data]);
  const compositeScores = useMemo(
    () => compositeMetricScores?.data?.compositeMetrics ?? [],
    [compositeMetricScores?.data]
  );
  const compositeScoresByName = useMemo(
    () =>
      compositeScores.reduce((acc, cur) => {
        acc[cur.name] = cur;
        return acc;
      }, {}),
    [compositeScores]
  );

  const filteredQuotes = useMemo(() => filterQuotes(quotes, filters), [quotes, filters]);
  const selectedDiagnostics = useMemo(() => filters.diagnostics, [filters]);
  const selectedSentiments = useMemo(() => filters.sentiments, [filters]);
  const allWords = useMemo(() => diagnostics.map((diagnostic) => diagnostic.name), [diagnostics]);
  const [selectedCompositeMetrics, setSelectedCompositeMetrics] = useState([]);

  const { track } = useAnalytics();

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_JOURNEY_DIAGNOSTICS,
    properties: { wevoId: wevo?.analyticsId, pageId: page?.id },
  });

  const onQuoteUpdate = ({ id, updates }) => {
    const quoteIndex = quotes.findIndex((quote) => quote.id === id);
    const updatedQuote = { ...quotes[quoteIndex], ...updates };
    const updatedQuotes = [...quotes];
    updatedQuotes[quoteIndex] = updatedQuote;
    setQuotes(updatedQuotes);
  };

  // Called by QuoteSearch if search query has changed.
  const onSearchChange = (searchQuery) => {
    if (searchQuery) {
      track(TrackEvent.SEARCHED_QUOTE, {
        searchQuery,
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      });
    }

    setFilters((filters) => ({
      ...filters,
      search: searchQuery,
    }));
  };

  // Called by QuoteAudienceFilter if audience filters are changed.
  const onAudienceFilterChange = (audienceFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      audienceFilters,
      filters.audience,
      segmentToAudienceMap,
      {
        wevoId: wevo?.id,
        pageId: page?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, audienceFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_AUDIENCE, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      audience: audienceFilters,
    }));
  };

  // Called by CustomScreenerFilter if custom screener filters are changed.
  const onCustomScreenerFilterChange = (screenerFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      screenerFilters,
      filters.screeners,
      segmentToAudienceMap,
      {
        wevoId: wevo?.id,
        pageId: page?.id,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, screenerFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_CUSTOM_SCREENER, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      screeners: screenerFilters,
    }));
  };

  /**
   * Called by DiagnosticsFilter if selected diagnostics list is changed.
   * @param {string[]} selectedDiagnosticNames
   */
  const onDiagnosticChange = (selectedDiagnosticNames) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_DIAGNOSITCS,
      buildWordFilterProperties(selectedDiagnosticNames, filters.diagnostics, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      })
    );

    const selectedCompositeMetricGroups = compositeScores
      .filter((group) => {
        return group.components.some((component) => selectedDiagnosticNames.includes(component.name));
      })
      .map((group) => group.name);

    setSelectedCompositeMetrics(selectedCompositeMetricGroups);

    setFilters((filters) => ({
      ...filters,
      diagnostics: selectedDiagnosticNames,
    }));
  };

  /**
   * Called by SentimentFilter if selected sentiments list is changed.
   * @param {string[]} selectedDiagnosticNames
   */
  const onSentimentChange = (selectedSentiments) => {
    track(
      TrackEvent.FILTERED_QUOTES_BY_SENTIMENT,
      buildWordFilterProperties(selectedSentiments, filters.sentiments, {
        location: 'dropdown',
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        testType: wevo?.type,
      })
    );

    setFilters((filters) => ({
      ...filters,
      sentiments: selectedSentiments,
    }));
  };

  /**
   * Helper for figuring out which CompositeMetricGroup a Diagnostic is a Component of.
   * @param {string} diagnosticName - Diagnostic word
   */
  const getCompositeMetricGroup = (diagnosticName) => {
    return compositeScores.filter((composite) => {
      const componentNames = composite.components.map((component) => component.name);
      return componentNames.includes(diagnosticName);
    })?.[0];
  };

  /**
   * Helper for figuring out which Diagnostics are part of the same Composite Metric Group as the diagnostic
   * @param {string} diagnosticName - Diagnostic word
   */
  const getCompositeMetricGroupSiblingDiagnostics = (diagnosticName) => {
    return getCompositeMetricGroup(diagnosticName)?.components?.map((c) => c.name) || [];
  };

  const orderedSelectedCompositeMetrics = orderCompositeMetrics(wevo?.metricType, selectedCompositeMetrics);

  /**
   * Toggles whether diagnostic is selected or not.
   * @param {string} diagnosticName - Diagnostic word to toggle
   */
  const toggleDiagnostic = (diagnosticName) => {
    const shouldDeselect = selectedDiagnostics.includes(diagnosticName);

    track(TrackEvent.FILTERED_QUOTES_BY_DIAGNOSITCS, {
      filters: [diagnosticName],
      location: 'DQS Diagnostic Filter',
      deselected: shouldDeselect,
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    const compositeMetric = getCompositeMetricGroup(diagnosticName);

    const compositeMetricComponentNames = (compositeMetric?.components || []).map((c) => c.name);

    let newDiagnosticFilters;
    let groupCount;

    if (selectedDiagnostics.indexOf(diagnosticName) > -1) {
      // if no other diagnostics in the composite metric group, deselect that group
      newDiagnosticFilters = selectedDiagnostics.filter((diagnostic) => diagnostic !== diagnosticName);

      groupCount = newDiagnosticFilters.filter((diagnostic) =>
        compositeMetricComponentNames.includes(diagnostic)
      ).length;

      if (groupCount === 0) {
        setSelectedCompositeMetrics(selectedCompositeMetrics.filter((name) => name !== compositeMetric?.name));
      }

      setFilters((filters) => ({
        ...filters,
        diagnostics: newDiagnosticFilters,
      }));
    } else {
      // if no other diagnostics in the composite metric group, select that group
      groupCount = selectedDiagnostics.filter((diagnostic) =>
        compositeMetricComponentNames.includes(diagnostic)
      ).length;

      if (groupCount === 0) {
        setSelectedCompositeMetrics(selectedCompositeMetrics.concat([compositeMetric.name]));
      }
      setFilters((filters) => ({
        ...filters,
        diagnostics: selectedDiagnostics.concat([diagnosticName]),
      }));
    }
  };

  /**
   * Handle when a given diagnostic or sentiment chip is clicked. For diagnostics, this may mean toggling a diagnostic and
   * composite metric group. For sentiment, this is a deselect operation.
   * @param {string} deselectedWord diagnostic or sentiment
   */
  const onChipClicked = (word) => {
    track(TrackEvent.DESELECTED_QUOTE_FILTER, {
      filters: [word],
      deselected: true,
      location: 'chip',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    if (getCompositeMetricGroupSiblingDiagnostics(word).includes(word)) {
      toggleDiagnostic(word);
      return;
    }

    setFilters((filters) => ({
      ...filters,
      sentiments: filters.sentiments.filter((sentiment) => sentiment !== word),
    }));
  };

  const toggleCompositeMetricGroup = (compositeMetric) => {
    track(TrackEvent.FILTERED_QUOTES_BY_COMPOSITE_METRIC, {
      filters: [compositeMetric.name],
      location: 'Design Quality Score Card',
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    const compositeMetricName = compositeMetric.name;
    const compositeMetricComponentNames = compositeMetric.components.map((component) => component.name);

    if (selectedCompositeMetrics.includes(compositeMetricName)) {
      setSelectedCompositeMetrics(selectedCompositeMetrics.filter((name) => name !== compositeMetricName));

      const filteredDiagnostics = selectedDiagnostics.filter((diagnostic) => {
        return !compositeMetricComponentNames.includes(diagnostic);
      });

      setFilters((filters) => ({
        ...filters,
        diagnostics: filteredDiagnostics,
      }));
    } else {
      setSelectedCompositeMetrics(selectedCompositeMetrics.concat([compositeMetricName]));

      const filteredDiagnostics = selectedDiagnostics.concat(
        compositeMetricComponentNames.filter((name) => !selectedDiagnostics.includes(name))
      );

      setFilters((filters) => ({
        ...filters,
        diagnostics: filteredDiagnostics,
      }));
    }
  };

  /**
   * Clear diagnostic and sentiment filters
   */
  const clearWordFilters = () => {
    track(TrackEvent.CLEARED_QUOTE_FILTERS, {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      testType: wevo?.type,
    });

    setFilters((filters) => ({
      ...filters,
      diagnostics: [],
      sentiments: [],
    }));

    setSelectedCompositeMetrics([]);
  };

  let title = `${filteredQuotes.length} Quotes`;
  if (quotes.length !== filteredQuotes.length) {
    title += ` (out of ${quotes.length})`;
  }
  return (
    <Box mr={5}>
      <Box>
        {compositeScoresByName[EXPERIENCE_SCORE_TOP_LEVEL_NAME]?.score && (
          <TopLevelScoreCard
            header="Design Quality Score"
            description={
              'Measuring the quality of the design, experience and content. Comprised of three themes: Emotion, Ease, and Effectiveness.'
            }
            topLevelScore={compositeScoresByName[EXPERIENCE_SCORE_TOP_LEVEL_NAME]?.score}
            metricScores={EXPERIENCE_SCORE_THEME_NAMES.map((name) => compositeScoresByName[name]?.score)}
            compositeMetricsThemeNames={EXPERIENCE_SCORE_THEME_NAMES}
            compositeMetricsColors={EXPERIENCE_SCORE_COLORS}
            isDashboard={false}
          />
        )}
      </Box>

      <Box>
        <CompositeMetricsChart
          wevo={wevo}
          compositeMetrics={EXPERIENCE_SCORE_THEME_NAMES.map((name) => compositeScoresByName[name])}
          compositeMetricsThemeNames={EXPERIENCE_SCORE_THEME_NAMES}
          compositeMetricsColors={EXPERIENCE_SCORE_COLORS}
          backgroundColor={EXPERIENCE_SCORE_BAR_CHART_COLORS.backgroundColor}
          hoverBackgroundColor={EXPERIENCE_SCORE_BAR_CHART_COLORS.hoverBackgroundColor}
          selectedCompositeMetrics={selectedCompositeMetrics}
          diagnostics={diagnostics}
          onBarChartClick={(diagnosticName) => toggleDiagnostic(diagnosticName)}
          onScoreCardClick={toggleCompositeMetricGroup}
        />
      </Box>

      <Paper
        elevation={4}
        sx={{
          marginTop: 4,
          paddingBottom: 2,
          paddingLeft: { sm: 2 },
          paddingRight: { sm: 2 },
          borderRadius: '20px',
        }}>
        <Box sx={{ padding: { xs: 1, md: 4 } }}>
          <Grid container>
            <Grid item xs={12}>
              <QuotesTable
                wevo={wevo}
                page={page}
                fullQuotes={quotes}
                displayQuotes={filteredQuotes}
                onQuoteUpdate={onQuoteUpdate}
                onAudienceFilterChange={onAudienceFilterChange}
                onCustomScreenerFilterChange={onCustomScreenerFilterChange}
                clearWordFilters={clearWordFilters}
                onSearchChange={onSearchChange}
                title={title}
                selectedWords={[...selectedDiagnostics, ...selectedSentiments]}
                deselectWord={onChipClicked}
                filters={[
                  <DiagnosticWordFilter
                    wevo={wevo}
                    words={allWords}
                    selectedWords={selectedDiagnostics}
                    onWordFilterChange={onDiagnosticChange}
                  />,
                  <SentimentFilter
                    sentiments={Object.values(Sentiments)}
                    selectedSentiments={selectedSentiments}
                    onSentimentFilterChange={onSentimentChange}
                  />,
                ]}
                chips={[DiagnosticChip, SentimentChip]}
                quoteChipContainerComponent={
                  <GroupedQuoteTableChipContainer
                    wevo={wevo}
                    selectedChipGroups={selectedCompositeMetrics.map(
                      (name) =>
                        compositeScoresByName[name]?.components.map((component) => component.name) || []
                    )}
                    chipGroupColor={(word, chipGroupIdx) =>
                      EXPERIENCE_SCORE_COLORS[orderedSelectedCompositeMetrics[chipGroupIdx]] || 'auto'
                    }
                    selectedCompositeMetrics={selectedCompositeMetrics}
                    selectedWords={[...selectedDiagnostics, ...selectedSentiments]}
                    deselectWord={onChipClicked}
                    clearWordFilters={clearWordFilters}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <RightDrawerButton
        wevo={wevo}
        rightDrawerOpen={rightDrawerOpen}
        handleRightDrawerOpenClick={handleRightDrawerOpenClick}
      />
    </Box>
  );
};

ExperienceScore.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
};

export default ExperienceScore;
