import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';
import SmartphoneOutlinedIcon from '@mui/icons-material/SmartphoneOutlined';

const DeviceIcon = ({ deviceId }) => {
  if (deviceId === 1) {
    return <DesktopWindowsOutlinedIcon />;
  } else if (deviceId === 2) {
    return <SmartphoneOutlinedIcon />;
  }
};

export default DeviceIcon;
