import { FormControl, FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import InfoIcon from '@mui/icons-material/Info';
import React from 'react';
import { DEVICE_ID_TO_NAME, DEVICE_NAME_TO_DEVICE_ID } from '../../../../../modules/wevos/constants';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  deviceRadioButtons: {
    flexDirection: 'row',
  },
  radioButtonLabel: {
    textTransform: 'capitalize',
  },
}));

const DeviceSelection = ({ devices, handleDeviceSelect }) => {
  const classes = useStyles();
  return (
    <FormControl variant="standard" component="fieldset">
      <RadioGroup
        className={classes.deviceRadioButtons}
        name="deviceId"
        value={devices[0]}
        onChange={handleDeviceSelect}
        aria-labelledby="device-selection-section-heading">
        {Object.values(DEVICE_NAME_TO_DEVICE_ID).map((deviceId) => (
          <FormControlLabel
            className={classes.radioButtonLabel}
            key={deviceId}
            value={deviceId}
            control={<Radio color="primary" />}
            label={DEVICE_ID_TO_NAME[deviceId]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

DeviceSelection.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.number),
  handleDeviceSelect: PropTypes.func.isRequired,
};

DeviceSelection.defaultProps = {
  devices: [],
};

function DeviceSelectionSection({ devices, onDeviceSelected, classes }) {
  return (
    <Grid item xs={12}>
      <Typography variant="h5" id="device-selection-section-heading">
        Select which device you would like to test*{' '}
        <Tooltip
          placement="right-start"
          title="If you would like to test both for mobile and desktop device experiences, please submit a separate test for each.">
          <InfoIcon className={classes.infoIcon} />
        </Tooltip>
      </Typography>
      <DeviceSelection devices={devices} handleDeviceSelect={onDeviceSelected} />
    </Grid>
  );
}

DeviceSelectionSection.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.number),
  onDeviceSelected: PropTypes.func.isRequired,
  classes: PropTypes.object,
};

export { DeviceSelection, DeviceSelectionSection };
