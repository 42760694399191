import Container from '@mui/material/Container';
import React from 'react';
import AccountSettingsForm from './AccountSettings';

const AccountSettings = (props) => {
  return (
    <Container component="main" maxWidth="false" sx={{ maxWidth: { xs: '80%', md: '50%' } }}>
      <AccountSettingsForm {...props} />
    </Container>
  );
};

export default AccountSettings;
