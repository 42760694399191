import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as CollapseIcon } from '../../../src/assets/collapse.svg';
import { ReactComponent as ExpandIcon } from '../../../src/assets/expand.svg';
import LockIcon from '../../assets/lockIcon.png';
import {
  AutomatedInsightSectionToDisplayName,
  AutomatedInsightSections,
} from '../../modules/automated-insights/constants';
import { TrackEvent } from '../analytics';
import QuestionsChat from './QuestionsChat';
import { ExplorePlansButton } from './ui/Button';

const Toolbar = ({ expanded, onExpand }) => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingX: 2, paddingY: 1.5 }}>
      {expanded ? (
        <Tooltip title="Collapse">
          <IconButton aria-label="collapse" onClick={onExpand}>
            <CollapseIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Expand">
          <IconButton aria-label="expand" onClick={onExpand}>
            <ExpandIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

// ownerUserId is the user id of the owner of the pulse, while chatUserId is the user that is logged in
// that is trying to send a chat message (based on a share link).
// This will be empty if the user is not logged in.
const ChatDrawer = ({
  sessionId,
  selectedSegment,
  ownerUserId,
  chatUserId,
  open,
  onClose,
  sessionType,
  hideReportContent,
  selectedTab,
  chatEnabled,
  selectedQuantTheme = null,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  // For pulse owner, if the current selected tab is not part of the user's tier, we block this tab and display a blocking message.
  // If the selected tab is Audience, we don't show this message.
  return chatUserId &&
    hideReportContent &&
    selectedTab !== AutomatedInsightSectionToDisplayName[AutomatedInsightSections.MyAudience] &&
    selectedTab !== AutomatedInsightSectionToDisplayName[AutomatedInsightSections.Audience] &&
    selectedTab !== null ? (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderRadius: '0 0 20px 20px',
        height: hideReportContent ? 'max(70%, calc(100vh - 350px))' : 'calc(100vh - 300px)', // Set fixed height conditionally
        minHeight: '425px', // for mobile when hiding report content
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.30)',
        overflowY: hideReportContent ? 'auto' : 'visible', // Add scroll if content exceeds height when fixed
      }}>
      <Grid
        container
        justifyContent={'center'}
        spacing={2}
        alignItems={'center'}
        alignContent={'center'}
        sx={{
          height: 'inherit',
          borderRadius: '20px',
          overflow: 'hidden',
          paddingX: 1,
        }}>
        <Grid item>
          <Typography variant="h6" sx={{ textAlign: 'center', marginBottom: 1.5 }}>
            <img src={LockIcon} alt="selectable" style={{ width: '40px', height: 'auto' }} />
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, textAlign: 'center', fontSize: '15px', marginBottom: 1 }}>
            Upgrade to unlock full access
          </Typography>
          <Typography variant="body1" sx={{ textAlign: 'center', fontSize: '13px', marginBottom: 3 }}>
            This feature is not included in your subscription
          </Typography>
          <Grid item sx={{ textAlign: 'center' }}>
            <ExplorePlansButton
              blockedFeature={selectedTab}
              trackEventName={TrackEvent.PULSE_USER_CLICKED_EXPLORE_PLANS}
              height={'38px'}
              width={'136px'}
              fontSize={'13px'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ) : chatEnabled ? (
    <Box
      sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: open ? (expanded ? '85%' : '60%') : 0,
        borderRadius: '20px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.25)',
        transition: (theme) =>
          theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: '100ms',
          }),
      }}>
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: '50%',
          transform: 'translate(-50%, -50%)',
          opacity: 0,
          ...(open && {
            opacity: 1,
            transition: 'opacity 0.5s ease-in-out',
          }),
        }}>
        <IconButton
          aria-label="close chat"
          onClick={() => onClose(false)}
          sx={{
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.25)',

            '&:hover': {
              backgroundColor: '#FFFFFF',
              color: (theme) => theme.palette.secondary.main,
            },
          }}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <Grid
        container
        flexDirection="column"
        sx={{ height: '100%', borderRadius: '20px', overflow: 'hidden', flexWrap: 'nowrap' }}>
        <Grid container item justifyContent={{ xs: 'flex-end', sm: 'space-between' }}>
          <Grid item alignSelf="center" sx={{ display: { xs: 'none', sm: 'initial' } }}>
            <Typography px={2} fontSize={13} fontStyle="italic">
              Insights Assistant - Chat with AI to learn more about your experience's performance.
            </Typography>
          </Grid>
          <Grid item>
            <Toolbar expanded={expanded} onExpand={handleExpandClick} />
          </Grid>
        </Grid>
        <Grid item sx={{ flexGrow: 1 }}>
          <QuestionsChat
            sessionId={sessionId}
            selectedSegment={selectedSegment}
            ownerUserId={ownerUserId}
            chatUserId={chatUserId}
            showToolbar={true}
            showCarousel={true}
            selectedQuantTheme={selectedQuantTheme}
            sessionType={sessionType}
          />
        </Grid>
      </Grid>
    </Box>
  ) : null;
};

export default ChatDrawer;
