import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Chip, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { ReactComponent as CustomQuestionsIcon } from '../../../assets/custom-questions.svg';

const styles = {
  arrow: {
    height: '14px',
    width: '14px',
  },
};

const QuestionCarousel = ({ questionsAndOptions }) => {
  const [questions, setQuestions] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setQuestions(questionsAndOptions);
    setIndex(0);
  }, [questionsAndOptions]);

  const handlePreviousClick = () => {
    if (index > 0) {
      setIndex(index - 1);
    }
  };

  const handleNextClick = () => {
    if (index < questions.length - 1) {
      setIndex(index + 1);
    }
  };

  if (!questions.length) {
    return <></>;
  }

  return (
    <Box>
      <Grid container alignItems="center">
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              aria-label="previous question"
              size="small"
              onClick={handlePreviousClick}
              disabled={index === 0}>
              <ArrowBackIosIcon sx={styles.arrow} />
            </IconButton>
            <CustomQuestionsIcon style={{ width: '20px', height: '20px', fill: grey[500] }} />
            <IconButton
              aria-label="next question"
              size="small"
              onClick={handleNextClick}
              disabled={index === questions?.length - 1}>
              <ArrowForwardIosIcon sx={styles.arrow} />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs>
          <Box>
            <Typography sx={{ marginBottom: 0.5, fontSize: '10px', fontWeight: 'bold', color: '#757575' }}>
              {questions[index].questionText}
            </Typography>

            <Grid container columnGap={1}>
              {questions[index]?.options?.map((option, index) => (
                <Grid item key={option?.id}>
                  <Tooltip title={option?.optionText}>
                    <Chip label={option?.optionText} size="small" sx={{ fontSize: 10, maxWidth: 150 }} />
                  </Tooltip>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

QuestionCarousel.propTypes = {
  questionsAndOptions: PropTypes.arrayOf(PropTypes.object),
};

export default QuestionCarousel;
