import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment, useMemo } from 'react';
import { ReactComponent as FlagIcon } from '../../../../assets/intake-experience-flag.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/intake-experience-location.svg';
import ExperienceStepCard from './ExperienceStepCard';

const PathBlocks = ({ steps, visitorsPerStep, startStep, destinationStep, onStepSelection }) => {
  const handleCardClick = (stepId) => {
    onStepSelection(stepId);
  };

  const numOfBlocks = useMemo(
    () => Object.entries(visitorsPerStep).filter(([key, value]) => value.length > 0).length,
    [visitorsPerStep]
  );

  const getIcon = (stepId) => {
    if (stepId === startStep.id) {
      return <FlagIcon style={{ width: 20, height: 20 }} />;
    } else if (stepId === destinationStep.id) {
      return <LocationIcon style={{ width: 20, height: 20 }} />;
    } else return <></>;
  };

  return (
    <Box>
      {steps?.map(
        (step) =>
          visitorsPerStep[step.id].length > 0 && (
            <Fragment key={step.id}>
              <Grid
                container
                sx={{
                  paddingY: 2,
                  paddingRight: { lg: 2 },
                  columnGap: 1,
                }}>
                <Grid item sx={{ minWidth: 62, order: { xs: 1, md: 0 } }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      height: '100%',
                    }}>
                    <Grid container alignItems="center" columnGap={1}>
                      <Grid item display="flex" justifyContent="flex-end" sx={{ minWidth: 24 }}>
                        <PeopleOutlineIcon />
                      </Grid>
                      <Grid item sx={{ textAlign: 'right' }}>
                        <Typography variant="body2">{visitorsPerStep[step.id].length}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item onClick={() => handleCardClick(step.id)}>
                  <ExperienceStepCard
                    name={step?.configuration?.name}
                    image={step?.staticImage}
                    width={226}
                    height={82}
                    icon={getIcon(step.id)}
                    cursor={'pointer'}
                    onStepSelection={onStepSelection}
                  />
                </Grid>
              </Grid>
              {numOfBlocks > 1 && <Divider />}
            </Fragment>
          )
      )}
      {/* give up block */}
      {visitorsPerStep?.giveUps?.length > 0 && (
        <Grid container sx={{ paddingY: 2, paddingRight: { lg: 2 }, columnGap: 1 }}>
          <Grid item sx={{ minWidth: 62, order: { xs: 1, md: 0 } }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}>
              <Grid container alignItems="center" columnGap={2}>
                <Grid item display="flex" justifyContent="flex-end" sx={{ minWidth: 24 }}>
                  <PeopleOutlineIcon />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="error">
                    {visitorsPerStep?.giveUps?.length}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <ExperienceStepCard width={226} height={82} text={'User gave up on completing the task'} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

PathBlocks.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
  visitorsPerStep: PropTypes.object.isRequired,
  startStep: PropTypes.object,
  destinationStep: PropTypes.object,
  onStepSelection: PropTypes.func.isRequired,
};

export default PathBlocks;
