import { Box, Grid, MenuItem, Paper, Select, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useAnalytics } from 'use-analytics';
import axios from '../../../api';
import { TransitionType } from '../../../modules/wevos/constants';
import { filterQuotes } from '../../../modules/wevos/quotes';
import ImagePreview from '../../../ui/ImagePreview';
import { buildAudienceFilterProperties, TrackEvent, useTrackPageLoad } from '../../analytics';
import { shouldTrackAudienceFilterChange } from '../../analytics/eventPropertyHelpers';
import QuotesTable from '../components/QuotesTable';
import PageFlowChart from './PageFlowChart';

const styles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    borderRadius: '20px',
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  wordGraphContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
  imageContainer: {
    textAlign: 'center',
    padding: theme.spacing(4),
  },
  quotesContainer: {
    [theme.breakpoints.down('lg')]: {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
}));

const renderQuotes = (
  wevo,
  page,
  quotes,
  filteredQuotes,
  onAudienceFilterChange,
  onCustomScreenerFilterChange,
  onSearchChange
) => {
  let title = `${filteredQuotes.length} Quotes`;
  if (quotes.length !== filteredQuotes.length) {
    title += ` (out of ${quotes.length})`;
  }
  return (
    <QuotesTable
      wevo={wevo}
      page={page}
      fullQuotes={quotes}
      displayQuotes={filteredQuotes}
      onAudienceFilterChange={onAudienceFilterChange}
      onCustomScreenerFilterChange={onCustomScreenerFilterChange}
      onSearchChange={onSearchChange}
      title={title}
    />
  );
};

const PageFlow = (props) => {
  const { wevo, page, step } = props;
  const classes = styles();

  const [quoteType, setQuoteType] = useState(TransitionType.Leave);
  const [filters, setFilters] = useState({ transitionType: [quoteType] });
  const [score, setScore] = useState(0);
  const [quotesByType, setQuotesByType] = useState({ [TransitionType.Leave]: [], [TransitionType.Stay]: [] });
  const quotes = useMemo(() => quotesByType[quoteType], [quotesByType, quoteType]);
  const filteredQuotes = useMemo(() => filterQuotes(quotes, filters), [quotes, filters]);

  const { track } = useAnalytics();

  useEffect(() => {
    axios({
      url: `/api/v2/wevos/${wevo.id}/steps/${step.id}/transitions`,
      method: 'GET',
    }).then((response) => {
      const score = _.get(response, 'data.score') || 0;
      const quotes = _.get(response, 'data.quotes') || [];

      setScore(Math.round(score * 100));

      // Categorize quotes into stay/leave quotes.
      const quotesLeave = [];
      const quotesStay = [];
      quotes.forEach((quote) => {
        const quoteType = quote && quote.type && quote.type.toLowerCase();
        if (quoteType === TransitionType.Leave) {
          quotesLeave.push(quote);
        } else if (quoteType === TransitionType.Stay) {
          quotesStay.push(quote);
        }
      });

      setQuotesByType({
        [TransitionType.Leave]: quotesLeave,
        [TransitionType.Stay]: quotesStay,
      });
    });
  }, [wevo, step]);

  useTrackPageLoad({
    name: TrackEvent.VIEWED_REPORT_PAGE_FLOW,
    properties: {
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      step: step?.id,
      stepNumber: step?.stepNumber,
    },
  });

  /**
   * Changes the list of quotes (e.g. Stay, Leave, etc.) that are shown.
   */
  const onQuoteTypeChange = (ev) => {
    const newQuoteType = _.get(ev, 'target.value');
    if (!newQuoteType) {
      return;
    }
    handleQuoteTypeChange(newQuoteType);
  };

  const handleQuoteTypeChange = (quoteType) => {
    track(TrackEvent.FILTERED_QUOTES_BY_TRANSITION_TYPE, {
      filter: [quoteType],
      wevoId: wevo?.analyticsId,
      pageId: page?.id,
      stepId: step?.id,
      stepNumber: step?.stepNumber,
      testType: wevo?.type,
    });

    setQuoteType(quoteType);

    setFilters((filters) => ({
      ...filters,
      transitionType: [quoteType],
    }));
  };

  // Called by QuoteSearch if search query has changed.
  const onSearchChange = (searchQuery) => {
    if (searchQuery) {
      track(TrackEvent.SEARCHED_QUOTE, {
        searchQuery,
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      });
    }

    setFilters((filters) => ({
      ...filters,
      search: searchQuery,
    }));
  };

  // Called by QuoteAudienceFilter if audience filters are changed.
  const onAudienceFilterChange = (audienceFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      audienceFilters,
      filters.audience,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, audienceFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_AUDIENCE, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      audience: audienceFilters,
    }));
  };

  // Called by CustomScreenerFilter if custom screener filters are changed.
  const onCustomScreenerFilterChange = (screenerFilters, segmentToAudienceMap) => {
    const filteredQuotesbyAudienceProperties = buildAudienceFilterProperties(
      screenerFilters,
      filters.screeners,
      segmentToAudienceMap,
      {
        wevoId: wevo?.analyticsId,
        pageId: page?.id,
        stepId: step?.id,
        stepNumber: step?.stepNumber,
        testType: wevo?.type,
      }
    );

    if (shouldTrackAudienceFilterChange(filteredQuotesbyAudienceProperties, screenerFilters)) {
      track(TrackEvent.FILTERED_QUOTES_BY_CUSTOM_SCREENER, filteredQuotesbyAudienceProperties);
    }

    setFilters((filters) => ({
      ...filters,
      screeners: screenerFilters,
    }));
  };

  return (
    <Box>
      <Typography variant="body1" gutterBottom>
        See the likelihood of users transitioning to the next page
      </Typography>
      <Paper elevation={4} className={classes.paper}>
        <Grid container item alignItems="center" justifyContent="space-around" spacing={2}>
          <Grid item lg={6} md={4} sm={6} xs={12} className={classes.wordGraphContainer}>
            <div style={{ height: '80px', marginLeft: '-10px' }}>
              <PageFlowChart score={score} />
            </div>
            <Box marginTop={1}>
              <Typography variant="caption">
                The portion of people who visited this page that chose to continue onto the next step in the
                process
              </Typography>
            </Box>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={12} className={classes.imageContainer}>
            <ImagePreview image={step.images} maxHeight={250} maxWidth={250} />
          </Grid>
        </Grid>
        <Box borderTop={1} borderColor="grey.400" marginTop={4} className={classes.quotesContainer}>
          <Box py={2}>
            <Typography variant="h5" display="inline">
              Quotes
            </Typography>{' '}
            <Typography variant="body1" display="inline">
              about
            </Typography>{' '}
            <Select variant="standard" value={quoteType} onChange={onQuoteTypeChange}>
              <MenuItem value={TransitionType.Leave}>Why people decided to leave the page</MenuItem>
              <MenuItem value={TransitionType.Stay}>What would make people decide to stay</MenuItem>
            </Select>
          </Box>
          {renderQuotes(
            wevo,
            page,
            quotes,
            filteredQuotes,
            onAudienceFilterChange,
            onCustomScreenerFilterChange,
            onSearchChange
          )}
        </Box>
      </Paper>
    </Box>
  );
};

PageFlow.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  step: PropTypes.object.isRequired,
};

export default PageFlow;
