import { fetchHeatmapImage } from '../../components/reports/api';
import { CHUNK_HEIGHT, HEATMAP_BG, HEATMAP_CANVAS_CONTAINER } from './constants';

const downloadHeatmap = async (wevo, page, step, filename) => {
  const heatmapCanvasContainer = document.querySelector(`#${HEATMAP_CANVAS_CONTAINER}`);
  const heatmapCanvasChunks = heatmapCanvasContainer.querySelectorAll('canvas');

  // heatmap image in browser window - used to get the height in order to scale up the height of heatmap canvas chunks
  const heatmapBg = document.querySelector(`#${HEATMAP_BG}`);

  // heatmap image fetched from s3 bucket
  const bgImage = new Image();
  const imageURL = await fetchHeatmapImage(wevo?.id, wevo?.type, page?.id, step?.id);
  bgImage.src = imageURL;

  bgImage.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    // ratio used to scale up heatmap canvas points to size of heatmap image from s3
    const ratio = bgImage.height / heatmapBg.height;

    canvas.width = bgImage.width;
    canvas.height = bgImage.height;
    context.drawImage(bgImage, 0, 0, canvas.width, canvas.height);
    context.fillStyle = 'rgba(0, 0, 0, .5)';
    context.fillRect(0, 0, canvas.width, canvas.height);
    heatmapCanvasChunks.forEach((chunk, index) => {
      const yPosition = index * CHUNK_HEIGHT * ratio;
      const scaledChunkHeight = chunk.height * ratio;
      context.drawImage(chunk, 0, yPosition, canvas.width, scaledChunkHeight);
    });
    const dataURL = canvas.toDataURL();
    const link = document.createElement('a');
    link.download = `${filename}.png`;
    link.href = dataURL;
    link.click();
  };
};

export default downloadHeatmap;
