import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { SavedAudienceMutationKeys } from '../../../modules/intake/constants';

const deleteSavedAudience = async ({ groupId }) => {
  const response = await axios({
    url: `/api/v2/saved-audiences/groups/${groupId}`,
    method: 'DELETE',
  });
  return response.data.deleted;
};

export default function useDeleteSavedAudience() {
  const queryClient = useQueryClient();

  return useMutation(deleteSavedAudience, {
    mutationKey: SavedAudienceMutationKeys.deleteSavedAudience,
    onSuccess: () => {
      queryClient.invalidateQueries('savedAudienceData');
    },
  });
}
