import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import sumBy from 'lodash/sumBy';
import React, { useEffect, useMemo, useState } from 'react';
import { DEFAULT_AUDIENCE_ATTRIBUTES } from '../../../modules/wevos/constants';
import EditQuotas from './EditQuotas';

const ageMarks = [
  { value: 1, label: '18', intValue: 18 },
  { value: 2, label: '25', intValue: 25 },
  { value: 3, label: '35', intValue: 35 },
  { value: 4, label: '45', intValue: 45 },
  { value: 5, label: '55', intValue: 55 },
  { value: 6, label: '55+', intValue: Infinity },
];

const Age = (props) => {
  const { age, setAge, setIsAgeValid } = props;
  const [ageRange, setAgeRange] = useState([1, 6]);
  const quotasTotal = useMemo(() => sumBy(age.segments, (segment) => segment.percentage), [age.segments]);

  const isAttributeSelected = useMemo(() => age?.isSelected, [age?.isSelected]);
  const isCustomizedQuota = useMemo(() => Boolean(age.isCustomizedQuota), [age.isCustomizedQuota]);

  useEffect(() => {
    if (age) {
      // update age range whenever age changes to ensure slider is always in sync with current attribute state
      let newAgeRange = [Infinity, -Infinity];
      age.segments.forEach((segment, index) => {
        if (segment.isSelected) {
          if (index + 1 < newAgeRange[0]) {
            newAgeRange[0] = index + 1;
          }
          if (index + 2 > newAgeRange[1]) {
            newAgeRange[1] = index + 2;
          }
        }
      });
      setAgeRange(newAgeRange);
    } else {
      setAgeRange([1, 6]);
    }
  }, [age]);

  useEffect(() => {
    setIsAgeValid(quotasTotal === 100);
  }, [quotasTotal, setIsAgeValid]);

  const handleAgeChange = (ev, newAgeRange) => {
    if (newAgeRange[0] !== newAgeRange[1]) {
      const lowerBound = ageMarks.find((ageMark) => ageMark.value === newAgeRange[0]).intValue;
      const upperBound = ageMarks.find((ageMark) => ageMark.value === newAgeRange[1]).intValue;

      let newSegments = [...DEFAULT_AUDIENCE_ATTRIBUTES['age'].segments];
      newSegments = newSegments.map((segment) => ({
        ...segment,
        isSelected: segment.lowerBound >= lowerBound && segment.upperBound <= upperBound,
      }));
      const basePercentage = Math.floor(100 / newSegments.filter((segment) => !!segment.isSelected).length);
      let remainder = 100 % basePercentage;
      newSegments = newSegments.map((segment) => {
        if (!segment.isSelected) {
          return { ...segment, percentage: 0 };
        } else {
          if (remainder > 0) {
            remainder -= 1;
            return { ...segment, percentage: basePercentage + 1 };
          }
          return {
            ...segment,
            percentage: basePercentage,
          };
        }
      });
      setAge((age) => ({ ...age, segments: newSegments }));
      setAgeRange(newAgeRange);
    }
  };

  const updateAgeQuotas = (ev) => {
    const newPercentage = Number(ev.target.value) || 0;
    const segmentLabel = ev.target.name;
    if (newPercentage >= 5 && newPercentage <= 100) {
      setAge((age) => {
        if (age.segments.filter((segment) => segment.isSelected).length === 2) {
          return {
            ...age,
            segments: age.segments.map((segment) => {
              if (segment.label === segmentLabel) {
                return { ...segment, percentage: newPercentage };
              } else {
                if (segment.isSelected) {
                  return { ...segment, percentage: Number(100 - newPercentage) };
                }
                return segment;
              }
            }),
          };
        } else {
          return {
            ...age,
            segments: age.segments.map((segment) => {
              if (segment.label === segmentLabel) {
                return { ...segment, percentage: newPercentage };
              }
              return segment;
            }),
          };
        }
      });
    }
  };

  const handleCustomizeQuotasClick = (ev) => {
    setAge({
      ...age,
      isCustomizedQuota: ev.target.checked,
    });
  };

  return (
    <Grid container justifyContent="space-between" spacing={2} alignItems="center">
      <Grid item xs={6}>
        <Slider
          disabled={!isAttributeSelected}
          value={ageRange}
          valueLabelDisplay="off"
          marks={ageMarks}
          min={1}
          max={6}
          step={null}
          onChange={handleAgeChange}
          size="small"
        />
      </Grid>
      <Grid item xs>
        <FormControlLabel
          disabled={!isAttributeSelected}
          sx={{ width: '100%', justifyContent: 'flex-end' }}
          control={<Checkbox checked={isCustomizedQuota} onChange={handleCustomizeQuotasClick} />}
          label={
            <Typography fontSize={'11px'} variant="caption">
              Customize my quotas
            </Typography>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <EditQuotas
          open={isCustomizedQuota}
          audience={age}
          quotasTotal={quotasTotal}
          handleQuotaChange={updateAgeQuotas}
        />
      </Grid>
    </Grid>
  );
};

export default Age;
