import React, { useCallback, useState } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { Box, TextField, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { MAX_PRIMER_COUNT, PrimerValues } from '../../../../../modules/intake/constants';
import DropzoneCard from './DropzoneCard';
import PrimerCard from './PrimerCard';
import PrimerSelect from './PrimerSelect';
import DebouncedInput from '../../DebouncedInput';
import { isPrimerConfigured } from '../../../../../modules/intake/helpers';

function PrimerConfiguration({
  draft,
  primers,
  handleShowPrimerSelect,
  handlePrimerInput,
  ongoingPrimerUploads,
  primerContext,
  handlePrimerContextInput,
  errors,
  classes,
}) {
  const [showPrimer, setShowPrimer] = useState(isPrimerConfigured(draft) ? PrimerValues.Yes : PrimerValues.No);

  const handlePrimerSelectionChanged = useCallback(
    (ev) => {
      const oldValue = showPrimer;

      setShowPrimer(ev.target.value);

      handleShowPrimerSelect(ev, {
        onError: () => {
          setShowPrimer(oldValue);
        },
      });
    },
    [handleShowPrimerSelect, setShowPrimer, showPrimer]
  );

  return (
    <>
      <Box mb={2}>
        <Box mb={1}>
          <Typography variant="h5" id="primer-selection-section-heading">
            Primer for the Test Page(s){' '}
            <Tooltip
              placement="right-start"
              title="Is there any primer that would be helpful to
          incorporate in the test experience for panelists to see
          or understand before arriving on the first test page?">
              <InfoIcon className={classes.infoIcon} />
            </Tooltip>
          </Typography>
        </Box>
        <PrimerSelect
          showPrimer={showPrimer}
          allowPrimer={Boolean(draft?.pages?.[0])}
          handleShowPrimerSelect={handlePrimerSelectionChanged}
        />
        {primers.length > 0 && <PrimerCard key={`${primers[0].id}`} primer={primers[0]} />}
        {ongoingPrimerUploads.map((mutation) => (
          <PrimerCard key={mutation.options.variables.uploadId} isLoading={true} />
        ))}
      </Box>

      {showPrimer === PrimerValues.Yes && primers.length + ongoingPrimerUploads.length < MAX_PRIMER_COUNT && (
        <Box>
          <DropzoneCard
            numAssets={primers.length}
            maxNumAssets={MAX_PRIMER_COUNT}
            handleFileInput={handlePrimerInput}
          />
        </Box>
      )}

      {showPrimer === PrimerValues.Yes && (
        <Box mt={2}>
          <Box mb={2}>
            <Typography variant="h5" gutterBottom>
              Primer Context*{' '}
              <Tooltip
                placement="right-start"
                title={
                  <>
                    <span className={classes.tooltipTitle}>
                      You can include a primer for incoming context to help form expectations of the website or
                      task of the test.
                    </span>
                  </>
                }>
                <InfoIcon className={classes.infoIcon} />
              </Tooltip>
            </Typography>
          </Box>
          <DebouncedInput
            value={primerContext ?? ''}
            onChange={handlePrimerContextInput}
            debounceMs={500}
            renderInput={({ value, onChange }) => (
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={4}
                value={value}
                onChange={onChange}
                placeholder="Add Primer Context"
                error={Boolean(errors?.primerContext)}
                helperText={errors?.primerContext?.message ?? ''}
              />
            )}
          />
        </Box>
      )}
    </>
  );
}

PrimerConfiguration.propTypes = {
  draft: PropTypes.object.isRequired,
  primers: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleShowPrimerSelect: PropTypes.func,
  handlePrimerInput: PropTypes.func,
  ongoingPrimerUploads: PropTypes.arrayOf(PropTypes.object),
  primerContext: PropTypes.string,
  handlePrimerContextInput: PropTypes.func,
  errors: PropTypes.object,
  classes: PropTypes.object,
};

export default PrimerConfiguration;
