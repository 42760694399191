import { Box, Container, Grid, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { debounce as _debounce } from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import useWevo from '../../../hooks/useWevo';
import { snackbar } from '../../../notifications';
import useSaveWevo from '../hooks/useSaveWevo';

const styles = {
  paper: {
    marginTop: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainTestGoalContainer: {
    marginTop: '16px',
  },
  textField: { marginTop: '8px', minWidth: '390px' },
  logoGrid: {
    maxWidth: '60px',
    minWidth: '60px',
  },
};

const TestGoals = (props) => {
  const { updateTestGoalsValidity } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { wevoId } = useParams();
  const { data: draft } = useWevo(wevoId);
  const { mutate: saveWevo } = useSaveWevo();

  const [mainTestGoal, setMainTestGoal] = useState(draft?.description);
  const [showDescription, setShowDescription] = useState(false);

  const debouncedUpdateMainTestGoal = useMemo(
    () =>
      _debounce(
        (value) =>
          saveWevo(
            { id: draft?.id, description: value },
            {
              onError: (err) => {
                snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving test goal');
              },
            }
          ),
        1000
      ),
    [draft?.id, saveWevo]
  );

  const handleMainTestGoalChange = useCallback(
    (value) => {
      setMainTestGoal(value);
      debouncedUpdateMainTestGoal(value);
    },
    [debouncedUpdateMainTestGoal]
  );

  if (!isSmallScreen && showDescription) {
    setShowDescription(false);
  }

  useEffect(() => {
    setMainTestGoal(draft.description);
  }, [draft.description]);

  useEffect(() => {
    updateTestGoalsValidity(Boolean(mainTestGoal?.length > 0));
  }, [mainTestGoal, updateTestGoalsValidity]);

  return (
    <Box sx={styles.paper}>
      <Typography variant="h3" role="heading" aria-level="3" id="test-goal-heading">
        Enter Your Test Goal
      </Typography>

      <Box pt={3} />
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <Container maxWidth="xs" disableGutters sx={styles.mainTestGoalContainer}>
            <TextField
              sx={styles.textField}
              type="text"
              onChange={(ev) => handleMainTestGoalChange(ev.target.value)}
              variant="outlined"
              fullWidth
              size="small"
              label="What do you want to learn?*"
              value={mainTestGoal}
              multiline
              rows={4}
              aria-labelledby="test-goal-heading"
            />
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

TestGoals.propTypes = {
  updateTestGoalsValidity: PropTypes.func,
};

export default TestGoals;
