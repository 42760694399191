import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import PeopleIcon from '@mui/icons-material/People';
import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Paths } from '../../../../routes';
import useFetchSurveyDetails from '../../hooks/useFetchSurveyDetails';

const styles = makeStyles((theme) => ({
  card: {
    borderRadius: '20px',
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    height: '100%',
  },
  numRespondentsGrid: { alignItems: 'center' },
  numRespondentsIconGrid: {
    marginRight: theme.spacing(1),
  },
  numRespondentsIcon: {
    color: theme.palette.primary.main,
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
  },
  numRespondentsContainer: {
    alignItems: 'flex-start',
  },
  totalNumberGrid: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  grey: {
    backgroundColor: grey[100],
  },
  text: {
    textAlign: 'center',
  },
  icon: {
    backgroundColor: grey[100],
    borderRadius: '50%',
    padding: '3px',
  },
}));

const NumRespondentsCard = (props) => {
  const { wevo, page, isLimitedReport } = props;
  const classes = styles();

  const surveyDetailsResult = useFetchSurveyDetails({
    wevoId: wevo?.id,
    pageId: page?.id,
  });

  const numRespondents = useMemo(() => {
    return surveyDetailsResult?.data?.numRespondents ?? '?';
  }, [surveyDetailsResult]);

  return (
    <Card className={classes.card} elevation={4}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={1} justifyContent="center" className={classes.numRespondentsContainer}>
          <Grid item xs={12}>
            <Grid container spacing={1} className={classes.numRespondentsGrid}>
              <Grid item className={classes.numRespondentsIconGrid}>
                {isLimitedReport ? (
                  <Box className={classes.icon}>
                    <PeopleIcon className={classes.numRespondentsIcon} />
                  </Box>
                ) : (
                  <IconButton size="small" className={classes.grey}>
                    <Link to={generatePath(Paths.reports.testDetails, { wevoId: wevo?.id })}>
                      <PeopleIcon className={classes.numRespondentsIcon} />
                    </Link>
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h5">Total N</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={1} xs={12}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.totalNumberGrid}>
                {numRespondents}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.text}>
              respondents
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NumRespondentsCard;
