import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteSavedAudienceDialog = (props) => {
  const { open, deleteSavedAudience, closeCallback } = props;

  const handleClose = () => {
    closeCallback();
  };

  const handleReset = () => {
    !!deleteSavedAudience && deleteSavedAudience();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="delete saved audience confirmation dialog"
        aria-describedby="delete-saved-audience-confirmation-description">
        <DialogTitle>Deleting Saved Audience</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-saved-audience-confirmation-description">
            Are you sure you want to delete this Saved Audience?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Yup! Delete Saved Audience</Button>
          <Button onClick={handleClose}>Do Nothing</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteSavedAudienceDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  deleteSavedAudience: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

export default React.memo(DeleteSavedAudienceDialog);
