import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import { useMemo } from 'react';
import PropTypes from 'prop-types';

const ROW_NAMES = {
  UniqueVisitors: {
    name: 'unique visitors',
    icon: <PeopleOutlineIcon />,
  },
  GiveUps: {
    name: 'give ups',
    icon: <PeopleOutlineIcon />,
  },
  // TODO: add row for average time per page
  // AverageTime: {name: 'average time per page'}
};

const FIRST_COLUMN_HEADER = 'Metric';

const ExperienceStepsMetricsTable = ({ steps, visitorsPerStep, giveUpsPerStep, firstItemIndex }) => {
  const theme = useTheme();
  const stepIds = useMemo(
    () => steps?.map((step) => step.id).slice(firstItemIndex, firstItemIndex + 4),
    [steps, firstItemIndex]
  );

  const headers = [FIRST_COLUMN_HEADER].concat(stepIds);

  const rows = Object.values(ROW_NAMES).map((metric) => {
    return headers.map((header) => {
      if (header === FIRST_COLUMN_HEADER) {
        return (
          <Box display="flex" alignItems="center" columnGap={1}>
            {metric.icon}
            <Typography fontSize={14}>{metric.name}</Typography>
          </Box>
        );
      } else if (metric === ROW_NAMES.UniqueVisitors) {
        return visitorsPerStep[header];
      } else if (metric === ROW_NAMES.GiveUps) {
        if (visitorsPerStep[header] === 0) {
          return <Typography fontSize={14}>N/A</Typography>;
        }
        return giveUpsPerStep[header] === 0 ? (
          <></>
        ) : (
          <Typography fontSize={14} color={theme.palette.error.main}>
            -{giveUpsPerStep[header]}
          </Typography>
        );
      } else return <></>;
    });
  });

  return (
    <TableContainer>
      <Table aria-label="metrics table">
        <TableBody>
          {rows?.map((row, index) => (
            <TableRow
              key={`${row[0]}-${index}`}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}>
              {row?.map((item, itemIndex) => (
                <TableCell
                  key={`${row[0]}-${itemIndex}-${item}`}
                  component="th"
                  scope="row"
                  sx={{
                    paddingLeft: { xs: 0, md: '2vw', lg: '3vw' },
                    width: '150px',
                    '&:first-of-type': { textAlign: 'left', width: '150px', paddingLeft: 0 },
                  }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

ExperienceStepsMetricsTable.propTypes = {
  steps: PropTypes.array.isRequired,
  visitorsPerStep: PropTypes.object.isRequired,
  giveUpsPerStep: PropTypes.object.isRequired,
  firstItemIndex: PropTypes.number.isRequired,
};

export default ExperienceStepsMetricsTable;
