import InfoIcon from '@mui/icons-material/Info';
import { Box, Checkbox, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { grey } from '@mui/material/colors';
import { get as _get, isEmpty as _isEmpty, debounce, isNil } from 'lodash';
import sumBy from 'lodash/sumBy';
import {
  Fragment,
  default as React,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router';
import b2bImageSelected from '../../../assets/b2b-selected.svg';
import b2bImage from '../../../assets/b2b.svg';
import b2cImageSelected from '../../../assets/b2c-selected.svg';
import b2cImage from '../../../assets/b2c.svg';
import usePrevious from '../../../hooks/usePrevious';
import useWevo from '../../../hooks/useWevo';
import {
  AudienceCategory,
  AudienceDisplay,
  DEFAULT_AUDIENCE_ATTRIBUTES,
} from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import ExplainerPopup from '../ExplainerPopup';
import SavedAudienceCard from '../edit/SavedAudienceCard';
import useSaveWevo from '../hooks/useSaveWevo';
import Age from './Age';
import AttributeCard from './AttributeCard';
import CheckboxAttribute from './CheckboxAttribute';
import CustomScreenerQuestions from './CustomScreenerQuestions';
import DiscardTargetAudienceDialog from './DiscardTargetAudienceDialog';
import Income from './Income';
import Revenue from './Revenue';
import SelectableCard from './SelectableCard';

const minNumRespondents = 25;
const maxNumRespondents = 250;
const defaultNumRespondents = 125;

const CustomScreenersTooltipInfo = [
  {
    name: 'Choose Your Criteria',
    description:
      "Select from a range of custom questions tailored to your audience's interests and demographics. Save your favorite ones/ones used most often by your team. ",
  },
  {
    name: 'Set Your Filters',
    description:
      "Define your audience by setting filters based on responses to these questions. Whether it's marital status, interests, or purchasing habits, customize your screening criteria to match your target audience.",
  },
  {
    name: 'Refine Your Results',
    description:
      'By using our Enhanced Filters, you can identify how specific personas will engage with your content or offerings, ensuring a better digital experience and enhancing ROI. For example, you can inquire about the credit cards your potential audience holds and choose to recruit only those with Mastercard and/or American Express cards. In the results you can then filter the responses to examine the reactions of Mastercard holders compared to those with American Express cards.',
  },
];

function SelectAudienceInstructions() {
  return (
    <Box pr={2}>
      <Typography variant="caption" color="textSecondary" fontSize={11}>
        <ul style={{ marginLeft: '-24px', marginTop: '8px', marginRight: '-8px' }}>
          <li style={{ marginBottom: '6px' }}>
            <div>Use the toggle to ‘turn-on’ each demographic criteria that is important to the study.</div>
          </li>
          <li style={{ marginBottom: '6px' }}>
            <div>
              Demographics you leave off will still be collected for the study but will not have any quota
              parameters set around them.
            </div>
          </li>
          <li style={{ marginBottom: '6px' }}>
            <div>
              Once you ‘turn-on’ a demographic, you will have the opportunity to select specific quotas for
              each demographic area
            </div>
          </li>
          <ul style={{ marginLeft: '-24px' }}>
            <li style={{ marginBottom: '6px' }}>
              <div>
                WEVO will aim to get as close as possible to meet your desired quota, and we do our best not
                exceed +/- 5 percentage points for each quota requested.
              </div>
            </li>
            <li>
              <div>
                You do not need to specify quotas for the demographics you ‘turn-on’. If you do not specify
                quotas, WEVO will aim for a general population audience where applicable.
              </div>
            </li>
          </ul>
        </ul>
      </Typography>
    </Box>
  );
}

const SelectAudience = React.forwardRef((props, ref) => {
  const {
    updateAudienceValidity,
    savedAudienceName,
    setSavedAudienceName,
    audienceDisplay,
    setAudienceDisplay,
    selectedAudienceGroupId,
    setSelectedAudienceGroupId,
    savedAudiences,
    showAudienceNameBox,
    setShowAudienceNameBox,
    deleteSavedAudienceGroup,
  } = props;

  const { wevoId } = useParams();
  const { data: draft } = useWevo(wevoId);
  const prevDraft = usePrevious(draft);
  const { mutate: saveWevo } = useSaveWevo();

  const [showDiscardTargetAudienceDialog, setShowDiscardTargetAudienceDialog] = useState(false);
  const [targetAudienceToSetOnConfirm, setTargetAudienceToSetOnConfirm] = useState();

  const [errors, setErrors] = useState({});

  const [gender, setGender] = useState(
    _get(draft, 'audience.attributes', []).find(
      (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['gender'].id
    ) || DEFAULT_AUDIENCE_ATTRIBUTES['gender']
  );
  const genderQuotasTotal = useMemo(
    () => sumBy(gender.segments, (segment) => segment.percentage),
    [gender.segments]
  );
  const [isGenderValid, setIsGenderValid] = useState(false);

  const [age, setAge] = useState(
    _get(draft, 'audience.attributes', []).find(
      (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['age'].id
    ) || DEFAULT_AUDIENCE_ATTRIBUTES['age']
  );
  const ageQuotasTotal = useMemo(() => sumBy(age.segments, (segment) => segment.percentage), [age.segments]);
  const [isAgeValid, setIsAgeValid] = useState(false);

  const [income, setIncome] = useState(
    _get(draft, 'audience.attributes', []).find(
      (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['income'].id
    ) || DEFAULT_AUDIENCE_ATTRIBUTES['income']
  );
  const incomeQuotasTotal = useMemo(
    () => sumBy(income.segments, (segment) => segment.percentage),
    [income.segments]
  );
  const [isIncomeValid, setIsIncomeValid] = useState(false);

  const [education, setEducation] = useState(
    _get(draft, 'audience.attributes', []).find(
      (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['education'].id
    ) || DEFAULT_AUDIENCE_ATTRIBUTES['education']
  );
  const educationQuotasTotal = useMemo(
    () => sumBy(education.segments, (segment) => segment.percentage),
    [education.segments]
  );
  const [isEducationValid, setIsEducationValid] = useState(false);

  const [revenue, setRevenue] = useState(
    _get(draft, 'audience.attributes', []).find(
      (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['revenue'].id
    ) || DEFAULT_AUDIENCE_ATTRIBUTES['revenue']
  );
  const revenueQuotasTotal = useMemo(
    () => sumBy(revenue.segments, (segment) => segment.percentage),
    [revenue.segments]
  );
  const [isRevenueValid, setIsRevenueValid] = useState(false);

  const [jobRole, setJobRole] = useState(
    _get(draft, 'audience.attributes', []).find(
      (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['jobRole'].id
    ) || DEFAULT_AUDIENCE_ATTRIBUTES['jobRole']
  );
  const jobRoleQuotasTotal = useMemo(
    () => sumBy(jobRole.segments, (segment) => segment.percentage),
    [jobRole.segments]
  );
  const [isJobRoleValid, setIsJobRoleValid] = useState(false);

  const [companySize, setCompanySize] = useState(
    _get(draft, 'audience.attributes', []).find(
      (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['companySize'].id
    ) || DEFAULT_AUDIENCE_ATTRIBUTES['companySize']
  );
  const companySizeQuotasTotal = useMemo(
    () => sumBy(companySize.segments, (segment) => segment.percentage),
    [companySize.segments]
  );
  const [isCompanySizeValid, setIsCompanySizeValid] = useState(false);

  const [description, setDescription] = useState('');

  const [numRespondents, setNumRespondents] = useState(draft.numRespondents || defaultNumRespondents);

  const [customScreenerErrorsById, setCustomScreenerErrorsById] = useState({});

  const audienceCategoryId = useMemo(() => draft?.audienceCategory?.id, [draft.audienceCategory]);

  const reset = useCallback((draft) => {
    if (!isNil(draft.audience)) {
      setGender(
        draft.audience.attributes.find(
          (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['gender'].id
        ) || DEFAULT_AUDIENCE_ATTRIBUTES['gender']
      );
      setAge(
        draft.audience.attributes.find(
          (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['age'].id
        ) || DEFAULT_AUDIENCE_ATTRIBUTES['age']
      );
      setIncome(
        draft.audience.attributes.find(
          (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['income'].id
        ) || DEFAULT_AUDIENCE_ATTRIBUTES['income']
      );
      setEducation(
        draft.audience.attributes.find(
          (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['education'].id
        ) || DEFAULT_AUDIENCE_ATTRIBUTES['education']
      );
      setRevenue(
        draft.audience.attributes.find(
          (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['revenue'].id
        ) || DEFAULT_AUDIENCE_ATTRIBUTES['revenue']
      );
      setJobRole(
        draft.audience.attributes.find(
          (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['jobRole'].id
        ) || DEFAULT_AUDIENCE_ATTRIBUTES['jobRole']
      );
      setCompanySize(
        draft.audience.attributes.find(
          (attribute) => attribute.id === DEFAULT_AUDIENCE_ATTRIBUTES['companySize'].id
        ) || DEFAULT_AUDIENCE_ATTRIBUTES['companySize']
      );
      setDescription(draft.audience.description || '');
    }
    if (draft.numRespondents) {
      setNumRespondents(draft.numRespondents);
    }
  }, []);

  useEffect(() => {
    if (_isEmpty(prevDraft) && !_isEmpty(draft)) {
      reset(draft);
    }
  }, [draft, prevDraft, reset]);

  // Note: debounce function needs to be retained across renders and therefore we use useRef.
  const debouncedSaveWevo = useRef(
    debounce(
      (params) =>
        saveWevo(params, {
          onError: (err, variables, { previousWevoState }) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
            reset(previousWevoState);
          },
        }),
      1000
    )
  );

  const updateAudience = useCallback(
    (audience) => {
      debouncedSaveWevo.current({
        id: wevoId,
        audience,
      });
    },
    [wevoId]
  );

  const handleAudienceCategoryChange = useCallback(
    (audienceCategoryId) => {
      const previousSavedAudienceName = savedAudienceName;
      setSavedAudienceName('');
      setShowAudienceNameBox(false);
      saveWevo(
        {
          id: draft.id,
          audienceCategoryId,
        },
        {
          onSuccess: () => {
            setDefaultAttributes();
          },
          onError: (err, variables, { previousWevoState }) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
            reset(previousWevoState);
            setSavedAudienceName(previousSavedAudienceName);
            setShowAudienceNameBox(Boolean(previousSavedAudienceName));
          },
        }
      );
    },
    [draft.id, reset, saveWevo, savedAudienceName, setSavedAudienceName, setShowAudienceNameBox]
  );

  const hasCustomScreenerErrors = useMemo(() => {
    // check if each any questions have errors
    for (let key in customScreenerErrorsById) {
      if (customScreenerErrorsById.hasOwnProperty(key)) {
        let errors = customScreenerErrorsById[key];
        for (let item in errors) {
          if (errors.hasOwnProperty(item) && errors[item] === true) {
            return true;
          }
        }
      }
    }
    return false; // Return false if no errors for all questions
  }, [customScreenerErrorsById]);

  useEffect(() => {
    if (!!draft.id) {
      let attributes = [];

      if (audienceCategoryId === AudienceCategory.B2C.id) {
        attributes = [gender, age, income, education];
      } else if (audienceCategoryId === AudienceCategory.B2B.id) {
        attributes = [revenue, jobRole, companySize];
      }
      updateAudience({ attributes, description });
    }
  }, [
    age,
    audienceCategoryId,
    companySize,
    description,
    draft.id,
    education,
    gender,
    income,
    jobRole,
    revenue,
    updateAudience,
  ]);

  useEffect(() => {
    if (
      draft?.id &&
      numRespondents >= minNumRespondents &&
      numRespondents <= maxNumRespondents &&
      numRespondents % 25 === 0
    ) {
      debouncedSaveWevo.current({ id: draft.id, numRespondents });
    }
  }, [draft.id, numRespondents]);

  // Updates audience section's validity
  useEffect(() => {
    if (!!hasCustomScreenerErrors) {
      updateAudienceValidity(!hasCustomScreenerErrors);
    } else if (audienceDisplay === AudienceDisplay.SavedAudience) {
      const savedAudienceGroupIds = savedAudiences
        ?.filter((savedAudience) => !savedAudience?.isDraft)
        ?.map((savedAudience) => savedAudience?.id);
      updateAudienceValidity(
        selectedAudienceGroupId !== null && savedAudienceGroupIds?.includes(selectedAudienceGroupId)
      );
    } else {
      const isSavedAudienceNameValid =
        !showAudienceNameBox || (showAudienceNameBox && savedAudienceName?.replace(/\s/g, '').length > 0);

      if (audienceCategoryId === AudienceCategory.B2C.id) {
        updateAudienceValidity(
          isGenderValid && isAgeValid && isIncomeValid && isEducationValid && isSavedAudienceNameValid
        );
      } else if (audienceCategoryId === AudienceCategory.B2B.id) {
        updateAudienceValidity(
          isRevenueValid && isJobRoleValid && isCompanySizeValid && isSavedAudienceNameValid
        );
      }
    }
  }, [
    audienceCategoryId,
    isAgeValid,
    isCompanySizeValid,
    isEducationValid,
    isGenderValid,
    isIncomeValid,
    isJobRoleValid,
    isRevenueValid,
    updateAudienceValidity,
    audienceDisplay,
    selectedAudienceGroupId,
    savedAudiences,
    showAudienceNameBox,
    savedAudienceName,
    hasCustomScreenerErrors,
  ]);

  // Ref: Called by CreateTest parent component to update itself.
  useImperativeHandle(ref, () => ({
    submit() {
      let attributes = [];
      if (audienceCategoryId === AudienceCategory.B2C.id) {
        attributes = [gender, age, income, education];
      } else if (audienceCategoryId === AudienceCategory.B2B.id) {
        attributes = [revenue, jobRole, companySize];
      }

      saveWevo(
        {
          id: draft.id,
          audience: {
            attributes,
            description,
          },
          audienceCategoryId,
          numRespondents,
        },
        {
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    },
  }));

  useTrackPageLoad({ name: TrackEvent.VIEWED_EDIT_TEST_AUDIENCE, properties: { wevoId: draft?.analyticsId } });

  const handleDescriptionInput = (ev) => {
    setDescription(ev.target.value);
  };

  /**
   * Returns audience selector image depending on whether it is selected or not.
   */
  const getAudienceSelectorImage = (category) => {
    if (category === AudienceCategory.B2C.id) {
      return audienceCategoryId === category ? b2cImageSelected : b2cImage;
    } else {
      // B2B
      return audienceCategoryId === category ? b2bImageSelected : b2bImage;
    }
  };

  const handleAudienceSelect = useCallback(
    (category) => {
      // If the category stays the same, no need to do anything.
      if (audienceCategoryId === category) {
        return;
      }

      if (draft.isLiteTestType) {
        setShowDiscardTargetAudienceDialog(true);
        setTargetAudienceToSetOnConfirm(category);
      } else {
        handleAudienceCategoryChange(category);
      }
    },
    [audienceCategoryId, handleAudienceCategoryChange, draft.isLiteTestType]
  );

  const toggleShowTargetAudienceDialog = useCallback(() => {
    if (draft.isLiteTestType) {
      setShowDiscardTargetAudienceDialog(!showDiscardTargetAudienceDialog);
    }
  }, [setShowDiscardTargetAudienceDialog, showDiscardTargetAudienceDialog, draft.isLiteTestType]);

  const discardTargetAudience = useCallback(() => {
    if (draft.isLiteTestType) {
      handleAudienceCategoryChange(targetAudienceToSetOnConfirm);
    }
  }, [targetAudienceToSetOnConfirm, handleAudienceCategoryChange, draft.isLiteTestType]);

  const handleAudienceNameInput = (ev) => {
    setSavedAudienceName(ev.target.value);
  };

  const handleSaveAudienceClick = (ev) => {
    setShowAudienceNameBox((showAudienceNameBox) => !showAudienceNameBox);
  };

  /*
   * Sets audience data according to the selected audience display option.
   */
  const handleAudienceDisplaySelect = (ev) => {
    // Audience display was changed from saved audience to select audience
    if (
      audienceDisplay === AudienceDisplay.SavedAudience &&
      ev.target.value === AudienceDisplay.SelectAudience
    ) {
      if (selectedAudienceGroupId !== null) {
        saveWevo(
          { id: wevoId, savedAudienceGroupId: null },
          {
            onSuccess: () => {
              const draftSavedAudiences = savedAudiences?.filter((savedAudience) => savedAudience?.isDraft);
              for (const draftSavedAudience of draftSavedAudiences) {
                deleteSavedAudienceGroup(draftSavedAudience.id);
              }
              setDefaultAttributes();
            },
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
            },
          }
        );
      }
      setSelectedAudienceGroupId(null);
    }

    setAudienceDisplay(ev.target.value);
  };

  /*
   * Sets the audience data according to the selected saved audience group.
   */
  const handleAudienceGroupSelect = (ev) => {
    ev.preventDefault();
    ev.stopPropagation();
    if (selectedAudienceGroupId !== ev.target.value) {
      const previousSelectedAudienceGroupId = selectedAudienceGroupId;
      const savedAudience = savedAudiences
        ?.filter((savedAudience) => !savedAudience?.isDraft)
        ?.find((savedAudience) => savedAudience?.id === ev.target.value);
      const categoryId = savedAudience?.audience_categories?.[0]?.audienceCategoryId;
      setSelectedAudienceGroupId(ev.target.value);
      saveWevo(
        {
          id: wevoId,
          audienceCategoryId: categoryId,
          savedAudienceGroupId: ev.target.value,
        },
        {
          onSuccess: () => {
            setAttributes(savedAudience, false);
            setShowAudienceNameBox(false);
          },
          onError: (err) => {
            setSelectedAudienceGroupId(previousSelectedAudienceGroupId);
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    }
  };

  const handleSetQuestionErrors = useCallback(({ id, errors }) => {
    setCustomScreenerErrorsById((prevCustomScreenerErrors) => ({
      ...prevCustomScreenerErrors,
      [id]: { ...prevCustomScreenerErrors?.[id], ...errors },
    }));
  }, []);

  const setDefaultAttributes = () => {
    setGender(DEFAULT_AUDIENCE_ATTRIBUTES['gender']);
    setAge(DEFAULT_AUDIENCE_ATTRIBUTES['age']);
    setIncome(DEFAULT_AUDIENCE_ATTRIBUTES['income']);
    setEducation(DEFAULT_AUDIENCE_ATTRIBUTES['education']);
    setRevenue(DEFAULT_AUDIENCE_ATTRIBUTES['revenue']);
    setJobRole(DEFAULT_AUDIENCE_ATTRIBUTES['jobRole']);
    setCompanySize(DEFAULT_AUDIENCE_ATTRIBUTES['companySize']);
    setDescription('');
  };

  const setAttributes = (savedAudience, reset) => {
    if (reset) {
      setDefaultAttributes();
    } else {
      const newGender = editAttribute(savedAudience, 'gender');
      setGender(newGender);
      const newAge = editAttribute(savedAudience, 'age');
      setAge(newAge);
      const newIncome = editAttribute(savedAudience, 'income');
      setIncome(newIncome);
      const newEducation = editAttribute(savedAudience, 'education');
      setEducation(newEducation);
      const newRevenue = editAttribute(savedAudience, 'revenue');
      setRevenue(newRevenue);
      const newJobRole = editAttribute(savedAudience, 'jobRole');
      setJobRole(newJobRole);
      const newCompanySize = editAttribute(savedAudience, 'companySize');
      setCompanySize(newCompanySize);
      setDescription(savedAudience?.audienceDescription || '');
    }
  };

  const editAttribute = (savedAudience, attribute) => {
    let newAttribute = DEFAULT_AUDIENCE_ATTRIBUTES[attribute];
    const attributeData = savedAudience?.audiences.find(
      (audience) => String(audience.audience.audience_id) === String(newAttribute.id)
    );

    const updatedSegments = newAttribute.segments.map((defaultSegment) => {
      const attributeSegment = attributeData?.audience_segments?.find(
        (segment) => String(segment.audienceSegmentId) === String(defaultSegment.id)
      );

      return {
        ...defaultSegment,
        percentage: attributeSegment?.percentage,
        isSelected: attributeSegment?.is_selected,
      };
    });

    return {
      ...newAttribute,
      segments: updatedSegments,
      isCustomizedQuota: attributeData?.audience?.isCustomizedQuota,
      isSelected: attributeData?.audience?.isSelected,
    };
  };

  // Selects the first saved audience group (default) if exists, after deleting a selected saved audience group.
  useEffect(() => {
    const savedAudienceGroupIds = savedAudiences
      ?.filter((savedAudience) => !savedAudience?.isDraft)
      ?.map((savedAudience) => savedAudience?.id);

    if (
      audienceDisplay === AudienceDisplay.SavedAudience &&
      selectedAudienceGroupId &&
      !savedAudienceGroupIds?.includes(selectedAudienceGroupId)
    ) {
      const savedAudience = savedAudiences?.filter((savedAudience) => !savedAudience?.isDraft)?.[0];
      if (savedAudience) {
        const categoryId = savedAudience?.audience_categories?.[0]?.audienceCategoryId;
        setSelectedAudienceGroupId(savedAudience?.id);
        setAttributes(savedAudience, false);
        saveWevo(
          {
            id: wevoId,
            audienceCategoryId: categoryId,
            savedAudienceGroupId: savedAudience?.id,
          },
          {
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
            },
          }
        );
      }
    }
  });

  useEffect(() => {
    if (showAudienceNameBox && savedAudienceName?.replace(/\s/g, '').length === 0) {
      setErrors((errors) => ({
        ...errors,
        savedAudienceName: { message: 'Please provide a name for this audience' },
      }));
    } else {
      setErrors((errors) => ({
        ...errors,
        savedAudienceName: false,
      }));
    }
  }, [showAudienceNameBox, savedAudienceName]);

  useEffect(() => {
    if (audienceDisplay === AudienceDisplay.SelectAudience) {
      if (audienceCategoryId === AudienceCategory.B2C.id) {
        gender.isSelected && gender.isCustomizedQuota && genderQuotasTotal
          ? setIsGenderValid(Boolean(genderQuotasTotal === 100))
          : setIsGenderValid(true);
        age.isSelected && age.isCustomizedQuota && ageQuotasTotal
          ? setIsAgeValid(Boolean(ageQuotasTotal === 100))
          : setIsAgeValid(true);
        income.isSelected && income.isCustomizedQuota && incomeQuotasTotal
          ? setIsIncomeValid(Boolean(incomeQuotasTotal === 100))
          : setIsIncomeValid(true);
        education.isSelected && education.isCustomizedQuota && educationQuotasTotal
          ? setIsEducationValid(Boolean(educationQuotasTotal === 100))
          : setIsEducationValid(true);
      } else if (audienceCategoryId === AudienceCategory.B2B.id) {
        revenue.isSelected && revenue.isCustomizedQuota && revenueQuotasTotal
          ? setIsRevenueValid(Boolean(revenueQuotasTotal === 100))
          : setIsRevenueValid(true);
        jobRole.isSelected && jobRole.isCustomizedQuota && jobRoleQuotasTotal
          ? setIsJobRoleValid(Boolean(jobRoleQuotasTotal === 100))
          : setIsJobRoleValid(true);
        companySize.isSelected && companySize.isCustomizedQuota && companySizeQuotasTotal
          ? setIsCompanySizeValid(Boolean(companySizeQuotasTotal === 100))
          : setIsCompanySizeValid(true);
      }
    }
  }, [
    gender,
    age,
    income,
    education,
    revenue,
    jobRole,
    companySize,
    genderQuotasTotal,
    ageQuotasTotal,
    incomeQuotasTotal,
    educationQuotasTotal,
    revenueQuotasTotal,
    jobRoleQuotasTotal,
    companySizeQuotasTotal,
    audienceDisplay,
    audienceCategoryId,
  ]);

  const explainerSections = useMemo(() => {
    return audienceDisplay === AudienceDisplay.SelectAudience
      ? [
          {
            id: 'select-audience',
            title: 'Selecting Your WEVO Audience',
            content: <SelectAudienceInstructions />,
          },
        ]
      : [];
  }, [audienceDisplay]);

  return (
    <div>
      <Box sx={{ position: 'fixed', right: 20, top: 80 }}>
        <ExplainerPopup sections={explainerSections} />
      </Box>
      <Grid container spacing={2} justifyContent="center" wrap="nowrap">
        <Grid item xs={12}>
          <Grid container spacing={2} justifyContent="center" sx={{ marginBottom: 2, marginTop: 2 }}>
            <Grid item xs={12}>
              <Typography variant="h4">Provide Your Target Audience Criteria</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Who is your audience?*</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="standard" component="fieldset">
                <RadioGroup
                  name="audience-display"
                  value={audienceDisplay}
                  size="small"
                  onChange={(ev) => handleAudienceDisplaySelect(ev)}>
                  <FormControlLabel
                    value={AudienceDisplay.SavedAudience}
                    control={<Radio color="primary" />}
                    label={<Typography variant="body1">Saved Audience</Typography>}
                  />
                  <FormControlLabel
                    value={AudienceDisplay.SelectAudience}
                    control={<Radio color="primary" />}
                    label={<Typography variant="body1">Select Audience Criteria</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
          {audienceDisplay === AudienceDisplay.SelectAudience && (
            /* this is the images part */
            <Grid
              container
              item
              direction="row"
              justifyContent="center"
              spacing={2}
              xs={12}
              sx={{ marginBottom: 2 }}>
              <Grid item>
                <SelectableCard
                  title="Consumers"
                  description="Leverage the WEVO panel to find people who buy for personal use"
                  image={getAudienceSelectorImage(AudienceCategory.B2C.id)}
                  isSelected={audienceCategoryId === AudienceCategory.B2C.id}
                  onClick={() => handleAudienceSelect(AudienceCategory.B2C.id)}
                />
              </Grid>
              <Grid item>
                <SelectableCard
                  title="Businesses"
                  description="Leverage the WEVO panel to find people who buy for their organization"
                  image={getAudienceSelectorImage(AudienceCategory.B2B.id)}
                  isSelected={audienceCategoryId === AudienceCategory.B2B.id}
                  onClick={() => handleAudienceSelect(AudienceCategory.B2B.id)}
                />
              </Grid>
              {draft.isLiteTestType && (
                <DiscardTargetAudienceDialog
                  open={showDiscardTargetAudienceDialog}
                  discardTargetAudience={discardTargetAudience}
                  closeCallback={toggleShowTargetAudienceDialog}
                />
              )}
            </Grid>
          )}
          {/* /* this it the audience part */}
          {/* /* renders this if the Consumer is checked */}
          {audienceDisplay === AudienceDisplay.SelectAudience &&
            !isNil(draft.audienceCategory) &&
            draft.audienceCategory.id === AudienceCategory.B2C.id && (
              <Fragment>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <AttributeCard heading="Gender*" attribute={gender} setAttribute={setGender} name="gender">
                    <CheckboxAttribute
                      attribute={gender}
                      setAttribute={setGender}
                      setIsAttributeValid={setIsGenderValid}
                    />
                  </AttributeCard>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <AttributeCard heading="Age*" attribute={age} setAttribute={setAge} name="age">
                    <Age age={age} setAge={setAge} setIsAgeValid={setIsAgeValid} />
                  </AttributeCard>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <AttributeCard heading="Income*" attribute={income} setAttribute={setIncome} name="income">
                    <Income income={income} setIncome={setIncome} setIsIncomeValid={setIsIncomeValid} />
                  </AttributeCard>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <AttributeCard
                    heading="Education*"
                    attribute={education}
                    setAttribute={setEducation}
                    name="education">
                    <CheckboxAttribute
                      attribute={education}
                      setAttribute={setEducation}
                      setIsAttributeValid={setIsEducationValid}
                    />
                  </AttributeCard>
                </Grid>
              </Fragment>
            )}
          {/* renders this if the Business is checked */}
          {audienceDisplay === AudienceDisplay.SelectAudience &&
            !isNil(draft.audienceCategory) &&
            draft.audienceCategory.id === AudienceCategory.B2B.id && (
              <Fragment>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <AttributeCard
                    heading="Revenue*"
                    attribute={revenue}
                    setAttribute={setRevenue}
                    name="revenue">
                    <Revenue revenue={revenue} setRevenue={setRevenue} setIsRevenueValid={setIsRevenueValid} />
                  </AttributeCard>
                </Grid>

                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <AttributeCard
                    heading="Job Role*"
                    attribute={jobRole}
                    setAttribute={setJobRole}
                    name="jobRole">
                    <CheckboxAttribute
                      attribute={jobRole}
                      setAttribute={setJobRole}
                      setIsAttributeValid={setIsJobRoleValid}
                    />
                  </AttributeCard>
                </Grid>
                <Grid item xs={12} sx={{ marginBottom: 2 }}>
                  <AttributeCard
                    heading="Company Size*"
                    attribute={companySize}
                    setAttribute={setCompanySize}
                    name="companySize">
                    <CheckboxAttribute
                      attribute={companySize}
                      setAttribute={setCompanySize}
                      setIsAttributeValid={setIsCompanySizeValid}
                    />
                  </AttributeCard>
                </Grid>
              </Fragment>
            )}
          {audienceDisplay === AudienceDisplay.SavedAudience &&
            (savedAudiences?.filter((savedAudience) => !savedAudience?.isDraft)?.length > 0 ? (
              <Grid container item spacing={2} xs={12}>
                <Grid item xs={12}>
                  <Typography sx={{ color: 'grey' }}>
                    <i>Recently saved audiences</i>
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl variant="standard" component="fieldset">
                    <RadioGroup
                      name="saved-audience-groups"
                      value={selectedAudienceGroupId}
                      size="small"
                      onChange={(ev) => handleAudienceGroupSelect(ev)}>
                      {savedAudiences
                        ?.filter((savedAudience) => !savedAudience?.isDraft)
                        ?.map((savedAudience, index) => {
                          return (
                            <Grid item key={savedAudience?.id || index} sx={{ marginBottom: 2 }}>
                              <SavedAudienceCard savedAudienceGroup={savedAudience} />
                            </Grid>
                          );
                        })}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            ) : (
              <Grid container item spacing={2} xs={12}>
                <Typography sx={{ color: 'grey' }}>
                  <i> There are no saved audiences to display.</i>
                </Typography>
              </Grid>
            ))}
          {audienceDisplay === AudienceDisplay.SelectAudience && (
            <Grid container item spacing={2} xs={12}>
              <Grid item xs={12}>
                <FormControlLabel
                  sx={{
                    justifyContent: 'flex-end',
                  }}
                  control={
                    <Checkbox
                      color="primary"
                      checked={showAudienceNameBox}
                      onChange={handleSaveAudienceClick}
                      value={'CheckBoxSavedAudience'}></Checkbox>
                  }
                  label={<Typography variant="h5">Save this audience for future use.</Typography>}
                />
              </Grid>
              {showAudienceNameBox && (
                <Grid item xs={12}>
                  <TextField
                    id="audienceName"
                    name="audienceName"
                    type="text"
                    fullWidth
                    rows="1"
                    value={savedAudienceName}
                    placeholder="Name this audience*"
                    variant="outlined"
                    onChange={handleAudienceNameInput}
                    error={Boolean(errors?.savedAudienceName)}
                    helperText={errors?.savedAudienceName?.message || ''}
                  />
                </Grid>
              )}
            </Grid>
          )}

          <Grid item xs={12} my={4}>
            <Box>
              <Box sx={{ display: 'flex', columnGap: 1 }}>
                <Typography variant="h5" mb={2}>
                  (Optional) Custom Audience Screeners
                </Typography>
                <Tooltip
                  title={
                    <div styles={{ padding: '0px 8px' }}>
                      <Typography fontSize={12} fontWeight="fontWeightBold">
                        How this works:
                      </Typography>
                      <ol style={{ margin: 0, padding: '16px 8px 0px 24px' }}>
                        {CustomScreenersTooltipInfo.map((item, idx) => {
                          return (
                            <li key={idx}>
                              <Typography component="p" gutterBottom fontSize={12}>
                                <Typography component="span" fontSize={12} fontWeight="fontWeightBold">
                                  {`${item?.name}: `}
                                </Typography>
                                {item?.description}
                              </Typography>
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  }
                  placement="right">
                  <InfoIcon
                    sx={{
                      verticalAlign: 'bottom',
                      color: grey[600],
                    }}
                  />
                </Tooltip>
              </Box>
              <Typography variant="body2">
                Refine your targeting precision and segment your results effectively with Custom Audience
                Screeners and Enhanced Filtering!
              </Typography>
              <Typography variant="body2" mt={2}>
                By leveraging WEVO’s Custom Audience Screeners, you can ensure that our team recruits the
                precise audience you are targeting. Subsequently, you can filter your qualitative results based
                on the responses to those screeners.
              </Typography>

              <CustomScreenerQuestions wevoId={wevoId} onQuestionChange={handleSetQuestionErrors} />
            </Box>
          </Grid>

          {audienceDisplay === AudienceDisplay.SelectAudience && (
            <Grid container item spacing={2} direction="column" xs={12} sx={{ marginBottom: 2 }}>
              <Grid
                container
                item
                direction="row"
                spacing={2}
                justifyContent="flex-start"
                alignItems="baseline">
                <Grid item>
                  <Box>
                    <Typography variant="h5">
                      Other Criteria{' '}
                      <Tooltip
                        title="If there are other participant requirements, please enter here. Custom requirements will
                    require a review by a WEVO team member as requirements can change price and time to complete.
                    If changed, you will be asked to approve before launch. By default, WEVO may screen for
                    panelists with an interest or intent in your product or industry."
                        placement="right">
                        <InfoIcon
                          sx={{
                            verticalAlign: 'bottom',
                            color: grey[600],
                          }}
                        />
                      </Tooltip>
                    </Typography>
                    <Typography mt={2} variant="body2">
                      Use this open-entry field to specify details associated with your Custom Audience . For
                      example: specific areas you want to test in ("Geo: UK") or any specific ranges not
                      provided in the regular filters above ("Income: $65k+"). For most "regular" screening
                      criteria (HHI, Age, Gender Identity, etc.), you should utilize the toggles/filters above,
                      not the open ended field.
                    </Typography>
                    <Typography mt={2} variant="body2">
                      Please note: To filter your results by a specific screener answer/persona, it must be
                      included in the Custom Audience Screeners above. We'll recruit any audience mentioned
                      here, but only results from Custom Screeners and Regular Screeners can be filtered.
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  id="audienceDescription"
                  name="audienceDescription"
                  type="text"
                  fullWidth
                  multiline
                  rows="5"
                  value={description}
                  placeholder="Include additional requirements:&#13;
                - Demographics&#13;
                - Behavioral characteristics&#13;
                - Intent to buy"
                  variant="outlined"
                  onChange={handleDescriptionInput}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
});

export default SelectAudience;
