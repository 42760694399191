import styled from '@emotion/styled';
import { Grid, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { SeatsBillingMethods } from '../../modules/automated-insights/constants';

const SeatsBillingMethodSwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 45,
  padding: 12,
  '& .MuiSwitch-switchBase': {
    padding: 11,
    '& +.MuiSwitch-track': {
      backgroundColor: '#359DAB',
      borderRadius: 20,
      opacity: 1,
    },
    '&.Mui-checked': {
      transform: 'translateX(17px)',
      '& +.MuiSwitch-track': {
        backgroundColor: '#359DAB',
        borderRadius: 20,
      },
    },
  },
  '& .MuiSwitch-thumb': {
    marginTop: '5px',
    marginLeft: '5px',
    width: 13,
    height: 13,
    color: '#FFFFFF',
    boxShadow: 'none',
  },
}));

const SeatsBillingMethodToggle = (props) => {
  const { billingMethod, setBillingMethod } = props;

  return (
    <Grid container flexWrap={'nowrap'} alignItems="baseline" sx={{ height: '100%' }}>
      <Grid item sx={{ height: '100%' }}>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.tertiary,
            fontSize: '13px',
            textAlign: 'end',
            fontWeight: billingMethod === SeatsBillingMethods.Annually ? 'bold' : 'normal',
          }}>
          Annually
        </Typography>
        {billingMethod === SeatsBillingMethods.Annually && (
          <Typography
            sx={{ color: (theme) => theme.palette.text.tertiary, fontSize: '9px', textAlign: 'end' }}>
            Save up to 25%
          </Typography>
        )}
      </Grid>
      <Grid item>
        <SeatsBillingMethodSwitch
          checked={billingMethod === SeatsBillingMethods.Monthly}
          name="seatsBillingMethodToggle"
          color="primary"
          onChange={(ev) =>
            setBillingMethod(ev.target.checked ? SeatsBillingMethods.Monthly : SeatsBillingMethods.Annually)
          }
        />
      </Grid>
      <Grid item>
        <Typography
          sx={{
            color: (theme) => theme.palette.text.tertiary,
            fontSize: '13px',
            textAlign: 'end',
            fontWeight: billingMethod === SeatsBillingMethods.Monthly ? 'bold' : 'normal',
          }}>
          Monthly
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SeatsBillingMethodToggle;
