import { Divider, Paper } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import React, { createElement } from 'react';
import ReactMarkdown from 'react-markdown';
import { KeyFindingType } from '../../../../modules/report/constants';
import { isAuthenticated } from '../../../../modules/user/helpers';
import SlateKeyFindingsViewer from './SlateKeyFindingsViewer';
import _ from 'lodash';

const styles = {
  root: {
    paddingRight: '32px',
    paddingLeft: '32px',
    paddingTop: '16px',
    paddingBottom: '16px',
    borderRadius: '20px',
  },
  divider: {
    width: '100%',
    backgroundColor: '#979797',
    opacity: 0.5,
    marginTop: (theme) => theme.spacing(-2),
    marginBottom: (theme) => theme.spacing(2),
  },
  bullets: {
    color: grey[800],
  },
};

const headingRenderer = (props) =>
  createElement(
    `h${props.level}`,
    {
      style: {
        fontSize: '16px',
        lineHeight: '1.2',
        textAlign: 'left',
      },
    },
    props.children
  );

const customRenderers = {
  h1: headingRenderer,
  h2: headingRenderer,
  h3: headingRenderer,
  h4: headingRenderer,
  h5: headingRenderer,
  h6: headingRenderer,
  ul: (props) =>
    createElement(
      `ul`,
      {
        style: {
          fontSize: '16px',
          lineHeight: '1.5', // lineHeight = sets the margin between each bullet's lines
          textAlign: 'left',
          marginBottom: '48px',
        },
      },

      props.children.map((child, index) => {
        return createElement('div', { key: index, style: { marginBlock: '24px' } }, child); // marginBlock = sets the margin between 2 bullets
      })
    ),
};

const KeyFindingCard = (props) => {
  const { keyFinding, wevoId, pageId, crossPageKeyFindings } = props;

  if (!keyFinding) {
    return (
      <Paper sx={styles.root} elevation={4}>
        <Typography variant="body2" component="span" fontSize={16}>
          This study does not include WEVO Key Findings.
        </Typography>
      </Paper>
    );
  }

  if (keyFinding.type === KeyFindingType.Markdown) {
    const finding = crossPageKeyFindings
      ? `${crossPageKeyFindings.finding}\n\n${keyFinding.finding}`
      : keyFinding.finding;

    return (
      <Paper sx={styles.root} elevation={4}>
        <Typography variant="body2" component="span" sx={styles.bullets}>
          <ReactMarkdown components={customRenderers}>{finding}</ReactMarkdown>
        </Typography>
      </Paper>
    );
  } else if (keyFinding.type === KeyFindingType.Slate) {
    return (
      <Paper sx={styles.root} elevation={4}>
        {!_.isEmpty(crossPageKeyFindings) && (
          <SlateKeyFindingsViewer
            key={`wevo-key-findings-wevo-${wevoId}-cross-page-key-findings`}
            findings={crossPageKeyFindings.finding}
            wevoId={wevoId}
            pageId={null}
            allowUpvote={isAuthenticated()}
            showUpvotes
          />
        )}
        <SlateKeyFindingsViewer
          key={`wevo-key-findings-wevo-${wevoId}-page-${pageId}`}
          findings={keyFinding.finding}
          wevoId={wevoId}
          pageId={pageId}
          allowUpvote={isAuthenticated()}
          showUpvotes
        />
      </Paper>
    );
  } else {
    // Supports key finding 'bullets' type, which will be deprecated eventually.
    return (
      <Card sx={styles.root}>
        <CardHeader title={<Typography variant="h6">{keyFinding.headline}</Typography>} />
        <CardContent>
          <Divider sx={styles.divider} />
          <Typography variant="body2" component="span" sx={styles.bullets}>
            <ReactMarkdown>{keyFinding.bullets}</ReactMarkdown>
          </Typography>
        </CardContent>
      </Card>
    );
  }
};

KeyFindingCard.propTypes = {
  keyFinding: PropTypes.object,
  wevoId: PropTypes.string.isRequired,
  pageId: PropTypes.number,
};

export default KeyFindingCard;
