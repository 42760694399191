import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import * as actions from './actions';
import * as api from './api';

export const fetchWevosEpic = (action$) =>
  action$.ofType(actions.FETCH_WEVOS).pipe(
    mergeMap((action) =>
      api.fetchWevos().pipe(
        map((response) => actions.fetchWevosSuccess(response)),
        catchError((error) => of(actions.fetchWevosFailed(error)))
      )
    )
  );

export const fetchWevoEpic = (action$) =>
  action$.ofType(actions.FETCH_WEVO).pipe(
    mergeMap((action) =>
      api.fetchWevo(action.payload.wevoId).pipe(
        map((response) => actions.fetchWevoSuccess(response)),
        catchError((error) => of(actions.fetchWevoFailed(error)))
      )
    )
  );

export const archiveWevoEpic = (action$) =>
  action$.ofType(actions.ARCHIVE_WEVO).pipe(
    switchMap((action) =>
      api.archiveWevo(action.payload.wevoId).pipe(
        map((response) => actions.archiveWevoSuccess(response)),
        catchError((error) => of(actions.archiveWevoFailed(error)))
      )
    )
  );

export const restoreWevoEpic = (action$) =>
  action$.ofType(actions.RESTORE_WEVO).pipe(
    switchMap((action) =>
      api.restoreWevo(action.payload.wevoId).pipe(
        map((response) => actions.restoreWevoSuccess(response)),
        catchError((error) => of(actions.restoreWevoFailed(error)))
      )
    )
  );

export const deleteWevoEpic = (action$) =>
  action$.ofType(actions.DELETE_WEVO).pipe(
    switchMap((action) =>
      api.deleteWevo(action.payload.wevoId).pipe(
        map((response) => actions.deleteWevoSuccess(response)),
        catchError((error) => of(actions.deleteWevoFailed(error)))
      )
    )
  );

export const copyWevoEpic = (action$) =>
  action$.ofType(actions.COPY_WEVO).pipe(
    switchMap((action) =>
      api.copyWevo(action.payload.wevoId).pipe(
        map((response) => actions.copyWevoSuccess(response)),
        catchError((error) => of(actions.copyWevoFailed(error)))
      )
    )
  );

export const createWevoEpic = (action$) =>
  action$.ofType(actions.CREATE_WEVO).pipe(
    switchMap((action) =>
      api.createWevo(action.payload).pipe(
        map((response) => actions.createWevoSuccess(response)),
        catchError((error) => of(actions.createWevoFailed(error)))
      )
    )
  );

export const updateWevoDefinitionEpic = (action$) =>
  action$.ofType(actions.UPDATE_WEVO_DEFINITION).pipe(
    switchMap((action) =>
      api.updateWevoDefinition(action.payload).pipe(
        map(
          (response) => actions.updateWevoDefinitionSuccess(response),
          catchError((error) => of(actions.updateWevoDefinitionFailed(error)))
        )
      )
    )
  );

export const updateWevoAudienceEpic = (action$) =>
  action$.ofType(actions.UPDATE_WEVO_AUDIENCE).pipe(
    switchMap((action) =>
      api.updateWevoAudience(action.payload).pipe(
        map((response) => actions.updateWevoAudienceSuccess(response)),
        catchError((error) => of(actions.updateWevoAudienceFailed(error)))
      )
    )
  );

export const uploadPageImageEpic = (action$) => {
  return action$.ofType(actions.UPLOAD_PAGE_IMAGE).pipe(
    mergeMap(({ payload }) =>
      api.uploadImage({ file: payload.image }).pipe(
        map((res) => actions.createPage({ ...payload, ...res })),
        catchError((err) => of(actions.uploadPageImageFailed(err)))
      )
    )
  );
};

export const uploadStepImageEpic = (action$) => {
  return action$.ofType(actions.UPLOAD_STEP_IMAGE).pipe(
    mergeMap(({ payload }) =>
      api.uploadImage({ file: payload.image }).pipe(
        map((res) => actions.createStep({ ...payload, ...res })),
        catchError((err) => of(actions.uploadStepImageFailed(err)))
      )
    )
  );
};

export const createPageEpic = (action$, state$) => {
  return action$.ofType(actions.CREATE_PAGE).pipe(
    mergeMap(({ payload }) =>
      api
        .createPageFromImage({
          wevoId: payload.wevoId,
          name: payload.name,
          deviceId: payload.deviceId,
          imageId: payload.data.imageId,
          sortOrder: payload.sortOrder,
        })
        .pipe(
          map((response) => actions.createPageSuccess(response)),
          catchError((error) => of(actions.createPageFailed(error)))
        )
    )
  );
};

// WIP
// export const imageUploadProgressEpic = (action$) => {
//   return action$.ofType(actions.IMAGE_UPLOAD_PROGRESS).pipe(map(console.log));
// };

export const deletePageEpic = (action$, state$) =>
  action$.ofType(actions.DELETE_PAGE).pipe(
    switchMap((action) =>
      api.deletePage(action.payload.wevoId, action.payload.pageId).pipe(
        map((response) => actions.deletePageSuccess(response)),
        catchError((error) => of(actions.deletePageFailed(error)))
      )
    )
  );

export const updatePageEpic = (action$) =>
  action$.ofType(actions.UPDATE_PAGE).pipe(
    mergeMap((action) =>
      api.updatePage(action.payload).pipe(
        map((response) => actions.updatePageSuccess(response)),
        catchError((error) => of(actions.updatePageFailed(error)))
      )
    )
  );

export const createStepEpic = (action$) =>
  action$.ofType(actions.CREATE_STEP).pipe(
    mergeMap(({ payload }) =>
      api
        .createStepFromImage({
          wevoId: payload.wevoId,
          name: payload.name,
          deviceId: payload.deviceId,
          imageId: payload.data.imageId,
          sortOrder: payload.sortOrder,
        })
        .pipe(
          map((response) => actions.createStepSuccess(response)),
          catchError((error) => of(actions.createStepFailed(error)))
        )
    )
  );

export const updateStepEpic = (action$) =>
  action$.ofType(actions.UPDATE_STEP).pipe(
    mergeMap((action) =>
      api.updateStep(action.payload).pipe(
        map((response) => actions.updateStepSuccess(response)),
        catchError((error) => of(actions.updateStepFailed(error)))
      )
    )
  );

export const deleteStepEpic = (action$) =>
  action$.ofType(actions.DELETE_STEP).pipe(
    mergeMap((action) =>
      api.deleteStep(action.payload.wevoId, action.payload.stepId).pipe(
        map((response) => actions.deleteStepSuccess(response)),
        catchError((error) => of(actions.deleteStepFailed(error)))
      )
    )
  );

export const launchWevoEpic = (action$) =>
  action$.ofType(actions.LAUNCH_WEVO).pipe(
    switchMap((action) =>
      api.launchWevo(action.payload.wevoId).pipe(
        map((response) => actions.launchWevoSuccess(response)),
        catchError((error) => of(actions.launchWevoFailed(error)))
      )
    )
  );

export const fetchDraftEpic = (action$) =>
  action$.ofType(actions.FETCH_DRAFT).pipe(
    mergeMap((action) =>
      api.fetchDraft(action.payload.wevoId).pipe(
        map((response) => actions.fetchDraftSuccess(response)),
        catchError((error) => of(actions.fetchDraftFailed(error)))
      )
    )
  );

export const saveWevoEpic = (action$, state$) =>
  action$.ofType(actions.SAVE_WEVO).pipe(
    switchMap((action) =>
      api.saveWevo(action.payload).pipe(
        map((response) => actions.saveWevoSuccess(response)),
        catchError((error) => of(actions.saveWevoFailed(error)))
      )
    )
  );
