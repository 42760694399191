import { createSelector } from 'reselect';

export const getUserProfile = createSelector(
  (state) => state.user.id,
  (state) => state.user.firstName,
  (state) => state.user.lastName,
  (state) => state.user.email,
  (state) => state.user.title,
  (state) => state.user.dateCreated,
  (state) => state.user.pulseSubscription,
  (state) => state.user.isStreamlined,
  (state) => state.user.isEmailVerified,
  (state) => state.user.userType,
  (state) => state.user.publicId,
  (state) => state.user.isBillingAdmin,
  (state) => state.user.usage,
  (
    id,
    firstName,
    lastName,
    email,
    title,
    dateCreated,
    pulseSubscription,
    isStreamlined,
    isEmailVerified,
    userType,
    publicId,
    isBillingAdmin,
    usage
  ) => ({
    id,
    firstName,
    lastName,
    email,
    title,
    dateCreated,
    pulseSubscription,
    isStreamlined,
    isEmailVerified,
    userType,
    publicId,
    isBillingAdmin,
    usage,
  })
);

export const getUserTeamId = createSelector(
  (state) => state.user.teamId,
  (teamId) => teamId
);

export const getUserSubscriptionLevel = createSelector(
  (state) => state.user.subscriptionLevel,
  (subscriptionLevel) => subscriptionLevel
);

export const getUserAcls = createSelector(
  (state) => state.user.acls,
  (acls) => acls
);

export const getUserCustomizations = createSelector(
  (state) => state.user.customizations,
  (customizations) => customizations
);

export const getUserPulseSubscription = createSelector(
  (state) => state.user.pulseSubscription,
  (pulseSubscription) => pulseSubscription
);

export const getUserCompany = createSelector(
  (state) => state.user.company,
  (company) => company
);

export const getUserType = createSelector(
  (state) => state.user.userType,
  (userType) => userType
);
