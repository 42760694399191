import AddBoxIcon from '@mui/icons-material/AddBox';
import WarningIcon from '@mui/icons-material/Warning';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'classnames';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useDropzone } from 'react-dropzone';

const acceptedFileTypes = {
  'image/jpeg': [],
  'image/png': [],
};
const MAX_FILE_SIZE = 20000000;
const WARNING_PAGE_COUNT = 4;

export function serializeFileRejections(fileRejections) {
  const flattenedErrors = fileRejections.flatMap((rejection) => rejection?.errors ?? []);
  const rejections = [];

  if (flattenedErrors.find((error) => error.code === 'file-too-large')) {
    rejections.push('Please ensure files are less than 20mb');
  }

  if (flattenedErrors.find((error) => error.code === 'file-invalid-type')) {
    rejections.push('Please ensure files are png or jpeg');
  }

  return rejections.join('. ');
}

const useStyles = makeStyles((theme) => ({
  dropzoneBase: {
    borderStyle: 'dashed',
    borderColor: '#7a7c7e',
    minHeight: 200,
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  dropzoneAccept: {
    borderColor: theme.palette.primary.main,
  },
  dropzoneReject: {
    borderColor: theme.palette.error.main,
  },
  dropzoneWarningIcon: {
    color: theme.palette.error.main,
    fontSize: '2.5rem',
  },
  submit: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2),
  },
}));

const DropzoneCard = ({ numAssets, maxNumAssets, handleFileInput, multiple }) => {
  const classes = useStyles();
  const showPageCountWarning = numAssets >= WARNING_PAGE_COUNT && numAssets < maxNumAssets;
  const pageLimitReached = numAssets >= maxNumAssets;

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: acceptedFileTypes,
    onDrop: handleFileInput,
    maxSize: MAX_FILE_SIZE,
    multiple: multiple,
    disabled: pageLimitReached,
  });

  const isFileTooLarge = fileRejections.length > 0 && fileRejections[0].size > MAX_FILE_SIZE;

  return (
    <Card sx={{ cursor: 'pointer' }} aria-labelledby="identify-pages-section-heading">
      <CardContent>
        <Grid container>
          <Grid
            container
            item
            alignItems="center"
            {...getRootProps()}
            className={clsx(
              classes.dropzoneBase,
              isDragActive && classes.dropzoneAccept,
              isDragReject && classes.dropzoneReject
            )}>
            <input {...getInputProps()} />
            <Grid container item direction="column">
              {!isDragActive && (
                <Grid item xs={12}>
                  <Typography>Click here or drop a file to upload!</Typography>
                </Grid>
              )}
              {isDragActive && !isDragReject && (
                <Grid item xs={12}>
                  <Typography>Drop files here</Typography>
                </Grid>
              )}
              {showPageCountWarning && (
                <Grid item xs={12}>
                  <Typography variant="caption">
                    You have {numAssets} pages, which may delay your results.
                  </Typography>
                </Grid>
              )}
              {pageLimitReached && (
                <Grid item xs={12}>
                  <Typography variant="caption" color="error">
                    You have reached the page limit of {maxNumAssets} pages.
                  </Typography>
                </Grid>
              )}
              {isDragReject && (
                <Grid item xs={12}>
                  <Typography>File type not accepted, sorry!</Typography>
                </Grid>
              )}
              {isFileTooLarge && (
                <Grid item xs={12}>
                  <Typography>File is too large.</Typography>
                </Grid>
              )}

              <Box pt={2}>
                {!isDragReject ? (
                  <Grid item xs={12}>
                    <AddBoxIcon color="primary" />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    <WarningIcon className={classes.dropzoneWarningIcon} />
                  </Grid>
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

DropzoneCard.propTypes = {
  numAssets: PropTypes.number.isRequired,
  maxNumAssets: PropTypes.number.isRequired,
  handleFileInput: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
};

DropzoneCard.defaultProps = {
  multiple: true,
};

export default memo(DropzoneCard);
