import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActionArea,
  CircularProgress,
  Grid,
  List,
  ListItem,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useFetchExperience from '../../../../../hooks/useExperience';
import { useFetchFigmaPrototypeByPage } from '../../../../../hooks/useFetchFigmaPrototype';
import {
  AssetScope,
  ExperienceEndScreenType,
  IntakePrototypeImportStages,
  JobToBeDone,
  MetricTypes,
  TestTypes,
} from '../../../../../modules/intake/constants';
import { numMaxAssets } from '../../../../../modules/intake/helpers';
import { getUserCustomizations } from '../../../../../modules/user/selectors';
import { AuthenticExperienceOption } from '../../../../../modules/wevos/constants';
import { stepHasCustomQuestions } from '../../../../../modules/wevos/helpers';
import theme from '../../../../../theme';
import { AssetList, AssetPreview } from './Assets';
import {
  ExperienceGoalPageSelectionSection,
  ExperienceLoadingSection,
  ExperienceRepairSection,
  JourneyPrototypeCard,
} from './JourneyExperienceComponents';
import { JourneyNameInput, JourneyStartURLInput, JourneyStartURLTitle } from './JourneyPageComponents';

const JourneyPageConfigHandlersProps = PropTypes.shape({
  handleJourneyStartUrlChanged: PropTypes.func.isRequired,
  handleNameChanged: PropTypes.func.isRequired,
  handleCreatePage: PropTypes.func.isRequired,
  handleDeletePage: PropTypes.func.isRequired,
  handleReorderPages: PropTypes.func.isRequired,
});

const JourneyPrototypeConfigHandlersProps = PropTypes.shape({
  handleAuthorizeApp: PropTypes.func.isRequired,
  handleDeletePrototype: PropTypes.func.isRequired,
  handleImportPrototype: PropTypes.func.isRequired,
  handleSetUpPrototype: PropTypes.func.isRequired,
});

const JourneyExperienceConfigHandlersProps = PropTypes.shape({
  setExperienceGoalStep: PropTypes.func.isRequired,
});

const JourneyConfigHandlersProps = PropTypes.shape({
  page: JourneyPageConfigHandlersProps,
  prototype: JourneyPrototypeConfigHandlersProps,
  experience: JourneyExperienceConfigHandlersProps,
});

function JourneyPageConfiguration({
  draft,
  page,
  testType,
  customQuestions,
  handleAssetDelete,
  togglePageBaseline,
  togglePageCompetitor,
  updateAssetFields,
  showBaselineCompetitor,
  ongoingUploads,
  handleFileInput,
  handleReorderAssets,
  journeyConfigHandlers,
}) {
  const assetLimit = numMaxAssets(testType, AssetScope.Step);

  const errors = useMemo(() => {
    const numSteps = (page?.steps ?? []).length;
    const minSteps = 1;

    if (numSteps < minSteps) {
      return { steps: { message: 'Please add at least one insights page' } };
    }

    if (numSteps > assetLimit) {
      return { steps: { message: `Please delete ${numSteps - assetLimit} insights pages` } };
    }

    return { steps: null };
  }, [assetLimit, page]);

  const showDropzone = (page?.steps ?? []).length < assetLimit;

  const userCustomizations = useSelector(getUserCustomizations);

  const hasAuthenticExperience =
    userCustomizations?.authenticExperience === AuthenticExperienceOption.Enabled &&
    draft?.metricType === MetricTypes.Standard;

  const { data: prototype } = useFetchFigmaPrototypeByPage(
    { wevoId: draft.id, pageId: page.id },
    { enabled: hasAuthenticExperience && !_.isNil(draft?.id) && !_.isNil(page?.id) }
  );

  const { data: experience } = useFetchExperience(
    { wevoId: draft.id, pageId: page.id, includeConfig: true },
    { enabled: hasAuthenticExperience && !_.isNil(draft?.id) && !_.isNil(page?.id) }
  );

  const isExperienceSetupComplete = useMemo(() => {
    return experience && Boolean((experience?.steps || []).every((step) => !_.isNil(step?.staticImage)));
  }, [experience]);

  const isExperienceGoalSelected = useMemo(() => {
    return (
      experience &&
      Boolean((experience?.steps || []).find((step) => step.endScreenType === ExperienceEndScreenType.Success))
    );
  }, [experience]);

  const [prototypeImportStage, setPrototypeImportStage] = useState(IntakePrototypeImportStages.Ready);

  const pageInstructions = useMemo(() => {
    if (testType === TestTypes.Usability) {
      return (
        <>
          <Typography variant="h5">Identify the page you would like additional insights on.</Typography>
          <Typography py={2}>
            If you would like to see a sentiment map and page flow analysis on a specific page in the
            experience, please add the page here.
          </Typography>
        </>
      );
    }

    if (draft?.isLiteTestType) {
      return (
        <>
          <Typography variant="h5" id="identify-pages-section-heading">
            Identify pages you would like additional insights on.
          </Typography>
          <Typography py={2}>
            If you would like to see a sentiment map and page flow analysis on specific pages, please add those
            pages here.
          </Typography>
        </>
      );
    }

    return (
      <>
        <Typography variant="h5" id="identify-pages-section-heading">
          Identify pages you would like additional insights on.
        </Typography>
        <Typography py={2}>
          If you would like to see a sentiment map and page flow analysis on specific pages in the journey,
          please add those pages here.
        </Typography>
      </>
    );
  }, [testType, draft]);

  const handleSetupPrototype = useCallback(
    (newPrototype) => {
      journeyConfigHandlers.prototype.handleSetUpPrototype({
        draft,
        page,
        // newly imported prototypes won't have been refreshed from the hook, so we need to override
        prototype: newPrototype || prototype,
        callbacks: {
          onBeforeRequest: () => setPrototypeImportStage(IntakePrototypeImportStages.GeneratingImages),
          onStaticImagesGenerated: () =>
            setPrototypeImportStage(IntakePrototypeImportStages.GeneratingComponents),
          onComponentsGenerated: () => setPrototypeImportStage(IntakePrototypeImportStages.Ready),
          onError: () => setPrototypeImportStage(IntakePrototypeImportStages.Ready),
        },
      });
    },
    [draft, journeyConfigHandlers.prototype, page, prototype, setPrototypeImportStage]
  );

  const journeyStartUrlComponent = !_.isNil(prototype) ? (
    <JourneyPrototypeCard
      // TODO: temporary fallback to wevo start url
      journeyStartUrl={page?.journeyStartUrl ?? draft?.details?.journeyStartUrl}
      handleDeletePrototype={() =>
        journeyConfigHandlers.prototype.handleDeletePrototype({
          draft,
          page,
          prototype,
          callbacks: {
            onSuccess: () => setPrototypeImportStage(IntakePrototypeImportStages.Ready),
          },
        })
      }
      showHelpText={testType !== TestTypes.Usability}
    />
  ) : (
    <JourneyStartURLInput
      handleJourneyStartUrlChanged={(value) =>
        journeyConfigHandlers.page.handleJourneyStartUrlChanged({ draft, page, value })
      }
      handleImportPrototype={() => {
        journeyConfigHandlers.prototype.handleImportPrototype({
          draft,
          page,
          prototype,
          callbacks: {
            onBeforeRequest: () => setPrototypeImportStage(IntakePrototypeImportStages.Importing),
            onSuccess: (data) => {
              setPrototypeImportStage(IntakePrototypeImportStages.Ready);
              handleSetupPrototype(data.prototype);
            },
            onError: () => setPrototypeImportStage(IntakePrototypeImportStages.Ready),
          },
          authorizeCallbacks: {},
        });
      }}
      prototypeImportStage={prototypeImportStage}
      // TODO: temporary fallback to wevo start url
      journeyStartUrl={page?.journeyStartUrl ?? draft?.details?.journeyStartUrl ?? ''}
      helperText={
        testType === TestTypes.Usability ? '' : 'Note: A Journey should take less than 3 minutes to complete.'
      }
      size="md"
      prototypeImportEnabled={hasAuthenticExperience && draft?.jobToBeDone !== JobToBeDone.Lite}
    />
  );

  return (
    <Box>
      <JourneyNameInput
        name={page.name ?? `${draft.name} - New Journey`}
        inputLabel={draft?.isLiteTestType ? 'Name' : 'Journey Name'}
        placeholderLabel={
          draft?.isLiteTestType ? 'Add your Page or Journey name here' : 'Add your Journey name'
        }
        handleNameChanged={(value) => journeyConfigHandlers.page.handleNameChanged({ draft, page, value })}
      />
      <Box my={4} />
      <JourneyStartURLTitle testType={testType} isLiteTestType={draft?.isLiteTestType} />
      {journeyStartUrlComponent}
      <Box my={4} />
      <ExperienceLoadingSection prototypeImportStage={prototypeImportStage} />
      <ExperienceRepairSection
        figmaPrototype={prototype}
        handleSetupPrototype={() => handleSetupPrototype()}
        isExperienceSetupComplete={isExperienceSetupComplete}
        prototypeImportStage={prototypeImportStage}
      />
      {hasAuthenticExperience && isExperienceSetupComplete && (
        <Box mb={4}>
          <ExperienceGoalPageSelectionSection
            wevo={draft}
            experience={experience}
            onExperienceGoalStepSelected={(experienceStep) =>
              journeyConfigHandlers.experience.setExperienceGoalStep({
                draft,
                experienceStep,
                experience,
              })
            }
            isExperienceGoalSelected={isExperienceGoalSelected}
            isExperienceSetupComplete={isExperienceSetupComplete}
          />
        </Box>
      )}
      <Box my={4} />
      {pageInstructions}
      {Boolean(errors?.steps) && (
        <Grid item xs={12}>
          <Typography color="error">{errors?.steps?.message}</Typography>
        </Grid>
      )}
      <AssetList
        ongoingUploads={ongoingUploads}
        showDropzone={showDropzone}
        handleFileInput={(files, fileRejections) =>
          handleFileInput({ files, fileRejections, assetScope: AssetScope.Step, pageId: page.id })
        }
        handleReorderAssets={(result) =>
          handleReorderAssets({ result, assetScope: AssetScope.Step, pageId: page.id })
        }
        numAssets={page?.steps?.length ?? 0}
        maxNumAssets={assetLimit}>
        {(page?.steps ?? []).map((step) => (
          <AssetPreview
            id={step.id}
            key={step?.id || step?.uploadId}
            asset={step}
            customQuestions={customQuestions}
            handleAssetDelete={() =>
              handleAssetDelete({ assetId: step.id, assetScope: AssetScope.Step, pageId: page.id })
            }
            toggleBaseline={togglePageBaseline}
            toggleCompetitor={togglePageCompetitor}
            handleUpdateFields={(_, fields) =>
              updateAssetFields({ assetId: step.id, pageId: page.id, assetScope: AssetScope.Step, fields })
            }
            showBaselineCompetitor={showBaselineCompetitor}
            isLoading={false}
            isDraggable={true}
            shouldWarnOnDelete={stepHasCustomQuestions(customQuestions, step.id)}
          />
        ))}
      </AssetList>
    </Box>
  );
}

JourneyPageConfiguration.propTypes = {
  draft: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  testType: PropTypes.string,
  customQuestions: PropTypes.arrayOf(PropTypes.object),
  handleAssetDelete: PropTypes.func,
  togglePageBaseline: PropTypes.func,
  togglePageCompetitor: PropTypes.func,
  updateAssetFields: PropTypes.func,
  showBaselineCompetitor: PropTypes.bool,
  ongoingUploads: PropTypes.arrayOf(PropTypes.object),
  handleFileInput: PropTypes.func,
  handleReorderAssets: PropTypes.func,
  journeyConfigHandlers: JourneyConfigHandlersProps,
};

const journeyPageConfigurationAccordionStyles = {
  deleteIcon: {
    color: 'white',
    verticalAlign: 'bottom',
    fontSize: '20px',
  },
  deleteText: {
    color: 'white',
    fontSize: '13px',
  },
  delete: {
    cursor: 'pointer',
    marginTop: '20px',
  },
  text: {
    marginTop: (theme) => theme.spacing(2),
  },
  reorderIcon: {
    verticalAlign: 'bottom',
    marginRight: '10px',
  },
};

function JourneyPageConfigurationAccordion({ title, children, handleDeletePage }) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Box>
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          onClick={() => setIsOpen(!isOpen)}
          expandIcon={
            isOpen ? (
              <RemoveIcon sx={{ color: 'white' }} fontSize="small" />
            ) : (
              <AddIcon sx={{ color: 'white' }} fontSize="small" />
            )
          }
          aria-controls="stuff"
          id="stuff"
          sx={{ backgroundColor: theme.palette.primary.main }}>
          <Typography variant="h5" sx={{ color: 'white' }}>
            <DragIndicatorIcon fontSize="small" sx={journeyPageConfigurationAccordionStyles.reorderIcon} />
            {title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box p={2}>{children}</Box>
          <Box>
            <Button
              variant="contained"
              size="medium"
              onClick={() => handleDeletePage()}
              sx={journeyPageConfigurationAccordionStyles.delete}
              startIcon={<DeleteIcon sx={journeyPageConfigurationAccordionStyles.deleteIcon} />}>
              <Typography sx={journeyPageConfigurationAccordionStyles.deleteText}>{'Delete'}</Typography>
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

JourneyPageConfigurationAccordion.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  handleDeletePage: PropTypes.func,
};

const addJourneyButtonStyles = {
  addIcon: {
    marginTop: '8px',
    marginRight: '8px',
    verticalAlign: 'bottom',
    color: (theme) => theme.palette.primary.main,
  },
  disabled: {
    color: 'rgba(0,0,0,0.26)',
  },
  addText: {
    marginTop: '10px',
    marginLeft: (theme) => theme.spacing(2),
    verticalAlign: 'bottom',
  },
  addCard: {
    height: (theme) => theme.spacing(5),
  },
};

function AddJourneyButton({
  addButtonText = 'Add a Journey',
  exceedsLimitText = 'You have hit the limit on journeys for this test.',
  exceedsLimits,
  handleAddPage,
  isLoading = false,
}) {
  return (
    <Box>
      <Tooltip
        title={exceedsLimits ? exceedsLimitText : ''}
        placement="right"
        arrow
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              maxWidth: 250,
            },
          },
        }}>
        <div>
          <CardActionArea onClick={() => handleAddPage()} disabled={exceedsLimits || isLoading}>
            <Card sx={addJourneyButtonStyles.addCard}>
              <Grid container spacing={2} direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <Typography
                    variant="h5"
                    sx={{
                      ...addJourneyButtonStyles.addText,
                      ...(exceedsLimits && addJourneyButtonStyles.disabled),
                    }}>
                    {addButtonText}
                  </Typography>
                </Grid>
                {!isLoading && (
                  <Grid item>
                    <AddBoxIcon
                      sx={{
                        ...addJourneyButtonStyles.addIcon,
                        ...(exceedsLimits && addJourneyButtonStyles.disabled),
                      }}
                    />
                  </Grid>
                )}
                {isLoading && (
                  <Grid item alignSelf={'end'}>
                    <Box px={2}>
                      <CircularProgress size={'1rem'} />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Card>
          </CardActionArea>
        </div>
      </Tooltip>
    </Box>
  );
}

AddJourneyButton.propTypes = {
  exceedsLimits: PropTypes.bool.isRequired,
  handleAddPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

function JourneyPagesReorderableList({ children, handleReorderAssets }) {
  return (
    <DragDropContext onDragEnd={handleReorderAssets}>
      {/* note: droppableId must be a string */}
      <Droppable droppableId="ItemsList">
        {(provided) => (
          <List className="ItemsList" {...provided.droppableProps} ref={provided.innerRef}>
            {!_.isEmpty(children) &&
              children?.map((child, index) => (
                <Draggable
                  // note: draggableId must be a string
                  draggableId={String(child.key)}
                  key={child.key}
                  index={index}>
                  {(provided) => (
                    <ListItem ref={provided.innerRef} {...provided.draggableProps} sx={{ p: 0 }}>
                      <Grid item xs={12} {...provided.dragHandleProps}>
                        {child}
                      </Grid>
                    </ListItem>
                  )}
                </Draggable>
              ))}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function JourneyPageConfigurationList({
  draft,
  testType,
  customQuestions,
  handleAssetDelete,
  togglePageBaseline,
  togglePageCompetitor,
  updateAssetFields,
  showBaselineCompetitor,
  ongoingUploads,
  handleFileInput,
  handleReorderAssets,
  journeyConfigHandlers,
}) {
  // HACK: Allow admins to bypass the limit of compare journeys.
  const useQuery = () => new URLSearchParams(useLocation()?.search);
  const query = useQuery();
  const forceNoLimit = query.get('noLimit') === 'true';

  const assetLimit = numMaxAssets(testType, AssetScope.Page);
  const exceedsLimits = !forceNoLimit && (draft?.pages ?? []).length >= assetLimit;
  const [isAddingPage, setIsAddingPage] = useState(false);

  const errors = useMemo(() => {
    const isLiteTestType = draft?.isLiteTestType;
    const numPages = (draft?.pages ?? []).length;
    const minPages = testType === TestTypes.CompareJourneys ? 2 : 1;

    if (numPages < minPages) {
      let message = isLiteTestType ? 'Please add a single page or journey' : 'Please add a journey';

      const minDelta = numPages - minPages;

      if (testType === TestTypes.CompareJourneys && minDelta > 1) {
        message = 'Please add at least one more journey';
      } else if (testType === TestTypes.CompareJourneys && minDelta === 1) {
        message = 'Please add at least two journeys';
      }

      return { pages: { message } };
    }

    if (numPages > assetLimit) {
      return { pages: { message: `Please delete ${numPages - assetLimit} journeys` } };
    }

    return { pages: null };
  }, [assetLimit, draft, testType]);

  const title = useMemo(() => {
    if (testType === TestTypes.CompareJourneys) {
      return <Typography variant="h5">Compare Journeys</Typography>;
    }

    if (draft?.isLiteTestType) {
      return <Typography variant="h5">Single Page or Journey</Typography>;
    }

    return <Typography variant="h5">Journey</Typography>;
  }, [testType, draft]);

  const defaultNewJourneyName = useCallback(() => {
    if (draft?.isLiteTestType) {
      return draft.name;
    }

    return `${draft.name} - Journey ${(draft?.pages ?? []).length + 1}`;
  }, [draft]);

  const shouldShowAddButton = useMemo(() => {
    if (draft?.isLiteTestType && draft?.pages?.length > 0) {
      return false;
    }

    return true;
  }, [draft]);

  const addButtonText = useMemo(
    () => (draft?.isLiteTestType ? 'Add a Single Page or Journey' : 'Add a Journey'),
    [draft]
  );

  return (
    <>
      {title}
      {Boolean(errors?.pages) && (
        <Grid item xs={12}>
          <Typography color="error">{errors?.pages?.message}</Typography>
        </Grid>
      )}
      <JourneyPagesReorderableList
        handleReorderAssets={(result) => journeyConfigHandlers.page.handleReorderPages({ result })}>
        {(draft?.pages ?? []).map((page, index) => {
          return (
            <Box key={page?.id ?? `pending_journey_${index}`} sx={{ mb: 4, ':last-child': { mb: 2 } }}>
              <JourneyPageConfigurationAccordion
                title={page?.name || `Journey ${index + 1}`}
                handleDeletePage={() => journeyConfigHandlers.page.handleDeletePage({ page })}>
                <JourneyPageConfiguration
                  draft={draft}
                  page={page}
                  testType={testType}
                  customQuestions={customQuestions}
                  handleAssetDelete={handleAssetDelete}
                  togglePageBaseline={togglePageBaseline}
                  togglePageCompetitor={togglePageCompetitor}
                  updateAssetFields={updateAssetFields}
                  showBaselineCompetitor={showBaselineCompetitor}
                  ongoingUploads={ongoingUploads}
                  handleFileInput={handleFileInput}
                  handleReorderAssets={handleReorderAssets}
                  journeyConfigHandlers={journeyConfigHandlers}
                />
              </JourneyPageConfigurationAccordion>
            </Box>
          );
        })}
      </JourneyPagesReorderableList>
      {shouldShowAddButton && (
        <AddJourneyButton
          addButtonText={addButtonText}
          exceedsLimits={exceedsLimits}
          isLoading={isAddingPage}
          handleAddPage={() => {
            setIsAddingPage(true);
            journeyConfigHandlers.page.handleCreatePage({
              draft,
              name: defaultNewJourneyName(),
              callbacks: {
                onSettled: () => setIsAddingPage(false),
              },
            });
          }}
        />
      )}
    </>
  );
}

JourneyPageConfigurationList.propTypes = {
  draft: PropTypes.object.isRequired,
  testType: PropTypes.string,
  customQuestions: PropTypes.arrayOf(PropTypes.object),
  handleAssetDelete: PropTypes.func,
  togglePageBaseline: PropTypes.func,
  togglePageCompetitor: PropTypes.func,
  updateAssetFields: PropTypes.func,
  showBaselineCompetitor: PropTypes.bool,
  ongoingUploads: PropTypes.arrayOf(PropTypes.object),
  handleFileInput: PropTypes.func,
  handleReorderAssets: PropTypes.func,
  journeyConfigHandlers: JourneyConfigHandlersProps,
};

export { JourneyConfigHandlersProps, JourneyPageConfigurationList };
