import PropTypes from 'prop-types';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import { isClassicComparePages, isClassicSinglePage } from '../../../modules/wevos/helpers';
import { Paths } from '../../../routes';
import JourneyDashboard from './JourneyDashboard';
import NewDashboard from './NewDashboard';
import { WevoTestType } from '../../../modules/wevos/constants';

const DashboardContainer = ({
  wevo,
  rightDrawerOpen,
  setRightDrawerOpen,
  handleShareButtonClick,
  isLimitedReport,
}) => {
  if (wevo?.useVersionedDiagnostics) {
    if (wevo?.testType === WevoTestType.Compare) {
      const pageNums = wevo?.pages?.map((page) => page.pageNumber);
      const dashboardPagePath = `${Paths.reports.dashboardPage}(${pageNums.join('|')})`;
      const limitedDashboardPagePath = `${Paths.reports.limitedDashboardPage}(${pageNums.join('|')})`;

      return (
        <Switch>
          <Route
            exact
            path={[dashboardPagePath, limitedDashboardPagePath]}
            render={({ match: { params } }) => {
              const { pageNum } = params;
              return (
                <NewDashboard
                  wevo={wevo}
                  page={wevo?.pages?.find((page) => Number(page.pageNumber) === Number(pageNum))}
                  rightDrawerOpen={rightDrawerOpen}
                  setRightDrawerOpen={setRightDrawerOpen}
                  handleShareButtonClick={handleShareButtonClick}
                  isLimitedReport={isLimitedReport}
                />
              );
            }}
          />
          {isLimitedReport ? (
            <Redirect
              to={generatePath(Paths.reports.limitedDashboardPage, { wevoId: wevo?.id, pageNum: pageNums[0] })}
            />
          ) : (
            <Redirect
              to={generatePath(Paths.reports.dashboardPage, { wevoId: wevo?.id, pageNum: pageNums[0] })}
            />
          )}
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path={[Paths.reports.dashboard, Paths.reports.limitedDashboard]}>
            <NewDashboard
              wevo={wevo}
              page={wevo?.pages?.[0]}
              rightDrawerOpen={rightDrawerOpen}
              setRightDrawerOpen={setRightDrawerOpen}
              handleShareButtonClick={handleShareButtonClick}
              isLimitedReport={isLimitedReport}
            />
          </Route>
          <Redirect to={generatePath(Paths.reports.dashboard, { wevoId: wevo?.id })} />
        </Switch>
      );
    }
  } else {
    if (isClassicComparePages(wevo)) {
      return <Redirect to={generatePath(Paths.reports.compare, { wevoId: wevo?.id })} />;
    } else if (isClassicSinglePage(wevo)) {
      return <Redirect to={generatePath(Paths.reports.sentimentMap, { wevoId: wevo?.id })} />;
    } else {
      return <JourneyDashboard wevo={wevo} page={wevo?.pages?.[0]} />;
    }
  }
};

DashboardContainer.propTypes = {
  wevo: PropTypes.object.isRequired,
  rightDrawerOpen: PropTypes.bool.isRequired,
  setRightDrawerOpen: PropTypes.func.isRequired,
  handleShareButtonClick: PropTypes.func.isRequired,
};

export default DashboardContainer;
