import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchStepHeatmapSummary = async ({ queryKey: [key, { wevoId, stepId }] }) => {
  if (!wevoId || !stepId) {
    return [];
  }

  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/steps/${stepId}/heatmap/summary?maxHeight=1200`,
    method: 'GET',
  });
  return response?.data?.points ?? [];
};

export default function useFetchStepHeatmapSummary({ wevoId, stepId }, options) {
  return useQuery(['stepHeatMapSummary', { wevoId, stepId }], fetchStepHeatmapSummary, options);
}
