import queryString from 'query-string';
import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const Accelerate = () => {
  const history = useHistory();
  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search), [search]);

  useEffect(() => {
    if (!queryParams?.userToken || !queryParams?.authToken) {
      window.location.replace(`${process.env.REACT_APP_HELLO_HOST}/login`);
    }

    const expiresAt = new Date();
    expiresAt.setDate(expiresAt.getDate() + 30);

    cookies.set('userToken', decodeURIComponent(queryParams?.userToken), {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: '/',
      expires: expiresAt,
    });

    cookies.set('authToken', decodeURIComponent(queryParams?.authToken), {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      path: '/',
      expires: expiresAt,
    });

    window.location.href = `${process.env.REACT_APP_BASE_URL}`;
  }, [queryParams, history]);
};

export default Accelerate;
