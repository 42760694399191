import { Box, Divider, Grid, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { ReactComponent as EaseIcon } from '../../../../assets/ease-icon.svg';
import { ReactComponent as SuccessRateIcon } from '../../../../assets/success-rate-icon.svg';
import { ReactComponent as TimeToCompleteIcon } from '../../../../assets/time-to-task-icon.svg';
import { EASE_SCALE, EXPERIENCE_OUTCOMES } from '../../../../modules/report/constants';
import { attributeToDisplayLabel, secondsToMinutes } from '../../../../modules/report/helpers';

const OutcomesBlock = ({ outcome, onOutcomeClick, isSelected }) => {
  const handleOutcomeClick = () => {
    if (onOutcomeClick) {
      onOutcomeClick(outcome?.id);
    }
  };

  return (
    <Grid container rowGap={1} onClick={handleOutcomeClick}>
      <Grid item container xs={12} justifyContent="space-evenly">
        <Grid item container xs={4} alignItems="center" justifyContent="center" columnGap={1}>
          <SuccessRateIcon width="12px" />
          <Typography fontSize={12} sx={{ fontWeight: isSelected && 'bold' }}>
            {EXPERIENCE_OUTCOMES[outcome?.outcome]}
          </Typography>
        </Grid>
        <Divider orientation="vertical" variant="middle" />
        <Grid item container xs={3} alignItems="center" justifyContent="center" columnGap={1}>
          <TimeToCompleteIcon width="12px" />
          <Typography fontSize={12} sx={{ fontWeight: isSelected && 'bold' }}>
            {secondsToMinutes(outcome?.taskTimeSeconds)}
          </Typography>
        </Grid>
        <Divider orientation="vertical" variant="middle" />
        <Grid item container xs={3} alignItems="center" justifyContent="center" columnGap={1}>
          <EaseIcon width="12px" />
          <Typography fontSize={12} sx={{ fontWeight: isSelected && 'bold' }}>
            {outcome?.easeScore}/{EASE_SCALE}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Box pt={1} pl={1}>
          {outcome?.attributes &&
            outcome.attributes.map((attribute, index) => (
              <Fragment key={attribute.attributeId}>
                <Tooltip title={attribute.attributeLabel}>
                  <Typography variant="caption" component="span" fontSize={10} color="#757575">
                    {index > 0 && ', '}
                    {attributeToDisplayLabel(attribute)}
                  </Typography>
                </Tooltip>
              </Fragment>
            ))}
        </Box>
      </Grid>
    </Grid>
  );
};

OutcomesBlock.propTypes = {
  outcome: PropTypes.object.isRequired,
  onOutcomeClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default OutcomesBlock;
