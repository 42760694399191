import React from 'react';
import _ from 'lodash';
import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import { ExperienceGoalSelection } from '../../ExperienceScreenSelection';
import { useSelector } from 'react-redux';
import { getUserCustomizations } from '../../../../../modules/user/selectors';
import { AuthenticExperienceOption } from '../../../../../modules/wevos/constants';
import PropTypes from 'prop-types';
import FigmaPrototypeCard from './FigmaPrototypeCard';
import { IntakePrototypeImportStages } from '../../../../../modules/intake/constants';

function ExperienceLoadingSection({ prototypeImportStage, page }) {
  const userCustomizations = useSelector(getUserCustomizations);
  const hasAuthenticExperience = userCustomizations?.authenticExperience === AuthenticExperienceOption.Enabled;

  if (
    !hasAuthenticExperience ||
    ![IntakePrototypeImportStages.GeneratingImages, IntakePrototypeImportStages.GeneratingComponents].includes(
      prototypeImportStage
    )
  ) {
    return <></>;
  }

  return (
    <Box py={2}>
      <Grid container textAlign="center">
        <Grid item xs={12}>
          {page && (
            <Box mb={2}>
              <Typography fontWeight={600}>Setting up prototype for page {page.name}</Typography>
            </Box>
          )}
          <Box>
            <Typography fontWeight={600}>Almost done!</Typography>
          </Box>
          <Box mt={3} mb={2}>
            <CircularProgress />
            {prototypeImportStage === IntakePrototypeImportStages.GeneratingImages && (
              <Typography>Downloading images...</Typography>
            )}
            {prototypeImportStage === IntakePrototypeImportStages.GeneratingComponents && (
              <Typography>Generating assets...</Typography>
            )}
          </Box>
          <Box>
            <Typography>Please don't refresh the page. This may take a minute.</Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ExperienceLoadingSection.propTypes = {
  prototypeImportStage: PropTypes.string.isRequired,
};

function ExperienceRepairSection({
  figmaPrototype,
  handleSetupPrototype,
  isExperienceSetupComplete,
  prototypeImportStage,
}) {
  const userCustomizations = useSelector(getUserCustomizations);
  const hasAuthenticExperience = userCustomizations?.authenticExperience === AuthenticExperienceOption.Enabled;

  if (
    !hasAuthenticExperience ||
    _.isNil(figmaPrototype) ||
    isExperienceSetupComplete ||
    ![IntakePrototypeImportStages.Ready].includes(prototypeImportStage)
  ) {
    return <></>;
  }
  return (
    <Box pt={2}>
      <Grid container alignContent="center">
        <Grid item xs={9}>
          <Box pr={1}>
            <Typography color="error">
              Incomplete setup detected. Please click the button to finish setting up or reimport your
              prototype.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Button
            size="sm"
            variant="contained"
            sx={{ borderRadius: '10px' }}
            onClick={() => handleSetupPrototype(figmaPrototype)}>
            Finish Setup
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Box px={1}>
            <Typography color="error" variant="caption">
              Note: This process can take a few minutes. Please don't refresh your browser while setup is in
              progress.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

ExperienceRepairSection.propTypes = {
  figmaPrototype: PropTypes.object,
  handleSetupPrototype: PropTypes.func,
  isExperienceSetupComplete: PropTypes.bool,
  prototypeImportStage: PropTypes.string.isRequired,
};

function ExperienceGoalPageSelectionSection({
  wevo,
  experience,
  onExperienceGoalStepSelected,
  isExperienceGoalSelected,
  isExperienceSetupComplete,
}) {
  return (
    <Box mb={4}>
      <Grid item xs={12}>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5">Identify your goal pages</Typography>
            <Typography py={1}>
              We'll use your destination pages to determine the success rate and time to complete for your
              experience.
            </Typography>
          </Grid>
          {!isExperienceGoalSelected && (
            <Grid item xs={12}>
              <Typography color="error">Please add at least one goal page for this experience</Typography>
            </Grid>
          )}
          {isExperienceSetupComplete && (
            <Grid item xs={12}>
              <Card sx={{ mb: 2 }}>
                <CardContent>
                  <ExperienceGoalSelection
                    wevo={wevo}
                    experience={experience}
                    onSelected={onExperienceGoalStepSelected}
                  />
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

function JourneyPrototypeCard({ journeyStartUrl, handleDeletePrototype, showHelpText = true }) {
  return (
    <div>
      <FigmaPrototypeCard url={journeyStartUrl} deletePrototype={handleDeletePrototype} />
      {showHelpText && (
        <Typography
          variant="body1"
          sx={{
            fontSize: '12px',
            color: 'grey',
            marginTop: '8px',
            marginLeft: '14px',
          }}>
          Note: A Journey should take less than 3 minutes to complete.
        </Typography>
      )}
    </div>
  );
}

JourneyPrototypeCard.propTypes = {
  handleDeletePrototype: PropTypes.func,
  journeyStartUrl: PropTypes.string.isRequired,
  showHelpText: PropTypes.bool,
};

export {
  ExperienceGoalPageSelectionSection,
  ExperienceLoadingSection,
  ExperienceRepairSection,
  JourneyPrototypeCard,
};
