import produce from 'immer';
import { get as _get } from 'lodash';
import { useMutation, useQueryClient } from 'react-query';
import axios from '../../../api';
import { MutationKeys } from '../../../modules/intake/constants';

const uploadImagesAndCreateSteps = async ({ wevoId, pageId, files, deviceId, primerId, primerContext }) => {
  const formData = new FormData();
  files.forEach((file) => formData.append('files', file));
  formData.append('wevoId', wevoId);
  formData.append('deviceId', deviceId);
  formData.append('pageId', pageId);

  // Primer is included because we may need to create a page if one doesn't exist
  if (primerId) {
    formData.append('primerId', primerId);
  }
  if (primerContext) {
    formData.append('primerContext', primerContext);
  }

  const response = await axios({
    url: `/api/v2/wevos/${wevoId}/images`,
    method: 'POST',
    data: formData,
  });

  return response.data.steps;
};

export default function useUploadStep() {
  const queryClient = useQueryClient();
  return useMutation(uploadImagesAndCreateSteps, {
    mutationKey: MutationKeys.uploadStep,
    onSuccess: (steps, variables) => {
      queryClient.setQueryData(['wevoData', { wevoId: variables.wevoId }], (oldData) => {
        return produce(oldData, (draft) => {
          const currentSteps = _get(draft, 'pages.0.steps', []);
          currentSteps.concat(steps);
        });
      });
      queryClient.invalidateQueries(['customQuestionsData', { wevoId: variables.wevoId }]);
    },
  });
}
