import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const ExperienceStepCard = ({ name, image, width, height, icon, cursor = 'initial', text }) => {
  const theme = useTheme();

  const smallWidth = 0.8 * width;
  const smallHeight = 0.8 * height;
  const mediumWidth = 0.75 * width;
  const mediumHeight = 0.75 * height;

  const textContent = () => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{ width: { xs: smallWidth, md: mediumWidth, lg: width }, height: 'inherit' }}>
        {icon ? (
          <Box display="flex" columnGap={1} alignItems="center">
            {icon}
            <Typography variant="body2">{text}</Typography>
          </Box>
        ) : (
          <Typography variant="body2" align="center">
            {text}
          </Typography>
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        minHeight: 146,
        height: text && '100%',
        padding: 2,
        backgroundColor: theme.palette.background.default,
        borderRadius: '10px',
        cursor: cursor,
      }}>
      {text ? (
        textContent()
      ) : (
        <>
          <Box
            display="flex"
            columnGap={1}
            alignItems="center"
            mb={1}
            sx={{ width: { xs: smallWidth, md: mediumWidth, lg: width } }}>
            {!!icon && icon}
            <Typography
              variant="body2"
              sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
              {name}
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: smallWidth, md: mediumWidth, lg: width },
              height: { xs: smallHeight, md: mediumHeight, lg: height },
              borderRadius: '10px',
              overflow: 'hidden',
            }}>
            <img style={{ width: '100%' }} src={image?.url} alt="page thumbnail" />
          </Box>
        </>
      )}
    </Box>
  );
};

ExperienceStepCard.propTypes = {
  name: PropTypes.string,
  image: PropTypes.object,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  icon: PropTypes.element,
  cursor: PropTypes.string,
  text: PropTypes.string,
};

export default ExperienceStepCard;
