import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { wevoStatus } from '../../modules/wevos/constants';

const WevoOptionsButton = ({ wevo, setOptionsMenuWevo, handleOptionsClick }) => {
  const isLaunchedTest =
    (wevo.status === wevoStatus['pending'] || wevo.status === wevoStatus['running']) && !wevo.isArchived;
  const canEdit = wevo.canEdit && !isLaunchedTest;

  const onOptionsClick = (ev) => {
    setOptionsMenuWevo(wevo);
    handleOptionsClick(ev);
  };

  return (
    <Tooltip title={'Options'}>
      <span>
        <IconButton
          aria-label="options"
          aria-haspopup="true"
          aria-disabled={!canEdit}
          onClick={onOptionsClick}
          size="large">
          <MoreVertIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default WevoOptionsButton;
