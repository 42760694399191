import { Box, CircularProgress, Container } from '@mui/material';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useSnackbar } from 'notistack';
import { createRef, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import { useFetchExperiences } from '../../../hooks/useExperience';
import useWevo from '../../../hooks/useWevo';
import useWevos from '../../../hooks/useWevos';
import {
  CustomQuestionTypes,
  ExperienceEndScreenType,
  IntakeStepIds,
  JobToBeDone,
  MetricTypes,
  MultiSelectOptions,
  PrimerValues,
  SelectOptions,
  TestTypes,
} from '../../../modules/intake/constants';
import * as UserActions from '../../../modules/user/actions';
import { getUserCustomizations } from '../../../modules/user/selectors';
import {
  AudienceDisplay,
  CustomQuestionOption,
  JTBDType,
  KeyFindingOption,
  UsabilityOption,
  LiteOption,
  WevoTestType,
  WevoType,
} from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import { Paths } from '../../../routes';
import Stepper from '../../../ui/IntakeStepper';
import { TrackEvent } from '../../analytics';
import useAddSavedAudience from '../hooks/useAddSavedAudience';
import useDeleteSavedAudience from '../hooks/useDeleteSavedAudience';
import useFetchCustomQuestions from '../hooks/useFetchCustomQuestions.js';
import useFetchSavedAudience from '../hooks/useFetchSavedAudience';
import useLaunchWevo from '../hooks/useLaunchWevo';
import useSaveWevo from '../hooks/useSaveWevo';
import BottomBar from './BottomBar';
import DefineTest from './DefineTest';
import Details from './Details';
import Review from './Review';
import SelectAudience from './SelectAudience';
import TestGoals from './TestGoals';
import TestTypePage from './TestType';
import { DEVICE_NAME_TO_DEVICE_ID } from '../../../modules/wevos/constants';
import { BackToTopButton } from '../BackToTopButton';
import { isPrimerConfigured } from '../../../modules/intake/helpers';

const NUM_CUSTOM_YES_NO = 2;
const NUM_CUSTOM_LIKERT_5 = 5;
const NUM_CUSTOM_LIKERT_7 = 7;
const MIN_NUM_MULTIPLE_CHOICE = 2;
const MAX_NUM_MULTIPLE_CHOICE = 10;
const MIN_NUM_MULTI_SELECT = 2;
const MAX_NUM_MULTI_SELECT = 15;

const EditTest = (props) => {
  const { fetchUser, userCustomizations } = props;
  const { wevoId, stepId } = useParams();
  const { mutate: saveWevo } = useSaveWevo();
  let history = useHistory();
  let location = useLocation();
  const { closeSnackbar } = useSnackbar();
  const { data: draft, isLoading: isDraftLoading } = useWevo(wevoId);
  const { data: wevos } = useWevos();
  const { mutate: launchWevo, isLoading: isLaunching } = useLaunchWevo();
  const canRunUsability = userCustomizations?.usabilityTestType !== UsabilityOption.Disabled;
  const canRunLite = userCustomizations?.liteTestType !== LiteOption.Disabled;

  const testType = useMemo(() => {
    if (_.isEmpty(draft)) {
      return TestTypes.Journey;
    }

    const type = draft.type;
    const testType = draft.testType;
    const isUsabilityTestType = draft.isUsabilityTestType && canRunUsability;
    const isLiteTestType = draft.isLiteTestType && canRunLite;

    if (type === WevoType.Journey && isUsabilityTestType) {
      return TestTypes.Usability;
    }

    if (type === WevoType.Journey && isLiteTestType) {
      return TestTypes.Lite;
    }

    if (type === WevoType.Journey && testType === WevoTestType.Compare) {
      return TestTypes.CompareJourneys;
    }

    if (type === WevoType.Journey && testType === WevoTestType.Page) {
      return TestTypes.Journey;
    }

    if (type === WevoType.Classic && testType === WevoTestType.Compare) {
      return TestTypes.ComparePages;
    }

    if (type === WevoType.Classic && testType === WevoTestType.Page) {
      return TestTypes.SinglePage;
    }

    return null; // this is bad
  }, [canRunLite, canRunUsability, draft]);

  // this field tracks server state of primer selection for validation purposes only
  const hasPrimerConfigured = useMemo(
    () => (isPrimerConfigured(draft) ? PrimerValues.Yes : PrimerValues.No),
    [draft]
  );

  const experiencesRequests = useFetchExperiences(
    (draft?.pages || [])
      .filter((page) => !_.isNil(page?.id))
      .map((page) => {
        return { wevoId, pageId: page.id, includeConfig: true };
      }),
    {
      enabled:
        !isDraftLoading &&
        [TestTypes.Journey, TestTypes.CompareJourneys, TestTypes.Usability].includes(testType),
    }
  );

  const experiences = useMemo(() => {
    const loading = experiencesRequests.some((request) => request.isLoading);

    if (loading) {
      return [];
    }

    return experiencesRequests.map((request) => request?.data);
  }, [experiencesRequests]);

  const areExperiencesValid = useMemo(() => {
    if (!experiences || experiences.every((experience) => _.isNil(experience))) {
      return true;
    }

    return experiences.every((experience) => {
      return (
        _.isNil(experience) ||
        experience.steps.find((step) => step.endScreenType === ExperienceEndScreenType.Success)
      );
    });
  }, [experiences]);

  const { data: savedAudiences, isSuccess: isFetchSavedAudienceSuccess } = useFetchSavedAudience();
  const [audienceDisplay, setAudienceDisplay] = useState('');
  const [selectedAudienceGroupId, setSelectedAudienceGroupId] = useState(null);
  const [savedAudienceName, setSavedAudienceName] = useState(
    savedAudiences?.find((savedAudience) => savedAudience?.id === draft.savedAudienceGroupId)?.name ?? ''
  );
  const [showAudienceNameBox, setShowAudienceNameBox] = useState(
    Boolean(
      savedAudiences?.find((savedAudience) => savedAudience?.id === draft?.savedAudienceGroupId)?.isDraft
    )
  );
  const initialSteps = location?.state?.initialSteps;

  const { mutate: addSavedAudience } = useAddSavedAudience();
  const { mutate: deleteSavedAudience } = useDeleteSavedAudience();

  const isDQS = draft?.metricType === MetricTypes.MastercardDqs;
  const isCDS = draft?.metricType === MetricTypes.MastercardCds;

  const { data: groups } = useFetchCustomQuestions(wevoId);

  const [isDefinitionValid, setIsDefinitionValid] = useState(
    JSON.parse(sessionStorage.getItem('isDefinitionValid')) || false
  );
  const [isAudienceValid, setIsAudienceValid] = useState(
    JSON.parse(sessionStorage.getItem('isAudienceValid')) || false
  );
  const [isPagesValid, setIsPagesValid] = useState(
    JSON.parse(sessionStorage.getItem('isPagesValid')) || false
  );
  const [isDetailsValid, setIsDetailsValid] = useState(
    JSON.parse(sessionStorage.getItem('isDetailsValid')) || false
  );
  const [isTestGoalsValid, setIsTestGoalsValid] = useState(
    JSON.parse(sessionStorage.getItem('isTestGoalsValid')) || false
  );

  const { track } = useAnalytics();
  const queryClient = useQueryClient();

  // this function is being called by the TestType component when the testType is changed by the user,
  // so it saves the new testType using saveWevo
  const testTypeChanged = (newTestType) => {
    if (
      [TestTypes.Usability, TestTypes.Lite].includes(testType) &&
      ![TestTypes.Journey, TestTypes.CompareJourneys, TestTypes.Usability, TestTypes.Lite].includes(
        newTestType
      )
    ) {
      // if switching away from Usability to Compare/Single page, we need to change the wevo type to Classic
      saveWevo(
        {
          id: wevoId,
          type: WevoType.Classic,
          jobToBeDone: JTBDType.Other,
          includeKeyFindings: true,
          testType: newTestType === TestTypes.ComparePages ? WevoTestType.Compare : WevoTestType.Page,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['customQuestionsData', { wevoId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    } else if (
      [TestTypes.Usability, TestTypes.Lite].includes(testType) &&
      [TestTypes.Journey, TestTypes.CompareJourneys].includes(newTestType)
    ) {
      // if switching away from Usability to Compare/Single page, we need to change the wevo type to Classic
      saveWevo(
        {
          id: wevoId,
          type: WevoType.Journey,
          jobToBeDone: JTBDType.Other,
          includeKeyFindings: true,
          testType: newTestType === TestTypes.CompareJourneys ? WevoTestType.Compare : WevoTestType.Page,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['customQuestionsData', { wevoId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    } else if (canRunUsability && newTestType === TestTypes.Usability) {
      // if switching to Usability, we need to change the wevo type to Journey and to update the jobToBeDone === "usability"
      saveWevo(
        {
          id: wevoId,
          type: WevoType.Journey,
          jobToBeDone: JobToBeDone.Usability,
          includeKeyFindings: false,
          devices: [DEVICE_NAME_TO_DEVICE_ID.desktop],
          testType: WevoTestType.Page,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['customQuestionsData', { wevoId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    } else if (canRunLite && newTestType === TestTypes.Lite) {
      // if switching to Lite, we need to change the wevo type to Journey and to update the jobToBeDone === "lite"
      saveWevo(
        {
          id: wevoId,
          type: WevoType.Journey,
          jobToBeDone: JobToBeDone.Lite,
          includeKeyFindings: false,
          testType: WevoTestType.Page,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['customQuestionsData', { wevoId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    } else if (
      [TestTypes.Journey, TestTypes.CompareJourneys, TestTypes.Lite].includes(testType) &&
      [TestTypes.SinglePage, TestTypes.ComparePages].includes(newTestType)
    ) {
      // if switching away from Journey it must be to Compare/Single page, we need to change the wevo type to Classic
      saveWevo(
        {
          id: wevoId,
          type: WevoType.Classic,
          jobToBeDone: JTBDType.Other,
          testType: newTestType === TestTypes.ComparePages ? WevoTestType.Compare : WevoTestType.Page,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['customQuestionsData', { wevoId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    } else if (
      [TestTypes.SinglePage, TestTypes.ComparePages].includes(testType) &&
      [TestTypes.Journey, TestTypes.CompareJourneys].includes(newTestType)
    ) {
      // if switching from compare or single to a journey we need to change the wevo type to Journey
      saveWevo(
        {
          id: wevoId,
          type: WevoType.Journey,
          jobToBeDone: JTBDType.Other,
          includeKeyFindings: true,
          testType: newTestType === TestTypes.CompareJourneys ? WevoTestType.Compare : WevoTestType.Page,
        },
        {
          onSuccess: () => {
            // Clears the customQuestionsData object in the cache when switching types
            // because all custom questions are being deleted
            queryClient.invalidateQueries(['customQuestionsData', { wevoId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    } else if (
      (testType === TestTypes.ComparePages && newTestType === TestTypes.SinglePage) ||
      (testType === TestTypes.SinglePage && newTestType === TestTypes.ComparePages)
    ) {
      // we must be switching from compare to single or vice versa, so only need to update the wevo test type
      saveWevo(
        {
          id: wevoId,
          testType: newTestType === TestTypes.ComparePages ? WevoTestType.Compare : WevoTestType.Page,
        },
        {
          onSuccess: () => {
            // Clears the customQuestionsData object in the cache when switching types
            // because all custom questions are being deleted
            queryClient.invalidateQueries(['customQuestionsData', { wevoId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    } else if (
      (testType === TestTypes.CompareJourneys && newTestType === TestTypes.Journey) ||
      (testType === TestTypes.Journey && newTestType === TestTypes.CompareJourneys)
    ) {
      // if switching from compare journey to single journey or vice-versa we need to change the test type
      saveWevo(
        {
          id: wevoId,
          type: WevoType.Journey,
          jobToBeDone: JTBDType.Other,
          includeKeyFindings: true,
          testType: newTestType === TestTypes.CompareJourneys ? WevoTestType.Compare : WevoTestType.Page,
        },
        {
          onSuccess: () => {
            // Clears the customQuestionsData object in the cache when switching types
            // because all custom questions are being deleted
            queryClient.invalidateQueries(['customQuestionsData', { wevoId }]);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    }
  };

  useEffect(() => {
    fetchUser(); // ensure we have user's info
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEmpty(draft) && !draft.launched) {
      // the extra check for if the draft is launched prevents an issue where the session storage would be cleared in the Review component upon successful launch,
      // and then just the draft would be set again in the storage from this component,
      // because the change of draft.launched being set to true hadn't propogated back up to here until after the clearDraft action had already completed
      // hacky, but works for now
      sessionStorage.setItem('draft', JSON.stringify(draft));
    }

    const duplicateTestName = Boolean(
      wevos?.filter((wevo) => wevo.id !== draft.id)?.find((wevo) => wevo.name === draft.name)
    );

    setIsDefinitionValid(!!draft.id && !!draft.name && !duplicateTestName);
  }, [draft, wevos]);

  useEffect(() => {
    if (isFetchSavedAudienceSuccess) {
      const groupId = draft?.savedAudienceGroupId;
      const savedAudienceGroupIsDraft = savedAudiences?.find(
        (savedAudience) => savedAudience?.id === groupId
      )?.isDraft;

      if (!groupId || savedAudienceGroupIsDraft) {
        setSelectedAudienceGroupId(null);
        setAudienceDisplay(AudienceDisplay.SelectAudience);
      } else {
        setSelectedAudienceGroupId(groupId);
        setAudienceDisplay(AudienceDisplay.SavedAudience);
      }
    }
    // set audience state once data is fetched
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchSavedAudienceSuccess]);

  useEffect(() => {
    sessionStorage.setItem('isDefinitionValid', JSON.stringify(isDefinitionValid));
  }, [isDefinitionValid]);

  useEffect(() => {
    sessionStorage.setItem('isAudienceValid', JSON.stringify(isAudienceValid));
  }, [isAudienceValid]);

  useEffect(() => {
    sessionStorage.setItem('isPagesValid', JSON.stringify(isPagesValid));
  }, [isPagesValid]);

  useEffect(() => {
    sessionStorage.setItem('isDetailsValid', JSON.stringify(isDetailsValid));
  }, [isDetailsValid]);

  useEffect(() => {
    sessionStorage.setItem('isTestGoalsValid', JSON.stringify(isTestGoalsValid));
  }, [isTestGoalsValid]);

  const setStep = (newStep) => {
    history.push(`/wevos/${wevoId}/edit/${newStep}`);
  };

  const isFirstStep = () => {
    return stepIndex === 0;
  };

  const isLastStep = () => {
    return stepIndex === steps.length - 1;
  };

  /**
   * Determines is the current step is valid and can continue.
   */
  const isStepValid = () => {
    switch (stepId) {
      case IntakeStepIds.Start:
        // Basic Info
        return isDefinitionValid;
      case IntakeStepIds.TestGoal:
        // Test Goals
        return isTestGoalsValid;
      case IntakeStepIds.TestType:
        // Pages
        return isPagesValid;
      case IntakeStepIds.Audience:
        // Audience
        return isAudienceValid;
      case IntakeStepIds.Details:
        // Details
        return isDetailsValid;
      default:
        // Review
        return isDefinitionValid && isTestGoalsValid && isAudienceValid && isPagesValid && isDetailsValid;
    }
  };

  const incrementStep = () => {
    const currentStepIndex = stepIndex;
    const stepRef = getRefForStep(currentStepIndex);

    handleSavedAudienceState();

    if (stepRef && stepRef.current) {
      stepRef.current.submit();
    }

    setStep(steps[currentStepIndex + 1].id);
  };

  const decrementStep = () => {
    const currentStepIndex = stepIndex;
    const stepRef = getRefForStep(currentStepIndex);
    if (stepRef && stepRef.current) {
      stepRef.current.submit();
    }
    setStep(steps[currentStepIndex - 1].id);
  };

  const handleSubmit = (ev) => {
    track(TrackEvent.LAUNCHED_TEST, {
      wevoId: draft?.analyticsId,
      draftId: draft?.id,
      testType: draft?.type,
    });

    ev.preventDefault();
    closeSnackbar();

    launchWevo(
      { id: draft?.id },
      {
        onError: (err) => {
          snackbar.error(
            err?.response?.data?.humanReadableMessage ?? 'Something went wrong, please try again.'
          );
        },
      }
    );
  };

  const createSavedAudience = useCallback(
    (wevoId, name, draft) =>
      addSavedAudience(
        { id: wevoId, name, draft },
        {
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error creating saved audience');
          },
        }
      ),
    [addSavedAudience]
  );

  const saveAndExit = () => {
    track(TrackEvent.SAVE_AND_EXIT_TEST, {
      wevoId: draft?.analyticsId,
      stepId: stepId,
      draftId: draft?.id,
      testType: draft?.type,
    });

    sessionStorage.removeItem('draft');
    sessionStorage.removeItem('isDefinitionValid');
    sessionStorage.removeItem('isAudienceValid');
    sessionStorage.removeItem('isPagesValid');
    sessionStorage.removeItem('isDetailsValid');
    sessionStorage.removeItem('isTestGoalsValid');

    queryClient.invalidateQueries(['wevoData', { wevoId: draft?.analyticsId }]);

    handleSavedAudienceState();
    history.push('/dashboard');
  };

  const handleSavedAudienceState = () => {
    if (
      (audienceDisplay === AudienceDisplay.SelectAudience ||
        (audienceDisplay === AudienceDisplay.SavedAudience && selectedAudienceGroupId === null)) &&
      showAudienceNameBox &&
      savedAudienceName &&
      stepId === IntakeStepIds.Audience
    ) {
      createSavedAudience(wevoId, savedAudienceName, true);
    } else if (
      (audienceDisplay === AudienceDisplay.SelectAudience ||
        (audienceDisplay === AudienceDisplay.SavedAudience && selectedAudienceGroupId === null)) &&
      !showAudienceNameBox &&
      !isNil(draft.savedAudienceGroupId)
    ) {
      saveWevo({ id: wevoId, savedAudienceGroupId: null });
      const draftSavedAudiences = savedAudiences?.filter((savedAudience) => savedAudience?.isDraft);
      draftSavedAudiences.forEach((draftSavedAudience) => {
        deleteSavedAudienceGroup(draftSavedAudience.id);
      });
    } else if (audienceDisplay === AudienceDisplay.SavedAudience) {
      if (selectedAudienceGroupId !== null) {
        saveWevo({ id: wevoId, savedAudienceGroupId: selectedAudienceGroupId });
        const draftSavedAudiences = savedAudiences?.filter((savedAudience) => savedAudience?.isDraft);
        draftSavedAudiences.forEach((draftSavedAudience) => {
          deleteSavedAudienceGroup(draftSavedAudience.id);
        });
      }
    }
  };

  const deleteSavedAudienceGroup = useCallback(
    (groupId) =>
      deleteSavedAudience(
        { groupId },
        {
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting saved audience');
          },
        }
      ),
    [deleteSavedAudience]
  );

  const checkTestGoalsValidity = useCallback(() => {
    return !!draft.description;
  }, [draft.description]);

  useEffect(() => {
    if (!!draft.audience && !isEmpty(draft.audience.attributes)) {
      let quotaTotals = new Set();
      draft.audience.attributes.forEach((attribute) => {
        let quotaTotal = 0;
        attribute.segments.forEach((segment) => {
          quotaTotal += segment.percentage;
        });
        quotaTotals.add(quotaTotal);
      });
      setIsAudienceValid(quotaTotals.size === 1 && quotaTotals.has(100));
    } else {
      setIsAudienceValid(false);
    }
  }, [draft.audience]);

  useEffect(() => {
    setIsTestGoalsValid(checkTestGoalsValidity());
  }, [draft.description, checkTestGoalsValidity]);

  useEffect(() => {
    // visitor objective is not collected for DQS, CDS, or Usability tests
    const hasVisitorObjective =
      isDQS || isCDS || [TestTypes.Usability, TestTypes.Lite].includes(testType)
        ? true
        : Boolean(draft?.visitorObjective);

    // A primer is valid if there is context or no primer is configured (primers are optional)
    const hasValidPrimer =
      hasPrimerConfigured === PrimerValues.Yes ? Boolean(draft?.pages?.[0]?.primerContext) : true;

    // Experiences are not supported for DQS or CDS and `areExperiencesValid` accounts for an experience not having been configured
    const hasValidExperiences = isDQS || isCDS ? true : areExperiencesValid;

    // Task to complete is required for draft.type === "journey", so we only test that condition
    const hasTaskToComplete = Boolean(draft?.details?.taskToComplete);

    if ([TestTypes.SinglePage, TestTypes.ComparePages].includes(testType)) {
      const numAssets = (draft.pages || []).length;
      const hasValidNumAssets = testType === TestTypes.ComparePages ? numAssets > 1 : numAssets === 1;
      setIsPagesValid(hasValidNumAssets && hasVisitorObjective && hasValidPrimer);
      return;
    }

    if (
      [TestTypes.Journey, TestTypes.Usability, TestTypes.CompareJourneys, TestTypes.Lite].includes(testType)
    ) {
      const numPages = (draft?.pages ?? []).length;

      // Journey and Usability Tests should have exactly one page. Compare Journeys should have at least two pages.
      const hasValidNumPages = testType === TestTypes.CompareJourneys ? numPages > 1 : numPages === 1;

      // Usability Tests should have exactly one step asset. Journeys and Compare Journeys need at least one step asset.
      const hasValidNumAssets = (draft?.pages ?? []).every((page) => {
        const numSteps = (page?.steps ?? []).length;
        return testType === TestTypes.Usability ? numSteps === 1 : numSteps > 0;
      });

      setIsPagesValid(
        hasValidNumPages &&
          hasValidNumAssets &&
          hasTaskToComplete &&
          hasVisitorObjective &&
          hasValidPrimer &&
          hasValidExperiences
      );
    }
  }, [areExperiencesValid, draft, draft.details, draft.pages, testType, hasPrimerConfigured, isDQS, isCDS]);

  useEffect(() => {
    const savedAudience = savedAudiences?.find(
      (savedAudience) => savedAudience?.id === draft?.savedAudienceGroupId
    );
    setSavedAudienceName(savedAudience?.name || '');
    setShowAudienceNameBox(Boolean(savedAudience?.isDraft));
  }, [isFetchSavedAudienceSuccess, draft.savedAudienceGroupId, savedAudiences]);

  const checkValidity = (group) => {
    const isScopesSectionValid = group?.isScopesSectionValid ?? true;
    const questionText = group?.question?.questionText ?? '';
    const followUpQuestionText = group?.question?.followUpQuestionText ?? '';
    const type = group?.question?.type;
    const labelsType = group?.question?.labelsType ?? '';
    const labels = group?.question?.labels ?? [];
    const rangeType = group?.question?.rangeType ?? '';
    const number = group?.question?.number ?? '';

    if (!isScopesSectionValid) {
      return false;
    } else if (isEmpty(questionText)) {
      return false;
    } else if (type === CustomQuestionTypes.Heatmap && isEmpty(followUpQuestionText)) {
      return false;
    } else {
      const allOptionsFilled = Boolean(labels.every((label) => !isEmpty(label)));
      if (type === CustomQuestionTypes.FiveLikertScale) {
        if (labelsType === SelectOptions.Custom) {
          if (
            labels.length !== NUM_CUSTOM_LIKERT_5 ||
            (labels.length === NUM_CUSTOM_LIKERT_5 && !allOptionsFilled)
          ) {
            return false;
          }
        } else if (labelsType === '') {
          return false;
        }
      } else if (type === CustomQuestionTypes.SevenLikertScale) {
        if (labelsType === SelectOptions.Custom) {
          if (
            labels.length !== NUM_CUSTOM_LIKERT_7 ||
            (labels.length === NUM_CUSTOM_LIKERT_7 && !allOptionsFilled)
          ) {
            return false;
          }
        } else if (labelsType === '') {
          return false;
        }
      } else if (type === CustomQuestionTypes.MultipleChoice) {
        if (!(labels.length >= MIN_NUM_MULTIPLE_CHOICE && labels.length <= MAX_NUM_MULTIPLE_CHOICE)) {
          return false;
        }
      } else if (type === CustomQuestionTypes.MultiSelect) {
        if (!(labels.length >= MIN_NUM_MULTI_SELECT && labels.length <= MAX_NUM_MULTI_SELECT)) {
          return false;
        }
        if (rangeType !== MultiSelectOptions.AllThatApply && (labels.length <= number || number === '')) {
          return false;
        }
      } else if (type === CustomQuestionTypes.YesNoTrueFalse) {
        if (labelsType === SelectOptions.Custom) {
          if (
            labels.length !== NUM_CUSTOM_YES_NO ||
            (labels.length === NUM_CUSTOM_YES_NO && !allOptionsFilled)
          ) {
            return false;
          }
        } else if (labelsType === '') {
          return false;
        }
      }
    }
    return true;
  };

  const checkTestCustomQuestionValidity = (draft) => {
    const draftType = draft?.type;
    const testType = draft?.testType;

    // for now, compare journeys only allow page / journey scoped custom queestions
    if (draftType === WevoType.Journey && testType === WevoTestType.Compare) {
      return !(draft?.pages ?? [])
        .flatMap((page) => page?.customQuestions ?? [])
        .some((customQuestion) => {
          return !_.isNil(customQuestion?.stepId);
        });
    }

    return true;
  };

  const isKeyFindingsOptional =
    _.get(userCustomizations, 'features.keyFindings') === KeyFindingOption.Optional;

  const isCustomQuestionsEnabled =
    _.get(userCustomizations, 'features.customQuestions') !== CustomQuestionOption.Disabled;
  const showCustomQuestions = isCustomQuestionsEnabled;

  // Make sure this stays in sync with the steps array in intake/create/index.js
  const steps = useMemo(() => {
    const testTypeLabel = isDQS || isCDS ? 'Task' : 'Test Type';

    const steps = [
      { id: IntakeStepIds.Start, label: 'Start', completed: isDefinitionValid },
      { id: IntakeStepIds.TestGoal, label: 'Test Goal', completed: isTestGoalsValid, ref: null },
      { id: IntakeStepIds.TestType, label: testTypeLabel, completed: isPagesValid, ref: null },
      ...(!isCDS && (showCustomQuestions || isKeyFindingsOptional)
        ? [{ id: IntakeStepIds.Details, label: 'Details', completed: isDetailsValid, ref: null }]
        : []),
      { id: IntakeStepIds.Audience, label: 'Audience', completed: isAudienceValid, ref: createRef() },
      { id: IntakeStepIds.Review, label: 'Review', completed: draft.launched, ref: null },
    ];
    return steps;
  }, [
    showCustomQuestions,
    isKeyFindingsOptional,
    isDefinitionValid,
    isTestGoalsValid,
    isAudienceValid,
    isPagesValid,
    isDetailsValid,
    isDQS,
    isCDS,
    draft.launched,
  ]);

  const stepIndex = useMemo(() => steps.findIndex((step) => step.id === stepId), [stepId, steps]);

  useEffect(() => {
    const foundNonValidGroup = groups?.find((group) => checkValidity(group) === false);

    if (foundNonValidGroup) {
      setIsDetailsValid(false);
      return;
    }

    // check test <> group validity
    if (!checkTestCustomQuestionValidity(draft)) {
      setIsDetailsValid(false);
      return;
    }

    setIsDetailsValid(true);
  }, [draft, groups]);

  if (isDraftLoading) {
    return (
      <Box p={4} textAlign="center">
        <CircularProgress />
      </Box>
    );
  }

  /**
   * Gets the child ref for a given step.
   */
  const getRefForStep = (stepIndex) => {
    return steps[stepIndex] && steps[stepIndex].ref;
  };

  let bottomBar;
  if (isFirstStep()) {
    bottomBar = (
      <BottomBar
        nextLabel="Next"
        isNextDisabled={!isStepValid()}
        nextButtonType="submit"
        nextButtonForm="create-test-form"
      />
    );
  } else if (isLastStep()) {
    bottomBar = (
      <BottomBar
        handleBackClick={decrementStep}
        handleNextClick={handleSubmit}
        handleSaveAndExit={saveAndExit}
        nextLabel="Submit Test"
        isNextDisabled={!isStepValid() || isLaunching}
      />
    );
  } else {
    bottomBar = (
      <BottomBar
        handleBackClick={decrementStep}
        handleNextClick={incrementStep}
        handleSaveAndExit={saveAndExit}
        nextLabel="Next"
        isNextDisabled={!isStepValid()}
      />
    );
  }

  let maxWidth;
  if (stepId === IntakeStepIds.Audience) {
    maxWidth = 'md';
  } else if (stepId === IntakeStepIds.TestGoal) {
    maxWidth = 'xl';
  } else if (stepId === IntakeStepIds.TestType) {
    maxWidth = 'lg';
  } else if (stepId === IntakeStepIds.Details) {
    maxWidth = 'md';
  } else if (stepId === IntakeStepIds.Start) {
    maxWidth = 'sm';
  } else {
    maxWidth = 'md';
  }

  return (
    <Fragment>
      <Stepper
        step={stepIndex}
        steps={initialSteps ?? steps}
        setStep={setStep}
        disableStepClicks={(isEmpty(draft) && !draft.id) || stepId === IntakeStepIds.Start}
        isDQS={isDQS}
        isCDS={isCDS}
      />
      <Container sx={{ paddingBottom: 24 }} component="main" maxWidth={maxWidth}>
        <Switch>
          <Route exact path={Paths.intake.start}>
            <DefineTest
              updateDefinitionValidity={setIsDefinitionValid}
              setTestType={testTypeChanged}
              customQuestionsGroups={groups}
            />
          </Route>
          <Route exact path={Paths.intake.testGoal}>
            {draft?.metricType && <TestGoals updateTestGoalsValidity={setIsTestGoalsValid} />}
          </Route>
          <Route exact path={Paths.intake.audience}>
            <SelectAudience
              ref={getRefForStep(1)}
              wevoId={wevoId}
              updateAudienceValidity={setIsAudienceValid}
              savedAudienceName={savedAudienceName}
              setSavedAudienceName={setSavedAudienceName}
              audienceDisplay={audienceDisplay}
              setAudienceDisplay={setAudienceDisplay}
              selectedAudienceGroupId={selectedAudienceGroupId}
              setSelectedAudienceGroupId={setSelectedAudienceGroupId}
              savedAudiences={savedAudiences}
              showAudienceNameBox={showAudienceNameBox}
              setShowAudienceNameBox={setShowAudienceNameBox}
              deleteSavedAudienceGroup={deleteSavedAudienceGroup}
            />
          </Route>
          <Route exact path={Paths.intake.testType}>
            <TestTypePage testType={testType} setTestType={testTypeChanged} isDQS={isDQS} isCDS={isCDS} />
          </Route>
          <Route exact path={Paths.intake.details}>
            <Details updateDetailsValidity={setIsDetailsValid} />
          </Route>
          <Route exact path={Paths.intake.review}>
            <Review
              setStep={setStep}
              isDefinitionValid={isDefinitionValid}
              isTestGoalsValid={isTestGoalsValid}
              isAudienceValid={isAudienceValid}
              isPagesValid={isPagesValid}
              isDetailsValid={isDetailsValid}
            />
          </Route>
          <Redirect to={Paths.intake.start} />
        </Switch>
      </Container>
      {bottomBar}
      <BackToTopButton />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    userCustomizations: getUserCustomizations(state),
  };
};

const actionCreators = {
  fetchUser: UserActions.fetchUserInfo,
};

export default connect(mapStateToProps, actionCreators)(EditTest);
