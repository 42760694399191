import { mergeMap, catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { fetchUser, updateUser, changePassword } from './api';
import {
  FETCH_USER,
  fetchUserSuccess,
  fetchUserFailed,
  UPDATE_USER,
  updateUserSuccess,
  updateUserFailed,
  CHANGE_PASSWORD,
  changePasswordSuccess,
  changePasswordFailed,
} from './actions';

export const fetchUserEpic = (action$) =>
  action$.ofType(FETCH_USER).pipe(
    mergeMap((action) =>
      fetchUser().pipe(
        map((response) => fetchUserSuccess(response)),
        catchError((error) => of(fetchUserFailed(error)))
      )
    )
  );

export const updateUserEpic = (action$) =>
  action$.ofType(UPDATE_USER).pipe(
    switchMap((action) =>
      updateUser(action.payload).pipe(
        map((response) => updateUserSuccess(response)),
        catchError((error) => of(updateUserFailed(error)))
      )
    )
  );

export const changePasswordEpic = (action$) =>
  action$.ofType(CHANGE_PASSWORD).pipe(
    switchMap((action) =>
      changePassword(action.payload).pipe(
        map((response) => changePasswordSuccess(response)),
        catchError((error) => of(changePasswordFailed(error)))
      )
    )
  );
