import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomButton from '../automated-insights/ui/Button';
import Segment from './Segment';
import SegmentsContainer from './SegmentsContainer';

const NOT_PROPOSED_AUDIENCE = 'no';

const ProposedAudienceContainer = ({
  taskStatus,
  sessionId,
  selectedSegment,
  sessionSegments,
  segmentsWithoutSessionInsights,
  segmentToResultMap,
  text,
  isCustomizingSegment,
  setIsCustomizingSegment,
  isSessionOwner,
  onSelectSegment,
  onSectionChange,
  onInsightsRegeneration,
  onPersonaConfirmation,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (selectedSegment) {
      const target = document.getElementById(selectedSegment.id);

      if (target) {
        target.scrollIntoView();
      }
    }
  }, [selectedSegment]);

  const proposedAudience = (sessionSegments ?? []).find((segment) => segment?.isGenerated);

  const isGeneratingInsights = (segmentId) =>
    !segmentToResultMap[segmentId]?.findings && sessionSegments?.some((segment) => segment?.id === segmentId);

  if (selectedOption === NOT_PROPOSED_AUDIENCE) {
    return (
      <SegmentsContainer
        taskStatus={taskStatus}
        sessionId={sessionId}
        sessionSegments={sessionSegments}
        selectedSegment={selectedSegment}
        segmentsWithoutSessionInsights={segmentsWithoutSessionInsights}
        segmentToResultMap={segmentToResultMap}
        text={text}
        isCustomizingSegment={isCustomizingSegment}
        setIsCustomizingSegment={setIsCustomizingSegment}
        isSessionOwner={isSessionOwner}
        onSelectSegment={onSelectSegment}
        onSectionChange={onSectionChange}
        onInsightsRegeneration={onInsightsRegeneration}
      />
    );
  }

  return (
    <Box
      className="lightContainer"
      sx={{
        height: '100%',
        width: '100%',
        overflow: 'auto',
        paddingX: 2,
        paddingBottom: 6,
        margin: 0,
      }}>
      {proposedAudience && selectedOption === null && (
        <Box>
          <Box mb={2}>
            <Typography fontSize={12} fontWeight={600}>
              {isSessionOwner ? 'Are these the correct personas?' : 'Generating insights with these personas'}
            </Typography>
          </Box>
          <Grid container>
            {[proposedAudience].map((segment) => (
              <Grid item xs={12} key={segment?.id}>
                <Segment
                  segment={segment}
                  isOwner={isSessionOwner}
                  onSelectSegment={onSelectSegment}
                  onSectionChange={onSectionChange}
                  hasSessionInsights={!!segmentToResultMap[segment?.id]?.findings}
                  hasInsightsGenerating={isGeneratingInsights(segment?.id)}
                  initialIsExpanded={segment?.id === selectedSegment?.id}
                  showActions={false}
                  canToggle={false}
                />
              </Grid>
            ))}
          </Grid>
          {isSessionOwner && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}>
              <CustomButton
                variant="secondaryDark"
                size="small"
                onClick={() => setSelectedOption(NOT_PROPOSED_AUDIENCE)}>
                No
              </CustomButton>
              <CustomButton
                variant="gradient"
                size="small"
                sx={{ marginLeft: 1 }}
                onClick={() => onPersonaConfirmation(true)}>
                Yes
              </CustomButton>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ProposedAudienceContainer;
