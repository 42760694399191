import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { ReactComponent as DiagnosticsIcon } from '../../../assets/diagnostics-dynamic.svg';
import { Paths } from '../../../routes';
import LeftNavItem from './LeftNavItem';
import { WevoTestType } from '../../../modules/wevos/constants';

const useStyles = makeStyles((theme) => ({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
}));

const DiagnosticsLeftNavButton = ({
  wevo,
  levels,
  selected,
  selectedSubItem,
  setSelectedSubItem,
  generateSubItemPath,
}) => {
  const classes = useStyles();
  const wevoId = wevo?.id;

  if (wevo?.testType === WevoTestType.Compare) {
    return (
      <LeftNavItem
        icon={<DiagnosticsIcon className={classes.svg} />}
        label="Diagnostics"
        path={generatePath(Paths.reports.diagnosticsPage, { wevoId, pageNum: selectedSubItem.pageNumber })}
        levels={levels}
        subItemPath={Paths.reports.diagnosticsPage}
        generateSubItemPath={generateSubItemPath}
        selectedSubItem={selectedSubItem}
        setSelectedSubItem={setSelectedSubItem}
        selected={selected}
      />
    );
  } else {
    return (
      <LeftNavItem
        icon={<DiagnosticsIcon className={classes.svg} />}
        label="Diagnostics"
        path={generatePath(Paths.reports.diagnostics, { wevoId })}
        selected={selected}
      />
    );
  }
};

DiagnosticsLeftNavButton.propTypes = {
  wevo: PropTypes.object.isRequired,
  levels: PropTypes.array,
  selected: PropTypes.bool.isRequired,
  selectedSubItem: PropTypes.object.isRequired,
  setSelectedSubItem: PropTypes.func.isRequired,
  generateSubItemPath: PropTypes.func.isRequired,
};

export default DiagnosticsLeftNavButton;
