import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const ShowImage = (props) => {
  const { image, height, width, maxHeight, maxWidth } = props;

  const url = image?.mini?.url || image?.original?.url;

  return (
    <Fragment>
      <img
        style={{
          height: height || 'auto',
          width: width || '100%',
          maxHeight,
          maxWidth,
          objectFit: 'cover',
          objectPosition: '0% 0',
          border: '1px solid #979797',
          cursor: 'pointer',
        }}
        src={url}
        alt={image?.name || ''}
      />
    </Fragment>
  );
};

ShowImage.propTypes = {
  image: PropTypes.object.isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default ShowImage;
