import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DiscardPageDialog = (props) => {
  const { open, discardPage, closeCallback } = props;

  const handleClose = () => {
    !!closeCallback && closeCallback();
  };

  const handleReset = () => {
    !!discardPage && discardPage();
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="discard page confirmation dialog"
        aria-describedby="discard-page-confirmation-description">
        <DialogTitle>Deleting Page</DialogTitle>
        <DialogContent>
          <DialogContentText id="discard-page-confirmation-description">
            Note: When you delete this page, any custom questions associated with it will no longer be
            associated with this page. Do you want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Yup! Delete Page</Button>
          <Button onClick={handleClose}>Do Nothing</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DiscardPageDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  discardPage: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

export default React.memo(DiscardPageDialog);
