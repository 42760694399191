import { useMutation } from 'react-query';
import axios from '../../../api';

/**
 * Change pulse subscription
 */

const changeSubscription = async ({ pulseSubscriptionId, newPulseSubscriptionTierId }) => {
  const { data } = await axios({
    url: `/api/v2/pulse/subscriptions/${pulseSubscriptionId}`,
    data: { newPulseSubscriptionTierId },
    method: 'PUT',
  });
  return data;
};

export default function useChangeSubscription(options) {
  return useMutation(changeSubscription, options);
}
