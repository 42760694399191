import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { green, lightGreen, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useMemo, useState } from 'react';
import { generatePath, Link } from 'react-router-dom';
import { BenchmarkScope } from '../../../../modules/wevos/constants';
import { Paths } from '../../../../routes';
import DoughnutScoreGraph from '../../components/DoughnutScoreGraph';
import DiagnosticsBarGraph from '../../diagnostics/DiagnosticsBarGraph';
import useBenchmarks from '../../hooks/useBenchmarks';
import useDiagnostics from '../../hooks/useDiagnostics';
import { JobToBeDone } from '../../../../modules/intake/constants';

const styles = makeStyles((theme) => ({
  root: {
    height: '100%',
  },
  card: {
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    borderRadius: '20px',
  },
  cardContent: {
    [theme.breakpoints.up('md')]: {
      padding: '16px 64px',
    },
  },
  cardActions: {
    justifyContent: 'flex-end',
  },
  link: {
    textDecoration: 'none',
  },
}));

const DiagnosticsSummary = (props) => {
  const { wevo, page } = props;
  const classes = styles();
  const { data: responseData } = useDiagnostics({ wevoId: wevo?.id, pageId: page?.id });
  const diagnostics = responseData?.diagnostics || [];

  const { data: benchmarksData } = useBenchmarks(wevo?.id, page?.id, { scopeType: BenchmarkScope.Default });
  const benchmarks = benchmarksData?.benchmarks;

  const [useNewDiagnostics, setUseNewDiagnostics] = useState(wevo?.useVersionedDiagnostics || false);
  const maxScore = useMemo(() => (useNewDiagnostics ? 30 : 100), [useNewDiagnostics]);

  const rescaleScoreToScale = (score, scale) => {
    // rescale score to a number out of 100
    return (Math.round(score * 100) / scale) * 100;
  };

  const newDiagnosticsDonutColor = (diagnostic) => {
    // TODO: remove this hack, only for the  New Diagnostics Demo, only
    const benchmark = diagnostic.benchmark;
    const benchmarkPoints = 5;

    const diff = Math.round(diagnostic.score * 100) - benchmark;

    if (diff >= benchmarkPoints) {
      return green[500];
    } else if (diff <= -benchmarkPoints) {
      return red[500];
    } else {
      return lightGreen[500];
    }
  };

  useEffect(() => {
    setUseNewDiagnostics(responseData?.useNewDiagnostics || wevo?.useVersionedDiagnostics || false);
  }, [responseData?.useNewDiagnostics, wevo?.useVersionedDiagnostics]);

  const hasBenchmarks = useMemo(() => wevo?.jobToBeDone !== JobToBeDone.Lite, [wevo]);

  return (
    <Card className={classes.card} elevation={4}>
      <CardHeader
        title={
          <Typography variant="h3" align="center" gutterBottom>
            Journey Diagnostics
          </Typography>
        }
        subheader={
          <Typography variant="caption" component="p" align="center">
            Learn how visitors assess the entire journey
          </Typography>
        }
      />
      <CardContent className={classes.cardContent}>
        {!useNewDiagnostics && (
          <Grid container justifyContent="space-between" spacing={2}>
            {diagnostics &&
              diagnostics.map((diagnostic) => (
                <Grid item key={diagnostic.id} xs={6} sm={3} md={2} lg={1}>
                  <Box p={2} margin="0 auto">
                    <DoughnutScoreGraph
                      score={rescaleScoreToScale(diagnostic.score, maxScore)}
                      showScore={false}
                      backgroundColor={useNewDiagnostics ? newDiagnosticsDonutColor(diagnostic) : null}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Typography variant="h5">{diagnostic.name}</Typography>
                  </Box>
                </Grid>
              ))}
          </Grid>
        )}
        {useNewDiagnostics && (
          <DiagnosticsBarGraph
            diagnostics={diagnostics}
            benchmarks={benchmarks}
            onBarSelected={(label) => {}}
            width={'100%'}
            height={'300px'}
            tooltipPlacement={'left'}
            hasBenchmarks={hasBenchmarks}
          />
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Link to={generatePath(Paths.reports.diagnostics, { wevoId: wevo?.id })} className={classes.link}>
          <Button endIcon={<ChevronRightIcon />}>See Diagnostics</Button>
        </Link>
      </CardActions>
    </Card>
  );
};

export default DiagnosticsSummary;
