import _ from 'lodash';
import { EXPERIENCE_OUTCOMES, EXPERIENCE_OUTCOME_TYPES } from './constants';

/**
 * Takes all outcomes for a step and returns an array of points {x, y} for each outcome
 *
 * @param {Array<object>} outcomes - All outcomes
 * @param {String} stepId - Current step id
 * @returns {Array<object>}
 */
export function getPointsByStepId(outcomes, stepId) {
  return outcomes?.reduce((result, outcome) => {
    outcome?.clicks.forEach((click) => {
      if (click.experienceStepId === stepId) {
        result.push({ x: click.x, y: click.y });
      }
    });
    return result;
  }, []);
}

/**
 * Filters outcomes based on the filters applied for the current step
 *
 * @param {Array<object>} outcomes - All outcomes
 * @param {String} stepId - Step id
 * @param {Object} filters - Object containing different filter types and the values selected
 * @returns {Array<object>} filtered outcomes
 */
export function filterOutcomes({ outcomes, stepId, filters = {} }) {
  const successFilters = filters.outcomes;
  const taskTimeFilters = filters.taskTimeRange;
  const easeFilters = filters.easeScores;
  const audienceFilters = filters.audience || {};

  if (_.isEmpty(successFilters) || _.isEmpty(easeFilters)) {
    return [];
  }

  const filteredOutcomes = outcomes?.filter((outcome) => {
    if (!outcome) {
      return false;
    }

    const matchAudience = Object.keys(audienceFilters).every((filterAttributeId) => {
      // Get the segments from attributeFilters that are true
      const validSegmentIds = Object.entries(audienceFilters[filterAttributeId])
        .filter(([, value]) => value) // Filter to keep only the segments that are true
        .map(([segmentId]) => segmentId); // Extract the segment IDs

      if (!validSegmentIds.length) {
        return true;
      }

      // See if the outcome has this attribute and if any of its segments match the valid segments
      const outcomeAttributes = outcome?.attributes?.filter((attr) => attr.attributeId === filterAttributeId);

      // Check if any segment for this attribute in the outcome matches any of the valid segments
      return outcomeAttributes?.some((outcomeAttribute) =>
        validSegmentIds.includes(outcomeAttribute.segmentId)
      );
    });

    let matchSuccessOutcome = true;
    if (!_.isEmpty(successFilters) && outcome?.outcome) {
      matchSuccessOutcome = successFilters.includes(EXPERIENCE_OUTCOMES[outcome.outcome]);
    }

    let matchTaskTimeRange = true;
    if (!_.isEmpty(taskTimeFilters) && outcome?.taskTimeSeconds) {
      matchTaskTimeRange =
        outcome.taskTimeSeconds >= taskTimeFilters[0] && outcome.taskTimeSeconds <= taskTimeFilters[1];
    }

    let matchEaseScore = true;
    if (!_.isEmpty(easeFilters) && outcome?.easeScore) {
      matchEaseScore = easeFilters.includes(String(outcome.easeScore));
    }

    const clickData = outcome.clicks;
    const matchStep = Boolean(clickData.find(({ experienceStepId }) => experienceStepId === stepId));

    return Boolean(matchSuccessOutcome && matchTaskTimeRange && matchEaseScore && matchAudience && matchStep);
  });

  return filteredOutcomes;
}

/**
 * Counts the number of successful outcomes
 *
 * @param {Array<object>} outcomes
 * @returns {Number}
 */
export function getNumOfSuccessfulOutcomes(outcomes) {
  return outcomes.filter((outcome) => outcome.outcome === EXPERIENCE_OUTCOME_TYPES.success).length;
}
