import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import axios from 'axios';
import * as EmailValidator from 'email-validator';
import freeEmailDomains from 'free-email-domains';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { useAnalytics } from 'use-analytics';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import { useTrialSignUp } from '../hooks/useTrialSignUp';
import CustomButton from '../ui/Button';
import TrialStepper from './TrialStepper';

/**
 * Submits to Hubspot form https://app.hubspot.com/submissions/2284379/form/f100f8f3-da8e-48d3-b000-dd2177a774d5/submissions
 *
 * Background: On the https://wevo.ai/takeapulse page (which is a hubspot form), it will track
 * the user with any UTM parameters that is received. However, we don't know the email of the user yet.
 *
 * Once they fill out the form on /takeapulse, they will be redirected to this page, so we submit
 * a hubspot form in order to track the user's email.
 *
 * Since they should have the hubspot cookie set from /takeapulse, the email that they submitted
 * will be tied together with the hubspot contact.
 */
async function submitHubspotForm(email) {
  // Get hubspot's hutk cookie
  const cookies = new Cookies();
  const hutk = cookies.get('hubspotutk');

  const url =
    'https://api.hsforms.com/submissions/v3/integration/submit/2284379/f100f8f3-da8e-48d3-b000-dd2177a774d5';

  const data = {
    submittedAt: Date.now(),
    fields: [
      {
        name: 'email',
        value: email,
      },
    ],
    context: {
      hutk,
      pageUri: window.location.href,
      pageName: 'Pulse Free Trial',
    },
  };

  try {
    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  } catch (error) {
    // No-op if there's an error
    console.log('Error submitting to hubspot', error?.message);
  }
}

const EmailTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    marginBottom: theme.spacing(0.5),
    backgroundColor: '#FFFFFF',
    borderRadius: '25px',
  },
  '& .MuiInputBase-input': {
    fontSize: '12px',
    padding: '12px 16px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& fieldset': { border: 'none' },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: '#00B3FF',
  opacity: 1,
  '&:hover': {
    color: '#00B3FF',
    opacity: 0.7,
  },
  '&:focus': {
    color: '#43BCFF',
    opacity: 1,
  },
  '&:selected': {
    color: '#287199',
    opacity: 1,
  },
}));

const TrialForm = () => {
  const { track } = useAnalytics();
  const { search } = useLocation();
  const queryParams = useMemo(() => queryString.parse(search), [search]);
  const url = queryParams?.url ? decodeURIComponent(queryParams?.url) : '';

  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resendSuccess, setResendSuccess] = useState(false);

  const { mutate: trialSignUp } = useTrialSignUp();

  useTrackPageLoad({
    name: TrackEvent.VIEWED_PULSE_TRIAL_SIGN_UP,
  });

  const handleEmailChange = (e) => {
    if (errorMessage) {
      setErrorMessage('');
    }
    setEmail(e.target.value);
  };

  const handleNextClick = () => {
    submitEmail();
  };

  const handleEmailKeyDown = (e) => {
    if (e.key === 'Enter') {
      submitEmail();
    }
  };

  const submitEmail = () => {
    if (!isValidEmail) {
      setErrorMessage('Please enter a valid email address.');
    } else if (isPersonalEmail) {
      setErrorMessage('Please enter a work email address.');
    } else {
      trialSignUp(
        { email, url },
        {
          onSuccess: async (data) => {
            track(TrackEvent.SUBMITTED_EMAIL_FOR_PULSE_TRIAL, { email, url });

            await submitHubspotForm(email);

            if (data?.redirect) {
              window.location.replace(data.redirect);
              return;
            }

            setStep(1);

            if (errorMessage) {
              setErrorMessage('');
            }

            track(TrackEvent.GENERATE_PULSE_WITH_UNVERIFIED_EMAIL, { email, url });
            const pulseUrl = `/pulse?url=${url}`;
            window.location.replace(pulseUrl);
          },
          onError: (err) => {
            const message =
              err?.response?.data?.humanReadableMessage ?? 'Error submitting email. Please try again.';
            setErrorMessage(message);
          },
        }
      );
    }
  };

  const handleResendClick = () => {
    setResendSuccess(true);
    setTimeout(() => {
      setResendSuccess(false);
    }, 1000);
    trialSignUp(
      { email, url },
      {
        onSuccess: () => {
          track(TrackEvent.RESEND_EMAIL_FOR_PULSE_TRIAL, { email, url });
        },
      }
    );
  };

  const isValidEmail = useMemo(() => !!email && EmailValidator.validate(email), [email]);

  const isPersonalEmail = useMemo(() => {
    const domain = email?.trim().split('@')[1];
    return freeEmailDomains.includes(domain);
  }, [email]);

  const renderCardContent = () => {
    if (step === 0) {
      return (
        <>
          <Typography mb={1} ml={1} fontSize={12}>
            Please enter your work email.
          </Typography>
          <EmailTextField
            fullWidth
            placeholder="you@company.com"
            value={email}
            onChange={handleEmailChange}
            onKeyDown={handleEmailKeyDown}
            error={!!errorMessage}
            helperText={errorMessage}
          />
          <Box display="flex" justifyContent="end" mt={1}>
            <CustomButton variant="gradient" sx={{ minWidth: 100 }} onClick={handleNextClick}>
              Next
            </CustomButton>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Typography fontSize={12} fontWeight={700} textAlign="center">
            Almost there!
          </Typography>
          <Typography mt={1.5} mb={2} fontSize={12}>
            To see your results, verify your email by clicking the link we sent you.
          </Typography>
          <Divider />
          <Typography
            mt={1.5}
            fontSize={10}
            sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '4px' }}>
            Didn't receive the email?
            <Typography
              component="span"
              fontSize={10}
              color="#26B2FF"
              onClick={handleResendClick}
              sx={{ cursor: 'pointer' }}>
              Resend.
            </Typography>
            {resendSuccess && <CheckCircleIcon style={{ color: '#65BA4D', fontSize: '12px' }} />}
          </Typography>
        </>
      );
    }
  };

  const redirectToPulse = useCallback(
    (queryEmail, queryUrl) => {
      trialSignUp(
        { email: queryEmail, url: queryUrl },
        {
          onSuccess: async (data) => {
            track(TrackEvent.SUBMITTED_EMAIL_FOR_PULSE_TRIAL, { queryEmail, queryUrl });

            if (data?.redirect) {
              window.location.replace(data.redirect);
              return;
            }

            track(TrackEvent.GENERATE_PULSE_WITH_UNVERIFIED_EMAIL, { queryEmail, queryUrl });

            const pulseUrl = `/pulse?url=${queryUrl}`;
            window.location.replace(pulseUrl);
          },
          onError: (err) => {
            // Clear out loading so that we can see the error.
            setIsLoading(false);

            const message =
              err?.response?.data?.humanReadableMessage ?? 'Error submitting email. Please try again.';
            setErrorMessage(message);
          },
        }
      );
    },
    [track, trialSignUp]
  );

  useEffect(() => {
    if (queryParams?.url && queryParams?.email) {
      const emailAddress = queryParams?.email ? decodeURIComponent(queryParams?.email) : '';

      // not needed but just in case there's an error so that we prefill the form
      setEmail(emailAddress);

      setIsLoading(true);
      redirectToPulse(emailAddress, queryParams?.url);
    }
  }, [queryParams?.url, queryParams?.email, redirectToPulse]);

  return (
    <Paper
      sx={{
        height: 'calc(100vh - 65px)',
        borderRadius: 0,
      }}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {isLoading ? (
          <Grid item xs={12} mt={6} mb={3}>
            <Box sx={{ textAlign: 'center' }}>
              <CircularProgress color="secondary" />
            </Box>
          </Grid>
        ) : (
          <Grid container justifyContent="center" sx={{ paddingTop: 6, paddingX: { xs: '5%' } }}>
            <Grid item container xs={12} justifyContent="center">
              <Box sx={{ width: '100%', maxWidth: 460 }}>
                <TrialStepper step={step} />
              </Box>
            </Grid>
            <Grid item xs={12} mt={6} mb={3}>
              <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h4" mb={1}>
                  Take a free WEVO Pulse
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} justifyContent="center">
              <Box sx={{ width: '100%', maxWidth: 440 }}>
                <Box
                  sx={{
                    backgroundColor: '#F3F8FB',
                    borderRadius: '30px',
                    paddingX: 2,
                    paddingTop: 3,
                    paddingBottom: 2,
                  }}>
                  {renderCardContent()}
                </Box>
                {step === 0 && (
                  <Box textAlign="center" pt={1}>
                    <Typography fontSize={10}>
                      By signing up, I agree to WEVO's{' '}
                      <StyledLink href="https://wevo.ai/terms/" target="_blank" rel="noopener noreferrer">
                        Terms & Conditions
                      </StyledLink>
                      .
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        )}
        {step === 0 && (
          <Box
            sx={{
              maxWidth: '620px',
              display: 'flex',
              alignItems: 'end',
              flex: 1,
              paddingX: { xs: '5%', md: 0 },
              paddingTop: 4,
              paddingBottom: 2,
              textAlign: 'center',
            }}>
            <Typography fontSize={10} sx={{ lineHeight: 'normal' }}>
              We value your privacy. Your personal information will not be sold to third parties. All the
              information you provide is stored securely and treated with the utmost confidentiality. It will
              only be used for the purposes outlined in our{' '}
              <StyledLink href="https://wevo.ai/privacy/" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </StyledLink>
              .
            </Typography>
          </Box>
        )}
      </Box>
    </Paper>
  );
};
export default TrialForm;
