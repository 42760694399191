import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import _ from 'lodash';
import { React, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { generatePath } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as StarIcon } from '../../../../assets/star.svg';
import { getUserType } from '../../../../modules/user/selectors';
import { UserType } from '../../../../modules/wevos/constants';
import { isClassicComparePages } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';
import useStarQuote from '../../hooks/useStarQuote';
import useStarredQuotes from '../../hooks/useStarredQuotes';
import StarredQuoteBlock from '../../takeaways/components/StarredQuoteBlock';

const styles = makeStyles((theme) => ({
  card: {
    borderRadius: '20px',
  },
  cardContent: {
    display: 'flex',
    height: '100%',
  },
  starredQuotesIcon: {
    color: theme.palette.primary.main,
    width: '25px',
    height: '25px',
    marginTop: theme.spacing(0.25),
    marginRight: theme.spacing(0.25),
    marginLeft: theme.spacing(0.25),
    marginBottom: theme.spacing(-0.25),
  },
  noQuotes: {
    marginLeft: theme.spacing(4),
  },
  starredQuotesIconGrid: {
    marginRight: theme.spacing(2),
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '12px',
    textAlign: 'end',
  },
  rightArrow: {
    marginLeft: theme.spacing(1),
    verticalAlign: 'bottom',
  },
  grey: {
    backgroundColor: grey[100],
  },
  subText: {
    color: theme.palette.primary.dark,
  },
  underline: {
    textDecoration: 'none',
  },
  icon: {
    backgroundColor: grey[100],
    borderRadius: '50%',
    padding: '3px',
  },
}));

const StarredQuotesCard = (props) => {
  const { wevo, page, selectedAssetNum, isLimitedReport } = props;
  const classes = styles();

  const [starredQuotes, setStarredQuotes] = useState([]);
  const [pagesById, setPagesById] = useState({});
  const { mutate: toggleQuoteStar } = useStarQuote();

  const userType = useSelector(getUserType);

  useEffect(() => {
    setPagesById(_.keyBy(wevo?.pages ?? [], 'id'));
  }, [wevo]);

  useStarredQuotes({
    wevoId: wevo?.id,
    pageId: page?.id,
    onSuccess: (data) => {
      setStarredQuotes(data?.quotes ?? []);
    },
  });

  const handleStarClick = (quote) => {
    toggleQuoteStar({
      wevoId: wevo.id,
      pageId: quote.wevoPageId,
      quoteType: quote.quoteType,
      quoteId: quote.id,
    });
    // update the starredQuotes array so the list of quotes and the star icons update immediately
    setStarredQuotes((starredQuotes) =>
      starredQuotes
        .map((starredQuote) => {
          if (starredQuote.quoteId === quote.id) {
            return {
              ...starredQuote,
              quote: {
                ...starredQuote.quote,
                starredByWevo: userType === UserType.Wevo ? !quote.starredByWevo : quote.starredByWevo,
                starredByCompany:
                  userType === UserType.External ? !quote.starredByCompany : quote.starredByCompany,
              },
            };
          } else {
            return starredQuote;
          }
        })
        .filter((starredQuote) => starredQuote.quote.starredByCompany || starredQuote.quote.starredByWevo)
    );
  };

  const takeawaysPath =
    wevo?.useVersionedDiagnostics && isClassicComparePages(wevo)
      ? generatePath(Paths.reports.takeawaysPage, {
          wevoId: wevo?.id,
          pageNum: selectedAssetNum,
        })
      : generatePath(Paths.reports.takeaways, {
          wevoId: wevo?.id,
        });

  return (
    <Card className={classes.card} elevation={4}>
      <CardContent className={classes.cardContent}>
        <Grid container spacing={2} alignContent="space-between">
          <Grid item container spacing={2} xs={12}>
            <Grid item container className={classes.noWrap} xs={12}>
              <Grid item className={classes.starredQuotesIconGrid}>
                {isLimitedReport ? (
                  <Box className={classes.icon}>
                    <StarIcon className={classes.starredQuotesIcon} />
                  </Box>
                ) : (
                  <IconButton size="small" className={classes.grey}>
                    <HashLink to={`${takeawaysPath}#starred-section`} smooth>
                      <StarIcon className={classes.starredQuotesIcon} />
                    </HashLink>
                  </IconButton>
                )}
              </Grid>
              <Grid item>
                <Typography variant="h5">Starred Quotes</Typography>
                <Typography variant="caption" className={classes.subText}>
                  Starred quotes from respondents
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {starredQuotes?.slice(0, 5)?.map((starredQuote) => (
                <StarredQuoteBlock
                  key={starredQuote.id}
                  wevo={wevo}
                  pageNum={pagesById[starredQuote.wevoPageId].pageNumber}
                  pageLabel={pagesById[starredQuote.wevoPageId].label}
                  quote={starredQuote.quote}
                  handleStarClick={() => handleStarClick(starredQuote.quote)}
                  isLimitedReport={isLimitedReport}
                />
              ))}
              {!starredQuotes.length && (
                <Box px={2} className={classes.noQuotes}>
                  <Typography>
                    Sorry! You haven't saved any quotes yet. Click on the{' '}
                    <StarBorderIcon className={classes.grey} /> next to a quote to bookmark.
                  </Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          {!isLimitedReport && (
            <Grid item xs={12}>
              <HashLink to={`${takeawaysPath}#starred-section`} smooth className={classes.underline}>
                <Typography variant="body2" className={classes.link}>
                  See Starred Quotes
                  <ChevronRightIcon fontSize="small" className={classes.rightArrow} />
                </Typography>
              </HashLink>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StarredQuotesCard;
