import { useMutation } from 'react-query';
import axios from '../../../api';

/**
 * Report bad response.
 */
const flagQuote = async ({ wevoId, pageId, quoteId, feedback }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/quotes/${quoteId}/flag`,
    method: 'PUT',
    data: { feedback },
  });
  return data?.flaggedQuote ?? {};
};

export default function useFlagQuote() {
  return useMutation(flagQuote);
}
