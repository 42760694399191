import { Box, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const styles = makeStyles(() => ({
  honorNewLines: {
    whiteSpace: 'pre-line',
  },
  expandButton: {
    fontSize: ({ buttonFontSize }) => buttonFontSize,
    textTransform: 'capitalize',
  },
}));

const ExpandableText = ({
  header,
  headerVariant,
  content,
  contentVariant,
  maxLength = 150,
  contentColor,
  buttonFontSize,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const classes = styles({ buttonFontSize });

  const toggleExpanded = (e) => {
    // stops the onClick() of the parent component ie. CardActionArea
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      {header && (
        <Typography variant={headerVariant} gutterBottom>
          {header}
        </Typography>
      )}
      {content.length < maxLength ? (
        <Typography variant={contentVariant} color={contentColor} className={classes.honorNewLines}>
          {content}
        </Typography>
      ) : (
        <>
          <Typography variant={contentVariant} color={contentColor} className={classes.honorNewLines}>
            {isExpanded ? content : content.substring(0, maxLength) + '...'}
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            {isExpanded ? (
              <Button
                className={classes.expandButton}
                endIcon={<ExpandLessIcon />}
                onClick={toggleExpanded}
                onMouseDown={(e) => e.stopPropagation()}>
                Show Less
              </Button>
            ) : (
              <Button
                className={classes.expandButton}
                endIcon={<ExpandMoreIcon />}
                onClick={toggleExpanded}
                onMouseDown={(e) => e.stopPropagation()}>
                Show More
              </Button>
            )}
          </Box>
        </>
      )}
    </>
  );
};

ExpandableText.propTypes = {
  header: PropTypes.string,
  headerVariant: PropTypes.string,
  content: PropTypes.string.isRequired,
  contentVariant: PropTypes.string,
  maxLength: PropTypes.number,
  contentColor: PropTypes.string,
  buttonFontSize: PropTypes.string,
};

export default ExpandableText;
