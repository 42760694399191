import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Lightbox from 'react-image-lightbox';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useAnalytics } from 'use-analytics';
import validator from 'validator';
import { ReactComponent as AddIcon } from '../../assets/pulse-add-icon.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash-icon.svg';
import {
  AutomatedInsightSessionType,
  MAX_PULSE_JOURNEY_ASSETS,
  MIN_PULSE_JOURNEY_ASSETS,
  MainContainerDimensions,
  TaskStatus,
} from '../../modules/automated-insights/constants';
import {
  AssetBadgeLabelStyle,
  canUseAutomatedInsightSessions,
  getBadgeLabel,
  isAutomatedInsightSessionShareLink,
} from '../../modules/automated-insights/helpers';
import { getUserCustomizations, getUserProfile } from '../../modules/user/selectors';
import { snackbar } from '../../notifications';
import { Paths } from '../../routes';
import { TrackEvent, useTrackPageLoad } from '../analytics';
import ImagePlaceholder from '../automated-insights/ImagePlaceholder';
import AssetBadge from './AssetBadge';
import { ImportURLErrorInfo, ImportURLLoadingInfo } from './ImportURLConfirmation';
import ImportURLField, { TEXT_FIELD_COLOR_VARIANT } from './ImportURLField';
import { ButtonWithInfoBox } from './InfoBox';
import SessionAssetThumbnail from './SessionAssetThumbnail';
import {
  useCreateSessionAssetFromURL,
  useDeleteSessionAsset,
  useReordereSessionAsset,
  useReplaceSessionAsset,
} from './hooks/useAutomatedInsightSessionAsset';
import useFetchSession from './hooks/useFetchSession';
import useGenerateInsights from './hooks/useGenerateInsights';
import { useCreateSessionFromURLs, useFetchImportUrlTasks } from './hooks/useImportUrl';
import { useDeleteSessionLink, useReorderSessionLink } from './hooks/useSessionLink';
import CustomButton from './ui/Button';

function getSessionAssets(session) {
  return session?.type === AutomatedInsightSessionType.Compare
    ? session?.linkedSessions.flatMap((linkedSession) => linkedSession?.assets ?? []) ?? []
    : session?.assets ?? [];
}

function AssetCreateModal({ open, onClose, isLoading = false, onCancel, onConfirm }) {
  const [newUrl, setNewUrl] = useState('');

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: 4, p: 1 } }}>
      <DialogTitle>Add a url to this experience?</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <ImportURLField
            initialURL={newUrl}
            onStateChange={(url) => setNewUrl(url)}
            isLoading={isLoading}
            isFocused={true}
            colorVariant={TEXT_FIELD_COLOR_VARIANT.Light}
            notifyErrorStates={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="secondaryDark" onClick={onCancel} disabled={isLoading}>
          Cancel
        </CustomButton>

        {isLoading ? (
          <Box px={2}>
            <CircularProgress color="secondary" size="2rem" />
          </Box>
        ) : (
          <CustomButton
            variant="gradient"
            disabled={isLoading || !newUrl.length || !validator.isURL(newUrl) || validator.isEmail(newUrl)}
            onClick={() => onConfirm({ url: newUrl })}>
            Create
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

function AssetReplaceModal({ asset, open, onClose, isLoading, onCancel, onConfirm }) {
  const [newUrl, setNewUrl] = useState('');

  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: 4, p: 1 } }}>
      <DialogTitle>Replace {asset?.name || `image ${asset?.sortOrder}`}?</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <ImportURLField
            initialURL={asset?.importURL?.url}
            onStateChange={(url) => setNewUrl(url)}
            isLoading={isLoading}
            isFocused={true}
            colorVariant={TEXT_FIELD_COLOR_VARIANT.Light}
            notifyErrorStates={true}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="secondaryDark" onClick={onCancel}>
          Cancel
        </CustomButton>
        {isLoading ? (
          <Box px={2}>
            <CircularProgress color="secondary" size="2rem" />
          </Box>
        ) : (
          <CustomButton
            variant="gradient"
            disabled={
              isLoading ||
              !newUrl.length ||
              newUrl === asset?.importURL?.url ||
              !validator.isURL(newUrl) ||
              validator.isEmail(newUrl)
            }
            onClick={() =>
              onConfirm({
                id: asset.id,
                url: newUrl,
                automatedInsightSessionId: asset.automatedInsightSessionId,
              })
            }>
            Replace
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

function AssetDeleteModal({ asset, open, onClose, isDeleting = false, onCancel, onConfirm }) {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      fullWidth
      maxWidth="sm"
      PaperProps={{ sx: { borderRadius: 4, p: 1 } }}>
      <DialogTitle>Delete {asset?.name || `image ${asset?.sortOrder}`}?</DialogTitle>
      <DialogContent>
        <Box my={2}>
          <Typography>
            Are you sure you want to delete{' '}
            <Typography component="span" fontWeight={'bold'}>
              {asset?.name || `image ${asset?.sortOrder}`}
            </Typography>
            ?
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <CustomButton variant="secondaryDark" onClick={onCancel}>
          Cancel
        </CustomButton>
        {isDeleting ? (
          <Box px={2}>
            <CircularProgress color="secondary" size="2rem" />
          </Box>
        ) : (
          <CustomButton
            variant="error"
            onClick={() =>
              onConfirm({ id: asset.id, automatedInsightSessionId: asset.automatedInsightSessionId })
            }>
            Delete
          </CustomButton>
        )}
      </DialogActions>
    </Dialog>
  );
}

function SessionAssetDetail({ asset, status, errorMessage, onRetry, banner }) {
  const [isOpen, setIsOpen] = useState(false);

  const content = useMemo(() => {
    const handleOnClick = () => {
      document.body.style.overflow = isOpen ? '' : 'hidden';
      setIsOpen((isOpen) => !isOpen);
    };
    if (status === TaskStatus.Failed) {
      return (
        <Box
          my={0.5}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100% - 16px)',
            border: '1px solid rgba(217, 217, 217, 0.8)',
            borderRadius: 4,
          }}>
          <ImportURLErrorInfo
            message={errorMessage || 'Looks like something went wrong generating a screenshot.'}
            onReject={() => onRetry(asset)}
            sx={{ marginBottom: 8, textAlign: 'center' }}
          />
        </Box>
      );
    }

    if ([TaskStatus.Pending, TaskStatus.Enqueued, TaskStatus.Running].includes(status)) {
      return (
        <Box
          my={0.5}
          px={2}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100% - 16px)',
            border: '1px solid rgba(217, 217, 217, 0.8)',
            borderRadius: 4,
          }}>
          <ImportURLLoadingInfo taskStatus={status} sx={{ marginBottom: 16, textAlign: 'center' }} />
        </Box>
      );
    }

    if (asset?.image?.url) {
      return (
        <>
          <img
            src={asset.image.url}
            alt="page"
            style={{ width: '100%', cursor: 'pointer' }}
            onClick={handleOnClick}
          />
          {isOpen && (
            <Lightbox
              mainSrc={asset.image.url}
              onCloseRequest={handleOnClick}
              reactModalStyle={{ overlay: { zIndex: 9000 } }}
            />
          )}
        </>
      );
    }
  }, [asset, status, errorMessage, onRetry, isOpen]);

  return (
    <Box
      sx={{
        height: 'calc(100vh - 240px)',
        minHeight: 480,
        overflow: 'auto',
        borderRadius: '20px',
        lineHeight: 0, //removes gap at the bottom of the image
        '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
          display: 'none', // chrome and safari
        },
        msOverflowStyle: 'none', // IE and edge
        scrollbarWidth: 'none', // firefox
      }}>
      {banner}
      {content}
    </Box>
  );
}

const SessionAssetPicker = ({
  assets,
  currentAsset,
  onClick,
  itemHeight = 100,
  importURLTasks,
  onAdd,
  onDragEnd,
  badgeLabelStyle,
}) => {
  const hasMaxAssets = assets?.length === MAX_PULSE_JOURNEY_ASSETS;

  return (
    <Box
      sx={{ height: 'calc(100vh - 240px)', minHeight: 480, overflow: 'auto' }}
      className="transparentScrollBar">
      <DragDropContext onDragEnd={onDragEnd}>
        {/* note: droppableId must be a string */}
        <Droppable droppableId="ItemsList">
          {(provided) => (
            <div className="ItemsList" {...provided.droppableProps} ref={provided.innerRef}>
              {!_.isEmpty(assets) &&
                assets?.map((asset, index) => (
                  <Draggable
                    // note: draggableId must be a string
                    draggableId={asset?.id}
                    key={asset?.id}
                    index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps}>
                        <SessionAssetThumbnail
                          key={asset.id}
                          asset={asset}
                          onClick={() => onClick(asset)}
                          height={itemHeight}
                          {...provided.dragHandleProps}
                          sx={{
                            cursor: 'pointer',
                            ':hover': { opacity: 0.5 },
                            border:
                              currentAsset?.id === asset.id
                                ? '2px solid rgba(0, 0, 0, 0.5)'
                                : '1px solid rgba(0, 0, 0, 0.05)',
                          }}
                          mb={2}
                          status={importURLTasks?.[asset?.importURL?.id]?.status}
                          badge={
                            <AssetBadge
                              height={{ xs: 20, sm: 25 }}
                              width={{ xs: 20, sm: 25 }}
                              text={getBadgeLabel(asset, index, badgeLabelStyle)}
                            />
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {onAdd && (
        <Box
          sx={{
            cursor: !hasMaxAssets ? 'pointer' : 'initial',
            opacity: hasMaxAssets ? 0.5 : 1,
            ':hover': { opacity: 0.5 },
          }}
          onClick={!hasMaxAssets ? onAdd : () => {}}>
          <Tooltip title={hasMaxAssets ? 'Reached max number of assets' : 'Add an asset'} placement="right">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: itemHeight,
                border: '1px solid rgba(0, 0, 0, 0.2)',
                borderRadius: 4,
              }}>
              <AddIcon style={{ width: 32, height: 32 }} />
            </Box>
          </Tooltip>
        </Box>
      )}
    </Box>
  );
};

const SessionAssetListDetail = ({
  assets,
  importURLTasks,
  selectedAsset,
  onClick,
  onAdd,
  onReplace,
  onRetry,
  onDelete,
  onReorder,
  badgeLabelStyle = AssetBadgeLabelStyle.Numeric,
}) => {
  const disableDelete = useMemo(() => assets?.length < 2, [assets]);

  const banner = useMemo(() => {
    if (!onReplace || !onDelete) {
      return <></>;
    }

    // we need to make adjustments to banner dimensions based on the whether or not we show an image placeholder
    const hasPlaceholderImage = !selectedAsset?.image?.url;

    return (
      <Box
        className="actionBanner"
        sx={{
          position: 'absolute',
          background:
            'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1.0) 80%, rgba(255,255,255,0.8) 100%)',
          width: hasPlaceholderImage ? 'calc(100% - 20px)' : '100%',
          height: 50,
          opacity: 0,
          visibility: 'hidden',
          margin: hasPlaceholderImage ? '10px' : 0,
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
          }}
          px={2}>
          <Box>
            <Button
              color="secondary"
              onClick={() => onReplace(selectedAsset)}
              sx={{ textTransform: 'none', fontSize: 12, fontWeight: 600, borderRadius: 4 }}>
              Replace
            </Button>
          </Box>
          <Tooltip
            title={disableDelete ? 'You need at least two assets for this test type' : 'Delete this asset'}>
            <Box>
              <IconButton
                onClick={onDelete}
                disabled={disableDelete}
                sx={{ opacity: disableDelete ? '0.5' : 1 }}>
                <TrashIcon />
              </IconButton>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    );
  }, [disableDelete, onDelete, onReplace, selectedAsset]);

  return (
    <Grid container spacing={1} sx={{ height: '100%' }}>
      <Grid item xs={2}>
        <SessionAssetPicker
          currentAsset={selectedAsset}
          assets={assets ?? []}
          importURLTasks={importURLTasks}
          onClick={onClick}
          onAdd={onAdd}
          onDragEnd={onReorder}
          badgeLabelStyle={badgeLabelStyle}
        />
      </Grid>
      <Grid item xs={10}>
        {selectedAsset ? (
          <Box
            sx={{
              position: 'relative',
              ':hover .actionBanner': {
                opacity: 1.0,
                visibility: 'initial',
                transitionDuration: '150ms',
                transitionProperty: 'opacity',
                transitionTimingFunction: 'ease-in-out',
              },
            }}>
            <SessionAssetDetail
              asset={selectedAsset}
              status={importURLTasks?.[selectedAsset?.importURL?.id]?.status}
              errorMessage={importURLTasks?.[selectedAsset?.importURL?.id]?.displayMessage}
              onRetry={onRetry}
              banner={banner}
            />
          </Box>
        ) : (
          <ImagePlaceholder />
        )}
      </Grid>
    </Grid>
  );
};

export default function EditSessionAssets() {
  const { track } = useAnalytics();
  const history = useHistory();
  const queryClient = useQueryClient();
  const user = useSelector(getUserProfile);
  const userCustomizations = useSelector(getUserCustomizations);

  const { sessionId } = useParams();

  useTrackPageLoad({
    name: TrackEvent.VIEWED_PULSE_SESSION_IMPORT_URL_CONFIRMATION,
    properties: { sessionId: sessionId },
  });

  const isShareLink = isAutomatedInsightSessionShareLink(sessionId);

  const hasAutomatedInsightsFeatureEnabled = canUseAutomatedInsightSessions(user, userCustomizations);

  const [selectedAsset, setSelectedAsset] = useState(null);

  const [shouldPollTasks, setShouldPollTasks] = useState(true);

  const [showCreateAssetModal, setShowCreateAssetModal] = useState(false);
  const [isCreatingNewAsset, setIsCreatingNewAsset] = useState(false);

  const [editedAsset, setEditedAsset] = useState(null);
  const [isReplacingAsset, setIsReplacingAsset] = useState(false);

  const [showDeleteAssetModal, setShowDeleteAssetModal] = useState(false);
  const [isDeletingAsset, setIsDeletingAsset] = useState(false);
  const [isReorderingAsset, setIsReorderingAsset] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [showCallToAction, setShowCallToAction] = useState(false);

  const { mutate: createSessionAsset } = useCreateSessionAssetFromURL();
  const { mutate: replaceSessionAsset } = useReplaceSessionAsset();
  const { mutate: reorderSessionAsset } = useReordereSessionAsset();
  const { mutate: deleteSessionAsset } = useDeleteSessionAsset();

  const { mutate: createSessionFromURLs } = useCreateSessionFromURLs();
  const { mutate: reorderSessionLink } = useReorderSessionLink();
  const { mutate: deleteSessionLink } = useDeleteSessionLink();

  const { mutate: generateInsights } = useGenerateInsights();

  const {
    data: sessionAndTasks,
    isLoading: isSessionLoading,
    isFetching: isSessionFetching,
  } = useFetchSession(
    { sessionId }, // todo: include tests with personalized findings only...should be exceedingly rare
    {
      onSuccess: (data) => {
        // Can't edit session assets post-launch, which is the case if there are any tasks
        const alreadyLaunched = Object.values(data?.tasks).filter((task) => !!task.status)?.length > 0;
        const isExperience = data?.automatedInsightSession?.type === AutomatedInsightSessionType.Experience;
        const isCompare = data?.automatedInsightSession?.type === AutomatedInsightSessionType.Compare;

        if (alreadyLaunched || !(isExperience || isCompare)) {
          history.replace({
            pathname: generatePath(Paths.automatedInsights.session, {
              sessionId,
            }),
          });
        }

        const incomingAssets = getSessionAssets(data?.automatedInsightSession);

        // default selected asset if not set
        if (_.isNil(selectedAsset)) {
          setSelectedAsset(incomingAssets?.[0]);
        } else {
          // ensure selected asset always has the latest state
          setSelectedAsset((incomingAssets ?? []).find((asset) => asset.id === selectedAsset.id));
        }

        // show call to action if all assets were not generated via url
        if (
          incomingAssets?.length > 0 &&
          incomingAssets?.every((asset) => !asset.importURL) &&
          !showCallToAction
        ) {
          setShowCallToAction(true);
        }
      },
      onError: (err) => {
        if ([403, 404].includes(err?.response?.status)) {
          history.replace({
            pathname: Paths.automatedInsights.basePath,
          });
        } else {
          snackbar.error(
            err?.response?.humanReadableMessage ??
              'Something went wrong getting this Pulse. Please try again later.'
          );
        }
      },
      retry: 1,
    }
  );

  const isCompare = useMemo(
    () => sessionAndTasks?.automatedInsightSession?.type === AutomatedInsightSessionType.Compare,
    [sessionAndTasks?.automatedInsightSession?.type]
  );

  const assets = useMemo(
    () => getSessionAssets(sessionAndTasks?.automatedInsightSession),
    [sessionAndTasks?.automatedInsightSession]
  );

  const badgeLabelStyle = useMemo(
    () =>
      sessionAndTasks?.automatedInsightSession?.type === AutomatedInsightSessionType.Compare
        ? AssetBadgeLabelStyle.Alphabetical
        : AssetBadgeLabelStyle.Numeric,
    [sessionAndTasks?.automatedInsightSession]
  );

  const automatedInsightImportUrls = useMemo(() => {
    return (assets ?? []).filter((asset) => !!asset?.importURL).map((asset) => asset.importURL);
  }, [assets]);

  const { data: tasks, isLoading: isTasksLoading } = useFetchImportUrlTasks(
    { automatedInsightImportUrlIds: automatedInsightImportUrls.map((importURL) => importURL.id) },
    {
      onSuccess: (data) => {
        setShouldPollTasks(
          data.some((task) =>
            [TaskStatus.Pending, TaskStatus.Enqueued, TaskStatus.Running].includes(task.status)
          )
        );

        if (
          !showCallToAction &&
          (data?.every((task) => [TaskStatus.Completed].includes(task.status)) ||
            data.some((task) => [TaskStatus.Failed].includes(task.status)))
        ) {
          setShowCallToAction(true);
        }

        if (data.some((task) => [TaskStatus.Completed, TaskStatus.Failed].includes(task.status))) {
          queryClient.invalidateQueries([
            'automatedInsightSession',
            { sessionId: sessionAndTasks.automatedInsightSession.id },
          ]);
        }
      },
      enabled: automatedInsightImportUrls.length > 0 && !isReorderingAsset && !isDeletingAsset,
      retry: 1,
      refetchInterval: shouldPollTasks ? 5000 : null,
    }
  );

  const tasksByImportURLId = useMemo(() => {
    const automatedInsightImportUrlsByTaskId = automatedInsightImportUrls.reduce((acc, cur) => {
      if (!cur?.taskId) {
        return acc;
      }

      acc[cur.taskId] = cur;
      return acc;
    }, {});

    return (tasks ?? []).reduce((acc, cur) => {
      const importURL = automatedInsightImportUrlsByTaskId?.[cur.id];

      if (!importURL) {
        return acc;
      }

      acc[importURL.id] = cur;
      return acc;
    }, {});
  }, [automatedInsightImportUrls, tasks]);

  const hasImportsInProgress = useMemo(() => {
    if (isTasksLoading) {
      return true;
    }
    return (tasks ?? []).some((task) =>
      [TaskStatus.Pending, TaskStatus.Enqueued, TaskStatus.Running].includes(task.status)
    );
  }, [tasks, isTasksLoading]);

  const hasImportErrors = useMemo(() => {
    if (isTasksLoading) {
      return false;
    }
    return tasks?.length === 0 || (tasks ?? []).some((task) => task.status === TaskStatus.Failed);
  }, [tasks, isTasksLoading]);

  const sessionHasErrors = useMemo(() => {
    if (isSessionLoading || isSessionFetching || isReorderingAsset) {
      return false;
    }
    if (_.isEmpty(assets)) {
      return false;
    }
    const isSessionFullyPopulated = assets.length > 0 && assets.every((asset) => !!asset.image);
    return (!hasImportsInProgress && !isSessionFullyPopulated) || hasImportErrors;
  }, [assets, hasImportErrors, hasImportsInProgress, isSessionLoading, isSessionFetching, isReorderingAsset]);

  const hasMinimumAssets = useMemo(() => assets?.length >= MIN_PULSE_JOURNEY_ASSETS, [assets]);
  const canRunOnePageJourney = userCustomizations?.automatedInsights?.onePageJourney === true;

  const callToActionMessage = useMemo(() => {
    const message = assets?.length >= 2 ? 'Are these pages correct?' : 'Is this page correct?';
    return sessionHasErrors && !hasImportsInProgress
      ? "We've detected errors generating screenshots for some urls. Please resolve these errors to continue."
      : message;
  }, [assets?.length, hasImportsInProgress, sessionHasErrors]);

  const nextTooltipMessage = useMemo(() => {
    if (sessionHasErrors) {
      return 'Please resolve all screenshot errors to continue.';
    }

    if (hasImportsInProgress) {
      return "We're still generating screenshots for this experience. You'll be able to proceed once screenshots have been generated.";
    }
    if (!hasMinimumAssets && !canRunOnePageJourney) {
      return 'Please add at least two pages to continue.';
    }
    return isCompare ? 'Generate comparison' : 'Proceed to session settings';
  }, [sessionHasErrors, hasImportsInProgress, hasMinimumAssets, canRunOnePageJourney, isCompare]);

  const handleAddAsset = useCallback(
    ({ url }) => {
      setIsCreatingNewAsset(true);

      if (isCompare) {
        createSessionFromURLs(
          {
            urls: [url],
            name: 'Untitled',
            deviceId: sessionAndTasks.automatedInsightSession?.deviceId,
            type: AutomatedInsightSessionType.Page,
            linkSessionId: sessionAndTasks?.automatedInsightSession?.id,
          },
          {
            onSuccess: (data) => {
              setShowCreateAssetModal(false);
              queryClient.invalidateQueries([
                'automatedInsightSession',
                { sessionId: sessionAndTasks.automatedInsightSession.id },
              ]);
              track(TrackEvent.ADDED_COMPARE_PULSE_SESSION, {
                compareSessionId: sessionAndTasks.automatedInsightSession.id,
                linkedSessionId: data.automatedInsightSession.id,
              });
            },
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error replacing asset');
            },
            onSettled: () => {
              setIsCreatingNewAsset(false);
            },
          }
        );
      } else {
        createSessionAsset(
          {
            automatedInsightSessionId: sessionAndTasks.automatedInsightSession.id,
            url,
          },
          {
            onSuccess: (data) => {
              setShowCreateAssetModal(false);
              queryClient.invalidateQueries([
                'automatedInsightSession',
                { sessionId: sessionAndTasks.automatedInsightSession.id },
              ]);
              track(TrackEvent.ADDED_PULSE_URL_ASSET, {
                sessionId: sessionAndTasks.automatedInsightSession.id,
              });
            },
            onError: (err) => {
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error replacing asset');
            },
            onSettled: () => {
              setIsCreatingNewAsset(false);
            },
          }
        );
      }
    },
    [
      createSessionAsset,
      createSessionFromURLs,
      isCompare,
      queryClient,
      sessionAndTasks,
      setIsCreatingNewAsset,
      setShowCreateAssetModal,
      track,
    ]
  );

  const handleReplaceAsset = useCallback(
    ({ id, url, automatedInsightSessionId }) => {
      setIsReplacingAsset(true);

      replaceSessionAsset(
        {
          automatedInsightSessionId: automatedInsightSessionId,
          automatedInsightSessionAssetId: id,
          url,
        },
        {
          onSuccess: (data) => {
            setEditedAsset(null);
            queryClient.invalidateQueries([
              'automatedInsightSession',
              { sessionId: sessionAndTasks.automatedInsightSession.id },
            ]);
            track(TrackEvent.REPLACED_PULSE_URL_ASSET, {
              sessionId: automatedInsightSessionId,
              automatedInsightSessionAssetId: id,
            });
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error replacing asset');
          },
          onSettled: () => {
            setIsReplacingAsset(false);
          },
        }
      );
    },
    [queryClient, replaceSessionAsset, sessionAndTasks, setEditedAsset, setIsReplacingAsset, track]
  );

  const handleReorderAsset = useCallback(
    ({ result }) => {
      setIsReorderingAsset(true);
      // make sure we drag items only on dragAndDrop area
      if (!result.destination) return;

      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      const assetToReorder = assets[sourceIndex];
      const assetId = assetToReorder.id;

      if (sessionAndTasks?.automatedInsightSession?.type === AutomatedInsightSessionType.Compare) {
        const originalSessionLinks = [...(sessionAndTasks.automatedInsightSession?.linkedSessions ?? [])];
        const reorderedSessionLinks = [...originalSessionLinks];
        reorderedSessionLinks.splice(sourceIndex, 1);
        reorderedSessionLinks.splice(destinationIndex, 0, originalSessionLinks[sourceIndex]);

        // Optimistically reorder the client-side mirror of the server state
        // note: this assumes assest and sessions map 1:1
        queryClient.setQueryData(
          ['automatedInsightSession', { sessionId: sessionAndTasks.automatedInsightSession.id }],
          {
            ...sessionAndTasks,
            automatedInsightSession: {
              ...sessionAndTasks.automatedInsightSession,
              linkedSessions: reorderedSessionLinks,
            },
          }
        );
        reorderSessionLink(
          {
            sessionId: sessionAndTasks?.automatedInsightSession?.id,
            otherSessionId: assetToReorder.automatedInsightSessionId,
            sortOrder: destinationIndex, // session link sort order is zero-indexed
          },
          {
            onSuccess: (data) => {
              setIsReorderingAsset(false);
              track(TrackEvent.REORDERED_COMPARE_PULSE_SESSION, {
                compareSessionId: sessionAndTasks.automatedInsightSession.id,
                linkedSessionId: assetToReorder.automatedInsightSessionId,
              });
            },
            onError: (err) => {
              setIsReorderingAsset(false);
              queryClient.setQueryData(
                ['automatedInsightSession', { sessionId: sessionAndTasks.automatedInsightSession.id }],
                {
                  ...sessionAndTasks,
                  automatedInsightSession: {
                    ...sessionAndTasks.automatedInsightSession,
                    linkedSessions: originalSessionLinks,
                  },
                }
              );
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error reordering asset');
            },
          }
        );
      } else {
        // copy original assests - as long as we don't modify the asset objects, only the order, this shallow copy is OK
        const originalAssets = [...assets];

        // generate reordered assets - as long as we don't modify the asset objects, only the order, this shallow copy is OK
        const reorderedAssets = [...assets];
        reorderedAssets.splice(sourceIndex, 1);
        reorderedAssets.splice(destinationIndex, 0, assetToReorder);

        // Optimistically reorder the client-side mirror of the server state to make reordering appear smooth
        // When the backend responds with a success response, the client state will be wiped anyway
        queryClient.setQueryData(
          ['automatedInsightSession', { sessionId: sessionAndTasks.automatedInsightSession.id }],
          {
            ...sessionAndTasks,
            automatedInsightSession: {
              ...sessionAndTasks.automatedInsightSession,
              assets: reorderedAssets,
            },
          }
        );

        reorderSessionAsset(
          {
            automatedInsightSessionId: sessionAndTasks.automatedInsightSession.id,
            automatedInsightSessionAssetId: assetId,
            // asset sort order starts at index 1
            sortOrder: destinationIndex + 1,
          },
          {
            onSuccess: (data) => {
              setIsReorderingAsset(false);
              track(TrackEvent.REORDERED_PULSE_URL_ASSET, {
                sessionId: sessionAndTasks.automatedInsightSession.id,
                automatedInsightSessionAssetId: assetId,
              });
            },
            onError: (err) => {
              setIsReorderingAsset(false);
              queryClient.setQueryData(
                ['automatedInsightSession', { sessionId: sessionAndTasks.automatedInsightSession.id }],
                {
                  ...sessionAndTasks,
                  automatedInsightSession: {
                    ...sessionAndTasks.automatedInsightSession,
                    assets: originalAssets,
                  },
                }
              );
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error reordering asset');
            },
          }
        );
      }
    },
    [assets, queryClient, reorderSessionAsset, reorderSessionLink, sessionAndTasks, track]
  );

  const handleDeleteAsset = useCallback(
    ({ id, automatedInsightSessionId }) => {
      setIsDeletingAsset(true);

      const assetIndex = assets?.findIndex((asset) => asset?.id === id);

      if (isCompare) {
        const originalLinkedSessions = [...(sessionAndTasks.automatedInsightSession?.linkedSessions ?? [])];

        const session = originalLinkedSessions.find((session) => session.id === automatedInsightSessionId);

        const sessionIndex = originalLinkedSessions
          .map((session) => session.id)
          .indexOf(automatedInsightSessionId);

        const updatedLinkedSessions = [...originalLinkedSessions];
        updatedLinkedSessions.splice(sessionIndex, 1);

        track(TrackEvent.DELETED_COMPARE_PULSE_SESSION, {
          compareSessionId: sessionAndTasks.automatedInsightSession.id,
          linkedSessionId: automatedInsightSessionId,
        });

        deleteSessionLink(
          {
            sessionId: sessionAndTasks.automatedInsightSession.id,
            otherSessionId: automatedInsightSessionId,
            shouldDeleteSession: session && (session?.data ?? []).length < 1,
          },
          {
            onSuccess: () => {
              setShowDeleteAssetModal(false);
              setIsDeletingAsset(false);
              setSelectedAsset(updatedLinkedSessions?.[0]?.assets?.[0]);

              queryClient.setQueryData(
                ['automatedInsightSession', { sessionId: sessionAndTasks.automatedInsightSession.id }],
                {
                  ...sessionAndTasks,
                  automatedInsightSession: {
                    ...sessionAndTasks.automatedInsightSession,
                    linkedSessions: updatedLinkedSessions,
                  },
                }
              );
            },
            onError: (err) => {
              queryClient.setQueryData(
                ['automatedInsightSession', { sessionId: sessionAndTasks.automatedInsightSession.id }],
                {
                  ...sessionAndTasks,
                  automatedInsightSession: {
                    ...sessionAndTasks.automatedInsightSession,
                    linkedSessions: originalLinkedSessions,
                  },
                }
              );
              setIsDeletingAsset(false);
              setSelectedAsset(originalLinkedSessions?.[assetIndex]?.assets?.[0]);
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting asset');
            },
          }
        );
      } else {
        const originalAssets = [...assets];
        const updatedAssets = [...assets];
        updatedAssets.splice(assetIndex, 1);

        track(TrackEvent.DELETED_PULSE_URL_ASSET, {
          sessionId: sessionAndTasks.automatedInsightSession.id,
          automatedInsightSessionAssetId: id,
        });

        deleteSessionAsset(
          {
            automatedInsightSessionId: sessionAndTasks.automatedInsightSession.id,
            automatedInsightSessionAssetId: id,
          },
          {
            onSuccess: () => {
              setShowDeleteAssetModal(false);
              setIsDeletingAsset(false);
              setSelectedAsset(updatedAssets[0]);

              queryClient.setQueryData(
                ['automatedInsightSession', { sessionId: sessionAndTasks.automatedInsightSession.id }],
                {
                  ...sessionAndTasks,
                  automatedInsightSession: {
                    ...sessionAndTasks.automatedInsightSession,
                    assets: updatedAssets,
                  },
                }
              );
            },
            onError: (err) => {
              queryClient.setQueryData(
                ['automatedInsightSession', { sessionId: sessionAndTasks.automatedInsightSession.id }],
                {
                  ...sessionAndTasks,
                  automatedInsightSession: {
                    ...sessionAndTasks.automatedInsightSession,
                    assets: originalAssets,
                  },
                }
              );
              setIsDeletingAsset(false);
              setSelectedAsset(originalAssets[assetIndex]);
              snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error deleting asset');
            },
          }
        );
      }
    },
    [assets, queryClient, deleteSessionAsset, deleteSessionLink, isCompare, sessionAndTasks, track]
  );

  const handleGenerateInsights = useCallback(
    ({ sessionId, createDependencies }) => {
      setIsLoading(true);

      generateInsights(
        { sessionId, createDependencies },
        {
          onSuccess: (data) => {
            queryClient.invalidateQueries('automatedInsightSessions');
            history.replace({
              pathname: generatePath(Paths.automatedInsights.session, { sessionId }),
              state: { sessionDetails: data },
            });
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error generating insights');
          },
          onSettled: () => setIsLoading(false),
        }
      );
    },
    [generateInsights, history, queryClient]
  );

  const handlePreviousClick = () => {
    track(TrackEvent.CLICKED_PULSE_CANCEL_GENERATE_SESSION_FROM_URL, {
      sessionId: sessionAndTasks.automatedInsightSession.id,
      urlIds: automatedInsightImportUrls.map((importURL) => importURL.id),
    });
    history.push({
      pathname: generatePath(Paths.automatedInsights.basePath),
    });
  };

  const handleNextClick = () => {
    if (isCompare) {
      track(TrackEvent.GENERATE_COMPARE_PULSE, {
        compareSessionId: sessionAndTasks.automatedInsightSession.id,
        numberOfSessions: sessionAndTasks.automatedInsightSession?.linkedSessions?.length,
      });

      handleGenerateInsights({
        sessionId: sessionAndTasks.automatedInsightSession.id,
        createDependencies: true,
      });
    } else {
      track(TrackEvent.CLICKED_PULSE_GENERATE_SESSION_FROM_URL, {
        sessionId: sessionAndTasks.automatedInsightSession.id,
        urlIds: automatedInsightImportUrls.map((importURL) => importURL.id),
      });
      history.push({
        pathname: generatePath(Paths.automatedInsights.sessionSettings, {
          sessionId: sessionAndTasks.automatedInsightSession.id,
        }),
      });
    }
  };

  if (!sessionAndTasks || ((_.isNil(user) || _.isNil(userCustomizations)) && !isShareLink)) {
    return (
      <Box sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!isShareLink && !hasAutomatedInsightsFeatureEnabled) {
    return window.location.replace('/');
  }
  return (
    <Box py={3}>
      <Grid container pt={3}>
        {showCreateAssetModal && (
          <AssetCreateModal
            open={showCreateAssetModal}
            onClose={() => {}}
            isLoading={isCreatingNewAsset}
            onCancel={() => setShowCreateAssetModal(false)}
            onConfirm={handleAddAsset}
          />
        )}
        {editedAsset && (
          <AssetReplaceModal
            asset={editedAsset}
            open={!!editedAsset}
            onClose={() => {}}
            isLoading={isReplacingAsset}
            onCancel={() => setEditedAsset(null)}
            onConfirm={handleReplaceAsset}
          />
        )}
        {showDeleteAssetModal && (
          <AssetDeleteModal
            asset={selectedAsset}
            open={showDeleteAssetModal}
            onClose={() => {}}
            isDeleting={isDeletingAsset}
            onCancel={() => setShowDeleteAssetModal(false)}
            onConfirm={handleDeleteAsset}
          />
        )}
        <Paper
          elevation={0}
          sx={{
            height: { md: MainContainerDimensions.Height },
            minHeight: MainContainerDimensions.MinHeight,
            borderRadius: '20px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
          }}>
          <Grid container item px={{ xs: 1, sm: 2, md: 4 }} py={{ xs: 4, md: 2 }}>
            <Grid item xs={12} md={6} pb={0}>
              <Box
                sx={{ height: '100%', border: '2px solid rgba(217, 217, 217, 0.8)', borderRadius: 4 }}
                p={1}>
                <SessionAssetListDetail
                  assets={assets}
                  importURLTasks={tasksByImportURLId}
                  selectedAsset={selectedAsset}
                  onClick={(asset) => {
                    setSelectedAsset(asset);
                  }}
                  onAdd={() => setShowCreateAssetModal(true)}
                  onReplace={(selectedAsset) => setEditedAsset(selectedAsset)}
                  onRetry={(selectedAsset) =>
                    handleReplaceAsset({
                      id: selectedAsset.id,
                      url: selectedAsset.importURL.url,
                      automatedInsightSessionId: selectedAsset.automatedInsightSessionId,
                    })
                  }
                  onDelete={() => setShowDeleteAssetModal(true)}
                  onReorder={(result) => handleReorderAsset({ result })}
                  badgeLabelStyle={badgeLabelStyle}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} pb={0} pl={{ md: 1 }}>
              <Grid
                container
                sx={{ position: 'relative', height: 'calc(100vh - 220px)', minHeight: 500 }}
                justifyContent="center"
                alignItems="center">
                <Grid item xs={12}>
                  <Box
                    sx={{
                      height: 'inherit',
                      paddingX: { xs: 0, md: 3 },
                    }}>
                    <Box
                      sx={{
                        height: 'inherit',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        position: 'relative',
                      }}>
                      <Box
                        sx={{
                          maxWidth: { sm: '90%', md: '85%' },
                          textAlign: 'center',
                          mb: 10,
                        }}>
                        {showCallToAction && (
                          <>
                            <Typography fontWeight={600} fontSize={16}>
                              {callToActionMessage}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'center', columnGap: 2, my: 3 }}>
                              <CustomButton variant="secondaryDark" onClick={handlePreviousClick}>
                                Back
                              </CustomButton>
                              <Tooltip title={nextTooltipMessage}>
                                <span>
                                  <CustomButton
                                    variant="gradient"
                                    disabled={
                                      hasImportsInProgress ||
                                      hasImportErrors ||
                                      (!hasMinimumAssets && !canRunOnePageJourney) ||
                                      isLoading
                                    }
                                    onClick={handleNextClick}>
                                    {hasImportsInProgress || isLoading ? (
                                      <CircularProgress color="primary" size="1.3rem" />
                                    ) : (
                                      'Yes'
                                    )}
                                  </CustomButton>
                                </span>
                              </Tooltip>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <ButtonWithInfoBox
                  includeTips={true}
                  buttonContainerStyles={{
                    position: 'absolute',
                    bottom: 0,
                    left: (theme) => ({ xs: 0, md: theme.spacing(2) }),
                  }}
                  infoBoxContainerStyles={{
                    height: '43%',
                    position: 'absolute',
                    bottom: 0,
                    left: (theme) => ({ xs: 0, md: theme.spacing(2) }),
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Box>
  );
}
