import React from 'react';
import { grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Box, TextField, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { TestTypes } from '../../../../../modules/intake/constants';
import DebouncedInput from '../../DebouncedInput';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    verticalAlign: 'bottom',
    color: grey[600],
  },
  invisibleIcon: {
    fontSize: '14px',
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.25),
  },
}));

export default function TaskToComplete({
  errors,
  taskToComplete,
  handleTaskToCompleteInput,
  hasExperienceConfiguration,
  disabled = false,
  testType,
}) {
  const classes = useStyles();

  const helpTooltip = disabled ? (
    <InfoIcon className={classes.infoIcon} />
  ) : (
    <Tooltip
      placement="right-start"
      title={
        <>
          <span>
            Describe the task(s) you would like users to complete. Phrase it with an end action in mind.
          </span>
          <Box py={1} />
          <span>
            WEVO cannot ask users to make a purchase or to provide personally identifiable information.
          </span>
          {!hasExperienceConfiguration && (
            <>
              <Box py={1} />
              <VisibilityOffIcon className={classes.invisibleIcon} />
              <span>
                Note: The following details are not panel facing, but are used by WEVO to create the best
                possible study based on your input.
              </span>
            </>
          )}
        </>
      }>
      <InfoIcon className={classes.infoIcon} />
    </Tooltip>
  );

  const inputPlaceholderText = hasExperienceConfiguration
    ? `Example: Checkout a 65-inch TV.`
    : `Example: Begin at product detail page, proceed through the cart page and complete the checkout journey all the way through the point of payment.`;

  const taskCompletionText =
    testType === TestTypes.Usability ? (
      <>
        <Typography>Give respondents instructions on how to navigate your experience.</Typography>
        <Typography pb={1}>Please see example below.</Typography>
      </>
    ) : (
      <>
        <Typography py={1}>
          Input the exact instructions that you want participants to see before completing your task.
        </Typography>
        <Typography pt={1} pb={2}>
          Pro-tip: if you're testing usability/navigation you likely want to give a broad task and avoid giving
          specific instructions (e.g. "Sign up for an account" vs. "Click 'sign-up,' then enter your name,
          etc...").
        </Typography>
      </>
    );

  const disabledTooltipText =
    testType === TestTypes.Usability
      ? 'Please add a test asset before adding a task.'
      : 'Add your asset first: asset type may impact how we recommend you structure your task to complete.';

  const input = (
    <DebouncedInput
      value={taskToComplete}
      onChange={handleTaskToCompleteInput}
      debounceMs={500}
      renderInput={({ value, onChange }) => (
        <TextField
          fullWidth
          variant="outlined"
          multiline={!hasExperienceConfiguration}
          disabled={disabled}
          rows={4}
          value={value}
          onChange={onChange}
          placeholder={inputPlaceholderText}
          error={Boolean(errors?.taskToComplete)}
          helperText={errors?.taskToComplete?.message ?? ''}
        />
      )}
    />
  );

  return (
    <Box>
      <Box sx={{ opacity: disabled ? '50%' : '100%' }}>
        <Typography variant="h5" gutterBottom>
          Task to Complete* {helpTooltip}
        </Typography>
        {taskCompletionText}
      </Box>
      {disabled ? (
        <Tooltip placement="top-end" title={disabledTooltipText}>
          <Box>{input}</Box>
        </Tooltip>
      ) : (
        input
      )}
    </Box>
  );
}

TaskToComplete.propTypes = {
  errors: PropTypes.object,
  taskToComplete: PropTypes.string.isRequired,
  handleTaskToCompleteInput: PropTypes.func.isRequired,
  hasExperienceConfiguration: PropTypes.bool,
  disabled: PropTypes.bool,
};
