import _ from 'lodash';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { createElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { generatePath } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { ReactComponent as KeyFindingsIcon } from '../../../../assets/key-findings.svg';
import { KEY_FINDING_TYPE, KeyFindingType } from '../../../../modules/report/constants';
import { hasKeyFindings } from '../../../../modules/wevos/helpers';
import { Paths } from '../../../../routes';
import useKeyFindings, { useCrossPageKeyFindings } from '../../hooks/useKeyFindings';
import SlateKeyFindingsViewer from '../../takeaways/components/SlateKeyFindingsViewer';
import { WevoTestType } from '../../../../modules/wevos/constants';
import { PropTypes } from 'prop-types';

function generateKeyFindingsPath(wevo, pageNumber) {
  return wevo?.useVersionedDiagnostics && wevo?.testType === WevoTestType.Compare && !_.isNil(pageNumber)
    ? generatePath(Paths.reports.takeawaysPage, {
        wevoId: wevo?.id,
        pageNum: pageNumber,
      })
    : generatePath(Paths.reports.takeaways, {
        wevoId: wevo?.id,
      });
}

const styles = {
  cardContent: {
    display: 'flex',
    height: '100%',
  },
  grey: {
    backgroundColor: grey[100],
  },
  icon: {
    backgroundColor: grey[100],
    borderRadius: '50%',
    padding: '3px',
  },
  container: {
    alignContent: 'space-between',
    minHeight: '280px',
  },
  keyFindingsIconGrid: {
    marginRight: (theme) => theme.spacing(2),
  },
  link: {
    color: (theme) => theme.palette.primary.main,
    fontSize: '12px',
    textAlign: 'end',
    padding: '6px 0px',
  },
  rightArrow: {
    marginLeft: (theme) => theme.spacing(1),
    verticalAlign: 'bottom',
  },
  keyFindingsContainer: {
    marginLeft: (theme) => theme.spacing(6.5),
    marginRight: (theme) => theme.spacing(4.5),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'wrap',
  },
  noWrap: {
    flexWrap: 'nowrap',
  },
  subText: {
    color: (theme) => theme.palette.primary.dark,
  },
  underline: {
    textDecoration: 'none',
  },
  expandButton: {
    fontSize: '12px',
    textTransform: 'capitalize',
    lineHeight: 'inherit',
  },
};

const StyledCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isExpanded',
})(({ isExpanded }) => ({
  borderRadius: '20px',
  height: isExpanded ? '' : '100%',
  maxHeight: isExpanded ? '' : '500px',
}));

const headingRenderer = (props) =>
  createElement(
    `h${props.level}`,
    {
      style: {
        marginLeft: '24px',
        lineHeight: '1.3',
        textAlign: 'left',
      },
    },
    props.children
  );

const customRenderers = {
  h1: headingRenderer,
  h2: headingRenderer,
  h3: headingRenderer,
  h4: headingRenderer,
  h5: headingRenderer,
  h6: headingRenderer,
  ul: (props) =>
    createElement(
      `ul`,
      {
        style: {
          lineHeight: '1.5', // lineHeight = sets the margin between each bullet's lines
          textAlign: 'left',
          marginTop: '-8px',
        },
      },

      props.children.map((child, index) => {
        return createElement('div', { key: index, style: { marginBlock: '16px' } }, child); // marginBlock = sets the margin between 2 bullets
      })
    ),
};

function WevoKeyFindingsWidget({
  wevoId,
  title = 'WEVO Key Findings',
  IconComponent = KeyFindingsIcon,
  keyFindings,
  keyFindingsPath,
  isLimitedReport,
  hideKeyFindings, // this key determines if we should mask key findings data with a default message
  findingType = KEY_FINDING_TYPE.Summary,
}) {
  const [showExpandButton, setShowExpandButton] = useState();
  const wevoKeyFindingsRef = useRef();
  const theme = useTheme();
  const [isExpanded, setIsExpanded] = useState(false);

  const scrollWithOffset = (el) => {
    const yOffset = -70;
    const yCoordinate = el.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: yCoordinate, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('resize', handleExpandButton);
    handleExpandButton();

    return () => {
      window.removeEventListener('resize', handleExpandButton);
    };
  });

  const handleExpandButton = () => {
    // firstChild of the ref refers to the element within the SlateKeyFindingsViewer
    if (wevoKeyFindingsRef?.current?.firstChild?.offsetHeight) {
      setShowExpandButton(wevoKeyFindingsRef?.current?.firstChild?.offsetHeight > 360);
    }
  };

  const toggleExpanded = (e) => {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  };

  const renderKeyFindingsSummary = useCallback(() => {
    const keyFindingsType = keyFindings?.[0]?.type;
    const pageId = keyFindings?.[0]?.wevoPageId;

    const displayValue = keyFindings?.[0]?.[findingType];

    if (!keyFindings.length) {
      return (
        <Box p={4} textAlign="center">
          <CircularProgress />
        </Box>
      );
    }

    if (keyFindingsType === KeyFindingType.Slate) {
      return (
        <Typography variant="body2" component="span" ref={wevoKeyFindingsRef}>
          {/* Assuming all new tests have a summary for key findings */}
          <SlateKeyFindingsViewer
            key={`wevo-key-findings-dashboard-viewer-wevo-${wevoId}-${
              pageId ? `page-${pageId}` : 'cross-page-findings'
            }`}
            findings={displayValue}
            wevoId={wevoId}
            pageId={pageId}
            allowUpvote={false}
            showUpvotes={false}
            isDashboard={true}
          />
        </Typography>
      );
    } else if (keyFindingsType === KeyFindingType.Markdown) {
      return (
        <Typography variant="body2" component="span" ref={wevoKeyFindingsRef}>
          {/* Assuming all new tests have a summary for key findings */}
          <ReactMarkdown components={customRenderers}>{displayValue}</ReactMarkdown>
        </Typography>
      );
    } else {
      return (
        <Typography variant="body2" sx={{ marginLeft: 3, marginTop: 3 }}>
          This study does not include WEVO Key Findings.
        </Typography>
      );
    }
  }, [keyFindings, findingType, wevoId]);

  return (
    <StyledCard elevation={4} isExpanded={isExpanded}>
      <CardContent sx={styles.cardContent}>
        <Grid container spacing={2} justifyContent="center" sx={hideKeyFindings ? {} : styles.container}>
          <Grid item container sx={styles.noWrap} xs={12}>
            <Grid item sx={styles.keyFindingsIconGrid}>
              {isLimitedReport || !keyFindingsPath ? (
                <Box sx={styles.icon}>
                  <IconComponent
                    fill={theme.palette.primary.main}
                    style={{
                      width: '25px',
                      height: '25px',
                      marginTop: theme.spacing(0.25),
                      marginRight: theme.spacing(0.25),
                      marginLeft: theme.spacing(0.25),
                      marginBottom: theme.spacing(-0.25),
                    }}
                  />
                </Box>
              ) : (
                <IconButton size="small" sx={styles.grey}>
                  <HashLink
                    to={`${keyFindingsPath}#wevo-key-findings-section`}
                    scroll={scrollWithOffset}
                    style={styles.underline}>
                    <IconComponent
                      fill={theme.palette.primary.main}
                      style={{
                        width: '25px',
                        height: '25px',
                        marginTop: theme.spacing(0.25),
                        marginRight: theme.spacing(0.25),
                        marginLeft: theme.spacing(0.25),
                        marginBottom: theme.spacing(-0.25),
                      }}
                    />
                  </HashLink>
                </IconButton>
              )}
            </Grid>
            <Grid item>
              <Typography variant="h5">{title}</Typography>
              <Typography variant="caption" sx={styles.subText}>
                Insights generated by WEVO's analysts
              </Typography>
            </Grid>
          </Grid>
          {hideKeyFindings ? (
            <Grid item container justifyContent="center">
              <Typography variant="body2">This study does not include WEVO Key Findings.</Typography>
            </Grid>
          ) : (
            <Grid
              item
              sx={{
                maxHeight: isExpanded ? '100%' : '340px',
                ...styles.keyFindingsContainer,
              }}
              xs={12}>
              {renderKeyFindingsSummary()}
            </Grid>
          )}

          {isLimitedReport && showExpandButton && (
            <Grid item xs={12}>
              <Button
                sx={styles.expandButton}
                endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={toggleExpanded}
                cursor={'pointer'}>
                {isExpanded ? 'Show Less' : 'Show More'}
              </Button>
            </Grid>
          )}
          {!isLimitedReport && showExpandButton && (
            <Grid container item justifyContent="space-between">
              <Grid item>
                <Button
                  sx={styles.expandButton}
                  endIcon={isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  onClick={toggleExpanded}
                  cursor={'pointer'}>
                  {isExpanded ? 'Show Less' : 'Show More'}
                </Button>
              </Grid>
              <Grid item>
                <HashLink
                  to={`${keyFindingsPath}#wevo-key-findings-section`}
                  scroll={scrollWithOffset}
                  style={styles.underline}>
                  <Typography variant="body2" sx={styles.link}>
                    See Key Findings
                    <ChevronRightIcon fontSize="small" sx={styles.rightArrow} />
                  </Typography>
                </HashLink>
              </Grid>
            </Grid>
          )}
          {!hideKeyFindings && !isLimitedReport && !showExpandButton && keyFindingsPath && (
            <Grid item xs={12}>
              <HashLink
                to={`${keyFindingsPath}#wevo-key-findings-section`}
                scroll={scrollWithOffset}
                style={styles.underline}>
                <Typography variant="body2" sx={styles.link}>
                  See Key Findings
                  <ChevronRightIcon fontSize="small" sx={styles.rightArrow} />
                </Typography>
              </HashLink>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </StyledCard>
  );
}

WevoKeyFindingsWidget.propTypes = {
  wevoId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  title: PropTypes.string,
  IconComponent: PropTypes.elementType,
  keyFindings: PropTypes.arrayOf(PropTypes.object),
  keyFindingsPath: PropTypes.string,
  selectedAssetNum: PropTypes.number,
  isLimitedReport: PropTypes.bool,
  hideKeyFindings: PropTypes.bool,
  findingType: PropTypes.oneOf([
    KEY_FINDING_TYPE.CrossPageFinding,
    KEY_FINDING_TYPE.PageFinding,
    KEY_FINDING_TYPE.Summary,
  ]),
};

function WevoKeyFindings(props) {
  const { wevo, page, selectedAssetNum, isLimitedReport } = props;

  const keyFindingsQueryResult = useKeyFindings({
    wevoId: wevo?.id,
    pageId: page?.id,
  });

  const keyFindings = useMemo(() => {
    return keyFindingsQueryResult?.data ?? [];
  }, [keyFindingsQueryResult]);

  const keyFindingsPath = useMemo(() => {
    if (!selectedAssetNum) {
      return null;
    }

    return generateKeyFindingsPath(wevo, selectedAssetNum);
  }, [selectedAssetNum, wevo]);

  const hideKeyFindings = useMemo(() => !hasKeyFindings(wevo, keyFindings), [wevo, keyFindings]);

  return (
    <WevoKeyFindingsWidget
      wevoId={wevo.id} // need to pass this in explicitly because it may be a shareId, which allows unauthenticated access
      keyFindings={keyFindings}
      keyFindingsPath={keyFindingsPath}
      isLimitedReport={isLimitedReport}
      hideKeyFindings={hideKeyFindings}
      findingType={KEY_FINDING_TYPE.Summary}
    />
  );
}

WevoKeyFindings.propTypes = {
  wevo: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  selectedAssetNum: PropTypes.number,
  isLimitedReport: PropTypes.bool,
};

export function WevoCrossPageKeyFindings({ wevo, isLimitedReport }) {
  const keyFindingsPath = useMemo(() => generateKeyFindingsPath(wevo), [wevo]);
  const { data: crossPageKeyFindings } = useCrossPageKeyFindings({ wevoId: wevo.id });
  const hasCrossPageKeyFindings = useMemo(() => !_.isEmpty(crossPageKeyFindings), [crossPageKeyFindings]);

  return (
    <WevoKeyFindingsWidget
      wevoId={wevo.id} // need to pass this in explicitly because it may be a shareId, which allows unauthenticated access
      title="Wevo Cross-Page Findings"
      keyFindings={hasCrossPageKeyFindings ? [crossPageKeyFindings] : []}
      keyFindingsPath={keyFindingsPath}
      isLimitedReport={isLimitedReport}
      hideKeyFindings={!hasCrossPageKeyFindings}
      findingType={KEY_FINDING_TYPE.CrossPageFinding}
    />
  );
}

WevoCrossPageKeyFindings.propTypes = {
  wevo: PropTypes.object.isRequired,
  isLimitedReport: PropTypes.bool,
};

export default WevoKeyFindings;
