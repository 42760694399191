import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { ReactComponent as DashboardIcon } from '../../../assets/dashboard.svg';
import { WevoTestType, WevoType } from '../../../modules/wevos/constants';
import { isClassicSinglePage } from '../../../modules/wevos/helpers';
import { Paths } from '../../../routes';
import LeftNavItem from './LeftNavItem';

const useStyles = makeStyles((theme) => ({
  svg: {
    width: 'inherit',
    height: 'inherit',
  },
}));

const DashboardLeftNavButton = ({
  wevo,
  levels,
  selected,
  selectedSubItem,
  setSelectedSubItem,
  generateSubItemPath,
  isLimitedReport,
}) => {
  const classes = useStyles();
  const wevoId = wevo?.id;
  const isJourney = wevo?.type === WevoType.Journey;
  const isClassicSinglePageWevo = isClassicSinglePage(wevo);

  if (wevo?.testType === WevoTestType.Compare && wevo?.useVersionedDiagnostics) {
    return (
      <LeftNavItem
        icon={<DashboardIcon className={classes.svg} />}
        label={'Dashboard'}
        path={generatePath(
          isLimitedReport ? Paths.reports.limitedDashboardPage : Paths.reports.dashboardPage,
          {
            wevoId,
            pageNum: selectedSubItem.pageNumber,
          }
        )}
        levels={levels}
        selected={selected}
        subItemPath={isLimitedReport ? Paths.reports.limitedDashboardPage : Paths.reports.dashboardPage}
        generateSubItemPath={generateSubItemPath}
        selectedSubItem={selectedSubItem}
        setSelectedSubItem={setSelectedSubItem}
      />
    );
  } else if (isJourney || (isClassicSinglePageWevo && wevo?.useVersionedDiagnostics)) {
    return (
      <LeftNavItem
        icon={<DashboardIcon className={classes.svg} />}
        label="Dashboard"
        path={generatePath(isLimitedReport ? Paths.reports.limitedDashboard : Paths.reports.dashboard, {
          wevoId,
        })}
        selected={selected}
      />
    );
  } else {
    return null;
  }
};

DashboardLeftNavButton.propTypes = {
  wevo: PropTypes.object.isRequired,
  levels: PropTypes.array,
  selected: PropTypes.bool.isRequired,
  selectedSubItem: PropTypes.object.isRequired,
  setSelectedSubItem: PropTypes.func.isRequired,
  generateSubItemPath: PropTypes.func.isRequired,
};

export default DashboardLeftNavButton;
