import { useQuery } from 'react-query';
import axios from '../api';

const fetchConstants = async () => {
  const { data } = await axios({
    url: `/api/v2/constants/customer-app`,
    method: 'GET',
  });
  return data?.constants || {};
};

export default function useConstants() {
  return useQuery(['wevoCustomerAppConstants'], fetchConstants, { refetchOnWindowFocus: false });
}
