import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Chip, Grid, Popper, TextField, Tooltip, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { validate as uuidValidate } from 'uuid';
import { useAddTags, useDeleteTag, useWevosTags } from '../../hooks/useTags';
import theme from '../../theme';

const tagsCompareFunction = (a, b) => {
  const aIsUUID = uuidValidate(a.name);
  const bIsUUID = uuidValidate(b.name);

  if (aIsUUID && !bIsUUID) {
    return 1;
  } else if (!aIsUUID && bIsUUID) {
    return -1;
  } else {
    return 0;
  }
};

export const Tag = (props) => {
  const { tooltipTitle, tooltipPlacement, label, countIcon, tagId, onDelete, onClick, style } = props;

  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement || 'top'}>
      <Chip
        label={label}
        deleteIcon={countIcon || <CloseIcon style={{ color: theme.palette.primary.main, fontSize: '16px' }} />}
        onDelete={!!onDelete ? () => onDelete(tagId) : null}
        onClick={!!onClick ? () => onClick() : null}
        sx={style}
        size="small"
      />
    </Tooltip>
  );
};

// Tags for Intake
export const TagsListIntake = (props) => {
  const { wevoId, tags, tagStyle } = props;

  const { mutate: deleteTag } = useDeleteTag();

  const handleDeleteChipClick = useCallback((tagId) => deleteTag({ id: wevoId, tagId }), [wevoId, deleteTag]);

  return (
    <Grid
      container
      spacing={1}
      justifyContent={'flex-start'}
      sx={{ maxHeight: 110, overflowY: 'auto', marginLeft: 1, marginBottom: tags?.length ? 3 : 0 }}>
      {tags?.map((tag, index) => {
        return (
          <Grid item key={`${tag.name}-${index}`} sx={{ maxWidth: '140px' }}>
            <Tag
              tooltipTitle={tag.name}
              label={tag.name}
              tagId={tag.tagId}
              onDelete={() => handleDeleteChipClick(tag.tagId)}
              style={{
                backgroundColor: grey[100],
                borderRadius: 2,
                '& .MuiChip-deleteIcon': {
                  display: 'none',
                },
                '&:hover': {
                  '& .MuiChip-deleteIcon': {
                    display: 'block',
                  },
                },
                ...tagStyle,
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const TagsMenuIntake = (props) => {
  const { draft, tags, openTagsMenu, tagsRef, tag, setTag, trimmedTag, setOpenTagsMenu } = props;

  const { data: wevosTagsGroups } = useWevosTags();
  // sort to put uuid at the end
  const sortedTagsGroups = useMemo(() => {
    return wevosTagsGroups.sort(tagsCompareFunction);
  }, [wevosTagsGroups]);

  const { mutate: addTags } = useAddTags();

  const currentWevoTagsNames = useMemo(() => {
    return tags?.map((tag) => tag.name.toLowerCase());
  }, [tags]);

  const allWevosTagGroupsNames = useMemo(() => {
    return wevosTagsGroups.map((wevoTagsGroup) => wevoTagsGroup.name.toLowerCase());
  }, [wevosTagsGroups]);

  const createTag = useCallback(
    (newTag) =>
      addTags(
        { id: draft?.id, tags: [newTag.trim()] },
        {
          onSuccess: () => {
            setTag('');
            setOpenTagsMenu(false);
          },
        }
      ),
    [draft?.id, addTags, setTag, setOpenTagsMenu]
  );

  const handleTagSelect = (tagSelected) => {
    createTag(tagSelected);
  };

  const handleClose = () => {
    setTag('');
    setOpenTagsMenu(false);
  };

  const isNewTag = () => {
    return Boolean(
      !currentWevoTagsNames?.includes(trimmedTag) && !allWevosTagGroupsNames?.includes(trimmedTag)
    );
  };

  const isAlreadyTagged = (tag) => {
    return Boolean(currentWevoTagsNames?.includes(tag.toLowerCase()));
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Popper
        open={openTagsMenu}
        anchorEl={tagsRef.current}
        placement="bottom-start"
        transition
        disablePortal
        sx={{ zIndex: 1000, position: 'relative' }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}>
            <Paper>
              <MenuList id="tags-menu" aria-labelledby="tags-text-Box" sx={{ width: '552px' }}>
                {isNewTag() ? (
                  <span>
                    <MenuItem
                      disabled={!tag.trim().length}
                      onClick={() => handleTagSelect(tag)}
                      sx={{ marginTop: '-8px', marginBottom: '-8px' }}>
                      <AddIcon
                        sx={{
                          color: grey[500],
                          fontSize: '13px',
                          marginLeft: -1,
                          marginRight: 0.25,
                        }}
                      />
                      <Typography variant="caption">Create tag:</Typography>
                      &nbsp;
                      {tag?.length ? (
                        <Tag label={tag} style={{ height: '25px' }} />
                      ) : (
                        <Typography variant="caption" sx={{ color: grey[600] }}>
                          ""
                        </Typography>
                      )}
                    </MenuItem>
                    {sortedTagsGroups?.filter(
                      ({ name }) => !trimmedTag || name.toLowerCase().includes(trimmedTag)
                    ).length ? (
                      <hr
                        style={{
                          background: grey[300],
                          height: '0.5px',
                          border: 'none',
                        }}
                      />
                    ) : null}
                  </span>
                ) : null}
                {sortedTagsGroups
                  ?.filter(
                    ({ name }) => !trimmedTag || (name.toLowerCase().includes(trimmedTag) && tag.trim().length)
                  )
                  ?.map((filteredTag, index) => {
                    return (
                      <MenuItem
                        disabled={isAlreadyTagged(filteredTag.name)}
                        key={index}
                        onClick={
                          !isAlreadyTagged(filteredTag.name)
                            ? () => handleTagSelect(filteredTag.name)
                            : () => {}
                        }>
                        <Tag label={filteredTag.name} style={{ height: '25px' }} />
                      </MenuItem>
                    );
                  })}
              </MenuList>
            </Paper>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  );
};

const TagTextField = (props) => {
  const {
    tagsRef,
    inputRef,
    onClick,
    onBlur,
    onChange,
    onFocus,
    tag,
    invalid,
    style,
    required,
    helperText,
    isDQS,
    isCDS,
  } = props;
  return (
    <Tooltip
      placement="bottom"
      title={
        isDQS || isCDS ? (
          <Box sx={{ marginTop: -1, marginBottom: -1 }}>
            <Box mb={2} sx={{ marginLeft: 1, marginTop: 2 }}>
              <Typography variant="body1" sx={{ fontSize: '13px' }}>
                Your product's UUID, found in{' '}
                <b>
                  <a
                    href="https://mastercard.sharepoint.com/sites/info_macatalog"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: 'white' }}>
                    Mastercard Catalog
                  </a>
                </b>
                , is required to launch a CDS or DQS study.
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '13px' }}>
                All CDS and DQS studies are displayed within the applicable Studio Dashboards.
              </Typography>
            </Box>
          </Box>
        ) : (
          ''
        )
      }>
      <TextField
        ref={tagsRef}
        inputRef={inputRef}
        type="text"
        onClick={onClick}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        variant="outlined"
        fullWidth
        label="Test Tag(s)"
        InputLabelProps={{ shrink: true }}
        placeholder="Test Tag(s)"
        value={tag}
        error={invalid}
        helperText={helperText}
        sx={style}
        required={required}
      />
    </Tooltip>
  );
};

const TagsForExistingDraft = (props) => {
  const {
    draft,
    tags,
    tagsRef,
    inputRef,
    onBlur,
    onChange,
    onFocus,
    setOpenTagsMenu,
    openTagsMenu,
    trimmedTag,
    tag,
    setTag,
    invalid,
    error,
    required,
    helperText,
    isDQS,
    isCDS,
  } = props;

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TagTextField
            tagsRef={tagsRef}
            inputRef={inputRef}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            tag={tag}
            invalid={invalid}
            error={error}
            style={{ marginTop: 1, marginBottom: 1 }}
            required={required}
            helperText={helperText}
            isDQS={isDQS}
            isCDS={isCDS}
          />
        </Grid>
        <TagsListIntake
          wevoId={draft?.id}
          tags={tags}
          tagStyle={{
            backgroundColor: 'white',
            color: 'grey',
            fontSize: '12px',
            height: '23px',
            borderRadius: 10,
            '& .MuiChip-deleteIcon': {
              display: 'block',
              height: '10px',
              color: theme.palette.primary.main,
              stroke: theme.palette.primary.main,
              strokeWidth: '0.5',
            },
          }}
        />
      </Grid>
      {tagsRef.current && (
        <TagsMenuIntake
          draft={draft}
          tags={tags}
          openTagsMenu={openTagsMenu}
          tagsRef={tagsRef}
          tag={tag}
          setTag={setTag}
          trimmedTag={trimmedTag}
          setOpenTagsMenu={setOpenTagsMenu}
        />
      )}
    </Box>
  );
};

const TagsForNewDraft = (props) => {
  const {
    tagsRef,
    inputRef,
    onBlur,
    onChange,
    onFocus,
    setOpenTagsMenu,
    openTagsMenu,
    trimmedTag,
    tag,
    setTag,
    invalid,
    error,
    setTagsList,
    required,
    helperText,
    isDQS,
    isCDS,
  } = props;

  const [tags, setTags] = useState([]);
  const { data: wevosTagsGroups } = useWevosTags();

  const allTags = useMemo(() => {
    const tagsArray = [...wevosTagsGroups];
    for (const tag of tags) {
      if (!tagsArray.map((tag) => tag.name).includes(tag.name)) {
        tagsArray.push(tag);
      }
    }
    return tagsArray.sort(tagsCompareFunction);
  }, [wevosTagsGroups, tags]);

  const currentWevoTagsNames = useMemo(() => {
    return tags?.map((tag) => tag.name.toLowerCase());
  }, [tags]);

  const allTagsNames = useMemo(() => {
    return allTags.map((tag) => tag.name.toLowerCase());
  }, [allTags]);

  const handleDeleteChipClick = (tagName) => {
    const tagToDeleteIndex = tags.findIndex((tag) => tag.name === tagName);
    const newTags = [...tags];
    newTags.splice(tagToDeleteIndex, 1);
    setTags(newTags);
    setTagsList(newTags);
  };

  const handleTagSelect = (tagSelected) => {
    setTag('');
    setOpenTagsMenu(false);
    const newTagToAdd = { name: tagSelected };
    setTags((tags) => [...tags, newTagToAdd]);
    setTagsList([...tags, newTagToAdd]);
  };

  const handleClose = () => {
    setTag('');
    setOpenTagsMenu(false);
  };

  const isNewTag = () => {
    return Boolean(!currentWevoTagsNames?.includes(trimmedTag) && !allTagsNames?.includes(trimmedTag));
  };

  const isAlreadyTagged = (tag) => {
    return Boolean(currentWevoTagsNames?.includes(tag.toLowerCase()));
  };

  return (
    <Box>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TagTextField
            tagsRef={tagsRef}
            inputRef={inputRef}
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            tag={tag}
            invalid={invalid}
            error={error}
            style={{ marginTop: 1, marginBottom: 1 }}
            required={required}
            helperText={helperText}
            isDQS={isDQS}
            isCDS={isCDS}
          />
        </Grid>
        {
          <Grid
            container
            spacing={1}
            justifyContent={'flex-start'}
            sx={{ maxHeight: 110, overflowY: 'auto', marginLeft: 1, marginBottom: tags?.length ? 3 : 0 }}>
            {tags.map((tag, index) => {
              return (
                <Grid item key={`${tag.name}-${index}`} sx={{ maxWidth: '140px' }}>
                  <Tag
                    tooltipTitle={tag.name}
                    label={tag.name}
                    tagId={tag.tagId || ''}
                    onDelete={() => handleDeleteChipClick(tag.name)}
                    style={{
                      backgroundColor: 'white',
                      color: 'grey',
                      fontSize: '12px',
                      height: '23px',
                      borderRadius: 10,
                      '& .MuiChip-deleteIcon': {
                        display: 'block',
                        height: '10px',
                        color: theme.palette.primary.main,
                        stroke: theme.palette.primary.main,
                        strokeWidth: '0.5',
                      },
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        }
      </Grid>
      {tagsRef.current && (
        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            open={openTagsMenu}
            anchorEl={tagsRef.current}
            placement="bottom-start"
            transition
            disablePortal
            sx={{ zIndex: 1000, position: 'relative' }}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}>
                <Paper>
                  <MenuList id="tags-menu" aria-labelledby="tags-text-Box" sx={{ width: '552px' }}>
                    {isNewTag() ? (
                      <span>
                        <MenuItem
                          disabled={!tag.trim().length}
                          onClick={() => handleTagSelect(tag)}
                          sx={{ marginTop: '-8px', marginBottom: '-8px' }}>
                          <AddIcon
                            sx={{
                              color: grey[500],
                              fontSize: '13px',
                              marginLeft: -1,
                              marginRight: 0.25,
                            }}
                          />
                          <Typography variant="caption">Create tag:</Typography>
                          &nbsp;
                          {tag?.length ? (
                            <Tag label={tag} style={{ height: '25px' }} />
                          ) : (
                            <Typography variant="caption" sx={{ color: grey[600] }}>
                              ""
                            </Typography>
                          )}
                        </MenuItem>
                        {wevosTagsGroups?.filter(
                          ({ name }) => !trimmedTag || name.toLowerCase().includes(trimmedTag)
                        ).length ? (
                          <hr
                            style={{
                              background: grey[300],
                              height: '0.5px',
                              border: 'none',
                            }}
                          />
                        ) : null}
                      </span>
                    ) : null}
                    {allTags
                      ?.filter(
                        ({ name }) =>
                          !trimmedTag || (name.toLowerCase().includes(trimmedTag) && tag.trim().length)
                      )
                      ?.map((filteredTag, index) => {
                        return (
                          <MenuItem
                            disabled={isAlreadyTagged(filteredTag.name)}
                            key={index}
                            onClick={
                              !isAlreadyTagged(filteredTag.name)
                                ? () => handleTagSelect(filteredTag.name)
                                : () => {}
                            }>
                            <Tag label={filteredTag.name} style={{ height: '25px' }} />
                          </MenuItem>
                        );
                      })}
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export const TagsIntake = (props) => {
  const { draft, tags, inputRef, onBlur, invalid, error, setTagsList, required, helperText, isDQS, isCDS } =
    props;

  const tagsRef = useRef(null);

  const [openTagsMenu, setOpenTagsMenu] = useState(false);
  const [tag, setTag] = useState('');

  const trimmedTag = useMemo(() => {
    return tag.trim().toLowerCase();
  }, [tag]);

  const handleTagChange = (ev) => {
    setTag(ev.target.value);
    setOpenTagsMenu(Boolean(ev.target.value.length));
  };

  const handleTagTextFieldFocus = () => {
    setOpenTagsMenu(true);
  };

  return draft && draft?.id ? (
    <TagsForExistingDraft
      draft={draft}
      tags={tags}
      tagsRef={tagsRef}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={handleTagChange}
      onFocus={handleTagTextFieldFocus}
      openTagsMenu={openTagsMenu}
      setOpenTagsMenu={setOpenTagsMenu}
      trimmedTag={trimmedTag}
      tag={tag}
      setTag={setTag}
      invalid={invalid}
      error={error}
      required={required}
      helperText={helperText}
      isDQS={isDQS}
      isCDS={isCDS}
    />
  ) : (
    <TagsForNewDraft
      tagsRef={tagsRef}
      inputRef={inputRef}
      onBlur={onBlur}
      onChange={handleTagChange}
      onFocus={handleTagTextFieldFocus}
      openTagsMenu={openTagsMenu}
      setOpenTagsMenu={setOpenTagsMenu}
      trimmedTag={trimmedTag}
      tag={tag}
      setTag={setTag}
      invalid={invalid}
      error={error}
      setTagsList={setTagsList}
      required={required}
      helperText={helperText}
      isDQS={isDQS}
      isCDS={isCDS}
    />
  );
};

// Tags for Dashboard
export const TagsList = (props) => {
  const { wevoId, tags } = props;

  const { mutate: deleteTag } = useDeleteTag();

  const handleDeleteTagClick = useCallback((tagId) => deleteTag({ id: wevoId, tagId }), [wevoId, deleteTag]);

  return tags?.map((tag, index) => {
    return (
      <Grid item key={`${tag.name}-${index}`} sx={{ maxWidth: '140px' }}>
        <Tag
          tooltipTitle={tag.name}
          label={tag.name}
          tagId={tag.tagId}
          onDelete={() => handleDeleteTagClick(tag.tagId)}
          style={{
            backgroundColor: grey[100],
            borderRadius: 2,
            '& .MuiChip-deleteIcon': {
              display: 'none',
            },
            '&:hover': {
              '& .MuiChip-deleteIcon': {
                display: 'block',
              },
            },
          }}
        />
      </Grid>
    );
  });
};

export const TagSelector = (props) => {
  const { wevoId, tags, setEnableTaggingForWevoId, setDisplayAddTagForWevoId } = props;

  const [openTagsMenu, setOpenTagsMenu] = useState(true);
  const [tag, setTag] = useState('');

  const anchorRef = useRef(null);
  const [mountPopper, setMountPopper] = useState(false);

  const trimmedTag = useMemo(() => {
    return tag.trim().toLowerCase();
  }, [tag]);

  const { data: wevosTagsGroups } = useWevosTags();
  // sort to put uuid at the end
  const sortedTagsGroups = useMemo(() => {
    return wevosTagsGroups.sort(tagsCompareFunction);
  }, [wevosTagsGroups]);

  const { mutate: addTags } = useAddTags();

  const currentWevoTagsNames = useMemo(() => {
    return tags?.map((tag) => tag.name.toLowerCase());
  }, [tags]);

  const allWevosTagGroupsNames = useMemo(() => {
    return wevosTagsGroups.map((wevoTagsGroup) => wevoTagsGroup.name.toLowerCase());
  }, [wevosTagsGroups]);

  const createTag = useCallback(
    (newTag) =>
      addTags(
        { id: wevoId, tags: [newTag.trim()] },
        {
          onSuccess: () => {
            setTag('');
            setEnableTaggingForWevoId(null);
          },
        }
      ),
    [wevoId, addTags, setEnableTaggingForWevoId, setTag]
  );

  const handleTagChange = (ev) => {
    setTag(ev.target.value);
  };

  const handleTagSelect = (tagSelected) => {
    createTag(tagSelected);
  };

  const handleClose = () => {
    setTag('');
    setOpenTagsMenu(false);
    setEnableTaggingForWevoId(null);
    setDisplayAddTagForWevoId(null);
  };

  const isNewTag = () => {
    return Boolean(
      !currentWevoTagsNames?.includes(trimmedTag) && !allWevosTagGroupsNames?.includes(trimmedTag)
    );
  };

  const isAlreadyTagged = (tag) => {
    return Boolean(currentWevoTagsNames?.includes(tag.toLowerCase()));
  };

  useEffect(() => {
    if (anchorRef?.current) {
      setMountPopper(true);
    }
  }, [setMountPopper]);

  return (
    <Box mt={1}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TextField
            ref={anchorRef}
            autoFocus
            sx={{
              width: '150px',
              backgroundColor: grey[100],
              borderRadius: 2,
              '& fieldset': { border: 'none' },
            }}
            type="text"
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
            }}
            onChange={handleTagChange}
            size="small"
            variant="outlined"
            value={tag}
            placeholder={'Add tags...'}
            inputProps={{ style: { fontSize: 14, height: 11 } }}
          />
        </Grid>
      </Grid>
      {mountPopper && (
        <ClickAwayListener onClickAway={handleClose}>
          <Popper
            open={openTagsMenu}
            anchorEl={anchorRef?.current}
            placement="bottom-start"
            transition
            disablePortal
            sx={{ zIndex: 1000, position: 'relative' }}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                }}>
                <Paper>
                  <MenuList id="tags-menu" aria-labelledby="tags-text-Box">
                    {isNewTag() ? (
                      tag.length ? (
                        <span>
                          <MenuItem
                            disabled={!tag.trim().length}
                            onClick={() => handleTagSelect(tag, null)}
                            sx={{ marginTop: '-8px', marginBottom: '-8px', minWidth: '150px' }}>
                            <AddIcon
                              sx={{
                                color: grey[500],
                                fontSize: '13px',
                                marginLeft: -1,
                                marginRight: 0.25,
                              }}
                            />
                            <Typography variant="caption">Create tag:</Typography>
                            &nbsp;
                            <Typography variant="caption" sx={{ color: grey[600] }}>{`"${tag}"`}</Typography>
                          </MenuItem>
                          {sortedTagsGroups?.filter(({ name }) => name.toLowerCase().includes(trimmedTag))
                            .length ? (
                            <hr
                              style={{
                                background: grey[300],
                                height: '0.5px',
                                border: 'none',
                              }}
                            />
                          ) : null}
                        </span>
                      ) : null
                    ) : null}
                    {tag.length
                      ? sortedTagsGroups
                          ?.filter(({ name }) => name.toLowerCase().includes(trimmedTag) && tag.trim().length)
                          ?.map((filteredTag, index) => {
                            return (
                              <MenuItem
                                disabled={isAlreadyTagged(filteredTag.name)}
                                key={index}
                                onClick={
                                  !isAlreadyTagged(filteredTag.name)
                                    ? () => handleTagSelect(filteredTag.name)
                                    : () => {}
                                }>
                                <Typography variant="caption">{filteredTag.name}</Typography>
                              </MenuItem>
                            );
                          })
                      : sortedTagsGroups?.slice(0, 10)?.map((filteredTag, index) => {
                          return (
                            <MenuItem
                              disabled={isAlreadyTagged(filteredTag.name)}
                              key={index}
                              onClick={
                                !isAlreadyTagged(filteredTag.name)
                                  ? () => handleTagSelect(filteredTag.name)
                                  : () => {}
                              }>
                              <Typography variant="caption">{filteredTag.name}</Typography>
                            </MenuItem>
                          );
                        })}
                  </MenuList>
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      )}
    </Box>
  );
};

const Tags = (props) => {
  const { wevoId, tags, enableTagging, setDisplayAddTagForWevoId, setEnableTaggingForWevoId } = props;

  const handleAddTagClick = () => {
    setDisplayAddTagForWevoId(null);
    setEnableTaggingForWevoId(wevoId);
  };

  return (
    <span>
      <Box>
        <Grid container spacing={1} sx={{ maxWidth: '500px' }}>
          <TagsList wevoId={wevoId} tags={tags} />
          {enableTagging ? (
            <Grid item xs={12}>
              <TagSelector
                wevoId={wevoId}
                tags={tags}
                setEnableTaggingForWevoId={setEnableTaggingForWevoId}
                setDisplayAddTagForWevoId={setDisplayAddTagForWevoId}
              />
            </Grid>
          ) : (
            <Grid item xs={12} className="show-on-hover">
              <Button onClick={() => handleAddTagClick()}># Add tag</Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </span>
  );
};

Tags.propTypes = {
  wevoId: PropTypes.string,
  enableTagging: PropTypes.bool,
  setDisplayAddTagForWevoId: PropTypes.func,
  setEnableTaggingForWevoId: PropTypes.func,
};

export default Tags;
