import { useQuery } from 'react-query';
import axios from '../../../api';

const getComparePagesResults = async ({ queryKey: [key, { wevoId }] }) => {
  const { data } = await axios({ url: `/api/v2/wevos/${wevoId}/compare`, method: 'GET' });
  return data;
};

export default function useComparePageResults(wevoId) {
  return useQuery(['comparePageResults', { wevoId }], getComparePagesResults);
}
