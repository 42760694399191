import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DeleteCustomQuestionDialog = (props) => {
  const { open, deleteCustomQuestion, closeCallback, isScreenerQuestion = false } = props;

  const handleClose = () => {
    closeCallback();
  };

  const handleReset = () => {
    !!deleteCustomQuestion && deleteCustomQuestion();
    handleClose();
  };

  const questionType = isScreenerQuestion ? 'Custom Screener Question' : 'Custom Question';

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="delete custom question confirmation dialog"
        aria-describedby="delete-custom-question-confirmation-description">
        <DialogTitle>Deleting {questionType}</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-custom-question-confirmation-description">
            Are you sure you want to delete this {questionType}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleReset}>Yup! Delete {questionType}</Button>
          <Button onClick={handleClose}>Do Nothing</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

DeleteCustomQuestionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  deleteCustomQuestion: PropTypes.func.isRequired,
  closeCallback: PropTypes.func.isRequired,
  isScreenerQuestion: PropTypes.bool,
};

export default React.memo(DeleteCustomQuestionDialog);
