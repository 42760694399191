import {
  CHANGE_PASSWORD_FAILED,
  FETCH_USER_FAILED,
  FETCH_USER_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
} from './actions';
import { setUser } from './helpers';

const initialState = {
  email: '',
  firstName: '',
  lastName: '',
  is_sso: false,
  title: '',
  dateCreated: null,
  pulseSubscription: null,
  isStreamlined: false,
  isEmailVerified: false,
  publicId: '',
  isBillingAdmin: null,
  usage: null,
};

const defaultAction = {
  payload: {},
};

export const userReducer = (state = initialState, action) => {
  const {
    id,
    email_address,
    firstName,
    lastName,
    is_sso,
    title,
    dateCreated,
    pulseSubscription,
    isStreamlined,
    isEmailVerified,
    teamId,
    acls,
    subscriptionLevel,
    customizations,
    company,
    userType,
    publicId,
    isBillingAdmin,
    usage,
    errorMessage,
  } = action.payload || defaultAction.payload;
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      // note: setUser() sets a limited version of the user in cookies for tracking
      // only fields used for analytics should be included in this version of the user
      setUser({
        userId: id,
        emailAddress: email_address,
        firstName,
        lastName,
        dateCreated,
        teamId,
        isStreamlined,
        isEmailVerified,
        publicId,
        isBillingAdmin,
      });

      return {
        ...state,
        id,
        email: email_address,
        firstName,
        lastName,
        is_sso,
        title: title === null ? '' : title,
        dateCreated,
        pulseSubscription,
        teamId,
        acls,
        subscriptionLevel,
        customizations,
        company,
        userType,
        isStreamlined,
        isEmailVerified,
        publicId,
        isBillingAdmin,
        usage,
      };
    case FETCH_USER_FAILED:
      return { ...state, errorMessage };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        email: email_address,
        firstName,
        lastName,
        is_sso,
        title: title === null ? '' : title,
        dateCreated,
      };
    case UPDATE_USER_FAILED:
      return { ...state, errorMessage };
    case CHANGE_PASSWORD_FAILED:
      return { ...state, errorMessage };

    default:
      return state;
  }
};
