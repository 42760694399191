import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchCompositeScores = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/composite-scores`,
    method: 'GET',
  });

  return data;
};

/**
 *
 * @param {{wevoId: string, pageId: string}} params
 * @returns
 */
export default function useCompositeScores({ wevoId, pageId }) {
  return useQuery(['compositeMetrics', { wevoId, pageId }], fetchCompositeScores, {
    notifyOnChangeProps: 'tracked',
  });
}
