import { useEffect } from 'react';
import { useQueryClient } from 'react-query';
import useConstants from '../../hooks/useConstants';
import { setIntakeConstants } from '../../modules/intake/constants';
import { setReportConstants } from '../../modules/report/constants';
import { setWevosConstants } from '../../modules/wevos/constants';

const InitializeConstants = ({ setConstantsPopulated }) => {
  const { isSuccess } = useConstants();
  const queryClient = useQueryClient();
  let constants;

  if (isSuccess) {
    constants = queryClient.getQueryData(['wevoCustomerAppConstants']);
    setWevosConstants(constants);
    setIntakeConstants(constants);
    setReportConstants(constants);
  }

  useEffect(() => {
    setConstantsPopulated(Boolean(constants));
  });

  return null;
};

export default InitializeConstants;
