import { generatePath, Redirect } from 'react-router-dom';
import { MetricTypes } from '../../../modules/intake/constants';
import { Paths } from '../../../routes';
import ConceptScore from './ConceptScore';

const ConceptScoreContainer = ({ wevo, rightDrawerOpen, setRightDrawerOpen }) => {
  if (wevo?.metricType !== MetricTypes.MastercardCds) {
    return <Redirect to={generatePath(Paths.reports.dashboard, { wevoId: wevo?.id })} />;
  }

  return (
    <ConceptScore
      wevo={wevo}
      page={wevo?.pages?.[0]}
      rightDrawerOpen={rightDrawerOpen}
      handleRightDrawerOpenClick={setRightDrawerOpen}
    />
  );
};

export default ConceptScoreContainer;
