import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { OrderedWevoStatuses } from '../../modules/wevos/constants';

const StatusFilter = ({ value = [], onChange }) => {
  const handleChange = (ev) => {
    onChange(ev.target.value);
  };
  return (
    <FormControl variant="standard">
      <InputLabel id="status-filter-heading" shrink>
        Status
      </InputLabel>
      <Select
        variant="standard"
        autoWidth
        multiple
        value={value}
        onChange={handleChange}
        aria-labelledby="status-filter-heading"
        displayEmpty
        style={{ textTransform: 'capitalize' }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
        renderValue={(selected) => (!Boolean(selected?.length) ? 'all' : selected.join(', '))}>
        {OrderedWevoStatuses.map((status) => (
          <MenuItem key={status} value={status}>
            <Checkbox checked={value.indexOf(status) > -1} color="secondary" />
            <ListItemText primary={status} style={{ textTransform: 'capitalize' }} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default StatusFilter;
