import { Box, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { default as React, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import useWevo from '../../../hooks/useWevo';
import { KeyFindingValues, MetricTypes } from '../../../modules/intake/constants';
import { getUserCustomizations } from '../../../modules/user/selectors';
import { CustomQuestionOption, KeyFindingOption } from '../../../modules/wevos/constants';
import { snackbar } from '../../../notifications';
import { TrackEvent, useTrackPageLoad } from '../../analytics';
import useFetchCustomQuestions from '../hooks/useFetchCustomQuestions.js';
import useSaveWevo from '../hooks/useSaveWevo';
import CustomQuestionDetailsSection from './CustomQuestionDetailsSection';
import KeyFindingsSelection from './KeyFindingsSelection';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(5),
  },
}));

const Details = (props) => {
  const { wevoId } = useParams();
  const { data: draft } = useWevo(wevoId);

  const { updateDetailsValidity, userCustomizations } = props;
  const [questionsValidity, setQuestionsValidity] = useState({});

  const classes = useStyles();
  const { mutate: saveWevo } = useSaveWevo();

  const { data: groups, isSuccess } = useFetchCustomQuestions(wevoId);
  const [customQuestions, setCustomQuestions] = useState();

  const [includeKeyFindings, setIncludeKeyFindings] = useState(draft?.details?.includeKeyFindings ?? false);

  const isKeyFindingsOptional =
    userCustomizations?.features?.keyFindings === KeyFindingOption.Optional &&
    draft?.metricType !== MetricTypes.MastercardCds;

  const isCustomQuestionsEnabled =
    userCustomizations?.features?.customQuestions !== CustomQuestionOption.Disabled;
  const showCustomQuestions = isCustomQuestionsEnabled;

  const handleKeyFindingSelect = useCallback(
    (ev) => {
      const prevVal = includeKeyFindings;
      const newVal = ev.target.value !== KeyFindingValues.No;
      setIncludeKeyFindings(newVal);
      saveWevo(
        { id: wevoId, includeKeyFindings: newVal },
        {
          onError: (err) => {
            setIncludeKeyFindings(prevVal);
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error saving wevo');
          },
        }
      );
    },
    [saveWevo, wevoId, includeKeyFindings]
  );

  const customQuestionCoupletsCount = useMemo(() => {
    return groups?.reduce((first, b) => {
      // When 'Insight Pages' option is selected but there are 0 steps selected, the length of the scopes
      // array is 0. Since we still want to count this as a custom question, we count this as 1.
      const second = b?.scopes?.length === 0 ? 1 : b?.scopes?.length;
      return first + second;
    }, 0);
  }, [groups]);

  useEffect(() => {
    // Only set it on initial load.
    if (!customQuestions && isSuccess) {
      setCustomQuestions(groups);
    }
  }, [groups, customQuestions, isSuccess]);

  useEffect(() => {
    if (customQuestions?.length === 0) {
      updateDetailsValidity(true);
    } else {
      const validationValues = Object.values({ ...questionsValidity });
      const foundItems = validationValues.filter((element) => element === false);
      updateDetailsValidity(foundItems.length > 0 ? false : true);
    }
  }, [customQuestions?.length, questionsValidity, updateDetailsValidity]);

  useTrackPageLoad({ name: TrackEvent.VIEWED_EDIT_TEST_DETAILS, properties: { wevoId: draft?.analyticsId } });

  return (
    <Grid container className={classes.root} spacing={2} justifyContent="center">
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom align="center">
          Select Your Test Details
        </Typography>
      </Grid>

      {/* Key Findings section */}
      <Grid item xs={12} className={classes.section}>
        {/* show only if the value of 'customizations.features.keyFindings' is "optional" and testType !== "usability" */}
        {isKeyFindingsOptional && !draft.isUsabilityTestType && (
          <Fragment>
            <Box py={1} />
            <Grid item xs>
              <Typography variant="h5" gutterBottom>
                Would you like to add Key Findings to your report?
              </Typography>
              <div>
                <Typography gutterBottom variant="body2">
                  Too much on your plate? Let WEVO do the heavy lifting.
                </Typography>
                <Typography variant="caption" component="div" gutterBottom>
                  WEVO can analyze your test and include Key Findings in your report. See an example{' '}
                  <a
                    href="https://app.wevo.ai/wevos/5AhsrU4o/report/key-findings"
                    target="_blank"
                    rel="noopener noreferrer">
                    here
                  </a>
                  .
                </Typography>
                <Typography variant="caption" component="div">
                  Add Key Findings:
                </Typography>
                <List dense disablePadding>
                  <ListItem style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <ListItemText primary="+ 1 credit" primaryTypographyProps={{ variant: 'caption' }} />
                  </ListItem>
                  <ListItem style={{ paddingTop: '0px', paddingBottom: '0px' }}>
                    <ListItemText primary="+ 4 days" primaryTypographyProps={{ variant: 'caption' }} />
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} sm={9} md={7}>
              <KeyFindingsSelection
                keyFindingsSelection={includeKeyFindings === true ? KeyFindingValues.Yes : KeyFindingValues.No}
                handleKeyFindingSelect={handleKeyFindingSelect}
              />
            </Grid>
            <Box py={1} />
          </Fragment>
        )}
      </Grid>
      {showCustomQuestions && (
        <Grid item xs={12}>
          <CustomQuestionDetailsSection
            draft={draft ?? {}}
            customQuestions={customQuestions ?? []}
            setCustomQuestions={setCustomQuestions}
            setQuestionsValidity={setQuestionsValidity}
            customQuestionCoupletsCount={customQuestionCoupletsCount}
          />
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    userCustomizations: getUserCustomizations(state),
  };
};

export default connect(mapStateToProps)(Details);
