import { useQuery } from 'react-query';
import axios from '../../../api';

const fetchSurveyDetails = async ({ queryKey: [key, { wevoId, pageId }] }) => {
  const { data } = await axios({
    url: `/api/v2/wevos/${wevoId}/pages/${pageId}/respondents/details`,
    method: 'GET',
  });

  return data ?? [];
};

/**
 *
 * @param {{wevoId: string, pageIds: array[string]}} params
 * @returns
 */
export default function useFetchSurveyDetails({ wevoId, pageId }) {
  return useQuery(['surveyDetails', { wevoId, pageId }], fetchSurveyDetails, {
    notifyOnChangeProps: 'tracked',
  });
}
