import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import InfoIcon from '@mui/icons-material/Info';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Grid, List, ListItem, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { isEmpty as _isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  CustomQuestionTypes,
  MultiSelectOptions,
  PagesApplyTo,
  Scales,
  SelectOptions,
} from '../../../modules/intake/constants';
import { snackbar } from '../../../notifications';
import MultipleChoice from '../edit/MultipleChoice';
import useUpdateCustomQuestion from '../hooks/useUpdateCustomQuestion';

const styles = {
  radioIcon: {
    marginRight: 1,
    fontSize: 'large',
    color: grey[600],
  },
  labelErrors: {
    fontSize: '14px',
    marginLeft: '12px',
  },
  infoIcon: {
    marginLeft: (theme) => theme.spacing(1),
    verticalAlign: 'bottom',
    color: grey[600],
    fontSize: '20px',
  },
};

const DEFAULT_NUM_CHOICES = {
  [CustomQuestionTypes.YesNoTrueFalse]: 2,
  [CustomQuestionTypes.MultipleChoice]: 2,
  [CustomQuestionTypes.FiveLikertScale]: 5,
  [CustomQuestionTypes.SevenLikertScale]: 7,
  [CustomQuestionTypes.MultiSelect]: 2,
};

const NUM_CUSTOM_YES_NO = 2;
const NUM_CUSTOM_LIKERT_5 = 5;
const NUM_CUSTOM_LIKERT_7 = 7;
const MIN_NUM_MULTIPLE_CHOICE = 2;
const MAX_NUM_MULTIPLE_CHOICE = 10;
const MIN_NUM_MULTI_SELECT = 2;
const MAX_NUM_MULTI_SELECT = 15;

const MIN_NUM_RESPONSES = 1;

const MAX_NUM_HEATMAP_QUESTIONS = 1;

const QuestionTypeScale = (props) => {
  const {
    question,
    setFollowUpText,
    setQType,
    setIsValidTypeAndLabels,
    wevoId,
    groupId,
    checkedPagesApplyTo,
    customQuestions,
    setCustomQuestions,
  } = props;

  // HACK: Allow admins to bypass the limit of heatmap custom questions.
  const useQuery = () => new URLSearchParams(useLocation()?.search);
  const query = useQuery();
  const forceNoLimit = query.get('noLimit') === 'true';

  const [type, setType] = useState(question?.type);
  const [labelsType, setLabelsType] = useState(question?.labelsType ?? '');
  const [labels, setLabels] = useState(question?.labels ?? []);
  const [selectionRange, setSelectionRange] = useState(question?.rangeType ?? '');
  const [numOfSelections, setNumOfSelections] = useState(question?.number ?? '');

  const selectionType =
    type === CustomQuestionTypes.MultipleChoice
      ? CustomQuestionTypes.MultipleChoice
      : CustomQuestionTypes.MultiSelect;
  let amountToFill = DEFAULT_NUM_CHOICES[selectionType] - labels.length;
  if (amountToFill <= 0 || type !== selectionType) {
    amountToFill = 0;
  }

  const [customLabels, setCustomLabels] = useState([...labels, ...Array(amountToFill).fill('')]);

  const [localErrors, setLocalErrors] = useState({});
  const { mutate: updateCustomQuestion } = useUpdateCustomQuestion();

  /* Handles question type selection */
  const handleTypeOptionClick = (selectedType) => {
    if (selectedType !== type) {
      updateQuestionType(selectedType);
    }
  };

  const numOfHeatmapQuestions = useMemo(
    () => customQuestions?.filter((group) => group?.question?.type === CustomQuestionTypes.Heatmap).length,
    [customQuestions]
  );

  /* Handles labelsType selection */
  const handleLabelsTypeOptionClick = (selectedLabelsType) => {
    if (selectedLabelsType !== labelsType) {
      setIsValidTypeAndLabels(selectedLabelsType !== SelectOptions.Custom ? true : false);
      switch (selectedLabelsType) {
        case SelectOptions.AgreeDisagree:
          if (type === CustomQuestionTypes.FiveLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.agreeDisagree.likert5);
          } else if (type === CustomQuestionTypes.SevenLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.agreeDisagree.likert7);
          }
          return;
        case SelectOptions.LikeDislike:
          if (type === CustomQuestionTypes.FiveLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.likeDislike.likert5);
          } else if (type === CustomQuestionTypes.SevenLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.likeDislike.likert7);
          }
          return;
        case SelectOptions.SatisfiedDissatisfied:
          if (type === CustomQuestionTypes.FiveLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.satisfiedDissatisfied.likert5);
          } else if (type === CustomQuestionTypes.SevenLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.satisfiedDissatisfied.likert7);
          }
          return;
        case SelectOptions.LikelyUnlikely:
          if (type === CustomQuestionTypes.FiveLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.likelyUnlikely.likert5);
          } else if (type === CustomQuestionTypes.SevenLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.likelyUnlikely.likert7);
          }
          return;
        case SelectOptions.EasyDifficult:
          if (type === CustomQuestionTypes.FiveLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.easyDifficult.likert5);
          } else if (type === CustomQuestionTypes.SevenLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.easyDifficult.likert7);
          }
          return;
        case SelectOptions.YesNo:
          updateLabelsAndLabelsType(selectedLabelsType, Scales.yesNo);
          return;
        case SelectOptions.TrueFalse:
          updateLabelsAndLabelsType(selectedLabelsType, Scales.trueFalse);
          return;
        case SelectOptions.Custom:
          const labelsArray = Array(DEFAULT_NUM_CHOICES[type]).fill('');
          updateLabelsAndLabelsType(selectedLabelsType, labelsArray);
          return;
        default:
          if (type === CustomQuestionTypes.FiveLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.agreeDisagree.likert5);
          } else if (type === CustomQuestionTypes.SevenLikertScale) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.agreeDisagree.likert7);
          } else if (type === CustomQuestionTypes.YesNoTrueFalse) {
            updateLabelsAndLabelsType(selectedLabelsType, Scales.yesNo);
          }
          return;
      }
    }
  };

  /* Updates labelsType and labels together */
  const updateLabelsAndLabelsType = useMemo(
    () => (newLabelsType, newLabels) =>
      updateCustomQuestion(
        { id: wevoId, groupId: groupId, labelsType: newLabelsType, labels: newLabels },
        {
          onSuccess: () => {
            if (newLabelsType === SelectOptions.Custom) {
              setCustomLabels(newLabels);
            }
            setLabelsType(newLabelsType);
            setLabels(newLabels);
          },
          onError: (err, variables, { previousCustomQuestionState }) => {
            const index = previousCustomQuestionState?.findIndex((q) => String(q.groupId) === String(groupId));
            if (index >= 0) {
              setLabels(previousCustomQuestionState?.[index]?.question?.labels);
              setLabelsType(previousCustomQuestionState?.[index]?.question?.labelsType ?? '');
            }

            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');
          },
        }
      ),
    [wevoId, groupId, updateCustomQuestion]
  );

  /* Updates options for multi-select */
  const updateOptions = useMemo(
    () => (rangeType, number) => {
      let validation = {};
      if (rangeType === MultiSelectOptions.AllThatApply) {
        validation.min = MIN_NUM_RESPONSES;
        validation.max = customLabels.length;
      } else if (rangeType === MultiSelectOptions.Exactly) {
        validation.min = number;
        validation.max = number;
      } else if (rangeType === MultiSelectOptions.AtLeast) {
        validation.min = number;
        validation.max = customLabels.length;
      } else if (rangeType === MultiSelectOptions.UpTo) {
        validation.min = MIN_NUM_RESPONSES;
        validation.max = number;
      }

      updateCustomQuestion(
        {
          id: wevoId,
          groupId: groupId,
          validation: { type: 'range', rangeType, number, ...validation },
          randomization: { type: 'all' },
        },
        {
          onSuccess: () => {
            setSelectionRange(rangeType);
            if (rangeType === MultiSelectOptions.AllThatApply) {
              setNumOfSelections('');
            } else setNumOfSelections(number);
          },
          onError: (err) => {
            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');
          },
        }
      );
    },
    [wevoId, groupId, updateCustomQuestion, customLabels]
  );

  /* Update QuestionType */
  const updateQuestionType = useMemo(
    () => (newType) =>
      updateCustomQuestion(
        { id: wevoId, groupId: groupId, type: newType },
        {
          onSuccess: () => {
            const groups = [...customQuestions];
            const updatedGroupIndex = customQuestions.findIndex((question) => question.groupId === groupId);
            const updatedGroup = groups[updatedGroupIndex];
            const updatedQuestion = updatedGroup.question;
            groups[updatedGroupIndex] = { ...updatedGroup, question: { ...updatedQuestion, type: newType } };
            setCustomQuestions(groups);
            setLabelsType('');
            setLabels([]);
            setCustomLabels([]);
            setNumOfSelections('');
            setSelectionRange('');
            setLocalErrors({});
            setIsValidTypeAndLabels(
              ![CustomQuestionTypes.CustomQualitative, CustomQuestionTypes.Heatmap].includes(newType)
                ? false
                : true
            );
            if ([CustomQuestionTypes.MultipleChoice, CustomQuestionTypes.MultiSelect].includes(newType)) {
              setLabelsType(SelectOptions.Custom);
              setCustomLabels([...Array(DEFAULT_NUM_CHOICES[newType]).fill('')]);
              updateLabelsAndLabelsType(SelectOptions.Custom, []);
            }
            if (newType === CustomQuestionTypes.CustomQualitative) {
              setFollowUpText('');
            }
            setType(newType);
            setQType(newType); //updates type in parent component for showing/not showing "FollowUpQuestion"
          },
          onError: (err, variables, { previousCustomQuestionState }) => {
            const index = previousCustomQuestionState?.findIndex((q) => String(q.groupId) === String(groupId));
            if (index >= 0) {
              setType(previousCustomQuestionState?.[index]?.question?.type);
            }

            snackbar.error(err?.response?.data?.humanReadableMessage ?? 'Error updating custom question');
          },
        }
      ),
    [
      wevoId,
      groupId,
      updateCustomQuestion,
      setFollowUpText,
      setIsValidTypeAndLabels,
      setQType,
      updateLabelsAndLabelsType,
      customQuestions,
      setCustomQuestions,
    ]
  );

  useEffect(() => {
    if (!type) {
      // on first render, if there is no type defined, set type with default value: Custom Qualitative
      setType(CustomQuestionTypes.CustomQualitative);
    }
  }, [type]);

  // Handles errors
  useEffect(() => {
    const filteredLabels = customLabels.filter((label) => !_isEmpty(label));
    if (type === CustomQuestionTypes.CustomQualitative || type === CustomQuestionTypes.Heatmap) {
      setLocalErrors((localErrors) => ({ ...localErrors, labels: false }));
    } else if (type === CustomQuestionTypes.MultipleChoice) {
      if (
        filteredLabels.length >= MIN_NUM_MULTIPLE_CHOICE &&
        filteredLabels.length <= MAX_NUM_MULTIPLE_CHOICE
      ) {
        setLocalErrors((localErrors) => ({ ...localErrors, options: false, labels: false }));
        setIsValidTypeAndLabels(true);
      } else {
        setLocalErrors((localErrors) => ({
          ...localErrors,
          options: false,
          labels: {
            message: 'Please make sure none of your choices are blank',
          },
        }));
        setIsValidTypeAndLabels(false);
      }
    } else if (type === CustomQuestionTypes.MultiSelect) {
      if (
        filteredLabels.length >= MIN_NUM_MULTI_SELECT &&
        filteredLabels.length <= MAX_NUM_MULTI_SELECT &&
        selectionRange !== '' &&
        numOfSelections !== ''
      ) {
        // if all Multi Select fields are filled, check if number of required responses is less than the number of choices
        if (selectionRange !== MultiSelectOptions.AllThatApply && filteredLabels.length <= numOfSelections) {
          setLocalErrors((localErrors) => ({
            ...localErrors,
            number: { message: '' },
            labels: {
              message: 'Please make sure there are more choices than required responses',
            },
          }));
          setIsValidTypeAndLabels(false);
        } else {
          setLocalErrors((localErrors) => ({ ...localErrors, options: false, labels: false, number: false }));
          setIsValidTypeAndLabels(true);
        }
      }
      // if All that apply is selected, the number of required responses is not necessary
      else if (
        filteredLabels.length >= MIN_NUM_MULTI_SELECT &&
        filteredLabels.length <= MAX_NUM_MULTI_SELECT &&
        selectionRange === MultiSelectOptions.AllThatApply
      ) {
        setLocalErrors((localErrors) => ({ ...localErrors, options: false, labels: false, number: false }));
        setIsValidTypeAndLabels(true);
      } else {
        // if the minimum number of choices is not filled out, validate each field individually
        if (selectionRange === '') {
          setLocalErrors((localErrors) => ({
            ...localErrors,
            options: {
              message: 'Please select one',
            },
          }));
        } else {
          setLocalErrors((localErrors) => ({
            ...localErrors,
            options: false,
          }));
        }
        if (numOfSelections === '') {
          setLocalErrors((localErrors) => ({
            ...localErrors,
            number: {
              message: 'Please select one',
            },
          }));
        } else {
          setLocalErrors((localErrors) => ({
            ...localErrors,
            number: false,
          }));
        }
        if (filteredLabels.length < MIN_NUM_MULTI_SELECT) {
          setLocalErrors((localErrors) => ({
            ...localErrors,
            labels: {
              message: 'Please make sure none of your choices are blank',
            },
          }));
        } else {
          setLocalErrors((localErrors) => ({
            ...localErrors,
            labels: false,
          }));
        }
        setIsValidTypeAndLabels(false);
      }
    } else {
      // there is not labelsType selected - (after switching type)
      if (labelsType === '') {
        setLocalErrors((localErrors) => ({
          ...localErrors,
          options: { message: 'Please select labels scale' },
        }));
        setIsValidTypeAndLabels(false);
      } else if (labelsType !== SelectOptions.Custom) {
        setLocalErrors((localErrors) => ({
          ...localErrors,
          options: false,
          labels: false,
        }));
        setIsValidTypeAndLabels(true);
      }
      // labelsType is Custom - need to check if all required custom choices are filled
      else {
        if (type === CustomQuestionTypes.FiveLikertScale) {
          if (filteredLabels.length === NUM_CUSTOM_LIKERT_5) {
            setLocalErrors((localErrors) => ({ ...localErrors, labels: false }));
            setIsValidTypeAndLabels(true);
          } else {
            setLocalErrors((localErrors) => ({
              ...localErrors,
              options: false,
              labels: { message: 'Please enter 5 custom choices' },
            }));
            setIsValidTypeAndLabels(false);
          }
        } else if (type === CustomQuestionTypes.SevenLikertScale) {
          if (filteredLabels.length === NUM_CUSTOM_LIKERT_7) {
            setLocalErrors((localErrors) => ({ ...localErrors, labels: false }));
            setIsValidTypeAndLabels(true);
          } else {
            setLocalErrors((localErrors) => ({
              ...localErrors,
              options: false,
              labels: { message: 'Please enter 7 custom choices' },
            }));
            setIsValidTypeAndLabels(false);
          }
        } else if (type === CustomQuestionTypes.YesNoTrueFalse) {
          if (filteredLabels.length === NUM_CUSTOM_YES_NO) {
            setLocalErrors((localErrors) => ({ ...localErrors, labels: false }));
            setIsValidTypeAndLabels(true);
          } else {
            setLocalErrors((localErrors) => ({
              ...localErrors,
              options: false,
              labels: { message: 'Please enter 2 custom choices' },
            }));
            setIsValidTypeAndLabels(false);
          }
        }
      }
    }
  }, [customLabels, setIsValidTypeAndLabels, labelsType, type, selectionRange, numOfSelections]);

  useEffect(() => {
    setLabels(customLabels);
  }, [customLabels]);

  const handleSelectionRange = (range) => {
    if (range !== selectionRange) {
      if (range === MultiSelectOptions.AllThatApply) {
        const number = '';
        updateOptions(range, number);
      } else updateOptions(range, numOfSelections);
    }
  };

  const handleNumOfSelections = (number) => {
    if (number !== numOfSelections) {
      updateOptions(selectionRange, number);
    }
  };

  const heatmapTypeTooltipText = () => {
    if (forceNoLimit) {
      if (checkedPagesApplyTo === PagesApplyTo.JourneyLevel) {
        return 'Custom Click Map is only supported for "Insight Pages" scoped questions';
      } else return '';
    } else if (numOfHeatmapQuestions >= MAX_NUM_HEATMAP_QUESTIONS && type !== CustomQuestionTypes.Heatmap) {
      return 'Only one Custom Click Map question may be applied per test';
    } else if (checkedPagesApplyTo === PagesApplyTo.JourneyLevel) {
      return 'Custom Click Map is only supported for "Insight Pages" scoped questions';
    } else return '';
  };

  const canAddHeatmapQuestions =
    checkedPagesApplyTo !== PagesApplyTo.JourneyLevel &&
    (type === CustomQuestionTypes.Heatmap ||
      forceNoLimit ||
      numOfHeatmapQuestions < MAX_NUM_HEATMAP_QUESTIONS);

  return (
    <Grid container spacing={2} justifyContent="space-evenly" wrap="wrap">
      <Grid item sx={{ width: '50%' }}>
        <TextField
          fullWidth
          select
          variant="outlined"
          id="QuestionType"
          label="Question Type"
          value={type}
          InputLabelProps={{ shrink: true }}
          SelectProps={{ displayEmpty: true }}>
          <MenuItem
            value={CustomQuestionTypes.CustomQualitative}
            onClick={() => handleTypeOptionClick(CustomQuestionTypes.CustomQualitative)}>
            Custom Qualitative
          </MenuItem>
          <MenuItem
            value={CustomQuestionTypes.FiveLikertScale}
            onClick={() => handleTypeOptionClick(CustomQuestionTypes.FiveLikertScale)}>
            5 Likert Scale
          </MenuItem>
          <MenuItem
            value={CustomQuestionTypes.SevenLikertScale}
            onClick={() => handleTypeOptionClick(CustomQuestionTypes.SevenLikertScale)}>
            7 Likert Scale
          </MenuItem>
          <MenuItem
            value={CustomQuestionTypes.MultipleChoice}
            onClick={() => handleTypeOptionClick(CustomQuestionTypes.MultipleChoice)}>
            Multiple Choice (up to 10 choices)
          </MenuItem>
          <MenuItem
            value={CustomQuestionTypes.MultiSelect}
            onClick={() => handleTypeOptionClick(CustomQuestionTypes.MultiSelect)}>
            Multiple Selection (up to 15 choices)
          </MenuItem>
          <MenuItem
            value={CustomQuestionTypes.YesNoTrueFalse}
            onClick={() => handleTypeOptionClick(CustomQuestionTypes.YesNoTrueFalse)}>
            Yes/No or True/False
          </MenuItem>
          {/* Enable the custom click map option only if the question is not scoped to the journey
          and either the max number of click map questions has not been reached or the option was previously selected */}
          {canAddHeatmapQuestions ? (
            <MenuItem
              value={CustomQuestionTypes.Heatmap}
              onClick={() => handleTypeOptionClick(CustomQuestionTypes.Heatmap)}>
              Custom Click Map
            </MenuItem>
          ) : (
            <Tooltip title={heatmapTypeTooltipText()} placement="right" arrow>
              <div>
                <MenuItem value={CustomQuestionTypes.Heatmap} disabled={true}>
                  Custom Click Map
                </MenuItem>
              </div>
            </Tooltip>
          )}
        </TextField>
      </Grid>
      <Grid item sx={{ width: '50%' }}>
        {/* Drop down menu for 5 Likert or 7 Likert Type */}
        {(type === CustomQuestionTypes.FiveLikertScale || type === CustomQuestionTypes.SevenLikertScale) && (
          <TextField
            fullWidth
            select
            variant="outlined"
            id="5LikertTypeOptions"
            label="Scale"
            value={labelsType}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            error={Boolean(localErrors?.options)}
            helperText={localErrors?.options?.message ?? ''}>
            <MenuItem
              value={SelectOptions.AgreeDisagree}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.AgreeDisagree)}>
              <Grid container direction="row" alignItems="center">
                Agree <ArrowRightAltIcon /> Disagree
              </Grid>
            </MenuItem>
            <MenuItem
              value={SelectOptions.LikeDislike}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.LikeDislike)}>
              <Grid container direction="row" alignItems="center">
                Like <ArrowRightAltIcon /> Dislike
              </Grid>
            </MenuItem>
            <MenuItem
              value={SelectOptions.SatisfiedDissatisfied}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.SatisfiedDissatisfied)}>
              <Grid container direction="row" alignItems="center">
                Satisfied <ArrowRightAltIcon /> Dissatisfied
              </Grid>
            </MenuItem>
            <MenuItem
              value={SelectOptions.LikelyUnlikely}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.LikelyUnlikely)}>
              <Grid container direction="row" alignItems="center">
                Likely <ArrowRightAltIcon /> Unlikely
              </Grid>
            </MenuItem>
            <MenuItem
              value={SelectOptions.EasyDifficult}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.EasyDifficult)}>
              <Grid container direction="row" alignItems="center">
                Easy <ArrowRightAltIcon /> Difficult
              </Grid>
            </MenuItem>
            <MenuItem
              value={SelectOptions.Custom}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.Custom)}>
              <Grid container direction="row" alignItems="center">
                Custom
              </Grid>
            </MenuItem>
          </TextField>
        )}

        {/* Drop down menu for Yes/No */}
        {type === CustomQuestionTypes.YesNoTrueFalse && (
          <TextField
            fullWidth
            select
            variant="outlined"
            id="YesNoTrueFalseOptions"
            label="Scale"
            value={labelsType}
            InputLabelProps={{ shrink: true }}
            SelectProps={{ displayEmpty: true }}
            error={Boolean(localErrors?.options)}
            helperText={localErrors?.options?.message ?? ''}>
            <MenuItem
              value={SelectOptions.YesNo}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.YesNo)}>
              Yes / No
            </MenuItem>
            <MenuItem
              value={SelectOptions.TrueFalse}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.TrueFalse)}>
              True / False
            </MenuItem>
            <MenuItem
              value={SelectOptions.Custom}
              onClick={() => handleLabelsTypeOptionClick(SelectOptions.Custom)}>
              Custom
            </MenuItem>
          </TextField>
        )}

        {/* Dropdown menus for Multi Select */}
        {type === CustomQuestionTypes.MultiSelect && (
          <Grid container item spacing={2} wrap="nowrap">
            <Grid item sx={{ width: '60%' }}>
              <TextField
                fullWidth
                select
                variant="outlined"
                id="MultiSelectOptions"
                label="Respondents must choose"
                value={selectionRange}
                InputLabelProps={{ shrink: true }}
                SelectProps={{ displayEmpty: true }}
                error={Boolean(localErrors?.options)}
                helperText={localErrors?.options?.message ?? ''}>
                <MenuItem
                  value={MultiSelectOptions.AllThatApply}
                  onClick={() => handleSelectionRange(MultiSelectOptions.AllThatApply)}>
                  <Grid container direction="row" alignItems="center">
                    All that apply
                  </Grid>
                </MenuItem>
                <MenuItem
                  value={MultiSelectOptions.Exactly}
                  onClick={() => handleSelectionRange(MultiSelectOptions.Exactly)}>
                  <Grid container direction="row" alignItems="center">
                    Exactly
                  </Grid>
                </MenuItem>
                <MenuItem
                  value={MultiSelectOptions.AtLeast}
                  onClick={() => handleSelectionRange(MultiSelectOptions.AtLeast)}>
                  <Grid container direction="row" alignItems="center">
                    At least
                  </Grid>
                </MenuItem>
                <MenuItem
                  value={MultiSelectOptions.UpTo}
                  onClick={() => handleSelectionRange(MultiSelectOptions.UpTo)}>
                  <Grid container direction="row" alignItems="center">
                    Up to
                  </Grid>
                </MenuItem>
              </TextField>
            </Grid>
            {[MultiSelectOptions.AtLeast, MultiSelectOptions.Exactly, MultiSelectOptions.UpTo].includes(
              selectionRange
            ) && (
              <Grid item sx={{ minWidth: '30%' }}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  id="NumberOfSelections"
                  label="Number"
                  value={numOfSelections}
                  InputLabelProps={{ shrink: true }}
                  SelectProps={{ displayEmpty: true }}
                  error={Boolean(localErrors?.number)}
                  helperText={localErrors?.number?.message ?? ''}>
                  {[...Array(MAX_NUM_MULTI_SELECT).keys()].map((num) => (
                    <MenuItem key={num} value={num + 1} onClick={() => handleNumOfSelections(num + 1)}>
                      <Grid container direction="row" alignItems="center">
                        {num + 1}
                      </Grid>
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>

      {/* Chart for 5 Likert Type */}
      {type === CustomQuestionTypes.FiveLikertScale && labelsType !== SelectOptions.Custom && (
        <Grid item xs={12}>
          <List>
            {labels.map((value, index) => (
              <ListItem id={value} key={index}>
                <RadioButtonUncheckedIcon sx={styles.radioIcon} />
                <Typography variant="body1">{value}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}

      {/* Chart for 5 Likert Type - Custom Labels Type  */}
      {type === CustomQuestionTypes.FiveLikertScale && labelsType === SelectOptions.Custom && (
        <Grid container item direction="column" justifyContent="center">
          <MultipleChoice
            customLabels={customLabels}
            setCustomLabels={setCustomLabels}
            type={type}
            wevoId={wevoId}
            groupId={groupId}
          />
        </Grid>
      )}
      {type === CustomQuestionTypes.FiveLikertScale &&
        labelsType === SelectOptions.Custom &&
        Boolean(localErrors.labels) && (
          <Grid item xs={12}>
            <Typography color="error" sx={styles.labelErrors}>
              {localErrors.labels.message}
            </Typography>
          </Grid>
        )}

      {/* Chart for 7 Likert Type  */}
      {type === CustomQuestionTypes.SevenLikertScale && labelsType !== SelectOptions.Custom && (
        <Grid item xs={12}>
          <List>
            {labels.map((value, index) => (
              <ListItem id={value} key={index}>
                <RadioButtonUncheckedIcon sx={styles.radioIcon} />
                <Typography variant="body1">{value}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}

      {/* Chart for 7 Likert Type - Custom Labels Type  */}
      {type === CustomQuestionTypes.SevenLikertScale && labelsType === SelectOptions.Custom && (
        <Grid container item direction="column" justifyContent="center">
          <MultipleChoice
            customLabels={customLabels}
            setCustomLabels={setCustomLabels}
            type={type}
            wevoId={wevoId}
            groupId={groupId}
          />
        </Grid>
      )}
      {type === CustomQuestionTypes.SevenLikertScale &&
        labelsType === SelectOptions.Custom &&
        Boolean(localErrors.labels) && (
          <Grid item xs={12}>
            <Typography color="error" sx={styles.labelErrors}>
              {localErrors.labels.message}
            </Typography>
          </Grid>
        )}

      {/* Chart for Yes / No  */}
      {type === CustomQuestionTypes.YesNoTrueFalse && labelsType !== SelectOptions.Custom && (
        <Grid item xs={12}>
          <List>
            {labels.map((value, index) => (
              <ListItem id={value} key={index}>
                <RadioButtonUncheckedIcon sx={styles.radioIcon} />
                <Typography variant="body1">{value}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}

      {/* Chart for Yes / No Type - Custom Labels Type  */}
      {type === CustomQuestionTypes.YesNoTrueFalse && labelsType === SelectOptions.Custom && (
        <Grid container item direction="column" justifyContent="center">
          <MultipleChoice
            customLabels={customLabels}
            setCustomLabels={setCustomLabels}
            type={type}
            wevoId={wevoId}
            groupId={groupId}
          />
        </Grid>
      )}
      {type === CustomQuestionTypes.YesNoTrueFalse &&
        labelsType === SelectOptions.Custom &&
        Boolean(localErrors.labels) && (
          <Grid item xs={12}>
            <Typography color="error" sx={styles.labelErrors}>
              {localErrors.labels.message}
            </Typography>
          </Grid>
        )}

      {/* Chart for Multiple Choice Type Component */}
      {type === CustomQuestionTypes.MultipleChoice && (
        <Grid container item direction="column" justifyContent="center">
          <MultipleChoice
            customLabels={customLabels}
            setCustomLabels={setCustomLabels}
            type={type}
            wevoId={wevoId}
            groupId={groupId}
          />
        </Grid>
      )}

      {/* Chart for Multi Select Type Component */}
      {type === CustomQuestionTypes.MultiSelect && (
        <Grid container item direction="column" justifyContent="center">
          <Grid item mt={1}>
            <Typography variant="h5">
              Enter your custom choices
              <Tooltip
                title={`Choices will be randomized in order to minimize survey biases`}
                placement="right">
                <InfoIcon sx={styles.infoIcon} />
              </Tooltip>
            </Typography>
          </Grid>
          <MultipleChoice
            customLabels={customLabels}
            setCustomLabels={setCustomLabels}
            type={type}
            wevoId={wevoId}
            groupId={groupId}
            selectionRange={selectionRange}
            numOfSelections={numOfSelections}
          />
        </Grid>
      )}
      {[CustomQuestionTypes.MultipleChoice, CustomQuestionTypes.MultiSelect].includes(type) &&
        labelsType === SelectOptions.Custom &&
        Boolean(localErrors.labels) && (
          <Grid item xs={12}>
            <Typography color="error" sx={styles.labelErrors}>
              {localErrors.labels.message}
            </Typography>
          </Grid>
        )}
    </Grid>
  );
};
export default QuestionTypeScale;
